<app-loading-screen></app-loading-screen>
<div class="config-infoParent mr-0">
  <div class="row config-info-title mediumFontSize2 mb-3 m-0"> Configure solution codes</div>

  <div class="bg-edp-body p-2 m-0 position-relative">
    <div class="d-flex p-2">
      <div class="mr-auto">
        <div class="d-flex flex-row bd-highlight">
          <a class="px-3 py-1 anchoroutline" (click)="ShowHideListFilterPanel('Show')" data-placement="bottom"
            target="_blank"
            [class]="(isActiveFilter===true)?'px-3 py-1 anchoroutline active-filter':'px-3 py-1 anchoroutline ' ">
            <img class="shortcutimg" src="../../../../assets/images/EDP/Filters/filter_list.svg" alt="filter-list" />
            Filter
          </a>
          <a class="px-3 py-1 anchoroutline" (click)="ShowAllSolutionCodesWithNoFilters()" data-placement="bottom"
            target="_blank"
            [class]="(isShowActiveFilter===true)?'px-3 py-1 anchoroutline active-filter':'px-3 py-1 anchoroutline ' ">
            <img class="shortcutimg" src="../../../../assets/images/EDP/Filters/remove_red_eye.svg" alt="remove" />
            Show all
          </a>
        </div>
      </div>
    </div>
    <!-- Accordian view For SolutionCode ListView start -->
    <section id="listViewFilterSectionForSolutionCode" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-2">
            <div class="col-lg-2 col-md-3 filter-partition">
              <label class="font-weight-bold mb-1">Service line</label>
              <span class="custom-control custom-checkbox">
                <input type="checkbox" id="isFilterAdvisory" name="isFilterAdvisory" class="custom-control-input"
                  [(ngModel)]="currentSolutionCodeSearchFilter.isAdvisory">
                <label class="custom-control-label" for="isFilterAdvisory">Advisory</label>
              </span>

              <span class="custom-control custom-checkbox ">
                <input type="checkbox" id="isFilterAudit" name="isFilterAudit" class="custom-control-input"
                  [(ngModel)]="currentSolutionCodeSearchFilter.isAudit">
                <label class="custom-control-label" for="isFilterAudit">Audit</label>
              </span>

              <span class="custom-control custom-checkbox">
                <input type="checkbox" id="isFilterTax" name="isFilterTax" class="custom-control-input"
                  [(ngModel)]="currentSolutionCodeSearchFilter.isTax">
                <label class="custom-control-label" for="isFilterTax">Tax</label>
              </span>

              <span class="custom-control custom-checkbox">
                <input type="checkbox" id="isFilterNone" name="isFilterNone" class="custom-control-input"
                  [(ngModel)]="currentSolutionCodeSearchFilter.isNone">
                <label class="custom-control-label" for="isFilterNone">None</label>
              </span>
            </div>
            <div class="col-lg-2 col-md-3 filter-partition pl-5">
              <label class="font-weight-bold mb-1">Status</label>
              <span class="custom-control custom-checkbox " *ngFor="let status of solutionCodeStatusList, let i=index">
                <input type="checkbox" name="chkSolutionCodeStatus_{{i}}" id="chkSolutionCodeStatus_{{i}}"
                  class="custom-control-input" [(ngModel)]="status.isChecked" (change)="changeSolutionCodeStatus()">
                <label class="custom-control-label" for="chkSolutionCodeStatus_{{i}}"> {{status.typeValue}}</label>
              </span>
            </div>
            <div class="col-lg-4 col-md-6 pl-5">
              <label class="font-weight-bold mb-1">Solution code</label>
              <div class=" ">
                <app-auto-complete-text-box id="SolutionCodeFilter" name="SolutionCodeFilter"
                  source="SolutionCodeConfigurationFilter" [MappedSource]="SolutionCodeFilterMappedValue"
                  (change)="OnSolutionCodeChange()" [Sequence]="0" [NoRecordFoundFor]="false" class="autocomplete-alt">
                </app-auto-complete-text-box>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 align-self-end text-right pr-4">
          <button type="reset" (click)="ShowHideListFilterPanel('HideWithCancel')" class="SecondaryButton mr-3"
            style="min-width: 110px; text-align: center;">
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" (click)="getAllSolutionCodeListCount()" class="PrimaryButton mr-3"
            style="min-width: 110px; text-align: center;">
            <span class="d-inline-block align-middle">Apply</span>
          </button>
          <button type="button" (click)="ShowHideListFilterPanel('Hide')" class="toggle-btn mr-1">
            <img alt="OK" src="../../../../assets/images/icons/expand_more-24px.svg" alt="expand" />
          </button>
        </div>
      </div>
    </section>
    <!-- Accordian view For SolutionCode ListView end -->
    <div class="row m-0">
      <div class="col-md-12 col-lg-8 px-0">
        <div class="row1 p-2">
          <div class="MainContainer m-0">
            <div class="">
              <table class="listViewDataTable ResponsiveDataTable Config-table">
                <thead>
                  <tr>
                    <th scope="col" class="table-sorted-desc py-2 t-w-20">
                      <div class="row p-0 m-0 th-height">
                        <div class="col-md-10 p-0 m-0">
                          {{headerModelList[0].headerName}}
                        </div>
                        <div class="col-md-2 p-0 m-0 text-right">
                          <div *ngFor="let img of headerModelList[0].imageModelList">
                            <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                              (click)="HeaderSort(headerModelList[0].headerId)"
                              [ngClass]="{isDisabled:solutionCodeCnt == 0}">
                              <img src="{{img.imagePath}}" alt="Header0sort">
                            </a>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col" class="table-sorted-desc py-2 t-w-37">
                      <div class="row p-0 m-0 th-height">
                        <div class="col-md-10 p-0 m-0">
                          {{headerModelList[1].headerName}}
                        </div>
                        <div class="col-md-2 p-0 m-0 text-right">
                          <div *ngFor="let img of headerModelList[1].imageModelList">
                            <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                              (click)="HeaderSort(headerModelList[1].headerId)"
                              [ngClass]="{isDisabled:solutionCodeCnt == 0}">
                              <img src="{{img.imagePath}}" alt="Header1sort">
                            </a>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col" class="table-sorted-desc py-2 w-sm-25">
                      <div class="row p-0 m-0 th-height">
                        <div class="col-md-10 p-0 m-0">
                          {{headerModelList[2].headerName}}
                        </div>
                        <div class="col-md-2 p-0 m-0 text-right">
                          <div *ngFor="let img of headerModelList[2].imageModelList">
                            <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                              (click)="HeaderSort(headerModelList[2].headerId)"
                              [ngClass]="{isDisabled:solutionCodeCnt == 0}">
                              <img src="{{img.imagePath}}" alt="Header2sort">
                            </a>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col" class="table-sorted-desc t-md-col py-2">
                      <div class="row p-0 m-0 th-height">
                        <div class="col-md-10 p-0 m-0">
                          {{headerModelList[3].headerName}}
                        </div>
                        <div class="col-md-2 p-0 m-0 text-right">
                          <div *ngFor="let img of headerModelList[3].imageModelList">
                            <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                              (click)="HeaderSort(headerModelList[3].headerId)"
                              [ngClass]="{isDisabled:solutionCodeCnt == 0}">
                              <img src="{{img.imagePath}}" alt="Header2sort">
                            </a>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody class="display" *ngIf="solutionCodeCnt > 0">
                  <tr tabindex="{{i}}" class="searchDatajs" *ngFor="let list of solutionCodelistView let i = index;"
                    (click)="solutionCodeRowClick(list.solutionCodeId, i)" style="cursor: pointer;"
                    id="solutionCode_{{i}}" class="solutionCodeRow">
                    <td data-label="Solution code:" class="t-w-20">
                      <span class="no-link" *ngIf="!list.isLoggedInUserPartOfWorkSpace">{{list.solutionCode}}</span>
                    </td>
                    <td data-label="Description:" class="t-w-37"> {{list.solutionCodeDescription}} </td>
                    <td data-label="Service line:" class="w-sm-25">{{list.serviceLinesText}} </td>
                    <td data-label="Status:" class="text-center t-md-col "> {{list.status }} </td>
                  </tr>
                </tbody>

                <div *ngIf="solutionCodeCnt == 0 || solutionCodeCnt == null">
                  <div class="no-data-available no-data">{{listDataMessage}}</div>
                </div>

              </table>
            </div>
            <!-- ListView Pagination Start -->
            <div class="d-flex justify-content-center text-center pagination-section" *ngIf="solutionCodeCnt > 0">
              <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
                <li>
                  <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="movePage(1)">
                    <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first">
                  </a>
                </li>
                <li>
                  <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
                    (click)="movePage(pager.currentPage - 1)">
                    <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous">
                  </a>

                </li>
                <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                  <a (click)="movePage(page)">{{page}}</a>
                </li>

                <li>
                  <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                    (click)="movePage(pager.currentPage + 1)">
                    <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next">
                  </a>
                </li>
                <li>
                  <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                    (click)="movePage(pager.totalPages)">
                    <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last">
                  </a>
                </li>

                <li>
                  <select id="drprecordsPerPage" name="drprecordsPerPage" (change)="movePage(1)"
                    class="page-counts pagenumber-dropdown">
                    <option *ngFor="let rcdcount of noOfrecordsPerPageList" value="{{rcdcount}}">{{rcdcount}}</option>
                  </select>
                </li>
              </ul>
            </div>
            <!-- ListView Pagination End -->
          </div>
        </div>
      </div>

      <div
      [ngClass]="{   'col-md-12 col-lg-4 m-0 pl-3 p-0 solutionCodeInactive': isInactiveSolutionCode,   'col-md-12 col-lg-4 m-0 pl-3 p-0': !isInactiveSolutionCode }"
        id="solutionCodeEditDiv">
        <div class="p-2 right-sidepanel">
          <div id="solutionCdoeBasicDiv">
            <div class="font-weight-bold mb-2">Edit solution code</div>
            <div class="edp-font-12"><span>Solution code: </span>
              <span>{{currentsolutionCodeMapping.solutionCode}}</span>
            </div>
            <div class="edp-font-12"><span>Description:
              </span><span>{{currentsolutionCodeMapping.solutionCodeDescription}}</span></div>
            <div class="edp-font-12"><span>Status: </span><span
                class="font-weight-bold">{{currentsolutionCodeMapping.status}}</span></div>

            <div class="row m-0 my-3">
              <div class="col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="re_acceptance_eligibility" name="re_acceptance_eligibility"
                  value="{{currentsolutionCodeMapping.re_acceptance_eligibility}}"
                  [(ngModel)]="currentsolutionCodeMapping.re_acceptance_eligibility">
                <label for="re_acceptance_eligibility" class="edp-font-12 mb-0">Continuance eligible</label>
              </div>
              <div *ngIf="currentsolutionCodeMapping.re_acceptance_eligibility"
                class="ml-4 col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="preRequestDuringContinuance" name="preRequestDuringContinuance"
                  value="{{currentsolutionCodeMapping.preRequestDuringContinuance}}"
                  [(ngModel)]="currentsolutionCodeMapping.preRequestDuringContinuance">
                <label for="preRequestDuringContinuance" class="edp-font-12 mb-0">Pre-request during Continuance</label>
              </div>
              <div *ngIf="currentsolutionCodeMapping.re_acceptance_eligibility"
                class="ml-4 col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="carryForwadIndusConsent" name="carryForwadIndusConsent"
                  value="{{currentsolutionCodeMapping.carryForwadIndusConsent}}"
                  [(ngModel)]="currentsolutionCodeMapping.carryForwadIndusConsent">
                <label for="carryForwadIndusConsent" class="edp-font-12 mb-0">Carry forward INDUS consent</label>
              </div>
              <div class="col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="isPeriodRequire" name="isPeriodRequire"
                  value="{{currentsolutionCodeMapping.isPeriodRequire}}"
                  [(ngModel)]="currentsolutionCodeMapping.isPeriodRequire">
                <label for="isPeriodRequire" class="edp-font-12 mb-0">Period end date required</label>
              </div>
              <div class="col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="isGrossMarginRequire" name="isGrossMarginRequire"
                  value="{{currentsolutionCodeMapping.isGrossMarginRequire}}"
                  [(ngModel)]="currentsolutionCodeMapping.isGrossMarginRequire">
                <label for="isGrossMarginRequire" class="edp-font-12 mb-0">Gross margin required</label>
              </div>

              <div class="col-md-12 r-n-res-checkbox px-0 " hidden>
                <input type="checkbox" id="isEngagementStartDateRequired" name="isEngagementStartDateRequired"
                  value="{{currentsolutionCodeMapping.isEngagementStartDateRequired}}"
                  [(ngModel)]="currentsolutionCodeMapping.isEngagementStartDateRequired">
                <label for="isEngagementStartDateRequired" class="edp-font-12 mb-0">Assignment start date
                  required</label>
              </div>
              <div class="col-md-12 r-n-res-checkbox px-0 " hidden>
                <input type="checkbox" id="isEngagementEndDateRequired" name="isEngagementEndDateRequired"
                  value="{{currentsolutionCodeMapping.isEngagementEndDateRequired}}"
                  [(ngModel)]="currentsolutionCodeMapping.isEngagementEndDateRequired">
                <label for="isEngagementEndDateRequired" class="edp-font-12 mb-0">Assignment end date required</label>
              </div>
              <div class="col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="updateQuestionnaireAfterAcceptance" name="updateQuestionnaireAfterAcceptance"
                  value="{{currentsolutionCodeMapping.updateQuestionnaireAfterAcceptance}}"
                  [(ngModel)]="currentsolutionCodeMapping.updateQuestionnaireAfterAcceptance">
                <label for="updateQuestionnaireAfterAcceptance" class="edp-font-12 mb-0">Update questionnaire after
                  final approval</label>
              </div>
              <div class="col-md-12 r-n-res-checkbox px-0 ">
                <input type="checkbox" id="allowMultipleTemplates" name="allowMultipleTemplates"
                  value="{{currentsolutionCodeMapping.allowMultipleTemplates}}"
                  [(ngModel)]="currentsolutionCodeMapping.allowMultipleTemplates">
                <label for="allowMultipleTemplates" class="edp-font-12 mb-0">Allow multiple templates</label>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-md-12 px-0 edp-font-12 mb-1">
                <span>Code request triggers</span>
              </div>
              <div class="col-md-12 px-0 edp-font-12 my-1">
                <div class="mb-2">
                  <label class="custom-radio">
                    <span class="mb-0 pos-top ">Upon approval</span>
                    <input type="radio" name="requestTriggersOptions" id="uponApproval"
                      [value]="requestTriggerOptions[0].optionValue" ng-control="requestTriggersOptions"
                      [(ngModel)]="TriggerOption" (change)="onRequestTriggerChange('uponApproval')">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="mb-2">
                  <label class="custom-radio">
                    <span class="mb-0 pos-top ">Prior to approval</span>
                    <input type="radio" name="requestTriggersOptions" disabled="disabled">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="mb-2">
                  <label class="custom-radio">
                    <span class="mb-0 pos-top ">On signature</span>
                    <input type="radio" name="requestTriggersOptions" id="onSignature"
                      [value]="requestTriggerOptions[1].optionValue" ng-control="requestTriggersOptions"
                      [(ngModel)]="TriggerOption" (change)="onRequestTriggerChange('onSignature')">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div id="serviceLinesDiv" class="row m-0">
            <div class="col-md-12 px-0 edp-font-12 mb-1"><span>Service line </span><span class="error-color">*</span>
            </div>
            <div class="col-md-12 px-0 edp-font-12">
              <span class="r-n-res-checkbox">
                <input type="checkbox" id="isAdvisory" name="isAdvisory"
                  value="{{currentsolutionCodeMapping.isAdvisory}}" [(ngModel)]="currentsolutionCodeMapping.isAdvisory">
                <label for="isAdvisory">Advisory</label>
              </span>

              <span class="r-n-res-checkbox mx-3">
                <input type="checkbox" id="isAudit" name="isAudit" value="{{currentsolutionCodeMapping.isAudit}}"
                  [(ngModel)]="currentsolutionCodeMapping.isAudit">
                <label for="isAudit">Audit</label>
              </span>

              <span class="r-n-res-checkbox">
                <input type="checkbox" id="isTax" name="isTax" value="{{currentsolutionCodeMapping.isTax}}"
                  [(ngModel)]="currentsolutionCodeMapping.isTax">
                <label for="isTax">Tax</label>
              </span>

            </div>
            <div *ngIf="currentsolutionCodeMapping.isAdvisory" class="col-md-12 px-0 edp-font-12 my-1">
              <label class="custom-radio">
                Attest
                <input type="radio" name="attestNonAttestCheck" (click)="onAttestSelected('Attest')" id="Attest"
                  [checked]="currentsolutionCodeMapping.isAttest === 1">
                <span class="checkmark Attest"></span>
              </label>&nbsp;&nbsp;

              <label class="custom-radio">
                Non-Attest
                <input type="radio" name="attestNonAttestCheck" (click)="onAttestSelected('NonAttest')" id="nonAttest"
                  [checked]="currentsolutionCodeMapping.isAttest === 0">
                <span class="checkmark nonAttest"></span>
              </label>
            </div>
            <div *ngIf="currentsolutionCodeMapping.isTax" class="col-md-12 px-0 edp-font-12 my-1">
              <label class="">
                Closure prompt &nbsp;&nbsp;
                <input type="text" style="width: 35px;" (keypress)="numberOnly($event)" maxlength="3" min="0" max="999"
                  name="Closurepromttargetdays" [(ngModel)]="currentsolutionCodeMapping.closurepromttargetdays"
                  id="Closurepromttargetdays"> &nbsp;&nbsp;days

              </label>&nbsp;&nbsp;<br>

              <label class="">
                Reminder email frequency &nbsp;&nbsp;
                <input type="text" style="width: 35px;" (keypress)="numberOnly($event)" maxlength="3" min="0" max="999"
                  name="Frequencyofprompt" [(ngModel)]="currentsolutionCodeMapping.frequencyofprompt"
                  id="Frequencyofprompt"> &nbsp;&nbsp;days
              </label>
            </div>


            <div class="row m-0 form-control-feedback error-color text-left edp-font-12" *ngIf=" (currentsolutionCodeMapping.solutionCode != '') && !(currentsolutionCodeMapping.isAudit ||
                  currentsolutionCodeMapping.isTax ||
                  currentsolutionCodeMapping.isAdvisory) && !isInactiveSolutionCode">
              <span style="width: 100%;">Please select service line</span>
            </div>
          </div>

          <div id="PSDdiv" *ngIf="currentsolutionCodeMapping.isAdvisory && currentsolutionCodeMapping.isAttest === 0">
           
            <div class="col-md-12 px-0 edp-font-12"><span>Select PSD(s)</span></div>
            <div class="row m-0 px-0 edp-font-12">
              <div class="col-md-10 px-0 select-slim mt-1">
                <div class="input-group mw-100">
                  <app-people-picker class="eng-people-picker" [roleShortName]="PSDRoleName"
                    [labelCtrlName]="PSDlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="PSDpeoplepickerIndex"
                    [IsPickerForAssignment]=true [PeoplePickerModel]="PSDDisplayUserName"
                    [userEmail]="PSDDisplayUserEmail" [IsValidateUserWithADGroup]="validatePSD">
                  </app-people-picker>

                  <div class="input-group-append input-height">
                    <button type="button" id="PPIcon_{{PSDpeoplepickerIndex}}" data-toggle="modal"
                      data-target="#modal-PSD" data-keyboard="false" data-backdrop="static"
                      class="mw-100 people-picker-button text-center" (click)="refreshPSDPopup(i,'txtPeoplePicker_')">
                      <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Team Member" />
                    </button>
                  </div>                 
                </div>
                <span id="PSDErrorMessage" class="form-control-feedback error-color"></span>
                <div class="form-control-feedback error-color" *ngIf="!isvalidPSD">
                  <p>PSD should be valid</p>
                </div>
              </div>
              <div class="col-md-2 text-right edp-font-12 my-2 pt-1 px-0">
                <a class="link-text-blue cursor-pointer" (click)="validatePSDMappingData()">Add</a>
              </div>
              <div id="SelectedPSDListDiv" class="row m-0">
                <div class="col-md-12 px-0 edp-font-12 my-2">Selected PSD(s)</div>
                <div class="Sec-code w-100">
                  <div id="gridPsdDiv" class="px-2   bg-white w-100" *ngFor="let item of PSDMapingList let i = index;">
                    <div id="row_{{i}}" class="row m-0">
                      <div class="col-md-10 px-0 edp-font-12">

                        <span>{{item.psdName}}</span>
                      </div>
                      <div class="col-md-2 px-0 edp-font-12 text-right">
                        <a id="removePSDMappingLink_{{i}}" style="cursor: pointer;" (click)="removePSDMapping(i)">
                          <img class="remove-ass-button"
                            src="../../../../assets/images/EDP/Engagement/remove_circle_outline-24px.svg" alt="Remove">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="col-md-12 px-0 edp-font-12">
              <label class="">
                No time charged exception report (number of days):&nbsp;&nbsp;
                <input type="text" style="width: 35px;" (keypress)="numberOnly($event)" maxlength="3" min="0" max="999"
                  name="noTimeChargedDays" [(ngModel)]="currentsolutionCodeMapping.noTimeChargedDays"
                  id="noTimeChargedDays"> &nbsp;&nbsp;days
              </label>
            </div>
          </div>
          <hr>
          <div id="templateDiv" class="row mx-0 my-2">
            <div class="col-md-12 px-0 edp-font-12"><span>Select template</span></div>
            <div class="col-md-10 px-0 select-slim mt-1">
              <div class="selectdiv">
                <select class="dropdown rounded-0  font-12" id="ddTemplate">
                  <option value="0" disabled hidden>Select template</option>
                  <option *ngFor="let temp of templateList" [value]=temp.templatePackageId>
                    {{temp.name}}
                  </option>
                </select>
              </div>
              <div class="error-color templateMandatoryCheck edp-font-12"
              *ngIf="!isValidTemplateSelected || isTemplateAlreadyAdded">
              <p *ngIf="!isValidTemplateSelected">Template is a required field</p>
              <p *ngIf="isTemplateAlreadyAdded">Template is already added</p>
              </div>
            </div>          

            <div class="col-md-2 text-right edp-font-12 my-2 pt-1 px-0">
              <a class="link-text-blue cursor-pointer" (click)="validateTemplateMappingData()">Add</a>
            </div>
            <div id="secondaryCodeListDiv" class="row m-0">
              <div class="col-md-12 px-0 edp-font-12 my-2">Selected templates</div>
              <div class="Sec-code w-100">
                <div id="gridDiv" class="px-2   bg-white w-100" *ngFor="let item of templateMappingList let i = index;">
                  <div id="row_{{i}}" class="row m-0">
                    <div class="col-md-10 px-0 edp-font-12">
                      <span>{{item.templateName}}</span>
                    </div>
                    <div class="col-md-2 px-0 edp-font-12 text-right">
                      <a id="removeTemplateMappingLink_{{i}}" style="cursor: pointer;"
                        (click)="removeTemplateMapping(i)">
                        <img class="remove-ass-button"
                          src="../../../../assets/images/EDP/Engagement/remove_circle_outline-24px.svg" alt="Remove">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div id="SecondaryCodesDiv" class="row m-0">
            <div class="col-md-12 px-0 edp-font-12 mb-1"><span>Secondary codes <span class="error-color">*</span></span>
            </div>
            <div class="col-md-12 px-0 edp-font-12 my-1">
              <label class="custom-radio">
                <span class="mb-0 pos-top ">{{solutionCodeTypeList[1].typeValue}}</span>
                <input type="radio" name="secondaryCodeTypeOptions" ng-control="secondaryCodeTypeOptions"
                  [value]="solutionCodeTypeList[1].id" [(ngModel)]="solutionCodeTypeModel.id"
                  (change)="onItemChange(solutionCodeTypeList[1].id)">
                <span class="checkmark"></span>
              </label>
            </div>

            <div id="allServiceLivesDiv" class="row m-0">
              <div class="col-md-12 px-0 edp-font-12  mb-1">
                <label class="custom-radio">
                  <span class="mb-0 pos-top ">{{solutionCodeTypeList[0].typeValue}}</span>
                  <input type="radio" name="secondaryCodeTypeOptions" ng-control="secondaryCodeTypeOptions"
                    [value]="solutionCodeTypeList[0].id" [(ngModel)]="solutionCodeTypeModel.id"
                    (change)="onItemChange(solutionCodeTypeList[0].id)">
                  <span class="checkmark"></span>
                </label>
              </div>

              <div class="ml-2 p-0">
                <div class="col-md-12 r-n-res-checkbox ">
                  <input type="checkbox" id="isAllAdvisory" name="isAllAdvisory"
                    value="{{currentsolutionCodeMapping.isAllAdvisory}}"
                    [(ngModel)]="currentsolutionCodeMapping.isAllAdvisory" (change)="setSecondaryCodesType()">
                  <label class=" edp-font-12 mb-0" for="isAllAdvisory">All advisory</label>
                </div>
                <div class="col-md-12 r-n-res-checkbox ">
                  <input type="checkbox" id="isAllAudit" name="isAllAudit"
                    value="{{currentsolutionCodeMapping.isAllAudit}}" (change)="setSecondaryCodesType()"
                    [(ngModel)]="currentsolutionCodeMapping.isAllAudit">
                  <label class="edp-font-12 mb-0" for="isAllAudit">All audit</label>
                </div>
                <div class="col-md-12 r-n-res-checkbox ">
                  <input type="checkbox" id="isAllTax" name="isAllTax" value="{{currentsolutionCodeMapping.isAllTax}}"
                    (change)="setSecondaryCodesType()" [(ngModel)]="currentsolutionCodeMapping.isAllTax">
                  <label class="edp-font-12 mb-0" for="isAllTax">All tax</label>
                </div>
              </div>
            </div>

            <div id="SpecificSecondaryCodesDiv" class="row m-0 my-2">
              <div class="col-md-12 px-0 edp-font-12 mb-2">
                <label class="custom-radio">
                  <span class="mb-0 pos-top ">{{solutionCodeTypeList[2].typeValue}}</span>
                  <input type="radio" name="secondaryCodeTypeOptions" ng-control="secondaryCodeTypeOptions"
                    [value]="solutionCodeTypeList[2].id" [(ngModel)]="solutionCodeTypeModel.id"
                    (change)="onItemChange(solutionCodeTypeList[2].id)">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div id="innerSpecificDiv" class="row m-0 innerSpecificDiv"
                *ngIf="currentsolutionCodeMapping.solutionCodeTypeId === solutionCodeTypeList[2].id ?true:false">
                <div class="col-md-12 px-0  edp-font-12"><span>Add specific secondary codes</span></div>
                <div class="col-md-12 px-0 mt-1">
                  <app-auto-complete-text-box id="solutionCode" name="solutionCode" source="SolutionCodeConfiguration"
                    [MappedSource]="SolutionCodeMappedValue" [Sequence]="0" [NoRecordFoundFor]="false">
                  </app-auto-complete-text-box>
                </div>
                <div class="error-color solutionCodeMandatoryCheck edp-font-12"
                  *ngIf="!selectsolutionCodeModel.isvalid || isSolutionCodeAlreadyAdded">
                  <p *ngIf="!selectsolutionCodeModel.isvalid">Solution code is a required field</p>
                  <p *ngIf="isSolutionCodeAlreadyAdded">Solution code is already added</p>
                </div>

                <div class="col-md-12 text-right edp-font-12 my-2 px-0">
                  <a class="link-text-blue" style="cursor: pointer;" (click)="AddSecondarySolutionMapping()">Add
                  </a>
                </div>
                <div id="secondaryCodeListDiv" class="row m-0">
                  <div class="col-md-12 px-0 edp-font-12 my-2">Secondary code list</div>
                  <div class="Sec-code w-100">
                    <div id="gridDiv" class="px-2   bg-white w-100"
                      *ngFor="let item of secSolutionCodeList let i = index;">
                      <div id="row_{{i}}" class="row m-0">
                        <div class="col-md-2 px-0 edp-font-12">
                          <span>{{item.solutionCode}}</span>
                        </div>
                        <div class="col-md-8 px-0 edp-font-12 ">
                          <span class="text-short-solution"
                            title="{{item.solutionCodeDescription}}">{{item.solutionCodeDescription}}</span>
                        </div>
                        <div class="col-md-2 px-0 edp-font-12 text-right">
                          <a id="removeMappingLink_{{i}}" style="cursor: pointer;" (click)="removeMapping(i)">
                            <img class="remove-ass-button"
                              src="../../../../assets/images/EDP/Engagement/remove_circle_outline-24px.svg"
                              alt="Remove">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row m-0 my-3 d-none">
            <div class="col-md-6  px-0 pr-1">
              <button type="button" id="btnAddReviewNoteCancel" class="btn customBtnWhite btn-reply-note-h-34 w-100"
                data-toggle="modal" (click)="solutionCodeRowClick(currentsolutionCodeMapping.solutionCodeId)">
                <span class="d-inline-block  align-middle text-blue-button">Reset</span>
              </button>

            </div>
            <div class="col-md-6 px-0 pl-1"><button type="button" id="btnSaveSolutionCodeMapping"
                class="btn PrimaryButton  w-100 edp-font-12 btn-reply-note-h-34" data-toggle="modal"
                (click)="updateSolutionCodeMapping()">
                <span class="d-inline-block mx-2  align-middle">Save</span>
              </button>
            </div>
          </div>
          <div class="error-color templateMandatoryCheck edp-font-12" *ngIf="!isTemplateMappingExist">
            <p>Template mapping is mandatory.</p>
          </div>
          <div class="error-color templateMandatoryCheck edp-font-12" *ngIf="!isSpecificTypeValid">
            <p>Secondary solution code is mandatory.</p>
          </div>
          <!-- <div class="error-color templateMandatoryCheck edp-font-12" *ngIf="!isPSDMappingExist">
            <p>PSD member is mandatory.</p>
          </div> -->

          <div class="row m-0 form-control-feedback error-color text-right" *ngIf="showMessage">
            <p style="width: 100%;">{{showMessageText}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="modal-PSD" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Select PSD Member </span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectPSD();">
            <span class="close-text mr-1">Close </span>
            <span class="modal-close-btn">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span id="PSDErrorMessage" class="ErrorFont"></span>

          <div class="input-group nav-searchbox-length pb-2">
            <input type="text" id="searchAsPSDName" (keydown.enter)="searchUsers('searchAsPSDName','tblPSDAD');"
              class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for PSD member" autocomplete="off">
            <span class="input-group-append nav-seachBox">
              <div class="input-group-text" (click)="searchUsers('searchAsPSDName','tblPSDAD');">
                <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
              </div>
            </span>
          </div>
          <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>

          <div class="tableScroll mt-2">
            <table id="tblPSDAD" aria-describedby="Team Members" class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email </th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Line of Business</th>
                  <th scope="col">Service Line</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr (click)='selectPSD(i,$event)' id="trPSDs" name="trPSDs" style="cursor: pointer;"
                  *ngFor="let member of membersListArray let i=index">
                  <td> <span id='spPSDdisplayName{{i}}'>{{member.displayName}}</span>
                  </td>
                  <td> <span id='spPSDmail{{i}}'>{{member.mail}}</span>
                  </td>
                  <td>
                    <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                  </td>
                  <td> <span id='spPSDbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                  </td>
                  <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                  </td>
                  <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                  </td>
                  <td> <span id='splocation{{i}}'>{{member.location}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
            (click)="deselectPSD();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
            (click)="setPSD();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
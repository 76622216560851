import { Component, OnInit , Input, OnChanges, SimpleChanges, Output} from '@angular/core';
import { DocumentService } from 'src/app/services/DocumentService';
import { DocumentList } from 'src/app/model/DocumentListModel';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { WorkspaceModel } from 'src/app/model/WorkspaceModel';
import { Router } from '@angular/router';
declare var $;
@Component({
  selector: 'app-activereviews',
  templateUrl: './activereviews.component.html',
  styleUrls: ['./activereviews.component.css'],
  providers: [DocumentService]

})
export class ActivereviewsComponent implements OnInit {
  
  myReviewsDocumentList: DocumentList[] = [];
  @Input() clientId: number;
  PreviousWorkspaceId: string;
  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  constructor(private documentService: DocumentService, private workspaceService: WorkspaceService
    , private loadingScreenService: LoadingScreenService
    , private route :Router) { }


  ngOnInit() {
  }
  ngAfterContentChecked() {
    // ...
    var currentWorkspaceId = $('#HiddenWorkspaceIdonDocListPopup').val();
    if (currentWorkspaceId != null && currentWorkspaceId != "") {
      this.loadingScreenService.startLoading();
      // this.PreviousWorkspaceId = currentWorkspaceId;
      this.getWorkspaceDatails(currentWorkspaceId);
      // if(this.PreviousWorkspaceId != currentWorkspaceId){
      //   this.getWorkspaceDatails(currentWorkspaceId);
      // }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      let curVal = change.currentValue;
      let prevVal = change.previousValue;
      if(curVal != prevVal) {
        console.log('engagement list popup ngOnChanges ', curVal, prevVal);
        this.getMyReviewsDocumentsByClient(parseInt(curVal));
      }
    }
  }

  getMyReviewsDocumentsByClient(clientId: number) {
    
    return this.documentService.getMyReviewsDocumentsByClient(clientId)
      .subscribe(
        result => {
          console.log('getMyReviewsDocumentsByClient ', result);
          setTimeout(() => {
            this.myReviewsDocumentList = result;
          }, 100);
          console.log('getMyReviewsDocumentsByClient func called ' + result, this.myReviewsDocumentList);
        },
        error => {
          this.myReviewsDocumentList = null;
        }
      );
      
  }

  
  getWorkspaceDatails(id: string) {
    if (id != null) {
      $('#HiddenWorkspaceIdonDocListPopup').val("");
      $('#ActiveDocumentsModal').flyout('hide')
      $(".showReviewDetailsjs").each(function (i, obj) {
        setTimeout(() => {
          $(this).flyout('hide');
        }, 1000);
      });

      this.workspaceService.getWorkspaceById(id).subscribe(
        result => {
          this.loadingScreenService.stopLoading();
          result.setAnsFlag = "update";
          this.CurrentWorkspaceData = result;
          console.log("Workitem id check on document flyout:", this.CurrentWorkspaceData.hdWorkItemId);
          if (this.CurrentWorkspaceData.hdWorkItemId != null && this.CurrentWorkspaceData.workspaceId != "00000000-0000-0000-0000-000000000000") {
            this.DisplayInterviewsModal(this.CurrentWorkspaceData);

          }
        });
    }
  }

  DisplayInterviewsModal(result) {
    this.route.navigate(['Interviews/' + result.workspaceId]);
  }
}

<div class="delegate-dialog-wrapper" *ngIf="isHidden">
  <div class="delegate-dialog-container delegate-dialog-container">
    <div class="close-dialog">
      <div class="d-inline-block ml-1 align-top">
        <button class="btn btn-link btn-sm py-0" (click)="closeDialog($event)">
          Close
        </button>
      </div>
      <span class="d-inline-block ml-1 align-top">
        <img src="../../../../assets/images/EDP/MessageDialog/close-24px.svg" alt="Close" (click)="closeDialog($event)">
      </span>
    </div>
    <div class="row m-0 p-0 pb-2">
      <div class="col-md-12 col-xl-12 pl-0 pr-0" *ngIf="(isPeoplePickerDisabled && isAdvisoryRetentionFeatureFlagEnabled)||(!isPeoplePickerDisabled && isAdvisoryRetentionFeatureFlagEnabled)">
        <h5 style="color:#433434;"><strong> Approval delegation</strong></h5>
      </div>
      <div class="col-md-12 col-xl-12 pl-0 pr-0" *ngIf="(!isPeoplePickerDisabled && !isAdvisoryRetentionFeatureFlagEnabled)||(isPeoplePickerDisabled && !isAdvisoryRetentionFeatureFlagEnabled )">
       <h5 style="color:#707070;"><strong>RP approval delegation</strong></h5>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-md-12 col-xl-12 pl-0 pr-0" *ngIf="(isPeoplePickerDisabled && isAdvisoryRetentionFeatureFlagEnabled )||(!isPeoplePickerDisabled && isAdvisoryRetentionFeatureFlagEnabled)">
        Select the period of time for which you are delegating your approval responsibilities to another individual. 
        Records that become eligible for approval during the defined period may be approved by either you or your delegate. 
      </div>
      <div class="col-md-12 col-xl-12 pl-0 pr-0" *ngIf="(!isPeoplePickerDisabled && !isAdvisoryRetentionFeatureFlagEnabled )||(isPeoplePickerDisabled && !isAdvisoryRetentionFeatureFlagEnabled )">
       Select the period of time for which you are delegating your RP approval responsibilities to another individual.
        Records that become eligible for RP approval during the defined period may be approved by either you or your delegate.
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-6 col-md-6 pt-3 pl-0 pr-2">
        <input type="hidden" id="DelegationId" value="" />
        <label for="EstimatedGM">Beginning date <span class="mandatory">*</span></label>
        <app-calendercontrol id="beginningDate" [txtDate]="BeginningDateCtrlId" class="eng-period-date">
        </app-calendercontrol>
        <div class="form-control-feedback error-color" *ngIf="CurrentCompareResult > 0">
          <p>Beginning date cannot be before the current date</p>
        </div>
        <div id="errormessage" class="form-control-feedback error-color" *ngIf="DateCompareResult > 0">
          <p>Beginning date cannot be after the ending date</p>
        </div>
        <div id="errormessage" class="form-control-feedback error-color" *ngIf="isBeginningDateRequired">
          <p>Beginning date is required</p>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 pt-3 pl-0 pr-0">
        <label for="EstimatedGM">Ending date <span class="mandatory">*</span></label>
        <app-calendercontrol id="endingDate" [txtDate]="EndingDateCtrlId" class="eng-period-date">
        </app-calendercontrol>
        <div class="form-control-feedback error-color" *ngIf="isEndingDateAdded">
          <p>Enter ending date</p>
        </div>
        <div id="errormessage" class="form-control-feedback error-color" *ngIf="isEndingDateRequired">
          <p>Ending date is required</p>
        </div>
      </div>
      <div class="col-xl-12 col-md-12 pt-3 pl-0 pr-0" *ngIf="!isPeoplePickerDisabled" >
        <label><span class="label">Select delegate </span> <span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <app-people-picker class="eng-assign-people-picker" [roleShortName]="assignDelegateRoleName"
            [labelCtrlName]="assignDelegateLabelCtrlName" [isLabelVisible]="false"
            [InputCtrlIdIndex]="peoplePickerIndex" [PeoplePickerModel]="delegateDisplayUserName"
            [userEmail]="delegateDisplayUserEmail" [IsValidateUserWithADGroup]="validateDelegate"
            [disabled]="isButtonDisabled">
          </app-people-picker>

          <div class="input-group-append input-height">
            <button type="button" id="PPIcon_{{peoplePickerIndex}}" data-toggle="modal" data-target="#modal-Delegate"
              class="mw-100 people-picker-button text-center" (click)="refreshDelegatePopup('txtPeoplePicker_')">
              <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Select delegate" />
            </button>
          </div>
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="isDelegateSelected">
          <p>Select delegate</p>
        </div>
        <div class="form-control-feedback error-color" *ngIf="isDelegateValid">
          <p>Delegate should be valid</p>
        </div>
        <div class="form-control-feedback error-color" *ngIf="isDelegateSameAsLoggedInUser">
          <p>Invalid Delegate Person</p>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0 pt-3 pb-3" *ngIf="delegateStatusMessage.length > 0">
      <div class="col-lg-12 col-md-12 questAlert">
        <span class="msg-icon">
          <img src="assets/images/EDP/Interviews/report_problem-24px.svg" alt="save successfully">
        </span>
        <span class="questAlertText px-2">{{delegateStatusMessage}}</span>
      </div>
    </div>
    <div class="row" *ngIf="delegateStatusMessage.length == 0">
      <div class="col-xl-12">&nbsp;</div>
    </div>
    <div class="d-flex justify-content-end px-0">
      <div>
        <button (click)="Cancel()" class="btn SecondaryButton rounded-0 information mr-4">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg" alt="Clear" />
          </span>
          <span class="d-inline-block align-middle">Clear</span>
        </button>
        <button (click)="Ok()" class="btn rounded-0 information">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div id="modal-Delegate" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select delegate </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deSelectDelegate();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="DelegateErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchDelegate" (keydown.enter)="searchUsers('searchDelegate');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for delegate" autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchDelegate');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>

        <div class="tableScroll mt-2">
          <table id="tblDelegate" aria-describedby="Delegate" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectDelegate(i,$event)' id="trDelegate" name="trDelegate" style="cursor: pointer;"
                *ngFor="let member of membersListArrayForDelegate let i=index">
                <td>
                  <span id='delegateDisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td>
                  <span id='delegateEmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='delegateJobTitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td>
                  <span id='delegateBusinessPhone{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td>
                  <span id='delegateLineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td>
                  <span id='delegateServiceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td>
                  <span id='delegateLocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deSelectDelegate();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="setDelegate();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
export class ClientTeamMembers {
  id: number;
  engagementId: number;
  collaborationSiteId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  clientEmailId:string;
  title: string;
  permissionId: number;
  createdby: string;
  createdon: Date;
  updatedBy: string;
  updatedon: Date;  
  invitationUrl: string;
  status: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ILeftnavList } from '../model/ILeftnavList';
import { AppConfigService } from './appConfigService';

@Injectable({
  providedIn: 'root'
})
export class LeftNavService {
  APIEndPoint: string;

  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
  }

  getILeftnavList(rolesList: string, isActive: boolean): Observable<ILeftnavList[]> {
    return this.http.get<ILeftnavList[]>(this.APIEndPoint + '/Leftnav/List/' + rolesList + '/' + isActive);
  }
  getShortcutCount(rolesList: string, isActive: boolean): Observable<string> {
    return this.http.get<string>(this.APIEndPoint + '/Leftnav/GetShortcutCount/' + rolesList + '/' + isActive);
  }
}

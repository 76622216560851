export class SolutionCodeListModel {

    solutionCodeId: number

    solutionCode: string

    solutionCodeDescription: string

    solutionCodeLongName: string

    status: string

    lastModifiedBy: string
    
    lastModifiedDate: Date
}

export class HeaderModel {
    headerId: number;
    headerName: string;
    headerValue: string;
    headerClick: boolean = false;
    imageModelList: ImageModel[] = null;
  }
export class ImageModel {
    imageName: string;
    imageValue: string;
    imagePath: string;
    isVisible: boolean = false;
  }
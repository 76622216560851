<div id="modal-SharepointFilePicker" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <span class="modal-title">
        </span>
      </div> -->
      <div class="modal-body">
        <div class="d-flex align-items-stretch">
          <div class="p-3 first-col">
            <div class="font-weight-bold" style="font-size: 17px;">{{clientName}}</div>
            <div>{{engagementName}}</div>
            <br />
            <div> <img id="sharepoint-documents" src="../../../../assets/images/EDP/Dashboard/folder.svg"
                alt="sharepoint folder" class="folder-structure" />
              Engagement Documents</div>
          </div>
          <div class="p-3 second-col">
            <nav aria-label="breadcrumb" style="margin-top: -5px;font-size:16px;">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Engagement Documents</li>
                <li class="breadcrumb-item cursor-pointer" (click)="generalClicked()">General</li>
                <li class="breadcrumb-item cursor-pointer" *ngFor="let breadcrumSPItem of breadcrumSPItems"
                  (click)="breadClicked(breadcrumSPItem)">
                  {{breadcrumSPItem.name}}</li>
              </ol>
            </nav>
            <div class="line"></div>
            <div class="ml-2 mt-3 file-list-items">
              <div class="row p-1" style="color:rgb(55,55,55);font-weight:bold;">
                <div class="col-6">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(55,55,55)" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                   New 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(55,55,55)" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg> -->
                </div>
                <div class="col-3">Modified</div>
                <div class="col-3">Modified By</div>
              </div>
              <div class="line-second"></div>
              <div class="overflow-auto">
                <div class="row cursor-pointer p-1" 
                  *ngFor="let sharepointItem of selectedsharepointItems" (click)="selectedDocument(sharepointItem)">
                  <div class="col-6" style="position: relative;display: block;">
                    <input style="position: absolute;left:15px;top:3px" type="radio" name="group" [checked]="sharepointItem.isSelected" class="ml-2 mr-2"/>
                    <img style="position: absolute;left:40px;top:3px;height: auto;" *ngIf="sharepointItem.documentType!='Folder'" class="sharepoint-file-icon"
                      id="sharepoint-documents" src="../../../../assets/images/Client/save_grey.svg"
                      alt="sharepoint folder" />
                    <img style="position: absolute;left:40px;top:1px;" *ngIf="sharepointItem.documentType=='Folder'" id="sharepoint-documents"
                      src="../../../../assets/images/EDP/Dashboard/folder.svg" alt="sharepoint folder" class="folder" />
                    <span style="display:block;margin-left:50px;">{{ sharepointItem.name }}</span>
                  </div>
                  <div class="col-3"> {{ sharepointItem.lastModifiedDate }}</div>
                  <div class="col-3"> {{ sharepointItem.lastModifiedBy }}</div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-5 pr-3 pt-5 justify-content-end mb-1 w-100">
              <button type="button" class="SecondaryButtonBlue mr-4" data-dismiss="modal"
                style="width: 150px; text-align: center;" (click)="cancelClicked($event)">
                <span class="d-inline-block align-middle">Cancel</span>
              </button>
              <button type="button" class="PrimaryButtonBlue" style="width: 150px; text-align: center; "
                (click)="okClicked()" [disabled]="currentSelectedFile==undefined">
                <span class="d-inline-block align-middle">Link</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
      
      </div> -->
    </div>
  </div>
</div>
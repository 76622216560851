import { KeyValue } from 'src/app/model/KeyValue';
import { HdWorkspaceDocumentModel } from './WorkspaceDocumentModel';
import { WorkspaceModel } from './WorkspaceModel';
export class HDWorkItemModel {
    id: string;
    workGroupId: string;
    name: string;
    description: string;
    templatePackageId: string;
    answerXml: string;
    originalWorkItemId: string;
    templateCurrentVersion: number;
    workspaceCode: number;
    workItemVersionId: string;
    templatePackageVersionId: string;
    siteUniqueId: string;
    isDelete: boolean = false;
    appliedTnC: boolean = false;
}

export class WorkItemDetailsModel {
    workItemId: string;
    workGroupId: string;
    name: string;
    ownedBy: string;
    createdDate: Date;
    createdBy: string;
    templatePackageId: string;
    templateName: string;
    modifiedBy: string;
    modifiedDate: Date;
    isPrivateToOwner: boolean;
    ownedById: string;
    inProgressAssemblySessionId: string;
}

export class HDQuestionBankData{
    engageDataList: KeyValue[];
    hDVariableList: HotDocsQuestionBank[];
}

export class HDQuestionnaireModel {
    questionBankData: HDQuestionBankData;
    workspace: WorkspaceModel;
    appliedTnC: boolean = false;
    tnCWorkItemId: string;
}

export class HotDocsQuestionBank {
    HotDocsQuestionBankId: number;
    HDVariableName: string;
    EngageColumnName: string;
    Status: string;
    HDInputType: string;
    DefaultValue: string;
}

export class HDWorkItemDocumentModel {
    hdWorkItem: HDWorkItemModel;
    hdWorkspaceDocumentList: HdWorkspaceDocumentModel[];
}

export class DownloadWorkItem {
    workItemList: HDWorkItemModel[];
    independenceConsiderations: string;
    engagementId: number;
    engagementName: string;
    engagementPPMD: string;
    siteuniqueId:string;
    clientId:number;
    clientName: string;
    primarySolutionCode: string;
    isSaveToSharepoint: boolean;    
   
}

export class WorkspaceSupplementalDoc {
  id: string;
  workspaceId: string;
  categoryId: string;
  sourceFilePath: string;
  sourceFileName: string;
  tSFilePath: string;
  uploadStatus: boolean;
  userName: string;
  label:string;
  WorkspaceSupplementalDocId:number;
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
}


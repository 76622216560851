import { Component, OnInit, Input, ElementRef, HostListener, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// To include custom model and service class
import { DataService } from 'src/app/services/data.service';
import { AdUsersService } from 'src/app/services/adusers.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { AzureADUsersArrayList, SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { RoleNames } from 'src/app/model/Role';
import { TeamMember } from 'src/app/model/Engagement/TeamMember';
import { MembersList } from 'src/app/model/MembersList';
import { MemberFirmData } from 'src/app/model/MemberFirm/member-firm-data';

declare var $;

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.css']
})
export class PeoplePickerComponent implements OnInit, OnChanges {

  private _roleName = '';

  @Input() set roleShortName(roleName: string) {
    if (roleName !== '' && roleName !== undefined && roleName !== null) {
      this._roleName = roleName;
    }
  }
  get roleShortName(): string {
    return this._roleName;
  }
  @Input() labelCtrlName: string;
  @Input() isLabelVisible: boolean;
  @Input() popupMethodName: string;
  @Input() InputCtrlIdIndex: number; // used as index for textbox id and/or name also for icon id
  @Input() PeoplePickerModel: string;
  @Input() userEmail: string;
  @Input() IsPickerForAssignment: boolean;
  @Input() isValidateUser: boolean = false;
  @Input() memberFirmDetails: MemberFirmData;
  @Input() hideHinttext: boolean = false;

  azureADUsersArrayList: AzureADUsersArrayList = new AzureADUsersArrayList();
  azureADUsersArrayListAdmin: AzureADUsersArrayList;
  azureADUsersArrayListMemberFirm: AzureADUsersArrayList;
  searchUserFound: boolean = false;
  alert: Alert;
  isGivenUserGroupMember: boolean = false;
  @Input() teamMemberList: Array<TeamMember> = [];
  newTeamMember: any = {};
  PickerTextBoxCtrlName: string;
  PickerSearchUserErrorCtrlName: string;
  PickerSearchUserListCtrlName: string;
  PickerLoaderIconCtrlName: string;
  PickerHintLabelCtrlName: string;
  PickerUserHiddenPropsCtrlName: string;
  searchedUser: SearchGroupUserViewModel = new SearchGroupUserViewModel();
  PickerHiddenValidInputCtrlName: string;
  private _isValidateUserWithADGroup = false;
  ManagerJobCode: string = '280';
  isSelectedUserManager: boolean = false;
  PickerSearchUserManagerWarningCtrlName: string;
  PickerSearchUseremptytxtboxCtrlName: string;
  isValidUser: boolean = false;
  labelRoleName: string;
  userInfo: any;
  isMemberFirm: boolean = false;
  isLoggedInUserSystemAdmin: boolean = false;
  @Input() set IsValidateUserWithADGroup(newValue: boolean) {
    if (newValue && newValue !== undefined && newValue !== null) {
      this._isValidateUserWithADGroup = newValue;
      this.ValidateUserWithADGroup();
    }
  }
  @Output() validPeopleSelected: EventEmitter<string> = new EventEmitter<string>();
  //@Output() validUser: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() assignUser = new EventEmitter<any>();
  @Input() disabled: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (!this.eRef.nativeElement.contains(event.target)) {
      // console.log("clicked outside", this.eRef, event, event.target);
      if (!$(event.target).is('#' + this.PickerSearchUserListCtrlName)) {
        $('#' + this.PickerSearchUserListCtrlName).css('display', 'none');
        //$(this.PickerTextBoxCtrlName).val('');
        $('#' + this.PickerSearchUserErrorCtrlName).css('display', 'none');
        $('#' + this.PickerSearchUseremptytxtboxCtrlName).css('display', 'none');
        $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:block');
      }
    }
  }

  constructor(private dataService: DataService, private route: Router, private activeRoute: ActivatedRoute
    , private adUsersService: AdUsersService, private appService: AppConfigService,
    private _alertService: AlertsService, private eRef: ElementRef) {
    this.azureADUsersArrayList.azureADUsers = [];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.PeoplePickerModel) {
      this.assignAzureADGroupsByAdminForMemberFirm();
      this.checkLoggedInUserRole();
    }
    if (this.PeoplePickerModel?.length > 0) {
      this.removeValidationStyle();
    }
  }

  ngOnInit() {
    let jsonString = localStorage.getItem("currentUser");
    this.userInfo = JSON.parse(jsonString);
    this.checkLoggedInUserRole();
    this.isMemberFirm = this.userInfo?.isMemberFirmFeatureEnabled;
    this.isGivenUserGroupMember = this.isValidateUser;
    this.searchedUser.user = new MembersList();
    if (this.roleShortName == 'IC' && (this.isMemberFirm || this.memberFirmDetails?.memberFirmMappingId > 0)) {
      if (this.isMemberFirm)
        this.roleShortName = this.userInfo?.memberFirmIndusConsentShortRoleName;
      else if (this.memberFirmDetails?.memberFirmMappingId > 0)
        this.roleShortName = this.memberFirmDetails.indusConsentShortRoleName;
      this.GetAzureADGroupsByRoleShortNameINDUS(this.GetRoleShortName());
    }
    else if (this.roleShortName == 'IC' || this.roleShortName == 'GIA') {
      this.GetAzureADGroupsByRoleShortNameINDUS(this.GetRoleShortName());
    }
    else {
      this.GetAzureADGroupsByRoleShortName(this.GetRoleShortName());
    }
    this.assignAzureADGroupsByAdminForMemberFirm();

    this.reInitializeUser();
  } // end ngOnInit

  ValidateUserWithADGroup() {
    $('#' + this.PickerLoaderIconCtrlName).addClass('loader');
    const searchUsers: MembersList[] = [];
    const searchGroupUserViewModel = new SearchGroupUserViewModel();

    const addTeamMember = new MembersList();
    //addTeamMember.displayName = $('#' + this.PickerTextBoxCtrlName).val();
    //  addTeamMember.mail = $('#' + this.PickerUserHiddenPropsCtrlName + ' > #hdnUserEmail').val();
    addTeamMember.displayName = this.PeoplePickerModel;
    if (this.userEmail != '' && this.userEmail != null) {
      addTeamMember.mail = this.userEmail;
    }
    else {
      addTeamMember.mail = $('#' + this.PickerUserHiddenPropsCtrlName + ' > #hdnUserEmail').val();
    }

    searchUsers[0] = addTeamMember;
    this.assignUser.emit(JSON.stringify({ data: this.searchedUser.user, roleType: this.roleShortName, status: false }));
    this.adUsersService.GetAzureADGroupsByRoleShortName(this.GetRoleShortName()).subscribe(result => {
      this.azureADUsersArrayList = result;
      this.azureADUsersArrayList.roleName = this.GetRoleShortName();
      this.assignAzureADGroupsByAdminForMemberFirm();
      searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
      searchGroupUserViewModel.user = searchUsers[0];
      this.FindUserInGroupMembers(searchGroupUserViewModel, searchUsers);
      // $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
    });
  }

  // methods
  GetRoleShortName(): string {
    if (this.roleShortName.toUpperCase() === RoleNames.AssignmentAdministrator.toString()) {
      return RoleNames.AssignmentAdministrator.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.EngagementPartner.toString()) {
      return RoleNames.EngagementPartner.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.KeyRelationshipPartner.toString()) {
      return RoleNames.KeyRelationshipPartner.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.PracticeLeader.toString()) {
      return RoleNames.PracticeLeader.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.ReviewingProfessional.toString()) {
      return RoleNames.ReviewingProfessional.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.StaffMember.toString()) {
      return RoleNames.StaffMember.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.SupportManager.toString()) {
      return RoleNames.SupportManager.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.SystemAdministrator.toString()) {
      return RoleNames.SystemAdministrator.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.ClientAdministrator.toString()) {
      return RoleNames.ClientAdministrator.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.INDUSConsent.toString()) {
      return RoleNames.INDUSConsent.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.GTUSINDUSALL.toString()) {
      return RoleNames.GTUSINDUSALL.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.AssignmentEQCR.toString()) {
      return RoleNames.AssignmentEQCR.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.GTAgreementOwner.toString()) {
      return RoleNames.GTAgreementOwner.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.Delegate.toString()) {
      return RoleNames.Delegate.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.ICANDGIA.toString()) {
      return RoleNames.ICANDGIA.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.CanadaStaffMember.toString()) {
      return RoleNames.CanadaStaffMember.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.CanadaINDUSConsent.toString()) {
      return RoleNames.CanadaINDUSConsent.toString();
    } else if (this.roleShortName.toUpperCase() === RoleNames.AuditPartner.toString()) {
      return RoleNames.AuditPartner.toString();
    }
  }

  alertServiceFun(message, type) {
    this._alertService.remove(this.alert);
    this._alertService.add(message, type, null);
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  GetAzureADGroupsByRoleShortName(roleShortName: string) {
    this.adUsersService.GetAzureADGroupsByRoleShortName(roleShortName).subscribe(
      result => {
        this.azureADUsersArrayList = result;
        this.azureADUsersArrayList.roleName = roleShortName;
      }
    );
  }

  GetAzureADGroupsByRoleShortNameINDUS(roleShortName: string) {
    this.adUsersService.GetAzureADGroupsByRoleShortName(roleShortName).subscribe(
      result => {
        this.azureADUsersArrayList = result;
        this.azureADUsersArrayList.roleName = roleShortName;
      }
    );
  }

  GetUserInformationByDisplayName() {
    this.reInitializeUser();
    if ($('#' + this.PickerTextBoxCtrlName).val() != '') {
      $('#' + this.PickerSearchUseremptytxtboxCtrlName).attr('style', 'display:none');
      this.isValidUser == true;
      this.assignUser.emit(JSON.stringify({ data: this.searchedUser.user, roleType: this.roleShortName, status: false }));
      //this.validUser.emit(false);
      this._alertService.remove(this.alert);
      $('#' + this.PickerLoaderIconCtrlName).addClass('loader');
      let isUserSelected = false;

      let displayName = '';

      displayName = $('#' + this.PickerTextBoxCtrlName).val();
      displayName = displayName.trim();

      let searchUsers: MembersList[] = [];
      let searchGroupUserViewModel = new SearchGroupUserViewModel();
      if (this.searchUserFound === false) {
        let searchModel = new SearchGroupUserViewModel();

        searchModel.azureADUsers = this.azureADUsersArrayList;
        searchModel.user = new MembersList();
        searchModel.user.displayName = displayName;
        if (this.memberFirmDetails?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
          searchModel.isMemberFirm = true;
          searchModel.memberFirmDomain = [];
          searchModel.memberFirmDomain.push(this.memberFirmDetails?.memberDomain)
        }
        this.adUsersService.GetUserInformationByDisplayName(searchModel).subscribe(result => {
          if (this.roleShortName === 'AA') {
            searchUsers = result.filter(
              item => item.lineOfBusiness != this.appService.config.DATA.LINE_OF_BUSINESS_ID_PS
            );
          } else {
            searchUsers = result;
          }
          console.log('searched user', result);
          if (searchUsers !== null && searchUsers.length > 0) {
            this.searchUserFound = true;
            if (searchUsers.length === 1) {
              // validate given user for group membership.
              searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
              searchGroupUserViewModel.user = searchUsers[0];
              if (this.memberFirmDetails?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
                searchModel.isMemberFirm = true;
                searchModel.memberFirmDomain = [];
                searchModel.memberFirmDomain.push(this.memberFirmDetails?.memberDomain)
              }
              this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(
                isUserValid => {
                  this.isGivenUserGroupMember = isUserValid;
                  this.searchedUser.user = searchGroupUserViewModel.user;
                  this.assignUser.emit(JSON.stringify({ data: this.searchedUser.user, roleType: this.roleShortName, status: this.isGivenUserGroupMember }));
                  //this.validUser.emit(this.isGivenUserGroupMember);
                  $('#' + this.PickerTextBoxCtrlName).val(searchUsers[0].displayName);
                  this.addSearchResultsStyle(this.isGivenUserGroupMember);
                  $('#' + this.PickerHiddenValidInputCtrlName).val(this.isGivenUserGroupMember);
                  this.searchUserFound = false;
                  this.RemoveStylesonKeyDownEvent();
                  if (this.isGivenUserGroupMember) {
                    let memberJson = JSON.stringify(searchUsers[0]);
                    this.validPeopleSelected.emit(memberJson);
                  }
                  $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
                  this.DisplayManagerWarningForAA(isUserValid);
                }
              );

            } else if (searchUsers.length > 1) {
              $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
              $('#' + this.PickerSearchUserListCtrlName).css('display', 'block');
              $('#' + this.PickerSearchUserManagerWarningCtrlName).attr('style', 'display:none');
              if (!isUserSelected) {
                $('#' + this.PickerSearchUserListCtrlName + ' .searchItemLi').remove();
              }

              $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:none');
              for (let i = 0; i < searchUsers.length; i++) {
                let li_data =
                  "<li class='list-group-item searchItemLi' id='searchItemList_" + this.roleShortName + '_' + i + "'><a id='searchUserName_" + this.roleShortName + '_' + i + "'> " + searchUsers[i].displayName + " ( " + searchUsers[i].mail + " ) </a></li>";
                $(li_data).appendTo('#' + this.PickerSearchUserListCtrlName);
              }

              let children = $('.searchItemLi a');
              for (let j = 0; j < children.length; j++) {
                children[j].addEventListener('click', (e) => {
                  $('#' + this.PickerSearchUserListCtrlName).attr('style', 'display:none');
                  $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:block');
                  isUserSelected = true;
                  console.log(e);
                  $('#' + this.PickerLoaderIconCtrlName).addClass('loader');
                  let anchorId = e.target['id'];
                  let index = anchorId.split('_')[2];
                  $('#' + this.PickerTextBoxCtrlName).val(searchUsers[index].displayName);
                  // validate given user for group membership.
                  this.assignAzureADGroupsByAdminForMemberFirm();
                  searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
                  searchGroupUserViewModel.user = searchUsers[index];
                  if (this.memberFirmDetails?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
                    searchModel.isMemberFirm = true;
                    searchModel.memberFirmDomain = [];
                    searchModel.memberFirmDomain.push(this.memberFirmDetails?.memberDomain)
                  }
                  this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(
                    isUserValid => {
                      this.isGivenUserGroupMember = isUserValid;
                      $('#' + this.PickerSearchUserListCtrlName + ' .searchItemLi').remove();
                      $('#' + this.PickerSearchUserListCtrlName).attr('style', 'display:none');

                      this.addSearchResultsStyle(this.isGivenUserGroupMember);
                      if (this.isGivenUserGroupMember) {
                        this.searchedUser.user = searchGroupUserViewModel.user;
                        this.assignUser.emit(JSON.stringify({ data: this.searchedUser.user, roleType: this.roleShortName, status: this.isGivenUserGroupMember }));
                        //this.validUser.emit(this.isGivenUserGroupMember);
                        $('#' + this.PickerHiddenValidInputCtrlName).val(this.isGivenUserGroupMember);
                      }
                      if (this.isGivenUserGroupMember) {
                        let memberJson = JSON.stringify(searchUsers[index]);
                        this.validPeopleSelected.emit(memberJson);
                      }
                      this.RemoveStylesonKeyDownEvent();
                      $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
                      this.DisplayManagerWarningForAA(isUserValid);
                    }
                  ); // end subscribe FindUserInGroupMembers

                }); // end addEventListener

              } // end for loop on children of ul
              this.RemoveStylesonKeyDownEvent();
            } // end if (searchUsers.length > 1)
          } else if (searchUsers == null || searchUsers.length === 0) {
            this.addSearchResultsStyle(false);
            $('#' + this.PickerSearchUserErrorCtrlName).attr('style', 'display:block');
            this.searchUserFound = false;
            this.RemoveStylesonKeyDownEvent();
            $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
          }
        }, // end success part
          error => {
            $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
            this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
          }
        ); // end main subscribe method
      }
    } else {
      $('#' + this.PickerSearchUseremptytxtboxCtrlName).attr('style', 'display:block');
      this.isValidUser == false;
      $('#' + this.PickerSearchUserErrorCtrlName).attr('style', 'display:none');
      if (this.labelCtrlName != 'CMS PPMD') {
        this.labelRoleName = this.labelCtrlName ? this.labelCtrlName.charAt(0).toUpperCase() + this.labelCtrlName.slice(1) : '';
      } else {
        this.labelRoleName = this.labelCtrlName;
      }
    }
  }// end GetUserInformationByDisplayName method

  reInitializeUser() {
    if (this.IsPickerForAssignment) {
      this.PickerTextBoxCtrlName = 'txtPeoplePicker_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUserErrorCtrlName = 'searchUserError_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUserListCtrlName = 'searchUserList_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerLoaderIconCtrlName = 'loadericon_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerHintLabelCtrlName = 'hint_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerUserHiddenPropsCtrlName = 'UserHiddenProps_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerHiddenValidInputCtrlName = 'hdnValidInput_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUserManagerWarningCtrlName = 'searchUserManagerWarning_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUseremptytxtboxCtrlName = 'searchUseremptytxtbox_AS_' + this.roleShortName + '_' + this.InputCtrlIdIndex;

    } else {
      this.PickerTextBoxCtrlName = 'txtPeoplePicker_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUserErrorCtrlName = 'searchUserError_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUserListCtrlName = 'searchUserList_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerLoaderIconCtrlName = 'loadericon_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerHintLabelCtrlName = 'hint_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerUserHiddenPropsCtrlName = 'UserHiddenProps_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerHiddenValidInputCtrlName = 'hdnValidInput_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUserManagerWarningCtrlName = 'searchUserManagerWarning_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
      this.PickerSearchUseremptytxtboxCtrlName = 'searchUseremptytxtbox_' + this.roleShortName + '_' + this.InputCtrlIdIndex;
    }
  }
  selectUserFromSearchResult(searchUser: MembersList, event) {
    $('#' + this.PickerSearchUserListCtrlName).remove();
    let searchGroupUserViewModel = new SearchGroupUserViewModel();
    let isGivenUserGroupMember = false;
    searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
    searchGroupUserViewModel.user = searchUser;
    if (this.memberFirmDetails?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
      searchGroupUserViewModel.isMemberFirm = true;
      searchGroupUserViewModel.memberFirmDomain = [];
      searchGroupUserViewModel.memberFirmDomain.push(this.memberFirmDetails?.memberDomain)
    }
    this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(
      isUserValid => {
        isGivenUserGroupMember = isUserValid;
        this.assignUser.emit(JSON.stringify({ data: this.searchedUser.user, roleType: this.roleShortName, status: this.isGivenUserGroupMember }));
        //this.validUser.emit(this.isGivenUserGroupMember);        
        // this.loadingScreenService.stopLoading();
        alert('valid user ' + isGivenUserGroupMember);
        this.addSearchResultsStyle(isGivenUserGroupMember);
        if (isGivenUserGroupMember) {
          $('#' + this.PickerTextBoxCtrlName).val($(this).text());
          $('#' + this.PickerTextBoxCtrlName).addClass('searchresult');
          $('#' + this.PickerSearchUserListCtrlName + 'li').parent().fadeToggle(500);
          $('#' + this.PickerHiddenValidInputCtrlName).val(this.isGivenUserGroupMember);
        }
      });
  } // end selectUserFromSearchResult

  // start search result styling methods
  removeSearchResultsStyle() {
    $('#' + this.PickerTextBoxCtrlName).on('keydown', function (event) {
      if (event.keyCode === 8) {
        if ($('#' + this.PickerTextBoxCtrlName).hasClass('searchresult')) {
          $('#' + this.PickerTextBoxCtrlName).removeClass('searchresult');
        }
        if ($('#' + this.PickerTextBoxCtrlName).hasClass('text-danger')) {
          $('#' + this.PickerTextBoxCtrlName).removeClass('text-danger');
        }
      }
      $('#' + this.PickerSearchUserErrorCtrlName).attr('style', 'display:none');
      $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:block');
    });
  }
  removeValidationStyle() {
    if ($('#' + this.PickerTextBoxCtrlName).hasClass('text-danger')) {
      $('#' + this.PickerTextBoxCtrlName).removeClass('text-danger');
    }
  }

  addSearchResultsStyle(ValidInput: boolean) {
    console.log('addSearchResultsStyle func called ', ValidInput);
    if (ValidInput) {
      if (!$('#' + this.PickerTextBoxCtrlName).hasClass('searchresult')) {
        $('#' + this.PickerTextBoxCtrlName).addClass('searchresult');
      }
      if ($('#' + this.PickerTextBoxCtrlName).hasClass('text-danger')) {
        $('#' + this.PickerTextBoxCtrlName).removeClass('text-danger');
      }
      $('#' + this.PickerSearchUserErrorCtrlName).attr('style', 'display:none');
      $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:block');
    } else {
      $('#' + this.PickerTextBoxCtrlName).addClass('text-danger');
      $('#' + this.PickerSearchUserErrorCtrlName).attr('style', 'display:block');
      $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:none');
    }
  }
  // end search result styling methods

  // add keydown event to textbox to remove styling
  RemoveStylesonKeyDownEvent() {
    let selectedtxtPicker = $('#' + this.PickerTextBoxCtrlName);

    // for (var i = 0; i < selectedtxtPicker.length; i++) {
    if (selectedtxtPicker.length > 0) {
      selectedtxtPicker[0].addEventListener('keydown', (e: KeyboardEvent) => {
        this.searchUserFound = false;

        if ($('#' + this.PickerTextBoxCtrlName).hasClass('searchresult')) {
          $('#' + this.PickerTextBoxCtrlName).removeClass('searchresult');
        }
        if ($('#' + this.PickerTextBoxCtrlName).hasClass('text-danger')) {
          $('#' + this.PickerTextBoxCtrlName).removeClass('text-danger');
        }
        let searchUserError = document.getElementById(this.PickerSearchUserErrorCtrlName);
        if (searchUserError) {
          searchUserError.setAttribute('style', 'display:none');
        }
        $('#' + this.PickerSearchUserListCtrlName + ' .searchItemLi').remove();
        $('#' + this.PickerSearchUserListCtrlName).css('display', 'none');
        $('#' + this.PickerHintLabelCtrlName).attr('style', 'display:block');
        if (e.key !== 'Tab') {
          $('#' + this.PickerHiddenValidInputCtrlName).val(false);
        }

      });
    }
  }

  // end keydown event code
  setPeoplePickerPlaceholder() {
    $('#' + this.PickerTextBoxCtrlName).attr('placeholder', 'Select ' + this.labelCtrlName);
  }

  setPeoplePickerPlaceholderForFocus() {
    $('#' + this.PickerTextBoxCtrlName).attr('placeholder', '');
  }

  FindUserInGroupMembers(searchGroupUserViewModel: SearchGroupUserViewModel, searchUsers: MembersList[]) {
    let searchModel = new SearchGroupUserViewModel();
    searchModel.azureADUsers = searchGroupUserViewModel.azureADUsers;
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchGroupUserViewModel.user.displayName;
    if (searchModel.user.displayName != '') {
      $('#' + this.PickerLoaderIconCtrlName).addClass('loader');
      this.adUsersService.GetUserInformationByDisplayName(searchModel).subscribe(result => {
        searchUsers = result;
        if (searchUsers.length == 1) {
          searchGroupUserViewModel.user = searchUsers[0];
        }
        if (this.memberFirmDetails?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
          searchModel.isMemberFirm = true;
          searchModel.memberFirmDomain = [];
          searchModel.memberFirmDomain.push(this.memberFirmDetails?.memberDomain)
        }
        this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(isUserValid => {
          this.isGivenUserGroupMember = isUserValid;
          this.searchedUser.user = searchGroupUserViewModel.user;
          this.assignUser.emit(JSON.stringify({ data: this.searchedUser.user, roleType: this.roleShortName, status: this.isGivenUserGroupMember }));
          //this.validUser.emit(this.isGivenUserGroupMember);
          $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
          this.addSearchResultsStyle(this.isGivenUserGroupMember);
          // $('#' + this.PickerHiddenValidInputCtrlName).val(this.isGivenUserGroupMember);
          this.searchUserFound = false;
          this.RemoveStylesonKeyDownEvent();
          $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
          // $('#' + this.PickerHiddenValidInputCtrlName).val(isUserValid);
          this.DisplayManagerWarningForAA(isUserValid);
          if (this.isGivenUserGroupMember) {
            let memberJson = JSON.stringify(searchUsers[0]);
            this.validPeopleSelected.emit(memberJson);
          }
        },
          error => {
            console.log('Error in ValidateUserWithADGroup ', error);
            $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
          });
      },
        error => {
          console.log('Error in ValidateUserWithADGroup ', error);
          $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
        });
    } else {
      $('#' + this.PickerLoaderIconCtrlName).removeClass('loader');
    }
  }
  DisplayManagerWarningForAA(isValidUser: boolean) {
    //Display Manager Warning fo AA control box 
    if (this.roleShortName === RoleNames.AssignmentAdministrator &&
      this.searchedUser.user.jobCode === this.ManagerJobCode && isValidUser) {
      this.isSelectedUserManager = true;
      $('#' + this.PickerSearchUserManagerWarningCtrlName).attr('style', 'display:block');
    } else {
      this.isSelectedUserManager = false;
      $('#' + this.PickerSearchUserManagerWarningCtrlName).attr('style', 'display:none');
    }
  }

  assignAzureADGroupsByAdminForMemberFirm() {
    if (this.isLoggedInUserSystemAdmin && this.memberFirmDetails?.memberFirmMappingId > 0) {
      if (this.azureADUsersArrayListAdmin == undefined)
        this.adUsersService.GetAzureADGroupsByRoleShortName(RoleNames.StaffMember.toString()).subscribe(
          result => {
            this.azureADUsersArrayListAdmin = result;
            this.azureADUsersArrayListAdmin.azureADUsers?.forEach(resu => {
              this.azureADUsersArrayList.azureADUsers.push(resu);
            });
            this.azureADUsersArrayList.roleName = RoleNames.StaffMember.toString();
          }
        );
      else {
        this.azureADUsersArrayListAdmin.azureADUsers?.forEach(resu => {
          this.azureADUsersArrayList.azureADUsers.push(resu);
        });
        this.azureADUsersArrayList.roleName = RoleNames.StaffMember.toString();
      }
    }
  }
  checkLoggedInUserRole() {
    console.log('userInfo ==>', this.userInfo);
    if (this.userInfo != null && this.userInfo.role != null && this.userInfo.role.length > 0) {
      for (let i = 0; i < this.userInfo.role.length; i++) {
        if (this.userInfo.role[i].shortRoleName === 'SA') {
          this.isLoggedInUserSystemAdmin = true;
        }
      } //End of For  
    }// End of If
  }
} // end PeoplePickerComponent

import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfigService } from './appConfigService';
import { AuthService } from './auth.service';
import { UtilityDocument, UtilityInsertModel, UtilityModelData, UtilityUpdatedADGroupCheckModel, UtilityValidationModel } from '../model/DocumentUtility';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProcessAddProfessionalsEngagementService {
  APIEndPoint: string;
  integrationEndPoint: string;


  constructor(private http: HttpClient, private enviornment: AppConfigService,
    private authService: AuthService) { 
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.integrationEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;

  

  }

  UtilityUploadExcel(utilityDocument: UtilityDocument): Observable<UtilityModelData> {
    return this.http.post<UtilityModelData>(this.APIEndPoint + '/Engagement/UtilityUploadExcel/',
      utilityDocument);
  }

  ValidateExcelDataAcrossAD(validationModel:UtilityValidationModel): Observable<UtilityValidationModel> {
    return this.http.post<UtilityValidationModel>(this.integrationEndPoint+'/Graph/ValidateUtilityExcelDataAcrossAD',validationModel);
  }

  AddTheValidatedDataToADGroups(validationModel:UtilityValidationModel) {
    return this.http.post(this.integrationEndPoint+'/Graph/AddTheValidatedDataToADGroups',validationModel);
  }

  InsertValidatedDataToEngagements(utilityInsertModel: UtilityInsertModel): Observable<UtilityModelData> {
    return this.http.post<UtilityModelData>(this.APIEndPoint + '/Engagement/InsertValidatedDataToEngagements/',
    utilityInsertModel);
  }



}

export class WorkspaceComments {
  id: string;
  discussionId: string;
  workspaceId: string;
  description: string;
  comments: string[] = [];
  userName: string;
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
}

<div class="card">
    <div class="card-header justify-content-between d-flex">
        <div class="card-heading">
          <img src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive" 
            *ngIf="sectionConfiguration.isCompleted" alt="Completed"
            style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" 
            width="20" height="20" />
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" class="img-responsive" 
            *ngIf="showRequiredWarning" alt="Warning" 
            style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" 
            width="20" height="20" />
          <a class="card-link">
            <h5 class="edp-font-14 edp-font-color font-weight-bold">Retention period</h5>
          </a>
        </div>
        <div class="blank-card"></div>
        <div class="card-icon" (click)="toggleUp()" *ngIf="!isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/desc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
        <div class="card-icon" (click)="toggleDown()" *ngIf="isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/asc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
    </div>
    <div id="collapseOne" class="card-header pt-0 mt-0" data-parent="#accordion" *ngIf="isImageEnabled">
        <div>
          Tax engagement records are retained for a period of 7 years after completion of the services.
           In limited cases, records may need to be retained longer than the standard 7 year period.
            Indicate below whether records to be archived in this engagement’s collaboration site require retention beyond 7 years (e.g., NOLs or credits originated in the current year where the carryforward period extends beyond 7 years, increased statute of limitations, etc.).
             Note, all permanent client files should be separately stored in the client’s Perm Library.  
        </div>
        <div class="mt-3 mb-3">
          Does this engagement require retention of records beyond the standard 7 year retention period?<br>
          <label class="custom-radio">
            Yes
            <input type="radio" name="isRetentionExtensionRequired"
             (click)="onIsRetentionExtensionRequiredClick('Yes')" id="Yes" 
             [checked]="isRetentionExtensionRequired" >
            <span class="checkmark isRetentionExtentionRequiredYes"></span>
          </label>&nbsp;&nbsp;

          <label class="custom-radio">
            No
            <input type="radio" name="isRetentionExtensionRequired" 
            (click)="onIsRetentionExtensionRequiredClick('No')"  id="No" 
            [checked]="isRetentionExtensionRequired === false" >
            <span class="checkmark isRetentionExtentionRequiredNo"></span>
          </label>
        </div>
        <div *ngIf="isRetentionExtensionRequired">
          (If yes) Indicate the TOTAL NUMBER of years that these engagement records must be retained from the date of site
          closure/archival
          <div class="selectdiv w-25 mt-2" id="divNoOfYearsExtension">
            <select id="noOfYearsExtension" [(ngModel)]="noOfYearsExtention" (change)="changeYear();"
              class="dropdown rounded-0 form-control" name="noOfYearsExtension">
              <option value="0" disabled hidden>Select years count</option>
              <option *ngFor="let count of yearsList" [value]="count">
                {{count}}
              </option>
            </select>
          </div>
          <div class="label error-color edp-font-12" *ngIf="showExtensionRequiredMsg">
            <p>Select retention period</p>
          </div>
        </div>
        <div class="col-md-3 mt-3 px-0"><button type="button" id="btnSaveChecklist"
          class="btn SecondaryButton w-100 edp-font-14 font-weight-bold" data-toggle="modal"
          (click)="SaveChecklist()" style="border:1px solid #c7c7c7;border-radius: 3px !important;">
          <span class="d-inline-block mx-2  align-middle">Save retention</span>
        </button>
        </div>
        <div class="row success-msg col-lg-12 text-start d-flex "
              *ngIf="isSaveChecklist">
              <p><b>Retention record saved successfully</b></p>
        </div>
    </div>
</div>


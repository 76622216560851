<div class="row config-info-title mediumFontSize2 mb-3 m-0"> Templates</div>
<div class="bg-edp-body card-container position-relative p-2">
  <div class="font-weight-bold m-2 ">Defined HotDocs templates</div>
  <!--filter and show icon design start-->
  <div class="template-list-view">
    <div class="d-flex ">
      <div class="mr-auto m-2">
        <div class="d-flex flex-row bd-highlight">
          <a class="px-3 py-1 anchoroutline" (click)="showHideFilterPanel(true)" data-placement="bottom"
            target="_blank" [class]="(isActiveFilter===true)?'px-3 py-1 anchoroutline active-filter':'px-3 py-1 anchoroutline ' ">
            <img class="shortcutimg" src="../../../../assets/images/EDP/Filters/filter_list.svg" alt="filter-list" />
            Filter
          </a>
          <a class="px-3 py-1 anchoroutline" (click)="resetFilters()" data-placement="bottom" target="_blank"
          [class]="(isShowActiveFilter===true)?'px-3 py-1 anchoroutline active-filter':'px-3 py-1 anchoroutline ' ">
            <img class="shortcutimg" src="../../../../assets/images/EDP/Filters/remove_red_eye.svg" alt="remove" />
            Show all
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--filter and show icon design end-->

  <!-- Template maintenance filter view start -->
  <section *ngIf="isDisplayFilter" class="filter-panel">
    <div class="row m-0 bg-edp-body">
      <div class="col-md-12">
        <div class="row m-0 pb-4">
          <div class="col-xl-2 col-md-2 filter-partition">
            <label class="font-weight-bold mb-1">Service line</label>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" name="advisory" id="advisory"
                [(ngModel)]="templateMaintenanceFilter.isAdvisory">
              <label for="advisory" class="custom-control-label">Advisory</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="audit" name="audit" class="custom-control-input"
                [(ngModel)]="templateMaintenanceFilter.isAudit">
              <label for="audit" class="custom-control-label"> Audit</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" name="tax" id="tax" class="custom-control-input"
                [(ngModel)]="templateMaintenanceFilter.isTax">
              <label for="tax" class="custom-control-label"> Tax</label>
            </div>
          </div>
          <div class="col-xl-3 col-md-3 filter-partition pl-xl-4">
            <label class="font-weight-bold mb-1">Type</label>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" name="standalone" id="standalone"
                [(ngModel)]="templateMaintenanceFilter.isStandalone">
              <label for="standalone" class="custom-control-label">Single use/Standalone</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="full-agreement" name="full-agreement" class="custom-control-input"
                [(ngModel)]="templateMaintenanceFilter.isFullAgreement">
              <label for="full-agreement" class="custom-control-label"> Full agreement</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" name="component-template" id="component-template" class="custom-control-input"
                [(ngModel)]="templateMaintenanceFilter.isComponentTemplate">
              <label for="component-template" class="custom-control-label"> Component template</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" name="bypass" id="bypass"
                [(ngModel)]="templateMaintenanceFilter.isBypass">
              <label for="bypass" class="custom-control-label">Bypass</label>
            </div>
          </div>
          <div class="col-xl-3 col-md-5 filter-partition pl-xl-4">
            <label class="font-weight-bold mb-1">Governing agreement</label>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" name="gt-standalone-msa" id="gt-standalone-msa"
                [(ngModel)]="templateMaintenanceFilter.isGTStandardMSA" class="custom-control-input">
              <label for="gt-standalone-msa" class="custom-control-label">GT standard MSA</label>
            </div>

            <div class="custom-control custom-checkbox">
              <input type="checkbox" name="universal-engagement-letter" id="universal-engagement-letter"
                [(ngModel)]="templateMaintenanceFilter.isUniversalEngagementLetter" class="custom-control-input">
              <label for="universal-engagement-letter" class="custom-control-label">Universal engagement
                letter</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" name="tax-evergreen" id="tax-evergreen"
                [(ngModel)]="templateMaintenanceFilter.isTaxEvergreen" class="custom-control-input">
              <label for="tax-evergreen" class="custom-control-label">Tax evergreen engagement letter</label>
            </div>
          </div>
          <div class="col-xl-2 col-md-2 pl-xl-4">
            <label class="font-weight-bold mb-1">Status</label>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" name="active" id="active"
                [(ngModel)]="templateMaintenanceFilter.isActive">
              <label for="active" class="custom-control-label">Active</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="inactive" name="inactive" class="custom-control-input"
                [(ngModel)]="templateMaintenanceFilter.isInactive">
              <label for="inactive" class="custom-control-label"> Inactive</label>
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-md-12 align-self-end text-right pr-4">
        <button type="button" (click)="resetFilters(true)" class="SecondaryButton mr-3"
          style="min-width: 110px; text-align: center;">
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" (click)="getTemplateMaintenanceListByFilters()" class="PrimaryButton mr-3"
          style="min-width: 110px; text-align: center;">
          <span class="d-inline-block align-middle">Apply</span>
        </button>
        <button type="button" (click)="showHideFilterPanel(false)" class="toggle-btn mr-1">
          <img alt="OK" src="../../../../assets/images/icons/expand_more-24px.svg" alt="expand" />
        </button>
      </div>
    </div>
  </section>
  <!-- Template maintenance filter view end -->

  <!-- Template maintenance grid view start -->
  <div class="MainContainer m-2">
    <table class="listViewDataTable ResponsiveDataTable template-list-view">
      <thead>
        <tr>
          <th scope="col" class="table-sorted-desc t-lr-col">
            <div class="row p-0 m-0 th-height">
              <div class="col-md-10 p-0 m-0">
                {{headerModelList[0].headerName}}
              </div>
              <div class="col-md-2 p-0 m-0 text-right">
                <div *ngFor="let img of headerModelList[0].imageModelList">
                  <a id="image_{{img.imageName}}" *ngIf="img.isVisible" [ngClass]="{isDisabled:listCount == 0}"
                    (click)="headerSort(headerModelList[0].headerId)">
                    <img src="{{img.imagePath}}" alt="column-sort" />
                  </a>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="table-sorted-desc t-sm-col">
            <div class="row p-0 m-0 th-height">
              <div class="col-md-10 p-0 m-0">
                {{headerModelList[1].headerName}}
              </div>
              <div class="col-md-2 p-0 m-0 text-right">
                <div *ngFor="let img of headerModelList[1].imageModelList">
                  <a id="image_{{img.imageName}}" *ngIf="img.isVisible" [ngClass]="{isDisabled:listCount == 0}"
                    (click)="headerSort(headerModelList[1].headerId)">
                    <img src="{{img.imagePath}}" alt="column-sort" />
                  </a>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="table-sorted-desc t-lr-col">
            <div class="row p-0 m-0 th-height">
              <div class="col-md-10 p-0 m-0">
                {{headerModelList[2].headerName}}
              </div>
              <div class="col-md-2 p-0 m-0 text-right">
                <div *ngFor="let img of headerModelList[2].imageModelList">
                  <a id="image_{{img.imageName}}" *ngIf="img.isVisible" [ngClass]="{isDisabled:listCount == 0}"
                    (click)="headerSort(headerModelList[2].headerId)">
                    <img src="{{img.imagePath}}" alt="column-sort" />
                  </a>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="table-sorted-desc t-lr-col">
            <div class="row p-0 m-0 th-height">
              <div class="col-md-10 p-0 m-0">
                {{headerModelList[3].headerName}}
              </div>
              <div class="col-md-2 p-0 m-0 text-right">
                <div *ngFor="let img of headerModelList[3].imageModelList">
                  <a id="image_{{img.imageName}}" *ngIf="img.isVisible" [ngClass]="{isDisabled:listCount == 0}"
                    (click)="headerSort(headerModelList[3].headerId)">
                    <img src="{{img.imagePath}}" alt="column-sort" />
                  </a>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="table-sorted-desc t-md-col">
            <div class="row p-0 m-0 th-height">
              <div class="col-md-10 p-0 m-0">
                {{headerModelList[4].headerName}}
              </div>
              <div class="col-md-2 p-0 m-0 text-right">
                <div *ngFor="let img of headerModelList[4].imageModelList">
                  <a id="image_{{img.imageName}}" *ngIf="img.isVisible" [ngClass]="{isDisabled:listCount == 0}"
                    (click)="headerSort(headerModelList[4].headerId)">
                    <img src="{{img.imagePath}}" alt="column-sort" />
                  </a>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="table-sorted-desc text-center t-sm-col">
            <div class="row p-0 m-0 th-height">
              <div class="col-md-10 p-0 m-0">
                {{headerModelList[5].headerName}}
              </div>
              <div class="col-md-2 p-0 m-0 text-right">
                <div *ngFor="let img of headerModelList[5].imageModelList">
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="display" *ngIf="templateMaintenanceList.length > 0">
        <tr class="searchDatajs"
          [ngClass]="(selectedTemplatePackageId=== templateMaintenance.templatePackageId)?'tr-expanded-height':''"
          *ngFor="let templateMaintenance of templateMaintenanceList" class="position-relative">
          <td data-label="Template name:" class="t-lr-col">
            {{ templateMaintenance.templateName }}
            <div *ngIf="selectedTemplatePackageId === templateMaintenance.templatePackageId"
              class="expand-data-position d-flex row m-0 ">
              <div [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}" class="col-lg-2 col-md-4 my-md-2 my-lg-0 pl-0">
                <div class="font-weight-bold link">{{ templateMaintenance.templateName }} <span
                    class="font-weight-normal text-black ml-2">- v{{ templateMaintenance.templateVersion }}</span></div>
              </div>
              <div [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}" class="col-lg-2 col-md-3 my-md-2 my-lg-0  line-sep-right">
                <div class="font-weight-bold mb-2">Service line</div>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" name="advisory" id="advisory"
                    [(ngModel)]="templateMaintenance.isAdvisory">
                  <label for="advisory" class="custom-control-label">Advisory</label>
                </div>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" id="audit" name="audit" class="custom-control-input"
                    [(ngModel)]="templateMaintenance.isAudit">
                  <label for="audit" class="custom-control-label"> Audit</label>
                </div>
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" name="tax" id="tax" class="custom-control-input"
                    [(ngModel)]="templateMaintenance.isTax">
                  <label for="tax" class="custom-control-label"> Tax</label>
                </div>
              </div>
              <div [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}" class="col-lg-2 col-md-4 my-md-2 my-lg-0 line-sep-right">
                <div class="font-weight-bold mb-2">Type</div>
                <div class="my-1">
                  <label for="standalone" class="custom-radio mr-1">Single use/Standalone
                    <input type="radio" name="template-type" id="standalone" [value]="'SU'"
                      [(ngModel)]="templateMaintenance.templateTypeCode">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="my-1">
                  <label for="full-agreement" class="custom-radio mr-1">Full agreement
                    <input type="radio" name="template-type" id="full-agreement" [value]="'FA'"
                      [(ngModel)]="templateMaintenance.templateTypeCode">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="my-1">
                  <label for="component-template" class="custom-radio mr-1">Component template
                    <input type="radio" name="template-type" id="component-template" [value]="'CT'"
                      [(ngModel)]="templateMaintenance.templateTypeCode">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="my-1">
                  <label for="bypass" class="custom-radio mr-1">Bypass
                    <input type="radio" name="template-type" id="bypass" [value]="'BY'"
                      [(ngModel)]="templateMaintenance.templateTypeCode">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="(templateMaintenance.templateTypeCode === templateTypeCode)" [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}"
                class="col-lg-2 col-md-4 my-md-2 my-lg-0 nosep-line line-sep-right pr-md-0">
                <div class="row m-0">
                  <!-- <div class="col-lg-12 px-0"> -->
                    <div class="font-weight-bold">Governing agreement</div>
                    <span>Used with component</span>
                  <!-- </div> -->
                  <div class="my-1">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" name="gt-standalone-msa" id="gt-standalone-msa"
                        [(ngModel)]="templateMaintenance.isGTStandardMSA" class="custom-control-input">
                      <label for="gt-standalone-msa" class="custom-control-label">MSA on GT Paper</label>
                    </div>

                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" name="universal-engagement-letter" id="universal-engagement-letter"
                        [(ngModel)]="templateMaintenance.isUniversalEngagementLetter" class="custom-control-input">
                      <label for="universal-engagement-letter" class="custom-control-label">Universal engagement
                        letter</label>
                    </div>
                  </div>
                  <div class="my-1">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" name="tax-evergreen" id="tax-evergreen"
                        [(ngModel)]="templateMaintenance.isTaxEvergreen" class="custom-control-input">
                      <label for="tax-evergreen" class="custom-control-label">Tax evergreen engagement letter</label>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}" class="col-lg-2 col-md-3 my-md-2 my-lg-0  line-sep-right">
                <div class="font-weight-bold mb-2">Engagement State</div>
                <div class="my-1">
                  <label for="Acceptanceonly" class="custom-radio mr-1">Acceptance only
                    <input type="radio" name="stateconfig-type" id="Acceptanceonly"  [value]="'AO'"
                      [(ngModel)]="templateMaintenance.engagementStatecode">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="my-1">
                  <label for="Executiononly" class="custom-radio mr-1">Execution only
                    <input type="radio" name="stateconfig-type" id="Executiononly" [value]="'EO'"
                      [(ngModel)]="templateMaintenance.engagementStatecode">
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="my-1">
                  <label for="Both" class="custom-radio mr-1">Both
                    <input type="radio" name="stateconfig-type" id="Both" [value]="'Both'"
                      [(ngModel)]="templateMaintenance.engagementStatecode"> 
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="my-1" *ngIf="templateMaintenance.isAdvisory">
                  <label for="Downloadtemplate" class="custom-radio mr-1">Download Template
                    <input type="radio" name="stateconfig-type" id="Downloadtemplate" [value]="'DT'"
                      [(ngModel)]="templateMaintenance.engagementStatecode"> 
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}" class="col-lg-2 col-md- my-md-3 my-lg-0 line-sep-right" *ngIf="templateMaintenance.isAdvisory">
                <div class="font-weight-bold mb-2">Independence Consideration</div>
                <div class="my-1">
                    <label for="sec" class="custom-radio mr-1">SEC
                        <input type="radio" name="independanceConsideration-type" id="sec" [value]="'SEC'" [(ngModel)]="templateMaintenance.independenceConsiderationCode">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="my-1">
                    <label for="nonSec" class="custom-radio mr-1">Non-SEC
                        <input type="radio" name="independanceConsideration-type" id="nonSec" [value]="'Non-SEC'" [(ngModel)]="templateMaintenance.independenceConsiderationCode">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="my-1">
                    <label for="both" class="custom-radio mr-1">Both
                        <input type="radio" name="independanceConsideration-type" id="both" [value]="'Both'" [(ngModel)]="templateMaintenance.independenceConsiderationCode">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            
            <div [ngClass]="{'component-template-width-GA': templateMaintenance.templateTypeCode === templateTypeCode}" class="col-lg-2 col-md-3 my-md-2 my-lg-0 line-sep-right" *ngIf="templateMaintenance.isAdvisory">
                <div class="font-weight-bold mb-2">Template Category</div>
                <div class="my-1">
                    <label for="el" class="custom-radio mr-1">EL
                        <input type="radio" name="templateCategory" id="el" [value]="'EL'" [(ngModel)]="templateMaintenance.templateCategoryCode">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="my-1">
                    <label for="sow" class="custom-radio mr-1">SOW
                        <input type="radio" name="templateCategory" id="sow" [value]="'SOWs'" [(ngModel)]="templateMaintenance.templateCategoryCode">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="my-1">
                    <label for="other" class="custom-radio mr-1">Others
                        <input type="radio" name="templateCategory" id="other" [value]="'Others'" [(ngModel)]="templateMaintenance.templateCategoryCode">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>

              
              <div class="col-lg-1 col-md-5 my-md-2 my-lg-0 link-area text-right">
                <a class="link-text-blue d-block" (click)="saveTemplateMaintenance(templateMaintenance)">Save</a>
                <a class="link-text-blue mt-3 d-block" (click)="cancelSave(templateMaintenance.templatePackageId)">Cancel</a>
              </div>
            </div>
          </td>
          <td data-label="Template version:" class="t-sm-col"> {{templateMaintenance.templateVersion }}
          </td>          
          <td data-label="Service line:" class="t-lr-col"> {{ templateMaintenance.serviceLine }} </td>
          <td data-label="Type:" class="t-lr-col">{{ templateMaintenance.templateTypeName }} </td>
          <td data-label="Status:" class="t-md-col">{{ (templateMaintenance.status === true)? 'Active':'Inactive' }}
          </td>
          <td data-label="Action:" class="text-center t-sm-col">
            <a class="link-text-blue" *ngIf="!(selectedTemplatePackageId === templateMaintenance.templatePackageId)" (click)="expandRow(templateMaintenance.templatePackageId)">
              Edit
            </a>
          </td>
        </tr>
      </tbody>
      <div *ngIf="templateMaintenanceList === null || templateMaintenanceList.length === 0 ">
        <div class="no-data-available no-data">{{noDataMessage}}</div>
      </div>
    </table>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-noaccess',
  templateUrl: './noaccess.component.html',
  styleUrls: ['./noaccess.component.css']
})
export class NoaccessComponent implements OnInit {

  signout: boolean = false;

  constructor(private auth: AuthService, private route: Router) {
    this.redirectRouteLink();
   }

  ngOnInit() {  }

  redirectRouteLink() {
    const LocAddr = this.route.url.split('/');
    const routeName = LocAddr[LocAddr.length - 1];
    if (routeName  === 'signOut') {
      this.signout = true;
      this.auth.logout();
    }
  }
}

<div class="message-dialog-wrapper" *ngIf="isHidden">
  <div class="message-dialog-container">
    <div class="close-dialog" (click)="closeDialog()">
      <span class="d-inline-block align-middle">Close</span>
      <span class="d-inline-block ml-1 align-top">
        <img src="../../../../assets/images/EDP/MessageDialog/close-24px.svg" alt="Close">
      </span>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <div class="message-background-icon {{messageType}}">
        <img src="../../../../assets/images/EDP/MessageDialog/{{messageType}}.svg" class="message-icon"
          alt="{{messageType}}" />
      </div>
    </div>
    <div class="d-flex justify-content-center message-title {{messageType}} pt-2">{{messageTitle}}</div>

    <div class="message-description desc-height">
      {{messageDescription}}</div>

      <div class="message-description desc-height">
        <div class="row m-0">
          <div class="col-md-6 px-0">
            <label>Completion</label>
            <div class="selectdiv" style="font-size: 14px;">
              <select id="engCompletionTypeId" [(ngModel)]="engCompletionViewModel.engCompletionTypeId"
                (change)="changeCompletionType($event);" class="dropdown rounded-0 form-control" name="engCompletionTypeId">
                <option value="0" disabled hidden>Select type</option>
                <option *ngFor="let type of engCompletionTypes" [value]="type.value">
                  {{type.type}}
                </option>
              </select>
            </div>
            <div class="form-control-feedback error-color float-right "
              *ngIf="!engCompletionViewModel.isEngCompletionTypeSelected">
              <p class="mb-0">Type required</p>
            </div>
          </div>
          <div class="col-md-6 pr-0">
            <label>Completion date</label>
            <app-calendercontrol id="engCompletionDate" [txtDate]="engCompletionDt" class="eng-period-date">
            </app-calendercontrol>
            <div class="form-control-feedback error-color float-right"
              *ngIf="!engCompletionViewModel.isCompletionDateSelected">
              <p class="mb-0">Date required</p>
            </div>
          </div>          
        </div>
        <div class="form-control-feedback error-color mt-1" style="text-align: right"  *ngIf="engCompletionViewModel.isEngCompletionDateSame">
          <p class="m-0">Completion date should be different than existing date</p>
        </div>
      </div>

    <div class="d-flex pr-3 pt-3"
      [ngClass]="{true: 'justify-content-end', false: 'justify-content-center'}[(modalButtons==2)]"
      [ngSwitch]="modalButtons">
      <div *ngSwitchCase="'2'">
        <button (click)="Cancel()" class="btn SecondaryButton rounded-0 {{messageType}} mr-4">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/cancel-{{messageType}}-24px.svg" alt="CANCEL" />
          </span>
          <span class="d-inline-block align-middle">CANCEL</span>
        </button>
        <button (click)="updateEngCompletionDate()" class="btn rounded-0 {{messageType}}">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>     
    </div>
    <div class="form-control-feedback error-color d-flex pr-3 pt-2 justify-content-end"  style="font-size: 16px;" *ngIf="engCompletionViewModel.resultMessage.length > 0">
      <p>{{engCompletionViewModel.resultMessage}}</p>
    </div>
  </div>
</div>
  
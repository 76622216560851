import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

import { QuestionAnswer } from 'src/app/model/Workspace/QuestionAnswer';
import { HotDocsService } from 'src/app/services/HotDocsService';

@Component({
  selector: 'app-questionnaire-summary',
  templateUrl: './questionnaire-summary.component.html',
  styleUrls: ['./questionnaire-summary.component.css']
})
export class QuestionnaireSummaryComponent implements OnInit, OnChanges {

  @Input() workItemId: string;
  @Input() templatePackageVersionId: string;
  @Input() lastUpdatedBy: string;
  @Input() screen: string;
  questionAnswerList: QuestionAnswer[];
  showHeadingFlag: boolean;
  constructor(private hdService: HotDocsService) { }

  ngOnInit() {
    this.showHeadingFlag = false;
    console.log('ngOnInit Review Response', this.screen);
    if (this.screen === 'EngagementDetail') {
      this.showHeadingFlag = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.questionAnswerList = null;

    let updatedWorkItemId;
    let updatedTemplatePackageVersionId;

    for (const propName in changes) {
      const change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      const prevVal = JSON.stringify(change.previousValue);
      console.log('Review Response data elements:', this.workItemId, curVal);
      if (curVal !== prevVal) {
        curVal = curVal.replace(/['"]+/g, '');
        curVal = curVal.replace('null', '');
        if (propName === 'templatePackageVersionId') {
          updatedTemplatePackageVersionId = curVal;
        }
        if (propName === 'workItemId') {
          updatedWorkItemId = curVal;
        }
      }
    }

    this.getReviewResponse(updatedWorkItemId, updatedTemplatePackageVersionId);
  }

  getReviewResponse(workItemId: string, templatePackageVersionId: string) {
    this.hdService.getReviewResponse(workItemId, templatePackageVersionId).subscribe(
      result => {
        console.log('Result in getReviewResponse ', result);
        if (result === null) {
          this.questionAnswerList = [];
        } else {
          this.questionAnswerList = result;
        }
      }, error => {
        console.log('Error in getReviewResponse ', error);
        this.questionAnswerList = [];
      });
  }
}


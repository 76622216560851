import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Applicationmaster } from 'src/app/model/ApplicationMaster';
import { SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { ClientLevelDocument } from 'src/app/model/Client/ClientLevelDocument';
import { MembersList } from 'src/app/model/MembersList';
import { RoleNames } from 'src/app/model/Role';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { AdUsersService } from 'src/app/services/adusers.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { ClientService } from 'src/app/services/clientservice';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
declare var $;

@Component({
  selector: 'app-governing-agreement-actions',
  templateUrl: './governing-agreement-actions.component.html',
  styleUrls: ['./governing-agreement-actions.component.css']
})
export class GoverningAgreementActionsComponent implements OnInit {

  currentClientId: number = 0;
  selectedClientLevelDocId: number = 0;
  updatedList: ClientLevelDocument[] =[];
  isEditMode: boolean = false;
  selectedClientLevelDocument: ClientLevelDocument = new ClientLevelDocument();
  updatedClientLevelDocument: ClientLevelDocument = new ClientLevelDocument();
  currentParentComponentName: string = '';
  subDocumentTypes: Applicationmaster[] = [];
  isEngagementParent: boolean = false;
  msaEffectiveDateCntrlIdAtChild: string = "msaEffectiveDate";
  msaExpirationDateCntrlIdAtChild: string = "msaExpirationDate";
  controlPopoverId: string;
  ContactEmailId: string;

  memberFirmFlag: boolean;

  @Input() set isMemberFirm(flag: boolean){
    console.log("check the value of child isMemberFirm",flag);
    this.memberFirmFlag=flag;
  }

  GTAgreementOwnerRoleNameAtChild: string = RoleNames.GTAgreementOwner;
  ADUsersList: any;

  GTAgreementOwnerlblCtrlNameAtChild: string = 'GT agreement owner';HdnNameCtrl: string;
  HdnNameCtrlName: string;
  HdnEmailCtrlName: string;
  HdnPhoneCtrlName: string;
  HdnValidInputCtrlName: string;
  currentPeoplePickerIndexAtChild: number;
  validateGTAgreementOwnerAtChild: boolean;
  selectedGTAgreementOwnerIndexAtChild: number;
  GTAgreementOwnerNameCtrlAtChild: string;
  isvalidGTAgreementOwnerAtChild: boolean = true;
  GTAgreementOwnerCtrlAtChild: string;
  peoplepickerIndexAtChild: number;
  isTemplateDisabledAtChild: boolean = true;
  isInvalidSubDocTypeAtChild: boolean;
  isInvalidMSAExpirationDateAtChild: boolean;
  isEffectiveDateEmptyAtChild: boolean;
  isExpriationDateEmptyAtChild: boolean;
  isInvalidMSAEffectiveDateAtChild: boolean;
  templateListAtChild: TemplatePackageModel[] = [];
  peoplePickerIndexTest: number;
  callingChildClientId: number;
  callingChildClientLevelId: number;
  callingPopupId: number = 0;
  selectedGoverningAgreementAction: string;
  isvalidEFDChild: boolean = true;
  isvalidEXDChild: boolean = true;
  @Input() set parentComponentName(name: string) {
    if (name != undefined && name !== null && name != '') {
      this.currentParentComponentName = name;
      if(this.currentParentComponentName ==='InitiateEngagement') {
        this.isEngagementParent = true;
      } else {
        this.isEngagementParent = false;
      }
    }
  }
  @Input() set clientLevelDocument(clientLevelDocument: ClientLevelDocument) {
    if (clientLevelDocument != undefined && clientLevelDocument !== null && clientLevelDocument.clientId > 0) {
      this.currentClientId = clientLevelDocument.clientId;
      this.selectedClientLevelDocId = clientLevelDocument.clientLevelDocumentId;
      this.selectedClientLevelDocument = clientLevelDocument;
      this.msaExpirationDateCntrlIdAtChild = this.msaExpirationDateCntrlIdAtChild+'_'+this.selectedClientLevelDocId;
      this.msaEffectiveDateCntrlIdAtChild = this.msaEffectiveDateCntrlIdAtChild+'_'+this.selectedClientLevelDocId;
      this.peoplepickerIndexAtChild = this.selectedClientLevelDocId;
    }
  }
  @Output() UpdatedClientLevelDocList: EventEmitter<ClientLevelDocument[]> = new EventEmitter<ClientLevelDocument[]>();
  @Output() SelectedGoverningAgreementDetails: EventEmitter<ClientLevelDocument> = new EventEmitter<ClientLevelDocument>();
  constructor( 
    private loadingScreenService: LoadingScreenService,
    private clientService: ClientService,
    private _alertService: AlertsService,
    private applicationMasterService: ApplicationMasterService,
    private _hdService: HotDocsService,
    private adUsersService: AdUsersService) {
     }

  ngOnInit() {
    
  }
  GetAgreementTypes() {
    this.applicationMasterService.GetSubDocumentTypes().subscribe(result => {
      this.subDocumentTypes = result;
      if(this.isEngagementParent) {
        //remove custom msa only if its not created at eng While displaying at Engagment screen
        if(this.selectedClientLevelDocument.documentSubTypeCode != 'CMSA') {
        this.subDocumentTypes.splice(this.subDocumentTypes.findIndex(x => x.code === 'CMSA'), 1);
        } 
      } else {
        this._hdService.getTemplatePackages().subscribe(templateLists => {
        this.templateListAtChild = templateLists;
        },
          error => {
            this.loadingScreenService.stopLoading();
            this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
          });
      }
      this.SetAgreementData();
    },
      err => {
        this._alertService.add('Failed to load subDocumentTypes', 'danger', null);
      })
  }
  ProcessAction(actionName: string){
    if(actionName === 'Remove'){
      this.isEditMode = false;
      this.DeleteClientLevelDocument();
    } else  if(actionName === 'Edit'){
      this.isEditMode = true;
      this.isvalidEFDChild = true;
      this.isvalidEXDChild = true;
      $('#agreementDescription_'+this.currentParentComponentName+'_'+this.selectedClientLevelDocId).fadeOut(100);
      this.GetAgreementTypes();
    }
    this.selectedGoverningAgreementAction = actionName;
  }
  DeleteClientLevelDocument(){
    this.loadingScreenService.startLoading();
    if(this.selectedClientLevelDocId > 0){
    this.clientService.DeleteClientLevelDocument(this.selectedClientLevelDocId).subscribe(deleteResult =>{
      if(deleteResult === 'Success'){
        console.log(deleteResult);
       this.updatedList = [];
      }
      this.GetClientLevelDocumentsAtChild();
    },
    error => {
      this.loadingScreenService.stopLoading();
      this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
    });
  }
  }
  GetClientLevelDocumentsAtChild(){
    this.clientService.GetClientLevelDocuments(this.currentClientId).subscribe(
      result => {
        this.updatedList = result;
        this.UpdatedClientLevelDocList.emit(this.updatedList);
        if(this.currentParentComponentName ==='InitiateEngagement'){
          this.updatedClientLevelDocument.selectedActionName = this.selectedGoverningAgreementAction;
          this.SelectedGoverningAgreementDetails.emit(this.updatedClientLevelDocument);
        }
        
        this.loadingScreenService.stopLoading();
      }, error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  GetDateFromString(date) {
    //format date to  mm/dd/yy
    let strDateArray = date.split('/');
    let dateObject = new Date(strDateArray[2], parseInt(strDateArray[0]) - 1, strDateArray[1]);
    return dateObject;
  }
  formatDate(date: any , id: string) {
      const formattedDate = new Date(date);
      const d = formattedDate.getDate();
      let m = formattedDate.getMonth();
      m += 1;  // JavaScript months are 0-11
      const y = formattedDate.getFullYear();
      const finalFormattedDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
        $('.'+ id).val(finalFormattedDate);
  }

  SetAgreementData(){
    this.formatDate(this.selectedClientLevelDocument.effectiveDate, this.msaEffectiveDateCntrlIdAtChild);
    if (this.selectedClientLevelDocument.documentSubTypeId > 0) {
      let selectedSubDocumentType = this.subDocumentTypes.filter(documentType => documentType.applicationMasterId == this.selectedClientLevelDocument.documentSubTypeId)[0];
      if (selectedSubDocumentType.code != 'CMSA') {
        this.isTemplateDisabledAtChild = true;
        this.selectedClientLevelDocument.templatePackageId = undefined;
      }
      else {
        this.isTemplateDisabledAtChild = false;
      }
    }
    if(this.selectedClientLevelDocument.expirationDate != null){
      this.formatDate(this.selectedClientLevelDocument.expirationDate, this.msaExpirationDateCntrlIdAtChild);
    } else {
      $('.'+ this.msaExpirationDateCntrlIdAtChild).val('');
    }
     if(this.selectedClientLevelDocument.gtAgreementOwnerName != '' && this.selectedClientLevelDocument.gtAgreementOwnerName != null &&
        this.selectedClientLevelDocument.gtAgreementOwnerEmail != '' && this.selectedClientLevelDocument.gtAgreementOwnerEmail != null ){
        this.validateGTAgreementOwnerAtChild = true; 
        $('#txtPeoplePicker_AO_'+this.peoplepickerIndexAtChild).val(this.selectedClientLevelDocument.gtAgreementOwnerName);
       // $('#UserHiddenProps_AO_'+this.peoplepickerIndexAtChild +' > #hdnUserEmail').val(this.selectedClientLevelDocument.gtAgreementOwnerEmail); 
      } else {
        this.validateGTAgreementOwnerAtChild = false;
        $('#txtPeoplePicker_AO_'+this.peoplepickerIndexAtChild).val('');
        $('#UserHiddenProps_AO_'+this.peoplepickerIndexAtChild +' > #hdnUserEmail').val('');
        this.selectedClientLevelDocument.gtAgreementOwnerName = '';
        this.selectedClientLevelDocument.gtAgreementOwnerEmail ='';
      }
      this.updatedClientLevelDocument = this.selectedClientLevelDocument;
  }
  CancelEdit(){
    this.isEditMode = false;
    $('#agreementDescription_'+this.currentParentComponentName+'_'+this.selectedClientLevelDocId).show(100);
  }
  UpdateClientLevelDocument(){
    this.isvalidEFDChild = true;
    this.isvalidEXDChild = true;
    let effectiveDate = $('.' + this.msaEffectiveDateCntrlIdAtChild).val();
    let expirationDate = $('.' + this.msaExpirationDateCntrlIdAtChild).val();
    this.validateDate(effectiveDate,'EFDC');
    this.validateDate(expirationDate,'EXDC');
    this.SetUpdatedData();
    if(this.ValidateSubDocumentTypeSection() && this.isvalidEXDChild && this.isvalidEFDChild){
      this.loadingScreenService.startLoading();
      this.clientService.UpdateClientLevelDocument(this.updatedClientLevelDocument).subscribe(
        updateResult =>{
          this.isEditMode = false;
          this.GetClientLevelDocumentsAtChild();
      },
      error =>{
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
    }

  }

  validateDate(date: any, dateName: string) 
  {
    let isSpace = false;
    console.log("passed date is ====="+date);
    if(date === ""){isSpace = true;}else{isSpace = false;}
    let date1 = new Date(date);
    if(isNaN(date1.getTime()) && !isSpace) {
      if(dateName === 'EFDC'){
        this.isvalidEFDChild = false;
      }
      if(dateName === 'EXDC'){
        this.isvalidEXDChild = false;
      }
    }
  }


  SetUpdatedData(){
    if (this.updatedClientLevelDocument.documentSubTypeId > 0) {
      let selectedSubDocumentType = this.subDocumentTypes.filter(documentType => documentType.applicationMasterId == this.updatedClientLevelDocument.documentSubTypeId)[0];
      this.updatedClientLevelDocument.documentSubTypeCode = selectedSubDocumentType.code;
      this.updatedClientLevelDocument.documentSubTypeValue = selectedSubDocumentType.value;
      this.updatedClientLevelDocument.documentSubTypeId = selectedSubDocumentType.applicationMasterId;

      if (selectedSubDocumentType.code != 'CMSA') {
        this.isTemplateDisabledAtChild = true;
        this.updatedClientLevelDocument.templatePackageId = undefined;
        this.updatedClientLevelDocument.templateName ='';
        this.updatedClientLevelDocument.templateVersion = 0;
      }
      else {
        this.isTemplateDisabledAtChild = false;
        this.templateListAtChild.forEach(temp => {
          if (temp.templatePackageId === this.updatedClientLevelDocument.templatePackageId) {
            this.updatedClientLevelDocument.templateName = temp.name;
          }
        });
        if(this.isEngagementParent && this.updatedClientLevelDocument.engLevelCustonMSA){
          this.updatedClientLevelDocument.templatePackageId = this.selectedClientLevelDocument.templatePackageId;
          this.updatedClientLevelDocument.templateName = this.selectedClientLevelDocument.templateName;
        }
      }
    }
   
    let effectiveDate = $('.' + this.msaEffectiveDateCntrlIdAtChild).val();
    this.updatedClientLevelDocument.effectiveDate = this.GetDateFromString(effectiveDate);
    let expirationDate = $('.' + this.msaExpirationDateCntrlIdAtChild).val();
    this.updatedClientLevelDocument.expirationDate = this.GetDateFromString(expirationDate);

    this.updatedClientLevelDocument.gtAgreementOwnerName =  $('#txtPeoplePicker_AO_'+ this.peoplepickerIndexAtChild).val();
    this.updatedClientLevelDocument.gtAgreementOwnerEmail = $('#UserHiddenProps_AO_'+ this.peoplepickerIndexAtChild +' > #hdnUserEmail').val();  

  }
  ValidateSubDocumentTypeSection() {
    let status = true;
    this.isInvalidSubDocTypeAtChild = false;
    this.isInvalidMSAExpirationDateAtChild = false;
    this.isvalidGTAgreementOwnerAtChild = true;
      this.isEffectiveDateEmptyAtChild = $('.' + this.msaEffectiveDateCntrlIdAtChild).val() === '';
      this.isExpriationDateEmptyAtChild = $('.' + this.msaExpirationDateCntrlIdAtChild).val() === '';
      if (!this.isEffectiveDateEmptyAtChild) {
        this.ValidateMSAEffectiveDate();
      }
      if (!this.isExpriationDateEmptyAtChild) {
        this.ValidateMSAExpirationDate();
      }
      if (this.updatedClientLevelDocument.documentSubTypeId == undefined || this.updatedClientLevelDocument.documentSubTypeId <= 0) {
        this.isInvalidSubDocTypeAtChild = true;
      }
      let validName = this.updatedClientLevelDocument.gtAgreementOwnerName != '' && this.updatedClientLevelDocument.gtAgreementOwnerName != null;
      let blankEmail = this.updatedClientLevelDocument.gtAgreementOwnerEmail == '' || this.updatedClientLevelDocument.gtAgreementOwnerEmail == null;
      let validEmail = this.updatedClientLevelDocument.gtAgreementOwnerEmail != '' && this.updatedClientLevelDocument.gtAgreementOwnerEmail != null;
      let validInput = Boolean(JSON.parse($('#hdnValidInput_AO_'+this.selectedClientLevelDocId).val()));
      if((validName && blankEmail) || 
        (validName && validEmail && !validInput) || 
        (validName && !validEmail)){
          this.isvalidGTAgreementOwnerAtChild = false;
          this.updatedClientLevelDocument.gtAgreementOwnerEmail = '';
      }
     
     status = !this.isInvalidSubDocTypeAtChild && !this.isEffectiveDateEmptyAtChild && !this.isInvalidMSAEffectiveDateAtChild && !this.isInvalidMSAExpirationDateAtChild && this.isvalidGTAgreementOwnerAtChild;

    return status;
  }

  ValidateMSAEffectiveDate() {
    this.isInvalidMSAEffectiveDateAtChild = this.updatedClientLevelDocument.effectiveDate > this.updatedClientLevelDocument.expirationDate;
  }

  ValidateMSAExpirationDate() {
    let rawDate = new Date();
    let today = new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate());
    this.isInvalidMSAExpirationDateAtChild = (this.updatedClientLevelDocument.expirationDate < today || this.updatedClientLevelDocument.expirationDate < this.selectedClientLevelDocument.effectiveDate);
  }
  displayContactPopoverAtChild(emailId: string, popoverControlId: string) {
    this.ContactEmailId = emailId;
    this.controlPopoverId = popoverControlId;
    this.hidePopUpAtChild();
    this.bindContactPopupAtChild();
    this.checkForContactContentAtChild('contact-popup');
    $('#' + this.controlPopoverId).popover('show');
  }
  checkForContactContentAtChild(contact) {
    const timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContentAtChild(contact);
      }
    }, 900);
  }
  hidePopUpAtChild() {
    $('.contactUser').popover('hide');
  }

  bindContactPopupAtChild() {
    $('.contactUser').popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',    
      boundary: 'viewport',
      content: function () {
        return '<div class="mx-auto" style="width: 200px;">Loading ...</div>';
      }
    });
  }
  ngAfterViewInit() {
    $('html').on('click', function (e) {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        $('.contactUser').popover('hide');
      }
    });

    $('body').on('click', (e) => {
      //did not click a popover toggle or popover
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          $('.contactUser').popover('hide');

        }
      }
    });
  }
  setGTAgreementOwnerAtChild() {
    let index = this.selectedGTAgreementOwnerIndexAtChild;
    if (Number(index) >= 0) {
      const selectedGTAgreementOwnerNameatChild = $('#spGTAgreementOwnerdisplayNameAtChild' + index).text();
      const selectedGTAgreementOwnerEmailatChild = $('#spGTAgreementOwnermailAtChild' + index).text();
      const selectedEGTAgreementOwnerPhoneatChild = $('#spGTAgreementOwnerbusinessPhonesAtChild' + index).text();
      this.updatedClientLevelDocument.gtAgreementOwnerEmail = selectedGTAgreementOwnerEmailatChild;
      this.updatedClientLevelDocument.gtAgreementOwnerName = selectedGTAgreementOwnerNameatChild;
      this.validateGTAgreementOwnerAtChild = true;
      $("#GTAgreementOwnerErrorMessage_"+this.callingPopupId).text('');
      if (this.GTAgreementOwnerCtrlAtChild !== '') {
        $('#'+this.GTAgreementOwnerCtrlAtChild).val(selectedGTAgreementOwnerNameatChild);
        $('#'+this.HdnNameCtrlName).val(selectedGTAgreementOwnerNameatChild);
        $('#'+this.HdnEmailCtrlName).val(selectedGTAgreementOwnerNameatChild);
        $('#'+this.HdnEmailCtrlName).val(selectedGTAgreementOwnerEmailatChild);
        $('#'+this.HdnPhoneCtrlName).val(selectedEGTAgreementOwnerPhoneatChild);
        $('#'+this.HdnValidInputCtrlName).val(true);
      }

      if (this.GTAgreementOwnerCtrlAtChild !== '') {
        this.updatedClientLevelDocument.gtAgreementOwnerName = selectedGTAgreementOwnerNameatChild;
        this.updatedClientLevelDocument.gtAgreementOwnerEmail = selectedGTAgreementOwnerEmailatChild;
      }
    }
    this.ADUsersList = null;
    this.callingPopupId = 0;
  }
  searchPartnerNamesAtChild(searchControlName: string) {
    const searchText = $('#' + searchControlName+'_'+this.callingPopupId).val();
    this.GetRelationshipPartnersFromADAtChild(searchText);
  }

  GetRelationshipPartnersFromADAtChild(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.ADUsersList = userList;
        },
        (error) => {
          this.ADUsersList = null;
          this.callingPopupId= 0;
          this.loadingScreenService.stopLoading();
          console.log('Error in getting response GetRelationshipPartners func ', error);
        });
  }
  deselectGTAgreementOwnerAtChild() {
    this.callingPopupId = 0;
    $('#' + this.GTAgreementOwnerNameCtrlAtChild).val('');
    $('#' + this.GTAgreementOwnerNameCtrlAtChild).innerText = '';
    $('#' + this.GTAgreementOwnerCtrlAtChild).val('');
    $('#' + this.GTAgreementOwnerCtrlAtChild).innerText = '';
  }
  selectGTAgreementOwnerAtChild(index) {
    $('#tblGTAgreementOwnerAtChild_'+this.selectedClientLevelDocId).on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedGTAgreementOwnerIndexAtChild = index;
    console.log('selectGTAgreementOwnerAtChild func called ', this.selectedGTAgreementOwnerIndexAtChild);
  }
  removeRowSelectionAtChild(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $("#" + errorMessageId).text('');
    var searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }
  refreshAgreementOwnerPopupAtChild(index: number,textboxName: string) {
     $('#modal-Child-GTAgreementOwner_'+index).modal({   
      backdrop: 'static',
      keyboard: false  // to prevent closing with Esc button (if you want this too)
    });
    this.removeRowSelectionAtChild('tblGTAgreementOwnerAtChild_'+index, 'searchGTAgreementOwnerNameAtChild_'+index, 'GTAgreementOwnerErrorMessage');
    this.GTAgreementOwnerCtrlAtChild = textboxName + this.GTAgreementOwnerRoleNameAtChild + '_' +  index;
    this.HdnNameCtrlName ='UserHiddenProps_AO_'+ index +' > #hdnUserName';
    this.HdnEmailCtrlName = 'UserHiddenProps_AO_'+ index +' > #hdnUserEmail';
    this.HdnPhoneCtrlName = 'UserHiddenProps_AO_'+ index +' > #hdnUserPhone';
    this.HdnValidInputCtrlName = 'hdnValidInput_AO_'+ index;
    this.validateGTAgreementOwnerAtChild = false;
    this.callingPopupId = index;
    this.ADUsersList = null;
    if ($('#' + this.GTAgreementOwnerCtrlAtChild).hasClass('text-danger')) {
      $('#' + this.GTAgreementOwnerCtrlAtChild).removeClass('text-danger');
    }
  }
}//end of component

<div id="sidebar">
  <div>

    <!-- Start I-want-to  section -->
    <div class="divIwantto display-large IwanttoDiv" id="IwanttoDiv">
      <!-- <label class="lblIwanttoLabel">I want to....</label><br> -->
      <!-- <button class="btnIwanttoHeading" routerLink="/NewEngagement" routerLinkActive="active"
        (click)="removeActiveMenuStyle('imgReplace');">{{item.text}}</button> -->
      <!-- <div class="selectdiv">
          <select class="dropdown rounded-0" [(ngModel)]="selectedValue" (change)="selected()">            
            <option *ngFor="let item of iWantToActionList" [ngValue]="item.tileId">
              {{item.text}}</option>
          </select>
        </div> -->
      <div class="selectdiv">
        <div class="select">
        <!-- <button class="btn" routerLink="/NewEngagement" type="button">
          Create new engagement
        </button> -->
        <button type="button" class="btn btn-lg"
          data-toggle="dropdown" aria-expanded="false">
          I want to...
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" style="border-left: 1px solid #dedde0; border-right: 1px solid #dedde0; " *ngFor="let item of iWantToActionList" (click)="selected(item)">{{item.text}}</a>
        </div>
      </div>
      </div>
    </div>
    <!-- End I-want-to section -->

    <!-- i wantto box -->
    <div class="iWantToParent display-large display-medium IwanttoDiv">
      <div class="row m-0">
        <div class="col-md-1 divImgIwanTo">
          <img class='imgIwantTo' src="/assets/images/EDP/LeftSidebar/how_to_reg-24px.svg" data-toggle="toggle"
            data-target="#iwantto" alt="">
        </div>
        <div id="iwantto" class="">
          <div class="divIwantto">
            <!-- <label class="lblIwanttoLabel float-left">I want to....</label> -->
            <!-- <button class="btnIwanttoHeading" routerLink="/NewEngagement" routerLinkActive="active">
              {{item.text}} </button> -->
            <!-- <div class="selectdiv w-75 mt-2 pl-2 mr-2 float-right">
              <select class="dropdown rounded-0" [(ngModel)]="selectedValue" (change)="selected()">                
                <option *ngFor="let item of iWantToActionList" [ngValue]="item.tileId">
                  {{item.text}}</option>
              </select>
            </div> -->
            <div class="selectdiv w-75 mt-2 mb-2 pl-0 mr-2 float-right">
              <div class="select">
              <!-- <button class="btn" routerLink="/NewEngagement" type="button">
                Create new engagement
              </button> -->
              <button type="button" class="btn btn-lg"
                data-toggle="dropdown" aria-expanded="false">
                I want to....
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" style="border-left: 1px solid #dedde0; border-right: 1px solid #dedde0; " *ngFor="let item of iWantToActionList" (click)="selected(item)">{{item.text}}</a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- start nav -->
  <div id="sideMenuParent">
    <div class="tabbable tabs-left">
      <ul class="nav nav-tabs flex-column">
        <li *ngFor="let menu of cardlistmodel; let i = index" id="sideMenuItem" routerLinkActive="active" class=""
          (click)="adjustImagePath($event,i,menu.navigationId)">
          <a routerLink="/{{menu.navigationId}}" class="nav-link" data-toggle="tab">
            <img class="imgchange{{i}}" src="{{menu.imagePath}}" alt="{{menu.cardName}}" />
            <span class="cardMenuName cardMenuNameHide"> {{menu.cardName}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- end nav -->
  <!-- footer start -->
  <footer class="footer display-large collapse-copy-large">
    <div class="d-flex flex-column justify-content-center">
      <a href="https://gtus365.sharepoint.com/sites/canvas/SitePages/office-365-acceptable-use-reminder.aspx"
        class="acceptable-link" target="_blank" rel="noopener noreferrer">Acceptable Use</a>
      <div class="mt-1">&copy; {{currentYear}} Grant Thornton Advisors LLC</div>
    </div>
  </footer>

  <footer class="footer display-large display-medium collapse-copy-small">
    <h2 class="copyright">&copy;</h2>
  </footer>
  <!-- footer end -->
</div>
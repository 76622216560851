export class InitiateNewEngagement {
    reacceptanceEngagementId:number
    newEngagementId:number
    newEngagementName:string
    userName:string
}

export enum ContinuanceDialogTitles {
    InitiateContinuanceEngagement = 'Initiate continuance engagement',
    UpdatedSolutioncCode = 'Update solution code',
    RemoveContinuance = 'Remove continuance',
    ClosedClientContinuance = 'Closed client'
}
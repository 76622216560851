import { ASQMemberModel } from "../AzureADUsersArrayList";

export class EngagementDueDateExtension {
    extentionId: number;
    engagementId: number;
    extensionDueDate: Date;
    requestedOn: Date;
    requestedByName: string
    requestedByEmail: string;
    extensionReason: string;
    status: string;
    denyReason: string;
    approveDenyDate: Date;
    approveDenyByName: string;
    approveDenyByEmail: string;
    isActive: boolean;
}

export class ApproveDenyExtensionModal {
    extentionId: number;
    engagementId: number;        
    denyReason: string;    
    approveDenyByName: string;
    approveDenyByEmail: string;
    status: string;
    isAutoDenied:boolean;
}

export class ApproveDenyExtensionViewModal {
    approveDenyExtensionModal: ApproveDenyExtensionModal
    isValidDenyReason: boolean = true;
}

export class DueDateExtensionRequestModal
    {
         engagementId: number; 
         extensionDueDate: Date; 
         requestedByName: string; 
         requestedByEmail: string; 
         extensionReason: string;  
         status: string;
         aSQMemberModel:ASQMemberModel;
    }
   
<table id="DocumentDataId" class="table DataList no-footer hide" aria-describedby="My Reviews">
  <thead>
    <tr>
      <th scope="col">Document Name</th>
      <th scope="col">Document Type</th>
      <th scope="col">Status</th>
      <th scope="col">Total Review Points</th>
      <th scope="col">Date Assigned</th>
      <th scope="col" class="customWidth"></th>
    </tr>
  </thead>
  <tbody class="display" *ngIf="myReviewsDocumentList?.length != 0">
    <tr class="searchDatajs" *ngFor="let list of myReviewsDocumentList">
      <td><a>{{list.documentName}}</a>
      </td>
      <td><a> {{list.documentType}} </a></td>
      <td>{{list.status}}</td>
      <td> {{list.openReviews}} </td>
      <td>{{list.createdon | date:"MM/dd/yyyy"}}</td>
      <td class="customWidth">
        <div *ngIf="list.status == 'Not started' || list.status == null">
          <a href="/StaticQuestions/{{list.workspaceId}}" class="link">Continue Workspace
            <img alt="Continue" src="../../../../assets/images/Document/arrow_card-view.png" /></a>
        </div>
        <div *ngIf="list.status != 'Draft' && list.status != 'Not started' && list.status != null">
          <a href="/Review/{{list.workspaceId}}" class="link">Go To Workspace
            <img class="GoToWorkSpaceImg" src="../../../../assets/images/listview/GoToWorkspace_Arrow.png"
              alt="Workspace" />&nbsp;
          </a>
        </div>
        <div *ngIf="list.status == 'Draft' ">
          <a id="{{list.workspaceId}}"
            onClick="document.getElementById('HiddenWorkspaceIdonDocListPopup').value = this.id;"
            class="{{list.workspaceId}} showInterviewLink">Show Interviews
          </a> <img alt="Show" src="../../../../assets/images/Document/arrow_card-view.png" />
        </div>
      </td>
    </tr>
  </tbody>
</table>
<input type="text" class="hide" id="HiddenWorkspaceIdonDocListPopup">

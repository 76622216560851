import { UsersDetails } from "../SharePoiint/ActiveDirectory";
export class DelegateApproval {
    memberDelegationId: number;
    beginningDate: string;
    endingDate: string;
    delegateFromName: string;
    delegateFromEmailId: string;
    delegateToName: string;
    delegateToEmailId: string;
    createdBy: string;
    timeEvent: number;
    isDisabled: boolean;
}

export class DelegateNotification {
    delegateApproval: DelegateApproval;
    isTriggerNotification: boolean;
    usersDetails: UsersDetails[] = [];
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from './appConfigService';
import { CMSClient } from 'src/app/model/Client/NewClient';
import { AssignmentRequestList } from '../model/Workspace/AssignmentRequestList';
import { AssignmentResponse } from '../model/Workspace/AssignmentResponse';

@Injectable({
  providedIn: 'root'
})
export class CMSService {

  IntegrationAPIEndPoint: string;
  constructor(private environment: AppConfigService, private http: HttpClient) {
    this.IntegrationAPIEndPoint = environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  GetClientByNameFromCMS(searchTerm: string): Observable<CMSClient[]> {
    return this.http.get<CMSClient[]>(this.IntegrationAPIEndPoint + '/CMS/' + searchTerm);
  }

  PostPrimaryAssignment(list: AssignmentRequestList): Observable<AssignmentResponse[]> {
    return this.http.post<AssignmentResponse[]>(this.IntegrationAPIEndPoint + '/CMS/PrimaryAssignment', list);
  }
}

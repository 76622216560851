<app-loading-screen></app-loading-screen>

<div class="containerDiv">
  <!-- Page Title row -->
  <div class="d-flex justify-content-between pageTitleParent pb-3 mw-100">
    <div class="text-center">
      <h2 class=" float-md-left pageTitle largerFontSize2">Process audit engagement</h2>
    </div>
    <div class="text-center">
      <span class="float-md-right">
        <app-shortcut></app-shortcut>
      </span>
    </div>
  </div>
  <!-- End Page Title row -->

  <div class="row py-3">
    <div class="col-md-6">
      <label for="engagmentId">Engagement Id </label>
      <input type="text" id="engagmentId" class="form-control rounded-0 border engagement-name input-height"
         placeholder="{{placeholder}}" (focus)="this.placeholder=''"
        (blur)="this.placeholder = 'Enter engagement Id'" name="engagmentId" [(ngModel)]="engagementId"
        autocomplete="off" />
    </div>
    <div class="col-md-3 padding-left-0">
      <div class="">
        <div class="">
          <div><label>&nbsp;</label></div>
          <button id="btnProcessAuditEngagementRequest" class="btn PrimaryButton rounded-0 w-100 input-height"
            type="button" (click)="processAuditEngagementRequest()">
            Process
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngFor="let alert of alerts;let id=index;">
  <div class="alert alert-{{alert.messageType | lowercase}} alert-dismissible fade show" role="alert">
    <span class="msg-icon">
      <img *ngIf="!alert.isSuccess" src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="warning">
      <img *ngIf="alert.isSuccess" src="assets/images/EDP/AuditEngagement/audit-engagement-process-success.png" alt="success">
    </span>
    <span class="d-inline-block align-middle msg-text">
     {{alert.engagementId}}  - {{alert.message}}
    </span>
  </div>
</div>
import { Role } from './Role';

export class User {
    userName: string;  // Unique Identifier as Email Id
    firstName: string;
    lastName: string;
    loggedInUserFullName: string;
    loggedInUserEmail: string;
    role: Role[];
    activeDirectoryGroups: string[];
    businessPhones: string;
    cachedDateTime: string;
    jobTitle: string;
    officeLocation: string;
    lineOfBusiness: string;
    lineOfBusiness_sharepoint: string;
    employeeId: string;
    userLanguage: string;
    serviceLine: string
    account: string;
    id: string;
    userType: string;
    userPrincipalName: string;
    mobilePhone: string;
    OnPremisesExtensionAttributes: string;
    department: string;
    preferredLanguage: string;
    givenName: string;
    surname: string;
    accountEnabled: string;
    memberFirmADGroup: string;
    memberFirmTeamMemberShortRoleName: string;
    memberFirmIndusConsentShortRoleName: string;
    memberFirmCountry: string;
    isMemberFirmFeatureEnabled:boolean;
    isAllowedAccess:boolean
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-document-saved-popup',
  templateUrl: './export-document-saved-popup.component.html',
  styleUrls: ['./export-document-saved-popup.component.css']
})
export class ExportDocumentSavedPopupComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() ok = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  okClicked() {
    this.ok.emit();
  }
  cancelClicked(event) {
    this.close.emit(event);
  }

}

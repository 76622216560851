export class TeamMember {
  id: number;

  workspaceId: string;

  isReviewer: boolean;

  memberName: string;

  roleId: number;

  createdby: string;

  createdon: Date;

  updatedBy: string;

  updatedon: Date;

  teamMemberRole: number;

  memberEmailId: string;

  memeberContactNo: string;

  approvalStatus: string;

  roleName: string;

  loggedInUserFullName: string;

  teamMemberPermissionId: number;

  oLDreviewerName: string;

  collaborationSiteId: number;
  memberType: string;
  memberRoleName: string;
  memberRoleDescription: string;
  isNotified?: boolean;
  engageWorkflowStage: string;
  notificationTrigger: string;
  isKeyTeamMember: boolean;
  keyTeamMemberRoleId: number;
  description: string;
}

export class AssignmentTeamMember  {
  teamMemberList: TeamMember[];
  assignmentName: string;
  solutionCodeName: string;
  isAssignmentChecked: boolean;
  isTeamDetailsVisible: boolean = false;
}


export class ValidationTeamMember {
  memberEmailId: string;
  memberName: string;
  teamMemberRole: string;
  memeberContactNo: string;
  approvalStatus: string;
  teamMemberPermissionId: number;
  ishdnValidInputName: boolean;
  isUserExist: boolean;
  isNewUserDuplicate: boolean;
  index: number;
}


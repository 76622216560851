import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appConfigService';
import { Observable } from 'rxjs';
import { StateMaster } from '../model/StateMaster';
@Injectable({
  providedIn: 'root'
})
export class StateService {
  APIEndPoint: string;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
  }

  GetStateMasters(countryId: number): Observable<StateMaster[]> {
    return this.http.get<StateMaster[]>(this.APIEndPoint + '/client/states/' + countryId);
  }
}

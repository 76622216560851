import { Component, OnInit } from '@angular/core';
import { KeyValue } from 'src/app/model/KeyValue';
import { NavigationRoleGridModel } from 'src/app/model/Navigation/NavigationRoleGridModel';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { DocumentTypeListModel } from 'src/app/model/DocumentTypeListModel';
import { NavigationRoleViewModel } from 'src/app/model/Navigation/NavigationRoleViewModel';
import { Alert } from 'src/app/model/Alert';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuthService } from 'src/app/services/auth.service';
declare var $;
@Component({
  selector: 'app-shortcuts-configuration',
  templateUrl: './shortcuts-configuration.component.html',
  styleUrls: ['./shortcuts-configuration.component.css'],
  providers: [ConfigurationService, HotDocsService]
})
export class ShortcutsConfigurationComponent implements OnInit {
  selectedNavigationId: number;
  sequenceList: number[];
  roleList: KeyValue[] = [];

  navigationBasedRoleList: NavigationRoleGridModel[] = [];
  cardTypeList: KeyValue[] = [];
  cardList: KeyValue[] = [];
  roles: number[] = [];
  TemplateList: TemplatePackageModel[] = [];
  DocumentTypeList: DocumentTypeListModel;
  model = new NavigationRoleViewModel(0, 1, '', '', '', null, this.roles);
  totalRec: number;
  alertClient: Alert;

  isRoleValidationDisplayed: boolean = false;
  isDisplayConfiguration: boolean = false;
  userName = '';
  isUserAgentIE: number;
  staticImageList: string[] = [];

  constructor(private _configService: ConfigurationService, private _hdService: HotDocsService,
    private _alertService: AlertsService, private auth: AuthService) {

    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);
  }

  ngOnInit() {
    this.sequenceList = this.range(1, 15);
    this.getRoleList();
    this.getNavigationBasedRoleList();
  }
  getRoleList() {
    this._configService.getRolesList().subscribe(result => {
      this.roleList = result;
    }
      ,
      err => console.log('Error in fetching data from getRolesList in configuration service.'));
  }


  checkSpaceChartBegin(event) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      this.model.title = null;
      return false;
    } else { return true; }
  }

  range(start, end) {
    const numsequence = [];
    for (let i = start; i <= end; i++) {
      numsequence.push(i);
    }
    return numsequence;
  }
  getNavigationBasedRoleList() {

    if ($.fn.dataTable.isDataTable('#NavigationRoleTable')) {
      $('#NavigationRoleTable').DataTable().destroy();
    }

    this._configService.getNavigationBasedRoleList().subscribe(result => {
      this.navigationBasedRoleList = result;

      setTimeout(() => {
        $("#NavigationRoleTable").DataTable({
          "iDisplayLength": 5,
          "columnDefs": [
            { "orderable": false, "targets": 5 }
          ]
        });
      }, 2000);

      console.log('getNavigationBasedRoleList function result ', this.navigationBasedRoleList);
    },
      error => {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
  }

  getCardTypeList() {
    this._configService.getCardTypeList().subscribe(result => {
      this.cardTypeList = result;
      console.log(this.cardTypeList);
    }
      ,
      err => console.log('Error in fetching data from getCardTypeList in configuration service.'));
  }

  getCardList(cardTypeId: number) {
    this._configService.getCardList(cardTypeId).subscribe(result => {
      this.cardList = result;
      console.log(this.cardList);
    }
      ,
      error => {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
  }

  onSubmit(navigationForm: any) {

    let pendingValidation = this.isAtleastOneItemSelected();
    if (!pendingValidation) {
      this.isRoleValidationDisplayed = true;
    } else {
      var localRoles = new Array();
      let role_length = this.roleList.length;
      for (var i = 0; i < role_length; i++) {
        if (this.roleList[i].checked)
          localRoles.push(this.roleList[i].key)
      }

      this.model.roles = localRoles;
      if (!this.isTitleExists(this.model.title, this.model.navigationId)) {
        this.model.title = this.model.title.trim();
        if (this.model.navigationId === 0) {
          this.insertRecord(this.model, navigationForm);
        } else {
          this.updateRecord(this.model, navigationForm);
        }
      } else {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Title already exists, Please enter valid Title', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
  }


  isTitleExists(title: string, id: number) {
    let retval: boolean;
    if (this.navigationBasedRoleList.length > 0) {
      for (var i = 0; i < this.navigationBasedRoleList.length; i++) {

        if ((this.navigationBasedRoleList[i].title === title)
          && (this.navigationBasedRoleList[i].navigationId != id)) {
          retval = true;
          break;
        }
      }
    } else {
      retval = false;
    }
    return retval;
  }

  insertRecord(form: NavigationRoleViewModel, navigationForm: any) {
    this._configService.postNavigationBasedRoles(form).subscribe(res => {
      if (res.key == 'Success') {
        this._alertService.remove(this.alertClient);
        this._alertService.add(res.value, res.key, null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        navigationForm.resetForm();
        this.getNavigationBasedRoleList();
      } else {
        this._alertService.remove(this.alertClient);
        this._alertService.add(res.value, res.key, null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }, error => {
      this._alertService.remove(this.alertClient);
      this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
  }

  updateRecord(form: NavigationRoleViewModel, navigationForm) {
    this._configService.postNavigationBasedRoles(form).subscribe(res => {
      console.log('updateRecord func called', res);
      if (res.key == 'Success') {
        this._alertService.remove(this.alertClient);
        this._alertService.add(res.value, res.key, null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        navigationForm.resetForm();
        this.getNavigationBasedRoleList();
      } else {
        this._alertService.remove(this.alertClient);
        this._alertService.add(res.value, res.key, null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    },
      error => {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
  }

  editNavigation(navigationId: number) {
    this.getRoleList();
    this._configService.getNavigationBasedRolesById(navigationId).subscribe(rolesResult => {
      let role_length = this.roleList.length;
      for (var index = 0; index < rolesResult.length; index++) {
        for (var i = 0; i < role_length; i++) {
          if (parseInt(this.roleList[i].key) == rolesResult[index]) {
            this.roleList[i].checked = true;
          }
        }
      }
      this.roles = rolesResult;
      this._configService.getNavigationInformationById(navigationId).subscribe(res => {
        this.model = new NavigationRoleViewModel(res[0].navigationId, res[0].navigationTypeId, res[0].title,
          res[0].imagePath, res[0].link, res[0].sequence, this.roles);
        //$('#Nav_' + res[0].navigationTypeId).prop('checked', true);
      });
    },
      error => {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
  }

  selectNavigation(navigationId: number) {
    this.selectedNavigationId = navigationId;
  }

  deleteNavigation(navigationForm: any) {
    this._configService.deleteNavigationBasedRoles(this.selectedNavigationId).subscribe(res => {
      if (res == 'Success') {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Navigation record was deleted.', 'Success', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        navigationForm.resetForm();
        this.getNavigationBasedRoleList();
        $(function () {
          $('#confirm-delete').modal('toggle');
        });
      }
    },
      error => {
        this._alertService.remove(this.alertClient);
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
  }
  clearMessage() {
    this._alertService.remove(this.alertClient);
  }
  clearForm(navigationForm: any) {
    this._alertService.remove(this.alertClient);
    navigationForm.reset();
    navigationForm.resetForm();
  }

  public isAtleastOneItemSelected() {
    const selectedItems = this.roleList.filter((item) => item.checked);
    if (selectedItems.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public CheckBoxChanged() {
    this.isRoleValidationDisplayed = false;
  }
  bindNavigationBasedRoleList() {
    setTimeout(() => {
      $("#NavigationRoleTable").DataTable({
        "iDisplayLength": 5,
        "columnDefs": [
          { "orderable": false, "targets": 5 }
        ]
      });

    }, 2000);
  }
}

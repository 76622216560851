<div class="card">
    <div class="card-header">
        <div class="justify-content-between d-flex">
            <div class="card-heading">
                <img src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                    *ngIf="sectionConfiguration.isCompleted ; else showRequiredWarningImg" alt="Completed"
                    style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" width="20"
                    height="20" />
                <ng-template #showRequiredWarningImg>
                    <img src="assets/images/EDP/Workspace/report_problem-24px.svg" class="img-responsive"
                        *ngIf="showRequiredWarning" alt="Warning"
                        style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" width="20"
                        height="20" />
                </ng-template>
                <a class="card-link">
                    <h5 class="edp-font-14 edp-font-color font-weight-bold">Audit Lifecycle checklist</h5>
                </a>
            </div>
            <div class="card-icon" *ngIf="!isImageEnabled">
                <a id="auditCloseoutchecklistlink" class="collapsed card-link" data-toggle="collapse"
                    (click)="toggleUp();">
                    <img src="assets/images/EDP/Engagement/desc_order.png" alt="Expand"
                        style="cursor: pointer; padding-bottom: 2px;">
                </a>
            </div>
            <div class="card-icon" *ngIf="isImageEnabled">
                <a class="collapsed card-link" data-toggle="collapse" (click)="toggleDown();">
                    <img src="assets/images/EDP/Engagement/asc_order.png" alt="Expand"
                        style="cursor: pointer; padding-bottom: 2px;">
                </a>
            </div>
        </div>

    </div>

    <div id="collapseTwo" data-parent="#accordion" *ngIf="isImageEnabled">
        <div class="card-body pt-0">
            <p class="">The following tasks are part of the Audit Lifecyle and should have
                been completed within this engagement cycle.</p>
            <div class="row mt-2 pl-3">
                <p><strong>Business conversation documentation</strong></p>
                <p>Engaging in business conversations with our clients is an important element to delivering the Grant
                    Thornton Experience (GTx) and can help determine areas where we can improve quality and provide
                    heightened insight. Please affirm the date your PPMD (and any combination of the engagement team)
                    conducted this conversation with the client during this engagement cycle.</p>



                    <p><i>Note: The purpose of this conversation is for the PPMD to gain a deeper understanding of our 
                        client’s 
                        business priorities and is generally conducted with client management during the early stages of an engagement cycle. 
                        The <u><a href="https://gtus365.sharepoint.com/sites/gt_audit/Shared%20Documents/Audit%20Go%20to%20Market%20Resource%20Center/aud_value%20drivers%20for%20client%20conversations.pdf?OR=Teams-HL&CT=1707929296401&cid=18f4df26-1597-4446-af7a-cf66c0720ef4" target="_blank">
                            <u>Value Drivers for Client Conversations</u></a></u> script has been provided as a resource to guide these conversations.</i>
                   </p>
    
                <div class="col-12 col-sm-6 col-lg-4 pl-0">
                    <label for="lblauditchecklistDate">Conversation Date:<span class="mandatory">*</span></label>
                    <app-calendercontrol id="calenderauditDt" [txtDate]="auditchecklistDate2CtrlId"
                        [disableWeekend]="disableWeekendValue" [selectedDate]="engagement?.conversationDate"
                        [ngClass]="{true: 'isDisabled'}[isuditChecklistCompleted]">
                    </app-calendercontrol>
                    <div class="form-control-feedback error-color row ml-2 mb-2">
                        <p *ngIf="!engagementAuditChecklistViewModel.isConversationDateBack">Date can be back dated to
                            the opening of this engagement</p>
                        <p *ngIf="!engagementAuditChecklistViewModel.isConversationDateFuture">Date cannot be future
                            dated</p>
                        <p *ngIf="!engagementAuditChecklistViewModel.isConversationDateEmpty">Date is a required field
                        </p>
                    </div>
                </div>

            </div>

            <div class="row mt-4 pl-3">
                <p><strong>Continuous improvement discussion</strong></p>
                <p>
                    Another important element of delivering the Grant Thornton Experience (GTx) is to reflect on
                    engagement execution and identify opportunities for improvement. Please affirm the date that your
                    engagement team conducted the continuous improvement discussion with the client. </p>



                <p><i>Note: Continuous improvement discussions are reflective conversations conducted at the conclusion
                        of
                        an engagement to identify opportunities for improvement in the next engagement cycle.</i>



                </p>
                <div class="col-12 col-sm-6 col-lg-4 pl-0">
                    <label for="">Improvement Date:<span class="mandatory">*</span></label>
                    <app-calendercontrol id="calblauditchecklistDatelenderauditDt" [txtDate]="auditchecklistDateCtrlId"
                        [disableWeekend]="disableWeekendValue" [selectedDate]="engagement?.improvementDate"
                        [ngClass]="{true: 'isDisabled'}[isuditChecklistCompleted]">
                    </app-calendercontrol>

                    <div class="form-control-feedback error-color row ml-2 mb-2">
                        <p *ngIf="!engagementAuditChecklistViewModel.isImprovementDateBack">Date can be back dated to
                            the opening of this engagement</p>
                        <p *ngIf="!engagementAuditChecklistViewModel.isImprovementDateFuture">Date cannot be future
                            dated</p>
                        <p *ngIf="!engagementAuditChecklistViewModel.isImprovementDateEmpty">Date is a required field
                        </p>
                    </div>
                </div>
            </div>
            <div class="row success-msg col-lg-12 justify-content-end d-flex "
                *ngIf="engagementAuditChecklistViewModel.isSaved">
                <p><b>Audit Lifecycle checklist saved successfully</b></p>
            </div>

            <div class="col-lg-12 justify-content-end d-flex mt-3 p-1 pr-4 button-text">
                <button id="btnAuditChecklistCancel" class="btn SecondaryButton w-40  text-center mr-3 btn-closeout"
                    type="button" (click)="toggleDown();">
                    Cancel
                </button>
                <button id="btnAuditChecklistSave" class="btn PrimaryButton w-40 text-center  pr-4 btn-closeout"
                    type="button" [disabled]="isuditChecklistCompleted" (click)="saveAuditChecklistDetails()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
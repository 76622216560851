<app-loading-screen></app-loading-screen>
<div class="row pageShortcutParent" *ngIf="iShortcutCount > 6">
  <div class="col-md-12">
    <app-shortcut></app-shortcut>
  </div>
</div>

<div *ngIf="!isMyEngagementLevel" class="row pb-3 mx-0 mt-3" [class.pageTitleParentExtraMargin]="iShortcutCount > 6"
  [class.pageTitleParent]="iShortcutCount <= 6">
  <div class="col-md-10 pl-0">
    <h2 class="pageTitle largerFontSize2">My engagements</h2>
  </div>
  <div class="col-md-2 pr-0" *ngIf="iShortcutCount <= 6">
    <app-shortcut></app-shortcut>
  </div>
</div>
<div class="bg-edp-body card-container position-relative">
  <div *ngIf="isMyEngagementLevel" class="col-md-10">
    <h2 class="pageTitle smallFontSize">Client engagements</h2>
  </div>
  <!-- List View and card view main container start -->
  <!--ListView Design Start-->
  <div class="Eng-ListView-Div" style="display:none">
    <div class="d-flex p-2">
      <div class="mr-auto ml-1">
        <div class="d-flex flex-row bd-highlight">
          <a id="filterEngagementListView" class="anchoroutline filter-tab" (click)="ShowHideListFilterPanel('Show')"
            data-placement="bottom" target="_blank">
            Filter
          </a>
          <a id="showAllEngagementListView" class="anchoroutline showall-tab"
            (click)="ShowAllEngagementsWithNoFilters('ListView')" data-placement="bottom" target="_blank">
            Show all
          </a>
        </div>
      </div>
      <!-- Toggle buttons Start -->
      <div class="ml-auto mr-1">
        <div class="d-flex flex-row bd-highlight">
          <a class="ShowCardViewjs px-3 py-1 anchoroutline" (click)="ToggleListAndCardView('Card');">
            <img class="CardViewImg pointerCursor shortcutimg"
              src="../../../../assets/images/EDP/Filters/apps-unselect.svg" alt="Card View" />
          </a>
          <a class="ShowListViewjs px-3 py-1 anchoroutline active" (click)="ToggleListAndCardView('List');">
            <img class="ListViewImgEng pointerCursor shortcutimg"
              src="../../../../assets/images/EDP/Filters/list_alt.svg" alt="List View" />
          </a>
        </div>
      </div>
      <!--  Toggle buttons End -->
    </div>
    <!-- Accordian view start -->
    <section id="listViewFilterSection" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-4">
            <div class="col-lg-3 col-md-4 filter-partition">
              <label class="font-weight-bold mb-1">Client name</label>
              <div class="selectdiv">
                <select id="ddListClientsForEngagement" [(ngModel)]="engagementListViewFilterModel.clientId"
                  class="dropdown rounded-0 form-control" name="ddListClientsForEngagement">
                  <option value="0" disabled hidden>Select client</option>
                  <option *ngFor="let clients of clientsList" [value]="clients.clientId">
                    {{clients.clientName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 filter-partition">
              <label class="font-weight-bold mb-1">Solution code</label>
              <div class="selectdiv ">
                <select id="ddListSolutionCode" [(ngModel)]="engagementListViewFilterModel.solutionCodeId"
                  class="dropdown rounded-0 form-control" name="ddListSolutionCode">
                  <option value="0" disabled hidden>Select solution code</option>
                  <option *ngFor="let solutionCode of solutionCodeList" [value]="solutionCode.solutionCodeId">
                    {{solutionCode.solutionCodeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 filter-partition partition-md-remove">
              <label class="font-weight-bold mb-1">EPPMD</label>
              <div class="selectdiv ">
                <select id="ddListEppmd" [(ngModel)]="engagementListViewFilterModel.eppmdEmail"
                  class="dropdown rounded-0 form-control" name="ddListEppmd">
                  <option value="" disabled hidden>Select EPPMD</option>
                  <option *ngFor="let eppmd of eppmdList" [value]="eppmd.eppmdEmail">
                    {{eppmd.eppmdName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 filter-partition">
              <label class="font-weight-bold mb-1">Industry</label>
              <div class="selectdiv ">
                <select id="ddListIndustryType" [(ngModel)]="engagementListViewFilterModel.industryTypeId"
                  class="dropdown rounded-0 form-control" name="ddListIndustryType">
                  <option value="0" disabled hidden>Select industry</option>
                  <option *ngFor="let industry of industryList" [value]="industry.industryTypeId">
                    {{industry.industryTypeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0">
              <label class="font-weight-bold mb-1">Engagement status</label>
              <div class="selectdiv ">
                <select id="ddListEngagementStatus" [(ngModel)]="engagementListViewFilterModel.engagementStatusId"
                  class="dropdown rounded-0 form-control" name="ddListEngagementStatus">
                  <option value="" disabled hidden>Select engagement status</option>
                  <option *ngFor="let engagementStatus of engagementStatusList"
                    [value]="engagementStatus.engagementStatusId">
                    {{engagementStatus.engagementStatusName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-self-end text-right m-0 pr-2">
          <div class="col-lg-8 col-md-4 filter-partition">
            <button type="button" id="resetListViewFiliter" (click)="ShowHideListFilterPanel('Reset')"
              class="SecondaryButton filter-button-eng">
              <span class="d-inline-block align-middle">Reset</span>
            </button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
            <button type="button" (click)="ShowHideListFilterPanel('Hide')" class="SecondaryButton filter-button-eng">
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
            <button type="button" (click)="GetAllEngagementsListByFilters()"
              class="PrimaryButton mr-2 filter-button-eng">
              <span class="d-inline-block align-middle">Apply</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- Accordian view end -->
    <!-- Accordian view For Client ListView start -->
    <section id="listViewFilterSectionForClient" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-4">
            <div class="col-lg-3 col-md-6 filter-partition">
              <label class="font-weight-bold mb-1">Solution code</label>
              <div class="selectdiv ">
                <select id="ddListSolutionCodeForClientListView"
                  [(ngModel)]="engagementListViewForClientFilterModel.solutionCodeId"
                  class="dropdown rounded-0 form-control" name="ddListSolutionCodeForClientListView">
                  <option value="0" disabled hidden>Select solution code</option>
                  <option *ngFor="let solutionCode of solutionCodeListForClient" [value]="solutionCode.solutionCodeId">
                    {{solutionCode.solutionCodeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 filter-partition partition-md-remove">
              <label class="font-weight-bold mb-1">EPPMD</label>
              <div class="selectdiv ">
                <select id="ddListEppmdForClientListView"
                  [(ngModel)]="engagementListViewForClientFilterModel.eppmdEmail"
                  class="dropdown rounded-0 form-control" name="ddListEppmdForClientListView">
                  <option value="" disabled hidden>Select EPPMD</option>
                  <option *ngFor="let eppmd of eppmdListForClient" [value]="eppmd.eppmdEmail">
                    {{eppmd.eppmdName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 filter-partition mt-md-3 mt-lg-0">
              <label class="font-weight-bold mb-1">Client industry</label>
              <div class="selectdiv ">
                <select id="ddListIndustryTypeForClientListView"
                  [(ngModel)]="engagementListViewForClientFilterModel.industryTypeId"
                  class="dropdown rounded-0 form-control" name="ddListIndustryTypeForClientListView">
                  <option value="0" disabled hidden>Select industry</option>
                  <option *ngFor="let industry of industryListForClient" [value]="industry.industryTypeId">
                    {{industry.industryTypeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-md-3 mt-lg-0">
              <label class="font-weight-bold mb-1">Engagement status</label>
              <div class="selectdiv ">
                <select id="ddListEngagementStatusForClientListView"
                  [(ngModel)]="engagementListViewForClientFilterModel.engagementStatusId"
                  class="dropdown rounded-0 form-control" name="ddListEngagementStatusForClientListView">
                  <option value="" disabled hidden>Select engagement status</option>
                  <option *ngFor="let engagementStatus of engagementStatusListForClient"
                    [value]="engagementStatus.engagementStatusId">
                    {{engagementStatus.engagementStatusName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-self-end text-right">
          <div class="col-md-8 p-0 filter-partition mb-1 my-0">
            <button type="button" id="clientListViewResetFiliter" (click)="ShowHideListFilterPanel('Reset')"
              class="SecondaryButton " style="min-width: 125px; text-align: center; margin-right: 30px;">
              <span class="d-inline-block align-middle">Reset</span>
            </button>
          </div>
          <div class="col-md-4 p-0 mb-1 my-0">
            <button type="button" id="clientListViewCancelFiliter" (click)="ShowHideListFilterPanel('Hide')"
              class="SecondaryButton " style="min-width: 125px; text-align: center; margin-right: 30px;">
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" id="clientListViewApplyFiliter"
              (click)="GetAllEngagementsListByFiltersForClientView()" class="PrimaryButton mr-3"
              style="min-width: 125px; text-align: center;">
              <span class="d-inline-block align-middle">Apply</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- Accordian view For Client ListView end -->
    <div class="MainContainer ml-3 mr-2 my-3">
      <div class="tableData1">
        <div class="tableData2">
          <table class="listViewDataTable ResponsiveDataTable">
            <thead>
              <tr>
                <th *ngIf="!isMyEngagementLevel" scope="col" class="table-sorted-desc t-mini-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-9 p-0 m-0">
                      {{headerModelList[0].headerName}}
                    </div>
                    <div class="col-md-3 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[0].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[1].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[1].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>


                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-12 p-0 m-0">
                      {{headerModelList[8].headerName}}<img alt="add" src="../../../../assets/images/Client/tooltip.svg"
                        style="height: 16px; width: 16px;margin-left:6px;" data-toggle="tooltip"
                        data-custom-class="tooltipIndicators" data-html="true" data-title="<div><b>Indicators legend</b><br/><br/>
                            <span class='tooltipBgIconColor'>
                              <img alt='add' src='../../../../assets/images/EDP/Dashboard/reviewNoteIndicator18.svg'/>
                            </span>
                            &nbsp;&nbsp;&nbsp;Open review notes<br/><br/>
    
                        <b>N/N</b>&nbsp;&nbsp;  Review notes with response / open<br/><br/>
                        
                        <span class='tooltipBgIconColorEng'>
                          <img alt='add' 
                            src='../../../../assets/images/EDP/Dashboard/redo-24px.svg'/>
                        </span>
                        &nbsp;&nbsp;Continuance engagement<br/><br/>
                        <span >
                          <img alt='add' 
                            src='../../../../assets/images/EDP/Dashboard/notStartedIndicator.svg'/>
                        </span>
                        &nbsp;&nbsp;Not started<br/><br/>
                    </div>" />
                    </div>                  
                  </div>
                </th>

                <th scope="col" class="table-sorted-desc t-lr-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[2].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[2].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>

                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[9].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[9].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[9].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[3].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[3].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[4].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[4].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[5].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[5].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[5].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-mini-md-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-9 p-0 m-0">
                      {{headerModelList[6].headerName}}
                    </div>
                    <div class="col-md-3 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[6].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="HeaderSort(headerModelList[6].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort">
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-mini-sm-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[7].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">

                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="display" *ngIf="listCount > 0">
              <tr class="searchDatajs" *ngFor="let list of engagementList">
                <td *ngIf="!isMyEngagementLevel" class="t-mini-md-col" data-label="Client Name :"><a
                    routerLink="/ClientDetails/{{list.clientId}}" class="link">
                    {{list.clientName}} </a></td>
                <td data-label="Eng Name:" *ngIf="ShowClientName" class="t-md-col">
                  <span class="no-link"
                    *ngIf=" !isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace">{{list.engagementName}}</span>
                  <span class="no-link"
                    *ngIf=" isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && !isQualityUserLoggedIn || list.status == 'Site Provisioning Error'">{{list.engagementName}}</span>
                  <span class="no-link"
                    *ngIf="isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && isQualityUserLoggedIn && (list.status == 'Not started' || list.status == 'Draft')">{{list.engagementName}}</span>
                  <a *ngIf="isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && isQualityUserLoggedIn && (list.status == 'Team review' || list.status == 'RP review' || list.status == 'EPPMD review' || list.status == 'Declined' || list.status == 'Abandoned' || list.status == 'RP rule engine error')"
                    class="link CardViewText CardViewHeaderFont eng-pointer-cursor"
                    [routerLink]="['/Review/',list.workspaceId]">{{list.engagementName}}</a>
                  <a *ngIf="isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && isQualityUserLoggedIn && (list.status =='Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature  || list.status == EngagementStatus.EngActivePendingSignature  || list.status == 'CMS code error' || list.status == 'Active' || list.status == 'Closed' || list.status == EngagementStatus.EngActivePendingSignature || list.status == EngagementStatus.EngVoided  )"
                    class="link CardViewText CardViewHeaderFont eng-pointer-cursor"
                    [routerLink]="['/EngagementDetail/',list.engagementId]">{{list.engagementName}}</a>
                  <a *ngIf="list.isLoggedInUserPartOfWorkSpace && list.status == 'Not started'"
                    class="link CardViewText CardViewHeaderFont eng-pointer-cursor"
                    [routerLink]="['/EditEngagement/',list.engagementId]">{{list.engagementName}}</a>
                  <a *ngIf="list.isLoggedInUserPartOfWorkSpace && list.status == 'Draft'"
                    class="link CardViewText CardViewHeaderFont eng-pointer-cursor"
                    [routerLink]="['/Interviews/',list.workspaceId]">{{list.engagementName}}</a>
                  <a *ngIf="list.isLoggedInUserPartOfWorkSpace && (list.status == 'Team review' || list.status == 'RP review' || list.status == 'EPPMD review' || list.status == 'Declined' || list.status == 'Abandoned' || list.status == 'RP rule engine error')"
                    class="link CardViewText CardViewHeaderFont eng-pointer-cursor"
                    [routerLink]="['/Review/',list.workspaceId]">{{list.engagementName}}</a>
                  <a *ngIf="list.isLoggedInUserPartOfWorkSpace && (list.status =='Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature || list.status == 'CMS code error' || list.status == 'Active' || list.status == 'Closed'  || list.status == EngagementStatus.EngActivePendingSignature || list.status == EngagementStatus.EngVoided )"
                    class="link CardViewText CardViewHeaderFont eng-pointer-cursor"
                    [routerLink]="['/EngagementDetail/',list.engagementId]">{{list.engagementName}}</a>
                </td>
                <td data-label="Indicators :" class="t-md-col pt-2 px-2 pb-0">
                  <div class="col-md-12 col-lg-12 m-0 pt-0 pl-0 pb-0 pr-0 m-0 text-left">
                    <span class="px-0" *ngIf="list.workspaceOpenReviewPointsCount > 0">
                      <span style="background-color: #f5f5f5;padding:3px;border-radius: 50%;">
                        <img alt="add" style="height: 18px; width: 18px;"
                          src="../../../../assets/images/EDP/Dashboard/reviewNoteIndicator.svg">
                      </span>
                      <span *ngIf="list.workspaceRespondedReviewPointsCount > 0">
                        {{list.workspaceRespondedReviewPointsCount}}/</span>{{list.workspaceOpenReviewPointsCount}}

                    </span>

                    <span class="rounded-circle" style="padding:3px; margin:1px; background-color: #F5F5F5 "
                      *ngIf="list.reacceptanceEngagementId != null && list.reacceptanceEngagementId > 0">
                      <img alt="add" style="padding-bottom:5px ; width: 18px;"
                        src="../../../../assets/images/EDP/Dashboard/redo-24px.svg">
                    </span>
                    <span class="rounded-circle" style="padding:3px; margin:1px; background-color: #F5F5F5 "
                      *ngIf="list.reacceptanceEngagementId != null && list.reacceptanceEngagementId > 0 && list.status == 'Not started'">
                      <img alt="add" style="padding-bottom:5px ; width: 18px;"
                        src="../../../../assets/images/EDP/Dashboard/notStartedIndicator.svg">
                    </span>
                  </div>
                </td>
                <td data-label="Solution code:" class="t-lr-col"> {{list.parentSolutionCode}} </td>
                <td data-label="Period end:" class="t-md-col"> {{list.taxYear | date: 'MM/dd/yyyy'}} </td>
                <td data-label="Engagement partner:" class="t-md-col">
                  <span class="ePPMDcontactPopup link" id="ePPMDcontactPopup_{{list.engagementId}}"
                    (mousedown)="DisplayContactPopup(list.engagementId, list.ePPMDEmailID)">{{list.ePPMD}}
                  </span>
                  <input type="hidden" id="listViewtxtengId" value="" />
                </td>
                <td data-label="Assignments count:" class="text-center t-md-col"> {{list.assignmentsCount }} </td>
                <td data-label="Last activity date:" class="t-md-col"> {{list.lastActivityDate | date: "MM/dd/yyyy"}}
                </td>
                <td data-label="Status:" class="t-mini-md-col"> {{list.status }} </td>
                <td data-label="Actions:" class="text-center t-mini-sm-col" *ngIf="isClientEngagements">
                  <a *ngIf="list.siteUrl != '' && list.isLoggedInUserPartOfWorkSpace && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned )"
                    href="{{list.siteUrl}}" target="_blank">
                    <img alt="sharepoint" class="icon-logo"
                      src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg">
                  </a>
                  <a *ngIf="list.status != 'Active' && list.status != 'CMS code error' && list.status != 'Active (pending CMS codes)' && list.status != EngagementStatus.EngActivePendingSignature  && list.status != 'RP review' && list.status != 'EPPMD review' && list.status != 'Team review' && list.status != 'Draft' && list.status != EngagementStatus.EngDeclined && list.status != EngagementStatus.EngClosed && list.status != EngagementStatus.EngAbandoned"
                    href="{{list.siteUrl}}" class="nonClickableLink">
                  </a>
                </td>
                <td data-label="Actions:" class="text-center t-mini-sm-col" *ngIf="!isClientEngagements">
                  <a *ngIf="list.siteUrl != '' && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned)"
                    href="{{list.siteUrl}}" target="_blank">
                    <img alt="sharepoint" class="icon-logo"
                      src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg">
                  </a>
                  <a *ngIf="list.status != 'Active' && list.status != 'CMS code error' && list.status != 'Active (pending CMS codes)' && list.status != EngagementStatus.EngActivePendingSignature  && list.status != 'RP review' && list.status != 'EPPMD review' && list.status != 'Team review' && list.status != 'Draft' && list.status != EngagementStatus.EngDeclined && list.status != EngagementStatus.EngClosed && list.status != EngagementStatus.EngAbandoned"
                    href="{{list.siteUrl}}" class="nonClickableLink">
                  </a>
                </td>
              </tr>
            </tbody>
            <div *ngIf="listCount == 0 || listCount == null">
              <div class="no-data-available no-data">{{listDataMessage}}</div>
            </div>
          </table>

          <!-- pager start-->
          <!-- pager End-->
        </div>
      </div>
      <!-- ListView Pagination Start -->
      <div class="d-flex justify-content-center text-center pagination-section" *ngIf="listCount > 0">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
          <li>
            <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="listViewSetPage(1)">
              <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first-page">
            </a>
          </li>
          <li>
            <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
              (click)="listViewSetPage(pager.currentPage - 1)">
              <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous-page">
            </a>

          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="listViewSetPage(page)">{{page}}</a>
          </li>

          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.currentPage + 1)">
              <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next-page">
            </a>
          </li>
          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.totalPages)">
              <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last-page">
            </a>
          </li>

        </ul>
        <div>
          <select id="drprecordsPerPage" [(ngModel)]="listViewnoOfrecordsPerPage" name="drprecordsPerPage"
            (change)="listViewSetPage(1)" class="page-counts">
            <option *ngFor="let listViewnoOfrecordsPerPage of noOfrecordsPerPageList"
              value="{{listViewnoOfrecordsPerPage}}">{{listViewnoOfrecordsPerPage}}</option>
          </select>
        </div>

      </div>
      <!-- ListView Pagination End -->
    </div>
    <!-- MainContainer End -->
  </div>
  <!--ListView Design End-->

  <!--CardView Design Start-->
  <div class="Eng-CardView-Div">
    <div class="d-flex p-2">
      <div class="mr-auto ml-1">
        <div class="d-flex flex-row bd-highlight">
          <a id="filterEngagementCardView" class="anchoroutline filter-tab" (click)="ShowHideFilterPanel('Show')"
            data-placement="bottom" target="_blank">
            Filter
          </a>
          <a id="sortEngagementCardView" class="anchoroutline sort-tab" (click)="ShowHideFilterSortPanel('Show')"
            data-placement="bottom" target="_blank">
            Sort
          </a>
          <a id="showAllEngagementCardView" class="anchoroutline showall-tab"
            (click)="ShowAllEngagementsWithNoFilters('CardView')" data-placement="bottom" target="_blank">
            Show all
          </a>
        </div>
      </div>
      <!-- Toggle buttons Start -->
      <div class="ml-auto mr-1">
        <div class="d-flex flex-row bd-highlight">
          <a class="ShowCardViewjs px-3 py-1 anchoroutline active" (click)="ToggleListAndCardView('Card');">
            <img class="CardViewImg pointerCursor shortcutimg active"
              src="../../../../assets/images/EDP/Filters/grid_on.svg" alt="Card View" />
          </a>
          <a class="ShowListViewjs px-3 py-1 anchoroutline" (click)="ToggleListAndCardView('List');">
            <img class="ListViewImgEng pointerCursor shortcutimg"
              src="../../../../assets/images/EDP/Filters/list_alt-unselected.svg" alt="List View" />
          </a>
        </div>
      </div>
      <!--  Toggle buttons End -->
    </div>
    <!-- Accordian view start -->
    <section id="cardViewFilterSection" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-4">
            <div class="col-lg-3 col-md-4 filter-partition">
              <label class="font-weight-bold mb-1">Client name</label>
              <div class="selectdiv">
                <select id="ddClientsForEngagementList" class="dropdown rounded-0 form-control"
                  [(ngModel)]="engagementCardViewFilterModel.clientId" name="ddClientsForEngagementList">
                  <option value="0" disabled hidden>Select client</option>
                  <option *ngFor="let clients of clientsList" [value]="clients.clientId">
                    {{clients.clientName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 filter-partition">
              <label class="font-weight-bold mb-1">Solution code</label>
              <div class="selectdiv ">
                <select id="ddSolutionCodeList" class="dropdown rounded-0 form-control"
                  [(ngModel)]="engagementCardViewFilterModel.solutionCodeId" name="ddSolutionCodeList">
                  <option value="0" disabled hidden>Select solution code</option>
                  <option *ngFor="let solutionCode of solutionCodeList" [value]="solutionCode.solutionCodeId">
                    {{solutionCode.solutionCodeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 filter-partition partition-md-remove">
              <label class="font-weight-bold mb-1">EPPMD</label>
              <div class="selectdiv ">
                <select id="ddEppmdList" class="dropdown rounded-0 form-control"
                  [(ngModel)]="engagementCardViewFilterModel.eppmdEmail" name="ddEppmdList">
                  <option value="" disabled hidden>Select EPPMD</option>
                  <option *ngFor="let eppmd of eppmdList" [value]="eppmd.eppmdEmail">
                    {{eppmd.eppmdName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 mt-md-3 mt-lg-0 filter-partition">
              <label class="font-weight-bold mb-1">Industry</label>
              <div class="selectdiv ">
                <select id="ddIndustryTypeList" class="dropdown rounded-0 form-control"
                  [(ngModel)]="engagementCardViewFilterModel.industryTypeId" name="ddIndustryTypeList">
                  <option value="0" disabled hidden>Select industry</option>
                  <option *ngFor="let industry of industryList" [value]="industry.industryTypeId">
                    {{industry.industryTypeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 mt-md-3 mt-lg-0 px-md-2 px-lg-2 px-xl-3">
              <label class="font-weight-bold mb-1">Engagement status</label>
              <div class="selectdiv ">
                <select id="ddEngagementStatusList" class="dropdown rounded-0 form-control"
                  [(ngModel)]="engagementCardViewFilterModel.engagementStatusId" name="ddEngagementStatusList">
                  <option value="" disabled hidden>Select engagement status</option>
                  <option *ngFor="let engagementStatus of engagementStatusList"
                    [value]="engagementStatus.engagementStatusId">
                    {{engagementStatus.engagementStatusName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-self-end text-right m-0 pr-2">
          <div class="col-lg-8 col-md-4 filter-partition">
            <button type="button" id="resetCardViewFiliter" (click)="ShowHideFilterPanel('Reset')"
              class="SecondaryButton filter-button-eng">
              <span class="d-inline-block align-middle">Reset</span>
            </button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
            <button type="button" (click)="ShowHideFilterPanel('Hide')" class="SecondaryButton filter-button-eng"
              style="margin-right: 20px;">
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
          </div>
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
            <button type="button" (click)="GetAllEngagementsByFilters()" class="PrimaryButton filter-button-eng mr-2">
              <span class="d-inline-block align-middle">Apply</span>
            </button>
          </div>
        </div>

      </div>
    </section>
    <!-- Accordian view end -->
    <!-- Accordian sort card view start -->
    <section id="cardViewSortSection" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-3">
            <div class="col-xl-2 col-md-3 pr-0">
              <div class="custom-control custom-checkbox">
                <input id="chkClientName" type="checkbox" name="chkClientName"
                  [(ngModel)]="engagementCardViewFilterModel.sortClientId" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkClientName"> Client name</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input id="chkSolutionCode" [(ngModel)]="engagementCardViewFilterModel.sortSolutionCodeId"
                  type="checkbox" name="chkSolutionCode" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkSolutionCode">Solution code</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input id="chkEppmd" type="checkbox" name="chkEppmd"
                  [(ngModel)]="engagementCardViewFilterModel.sortEppmdEmailId" class="custom-control-input"
                  font-weight-bold>
                <label class="custom-control-label font-weight-bold" for="chkEppmd">EPPMD</label>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 px-xl-0 filter-partition">
              <div class="custom-control custom-checkbox">
                <input id="chkEngagementStatus" [(ngModel)]="engagementCardViewFilterModel.sortEngagementStatusId"
                  type="checkbox" name="chkEngagementStatus" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkEngagementStatus">Engagement status</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input id="chkLastActivityDate" [(ngModel)]="engagementCardViewFilterModel.sortLastActivityDate"
                  type="checkbox" name="chkLastActivityDate" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkLastActivityDate">Last activity
                  date</label>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 pl-md-5 pl-xl-3">
              <label class="font-weight-bold mb-1" for="chkLastActivityDate">Order by type</label>
              <div class="selectdiv">
                <select id="ddListOrderByType" class="dropdown rounded-0 form-control" [(ngModel)]="sortOrderByType"
                  name="ddListOrderByType">
                  <option value="DESC">Descending</option>
                  <option value="ASC">Ascending</option>
                </select>
              </div>
            </div>
            <div class="col-xl-6 col-md-12 col-lg-12 mt-md-4 mt-2">
              <div class="row mb-2 mt-0 mx-0 p-0">
                <div class="col-lg-8 col-xl-4 col-md-4 filter-partition px-2 align-self-end text-right">
                  <button type="button" id="resetCardViewSortFiliter" (click)="ShowHideFilterSortPanel('Reset')"
                    class="SecondaryButton filter-button-eng mr-1">
                    <span class="d-inline-block align-middle">Reset</span>
                  </button>
                </div>
                <div class="col-lg-2 col-xl-4 col-md-4 mt-md-3 mt-lg-0 px-2 align-self-end text-left">
                  <button type="button" (click)="ShowHideFilterSortPanel('Hide')"
                    class="SecondaryButton filter-button-eng ml-1">
                    <span class="d-inline-block align-middle">Cancel</span>
                  </button>
                </div>
                <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 pl-2 align-self-end text-left">
                  <button type="button" (click)="GetAllSortedEngagementsByFilters()"
                    class="PrimaryButton filter-button-eng">
                    <span class="d-inline-block align-middle">Apply</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Accordian sort card view end -->
    <!-- Accordian view For My Clients start -->
    <section id="cardViewFilterSectionForMyClient" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-4">
            <div class="col-lg-3 col-md-6 filter-partition">
              <label class="font-weight-bold mb-1">Solution code</label>
              <div class="selectdiv ">
                <select id="ddSolutionCodeListForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.solutionCodeId"
                  class="dropdown rounded-0 form-control" name="ddSolutionCodeListForClient">
                  <option value="0" disabled hidden>Select solution code</option>
                  <option *ngFor="let solutionCode of solutionCodeListForClient" [value]="solutionCode.solutionCodeId">
                    {{solutionCode.solutionCodeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 filter-partition partition-md-remove">
              <label class="font-weight-bold mb-1">EPPMD</label>
              <div class="selectdiv ">
                <select id="ddEppmdListForClient" [(ngModel)]="engagementCardViewForClientFilterModel.eppmdEmail"
                  class="dropdown rounded-0 form-control" name="ddEppmdListForClient">
                  <option value="" disabled hidden>Select EPPMD</option>
                  <option *ngFor="let eppmd of eppmdListForClient" [value]="eppmd.eppmdEmail">
                    {{eppmd.eppmdName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-md-3 mt-lg-0 filter-partition">
              <label class="font-weight-bold mb-1">Client industry</label>
              <div class="selectdiv ">
                <select id="ddIndustryTypeListForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.industryTypeId"
                  class="dropdown rounded-0 form-control" name="ddIndustryTypeListForClient">
                  <option value="0" disabled hidden>Select industry</option>
                  <option *ngFor="let industry of industryListForClient" [value]="industry.industryTypeId">
                    {{industry.industryTypeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-md-3 mt-lg-0">
              <label class="font-weight-bold mb-1">Engagement status</label>
              <div class="selectdiv ">
                <select id="ddEngagementStatusListForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.engagementStatusId"
                  class="dropdown rounded-0 form-control" name="ddEngagementStatusListForClient">
                  <option value="" disabled hidden>Select engagement status</option>
                  <option *ngFor="let engagementStatus of engagementStatusListForClient"
                    [value]="engagementStatus.engagementStatusId">
                    {{engagementStatus.engagementStatusName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-self-end text-right m-0 pr-2">
          <div class="col-lg-8 col-md-4 filter-partition">
            <button class="SecondaryButton filter-button-cnt" id="clientCardViewResetFiliter"
              (click)="ShowHideFilterPanel('Reset')" type="button">
              <span class="d-inline-block align-middle">Reset</span></button>

          </div>
          <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 px-3">
            <button class="SecondaryButton filter-button-cnt" id="clientCardViewCancelFiliter"
              (click)="ShowHideFilterPanel('Hide')" type="button">
              <span class="d-inline-block align-middle">Cancel</span></button>
          </div>

          <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 pl-2">
            <button class="PrimaryButton mr-2 filter-button-cnt" type="button" id="clientCardViewApplyFiliter"
              (click)="GetAllEngagementsByFiltersForClient()">
              <span class="d-inline-block align-middle">Apply</span></button>
          </div>
        </div>
      </div>
    </section>
    <!-- Accordian view For My Clients end -->
    <!-- Accordian sort card view For Client start -->
    <section id="cardViewSortSectionForClient" class="filter-panel" style="display: none;">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12">
          <div class="row m-0 pb-2">
            <div class="col-xl-2 col-md-3 pr-0">
              <div class="custom-control custom-checkbox">
                <input id="chkSolutionCodeForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.sortSolutionCodeId" type="checkbox"
                  name="chkSolutionCodeForClient" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkSolutionCodeForClient">Solution
                  code</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input id="chkEppmdForClient" [(ngModel)]="engagementCardViewForClientFilterModel.sortEppmdEmailId"
                  type="checkbox" name="chkEppmdForClient" class="custom-control-input" font-weight-bold>
                <label class="custom-control-label font-weight-bold" for="chkEppmdForClient">EPPMD</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input id="chkIndustryTypeForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.sortIndustryTypeId" type="checkbox"
                  name="chkIndustryTypeForClient" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkIndustryTypeForClient">Client
                  industry</label>
              </div>
            </div>
            <div class="col-xl-2 col-md-4 px-xl-0 filter-partition">
              <div class="custom-control custom-checkbox">
                <input id="chkEngagementStatusForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.sortEngagementStatusId" type="checkbox"
                  name="chkEngagementStatusForClient" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkEngagementStatusForClient">Engagement
                  status</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input id="chkLastActivityDateForClient"
                  [(ngModel)]="engagementCardViewForClientFilterModel.sortLastActivityDate" type="checkbox"
                  name="chkLastActivityDateForClient" class="custom-control-input">
                <label class="custom-control-label font-weight-bold" for="chkLastActivityDateForClient">Last activity
                  date</label>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 pl-md-5 pl-xl-3">
              <label class="font-weight-bold mb-1" for="chkLastActivityDate">Order by type</label>
              <div class="selectdiv">
                <select id="ddListOrderByTypeForClient" class="dropdown rounded-0 form-control"
                  [(ngModel)]="sortOrderByTypeForClient" name="ddListOrderByTypeForClient">
                  <option value="DESC">Descending</option>
                  <option value="ASC">Ascending</option>
                </select>
              </div>
            </div>

            <div class="col-xl-6 col-md-12 col-lg-12 mt-md-4 mt-2">
              <div class="row my-0 mx-0 p-0">
                <div class="col-lg-8 col-xl-4 col-md-4 filter-partition px-2 align-self-end text-right">
                  <button class="SecondaryButton filter-button-cnt mr-1" id="resetClientCardViewSortFilter"
                    (click)="ShowHideFilterSortPanel('Reset')" type="button">
                    <span class="d-inline-block align-middle">Reset</span></button>
                </div>
                <div class="col-lg-2 col-xl-4 col-md-4 mt-md-3 mt-lg-0 px-2 align-self-end text-left">
                  <button class="SecondaryButton filter-button-cnt ml-1" id="cancelresetClientCardViewSortFilter"
                    (click)="ShowHideFilterSortPanel('Hide')" type="button">
                    <span class="d-inline-block align-middle">Cancel</span></button>
                </div>
                <div class="col-lg-2 col-md-4 mt-md-3 mt-lg-0 pl-2 align-self-end text-left">
                  <button class="PrimaryButton filter-button-cnt" id="applyresetClientCardViewSortFilter"
                    (click)="GetAllSortedEngagementsByFiltersForClient()" type="button">
                    <span class="d-inline-block align-middle">Apply</span></button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
    <!-- Accordian sort card view For Client end -->
    <div class="d-flex p-0">
      <div class="card-deck w-100 mx-0">
        <div class="card" *ngFor="let list of engagementCardList">
          <div class="card-body text-align-left position-relative">
            <div class="CardViewContent">
              <ul class="list-group">
                <li class="list-group-item">
                  <a *ngIf="!isMyEngagementLevel" routerLink="/ClientDetails/{{list.clientId}}" class="client-link">
                    {{ list.clientName}}</a>

                    <a class="card-status">
                      <ng-container *ngIf="list.reacceptanceEngagementId != null && list.reacceptanceEngagementId > 0 && list.status === 'Not started'">
                        <img alt="add" src="../../../../assets/images/Client/tooltip.svg"
                             style="height: 16px; width: 16px;margin-left:6px;margin-bottom:3px;"data-toggle="tooltip" 
                             data-html="true"
                             title="If this engagement isn't started within 30 days of creation, it will be rolled back to Continuance dashboard."/>
                     </ng-container>&nbsp;
                    {{ list.status }}</a>
                </li>
                <li class="list-group-item">
                  <div class="row p-0 m-0">
                    <div class="col-11 p-0 m-0">
                      <span class="CardViewText CardViewHeaderFont"
                        *ngIf="!isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace">{{list.engagementName}}</span>
                      <span class="CardViewText CardViewHeaderFont"
                        *ngIf=" isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && !isQualityUserLoggedIn || list.status == 'Site Provisioning Error'">{{list.engagementName}}</span>
                      <span class="CardViewText CardViewHeaderFont"
                        *ngIf="isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && isQualityUserLoggedIn && (list.status == 'Not started' || list.status == 'Draft')">{{list.engagementName}}</span>
                      <a *ngIf="isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && isQualityUserLoggedIn && (list.status == 'Team review' || list.status == 'RP review' || list.status == 'EPPMD review' || list.status == 'Declined' || list.status == 'Abandoned' || list.status == 'RP rule engine error')"
                        class="CardViewText CardViewHeaderFont eng-pointer-cursor"
                        [routerLink]="['/Review/',list.workspaceId]">{{list.engagementName}}</a>
                      <a *ngIf="isMyEngagementLevel && !list.isLoggedInUserPartOfWorkSpace && isQualityUserLoggedIn && (list.status =='Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature || list.status == 'CMS code error' || list.status == 'Active' || list.status == 'Closed'|| list.status == EngagementStatus.EngVoided )"
                        class="CardViewText CardViewHeaderFont eng-pointer-cursor"
                        [routerLink]="['/EngagementDetail/',list.engagementId]">{{list.engagementName}}</a>
                      <a *ngIf="list.isLoggedInUserPartOfWorkSpace && list.status == 'Not started'"
                        class="CardViewText CardViewHeaderFont eng-pointer-cursor"
                        [routerLink]="['/EditEngagement/',list.engagementId]">{{list.engagementName}}</a>
                      <a *ngIf="list.isLoggedInUserPartOfWorkSpace && list.status == 'Draft'"
                        class="CardViewText CardViewHeaderFont eng-pointer-cursor"
                        [routerLink]="['/Interviews/',list.workspaceId]">{{list.engagementName}}</a>
                      <a *ngIf="list.isLoggedInUserPartOfWorkSpace && (list.status == 'Team review' || list.status == 'RP review' || list.status == 'EPPMD review' || list.status == 'Declined' || list.status == 'Abandoned' || list.status == 'RP rule engine error')"
                        class="CardViewText CardViewHeaderFont eng-pointer-cursor"
                        [routerLink]="['/Review/',list.workspaceId]">{{list.engagementName}}</a>
                      <a *ngIf="list.isLoggedInUserPartOfWorkSpace && (list.status =='Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature  || list.status == 'CMS code error' || list.status == 'Active' || list.status == 'Closed' || list.status == EngagementStatus.EngVoided )"
                        class="CardViewText CardViewHeaderFont eng-pointer-cursor"
                        [routerLink]="['/EngagementDetail/',list.engagementId]">{{list.engagementName}}</a>
                    </div>

                  </div>

                </li>

                <li class="list-group-item">
                  <span>Primary solution code:</span>
                  <span class="SolutionCodejsForFilter font-weight-bold pl-1">{{ list.parentSolutionCode }}</span>
                </li>
                <li class="list-group-item">
                  <span>Primary assignment code:</span>
                  <span class="StatusjsForFilter font-weight-bold pl-1">{{ list.assignmentCode }}</span>
                </li>
                <li class="list-group-item">
                  <span>EPPMD:</span>
                  <span class="ePPMDcontactPopup id-name font-weight-bold pl-1"
                    id="cardViewEPPMDcontactPopup_{{list.engagementId}}"
                    (mousedown)="DisplayCardViewContactPopup(list.engagementId, list.ePPMDEmailID)">{{list.ePPMD}}
                  </span>
                  <input type="hidden" id="cardViewtxtengId" value="" />
                </li>
                <li class="list-group-item">
                  <span>Active assignments:</span>
                  <span class="font-weight-bold pl-1">{{ list.assignmentsCount }}</span>
                </li>
                <li *ngIf="list.taxYear != null" class="list-group-item">
                  <span>Period end:</span>
                  <span class="font-weight-bold pl-1">{{ list.taxYear | date : 'MM/dd/yyyy' }}</span>
                </li>
                <li class="list-group-item" *ngIf="isClientEngagements">
                  <a *ngIf="list.siteUrl != '' && list.isLoggedInUserPartOfWorkSpace && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)' || list.status === EngagementStatus.EngActivePendingSignature  || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned)"
                    href="{{list.siteUrl}}" target="_blank">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open.svg" style="vertical-align: sub;"
                      alt="Link to SharePoint" />
                    Link to SharePoint</a>
                  <a *ngIf="list.siteUrl != '' && !list.isLoggedInUserPartOfWorkSpace && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)' || list.status === EngagementStatus.EngActivePendingSignature  || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned)"
                    class="nonClickableLink">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open_black.svg"
                      style="vertical-align: sub;" alt="Link to SharePoint" />Link to SharePoint
                  </a>
                  <a *ngIf="list.siteUrl === '' && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)'  || list.status === EngagementStatus.EngActivePendingSignature || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned)"
                    class="nonClickableLink">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open_black.svg"
                      style="vertical-align: sub;" alt="Link to SharePoint" />
                    Link to SharePoint</a>
                  <a *ngIf="list.status != 'Active' && list.status != 'Not started' && list.status != 'CMS code error' && list.status != 'Active (pending CMS codes)'  && list.status != EngagementStatus.EngActivePendingSignature  && list.status != 'RP review' && list.status != 'EPPMD review' && list.status != 'Team review' && list.status != 'Draft' && list.status != EngagementStatus.EngDeclined && list.status != EngagementStatus.EngClosed && list.status != EngagementStatus.EngAbandoned"
                    href="{{list.siteUrl}}" class="nonClickableLink">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open_black.svg"
                      style="vertical-align: sub;" alt="Link to SharePoint" />
                    Link to SharePoint</a>
                </li>
                <li class="list-group-item" *ngIf="!isClientEngagements">
                  <a *ngIf="list.siteUrl != '' && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned)"
                    href="{{list.siteUrl}}" target="_blank">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open.svg" style="vertical-align: sub;"
                      alt="Link to SharePoint" />
                    Link to SharePoint</a>
                  <a *ngIf="list.siteUrl === '' && (list.status === 'Active' || list.status === 'CMS code error' || list.status === 'Active (pending CMS codes)'  || list.status == EngagementStatus.EngActivePendingSignature || list.status === 'RP review' || list.status === 'EPPMD review' || list.status === 'Team review' || list.status === 'Draft' || list.status === EngagementStatus.EngDeclined || list.status === EngagementStatus.EngClosed || list.status === EngagementStatus.EngAbandoned)"
                    class="nonClickableLink">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open_black.svg"
                      style="vertical-align: sub;" alt="Link to SharePoint" />
                    Link to SharePoint</a>
                  <a *ngIf="list.status != 'Active' && list.status != 'Not started' && list.status != 'CMS code error' && list.status != 'Active (pending CMS codes)'  && list.status != EngagementStatus.EngActivePendingSignature  && list.status != 'RP review' && list.status != 'EPPMD review' && list.status != 'Team review' && list.status != 'Draft' && list.status != EngagementStatus.EngDeclined && list.status != EngagementStatus.EngClosed && list.status != EngagementStatus.EngAbandoned"
                    href="{{list.siteUrl}}" class="nonClickableLink">
                    <img src="../../../../assets/images/EDP/Dashboard/folder_open_black.svg"
                      style="vertical-align: sub;" alt="Link to SharePoint" />
                    Link to SharePoint</a>
                </li>
              </ul>
            </div>
            <div class="last-eng-activity">
              <div class="row p-0 m-0">
                <div class="col-8 p-0 m-0 text-align-left">
                  <span class="float-left">Last engagement activity:</span>
                  <span class="pl-1">{{ list.lastActivityDate | date: "MM/dd/yyyy"}}</span>
                </div>
                <div class="col-4 p-0 m-0 text-right">
                  <span *ngIf="list.workspaceOpenReviewPointsCount > 0" data-toggle="tooltip" data-custom-class="tooltipIndicators" data-html="true"
                    data-title="<div><b>Indicators legend</b><br/><br/>
                          <span class='tooltipBgIconColor'>
                            <img alt='add' src='../../../../assets/images/EDP/Dashboard/reviewNoteIndicator18.svg'/>
                          </span>
                          &nbsp;&nbsp;&nbsp;Open review notes<br/><br/>
  
                      <b>N/N</b>&nbsp;&nbsp;  Review notes with response / open<br/><br/>
                      
                      <span class='tooltipBgIconColorEng'>
                        <img alt='add'
                          src='../../../../assets/images/EDP/Dashboard/redo-24px.svg'/>
                      </span>
                      &nbsp;&nbsp;Continuance engagement<br/></br>
                      <span >
                        <img alt='add' 
                          src='../../../../assets/images/EDP/Dashboard/notStartedIndicator.svg'/>
                      </span>
                      &nbsp;&nbsp;Not started<br/><br/>
                  </div>">
                    <span style="background-color: #f5f5f5;padding:3px;border-radius: 50%;">
                      <img alt="add" style="height: 18px; width: 18px;"
                        src="../../../../assets/images/EDP/Dashboard/reviewNoteIndicator.svg"
                       />
                       
                    </span>
                    <span *ngIf="list.workspaceRespondedReviewPointsCount > 0"  >
                      {{list.workspaceRespondedReviewPointsCount}}/</span>{{list.workspaceOpenReviewPointsCount}}
                  </span>
                  <span class="rounded-circle" style=" padding:3px; text-align: center; background-color: #F5F5F5; margin-right: 5px; "
                    *ngIf="list.reacceptanceEngagementId != null && list.reacceptanceEngagementId > 0">
                    <img style="width:18px; padding-bottom: 3px;" alt="add"
                      src="../../../../assets/images/EDP/Dashboard/redo-24px.svg"
                      data-toggle="tooltip" data-custom-class="tooltipIndicators" data-html="true"
                            data-title="<div><b>Indicators legend</b><br/><br/>
                                  <span class='tooltipBgIconColor'>
                                    <img alt='add' src='../../../../assets/images/EDP/Dashboard/reviewNoteIndicator18.svg'/>
                                  </span>
                                  &nbsp;&nbsp;&nbsp;Open review notes<br/><br/>
          
                              <b>N/N</b>&nbsp;&nbsp;  Review notes with response / open<br/><br/>
                              
                              <span class='tooltipBgIconColorEng'>
                                <img alt='add' 
                                  src='../../../../assets/images/EDP/Dashboard/redo-24px.svg'/>
                              </span>
                              &nbsp;&nbsp;Continuance engagement<br/><br/>
                              <span >
                                <img alt='add' 
                                  src='../../../../assets/images/EDP/Dashboard/notStartedIndicator.svg'/>
                              </span>
                              &nbsp;&nbsp;Not started<br/><br/>
                          </div>"/> 
                      
                  </span>
                  <span class="rounded-circle" style=" padding:3px; text-align: center; background-color: #F5F5F5 "
                  *ngIf="list.reacceptanceEngagementId != null && list.reacceptanceEngagementId > 0 && list.status == 'Not started'">
                  <img style="width:18px; padding-bottom: 3px;" alt="add"
                    src="../../../../assets/images/EDP/Dashboard/notStartedIndicator.svg"
                    data-toggle="tooltip" data-custom-class="tooltipIndicators" data-html="true"
                          data-title="<div><b>Indicators legend</b><br/><br/>
                                <span class='tooltipBgIconColor'>
                                  <img alt='add' src='../../../../assets/images/EDP/Dashboard/reviewNoteIndicator18.svg'/>
                                </span>
                                &nbsp;&nbsp;Open review notes<br/><br/>
        
                            <b>N/N</b>&nbsp;&nbsp;  Review notes with response / open<br/><br/>
                            
                            <span class='tooltipBgIconColorEng'>
                              <img alt='add' 
                                src='../../../../assets/images/EDP/Dashboard/redo-24px.svg'/>
                            </span>
                            &nbsp;&nbsp;Continuance engagement<br/><br/>
                            <span >
                              <img alt='add' 
                                src='../../../../assets/images/EDP/Dashboard/notStartedIndicator.svg'/>
                            </span>
                            &nbsp;&nbsp;Not started<br/><br/>
                        </div>"/> 
                    
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="NoDataMessage" class="action-label" *ngIf="cardCount == 0 || cardCount == null ">
        {{DataMessage}}
      </div>
    </div>
    <!-- CardView Pagination Start -->
    <div class="d-flex justify-content-center text-center pagination-section" *ngIf="cardCount > 0">
      <ul *ngIf="cardViewPager.pages && cardViewPager.pages.length" class="pagination mx-2">
        <li>
          <a id="cardViewFirst" [ngClass]="{isDisabled:cardViewPager.currentPage === 1}" (click)="cardViewSetPage(1)">
            <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="First page" />
          </a>
        </li>
        <li>
          <a id="cardViewPrevious" [ngClass]="{isDisabled:cardViewPager.currentPage === 1}"
            (click)="cardViewSetPage(cardViewPager.currentPage - 1)">
            <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="Prev page" />
          </a>

        </li>
        <li *ngFor="let page of cardViewPager.pages" [ngClass]="{active:cardViewPager.currentPage === page}">
          <a (click)="cardViewSetPage(page)">{{page}}</a>
        </li>

        <li>
          <a [ngClass]="{isDisabled:cardViewPager.currentPage === cardViewPager.totalPages}"
            (click)="cardViewSetPage(cardViewPager.currentPage + 1)">
            <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="Next page" />
          </a>
        </li>
        <li>
          <a [ngClass]="{isDisabled:cardViewPager.currentPage === cardViewPager.totalPages}"
            (click)="cardViewSetPage(cardViewPager.totalPages)">
            <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="Last page" />
          </a>
        </li>

      </ul>
      <div>
        <select id="cardViewdrprecordsPerPage" [(ngModel)]="cardViewnoOfrecordsPerPage" name="cardViewdrprecordsPerPage"
          (change)="cardViewSetPage(1)" class="page-counts">
          <option *ngFor="let cardViewnoOfrecordsPerPage of noOfrecordsPerPageList"
            value="{{cardViewnoOfrecordsPerPage}}">{{cardViewnoOfrecordsPerPage}}</option>
        </select>
      </div>
    </div>
    <!-- CardView Pagination End -->
  </div>
  <!--CardView Design End-->
</div>
<!-- List View and card view main container End-->
<!-- end MainContainer -->
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>
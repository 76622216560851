import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { DataService } from 'src/app/services/data.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { Applicationmaster } from 'src/app/model/ApplicationMaster';
import { SolutionFamily, TemplateDetails, UserClientDetails, UserDetails, tempalteDetailsRequestModel } from 'src/app/model/DocumentTemplate';
import { DocumentService } from 'src/app/services/DocumentService';
import { KeyValue } from 'src/app/model/KeyValue';
import { DownloadWorkItem, HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { ClientService } from 'src/app/services/clientservice';
import { EngagementService } from 'src/app/services/EngagementService';
import { ExecutionDocumentEnagments } from 'src/app/model/engagements';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/model/User';
declare var $;


@Component({
  selector: 'app-document-templates-dialog',
  templateUrl: './document-templates-dialog.component.html',
  styleUrls: ['./document-templates-dialog.component.css']
})
export class DocumentTemplatesDialogComponent implements OnInit, OnChanges {
  @Input() modalDisplay: boolean;
  // @Input() set prePopulatedClientId(id: number) {
  //   if (id !== undefined && id !== null && id > 0) {
  //     this.clientId = id;
  //   }
  //   else {
  //     this.clientId = -1;
  //   }
  // }
  // @Input() set prePopulatedEngagementId(id: number) {
  //   if (id !== undefined && id !== null && id > 0) {
  //     this.engagementId = id;
  //   }
  //   else {
  //     this.engagementId = -1;
  //   }
  // }

  serviceLine = ['Advisory', 'Audit', 'Tax'];
  templateConsiderationList: Applicationmaster[] = [];
  templateConsiderations: string[] = [];
  templateCategoryList: Applicationmaster[] = [];
  templateCategory: string[] = [];
  templateDestination: Applicationmaster[] = [];
  destinations: string[] = [];

  primarySolutionFamily: string[] = []
  templateSolutionFamily: SolutionFamily[] = [];

  templateDetails: TemplateDetails[] = [];
  templatekeyValueList: KeyValue[] = [];
  templates:string[]=[];
  templateKeyValue:KeyValue[]=[];
 

  clientId: number = -1;
  engagementId: number = -1;
  clientkeyValueList: KeyValue[] = [];
 

  isHidden: boolean;
  isDisabled: boolean;
  loggedInUser: any;
  userObject: any;
  selectedOption: string;
  dropdownOpen: boolean = false;
  selectedSolutionFamily: string = 'Select...';
  sortOrder: Applicationmaster[] = [];

  selectedConsiderations: string;
  selectedCategory: string[] = [];
  selectCategory: string;
  selectedTemplates:string[]=[];
  checkedTemplate: string;

  sharePointUrlCategoryText:string;
  categoryText:string;
  independenceConsiderationText:string;
  sharePointUrlIndependenceConsideration:string;
  independenceConsiderationPopoverVisible: boolean = false;

  selectedDestination: string = 'Select...';
  selectedEngagement: string = 'Select...';
  selectedClient: string = 'Select...'
  isSaveSelected: boolean = false;
  isPrimarySolutionFamily: boolean;
  isConsiderations: boolean;
  isCategory: boolean;
  isTemplate: boolean;
  isDestination: boolean;
  isClient: boolean;
  isEngagement: boolean;
  checkboxOpen: boolean = false;
  templateCheckboxOpen: boolean;
  createWorkItemData: HDWorkItemModel = new HDWorkItemModel();
  createWorkItemDataList: HDWorkItemModel[] = [];
  downloadWorkItem: DownloadWorkItem = new DownloadWorkItem();
  isValidData: boolean = true;
  userDetails:UserDetails=new UserDetails();
  userClientDetails:UserClientDetails=new UserClientDetails();
  engagements: ExecutionDocumentEnagments[]=[];
  engagementkeyValueList: KeyValue[] = [];
  addWorkspace_SiteUniqueId: string;
  templateDetailsRequest:tempalteDetailsRequestModel=new tempalteDetailsRequestModel();
  isTemplateSaved:boolean=false;
  independenceConsiderationCode: string;
  user: User;

  constructor(private authService: AuthService,private data: DataService,
    private loadingService: LoadingScreenService,
    private alertService: AlertsService,
    private workspaceService: WorkspaceService,
    private applicationMasterService: ApplicationMasterService,
    private documentService: DocumentService,
    private _hdService: HotDocsService,
    private clientService: ClientService,
    private engagementService: EngagementService,
  ) {
    this.isHidden = false;
    this.getAuthUser();

  }

  ngOnInit(): void {
    this.isHidden = this.modalDisplay;
    this.selectedOption = this.serviceLine[0];
    if(this.user !=null || this.user != undefined){
    this.getDownloadTemplatesConfiguration();
  }
  }
  private getAuthUser() {
    this.user = this.authService.getUserInfo();
  }

  toggleIndependenceConsiderationPopoverVisiblePopover(): void {
    this.independenceConsiderationPopoverVisible = !this.independenceConsiderationPopoverVisible;
  }

  closeIndependenceConsiderationPopover(): void {
    this.independenceConsiderationPopoverVisible = false;
  }

  getDownloadTemplatesConfiguration(){
   this.engagementService.GetDownloadTemplatesConfiguration().subscribe
    (result => {
      this.categoryText=result.categoryLabel;
      this.sharePointUrlCategoryText=result.categoryLink;
      this.independenceConsiderationText=result.independenceConsiderationLabel;
      this.sharePointUrlIndependenceConsideration=result.independenceConsiderationLink;
    });
   }

  ngOnChanges(changes: SimpleChanges) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    let nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.loggedInUser = nameJSON.userName;

    }
    this.getAuthUser();
    // console.log('Calling workspaceService.getAuthUser() to repopulate User Info.');
    // this.workspaceService.getAuthUser();
    for (const propName in changes) {
      if (propName === 'modalDisplay') {
        const change = changes[propName];
        let curVal = JSON.stringify(change.currentValue);
        const prevVal = JSON.stringify(change.previousValue);
        if (curVal) {
          curVal = curVal.replace(/['"]+/g, '');
          if (curVal !== prevVal) {
            this.isHidden = (/true/i).test(curVal);
          }
        }
      }
      // if (this.clientId > 0) {
      //   this.prepopulateData();
      // }
      // else {      
      //   this.BindClientData();
      // }
      if(this.user !=null || this.user != undefined){
      this.BindClientData();
      this.getTemplateConsideration();
      this.getTemplateCategory();
      this.getTemplateDestination();
      this.getPrimarySolutionFamily();
      this.getTemplateDetails();

      if (this.selectedDestination === 'Save to existing engagement') {
        this.isSaveSelected = true;
      }
    }

    }
  }

  closeDialog() {
    this.ClearNewDocumentDailog('All');
    this.clearValidationMessages();
    this.hideModal();
    this.independenceConsiderationPopoverVisible = false;
  }

  hideModal() {
    this.isHidden = false;
    const modalObj = {
      modalDisplay: false
    };
    const modalJSON = JSON.stringify(modalObj);
    this.data.setDocumentTemplateDialogDetails(modalJSON);
  }
  
  DisplaySystemAlert() {
    this.alertService.remove(new Alert());
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }
  getTemplateConsideration() {
    let applicationName = 'TemplateConsideration';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        this.templateConsiderationList = result.filter(data=>data.value !='Both');
        this.templateConsiderations = this.templateConsiderationList.map(item => item.value);
        // Sort the Template array with custom order (SEC first, then Non-SEC)     
        this.templateConsiderations.sort((a, b) => b.localeCompare(a));
      });

  }

  getTemplateCategory() {
  let applicationName = 'TemplateCategory';
  this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
    result => {
      this.sortOrder = result;
      this.templateCategoryList = result.sort((a, b) => a.displayOrder - b.displayOrder);
      this.templateCategory = this.templateCategoryList.map(item => item.value);
    });
  }

  getTemplateDestination() {
    let applicationName = 'TemplateDestination';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        this.templateDestination = result;
        this.destinations = this.templateDestination.map(item => item.value);
        // Sort the Template array with custom order (Save,Download)     
        this.destinations.sort((a, b) => b.localeCompare(a));

      });

  }
  getPrimarySolutionFamily() {
    if (this.selectedOption != undefined) {
      let serviceLine = this.selectedOption;
      this.documentService.getSolutionFamilyByServiceLine(serviceLine).subscribe(
        result => {
  
          this.primarySolutionFamily = result.map((item: any) => item.solutionFamily);
  
  
        });
  
    }
    
  }
  onDestinationChange() {
    if (this.selectedDestination === 'Save to existing engagement') {
      this.isSaveSelected = true;
     
    }
    else {
      this.isSaveSelected = false;
      this.selectedClient='Select...';
      this.selectedEngagement='Select...';
    }
  }

  Cancel() {
    this.ClearNewDocumentDailog('All');
    this.clearValidationMessages();
    this.hideModal();
    this.closeIndependenceConsiderationPopover();
  }
  clearValidationMessages() {
    this.isPrimarySolutionFamily = undefined;
    this.isConsiderations = undefined;
    this.isCategory = undefined;
    this.isClient = undefined;
    this.isTemplate = undefined;
    this.isDestination = undefined;
    this.isEngagement = undefined;

  }
  ClearNewDocumentDailog(type: string) {
    switch (type) {
      case 'All':
        if (this.serviceLine != undefined) {
          this.selectedOption = this.serviceLine[0];
        }
        this.selectedSolutionFamily = 'Select...';
        this.selectedCategory = [];
        this.selectedTemplates = [];
        this.selectedDestination = 'Select...';
        this.selectedEngagement = 'Select...';
        this.selectedClient = 'Select...';
        this.selectedConsiderations = '';
        this.isSaveSelected = false;
        this.selectCategory = '';
        this.checkedTemplate='';
        this.clientId=-1;
        this.engagementId=-1;
        this.templatekeyValueList = this.getMethod([], 'templatePackageId', 'templateName');
        this.templates=[];
        this.isTemplateSaved=false;
        this.engagementkeyValueList=[];
        this.clientkeyValueList=[];
        
        break;
       

    }
  }
  validateData() {
    
    if (this.selectedSolutionFamily == undefined || this.selectedSolutionFamily == 'Select...' || this.selectedSolutionFamily == '')
      this.isPrimarySolutionFamily = true;
    else
      this.isPrimarySolutionFamily = false;

    if (this.selectedConsiderations == undefined || this.selectedConsiderations == 'Select...' || this.selectedConsiderations == '')
      this.isConsiderations = true;
    else
      this.isConsiderations = false;

    if (this.selectedCategory == undefined || this.selectedCategory.length == 0)
      this.isCategory = true;
    else
      this.isCategory = false;

    if (this.selectedDestination == undefined || this.selectedDestination == 'Select...' || this.selectedDestination == '')
      this.isDestination = true;
    else
      this.isDestination = false;

    if (this.selectedTemplates == undefined || this.selectedTemplates.length == 0)
      this.isTemplate = true;

    else
      this.isTemplate = false;

    if ((this.clientId == undefined || this.clientId == -1) && this.isSaveSelected)
      this.isClient = true;

    else
      this.isClient = false;

    if ( (this.engagementId == undefined || this.engagementId == -1) && this.isSaveSelected)
      this.isEngagement = true;

    else
      this.isEngagement = false;


    if (this.isPrimarySolutionFamily || this.isConsiderations || this.isCategory || this.isDestination || this.isTemplate) {
      this.isValidData = false;
     
    }
    else if (this.isSaveSelected) {
      if ((this.isClient || this.isEngagement) && !this.isValidData) {
        this.isValidData = false;
       
      }
      else {
        this.isValidData = true;
      }
    }
    else {
      this.isValidData = true;
    }
  }
  onServiceLineChange() {
    if (this.selectedOption != undefined) {
      this.selectedSolutionFamily = 'Select...';
      this.getPrimarySolutionFamily();
    }
  }
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }
  onRadioLabelClick(option: string): void {
    this.selectedSolutionFamily = option;
    this.clearTemplate();
    this.independenceConsiderationPopoverVisible = false;
  }
  isDropdownOpen(): boolean {
    return this.dropdownOpen;
  }

  //New Code
  toggleCheckbox() {
    this.checkboxOpen = !this.checkboxOpen;
    this.selectedTemplates=[];
    this.checkedTemplate='';
    
    
  }
  toggleCheckboxTemplate() {
    // this.templateCheckboxOpen = !this.templateCheckboxOpen;
    this.getTemplateDetails();
  }

  onCheckboxChange(category: string) {

    if (this.isChecked(category)) {

      this.selectedCategory = this.selectedCategory.filter(item => item !== category);
      if (this.selectedCategory.length > 1) {
        this.selectCategory = 'Multiple selections';
      }
      else {
        this.selectCategory = this.selectedCategory[0];
      }
  } else {
      this.selectedCategory.push(category);
      if (this.selectedCategory.length > 1) {
        this.selectCategory = 'Multiple selections';
      }
      else {
        this.selectCategory = category;
      }

    }

    this.checkboxOpen = false;
    
  }
  isChecked(category: string): boolean {

    return this.selectedCategory.includes(category);

  }
  isCheckboxOpen(): boolean {
    return this.checkboxOpen;
  }
 ''
  isTemplateCheckboxOpen(): boolean {
    return this.templateCheckboxOpen;
  }

  getTemplateDetails() {

    if(this.selectedConsiderations!=null && this.selectedSolutionFamily!='' && this.selectedCategory.length!=0 && this.selectedCategory!=undefined){
      this.templateDetailsRequest.IndependenceConsiderations=this.selectedConsiderations;
      this.templateDetailsRequest.SolutionFamily=this.selectedSolutionFamily;
      this.templateDetailsRequest.TemplateCategory=this.selectedCategory.join(',')
      this.documentService.getTemplateDetails(this.templateDetailsRequest).subscribe(
        result => {
          this.templateDetails = result;
          this.templates = this.templateDetails.map(item => item.templateName);
          this.templatekeyValueList = this.getMethod(result, 'templatePackageId', 'templateName');
        });
  
    }
   else{
    this.templatekeyValueList = this.getMethod([], 'templatePackageId', 'templateName');
   }
   
  }
  getMethod(array: any, keyProperty: string, valueProperty) {
    let keyValueList: KeyValue[] = [];
    array.forEach(element => {
      let kv = new KeyValue();
      kv.key = element[keyProperty];
      kv.value = element[valueProperty];
      keyValueList.push(kv);
    });
    return keyValueList;
  }

  base64ToArrayBuffer(base64: any): ArrayBuffer {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  DownlodTemplateWorkItem() {
    this.templateCheckboxOpen = false;
    this.loadingService.startLoading();
    this.independenceConsiderationPopoverVisible = false;
    this.validateData();
    this.checkboxOpen = false;
    if (this.selectedTemplates.length > 0 && this.isValidData) {    
      this.applicationMasterService.GetApplicationMasterDataByNameAndValue("Template Consideration", this.selectedConsiderations).subscribe(applicationMasterData => {             
        if( applicationMasterData.length>0){
          this.independenceConsiderationCode = applicationMasterData[0].code;  
          this.buildDowmloadTemplateData(false);
          this._hdService.CreateDownloadTemplateWorkitem(this.downloadWorkItem).subscribe(workItemResult => {
            var content = this.base64ToArrayBuffer(workItemResult.fileByteArray);
            var blob = new Blob();
            if (this.createWorkItemDataList.length === 1) {
              blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
            }
            else {
              blob = new Blob([content], { type: 'application/zip' })
            }
  
            this.loadingService.stopLoading();
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', workItemResult.fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          },
          error => {
            this.loadingService.stopLoading();
          });
        }
     
      },
      error => {
        this.loadingService.stopLoading();
      });  
    }
    else {
      this.loadingService.stopLoading();

    }
  }

  SaveTemplateWorkItem() {
    this.templateCheckboxOpen = false;
    this.independenceConsiderationPopoverVisible = false;
    this.validateData();
    this.checkboxOpen = false;
    this.applicationMasterService.GetApplicationMasterDataByNameAndValue("Template Consideration", this.selectedConsiderations).subscribe(applicationMasterData => {      
      this.loadingService.startLoading();
      if( applicationMasterData.length>0){
        this.independenceConsiderationCode = applicationMasterData[0].code;
        this.buildDowmloadTemplateData(true);
      if (this.selectedTemplates.length > 0 && this.isValidData) {
        this._hdService.SaveTemplateWorkitemToSharepoint(this.downloadWorkItem).subscribe(workItemResult => {
          this.loadingService.stopLoading();
          this.isTemplateSaved=true;
          this.closeDialog();
          $('#modal-exportDocumentSaved').modal({ backdrop: 'static', keyboard: false });
          $('#modal-exportDocumentSaved').modal('show');
        },
          error => {
            this.loadingService.stopLoading();
          });
      }
      else {
        this.loadingService.stopLoading();
      }
      }
     else{
      this.loadingService.stopLoading();
     }
      
    },
    error => {
      this.loadingService.stopLoading();
    });
  }

  buildDowmloadTemplateData(isForSaveToSP: boolean){    
    this.createWorkItemDataList = [];
	  this.downloadWorkItem = new DownloadWorkItem();
    this.selectedTemplates.forEach(element => {
      this.createWorkItemData = new HDWorkItemModel();
      this.templateKeyValue=this.templatekeyValueList.filter(item =>item.value ===element);
      this.createWorkItemData.name = "download_Template_";
      this.createWorkItemData.templatePackageId = this.templateKeyValue[0].key;
      this.createWorkItemDataList.push(this.createWorkItemData);
    });
    this.downloadWorkItem.independenceConsiderations = this.independenceConsiderationCode;
    this.downloadWorkItem.workItemList = this.createWorkItemDataList;
    if(isForSaveToSP){
      this.downloadWorkItem.siteuniqueId = this.engagements.filter(item =>item.engagementId == this.engagementId)[0].siteUniqueID;  
      this.downloadWorkItem.primarySolutionCode = this.engagements.filter(item =>item.engagementId == this.engagementId)[0].primarySolutionCode;  
      this.downloadWorkItem.engagementPPMD = this.engagements.filter(item =>item.engagementId == this.engagementId)[0].eppmd;   
      this.downloadWorkItem.independenceConsiderations = this.independenceConsiderationCode;
      this.downloadWorkItem.engagementId = this.engagementId;
      this.downloadWorkItem.engagementName = this.engagementkeyValueList.filter(item =>item.key == this.engagementId.toString())[0].value;        
      this.downloadWorkItem.isSaveToSharepoint = true;
      this.downloadWorkItem.clientName = this.clientkeyValueList.filter(item =>item.key == this.clientId.toString())[0].value;
    }
  }

  onDestinationClick() {
    this.templateCheckboxOpen = false;
  }

toggleItem(item)
 {     
 item.checked = !item.checked;  

 }
 onDropdownClick(event: MouseEvent): void {
  event.stopPropagation(); // Prevents the click event from reaching the parent dropdown
}
 
 prepopulateData() {
  this.clearValidationMessages();
  this.userDetails.loggedInUserEmail=this.loggedInUser;
  this.clientService.GetClientsByUserId(this.userDetails).subscribe(clients => {
    this.clientkeyValueList = this.getMethod(clients, 'clientId', 'clientName');
    if (this.clientId > 0 && this.clientId != null) {
      let result1 = clients.filter(en => en.clientId == this.clientId);
      if (result1.length > 0) {
        this.clearValidationMessages();
        this.userClientDetails.loggedInUserEmail=this.loggedInUser;
        this.userClientDetails.clientId=this.clientId;
        this.engagementService.GetActiveEngagementsByClientId(this.userClientDetails).subscribe
        (engagments => {
        this.engagements=engagments;
          this.engagementkeyValueList = this.getMethod(engagments, 'engagementId', 'engagementName');
          if (this.engagementId > 0 && this.engagementId != null) {
            let result2 = this.engagements.filter(en => en.engagementId == this.engagementId)
            if (result2.length > 0) {
              this.addWorkspace_SiteUniqueId = result2[0].siteUniqueID;        
            }
            else {
              this.engagementId = -1;
            }

          }
          else {
            this.engagementId = -1;
          }
        });
      }
    }

  });
   
}
BindClientData() {
  if (this.loggedInUser !== null && this.loggedInUser !== undefined) {
    this.userDetails.loggedInUserEmail=this.loggedInUser;
    this.clientService.GetClientsByUserId(this.userDetails).subscribe(clients =>  {
      this.clientkeyValueList = this.getMethod(clients, 'clientId', 'clientName');
    });
  }
}
onClientsChange() {
   this.clearValidationMessages();
  //this.engagementId = -1;
  // this.ClearNewDocumentDailog('All');
  this.BindEngagementData(this.clientId);
 
}
BindEngagementData(clientId: number) {
  this.clearValidationMessages();
  this.userClientDetails.loggedInUserEmail=this.loggedInUser;
  this.userClientDetails.clientId=clientId;
  this.engagementService.GetActiveEngagementsByClientId(this.userClientDetails).subscribe(result => {
    this.engagements = result;
    this.engagementkeyValueList = this.getMethod(result, 'engagementId', 'engagementName');
  });

}
clearTemplate(){
  this.selectedTemplates=[];
  this.checkedTemplate='';

}
onTemplateChange(template: string) {

  if (this.isTemplateChecked(template)) {

    this.selectedTemplates = this.selectedTemplates.filter(item => item !== template);
    if (this.selectedTemplates.length > 1) {
      this.checkedTemplate = 'Multiple selections';
    }
    else {
      this.checkedTemplate = this.selectedTemplates[0];
    }
} else {
    this.selectedTemplates.push(template);
    if (this.selectedTemplates.length > 1) {
      this.checkedTemplate = 'Multiple selections';
    }
    else {
      this.checkedTemplate = template;
    }

  }

  this.templateCheckboxOpen = false;
  
}
isTemplateChecked(template: string): boolean {

  return this.selectedTemplates.includes(template);

}

}

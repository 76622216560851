import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DelegateApproval, DelegateNotification } from '../model/Common/DelegateApproval';
import { UsersDetails } from '../model/SharePoiint/ActiveDirectory';
import { AppConfigService } from './appConfigService';

@Injectable({
  providedIn: 'root'
})
export class DelegateService {

  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  userName: string;
  userFullName: string;

  triggerNotification: boolean = false;
  delegateNotification: DelegateNotification = new DelegateNotification();
  constructor(private environment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = this.environment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  InitializeUserDetails(){
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.userFullName = nameJSON.loggedInUserFullName;
    }
    this.setTriggerNotification();
  }

  GetDelegateApproval(lob: string): Observable<DelegateApproval> {
    const delegate = { delegateFrom: this.userName, lineOfBusiness: lob };
    return this.http.post<DelegateApproval>(
      this.APIEndPoint + '/DelegateApproval/GetDelegateApproval/', delegate
    );
  }

  setTriggerNotification() {
    this.validateUserIsPartOfAuditADGroup(this.userName)
      .subscribe(result => {
        this.triggerNotification = result;
        if (result) {
          this.setDistributionList();
        }
      }, error => {
        console.error('error in validateUserIsPartOfAuditADGroup', error);
      }
      );
  }

  setDistributionList() {
    this.getDistributionList()
      .subscribe(result => {
        this.delegateNotification.usersDetails = result;
      }, error => {
        console.error('error in getDistributionList', error);
      }
      );
  }
  
  StoreDelegateApproval(delegate: DelegateApproval): Observable<string> {
    // override the delegateFrom and CreatedBy with logged in user details
    delegate.delegateFromEmailId = this.userName;
    delegate.delegateFromName = this.userFullName;
    delegate.createdBy = this.userName;

    this.delegateNotification.delegateApproval = delegate;
    this.delegateNotification.isTriggerNotification = this.triggerNotification;


    const requestOptions: object = {
      responseType: 'text'
    };

    return this.http.post<string>(
      this.APIEndPoint + '/DelegateApproval/StoreDelegateApproval', this.delegateNotification, requestOptions
    );
  }

  UpdateDelegateApproval(updateDelegate: DelegateApproval): Observable<string> {
    // override the delegateFrom and CreatedBy with logged in user details
    updateDelegate.delegateFromEmailId = this.userName;
    updateDelegate.delegateFromName = this.userFullName;
    updateDelegate.createdBy = this.userName;

    this.delegateNotification.delegateApproval = updateDelegate;
    this.delegateNotification.isTriggerNotification = this.triggerNotification;


    const requestOptions: object = {
      responseType: 'text'
    };

    return this.http.post<string>(
      this.APIEndPoint + '/DelegateApproval/UpdateDelegateApproval', this.delegateNotification, requestOptions
    );
  }

  ClearDelegateApproval(delegateFrom: string): Observable<string> {
    const updateDelegate = { delegateFromEmailId: delegateFrom, createdBy: this.userName };
    const requestOptions: object = {
      responseType: 'text'
    };

    return this.http.post<string>(
      this.APIEndPoint + '/DelegateApproval/ClearDelegateApproval', updateDelegate, requestOptions
    );

  }

  validateUserIsPartOfAuditADGroup(userPrincipalName: string): Observable<boolean> {
    return this.http.post<boolean>(this.IntegrationAPIEndPoint + '/User/validateUserIsPartOfAuditADGroup',
      { UserPrincipalName: userPrincipalName });
  }


  getDistributionList(): Observable<UsersDetails[]> {
    return this.http.get<UsersDetails[]>(this.IntegrationAPIEndPoint + '/Graph/GetAuditDistributionListFromADGroup');
  }
}

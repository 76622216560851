import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CLMDetails } from 'src/app/model/CLM/clm-details';
import { SPDocumentList } from 'src/app/model/SharePoiint/SharePointSite';
import { SummaryReport } from 'src/app/model/SummaryReport/SummaryReport';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { SharepointService } from 'src/app/services/sharepoint.service';
import { WorkspaceService } from 'src/app/services/workspace.service';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css']
})
export class SummaryReportComponent implements OnInit {

  acceptanceDocuments: SPDocumentList[] = [];
  supplementalDocuments: SPDocumentList[] = [];

  report: SummaryReport;
  @Input() siteUrl: string;
  @Input() clmDetails: CLMDetails;
  gaEffectiveDate: string = "";

  reportDate: string;
  @Input() set workspaceId(newValue: string) {
    if (newValue && newValue !== undefined && newValue !== null) {
      const localReportDate = new Date();
      this.reportDate = localReportDate.toLocaleDateString();
      console.log('Report Date', this.reportDate);
      this.GetEngageSummaryReport(newValue);
    }
  }

  @Output() onGenerateStatus = new EventEmitter<string>();

  constructor(private workspaceService: WorkspaceService,
    private sharePointService: SharepointService,
    private graphService: GraphService,
    private loadingScreenService: LoadingScreenService,
    private datePipe: DatePipe) {

  }

  ngOnInit() {

  }

  GetEngageSummaryReport(reviewId: string) {
    console.log('GetEngageSummaryReport func called ', reviewId);
    this.loadingScreenService.startLoading();
    this.gaEffectiveDate = this.datePipe.transform(this.clmDetails.effectiveDate, 'MM/dd/yyyy');
    this.workspaceService.GetEngageSummaryReport(reviewId).subscribe(
      result => {
        this.loadingScreenService.startLoading();
        this.report = result;

        this.graphService.GetContactInfo(this.report.reportHeader.epEmail).subscribe(contactResult => {
          this.loadingScreenService.startLoading();
          this.report.reportHeader.responsibleOffice = contactResult.officeLocation;

          this.GetAllDocumentsFromSPLibrary(this.siteUrl, 'Acceptance Documents/Acceptance').subscribe(
            acceptanceList => {
              this.loadingScreenService.startLoading();
              this.acceptanceDocuments = acceptanceList;
              this.GetAllDocumentsFromSPLibrary(this.siteUrl, 'Acceptance Documents/Supplemental').subscribe(
                supplementList => {
                  this.loadingScreenService.startLoading();
                  this.supplementalDocuments = supplementList;

                  console.log('GetEngageSummaryReport ', this.report, result, this.acceptanceDocuments, this.supplementalDocuments);
                  this.onGenerateStatus.emit('Success');
                  // setTimeout(() => { 
                  //   this.onGenerateStatus.emit('Success'); }, 5000);
                });
            });
        });

        this.ConvertImageToBase64();
      },
      error => {
        this.loadingScreenService.stopLoading();
        console.error('Error in getting GetEngageSummaryReport ', error);
        this.onGenerateStatus.emit('Failure');
      }
    );
  }

  ConvertImageToBase64() {
    let imgToExport = document.getElementById('logo') as HTMLImageElement;
    let base64value = this.getBase64Image(imgToExport);
    // console.log(base64value);
    imgToExport.src = 'data:image/jpg;base64,' + base64value;
  }

  GetAllDocumentsFromSPLibrary(siteURL: string, documentFolderPath: string): Observable<SPDocumentList[]> {
    console.log('GetAllDocumentsFromSPLibrary ', siteURL, documentFolderPath);
    return this.sharePointService.GetDocumentsFromFolderInLibrary(siteURL, documentFolderPath);
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    let canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}

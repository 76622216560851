export class RetentionExtensionModel {   
    engagementId: number;
    isRetentionExtensionRequired:boolean;
    noOfYearsExtension: number; 
    isGetExtentionData: boolean;   
    sectionShortName: string;
    loggedInUser: string;
    loggedInUserFullName: string;
}

export class RetentionExtensionDetailsModel {    
    isRetentionExtensionRequired:boolean;
    noOfYearsExtension: number; 
}
import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Assignments } from './../../../model/assignments.model';
import { AssignmentFilter } from './../../../model/assignment-filter.model';
import { EngagementService } from './../../../services/EngagementService';
import { AssignmentUniqueDataModel, AssignmentStatusModel, CmseppmdModel, EQCRModel } from './../../../model/assignment-unique-data.model';
import { AssignmentDashboardService } from './../../../services/assignment-dashboard.service';
import { ImageModel, HeaderModel, ContactCardControl, ContactCardClick, EngagementList } from './../../../model/EngagementListModel';
import { PagerService } from './../../../services/pager.service';
import { Engagements, EngagementStatus } from './../../..//model/Engagement/Engagements';
import {
  ClientModel, SolutionCodeModel, EppmdModel, IndustryModel, EngagementNameModel
} from './../../..//model/EngagementFilterModel';
import { UserPreferenceData } from 'src/app/model/UserPreferenceModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

declare var $;

@Component({
  selector: 'app-assignment-list-view',
  templateUrl: './assignment-list-view.component.html',
  styleUrls: ['./assignment-list-view.component.css']
})
export class AssignmentListViewComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  iShortcutCount: number;
  assignmentCount = 0;
  listViewNoOfRecordsPerPage = 10;
  listViewCurrentPageNumber = 1;
  noOfRecordsPerPageList: number[];

  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

  orderByColumn: string;
  orderByType: string;

  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];

  userObject: any;
  isLoggedInUserAdmin: string;

  selectedAssignmentCodeId: number;
  assignmentsList: Assignments[] = [];
  engagementCardList: EngagementList[] = [];
  selectContactEmailId: string;

  applyFilterOnceAfterDataLoad: boolean;
  listCount: number = null;

  DataMessage: string;
  listDataMessage: string;
  selectedEngagement: Engagements = new Engagements();
  assignmentFilterUniqueDataList: AssignmentUniqueDataModel;
  clientsList: ClientModel[] = [];
  solutionCodeList: SolutionCodeModel[] = [];
  eppmdList: EppmdModel[] = [];
  cmseppmdList: CmseppmdModel[] = [];
  industryList: IndustryModel[] = [];
  assignmentStatusList: AssignmentStatusModel[] = [];

  ClientMappedValue: string;
  ClientListMappedValue: string;
  userName: string;
  clientsId = '';

  assignmentFilter: AssignmentFilter;

  sortLastActivityDate = 0;
  sortOrderByType = '';
  eqcrList: EQCRModel[];
  engagementNameList: EngagementNameModel[];
  userPreferenceData:UserPreferenceData[];
  assignmentUserPreference:UserPreferenceData = null;
  userPrefData : UserPreferenceData;
 
  constructor(private configService: ConfigurationService  ,private engService: EngagementService, private route: Router, private pagerService: PagerService,
    private loadingScreenService: LoadingScreenService, private assignmentsDashboardService: AssignmentDashboardService) {
    const IShortcutCount = localStorage.getItem('iShortcutCount');
    this.iShortcutCount = JSON.parse(IShortcutCount);
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    this.DataMessage = 'Loading...';
    this.listDataMessage = 'Loading...';
    this.ClientMappedValue = 'ClientMappedValue';
    this.ClientListMappedValue = 'ClientListMappedValue';
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
    }
    var userPreferenceString = localStorage.getItem('userpreferencelist');
    this.userPreferenceData = JSON.parse(userPreferenceString);
    console.log(this.userPreferenceData);
    if(this.userPreferenceData.length > 0){
    this.userPreferenceData.forEach(value => {
      if(value.dashBoardName === "Assignments"){
        this.assignmentUserPreference = value;
      }
    });
  }
  }

  ngOnInit() {
    $('#viewFilter').hide();
    this.assignmentFilter = new AssignmentFilter(0, 0, '', '', 0, '','',0, this.userName);
    if(this.userPreferenceData.length > 0 && this.assignmentUserPreference != null){
    this.assignmentFilter = JSON.parse(this.assignmentUserPreference.listViewFilterList);
    this.orderByColumn =  JSON.parse(this.assignmentUserPreference.listViewSortingList).orderByColumn;
    this.listViewNoOfRecordsPerPage = this.assignmentUserPreference.listViewPageNumber;
    if(this.listViewNoOfRecordsPerPage == undefined || this.listViewNoOfRecordsPerPage == null){
      this.listViewNoOfRecordsPerPage = 10;
    }
    this.orderByType = JSON.parse(this.assignmentUserPreference.listViewSortingList).orderByType;
    

    }
    
    this.setPageRecordRange();
    this.setDefaultInitialization();
    this.loadListViewHeaders();
    this.isLoggedinUserSystemAdmin();
    const contactPopup = $('#contact-popup');
    contactPopup.hide();
    if(this.assignmentFilter.clientId > 0 || this.assignmentFilter.assignmentStatus != "" || this.assignmentFilter.cmseppmdEmail != ""
        || this.assignmentFilter.engagementId > 0 || this.assignmentFilter.eppmdEmail != "" || this.assignmentFilter.eqcrEmail !=""
          || this.assignmentFilter.industryTypeId > 0 || this.assignmentFilter.solutionCodeId > 0){
            $("#filterAssignmentListView").addClass('partialActive');
          }
    this.getMyAssignmentByUserCount();
    this.getAssignmentFilterSectionUniqueData();
    
    
  }

  getAssignmentFilterSectionUniqueData() {
    this.assignmentsDashboardService.getAssignmentFilterSectionUniqueData(this.isLoggedInUserAdmin).subscribe(
      result => {
        this.assignmentFilterUniqueDataList = result;
        this.clientsList = this.assignmentFilterUniqueDataList.clientsList;
        this.solutionCodeList = this.assignmentFilterUniqueDataList.solutionCodeList;
        this.eppmdList = this.assignmentFilterUniqueDataList.eppmdList;
        this.cmseppmdList = this.assignmentFilterUniqueDataList.cmseppmdList;
        this.industryList = this.assignmentFilterUniqueDataList.industryList;
        this.assignmentStatusList = this.assignmentFilterUniqueDataList.assignmentStatusList;
        this.eqcrList = this.assignmentFilterUniqueDataList.eqcrList;
        this.engagementNameList = this.assignmentFilterUniqueDataList.engagementNameList;
        
      },
      error => {
      }
    );
  }

  showAllAssignmentsWithNoFilters() {
    this.listViewCurrentPageNumber = 1;
    this.clearListViewFilterFields();
    this.InsertUpdateUserPreferenceData();
    this.getMyAssignmentByUserCount();
    $("#filterAssignmentListView").removeClass('active');
    if(!(this.assignmentFilter.clientId > 0 || this.assignmentFilter.assignmentStatus != "" || this.assignmentFilter.cmseppmdEmail != ""
        || this.assignmentFilter.engagementId > 0 || this.assignmentFilter.eppmdEmail != "" || this.assignmentFilter.eqcrEmail !=""
          || this.assignmentFilter.industryTypeId > 0 || this.assignmentFilter.solutionCodeId > 0)){
            $("#filterAssignmentListView").removeClass('partialActive');
          }
    $("#showAllAssignmentListView").addClass('active');

  }

  getAllAssignmentListByFilters() {
    this.listViewCurrentPageNumber = 1;
    this.InsertUpdateUserPreferenceData();
    $("#filterAssignmentListView").removeClass('active');
    if(this.assignmentFilter.clientId > 0 || this.assignmentFilter.assignmentStatus != "" || this.assignmentFilter.cmseppmdEmail != ""
        || this.assignmentFilter.engagementId > 0 || this.assignmentFilter.eppmdEmail != "" || this.assignmentFilter.eqcrEmail !=""
          || this.assignmentFilter.industryTypeId > 0 || this.assignmentFilter.solutionCodeId > 0){
            $("#filterAssignmentListView").addClass('partialActive');
          }
    if(!(this.assignmentFilter.clientId > 0 || this.assignmentFilter.assignmentStatus != "" || this.assignmentFilter.cmseppmdEmail != ""
          || this.assignmentFilter.engagementId > 0 || this.assignmentFilter.eppmdEmail != "" || this.assignmentFilter.eqcrEmail !=""
            || this.assignmentFilter.industryTypeId > 0 || this.assignmentFilter.solutionCodeId > 0)){
              $("#filterAssignmentListView").removeClass('partialActive');
            }         
    this.getMyAssignmentByUserCount();
    $('#viewFilter').hide();
  }

  showHideListFilterPanel(selectedView: string) {
    switch (selectedView) {
      case 'Show':
        $('#viewFilter').show();
        $('#filterAssignmentListView').addClass('active');
        $('#showAllAssignmentListView').removeClass('active');
        break;
      case 'Hide':
        $('#viewFilter').hide();
        $('#filterAssignmentListView').removeClass('active');
        $('#showAllAssignmentListView').removeClass('active');
        break;
      case 'HideWithCancel':
        this.clearListViewFilterFields();
        this.getMyAssignmentByUserCount();
        $('#filterAssignmentListView').removeClass('active');
        $('#showAllAssignmentListView').removeClass('active');
        break;
      case 'Reset':
        this.resetListViewFilterFields();
        this.InsertUpdateUserPreferenceData();
        if(!(this.assignmentFilter.clientId > 0 || this.assignmentFilter.assignmentStatus != "" || this.assignmentFilter.cmseppmdEmail != ""
        || this.assignmentFilter.engagementId > 0 || this.assignmentFilter.eppmdEmail != "" || this.assignmentFilter.eqcrEmail !=""
          || this.assignmentFilter.industryTypeId > 0 || this.assignmentFilter.solutionCodeId > 0)){
            $("#filterAssignmentListView").removeClass('partialActive');
          }
        this.getMyAssignmentByUserCount();
        // $('#filterAssignmentListView').removeClass('active');
        // $('#showAllAssignmentListView').removeClass('active');
        break;
      default:
        $('#viewFilter').hide();
        $('#filterAssignmentListView').removeClass('active');
        $('#showAllAssignmentListView').removeClass('active');
        break;
    }
  }

  clearListViewFilterFields() {
    this.assignmentFilter = new AssignmentFilter(0, 0, '', '', 0, '','',0, this.userName);
    $('#txtValueClientListMappedValue0').val('');
    $('#viewFilter').hide();
  }

  resetListViewFilterFields() {
    this.assignmentFilter = new AssignmentFilter(0, 0, '', '', 0, '','',0, this.userName);
    $('#txtValueClientListMappedValue0').val('');
    //$('#viewFilter').hide();
  }


  ngAfterViewChecked() {
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.bindContactPopup();
      this.applyFilterOnceAfterDataLoad = false;
    }
  }

  closeContactPopupListView() {
    $('html').on('click', (e) => {

      if (typeof $(e.target).data('original-title') === 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#' + ContactCardClick.listViewInput).val();
        $('#cmsppmdContactPopup_' + id).popover('hide');
      }
    });

    $('body').on('click', function (e) {
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#cmsppmdContactPopup_' + id).popover('hide');
        }
      }
    });

  }


  closeContactPopupCardView() {

    $('html').on('click', (e) => {
      if (typeof $(e.target).data('original-title') === 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#cardViewtxtengId').val();
        $('#cardViewEPPMDcontactPopup_' + id).popover('hide');
      }
    });

    $('body').on('click', (e) => {
      console.log('body click event fired', $(e.target).is('.contact-close'));
      const target = e.target;
      const parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          console.log('condition met');
          console.log('listViewtxtengId ==>', $('#cardViewtxtengId').val());
          const id = $('#cardViewtxtengId').val();
          $('#cardViewEPPMDcontactPopup_' + id).popover('hide');
        }
      }
      // else {
      //     let strids = $('#listViewtxtengId').val();
      //      $('#ePPMDcontactPopup_'+ strids).popover('hide');
      // }
    });

  }

  closeContactPopupEPPMDView() {
    $('html').on('click', (e) => {
      if (typeof $(e.target).data('original-title') === 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#' + ContactCardClick.listViewInput).val();
        $('#eppmdContactPopup_' + id).popover('hide');

      }
    });

    $('body').on('click', function (e) {
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#eppmdContactPopup_' + id).popover('hide');
        }
      }
    });

  }
  closeContactPopupEPPMDTableView() {
    $('html').on('click', (e) => {
      if (typeof $(e.target).data('original-title') === 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#' + ContactCardClick.listViewInput).val();
        $('#eppmdContactPopupTable_' + id).popover('hide');

      }
    });

    $('body').on('click', function (e) {
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#eppmdContactPopupTable_' + id).popover('hide');
        }
      }
    });

  }
  closeContactPopupEQCRTableView() {
    $('html').on('click', (e) => {
      if (typeof $(e.target).data('original-title') === 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#' + ContactCardClick.listViewInput).val();
        $('#eqcrContactPopupTable_' + id).popover('hide');

      }
    });

    $('body').on('click', function (e) {
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#eqcrContactPopupTable_' + id).popover('hide');
        }
      }
    });

  }


  ngAfterViewInit() {
    this.closeContactPopupListView();
    this.closeContactPopupCardView();
    this.closeContactPopupEPPMDView();
    this.closeContactPopupEPPMDTableView();
    this.closeContactPopupEQCRTableView();
  }

  setDefaultInitialization() {
    this.applyFilterOnceAfterDataLoad = false;
    this.isLoggedInUserAdmin = 'no';
    this.orderByColumn = 'LastActivityDate';
    this.orderByType = 'DESC';
    if(this.userPreferenceData.length > 0  && this.assignmentUserPreference != null){
    this.orderByColumn = JSON.parse(this.assignmentUserPreference.listViewSortingList).orderByColumn;
    if(this.orderByColumn == undefined || this.orderByColumn == null) {
      this.orderByColumn = 'LastActivityDate';
    }
    this.orderByType = JSON.parse(this.assignmentUserPreference.listViewSortingList).orderByType;
    if(this.orderByType == undefined || this.orderByType == null) {
      this.orderByType = 'DESC';
    }
    }
  }

  isLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isLoggedInUserAdmin = 'yes';
          break;
        }
      }
    }
  }

  setPageRecordRange() {
    this.noOfRecordsPerPageList = [];
    this.noOfRecordsPerPageList.push(10);
    this.noOfRecordsPerPageList.push(20);
    this.noOfRecordsPerPageList.push(40);
    this.noOfRecordsPerPageList.push(60);
    this.noOfRecordsPerPageList.push(80);
    this.noOfRecordsPerPageList.push(100);
  }

  getMyAssignmentByUserCount() {
    this.loadingScreenService.startLoading();
    console.log("assignmentfilterdata=====>",this.assignmentFilter);
    this.assignmentsDashboardService.getMyAssignmentByUserCount(this.isLoggedInUserAdmin, this.assignmentFilter).subscribe(
      result => {
        this.assignmentCount = result;
        if (this.assignmentCount > 0) {
          this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewNoOfRecordsPerPage);
        } else {
          this.loadingScreenService.stopLoading();
          this.listCount = 0;
          this.assignmentsList = null;
          if (this.listCount === 0) {
            this.listDataMessage = 'No Data Available';
          }
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
      }
    );
  }

  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service this.passedClientId
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewNoOfRecordsPerPage = recordsPerPage;
    } else {
      this.listViewNoOfRecordsPerPage = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();
    if (this.listViewCurrentPageNumber !== 0) {

      this.getMyAssignmentListView(this.listViewCurrentPageNumber, this.listViewNoOfRecordsPerPage, this.orderByColumn, this.orderByType);
      this.pager = this.pagerService.getPager(this.assignmentCount, this.listViewCurrentPageNumber, this.listViewNoOfRecordsPerPage);

      console.log('pager ==>', this.pager);
    } else {
      this.loadingScreenService.stopLoading();
      $('#previous').off('click');
      $('#first').off('click');
    }

  }
  setInitialsForListView() {
    if (this.listCount === 0) {
      this.headerModelList[0].imageModelList[0].isVisible = true;
      this.headerModelList[0].imageModelList[1].isVisible = false;
      this.headerModelList[0].imageModelList[2].isVisible = false;
    }
  }

  loadListViewHeaders() {
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.headerModel = {
      headerId: 1,
      headerName: 'Client name',
      headerValue: 'clientName',
      headerClick: true,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'clientName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = true;
        this.headerModelList[0].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[0].imageModelList[0].isVisible = false;
          this.headerModelList[0].imageModelList[1].isVisible = false;
          this.headerModelList[0].imageModelList[2].isVisible = true;
          }
    }

    this.headerModel = {
      headerId: 2,
      headerName: 'Engagement name',
      headerValue: 'engagementName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'engagementName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = true;
        this.headerModelList[1].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = false;
        this.headerModelList[1].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 3,
      headerName: 'Assignment name',
      headerValue: 'assignmentName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'assignmentName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = true;
        this.headerModelList[2].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = false;
        this.headerModelList[2].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 4,
      headerName: 'EPPMD',
      headerValue: 'EngagementPartnerName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'EngagementPartnerName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = true;
        this.headerModelList[3].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = false;
        this.headerModelList[3].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 5,
      headerName: 'CMS PPMD',
      headerValue: 'cmsPPMD',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'cmsPPMD'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = true;
        this.headerModelList[4].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = false;
        this.headerModelList[4].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 6,
      headerName: 'EQCR',
      headerValue: 'EQCRName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'EQCRName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = true;
        this.headerModelList[5].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = false;
        this.headerModelList[5].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 7,
      headerName: 'Assignment administrator',
      headerValue: 'assignmentAdministrator',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'assignmentAdministrator'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = true;
        this.headerModelList[6].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = false;
        this.headerModelList[6].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 8,
      headerName: 'Solution code',
      headerValue: 'solutionCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'solutionCode'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[7].imageModelList[0].isVisible = false;
        this.headerModelList[7].imageModelList[1].isVisible = true;
        this.headerModelList[7].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[7].imageModelList[0].isVisible = false;
        this.headerModelList[7].imageModelList[1].isVisible = false;
        this.headerModelList[7].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 9,
      headerName: 'Status',
      headerValue: 'status',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'status'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[8].imageModelList[0].isVisible = false;
        this.headerModelList[8].imageModelList[1].isVisible = true;
        this.headerModelList[8].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[8].imageModelList[0].isVisible = false;
        this.headerModelList[8].imageModelList[1].isVisible = false;
        this.headerModelList[8].imageModelList[2].isVisible = true;
        }
    }
    this.headerModel = {
      headerId: 10,
      headerName: 'Indus approval',
      headerValue: 'INDUSApproved',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'INDUSApproved'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[9].imageModelList[0].isVisible = false;
        this.headerModelList[9].imageModelList[1].isVisible = true;
        this.headerModelList[9].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[9].imageModelList[0].isVisible = false;
        this.headerModelList[9].imageModelList[1].isVisible = false;
        this.headerModelList[9].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 11,
      headerName: 'Assignment code',
      headerValue: 'assignmentCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'assignmentCode'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[10].imageModelList[0].isVisible = false;
        this.headerModelList[10].imageModelList[1].isVisible = true;
        this.headerModelList[10].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[10].imageModelList[0].isVisible = false;
        this.headerModelList[10].imageModelList[1].isVisible = false;
        this.headerModelList[10].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 12,
      headerName: 'Last activity',
      headerValue: 'LastActivityDate',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'LastActivityDate'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[11].imageModelList[0].isVisible = false;
        this.headerModelList[11].imageModelList[1].isVisible = true;
        this.headerModelList[11].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[11].imageModelList[0].isVisible = false;
        this.headerModelList[11].imageModelList[1].isVisible = false;
        this.headerModelList[11].imageModelList[2].isVisible = true;
        }
    }
    this.headerModel = {
      headerId: 13,
      headerName: '',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    this.headerModelList[12].imageModelList[0].isVisible = false;
    this.headerModelList[12].imageModelList[1].isVisible = false;
    this.headerModelList[12].imageModelList[2].isVisible = false;
    console.log('headerModelList ==>', this.headerModelList);

  }

  headerSort(headerId: number) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.headerModelList.length; i++) {
      if (this.headerModelList[i].headerId === headerId) {
        this.headerModelList[i].headerClick = true;
        if ((this.headerModelList[i].imageModelList[0].isVisible) || (this.headerModelList[i].imageModelList[2].isVisible)) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = true;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        } else if (this.headerModelList[i].imageModelList[1].isVisible) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = true;
        }
      } else {
        this.headerModelList[i].headerClick = false;
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.headerModelList[i].imageModelList.length; j++) {
          this.headerModelList[i].imageModelList[0].isVisible = true;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        }
      }
    }

    const headerObj = this.headerModelList.find(c => c.headerClick === true);
    this.orderByColumn = headerObj.headerValue;
    // tslint:disable-next-line: prefer-for-of
    for (let k = 0; k < headerObj.imageModelList.length; k++) {
      if (headerObj.imageModelList[k].isVisible === true) {
        this.orderByType = headerObj.imageModelList[k].imageValue;
        break;
      }
    }
    this.InsertUpdateUserPreferenceData();
    this.listViewSetPage(1, this.listViewNoOfRecordsPerPage);
  }

  getMyAssignmentListView(pageNumber: number, listViewNoOfRecordsPerPage, orderBy, orderType) {
    this.assignmentsDashboardService.getAssignmentsListView(this.assignmentFilter,
      this.sortLastActivityDate, pageNumber, listViewNoOfRecordsPerPage,
      this.isLoggedInUserAdmin, orderBy, orderType).subscribe(
        result => {
          this.assignmentsList = result;
          
          this.assignmentsList.forEach(item => {
            item.assignmentCode = item.assignmentCode.replace(/\.$/, '');
          });
          this.listCount = this.assignmentsList.length;
          this.loadingScreenService.stopLoading();
          console.log("aslength",this.listCount);
          console.log('assignmentslist ==>', this.assignmentsList);
          if (this.listCount === 0) {
            this.listDataMessage = ' No Data Available';
          } else if (this.listCount === null) {
            this.DataMessage = 'Loading...';
          } else { this.listDataMessage = ''; }

          this.applyFilterOnceAfterDataLoad = true;
          this.setInitialsForListView();

        },
        err =>{ console.log('Error in loading the client list information.');
        this.loadingScreenService.stopLoading();
      });
  }


  navigateEngagement(engagementId: number) {

    this.engService.GetEditEngagementDetailsById(engagementId).subscribe(
      result => {
        this.selectedEngagement = result;

        switch (this.selectedEngagement.status) {
          case 'Not started':
            this.route.navigate(['EditEngagement/' + this.selectedEngagement.engagementId]);
            break;
          case 'Draft':
            this.route.navigate(['Interviews/' + this.selectedEngagement.workspaceId]);
            break;
          case 'InReview':
          case 'Declined':
          case 'Abandoned':
            this.route.navigate(['Review/' + this.selectedEngagement.workspaceId]);
            break;
          case 'RP rule engine error':
            this.route.navigate(['Review/' + this.selectedEngagement.workspaceId]);
            break;
          case 'Pending CMS codes':
          case 'CMS code error':
          case 'Active':
          case 'Closed':
          case EngagementStatus.EngActivePendingSignature:
          case EngagementStatus.EngVoided:
            this.route.navigate(['EngagementDetail/' + this.selectedEngagement.engagementId]);
            break;
          default:
            break;
        }
      },
      err => {
        console.log('Error in getting workspace status.', err);
      }
    );
  }

  expandRow(assignmentCodeId) {
    this.selectedAssignmentCodeId = assignmentCodeId;
  }

  createContactPopupDiv(controlIdText: string, id: number) {
    $('#' + controlIdText + id).popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',    
      boundary: 'viewport',
      content: () => {
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  bindContactPopup() {
    this.assignmentsList.forEach(element => {
      this.createContactPopupDiv('cmsppmdContactPopup_', element.assignmentCodeId);
      this.createContactPopupDiv('cardViewEPPMDcontactPopup_', element.assignmentCodeId);
      this.createContactPopupDiv('eppmdContactPopupTable_', element.assignmentCodeId);
      this.createContactPopupDiv('eqcrContactPopupTable_', element.assignmentCodeId);
    });
  }

  displayContactPopup(assignmentCodeId: number, emailId: string) {
    this.selectContactEmailId = emailId;
    $('#listViewtxtengId').val(assignmentCodeId);
    // Close the pop up if any pop up open earlier
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#cmsppmdContactPopup_' + assignmentCodeId).popover('show');
  }

  displayEPPMDContactPopup(assignmentCodeId: number, emailId: string) {
    this.selectContactEmailId = emailId;
    $('#listViewtxtengId').val(assignmentCodeId);
    // Close the pop up if any pop up open earlier
    this.assignmentsList.forEach(element => {
      this.createContactPopupDiv('eppmdContactPopup_', element.assignmentCodeId);
    });
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#eppmdContactPopup_' + assignmentCodeId).popover('show');
  }
  displayEPPMDContactPopupTable(assignmentCodeId: number, emailId: string) {
    this.selectContactEmailId = emailId;
    $('#listViewtxtengId').val(assignmentCodeId);
    // Close the pop up if any pop up open earlier
    this.assignmentsList.forEach(element => {
      this.createContactPopupDiv('eppmdContactPopupTable_', element.assignmentCodeId);
    });
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#eppmdContactPopupTable_' + assignmentCodeId).popover('show');
  }
  displayEQCRContactPopupTable(assignmentCodeId: number, emailId: string) {
    this.selectContactEmailId = emailId;
    $('#listViewtxtengId').val(assignmentCodeId);
    // Close the pop up if any pop up open earlier
    this.assignmentsList.forEach(element => {
      this.createContactPopupDiv('eqcrContactPopupTable_', element.assignmentCodeId);
    });
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#eqcrContactPopupTable_' + assignmentCodeId).popover('show');
  }

  displayCardViewContactPopup(assignmentCodeId: number, emailId: string) {
    this.selectContactEmailId = emailId;
    $('#cardViewtxtengId').val(assignmentCodeId);
    // Close the pop up if any pop up open earlier
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#cardViewEPPMDcontactPopup_' + assignmentCodeId).popover('show');
  }

  hideAllPopUp() {
    if (this.assignmentsList !== null && this.assignmentsList !== undefined) {
      this.assignmentsList.forEach(element => {
        $('#cmsppmdContactPopup_' + element.assignmentCodeId).popover('hide');
        $('#cardViewEPPMDcontactPopup_' + element.assignmentCodeId).popover('hide');
        $('#eppmdContactPopup_' + element.assignmentCodeId).popover('hide');
        $('#eppmdContactPopupTable_' + element.assignmentCodeId).popover('hide');
        $('#eqcrContactPopupTable_' + element.assignmentCodeId).popover('hide');
      });
    }
  }
  InsertUpdateUserPreferenceData(){
    var  listViewFilterList : any;
    var  listViewSortList : any;
    if(this.userPreferenceData.length > 0  && this.assignmentUserPreference != null){
    this.assignmentUserPreference.userEmail = this.userObject.loggedInUserEmail;
    this.assignmentUserPreference.dashBoardName = 'Assignments';
    this.assignmentUserPreference.dashboardViewType = 'List';
    listViewFilterList = this.assignmentFilter;
    listViewSortList = this.assignmentFilter;
    listViewSortList.orderByColumn = this.orderByColumn;
    listViewSortList.orderByType = this.orderByType;
    this.assignmentUserPreference.listViewFilterList = JSON.stringify(listViewFilterList);
    this.assignmentUserPreference.listViewSortingList = JSON.stringify(listViewSortList);
    this.assignmentUserPreference.listViewPageNumber = this.listViewNoOfRecordsPerPage;
    this.userPrefData = this.assignmentUserPreference;        
    let index = this.userPreferenceData.findIndex(obj => obj.dashBoardName === "Assignments");
    this.userPreferenceData[index] = this.userPrefData;
    localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


    this.engService.InsertUpdateUserPreferenceData(this.userPrefData).subscribe(result =>{
          console.log(result);
    });
  }
  if((this.userPreferenceData.length == 0 || this.userPreferenceData.length > 0) && this.assignmentUserPreference == null ){
    var datatoinsert = new UserPreferenceData();
    listViewFilterList = this.assignmentFilter;
    listViewSortList = this.assignmentFilter;
    listViewSortList.orderByColumn = this.orderByColumn;
    listViewSortList.orderByType = this.orderByType;
    datatoinsert.userEmail = this.userObject.loggedInUserEmail;
    datatoinsert.dashBoardName = 'Assignments';
    datatoinsert.dashboardViewType = 'List';
    datatoinsert.cardViewPageNumber = this.listViewNoOfRecordsPerPage;
    datatoinsert.listViewPageNumber = this.listViewNoOfRecordsPerPage;
    datatoinsert.cardViewFilterList = JSON.stringify(listViewFilterList);
    datatoinsert.cardViewSortingList = JSON.stringify(listViewSortList);
    datatoinsert.listViewFilterList = JSON.stringify(listViewFilterList);
    datatoinsert.listViewSortingList = JSON.stringify(listViewSortList);
    this.userPreferenceData.push(datatoinsert);
    localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


    this.engService.InsertUpdateUserPreferenceData(datatoinsert).subscribe(result =>{
          console.log(result);
    });
  }
    
  }

  ngOnDestroy() {
    this.hideAllPopUp();
  }

  checkForContactContent(contact) {
    const timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 2000);
  }

  isValidateSiteURL(siteURL: string) {
    return (siteURL !== null && siteURL !== undefined && siteURL !== '');
  }

}

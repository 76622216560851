<div class="card">
    <div class="card-header justify-content-between d-flex border-bottom-0">
        <div class="card-heading">
            <a class="card-link">
                <h5 class="edp-font-14 edp-font-color font-weight-bold">Insight metrics</h5>
            </a>
        </div>
        <div class="blank-card"></div>
        <div class="card-icon" (click)="toggleUp()" *ngIf="!isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/desc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
        <div class="card-icon" (click)="toggleDown()" *ngIf="isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/asc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
    </div>
    <div id="collapseOne" class="card-header pt-0 mt-0" data-parent="#accordion" *ngIf="isImageEnabled">
        <p>Insight metrics coming soon...</p>
    </div>
</div>
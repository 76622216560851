export class EngagementESignature {
    id: number;
    engagmentId: number;
    signatureTypeId: number;
    signatureTypeCode: string;
    signatureTypeValue: string;
    handlerEmail: string;
    handlerName: string;
    sharePointDocuments: SharePointDocuments[] = [];
    envelopeStatusId: number;
    envelopeStatusCode: string;
    envelopeStatusValue: string;
    envelopeId: string;
    payLoad: string;
    createByEmailId: string;
    createdByName: string;
    createDate: Date;
    modifiedBy: string;
    modifiedDate: Date;
    envelopeUri: string;
    errorCode: string;
    errorMessage: string;
    isRemoved: boolean; 
    removedRemark: string;   
}

export class SharePointDocuments {
    sharePointDocumentId: number;
    sharePointDocumentName: string;
}

export enum SigningRequestDiscardRemark{
    SigningHandlerChanged = 'Signature handler has been changed',
    DocumentToSignChanged = 'Document to sign has been changed',
    SignatureTypeChangedToPrinted = 'Signature type has been changed to printed',
    SignatureTypeChangedToElectronic = 'Signature type has been changed to electronic',
    SigningHandlerAndDocumentToSignChanged = 'Signature handler and document to sign have been changed',
    SignerChanged = 'Document signer has changed',
    SignedDateChanged = 'Document signed date has changed',
    SignedDocumentChanged = 'Signed document has changed',
    SignedDocumentSignerAndSignedDateChanged = 'Signed document, signer and document signed date has changed',
    SignedDocumentAndSignerChanged = 'Signed document and signer has changed',
    SignerAndSignedDateChanged = 'Signer and signed date has changed',
    SignedDocumentAndSignedDateChanged = 'Signed document and signed date has changed'
}

import { Component, OnInit } from '@angular/core';
import { listviewService } from 'src/app/services/listviewService';
import { ReviewsListModel } from 'src/app/model/ReviewsListModel';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentList } from 'src/app/model/DocumentListModel';
import { MyReviewsService } from 'src/app/services/MyReviewsService';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { ContactCardControl, ContactCardClick, ImageModel, HeaderModel, EngagementApprovalsDataModel, DelegatedApprovalsDataModel } from 'src/app/model/EngagementListModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { PagerService } from 'src/app/services/pager.service';
import { ApprovalSortModel, UserPreferenceData } from 'src/app/model/UserPreferenceModel';
import { EngagementService } from 'src/app/services/EngagementService';
declare var $;

export enum ApproverStatus {
  AwaitingImmediateApproval = 1,
  AwaitingTieredApproval = 2,
  Approved = 3,
  Declined = 4,
  AwaitingImmediateAndTieredApproval = 5
}

export class ApprovalFilterModel {
  isAwaitingImmediateApproval: boolean = false;
  isAwaitingTieredApproval: boolean = false;
  isApproved: boolean = false;
  isDeclined: boolean = false;
}

@Component({
  selector: 'app-my-reviews-list',
  templateUrl: './my-reviews-list.component.html',
  styleUrls: ['./my-reviews-list.component.css'],
  providers: [listviewService, MyReviewsService]
})
export class MyReviewsListComponent implements OnInit {

  applyFilterOnceAfterDataLoad: boolean = false;
  applyFilterOnceAfterDataLoadForDelegates: boolean = false;

  page: number[] = [];
  myReviewsList: ReviewsListModel[] = [];
  documentList: DocumentList[] = [];
  selectedClientId: number;
  popupRecordCount: number;
  alertClient: Alert;
  errorFlag: boolean = false;
  isUserAgentIE: number;
  iShortcutCount: number;
  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];
  newImageModelForDelegates: ImageModel = new ImageModel();
  headerModelForDelegates: HeaderModel = new HeaderModel();
  headerImageModelListForDelegates: ImageModel[] = [];
  headerModelListForDelegates: HeaderModel[] = [];
  listViewCurrentPageNumber: number = 1;
  listViewNoOfrecordsPerPage: number = 20;
  listViewCurrentPageNumberForDelegates: number = 1;
  listViewNoOfrecordsPerPageForDelegates: number = 20;
  orderByColumn: string;
  orderByType: string;
  allApprovalCount: number = 0;
  allApprovalList: EngagementApprovalsDataModel[] = [];
  orderByColumnForDelegates: string;
  orderByTypeForDelegates: string;
  allApprovalCountForDelegates: number = 0;
  allApprovalListForDelegates: DelegatedApprovalsDataModel[] = [];
  selectContactEmailId: string;
  noOfrecordsPerPageList: number[];
  noOfrecordsPerPageListForDelegates: number[];
  DataMessage: string;
  DataMessageForDelegates: string;

  userPreferenceData:UserPreferenceData[];
  approvalsUserPreference:UserPreferenceData = null;
  userPrefData : UserPreferenceData;
  userObject: any;
  isDelegateddashBoardActive: boolean = false;
  isMyApprovalTabActive: boolean = false;
  isApprovalMultiSelectFilterActive: boolean = false;
  isApprovalSingleSelectFilterActive: boolean = false;
  isApplyResetButtonClicked: boolean = false;

  constructor(private route: Router,private engService: EngagementService,
    private _myReviewsService: MyReviewsService, private router: ActivatedRoute,
    private pagerService: PagerService,
    enviornment: AppConfigService, private loadingScreenService : LoadingScreenService, 
    private _alertService: AlertsService) {
    this.popupRecordCount = enviornment.config.DATA.SHOW_CLOSE_BUTTON_RECORD_LIMIT;
    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);
    const IShortcutCount = localStorage.getItem('iShortcutCount');
    this.iShortcutCount = JSON.parse(IShortcutCount);
    this.DataMessage = 'Loading...';
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    var userPreferenceString = localStorage.getItem('userpreferencelist');
    this.userPreferenceData = JSON.parse(userPreferenceString);
    console.log(this.userPreferenceData);
    if(this.userPreferenceData.length > 0){
    this.userPreferenceData.forEach(value => {
      if(value.dashBoardName === "MyApproval"){
        this.approvalsUserPreference = value;
      }
    });
  }
  }
  pager: any = {};
  pagerForDelegates: any = {};
  ngOnInit() {
      if(this.userPreferenceData.length > 0 && this.approvalsUserPreference != null){
      this.approvalFilterModel.isAwaitingImmediateApproval = JSON.parse(this.approvalsUserPreference.listViewSortingList).awaitingImmediateApprovalFlag;
      if (this.approvalFilterModel.isAwaitingImmediateApproval == undefined || this.approvalFilterModel.isAwaitingImmediateApproval == null) {
        this.approvalFilterModel.isAwaitingImmediateApproval = false;
      }
      this.approvalFilterModel.isAwaitingTieredApproval = JSON.parse(this.approvalsUserPreference.listViewSortingList).awaitingTierApprovalFlag;
      if (this.approvalFilterModel.isAwaitingTieredApproval == undefined || this.approvalFilterModel.isAwaitingTieredApproval == null) {
        this.approvalFilterModel.isAwaitingTieredApproval = false
      }
      this.approvalFilterModel.isApproved = JSON.parse(this.approvalsUserPreference.listViewSortingList).approvedFlag;
      if (this.approvalFilterModel.isApproved == undefined || this.approvalFilterModel.isApproved == null) {
        this.approvalFilterModel.isApproved = false
      }
      this.approvalFilterModel.isDeclined = JSON.parse(this.approvalsUserPreference.listViewSortingList).declinedFlag;
      if (this.approvalFilterModel.isDeclined == undefined || this.approvalFilterModel.isDeclined == null) {
        this.approvalFilterModel.isDeclined = false
      }

      this.orderByColumn =  JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByColumn;
      this.listViewNoOfrecordsPerPage = this.approvalsUserPreference.listViewPageNumber;
      if (this.listViewNoOfrecordsPerPage == undefined || this.listViewNoOfrecordsPerPage == null) {
        this.listViewNoOfrecordsPerPage = 20;
      }
      this.orderByType = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByType;
      this.isDelegateddashBoardActive = JSON.parse(this.approvalsUserPreference.listViewSortingList).isDelegateddashBoardActive;
      if (this.isDelegateddashBoardActive == undefined || this.isDelegateddashBoardActive == null) {
        this.isDelegateddashBoardActive = false
      }
      this.isMyApprovalTabActive = JSON.parse(this.approvalsUserPreference.listViewSortingList).isMyApprovalTabActive;
      if (this.isMyApprovalTabActive == undefined || this.isMyApprovalTabActive == null) {
        this.isMyApprovalTabActive = false
      }
      this.orderByColumnForDelegates = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByColumnForDelegates;
      if (this.orderByColumnForDelegates == undefined || this.orderByColumnForDelegates == null) {
        this.orderByColumnForDelegates = "Decisiondate";
      }
      this.orderByTypeForDelegates = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByTypeForDelegates;
      if (this.orderByTypeForDelegates == undefined || this.orderByTypeForDelegates == null) {
        this.orderByTypeForDelegates = "DESC";
      }
      this.listViewNoOfrecordsPerPageForDelegates = JSON.parse(this.approvalsUserPreference.listViewSortingList).listViewNoOfrecordsPerPageForDelegates;
      if (this.listViewNoOfrecordsPerPageForDelegates == undefined || this.listViewNoOfrecordsPerPageForDelegates == null) {
        this.listViewNoOfrecordsPerPageForDelegates = 20;
      }
      this.isApprovalMultiSelectFilterActive = JSON.parse(this.approvalsUserPreference.listViewSortingList).isApprovalMultiSelectFilterActive;
      if (this.isApprovalMultiSelectFilterActive == undefined || this.isApprovalMultiSelectFilterActive == null) {
        this.isApprovalMultiSelectFilterActive = false
      }

      this.isApprovalSingleSelectFilterActive = JSON.parse(this.approvalsUserPreference.listViewSortingList).isApprovalSingleSelectFilterActive;
      if (this.isApprovalSingleSelectFilterActive == undefined || this.isApprovalSingleSelectFilterActive == null) {
        this.isApprovalSingleSelectFilterActive = false
      }
    }

    this.setPageRecordRange(); // Page record range is set here
    this.setDefaultInitialization();//Default Initialization
    this.LoadListViewHeaders(); // List View Headers Loaded
    this.LoadListViewHeadersForDelegates();//List View Headers Loaded for delegates
    this.getAllApprovalsByUserCount(); // Get All Approvals By User Count
    this.getAllDelegatedApprovalsByUserCount();// Get All Delegated Approvals By User Count
    var contactPopup = $('#contact-popup');
    contactPopup.hide();
  }
  setDefaultInitialization() {
    this.applyFilterOnceAfterDataLoad = false;
    this.applyFilterOnceAfterDataLoadForDelegates = false;
    this.orderByColumn = 'ApproverStatus';
    this.orderByType = 'ASC';
    this.orderByColumnForDelegates = 'Decisiondate';
    this.orderByTypeForDelegates = 'DESC';
    if(this.userPreferenceData.length > 0  && this.approvalsUserPreference != null) {
      this.orderByColumn = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByColumn;
      if (this.orderByColumn == undefined || this.orderByColumn == null) {
        this.orderByColumn = "ApproverStatus";
      }
      this.orderByType = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByType;
      if (this.orderByType == undefined || this.orderByType == null) {
        this.orderByType = "ASC";
      }
      this.orderByColumnForDelegates = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByColumnForDelegates;
      if (this.orderByColumnForDelegates == undefined || this.orderByColumnForDelegates == null) {
        this.orderByColumnForDelegates = "Decisiondate";
      }
      this.orderByTypeForDelegates = JSON.parse(this.approvalsUserPreference.listViewSortingList).orderByTypeForDelegates;
      if (this.orderByTypeForDelegates == undefined || this.orderByTypeForDelegates == null) {
        this.orderByTypeForDelegates = "DESC";
      }
    }
    

    if (!this.isMyApprovalTabActive && !this.isDelegateddashBoardActive) {
      this.approvalFilterModel = new ApprovalFilterModel();
      this.approvalFilterModel.isAwaitingImmediateApproval = true;
      this.approvalFilterModel.isAwaitingTieredApproval = true;
      this.isMyApprovalTabActive = true;
      this.isDelegateddashBoardActive = false;
      this.isApprovalSingleSelectFilterActive = true;
      this.isApprovalMultiSelectFilterActive = false;
    }

    this.setFilters(false, false, true, false);
    
  }
  ngAfterViewChecked() {
    console.log('inside ', this.applyFilterOnceAfterDataLoad);
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.BindContactPopup();
      this.applyFilterOnceAfterDataLoad = false;
    }
    if (this.applyFilterOnceAfterDataLoadForDelegates) {
      this.BindContactPopup();
      this.applyFilterOnceAfterDataLoadForDelegates = false;
    }
  }
  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(10);
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(40);
    this.noOfrecordsPerPageList.push(60);
    this.noOfrecordsPerPageList.push(80);
    this.noOfrecordsPerPageList.push(100);
    this.noOfrecordsPerPageListForDelegates = [];
    this.noOfrecordsPerPageListForDelegates.push(10);
    this.noOfrecordsPerPageListForDelegates.push(20);
    this.noOfrecordsPerPageListForDelegates.push(40);
    this.noOfrecordsPerPageListForDelegates.push(60);
    this.noOfrecordsPerPageListForDelegates.push(80);
    this.noOfrecordsPerPageListForDelegates.push(100);
  }
  SetContactPopup(emailid: string) {
    this.selectContactEmailId = emailid;
  }
  BindContactPopup() {
    if (this.allApprovalList && this.allApprovalList.length > 0) {
      this.allApprovalList.forEach(element => {
        this.createContactPopupDiv('ePPMDcontactPopup_', element.engagementId);
      });
    }
    
    if (this.allApprovalListForDelegates && this.allApprovalListForDelegates.length > 0) {
      this.allApprovalListForDelegates.forEach(element => {
        this.createContactPopupDiv('ePPMDcontactPopup_', element.engagementId);
      });
    }
  }
  //Create Contact popup Div
  createContactPopupDiv(controlIdText: string , id: number) {
    console.log('engagementId ==>', id );
    $('#' + controlIdText + id).popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',    
      boundary: 'viewport',
      content: function () {
        console.log('createContactPopupDiv func called ', $('#contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }
  // Display Contact Popup Div
  DisplayContactPopup(engagementid: number, emailid: string) {
    this.selectContactEmailId = emailid;
    $('#listViewtxtengId').val(engagementid);
    // Close the pop up if any pop up onen eariler
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#ePPMDcontactPopup_' + engagementid).popover('show');
  }
  //Hide All popups
  hideAllPopUp() {
    if (this.allApprovalList !== null && this.allApprovalList !== undefined) {
      this.allApprovalList.forEach(element => {
        $('#ePPMDcontactPopup_' + element.engagementId).popover('hide');
      });
    }
    if (this.allApprovalListForDelegates !== null && this.allApprovalListForDelegates !== undefined) {
      this.allApprovalListForDelegates.forEach(element => {
        $('#ePPMDcontactPopup_' + element.engagementId).popover('hide');
      });
    }
  }

  InsertUpdateUserPreferenceData(){
    var approvalSort = new ApprovalSortModel();
    if(this.userPreferenceData.length > 0  && this.approvalsUserPreference != null){

    this.approvalsUserPreference.userEmail = this.userObject.loggedInUserEmail;
    this.approvalsUserPreference.dashBoardName = 'MyApproval';
    this.approvalsUserPreference.dashboardViewType = 'List';
    approvalSort.orderByColumn = this.orderByColumn;
    approvalSort.orderByType = this.orderByType;
    approvalSort.orderByColumnForDelegates = this.orderByColumnForDelegates;
    approvalSort.orderByTypeForDelegates = this.orderByTypeForDelegates;
    approvalSort.listViewNoOfrecordsPerPageForDelegates = this.listViewNoOfrecordsPerPageForDelegates;
    approvalSort.isDelegateddashBoardActive = this.isDelegateddashBoardActive;
    approvalSort.isMyApprovalTabActive = this.isMyApprovalTabActive;
    approvalSort.awaitingImmediateApprovalFlag = this.approvalFilterModel.isAwaitingImmediateApproval;
    approvalSort.awaitingTierApprovalFlag = this.approvalFilterModel.isAwaitingTieredApproval;
    approvalSort.approvedFlag = this.approvalFilterModel.isApproved;
    approvalSort.declinedFlag = this.approvalFilterModel.isDeclined;
    approvalSort.isApprovalMultiSelectFilterActive = this.isApprovalMultiSelectFilterActive;
    approvalSort.isApprovalSingleSelectFilterActive = this.isApprovalSingleSelectFilterActive;
    this.approvalsUserPreference.listViewFilterList = JSON.stringify(approvalSort);
    this.approvalsUserPreference.listViewSortingList = JSON.stringify(approvalSort);
    this.approvalsUserPreference.listViewPageNumber = this.listViewNoOfrecordsPerPage;
    this.userPrefData = this.approvalsUserPreference;        
    let index = this.userPreferenceData.findIndex(obj => obj.dashBoardName === "MyApproval");
    this.userPreferenceData[index] = this.userPrefData;
    localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


    this.engService.InsertUpdateUserPreferenceData(this.userPrefData).subscribe(result =>{
          console.log(result);
    });
  }
  if((this.userPreferenceData.length == 0 || this.userPreferenceData.length > 0) && this.approvalsUserPreference == null ){
    var datatoinsert = new UserPreferenceData();
    

    approvalSort.orderByColumn = this.orderByColumn;
    approvalSort.orderByType = this.orderByType;
    approvalSort.orderByColumnForDelegates = this.orderByColumnForDelegates;
    approvalSort.orderByTypeForDelegates = this.orderByTypeForDelegates;
    approvalSort.listViewNoOfrecordsPerPageForDelegates = this.listViewNoOfrecordsPerPageForDelegates;
    approvalSort.isDelegateddashBoardActive = this.isDelegateddashBoardActive;
    approvalSort.awaitingImmediateApprovalFlag = this.approvalFilterModel.isAwaitingImmediateApproval;
    approvalSort.awaitingTierApprovalFlag = this.approvalFilterModel.isAwaitingTieredApproval;
    approvalSort.approvedFlag = this.approvalFilterModel.isApproved;
    approvalSort.declinedFlag = this.approvalFilterModel.isDeclined;
    datatoinsert.userEmail = this.userObject.loggedInUserEmail;
    datatoinsert.dashBoardName = 'MyApproval';
    datatoinsert.dashboardViewType = 'List';
    datatoinsert.cardViewPageNumber = this.listViewNoOfrecordsPerPage;
    datatoinsert.listViewPageNumber = this.listViewNoOfrecordsPerPage;
    datatoinsert.cardViewFilterList = JSON.stringify(approvalSort);
    datatoinsert.cardViewSortingList = JSON.stringify(approvalSort);
    datatoinsert.listViewFilterList = JSON.stringify(approvalSort);
    datatoinsert.listViewSortingList = JSON.stringify(approvalSort);
    this.userPreferenceData.push(datatoinsert);
    localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


    this.engService.InsertUpdateUserPreferenceData(datatoinsert).subscribe(result =>{
          console.log(result);
    });
  }
    
  }
  ngOnDestroy() {
    this.hideAllPopUp();
  }
  ngAfterViewInit() {
    this.closeContactPopupListView();
  }
  closeContactPopupListView() {
    $('html').on('click', function(e) {
      console.log('first', $(e.target).parents().is('.popover.in'));
      console.log('second',  $(e.target).data('original-title'));

      if (typeof $(e.target).data('original-title') == 'undefined' &&
         !$(e.target).parents().is('.popover.in')) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#ePPMDcontactPopup_' + id).popover('hide');
          console.log('xxx11', $('#ePPMDcontactPopup_'+ id));
          console.log('xxx', '#' + ContactCardControl.listViewdiv + id);
      }
    });

    $('body').on('click', function (e) {
      console.log('body click event fired', $(e.target).is('.' + ContactCardControl.close));
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#ePPMDcontactPopup_' + id).popover('hide');
        }
      }
    });

  }
  checkForContactContent(contact) {
    var timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 2 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 2000);
  }
  /*
      Auther : Mohit Saxena
      Date :  9th Sept 2020
      Description : Loading List View Headers
  */
  LoadListViewHeaders() {
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.headerModel = {
      headerId: 1,
      headerName: 'Client',
      headerValue: 'ClientName',
      headerClick: true,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'ClientName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = true;
        this.headerModelList[0].imageModelList[2].isVisible = false;
        }
        if(this.orderByType == 'DESC'){
          this.headerModelList[0].imageModelList[0].isVisible = false;
          this.headerModelList[0].imageModelList[1].isVisible = false;
          this.headerModelList[0].imageModelList[2].isVisible = true;
          }
    }

    this.headerModel = {
      headerId: 2,
      headerName: 'Engagement name',
      headerValue: 'EngagementName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'EngagementName'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = true;
        this.headerModelList[1].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = false;
        this.headerModelList[1].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 3,
      headerName: 'Status',
      headerValue: 'Status',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'Status'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = true;
        this.headerModelList[2].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = false;
        this.headerModelList[2].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 4,
      headerName: 'Primary solution code',
      headerValue: 'SolutionCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'SolutionCode'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = true;
        this.headerModelList[3].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = false;
        this.headerModelList[3].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 5,
      headerName: 'EPPMD',
      headerValue: 'EPPMD',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'EPPMD'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = true;
        this.headerModelList[4].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = false;
        this.headerModelList[4].imageModelList[2].isVisible = true;
        }
    }

    this.headerModel = {
      headerId: 6,
      headerName: 'Date',
      headerValue: 'DatePosted',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'DatePosted'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = true;
        this.headerModelList[5].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = false;
        this.headerModelList[5].imageModelList[2].isVisible = true;
        }
    }
    
    this.headerModel = {
      headerId: 7,
      headerName: '',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    this.headerModelList[6].imageModelList[0].isVisible = false;
    this.headerModelList[6].imageModelList[1].isVisible = false;
    this.headerModelList[6].imageModelList[2].isVisible = false;

    this.headerModel = {
      headerId: 8,
      headerName: 'Indicators',
      headerValue: 'Indicators',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 9,
      headerName: '',
      headerValue: 'ApproverStatus',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if(this.orderByColumn === 'ApproverStatus'){
      if(this.orderByType == 'ASC'){
        this.headerModelList[8].imageModelList[0].isVisible = false;
        this.headerModelList[8].imageModelList[1].isVisible = true;
        this.headerModelList[8].imageModelList[2].isVisible = false;
        }
      if(this.orderByType == 'DESC'){
        this.headerModelList[8].imageModelList[0].isVisible = false;
        this.headerModelList[8].imageModelList[1].isVisible = false;
        this.headerModelList[8].imageModelList[2].isVisible = true;
        }
    }
    
  }

  LoadListViewHeadersForDelegates() {
    this.newImageModelForDelegates = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelListForDelegates.push(JSON.parse(JSON.stringify(this.newImageModelForDelegates)));

    this.newImageModelForDelegates = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelListForDelegates.push(JSON.parse(JSON.stringify(this.newImageModelForDelegates)));

    this.newImageModelForDelegates = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelListForDelegates.push(JSON.parse(JSON.stringify(this.newImageModelForDelegates)));

    this.headerModelForDelegates = {
      headerId: 1,
      headerName: 'Client',
      headerValue: 'ClientName',
      headerClick: true,
      imageModelList: this.headerImageModelListForDelegates
    };
    this.headerModelListForDelegates.push(JSON.parse(JSON.stringify(this.headerModelForDelegates)));
    if(this.orderByColumnForDelegates === 'ClientName'){
      if(this.orderByTypeForDelegates == 'ASC'){
        this.headerModelListForDelegates[0].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[0].imageModelList[1].isVisible = true;
        this.headerModelListForDelegates[0].imageModelList[2].isVisible = false;
        }
        if(this.orderByTypeForDelegates == 'DESC'){
          this.headerModelListForDelegates[0].imageModelList[0].isVisible = false;
          this.headerModelListForDelegates[0].imageModelList[1].isVisible = false;
          this.headerModelListForDelegates[0].imageModelList[2].isVisible = true;
          }
    }

    this.headerModelForDelegates = {
      headerId: 2,
      headerName: 'Engagement name',
      headerValue: 'EngagementName',
      headerClick: false,
      imageModelList: this.headerImageModelListForDelegates
    };
    this.headerModelListForDelegates.push(JSON.parse(JSON.stringify(this.headerModelForDelegates)));
    if(this.orderByColumnForDelegates === 'EngagementName'){
      if(this.orderByTypeForDelegates == 'ASC'){
        this.headerModelListForDelegates[1].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[1].imageModelList[1].isVisible = true;
        this.headerModelListForDelegates[1].imageModelList[2].isVisible = false;
        }
      if(this.orderByTypeForDelegates == 'DESC'){
        this.headerModelListForDelegates[1].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[1].imageModelList[1].isVisible = false;
        this.headerModelListForDelegates[1].imageModelList[2].isVisible = true;
        }
    }

    this.headerModelForDelegates = {
      headerId: 3,
      headerName: 'Delegate',
      headerValue: 'Delegated',
      headerClick: false,
      imageModelList: this.headerImageModelListForDelegates
    };
    this.headerModelListForDelegates.push(JSON.parse(JSON.stringify(this.headerModelForDelegates)));
    if(this.orderByColumnForDelegates === 'Delegated'){
      if(this.orderByTypeForDelegates == 'ASC'){
        this.headerModelListForDelegates[2].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[2].imageModelList[1].isVisible = true;
        this.headerModelListForDelegates[2].imageModelList[2].isVisible = false;
        }
      if(this.orderByTypeForDelegates == 'DESC'){
        this.headerModelListForDelegates[2].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[2].imageModelList[1].isVisible = false;
        this.headerModelListForDelegates[2].imageModelList[2].isVisible = true;
        }
    }

    this.headerModelForDelegates = {
      headerId: 4,
      headerName: 'Decision',
      headerValue: 'Status',
      headerClick: false,
      imageModelList: this.headerImageModelListForDelegates
    };
    this.headerModelListForDelegates.push(JSON.parse(JSON.stringify(this.headerModelForDelegates)));
    if(this.orderByColumnForDelegates === 'Status'){
      if(this.orderByTypeForDelegates == 'ASC'){
        this.headerModelListForDelegates[3].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[3].imageModelList[1].isVisible = true;
        this.headerModelListForDelegates[3].imageModelList[2].isVisible = false;
        }
      if(this.orderByTypeForDelegates == 'DESC'){
        this.headerModelListForDelegates[3].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[3].imageModelList[1].isVisible = false;
        this.headerModelListForDelegates[3].imageModelList[2].isVisible = true;
        }
    }


    

    this.headerModelForDelegates = {
      headerId: 5,
      headerName: 'Decision date',
      headerValue: 'Decisiondate',
      headerClick: false,
      imageModelList: this.headerImageModelListForDelegates
    };
    this.headerModelListForDelegates.push(JSON.parse(JSON.stringify(this.headerModelForDelegates)));
    if(this.orderByColumnForDelegates === 'Decisiondate'){
      if(this.orderByTypeForDelegates == 'ASC'){
        this.headerModelListForDelegates[4].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[4].imageModelList[1].isVisible = true;
        this.headerModelListForDelegates[4].imageModelList[2].isVisible = false;
        }
      if(this.orderByTypeForDelegates == 'DESC'){
        this.headerModelListForDelegates[4].imageModelList[0].isVisible = false;
        this.headerModelListForDelegates[4].imageModelList[1].isVisible = false;
        this.headerModelListForDelegates[4].imageModelList[2].isVisible = true;
        }
    }
    
    this.headerModelForDelegates = {
      headerId: 6,
      headerName: '',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelListForDelegates
    };
    this.headerModelListForDelegates.push(JSON.parse(JSON.stringify(this.headerModelForDelegates)));
    this.headerModelListForDelegates[5].imageModelList[0].isVisible = false;
    this.headerModelListForDelegates[5].imageModelList[1].isVisible = false;
    this.headerModelListForDelegates[5].imageModelList[2].isVisible = false;

    console.log("delegates data"+JSON.stringify(this.headerImageModelListForDelegates));
  }
  /*
    Auther : Mohit Saxena
    Date :  9th Sept 2020
    Description : Get All Approvals by user count
  */  
  getAllApprovalsByUserCount() {
    this.loadingScreenService.startLoading();
    this._myReviewsService.getAllApprovalsByUserCount(this.approvalFilterModel.isAwaitingImmediateApproval, this.approvalFilterModel.isAwaitingTieredApproval, this.approvalFilterModel.isApproved, this.approvalFilterModel.isDeclined).subscribe(
      result => {
        this.allApprovalCount = result;
        this.loadingScreenService.stopLoading();
        if (this.allApprovalCount > 0) {
          this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage);
        }
        else{
          this.allApprovalCount = 0;
          this.allApprovalList = null;
            if (this.allApprovalCount === 0) {
              this.DataMessage = ' No Data Available';
            }
          this.loadingScreenService.stopLoading();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
      }
    );
  }

  getAllDelegatedApprovalsByUserCount() {
    this.loadingScreenService.startLoading();
    this._myReviewsService.getAllDelegatedApprovalsByUserCount().subscribe(
      result => {
        this.allApprovalCountForDelegates = result;
        this.loadingScreenService.stopLoading();
        if (this.allApprovalCountForDelegates > 0) {
          this.listViewSetPageForDelegates(this.listViewCurrentPageNumberForDelegates, this.listViewNoOfrecordsPerPageForDelegates);
        }
        else{
          this.allApprovalCountForDelegates = 0;
          this.allApprovalListForDelegates = null;
            if (this.allApprovalCountForDelegates === 0) {
              this.DataMessageForDelegates = ' No Data Available';
            }
          this.loadingScreenService.stopLoading();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
      }
    );
  }

  

  /*
      Auther : Mohit Saxena
      Date :  9th Sept 2020
      Description : Table Headers Sort Function
  */
  HeaderSort(headerid: number) {
    for (let i = 0; i < this.headerModelList.length; i++) {
        if (this.headerModelList[i].headerId === headerid) {
          this.headerModelList[i].headerClick = true;
          if ((this.headerModelList[i].imageModelList[0].isVisible) || (this.headerModelList[i].imageModelList[2].isVisible)) {
            this.headerModelList[i].imageModelList[0].isVisible = false;
            this.headerModelList[i].imageModelList[1].isVisible = true;
            this.headerModelList[i].imageModelList[2].isVisible = false;
          } else if (this.headerModelList[i].imageModelList[1].isVisible) {
            this.headerModelList[i].imageModelList[0].isVisible = false;
            this.headerModelList[i].imageModelList[1].isVisible = false;
            this.headerModelList[i].imageModelList[2].isVisible = true;
          }
        } else {
            this.headerModelList[i].headerClick = false;
            for (let j = 0; j <  this.headerModelList[i].imageModelList.length; j ++) {
              this.headerModelList[i].imageModelList[0].isVisible = true;
              this.headerModelList[i].imageModelList[1].isVisible = false;
              this.headerModelList[i].imageModelList[2].isVisible = false;
            }
          }
    }

    let headerObj = this.headerModelList.find(c => c.headerClick === true);
    this.orderByColumn = headerObj.headerValue;
      for (let k = 0; k <  headerObj.imageModelList.length; k ++) {
          if (headerObj.imageModelList[k].isVisible === true) {
            this.orderByType = headerObj.imageModelList[k].imageValue;
            break;
          }
      }
      this.InsertUpdateUserPreferenceData();
      this.listViewSetPage(1,this.listViewNoOfrecordsPerPage);
  }

  HeaderSortForDelegates(headerid: number) {
    for (let i = 0; i < this.headerModelListForDelegates.length; i++) {
        if (this.headerModelListForDelegates[i].headerId === headerid) {
          this.headerModelListForDelegates[i].headerClick = true;
          if ((this.headerModelListForDelegates[i].imageModelList[0].isVisible) || (this.headerModelListForDelegates[i].imageModelList[2].isVisible)) {
            this.headerModelListForDelegates[i].imageModelList[0].isVisible = false;
            this.headerModelListForDelegates[i].imageModelList[1].isVisible = true;
            this.headerModelListForDelegates[i].imageModelList[2].isVisible = false;
          } else if (this.headerModelListForDelegates[i].imageModelList[1].isVisible) {
            this.headerModelListForDelegates[i].imageModelList[0].isVisible = false;
            this.headerModelListForDelegates[i].imageModelList[1].isVisible = false;
            this.headerModelListForDelegates[i].imageModelList[2].isVisible = true;
          }
        } else {
            this.headerModelListForDelegates[i].headerClick = false;
            for (let j = 0; j <  this.headerModelListForDelegates[i].imageModelList.length; j ++) {
              this.headerModelListForDelegates[i].imageModelList[0].isVisible = true;
              this.headerModelListForDelegates[i].imageModelList[1].isVisible = false;
              this.headerModelListForDelegates[i].imageModelList[2].isVisible = false;
            }
          }
    }

    let headerObj = this.headerModelListForDelegates.find(c => c.headerClick === true);
    this.orderByColumnForDelegates = headerObj.headerValue;
      for (let k = 0; k <  headerObj.imageModelList.length; k ++) {
          if (headerObj.imageModelList[k].isVisible === true) {
            this.orderByTypeForDelegates = headerObj.imageModelList[k].imageValue;
            break;
          }
      }
      this.InsertUpdateUserPreferenceData();
      this.listViewSetPageForDelegates(1,this.listViewNoOfrecordsPerPageForDelegates);
  }

  /*
      Auther : Mohit Saxena
      Date :  9th Sept 2020
      Description : Get All Approvals by user as a List
  */ 
  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewNoOfrecordsPerPage = recordsPerPage;
    } else {
      this.listViewNoOfrecordsPerPage = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();
    if (this.listViewCurrentPageNumber != 0) {
      this.getAllApprovalsByUser('List', this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage,
        this.orderByColumn, this.orderByType);

      this.pager = this.pagerService.getPager(this.allApprovalCount, this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage);
      console.log('pager ==>', this.pager);
    } else {
      $('#previous').off('click');
      $('#first').off('click');
    }
  }

  listViewSetPageForDelegates(pageNumber: number, recordsPerPage: number) {
    // get pager object from service
    this.listViewCurrentPageNumberForDelegates = pageNumber;
    const inputDrpValue = $('#drprecordsPerPageForDelegates option:selected').text();
    if (inputDrpValue === '') {
      this.listViewNoOfrecordsPerPageForDelegates = recordsPerPage;
    } else {
      this.listViewNoOfrecordsPerPageForDelegates = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();
    if (this.listViewCurrentPageNumberForDelegates != 0) {
      this.getAllDelegatedApprovalsByUser('List', this.listViewCurrentPageNumberForDelegates, this.listViewNoOfrecordsPerPageForDelegates,
        this.orderByColumnForDelegates, this.orderByTypeForDelegates);

      this.pagerForDelegates = this.pagerService.getPager(this.allApprovalCountForDelegates, this.listViewCurrentPageNumberForDelegates, this.listViewNoOfrecordsPerPageForDelegates);
      console.log('pager for delegates ==>', this.pagerForDelegates);
    } else {
      $('#previousForDelegates').off('click');
      $('#firstForDelegates').off('click');
    }
  }

  onRightClick(id: string){
    
    const url = this.route.serializeUrl(this.route.createUrlTree(['Review/'+id]));
    window.open(url, '_blank');
    

  }
  onRightClick1(id: string){
    window.open(id, '_blank');
    

  }

  /*
      Auther : Mohit Saxena
      Date :  9th Sept 2020
      Description : Get All Approvals by user
  */ 
  getAllApprovalsByUser(SelectedView: string, pageNumber: number, listViewNoOfrecordsPerPage: number, orderBy: string, orderType: string) {
    this.loadingScreenService.startLoading();
    this._myReviewsService.getAllApprovalsByUser(pageNumber, listViewNoOfrecordsPerPage,
                                                 orderBy, orderType,this.approvalFilterModel.isAwaitingImmediateApproval, this.approvalFilterModel.isAwaitingTieredApproval, this.approvalFilterModel.isApproved, this.approvalFilterModel.isDeclined).subscribe(
        result => {
          this.allApprovalList = result;
          console.log(this.allApprovalList);
          this.allApprovalList.forEach(element => {
            element.siteURL = element.siteURL+'/Acceptance%20Documents/Forms/AllItems.aspx';
          });
          this.applyFilterOnceAfterDataLoad = true;
          this.loadingScreenService.stopLoading();
        },
        error => {
          console.log('Error in ValidateUserWithADGroup ', error);
          this.loadingScreenService.stopLoading();
        });
  }

  getAllDelegatedApprovalsByUser(SelectedView: string, pageNumber: number, listViewNoOfrecordsPerPage: number, orderBy: string, orderType: string) {
    this.loadingScreenService.startLoading();
    this._myReviewsService.getAllDelegatedApprovalsByUser(pageNumber, listViewNoOfrecordsPerPage,
                                                 orderBy, orderType).subscribe(
        result => {
          this.allApprovalListForDelegates = result;
          console.log(this.allApprovalListForDelegates);
          this.allApprovalListForDelegates.forEach(element => {
            element.siteURL = element.siteURL+'/Acceptance%20Documents/Forms/AllItems.aspx';
          });
          this.applyFilterOnceAfterDataLoadForDelegates = true;
          this.loadingScreenService.stopLoading();
        },
        error => {
          console.log('Error in ValidateUserWithADGroup ', error);
          this.loadingScreenService.stopLoading();
        });
  }

  isValidateSiteURL(siteURL: string) {
    return (siteURL !== null && siteURL !== undefined && siteURL !== '');
  }
  
  approvalFilterModel: ApprovalFilterModel = { isAwaitingImmediateApproval: true, isAwaitingTieredApproval: false, isApproved: false, isDeclined: false  };
  approverStatus = ApproverStatus;
  ShowHideListFilterPanel(selectedView: string) {
    switch (selectedView) {
      case 'Show':
        this.isApplyResetButtonClicked = false;
          $('#listViewMyApprovalsFilterSection').show();
          $("#filterMyApprovalsEngagementListView").addClass("active");
          $("#showAllMyApprovalsEngagementListView").removeClass("active");
        break;
      case 'ShowAll': 
      this.isApplyResetButtonClicked = false;
          $('#listViewMyApprovalsFilterSection').hide();
          $("#filterMyApprovalsEngagementListView").removeClass("active partialActive");
          $("#showAllMyApprovalsEngagementListView").addClass("active");
          this.setFilters(true, false, false, false);
          this.showAll();
        break;
      case 'Hide':
        this.isApplyResetButtonClicked = false;
        $('#listViewMyApprovalsFilterSection').hide();
        $("#filterMyApprovalsEngagementListView").removeClass("active");
        $("#showAllMyApprovalsEngagementListView").removeClass("active");
        break;
    }
  }

  setFilters(isShowAll: boolean, isCancel: boolean, isInitialize: boolean, isReset: boolean) {
    $("#filterMyApprovalsEngagementListView, #showAllMyApprovalsEngagementListView, #btn-single-filter-awaiting-approval, #btn-single-filter-approved-approval, #btn-single-filter-declined-approval").removeClass("partialActive active");
    
    if (isShowAll) {
      $("#showAllMyApprovalsEngagementListView").addClass("active");
    }
    else if (isCancel || isInitialize) {
      if (this.approvalFilterModel && this.isApprovalMultiSelectFilterActive && (this.approvalFilterModel.isAwaitingImmediateApproval || this.approvalFilterModel.isAwaitingTieredApproval || this.approvalFilterModel.isApproved || this.approvalFilterModel.isDeclined)) {
        $("#filterMyApprovalsEngagementListView").addClass("partialActive");
      }
      else if (this.approvalFilterModel && this.isApprovalSingleSelectFilterActive) {
        if (this.approvalFilterModel.isAwaitingImmediateApproval || this.approvalFilterModel.isAwaitingTieredApproval)   
          $("#btn-single-filter-awaiting-approval").addClass("partialActive");
  
        if (this.approvalFilterModel.isApproved)
          $("#btn-single-filter-approved-approval").addClass("partialActive");
        
        if (this.approvalFilterModel.isDeclined) 
          $("#btn-single-filter-declined-approval").addClass("partialActive");
      }
    }
    else if (this.approvalFilterModel && this.isApprovalMultiSelectFilterActive && (this.approvalFilterModel.isAwaitingImmediateApproval || this.approvalFilterModel.isAwaitingTieredApproval || this.approvalFilterModel.isApproved || this.approvalFilterModel.isDeclined)) {
      if (isReset)
        $("#filterMyApprovalsEngagementListView").addClass("active");
      else 
        $("#filterMyApprovalsEngagementListView").addClass("partialActive");
    }
    else if (this.approvalFilterModel && this.isApprovalSingleSelectFilterActive) {
      if (this.approvalFilterModel.isAwaitingImmediateApproval || this.approvalFilterModel.isAwaitingTieredApproval)   
        $("#btn-single-filter-awaiting-approval").addClass("active");

      if (this.approvalFilterModel.isApproved)
        $("#btn-single-filter-approved-approval").addClass("active");
      
      if (this.approvalFilterModel.isDeclined) 
        $("#btn-single-filter-declined-approval").addClass("active");
    }
  }

  filterApprovals (action: string) {

    switch(action) {
      case 'Apply':
        this.isApplyResetButtonClicked = true;
        this.loadApprovals();
        break;
      case 'Cancel':
        this.ShowHideListFilterPanel('Hide');
        break;
      case 'Reset':
        this.isApplyResetButtonClicked = true;
        this.resetApprovals();
        break;
    }
  }

  tabChanged(tab: string) {
    $('#listViewMyApprovalsFilterSection').hide();
    $("#showAllMyApprovalsEngagementListView").removeClass("active");
    switch(tab) {
      case 'Approvals':
        this.isMyApprovalTabActive = true;
        this.isDelegateddashBoardActive = false;
        this.getAllApprovalsByUserCount();
        break;
      case 'Delegated':
        if(!this.isApplyResetButtonClicked)
        {
          this.approvalFilterModel.isAwaitingImmediateApproval = JSON.parse(this.approvalsUserPreference.listViewSortingList).awaitingImmediateApprovalFlag;
          if (this.approvalFilterModel.isAwaitingImmediateApproval == undefined || this.approvalFilterModel.isAwaitingImmediateApproval == null) {
            this.approvalFilterModel.isAwaitingImmediateApproval = false;
          }
          this.approvalFilterModel.isAwaitingTieredApproval = JSON.parse(this.approvalsUserPreference.listViewSortingList).awaitingTierApprovalFlag;
          if (this.approvalFilterModel.isAwaitingTieredApproval == undefined || this.approvalFilterModel.isAwaitingTieredApproval == null) {
            this.approvalFilterModel.isAwaitingTieredApproval = false
          }
          this.approvalFilterModel.isApproved = JSON.parse(this.approvalsUserPreference.listViewSortingList).approvedFlag;
          if (this.approvalFilterModel.isApproved == undefined || this.approvalFilterModel.isApproved == null) {
            this.approvalFilterModel.isApproved = false
          }
          this.approvalFilterModel.isDeclined = JSON.parse(this.approvalsUserPreference.listViewSortingList).declinedFlag;
          if (this.approvalFilterModel.isDeclined == undefined || this.approvalFilterModel.isDeclined == null) {
            this.approvalFilterModel.isDeclined = false
          }
    
        }
        this.isDelegateddashBoardActive = true;
        this.isMyApprovalTabActive = false;
        this.getAllDelegatedApprovalsByUserCount();
        break;
    }    
  }

  filterEngagementApprovals (action: string, filterType: string, singleSelectionFilterType: number = 0) {
    switch(filterType) {
      case 'Single':
        this.isApprovalMultiSelectFilterActive = false;
        this.isApprovalSingleSelectFilterActive = true;
        this.approvalFilterModel = new ApprovalFilterModel();
        switch(singleSelectionFilterType) {
          case this.approverStatus.AwaitingImmediateAndTieredApproval:
            this.approvalFilterModel.isAwaitingImmediateApproval = true;
            this.approvalFilterModel.isAwaitingTieredApproval = true;
          break;
          case this.approverStatus.Approved:
            this.approvalFilterModel.isApproved = true;
            break;
          case this.approverStatus.Declined:
            this.approvalFilterModel.isDeclined = true;
            break;
        }
        break;
      case 'Multiple':
        if (action != 'Cancel') {
          this.isApprovalMultiSelectFilterActive = true;
          this.isApprovalSingleSelectFilterActive = false;
        }
        break;
    }
    this.filterApprovals(action);
    this.setFilters(false, action == 'Cancel', false, action == 'Reset');
  }

  loadApprovals() {
    this.listViewCurrentPageNumber = 1;
    this.listViewNoOfrecordsPerPage = 20;
    if(this.userPreferenceData.length > 0  && this.approvalsUserPreference != null) {
      this.listViewNoOfrecordsPerPage = this.approvalsUserPreference.listViewPageNumber;
    }

    this.setPageRecordRange();
    this.InsertUpdateUserPreferenceData();
    this.getAllApprovalsByUserCount();

    this.ShowHideListFilterPanel('Hide');
  }

  resetApprovals() {
    this.listViewCurrentPageNumber = 1;
    this.listViewNoOfrecordsPerPage = 20;
    if(this.userPreferenceData.length > 0  && this.approvalsUserPreference != null) {
      this.listViewNoOfrecordsPerPage = this.approvalsUserPreference.listViewPageNumber;
    }

    this.approvalFilterModel = new ApprovalFilterModel();
    this.approvalFilterModel.isAwaitingImmediateApproval = true;

    this.setPageRecordRange();
    this.InsertUpdateUserPreferenceData();
    this.getAllApprovalsByUserCount();
  }

  showAll() {
    this.listViewCurrentPageNumber = 1;
    this.listViewNoOfrecordsPerPage = 20;
    if(this.userPreferenceData.length > 0  && this.approvalsUserPreference != null) {
      this.listViewNoOfrecordsPerPage = this.approvalsUserPreference.listViewPageNumber;
    }

    this.approvalFilterModel = new ApprovalFilterModel();

    this.setPageRecordRange();
    this.InsertUpdateUserPreferenceData();
    this.getAllApprovalsByUserCount();
  }
}
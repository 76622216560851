export enum WhoCanResolveReviewNote {
    Creator = "Creator",
    AnyRP = "AnyRP",
    Anyone = "Anyone"
}

export enum ReviewNoteStatus {
    All = 0,
    Open = 1,
    Resolved = 2
}

export enum ReviewNoteFilterBy {
    All = 0,
    CreatedByMe = 1,
    MentionedMe = 2
}
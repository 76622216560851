export enum AuditPartnerDialogTypes {
    AdvisoryEPPMDReview = 'AdvisoryEPPMDReview',
    TaxEPPMDReview = 'TaxEPPMDReview',
    AuditEPPMDReview = 'AuditEPPMDReview',
    AdvisoryRPReview = 'AdvisoryRPReview',
    TaxRPReview = 'TaxRPReview',
    AuditRPReview = 'AuditRPReview',
}
export enum AuditPartnerDialogTitles {
    AdvisoryEPPMDReview = 'Send to Engagement PPMD',
    TaxEPPMDReview = 'Send to Engagement PPMD',
    AuditEPPMDReview = 'Send to Engagement PPMD',
    AdvisoryRPReview = 'EPPMD approval',
    TaxRPReview = 'EPPMD approval',
    AuditRPReview = 'EPPMD approval',
}

<div id="hdQuestionnaireSummary">
    <div *ngIf="questionAnswerList != null; else InProgressReviewResponse">
        <div *ngIf="questionAnswerList.length==0">
            <div class="pt-4 pl-4 pr-3 q-a-main text-center">
                <p>No review response found.</p>
            </div>
        </div>
        <div *ngIf="questionAnswerList.length>0"
            class="pl-4 pr-3 q-a-main">
            <div class="row p-3 ">
                <div class="col-12 col-md-12 border-dark" *ngIf="showHeadingFlag==false">{{lastUpdatedBy}}</div>
                <div class="col-12 col-md-12" *ngIf="showHeadingFlag==true">
                    <div class="sec-heading font-weight-bold">Questionnaire responses</div>
                </div>
            </div>
            <div class="qa-section row" *ngFor="let item of questionAnswerList; let i = index">
                <div class="col-8 col-md-8">
                    {{item.question}}
                    <input type="hidden" id="hdn{{i+1}}" name="hdn{{i+1}}" value="{{item.templateVariable}}" />
                </div>
                <div class="col-4 col-md-4" *ngIf="item.answers !== null">
                    <div *ngFor="let answer of item.answers" class="hi">
                        {{answer}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #InProgressReviewResponse>
        <div>
            Loading...
        </div>
    </ng-template>
</div>
export class BaseConeveneProjectInitiationModel
{
    cloneProjectid: string;
    isCloneProject : boolean;
    statusOfTheProject : string;
    dueDates  : string;
    isUsers  :boolean;
}

export class ConveneProjectsDetails
{
     clientId : string;
     clientName : string;
     collaborationSpaceName : string; 
     projectName : string;
     projectId : string;
     solutionCode : string;
     serviceLine : string;
     year :  string;
     clientFye : string;
}

export class ConveneProjectsDetailResponse
{
    statusCode : string;
    errorMessage: string;
    status: boolean;
    data: ConveneProjectsDetails[] = [];
}

export class ConveneGetProjetcsById
{
    engagementId : number;
    userEmailId: string;
}

export class ConveneProjectInsertModel
{
     engagementId : number;
     continuanceEngagementId : number; 
    isCloneProject : boolean;
     cloneProjectId : string;
     status  : string;
     dueDates : string;
     users : boolean;
     conveneProjectId : string;
     userEmail : string;
}

export class ConveneProjectDetailsResponse
{
    isCloneProject : boolean;
     cloneProjectId : string;
     status  : string;
     dueDates : string;
     users : boolean;
}
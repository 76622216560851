import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../model/User';
import { AppConfigService } from './appConfigService';
import { Role } from '../model/Role';
import { StringList } from '../model/StringList';
import { LogService } from './log.service';
import { GraphService } from './Integration/graphservice.service';
import { SharepointService } from './sharepoint.service';
import { ADGroupsUserMemberOfCheckModel } from '../model/AzureADUserList';
import { MemberFirmService } from './member-firm.service';

type ProfileType = {
  id?: string,
  displayName?: string,
  userPrincipalName?: string,
  mail?: string,
  mobilePhone?: string,
  jobTitle?: string,
  officeLocation?: string,
  businessPhones?: string,
  OnPremisesExtensionAttributes?: string,
  employeeId?: string,
  department?: string,
  preferredLanguage?: string,
  givenName?: string,
  surname?: string,
  accountEnabled?: string,
  userType?: string
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profile!: ProfileType;
  tokenURL: string;
  user: User;
  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  activeDirectoryGroups: string[];
  apiUserName: string;
  apiPassword: string;
  subscriptionKey: string;

  private loginFlagSource = new BehaviorSubject<number>(0);
  public loginFlag$ = this.loginFlagSource.asObservable();

  private roleLoadEndSource = new BehaviorSubject<string>('');
  public roleLoadEnd$ = this.roleLoadEndSource.asObservable();

  constructor(
    private environment: AppConfigService, private http: HttpClient, private router: Router,
    private logService: LogService, private graphService: GraphService,
    private sharepointService: SharepointService,
    private memberFirmService: MemberFirmService
  ) {
    this.APIEndPoint = this.environment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.tokenURL = this.environment.config.DATA.AZURE_AD_AUTHENTICATION.TOKEN_URL;
    this.apiUserName = this.environment.config.DATA.API_USER.USERNAME;
    this.apiPassword = this.environment.config.DATA.API_USER.PASSWORD;
    this.subscriptionKey = this.environment.config.DATA.API_USER.SUBSCRIPTION_KEY;
  }

  addUserDetails(userInfo: ProfileType) {
    this.user = new User();
    console.log('Before Initialization - user', this.user, userInfo);

    this.user.cachedDateTime = new Date().toLocaleDateString();
    this.user.id = userInfo?.id;
    this.user.loggedInUserFullName = userInfo?.displayName;
    this.user.userType = userInfo?.userType;
    this.user.userPrincipalName = userInfo?.userPrincipalName;
    this.user.loggedInUserEmail = userInfo?.mail;
    this.user.mobilePhone = userInfo?.mobilePhone;
    this.user.jobTitle = userInfo?.jobTitle;
    this.user.officeLocation = userInfo?.officeLocation;
    this.user.businessPhones = userInfo?.businessPhones;
    this.user.OnPremisesExtensionAttributes = userInfo?.OnPremisesExtensionAttributes;
    this.user.employeeId = userInfo?.employeeId;
    this.user.department = userInfo?.department;
    this.user.preferredLanguage = userInfo?.preferredLanguage;
    this.user.givenName = userInfo?.givenName;
    this.user.surname = userInfo?.surname;
    this.user.accountEnabled = userInfo?.accountEnabled;
    if (this.user.userType == "Member") {
      this.user.firstName = userInfo?.givenName;
      this.user.lastName = userInfo?.surname;
      this.user.userName = userInfo?.userPrincipalName;
    } else {
      this.user.userName = userInfo?.mail;
      let dn = this.user.loggedInUserFullName?.split(' ');
      this.user.firstName = this.user.firstName ?? dn[1];
      this.user.lastName = this.user.lastName ?? dn[0];
    }
    const token = window.btoa(this.apiUserName + ':' + this.apiPassword);
    localStorage.removeItem('currentUserToken');
    localStorage.setItem('currentUserToken', token);
    localStorage.removeItem('subscriptionKey');
    localStorage.setItem('subscriptionKey', this.subscriptionKey);

    this.getActiveDirectoryGroups().subscribe(adGroupList => {
      this.activeDirectoryGroups = adGroupList;
      console.log('getActiveDirectoryGroups func called', adGroupList);

      this.getGroupInformationByUserEmailV2(this.user.userPrincipalName, adGroupList).subscribe(groupList => {
        console.log('GetGroupInformationByUserEmailV2 func called', groupList);
        this.user.activeDirectoryGroups = groupList;

        if (this.user.activeDirectoryGroups.length > 0) {
          this.getRolesBasedOnActiveDirectoryGroups(this.user.activeDirectoryGroups).subscribe(roleList => {
            this.user.role = roleList;

            if (roleList.length > 0) {
              console.log('getRolesBasedOnActiveDirectoryGroups func called', roleList);
              this.updateLOBForUserObject();
            } else {
              console.log('Logged In User does not have any role for accessing the application.');
              this.updateFlagNoAccess();
            }
          });
        } else {
          console.log('Logged In User does not have access to application.');
          this.updateFlagNoAccess();
        }
      });
    });

    localStorage.setItem('currentUser', JSON.stringify(this.user));
    console.log('After Initialization - user', this.user);
  }

  updateUserDetails() { // Generic method used in many component to get user data after update 
    const jsonGlobalString = localStorage.getItem('currentUser');
    if (jsonGlobalString == null) {
      this.roleLoadEndSource.next('');
    }
    else {
      const jsonGlobalUserObj = JSON.parse(jsonGlobalString);
      this.user = new User();
      this.user.userName = jsonGlobalUserObj.userName;
      this.user.firstName = jsonGlobalUserObj.firstName;
      this.user.lastName = jsonGlobalUserObj.lastName;
      this.user.loggedInUserEmail = jsonGlobalUserObj.loggedInUserEmail;
      this.user.loggedInUserFullName = jsonGlobalUserObj.loggedInUserFullName;
      this.user.role = jsonGlobalUserObj.role;
      this.user.activeDirectoryGroups = jsonGlobalUserObj.activeDirectoryGroups;
      this.user.cachedDateTime = jsonGlobalUserObj.cachedDateTime;
      this.user.lineOfBusiness = jsonGlobalUserObj.lineOfBusiness;
      this.user.employeeId = jsonGlobalUserObj.employeeId;
      this.user.userLanguage = jsonGlobalUserObj.userLanguage;
      this.user.firstName = jsonGlobalUserObj.firstName;
      this.user.lastName = jsonGlobalUserObj.lastName;
      this.user.jobTitle = jsonGlobalUserObj.jobTitle;
      this.user.serviceLine = jsonGlobalUserObj.serviceLine;
      this.user.account = jsonGlobalUserObj.account;
      this.user.officeLocation = jsonGlobalUserObj.officeLocation;
      this.user.userType = jsonGlobalUserObj.userType;
      this.generateRoleEvent();

      console.log('Re-populate User Info ', this.user);
    }
  }

  updateLOBForUserObject() {
    console.log('all user information is ready ', this.user);
    if (this.user.userType == null || this.user.userType == undefined || this.user.userType == "Member" || this.user.userType.length == 0) {
      this.graphService.GetContactInfo(this.user.userName).subscribe(
        userDetails => {
          this.user.lineOfBusiness = userDetails.lineOfBusiness;
          this.user.employeeId = userDetails.employeeId;
          this.user.userLanguage = userDetails.userLanguage;
          this.user.firstName = userDetails.firstName;
          this.user.lastName = userDetails.lastName;
          this.user.jobTitle = userDetails.jobTitle;
          this.user.account = userDetails.department;
          this.user.serviceLine = userDetails.serviceLine;
          this.user.officeLocation = userDetails.officeLocation;

          //START Initiating member firm properties
          this.user.isMemberFirmFeatureEnabled = false;
          this.user.isAllowedAccess = false;
          //END Initiating member firm properties

          // PENDO CODE Getting properties from sharepoint 
          this.sharepointService.GetUserPropertiesByEmail(this.user.loggedInUserEmail).subscribe(userProperties => { //Reading the values from sharepoint 
            this.user.serviceLine = userProperties?._serviceline == null ? '' : userProperties?._serviceline;
            this.user.officeLocation = userProperties?._spslocation == null ? '' : userProperties._spslocation;
            this.user.lineOfBusiness_sharepoint = userProperties?._businessline == null ? '' : userProperties?._businessline;
            localStorage.removeItem('currentUser');
            localStorage.setItem('currentUser', JSON.stringify(this.user));
            this.generateRoleEvent();
          }, error => {
            console.error(" Error in updating LOB ", error)
          })
          // END PENDO CODE
        },
        error => {
          console.error(' Error in updating LOB ', error);
        });
    } // end member firm else
    else {
      this.memberFirmService.GetMemberFirmFeatureFlag().subscribe(isMemberFirmFeatureEnabled => {
        if (isMemberFirmFeatureEnabled) {
          this.getSetMemberFirmDetails();
        } else {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(this.user));
          console.log('Logged In User is Guest but memberfirm feature flag is disabled.');
          this.updateFlagNoAccess();
        }
      }, error => {
        console.error(' Error in getting GetMemberFirmFeatureFlag.', error);
        this.updateFlagNoAccess();
      });
    }
  }

  getSetMemberFirmDetails() {
    this.memberFirmService.getMemberFirmDetails(this.user.userName).subscribe(memberFirmData => {
      if (memberFirmData.length > 0) {
        let memberFirm = memberFirmData[0];
        this.user.memberFirmADGroup = memberFirm?.memberFirmADGroup;
        this.user.memberFirmTeamMemberShortRoleName = memberFirm?.teamMemberShortRoleName;
        this.user.memberFirmIndusConsentShortRoleName = memberFirm?.indusConsentShortRoleName;
        this.user.memberFirmCountry = memberFirm?.memberFirmCountry;
        this.user.isMemberFirmFeatureEnabled = memberFirm?.isMemberFirmFeatureEnabled;
        this.user.isAllowedAccess = memberFirm?.isAllowedAccess;
      }
      localStorage.removeItem('currentUser');
      localStorage.setItem('currentUser', JSON.stringify(this.user));
      this.generateRoleEvent();
      if (this.user.isAllowedAccess == false) {
        console.log('Logged In User is Guest but dose not have member firm access.');
        this.updateFlagNoAccess();
      }
    }, error => {
      console.error(' Error in updating LOB - getMemberFirmDetails', error);
    });
  }

  getUserInfo() {
    console.log('User Info ', this.user);
    this.updateUserDetails();
    return this.user;
  }

  logout() {
    localStorage.removeItem('returnUrlAfterLogin');
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('isUserAgentIE');
    localStorage.removeItem('userpreferencelist');
    localStorage.removeItem('iShortcutCount');
    localStorage.removeItem('subscriptionKey');
  }

  getGroupInformationByUser(id: string): Observable<string[]> {
    return this.http.get<string[]>(this.IntegrationAPIEndPoint + '/Graph/Group/' + id);
  }

  getGroupInformationByUserEmail(userPrincipalName: string): Observable<string[]> {
    return this.http.post<string[]>(this.IntegrationAPIEndPoint + '/User/GetGroupsUserMemberOf',
      { UserPrincipalName: userPrincipalName });
  }

  getGroupInformationByUserEmailV2(userPrincipalName: string, groupNames: string[]): Observable<string[]> {
    const adGroupsUserMemberOfCheckModel: ADGroupsUserMemberOfCheckModel = new ADGroupsUserMemberOfCheckModel();
    adGroupsUserMemberOfCheckModel.UserPrincipalName = userPrincipalName;
    adGroupsUserMemberOfCheckModel.AzureADGroupNames = groupNames;

    return this.http.post<string[]>(this.IntegrationAPIEndPoint + '/User/GetGroupsUserMemberOfV2',
      adGroupsUserMemberOfCheckModel);
  }

  getActiveDirectoryGroups(): Observable<string[]> {
    return this.http.get<string[]>(this.APIEndPoint + '/Configuration/ActiveDirectoryGroups');
  }

  getRolesBasedOnActiveDirectoryGroups(adGroupList: string[]): Observable<Role[]> {
    const stringList: StringList = new StringList();
    stringList.list = adGroupList;
    return this.http.post<Role[]>(this.APIEndPoint + '/Configuration/RolesByADGroups',
      stringList);
  }

  getProfile() {
    const httpHeaders = new HttpHeaders().set(
      'skip',
      'true'
    );
    const options = {
      headers: httpHeaders
    };
    return this.http.get(this.tokenURL + '/v1.0/me?$select=id,displayName,userPrincipalName,mail,mobilePhone,jobTitle,officeLocation,businessPhones,OnPremisesExtensionAttributes,employeeId,department,preferredLanguage,givenName,surname,accountEnabled,userType', options);
  }

  updateFlagAccess() {
    this.loginFlagSource.next(1);
  }

  updateFlagNoAccess() {
    this.loginFlagSource.next(-1);
  }

  updateFlagLoading() {
    this.loginFlagSource.next(0);
  }
  generateRoleEvent() { //For Navbar
    const jsonString = localStorage.getItem('currentUser');
    const jsonUserObj = JSON.parse(jsonString);
    let roleIdList = '';
    if (jsonUserObj != null && jsonUserObj.role != null) {
      for (const roleItem of jsonUserObj.role) {
        roleIdList += roleItem.roleId + ',';
      }
      roleIdList = roleIdList.slice(0, roleIdList.length - 1);
    }
    console.log(' Role List on Update LOB ', roleIdList);
    this.roleLoadEndSource.next(roleIdList);
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-no-data-found-in-list',
  templateUrl: './no-data-found-in-list.component.html',
  styleUrls: ['./no-data-found-in-list.component.css']
})
export class NoDataFoundInListComponent implements OnInit {

  cardTitle: string;
  constructor(private data: DataService) {

  }

  ngOnInit() {
    this.data.currentCardTitle.subscribe(title => this.cardTitle = title);
  }

}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.get('skip')) {
      return next.handle(req);
    } else {

      const user = JSON.parse(localStorage.getItem('currentUser'));
      const token = localStorage.getItem('currentUserToken');
      const subscriptionKey = localStorage.getItem('subscriptionKey');

      console.log('Req Url ', req.urlWithParams);
      if (req.method === 'POST') {
        const newHeaders = req.headers;
        const body = req.body;
        const newBody = {
          ...body,
          userName: user.userName,
          loggedInUserEmail: user.loggedInUserEmail,
          firstName: user.firstName,
          lastName: user.lastName,
          loggedInUserFullName: user.loggedInUserFullName,
          role: user.role,
          activeDirectoryGroups: user.activeDirectoryGroups
        };

        req = req.clone({ headers: newHeaders, body: newBody });
      }

      if (token !== null) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
            'Subscription-Key': subscriptionKey
          }
        });
      }

      return next.handle(req).pipe(catchError(error => {
        console.error(error);
        const localError = error.message || error.statusText;
        return throwError(localError);
      }));
    }
  }
}

export class UpdatePermissionsModelData {
    updatePermissionsModel: UpdatePermissionsModel[] = [];
}

export class UpdatePermissionsModelDataWithINDUSConsent {
    updatePermissionsModel: UpdatePermissionsModel[] = [];
    iNDUSConsent: string;
}

export class UpdatePermissionsModel {
    siteUniqueID: string;
    siteType: string;
    usersListEmail: UsersListEmailModel[] = [];
}

export class UsersListEmailModel {
    groupType: string;
    groupUsersEmail: ADUser[] = [];
}

export class ADUser {
    firstName: string;
    lastName: string;
    userEmailId: string;
    invitationurl: string;
}

export class ADGroupUsers {
    siteType: string;
    usersDetails: UsersDetails[] = [];
}

export class UsersDetails {
    id: string;
    groupType: string;
    groupName: string;
    userName: string;
    userEmailId: string;
    jobtitle: string;
    serviceLine: string;
}


export class UpdatePermissionResponseModel {
    clientUsersList: ADUser[] = [];
    status: string;
}
export class ADGroup
    {
        groupType:string;

        groupUsersEmail:ADUser[]=[];
    }
<div class="container-fluid user-profile" *ngIf="userInfo != null">
    <h2>User Profile</h2>
    <form>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="name">Name :</label>&nbsp;
                    <label>{{userInfo.loggedInUserFullName}}</label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="email">Email :</label>&nbsp;
                    <label>{{userInfo.loggedInUserEmail}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="firstname">First Name :</label>&nbsp;
                    <label>{{userInfo.firstName}}</label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="lastname">Last Name :</label>&nbsp;
                    <label>{{userInfo.lastName}}</label>
                </div>
            </div>
        </div>
        <div *ngIf="!isMemberFirmEnabled">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="userlanguage">User Language</label>&nbsp;
                    <label>{{userInfo.userLanguage}}</label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label></label>&nbsp;
                    <label></label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="department">Department :</label>&nbsp;
                    <label>{{userInfo.account}}</label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="employeeno">Employee No :</label>&nbsp;
                    <label>{{userInfo.employeeId}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="serviceline">Service Line :</label>&nbsp;
                    <label>{{userInfo.serviceLine}}</label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="jobtitle">Job Title :</label>&nbsp;
                    <label>{{userInfo.jobTitle}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="businessline">Business Line :</label>&nbsp;
                    <label>{{userInfo.lineOfBusiness}}</label>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="officelocation">Office Location :</label>&nbsp;
                    <label>{{userInfo.officeLocation}}</label>
                </div>
            </div>
        </div>
        </div>
        <div *ngIf="isMemberFirmEnabled">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="businessline">Member Firm :</label>&nbsp;
                        <label>{{userInfo.isMemberFirmFeatureEnabled}}</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="officelocation">User Type :</label>&nbsp;
                        <label>{{userInfo.userType | titlecase}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="businessline">Member Firm ADGroup :</label>&nbsp;
                        <label>{{userInfo.memberFirmADGroup}}</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="officelocation">Member Firm Country :</label>&nbsp;
                        <label>{{userInfo.memberFirmCountry}}</label>
                    </div>
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="role">Role :</label>&nbsp;
                    <ul id="form-grouplist" *ngIf="userInfo.role != null">
                        <li *ngFor="let roleInfo of userInfo.role let i = index">{{i+1}}. {{roleInfo.roleName}}</li>
                    </ul>
                    <div *ngIf="userInfo.role == null">
                        No role found for user.
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="groups">Active Directory Groups :</label>&nbsp;
                    <ul id="form-grouplist" *ngIf="groups != null">
                        <li *ngFor="let group of groups let i = index">{{i+1}}. {{group}}</li>
                    </ul>
                    <div *ngIf="groups == null">
                        No active directory group found for user.
                    </div>
                </div>
            </div>

        </div>
        <div class="form-group">
            <label>Last Cached Date Time :</label>&nbsp;
            <label>{{userInfo.cachedDateTime ? (userInfo.cachedDateTime | date:'medium') : 'Not Found' }}</label>
        </div>

        <br />
        <button type="button" class="btn btn-outline-warning" (click)="clearCacheAndLogout()">Clear Cache &
            Logout</button>
        <br />
    </form>
</div>
<div class="container user-profile" *ngIf="userInfo == null">
    <div>No User Information Found.</div>
</div>
import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { DocumentListModel, DocumentList } from '../model/DocumentListModel'
import { EngagementList } from '../model/EngagementListModel'
import { AppConfigService } from './appConfigService';
import { AddWorkspaceDocumentValidationModel } from "../model/TemplatePackageModel";
import { SolutionFamily, TemplateDetails, tempalteDetailsRequestModel } from "../model/DocumentTemplate";

@Injectable()
export class DocumentService {
  APIEndPoint: string;
  userName: string;
  engagement: EngagementList = new EngagementList();
  documentList: DocumentList = new DocumentList();
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    let nameJSON = JSON.parse(localStorage.getItem("currentUser"));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
    }
  }

  getMyDocumentListById(engId: number): Observable<DocumentList[]> {
    this.engagement.engagementId = engId;
    this.engagement.LastModifiedBy = this.userName;
    return this.http.post<DocumentList[]>(this.APIEndPoint + "/Document/GetMyDocumentsListByEngId", this.engagement);
  }

  getMyDocumentList(): Observable<DocumentList[]> {
    return this.http.post<DocumentList[]>(this.APIEndPoint + "/Document/GetMyDocumentsList", { userName: this.userName });
  }

  getMyReviewsDocumentsByClient(clientId: number): Observable<DocumentList[]> {
    this.documentList.clientId = clientId;
    this.documentList.createdby = this.userName;
    return this.http.post<DocumentList[]>(this.APIEndPoint + "/Document/GetMyReviewsDocumentsByClient/", this.documentList);
  }

  getSubDocumentList(documentCatgory: string, pageName: string): Observable<DocumentList[]> {
    const fetchUrl = '/Document/GetSubDocumentsList';
    return this.http.post<DocumentList[]>(this.APIEndPoint + fetchUrl, { userName: this.userName, documentCatgory: documentCatgory, pageName: pageName });
  }

  getCountOfDocumentAliasNameByEngagementId(workspaceDocumentValidationModel: AddWorkspaceDocumentValidationModel): Observable<number> {
    return this.http.post<number>(this.APIEndPoint + "/Document/GetCountOfDocumentAliasNameByEngagementId", workspaceDocumentValidationModel);
  }
  getSolutionFamilyByServiceLine(serviceLine: string): Observable<SolutionFamily[]> {
    return this.http.get<SolutionFamily[]>(
      this.APIEndPoint + '/Document/GetSolutionFamilyByServiceLine/' + serviceLine
    );
  }
  getTemplateDetails(tempalteDetailsRequestModel: tempalteDetailsRequestModel): Observable<TemplateDetails[]> {
    return this.http.post<TemplateDetails[]>(
      this.APIEndPoint + '/Document/GetTemplateDetails/', tempalteDetailsRequestModel);
  }
  getEngagementDocumentsByEngId(engId: number): Observable<DocumentList[]> {
    this.engagement = new EngagementList();
    this.engagement.engagementId = engId;
    return this.http.post<DocumentList[]>(this.APIEndPoint + "/Document/GetEngagementDocumentsByEngId", this.engagement);
  }
}

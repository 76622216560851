import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { LogService } from '../services/log.service';

interface ComponentCanDeactivate {
    canDeactivate: (currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) => boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;
}

@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private msalSvc: MsalService,
        private logService: LogService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //this.logService.log('canActivate func called in AuthGuard class ', route, this.msalSvc, state.url);
        localStorage.setItem('returnUrlAfterLogin', state.url);
        let isFlag = this.msalSvc.instance.getAllAccounts().length > 0;
        console.log('User Exists Flag ', isFlag);
        if (isFlag) {
            return true;
        }
        this.router.navigate(['Login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }

    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return component.canDeactivate(currentRoute, currentState, nextState);
    }
}

<div id="LeftNavandIWantToContainer">
<form (ngSubmit)="onSubmit(navigationForm)" #navigationForm="ngForm">
  <div class="row col-md-12">
    <div class="col-md-6">
      <label for="title">Title</label><br />
      <input type="text" class="textboxDesign" required minLength="4" maxlength="25"
        [(ngModel)]="model.title" id="title" name="title" #title="ngModel"
        (blur) = 'checkSpaceChartBegin($event)'/>
      <div *ngIf="title.invalid && (title.dirty || title.touched)" class="alert alert-danger">
        <div *ngIf="title.errors.required">
          Title is required.
        </div>
        <div *ngIf="title.errors.minlength">
          Title must be at least 5 characters long.
        </div>
        <div *ngIf="title.errors.maxlength">
          Title must not be at more than 25 characters long.
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="imagePath">Image Path</label><br />
      <div class="form-group">
        <select id="imagePath" name="imagePath" class="dropdownOutline" [(ngModel)]="model.imagePath"
        #imagePath="ngModel" required>
          <option *ngFor="let image of staticImageList" value="{{image}}">{{image}}</option>
        </select>
          <div *ngIf="imagePath.invalid && (imagePath.dirty || imagePath.touched)" class="alert alert-danger">
            Image Path is required.
          </div>

      </div>
    </div>
  </div>
  <div class="row col-md-12">
    <div class="col-md-6">
      <label for="link">Link</label><br />
      <input type="text" class="textboxDesign" pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$" required minLength="10" maxlength="100"
        [(ngModel)]="model.link" id="link" name="link" #link="ngModel" />
      <div *ngIf="link.invalid && (link.dirty || link.touched)" class="alert alert-danger">
        <div *ngIf="link.errors.required">
          Link is required.
        </div>
        <div *ngIf="link.errors.minlength">
          Link must be at least 10 characters long.
        </div>
        <div *ngIf="link.errors.maxlength">
          Link must not be at more than 100 characters long.
        </div>
        <div *ngIf="link.errors.pattern">
          Must be a valid Link URL.
        </div>

      </div>
    </div>
    <div class="col-md-6">
      <label for="Sequence">Sequence</label><br />

      <select id="Sequence" name="sequence" class="dropdownOutline"
      [(ngModel)]="model.sequence" required
      #sequence="ngModel">
        <option *ngFor="let number of sequenceList" value="{{number}}">{{number}}</option>
      </select>
      <div *ngIf="sequence.invalid && (sequence.dirty || sequence.touched)" class="alert alert-danger">
        Sequence is required.
      </div>
    </div>
  </div><br />
  <div class="row col-md-12">
    <div class="col-md-6">
      Roles<br />
      <div class="RoleDiv">
        <span *ngFor="let role of roleList">
          <input type="checkbox" id="cb_role_{{role.key}}" name="cb_role_{{role.key}}"
            class="regular-checkbox big-checkbox" value="{{role.key}}" [(ngModel)]="role.checked"
            (change)="CheckBoxChanged()"/>&nbsp;
          <label for="cb_role_{{role.key}}"></label>&nbsp;
          <label class="RolelabelDesign">{{role.value}}</label>&nbsp;
          <br />
        </span>
      </div>
      <div *ngIf="isRoleValidationDisplayed" class="alert alert-danger">
        Select at least one role.
      </div>
    </div>
  </div>
  <div class="row col-md-12" class="saveCancelButtonDiv">
      <button class="btn PrimaryButton" type="submit" [disabled]="!navigationForm.form.valid">Save</button>
      &nbsp;&nbsp;<button class="SecondaryButton" type="button" (click)="clearForm(navigationForm)">Reset</button>

  </div>            <br>
</form>
<div class="blank">&nbsp;</div>
<div class="tableDiv">
  <div class="tableHeader">Left Nav List</div>
  <table id="NavigationRoleTable" aria-describedby="Left Nav configuration"
  class="table table-light DocumentDataTable ResponsiveDataTable">
    <thead>
      <tr>
        <th scope="col">Title</th>
        <th scope="col">Image Path</th>
        <th scope="col">link</th>
        <th scope="col">Role</th>
        <th scope="col">Sequence</th>
        <th scope="col"
        [class.actionsCol]="isUserAgentIE==-1"  [class.actionsColonIE]="isUserAgentIE > 0"
        >Actions</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let info of navigationBasedRoleList">
        <td data-label="Navigation title: "><a href="{{info.link}}" style="cursor: pointer;">{{info.title}}</a></td>
        <td data-label="Navigation ImagePath: ">{{info.imagePath}}</td>
        <td data-label="Navigation Link: " class="link">{{info.link}}</td>
        <td data-label="Navigation Roles: ">{{info.roles}}</td>
        <td data-label="Navigation sequence: " >{{info.sequence}}</td>
        <td data-label="Action: ">
          <img class="img-editdeleteicon" src="../assets/images/adminconfiguration/Edit.png"
            (click)="editNavigation(info.navigationId)" alt="Edit" />
          <img class="img-editdeleteicon" src="../assets/images/adminconfiguration/delete.png" data-toggle="modal"
            data-target="#confirm-delete" (click)="selectNavigation(info.navigationId)" alt="Delete" />
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal fade" id="confirm-delete" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        Delete
      </div>
      <div class="modal-body">
        Are you sure you want to Delete?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <a class="btn btn-danger btn-ok" (click)="deleteNavigation(navigationForm)">Delete</a>
      </div>
    </div>
  </div>
</div> 

</div>


<div class="modal modal_outer right_modal fade" id="add-workspace-document-modal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel2">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header m-2">
        <h6 class="modal-title">Add an additional document</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="closeAddWorkspaceDocumentModal()">
          <span style="font-size: 15px;vertical-align: middle;margin-right: 3px;">Close</span>
          <span style="vertical-align: top;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="col-md-12 col-lg-12 col-xl-12 pl-0 pr-0">
          <span>Select the document template from list below and click "Ok". You will then be taken to the questionnaire
            page.</span>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-12 mt-3 pl-0 pr-0">
          <label for="templates" class="font-weight-bold">Select template<span class="mandatory"> *</span></label>
          <div class="col-md-10 px-0 mw-100">
            <div class="selectdiv">
              <select class="dropdown rounded-0" id="ddTemplate" name="template"
                [(ngModel)]="addWorkspace_SelectedData.templatePackageId" (change)="onTemplateChange()">
                <option value="undefined" disabled hidden>Select a template</option>
                <option *ngFor="let template of addWorkspace_TemplateList" [value]=template.templatePackageId>
                  {{template.templateName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12 px-0" class="error-color" *ngIf="!addWorkspace_SelectedData.IsTemplateSelected">
            Select a template
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-12 mt-3 pl-0 pr-0">
          <label for="templates" class="font-weight-bold">Tab name<span class="mandatory"> *</span></label>
          <div class="col-md-10 px-0 mw-100">
            <input type="text" id="document-alias-name"
              class="edp-font-14 form-control rounded-0 border document-alias-name input-height" maxlength="50"
              placeholder="{{tabNamePlaceholderText}}" (focus)="this.tabNamePlaceholderText=''"
              (blur)="this.tabNamePlaceholderText = 'Enter an tab name'" [(ngModel)]="addWorkspace_SelectedData.documentAliasName"
              autocomplete="off" />
          </div>
          <div class="col-md-12 px-0 select-slim" class="error-color"
            *ngIf="addWorkspace_SelectedData.IsDocumentAliasNameEmpty">
            <p>Tab name is a required field. </p>
          </div>
          <div class="col-md-12 px-0 select-slim" class="error-color"
            *ngIf="!addWorkspace_SelectedData.IsDocumentAliasNameEmpty && addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded">
            <p>Tab name is exceeding 50 char limit.</p>
          </div>
          <div class="col-md-12 px-0 select-slim" class="error-color"
            *ngIf="!addWorkspace_SelectedData.IsDocumentAliasNameEmpty && !addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded && addWorkspace_SelectedData.IsDocumentAliasNameDuplicate">
            <p>Please enter unique tab name.</p>
          </div>
        </div>
        <div class="row mt-3 mb-2 ml-0 mr-0">
          <div class="col-md-12 text-right pr-0">
            <button class="btn SecondaryButton text-center w-25 mr-4" type="button"
              (click)="closeAddWorkspaceDocumentModal()">
              <span>
                <img class="d-inline-block mr-1 align-top" src="../../../assets/images/EDP/Engagement/cancel-24px.svg"
                  alt="Cancel" />
              </span>
              <span class="d-inline-block align-middle"> Cancel</span>
            </button>
            <button class="btn PrimaryButton text-center w-25" type="button" (click)="validateAddWorkspaceDocument()">
              <span>
                <img class="d-inline-block mr-1  align-top" src="../../../assets/images/EDP/Engagement/ok-24px.svg"
                  alt="ok" />
              </span>
              <span class="d-inline-block align-middle"> Ok </span>
            </button>
          </div>
        </div>
      </div>
    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div>
<!--modal -->
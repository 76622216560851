import { SharePointMetadataMapping } from './../../model/sharepoint-metadata-mapping';
import { ApiRequest } from '../../model/Common/api-request.model';
import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EngagementService } from '../../services/EngagementService';
import { ActionTiles } from '../../model/actionTiles';
import '../engagement-summary/engagement-summary.js';
import { EngagementListModel, EngagementList, CollaborationAssignment, CollaborationClientTeamMember, UpdatedAssignmentMembersViewModel, EngagementCollaborationsViewModel, CollaborationSiteTeamMember, UpdateEngagementNameMode, ContinuanceEligibilityModel, UpdateEngagementStatusModel, EngagementCloseoutConfiguration, CloseoutSectionVisibility, CloseoutSections, IWantToDocumentsReference } from 'src/app/model/EngagementListModel';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { DataService } from 'src/app/services/data.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { Alert } from 'src/app/model/Alert';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { WorkspaceModel, WorkspaceInterviewsStatus, QuestionnaireDialogTitles, RemoveSecondaryWorkspaceModel } from 'src/app/model/WorkspaceModel';
import { DatePipe } from '@angular/common';
import { PowerAutomateRequestModel, TeamMemberForReviewer, TeamMembers } from 'src/app/model/Workspace/TeamMembers';
import { Subscription, forkJoin } from 'rxjs';
import { CollaborationSite, CollaborationSiteViewModel, CollaborationSiteMembers, ADGroupUsersSyncCollabMembers, CollaborationSiteMembersWithClientMemberModel, CurrentCollaborationSiteViewModel, ADClientSiteUsersSyncEngageClientMmembers, CollaborationClientSite, CollaborationSiteIndusModel, RemoveIndusCollaborationSiteMember, CollaborationSpacePriorYearMappingModel, CollaborationSpacePriorYearViewModel, UpdateCollaborationSpacePriorYearMappingModel, UpdateCollaborationSpacePriorYearMappingModelWrapper, CollabrartionPermissionManagementModel, CollaborationSpacePriorYearMappingModelWrapper } from 'src/app/model/CollaborationSite';
import { EngagementAssignment, UpdateAssignmentEQCRViewModel } from 'src/app/model/Engagement/EngagementAssignments';
import { TeamMember } from 'src/app/model/Engagement/TeamMember';
import { Applicationmaster, AppCodes } from 'src/app/model/ApplicationMaster';
import { TeamMemberPermission, ClientMemberPermission, ClientMemberPermissionModel } from 'src/app/model/Engagement/TeamMemberPermission';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { RoleNames, EnumRoles, RevievingProfessionalType } from 'src/app/model/Role';
import { AzureADUsersArrayList, IndusADUsersListViewModel, IndusADUsersViewModel, SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { AdUsersService } from 'src/app/services/adusers.service';
import { TeamMemberRole, EnumTeamMemberRoleCodes } from 'src/app/model/Engagement/TeamMemberRole';
import { MembersList } from 'src/app/model/MembersList';
import { LogService } from 'src/app/services/log.service';
import { EngagementStatus, EngagementDialogTitles, UpdateQuestionnairePreCheckModel, EngagementClientVoiceViewModel, ClientVoiceTeamMember, ConsentResetDialogTitle, Engagements } from 'src/app/model/Engagement/Engagements';
import { KeyValue } from 'src/app/model/KeyValue';
import { BooleanKeyValue } from 'src/app/model/BooleanKeyValue';
import { FieldValidator } from 'src/app/model/Engagement/FieldValidator';
import { SecondarySolutionCode } from 'src/app/model/Engagement/SecondarySolutionCodes';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ClientLocation } from 'src/app/model/Client/ClientLocation';
import { ContactTypes } from 'src/app/model/Client/ContactTypes';
import { EngagementAssignmentWithoutSCode, NewEngAssignmentWrapperModel, PrimaryWorkspaceModel, UpdateEngagementTnCModel } from 'src/app/model/Engagement/CreateEngagementData';
import { ClientTeamMembers } from 'src/app/model/Engagement/ClientTeamMember';
import { Employee } from 'src/app/model/Workspace/Employee';
import { SearchEmployeeModel } from 'src/app/model/Workspace/SearchEmployeeModel';
import { AssignmentRequestList } from 'src/app/model/Workspace/AssignmentRequestList';
import { CMSService } from 'src/app/services/cmsservice.service';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { SharepointSiteModel, SPSiteMetadata, AvepointResponseModel, DecisionsTemplateRequestModel, DecisionsTemplateResponseModel, SiteInputRequest, AvepointResponseListModel, DecisionTemplate, SharepointSiteAndUpdateSharePointMetadataModel, AvepointResponseDataModel, ApplySitePolicyRequestModel, SharepointSiteDocumentModel } from 'src/app/model/SharePoiint/SharePointSite';
import { AppConfigService } from 'src/app/services/appConfigService';
import { UpdatePermissionsModel, UsersListEmailModel, ADGroupUsers, UpdatePermissionsModelData, ADUser, UpdatePermissionsModelDataWithINDUSConsent } from 'src/app/model/SharePoiint/ActiveDirectory';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';
import { ESignatureService } from 'src/app/services/Integration/esignature.service';
import { SharepointService } from 'src/app/services/sharepoint.service';
import { SPDocumentList } from 'src/app/model/SharePoiint/SharePointSite';
import { EngagementESignature, SharePointDocuments, SigningRequestDiscardRemark } from 'src/app/model/Engagement/EngagementESignature';
import { EngagementESignatureRequestModel, SignatureMember } from 'src/app/model/Engagement/EngagementESignatureRequestModel';
import { EngagementESignatureResponseModel } from 'src/app/model/Engagement/EngagementESignatureResponseModel';
import { RPDisplayModel } from 'src/app/model/Workspace/RPDisplayModel';
import { DecisionsRPListModel } from 'src/app/model/Workspace/DecisionsRPListModel';
import { DecisionsRequestTypes } from 'src/app/model/Workspace/DecisionsApiLogModel';
import { ClientBillingContact } from 'src/app/model/Client/ClientBillingContact';
import { ClientService } from '../../services/clientservice';
import { AzureADUsersList } from 'src/app/model/AzureADUserList';
import { SharePointWrapper } from 'src/app/model/sharepoint-metadata-wrapper';
import { CloseSiteUrl } from 'src/app/model/SharePoiint/close-site-url-list';
import { AssignmentResponseList } from 'src/app/model/Workspace/AssignmentResponse';
import { ClosureService } from 'src/app/services/Integration/closure.service';
import { ConveneApiLogModel, ConveneRequestTypes, RequestMethods } from 'src/app/model/ConveneIntegration/convene-api-log-model';
import { ClosureRequestModel } from 'src/app/model/ConveneIntegration/closure-request-model';
import { HotDocsService } from 'src/app/services/HotDocsService';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { CollaborationSiteService } from 'src/app/services/collaboration-site.service';
import { HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { ConsentArray, EngagementConsentInsertModel, EngagementConsentModel } from 'src/app/model/Engagement/EngagementConsent';
import { EngageEvent } from 'src/app/model/Engagement/EngageEvent';
import { EngagementCompletionDateViewModel, EngCompletionDtEditViewModel } from 'src/app/model/Engagement/EngagementDetails';
import { EngagementCloseoutClientVoiceSurvey } from '../engagement/engagement-closeout-client-voice-survey/engagement-closeout-client-voice-survey.component';
import { EngagementCloseoutRetention } from '../engagement/engagement-closeout-retention/engagement-closeout-retention.component';
import { WorkspaceDocumentViewModel } from 'src/app/model/WorkspaceDocumentModel';
import { EngagementCloseoutArchivalComponent } from '../engagement/engagement-closeout-archival/engagement-closeout-archival.component';
import { EngagementCloseoutCleanUpChecklistComponent } from '../engagement/engagement-closeout-clean-up-checklist/engagement-closeout-clean-up-checklist.component';
import { EngagementCloseoutQualityChecklistComponent } from '../engagement/engagement-closeout-quality-checklist/engagement-closeout-quality-checklist.component';
import { EngagementCloseoutInsightMetrixComponent } from '../engagement/engagement-closeout-insight-metrix/engagement-closeout-insight-metrix.component';
import { EngagementCloseoutAuditChecklistComponent } from '../engagement/engagement-closeout-audit-checklist/engagement-closeout-audit-checklist.component';
import { PreviousClientVoiceSurveyRequestModel } from 'src/app/model/Engagement/EngagementClientVoice';
import { ClientvoiceService } from 'src/app/services/Integration/clientvoice.service';
import { PSDDataForUserCheck, solutionCodePSDMaping } from 'src/app/model/SolutionCodePSD';
import { OneViewClientOpportunityViewModel, OneViewClientOpportunityViewModelWrapper, OpportunityServiceList, OpportunityServiceMiniModel, OpportunityServiceModel, OpportunityServiceRequestModel, OpportunityServiceRequestModelByEngagementId, SearchOpportunityByEngagementIdRequestModel, SearchOpportunityByOpportunityIdRequestModel, SearchOpportunityByRequestModel } from 'src/app/model/Client/CreateClientViewModel';
import { WorkspaceSupplementalDoc } from 'src/app/model/Workspace/WorkspaceSupplementalDoc';
import { QnARecordForDelete } from 'src/app/model/Workspace/QnARecordForDelete';
import { SharepointListItem } from 'src/app/model/SharePoiint/sharepoint-list-item';
import { SharepointFilePickerComponent } from 'src/app/common/popups/sharepoint-file-picker/sharepoint-file-picker.component';
import { SiteCollaborationMembers } from 'src/app/model/ClosureNotification/site-collaboration-members.model';
import { AzureADUser } from 'src/app/model/ClosureNotification/azure-ad-user.mode';
import { DocuSignTemplateList } from 'src/app/model/Engagement/DocuSignTemplateModel';
import { ClientConsentDataTerm, ClientConsentFlagUpdateModel, ClientConsentOptions } from 'src/app/model/ClientConsent';
import { ClientConsentService } from 'src/app/services/ClientConsentService';
import { UpdateClientConsentOptions } from 'src/app/model/ClientConsent';
import { IndusMemberEmailIds, UpdateINDUSConsentFlag } from 'src/app/model/IndusConsentModel';
import { ApproveDenyExtensionModal, ApproveDenyExtensionViewModal, EngagementDueDateExtension } from 'src/app/model/Engagement/EngagementDueDateExtension';
import { InterchangeableSolutionCode } from 'src/app/model/Engagement/InterchangeableSolutioncodes';
import { MemberFirmData } from 'src/app/model/MemberFirm/member-firm-data';
import { MemberFirmService } from 'src/app/services/member-firm.service';
import { CLMService } from 'src/app/services/clm.service';
import { GoverningAgreementNameAndStatusRequest } from 'src/app/model/CLM/governing-agreemen-request';
import { GoverningAgreementNameAndStatusResponse } from 'src/app/model/CLM/governing-agreement-response';
import { CLMApiLog } from 'src/app/model/CLM/clm-api-log';
import { CLMApiLogrRequestType } from 'src/app/model/enums/clm-api-log-request-type.enum';
import { ClmSignedDocumentRequest } from 'src/app/model/CLM/clm-signed-document-request';
import { SharepointDocumentContentRequest } from 'src/app/model/SharePoiint/sharepoint-document-content-request';
import { EditClientViewModel } from 'src/app/model/Client/EditClientViewModel';
import { CLMResponse } from 'src/app/model/CLM/clm-response';

import { EngagementAdministrativeCloseModel } from 'src/app/model/engagements';
import { EngagementStatusViewModel } from 'src/app/model/Engagement/engagement-status-view-model';
import { CLMDocumentList } from 'src/app/model/CLM/clm-document-list';
import { DocumentList } from 'src/app/model/DocumentListModel';
import { DocumentService } from 'src/app/services/DocumentService';
import { CLMDetails } from 'src/app/model/CLM/clm-details';
import { unescape } from 'querystring';
declare var HD$;
declare var $;
@Component({
  selector: 'app-engagement-summary',
  templateUrl: './engagement-summary.component.html',
  styleUrls: ['./engagement-summary.component.css'],
})
export class EngagementSummaryComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  isEngSummaryScreen: boolean = false;
  SPSiteMetadataModel: SPSiteMetadata = new SPSiteMetadata();
  sharepointSiteModel: SharepointSiteModel = new SharepointSiteModel();
  sharepointSiteAndUpdateSharePointMetadataModel: SharepointSiteAndUpdateSharePointMetadataModel = new SharepointSiteAndUpdateSharePointMetadataModel()
  sharepointSiteArr: SharepointSiteModel[] = [];
  sharePointWrapperArr: SharePointWrapper[] = [];
  isDocExported: boolean = false;
  isDocExistsSP: boolean = false;
  departmentList: KeyValue[] = [];
  spProvisionEmailIds: any = {};
  AvepointResponseData: AvepointResponseModel = new AvepointResponseModel();
  avepointResponseDataModel: AvepointResponseDataModel = new AvepointResponseDataModel()

  IsSigningReqeuestFailed: boolean = false;
  SigningErrorMessage: string;
  isAssignmentNameExistForClient: boolean = false;
  validEngagementName: boolean = true;
  isPrintedSectionESignDiscardFailed: boolean = false;
  isClientVoiceFeatureFlagEnabled: boolean = false;
  invalidvaluesAN: string;
  invalidvalues: any;
  invalidvaluesCN: string;
  selectedCollabSpaceDetailOnEdit: CollaborationSite = new CollaborationSite();
  EditCollabSpaceNameRequired: boolean = false;
  checkEditCollabSpaceNameExists: boolean = false;
  validEditColabSpaceName: boolean = true;
  UpdatedCollabSpaceName: string = '';
  isQualityUserLoggedIn: string = 'no';
  isQualityUserEngagementMember: boolean;
  continuanceEligibleData: any = {};

  UpdateEngagementStatusData: UpdateEngagementStatusModel = new UpdateEngagementStatusModel();

  workspaceIdForSummaryReport: string;
  siteUrlForSummaryReport: string;
  isDownloadForSummaryReport: boolean;
  sharepointSiteURL: string;
  applicationBaseURL: string;
  IsContinuanceEligible: boolean = null;
  isINDUSStaffingManagerLoggedIn: boolean;
  isEngDetailINDUSStaffingManagersHaveAccess: boolean;
  primaryCollaborationDetails: CollaborationSite = new CollaborationSite();
  engDetailOriginalworkItemIdForReviewResponse: string;
  engDetailOriginalworkItemVersionIdReviewResponse: string;
  subscriptions: Subscription[] = [];
  engagementStatus = EngagementStatus;
  continuanceEngagement: boolean = false;
  dateCompareResult: number;
  IsHDQuestionAnswerLoaded: boolean = false;
  clientConsentOptions: ClientConsentOptions[] = [];
  clientConsentOptionsForPopup: ClientConsentOptions[] = [];
  clientConsentOptionsPrimary: ClientConsentOptions[] = [];
  clientConsentOptionsSecondary: ClientConsentOptions[] = [];
  clientConsentOptionsPrimaryForPopUp: ClientConsentOptions[] = [];
  clientConsentOptionsSecondaryForPopUp: ClientConsentOptions[] = [];
  clientConsentdataterm: ClientConsentDataTerm[] = [];
  filterApplicationCode: any;
  @ViewChild(EngagementCloseoutClientVoiceSurvey)
  private engagementCloseoutClientVoiceSurvey: EngagementCloseoutClientVoiceSurvey;

  @ViewChild(EngagementCloseoutRetention)
  private engagementCloseoutRetention: EngagementCloseoutRetention;

  @ViewChild(EngagementCloseoutArchivalComponent)
  private engagementCloseoutArchivalComponent: EngagementCloseoutArchivalComponent;

  @ViewChild(EngagementCloseoutCleanUpChecklistComponent)
  private engagementCloseoutCleanUpChecklistComponent: EngagementCloseoutCleanUpChecklistComponent;

  @ViewChild(EngagementCloseoutQualityChecklistComponent)
  private engagementCloseoutQualityChecklistComponent: EngagementCloseoutQualityChecklistComponent;

  @ViewChild(EngagementCloseoutInsightMetrixComponent)
  private engagementCloseoutInsightMetrixComponent: EngagementCloseoutInsightMetrixComponent;

  @ViewChild(EngagementCloseoutAuditChecklistComponent)
  private EngagementCloseoutAuditChecklistComponent: EngagementCloseoutAuditChecklistComponent;

  @ViewChild('sharepointFilePickerEsc') sharepointFilePickerComponent!: SharepointFilePickerComponent;

  engCompletionDtEditViewModel: EngCompletionDtEditViewModel = new EngCompletionDtEditViewModel();
  isvalidEED: boolean = true;
  isvalidESD: boolean = true;
  UpdateEngCompletionDateError = false;
  engagementCloseoutConfiguration: EngagementCloseoutConfiguration[] = [];
  previousClientVoiceSurveyRequestModel: PreviousClientVoiceSurveyRequestModel = new PreviousClientVoiceSurveyRequestModel();
  closeoutSectionStructureAndVisibility: CloseoutSectionVisibility = new CloseoutSectionVisibility();
  anyPendingEngagementCloseoutRequiredTask: boolean = false;
  anyEngagementCloseoutRequiredTaskExists: boolean = false;
  isRequiredToSendClientVoiceSurvey: boolean;
  closeoutSections = CloseoutSections;
  lastSurveySentDateForClient: Date;
  isAttestServiceLineEngagement: boolean;
  isNonAttestServiceLineEngagement: boolean;
  isCompletionDateIndicated: boolean = true;
  isPermLibraryFeatureFlagEnabled: boolean = false;
  checkPendingRequiredTaskOnCloseOutClick: boolean = false;
  isCloseButtonClicked: boolean = false;
  PSDMapingList: solutionCodePSDMaping[] = [];
  PSDMapingListforUserCheck: PSDDataForUserCheck[] = [];

  isSharepointFileLabelFeatureFlagEnabled: boolean = false;
  sharePointSelectedFileLabel = "";
  sharePointSelectedFileLabelForFilter = "All";
  sharePointSelectedFile: SPDocumentList;
  sharePointApplicationMasterLabels: Applicationmaster[] = [];
  sharePointApplicationMasterLabelsFiltered: Applicationmaster[] = [];

  isloggedInUserPSD: boolean = false;
  isOpportunityServiceFeatureFlagEnabled: boolean = false;
  selectedclientName: string;
  selectedclientCode: string;
  clientOneViewAccountId: string;
  selectedSolutionCode: string;
  selectedsolutionCodeWithDesc: string;
  clientOpportunityList: OneViewClientOpportunityViewModel[] = [];
  oneViewClientOpportunityViewModelWrapper: OneViewClientOpportunityViewModelWrapper = new OneViewClientOpportunityViewModelWrapper();
  noOpportunityMsg: string = '';
  validateOpportunityService: boolean = false;
  originalParentSolutionCodeId: number = 0;
  blankLineAssignmentName: string = '_______________';
  editAssignmentIndex: number = 0;
  editAssignMode: boolean = false;
  isvalidEQCR: boolean = true;
  isValidEQCRBusinessRule: boolean = true;
  IntegrationAPIEndPoint: string;
  activeTabName: string;
  sharepointExeDocumentPreviewNotFound: boolean = false;
  isClientConsentFlagYes: boolean = false;
  isClientConsentFlagNo: boolean = false;
  isClientConsentFlagNA: boolean = false;
  clientConsentFlagId: number;
  isClientConsentAgreed: boolean = false;
  isClientConsentModified: boolean = false;
  previousClientConsentFlagId: number;
  isRequiredConsentData: boolean = true;
  applicationMasterCode: string;
  indusConsentEvent: string;
  approveDenyExtensionModal: ApproveDenyExtensionModal;
  engagementDueDateExtensions: EngagementDueDateExtension[];
  isAutoDenied: boolean = false;
  approveDenyByName: string;
  approveDenyByEmail: string;
  status: string;
  denyReason: string;
  engagementDueDateExtensionPendingModel: EngagementDueDateExtension;
  approveDenyExtensionViewModal: ApproveDenyExtensionViewModal;
  engagementDueDateExtensionsHistory: EngagementDueDateExtension[];
  isExtensionHistory: boolean;
  alreadyInvoked: boolean = false;
  isAudit: boolean;
  @ViewChild('clmGoverningAgreementStatusDivDP') clmGoverningAgreementStatusDiv: ElementRef;
  isCLMFeatureEnabled: boolean = false;
  gaNameAndStatusResponse: GoverningAgreementNameAndStatusResponse;
  gaNameAndStatusRequest: GoverningAgreementNameAndStatusRequest;
  engagementAdministrativeCloseModel: EngagementAdministrativeCloseModel;
  sendSignedDocumentToCLMMaxRetries: number = 2;
  sendSignedDocumentToCLMCurrentRetries: number = 0;
  clmLsDownloadFileFromSharepoitisRunning = false;
  documentListDataFromDB: DocumentList[] = [];
  clmDetails: CLMDetails;

  constructor(private engagementService: EngagementService, private activatedRoute: ActivatedRoute,
    private engService: EngagementService,
    private loadingScreenService: LoadingScreenService, private router: Router,
    private dataService: DataService,
    private _alertService: AlertsService,
    private workspaceService: WorkspaceService, private applicationMasterService: ApplicationMasterService,
    public datepipe: DatePipe, private adUsersService: AdUsersService, private logService: LogService,
    private _configService: ConfigurationService, private cmsService: CMSService,
    private graphSerivce: GraphService, private esignatureService: ESignatureService,
    private sharepointService: SharepointService, private appService: AppConfigService,
    private _activedirectoryService: ActiveDirectoryService,
    private clientService: ClientService,
    private closureService: ClosureService,
    private hdService: HotDocsService, private collaborationSiteService: CollaborationSiteService,
    private _sharepointService: SharepointService,
    private clientvoiceService: ClientvoiceService,
    private _clientConsentService: ClientConsentService,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private memberFirmService: MemberFirmService,
    private clmService: CLMService,
    private documentService: DocumentService) {

    this.spProvisionEmailIds = {
      primaryAdmin: this.appService.config.SP_EMAIL_PROVISION.PRIMARY_ADMIN,
      primaryContact: this.appService.config.SP_EMAIL_PROVISION.PRIMARY_CONTACT,
      secondaryContact: this.appService.config.SP_EMAIL_PROVISION.SECONDARY_CONTACT
    }
    this.continuanceEligibleData = {
      solutionCode: this.appService.config.CONTINUANCE_ELIGIBILITY_DATA.SOLUTIONCODE,
      hdTemplatePackageId: this.appService.config.CONTINUANCE_ELIGIBILITY_DATA.HDTEMPLATEPACKAGEID
    }
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.applicationBaseURL = this.appService.config.DATA.AZURE_AD_AUTHENTICATION.LOGIN_REDIRECT_URL;

    this.IntegrationAPIEndPoint = this.appService.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  engagement: EngagementListModel[] = [];
  actionTiles: ActionTiles;
  engagementId: number;
  tabName: string
  engagementList: EngagementList = new EngagementList();
  engagementCollaborationsViewModel: EngagementCollaborationsViewModel = new EngagementCollaborationsViewModel();
  lastRedirectedFromCreateClient: string;
  IwantToHeading: string;
  lastIwantToHeading: string;
  alert: Alert;
  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  selectContactEmailId: string;
  engDetailWorkItemId: string;
  templatePackageVersionIdForReviewResponse: string;
  lastUpdatedByForReviewResponse: string;
  reviewerList: TeamMembers[] = [];
  reviewersListForRP: TeamMemberForReviewer[] = [];
  newReviewer: any = {};
  applyFilterOnceAfterDataLoad: boolean;
  isEditEngName = false;
  updatedEngName = '';
  EngagementNameRequired = false;
  selectedEngagementPartner = '';
  selectedEngagementPartnerEmail = '';
  EQCRCtrl: string;
  EQCRRoleName: string;
  EQCRlblCtrlName: string;
  validateEQCR: boolean;
  selectedEQCRIndex: string;
  EQCRNameCtrl: string;
  EQCRDisplayUserName = '';
  EQCRDisplayUserEmail = '';

  eppmdClassName = 'nonClickableLink';
  isEditEPPMD = false;
  isRequiredEngagementPartner = false;
  selectedEPPMDIndex: string;
  EPPMDCtrl: string;
  engDetailWorkItemVersionId: string;

  private modalConfirmationDialogRef: Subscription = null;
  private modalEngConfirmationDialogRef: Subscription = null;
  private modalEditQuestionnaireConfirmation: Subscription = null;
  engagementAssignmentList: Array<EngagementAssignment> = [];
  public engagementAssignmentWithoutSCode: Array<EngagementAssignmentWithoutSCode> = [];
  newTeamMemberList: Array<ADGroupUsersSyncCollabMembers> = [];
  newTeamMember: ADGroupUsersSyncCollabMembers = new ADGroupUsersSyncCollabMembers();
  teamMemberList: Array<TeamMember> = [];
  newCollabTeamMember: any = {};
  arrValidationForNewTeamMember: any = [];
  arrValidationForNewClientTeamMember: any = [];
  IsValidInput = true;
  cmsDataMatch = false;
  IsValidInputClient = true;
  cmsDataMatchClient = false;
  collaborationSiteList: CollaborationSite[] = [];
  collaborationClientSiteList: CollaborationClientSite[] = []
  collaborationSiteMembersList: CollaborationSiteMembers[] = [];
  teamMemberRoleList: TeamMemberRole[] = [];
  applicationmasterList: Applicationmaster[] = [];
  keyApplicationmasterList: Applicationmaster[] = [];
  teamMemberRoleCodes: AppCodes[] = [];
  keyTeamMemberRoleCodes: AppCodes[] = [];
  teamMemberPermissions: TeamMemberPermission[] = [];
  clientMemberPermissions: ClientMemberPermission[] = [];
  clientMemberPermissionModelList: ClientMemberPermissionModel[] = [];
  teamMemberRoleName: string;
  INDUSRoleName: string
  teamMemberlblCtrlName: string;
  peoplepickerIndex: number = 0;
  assignAdminList: AzureADUsersArrayList;
  membersListArrayForTeamMembers: MembersList[];
  currentTeamMemberIndex: number;
  teamMemberCtrl: string;
  teamMemberEmailCtrl: string;
  collaborationSiteViewModelList: CollaborationSiteViewModel[] = [];
  isSelectedAcceptanceImg: boolean = true;
  isSelectedCollabImg: boolean = false;
  isSelectedAssignmentImg: boolean = false;
  isSelectedEngCloseoutCheckListImg: boolean = false;
  isSelectedExecutionDocumentsImg: boolean = false;
  isSelectedConsentImg: boolean = false;
  newCollaborationSiteName: string;
  isNewCollaborationSpace = false;
  isNewSiteNameDuplicate = false;
  isNewSiteNameRequired = false;
  isNewSiteNameValid = true;
  userObject: any;
  TeamMemberManipulationTrigger = false;
  isEngagementClosed = true;
  isPageLoad = false;
  selectedTMIndex: string;
  arrControlValidation: FieldValidator[] = [];
  defaultEngAssignment: EngagementAssignment = new EngagementAssignment();
  isvalidcmsEPPMDName: boolean = true;
  isvalidAassignmentAdministrator: boolean = true;
  validRealization: boolean = true;
  RealizationMessage: string = '';
  validNetfees: boolean = true;
  netFeesMessage: string = '';
  isVisibleNewAssignmentDIV = false;
  isVisibledefaultAssignmentDiv = true;
  public arrEngagementAssignment: Array<EngagementAssignment> = [];
  epRoleName: string;
  assignAdminRoleName: string;
  eplblCtrlName: string;
  cmslblCtrlName: string;
  assignAdminlblCtrlName: string;
  validateEP: boolean;
  selectedEPIndex: string;
  validateAA: boolean;
  selectedAAIndex: string;
  validateCMSEP: boolean;
  validateCMSAA: boolean;
  selectedCMSEPIndex: string;
  selectedCMSAAIndex: string;
  currentAssignmentRowIndex: number = 0;
  membersListArray: MembersList[];
  membersListArrayForEP: MembersList[];
  CMSEPCtrl: string;
  ASAdminCtrl: string;
  engtypekeyValueList: KeyValue[] = [];
  contingentkeyValueList: BooleanKeyValue[] = [];
  strdefaultcontingentkeyValues: any = {};
  source: string;
  arrSecondarySolutionCode: SecondarySolutionCode[] = [];
  secondarySolutionCodeList: SecondarySolutionCode[] = [];
  primarySelectedSolutionCode: SecondarySolutionCode = new SecondarySolutionCode();
  objectArray: [string, any][];
  newEngAssignment: any = {};
  StartDateCtrlId: string;
  EndDateCtrlId: string;
  ClientContactTypes: ContactTypes[] = [];
  ClientLocations: ClientLocation[] = [];
  validEGrossMargin: boolean = true;
  grossMarginMessage: string = '';
  isInvalidClientBillingContactEmail: boolean = false;
  isNewAssignmentNameDuplicate = false;
  newClientTeamMember: any = {};
  newClientTeamMemberList: Array<ClientTeamMembers> = [];
  clientTeamMemberList: Array<ClientTeamMembers> = [];
  isInvalidClientTeamMemberEmail: boolean = false;
  newCollabClientTeamMember: any = {};
  DateCompareResult: number;
  startDateCompareResult: number;
  employeeList: Employee[] = [];
  AssignmentCodeRequested: string = "Request sent";
  isEngagementCloseoutDisable: boolean = false;
  isEngagementUpdateInProcess: boolean = false;
  departmentType: string = 'Departments';
  spDocumentList: Array<SPDocumentList> = [];
  defaultRoleid: number;
  defaultKeyRoleid: number;
  defaultPermissionId: number;
  defaultClientPermissionId: number
  //ESignature
  engagementESignatureRequestModel: EngagementESignatureRequestModel = new EngagementESignatureRequestModel();
  engagementESignature: EngagementESignature = new EngagementESignature();
  initialEngagementESignature: EngagementESignature = new EngagementESignature();
  esingatureSelectedDocumentId: number = 0;
  isvalidSignatureHandler: boolean = true;
  printedSigningDateId: string = "printedSigningDate";
  showElectronicSignerName: boolean = true;
  showPrintedSignerName: boolean = true;
  signingMember: SignatureMember = new SignatureMember();
  docuSignTemplate: DocuSignTemplateList = new DocuSignTemplateList();
  isEmptySignatureHandlerName: boolean = false;
  isInvalidSignatureHandler: boolean = false;
  isEmptyPrintedSinger: boolean = false;
  isEmptyPrintedDate: boolean = false;
  isFutureDateSelected: boolean = false;
  hasNoPrintedDocumentSelected: boolean = false;
  cmsCodeRequestError: boolean;
  //PopupVariables
  signatureHandlerRoleName: string;
  validateSignatureHandler: boolean;
  selectedSignatureIndex: string;
  signatureHandlerCntrl: string;
  signatureHandlers: MembersList[];
  handerlblCtrlName: string;
  currentCollabModel: CurrentCollaborationSiteViewModel;

  rpDisplayModel: RPDisplayModel = new RPDisplayModel;
  eppmdApproverDispalyModel: RPDisplayModel[] = [];
  autoRPDisplayModel: RPDisplayModel[] = [];
  manualRPDisplayModel: RPDisplayModel[] = [];
  adminPopupId: string;
  cmsEPPMDPopupId: string;
  isloggedInUserAdmin: string = 'no';
  isAdminEngagementMember: boolean = false;
  cmsDisplayUserName = '';
  cmsDisplayUserEmail = '';
  adminDisplayUserName = '';
  adminDisplayUserEmail = '';
  indusDisplayUserName = '';
  indusDisplayUserEmail = '';
  teamMemberDisplayUserName = '';
  teamMemberDisplayUserEmail = '';
  defaultClientBillingContact: ClientBillingContact;
  validAssignmentName = true;
  indusADGroupsList: AzureADUsersList[] = [];
  indusMembersListArray: Array<IndusADUsersViewModel> = [];
  indusTeamMemberData: IndusADUsersViewModel = new IndusADUsersViewModel();

  //IndusRemoval
  engDetailIndusADGroupsList: AzureADUsersList[] = [];
  engDetailIndusTeamMemberData: IndusADUsersViewModel = new IndusADUsersViewModel();
  engDetailValidIndusMemberList: CollaborationSiteMembers[] = [];
  removeIndusCollabMembersData: RemoveIndusCollaborationSiteMember = new RemoveIndusCollaborationSiteMember();

  globalMetadata: SPSiteMetadata[] = [];
  isAdMemberchecked: boolean = false;
  isEPMatchWithEQCR: boolean = false;
  checkEngagementExists: boolean = false;
  originalEngagementName: string = '';
  engagementCount: number = 0;
  isDisableAddCollaborationSite: boolean = true;
  newCollabrationCount: number = 0;
  collaborationSpacePriorYearViewModelList: CollaborationSpacePriorYearViewModel[] = [];
  collaborationSpacePriorYearMappingData: CollaborationSpacePriorYearMappingModel[] = [];
  collaborationSpacePriorYearMappingDataInitialObj = new CollaborationSpacePriorYearMappingModelWrapper();
  updateCollaborationSpacePriorYearMappingModelList: UpdateCollaborationSpacePriorYearMappingModel[] = [];
  selectedCollaborationSiteId: number;
  isConveneProjectsExists: boolean = false;
  CreateWorkItemData: HDWorkItemModel = new HDWorkItemModel();
  isTeamMember: boolean = false;
  memberList: CollaborationSiteMembers[] = [];
  updateQuestionnairePreCheckModel: UpdateQuestionnairePreCheckModel = new UpdateQuestionnairePreCheckModel();

  engagementConsentInsertModel = new EngagementConsentInsertModel('', 0, 0, true);
  engagementConsentInsertModelArray = new ConsentArray;
  engagementConsentModel: EngagementConsentModel[] = [];
  isSignatureCompleted: boolean = false;
  isEPPMD: boolean = false;
  isConsentFeatureEnabled: boolean = false;
  isConsentSaveClicked: boolean;
  reviewerPopupId: string;
  teamMemberPopupId: string;
  arrPrimaryClientConsentOptions: UpdateClientConsentOptions[] = [];
  arrSecondaryClientConsentOptions: UpdateClientConsentOptions[] = [];
  arrclientConsentOptions: UpdateClientConsentOptions[] = [];
  arrIsCheckedPrimaryClientConsentOptions: UpdateClientConsentOptions[] = [];
  IsCheckedVal: boolean = true;
  public get EngagementStatus(): typeof EngagementStatus {
    return EngagementStatus;
  }
  engCompletionDateCtrlId: string;

  workspaceDocumentList: WorkspaceDocumentViewModel[] = [];
  selectedWorkspaceDocumentItem = new WorkspaceDocumentViewModel();
  workspaceDocumentCount: number = 0;
  allWorkspaceDataList: WorkspaceModel[] = [];
  isrevisedresponsetabactive: boolean = true;
  //Execution Phase document variables
  sharepointSiteDocumentModel: SharepointSiteDocumentModel = new SharepointSiteDocumentModel();
  executionWorkspaceDocumentList: WorkspaceDocumentViewModel[] = [];
  secondaryWorkspaceDataList: WorkspaceModel[] = [];
  spGeneralFolderPath: string = "Shared Documents/General";
  spSharedDocumentsPath: string = "Shared Documents";
  executionWorkspaceSPDocumentList: SPDocumentList[];
  spDocumentFromEngage: IWantToDocumentsReference = new IWantToDocumentsReference();
  selectedSPDocument: SPDocumentList = new SPDocumentList();
  spExceutionDocExportURL: string = "";
  selectedExeWorkspaceDocumentEorkspaceId: string;
  removeSecondaryWorkspace: RemoveSecondaryWorkspaceModel;
  selectedSecondaryWorkspaceDocument = new WorkspaceDocumentViewModel();
  SiteCollaborationMembersList: SiteCollaborationMembers[] = [];
  updateINDUSConsentFlag = new UpdateINDUSConsentFlag();

  isMemberFirm: boolean = false;
  memberFirmData: MemberFirmData;
  engagements: Engagements;
  azureADUsersArrayListMemberFirm: AzureADUsersArrayList;
  azureADUsersArrayListAdmin: AzureADUsersArrayList;

  interchangeableSolutionCodes: InterchangeableSolutionCode[] = [];

  ngOnInit() {
    this.oneViewClientOpportunityViewModelWrapper = new OneViewClientOpportunityViewModelWrapper();
    this._alertService.remove(this.alert);
    this.setDefaultValues();
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    this.activatedRoute.paramMap.subscribe(params => {
      this.engagementId = +params.get('engagementId');
      this.redirectToCorrectEngagementState(this.engagementId);
      this.tabName = params.get('tabName');
      $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
      $('.ui-widget-overlay.ui-front').remove();
    });
    this.IsLoggedinUserSystemAdmin();
    this.loadMemberFirmDetails(this.engagementId);
    this.getCLMFeatureFlag();

    this.dataService.lastRedirectedFromCreateClient.subscribe(returnedFromCreateClient =>
      this.lastRedirectedFromCreateClient = returnedFromCreateClient);
    this.dataService.currentIwantToHeading.subscribe(heading => this.lastIwantToHeading = heading);
    this.dataService.getConveneProject$.subscribe(isExists => this.isConveneProjectsExists = isExists);
    this.RestoreUIStateAfterPageLoaded();
    this.RestoreUIStateAfterPageLoadedForQualityChecklist();
    this.epRoleName = RoleNames.EngagementPartner.toString();
    this.assignAdminRoleName = RoleNames.AssignmentAdministrator.toString();
    this.signatureHandlerRoleName = RoleNames.StaffMember.toString();
    this.eplblCtrlName = 'engagement PPMD';
    this.cmslblCtrlName = 'CMS PPMD';
    this.handerlblCtrlName = 'signature handler';
    this.assignAdminlblCtrlName = 'assignment administrator';
    this.teamMemberRoleName = RoleNames.StaffMember.toString();
    this.INDUSRoleName = RoleNames.INDUSConsent.toString();
    this.teamMemberlblCtrlName = 'team member';
    this.source = 'EngagementType';
    this.EQCRRoleName = RoleNames.AssignmentEQCR.toString();
    this.EQCRlblCtrlName = 'Assignment EQCR';
    // set current route Url as NewClient returnUrl for Client/Enagement Actions.
    this.dataService.setReturnUrlForCreateClient(this.router.url);
    this.InitializeMasterDataForAssignmentSecion(this.source);
    this.GetContactTypes();
    this.GetClientLocations();
    setTimeout(() => {
      this.getEngagementCollaborationsById(this.engagementId);
    }, 1000);
    if (this.engagementId != 0 || this.engagementId != null) {
      var contactPopup = $('#contact-popup');
      contactPopup.hide();
      this.initinateConfirmationPopUp();
      this.initinateEngConfirmationPopUp();
    }
    if (this.lastRedirectedFromCreateClient === 'true') {
      $('#InitiateNewEng').modal('show');
      this.IwantToHeading = this.lastIwantToHeading;
    }
    this.dataService.setIsRedirectedFromCreateClient('false');

    this.selectContactEmailId = null;
    this.applyFilterOnceAfterDataLoad = false;
    this.isPageLoad = true;
    this.StartDateCtrlId = "EstimatedStartDate";
    this.EndDateCtrlId = "EstimatedEndDate";
    this.setDepartment();
    this.cmsCodeRequestError = false;
    this.engagementService.getMetadataList().subscribe((metadata: SPSiteMetadata[]) => {
      if (metadata !== undefined && metadata !== null) {
        this.globalMetadata = metadata;
      }
    });
    this.engagementService.GetConsentFeatureFlag().subscribe(result => {
      this.isConsentFeatureEnabled = result;
      if (result) {
        this.GetEngagementConsentDetails();
      }
    });
    this.engagementService.GetClientVoiceFeatureFlag().subscribe(result => {
      this.isClientVoiceFeatureFlagEnabled = result;
    });


    this.engagementService.GetPremLibraryFeatureFlag().subscribe(result => {
      this.isPermLibraryFeatureFlagEnabled = result;
    });

    this.isConsentSaveClicked = false;

    $('#myDatePicker').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date(),
      orientation: 'bottom auto',
      onSelect: function () {
        this.changeDate();
      }
    });

    this.engCompletionDateCtrlId = 'engCompletionDate';

    this.getEngagementCloseoutConfigurations();

    this.engagementService.GetOpportunityServiceFeatureFlag().subscribe(result => {
      this.isOpportunityServiceFeatureFlagEnabled = result;
    });

    this.getSharepointFileLabelFeatureFlag();
    this.getSharepointFileLabels();
    this.isMemberFirm = this.userObject.isMemberFirmFeatureEnabled;
    if (this.isMemberFirm) {
      this.teamMemberRoleName = this.userObject.memberFirmTeamMemberShortRoleName
    }

    this.initializeApproveDenyExtensionModel();

  } // end ngOnInit()


  changeDate() {
    setTimeout(() => {
      const engCompletionDate = $('.engCompletionDate').val();
      this.engagementList.plannedClosureDate = engCompletionDate;
    }, 500);
  }

  setDefaultValues() {
    this.currentCollabModel = new CurrentCollaborationSiteViewModel();
    this.currentCollabModel.currentCollaborationSiteViewModel = new CollaborationSiteViewModel();
    this.currentCollabModel.index = 0;
    this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite = new CollaborationSite();
    this.currentCollabModel.currentCollaborationSiteViewModel.engagementAssignmentList = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.teamMemberList = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.clientTeamMemberList = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSiteMembersList = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.collaborationClientSite = new CollaborationClientSite();
    this.currentCollabModel.currentCollaborationSiteViewModel.engagement = new EngagementList();

    this.defaultEngAssignment.assignmentName = '';
    const defaultKeyvalue: KeyValue = new KeyValue();
    const defaultsecondarySolutionCode: SecondarySolutionCode = new SecondarySolutionCode();
    defaultKeyvalue.value = '';
    defaultKeyvalue.key = '';
    defaultKeyvalue.checked = false;
    this.defaultEngAssignment.selectedengagementType = defaultKeyvalue;
    defaultsecondarySolutionCode.solutionCode = '';
    defaultsecondarySolutionCode.solutionCodeDescription = '';
    defaultsecondarySolutionCode.isGrossMarginRequire = false;
    this.defaultEngAssignment.selectedsolutionCode = defaultsecondarySolutionCode;
    this.arrEngagementAssignment.push(JSON.parse(JSON.stringify(this.defaultEngAssignment)));
  }

  setDepartment() {
    this._configService.GetListBySource(this.departmentType).subscribe(
      result => {
        this.departmentList = result;
      },
      error => {
        this.alertServiceFun('You do not have access to view details !', 'danger');
      }
    );
  }

  getEngagementCollaborationsById(engId: number, isLoadDefaultTab: boolean = true) {
    this.loadingScreenService.startLoading();
    let setAdminFlagForAPICall: string = this.isloggedInUserAdmin;
    //to get engagement details if user belongs to quality group
    if (this.isloggedInUserAdmin == 'no' && (this.isQualityUserLoggedIn === 'yes' || this.isINDUSStaffingManagerLoggedIn)) {
      setAdminFlagForAPICall = 'yes';
    }
    return this.engagementService.getEngagementCollaborationsById(engId, setAdminFlagForAPICall)
      .subscribe(
        result => {
          this.engagementCollaborationsViewModel = result;
          this.originalEngagementName = this.engagementCollaborationsViewModel.engagement.engagementName;
          this.applyFilterOnceAfterDataLoad = true;
          this.engagementList = this.engagementCollaborationsViewModel.engagement;
          this.getEngagementDocumentsByEngId(this.engagementId);
          if (this.engagementList.engagementId > 0 && this.engagementList.clientId > 0 &&
            this.engagementList.status != 'Closed' && this.engagementList.status != 'Voided') {
            this.dataService.setClientIdForPrePopulate(this.engagementList.clientId);
            this.dataService.setEngagementIdForPrePopulate(this.engagementList.engagementId);
          }
          this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
          this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
          this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
          this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
          this.engagementESignature = this.engagementCollaborationsViewModel.engagementESignature;
          this.primaryCollaborationDetails = this.collaborationSiteList.find(x => x.isPrimaryCollaboration == true);
          this.subscriptions.push(this.engagementService.GetSolutionCodeMasterById(this.engagementList.parentSolutionCodeId).subscribe(primarySolutionCode => {
            if (primarySolutionCode.isAdvisory && primarySolutionCode.isAttest === 0) {
              this.isNonAttestServiceLineEngagement = true;
            }
            this.isAudit = primarySolutionCode.isAudit;
          },
            error => {
              this.logService.error('Get solution code details failed.', error);
            }));
          // Getting ownere from AD for 30Days notification 
          this.getOwnersMembersFromSharepointSiteActiveDirectoryGroups();
          //Check INDUS STAFFING Managers role access
          if (this.primaryCollaborationDetails != null &&
            this.primaryCollaborationDetails.isPrimaryCollaboration == true &&
            this.primaryCollaborationDetails.isIndus == true &&
            this.isINDUSStaffingManagerLoggedIn == true) {
            this.isEngDetailINDUSStaffingManagersHaveAccess = true;
          }
          //Check Continuacce engagement
          if (this.engagementList.reacceptanceEngagementId !== null &&
            this.engagementList.reacceptanceEngagementId > 0) {
            this.continuanceEngagement = true;
          }
          this.initialEngagementESignature = JSON.parse(JSON.stringify(this.engagementCollaborationsViewModel.engagementESignature));
          this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
          this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
          this.secondarySolutionCodeList = this.engagementCollaborationsViewModel.secondarySolutionCodeList;
          this.primarySelectedSolutionCode = this.secondarySolutionCodeList.find(c => c.seq == 0);
          this.collaborationSpacePriorYearViewModelList = this.engagementCollaborationsViewModel.collaborationSpacePriorYearViewModelList;
          if (this.isloggedInUserAdmin == 'yes') { this.CheckAdminUserMembership(); }
          if (this.isQualityUserLoggedIn === 'yes') {
            this.CheckQualityUserMembership();
            if (this.isQualityUserEngagementMember) {
              this.isQualityUserLoggedIn = 'no';
            }
          }
          this.getDefaultClientBillingContactDetails(this.engagementList.clientId);
          this.InitializeESignatureFields();
          this.CreateUIForCollaborationSpace();

          this.objectInitialization();
          this.setAssignmentSection();
          this.getTeamMemberPermissions();
          this.getClientMemberPermissions();
          this.getTeamMemberRoles();
          if (isLoadDefaultTab) {
            this.displayDefaultTab();
          }
          this.getTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and team member addition
          this.getKeyTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and key team member addition
          this.getWorkspaceData(this.engagementList.engagementId);

          //When Engagement status is CMS Code Error or closed block user to perform actions
          if (this.engagementList.status === EngagementStatus.EngCmsCodeError ||
            this.engagementList.status === EngagementStatus.EngClosed ||
            this.engagementList.status === EngagementStatus.EngVoided) {
            this.isEngagementClosed = true;
          } else {
            this.isEngagementClosed = false;
          }

          if (this.engagementList.status === EngagementStatus.EngActive) {
            this.isEngagementCloseoutDisable = false;
          }
          else {
            this.isEngagementCloseoutDisable = true;
          }

          this.newCollabrationCount = this.collaborationSiteList.filter(site => site.siteURL == null).length;
          if (((this.engagementList.status === EngagementStatus.EngActive ||
            this.engagementList.status === EngagementStatus.EngActivePendingCMSCode ||
            this.engagementList.status === EngagementStatus.EngActivePendingSignature)
            && this.engagementAssignmentList.length === 1) ||
            !(this.engagementList.status === EngagementStatus.EngActive ||
              this.engagementList.status === EngagementStatus.EngActivePendingCMSCode ||
              this.engagementList.status === EngagementStatus.EngActivePendingSignature
            )
          ) {
            this.isDisableAddCollaborationSite = true;
          } else {
            if (this.newCollabrationCount > 0) {
              this.isDisableAddCollaborationSite = true;
            }
            else {
              this.isDisableAddCollaborationSite = false;
            }
          }
          //When logged in user belong s to quality group block user to perform action
          this.blockUserAction();

          if (this.engagementList.siteUrl != null && this.engagementList.siteUrl != '') {
            this.getAllDocumentsfromSPLibrary(this.engagementList.siteUrl, this.engagementList.acceptanceFolderPath);
            // this.GetExcecutionDocumentsfromSPLibrary(this.engagementList.siteUrl, this.spGeneralFolderPath);

          }

          setTimeout(() => {
            if (this.isPageLoad == true) {
              //this.switchTabPanelIcons($('#engsummarytabs ul>li.nav-item >a.active ').attr('href').includes('engCloseoutCheckList') ? 'engCloseoutCheckList' : 'acceptance');
              this.enableDraggable(false);
              this.DisableDragDropSingleAssignment();
            } else {
              this.enableDraggable(true);
            }
            this.defaultSetting();

          }, 100);

          this.loadingScreenService.stopLoading();
        },
        error => {
          this.engagementList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('You do not have access to view details !', 'danger');
        }
      );
  }

  blockUserAction() {
    console.log('blockUserAction');
    if (this.isQualityUserLoggedIn === 'yes' && this.isloggedInUserAdmin == 'no' &&
      !this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isLoggedInUserPartOfCollaboration &&
      this.engagementList.status != EngagementStatus.EngCmsCodeError &&
      this.engagementList.status != EngagementStatus.EngClosed &&
      this.engagementList.status != EngagementStatus.EngVoided) {
      this.isEngagementClosed = true;
    }
  }
  displayDefaultTab() {
    if (this.tabName != undefined && this.tabName.length > 0) {
      if (this.tabName == 'Documents') {
        this.switchTabPanelIcons('executionDocuments');
        this.OnHDQuestionAnswerLoaded(true); //To enable other tabs by default when document tab is loaded on page load.
      }
      else if (this.tabName == 'Closeout') {
        this.switchTabPanelIcons('engCloseoutCheckList');
        this.OnHDQuestionAnswerLoaded(true); //To enable other tabs by default when document tab is loaded on page load.
      } else if (this.tabName == 'acceptance') { //If page was reloaded from acceptance tab for hotdocs caching issue, then make accpetance by default.
        this.switchTabPanelIcons('acceptance');
      }
      else if (this.tabName == 'Consent') {
        this.switchTabPanelIcons('consent');
        this.OnHDQuestionAnswerLoaded(true);//To enable other tabs by default when consent tab is loaded on page load.
      }

    } else if (this.engagementESignature.signatureTypeCode == "P" || (this.engagementESignature.signatureTypeCode == "E" && this.engagementESignature.envelopeStatusValue == "Completed")) {
      this.isSignatureCompleted = true;
      this.switchTabPanelIcons('collaborations');
      this.OnHDQuestionAnswerLoaded(true); //To enable other tabs by default when collabration tab is loaded on page load.
    }
    else {
      this.switchTabPanelIcons('acceptance');
      this.isSignatureCompleted = false;
    }
  }

  getDate(date: any) {
    var formattedDate = new Date(date);
    var d = formattedDate.getDate();
    var m = formattedDate.getMonth();
    m += 1;  // JavaScript months are 0-11
    var y = formattedDate.getFullYear();
    var finalFormattedDate = m + "/" + d + "/" + y
    if (finalFormattedDate == '1/1/1') {
      this.engagementList.taxYear = null;
    }
  }

  createAndUpdatePermissionsModel(updatedcollaborationSiteList: CollaborationSite[]) {
    for (let i = 0; i < updatedcollaborationSiteList.length; i++) {
      let updatedTeammembers = this.teamMemberList.filter(teamMember => teamMember.collaborationSiteId === this.collaborationSiteList[i].collaborationSiteId);
      let siteOwnerEmailIds: string[] = [];

      for (let j = 0; j < updatedTeammembers.length; j++) {
        siteOwnerEmailIds.push(updatedTeammembers[j].memberEmailId);
      }

      let updatePermissionsModelList: UpdatePermissionsModel[] = [];
      let updatePermissionsModel: UpdatePermissionsModel = new UpdatePermissionsModel();
      let usersListEmailModel: UsersListEmailModel = new UsersListEmailModel();

      usersListEmailModel.groupType = 'Owner';
      usersListEmailModel.groupUsersEmail = [];
      siteOwnerEmailIds.forEach(emailid => {
        let adUser: ADUser = new ADUser();
        adUser.firstName = '';
        adUser.lastName = '';
        adUser.userEmailId = emailid;
        usersListEmailModel.groupUsersEmail.push(adUser);
      })
      updatePermissionsModel.siteUniqueID = this.collaborationSiteList[i].siteUniqueID;
      updatePermissionsModel.usersListEmail.push(usersListEmailModel);
      updatePermissionsModelList.push(updatePermissionsModel);
      console.log('updatePermissionsModelList ==>', updatePermissionsModelList);
      //1. Call Active Directory API to update latest collaborationSite Member Ids
      this.CallUpdatePermissions(updatePermissionsModelList);
    }
  }

  getEngagementDetailsByIdWithSPPermission(addedAssignmentCodeId: number, engId: number, collaborationSiteId: number) {
    this.loadingScreenService.startLoading();
    return this.engagementService.getEngagementCollaborationsById(engId, this.isloggedInUserAdmin)
      .subscribe(
        result => {
          this.engagementCollaborationsViewModel = result;
          this.applyFilterOnceAfterDataLoad = true;
          this.engagementList = this.engagementCollaborationsViewModel.engagement;
          if (this.engagementList.status === EngagementStatus.EngActive) {
            this.isEngagementCloseoutDisable = false;
          }
          else {
            this.isEngagementCloseoutDisable = true;
          }
          this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
          this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
          this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
          this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
          this.engagementESignature = this.engagementCollaborationsViewModel.engagementESignature;
          this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
          this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
          this.secondarySolutionCodeList = this.engagementCollaborationsViewModel.secondarySolutionCodeList;
          this.CreateUIForCollaborationSpace();

          const updatedcollaborationSiteList = this.collaborationSiteList.filter(site => site.collaborationSiteId == collaborationSiteId);

          this.createAndUpdatePermissionsModel(updatedcollaborationSiteList);
          this.objectInitialization();
          this.setAssignmentSection();
          this.getTeamMemberPermissions();
          this.getClientMemberPermissions();
          this.getTeamMemberRoles();
          this.getTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and team member addition
          this.getKeyTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and key team member addition
          this.getWorkspaceData(this.engagementList.engagementId);

          this.isEngagementClosed = this.engagementList.status == EngagementStatus.EngClosed;
          this.getAllDocumentsfromSPLibrary(this.engagementList.siteUrl, this.engagementList.acceptanceFolderPath);

          setTimeout(() => {
            if (this.isPageLoad == true) {
              //this.switchTabPanelIcons('acceptance');
              this.enableDraggable(false);
              this.DisableDragDropSingleAssignment();
            } else {
              this.enableDraggable(true);
            }
            this.defaultSetting();
          }, 100);
        },
        error => {
          this.engagementList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('You do not have access to view details !', 'danger');

        }
      );
  }

  alertServiceFun(message, type) {
    this._alertService.remove(this.alert);
    this._alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getWorkspaceData(engagementId: number) {

    this.workspaceService.getWorkspaceByEngagementId(engagementId).subscribe(
      result => {
        this.loadingScreenService.stopLoading();
        this.CurrentWorkspaceData = result;
        this.getReviewers();
        this.ValidateUserIsTeamMemberOfEngagement();
        this.ValidateUserIsEPPMDOfEngagement();
        this.GetContinuanceEligibility();
        this.GetWorkspaceDocumentCountbyEngId();
        this.workspaceDocumentList = [];
        this.fillWorkspaceDocumentList(this.CurrentWorkspaceData);
        this.getSecondaryWorkspaceData(engagementId);
        setTimeout(() => {
          if (this.activeTabName == 'acceptance') // load review response only if there is acceptance tab selected
            this.WorkspaceDocumentChange();
        }, 10); //delay is minimized to 10ms from 5000ms
      });
  }

  getReviewers() {
    this.reviewersListForRP = [];
    return this.workspaceService.getAllMembers(this.CurrentWorkspaceData.workspaceId).subscribe(
      result => {
        let allMembers = result;

        console.log(allMembers.length);
        console.log(allMembers);

        if (allMembers.length > 0) {

          this.reviewerList = result.filter(member => member.isReviewer == 1);
          this.BuildRPsDisplayModel(allMembers);

          //Select RP and EPPMD users to show unser approver
          for (let j = 0; j < allMembers.length; j++) {
            if (allMembers[j].isReviewer == 1 || (allMembers[j].teamMemberRole === EnumRoles.EngagementPartner
              && allMembers[j].approvalStatus === 'Approved')) {
              this.newReviewer = {
                id: allMembers[j].id,
                workspaceId: allMembers[j].workspaceId,
                memberName: allMembers[j].memberName,
                teamMemberRole: allMembers[j].teamMemberRole,
                isReviewer: allMembers[j].isReviewer,
                roleId: allMembers[j].roleId,
                createdBy: allMembers[j].createdBy,
                createdOn: allMembers[j].createdOn,
                updatedBy: allMembers[j].updatedBy,
                updatedOn: allMembers[j].updatedOn,
                memberEmailId: allMembers[j].memberEmailId,
                memeberContactNo: allMembers[j].memeberContactNo,
                approvalStatus: allMembers[j].approvalStatus,
                oldreviewerName: allMembers[j].oldreviewerName,
                loggedInUserFullName: allMembers[j].loggedInUserFullName,
                jobTitle: allMembers[j].jobTitle
              }
              this.reviewersListForRP.push(this.newReviewer);
            }
          }
          setTimeout(() => {
            this.BindContactPopup();
          }, 100);
        }
      },
      () => {
        this.reviewerList = null;
      }
    );
  }
  QuestionSummaryDisplay() {
    this.isrevisedresponsetabactive = true;
    if (this.selectedWorkspaceDocumentItem != null) {
      this.engDetailWorkItemId = this.selectedWorkspaceDocumentItem.workItemId;
      this.engDetailWorkItemVersionId = this.selectedWorkspaceDocumentItem.workItemVersionId;
      this.engDetailOriginalworkItemIdForReviewResponse = null;
      this.engDetailOriginalworkItemVersionIdReviewResponse = null;
    }
    this.lastUpdatedByForReviewResponse = "Last updated by " + this.selectedWorkspaceDocumentItem.lastActivityBy + " on " + this.datepipe.transform(this.selectedWorkspaceDocumentItem.lastActivityDate, 'MM/dd/yyyy');
  }

  exeQuestionSummaryDisplay() {
    if (this.selectedSecondaryWorkspaceDocument.workspaceId != 'undefined' && this.selectedSecondaryWorkspaceDocument.workspaceId != "" && this.selectedSecondaryWorkspaceDocument.workspaceId != null) {
      this.engagementService.GetEngagementPreviewDocumentFromSPByWorkspaceId(this.selectedSecondaryWorkspaceDocument.workspaceId, 'Active')
        .subscribe(result => {
          if (result) {
            this.isDocExported = true;
          }
        });
    }
    if (this.selectedSecondaryWorkspaceDocument != null) {
      this.engDetailWorkItemId = this.selectedSecondaryWorkspaceDocument.workItemId;
      this.engDetailWorkItemVersionId = this.selectedSecondaryWorkspaceDocument.workItemVersionId;
    }
    this.lastUpdatedByForReviewResponse = "Last updated by " + this.selectedSecondaryWorkspaceDocument.lastActivityBy + " on " + this.datepipe.transform(this.selectedSecondaryWorkspaceDocument.lastActivityDate, 'MM/dd/yyyy');
  }

  SetRPContactPopup(emailid: string) {
    this.selectContactEmailId = emailid;
  }

  displayContactPopup(emailid: string, popoverId: string) {
    this.selectContactEmailId = emailid;
    this.checkForContactContent('contact-popup');
    this.hideRPPopUp();
    $('#' + popoverId).popover('show');
  }

  checkForContactContent(contact) {
    var timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 900);
  }
  ngOnDestroy() {
    //$('.contactUser').popover('hide');
    this.hideRPPopUp();
    this.ResetIwantToUnsavedFlag();
    this.modalConfirmationDialogRef?.unsubscribe();
    this.modalEngConfirmationDialogRef?.unsubscribe();

    this._alertService.remove(this.alert);

    //Unsubscribe active subscriptions
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    var isTaxQualityChecklistReloadedSession = sessionStorage.getItem("isTaxQualityChecklistReloaded");
    if (!(isTaxQualityChecklistReloadedSession == "true")) {
      sessionStorage.removeItem('isTaxQualityChecklistReloaded');
    }
  }

  ngAfterViewChecked() {
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.BindContactPopup();
      this.applyFilterOnceAfterDataLoad = false;

    }
  }
  ngAfterViewInit() {
    $('html').on('click', function (e) {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        //const id = $('#RPtxtId').val();
        // const id = this.reviewerPopupId;
        $('#' + this.reviewerPopupId).popover('hide');
      }
    });
    $('body').on('click', (e) => {
      //did not click a popover toggle or popover
      console.log('body click event fired', $(e.target).is('.contact-close'));
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          console.log('condition met');
          //$('.contactUser').popover('hide');
          if (this.adminPopupId !== undefined && this.adminPopupId !== null) {
            $('#' + this.adminPopupId).popover('hide');
          }
          if (this.cmsEPPMDPopupId !== undefined && this.cmsEPPMDPopupId !== null) {
            $('#' + this.cmsEPPMDPopupId).popover('hide');
          }

          if (this.reviewerPopupId !== undefined && this.reviewerPopupId !== null) {
            $('#RPcontactPopup_' + this.reviewerPopupId).popover('hide');
            this.reviewerPopupId = null;
          }

          if (this.teamMemberPopupId !== undefined && this.teamMemberPopupId !== null) {
            $('#teamMember_' + this.teamMemberPopupId).popover('hide');
            this.teamMemberPopupId = null;
          }

          $('#EPPMD_' + this.engagementList.engagementId).popover('hide');
        }
      }
    });
  }

  BindContactPopup() {
    this.createContactPopupDiv('EPPMD_', this.engagementList.engagementId)

    this.eppmdApproverDispalyModel.forEach(element => {
      element.teamMembers.forEach(member => {
        this.createContactPopupDiv('teamMember_', member.id)
      })
    });
    this.reviewersListForRP.forEach(element => {
      this.createContactPopupDiv('RPcontactPopup_', element.id);
    });
    if (this.arrEngagementAssignment !== undefined && this.arrEngagementAssignment !== null) {

      this.arrEngagementAssignment.forEach(item => {
        this.createContactPopupDiv('cmsEPPMDContactPopup_', item.assignmentCodeId);
        this.createContactPopupDiv('adminContactPopup_', item.assignmentCodeId);
        this.createContactPopupDiv('eqcrNameContactPopup_', item.assignmentCodeId);
      });
    }
  }

  EditEngagementName() {
    this.isEditEngName = true;
    this.updatedEngName = this.engagementList.engagementName;
  }

  CancelEditEngagementName() {
    this.isEditEngName = false;
    this.checkEngagementExists = false;
    this.updatedEngName = null;
    this.validEngagementName = true;
    this.EngagementNameRequired = false;
    this.invalidvalues = "";
  }

  editEPPMD() {
    this.isEditEPPMD = true;
    this.validateEP = true;
    this.isRequiredEngagementPartner = false;
    this.selectedEngagementPartner = this.engagementList.engagementPartnerName;
    this.selectedEngagementPartnerEmail = this.engagementList.engagementPartner;
    this.eppmdClassName = 'nonClickableLink';
  }

  cancelEPPMD() {
    this.isEditEPPMD = false;
    this.isRequiredEngagementPartner = false;
    this.selectedEngagementPartnerEmail = '';
    this.selectedEngagementPartner = '';
    this.isEPMatchWithEQCR = false;
  }

  cancelCompletionDate() {
    //Reset default values
    this.engCompletionDtEditViewModel = {
      editedcompletionDate: this.engagementList.plannedClosureDate,
      isEngCompletionDateFutureDt: true,
      isEngCompletionDateRequired: true,
      isEngCompletionDateSame: false,
      isEngCompletionDateValid: true,
      completionDateEditMode: false
    }
    this.UpdateEngCompletionDateError = false;
  }

  setEngCompletionDtEditViewModel() {
    this.engCompletionDtEditViewModel = {
      editedcompletionDate: this.engagementList.plannedClosureDate,
      isEngCompletionDateFutureDt: true,
      isEngCompletionDateRequired: true,
      isEngCompletionDateSame: false,
      isEngCompletionDateValid: true,
      completionDateEditMode: true
    }
  }

  editCompletionDate() {
    this.isCloseButtonClicked = false;
    //Set Edit mode values


    if (this.isNonAttestServiceLineEngagement &&
      this.engagementList.completionDate == null) {
      this.displayDialogForEngCompletionDate();
    } else {
      this.setEngCompletionDtEditViewModel();
    }

    this.isCompletionDateIndicated = true;
  }


  updateEngCompletionDate() {
    //Set Edit mode values
    this.engCompletionDtEditViewModel = {
      editedcompletionDate: $('.engCompletionDate').val(),
      isEngCompletionDateFutureDt: true,
      isEngCompletionDateRequired: true,
      isEngCompletionDateSame: false,
      isEngCompletionDateValid: true,
      completionDateEditMode: true
    }

    if ($('.engCompletionDate').val() == '') {
      this.engCompletionDtEditViewModel.isEngCompletionDateRequired = false;
    }
    else {
      const currentDate = new Date().toDateString();
      this.dateCompareResult = this.compareDateString(this.engCompletionDtEditViewModel.editedcompletionDate.toString(), currentDate);

      if (this.dateCompareResult == undefined) {
        this.engCompletionDtEditViewModel.isEngCompletionDateValid = false;
      }
      else {


        if (this.engCompletionDtEditViewModel.isEngCompletionDateFutureDt) {
          var transformInoDt = this.datepipe.transform(this.engagementList.plannedClosureDate, 'MM/dd/yyyy');
          if (transformInoDt == this.engCompletionDtEditViewModel.editedcompletionDate.toString()) {
            this.engCompletionDtEditViewModel.isEngCompletionDateSame = true;
          }
        }

        if (this.engCompletionDtEditViewModel.isEngCompletionDateRequired &&
          this.engCompletionDtEditViewModel.isEngCompletionDateFutureDt &&
          !this.engCompletionDtEditViewModel.isEngCompletionDateSame) {
          let engagementCompletionDateViewModel: EngagementCompletionDateViewModel = new EngagementCompletionDateViewModel();

          engagementCompletionDateViewModel.engagementId = this.engagementList.engagementId;
          engagementCompletionDateViewModel.loggedInUser = this.userObject.loggedInUserEmail;
          engagementCompletionDateViewModel.loggedInUserName = this.userObject.loggedInUserFullName;
          engagementCompletionDateViewModel.completionDate = this.engCompletionDtEditViewModel.editedcompletionDate; //engCompletionDt;
          engagementCompletionDateViewModel.isEngagementCompletionDate = false;
          this.engagementService.UpdateEngCompletionDate(engagementCompletionDateViewModel).subscribe(result => {
            if (result == 'true') {
              this.UpdateEngCompletionDateError = false;
              console.log('UpdateEngCompletionDate Result:==>', result);
              this.engagementList.plannedClosureDate = this.engCompletionDtEditViewModel.editedcompletionDate;
              this.engCompletionDtEditViewModel.completionDateEditMode = false;
            }
            else {
              this.UpdateEngCompletionDateError = true;
            }
          });
        }
      }
    }
  }


  updateEngagementEPPMD() {
    this.isEPMatchWithEQCR = false;
    const oldEngagementPartnerName = this.engagementList.engagementPartnerName;
    const oldEngagementPartner = this.engagementList.engagementPartner;
    const displayName = $('#txtPeoplePicker_EP_' + this.peoplepickerIndex).val();
    const engagementPartnerName = $('#UserHiddenProps_EP_' + this.peoplepickerIndex + ' #hdnUserName').val();
    const engagementPartner = $('#UserHiddenProps_EP_' + this.peoplepickerIndex + ' #hdnUserEmail').val();
    const epContactNo = $('#UserHiddenProps_EP_' + this.peoplepickerIndex + ' #hdnUserPhone').val();
    const epOfficeLocation = $('#UserHiddenProps_EP_' + this.peoplepickerIndex + ' #hdnOfficeLocation').val();
    const isValidEp = $('#hdnValidInput_EP_' + this.peoplepickerIndex).val();
    if (oldEngagementPartnerName !== displayName) {
      if (engagementPartner == null || engagementPartner === '' || (isValidEp) === 'false') {
        this.isRequiredEngagementPartner = true;
      } else {
        this.isRequiredEngagementPartner = false;
        this.engagementAssignmentList.forEach(assignment => {
          if (assignment.eqcrEmailId != '') {
            if (assignment.eqcrEmailId == engagementPartner) {
              this.isEPMatchWithEQCR = true;
            }
          }
        })

        if (!this.isEPMatchWithEQCR) {
          this.engagementList.engagementPartnerName = engagementPartnerName;
          this.engagementList.engagementPartner = engagementPartner;
          this.engagementList.epContactno = epContactNo;
          this.engagementList.ePPMDOfficeLocation = epOfficeLocation;
          this.loadingScreenService.startLoading();
          this.engagementService.updateEPPMD(this.engagementList).subscribe(result => {
            if (result) {
              this.isEditEPPMD = false;
              this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
                .subscribe(
                  engCollabModelResult => {
                    this.engagementCollaborationsViewModel = engCollabModelResult;
                    this.applyFilterOnceAfterDataLoad = true;
                    this.engagementList = this.engagementCollaborationsViewModel.engagement;
                    if (this.engagementList.status === EngagementStatus.EngActive) {
                      this.isEngagementCloseoutDisable = false;
                    }
                    else {
                      this.isEngagementCloseoutDisable = true;
                    }
                    this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
                    this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
                    this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
                    this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
                    this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
                    this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
                    this.CreateUIForCollaborationSpace();
                    this.ValidateUserIsEPPMDOfEngagement();

                    //Update Site Permission with available collbration Site member member
                    for (let i = 0; i < this.collaborationSiteList.length; i++) {

                      //1.For existing Collbration and has Sharepoint site created then
                      // CALL Update Permission API for team member update (ONLY FOR COLLBRATION SITE)
                      if (this.collaborationSiteList[i].siteURL != "") {

                        // Get List of Groups based on Permission Drop down(GROUP By)
                        const filterCollbMembers = this.collaborationSiteMembersList.
                          filter(collbMember => collbMember.collaborationSiteId === this.collaborationSiteList[i].collaborationSiteId);

                        let siteMembersGroups = filterCollbMembers.reduce((r, a) => {
                          r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                          return r;
                        }, {});

                        console.log("siteMembersGroups", siteMembersGroups);
                        let onwwerList: CollaborationSiteMembers[] = [];
                        let memberList: CollaborationSiteMembers[] = [];

                        let siteGroupTypes = Object.keys(siteMembersGroups);

                        siteGroupTypes.forEach(type => {
                          if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                            onwwerList = filterCollbMembers.filter(member =>
                              Number(member.memberPermissionId) === Number(type));
                          } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                            memberList = filterCollbMembers.filter(member =>
                              Number(member.memberPermissionId) === Number(type));
                          }
                        })

                        // Site Onwers Email List
                        let siteOwnerEmailIds: string[] = [];
                        onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                        //Site Members Email List
                        let siteMemberEmailIds: string[] = [];
                        memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                        let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                        let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
                        let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

                        OwnerListEmailModel.groupType = 'Owner';
                        OwnerListEmailModel.groupUsersEmail = [];
                        siteOwnerEmailIds.forEach(emailid => {
                          let adUser: ADUser = new ADUser();
                          adUser.firstName = '';
                          adUser.lastName = '';
                          adUser.userEmailId = emailid;
                          OwnerListEmailModel.groupUsersEmail.push(adUser);
                        })

                        MemberListEmailModel.groupType = 'Member';
                        MemberListEmailModel.groupUsersEmail = [];
                        siteMemberEmailIds.forEach(emailid => {
                          let adUser: ADUser = new ADUser();
                          adUser.firstName = '';
                          adUser.lastName = '';
                          adUser.userEmailId = emailid;
                          MemberListEmailModel.groupUsersEmail.push(adUser);
                        })

                        collbSiteUsersUpdatePermissionModel.siteType = "CollabrationSite";
                        collbSiteUsersUpdatePermissionModel.siteUniqueID = this.collaborationSiteList[i].siteUniqueID;
                        collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
                        collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
                        let updatePermissionsModelList: UpdatePermissionsModel[] = [];
                        updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);

                        let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
                        updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;

                        this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
                          updatePermissionsResult => {
                            console.log('UpdatePermissions Result', updatePermissionsResult);
                          },
                          error => {
                            this.loadingScreenService.stopLoading();
                            this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                          });

                      }
                    } //this.collaborationSiteList.length --- End
                    setTimeout(() => {
                      this.loadingScreenService.stopLoading();
                    }, 30000);
                  })
            }
          });
        }
      }
    } else {
      this.isEditEPPMD = false;
    }
  }

  updateTeamMembersList(event, roleShortName) {
    this.eppmdClassName = 'cursor-pointer';
  }

  refreshEPPopup(textboxName: string) {
    this.removeRowSelection('tblEPPMD', 'searchEPPMDName', 'EPErrorMessage');
    const lastIndex = this.peoplepickerIndex;
    this.EPPMDCtrl = textboxName + this.epRoleName + '_' + this.peoplepickerIndex;
    this.selectedEPIndex = null;
    this.validateEP = false;
    this.membersListArrayForEP = null;
    if ($('#' + this.EPPMDCtrl).hasClass('text-danger')) {
      $('#' + this.EPPMDCtrl).removeClass('text-danger');
    }
  }

  UpdateEngagementName() {
    this.checkEngagementExists = false;
    if (this.updatedEngName == null || this.updatedEngName == '') {
      this.EngagementNameRequired = true;
      this.validEngagementName = true;
    }
    else if (!this.validateAssignmentName(this.updatedEngName)) {
      this.validEngagementName = false;
    } else {
      this.EngagementNameRequired = false;
      this.validEngagementName = true;
      const engObj = new UpdateEngagementNameMode();
      engObj.engagementId = this.engagementList.engagementId;
      engObj.engagementName = this.updatedEngName;
      this.engagementService.isEngagementExists(this.engagementList.clientId, engObj.engagementName)
        .subscribe(
          res => {
            this.engagementCount = res;
            console.log("engagementCount ==>", this.engagementCount);
            if (this.engagementCount > 0 &&
              this.originalEngagementName.toLowerCase() != engObj.engagementName.toLowerCase() && this.isEditEngName) {
              this.checkEngagementExists = true;
            }
            else {
              this.checkEngagementExists = false;
              this.engagementService.UpdateEngagementName(engObj).subscribe(
                (isEngagementNameSaved: boolean) => {
                  if (isEngagementNameSaved) {
                    this.engagementList.engagementName = this.updatedEngName;
                    this.isEditEngName = false;
                    this.getEngagementCollaborationsById(this.engagementId);
                    const apiRequest = new ApiRequest();
                    apiRequest.title = this.engagementList.clientName + ' | ' + this.engagementList.engagementName;
                    apiRequest.siteUrl = this.engagementList.siteUrl;
                    this.engagementService.UpdateEngagementTitle(apiRequest).subscribe(result => {
                    });
                  }
                });
            }
          },
          () => {
            this.checkEngagementExists = false;
            this.loadingScreenService.stopLoading();
            this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
          });
    }
  }

  initinateConfirmationPopUp() {
    this.modalConfirmationDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dailogTitle = dialogModal.dialogTitle;
          const dialogButtonName = dialogModal.dialogButtonName;
          const engagementVoidedReason = dialogModal.DeclinedReasonInput;
          if (dailogTitle === EngagementDialogTitles.CloseEng
            && !this.isEngagementUpdateInProcess
            && this.isEngSummaryScreen
            && dialogButtonName === 'ok') {
            this.isEngagementUpdateInProcess = true;
            this.getEngagementDueDateExtensions(this.engagementId);
            this.UpdateEngagementStatus(EngagementStatus.EngClosed, null);
          }
          if (dailogTitle === EngagementDialogTitles.ReEditQuestionnaire &&
            dialogButtonName === 'ok') {
            //changes for edit quetionnaire
            this.CreateWorkItem();
          }
          if (dailogTitle === EngagementDialogTitles.VoidEngagement
            && !this.isEngagementUpdateInProcess
            && this.isEngSummaryScreen) {
            this.isEngagementUpdateInProcess = true;
            this.UpdateEngagementStatus(EngagementStatus.EngVoided, engagementVoidedReason);
          }
          if (this.isEngagementCloseoutDisable
            && (dailogTitle === EngagementDialogTitles.CloseEng && dialogButtonName === 'Cancel')
            || (dailogTitle === QuestionnaireDialogTitles.UnAnsQuestions && dialogButtonName === 'ok')) {
            this.isEngagementCloseoutDisable = false;
          }
          if (dailogTitle == EngagementDialogTitles.RemoveExecutionDocument && dialogButtonName == 'ok') {
            this.removeExecutionSecondaryWorkspaceDocumets();
          }
          if (dailogTitle == EngagementDialogTitles.EditQuestionnaire && dialogButtonName == 'ok') {
            this.removeSPDocFromEngage(this.selectedSecondaryWorkspaceDocument.workspaceId, dailogTitle)
          }
          if (dailogTitle == EngagementDialogTitles.ExportDocument && dialogButtonName == 'ok') {
            this.exportExecuationPhaseAddedDocument();
          }
          if (dailogTitle == ConsentResetDialogTitle.ResetClientConsent && dialogButtonName == 'ok') {
            this.ResetClientConsentOptions();
            this.UpdateClientConsentFlag(this.clientConsentFlagId);
            this.previousClientConsentFlagId = this.clientConsentFlagId;
          }
          if ((dailogTitle == ConsentResetDialogTitle.ResetClientConsent && dialogButtonName == 'cancel') || (dailogTitle == ConsentResetDialogTitle.ResetClientConsent && dialogButtonName == 'close')) {
            this.isRequiredConsentData = false;
            this.clientConsentFlagId = this.previousClientConsentFlagId;
            $("#" + this.clientConsentFlagId).attr('checked', true).trigger('click');
          }
          if (dailogTitle === EngagementDialogTitles.AdministrativeClose
            && dialogButtonName === 'ok') {
            this.UpdateEngagementStatus(EngagementStatus.EngClosed, null);
            this.insertEngAdministrativeClose();
          }
        }
      });
  }

  getEngagementDetailsById() {
    this.loadingScreenService.startLoading();
    this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
      .subscribe(
        result => {
          this.engagementCollaborationsViewModel = result;
          this.engagementList = this.engagementCollaborationsViewModel.engagement;
          if (this.engagementList.completionDate == null && this.engagementList.serviceLine == 'Advisory' && this.isNonAttestServiceLineEngagement) {
            this.isCompletionDateIndicated = false;
          }
          else {
            this.isCompletionDateIndicated = true;
          }
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.indusADGroupsList = null;
          console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
        });
  }

  initinateEngConfirmationPopUp() {
    this.modalEngConfirmationDialogRef = this.dataService.getEngModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dailogTitle = dialogModal.dialogTitle;
          const dialogButtonName = dialogModal.dialogButtonName;
          if (dailogTitle === 'Completion date updated'
            && dialogButtonName === 'ok') {
            this.getEngagementDetailsById();
          }
        }
      });
  }

  EngagementClosure(dialogTitle: string) {
    this.loadingScreenService.stopLoading();
    this.isEngSummaryScreen = true;
    this.alreadyInvoked = true;
    let dialogDesc: string = '';
    let dialogInput = '';
    let dialogType = 'warning';
    let action = dialogTitle;
    let engId: string = String(this.engagementId);
    let dialogButtons: number = 2;
    let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
    let isCloseEngagementModal: boolean = true;
    if (dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions) {
      dialogButtons = 1;
      dialogType = 'danger';
      engId = '';
      isCloseEngagementModal = false;
      dialogDesc = 'There are unanswered questions. You cannot close an engagement until all questions have been answered.';
      this.displayConfirmationPopUp(dialogType, action, dialogDesc, dialogButtons, dialogInput, false, isCloseEngagementModal, engId, loggedInUserEmail);
    }
    else {
      this.displayConfirmationPopUp(dialogType, action, dialogDesc, dialogButtons, dialogInput, false, isCloseEngagementModal, engId, loggedInUserEmail);
    }
  }

  displayConfirmationPopUp(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number, _dialogInput: string, IsDeclineModal: boolean, IsCloseEngagementModal: boolean = false,
    engId: string, loggedInUserEmail: string, _dialogRedirectUrl: string = '') {
    let isVoidEngagement: Boolean = false;
    let isUpdateQuestionnaireExecutionPhase: Boolean = false;
    if (_dialogTitle === EngagementDialogTitles.VoidEngagement) {
      isVoidEngagement = true;
    }
    if (_dialogTitle === EngagementDialogTitles.ReEditQuestionnaire) {
      isUpdateQuestionnaireExecutionPhase = true;
    }
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: _dialogRedirectUrl,
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogDeclineModal: IsDeclineModal,
      dialogCloseEngagementModal: IsCloseEngagementModal,
      engagementId: engId,
      loggedInUserEmail: loggedInUserEmail,
      dialogVoidEngagementModal: isVoidEngagement,
      dialogUpdateQuestionnaireExecutionPhase: isUpdateQuestionnaireExecutionPhase
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
  }

  UpdateEngagementStatus(status: string, engagementVoidedReason: string) {
    this.isEngSummaryScreen = false;
    const engObj = new EngagementList();
    engObj.status = status;
    engObj.engagementId = this.engagementId;
    engObj.LastModifiedBy = this.userObject.loggedInUserEmail;
    engObj.LastModifiedByName = this.userObject.loggedInUserFullName;
    this.isEngagementClosed = true;
    //Update engagement status
    this.loadingScreenService.startLoading();
    this.UpdateEngagementStatusData.engagementList = engObj;
    this.UpdateEngagementStatusData.isEngContinuanceEligible = this.IsContinuanceEligible;
    this.UpdateEngagementStatusData.engagementVoidedReason = engagementVoidedReason;
    this.UpdateEngagementStatusData.siteCollaborationMembersList = this.SiteCollaborationMembersList;
    this.UpdateEngagementStatusInDB();
  }

  UpdateEngagementStatusInDB() {
    this.engagementService.UpdateEngagementStatus(this.UpdateEngagementStatusData).subscribe((status: boolean) => {
      if (status) {

        if (this.UpdateEngagementStatusData.engagementList.status === EngagementStatus.EngVoided) {
          this.applyAbandonSitePolicy();
        }

        this.GetIndusConsentADGroups();
        if (this.collaborationSiteList !== null && this.collaborationClientSiteList !== undefined) {
          this.collaborationSiteList.forEach(collaborationItem => {
            this.updateSharePointMetadata(collaborationItem.collaborationSiteId);
          });
        }

        if (this.isConveneProjectsExists) {
          this.SendEngagementClosureNotification();
        }
        else {
          // skip to Send Engagement ClosureNotification in convene
          this.loadingScreenService.stopLoading();
        }

        this.GenerateIndusConsentEvent('No');

      } else {
        this.loadingScreenService.stopLoading();
      }
    },
      err => {
        this.loadingScreenService.stopLoading();
        this.logService.error('Engagement status update failed.', err);
        window.location.reload();
      });
  }

  updateSharePointMetadata(collaborationSiteId: number) {
    const sharepointMetadata: SPSiteMetadata[] = [];
    this.engagementService.getSharePointMetadataMapping(this.engagementId, collaborationSiteId)
      .subscribe((sharePointDbData: SharePointMetadataMapping) => {
        sharePointDbData.propertiesMapping.forEach(item => {
          this.globalMetadata.forEach(metadataElement => {
            if (item.value === metadataElement.name) {
              metadataElement.value = sharePointDbData.metadata[item.key];
              sharepointMetadata.push(metadataElement);
            }
          });
        });
        const sharePointWrapper = new SharePointWrapper();
        sharePointWrapper.siteURL = sharePointDbData.metadata.siteUrl;
        sharePointWrapper.metadataList = sharepointMetadata;
        this.engagementService.updateSiteMetadata(sharePointWrapper).subscribe(status => {
          console.log('Metadata saved', status);
        });
      });
  }

  DisplayTMContactPopup(teamMemberId: number, emailid: string) {
    this.selectContactEmailId = emailid;
    this.teamMemberPopupId = teamMemberId.toString();
    // Close the pop up if any pop up open eariler
    this.hideRPPopUp();
    this.checkForContactContent('contact-popup');
    $('#teamMember_' + teamMemberId).popover('show');
    //$('#teamMember_' + teamMemberId).popover('update');
  }

  DisplayRPContactPopup(reviewerId: number, emailid: string) {
    this.selectContactEmailId = emailid;
    //$('#RPtxtId').val(reviewerId);
    this.reviewerPopupId = reviewerId.toString();
    // Close the pop up if any pop up onen eariler
    this.hideRPPopUp();
    this.checkForContactContent('contact-popup');
    $('#RPcontactPopup_' + reviewerId).popover('show');
    //$('#RPcontactPopup_' + reviewerId).popover('update');
  }

  hideRPPopUp() {
    this.eppmdApproverDispalyModel.forEach(element => {
      element.teamMembers.forEach(member => {
        $('#teamMember_' + member.id).popover('hide');
      })
    });

    this.reviewersListForRP.forEach(element => {
      $('#RPcontactPopup_' + element.id).popover('hide');
    });
    if (this.arrEngagementAssignment !== undefined && this.arrEngagementAssignment !== null) {
      this.arrEngagementAssignment.forEach(engAssign => {
        $('#cmsEPPMDContactPopup_' + engAssign.assignmentCodeId).popover('hide');
        $('#adminContactPopup_' + engAssign.assignmentCodeId).popover('hide');
        $('#eqcrNameContactPopup_' + engAssign.assignmentCodeId).popover('hide');
      })
    }

    $('#EPPMD_' + this.engagementList.engagementId).popover('hide');
  }

  createContactPopupDiv(controlIdText: string, id: number) {
    console.log('reviewerId ==>', id);
    $('#' + controlIdText + id).popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',
      boundary: 'viewport',
      content: function () {
        // console.log('createContactPopupDiv func called ', $('#contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  // collaboration section
  getEngagementAssignmentsById(engId: number) {
    let isEditEng: boolean = false;
    this.engagementService.GetEngagementAssignmentsById(engId, isEditEng)
      .subscribe(
        assignments => {
          this.engagementAssignmentList = assignments;
          console.log(this.engagementAssignmentList);
        },
        () => {
          this.engagementAssignmentList = null;

          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }


  CreateUIForCollaborationSpace() {
    this.collaborationSiteViewModelList = [];
    for (let i = 0; i < this.collaborationSiteList.length; i++) {
      const collaborationViewModel = new CollaborationSiteViewModel();
      collaborationViewModel.collaborationSite = new CollaborationSite();
      collaborationViewModel.engagementAssignmentList = [];
      collaborationViewModel.teamMemberList = [];
      collaborationViewModel.clientTeamMemberList = [];
      collaborationViewModel.collaborationSiteMembersList = [];
      collaborationViewModel.adGroupUsersSyncCollabMembers = [];
      collaborationViewModel.adClientSiteUsersSyncEngageClientMmembers = [];
      collaborationViewModel.collaborationClientSite = new CollaborationClientSite();
      collaborationViewModel.engagement = new EngagementList();
      collaborationViewModel.collaborationSpacePriorYearViewModelList = [];
      collaborationViewModel.collaborationSpacePriorYearViewModelList =
        this.collaborationSpacePriorYearViewModelList.filter(model => model.currentCollaborationSiteId
          == this.collaborationSiteList[i].collaborationSiteId);

      this.collaborationSiteViewModelList.push(collaborationViewModel);

      this.collaborationSiteViewModelList[i].collaborationSite = this.collaborationSiteList[i];
      this.collaborationSiteViewModelList[i].collaborationClientSite =
        this.collaborationClientSiteList.find(clientSite =>
          clientSite.collaborationSiteId == this.collaborationSiteViewModelList[i].collaborationSite.collaborationSiteId)

      let defaultClientSite: CollaborationClientSite = {
        collaborationSiteId: 0,
        collaborationSiteName: '',
        engagementId: 0,
        clientId: 0,
        siteURL: '',
        siteUniqueID: '',
        sPPermissionStatus: '',
        siteRequestStatus: '',
        siteMemberCount: 0
      }
      if (this.collaborationSiteViewModelList[i].collaborationClientSite == undefined) {
        this.collaborationSiteViewModelList[i].collaborationClientSite = defaultClientSite;
      }

      this.collaborationSiteViewModelList[i].engagement = this.engagementList;

      let currentCollabId = this.collaborationSiteList[i].collaborationSiteId;
      for (let a = 0; a < this.engagementAssignmentList.length; a++) {
        if (this.engagementAssignmentList[a].collaborationSiteId == currentCollabId) {
          this.collaborationSiteViewModelList[i].engagementAssignmentList.push(this.engagementAssignmentList[a]);
        }
      } // end for loop engagementAssignmentList.length
      for (let t = 0; t < this.teamMemberList.length; t++) {
        if (this.teamMemberList[t].collaborationSiteId == currentCollabId) {
          this.collaborationSiteViewModelList[i].teamMemberList.push(this.teamMemberList[t]);

        }
      } // end for loop teamMemberList.length
      if (this.clientTeamMemberList != null) {
        for (let t = 0; t < this.clientTeamMemberList.length; t++) {
          if (this.clientTeamMemberList[t].collaborationSiteId == currentCollabId) {
            this.collaborationSiteViewModelList[i].clientTeamMemberList.push(this.clientTeamMemberList[t]);

          }
        } // end for loop clientTeamMemberList.length
      } // end   if (this.clientTeamMemberList != null)

      // Start Loop for collaborationSiteMembersList
      if (this.collaborationSiteMembersList != null) {
        for (let m = 0; m < this.collaborationSiteMembersList.length; m++) {
          if (this.collaborationSiteMembersList[m].collaborationSiteId == currentCollabId) {
            this.collaborationSiteViewModelList[i].collaborationSiteMembersList.push(this.collaborationSiteMembersList[m]);
          }
        } // end for loop collaborationSiteMembersList.length
      } //End collaborationSiteMembersList

      this.collaborationSiteViewModelList[i].collaborationSite.siteMemberCount
        = this.collaborationSiteViewModelList[i].collaborationSiteMembersList.length;

      console.log('collaborationSiteViewModelList==>', this.collaborationSiteViewModelList);
    } // end for loop collaborationSiteList.length
    // }); // end service call GetEngagementCollaborationSites(engid);
  }

  collapseAllAssignmentTeamDetails() {
    $('.assignmentsection').fadeOut(500);
  }
  expandAllAssignmentTeamDetails() {
    $('.assignmentsection').fadeIn(500);
  }

  CloseManageTeam() {
    this.newClientTeamMemberList = [];
    this.newTeamMemberList = [];
    this.arrValidationForNewTeamMember = [];
    this.arrValidationForNewClientTeamMember = [];
  }

  expandTeamDetails(index: number, isclick: boolean, selectedCollaborationSite: CollaborationSite) {
    this.UpdateEngagementCollaborationsData();
    this.isAdMemberchecked = false;
    $('#member-save-success').addClass('d-none');
    $('#member-save-validation').addClass('d-none');
    this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers = [];
    this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers = [];
    if (isclick) {
      this.currentCollabModel.index = index;
      this.currentCollabModel.currentCollaborationSiteViewModel = JSON.parse(JSON.stringify(this.collaborationSiteViewModelList[index]));
      let ADGroupUsersList: ADGroupUsers[] = [];
      let CollabrationSiteUserModel: ADGroupUsers = new ADGroupUsers();
      let ClientSiteUserModel: ADGroupUsers = new ADGroupUsers();
      let siteId: number;

      if (selectedCollaborationSite !== undefined) {
        siteId = selectedCollaborationSite.collaborationSiteId;
      }

      this._activedirectoryService.GetUsersFromADGroup(selectedCollaborationSite.siteUniqueID).subscribe(
        result => {
          ADGroupUsersList = result;
          this.isAdMemberchecked = true;
          console.log('ADGroupUsersModelList', ADGroupUsersList);

          if (ADGroupUsersList !== undefined) {
            CollabrationSiteUserModel = ADGroupUsersList[0];
            ClientSiteUserModel = ADGroupUsersList[1];
          }

          if (ADGroupUsersList != null && ADGroupUsersList.length > 0) {
            this.SyncClientTeamMemberDetails(siteId, ClientSiteUserModel);
            if (CollabrationSiteUserModel != null) {
              // Update siteMembersCount based in AD group member availability
              this.collaborationSiteViewModelList[index].collaborationSite.siteMemberCount =
                CollabrationSiteUserModel.usersDetails.length;
              //2.  Start  adGroupUsersSyncCollabMembers
              let adGroupUsersSyncCollabMemberList: ADGroupUsersSyncCollabMembers[] = [];
              const selectedCollabMemberList =
                this.collaborationSiteViewModelList.filter(colb => colb.collaborationSite.collaborationSiteId === siteId)[0].collaborationSiteMembersList;

              CollabrationSiteUserModel.usersDetails.forEach(ADCollbUser => {
                let tempPermissionId: number = 0;
                let tempRoleid: number = 0;
                let keyMemberRoleId: number = 0;
                let isKeyTeamMember: boolean = false;
                let description: string = '';
                let foundCollbMember = selectedCollabMemberList.find(member => member.memberEmailId.toLowerCase().trim() === ADCollbUser.userEmailId.toLowerCase().trim());

                if (foundCollbMember !== undefined) {
                  tempPermissionId = foundCollbMember.memberPermissionId;
                  isKeyTeamMember = foundCollbMember.isKeyTeamMember;
                  description = foundCollbMember.description;
                }
                // Associate member Permission(ONWER/MEMBER) based on AD Group Type
                this.teamMemberPermissions.forEach(permission => {
                  let permissionArr = permission.typeValue.split(' ');
                  if (permissionArr[1] == ADCollbUser.groupType && tempPermissionId == permission.id) {
                    tempPermissionId = permission.id;
                  }
                })

                if (tempPermissionId == 0 || tempPermissionId == null) {
                  tempPermissionId = this.defaultPermissionId;
                }

                // 2.	If site is MS Team (URL is not Null) then permission dropdown disable and set the value as “Site Member”
                if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.msTeamUrl != null) {
                  tempPermissionId = this.defaultPermissionId;
                }

                // Associate member Role for Engage Member based on matching Email ID
                if (foundCollbMember !== undefined) {
                  if (this.isAudit && foundCollbMember.keyMemberRoleId == this.getTeamMemberRoleIdByCode('A') ||
                    foundCollbMember.keyMemberRoleId == this.getTeamMemberRoleIdByCode('CMSEP')) {
                    keyMemberRoleId = this.defaultKeyRoleid;
                  } else {
                    tempRoleid = foundCollbMember.memberRoleId;
                    keyMemberRoleId = foundCollbMember.keyMemberRoleId;
                  }
                } else {
                  tempRoleid = this.defaultRoleid;
                  keyMemberRoleId = this.defaultKeyRoleid;
                }

                // IF AD Group mEMBERhas userName Blank then pick user Name from Engage Member
                if (ADCollbUser.userName == null && foundCollbMember !== undefined) {
                  ADCollbUser.userName = foundCollbMember.memberName;
                }

                let matchCollbMember: ADGroupUsersSyncCollabMembers = {
                  collaborationSiteMembersId: 0,
                  collaborationSiteId: siteId,
                  userName: ADCollbUser.userName,
                  memberEmailId: ADCollbUser.userEmailId,
                  memberRoleId: tempRoleid,
                  groupName: ADCollbUser.groupName,
                  jobtitle: ADCollbUser.jobtitle,
                  serviceLine: ADCollbUser.serviceLine,
                  memberPermissionId: tempPermissionId,
                  validateUser: false,
                  keyMemberRoleId: keyMemberRoleId,
                  isKeyTeamMember: isKeyTeamMember,
                  description: description
                }
                adGroupUsersSyncCollabMemberList.push(matchCollbMember);
              })
              this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers
                = adGroupUsersSyncCollabMemberList;
              //  <== End adGroupUsersSyncCollabMembers   ==>
            }
            console.log('currentCollabModel==>', this.currentCollabModel);
          }

        },
        error => {
          this.loadingScreenService.stopLoading();
          this.isAdMemberchecked = true;
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    }
  }

  SyncClientTeamMemberDetails(siteId: number, ClientSiteUserModel: ADGroupUsers) {
    //1.  Start  ADClientSiteUsersSyncEngageClientMmembers
    let adClientSiteUsersSyncEngageClientMmembersList: ADClientSiteUsersSyncEngageClientMmembers[] = [];
    const selectedCollabclientTeamMemberList =
      this.collaborationSiteViewModelList.filter(colb => colb.collaborationSite.collaborationSiteId === siteId)[0].clientTeamMemberList;

    selectedCollabclientTeamMemberList.forEach(clientTeamMember => {
      let foundADClientUser = ClientSiteUserModel.usersDetails.find(member => member.userEmailId.toLowerCase().trim() === clientTeamMember.emailId.toLowerCase().trim());
      if (foundADClientUser == undefined) {
        let matchClientUser: ADClientSiteUsersSyncEngageClientMmembers =
        {
          engagementId: this.engagementId,
          collaborationSiteId: siteId,
          firstName: clientTeamMember.firstName,
          lastName: clientTeamMember.lastName,
          emailId: clientTeamMember.emailId,
          title: clientTeamMember.title,
          permissionId: clientTeamMember.permissionId,
          invitationurl: '',
          status: clientTeamMember.status //"Add in progress"
        }
        adClientSiteUsersSyncEngageClientMmembersList.push(matchClientUser);
      }
    });

    if (ClientSiteUserModel != null) {
      ClientSiteUserModel.usersDetails.forEach(ADClientUser => {
        let tempPermissionId: number = 0;
        let clientMemberStatusInDb: string = "";
        // To check Client Member Permission(R/RW/RWD) based on AD Group Type
        let foundClientMember = selectedCollabclientTeamMemberList.find(member => member.emailId.toLowerCase().trim() === ADClientUser.userEmailId.toLowerCase().trim());
        if (foundClientMember !== undefined) {
          tempPermissionId = foundClientMember.permissionId;
          clientMemberStatusInDb = foundClientMember.status;
        } else {
          clientMemberStatusInDb = "Removed"
          this.clientMemberPermissionModelList.forEach(permission => {
            if (permission.code === ADClientUser.groupType) {
              tempPermissionId = permission.id;
            }
          });
        }

        let isPermissionInSync = false;
        this.clientMemberPermissionModelList.forEach(permission => {
          if (permission.code === ADClientUser.groupType && tempPermissionId == permission.id) {
            tempPermissionId = permission.id;
            isPermissionInSync = true;
          }
        })

        if (tempPermissionId === 0 || tempPermissionId == null) {
          tempPermissionId = this.defaultClientPermissionId;
        }

        if (isPermissionInSync == false) {
          clientMemberStatusInDb = "PermissionUpdated"; //"Permission update in progress"
        }

        // IF AD Group Member userName Blank then pick user First/Last Name from Engage Client Member
        let tempFirstName: string;
        let tewmpLastName: string;
        if (ADClientUser.userName != null) {
          let userNameArry = ADClientUser.userName.split(' ');
          tewmpLastName = userNameArry[0].toString().replace(',', '');
          if (userNameArry.length > 1) {
            tempFirstName = userNameArry[1].toString().replace(',', '');
          }
        } else if (foundClientMember !== undefined) {
          tempFirstName = foundClientMember.firstName;
          tewmpLastName = foundClientMember.lastName;
        }

        if (ADClientUser.jobtitle == null && foundClientMember !== undefined) {
          ADClientUser.jobtitle = foundClientMember.title;
        }

        let matchClientUser: ADClientSiteUsersSyncEngageClientMmembers =
        {
          engagementId: this.engagementId,
          collaborationSiteId: siteId,
          firstName: tempFirstName,
          lastName: tewmpLastName,
          emailId: ADClientUser.userEmailId,
          title: ADClientUser.jobtitle,
          permissionId: tempPermissionId,
          invitationurl: '',
          status: clientMemberStatusInDb
        }
        adClientSiteUsersSyncEngageClientMmembersList.push(matchClientUser);
      })
    }
    this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers = adClientSiteUsersSyncEngageClientMmembersList;
    //1. <==  end ADClientSiteUsersSyncEngageClientMmembers ==>
  }

  getTeamMemberRoles() {
    return this.engagementService.GetTeamMemberRoles().subscribe(
      res => {
        this.teamMemberRoleList = res;
      },
      () => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
      });
  }

  getClientMemberPermissions() {
    this.applicationMasterService.GetClientMemberPermissions().subscribe(
      result => {
        this.clientMemberPermissions = result;
        this.clientMemberPermissionModelList = [];
        this.clientMemberPermissions.forEach(pr => {
          let codeValueArr = pr.typeValue.split('|');
          let tempModel: ClientMemberPermissionModel = new ClientMemberPermissionModel();
          tempModel.id = pr.id;
          tempModel.code = codeValueArr[0];
          tempModel.typeValue = codeValueArr[1];
          this.clientMemberPermissionModelList.push(tempModel);
          if (codeValueArr[0] === 'R') {
            this.defaultClientPermissionId = pr.id;
          }
        })

        console.log('clientMemberPermissionModelList', this.clientMemberPermissionModelList);
      }
    );
  }

  getTeamMemberRoleList() {
    this.applicationMasterService.GetAllTeamMemberRoles('TeamMemberRole').subscribe(
      result => {
        this.applicationmasterList = result;
        this.applicationmasterList.forEach(element => {
          const teamMemberRoleCode = new AppCodes();
          teamMemberRoleCode.code = element.code;
          teamMemberRoleCode.codeValue = element.applicationMasterId;
          this.teamMemberRoleCodes.push(teamMemberRoleCode);
          if (element.code === 'P') {
            this.defaultRoleid = element.applicationMasterId
          }
        });
        console.log('teamMemberRoleCodes');
        console.log(this.teamMemberRoleCodes);
      }
    );
  }

  getKeyTeamMemberRoleList() {
    let applicationName = 'KeyTeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        this.keyApplicationmasterList = result;
        this.keyApplicationmasterList.forEach(element => {
          const keyTeamMemberRoleCode = new AppCodes();
          keyTeamMemberRoleCode.code = element.code;
          keyTeamMemberRoleCode.codeValue = element.applicationMasterId;
          this.keyTeamMemberRoleCodes.push(keyTeamMemberRoleCode);
          if (element.code === 'OTH') {
            this.defaultKeyRoleid = element.applicationMasterId
          }
        });
        console.log('Key team member : ' + result);
      }
    );
  }

  getTeamMemberRoleIdByCode(Code: string): number {
    return this.teamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }

  getKeyTeamMemberRoleIdByCode(Code: string): number {
    return this.keyTeamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }

  getTeamMemberPermissions() {
    this.applicationMasterService.GetTeamMemberPermissions().subscribe(
      result => {
        this.teamMemberPermissions = result;
        console.log('this.teamMemberPermissions ==>', this.teamMemberPermissions);
        this.teamMemberPermissions.forEach(permission => {
          if (permission.typeValue === 'Site member') {
            this.defaultPermissionId = permission.id
          }
        })
      }
    );
  }

  refreshTeamMemberPopup(index, textboxName: string) {
    this.newTeamMemberList[index].validateUser = false;
    this.removeRowSelection('tblMemberAD', 'searchTeamMemberName', 'TeamMemberErrorMessage');
    if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {
      this.teamMemberCtrl = textboxName + this.INDUSRoleName + '_' + index;
      this.newTeamMemberList[index].userName = '';
      this.newTeamMemberList[index].memberEmailId = '';
    }
    else {
      this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
      this.newTeamMemberList[index].userName = '';
      this.newTeamMemberList[index].memberEmailId = '';
    }

    this.teamMemberEmailCtrl = 'emailId_' + index;
    this.currentTeamMemberIndex = index;
    this.selectedTMIndex = null;
    console.log(this.teamMemberCtrl);
  }

  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $('#' + errorMessageId).text('');
    const searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }

  GetTeamMembers(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    if (this.memberFirmData?.memberFirmMappingId != undefined && this.isloggedInUserAdmin == 'yes') {
      searchModel.isMemberFirm = true;
      searchModel.memberFirmDomain = [];
      searchModel.memberFirmDomain.push(this.memberFirmData?.memberDomain)
    }
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForTeamMembers = userList;
          this.signatureHandlers = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForTeamMembers = null;
          this.signatureHandlers = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  searchUsers(searchTextBox, tableId: string) {
    console.log('searchUsers func called', searchTextBox, tableId);
    $("#TeamMemberErrorMessage").text('');
    const searchText = $('#' + searchTextBox).val();
    if (tableId === 'tblEPAD' || tableId === 'tblEQCR') {
      this.getEPList(searchText);
    }

    if (tableId === "tblAssAdminAD") {
      this.GetAssignmentAdmins(searchText);
    }

    if (tableId === "tblMemberAD") {
      this.GetTeamMembers(searchText);
    }
  }

  selectTeamMember(index: any, member: any) {
    $("#TeamMemberErrorMessage").text('');
    $('#tblMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedTMIndex = index;
  }

  setTeamMember() {
    let index = this.selectedTMIndex;
    if (Number(index) >= 0) {
      const selectedMemberName = $('#spMemberdisplayName' + index).text();
      const selectedMemberEmail = $('#spMembermail' + index).text();
      const selectedMemberPhone = $('#spMemberbusinessPhones' + index).text();
      const selectedJobTitle = $('#spjobtitle' + index).text();
      $("#TeamMemberErrorMessage").text('');
      var countofAddedTM = 0;

      if (this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers.find(member => member.memberEmailId === selectedMemberEmail)) {
        countofAddedTM = countofAddedTM + 1;
      }
      if (countofAddedTM == 0) {
        $("#TeamMemberErrorMessage").text('');
        $('#' + this.teamMemberCtrl).val(selectedMemberName);
        $('#' + this.teamMemberEmailCtrl).val(selectedMemberEmail);
        if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {
          $('#UserHiddenProps_' + this.INDUSRoleName + '_' + this.currentTeamMemberIndex + ' #hdnUserName').val(selectedMemberName);
          $('#UserHiddenProps_' + this.INDUSRoleName + '_' + this.currentTeamMemberIndex + ' #hdnUserEmail').val(selectedMemberEmail);
          $('#UserHiddenProps_' + this.INDUSRoleName + '_' + this.currentTeamMemberIndex + ' #hdnUserPhone').val(selectedMemberPhone);
          $('#UserHiddenProps_' + this.INDUSRoleName + '_' + this.currentTeamMemberIndex + ' #hdnJobTitle').val(selectedJobTitle);
          let validIndusUserInput = $('#hdnValidInput_' + this.INDUSRoleName + '_' + this.currentTeamMemberIndex).val();
          if (validIndusUserInput === undefined) {
            $('#hdnValidInput_' + this.INDUSRoleName + '_' + this.currentTeamMemberIndex).val(true);
          }
          this.newTeamMemberList[this.currentTeamMemberIndex].userName = selectedMemberName;
          this.newTeamMemberList[this.currentTeamMemberIndex].memberEmailId = selectedMemberEmail;
        }
        else {
          $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + this.currentTeamMemberIndex + ' #hdnUserName').val(selectedMemberName);
          $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + this.currentTeamMemberIndex + ' #hdnUserEmail').val(selectedMemberEmail);
          $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + this.currentTeamMemberIndex + ' #hdnUserPhone').val(selectedMemberPhone);
          $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + this.currentTeamMemberIndex + ' #hdnJobTitle').val(selectedJobTitle);
          let validTeamMemberInput = $('#hdnValidInput_' + this.teamMemberRoleName + '_' + this.currentTeamMemberIndex).val();
          if (validTeamMemberInput === undefined) {
            $('#hdnValidInput_' + this.teamMemberRoleName + '_' + this.currentTeamMemberIndex).val(true);
          }
          this.newTeamMemberList[this.currentTeamMemberIndex].userName = selectedMemberName;
          this.newTeamMemberList[this.currentTeamMemberIndex].memberEmailId = selectedMemberEmail;
        }

        this.newTeamMemberList[this.currentTeamMemberIndex].validateUser = true;

        $('#modal-TeamMember').modal('hide');
      }
      else {
        $("#TeamMemberErrorMessage").text('Selected team member is already added');
      }
    }
    if (countofAddedTM == 0) {
      this.membersListArrayForTeamMembers = null;
    }
  }

  deselectTeamMember() {
    $('#' + this.teamMemberCtrl).val('');
    $('#' + this.teamMemberCtrl).innerText = '';
    $('#' + this.teamMemberEmailCtrl).val('');
    $('#' + this.teamMemberEmailCtrl).innerText = '';
    this.membersListArrayForTeamMembers = null;
  }

  removeNewTeamMember(index: number, teamMemberRole: string) {
    this.newTeamMemberList.splice(index, 1);
    this.arrValidationForNewTeamMember.splice(index, 1);
    this.removeTeamMemeber(index, teamMemberRole);
    this._alertService.remove(this.alert);
    return true;
  }
  removeTeamMemeber(index: number, teamMemberRole: string) {
    //Renaming the rest of the team member Peoplepicker fields after deleting the first row 
    //start
    const rows = this.elementRef.nativeElement.querySelectorAll('.teammemberDataRow');
    if (rows.length > 0) {
      const rowToRemove = rows[index];
      this.renderer.removeChild(this.elementRef.nativeElement, rowToRemove);
      for (let i = index; i < rows.length - 1; i++) {
        const row = rows[i + 1];
        const txtPeoplePicker = row.querySelector('input[type="text"]:first-child');
        const hdnValidInput = row.querySelector('input[type="hidden"][id^="hdnValidInput_' + teamMemberRole + '_"]');
        const hint = row.querySelector('span[id^="hint_' + teamMemberRole + '_"]');

        const loadericon = row.querySelector('em');
        const searchUserList = row.querySelector('ul');
        const UserHiddenProps = row.querySelector('div:nth-child(4)');

        if (hdnValidInput) {
          const newId = 'hdnValidInput_' + teamMemberRole + '_' + i;
          hdnValidInput.setAttribute('id', newId);
        }
        txtPeoplePicker.id = 'txtPeoplePicker_' + teamMemberRole + '_' + i;
        loadericon.id = 'loadericon_' + teamMemberRole + '_' + i;
        searchUserList.id = 'searchUserList_' + teamMemberRole + '_' + i;
        hint.id = 'hint_' + teamMemberRole + '_' + i;

        if (UserHiddenProps) {
          const divId = UserHiddenProps.getAttribute('id');
          if (divId) {
            const newDivId = divId.replace(/_\d+$/, '_' + i);
            UserHiddenProps.setAttribute('id', newDivId);
          }
        }
        const errorDivs = row.querySelectorAll('div.error-color');
        for (let j = 0; j < errorDivs.length; j++) {
          const errorDiv = errorDivs[j];
          const errorDivId = errorDiv.getAttribute('id');
          if (errorDivId) {
            const newErrorDivId = errorDivId.replace(/_\d+$/, '_' + i);
            errorDiv.setAttribute('id', newErrorDivId);
          }
        }

      }
    }
    //End
  }



  removeCollabTeamMember(collabIndex: number, memberIndex: number) {
    if (collabIndex > -1 && memberIndex > -1) {
      this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers.splice(memberIndex, 1);
    }
  }

  removeClientNewTeamMember(index) {
    this.newClientTeamMemberList.splice(index, 1);
    this.arrValidationForNewClientTeamMember.splice(index, 1);
    this.arrValidationForNewClientTeamMember[index].isInvalidClientTeamMemberEmail = false;
    this._alertService.remove(this.alert);
    return true;
  }

  removeCollabClientTeamMember(collabIndex: number, clientMemberIndex: number) {
    if (collabIndex > -1 && clientMemberIndex > -1) {
      this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers.splice(clientMemberIndex, 1);
    }
  }

  CallUpdatePermissions(updatePermissionsModeList: UpdatePermissionsModel[]) {
    let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
    updatePermissionsModelData.updatePermissionsModel = updatePermissionsModeList;
    this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
      result => {
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }


  setTeamMemberValue() {
    this.indusDisplayUserName = '';
    this.indusDisplayUserEmail = '';
    this.teamMemberDisplayUserName = '';
    this.teamMemberDisplayUserEmail = '';
  }

  addTeamMember(index: number, siteId: number) {

    this.indusDisplayUserName = '';
    this.indusDisplayUserEmail = '';
    this.teamMemberDisplayUserName = '';
    this.teamMemberDisplayUserEmail = '';

    if (this.newTeamMemberList != null) {
      this._alertService.remove(this.alert);
      this.newTeamMember = {
        collaborationSiteMembersId: 0,
        collaborationSiteId: siteId,
        userName: '',
        memberEmailId: '',
        memberRoleId: this.defaultRoleid,
        groupName: '',
        jobtitle: '',
        serviceLine: '',
        memberPermissionId: this.defaultPermissionId,
        validateUser: false,
        keyMemberRoleId: this.defaultKeyRoleid,
        isKeyTeamMember: false,
        description: ''
      }
      this.newTeamMemberList.push(this.newTeamMember);
      this.collaborationSiteViewModelList[index].newTeamMemberList = this.newTeamMemberList;
      console.log(JSON.stringify(this.newTeamMemberList));
      const ValidNewTeamMember = {
        memberEmailId: '',
        userName: '',
        memberRoleId: 0,
        memeberContactNo: '',
        approvalStatus: '',
        memberPermissionId: 0,
        ishdnValidInputName: true,
        collaborationSiteId: 0,
        isNewUserDuplicate: false,
        isValidInput: true,
        cmsDataMatch: false

      };
      this.arrValidationForNewTeamMember.push(ValidNewTeamMember);
      console.log('arrValidationForNewTeamMember', this.arrValidationForNewTeamMember);
    }
  }

  addClientTeamMember(index: number, siteId: number) {
    if (this.newClientTeamMemberList != null) {
      this._alertService.remove(this.alert);
      this.newClientTeamMember = {
        engagementId: this.engagementId,
        collaborationSiteId: siteId,
        firstName: '',
        lastName: '',
        emailId: '',
        title: '',
        permissionId: this.defaultClientPermissionId
      };

      this.newClientTeamMemberList.push(this.newClientTeamMember);
      this.collaborationSiteViewModelList[index].newClientTeamMemberList = this.newClientTeamMemberList;
      const ValidNewClientTeamMember = {
        firstName: '',
        lastName: '',
        emailId: '',
        title: '',
        permissionId: 0,
        collaborationSiteId: 0,
        isNewUserDuplicate: false,
        isInvalidClientTeamMemberEmail: false,
        cmsDataMatchClient: false
      };
      this.arrValidationForNewClientTeamMember.push(ValidNewClientTeamMember);
    }
    console.log('this.collaborationSiteViewModelList ==>', this.collaborationSiteViewModelList);
  }

  addCollaborationSite() {
    this.newCollaborationSiteName = null;
    this.isNewCollaborationSpace = true;
    this.TeamMemberManipulationTrigger = false;
    this.invalidvaluesCN = null;
  } // end addCollaborationSite method


  DecisionTemplateApiLogs(isSuccess: boolean, currentWorkspaceId: string, request: DecisionsTemplateRequestModel, response: DecisionsTemplateResponseModel) {
    var decisionRPListModel: DecisionsRPListModel = new DecisionsRPListModel();
    decisionRPListModel.isSuccess = isSuccess;
    decisionRPListModel.workspaceId = currentWorkspaceId;
    decisionRPListModel.decisionsApiLogModel.workspaceId = currentWorkspaceId;
    decisionRPListModel.decisionsApiLogModel.loggedInUser = this.userObject.loggedInUserEmail;
    decisionRPListModel.decisionsApiLogModel.requestType = DecisionsRequestTypes.TemplateDetermination;
    decisionRPListModel.decisionsApiLogModel.requestPayload = JSON.stringify(request);
    decisionRPListModel.decisionsApiLogModel.responseBody = JSON.stringify(response);

    this.workspaceService.SaveDecisionDetails(decisionRPListModel).subscribe(count => {
      if (+count > 0) {
        console.log('Save Decision Details captured successfully !');
      }
    },
      err => {
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      })
  }

  saveCollaborationSite(newCollaborationSiteName: string) {
    this.TeamMemberManipulationTrigger = false;
    this.isNewSiteNameRequired = false;
    this.isNewSiteNameDuplicate = false;
    this.isNewSiteNameValid = true;
    if (newCollaborationSiteName != null && newCollaborationSiteName.trim() === '') {
      this.isNewSiteNameRequired = true;
    }
    else if (newCollaborationSiteName === '' || newCollaborationSiteName === null || newCollaborationSiteName === undefined) {
      this.isNewSiteNameRequired = true;
    } else if (!(this.validateAssignmentName(newCollaborationSiteName))) {
      this.isNewSiteNameValid = false;
    }
    else if (!(this.CheckDuplicateCollaborationSiteName(newCollaborationSiteName))) {
      this.isNewSiteNameRequired = false;
      this.isNewSiteNameDuplicate = false;
      this.isNewCollaborationSpace = false;
      const newCollaborationSite = new CollaborationSite();
      newCollaborationSite.collaborationSiteName = newCollaborationSiteName;
      newCollaborationSite.clientId = this.engagementList.clientId;
      newCollaborationSite.engagementId = this.engagementList.engagementId;
      this.loadingScreenService.startLoading();
      this.engagementService.addEngagementCollaboration(newCollaborationSite).subscribe(
        result => {
          if (result === 'Success') {
            this.engagementService.GetEngagementCollaborationSites(this.engagementList.engagementId).subscribe(
              collabsites => {
                this.collaborationSiteList = collabsites;
                this.newCollabrationCount = this.collaborationSiteList.filter(site => site.siteURL == null).length;
                if (((this.engagementList.status === EngagementStatus.EngActive ||
                  this.engagementList.status === 'Active (pending CMS codes)' ||
                  this.engagementList.status === EngagementStatus.EngActivePendingSignature)
                  && this.engagementAssignmentList.length === 1) ||
                  !(this.engagementList.status === EngagementStatus.EngActive ||
                    this.engagementList.status === 'Active (pending CMS codes)' ||
                    this.engagementList.status === EngagementStatus.EngActivePendingSignature
                  )
                ) {
                  this.isDisableAddCollaborationSite = true;
                } else {
                  if (this.newCollabrationCount > 0) {
                    this.isDisableAddCollaborationSite = true;
                  }
                  else {
                    this.isDisableAddCollaborationSite = false;
                  }
                }
                this.CreateUIForCollaborationSpace();
                setTimeout(() => {
                  this.enableDraggable(false);
                }, 200);
              }
            );
            this.newCollaborationSiteName = null;
          }

        });
    }
    this.loadingScreenService.stopLoading();
  } // end saveCollaborationSite method

  UpdateCollaborationSiteIndus(siteId: number) {
    $('#INDUS-update-success').addClass('d-none');

    console.log('collaborationSiteViewModelList==>', this.collaborationSiteViewModelList);
    console.log('currentCollabModel==>', this.currentCollabModel)

    // If Original value and update are different then procced for DB updation ---
    // collaborationSiteViewModelList -- ORIGINAL LIST
    // currentCollabModel-  CURENT MODEL LIST
    if (this.collaborationSiteViewModelList.find(collbSite =>
      collbSite.collaborationSite.collaborationSiteId === siteId).collaborationSite.isIndus !=
      this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {

      let collaborationSiteIndusModel: CollaborationSiteIndusModel = new CollaborationSiteIndusModel();
      collaborationSiteIndusModel.collaborationSiteId = this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteId
      collaborationSiteIndusModel.isIndus = this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus;

      console.log('collaborationSiteIndusModel', this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite);

      this.engagementService.updateCollaborationSiteIndus(collaborationSiteIndusModel).subscribe(
        result => {
          if (result == 'Success') {
            console.log('Site Indus updated successfully');
            /// Send Email Notification to Engagement EPPMD
            // On Result assign INDUS flag value to main model this.collaborationSiteViewModelList
            this.collaborationSiteViewModelList.find(collbSite =>
              collbSite.collaborationSite.collaborationSiteId === siteId).collaborationSite.isIndus =
              this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus;

            $('#INDUS-update-success').removeClass('d-none');
          }
          else {
            console.log('update Failed');
          }
        },
        error => {
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    }
  }

  removeCollaborationSpace() {
    this.isNewCollaborationSpace = false;
    this.TeamMemberManipulationTrigger = false;
    this.isNewSiteNameRequired = false;
    this.isNewSiteNameDuplicate = false;
    this.invalidvaluesCN = "";
  }

  updateAllCollaborationWithAssignment() {
    this.loadingScreenService.startLoading();
    this._alertService.remove(this.alert);
    const collabDiv = $('#collaborationSiteList');
    const collabs = $(collabDiv).children();
    const originalTeam = this.collaborationSiteMembersList;
    const originalClientTeam = this.clientTeamMemberList;
    let collaborationAssignment: CollaborationAssignment;
    let collaborationSiteTeamMember: CollaborationSiteTeamMember;
    let collaborationClientTeamMember: CollaborationClientTeamMember;
    const updatedAssignmentMembersViewModel: UpdatedAssignmentMembersViewModel = new UpdatedAssignmentMembersViewModel();
    updatedAssignmentMembersViewModel.collaborationAssignments = [];
    updatedAssignmentMembersViewModel.collaborationClientTeamMembers = [];
    updatedAssignmentMembersViewModel.collaborationSiteTeamMembers = [];

    this.sharepointSiteAndUpdateSharePointMetadataModel.sharepointSiteArr = [];
    this.sharepointSiteAndUpdateSharePointMetadataModel.sharePointWrapperArr = [];
    this.sharepointSiteArr = [];
    this.sharePointWrapperArr = [];

    let isCollabExitForAssignment = false;
    let isCollabExitForTeamMember = false;
    let isCollabExitForClientTeamMember = false;
    let canAddMember = false;
    let canAddClientMember = false;
    let LastMemberDestCollabId = undefined;
    let LastClientMemberDestCollabId = undefined;
    let prevDestCollabId = 0;
    let nextDestCollabId = 0;
    for (let i = 0; i < collabs.length; i++) {
      let collabAssignments = $($(collabs[i]).children()).closest('.div-sortable').children().closest('.assignmentsection').find('.hdnValues');
      const DestCollabId = $('#hdnCollaborationId' + i).val();

      if (collabAssignments !== undefined && collabAssignments.length > 0) {

        for (let c = 0; c < collabAssignments.length; c++) {
          const hiddenvalues = $(collabAssignments[c]).children();
          const collabId = hiddenvalues[0].value;
          const assignId = hiddenvalues[1].value;

          if (assignId > 0 && collabId > 0) {
            isCollabExitForAssignment = true;
          } else {
            isCollabExitForAssignment = false;
          }
          collaborationAssignment = {
            SourceCollaborationSiteId: collabId,
            AssignmentCodeId: assignId,
            DestinationCollaborationSiteId: DestCollabId,
            isAssignmentExit: isCollabExitForAssignment,
            updatedBy: this.userObject.userName
          };
          updatedAssignmentMembersViewModel.collaborationAssignments.push(collaborationAssignment);
        } // end for loop collabAssignments.length
      } // end if collabAssignments.length > 0
    } // end for loop collabs ui list.

    // team details independent computation.
    if (updatedAssignmentMembersViewModel.collaborationAssignments.length > 0) {
      for (let a = 0; a < updatedAssignmentMembersViewModel.collaborationAssignments.length; a++) {
        let curAssignmentSourceCollabId = updatedAssignmentMembersViewModel.collaborationAssignments[a].SourceCollaborationSiteId;
        let curAssignmentDestCollabId = updatedAssignmentMembersViewModel.collaborationAssignments[a].DestinationCollaborationSiteId;
        if (prevDestCollabId == 0 && nextDestCollabId == 0) {
          nextDestCollabId = curAssignmentDestCollabId;
          prevDestCollabId = curAssignmentDestCollabId;
        } else {
          nextDestCollabId = curAssignmentDestCollabId;
        }
        //  if (teamcollabId > 0 && teamMemberId > 0 && memberName !== '' && memberName !== undefined) {
        // Before updating in viewmodel do check duplicate member if any present for other collaboration.
        if (updatedAssignmentMembersViewModel.collaborationSiteTeamMembers.length > 0) {
          LastMemberDestCollabId = updatedAssignmentMembersViewModel.collaborationSiteTeamMembers.find(
            e => e.DestinationCollaborationSiteId === curAssignmentDestCollabId);

          if (LastMemberDestCollabId !== undefined) {
            prevDestCollabId = curAssignmentDestCollabId;
          }

        }

        if (updatedAssignmentMembersViewModel.collaborationClientTeamMembers.length > 0) {
          LastClientMemberDestCollabId = updatedAssignmentMembersViewModel.collaborationClientTeamMembers.find(e => e.DestinationCollaborationSiteId === curAssignmentDestCollabId);
          if (LastClientMemberDestCollabId !== undefined) {
            prevDestCollabId = curAssignmentDestCollabId;
          }
        }

        if (LastMemberDestCollabId == undefined) {

          for (let t = 0; t < originalTeam.length; t++) {

            if (originalTeam[t].collaborationSiteId == curAssignmentSourceCollabId) {

              if (!this.CheckDuplicateElementInCollaboration(this.collaborationSiteMembersList, 'memberEmailId', originalTeam[t].memberEmailId,
                'collaborationSiteId', originalTeam[t].collaborationSiteId)) {
                canAddMember = true;
                if (prevDestCollabId != nextDestCollabId) {
                  isCollabExitForTeamMember = false;
                } else {
                  isCollabExitForTeamMember = true;
                }
              } else {
                canAddMember = false;
              }
              if (canAddMember) {
                collaborationSiteTeamMember = {
                  SourceCollaborationSiteId: curAssignmentSourceCollabId,
                  collaborationSiteMembersId: originalTeam[t].collaborationSiteMembersId,
                  memberName: originalTeam[t].memberName,
                  memberEmailId: originalTeam[t].memberEmailId,
                  memberPermissionId: originalTeam[t].memberPermissionId,
                  DestinationCollaborationSiteId: curAssignmentDestCollabId,
                  isMemberExit: isCollabExitForTeamMember,
                  memberRoleId: originalTeam[t].memberRoleId
                };
                updatedAssignmentMembersViewModel.collaborationSiteTeamMembers.push(collaborationSiteTeamMember);
              } // end if canAddMember=true
            }// compare source collabid of given assignment and team member.
          } // end for loop this.teamMemberList.length
        } // end if LastMemberDestCollabId == undefined

        if (LastClientMemberDestCollabId == undefined) {
          for (let t = 0; t < originalClientTeam.length; t++) {
            if (originalClientTeam[t].collaborationSiteId == curAssignmentSourceCollabId) {

              if (!this.CheckDuplicateElementInCollaboration(this.clientTeamMemberList, 'emailId', originalClientTeam[t].emailId,
                'collaborationSiteId', originalClientTeam[t].collaborationSiteId)) {
                canAddClientMember = true;
                if (prevDestCollabId != nextDestCollabId) {
                  isCollabExitForClientTeamMember = false;
                } else {
                  isCollabExitForClientTeamMember = true;
                }
              } else {
                canAddClientMember = false;
              }
              if (canAddClientMember) {

                collaborationClientTeamMember = {
                  SourceCollaborationSiteId: curAssignmentSourceCollabId,
                  ClientTeamMemberId: originalClientTeam[t].id,
                  FirstName: originalClientTeam[t].firstName,
                  LastName: originalClientTeam[t].lastName,
                  ClientMemberEmail: originalClientTeam[t].emailId,
                  Title: originalClientTeam[t].title,
                  PermissionId: originalClientTeam[t].permissionId,
                  DestinationCollaborationSiteId: curAssignmentDestCollabId,
                  isMemberExit: isCollabExitForClientTeamMember,
                  updatedBy: this.userObject.userName
                };
                updatedAssignmentMembersViewModel.collaborationClientTeamMembers.push(collaborationClientTeamMember);
              } // end if canAddClientMember=true
            }// compare source collabid of given assignment and team member.
          } // end for loop this.clientTeamMemberList.length
        } // end if LastClientMemberDestCollabId == undefined

      } // end for loop updatedAssignmentMembersViewModel.collaborationAssignments.length

    } // end if updatedAssignmentMembersViewModel.collaborationAssignments.length > 0

    console.log("updatedAssignmentMembersViewModel ==>", updatedAssignmentMembersViewModel);

    updatedAssignmentMembersViewModel.engagementId = this.engagementId;
    updatedAssignmentMembersViewModel.workspaceId = this.CurrentWorkspaceData.workspaceId;

    let siteInputRequestData: SiteInputRequest[] = [];
    let engagementSiteInputRequest: SiteInputRequest = new SiteInputRequest();
    engagementSiteInputRequest.siteClassification = 'engagement';
    engagementSiteInputRequest.clientCode = this.engagementList.clientCode;
    engagementSiteInputRequest.solutionCode = this.engagementList.solutionCode;
    siteInputRequestData.push(engagementSiteInputRequest);

    let clientSiteInputRequest: SiteInputRequest = new SiteInputRequest();
    clientSiteInputRequest.siteClassification = 'client';
    clientSiteInputRequest.clientCode = this.engagementList.clientCode;
    clientSiteInputRequest.solutionCode = this.engagementList.solutionCode;
    siteInputRequestData.push(clientSiteInputRequest);

    let decisionsTemplateRequest: DecisionsTemplateRequestModel = new DecisionsTemplateRequestModel();
    decisionsTemplateRequest.templateRequest = siteInputRequestData;

    let engagementDecisionTemplate: DecisionTemplate = new DecisionTemplate();
    let clientDecisionTemplate: DecisionTemplate = new DecisionTemplate();
    const decisionTemplateArr: DecisionTemplate[] = [];

    this.sharepointService.GetDecisionsTemplate(decisionsTemplateRequest)
      .subscribe(result => {
        let tempplateResponse = result;
        if (tempplateResponse.templateSelection != null && tempplateResponse.templateSelection.length > 1) {

          engagementDecisionTemplate.templateName = tempplateResponse.templateSelection[0].templateName;
          engagementDecisionTemplate.policyID = tempplateResponse.templateSelection[0].policyID;
          engagementDecisionTemplate.policyName = tempplateResponse.templateSelection[0].policyName;
          engagementDecisionTemplate.acceptanceFolderPath = tempplateResponse.templateSelection[0].acceptanceFolderPath;
          engagementDecisionTemplate.supplementalFolderPath = tempplateResponse.templateSelection[0].supplementalFolderPath;

          updatedAssignmentMembersViewModel.acceptanceFolderPath = engagementDecisionTemplate.acceptanceFolderPath;
          updatedAssignmentMembersViewModel.supplementalFolderPath = engagementDecisionTemplate.supplementalFolderPath;

          decisionTemplateArr.push(engagementDecisionTemplate);

          clientDecisionTemplate.templateName = tempplateResponse.templateSelection[1].templateName;
          clientDecisionTemplate.policyID = tempplateResponse.templateSelection[1].policyID;
          clientDecisionTemplate.policyName = tempplateResponse.templateSelection[1].policyName;
          clientDecisionTemplate.acceptanceFolderPath = tempplateResponse.templateSelection[1].acceptanceFolderPath;
          clientDecisionTemplate.supplementalFolderPath = tempplateResponse.templateSelection[1].supplementalFolderPath;

          decisionTemplateArr.push(clientDecisionTemplate);

          this.DecisionTemplateApiLogs(tempplateResponse.isSuccess, this.engagementList.workspaceId, decisionsTemplateRequest, tempplateResponse);

          this.engagementService.updateCollaborationAssignmentMembers(updatedAssignmentMembersViewModel)
            .subscribe(
              UpdatedAssignmentMembersResult => {
                if (UpdatedAssignmentMembersResult === 'Success') {
                  this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
                    .subscribe(
                      engCollabresult => {
                        this.engagementCollaborationsViewModel = engCollabresult;
                        this.applyFilterOnceAfterDataLoad = true;
                        this.engagementList = this.engagementCollaborationsViewModel.engagement;
                        if (this.engagementList.status === EngagementStatus.EngActive) {
                          this.isEngagementCloseoutDisable = false;
                        }
                        else {
                          this.isEngagementCloseoutDisable = true;
                        }
                        this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
                        this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
                        this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
                        this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
                        this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
                        this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;

                        let collaborationSiteListsiteURLNullIndex: number = 0;
                        let collaborationSiteListsiteURLNullFilter =
                          this.collaborationSiteList.filter(site => site.siteURL == "" || site.siteURL == null)

                        let collaborationSiteListsiteURLNullLength = collaborationSiteListsiteURLNullFilter.length;
                        if (collaborationSiteListsiteURLNullLength > 0) {

                          for (let i = 0; i < collaborationSiteListsiteURLNullFilter.length; i++) {

                            let availableAssignmentsWithcollaborationSite =
                              this.engagementAssignmentList.filter(assignment => assignment.collaborationSiteId ===
                                collaborationSiteListsiteURLNullFilter[i].collaborationSiteId);

                            //1. check if selected collaborationSiteList as
                            if (availableAssignmentsWithcollaborationSite !== undefined
                              && availableAssignmentsWithcollaborationSite.length > 0) {
                              let isAssignmentCodeExists: boolean = false;

                              //2. check AssignmentCode existanace
                              availableAssignmentsWithcollaborationSite.forEach(assignmenent => {
                                if (assignmenent.assignmentCode != '' && assignmenent.assignmentCode !== undefined &&
                                  assignmenent.assignmentCode !== null) {
                                  isAssignmentCodeExists = true;
                                }
                              })

                              if (isAssignmentCodeExists && (collaborationSiteListsiteURLNullFilter[i].siteURL === ""
                                || collaborationSiteListsiteURLNullFilter[i].siteURL === null)) {

                                //3. Call API for Share Point  Site Provision
                                const filterCollbMembers = this.collaborationSiteMembersList.filter(collbMember => collbMember.collaborationSiteId === collaborationSiteListsiteURLNullFilter[i].collaborationSiteId);
                                let siteMembersGroups = filterCollbMembers.reduce((r, a) => {
                                  r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                                  return r;
                                }, {});

                                console.log("siteMembersGroups", siteMembersGroups);
                                let onwwerList: CollaborationSiteMembers[] = [];
                                let memberList: CollaborationSiteMembers[] = [];

                                let siteGroupTypes = Object.keys(siteMembersGroups);

                                siteGroupTypes.forEach(type => {
                                  if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                                    onwwerList = filterCollbMembers.filter(member =>
                                      Number(member.memberPermissionId) === Number(type));
                                  } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                                    memberList = filterCollbMembers.filter(member =>
                                      Number(member.memberPermissionId) === Number(type));
                                  }
                                })

                                // Site Onwers Email List
                                let siteOwnerEmailIds: string[] = [];
                                onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                                //Site Members Email List
                                let siteMemberEmailIds: string[] = [];
                                memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                                //========================== Start Site Provision Call ================== //
                                let siteVisitorEmailIds: string[] = [];

                                this.engagementService.getMetadataList().subscribe((metadata: SPSiteMetadata[]) => {

                                  const sharepointMetadata: SPSiteMetadata[] = [];
                                  this.engagementService.getSharePointMetadataMapping(this.engagementId, collaborationSiteListsiteURLNullFilter[i].collaborationSiteId)
                                    .subscribe((engagementSharePointData: SharePointMetadataMapping) => {
                                      engagementSharePointData.propertiesMapping.forEach(item => {
                                        metadata.forEach(element => {
                                          if (item.value === element.name) {
                                            element.value = engagementSharePointData.metadata[item.key];
                                            sharepointMetadata.push(element);
                                          }
                                        });
                                      });
                                      this.sharepointSiteModel = new SharepointSiteModel();
                                      this.sharepointSiteModel.siteType = "Collabration";
                                      this.sharepointSiteModel.engagementId = collaborationSiteListsiteURLNullFilter[i].engagementId;
                                      this.sharepointSiteModel.siteUniqueID = collaborationSiteListsiteURLNullFilter[i].siteUniqueID;
                                      this.sharepointSiteModel.department = this.departmentList[0].value;
                                      this.sharepointSiteModel.siteTitle = collaborationSiteListsiteURLNullFilter[i].collaborationSiteName;
                                      this.sharepointSiteModel.primaryAdmin = this.spProvisionEmailIds.primaryAdmin;
                                      this.sharepointSiteModel.primaryContact = this.spProvisionEmailIds.primaryContact;
                                      this.sharepointSiteModel.secondaryContact = this.spProvisionEmailIds.secondaryContact;

                                      this.sharepointSiteModel.siteOwnerEmailIds = siteOwnerEmailIds;
                                      this.sharepointSiteModel.siteMemberEmailIds = siteMemberEmailIds;
                                      this.sharepointSiteModel.siteVisitorEmailIds = siteVisitorEmailIds;

                                      this.sharepointSiteModel.metadata = sharepointMetadata;
                                      this.sharepointSiteModel.clientName = this.engagementList.clientName;
                                      this.sharepointSiteModel.collaborationSiteId = collaborationSiteListsiteURLNullFilter[i].collaborationSiteId;
                                      const primaryCollaborationSite = this.collaborationSiteList.find(collabSite => collabSite.isPrimaryCollaboration == true);
                                      this.sharepointSiteModel.primarySiteUniqueId = primaryCollaborationSite.siteUniqueID;

                                      this.sharepointSiteModel.decisionTemplate = decisionTemplateArr;

                                      if (this.sharepointSiteModel.metadata.find(m => m.name === 'Site Template Name') !== null
                                        && this.sharepointSiteModel.metadata.find(m => m.name === 'Site Template Name') !== undefined) {
                                        this.sharepointSiteModel.metadata.find(m => m.name === 'Site Template Name').value = this.sharepointSiteModel.decisionTemplate[0].templateName;
                                      }
                                      console.log('Push sharepointSiteModel ==>', this.sharepointSiteModel);

                                      this.sharepointSiteArr.push(this.sharepointSiteModel);
                                      collaborationSiteListsiteURLNullIndex = collaborationSiteListsiteURLNullIndex + 1;

                                      // For SiteURL NOT NULL
                                      if (collaborationSiteListsiteURLNullIndex == collaborationSiteListsiteURLNullLength) {
                                        this.fillUpdateSharePointMetadataAndProcess();
                                      }
                                    });
                                });
                                //========================== End Site Provision Call =========
                              }
                              else {
                                collaborationSiteListsiteURLNullIndex = collaborationSiteListsiteURLNullIndex + 1;
                                // For SiteURL NOT NULL
                                if (collaborationSiteListsiteURLNullIndex == collaborationSiteListsiteURLNullLength) {
                                  this.fillUpdateSharePointMetadataAndProcess();
                                }
                              }
                            } // End if availableAssignmentsWithcollaborationSite !== undefined
                            else {
                              collaborationSiteListsiteURLNullIndex = collaborationSiteListsiteURLNullIndex + 1;
                              // For SiteURL NOT NULL
                              if (collaborationSiteListsiteURLNullIndex == collaborationSiteListsiteURLNullLength) {
                                this.fillUpdateSharePointMetadataAndProcess();
                              }
                            }

                          } //End of loop this.collaborationSiteList.length

                        }   // collaborationSiteListsiteURLNullLength  > 0
                        else // if collaborationSiteListsiteURLNullLength = 0  then ONLY UpdateSharePointMetadata FOR existing sites
                        {
                          this.fillUpdateSharePointMetadataAndProcess();
                        }

                      }
                    ) //End getEngagementCollaborationsById subscribe

                } else if (UpdatedAssignmentMembersResult === 'Failure') {
                  this.loadingScreenService.stopLoading();
                  this.alertServiceFun('Assignment of collaboration space could not be updated !', 'danger');
                }

              }, () => {
                this.loadingScreenService.stopLoading();
                this.alertServiceFun('Assignment of collaboration space could not be updated !', 'danger');
              }); // end subscribe method updateCollaborationAssignmentMembers

        } // tempplateResponse.templateSelection != null

        else { //If tempplateResponse.templateSelection is null then not procced further
          this.loadingScreenService.stopLoading();
        }
        // Call DecisionTemplateApiLogs
        this.DecisionTemplateApiLogs(tempplateResponse.isSuccess, this.engagementList.workspaceId, decisionsTemplateRequest, tempplateResponse);
      },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        }); //End GetDecisionsTemplate subscribe Call
  } // end method updateAllCollaborationWithAssignment


  // This is only for those sites haviing siteURL Not NULL i.e
  // for an existing Collbration site having Sharepoint site created then Call API UpdateSharePointMetadata
  fillUpdateSharePointMetadataAndProcess() {
    let collaborationSiteListsiteURLNOTNullIndex: number = 0;
    let collaborationSiteListsiteURLNOTNullFilter =
      this.collaborationSiteList.filter(site => site.siteURL != "" && site.siteURL != null)
    let collaborationSiteListsiteURLNOTNullLength = collaborationSiteListsiteURLNOTNullFilter.length;

    for (let j = 0; j < collaborationSiteListsiteURLNOTNullFilter.length; j++) {
      const sharepointMetadata: SPSiteMetadata[] = [];
      this.engagementService.getSharePointMetadataMapping(this.engagementId, collaborationSiteListsiteURLNOTNullFilter[j].collaborationSiteId)
        .subscribe((sharePointDbData: SharePointMetadataMapping) => {
          sharePointDbData.propertiesMapping.forEach(item => {
            this.globalMetadata.forEach(metadataElement => {
              if (item.value === metadataElement.name) {
                metadataElement.value = sharePointDbData.metadata[item.key];
                sharepointMetadata.push(metadataElement);
                console.log('sharepointMetadata for - ' + collaborationSiteListsiteURLNOTNullFilter[j].collaborationSiteId, sharepointMetadata);
              }
            });
          });
          const sharePointWrapper = new SharePointWrapper();
          sharePointWrapper.siteURL = sharePointDbData.metadata.siteUrl;
          sharePointWrapper.metadataList = sharepointMetadata;

          //PUSH sharePointWrapper INTO Array
          this.sharePointWrapperArr.push(sharePointWrapper);
          collaborationSiteListsiteURLNOTNullIndex = collaborationSiteListsiteURLNOTNullIndex + 1;

          // Call createProvisionSiteAndUpdateSharePointMetadata while last element processing
          if (collaborationSiteListsiteURLNOTNullLength != undefined &&
            collaborationSiteListsiteURLNOTNullLength != null &&
            collaborationSiteListsiteURLNOTNullIndex == collaborationSiteListsiteURLNOTNullLength) {
            this.sharepointSiteAndUpdateSharePointMetadataModel.sharepointSiteArr = this.sharepointSiteArr;
            this.sharepointSiteAndUpdateSharePointMetadataModel.sharePointWrapperArr = this.sharePointWrapperArr;
            this.createProvisionSiteAndUpdateSharePointMetadata();
          }
        });

    } //End - For Loop siteURL NOT Null
  } //End- Method

  createProvisionSiteAndUpdateSharePointMetadata() {
    let avepointResponseModelIndex: number = 0;
    this.sharepointService.CreateProvisionSiteAndUpdateSharePointMetadata(this.sharepointSiteAndUpdateSharePointMetadataModel).subscribe(
      result => {
        this.avepointResponseDataModel = result;
        if (this.avepointResponseDataModel !== null && this.avepointResponseDataModel !== undefined) {

          let avepointResponseModelFilter = this.avepointResponseDataModel.avepointResponseArr.filter(model => model.avepointResponseArray != null)

          if (avepointResponseModelFilter !== undefined && avepointResponseModelFilter.length > 0) {
            avepointResponseModelFilter.forEach(avepointResponseModel => {
              avepointResponseModelIndex = avepointResponseModelIndex + 1;
              if (avepointResponseModel.responseStatus.toLowerCase() === 'success') {
                var avepointResponseListModel: AvepointResponseListModel = new AvepointResponseListModel();
                if (avepointResponseModel.avepointResponseArray != null && avepointResponseModel.avepointResponseArray.length != 0) {
                  avepointResponseListModel.avepointResponseArray = avepointResponseModel.avepointResponseArray;
                  this.engagementService.updateEngagementSiteRequestData(avepointResponseListModel)
                    .subscribe(
                      updateResult => {
                        console.log('updateEngagementSiteRequestData', updateResult);

                      },
                      error => {
                        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                      });
                }
              }
              else {
                //Error while creating site provision
                // this.loadingScreenService.stopLoading();
                this._alertService.add(avepointResponseModel.errorMessage + ' Please contact system administrator.', 'danger', null);
              }

              // Call GetEngagementCollaborationSites while last element processing
              setTimeout(() => {
                if (avepointResponseModelIndex == (avepointResponseModelFilter.length)) {
                  this.engagementService.GetEngagementCollaborationSites(this.engagementList.engagementId).
                    subscribe(
                      collabsites => {
                        this.collaborationSiteList = collabsites;
                        this.newCollabrationCount = this.collaborationSiteList.filter(site => site.siteURL == null).length;
                        if (((this.engagementList.status === EngagementStatus.EngActive ||
                          this.engagementList.status === 'Active (pending CMS codes)' ||
                          this.engagementList.status === EngagementStatus.EngActivePendingSignature)
                          && this.engagementAssignmentList.length === 1) ||
                          !(this.engagementList.status === EngagementStatus.EngActive ||
                            this.engagementList.status === 'Active (pending CMS codes)' ||
                            this.engagementList.status === EngagementStatus.EngActivePendingSignature
                          )
                        ) {
                          this.isDisableAddCollaborationSite = true;
                        } else {
                          if (this.newCollabrationCount > 0) {
                            this.isDisableAddCollaborationSite = true;
                          }
                          else {
                            this.isDisableAddCollaborationSite = false;
                          }
                        }
                        this.CreateUIForCollaborationSpace();
                        this.loadingScreenService.stopLoading();
                      });
                }
              }, 4000);
            }
            ); //End of Foor loop
          }
          else {
            this.loadingScreenService.stopLoading();
          }
        } //this.avepointResponseDataModel !== null
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  enableDraggable(tabClick: boolean) {
    $('.div-sortable').sortable({
      placeholderClass: 'list-group-item',
      connectWith: '.connected'
    });
    setTimeout(() => {
      // check assignment count per collaboration then disable drag-drop behavior when count is 1.
      // when count > 1 then check other conditions for disabling drag-drop behavior.
      const collabDiv = $('#collaborationSiteList');
      const collabs = $(collabDiv).children();
      let makeDisable = false;
      let assignmentCode = null;
      for (let i = 0; i < collabs.length; i++) {
        let collabAssignments = $($(collabs[i]).children()).closest('.div-sortable').children().closest('.assignmentsection');
        let noAssignmentLabel = $($(collabs[i]).children()).closest('.div-sortable').children().closest('.noAssignment');
        if (noAssignmentLabel !== undefined && noAssignmentLabel.length > 0) {
          $(noAssignmentLabel).attr('draggable', 'false');
        }
        console.log('collabAssignments=>', collabAssignments);
        if (collabAssignments !== undefined && collabAssignments.length > 0) {
          if (collabAssignments.length === 1) {
            $(collabAssignments).attr('draggable', 'false');
          } else if (collabAssignments.length > 1) {

            if (this.collaborationSiteList[i].isLoggedInUserPartOfCollaboration == false) {
              makeDisable = true;
            }
            if (this.isEngagementClosed) {
              makeDisable = true;
            }
            if (makeDisable) {
              for (let c = 0; c < collabAssignments.length; c++) {
                $(collabAssignments[c]).attr('draggable', 'false');
              }
            }
            else {
              for (let c = 0; c < collabAssignments.length; c++) {
                assignmentCode = $(collabAssignments[c]).find('#hdnAssignmentCode').val();

                if (!assignmentCode || assignmentCode.toString().trim().length == 0) {
                  $(collabAssignments[c]).attr('draggable', 'false');
                }
                else {
                  $(collabAssignments[c]).attr('draggable', 'true');
                  $(collabAssignments[c]).addClass('list-group-item');
                }
              }
            }
          } // end if collabAssignments.length > 1
        } // end if collabAssignments.length > 0
      } // end for loop collabs.length
      if (tabClick === true) {
        this.isPageLoad = false;
      }
    }, 500);
  }

  // Start - Restrict First assignmentsection Row for drag and drop
  DisableDragDropSingleAssignment() {
    var arrCurrentCollaboration = $('.currentCollaboration');
    var assignmentsectionDiv = $($(arrCurrentCollaboration[0]).children()).closest('.div-sortable').children().closest('.assignmentsection');

    var firstAssignmentsectionDivtlength = assignmentsectionDiv.length;
    var assignmentsectionDivChildrens = $(assignmentsectionDiv.children())
    var myDivClasses = assignmentsectionDivChildrens[1] ? assignmentsectionDivChildrens[1].classList : '';

    if (firstAssignmentsectionDivtlength == 1) {
      console.log('myDivClasses', myDivClasses);
      if (myDivClasses.contains("list-group-item")) {
        myDivClasses.remove("list-group-item");
      }
      assignmentsectionDiv[0].draggable = false;
      assignmentsectionDivChildrens.find("img").css({ "display": "none" });
      console.log('length is one', firstAssignmentsectionDivtlength);
    }
  }

  // End - Restrict First assignmentsection Row for drag and drop
  switchTabPanelIcons(tabname: string) {
    this.activeTabName = tabname;
    this.resetReviewResponseData();
    // activeTab = $('#engsummarytabs ul>li.nav-item >a.active ').attr('href');
    if (this.activeTabName.includes('acceptance')) {
      this.isSelectedAcceptanceImg = true;
      this.isSelectedCollabImg = false;
      this.isSelectedAssignmentImg = false;
      this.isSelectedEngCloseoutCheckListImg = false;
      this.isSelectedExecutionDocumentsImg = false;
      this.isSelectedConsentImg = false;
      if (this.workspaceDocumentList.length > 0) {
        this.selectedWorkspaceDocumentItem = this.workspaceDocumentList[0];
        this.ValidateHdTemplateCache('acceptance');
      }
      this.QuestionSummaryDisplay();
      this.setCLMGoverningAgrementNameStatus();
    } else if (this.activeTabName.includes('collaborations')) {
      this.isSelectedCollabImg = true;
      this.isSelectedAcceptanceImg = false;
      this.isSelectedAssignmentImg = false;
      this.isSelectedEngCloseoutCheckListImg = false;
      this.isSelectedExecutionDocumentsImg = false;
      this.isSelectedConsentImg = false;
    } else if (this.activeTabName.includes('assignments')) {
      this.isSelectedAssignmentImg = true;
      this.isSelectedCollabImg = false;
      this.isSelectedAcceptanceImg = false;
      this.isSelectedEngCloseoutCheckListImg = false;
      this.isSelectedExecutionDocumentsImg = false;
      this.isSelectedConsentImg = false;
    } else if (this.activeTabName.includes('engCloseoutCheckList')) {
      this.isSelectedEngCloseoutCheckListImg = true;
      this.isSelectedAssignmentImg = false;
      this.isSelectedCollabImg = false;
      this.isSelectedAcceptanceImg = false;
      this.anyPendingEngagementCloseoutRequiredTask = false;
      this.checkPendingRequiredTaskOnCloseOutClick = false;
      this.isSelectedExecutionDocumentsImg = false;
      this.isSelectedConsentImg = false;
      this.resetEngagementClosureRequiredTasksSection();
      this.getEngagementCloseoutConfigurations();
    } else if (this.activeTabName.includes('executionDocuments')) {
      this.isSelectedCollabImg = false;
      this.isSelectedAcceptanceImg = false;
      this.isSelectedAssignmentImg = false;
      this.isSelectedEngCloseoutCheckListImg = false;
      this.isSelectedExecutionDocumentsImg = true;
      this.isSelectedConsentImg = false;
      if (this.executionWorkspaceDocumentList.length > 0) {
        this.selectedSecondaryWorkspaceDocument = this.executionWorkspaceDocumentList[0];
        this.selectedExeWorkspaceDocumentEorkspaceId = this.selectedSecondaryWorkspaceDocument.workspaceId;
        this.ValidateHdTemplateCache('executionDocuments');
      }
      this.exeQuestionSummaryDisplay();
    }
    else if (this.activeTabName.includes('consent')) {
      this.isSelectedCollabImg = false;
      this.isSelectedAcceptanceImg = false;
      this.isSelectedAssignmentImg = false;
      this.isSelectedEngCloseoutCheckListImg = false;
      this.isSelectedExecutionDocumentsImg = false;
      this.isSelectedConsentImg = true;
      this.getClientConsentOptions('');
      this.GetClientConsentFlagByEngId();
    }
  }
  // end collaboration section

  resetReviewResponseData() {
    this.engDetailWorkItemId = null;
    this.engDetailWorkItemVersionId = null;
    this.engDetailOriginalworkItemIdForReviewResponse = null;
    this.engDetailOriginalworkItemVersionIdReviewResponse = null;
  }

  // start assignment tab section
  objectInitialization() {
    const sc = new SecondarySolutionCode();
    sc.secondarySolutionCodeId = 0;
    sc.parentSolutionCodeId = 0;
    sc.childSolutionCodeId = 0;
    sc.solutionCode = '';
    sc.solutionCodeDescription = '';
    sc.solutionCodeLongName = '';
    sc.status = 'A';
    sc.isAudit = false;
    sc.createdBy = null;
    sc.createdDate = new Date();
    sc.lastModifiedBy = null;
    sc.lastModifiedDate = null;

    this.arrSecondarySolutionCode.push(sc);
    const defaultSelectedsolutionCode = this.secondarySolutionCodeList.find(element =>
      element.childSolutionCodeId == this.engagementList.parentSolutionCodeId);
    const defaultSelectedengagementType = new KeyValue();
    defaultSelectedengagementType.key = '';
    defaultSelectedengagementType.value = '';

    const defaultSelectedClientBillingTitle = new ContactTypes();
    defaultSelectedClientBillingTitle.id = 0;
    defaultSelectedClientBillingTitle.typeValue = '';

    this.defaultEngAssignment = {
      solutionCodeListData: this.arrSecondarySolutionCode,
      iscontingentData: this.contingentkeyValueList,
      engagementTypeData: this.engtypekeyValueList,
      selectedsolutionCode: defaultSelectedsolutionCode,
      selectedengagementType: defaultSelectedengagementType,
      assignmentCodeId: 0,
      engagementId: 0,
      solutionCodeId: 0,
      assignmentName: '',
      realization: null,
      netFees: null,
      createdBy: '',
      createdDate: new Date(),
      lastModifiedBy: '',
      lastModifiedDate: new Date(),
      assignmentAdministrator: '',
      adminEmailId: '',
      adminContactNo: '',
      cmsEPPMDName: '',
      cmsEPPMDEmailId: '',
      engagementTypeId: '0',
      isContingent: null,
      isPrimaryAssignment: false,
      netFeesInCurrencyFormat: '',
      collaborationSiteId: 0,
      engagementStartDate: null,
      engagementEndDate: null,
      officeLocationCodeId: 0,
      estimatedGrossMargin: 0,
      clientBillingContactName: '',
      clientBillingContactTitleId: 0,
      clientBillingContactEmail: '',
      collaborationSiteName: '',
      officeLocationCodeName: '',
      estimatedGrossMarginInCurrencyFormat: '',
      clientBillingContactTitle: '',
      assignmentCode: null,
      clientCode: '000',
      assignmentCodeStatus: '',
      isContinuanceCarryFrwdAssignment: false,
      solutionCodeOnSigature: false,
      priorYearAssignmentName: '',
      isGrossMarginRequire: false,
      eqcrName: '',
      eqcrEmailId: '',
      isEditEQCR: false,
      newEQCRName: '',
      newEQCREmailId: '',
      isvalidEQCR: true,
      isValidEQCRBusinessRule: true,
      isEQCRExists: false,
      solutionCode: '',
      solutionCodeDescription: '',
      isEngagementStartDateRequired: false,
      isEngagementEndDateRequired: false,
      opportunityServiceId: null,
      status: null
    };

    this.newEngAssignment = {
      solutionCodeListData: this.secondarySolutionCodeList,
      assignmentCodeId: 0,
      engagementId: 0,
      solutionCodeId: this.secondarySolutionCodeList[0].parentSolutionCodeId,
      assignmentName: '',
      assignmentAdministrator: '',
      realization: null,
      netFees: null,
      createdBy: '',
      createdDate: '',
      lastModifiedBy: '',
      lastModifiedDate: ''
    };
    this.defaultEngAssignment.solutionCodeListData = this.secondarySolutionCodeList;
    this.defaultEngAssignment.solutionCodeId = this.secondarySolutionCodeList[0].parentSolutionCodeId;
    console.log("this.defaultEngAssignment", this.defaultEngAssignment);
    this.controlInitialization();
  }

  InitializeMasterDataForAssignmentSecion(source: string) {
    this.strdefaultcontingentkeyValues = [
      {
        key: true,
        value: 'Yes'
      },
      {
        key: false,
        value: 'No'
      }
    ];
    this.contingentkeyValueList = this.strdefaultcontingentkeyValues;

    this._configService.GetListBySource(source).subscribe(
      result => {
        this.engtypekeyValueList = result;
        console.log('GetListBySource == >', this.engtypekeyValueList);
      }
    );
  }
  invalidCharacterDetectionAssignmentName() {
    this.invalidvaluesAN = "";
    var value = this.defaultEngAssignment.assignmentName;
    const validvalues = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890&-_()',. ";
    for (let char of value) {
      if (validvalues.indexOf(char) === -1 && this.invalidvaluesAN.indexOf(char) === -1) {
        this.invalidvaluesAN += char;
      }
    }
  }

  invalidCharacterDetectionEngagementName() {
    this.invalidvalues = "";
    var value = this.updatedEngName;
    const validvalues = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890&-_()',. ";
    for (let char of value) {
      if (validvalues.indexOf(char) === -1 && this.invalidvalues.indexOf(char) === -1) {
        this.invalidvalues += char;
      }
    }
  }
  invalidCharacterDetectionCollaborationSiteName(callingFrom: string) {
    this.invalidvaluesCN = "";
    var value = '';
    switch (callingFrom) {
      case 'Add':
        value = this.newCollaborationSiteName;
        break;
      case 'Edit':
        value = this.UpdatedCollabSpaceName;
        break;
      default:
        value = this.newCollaborationSiteName;
        break;
    }
    const validvalues = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890&-_()',. ";
    for (let char of value) {
      if (validvalues.indexOf(char) === -1 && this.invalidvaluesCN.indexOf(char) === -1) {
        this.invalidvaluesCN += char;
      }
    }
  }


  mandatoryFieldInitialization() {
    this.arrControlValidation = [];
    this.arrControlValidation.push(new FieldValidator('assignmentName', this.defaultEngAssignment.assignmentName, false));
    this.arrControlValidation.push(new FieldValidator('solutionCodeId', this.defaultEngAssignment.solutionCodeId, false));
    this.arrControlValidation.push(new FieldValidator('selectedengagementType',
      this.defaultEngAssignment.selectedengagementType.value, false));
    this.arrControlValidation.push(new FieldValidator('realization', this.defaultEngAssignment.realization, false));
    this.arrControlValidation.push(new FieldValidator('netFees', this.defaultEngAssignment.netFees, false));
    this.arrControlValidation.push(new FieldValidator('isContingent', this.defaultEngAssignment.isContingent, false));
    this.arrControlValidation.push(new FieldValidator('cmsEPPMDName', this.defaultEngAssignment.cmsEPPMDName, false));
    this.arrControlValidation.push(new FieldValidator('assignmentAdministrator', this.defaultEngAssignment.assignmentAdministrator, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingContactName', this.defaultEngAssignment.clientBillingContactName, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingContactTitleId', this.defaultEngAssignment.clientBillingContactTitleId, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingContactEmail', this.defaultEngAssignment.clientBillingContactEmail, false));
    this.arrControlValidation.push(new FieldValidator('collaborationSiteId', this.defaultEngAssignment.collaborationSiteId, false));
    this.arrControlValidation.push(new FieldValidator('estimatedGrossMargin', this.defaultEngAssignment.estimatedGrossMargin, false));
    this.arrControlValidation.push(new FieldValidator('engagementStartDate', this.defaultEngAssignment.engagementStartDate, false));
    this.arrControlValidation.push(new FieldValidator('engagementEndDate', this.defaultEngAssignment.engagementEndDate, false));
  }

  controlInitialization() {
    this.isvalidAassignmentAdministrator = true;
    this.isvalidSignatureHandler = true;
    this.defaultEngAssignment.cmsEPPMDName = $('#txtPeoplePicker_AS_EP_0').val();
    this.defaultEngAssignment.cmsEPPMDEmailId = $('#UserHiddenProps_AS_EP_0 #hdnUserEmail').val();
    this.defaultEngAssignment.isPrimaryAssignment = false;
    this.defaultEngAssignment.netFees = null;
    this.defaultEngAssignment.realization = null;
    this.defaultEngAssignment.engagementTypeId = '0';
    const defaultengagementType = new KeyValue();
    defaultengagementType.key = '';
    defaultengagementType.value = '';
    this.defaultEngAssignment.selectedengagementType = defaultengagementType;
    this.defaultEngAssignment.isContingent = null;
    this.defaultEngAssignment.cmsEPPMDName = ''
    this.defaultEngAssignment.cmsEPPMDEmailId = ''
    this.defaultEngAssignment.adminContactNo = '';
    this.defaultEngAssignment.assignmentAdministrator = '';
    this.defaultEngAssignment.assignmentName = '';
    this.defaultEngAssignment.collaborationSiteId = null;
    this.defaultEngAssignment.engagementStartDate = null;
    this.defaultEngAssignment.engagementEndDate = null;
    this.defaultEngAssignment.officeLocationCodeId = 0;
    this.defaultEngAssignment.estimatedGrossMargin = null;
  }

  getUpdatedValue(id: string) {
    let retval: any = '';

    this.objectArray.forEach(([key, Keyvalue]) => {
      if (key == id) {
        switch (key) {
          case 'selectedengagementType':
            retval = Keyvalue.value;
            break;
          case 'selectedsolutionCode':
            retval = Keyvalue.childSolutionCodeId;
            break;
          case 'solutionCodeId':
            if (Keyvalue == null) {
              retval = Keyvalue;
            } else {
              retval = Keyvalue.value
            }
            break;
          case 'isContingent':
            if (Keyvalue == null) {
              retval = Keyvalue;
            } else {
              retval = Boolean(Keyvalue);
            }
            break;
          default:
            retval = Keyvalue;
            break;
        }
      }
    });
    return retval;
  }

  stringCompare(field1: string, field2: string) {
    let returnVal: boolean = false;

    if (field1 == field2) {
      returnVal = true;
    }
    return returnVal;
  }
  validateConfirmEmail(clientEmailId: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(clientEmailId)) {
      return true;
    } else {
      return false;
    }
  }
  checkValidEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(email))
      return true;
    else
      return false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkSpcialChar(event,) {
    if (!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57))) {
      return false;
    }
    return true;
  }

  checkSpaceChartBegin(event) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (event.target.id === 'txtAssignmentName') {
        this.defaultEngAssignment.assignmentName = null;
      } else if (event.target.id === 'txtrealization') {
        this.defaultEngAssignment.realization = null;
      } else if (event.target.id === 'txtnetFees') {
        this.defaultEngAssignment.netFees = null;
      } else if (event.target.id === 'txtGrossMargin') {
        this.defaultEngAssignment.estimatedGrossMargin = null;
      } else if (event.target.id === 'txtClientBillingName') {
        this.defaultEngAssignment.clientBillingContactName = null;
      } else if (event.target.id === 'txtClientBillingEmail') {
        this.defaultEngAssignment.clientBillingContactEmail = null;
      }
      return false;
    } else if (text.length > 0) {
      this.isNewAssignmentNameDuplicate = false;
      if (event.target.id === 'txtAssignmentName') {
        this.defaultEngAssignment.assignmentName = text;

      } else if (event.target.id === 'txtrealization') {
        this.defaultEngAssignment.realization = text;
      } else if (event.target.id === 'txtnetFees') {
        this.defaultEngAssignment.netFees = text;
      } else if (event.target.id === 'txtGrossMargin') {
        this.defaultEngAssignment.estimatedGrossMargin = text;
      } else if (event.target.id === 'txtClientBillingName') {
        this.defaultEngAssignment.clientBillingContactName = text;
      } else if (event.target.id === 'txtClientBillingEmail') {
        this.defaultEngAssignment.clientBillingContactEmail = text;
      }
    } else { return true; }
  }

  checkRealizationValue(str) {
    const regex = /^((0|[0-9]\d?)|100?)$/gm;
    if (regex.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  checkDecimalValue(str) {
    const regex = new RegExp('^[0-9]+(\.[0-9]{1,4})?$');
    if (regex.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  showHideErrorMessageDiv(flag: boolean) {
    const div = 'businessMessageForAdministratorsDiv';
    if (flag) {
      if ($('#' + div).is(':hidden')) {
        $('#' + div).show();
      }
    } else {
      if ($('#' + div).is(':visible')) {
        $('#' + div).hide();
      }
    }
  }

  checkCustomValidation() {
    if (
      this.defaultEngAssignment.isContingent === true &&
      this.defaultEngAssignment.netFees != 0) {
      this.validNetfees = false;
      this.netFeesMessage = 'Estimated net fees should be 0 for contingent assignment';
    } else {
      this.validNetfees = true;
      this.netFeesMessage = '';
    }
  }

  checkBusinessValidations() {
    let result: boolean = false;
    let startDate = new Date(this.defaultEngAssignment.engagementStartDate);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
	
    this.checkCustomValidation();
    if (this.validateAssignmentName(this.defaultEngAssignment.assignmentName)) {
      this.validAssignmentName = true;
    } else {
      this.validAssignmentName = false;
    }
    this.CheckDuplicateAssignment(this.defaultEngAssignment.assignmentName);

    if (this.checkRealizationValue(this.defaultEngAssignment.realization)) {
      this.validRealization = true;
      this.RealizationMessage = '';
    } else {
      this.validRealization = false;
      this.RealizationMessage = 'Invalid realization value';
    }

    if (this.CheckRealizationValueIsGreater(this.defaultEngAssignment.realization, this.defaultEngAssignment?.selectedsolutionCode?.solutionCode)) {
      this.validRealization = true;
      this.RealizationMessage = '';
    } else {
      this.validRealization = false;
      this.RealizationMessage = 'Realization % should not be lower than the existing primary assignment realization %';
    }

    if (this.validNetfees) {
      if (this.checkDecimalValue(this.defaultEngAssignment.netFees)) {
        this.validNetfees = true;
        this.netFeesMessage = '';
      } else {
        this.validNetfees = false;
        this.netFeesMessage = 'Invalid estimated net fees value';
      }
    }
    if ($('#txtGrossMargin').val() !== '') {
      if (this.checkRealizationValue(this.defaultEngAssignment.estimatedGrossMargin)) {
        this.validEGrossMargin = true;
        this.grossMarginMessage = '';
      } else {
        this.validEGrossMargin = false;
        this.grossMarginMessage = 'Invalid estimated gross margin value';
      }
    }
    if (!this.checkValidEmail(this.defaultEngAssignment.clientBillingContactEmail)) {
      this.isInvalidClientBillingContactEmail = true;
    } else {
      this.isInvalidClientBillingContactEmail = false;
    }
    // 1 cmsEPPMDName and assignmentAdministrator should not be the same
    if (this.stringCompare(this.defaultEngAssignment.cmsEPPMDName, this.defaultEngAssignment.assignmentAdministrator)) {
      this.showHideErrorMessageDiv(true);
      result = false;
    } else {
      this.showHideErrorMessageDiv(false);
      result = true;
    }

    // 2. EPPMDName and  AassignmentAdministrator should be valid and with Azure AD
    if (result) {
      this.isvalidcmsEPPMDName = Boolean(JSON.parse($('#hdnValidInput_AS_EP_' + this.peoplepickerIndex).val()));
      this.isvalidAassignmentAdministrator = Boolean(JSON.parse($('#hdnValidInput_AS_AA_' + this.peoplepickerIndex).val()));

      if ((!this.isvalidcmsEPPMDName) ||
        (!this.isvalidAassignmentAdministrator)
      ) {
        result = false;
      } else {
        result = true;
      }
    }

    //4.IF EQCR then check its valid and is not same as CMSEP nad AA

    this.defaultEngAssignment.isvalidEQCR = true;
    this.defaultEngAssignment.isValidEQCRBusinessRule = true;
    this.defaultEngAssignment.isEQCRExists = false;

    if (this.defaultEngAssignment.eqcrName != '' && this.defaultEngAssignment.eqcrName != null &&
      this.defaultEngAssignment.eqcrName != undefined) {
      this.defaultEngAssignment.isvalidEQCR = Boolean(JSON.parse($('#hdnValidInput_AS_EQCR_' + this.peoplepickerIndex).val()));

      if (!this.defaultEngAssignment.isvalidEQCR) {
        result = false;
      }

      if (this.stringCompare(this.engagementList.engagementPartnerName, this.defaultEngAssignment.eqcrName)
        || this.stringCompare(this.defaultEngAssignment.cmsEPPMDName, this.defaultEngAssignment.eqcrName)
        || this.stringCompare(this.defaultEngAssignment.assignmentAdministrator, this.defaultEngAssignment.eqcrName)
      ) {
        this.defaultEngAssignment.isValidEQCRBusinessRule = false;
        result = false;
      }
    }

    this.DateCompareResult = this.compareDate(new Date(this.defaultEngAssignment.engagementStartDate), new Date(this.defaultEngAssignment.engagementEndDate))

    if ((result !== this.validRealization) || (result !== this.validNetfees) || (result !== this.validEGrossMargin)) {
      result = false;
    }
    if (this.DateCompareResult === 0 || this.DateCompareResult > 0) {
      result = false;
    }
    if (this.isNewAssignmentNameDuplicate) {
      result = false;
    }
    if (!this.validAssignmentName) {
      result = false;
    }
    this.startDateCompareResult = this.compareDate(currentDate , startDate);
    if(this.startDateCompareResult > 0){
      result = false;
    }
    return result;
  }

  onSolutionCodeChange() {
    if (!this.empty(this.defaultEngAssignment.solutionCodeId)) {
      this.defaultEngAssignment.selectedsolutionCode =
        this.defaultEngAssignment.solutionCodeListData.find(element =>
          element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);
    }

    // Data prefiling for ADV029
    // if (this.defaultEngAssignment?.selectedsolutionCode?.solutionCode === "ADV029"){
    //   this.defaultEngAssignment.realization = this.appService.config.ADV_029_DATA.REALIZATION;
    //   this.defaultEngAssignment.estimatedGrossMargin = this.appService.config.ADV_029_DATA.ESTIMATED_GROSS_MARGIN;
     //   this.defaultEngAssignment.netFeesInCurrencyFormat = this.appService.config.ADV_029_DATA.ESTIMATED_NET_FEES;
    // }
    
  }
  checkMandatoryValidations(fieldValidationArray, modalValidateArray, callingFrom: string = '') {
    let retVal: boolean = true;
    this.objectArray = Object.entries(modalValidateArray);
    for (let i = 0; i < fieldValidationArray.length; i++) {

      fieldValidationArray[i].setvalues(fieldValidationArray[i].fieldId,
        this.getUpdatedValue(fieldValidationArray[i].fieldId), true);
    }

    if (this.defaultEngAssignment.selectedsolutionCode.isGrossMarginRequire) {
      if (fieldValidationArray[12].fieldId == 'estimatedGrossMargin'
        && (fieldValidationArray[12].fieldValue === null ||
          fieldValidationArray[12].fieldValue === '')) {
        this.arrControlValidation[12].isvalid = false;
        this.arrControlValidation[12].apply = true;
      }
      else {
        this.arrControlValidation[12].isvalid = true;
        this.arrControlValidation[12].apply = true;
      }
    } else {
      this.arrControlValidation[12].isvalid = true;
      this.arrControlValidation[12].apply = true;
    }

    if (callingFrom === 'engagementAssignment' && fieldValidationArray.length > 3) {  //This is only for Assignmnent
      if (fieldValidationArray[3].fieldId == 'realization' && fieldValidationArray[3].fieldValue === '0') {
        fieldValidationArray[3].isvalid = true;
      }
      if (fieldValidationArray[4].fieldId == 'netFees' && fieldValidationArray[4].fieldValue === '0') {
        fieldValidationArray[4].isvalid = true;
      }
    }
    //  //set engagement start and end date mandatory flag
    if (this.defaultEngAssignment.selectedsolutionCode.isEngagementStartDateRequired) {
      if (fieldValidationArray[13].fieldId == 'engagementStartDate'
        && (fieldValidationArray[13].fieldValue === null ||
          fieldValidationArray[13].fieldValue === undefined ||
          fieldValidationArray[13].fieldValue === '')) {

        this.arrControlValidation[13].isvalid = false;
        this.arrControlValidation[13].apply = true;
      }
      else {
        this.arrControlValidation[13].isvalid = true;
        this.arrControlValidation[13].apply = true;
      }
    } else {
      this.arrControlValidation[13].isvalid = true;
      this.arrControlValidation[13].apply = true;
    }


    if (this.defaultEngAssignment.selectedsolutionCode.isEngagementEndDateRequired) {
      if (fieldValidationArray[14].fieldId == 'engagementEndDate'
        && (fieldValidationArray[14].fieldValue === null ||
          fieldValidationArray[14].fieldValue === undefined ||
          fieldValidationArray[14].fieldValue === '')) {

        this.arrControlValidation[14].isvalid = false;
        this.arrControlValidation[14].apply = true;
      }
      else {
        this.arrControlValidation[14].isvalid = true;
        this.arrControlValidation[14].apply = true;
      }
    } else {
      this.arrControlValidation[14].isvalid = true;
      this.arrControlValidation[14].apply = true;
    }


    for (let i = 0; i < fieldValidationArray.length; i++) {
      if (!fieldValidationArray[i].isvalid) {
        retVal = false;
        break;
      }
    }
    return retVal;
  }

  empty(e) {
    switch (e) {
      case '':
      case 0:
      case '0':
      case null:
      case false:
      case typeof (e) == 'undefined':
        return true;
      default:
        return false;
    }
  }

  setAssignmentValues() {
    this.validRealization = true;
    this.validNetfees = false;
    this.validEGrossMargin = true;
    this.netFeesMessage = '';
    this.grossMarginMessage = '';
    this.RealizationMessage = '';

    this.defaultEngAssignment.solutionCodeId = $('#drpsolutionCodeId').val();
    const selectedengagementTypeId: string = $('#drpengagementTypeId').val();
    if (this.defaultEngAssignment.isContingent !== null) {
      this.defaultEngAssignment.isContingent = this.getBoolean(this.defaultEngAssignment.isContingent);
    }
    this.defaultEngAssignment.cmsEPPMDName = $('#txtPeoplePicker_AS_EP_' + this.peoplepickerIndex).val();
    this.defaultEngAssignment.cmsEPPMDEmailId = $('#UserHiddenProps_AS_EP_' + this.peoplepickerIndex + ' #hdnUserEmail').val();
    this.defaultEngAssignment.assignmentAdministrator = $('#txtPeoplePicker_AS_AA_' + this.peoplepickerIndex).val();
    this.defaultEngAssignment.adminEmailId = $('#UserHiddenProps_AS_AA_' + this.peoplepickerIndex + ' #hdnUserEmail').val();
    this.defaultEngAssignment.adminContactNo = $('#UserHiddenProps_AS_AA_' + this.peoplepickerIndex + ' #hdnUserPhone').val();
    this.defaultEngAssignment.engagementStartDate = $('.EstimatedStartDate').val();
    this.defaultEngAssignment.engagementEndDate = $('.EstimatedEndDate').val();
    this.defaultEngAssignment.eqcrName = $('#txtPeoplePicker_AS_EQCR_' + this.peoplepickerIndex).val();
    this.defaultEngAssignment.eqcrEmailId = $('#UserHiddenProps_AS_EQCR_' + this.peoplepickerIndex + ' #hdnUserEmail').val();

    if (!this.empty(this.defaultEngAssignment.solutionCodeId)) {
      this.defaultEngAssignment.selectedsolutionCode =
        this.defaultEngAssignment.solutionCodeListData.find(element =>
          element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);
    }
    else {
      this.defaultEngAssignment.solutionCodeId = null;
    }
    if (!this.empty(selectedengagementTypeId)) {
      this.defaultEngAssignment.selectedengagementType =
        this.defaultEngAssignment.engagementTypeData.find(element => element.key == selectedengagementTypeId);
    }
  }

  setAssignmentValuesBeforeSave() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
    this.defaultEngAssignment.netFeesInCurrencyFormat = formatter.format(Number(this.defaultEngAssignment.netFees));
    this.defaultEngAssignment.estimatedGrossMarginInCurrencyFormat =
      (this.defaultEngAssignment.estimatedGrossMargin == null)
        ? ''
        : formatter.format(Number(this.defaultEngAssignment.estimatedGrossMargin));
  }

  getBoolean(value) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  defaultSetting() {
    this.hideDefaultAssignmentBox();
  }

  resetDefaultAssignment() {
    this.resetAssignmentModel();
    this.hideDefaultAssignmentBox();
  }
  resetAssignmentModel() {
    this.controlInitialization();
    setTimeout(() => {
      this.hideDefaultAssignmentBox();
    }, 0);
  }

  hideDefaultAssignmentBox() {
    this.isVisibledefaultAssignmentDiv = false;
    this.isVisibleNewAssignmentDIV = true;
  }

  openDefaultAssignmentBox() {
    this.isVisibledefaultAssignmentDiv = true;
    this.isVisibleNewAssignmentDIV = false;
  }

  cancelAssignment() {
    this.cmsDisplayUserEmail = '';
    this.cmsDisplayUserName = '';
    this.adminDisplayUserName = '';
    this.adminDisplayUserEmail = '';
    this.validateCMSAA = false;
    this.validateCMSEP = false;
    this.resetDefaultAssignment();
    this.invalidvaluesAN = "";
  }

  saveAssignment(assignmentrowid: string) {
    this.isvalidEED = true;
    this.isvalidESD = true;
    this.mandatoryFieldInitialization();
    this.setAssignmentValues();
    this.validateDate(this.defaultEngAssignment.engagementStartDate, 'ESD');
    this.validateDate(this.defaultEngAssignment.engagementEndDate, 'EED');

    if (this.defaultEngAssignment.assignmentName !== '') {
      this.engagementService.GetAssignmentNameCountByClientId(this.defaultEngAssignment.assignmentName, this.engagementList.clientId).subscribe(count => {
        if (count > 0) {
          this.isAssignmentNameExistForClient = true;
        } else {
          this.isAssignmentNameExistForClient = false;
        }
        if (this.checkMandatoryValidations(this.arrControlValidation, this.defaultEngAssignment, 'engagementAssignment')
          && this.isvalidESD && this.isvalidEED) {

          if (this.checkBusinessValidations() && !this.isAssignmentNameExistForClient && !this.isInvalidClientBillingContactEmail) {

            this.setAssignmentValuesBeforeSave();
            if (this.arrEngagementAssignment.length >= 0) {
              this.AddAssignment();
            }
            this.cmsDisplayUserEmail = '';
            this.cmsDisplayUserName = '';
            this.adminDisplayUserName = '';
            this.adminDisplayUserEmail = '';
            this.validateCMSAA = false;
            this.validateCMSEP = false
            this.resetAssignmentModel();
          }
        }
      });
    }
  }

  validateDate(date: any, dateName: string) {
    let isSpace = false;
    console.log("passed date is =====" + date);
    if (date === "") { isSpace = true; } else { isSpace = false; }
    let date1 = new Date(date);
    if (isNaN(date1.getTime()) && !isSpace) {
      if (dateName === 'ESD') {
        this.isvalidESD = false;
      }
      if (dateName === 'EED') {
        this.isvalidEED = false;
      }
    }
  }


  formatDate(date: any) {
    if ((date == null) || (date == undefined)) {
      // $('.engPeriodDate').val('');
      this.engagementList.plannedClosureDate = null;
      return true;
    } else {
      const formattedDate = new Date(date);
      const d = formattedDate.getDate();
      let m = formattedDate.getMonth();
      m += 1;  // JavaScript months are 0-11
      const y = formattedDate.getFullYear();
      const finalFormattedDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
      if (finalFormattedDate === '1/1/1' || finalFormattedDate === '1/1/2001' ||
        finalFormattedDate == '0/0/0' || finalFormattedDate.length <= 0 || y <= 1990
        || isNaN(d) == true || isNaN(m) == true || isNaN(y) == true) {
        this.engagementList.plannedClosureDate = null;
        //  $('#txttaxYear').val('');
        return true;
      } else {
        // $('.engPeriodDate').val(finalFormattedDate);
        this.engagementList.plannedClosureDate = date;
        return false;
      }
    }
  }

  // formatDate(date: any) {
  //   if ((date == null) || (date == undefined)) {
  //     $('#txtDate').val('');
  //     return true;
  //   } else {
  //     const formattedDate = new Date(date);
  //     const d = formattedDate.getDate();
  //     let m = formattedDate.getMonth();
  //     m += 1;  // JavaScript months are 0-11
  //     const y = formattedDate.getFullYear();
  //     const finalFormattedDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
  //     if (finalFormattedDate === '1/1/1' || finalFormattedDate === '1/1/2001' ||
  //       finalFormattedDate == '0/0/0' || finalFormattedDate.length <= 0 || y <= 1990
  //       || isNaN(d) == true || isNaN(m) == true || isNaN(y) == true) {
  //       $('#txttaxYear').val('');
  //       return true;
  //     } else {
  //       $('#txtDate').val(finalFormattedDate);
  //       return false;
  //     }
  //   }
  // }

  checkCharCodeForNumber(event) {
    if (((event.keyCode >= 48) && (event.keyCode <= 57) || (event.keyCode === 40) || (event.keyCode === 41) || (event.keyCode === 45))) {
      return true;
    }
    return false;
  }

  checkSpaceChartBeginInTableRow(event, i, recordListName: string) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (recordListName === 'engagementAssignmentList') {
        this.engagementAssignmentList[i].assignmentName = null;
        return false;
      }
      if (recordListName === 'teamMemberList') {
        this.teamMemberList[i].memeberContactNo = null;
        return false;
      }
      if (recordListName === 'defaultEngAssignment') {
        this.defaultEngAssignment.assignmentName = null;
        return false;
      }
      if (recordListName === ('txtClientEmail_' + i)) {
        $('txtClientEmail_' + i).val('');
        return false;
      }
    }
    else if (text.length > 0) {
      if (recordListName === 'engagementAssignmentList') {
        this.engagementAssignmentList[i].assignmentName = text;
        return false;
      }
      if (recordListName === 'defaultEngAssignment') {
        this.defaultEngAssignment.assignmentName = text;
        return false;
      }
      if (recordListName === ('txtClientEmail_' + i)) {
        $('txtClientEmail_' + i).val(text);
        return true;
      }
    }
  }

  getEPList(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForEP = userList;

        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForEP = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  GetAssignmentAdmins(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArray = userList.filter(
            item => item.lineOfBusiness != this.appService.config.DATA.LINE_OF_BUSINESS_ID_PS
          );
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArray = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  refreshCMSEPPopup(index, textboxName: string) {
    this.removeRowSelection('tblEPAD', 'searchEPName', 'EPErrorMessage');
    index = this.peoplepickerIndex;
    this.CMSEPCtrl = textboxName + 'AS_' + this.epRoleName + '_' + this.peoplepickerIndex;
    // this.currentEPIndex = index;
    this.validateCMSEP = false;
    this.selectedEPIndex = null;
    this.membersListArrayForEP = null;
    if ($('#' + this.CMSEPCtrl).hasClass('text-danger')) {
      $('#' + this.CMSEPCtrl).removeClass('text-danger');
    }
  }

  refreshCMSAssignmentAdminPopup(index, textboxName: string) {
    this.removeRowSelection('tblAssAdminAD', 'searchAssAdminName', 'AssignmentAdminErrorMessage');
    index = this.peoplepickerIndex;

    this.ASAdminCtrl = textboxName + 'AS_' + this.assignAdminRoleName + '_' + this.peoplepickerIndex;
    this.currentAssignmentRowIndex = index;
    this.validateCMSAA = false;
    this.validateCMSEP = false;
    if ($('#' + this.ASAdminCtrl).hasClass('text-danger')) {
      $('#' + this.ASAdminCtrl).removeClass('text-danger');
    }
  }

  searchEP() {
    // Declare variables
    var input, filter, ul, li, i, txtValue;
    input = document.getElementById('searchEPName');
    filter = input.value.toUpperCase();
    ul = document.getElementById('ulEP');
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      txtValue = li[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = '';
      } else {
        li[i].style.display = 'none';
      }
    }
  }

  setEP() {
    let index = this.selectedEPIndex;
    if (Number(index) >= 0) {
      const selectedEPName = $('#spEPdisplayName' + index).text();
      const selectedEPEmail = $('#spEPmail' + index).text();
      const selectedEPPhone = $('#spEPbusinessPhones' + index).text();
      $('#' + this.CMSEPCtrl).val(selectedEPName);
      $('#UserHiddenProps_AS_EP_0 #hdnUserName').val(selectedEPName);
      $('#UserHiddenProps_AS_EP_0 #hdnUserEmail').val(selectedEPEmail);
      this.cmsDisplayUserName = selectedEPName;
      this.cmsDisplayUserEmail = selectedEPEmail;
      $("#EPErrorMessage").text('');
      if (this.CMSEPCtrl !== '') {
        this.validateCMSEP = true;
      }

      else {
        $("#EPErrorMessage").text('Selected EP is already added');
      }
    }
    this.membersListArrayForEP = null;
  }

  setEPPMD() {
    const index = this.selectedEPPMDIndex;
    if (Number(index) >= 0) {
      const selectedEPName = $('#spEPdisplayName' + index).text();
      const selectedEPEmail = $('#spEPmail' + index).text();
      const selectedEPPhone = $('#spEPbusinessPhones' + index).text();
      $('#' + this.EPPMDCtrl).val(selectedEPName);
      $('#UserHiddenProps_EP_0 #hdnUserName').val(selectedEPName);
      $('#UserHiddenProps_EP_0 #hdnUserEmail').val(selectedEPEmail);
      $('#UserHiddenProps_EP_0 #hdnUserPhone').val(selectedEPPhone);
      this.selectedEngagementPartner = selectedEPName;
      this.selectedEngagementPartnerEmail = selectedEPEmail;
      this.validateEP = true;
    }
  }

  selectEPPMD(index) {
    $('#tblEPPMD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedEPPMDIndex = index;
    console.log('selectEPPMD func called ', this.selectedEPPMDIndex);
  }

  deselectEPPMD() {
    this.membersListArrayForEP = null;
  }

  selectEP(index) {
    $('#tblEPAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedEPIndex = index;
    console.log('selectEP func called ', this.selectedEPIndex);
  }

  deselectEP() {
    $('#' + this.CMSEPCtrl).val('');
    $('#' + this.CMSEPCtrl).innerText = '';
    this.membersListArrayForEP = null;
  }

  setAdmin() {
    let index = this.selectedAAIndex;
    if (Number(index) >= 0) {
      const selectedAdminName = $('#spAdmindisplayName' + index).text();
      const selectedAdminEmail = $('#spAdminmail' + index).text();
      const selectedAdminPhone = $('#spAdminbusinessPhones' + index).text();
      this.validateAA = true;
      $("#AssignmentAdminErrorMessage").text('');
      var countofAddedAssignmentAdmin = 0;
      $('#' + this.ASAdminCtrl).val(selectedAdminName);
      $('#UserHiddenProps_AS_AA_0 #hdnUserName').val(selectedAdminName);
      $('#UserHiddenProps_AS_AA_0 #hdnUserEmail').val(selectedAdminEmail);
      $('#UserHiddenProps_AS_AA_0 #hdnUserPhone').val(selectedAdminPhone);
      this.adminDisplayUserName = selectedAdminName;
      this.adminDisplayUserEmail = selectedAdminEmail;
      this.validateCMSAA = true;
    }
    this.membersListArray = null;
  }

  selectAdmin(index) {
    $('#tblAssAdminAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedAAIndex = index;
  }

  deselectAdmin() {
    $('#' + this.ASAdminCtrl).val('');
    $('#' + this.ASAdminCtrl).innerText = '';
    this.membersListArray = null;
  }

  setAssignmentSection() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    this.arrEngagementAssignment = [];
    this.peoplepickerIndex = this.engagementAssignmentList.length;
    for (let i = 0; i < this.engagementAssignmentList.length; i++) {
      this.defaultEngAssignment.solutionCodeId = this.engagementAssignmentList[i].solutionCodeId;
      this.defaultEngAssignment.assignmentName = this.engagementAssignmentList[i].assignmentName;
      this.defaultEngAssignment.isContingent = Boolean(this.engagementAssignmentList[i].isContingent);
      this.defaultEngAssignment.cmsEPPMDName = this.engagementAssignmentList[i].cmsEPPMDName;
      this.defaultEngAssignment.cmsEPPMDEmailId = this.engagementAssignmentList[i].cmsEPPMDEmailId;
      this.defaultEngAssignment.assignmentAdministrator = this.engagementAssignmentList[i].assignmentAdministrator;
      this.defaultEngAssignment.adminEmailId = this.engagementAssignmentList[i].adminEmailId;
      this.defaultEngAssignment.adminContactNo = this.engagementAssignmentList[i].adminContactNo;

      if (this.engagementAssignmentList[i].solutionCodeId !== 0) {
        let assignmentSolutionCode: SecondarySolutionCode = new SecondarySolutionCode();
        assignmentSolutionCode.solutionCode = this.engagementAssignmentList[i].solutionCode;
        assignmentSolutionCode.solutionCodeDescription = this.engagementAssignmentList[i].solutionCodeDescription;
        assignmentSolutionCode.isGrossMarginRequire = this.engagementAssignmentList[i].isGrossMarginRequire;
        assignmentSolutionCode.childSolutionCodeId = this.engagementAssignmentList[i].solutionCodeId;
        assignmentSolutionCode.isEngagementStartDateRequired = this.engagementAssignmentList[i].isEngagementStartDateRequired;
        assignmentSolutionCode.isEngagementEndDateRequired = this.engagementAssignmentList[i].isEngagementEndDateRequired;
        this.defaultEngAssignment.selectedsolutionCode = assignmentSolutionCode;
      }
      this.defaultEngAssignment.selectedengagementType =
        this.engtypekeyValueList.find(element => element.key == this.engagementAssignmentList[i].engagementTypeId.toString());
      this.defaultEngAssignment.assignmentCodeId = this.engagementAssignmentList[i].assignmentCodeId;
      this.defaultEngAssignment.engagementId = this.engagementAssignmentList[i].engagementId;
      this.defaultEngAssignment.collaborationSiteId = this.engagementAssignmentList[i].collaborationSiteId;
      this.defaultEngAssignment.assignmentCode = this.engagementAssignmentList[i].assignmentCode;

      const defaultengagementType = new KeyValue();
      defaultengagementType.key = '';
      defaultengagementType.value = '';
      if (this.defaultEngAssignment.selectedengagementType == undefined) {
        this.defaultEngAssignment.selectedengagementType = defaultengagementType;
      }
      if (this.engagementAssignmentList[i].clientCode != null) {
        this.defaultEngAssignment.clientCode = this.engagementAssignmentList[i].clientCode.trim();
      }
      this.defaultEngAssignment.realization = this.engagementAssignmentList[i].realization;
      this.defaultEngAssignment.isPrimaryAssignment = this.engagementAssignmentList[i].isPrimaryAssignment;
      this.defaultEngAssignment.netFeesInCurrencyFormat = formatter.format(Number(this.engagementAssignmentList[i].netFees));
      this.defaultEngAssignment.netFees = this.engagementAssignmentList[i].netFees;
      this.defaultEngAssignment.engagementTypeId = this.engagementAssignmentList[i].engagementTypeId;
      this.defaultEngAssignment.engagementTypeData = this.engtypekeyValueList;
      this.defaultEngAssignment.iscontingentData = this.contingentkeyValueList;
      this.defaultEngAssignment.estimatedGrossMargin = this.engagementAssignmentList[i].estimatedGrossMargin;
      this.defaultEngAssignment.estimatedGrossMarginInCurrencyFormat =
        (this.engagementAssignmentList[i].estimatedGrossMargin == null || this.engagementAssignmentList[i].estimatedGrossMargin.toString().length === 0)
          ? ''
          : formatter.format(Number(this.engagementAssignmentList[i].estimatedGrossMargin));
      this.defaultEngAssignment.clientBillingContactName = this.engagementAssignmentList[i].clientBillingContactName;
      this.defaultEngAssignment.clientBillingContactEmail = this.engagementAssignmentList[i].clientBillingContactEmail;
      this.defaultEngAssignment.clientBillingContactTitleId = this.engagementAssignmentList[i].clientBillingContactTitleId;
      this.defaultEngAssignment.clientBillingContactTitle = this.GetClientTitle(this.engagementAssignmentList[i].clientBillingContactTitleId);
      this.defaultEngAssignment.collaborationSiteName = this.GetCollaborationSiteName(this.engagementAssignmentList[i].collaborationSiteId);
      this.defaultEngAssignment.officeLocationCodeName = this.GetOfficeLocationCodeName(this.engagementAssignmentList[i].officeLocationCodeId);
      this.defaultEngAssignment.engagementStartDate = this.engagementAssignmentList[i].engagementStartDate;
      this.defaultEngAssignment.engagementEndDate = this.engagementAssignmentList[i].engagementEndDate;
      this.defaultEngAssignment.eqcrName = this.engagementAssignmentList[i].eqcrName;
      this.defaultEngAssignment.eqcrEmailId = this.engagementAssignmentList[i].eqcrEmailId;
      this.defaultEngAssignment.newEQCRName = '';
      this.defaultEngAssignment.newEQCREmailId = ''
      this.defaultEngAssignment.isvalidEQCR = true;
      this.defaultEngAssignment.isValidEQCRBusinessRule = true;
      this.defaultEngAssignment.isEQCRExists = false;
      this.defaultEngAssignment.opportunityServiceId = this.engagementAssignmentList[i].opportunityServiceId;
      this.arrEngagementAssignment.push(JSON.parse(JSON.stringify(this.defaultEngAssignment)));
      //set team member data
    }
    console.log('arrEngagementAssignment ==>', this.arrEngagementAssignment);
    this.controlInitialization();
  }

  GetContactTypes() {
    let excludedType = 'Billing Contact';
    this.applicationMasterService.GetContactTypes().subscribe(
      contactTypes => {
        contactTypes.forEach(contact => {
          if (contact => contact.typeValue != 'Billing Contact') {
            this.ClientContactTypes = contactTypes;
          }
        });
      }
    );
  }

  GetClientLocations() {
    this.applicationMasterService.GetClientLocations().subscribe(
      result => {
        this.ClientLocations = result;
      }
    );
  }

  GetCollaborationSiteName(siteId: number) {
    if (siteId !== null) {
      if (siteId > 0) {
        return this.collaborationSiteList.find(c => c.collaborationSiteId == siteId).collaborationSiteName;
      }
    } else {
      return '';
    }
  }

  GetOfficeLocationCodeName(officeLocationCodeId: number) {
    if (officeLocationCodeId !== null) {
      if (officeLocationCodeId > 0) {
        console.log('Client Locations ', this.ClientLocations, officeLocationCodeId);
        let location = this.ClientLocations.find(c => c.id == officeLocationCodeId);
        if (location != undefined) {
          return location.typeValue;
        }
        else {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  GetClientTitle(clientBillingContactTitleId: number) {
    if (clientBillingContactTitleId !== null && this.ClientContactTypes && this.ClientContactTypes.length > 0) {
      if (clientBillingContactTitleId > 0) {
        return this.ClientContactTypes.find(c => c.id == clientBillingContactTitleId).typeValue;
      }
    } else {
      return '';
    }
  }

  CheckDuplicateAssignment(newAssignmentName: string) {
    for (let i = 0; i < this.engagementAssignmentList.length; i++) {
      if (this.engagementAssignmentList[i].assignmentName.toLowerCase() == newAssignmentName.toLowerCase()) {
        this.isNewAssignmentNameDuplicate = true;
        break;
      } else {
        this.isNewAssignmentNameDuplicate = false;
      }
    }
  }

  AddAssignment() {
    this.loadingScreenService.startLoading();
    this.defaultEngAssignment.isPrimaryAssignment = false;
    let addedAssignmentCodeId = 0;
    this.defaultEngAssignment.assignmentCode = null;
    this.newEngAssignment = {
      engagementId: this.engagementList.engagementId,
      solutionCodeId: this.defaultEngAssignment.solutionCodeId,
      assignmentName: this.defaultEngAssignment.assignmentName,
      assignmentAdministrator: this.defaultEngAssignment.assignmentAdministrator,
      realization: this.defaultEngAssignment.realization,
      netFees: this.defaultEngAssignment.netFees,
      createdBy: this.defaultEngAssignment.createdBy,
      createdDate: this.defaultEngAssignment.createdDate,
      lastModifiedBy: this.defaultEngAssignment.lastModifiedBy,
      lastModifiedDate: this.defaultEngAssignment.lastModifiedDate,
      adminEmailId: this.defaultEngAssignment.adminEmailId,
      adminContactNo: this.defaultEngAssignment.adminContactNo,
      assignmentCode: this.defaultEngAssignment.assignmentCode,
      cmsEPPMDName: this.defaultEngAssignment.cmsEPPMDName,
      cmsEPPMDEmailId: this.defaultEngAssignment.cmsEPPMDEmailId,
      engagementTypeId: this.defaultEngAssignment.engagementTypeId,
      isContingent: this.defaultEngAssignment.isContingent,
      isPrimaryAssignment: this.defaultEngAssignment.isPrimaryAssignment,
      collaborationSiteId: this.defaultEngAssignment.collaborationSiteId,
      engagementStartDate: this.defaultEngAssignment.engagementStartDate,
      engagementEndDate: this.defaultEngAssignment.engagementEndDate,
      officeLocationCodeId: this.defaultEngAssignment.officeLocationCodeId,
      estimatedGrossMargin: this.defaultEngAssignment.estimatedGrossMargin,
      clientBillingContactName: this.defaultEngAssignment.clientBillingContactName,
      clientBillingContactTitleId: this.defaultEngAssignment.clientBillingContactTitleId,
      clientBillingContactEmail: this.defaultEngAssignment.clientBillingContactEmail,
      eqcrName: this.defaultEngAssignment.eqcrName,
      eqcrEmailId: this.defaultEngAssignment.eqcrEmailId,
      opportunityServiceId: this.defaultEngAssignment.opportunityServiceId
    };
    this.engagementAssignmentWithoutSCode.push(this.newEngAssignment);
    console.log(this.engagementAssignmentWithoutSCode);
    let newEngAssignmentWrapperModel = new NewEngAssignmentWrapperModel();
    newEngAssignmentWrapperModel.newEngagementAssignment = this.newEngAssignment;
    newEngAssignmentWrapperModel.opportunityId = this.engagementList.opportunityId;

    this.engagementService.AddEngagementAssignment(newEngAssignmentWrapperModel).subscribe(
      addedAssignmentResult => {
        //  this.loadingScreenService.stopLoading();
        if (Number(addedAssignmentResult) > 0) {
          addedAssignmentCodeId = Number(addedAssignmentResult);
          // add cmsep and AA to given collaboration validaing duplicate records of team members.
          const tempSiteMembersList: TeamMember[] = []; //temperory Array to push into collaborationSiteMembersList
          var newAssignmentMember: any = {};
          const collaborationSiteViewModel = new CollaborationSiteViewModel();
          collaborationSiteViewModel.collaborationSite = new CollaborationSite();
          collaborationSiteViewModel.engagementAssignmentList = [];
          collaborationSiteViewModel.teamMemberList = [];

          var cmsEPMatch = false;
          var AAMatch = false;
          // check if new team member name is matching with existing team member name.
          for (let j in this.collaborationSiteMembersList) {
            if (this.newEngAssignment.cmsEPPMDEmailId.toLowerCase().trim() === this.collaborationSiteMembersList[j].memberEmailId.toLowerCase().trim() &&
              this.collaborationSiteMembersList[j].collaborationSiteId == this.newEngAssignment.collaborationSiteId) {
              cmsEPMatch = true;
            }
            if (this.newEngAssignment.adminEmailId.toLowerCase().trim() === this.collaborationSiteMembersList[j].memberEmailId.toLowerCase().trim() &&
              this.collaborationSiteMembersList[j].collaborationSiteId == this.newEngAssignment.collaborationSiteId) {
              AAMatch = true;
            }
          }
          if (!cmsEPMatch) {
            this.newCollabTeamMember = {
              collaborationSiteId: this.newEngAssignment.collaborationSiteId,
              memberName: this.newEngAssignment.cmsEPPMDName,
              memberEmailId: this.newEngAssignment.cmsEPPMDEmailId,
              memeberContactNo: null,
              jobTitle: '',
              teamMemberRole: this.getTeamMemberRoleIdByCode('P'),
              teamMemberPermissionId: this.defaultPermissionId,
              workspaceId: this.CurrentWorkspaceData.workspaceId,
              createdby: this.userObject.userName
            };
            tempSiteMembersList.push(this.newCollabTeamMember);
          }
          if (!AAMatch) {
            newAssignmentMember = {
              collaborationSiteId: this.newEngAssignment.collaborationSiteId,
              memberName: this.newEngAssignment.assignmentAdministrator,
              memberEmailId: this.newEngAssignment.adminEmailId,
              memeberContactNo: this.newEngAssignment.adminContactNo,
              jobTitle: '',
              teamMemberRole: this.getTeamMemberRoleIdByCode('P'),
              teamMemberPermissionId: this.defaultPermissionId,
              workspaceId: this.CurrentWorkspaceData.workspaceId,
              createdby: this.userObject.userName
            };
            tempSiteMembersList.push(newAssignmentMember);
          }
          // if (tempSiteMembersList.length > 0) {
          collaborationSiteViewModel.teamMemberList = tempSiteMembersList;
          collaborationSiteViewModel.collaborationSite.collaborationSiteId = Number(this.newEngAssignment.collaborationSiteId);
          collaborationSiteViewModel.collaborationSite.engagementId = this.engagementList.engagementId;
          collaborationSiteViewModel.collaborationSite.clientId = this.engagementList.clientId;

          // instead of adding to existing objectlist teamMemberList call service to add new teammember to db.
          this.workspaceService.addCollaborationTeamMembers(collaborationSiteViewModel).subscribe(
            addedResult => {
              if (addedResult === 'Success') {
                // Call API for Active directory Update Permission
                let finalSiteMembersList: CollaborationSiteMembers[] = [];

                this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
                  .subscribe(
                    result => {
                      this.engagementCollaborationsViewModel = result;
                      this.applyFilterOnceAfterDataLoad = true;
                      this.engagementList = this.engagementCollaborationsViewModel.engagement;
                      if (this.engagementList.status === EngagementStatus.EngActive) {
                        this.isEngagementCloseoutDisable = false;
                      }
                      else {
                        this.isEngagementCloseoutDisable = true;
                      }
                      this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
                      this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
                      this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
                      this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
                      this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
                      this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;

                      for (let j in this.collaborationSiteMembersList) {
                        if (this.collaborationSiteMembersList[j].collaborationSiteId == collaborationSiteViewModel.collaborationSite.collaborationSiteId) {
                          finalSiteMembersList.push(this.collaborationSiteMembersList[j]);
                        }
                      }

                      if (((this.engagementList.status === EngagementStatus.EngActive ||
                        this.engagementList.status === 'Active (pending CMS codes)' ||
                        this.engagementList.status === EngagementStatus.EngActivePendingSignature)
                        && this.engagementAssignmentList.length === 1) ||
                        !(this.engagementList.status === EngagementStatus.EngActive ||
                          this.engagementList.status === 'Active (pending CMS codes)' ||
                          this.engagementList.status === EngagementStatus.EngActivePendingSignature
                        )
                      ) {
                        this.isDisableAddCollaborationSite = true;
                      } else {
                        if (this.newCollabrationCount > 0) {
                          this.isDisableAddCollaborationSite = true;
                        }
                        else {
                          this.isDisableAddCollaborationSite = false;
                        }
                      }
                      // Get List of Groups based on member Permission
                      let siteMembersGroups = finalSiteMembersList.reduce((r, a) => {
                        r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                        return r;
                      }, {});

                      console.log("siteMembersGroups", siteMembersGroups);
                      let onwwerList: CollaborationSiteMembers[] = [];
                      let memberList: CollaborationSiteMembers[] = [];

                      let siteGroupTypes = Object.keys(siteMembersGroups);

                      siteGroupTypes.forEach(type => {
                        if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                          onwwerList = finalSiteMembersList.filter(member =>
                            Number(member.memberPermissionId) === Number(type));
                        } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                          memberList = finalSiteMembersList.filter(member =>
                            Number(member.memberPermissionId) === Number(type));
                        }
                      })

                      // Site Onwers Email List
                      let siteOwnerEmailIds: string[] = [];
                      onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                      //Site Members Email List
                      let siteMemberEmailIds: string[] = [];
                      memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                      let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                      let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
                      let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

                      OwnerListEmailModel.groupType = 'Owner';
                      OwnerListEmailModel.groupUsersEmail = [];
                      siteOwnerEmailIds.forEach(emailid => {
                        let adUser: ADUser = new ADUser();
                        adUser.firstName = '';
                        adUser.lastName = '';
                        adUser.userEmailId = emailid;
                        OwnerListEmailModel.groupUsersEmail.push(adUser);
                      });

                      MemberListEmailModel.groupType = 'Member';
                      MemberListEmailModel.groupUsersEmail = [];
                      siteMemberEmailIds.forEach(emailid => {
                        let adUser: ADUser = new ADUser();
                        adUser.firstName = '';
                        adUser.lastName = '';
                        adUser.userEmailId = emailid;
                        MemberListEmailModel.groupUsersEmail.push(adUser);
                      });

                      collbSiteUsersUpdatePermissionModel.siteType = "CollabrationSite";
                      const foundSite = this.collaborationSiteList.find(site => site.collaborationSiteId ===
                        collaborationSiteViewModel.collaborationSite.collaborationSiteId);
                      collbSiteUsersUpdatePermissionModel.siteUniqueID = foundSite.siteUniqueID;
                      collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
                      collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
                      let updatePermissionsModelList: UpdatePermissionsModel[] = [];
                      updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);
                      console.log('collbSiteUsersUpdatePermissionModel ==>', collbSiteUsersUpdatePermissionModel);
                      //4. Call Active Directory API to update latest collaborationSite Member Ids
                      this.CallUpdatePermissions(updatePermissionsModelList);
                      this.loadingScreenService.stopLoading();
                      console.log('added new assignment team members to given collaboration from assignment section');

                      this.getEngagementDetailsByIdWithSPPermission(addedAssignmentCodeId, this.engagementList.engagementId, this.newEngAssignment.collaborationSiteId);
                      this.workspaceService.
                        SendCMSRequestOnSignature(this.engagementId).subscribe(sendCMSRequestOnSignatureCompletion => {
                          if (!sendCMSRequestOnSignatureCompletion) {
                            this.requestAssignmentCode(null, addedAssignmentCodeId, null, 0, null);
                          }
                        },
                          err => {
                            this.loadingScreenService.stopLoading();
                          });
                    }
                  )
              }
            }
          );

        } else if (addedAssignmentResult === 'Failure') {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('New assignment could not be added !', 'danger');
        }
      },
      () => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('New assignment could not be added !', 'danger');
      }
    );
  }

  CheckDuplicateCollaborationSiteName(newCollaborationSiteName: string) {
    this.isNewSiteNameDuplicate = false;
    this.isNewSiteNameRequired = false;
    newCollaborationSiteName = newCollaborationSiteName.trim();
    for (let i = 0; i < this.collaborationSiteList.length; i++) {
      if (this.collaborationSiteList[i].collaborationSiteName.toLowerCase() == newCollaborationSiteName.toLowerCase()) {
        this.isNewSiteNameDuplicate = true;
        break;
      }
    } // end for loop this.collaborationSiteList.length
    return this.isNewSiteNameDuplicate;
  }

  // request Assignment Code from cms
  requestAssignmentCode(workspaceId: string, assignmentCodeId: number, newAssignmentName: string, index: number, eSignResponse: EngagementESignature) {
    this.loadingScreenService.startLoading();

    //let processAssignmentFlag = false;
    console.log(workspaceId, assignmentCodeId, newAssignmentName, index);
    if (workspaceId === null && assignmentCodeId === null) {
      console.log('Cannot process the CMS Request as no valid values found.');
      this.cmsCodeRequestError = true;
      if (!this.clmLsDownloadFileFromSharepoitisRunning)
        this.loadingScreenService.stopLoading();
    } else {

      let _workspaceId = '';
      let _assignmentCodeId = 0;
      let _isAcceptanceRequest = 0;

      if (assignmentCodeId != null) {
        _workspaceId = null;
        _assignmentCodeId = assignmentCodeId;
        _isAcceptanceRequest = 0;
      } else {
        _workspaceId = workspaceId;
        _assignmentCodeId = 0;
        _isAcceptanceRequest = 1;
      }

      this.workspaceService.CheckRequestSentForClient(_workspaceId, _assignmentCodeId, _isAcceptanceRequest).subscribe(processAssignmentFlagStatus => {
        if (processAssignmentFlagStatus == 1) {
          let engagementWorkspaceId = workspaceId;
          let engagementAssignmentCodeId = assignmentCodeId;
          let _assignmentName = newAssignmentName;

          this.workspaceService.getPrimaryAssignment(engagementWorkspaceId, engagementAssignmentCodeId, _assignmentName).subscribe(
            assignmentRequestList => {
              for (const assignmentRequest of assignmentRequestList) {
                console.log('data from getPrimaryAssignment ', assignmentRequest);

                if (!this.checkUserExistance(assignmentRequest.initiated_By_Email)) {
                  this.employeeList.push(new Employee('0', assignmentRequest.initiated_By_Email));
                }
                if (!this.checkUserExistance(assignmentRequest.client_Administrator_Email)) {
                  this.employeeList.push(new Employee('0', assignmentRequest.client_Administrator_Email));
                }
                if (!this.checkUserExistance(assignmentRequest.assignment_Administrator_Email)) {
                  this.employeeList.push(new Employee('0', assignmentRequest.assignment_Administrator_Email));
                }
                if (!this.checkUserExistance(assignmentRequest.engagement_Partner_Email)) {
                  this.employeeList.push(new Employee('0', assignmentRequest.engagement_Partner_Email));
                }
              }

              console.log('To get the employee ids for given user list ', this.employeeList);
              const searchEmployee = new SearchEmployeeModel();
              searchEmployee.employees = this.employeeList;

              this.graphSerivce.GetEmployeeIdForUserList(searchEmployee).subscribe(
                finalEmployeeList => {

                  console.log('API response for employee ids for given user list ', finalEmployeeList);
                  for (const assignmentRequestInner of assignmentRequestList) {
                    assignmentRequestInner.initiated_By
                      = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.initiated_By_Email)
                        .employeeId;
                    assignmentRequestInner.client_Administrator
                      = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.client_Administrator_Email)
                        .employeeId;
                    assignmentRequestInner.assignment_Administrator
                      = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.assignment_Administrator_Email)
                        .employeeId;
                    assignmentRequestInner.engagement_Partner
                      = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.engagement_Partner_Email)
                        .employeeId;

                    console.log('Updated Assignment Request ', assignmentRequestList);
                  }
                  const request = new AssignmentRequestList();
                  request.list = assignmentRequestList;
                  this.cmsService.PostPrimaryAssignment(request).subscribe(
                    assignmentResponse => {
                      let responseList = new AssignmentResponseList();
                      responseList.list = assignmentResponse;
                      console.log('Successfully submission of CMS Primary Assignment.', assignmentResponse);
                      this.workspaceService.storeCMSLog(responseList).subscribe(
                        logResult => {

                          for (let i = 0; i < assignmentResponse.length; i++) {
                            this.workspaceService
                              .updateFirstAssignmentStatus(assignmentResponse[i].responseStatus, assignmentResponse[i].assignmentCodeId)
                              .subscribe(
                                statusResult => { console.log(statusResult); }
                              );
                          }

                          if (eSignResponse != null && eSignResponse.envelopeStatusValue == "Completed") {
                            this.workspaceService.UpdateEngagementStatusOnSignatureCompletion(this.engagementId).subscribe(
                              finalUpdateStatus => {
                                console.log(finalUpdateStatus);
                                this.getEngagementCollaborationsById(this.engagementId, false);
                              }
                            )
                          }

                          this.loadingScreenService.stopLoading();
                          this.arrEngagementAssignment[index].assignmentCodeStatus = this.AssignmentCodeRequested;

                        },
                        logError => {
                          this.loadingScreenService.stopLoading();
                          console.error('Error in getting the response in storeCMSLog in Engage API ', logError);
                        }

                      );
                    },
                    error => {
                      this.loadingScreenService.stopLoading();
                      console.error('Error in getting the response in PostPrimaryAssignment in CMS Integration API ', error);
                    }
                  );
                },
                error => {
                  this.loadingScreenService.stopLoading();
                  console.error('Error in getting the response in GetEmployeeIdForUserList in Graph API ', error);
                }
              );
            },
            error => {
              this.loadingScreenService.stopLoading();
              console.error('Error in getting the response in getPrimaryAssignment in API ', error);
            }
          );

        } else {
          if (processAssignmentFlagStatus == 2) {
            const message = "Invalid case CMS Request cannot be sent.";
            console.error(message);
            this.workspaceService.storeErrorLog(message + "::" + this.userObject);
          }
          this.loadingScreenService.stopLoading();
        }
      },
        err => {
          this.loadingScreenService.stopLoading();

          console.error('Error in CheckRequestSentForClient ', err);
        });
    }
  }
  // end request assignment code from cms

  checkUserExistance(email: string): boolean {
    return this.employeeList.some(r => r.employeeEmailId === email);
  }
  // end assignment tab section

  ValidateINDUSCansentAndProcessTeamMember(index: number, siteId: number, SiteMembersWithClientMemberModel: CollaborationSiteMembersWithClientMemberModel) {
    // If Original/Previous INDUS value is YES and current INDUS value is NO then do this Remove operation
    // Get Previous Value from collaborationSiteViewModelList
    // Get current INDUS valu from currentCollabModel
    this.indusMembersListArray = [];
    this.indusADGroupsList = [];
    let previousIndusValue = this.collaborationSiteViewModelList.find(collbSite =>
      collbSite.collaborationSite.collaborationSiteId === siteId).collaborationSite.isIndus;
    let currentIndusValue = this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus;

    // 24:02:2021- Pass iNDUSConsent YES/NO only when it is actually change OTHERWSIE pass BLANK value to aviod metadata update on sharepoint side
    if (previousIndusValue != currentIndusValue) {
      if (SiteMembersWithClientMemberModel.collaborationSiteIndus) {
        SiteMembersWithClientMemberModel.iNDUSConsent = 'Yes';
      } else {
        SiteMembersWithClientMemberModel.iNDUSConsent = 'No';
      }
    } else {
      SiteMembersWithClientMemberModel.iNDUSConsent = "";
    }

    if (previousIndusValue === true && currentIndusValue === false) {
      this.adUsersService.GetINDUSADGroups(RoleNames.INDUSConsent).subscribe(
        adGroupList => {
          this.indusADGroupsList = adGroupList;
          console.log('this.indusADGroupsList', this.indusADGroupsList);
          if (SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList != undefined) {
            //1. Exclude EP from final member List ==>
            let GTmemberListExcludeEPAndEQCR =
              SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.filter(member => member.memberRoleId != this.getTeamMemberRoleIdByCode('EP') && member.memberRoleId != this.getTeamMemberRoleIdByCode('PR'));
            if (GTmemberListExcludeEPAndEQCR.length > 0) {
              //2. Collect Member in indusMembersListArray ==>
              GTmemberListExcludeEPAndEQCR.forEach(element => {
                this.indusTeamMemberData = {
                  azureADUsers: this.indusADGroupsList,
                  workspaceMemberId: 1,
                  memberEmail: element.memberEmailId,
                  memberName: element.userName,
                  isIndusMember: false
                };
                this.indusMembersListArray.push(this.indusTeamMemberData);
              })

              //3. Get Member validated in AD Group based Email id ==>
              let indusADUsersListViewModel: IndusADUsersListViewModel = new IndusADUsersListViewModel();
              if (this.indusMembersListArray != null && this.indusMembersListArray.length > 0) {
                indusADUsersListViewModel.indusADUsersList = this.indusMembersListArray;
                this.adUsersService.getValidIndusTeamMemberList(indusADUsersListViewModel).subscribe(
                  ValidIndusTeamMemberList => {
                    console.log('ValidIndusTeamMemberList==>', ValidIndusTeamMemberList);
                    //4. Remove Indus member from MAIN list i.e PUSH ONLY THOSE MEMBER WHERE IN EMAIL ID NOT MATCH
                    if (ValidIndusTeamMemberList.indusADUsersList != null && ValidIndusTeamMemberList.indusADUsersList.length > 0) {
                      let unMatchCollabTeamMemberList: Array<ADGroupUsersSyncCollabMembers> = [];
                      GTmemberListExcludeEPAndEQCR.forEach(gtMember => {

                        let unMatchedMember = ValidIndusTeamMemberList.indusADUsersList.find(aduser => aduser.memberEmail.toLowerCase().trim() === gtMember.memberEmailId.toLowerCase().trim());

                        console.log('unMatchedMember', unMatchedMember);
                        if (unMatchedMember == undefined || unMatchedMember == null) {
                          unMatchCollabTeamMemberList.push(gtMember);
                        }

                      });

                      let EPMember = SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.find(mem => mem.memberRoleId === this.getTeamMemberRoleIdByCode('EP'));
                      let EQCRMembers = SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.filter(mem => mem.memberRoleId === this.getTeamMemberRoleIdByCode('PR'));
                      SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList = [];
                      SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList = unMatchCollabTeamMemberList;
                      if (EPMember != undefined) {
                        SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.push(EPMember);
                      }
                      if (EQCRMembers != undefined && EQCRMembers.length > 0) {
                        EQCRMembers.forEach(EQCRMember => {
                          SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.push(EQCRMember);
                        })
                      }
                      //This below is ONLY for SYNC purpose
                      this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers = SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList;

                      console.log('Final member with ==>', SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList);
                      this.ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel);
                    }
                    else {
                      this.ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel);
                    }
                  },
                  error => {
                    this.indusADGroupsList = null;
                    console.log('Error in getting response from GetIndusTeamMembers func ', error);
                  }
                );
              } else {
                this.ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel);
              }
            } else {
              this.ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel);
            }
          } else {
            this.ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel);
          }

        },
        error => {
          this.indusADGroupsList = null;
          console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
        });
    }
    else {
      this.ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel);
    }
  }


  UpdateCollaborationSiteModel(index: number, siteId: number) {
    this._alertService.remove(this.alert);
    this.loadingScreenService.startLoading();
    $('#member-save-success').addClass('d-none');
    $('#member-save-validation').addClass('d-none');
    $('#manageTeam-close-cancel-cap').text('Close');
    const SiteMembersWithClientMemberModel = new CollaborationSiteMembersWithClientMemberModel();
    SiteMembersWithClientMemberModel.engagementId = this.engagementId;
    SiteMembersWithClientMemberModel.collaborationSiteId = siteId;
    SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList = [];
    SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList = [];
    SiteMembersWithClientMemberModel.collaborationSiteIndus = this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus;

    // <===== Client Team Member Validation Process Start =====>
    this.TeamMemberManipulationTrigger = true;
    this.cmsDataMatchClient = false;
    this.IsValidInputClient = true;

    if (this.newClientTeamMemberList.length > 0) {
      // Check Control Validations
      for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
        this.arrValidationForNewClientTeamMember[t].firstName = $('#txtFirstName_' + t).val();
        this.arrValidationForNewClientTeamMember[t].lastName = $('#txtLastName_' + t).val();
        this.arrValidationForNewClientTeamMember[t].emailId = $('#txtClientEmail_' + t).val();
        this.arrValidationForNewClientTeamMember[t].clientEmailId = $('#txtconfirmClientEmail_' + t).val();
        this.arrValidationForNewClientTeamMember[t].title = $('#txtClientTitle_' + t).val();
        this.arrValidationForNewClientTeamMember[t].permissionId = $('#drpClientTeamMemberPermissions_' + t).val();
        this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = false;
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;

        if (this.arrValidationForNewClientTeamMember[t].firstName === '') {
          $('#errorLabelFirstName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelFirstName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].lastName === '') {
          $('#errorLabelLastName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelLastName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].emailId === '') {
          $('#errorLabelEmail' + t).attr('style', 'display:block');
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        } else {
          $('#errorLabelEmail' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
          $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
          if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
            this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
            this.IsValidInputClient = false;
            $('#userExistClient' + t).attr('style', 'display:none');
            $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          } else {
            this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
            if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
              $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
              $('#userExistClient' + t).attr('style', 'display:none');

              this.IsValidInputClient = false;
            }
            else {
              $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
              $('#userExistClient' + t).attr('style', 'display:none');

            }
          }
        }
        else {
          $('#errorLabelConfirmEmail' + t).attr('style', 'display:block');
          $('#userExistClient' + t).attr('style', 'display:none');
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          this.IsValidInputClient = false;
        }
        if (this.arrValidationForNewClientTeamMember[t].permissionId === null) {
          $('#errorLabelClientTeamMemberPermission' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelClientTeamMemberPermission' + t).attr('style', 'display:none');
        }

        if (this.arrValidationForNewClientTeamMember[t].firstName === '' || this.arrValidationForNewClientTeamMember[t].lastName === ''
          || this.arrValidationForNewClientTeamMember[t].emailId === ''
          || (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId)
          || this.arrValidationForNewClientTeamMember[t].permissionId === null) {
          this.IsValidInputClient = false;
        } else { this.IsValidInputClient = true; }

        if (!this.checkValidEmail(this.arrValidationForNewClientTeamMember[t].emailId)) {
          this.IsValidInputClient = false;
          this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = true;
        } else {
          this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = false;
        }
      }
      // check duplicate emailid Recrods
      for (let t = 0; t < this.arrValidationForNewClientTeamMember.length; t++) {
        if (this.CheckDuplicateElement(this.arrValidationForNewClientTeamMember, 'emailId',
          this.arrValidationForNewClientTeamMember[t].emailId)) {
          this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = true;
          this.IsValidInputClient = false;
        } else {
          this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
        }
      }

      // check if new client member email is matching with existing  client team member email.
      if (this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers != undefined) {

        for (let j = 0; j < this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers.length; j++) {
          for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
            if ($('#txtClientEmail_' + t).val() ===
              this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers[j].emailId
              && this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers[j].collaborationSiteId == siteId) {
              this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = true;
              this.cmsDataMatchClient = true;
              $('#userExist' + t).attr('style', 'display:block');
              break;
            }
            if (this.cmsDataMatchClient === false || this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient === false) {
              $('#userExist' + t).attr('style', 'display:none');
            }
          }  //newClientTeamMemberList
        }//clientTeamMemberList
      }
      if (this.IsValidInputClient && !this.cmsDataMatchClient) {

        if (this.newClientTeamMemberList != null && this.newClientTeamMemberList.length > 0) {
          for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
            let adClientSiteUsersSyncEngageClientMmembers: ADClientSiteUsersSyncEngageClientMmembers = {
              engagementId: this.engagementId,
              collaborationSiteId: siteId,
              firstName: $('#txtFirstName_' + t).val(),
              lastName: $('#txtLastName_' + t).val(),
              emailId: $('#txtClientEmail_' + t).val(),
              title: this.newClientTeamMemberList[t].title,
              permissionId: this.newClientTeamMemberList[t].permissionId,
              invitationurl: '',
              status: ''
            }
            //this.collaborationSiteViewModelList[index].adClientSiteUsersSyncEngageClientMmembers.push(adClientSiteUsersSyncEngageClientMmembers);
            this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers.push(adClientSiteUsersSyncEngageClientMmembers);
          }
          this.newClientTeamMemberList = [];
          this.arrValidationForNewClientTeamMember = [];
        }
      } // end check for cmsmatchdata and valid input

    } // Top if newClientTeamMemberList.length > 0
    SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList = [];
    SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList =
      this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers;
    //  <===== Client Team Member Validation Process End  =====>

    //  <===== GT Team Member Validation Process Start  =====>
    this.TeamMemberManipulationTrigger = true;
    this.cmsDataMatch = false;
    this.IsValidInput = true;
    let ctrlRoleName: string = this.teamMemberRoleName + '_';
    if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus && this.isMemberFirm) {
      ctrlRoleName = this.userObject?.memberFirmIndusConsentShortRoleName + '_';
    }
    else if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus && this.memberFirmData?.memberFirmMappingId > 0) {
      ctrlRoleName = this.memberFirmData.indusConsentShortRoleName + "_";
    }
    else if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {
      ctrlRoleName = 'IC_';
    }

    if (this.newTeamMemberList.length > 0) {
      for (let t = 0; t < this.newTeamMemberList.length; t++) {
        this.arrValidationForNewTeamMember[t].userName = $('#txtPeoplePicker_' + ctrlRoleName + t).val();
        this.arrValidationForNewTeamMember[t].memberRoleId = this.isAudit ? $('#drpKeyTeamMemberRole_' + t).val() : $('#drpTeamMemberRole_' + t).val();
        this.arrValidationForNewTeamMember[t].memberEmailId = $('#UserHiddenProps_' + ctrlRoleName + t + ' > #hdnUserEmail').val();
        this.arrValidationForNewTeamMember[t].memberPermissionId = $('#drpTeamMemberPermissions_' + t).val();
        this.arrValidationForNewTeamMember[t].ishdnValidInputName = (/true/i).test($('#hdnValidInput_' + ctrlRoleName + t).val());
        this.arrValidationForNewTeamMember[t].isValidInput = true;
        this.arrValidationForNewTeamMember[t].cmsDataMatch = false;


        if (this.arrValidationForNewTeamMember[t].userName === '') {
          $('#errorLabelMemberName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelMemberName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewTeamMember[t].memberRoleId === null) {
          $('#errorLabelMemberRole' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelMemberRole' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewTeamMember[t].memberPermissionId === null) {
          $('#errorLabelMemberPermission' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelMemberPermission' + t).attr('style', 'display:none');
        }

        if (this.arrValidationForNewTeamMember[t].userName === '' || this.arrValidationForNewTeamMember[t].memberRoleId === null
          || this.arrValidationForNewTeamMember[t].memberPermissionId === null || this.arrValidationForNewTeamMember[t].ishdnValidInputName == false) {
          this.IsValidInput = false;
        } else { this.IsValidInput = true; }
      }
      // for loop to check duplicate
      for (let t = 0; t < this.arrValidationForNewTeamMember.length; t++) {
        if (this.CheckDuplicateElement(this.arrValidationForNewTeamMember, 'memberEmailId', this.arrValidationForNewTeamMember[t].memberEmailId)) {
          this.arrValidationForNewTeamMember[t].isNewUserDuplicate = true;
          this.arrValidationForNewTeamMember[t].isValidInput = false;
          this.IsValidInput = false;
        }

      }

      if (this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers != undefined) {
        for (let j = 0; j < this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers.length; j++) {
          for (let t = 0; t < this.newTeamMemberList.length; t++) {
            // check if new team member name is matching with existing assignment team member name.
            if ($('#UserHiddenProps_' + ctrlRoleName + t + ' > #hdnUserEmail').val() === this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers[j].memberEmailId
              && this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers[j].collaborationSiteId == siteId) {
              this.cmsDataMatch = true;
              this.arrValidationForNewTeamMember[t].cmsDataMatch = true;
            }
          }
        }
      }

      if (this.IsValidInput && !this.cmsDataMatch) {
        if (this.newTeamMemberList != null && this.newTeamMemberList.length > 0) {
          for (let t = 0; t < this.newTeamMemberList.length; t++) {
            let selectedPermissionId = 0;
            if ($('#drpTeamMemberPermissions_' + t + ' option:selected').val() != null) {
              selectedPermissionId = parseInt($('#drpTeamMemberPermissions_' + t + ' option:selected').val());
              this.newTeamMemberList[t].memberPermissionId = selectedPermissionId;
            }

            if (this.arrValidationForNewTeamMember[t].memberEmailId != ""
              && this.arrValidationForNewTeamMember[t].ishdnValidInputName == true) {
              let ADGroupUsersSyncCollabMember: ADGroupUsersSyncCollabMembers = {
                collaborationSiteMembersId: 0,
                collaborationSiteId: siteId,
                userName: $('#UserHiddenProps_' + ctrlRoleName + t + ' #hdnUserName').val(),
                memberEmailId: $('#UserHiddenProps_' + ctrlRoleName + t + ' #hdnUserEmail').val(),
                memberRoleId: this.isAudit ? this.getTeamMemberRoleIdByCode('P') : Number($('#drpTeamMemberRole_' + t + ' option:selected').val()),
                groupName: this.newTeamMemberList[t].groupName,
                jobtitle: this.newTeamMemberList[t].jobtitle,
                serviceLine: this.newTeamMemberList[t].serviceLine,
                memberPermissionId: this.newTeamMemberList[t].memberPermissionId,
                validateUser: this.newTeamMemberList[t].validateUser,
                keyMemberRoleId: this.newTeamMemberList[t].keyMemberRoleId,
                isKeyTeamMember: this.newTeamMemberList[t].isKeyTeamMember,
                description: this.newTeamMemberList[t].description
              }
              this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers.push(ADGroupUsersSyncCollabMember);
            }

          }
          this.newTeamMemberList = [];
          this.arrValidationForNewTeamMember = [];
        }
      } // end check for cmsmatchdata and valid input
    }
    SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList = [];
    SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList = this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers;
    // GT Team Member Validation Process End
    SiteMembersWithClientMemberModel.collaborationSiteMsTeamUrl =
      this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.msTeamUrl;
    // Validate GT Team Member with INDUS Consent groups then start process to update Sharepoint and Gagege DB
    this.ValidateINDUSCansentAndProcessTeamMember(index, siteId, SiteMembersWithClientMemberModel);
  }

  ProcessUpdatePermissionsWithCollSite(index, SiteMembersWithClientMemberModel: CollaborationSiteMembersWithClientMemberModel) {

    let onwwerCount: number = 0;
    if (this.IsValidInput && !this.cmsDataMatch && this.IsValidInputClient && !this.cmsDataMatchClient) {
      // Validation success
      let updatePermissionsModelList: UpdatePermissionsModel[] = [];
      //1. <== Start process to build sharepoint update permission modal ====>

      // Get List of Groups based on Permission Drop down(GROUP By) //
      let siteMembersGroups = SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.reduce((r, a) => {
        r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
        return r;
      }, {});

      console.log("siteMembersGroups", siteMembersGroups);
      let onwwerList: ADGroupUsersSyncCollabMembers[] = [];
      let memberList: ADGroupUsersSyncCollabMembers[] = [];
      let siteGroupTypes = Object.keys(siteMembersGroups);

      siteGroupTypes.forEach(type => {
        if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
          onwwerList = SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.filter(member =>
            Number(member.memberPermissionId) === Number(type));
        } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
          memberList = SiteMembersWithClientMemberModel.adGroupUsersSyncCollabMembersList.filter(member =>
            Number(member.memberPermissionId) === Number(type));
        }
      })
      onwwerCount = onwwerList.length;
      // Atleast two site onwer are mandatory for GT team member //
      if ((onwwerCount <= 1) && (SiteMembersWithClientMemberModel.collaborationSiteMsTeamUrl == null)) {
        this.loadingScreenService.stopLoading();
        $('#member-save-validation').removeClass('d-none');
      }
      else {
        // Proceed further if onwwerCount greater than 2  //
        $('#member-save-validation').addClass('d-none');

        // Collect site onwer email List //
        let siteOwnerEmailIds: string[] = [];
        onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

        // Collect site member email List //
        let siteMemberEmailIds: string[] = [];
        memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

        let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
        let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
        let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

        OwnerListEmailModel.groupType = 'Owner';
        OwnerListEmailModel.groupUsersEmail = [];
        siteOwnerEmailIds.forEach(emailid => {
          let adUser: ADUser = new ADUser();
          adUser.firstName = '';
          adUser.lastName = '';
          adUser.userEmailId = emailid;
          OwnerListEmailModel.groupUsersEmail.push(adUser);
        })

        MemberListEmailModel.groupType = 'Member';
        MemberListEmailModel.groupUsersEmail = [];
        siteMemberEmailIds.forEach(emailid => {
          let adUser: ADUser = new ADUser();
          adUser.firstName = '';
          adUser.lastName = '';
          adUser.userEmailId = emailid;
          MemberListEmailModel.groupUsersEmail.push(adUser);
        })

        collbSiteUsersUpdatePermissionModel.siteType = "CollabrationSite";
        collbSiteUsersUpdatePermissionModel.siteUniqueID = this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.siteUniqueID;
        collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
        collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
        updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);
        //1. <== Start process for CollabrationSite ===================================>

        //2. <== Start process for ClientSite =========================================>

        // Get List of Groups based on Permission Drop down(R/RW/RWD) //
        let clientSiteMembersGroups = SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList.reduce((r, a) => {
          r[a.permissionId] = [...r[a.permissionId] || [], a];
          return r;
        }, {});

        console.log("clientSiteMembersGroups", clientSiteMembersGroups);

        let RPermissionUserList: ADClientSiteUsersSyncEngageClientMmembers[] = [];
        let RWPermissionUserList: ADClientSiteUsersSyncEngageClientMmembers[] = [];
        let RWDPermissionUserList: ADClientSiteUsersSyncEngageClientMmembers[] = [];

        let clientSiteGroupTypes = Object.keys(clientSiteMembersGroups);

        clientSiteGroupTypes.forEach(type => {
          if ((this.clientMemberPermissionModelList.filter(permission => permission.id == Number(type))[0].code) === 'R') {
            RPermissionUserList = SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList.filter(member =>
              Number(member.permissionId) === Number(type));
          } else if ((this.clientMemberPermissionModelList.filter(pr => pr.id == Number(type))[0].code) === 'RW') {
            RWPermissionUserList = SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList.filter(member =>
              Number(member.permissionId) === Number(type));
          } else if ((this.clientMemberPermissionModelList.filter(pr => pr.id == Number(type))[0].code) === 'RWD') {
            RWDPermissionUserList = SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList.filter(member =>
              Number(member.permissionId) === Number(type));
          }
        })

        // Assemble UpdatePermissionModel  //
        let clientSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
        let RUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();
        let RWUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();
        let RWDUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();

        // Collect client site READ Permission User Email ID List //
        RUserEmailIdModel.groupType = 'R';
        RUserEmailIdModel.groupUsersEmail = [];
        RPermissionUserList.forEach(rUser => {
          let adUser: ADUser = new ADUser();
          adUser.firstName = rUser.firstName;
          adUser.lastName = rUser.lastName;
          adUser.userEmailId = rUser.emailId;
          RUserEmailIdModel.groupUsersEmail.push(adUser);
        })

        // Collect client site READ/WRITE Permission User Email ID List //
        RWUserEmailIdModel.groupType = 'RW';
        RWUserEmailIdModel.groupUsersEmail = [];
        RWPermissionUserList.forEach(rwUser => {
          let adUser: ADUser = new ADUser();
          adUser.firstName = rwUser.firstName;
          adUser.lastName = rwUser.lastName;
          adUser.userEmailId = rwUser.emailId;
          RWUserEmailIdModel.groupUsersEmail.push(adUser);
        })

        // Collect client site READ/WRITE/DELETE Permission User Email ID List //
        RWDUserEmailIdModel.groupType = 'RWD';
        RWDUserEmailIdModel.groupUsersEmail = [];
        RWDPermissionUserList.forEach(rwdUser => {
          let adUser: ADUser = new ADUser();
          adUser.firstName = rwdUser.firstName;
          adUser.lastName = rwdUser.lastName;
          adUser.userEmailId = rwdUser.emailId;
          RWDUserEmailIdModel.groupUsersEmail.push(adUser);
        })

        clientSiteUsersUpdatePermissionModel.siteType = 'ClientSite';
        clientSiteUsersUpdatePermissionModel.siteUniqueID = this.collaborationSiteViewModelList[index].collaborationClientSite.siteUniqueID; //collaborationClientSite.siteUniqueID;
        clientSiteUsersUpdatePermissionModel.usersListEmail.push(RUserEmailIdModel);
        clientSiteUsersUpdatePermissionModel.usersListEmail.push(RWUserEmailIdModel);
        clientSiteUsersUpdatePermissionModel.usersListEmail.push(RWDUserEmailIdModel);

        // // Push mode into actual object collection  //
        // updatePermissionsModelList.push(clientSiteUsersUpdatePermissionModel);
        //2. <== End process for ClientSite =========================================>
        console.log('updatePermissionsModelList', updatePermissionsModelList);

        console.log('stringify == updatePermissionsModelList ==>', JSON.stringify(updatePermissionsModelList));

        //4. Finally Call Active Directory API to update latest collaborationSite Member Ids //
        let updatePermissionsModelDataWithINDUSConsent: UpdatePermissionsModelDataWithINDUSConsent = new UpdatePermissionsModelDataWithINDUSConsent();
        updatePermissionsModelDataWithINDUSConsent.updatePermissionsModel = updatePermissionsModelList;
        updatePermissionsModelDataWithINDUSConsent.iNDUSConsent = SiteMembersWithClientMemberModel.iNDUSConsent;

        console.log('updatePermissionModel', updatePermissionsModelDataWithINDUSConsent);
        this._activedirectoryService.UpdatePermissionsWithClientUsers(updatePermissionsModelDataWithINDUSConsent).subscribe(
          updatedResult => {
            console.log('updatedResult', updatedResult);

            if (updatedResult.status.toLowerCase().trim() === 'success') {

              // //Bind invitationurl for Client Members received from AzureActiveDirectory based on matchced Email ID
              // if (updatedResult.clientUsersList !== undefined && updatedResult.clientUsersList !== null) {
              //   SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList.forEach(modalMember => {
              //     updatedResult.clientUsersList.forEach(aduser => {
              //       if (aduser.userEmailId === modalMember.emailId) {
              //         modalMember.invitationurl = aduser.invitationurl;
              //       }
              //     })
              //   })
              // }

              //5. Once member got update then call SP to update same into  Engage //

              //Exclude already removed client team members from this collection so it wont be saved again in Db when sync is pending.
              SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList = SiteMembersWithClientMemberModel.adClientSiteUsersSyncEngageClientMmembersList.filter(x => x.status != "Removed");

              this.engagementService.UpdateCollaborationSiteMembers(SiteMembersWithClientMemberModel).subscribe(
                updatedClientTeams => {
                  console.log('updatedClientTeams', updatedClientTeams);
                  this.getOwnersMembersFromSharepointSiteActiveDirectoryGroups();
                  if (updatedClientTeams.toLowerCase().trim() === 'success') {
                    this.newTeamMemberList = [];
                    this.newClientTeamMemberList = [];
                    //set second parameter as true or admin & indus staffing managers role
                    let isAdminAccestoGetEngData: string = 'no';
                    if (this.isloggedInUserAdmin ||
                      (!this.isloggedInUserAdmin && this.isEngDetailINDUSStaffingManagersHaveAccess)) {
                      isAdminAccestoGetEngData = 'yes';
                    }

                    this.CallPowerAutomateToProcessClientMemberNotification(SiteMembersWithClientMemberModel.collaborationSiteId);
                    this.engagementService.getEngagementCollaborationsById(this.engagementId, isAdminAccestoGetEngData)
                      .subscribe(
                        result => {
                          this.engagementCollaborationsViewModel = result;
                          $('#member-save-success').removeClass('d-none');
                          this.applyFilterOnceAfterDataLoad = true;
                          this.engagementList = this.engagementCollaborationsViewModel.engagement;
                          if (this.engagementList.status === EngagementStatus.EngActive) {
                            this.isEngagementCloseoutDisable = false;
                          }
                          else {
                            this.isEngagementCloseoutDisable = true;
                          }
                          this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
                          this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
                          this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
                          this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
                          this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
                          this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
                          this.CreateUIForCollaborationSpace();

                          //Start: Update the client member details in UI
                          let siteId: number;
                          if (this.collaborationSiteViewModelList[index].collaborationSite !== undefined) {
                            siteId = this.collaborationSiteViewModelList[index].collaborationSite.collaborationSiteId;
                          }

                          this._activedirectoryService.GetUsersFromADGroup(this.collaborationSiteViewModelList[index].collaborationSite.siteUniqueID).subscribe(
                            result => {
                              let ADGroupUsersList: ADGroupUsers[] = result;
                              console.log('ADGroupUsersModelList', ADGroupUsersList);
                              if (ADGroupUsersList !== undefined && ADGroupUsersList != null && ADGroupUsersList.length >= 2) {
                                let ClientSiteUserModel: ADGroupUsers = ADGroupUsersList[1];
                                this.SyncClientTeamMemberDetails(siteId, ClientSiteUserModel);
                                this.loadingScreenService.stopLoading();
                              }
                            },
                            error => {
                              this.loadingScreenService.stopLoading();
                            });
                          //End: Update the client member details in UI
                        })

                  } else if (updatedClientTeams === 'Failure') {
                    this.loadingScreenService.stopLoading();
                    this.alertServiceFun('Collaboration Site Member(s)/client team member(s) could not be updated !', 'danger');
                  }
                },
                error => {
                  this.loadingScreenService.stopLoading();
                  this.alertServiceFun('Collaboration Site Member(s)/client team member(s) could not be updated !', 'danger');
                }
              );

              this.GenerateIndusConsentEvent(SiteMembersWithClientMemberModel.iNDUSConsent);
            }
            else {
              this.loadingScreenService.stopLoading();
              this.GenerateIndusConsentEvent(SiteMembersWithClientMemberModel.iNDUSConsent);
              this.alertServiceFun('Error while updating user permission, Please contact system administrator.', 'danger');
            }
          },
          error => {
            this.loadingScreenService.stopLoading();
            this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
          });
      }
    }  // Validation success End
    else {
      //Validation Failed
      this.loadingScreenService.stopLoading();
    }
  }


  UpdateCollaborationClientTeamMember(index: number, siteId: number) {
    this.newClientTeamMemberList = [];
    this.TeamMemberManipulationTrigger = true;
    for (let t = 0; t < this.collaborationSiteViewModelList[index].clientTeamMemberList.length; t++) {
      this.collaborationSiteViewModelList[index].clientTeamMemberList[t].title = $('#txtUpdateClientTitle' + t).val();
      this.collaborationSiteViewModelList[index].clientTeamMemberList[t].permissionId = $('#clientTeamMemberPermissions_Row_' + t).val();
      this.collaborationSiteViewModelList[index].clientTeamMemberList[t].updatedBy = this.userObject.userName;
    }
    // service call to update client team members in db
    this.loadingScreenService.startLoading();
    this.engagementService.UpdateClientTeamMembers(this.collaborationSiteViewModelList[index]).subscribe(
      updatedClientTeams => {
        this.loadingScreenService.stopLoading();
        if (updatedClientTeams === 'Success') {

          this.getClientTeamMembersByEngagementId(this.engagementList.engagementId, index);

        } else if (updatedClientTeams === 'Failure') {
          this.alertServiceFun('client team member(s) could not be updated !', 'danger');
        }
      },
      () => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('client team member(s) could not be updated !', 'danger');
      }
    );
  }

  getClientTeamMembersByEngagementId(engId: number, index: number) {
    this.loadingScreenService.startLoading();
    return this.engagementService.GetClientTeamMembersByEngagementId(engId)
      .subscribe(
        clientTeams => {
          this.loadingScreenService.stopLoading();
          this.clientTeamMemberList = clientTeams;
          console.log(this.clientTeamMemberList);
          this.CreateUIForCollaborationSpace();
          setTimeout(() => {
            this.enableDraggable(false);
          }, 100);
        },
        () => {
          this.clientTeamMemberList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }
  // end client team member section
  private CheckDuplicateElementInCollaboration(array, property1, value1, property2, value2) {
    let foundElements = [];
    let elCount = 0;
    let isDuplicate = false;
    for (let element of array) {
      if (element[property1] === value1 && element[property2] === value2) {
        elCount = elCount + 1;
        if (elCount === 1) {
          foundElements.push(element);
        } else if (elCount > 1) {
          isDuplicate = true;
          break;
        }
      }
    }

    return isDuplicate;
  }

  private CheckDuplicateElement(array, property, value) {
    let foundElements = [];
    let elCount = 0;
    let isDuplicate = false;
    for (let element of array) {
      if (element[property] === value) {
        elCount = elCount + 1;
        if (elCount === 1) {
          foundElements.push(element);
        } else if (elCount > 1) {
          isDuplicate = true;
          break;
        }
      }
    }
    return isDuplicate;
  }

  // date comparison method
  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
 */
  private compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }


  private compareDateString(date1: string, date2: string): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) {
      return 0;
    }

    // Check if the first is greater than second
    if (d1 > d2) {
      return 1;
    }

    // Check if the first is less than second
    if (d1 < d2) {
      return -1;
    }
  }

  getAllDocumentsfromSPLibrary(siteUrl: string, documentFolderPath: string) {
    this.loadingScreenService.startLoading();
    this.sharepointService.getAllDocumentsfromSPLibrary(siteUrl, documentFolderPath).subscribe(documentLibrary => {
      this.spDocumentList = documentLibrary;
      this.attacheLabelToFiles();
      if (this.spDocumentList && this.spDocumentList.length > 0) {
        this.spDocumentList.sort((a, b) => {
          return (Date.parse(this.datepipe.transform(b.modifiedDate, 'MM/dd/yyyy')) - Date.parse(this.datepipe.transform(a.modifiedDate, 'MM/dd/yyyy'))) || a.documentName.localeCompare(b.documentName);
        });
        this.selectedDocumentChecking();
      }
      console.log(this.spDocumentList);
      this.loadingScreenService.stopLoading();
    },
      err => {
        this.spDocumentList = [];
        this.loadingScreenService.stopLoading();
      })
  }

  ChangeSignatureType() {
    this.isEmptySignatureHandlerName = false;
    this.isInvalidSignatureHandler = false;
    this.isEmptyPrintedSinger = false;
    this.isEmptyPrintedDate = false;
    this.isFutureDateSelected = false;
    this.hasNoPrintedDocumentSelected = false;
    this.IsSigningReqeuestFailed = false;
    this.isPrintedSectionESignDiscardFailed = false;
    if ((this.initialEngagementESignature.signatureTypeCode == 'E' && this.engagementESignature.signatureTypeCode == 'E')
      || (this.initialEngagementESignature.signatureTypeCode == 'P' && this.engagementESignature.signatureTypeCode == 'P')) {
      this.engagementESignature.sharePointDocuments = [...this.initialEngagementESignature.sharePointDocuments];
      this.engagementESignature.handlerEmail = this.initialEngagementESignature.handlerEmail;
      this.engagementESignature.handlerName = this.initialEngagementESignature.handlerName;
      this.engagementESignature.createDate = this.initialEngagementESignature.createDate;
      this.spDocumentList.forEach(doc => {
        const isMatched = this.engagementESignature.sharePointDocuments.findIndex(x => x.sharePointDocumentId == doc.documentId);
        doc.isChecked = isMatched != -1 ? true : false;
      });
    }
    else if (this.initialEngagementESignature.signatureTypeCode == 'P' && this.engagementESignature.signatureTypeCode == 'E') {
      this.engagementESignature.sharePointDocuments = [];
      this.engagementESignature.handlerEmail = null;
      this.engagementESignature.handlerName = null;
      this.engagementESignature.createDate = null;
      this.engagementESignature.envelopeId = '';
      this.spDocumentList.forEach(doc => {
        doc.isChecked = false
      });
    }
    else {
      this.engagementESignature.sharePointDocuments = [];
      this.engagementESignature.handlerEmail = null;
      this.engagementESignature.handlerName = null;
      this.engagementESignature.createDate = null;
      this.spDocumentList.forEach(doc => {
        doc.isChecked = false
      });
    }
  }

  DocumentChanged(documentId) {
    var selectedDocument = this.spDocumentList.filter(doc => doc.documentId == documentId)[0];
    var isdocumentAlreadyPresent = 0;
    var sharepointDocModel = new SharePointDocuments();

    if (selectedDocument) {
      sharepointDocModel.sharePointDocumentId = selectedDocument.documentId;
      sharepointDocModel.sharePointDocumentName = selectedDocument.documentName;
      isdocumentAlreadyPresent = this.engagementESignature.sharePointDocuments.findIndex(x => x.sharePointDocumentId == documentId);
      if (isdocumentAlreadyPresent == -1) {
        this.engagementESignature.sharePointDocuments.push(sharepointDocModel);
      }
      else {
        this.engagementESignature.sharePointDocuments.splice(isdocumentAlreadyPresent, 1);

      }
      this.selectedDocumentChecking();

    }
  }


  PrintDocument() {
    if (this.engagementESignature.sharePointDocuments.length > 0) {
      this.hasNoPrintedDocumentSelected = false;
      this.engagementESignature.sharePointDocuments.forEach(document => {
        var selectedDocument = this.spDocumentList.filter(doc => doc.documentId == document.sharePointDocumentId)[0];
        if (selectedDocument) {

          if (selectedDocument.type == 'docx' || selectedDocument.type == 'doc'
            || selectedDocument.type == 'pptx' || selectedDocument.type == 'ppt'
            || selectedDocument.type == 'pdf' || selectedDocument.type == 'xlsx' || selectedDocument.type == 'xls') {
            setTimeout(function () { window.open(selectedDocument.hyperLink, '_blank') }, 500);
          }
          else {
            setTimeout(function () { window.open(selectedDocument.documentUrl, '_blank') }, 500);
          }
        }
      });
    } else {
      this.hasNoPrintedDocumentSelected = true;
    }
  }

  refreshSignatureHandlerPopup(index, textboxName: string) {
    this.removeRowSelection('tblSignatureHanderMemberAD', 'searchSignatureHandlerName', 'SignatureHandlerErrorMessage');
    this.signatureHandlerCntrl = textboxName + this.signatureHandlerRoleName + '_' + this.peoplepickerIndex;
    this.validateSignatureHandler = false;
    this.selectedSignatureIndex = null;
    this.signatureHandlers = null;
    if ($('#' + this.signatureHandlerCntrl).hasClass('text-danger')) {
      $('#' + this.signatureHandlerCntrl).removeClass('text-danger');
    }
  }

  SelectSignatureHandler(index) {
    $('#tblSignatureHanderMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedSignatureIndex = index;
    console.log('select signature handler func called ', this.selectedSignatureIndex);
  }

  DeselectSignatureHandler() {
    $('#' + this.signatureHandlerCntrl).val('');
    $('#' + this.signatureHandlerCntrl).innerText = '';
    this.signatureHandlers = null;
  }

  SetSignatureHandler() {
    let index = this.selectedSignatureIndex;
    if (Number(index) >= 0) {
      const selectedSignatureName = $('#spSignatureHandlerDisplayName' + index).text();
      const selectedSignatureEmail = $('#spSignatureHandlerMail' + index).text();
      $('#' + this.signatureHandlerCntrl).val(selectedSignatureName);

      if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {
        $('#UserHiddenProps_' + this.INDUSRoleName + '_0, #hdnUserName').val(selectedSignatureName);
        $('#UserHiddenProps_' + this.INDUSRoleName + '_0, #hdnUserEmail').val(selectedSignatureEmail);
        $('#UserHiddenProps_' + this.INDUSRoleName + '_0, #hdnUserPhone').val(selectedSignatureEmail);
        $('#UserHiddenProps_' + this.INDUSRoleName + '_0, #hdnJobTitle').val(selectedSignatureEmail);
        $('#hdnValidInput_' + this.INDUSRoleName + '_' + this.peoplepickerIndex).val(true);
      }
      else {
        $('#UserHiddenProps_' + this.teamMemberRoleName + '_0, #hdnUserName').val(selectedSignatureName);
        $('#UserHiddenProps_' + this.teamMemberRoleName + '_0, #hdnUserEmail').val(selectedSignatureEmail);
        $('#UserHiddenProps_' + this.teamMemberRoleName + '_0, #hdnUserPhone').val(selectedSignatureEmail);
        $('#UserHiddenProps_' + this.teamMemberRoleName + '_0, #hdnJobTitle').val(selectedSignatureEmail);
        $('#hdnValidInput_' + this.teamMemberRoleName + '_' + this.peoplepickerIndex).val(true);
      }

      $("#SignatureHandlerErrorMessage").text('');
      if (this.signatureHandlerCntrl !== '') {
        this.validateSignatureHandler = true;
      }
      else {
        $("#SignatureHandlerErrorMessage").text('Selected signature handler is already added');
      }
    }
    this.signatureHandlers = null;
  }

  CancelHandlerSelection() {
    let signatureHandlerPeoplePickerTxtId = '#txtPeoplePicker_' + this.signatureHandlerRoleName + '_' + this.peoplepickerIndex;
    $(signatureHandlerPeoplePickerTxtId).val('')
    this.showElectronicSignerName = true;
  }

  ShowSelectedSignatureHandler() {
    let signatureHandlerPeoplePickerTxtId = '#txtPeoplePicker_' + this.signatureHandlerRoleName + '_' + this.peoplepickerIndex;
    let signatureHandlerPeoplePickerHdnIsValidFlag = '#hdnValidInput_' + this.signatureHandlerRoleName + '_' + this.peoplepickerIndex;
    if ($(signatureHandlerPeoplePickerTxtId).val() == this.engagementESignature.handlerName && $(signatureHandlerPeoplePickerHdnIsValidFlag).val() == 'false') {
      this.showElectronicSignerName = true;
      this.isEmptySignatureHandlerName = false;
      this.isInvalidSignatureHandler = false;
      return;
    }
    else if ($(signatureHandlerPeoplePickerTxtId).val() == '') {
      this.isEmptySignatureHandlerName = true;
      this.showElectronicSignerName = false;
      return;
    }
    else if ($(signatureHandlerPeoplePickerTxtId).val() != '' && $(signatureHandlerPeoplePickerHdnIsValidFlag).val() == 'false') {
      this.isInvalidSignatureHandler = true;
      this.isEmptySignatureHandlerName = false;
      return;
    }
    else if ($(signatureHandlerPeoplePickerTxtId).val() != '' && $(signatureHandlerPeoplePickerHdnIsValidFlag).val() == 'true') {
      this.engagementESignature.handlerEmail = $('#hdnUserEmail').val();
      this.engagementESignature.handlerName = $('#hdnUserName').val();
      this.showElectronicSignerName = true;
      this.isEmptySignatureHandlerName = false;
      this.isInvalidSignatureHandler = false;
    }
  }

  SetDiscardRemark() {
    if (!this.initialEngagementESignature.engagmentId || this.initialEngagementESignature.engagmentId == 0
      || this.initialEngagementESignature.envelopeId == null || !this.initialEngagementESignature.envelopeId || this.initialEngagementESignature.envelopeId == '') {
      this.engagementESignature.isRemoved = false;
      return;
    }
    this.engagementESignature.isRemoved = true;
    //Check if signature type has changed
    if (this.initialEngagementESignature.signatureTypeCode != this.engagementESignature.signatureTypeCode
      && (this.initialEngagementESignature.signatureTypeCode == 'P' && this.engagementESignature.signatureTypeCode == 'E')) {
      this.engagementESignatureRequestModel.discardRemark = SigningRequestDiscardRemark.SignatureTypeChangedToElectronic;
      this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignatureTypeChangedToElectronic;
      return;
    }

    if (this.initialEngagementESignature.signatureTypeCode != this.engagementESignature.signatureTypeCode
      && (this.initialEngagementESignature.signatureTypeCode == 'E' && this.engagementESignature.signatureTypeCode == 'P')) {
      this.engagementESignatureRequestModel.discardRemark = SigningRequestDiscardRemark.SignatureTypeChangedToPrinted;
      this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignatureTypeChangedToPrinted;
      return;
    }

    //Check if signing handler and document to sign have been changed
    if (this.initialEngagementESignature.handlerEmail != this.engagementESignature.handlerEmail
      && !this.DocumentsValidation()) {
      this.engagementESignatureRequestModel.discardRemark = SigningRequestDiscardRemark.SigningHandlerAndDocumentToSignChanged;
      this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SigningHandlerAndDocumentToSignChanged;
      return;
    }

    //Check if signing handler has changed
    if (this.initialEngagementESignature.handlerEmail != this.engagementESignature.handlerEmail) {
      this.engagementESignatureRequestModel.discardRemark = SigningRequestDiscardRemark.SigningHandlerChanged;
      this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SigningHandlerChanged;
      return;
    }
    //Check if document to sign has changed
    if (!this.DocumentsValidation()) {
      this.engagementESignatureRequestModel.discardRemark = SigningRequestDiscardRemark.DocumentToSignChanged;
      this.engagementESignature.removedRemark = SigningRequestDiscardRemark.DocumentToSignChanged;
      return;
    }
  }

  ShowSignatureHandlerPeoplePicker() {
    this.showElectronicSignerName = false;
  }

  SendToHandler() {
    this.loadingScreenService.startLoading();
    //Validate for required fields
    //Set properties if already requested for signing
    if (this.initialEngagementESignature.envelopeId
      && this.initialEngagementESignature.envelopeId != null
      && this.initialEngagementESignature.envelopeId.length > 0) {

      if (this.initialEngagementESignature.envelopeStatusCode != 'Voided') {
        this.engagementESignatureRequestModel.envelopeId = this.initialEngagementESignature.envelopeId;
      }

      this.SetDiscardRemark();
    }
    else if (this.initialEngagementESignature.signatureTypeCode == 'P') {
      this.engagementESignatureRequestModel.envelopeId = '';
    }

    //Set signature handler
    let signatureHandler = new MembersList();
    signatureHandler.mail = this.engagementESignature.handlerEmail;
    signatureHandler.displayName = this.engagementESignature.handlerName;
    this.engagementESignatureRequestModel.signatureHandler = signatureHandler;
    this.engagementESignatureRequestModel.envelopeSubject = `${this.engagementList.clientName} - ${this.engagementList.engagementName} engagement with Grant Thornton (US)`;
    this.engagementESignatureRequestModel.acceptanceFolderPath = this.engagementList.acceptanceFolderPath;
    //Set document to sign
    this.engagementESignatureRequestModel.selectedDocument = [];
    this.engagementESignature.sharePointDocuments.forEach(x => {
      var selectedDocumentId = this.spDocumentList.find(doc => doc.documentId == x.sharePointDocumentId);
      this.engagementESignatureRequestModel.selectedDocument.push(selectedDocumentId);
    });

    //Set signing members list
    this.engagementESignatureRequestModel.membersList = [];
    // var counter = 0;
    // var workspaceDocumentExists = new WorkspaceDocumentViewModel();
    //  this.engagementESignatureRequestModel.selectedDocument.forEach(doc => {
    this.esignatureService.GetESigners(this.engagementList.engagementId, "Not used/future scope").subscribe(esignersResult => {
      // counter = counter + 1;
      // workspaceDocumentExists = this.workspaceDocumentList.find(docWorkspace => docWorkspace.documentAliasName + "." + doc.type == doc.documentName);
      if (esignersResult != null && esignersResult.length > 0)
      // && workspaceDocumentExists != null && workspaceDocumentExists.isPrimaryWorkspaceId) 
      {
        esignersResult.forEach(esigner => {
          this.signingMember = new SignatureMember();
          this.signingMember.displayName = esigner.signerName;
          this.signingMember.mail = esigner.signerEmail;
          this.signingMember.mobilePhone = '';
          this.signingMember.businessPhones = '';
          this.signingMember.jobTitle = esigner.signerRole;
          this.signingMember.lineOfBusiness = '';
          this.signingMember.serviceLine = '';
          this.signingMember.location = '';
          this.signingMember.employeeId = '';
          this.engagementESignatureRequestModel.membersList.push(this.signingMember);
        });
      }
      this.esignatureService.GetDocuSignTemplate(this.engagementList.engagementId).subscribe(docuSignResult => {
        if (docuSignResult != null && docuSignResult.length > 0) {
          this.engagementESignatureRequestModel.docuSignTemplateList = [];
          docuSignResult.forEach(docusignTemplates => {
            this.docuSignTemplate = new DocuSignTemplateList();
            this.docuSignTemplate.TemplateId = docusignTemplates.templateId;
            this.engagementESignatureRequestModel.docuSignTemplateList.push(this.docuSignTemplate);
          });
        }
        // if (counter == this.engagementESignatureRequestModel.selectedDocument.length) {
        //Send to sign document
        this.IsSigningReqeuestFailed = false;
        this.esignatureService.SignDocument(this.engagementESignatureRequestModel).subscribe((result: EngagementESignatureResponseModel) => {
          if ((result.errorCode == null
            || (result.errorCode == 'ENVELOPE_CANNOT_VOID_INVALID_STATE' || result.errorCode == 'ENVELOPE_DOES_NOT_EXIST'))
            && result.envelopeId != '') {

            this.engagementESignature.engagmentId = this.engagementList.engagementId;
            this.engagementESignature.envelopeId = result.envelopeId;
            this.engagementESignature.envelopeUri = result.envelopeUri;
            this.engagementESignatureRequestModel.selectedDocument.forEach(doc => { doc.documentBytes = "" });
            this.engagementESignature.payLoad = JSON.stringify(this.engagementESignatureRequestModel);
            this.engagementESignature.createByEmailId = this.userObject.loggedInUserEmail
            this.engagementESignature.createdByName = this.userObject.loggedInUserFullName;
            this.engagementESignature.modifiedBy = this.userObject.loggedInUserEmail;

            if (this.initialEngagementESignature.signatureTypeCode == 'P' && this.engagementESignature.signatureTypeCode == 'E') {
              this.engagementESignature.isRemoved = true;
              this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignatureTypeChangedToElectronic;
            }

            if (result.errorCode == 'ENVELOPE_CANNOT_VOID_INVALID_STATE' || result.errorCode == 'ENVELOPE_DOES_NOT_EXIST') {
              this.engagementESignature.removedRemark += `(${result.errorMessage})`;
            }

            this.esignatureService.AddEngagementESignatureDetails(this.engagementESignature).subscribe(response => {
              //Success
              if (response.id > 0) {
                this.engagementESignature = response;
                this.selectedDocumentChecking();
                this.initialEngagementESignature = JSON.parse(JSON.stringify(response));
                if (this.engagementESignature.handlerEmail != null) {
                  this.UpdatePermissionsForPrimaryCollabrationSiteMember();
                }
                else {
                  this.loadingScreenService.stopLoading();
                }
              }
              else {
                this.loadingScreenService.stopLoading();
              }
            },
              err => {
                this.logService.error('Esignature detail update failed...', err);
                this.loadingScreenService.stopLoading();
              });
          }
          else if (result.errorCode == "404") {
            this.IsSigningReqeuestFailed = true;
            this.SigningErrorMessage = "Signature handler is not a part of DocuSign account";
            this.loadingScreenService.stopLoading();
          }
          else {
            this.IsSigningReqeuestFailed = true;
            this.SigningErrorMessage = "ESignature request failed";
            this.loadingScreenService.stopLoading();
          }

        },
          err => {
            this.IsSigningReqeuestFailed = true;
            this.SigningErrorMessage = "ESignature request failed";
            this.logService.error('Signing document failed...', err);
            this.loadingScreenService.stopLoading();
          });
        // }
      },
        err => {
          this.IsSigningReqeuestFailed = true;
          this.SigningErrorMessage = "DocusignTemplate request failed";
          this.logService.error('Get DocusignTemplate failed...', err);
          this.loadingScreenService.stopLoading();
        });

    }, err => {
      this.IsSigningReqeuestFailed = true;
      this.SigningErrorMessage = "ESignature request failed";
      this.loadingScreenService.stopLoading();
      this.logService.error('Error while retriving ESigners list...', err);
    }
    );

    // });
  }

  UpdatePermissionsForPrimaryCollabrationSiteMember() {
    this.collaborationSiteService.CollaborationSiteMembers(this.primaryCollaborationDetails.collaborationSiteId).subscribe(
      collabrationTeamMemberResult => {
        var primaryCollabrationSiteMemberList = collabrationTeamMemberResult;

        if (primaryCollabrationSiteMemberList != null && primaryCollabrationSiteMemberList.length > 0) {
          let siteMembersGroups = primaryCollabrationSiteMemberList.reduce((r, a) => {
            r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
            return r;
          }, {});

          console.log("PrimeryiteMembersGroups", siteMembersGroups);
          let onwwerList: CollaborationSiteMembers[] = [];
          let memberList: CollaborationSiteMembers[] = [];

          let siteGroupTypes = Object.keys(siteMembersGroups);

          siteGroupTypes.forEach(type => {
            if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
              onwwerList = primaryCollabrationSiteMemberList.filter(member =>
                Number(member.memberPermissionId) === Number(type));
            } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
              memberList = primaryCollabrationSiteMemberList.filter(member =>
                Number(member.memberPermissionId) === Number(type));
            }
          })
          // Site Onwers Email List
          let siteOwnerEmailIds: string[] = [];
          onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

          //Site Members Email List
          let siteMemberEmailIds: string[] = [];
          memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

          let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
          let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
          let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

          OwnerListEmailModel.groupType = 'Owner';
          OwnerListEmailModel.groupUsersEmail = [];
          siteOwnerEmailIds.forEach(emailid => {
            let adUser: ADUser = new ADUser();
            adUser.firstName = '';
            adUser.lastName = '';
            adUser.userEmailId = emailid;
            OwnerListEmailModel.groupUsersEmail.push(adUser);
          })

          MemberListEmailModel.groupType = 'Member';
          MemberListEmailModel.groupUsersEmail = [];
          siteMemberEmailIds.forEach(emailid => {
            let adUser: ADUser = new ADUser();
            adUser.firstName = '';
            adUser.lastName = '';
            adUser.userEmailId = emailid;
            MemberListEmailModel.groupUsersEmail.push(adUser);
          });

          collbSiteUsersUpdatePermissionModel.siteType = "CollabrationSite";
          collbSiteUsersUpdatePermissionModel.siteUniqueID = this.primaryCollaborationDetails.siteUniqueID;
          collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
          collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
          let updatePermissionsModelList: UpdatePermissionsModel[] = [];
          updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);

          //4. Call Active Directory API to update latest collaborationSite Member Ids
          let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
          updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;
          this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
            updateResult => {

              //set second parameter as true or admin & indus staffing managers role
              let isAdminAccestoGetEngData: string = 'no';
              if (this.isloggedInUserAdmin ||
                (!this.isloggedInUserAdmin && this.isEngDetailINDUSStaffingManagersHaveAccess)) {
                isAdminAccestoGetEngData = 'yes';
              }

              this.engagementService.getEngagementCollaborationsById(this.engagementId, isAdminAccestoGetEngData)
                .subscribe(
                  result => {
                    this.engagementCollaborationsViewModel = result;
                    this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
                    this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
                    this.CreateUIForCollaborationSpace();
                    this.loadingScreenService.stopLoading();
                  })
            },
            error => {
              this.loadingScreenService.stopLoading();
              this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
            });
        }
      },
      () => {
        console.log('end');
        this.loadingScreenService.stopLoading();
      }
    );
  }

  ValidatePrintedSigningFields() {
    this.isEmptyPrintedSinger = (this.engagementESignature.handlerName == null || this.engagementESignature.handlerName == '')
    this.isEmptyPrintedDate = $('.printedSigningDate').val() == '';
    this.hasNoPrintedDocumentSelected = !this.engagementESignature.sharePointDocuments.length ? true : false;
    // Check if the selected date is a future date
    const printedSigningDateValue = $('.printedSigningDate').val();
    if (!this.isEmptyPrintedDate) {
      const selectedDate = new Date(printedSigningDateValue);
      const today = new Date();
      if (selectedDate > today) {
        this.isFutureDateSelected = true;
      } else {
        this.isFutureDateSelected = false;
      }
    }
  }

  ProcessPrintedSigningDetails() {
    //Validate for required fields
    this.ValidatePrintedSigningFields();
    //Process if all values are provided
    if (!this.isEmptyPrintedSinger && !this.isEmptyPrintedDate && !this.hasNoPrintedDocumentSelected && !this.isFutureDateSelected) {
      //Set properties if already requested for singing
      this.SetDiscardRemark();
      //Check if previously requested for electronic signature
      if (this.engagementESignature.isRemoved == true && this.initialEngagementESignature.signatureTypeCode == 'E') {
        this.loadingScreenService.startLoading();
        this.esignatureService.DiscardEnvelope(this.initialEngagementESignature.envelopeId, this.engagementESignature.removedRemark).subscribe(response => {
          if (response == null || (response != null && (response.errorCode == 'ENVELOPE_CANNOT_VOID_INVALID_STATE') || response.errorCode == 'ENVELOPE_DOES_NOT_EXIST')) {
            //Success
            this.isPrintedSectionESignDiscardFailed = false;
            this.SavePrintedDocumentDetails(this.engagementESignature.isRemoved, response ? response.message : null);
          }
          else {
            this.isPrintedSectionESignDiscardFailed = true;
            this.loadingScreenService.stopLoading();
          }

        },
          err => {
            this.logService.error('Error while discarding envelope', err);
            this.loadingScreenService.stopLoading();
          });
      }
      else {
        this.engagementESignature.isRemoved = this.initialEngagementESignature.signatureTypeCode == 'P';
        this.SavePrintedDocumentDetails(this.engagementESignature.isRemoved, '');
      }
    }
  }

  CheckCMSRequestStatus(eSignResponse: EngagementESignature) {
    this.workspaceService.SendCMSRequestOnSignature(this.engagementId).subscribe(sendCMSRequestOnSignatureCompletion => {
      if (!sendCMSRequestOnSignatureCompletion) {
        this.requestAssignmentCode(this.CurrentWorkspaceData.workspaceId, null, null, 0, eSignResponse);
      }
    },
      err => {
        if (!this.clmLsDownloadFileFromSharepoitisRunning)
          this.loadingScreenService.stopLoading();
      });
  }
  sendSignedDocumentToCLM() {
    this.clmLsDownloadFileFromSharepoitisRunning = true;
    this.startLoadedeachSecondForSendFile();
    let csdRequest: ClmSignedDocumentRequest = new ClmSignedDocumentRequest();
    const clientdetailsSub = this.clientService.GetClientEditDetailById(this.engagementList.clientId)
    let sdcRequest = new SharepointDocumentContentRequest();
    sdcRequest.documentLibraryName = "Acceptance/Continuance";
    sdcRequest.siteURL = this.engagementList.siteUrl;
    sdcRequest.fileNamesWithPath = [];
    this.engagementESignature.sharePointDocuments.forEach(element => {
      let file = this.spDocumentList.find(doc => doc.documentId == element.sharePointDocumentId);
      let filenamewithPath: string = element.sharePointDocumentName = file.documentUrl.replace(file.siteURL + "/Acceptance%20Documents/", '')
      filenamewithPath = filenamewithPath.replace(/%20/gi, " ");
      let fileType = this.documentListDataFromDB.find(fileDoc => file.documentName.includes(fileDoc.documentAliasName));
      if (fileType != undefined && fileType.templateType == "Full agreement") {
        sdcRequest.fileNamesWithPath.push(filenamewithPath);
      }
    });
    sdcRequest.siteUniqueID = this.primaryCollaborationDetails.siteUniqueID;
    const sharepointDocumentContentSub = this.sharepointService.getSharepointDocumentContent(sdcRequest)
    forkJoin([clientdetailsSub, sharepointDocumentContentSub]).subscribe({
      next: result => {
        let clientDetails: EditClientViewModel = result[0];
        let client = clientDetails.client;
        let docList: CLMDocumentList[] = result[1];
        csdRequest["oneView Account ID"] = client.oneViewAccountId;
        csdRequest.agreementType = "UEL";
        csdRequest.clientName = client.clientName;
        csdRequest.engagementName = this.engagementList.engagementName;
        csdRequest.solutionCode = this.engagementList.solutionCode;
        csdRequest.industryType = client.industryType;
        csdRequest.engagementPPMDEmail = this.engagementList.engagementPartner;
        csdRequest.addressLine1 = client.addressLine1;
        csdRequest.addressLine2 = client.addressLine2;
        csdRequest.addressLine3 = client.addressLine3;
        csdRequest.addressLine4 = client.addressLine4;
        csdRequest.effectiveDate = new Date(this.engagementESignature.createDate);
        csdRequest.expirationDate = null;
        csdRequest.expirationcheckboxvalue = "";
        csdRequest.governmentEntity = "";
        csdRequest.jurisdiction = "";
        csdRequest.city = client.city;
        csdRequest.state = client.stateName;
        csdRequest.zipcode = client.zipCode;
        csdRequest.country = client.countryName;
        csdRequest.contractnotes = ""
        csdRequest.contractRequestorEmail = this.engagementList.engagementPartner;
        csdRequest.engagementID = "" + this.engagementList.engagementId;
        this.sendFileToCLMRecursive(docList, csdRequest);
      }, error: err => {
        this.clmLsDownloadFileFromSharepoitisRunning = false;
        this.loadingScreenService.stopLoading();
        console.error("Some is wrong while sending signed docs to CLM", err);
      }
    })
  }

  sendFileToCLMRecursive(docList: CLMDocumentList[], csdRequest: ClmSignedDocumentRequest) {
    if (docList.length > 0) {
      csdRequest.attachment = docList[0].documentBytes;
      if (docList[0].isSuccess) {
        this.clmService.sendSignedDocumentsToCLM(csdRequest).subscribe({
          next: result => {
            this.sendSignedDocumentToCLMMaxRetries = 0;
            this.insertCLMApiLogSendGA(this.engagementList.engagementId, csdRequest, result, docList[0].documentName, docList[0].type, false);
            docList = docList.slice(1);
            this.sendFileToCLMRecursive(docList, csdRequest);
          }, error: err => {
            if (this.sendSignedDocumentToCLMCurrentRetries < this.sendSignedDocumentToCLMMaxRetries) {
              this.sendFileToCLMRecursive(docList, csdRequest);
              this.sendSignedDocumentToCLMMaxRetries++;
            } else {
              docList = docList.slice(1);
              this.sendSignedDocumentToCLMMaxRetries = 0;
              this.sendFileToCLMRecursive(docList, csdRequest);
            }
            console.error("Error in uploading file to CLM", err, csdRequest);
          }
        });
      } else {
        let errorResult = new CLMResponse();
        errorResult.statusCode = docList[0].statusCode;
        errorResult.htmlData = docList[0].statusMessage; // Setting the error message for CLM api logs
        this.insertCLMApiLogSendGA(this.engagementList.engagementId, csdRequest, errorResult, docList[0].documentName, docList[0].type, true);
        docList = docList.slice(1);
        this.sendFileToCLMRecursive(docList, csdRequest);
      }
    } else {
      this.clmLsDownloadFileFromSharepoitisRunning = false;
      this.loadingScreenService.stopLoading();
    }
  }
  SavePrintedDocumentDetails(isRemoved: boolean, esignDiscardRemark: string) {
    this.loadingScreenService.startLoading();
    let selectedDate = $('.printedSigningDate').val();
    if (selectedDate != '') {
      /// let dateArry = selectedDate.toString().split('/');
      this.engagementESignature.createDate = selectedDate;
      //this.engagementESignature.createDate = new Date(dateArry[2], Number.parseInt(dateArry[0]) - 1, dateArry[1]);
    }
    this.ProcessPrintRequest(isRemoved, esignDiscardRemark);
  }

  ProcessPrintRequest(isRemoved: boolean, esignDiscardRemark: string) {
    this.engagementESignature.createByEmailId = this.userObject.loggedInUserEmail;
    this.engagementESignature.createdByName = this.userObject.loggedInUserFullName;
    this.engagementESignature.engagmentId = this.engagementList.engagementId;
    this.engagementESignature.modifiedBy = this.userObject.loggedInUserEmail;
    this.engagementESignature.isRemoved = isRemoved;

    if (this.initialEngagementESignature.signatureTypeCode == this.engagementESignature.signatureTypeCode) {
      let initDate = new Date(this.initialEngagementESignature.createDate);
      let parsedInitDate = (initDate.getMonth() + 1).toString().padStart(2, '0') + '/' + initDate.getDate().toString().padStart(2, '0') + '/' + initDate.getFullYear().toString();

      let selectedDate = new Date(this.engagementESignature.createDate);
      let ParsedSelectedDate = (selectedDate.getMonth() + 1).toString().padStart(2, '0') + '/' + selectedDate.getDate().toString().padStart(2, '0') + '/' + selectedDate.getFullYear().toString();

      if (parsedInitDate != ParsedSelectedDate
        && this.initialEngagementESignature.handlerName != this.engagementESignature.handlerName
        && !this.DocumentsValidation())
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignedDocumentSignerAndSignedDateChanged;

      else if (!this.DocumentsValidation()
        && this.initialEngagementESignature.handlerName != this.engagementESignature.handlerName)
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignedDocumentAndSignerChanged;

      else if (this.initialEngagementESignature.handlerName != this.engagementESignature.handlerName
        && parsedInitDate != ParsedSelectedDate)
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignerAndSignedDateChanged;

      else if (!this.DocumentsValidation()
        && parsedInitDate != ParsedSelectedDate)
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignedDocumentAndSignedDateChanged;

      else if (this.initialEngagementESignature.handlerName != this.engagementESignature.handlerName)
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignerChanged;

      else if (!this.DocumentsValidation())
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignedDocumentChanged;

      else if (parsedInitDate != ParsedSelectedDate)
        this.engagementESignature.removedRemark = SigningRequestDiscardRemark.SignedDateChanged;
    }

    if (esignDiscardRemark && esignDiscardRemark != '') {
      this.engagementESignature.removedRemark += `(${esignDiscardRemark})`;
    }

    this.esignatureService.AddEngagementESignatureDetails(this.engagementESignature).subscribe((response: EngagementESignature) => {
      //Success
      if (response.id > 0) {
        this.engagementESignature = null;
        this.initialEngagementESignature = null;
        this.engagementESignature = response;
        this.selectedDocumentChecking();
        this.initialEngagementESignature = JSON.parse(JSON.stringify(response));
        this.showPrintedSignerName = true;
        this.sendSignedDocumentToCLM();
        this.CheckCMSRequestStatus(response);

      }
      else
        this.loadingScreenService.stopLoading();
    },
      err => {
        this.logService.error('Esignature detail update failed...', err);
        this.loadingScreenService.stopLoading();
      });
  }

  get IsElectronicSendButtonDisabled(): boolean {
    return ((this.engagementESignature.sharePointDocuments.length == 0 || !this.engagementESignature.handlerEmail)
      || (this.DocumentsValidation()
        && this.initialEngagementESignature.handlerEmail == this.engagementESignature.handlerEmail
        && this.initialEngagementESignature.signatureTypeCode == this.engagementESignature.signatureTypeCode
        && this.initialEngagementESignature.envelopeStatusCode != 'Voided'));
  }


  SetDocumentPrintDate(date) {
    //format date to  mm/dd/yy
    let dt = new Date(date);
    if (dt && dt instanceof Date) {
      let formattedDate = (dt.getMonth() + 1).toString().padStart(2, '0') + '/' + dt.getDate().toString().padStart(2, '0') + '/' + dt.getFullYear().toString();
      $('.printedSigningDate').val(formattedDate);
    }
  }

  InitializeESignatureFields() {
    if (this.initialEngagementESignature.signatureTypeCode == 'P') {
      this.SetDocumentPrintDate(this.initialEngagementESignature.createDate);
    }
  }

  ShowPrintedSignerAndDatePicker() {
    this.showPrintedSignerName = false;

    setTimeout(() => {
      this.SetDocumentPrintDate(this.engagementESignature.createDate);
    }, 10);

  }
  CancelPrintedSigningDetails() {
    if (this.initialEngagementESignature.signatureTypeCode == 'P') {
      this.engagementESignature.handlerName = this.initialEngagementESignature.handlerName;
      this.isEmptyPrintedSinger = false;
      this.isEmptyPrintedDate = false;
    }
    else {
      $('.printedSigningDate').val('');
      this.engagementESignature.handlerName = '';
    }
    this.showPrintedSignerName = true;
  }

  BuildRPsDisplayModel(teamMembers: TeamMembers[]) {
    let strRoleArray: string[] = [];
    this.autoRPDisplayModel = [];
    this.manualRPDisplayModel = [];
    this.eppmdApproverDispalyModel = [];

    //Get distinct role
    teamMembers.forEach(member => {
      if (!strRoleArray.includes(member.memberRoleName) && member.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional) {
        strRoleArray.push(member.memberRoleName);
      }
    });

    strRoleArray.forEach(role => {
      //Get rolewise member list
      let memberWithSameGroup = teamMembers.filter(rpMembers => rpMembers.memberRoleName == role);
      if (role == null) {
        //Fill EP approvers list
        this.rpDisplayModel = new RPDisplayModel();
        this.rpDisplayModel.roleName = "Engagement PPMD";
        this.rpDisplayModel.roleDescription = "Engagement PPMD";
        this.rpDisplayModel.teamMembersType = "Engagement PPMD";

        memberWithSameGroup.forEach(member => {
          if (member.isReviewer != 1 && (member.teamMemberRole === EnumRoles.EngagementPartner && member.approvalStatus.toUpperCase() === WorkspaceInterviewsStatus.Approved.toUpperCase())) {
            this.rpDisplayModel.teamMembers.push(member);
          }
        });
        this.eppmdApproverDispalyModel.push(this.rpDisplayModel);

        //File manully added RP approver list
        this.rpDisplayModel = new RPDisplayModel();
        this.rpDisplayModel.roleName = "Manually added";
        this.rpDisplayModel.roleDescription = "Manually added";
        this.rpDisplayModel.teamMembersType = "Manually added";
        memberWithSameGroup.forEach(member => {
          if (member.isReviewer == 1 && member.approvalStatus.toUpperCase() === WorkspaceInterviewsStatus.Approved.toUpperCase()) {
            this.rpDisplayModel.teamMembers.push(member);
          }
        });
        this.manualRPDisplayModel.push(this.rpDisplayModel);

      }
      else {
        //File auto RP display model
        this.rpDisplayModel = new RPDisplayModel();
        this.rpDisplayModel.teamMembers = memberWithSameGroup.filter(member => member.approvalStatus.toUpperCase() == WorkspaceInterviewsStatus.Approved.toUpperCase());
        this.rpDisplayModel.roleName = memberWithSameGroup[0].memberRoleName;
        this.rpDisplayModel.roleDescription = memberWithSameGroup[0].memberRoleDescription;
        this.rpDisplayModel.teamMembersType = memberWithSameGroup[0].memberType;
        this.autoRPDisplayModel.push(this.rpDisplayModel);
      }
    });
  }

  displayCMSEPPMDContactPopup(assignmentItem) {
    this.selectContactEmailId = assignmentItem.cmsEPPMDEmailId;
    this.hideRPPopUp();
    this.cmsEPPMDPopupId = 'cmsEPPMDContactPopup_' + assignmentItem.assignmentCodeId;
    this.checkForContactContent('contact-popup');
    $('#cmsEPPMDContactPopup_' + assignmentItem.assignmentCodeId).popover('show');

  }

  displayAdminContactPopup(assignmentItem) {
    this.selectContactEmailId = assignmentItem.adminEmailId;
    this.hideRPPopUp();
    this.adminPopupId = 'adminContactPopup_' + assignmentItem.assignmentCodeId;
    this.checkForContactContent('contact-popup');
    $('#adminContactPopup_' + assignmentItem.assignmentCodeId).popover('show');
  }

  getPSDMappingDataForSelectedSolutionCode() {
    this._configService.getPSDMappingDataByEngId(this.engagementId).subscribe(result => {
      if (result.toString != null) {
        this.PSDMapingListforUserCheck = result;
        console.log('PSDMapingList summary ==>', this.PSDMapingListforUserCheck);
        //check logged in user belongs to quality group
        if (this.PSDMapingListforUserCheck.length > 0) {
          this.IsLoggedinUserPSD();
        }
      }
    });
  }

  IsLoggedinUserPSD() {
    console.log('userObject ==>', this.userObject);
    const localUserName = this.userObject.userName.toLowerCase();
    for (let i = 0; i < this.PSDMapingListforUserCheck.length; i++) {
      if (this.PSDMapingListforUserCheck[i].psdEmailId.toLowerCase() === localUserName || this.PSDMapingListforUserCheck[i].psdDelegateMemberEmailId.toLowerCase() === localUserName) {
        this.isloggedInUserPSD = true;
        this.isQualityUserLoggedIn = 'yes';
        this.blockUserAction();
        console.log('PSDUser=> isQualityUserLoggedIn => ', this.isQualityUserLoggedIn);
        break;
      }
    }
  }

  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      //check logged in user belongs to quality group
      for (let e of this.userObject.role) {
        if (e.shortRoleName === RoleNames.QualityGroup) {
          this.isQualityUserLoggedIn = 'yes';
          console.log('isQualityUserLoggedI => ', this.isQualityUserLoggedIn);
        }
        if (e.shortRoleName === RoleNames.INDUSStaffingManagers) {
          this.isINDUSStaffingManagerLoggedIn = true;
          console.log('isNDUSStaffingManagerLoggedIn => ', this.isINDUSStaffingManagerLoggedIn);
        }
      }
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === RoleNames.SystemAdministrator) {
          this.isloggedInUserAdmin = 'yes';
          console.log('isloggedInUserAdmin => ', this.isloggedInUserAdmin);
          break;
        } else {
          this.isloggedInUserAdmin = 'no';
        }
      }
      this.getPSDMappingDataForSelectedSolutionCode();
    }
  }

  CheckAdminUserMembership() {
    const localUserName = this.userObject.userName.toLowerCase();
    this.isAdminEngagementMember = false;
    if (this.engagementCollaborationsViewModel.engagement.engagementPartner.toLowerCase() === localUserName) {
      this.isAdminEngagementMember = true;
    } else if (this.engagementCollaborationsViewModel.teamMemberList != null
      && this.engagementCollaborationsViewModel.teamMemberList.length > 0) {
      console.log('teamMemberList=> ', this.engagementCollaborationsViewModel.teamMemberList);
      for (let i = 0; i < this.engagementCollaborationsViewModel.teamMemberList.length; i++) {
        if (this.engagementCollaborationsViewModel.teamMemberList[i].memberEmailId.toLowerCase() === localUserName) {
          this.isAdminEngagementMember = true;
          break;
        }
      }
    }

    if (this.engagementCollaborationsViewModel.collaborationSiteMembersList != null
      && this.engagementCollaborationsViewModel.collaborationSiteMembersList.length > 0) {
      console.log('collaborationSiteMembersList=>', this.engagementCollaborationsViewModel.collaborationSiteMembersList);
      for (let i = 0; i < this.engagementCollaborationsViewModel.collaborationSiteMembersList.length; i++) {
        if (this.engagementCollaborationsViewModel.collaborationSiteMembersList[i].memberEmailId.toLowerCase() === localUserName) {
          this.isAdminEngagementMember = true;
          break;
        }
      }
    }
  }

  CheckQualityUserMembership() {
    const localUserName = this.userObject.userName.toLowerCase();
    this.isQualityUserEngagementMember = false;
    if (this.engagementCollaborationsViewModel.engagement.engagementPartner.toLowerCase() === localUserName) {
      this.isQualityUserEngagementMember = true;
    } else if (this.engagementCollaborationsViewModel.teamMemberList != null
      && this.engagementCollaborationsViewModel.teamMemberList.length > 0) {
      console.log('teamMemberList=> ', this.engagementCollaborationsViewModel.teamMemberList);
      for (let i = 0; i < this.engagementCollaborationsViewModel.teamMemberList.length; i++) {
        if (this.engagementCollaborationsViewModel.teamMemberList[i].memberEmailId.toLowerCase() === localUserName) {
          this.isQualityUserEngagementMember = true;
          break;
        }
      }
    }

    if (this.engagementCollaborationsViewModel.collaborationSiteMembersList != null
      && this.engagementCollaborationsViewModel.collaborationSiteMembersList.length > 0) {
      console.log('collaborationSiteMembersList=>', this.engagementCollaborationsViewModel.collaborationSiteMembersList);
      for (let i = 0; i < this.engagementCollaborationsViewModel.collaborationSiteMembersList.length; i++) {
        if (this.engagementCollaborationsViewModel.collaborationSiteMembersList[i].memberEmailId.toLowerCase() === localUserName) {
          this.isQualityUserEngagementMember = true;
          break;
        }
      }
    }
  }

  getDefaultClientBillingContactDetails(clientId: number) {
    return this.clientService.getDefaultClientBillingContactDetails(clientId)
      .subscribe(
        result => {
          this.defaultClientBillingContact = result;
          //to set client contact billing details
          this.setClientBillingContactDetails();
        },
        error => {
        }
      );
  } // end getDefaultClientBillingContactDetails


  setClientBillingContactDetails() {
    //Client Billing contact details
    if (this.engagementList.clientId > 0) {
      if ((this.defaultClientBillingContact.clientBillingEmail !== undefined || this.defaultClientBillingContact.clientBillingEmail !== null) ||
        (this.defaultClientBillingContact.clientBillingName !== undefined || this.defaultClientBillingContact.clientBillingName !== null)) {
        this.defaultEngAssignment.clientBillingContactEmail = this.defaultClientBillingContact.clientBillingEmail;
        this.defaultEngAssignment.clientBillingContactName = this.defaultClientBillingContact.clientBillingName;

      }
      if (this.defaultClientBillingContact.clientBillingTitleId > 0) {
        this.defaultEngAssignment.clientBillingContactTitleId = this.defaultClientBillingContact.clientBillingTitleId;
      }
    }
  } // end setClientBillingContactDetails
  validateAssignmentName(name: string) {
    const regex = /^[A-Za-z0-9()',&._-\s]*$/gm;
    if (regex.test(name)) {
      return true;
    } else {
      return false;
    }
  }



  GetIndusConsentADGroups() {
    this.loadingScreenService.startLoading();
    //setp 1 : get site member details by engagement id
    this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
      .subscribe(
        result => {
          this.engagementCollaborationsViewModel = result;
          this.engagementList = this.engagementCollaborationsViewModel.engagement;
          if (this.engagementList.status === EngagementStatus.EngActive) {
            this.isEngagementCloseoutDisable = false;
          }
          else {
            this.isEngagementCloseoutDisable = true;
          }
          this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
          this.loadingScreenService.startLoading();
          //set 2: get indus consent groups those are not part of team member group(SM)
          this.adUsersService.GetINDUSADGroups(RoleNames.INDUSConsent).subscribe(
            adGroupList => {
              this.engDetailIndusADGroupsList = adGroupList;
              this.GetValidIndusMemberList();
            },
            error => {
              this.loadingScreenService.stopLoading();
              this.indusADGroupsList = null;
              console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
            });
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.indusADGroupsList = null;
          console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
        });
  }
  setTeamMemberListtoValidate() {
    //step 3: set indus member data to validate from azure ad
    for (let i = 0; this.collaborationSiteMembersList.length > i; i++) {
      if (this.collaborationSiteMembersList[i].memberRoleId != this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP)
        || this.collaborationSiteMembersList[i].memberRoleId != this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR)
      ) {
        //SET collaborationSitMemberId as workspaceMemberId
        this.indusTeamMemberData = {
          azureADUsers: this.engDetailIndusADGroupsList,
          workspaceMemberId: this.collaborationSiteMembersList[i].collaborationSiteMembersId,
          memberEmail: this.collaborationSiteMembersList[i].memberEmailId,
          memberName: this.collaborationSiteMembersList[i].memberName,
          isIndusMember: false
        };
        this.indusMembersListArray.push(this.indusTeamMemberData);
      }
    }
  }
  GetValidIndusMemberList() {
    this.setTeamMemberListtoValidate();

    //step 4: get valid indus member list from azure ad
    let memerList: IndusADUsersListViewModel = new IndusADUsersListViewModel();
    if (this.indusMembersListArray != null && this.indusMembersListArray.length > 0) {
      memerList.indusADUsersList = this.indusMembersListArray;
      this.loadingScreenService.startLoading();
      this.adUsersService.getValidIndusTeamMemberList(memerList)
        .subscribe(
          userList => {
            if (userList.indusADUsersList != null && userList.indusADUsersList.length > 0) {
              userList.indusADUsersList.forEach(element => {
                this.collaborationSiteMembersList.forEach(x => {
                  if (element.workspaceMemberId == x.collaborationSiteMembersId && element.isIndusMember) {
                    this.engDetailValidIndusMemberList.push(x);
                  }
                });
              });
              console.log(userList);
            }
            this.RemoveIndusCollabTemMembers();
          },
          error => {
            this.loadingScreenService.stopLoading();
            this.indusADGroupsList = null;
            console.log('Error in getting response from GetIndusTeamMembers func ', error);
          }
        );
    } else {
      //this.loadingScreenService.stopLoading();
      this.RemoveIndusCollabTemMembers();
    }
  }

  RemoveIndusCollabTemMembers() {
    this.loadingScreenService.startLoading();
    let teamMembersList: RemoveIndusCollaborationSiteMember = new RemoveIndusCollaborationSiteMember();
    teamMembersList.CollaborationSiteTeamMembers = this.engDetailValidIndusMemberList;
    teamMembersList.engagementId = this.engagementId;
    teamMembersList.loggedInUser = this.userObject.loggedInUserEmail;
    teamMembersList.isContinuanceEligible = this.engagementList.continuanceEligibility;
    //step 5 : Remove valid indus users from collaboration site member table
    this.workspaceService.removeIndusCollabMembers(teamMembersList).subscribe(
      result => {
        if (+result < 0) {
          this.UpdatePermissionForAllCollabSite();
        } else {
          this.onCloseEngagementReload();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.indusADGroupsList = null;
        console.log('Error in getting response from RemoveIndusTemMembers func ', error);
      });
  }
  UpdatePermissionForAllCollabSite() {
    this.loadingScreenService.startLoading();
    //step 6 : Get updated site member list from DB
    this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
      .subscribe(
        result => {
          this.engagementCollaborationsViewModel = result;
          this.applyFilterOnceAfterDataLoad = true;
          this.engagementList = this.engagementCollaborationsViewModel.engagement;
          if (this.engagementList.status === EngagementStatus.EngActive) {
            this.isEngagementCloseoutDisable = false;
          }
          else {
            this.isEngagementCloseoutDisable = true;
          }
          this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
          this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
          this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
          this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
          this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
          this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
          //Step 7 : Iterate on collaboration site and update ad group members
          let updatePermissionsModelList: UpdatePermissionsModel[] = [];
          for (let i = 0; i < this.collaborationSiteList.length; i++) {
            if (this.collaborationSiteList[i].siteURL != "") {

              // Get List of Groups based on Permission Drop down(GROUP By)
              const filterCollbMembers = this.collaborationSiteMembersList.
                filter(collbMember => collbMember.collaborationSiteId === this.collaborationSiteList[i].collaborationSiteId);

              let siteMembersGroups = filterCollbMembers.reduce((r, a) => {
                r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                return r;
              }, {});

              console.log("siteMembersGroups", siteMembersGroups);
              let onwwerList: CollaborationSiteMembers[] = [];
              let memberList: CollaborationSiteMembers[] = [];

              let siteGroupTypes = Object.keys(siteMembersGroups);

              siteGroupTypes.forEach(type => {
                if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                  onwwerList = filterCollbMembers.filter(member =>
                    Number(member.memberPermissionId) === Number(type));
                } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                  memberList = filterCollbMembers.filter(member =>
                    Number(member.memberPermissionId) === Number(type));
                }
              })

              // Site Onwers Email List
              let siteOwnerEmailIds: string[] = [];
              onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

              //Site Members Email List
              let siteMemberEmailIds: string[] = [];
              memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

              let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
              let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
              let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

              OwnerListEmailModel.groupType = 'Owner';
              OwnerListEmailModel.groupUsersEmail = [];
              siteOwnerEmailIds.forEach(emailid => {
                let adUser: ADUser = new ADUser();
                adUser.firstName = '';
                adUser.lastName = '';
                adUser.userEmailId = emailid;
                OwnerListEmailModel.groupUsersEmail.push(adUser);
              })

              MemberListEmailModel.groupType = 'Member';
              MemberListEmailModel.groupUsersEmail = [];
              siteMemberEmailIds.forEach(emailid => {
                let adUser: ADUser = new ADUser();
                adUser.firstName = '';
                adUser.lastName = '';
                adUser.userEmailId = emailid;
                MemberListEmailModel.groupUsersEmail.push(adUser);
              })

              collbSiteUsersUpdatePermissionModel.siteType = "CollabrationSite";
              collbSiteUsersUpdatePermissionModel.siteUniqueID = this.collaborationSiteList[i].siteUniqueID;
              collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
              collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);

              updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);

            }
          }
          if (updatePermissionsModelList !== null) {
            // Button Update Collbration
            //4. Call Active Directory API to update latest collaborationSite Member Ids
            // this.CallUpdatePermissions(updatePermissionsModelList);
            let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
            updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;
            this.loadingScreenService.startLoading();
            this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
              UpdatePermissionResult => {
                console.log('UpdatePermissions Result', UpdatePermissionResult);
                this.onCloseEngagementReload();
              },
              error => {
                this.loadingScreenService.stopLoading();
                this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
              });
          } else {
            this.onCloseEngagementReload();
          }
        },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
  }

  onCloseEngagementReload() {
    let arrayOfSolutionCode: any;
    let isAuditServiceLineEngagement: boolean = false;
    let isAttestServiceLineEngagement: boolean = false;
    this.loadingScreenService.startLoading();

    this.engagementService.GetSolutionCodeForCloseEngagement().subscribe(result => {
      arrayOfSolutionCode = result;
      let isAuditSolutionCodeSkipped = arrayOfSolutionCode.indexOf(this.engagementList.solutionCode) > 0
      this.subscriptions.push(this.engagementService.GetSolutionCodeMasterById(this.engagementList.parentSolutionCodeId).subscribe(primarySolutionCode => {

        isAuditServiceLineEngagement = primarySolutionCode.isAudit;
        isAttestServiceLineEngagement = primarySolutionCode.isAttest === 1;

        this.engagementService.getEngagementClientSiteUrl(this.engagementId).subscribe((engagementClientSiteUrlList: string[]) => {
          const closeSiteUrl = new CloseSiteUrl();
          closeSiteUrl.siteUrls = [];
          if (engagementClientSiteUrlList !== undefined && engagementClientSiteUrlList !== null) {
            closeSiteUrl.siteUrls = engagementClientSiteUrlList;
          }
          this.loadingScreenService.startLoading();
          // Commented attest service line functionality on 26th-April-2022
          // Skipping this feature from being released as per of release_13
          // This will be part of release_14
          // if ((isAuditServiceLineEngagement || isAttestServiceLineEngagement) && this.engagementList.status === this.EngagementStatus.EngClosed) {
          if (isAuditServiceLineEngagement && this.engagementList.status === this.EngagementStatus.EngClosed
            && !isAuditSolutionCodeSkipped) {
            this.ApplyAutoCloseoutSitesPolicyOnManualClose(closeSiteUrl.siteUrls);
          }
          else {
            this.engagementService.closeSite(closeSiteUrl).subscribe((closeSiteStatus) => {
              console.log('Success close site');
              this.loadingScreenService.stopLoading();
              this.isEngagementUpdateInProcess = false;
              this.logService.info(`Engagement status: ${status}`);
              this.getEngagementCollaborationsById(this.engagementId, false);
            },
              err => {
                this.loadingScreenService.stopLoading();
                this.logService.error('close site failed.', err);
              });
          }
        },
          err => {
            this.loadingScreenService.stopLoading();
            this.logService.error('Get engagement client siteurl list failed.', err);
          });//end getEngagementClientSiteUrl
      },
        error => {
          this.loadingScreenService.stopLoading();
          this.logService.error('Get solution code details failed.', error);
        }));//End GetSolutionCodeMasterById
    });  //End GetSolutionCodeForCloseEngagement
  }


  //Start EQCR functionality

  addEditEQCR(assignmentIndex: number) {
    this.arrEngagementAssignment[assignmentIndex].newEQCRName = this.arrEngagementAssignment[assignmentIndex].eqcrName
    this.arrEngagementAssignment[assignmentIndex].newEQCREmailId = this.EQCRDisplayUserEmail = this.arrEngagementAssignment[assignmentIndex].eqcrEmailId;
    this.arrEngagementAssignment[assignmentIndex].isEditEQCR = true;

  }

  cancelEQCR(assignmentIndex: number) {
    this.arrEngagementAssignment[assignmentIndex].newEQCRName = '';
    this.arrEngagementAssignment[assignmentIndex].newEQCREmailId = '';
    this.arrEngagementAssignment[assignmentIndex].isEditEQCR = false;
    this.arrEngagementAssignment[assignmentIndex].isvalidEQCR = true;
    this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = true;
    this.arrEngagementAssignment[assignmentIndex].isEQCRExists = false;
  }

  updateEngagementEQCR(assignmentIndex: number, assignmentCodeId: number) {

    this.arrEngagementAssignment[assignmentIndex].isvalidEQCR = true;
    this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = true;
    this.arrEngagementAssignment[assignmentIndex].isEQCRExists = false;

    let EQCRName = $('#txtPeoplePicker_AS_EQCR_' + assignmentIndex).val();
    let selectedEQCREmail = $('#UserHiddenProps_AS_EQCR_' + assignmentIndex + ' #hdnUserEmail').val();
    let isvalidLocalEQCR = Boolean(JSON.parse($('#hdnValidInput_AS_EQCR_' + assignmentIndex).val()));

    if (isvalidLocalEQCR) {
      this.arrEngagementAssignment[assignmentIndex].newEQCRName = EQCRName;
      this.arrEngagementAssignment[assignmentIndex].newEQCREmailId = selectedEQCREmail;
    }
    selectedEQCREmail = selectedEQCREmail.toLowerCase().trim();
    EQCRName = EQCRName.toLowerCase().trim();

    if ((isvalidLocalEQCR == false)
      && EQCRName != ''
      && EQCRName != this.arrEngagementAssignment[assignmentIndex].newEQCRName.toLowerCase().trim()
    ) {
      this.arrEngagementAssignment[assignmentIndex].isvalidEQCR = false;
    }
    else if (isvalidLocalEQCR == false &&
      EQCRName == this.arrEngagementAssignment[assignmentIndex].newEQCRName.toLowerCase().trim()) {
      this.arrEngagementAssignment[assignmentIndex].isEQCRExists = true;
    }
    else {
      if (this.arrEngagementAssignment[assignmentIndex].cmsEPPMDEmailId.toLowerCase().trim() == selectedEQCREmail
        || this.arrEngagementAssignment[assignmentIndex].adminEmailId.toLowerCase().trim() == selectedEQCREmail
        || this.engagementList.engagementPartner.toLowerCase().trim() == selectedEQCREmail) {
        this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = false;
      } else {
        if (EQCRName != '' &&
          this.arrEngagementAssignment[assignmentIndex].newEQCRName.toLowerCase().trim() ==
          this.arrEngagementAssignment[assignmentIndex].eqcrName.toLowerCase().trim()) {
          this.arrEngagementAssignment[assignmentIndex].isEQCRExists = true;
        }
        else {
          this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = true;
        }
      }
    }

    if (this.arrEngagementAssignment[assignmentIndex].isvalidEQCR &&
      this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule &&
      !this.arrEngagementAssignment[assignmentIndex].isEQCRExists) {
      console.log('proceed for updation');
      if (EQCRName == '') {
        this.arrEngagementAssignment[assignmentIndex].newEQCRName = '';
        this.arrEngagementAssignment[assignmentIndex].newEQCREmailId = '';
      }
      const assignmentItem = this.arrEngagementAssignment.find(item => item.assignmentCodeId == assignmentCodeId);

      let updateAssignmentEQCRViewModel: UpdateAssignmentEQCRViewModel = new UpdateAssignmentEQCRViewModel();
      updateAssignmentEQCRViewModel.assignmentCodeId = assignmentItem.assignmentCodeId;
      updateAssignmentEQCRViewModel.engagementId = assignmentItem.engagementId;
      updateAssignmentEQCRViewModel.collaborationSiteId = assignmentItem.collaborationSiteId;
      updateAssignmentEQCRViewModel.eqcrName = assignmentItem.eqcrName;
      updateAssignmentEQCRViewModel.eqcrEmailId = assignmentItem.eqcrEmailId;
      updateAssignmentEQCRViewModel.lastModifiedBy = this.userObject.userName;
      updateAssignmentEQCRViewModel.lastModifiedDate = assignmentItem.lastModifiedDate;
      updateAssignmentEQCRViewModel.newEQCRName = assignmentItem.newEQCRName;
      updateAssignmentEQCRViewModel.newEQCREmailId = assignmentItem.newEQCREmailId;

      console.log('updateAssignmentEQCRViewModel ==>', updateAssignmentEQCRViewModel);

      this.loadingScreenService.startLoading();
      this.engagementService.UpdateAssignmentEQCR(updateAssignmentEQCRViewModel).subscribe(result => {
        if (result.toLowerCase() === 'success') {

          assignmentItem.isEditEQCR = false;
          this.engagementService.getEngagementCollaborationsById(this.engagementId, this.isloggedInUserAdmin)
            .subscribe(
              engCollabresult => {
                this.engagementCollaborationsViewModel = engCollabresult;
                this.applyFilterOnceAfterDataLoad = true;
                this.engagementList = this.engagementCollaborationsViewModel.engagement;
                if (this.engagementList.status === EngagementStatus.EngActive) {
                  this.isEngagementCloseoutDisable = false;
                }
                else {
                  this.isEngagementCloseoutDisable = true;
                }
                this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
                this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
                this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
                this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
                this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
                this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
                this.CreateUIForCollaborationSpace();
                this.setAssignmentSection();
                // this.loadingScreenService.stopLoading();

                //  Update Site Permission for selected collbration Site members
                let filterCollaborationSiteList =
                  this.collaborationSiteList.filter(site => site.collaborationSiteId === updateAssignmentEQCRViewModel.collaborationSiteId);

                for (let i = 0; i < filterCollaborationSiteList.length; i++) {

                  //1.For existing Collbration and has Sharepoint site created then
                  // CALL Update Permission API for team member update (ONLY FOR COLLBRATION SITE)
                  if (filterCollaborationSiteList[i].siteURL != "") {

                    // Get List of Groups based on Permission Drop down(GROUP By)
                    const filterCollbMembers = this.collaborationSiteMembersList.
                      filter(collbMember => collbMember.collaborationSiteId === filterCollaborationSiteList[i].collaborationSiteId);

                    let siteMembersGroups = filterCollbMembers.reduce((r, a) => {
                      r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                      return r;
                    }, {});

                    console.log("siteMembersGroups", siteMembersGroups);
                    let onwwerList: CollaborationSiteMembers[] = [];
                    let memberList: CollaborationSiteMembers[] = [];

                    let siteGroupTypes = Object.keys(siteMembersGroups);

                    siteGroupTypes.forEach(type => {
                      if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                        onwwerList = filterCollbMembers.filter(member =>
                          Number(member.memberPermissionId) === Number(type));
                      } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                        memberList = filterCollbMembers.filter(member =>
                          Number(member.memberPermissionId) === Number(type));
                      }
                    })

                    // Site Onwers Email List
                    let siteOwnerEmailIds: string[] = [];
                    onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                    //Site Members Email List
                    let siteMemberEmailIds: string[] = [];
                    memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                    let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                    let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
                    let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

                    OwnerListEmailModel.groupType = 'Owner';
                    OwnerListEmailModel.groupUsersEmail = [];
                    siteOwnerEmailIds.forEach(emailid => {
                      let adUser: ADUser = new ADUser();
                      adUser.firstName = '';
                      adUser.lastName = '';
                      adUser.userEmailId = emailid;
                      OwnerListEmailModel.groupUsersEmail.push(adUser);
                    })

                    MemberListEmailModel.groupType = 'Member';
                    MemberListEmailModel.groupUsersEmail = [];
                    siteMemberEmailIds.forEach(emailid => {
                      let adUser: ADUser = new ADUser();
                      adUser.firstName = '';
                      adUser.lastName = '';
                      adUser.userEmailId = emailid;
                      MemberListEmailModel.groupUsersEmail.push(adUser);
                    })

                    collbSiteUsersUpdatePermissionModel.siteType = "CollabrationSite";
                    collbSiteUsersUpdatePermissionModel.siteUniqueID = filterCollaborationSiteList[i].siteUniqueID;
                    collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
                    collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
                    let updatePermissionsModelList: UpdatePermissionsModel[] = [];
                    updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);

                    let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
                    updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;

                    this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
                      updatePermissionResult => {
                        console.log('UpdatePermissions Result', updatePermissionResult);
                        this.loadingScreenService.stopLoading();
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                      });

                  }
                }
              }
            )
        }
      });
    }

  }

  deselectEQCR() {
    $('#searchEQCRName').val('');
    this.membersListArrayForEP = null;
    $('#' + this.EQCRNameCtrl).val('');
    $('#' + this.EQCRNameCtrl).innerText = '';
    $('#' + this.EQCRCtrl).val('');
    $('#' + this.EQCRCtrl).innerText = '';
  }

  refreshAssignmentEQCRPopup(currentAssignmentRowIndx: number, textboxName: string) {
    this.currentAssignmentRowIndex = currentAssignmentRowIndx;
    console.log('currentAssignmentRowIndex people picker', this.currentAssignmentRowIndex);
    this.removeRowSelection('tblEQCR', 'searchAssAdminName', 'AssignmentAdminErrorMessage');

    this.EQCRNameCtrl = textboxName + this.EQCRRoleName + '_' + this.currentAssignmentRowIndex;
    this.EQCRCtrl = textboxName + 'AS_' + this.EQCRRoleName + '_' + this.currentAssignmentRowIndex;

    this.selectedEQCRIndex = null;
    this.validateEQCR = false;
    if ($('#' + this.EQCRNameCtrl).hasClass('text-danger')) {
      $('#' + this.EQCRNameCtrl).removeClass('text-danger');
    }
  }

  setEQCR() {

    if (Number(this.selectedEQCRIndex) >= 0) {
      const selectedEQCRName = $('#spEQCRdisplayName' + this.selectedEQCRIndex).text();
      const selectedEQCREmail = $('#spEQCRmail' + this.selectedEQCRIndex).text();
      const selectedEQCRPhone = $('#spEQCRbusinessPhones' + this.selectedEQCRIndex).text();

      if (this.currentAssignmentRowIndex < this.arrEngagementAssignment.length) {
        this.arrEngagementAssignment[this.currentAssignmentRowIndex].newEQCRName = selectedEQCRName;
        this.arrEngagementAssignment[this.currentAssignmentRowIndex].newEQCREmailId = selectedEQCREmail;
      }

      if (this.EQCRNameCtrl !== '') {
        $('#' + this.EQCRNameCtrl).val(selectedEQCRName);
        $('#UserHiddenProps_EQCR_' + this.currentAssignmentRowIndex + ' #hdnUserName').val(selectedEQCRName);
        $('#UserHiddenProps_EQCR_' + this.currentAssignmentRowIndex + ' #hdnUserEmail').val(selectedEQCREmail);
        $('#UserHiddenProps_EQCR_' + this.currentAssignmentRowIndex + ' #hdnUserPhone').val(selectedEQCRPhone);
        $('#hdnValidInput_EQCR_' + this.currentAssignmentRowIndex).val(true);
      }
      $('#' + this.EQCRCtrl).val(selectedEQCRName);
      $('#UserHiddenProps_AS_EQCR_' + this.currentAssignmentRowIndex + ' #hdnUserName').val(selectedEQCRName);
      $('#UserHiddenProps_AS_EQCR_' + this.currentAssignmentRowIndex + ' #hdnUserEmail').val(selectedEQCREmail);
      $('#UserHiddenProps_AS_EQCR_' + this.currentAssignmentRowIndex + ' #hdnUserPhone').val(selectedEQCRPhone);
      $('#hdnValidInput_AS_EQCR_' + this.currentAssignmentRowIndex).val(true);

      console.log('arrEngagementAssignment after ok click', this.arrEngagementAssignment);
    }
    $('#searchEQCRName').val('');
    this.membersListArrayForEP = null;
  }

  selectEQCR(index) {
    $('#tblEQCR').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedEQCRIndex = index;
    console.log('selectEQCR func called ', this.selectedEQCRIndex);
  }

  displayEQCRContactPopup(assignmentItem) {
    this.selectContactEmailId = assignmentItem.eqcrEmailId;
    this.hideRPPopUp();
    this.cmsEPPMDPopupId = 'eqcrNameContactPopup_' + assignmentItem.assignmentCodeId;
    this.checkForContactContent('contact-popup');
    $('#eqcrNameContactPopup_' + assignmentItem.assignmentCodeId).popover('show');
  }
  //End  EQCR functionality

  OnRoleChange(memberRoleId: string, id: number) {
    if (Number(memberRoleId) == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P)) {
      $('#drpTeamMemberPermissions_' + id).prop("disabled", false);
    } else if (Number(memberRoleId) == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.S)) {
      $('#drpTeamMemberPermissions_' + id).prop('selectedIndex', 1);
      $('#drpTeamMemberPermissions_' + id).attr('disabled', true);
    }

  }

  OnRoleChangeForExistingMember(memberRoleId: string, id: number) {
    if (Number(memberRoleId) == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P)) {
      $('#teamMemberPermissions_Row_' + id).prop("disabled", false);
    } else if (Number(memberRoleId) == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.S)) {
      this.currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers[id].memberPermissionId =
        this.getTeamMemberPermissionsIdByValue('Site member');
      $('#teamMemberPermissions_Row_' + id).prop('selectedIndex', 1);
      $('#teamMemberPermissions_Row_' + id).attr('disabled', true);
    }
  }

  getTeamMemberPermissionsIdByValue(value: string): number {
    return this.teamMemberPermissions.find(c => c.typeValue === value).id;
  }

  SendEngagementClosureNotification() {
    this.loadingScreenService.startLoading();
    let conveneApiLogModel: ConveneApiLogModel = new ConveneApiLogModel();
    let closureRequestModel: ClosureRequestModel = new ClosureRequestModel();
    this.engagementService.getRetentionSecAppliedAndSuccessorFirmFlags(this.primaryCollaborationDetails.siteUniqueID).subscribe(result => {
      this.engagementService.getEngagementActualCloseoutInDays(result.isRetentionSectionApplied).subscribe(noofdays => {

        closureRequestModel.engageEngagementId = this.engagementId;
        closureRequestModel.closeOutInitiatedBy = this.userObject.loggedInUserEmail;
        closureRequestModel.closingOutTimespan = noofdays.actualCloseoutInDays;
        closureRequestModel.engageEngagementStatus = this.UpdateEngagementStatusData.engagementList.status;

        this.closureService.Notify(closureRequestModel).subscribe(closureViewModel => {
          conveneApiLogModel.requestPayload = JSON.stringify(closureViewModel.closureNotificationRequest);
          conveneApiLogModel.responseBody = JSON.stringify(closureViewModel.closureNotificationResponse);
          conveneApiLogModel.engagementId = this.engagementId;
          conveneApiLogModel.httpMethod = RequestMethods.RequestHttpPost;
          conveneApiLogModel.requestType = ConveneRequestTypes.SendClosureNotification;
          conveneApiLogModel.loggedInUser = this.userObject.loggedInUserEmail;
          conveneApiLogModel.status = closureViewModel.closureNotificationResponse.status;
          conveneApiLogModel.statusCode = closureViewModel.closureNotificationResponse.statusCode;
          conveneApiLogModel.statusDescription = closureViewModel.closureNotificationResponse.errorMessage;

          //  this.loadingScreenService.startLoading();
          this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
            if (affectedRows > 0) {
              //success
              this.loadingScreenService.stopLoading();
            }
          },
            err => {
              this.loadingScreenService.stopLoading();
              console.error(err);
            });
        });
      });
    },
      (err) => {
        conveneApiLogModel.requestPayload = JSON.stringify(closureRequestModel);
        conveneApiLogModel.responseBody = JSON.stringify(err);
        conveneApiLogModel.engagementId = this.engagementId;
        conveneApiLogModel.httpMethod = RequestMethods.RequestHttpPost;
        conveneApiLogModel.requestType = ConveneRequestTypes.SendClosureNotification;
        conveneApiLogModel.isSentSuccessfully = false;
        conveneApiLogModel.loggedInUser = this.userObject.loggedInUserEmail;
        conveneApiLogModel.status = false;
        conveneApiLogModel.statusCode = '400';
        conveneApiLogModel.statusDescription = err;
        this.loadingScreenService.startLoading();
        this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
          if (affectedRows > 0) {
            //success
          }
          this.loadingScreenService.stopLoading();
        },
          (errorDetails) => {
            this.loadingScreenService.stopLoading();
            console.log(err);
          });
      });
  }

  setEditCollabSpaceModel(collaborationSiteId: number) {
    this.checkEditCollabSpaceNameExists = false;
    this.EditCollabSpaceNameRequired = false;
    this.validEditColabSpaceName = true;
    this.invalidvaluesCN = null;
    this.selectedCollabSpaceDetailOnEdit = this.collaborationSiteList.find(x => x.collaborationSiteId == collaborationSiteId);
    this.UpdatedCollabSpaceName = this.selectedCollabSpaceDetailOnEdit.collaborationSiteName;
  }
  UpdateCollabSiteName() {
    this.checkEditCollabSpaceNameExists = false;
    this.EditCollabSpaceNameRequired = false;
    this.validEditColabSpaceName = true;
    if (this.UpdatedCollabSpaceName == null || this.UpdatedCollabSpaceName.trim() == '') {
      this.EditCollabSpaceNameRequired = true;
      this.validEditColabSpaceName = true;
    }
    else if (!this.validateAssignmentName(this.UpdatedCollabSpaceName)) {
      this.validEditColabSpaceName = false;
    } else if (this.CheckDuplicateSiteNameOnEdit()) {
      this.checkEditCollabSpaceNameExists = true;
    } else {
      $('#modal-EditCollabSpace').modal('hide');
      this.EngagementNameRequired = false;
      this.validEngagementName = true;
      const engObj = new UpdateEngagementNameMode();
      engObj.engagementId = this.engagementId;
      engObj.engagementName = this.engagementList.engagementName;
      engObj.collaborationSiteId = this.selectedCollabSpaceDetailOnEdit.collaborationSiteId;
      engObj.collaborationSiteName = this.UpdatedCollabSpaceName;
      this.checkEditCollabSpaceNameExists = false;
      this.engagementService.UpdateEngagementName(engObj).subscribe(
        (isCollabSpaceUpdated: boolean) => {
          if (isCollabSpaceUpdated) {
            this.selectedCollabSpaceDetailOnEdit.collaborationSiteName = this.UpdatedCollabSpaceName;
            this.getEngagementCollaborationsById(this.engagementId);
            const apiRequest = new ApiRequest();
            apiRequest.title = this.engagementList.clientName + ' | ' + this.selectedCollabSpaceDetailOnEdit.collaborationSiteName;
            apiRequest.siteUrl = this.selectedCollabSpaceDetailOnEdit.siteURL;
            if (apiRequest.siteUrl != null && apiRequest.siteUrl != '' && apiRequest.siteUrl != undefined) {
              this.engagementService.UpdateEngagementTitle(apiRequest).subscribe(result => {
                this.selectedCollabSpaceDetailOnEdit = null;
              });
            }
          }
        },
        () => {
          this.checkEditCollabSpaceNameExists = false;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
    }
  }
  CancelEditCollabSpaceName() {
    this.validEditColabSpaceName = true;
    this.checkEditCollabSpaceNameExists = false;
    this.EditCollabSpaceNameRequired = false;
    this.selectedCollabSpaceDetailOnEdit = null;
    this.UpdatedCollabSpaceName = '';
    this.invalidvaluesCN = "";
  }
  RemoveCollabSpace(removeCollabSiteId: number) {

    if (removeCollabSiteId != null && removeCollabSiteId != undefined && removeCollabSiteId > 0) {
      this.loadingScreenService.startLoading();
      this.engagementService.RemoveCollaborationSpace(this.engagementId, removeCollabSiteId).subscribe(
        deletedResult => {
          if (deletedResult == "Success") {
            this.getEngagementCollaborationsById(this.engagementId);
          } else {
            this.loadingScreenService.stopLoading();
          }
        },
        () => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
    }
  }
  CheckDuplicateSiteNameOnEdit(): boolean {
    let isDuplicate: boolean = false;
    let filteredList = this.collaborationSiteList.filter(x => x.collaborationSiteId != this.selectedCollabSpaceDetailOnEdit.collaborationSiteId);
    for (let x of filteredList) {
      if (x.collaborationSiteName.toLowerCase() == this.UpdatedCollabSpaceName.toLowerCase()) {
        isDuplicate = true;
        break;
      }
    }
    return isDuplicate;
  }

  SetWorkspaceIdForEngageSummaryReport(isDownload: boolean) {
    if (this.activeTabName === 'acceptance') {
      console.log('SetWorkspaceIdForEngageSummaryReport func called',
        this.selectedWorkspaceDocumentItem.workspaceId, this.engagementList.siteUrl, isDownload);

      this.siteUrlForSummaryReport = this.engagementList.siteUrl;
      this.workspaceIdForSummaryReport = this.selectedWorkspaceDocumentItem.workspaceId;
      this.isDownloadForSummaryReport = isDownload;
    } else {
      let description: string;
      description = "To generate report, go to Acceptance tab and click create report.";
      this.DisplayDialog("warning", "Create report", description, 1, true);
    }
  }

  UploadEngageSummaryReportToSharepoint(loadStatus: string) {
    this.loadingScreenService.startLoading();
    console.log('UploadEngageSummaryReportToSharepoint ', loadStatus);
    if (loadStatus == 'Success') {
      console.log('UploadEngageSummaryReportToSharepoint func called - start ');
      const FirstPageHeaderSection = document.getElementById('FirstPageHeaderSection').innerHTML;
      const PageHeaderSection = document.getElementById('PageHeaderSection').innerHTML;
      const ClientSection = document.getElementById('ClientSection').innerHTML;
      const EngagementSection = document.getElementById('EngagementSection').innerHTML;
      const AssignmentSection = document.getElementById('AssignmentSection').innerHTML;
      const TemplateSection = document.getElementById('TemplateSection').innerHTML;
      const ApprovalSection = document.getElementById('ApprovalSection').innerHTML;
      const NotificationSection = document.getElementById('NotificationSection').innerHTML;
      const AttachmentSection = document.getElementById('AttachmentSection').innerHTML;
      const ReportGeneratedOn = document.getElementById('ReportGeneratedOn').innerHTML;
      const GASection = document.getElementById('GASection').innerHTML;

      const hdcollection = document.getElementsByClassName('hd-answer-page');
      if (hdcollection.length > 0) {
        let answerSummaryElement = document.getElementsByClassName('hd-answer-page')[0].innerHTML;
        const searchTerm1 = /<td class="HDASAnsValue" style=" width:70% ">/gi;
        answerSummaryElement = answerSummaryElement
          .replace(searchTerm1, '<td class="HDASAnsValue" style="width:160px;border:1px solid #E0E0E0">');
        const searchTerm2 = /&nbsp;/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm2, '');
        const searchTerm3 = /font-size:125%;/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm3, 'font-size:18;');
        const searchTerm4 = /<td class="HDASVarPrompt" style="">/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm4, '<td class="HDASVarPrompt" style="border: 1px solid #E0E0E0;">');
        const searchTerm5 = /<td class="HDASDlgTitle" colspan="2">/gi;
        answerSummaryElement = answerSummaryElement
          .replace(searchTerm5, '<td class="HDASDlgTitle" colspan="2" style="border:1px solid #E0E0E0">');
        const searchTerm6 = /<td class="HDASDlgElem" style="" colspan="2">/gi;
        answerSummaryElement = answerSummaryElement
          .replace(searchTerm6, '<td class="HDASDlgElem" style="border:1px solid #E0E0E0" colspan="2">');
        const searchTerm7 = /<td style="" colspan="2">/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm7, '<td style="border:1px solid #E0E0E0" colspan="2">');

        const searchTerm = /width="100%"/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm, '')
        const startIndex = answerSummaryElement.indexOf("<table");
        const endIndex = answerSummaryElement.length;
        const answerSummaryContent = answerSummaryElement.substring(startIndex, endIndex);

        const pageBreakHtml = '<div class="pdf-pagebreak-before"> </div>';

        let finalHtml = FirstPageHeaderSection + PageHeaderSection + ClientSection + EngagementSection
          + pageBreakHtml + PageHeaderSection + AssignmentSection
          + pageBreakHtml + PageHeaderSection + TemplateSection + answerSummaryContent
          + pageBreakHtml + PageHeaderSection + ApprovalSection + NotificationSection + AttachmentSection;
        if (this.clmDetails?.name?.length > 0) {
          finalHtml = FirstPageHeaderSection + PageHeaderSection + ClientSection + EngagementSection
            + pageBreakHtml + PageHeaderSection + AssignmentSection
            + pageBreakHtml + PageHeaderSection + GASection + TemplateSection + answerSummaryContent
            + pageBreakHtml + PageHeaderSection + ApprovalSection + NotificationSection + AttachmentSection;
        }
        const html = htmlToPdfmake(finalHtml, {
          tableAutoSize: true
        });

        const footerhtml = htmlToPdfmake(ReportGeneratedOn);


        pdfMake.fonts = {
          GTWalsheimProRegular: {
            normal: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Regular.ttf',
            bold: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Bold.ttf',
            italics: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Regular.ttf',
            bolditalics: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Bold-Oblique.ttf'
          },
        }

        const docDefinition = {
          footer: footerhtml,
          content: html,
          styles: {
            PageHeader: {

            },
            ReportTitle: {
              bold: true,
              color: '#4F2D7F'
            },
            HDASDlgTitle: {
              fillColor: '#F5F5F5',
              bold: true,
              color: '#4F2D7F',
              fontSize: 12,
              width: '675px',
              alignment: 'center'
            },
            TableHeader: {
              fillColor: '#F5F5F5',
              bold: true,
              color: '#4F2D7F',
              width: '675px',
              fontSize: 12,
              alignment: 'center'
            },
            TableSubHeader: {
              fillColor: '#F5F5F5',
              fontSize: 10
            },
            TableSubHeaderLocation: {
              fillColor: '#F5F5F5',
              fontSize: 10,
              alignment: 'center'
            },
            FieldHeader: {
              fillColor: '#F5F5F5',
              fontSize: 10
            },
            FieldText: {
              fontSize: 10
            },
            FieldTextBold: {
              fontSize: 10,
              bold: true

            },
            HDASVarPrompt: {
              fontSize: 10
            },
            HDASAnsValue: {
              fontSize: 10
            },
            HDASDlgElem: {
              fontSize: 10
            },
            FooterText: {
              fontSize: 10,
              alignment: 'right'
            }
          },
          pageBreakBefore: function (currentNode) {
            return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
          },
          defaultStyle: {
            font: 'GTWalsheimProRegular'
          }
        };

        let filename = 'Engage Summary.pdf';
        if (this.engagementList.appliedTnC) {
          filename = 'Engage Summary Revised Responses.pdf';
        }

        if (this.workspaceDocumentCount > 1) {
          filename = `${this.selectedWorkspaceDocumentItem.documentAliasName} - ${filename}`;
        }

        if (this.isDownloadForSummaryReport) {
          this.loadingScreenService.stopLoading();
          pdfMake.createPdf(docDefinition).download(filename);
          this.siteUrlForSummaryReport = null;
          this.workspaceIdForSummaryReport = null;
          this.isDownloadForSummaryReport = false;
        }
      } else {
        console.error('Could not load HotDocs answer summary');
        this.DisplayHotDocsNoAnswerSummaryAlert();
      }

    }
  }

  DisplayHotDocsNoAnswerSummaryAlert() {
    this._alertService.remove(this.alert);
    this.loadingScreenService.stopLoading();
    this._alertService.add('Issue with HotDocs review responses. Please contact suppport.', 'danger', null);
  }

  GetContinuanceEligibility() {
    if (this.continuanceEligibleData.solutionCode.split(';').includes(this.engagementList.solutionCode) &&
      this.continuanceEligibleData.hdTemplatePackageId.split(';').includes(this.CurrentWorkspaceData.hdTemplatePackageId) &&
      this.engagementList.continuanceEligibility) {
      let continuanceEligibility: ContinuanceEligibilityModel = new ContinuanceEligibilityModel();
      continuanceEligibility.engagementId = this.engagementId;
      continuanceEligibility.solutionCode = this.engagementList.solutionCode;
      continuanceEligibility.hdTemplatePackageId = this.CurrentWorkspaceData.hdTemplatePackageId;
      continuanceEligibility.hdWorkItemId = this.CurrentWorkspaceData.hdWorkItemId;
      this.hdService.GetContinuanceEligibility(continuanceEligibility).subscribe((eligibilityResult: boolean) => {
        this.IsContinuanceEligible = eligibilityResult;
      },
        err => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    } else {
      this.IsContinuanceEligible = this.engagementList.continuanceEligibility;
    }
  }

  SaveManageRelatedSpaces() {
    console.log('collaborationSpacePriorYearMappingData save ==>', this.collaborationSpacePriorYearMappingData);

    if (Number(this.selectedCollaborationSiteId) >= 0) {
      this.loadingScreenService.startLoading();
      let collabrartionPermissionManagementModel = new CollabrartionPermissionManagementModel();
      this.updateCollaborationSpacePriorYearMappingModelList = [];
      this.collaborationSpacePriorYearMappingData.forEach(updatedItem => {
        let updateCollaborationSpacePriorYearMappingModel = new UpdateCollaborationSpacePriorYearMappingModel();
        updateCollaborationSpacePriorYearMappingModel.selectedCollaborationSiteId = this.selectedCollaborationSiteId;
        updateCollaborationSpacePriorYearMappingModel.pYCollaborationSiteId = updatedItem.collaborationSiteId;
        updateCollaborationSpacePriorYearMappingModel.updateMapping = updatedItem.mappingExists;
        updateCollaborationSpacePriorYearMappingModel.updatedBy = this.userObject.userName;
        this.updateCollaborationSpacePriorYearMappingModelList.push(updateCollaborationSpacePriorYearMappingModel);

        var objectsAreSame = true;
        this.collaborationSpacePriorYearMappingDataInitialObj.listobj.forEach(initialItem => {
          if (initialItem.collaborationSiteId == updatedItem.collaborationSiteId) {
            if (initialItem.mappingExists != updatedItem.mappingExists) {
              objectsAreSame = false;
            }
          }
        })

        if (!objectsAreSame) {
          collabrartionPermissionManagementModel.currentSiteUniqueID = this.collaborationSiteList
            .find(site => site.collaborationSiteId == this.selectedCollaborationSiteId).siteUniqueID.toString();

          if (updatedItem.mappingExists) {
            collabrartionPermissionManagementModel.pYCollabSiteUniqueIDList.push(updatedItem.siteUniqueID);
          } else {
            collabrartionPermissionManagementModel.pYCollabSiteUniqueIDListForRemove.push(updatedItem.siteUniqueID);
          }
        }

      });

      // IF USER HAS NOT CHANGES ANTTHING
      if (collabrartionPermissionManagementModel.currentSiteUniqueID != undefined) {
        let updateCollaborationSpacePriorYearMappingModelWrapper = new UpdateCollaborationSpacePriorYearMappingModelWrapper();
        updateCollaborationSpacePriorYearMappingModelWrapper.updateCollaborationSpacePriorYearMappingModels = this.updateCollaborationSpacePriorYearMappingModelList;

        console.log('updateCollaborationSpacePriorYearMappingModelWrapper ==>', updateCollaborationSpacePriorYearMappingModelWrapper);

        this.collaborationSiteService.updateCollaborationSpacePriorYearData(updateCollaborationSpacePriorYearMappingModelWrapper)
          .subscribe((status: boolean) => {
            if (status) {
              console.log('status', status);
              this.sharepointService.ContinuanceSitePermissionManagementAddRemove(collabrartionPermissionManagementModel).subscribe(
                result => {
                  console.log('ContinuanceSitePermissionManagementAddRemove Result', result);
                },
                error => {
                  this.loadingScreenService.stopLoading();
                  this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                });

              this.collaborationSiteService.getCollaborationSpacePriorYearList(this.engagementList.engagementId).subscribe(
                collabPriorYears => {
                  this.collaborationSpacePriorYearViewModelList = collabPriorYears;
                  this.CreateUIForCollaborationSpace();
                  setTimeout(() => {
                    this.enableDraggable(false);
                    this.loadingScreenService.stopLoading();
                    $('#modal-ManageRelatedSpaces').modal('hide');
                  }, 200);
                }
              );
            } else {
              this.loadingScreenService.stopLoading();
            }
          },
            err => {
              this.loadingScreenService.stopLoading();
              this.logService.error('Engagement status update failed.', err);
              window.location.reload();
            });
      }
      else {
        this.loadingScreenService.stopLoading();
        $('#modal-ManageRelatedSpaces').modal('show');
      }


    }
    else {
      $('#modal-ManageRelatedSpaces').modal('show');
    }
  }

  ManageRelatedSpaces_PopUpOpen(collaborationSiteId: number) {
    this.selectedCollaborationSiteId = collaborationSiteId;
    this.collaborationSiteService.getAllCollaborationSpacePriorYearMappingData(collaborationSiteId).subscribe(
      result => {
        this.collaborationSpacePriorYearMappingData = result;
        this.collaborationSpacePriorYearMappingDataInitialObj.listobj = JSON.parse(JSON.stringify(result))
        console.log("collaborationSpacePriorYearMappingDataInitialObj", this.collaborationSpacePriorYearMappingDataInitialObj.listobj);

        setTimeout(() => {
          $('#modal-ManageRelatedSpaces').modal('show');
        }, 400);
        console.log('collaborationSpacePriorYearMappingData ==>', this.collaborationSpacePriorYearMappingData);
      }
    );
  }

  cancelManageRelatedSpacesModel() {
    this.selectedCollaborationSiteId = 0;
  }

  CreateWorkItem() {
    if (this.CurrentWorkspaceData.hdWorkItemId != null &&
      this.CurrentWorkspaceData.hdWorkItemId != '' &&
      this.CurrentWorkspaceData.hdWorkItemId != undefined) {
      this.loadingScreenService.startLoading();
      this.workspaceService.getMaxWorkspaceId().subscribe(
        maxWorkspaceCode => {
          if (maxWorkspaceCode <= 0) {
            maxWorkspaceCode = 1;
          }
          this.CreateWorkItemData.workspaceCode = maxWorkspaceCode;
          this.CreateWorkItemData.siteUniqueId = this.primaryCollaborationDetails.siteUniqueID;
          this.CreateWorkItemData.originalWorkItemId = this.CurrentWorkspaceData.hdWorkItemId;
          this.CreateWorkItemData.templatePackageId = this.CurrentWorkspaceData.hdTemplatePackageId;
          this.CreateWorkItemData.appliedTnC = true;
          this.hdService.CreateWorkItemData(this.CreateWorkItemData).subscribe(
            workItemResult => {
              if (workItemResult.id != null &&
                workItemResult.id != "" &&
                workItemResult.id != undefined &&
                workItemResult.id != '00000000-0000-0000-0000-000000000000') {
                let WorkspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
                WorkspaceData.hdTemplatePackageId = workItemResult.templatePackageId;
                WorkspaceData.hdTemplatePackageVersionId = workItemResult.templatePackageVersionId;
                WorkspaceData.hdCurrentTempVersion = workItemResult.templateCurrentVersion;
                WorkspaceData.hdWorkItemId = workItemResult.id;
                WorkspaceData.hdWorkGroupId = workItemResult.workGroupId;
                WorkspaceData.status = WorkspaceInterviewsStatus.DraftSession;
                WorkspaceData.workspaceId = this.CurrentWorkspaceData.workspaceId;
                let updateEngagementTnC: UpdateEngagementTnCModel = new UpdateEngagementTnCModel();
                updateEngagementTnC.workspaceData = WorkspaceData;
                updateEngagementTnC.engagementId = this.engagementId;
                this.engagementService.UpdateEngagementTnC(updateEngagementTnC).subscribe(
                  updateTnCResult => {
                    if (updateTnCResult) {
                      this.router.navigate(['Interviews/' + this.CurrentWorkspaceData.workspaceId]);
                    }
                    this.loadingScreenService.stopLoading();
                  }
                );
              } else {
                this.loadingScreenService.stopLoading();
                this._alertService.add('Something went wrong while creating HotDocs workitem! Please contact system administrator.', 'danger', null);
              }
            },
            error => {
              this.loadingScreenService.stopLoading();
              this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
            });
        },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    }// end if
  }

  ValidateUserIsTeamMemberOfEngagement() {
    return this.workspaceService.getCollabrationTeamMembers(this.engagementId).subscribe(
      result => {
        this.memberList = result;
        console.log(this.memberList.length);
        console.log(this.memberList);
        if (this.memberList.length > 0) {
          for (var i in this.memberList) {
            if (this.memberList[i].memberEmailId.toLowerCase() == this.userObject.userName.toLowerCase()) {
              this.isTeamMember = true;

              break;
            }
          }
        }
      },
      () => {
        this.memberList = null;
      }
    );

  }

  ValidateUserIsEPPMDOfEngagement() {
    if (this.engagementList.engagementPartner.toLowerCase() == this.userObject.userName.toLowerCase()) {
      this.isEPPMD = true;
    }
    else {
      this.isEPPMD = false;
    }

  }

  ProcessConfirmationPopUpForUpdateQuestionnaire() {


    if (this.updateQuestionnairePreCheckModel.currentStatus === 'Closed') {
      let dialogDesc: string = 'Engagement is closed by someone else, please click OK to refresh the page.';
      let dialogInput = '';
      const dialogType = 'warning';
      const action = EngagementDialogTitles.EngagementClosed;
      let engId: string = String(this.engagementId);
      let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
      const _dialogRedirectUrl = '';
      this.displayConfirmationPopUp(dialogType, action, dialogDesc, 1, dialogInput, false, false, engId, loggedInUserEmail, _dialogRedirectUrl);

    }
    else if (this.updateQuestionnairePreCheckModel.appliedTncCheck) {
      let dialogDesc: string = 'Engagement revised questionnaire approval is in progress, please navigate from engagement dashboard.';
      let dialogInput = '';
      const dialogType = 'warning';
      const action = EngagementDialogTitles.UpdateInProgress;
      let engId: string = String(this.engagementId);
      let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
      const _dialogRedirectUrl = '';
      this.displayConfirmationPopUp(dialogType, action, dialogDesc, 1, dialogInput, false, false, engId, loggedInUserEmail, _dialogRedirectUrl);

    }

    else {
      let dialogDesc: string = '';
      let dialogInput = '';
      const dialogType = 'danger';
      const action = EngagementDialogTitles.ReEditQuestionnaire;
      let engId: string = String(this.engagementId);
      let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
      const _dialogRedirectUrl = '';
      this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false, engId, loggedInUserEmail, _dialogRedirectUrl);
    }
  }
  CurrentEngagementStatus() {

    this.engagementService.GetEngagementCurrentStatus(this.engagementId).subscribe(
      result => {
        this.updateQuestionnairePreCheckModel = result;

        this.ProcessConfirmationPopUpForUpdateQuestionnaire();
      }
    );


  }

  QuestionSummaryDisplayForOriginal() {
    this.isrevisedresponsetabactive = false;
    console.log('QuestionSummaryDisplayForOriginal func ', this.CurrentWorkspaceData);
    if (this.CurrentWorkspaceData != null) {
      this.engDetailOriginalworkItemIdForReviewResponse = this.CurrentWorkspaceData.orginalHDWorkItemId;
      this.engDetailOriginalworkItemVersionIdReviewResponse = this.CurrentWorkspaceData.orginalHDWorkItemVersionId;
      this.engDetailWorkItemId = null;
      this.engDetailWorkItemVersionId = null
    }
    this.lastUpdatedByForReviewResponse = "Last updated by " + this.CurrentWorkspaceData.lastActivityBy + " on " + this.datepipe.transform(this.CurrentWorkspaceData.lastActivityDate, 'MM/dd/yyyy');
  }

  get isTnCInProgress(): boolean {
    if (this.engagementList
      && this.engagementList.appliedTnC
      && (this.engagementList.status == EngagementStatus.EngDraft
        || this.engagementList.status == EngagementStatus.EngInReview
        || this.engagementList.status == EngagementStatus.EngDeclined
        || this.engagementList.status == EngagementStatus.EngRPRuleEngineError)) {

      this.alertServiceFun('Engagement revised questionnaire approval is in progress, please navigate from engagement dashboard', 'danger');

      return true;
    }
    else {
      return false;
    }
  }

  UpdateReviewResponseDisplay($event) {
    // if (!this.engagementList.appliedTnC) {
    //   $('#OriginalreviewReponseNavItem').tab('show');
    //   this.QuestionSummaryDisplayForOriginal();
    // }
    // else {
    //   $('#reviewReponseNavItem').tab('show');
    //   this.QuestionSummaryDisplay();
    // }
    if (this.isrevisedresponsetabactive) {
      this.QuestionSummaryDisplay();
    }
    else {
      this.QuestionSummaryDisplayForOriginal();
    }
    setTimeout(
      () => {
        this.UploadEngageSummaryReportToSharepoint($event);
      }, 3000
    );
  }
  HightlightSection(option: string) {
    const sharingConsents = $('#sharing-consent');
    const signatures = $('#signatures');
    $("#ConsentMessage").text('');


    switch (option) {
      case 'sharing-consent':
        sharingConsents.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-white.svg');
        signatures.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        break;
      case 'signatures':
        sharingConsents.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-blue.svg');
        signatures.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-white.svg');
        break;
      default:
        sharingConsents.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-white.svg');
        signatures.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        break;
    }
  }

  GetEngagementConsentDetails() {
    this.engagementService.GetEngagementConsentDetails(this.engagementId).subscribe(
      result => {
        this.engagementConsentModel = result;
        console.log("Engagement consent count" + this.engagementConsentModel.length);
        this.MapEngagementConsentModelWithInsertModel();
        if (this.isSignatureCompleted && this.engagementConsentModel.length > 0
          && this.isConsentFeatureEnabled && this.isConsentSaveClicked) {
          this.GenerateClientConsentEvent();
        }
      },
      () => {
        this.engagementConsentModel = null;
        $("#ConsentMessage").text('');
        this.logService.log("error in fetching engagement consent details for the engagement" + this.engagementId);
      }
    );
  }

  GenerateClientConsentEvent() {
    let clientConsent = [];
    let filteredResult;
    filteredResult = this.clientConsentOptions.filter(x => x.isChecked == true);
    if (filteredResult.length > 0) {
      for (let i = 0; i < filteredResult.length; i++) {
        clientConsent.push(filteredResult[i].clientConsentOptionNumber);
      }
    }
    this.logService.info('Client Consent ', clientConsent);
    if (clientConsent.length > 0) {
      let clientConsentEvent = new EngageEvent();
      clientConsentEvent.engageEventType = 'ClientConsent';
      clientConsentEvent.engagementId = this.engagementId;
      clientConsentEvent.engagementName = this.engagementList.engagementName;
      clientConsentEvent.createdBy = this.userObject.loggedInUserEmail;
      clientConsentEvent.clientConsent = clientConsent.toString();
      this.logService.info('Event data ', clientConsentEvent);

      this.engagementService.GenerateEventInEventGrid(clientConsentEvent).subscribe(
        result => {
          this.logService.info('Client Consent sent to Event Grid.', result);
        }, error => {
          this.logService.error('Error in sending Client Consent event to Event Grid.', error);
        }
      );
    }
  }

  GenerateIndusConsentEvent(consent: string) {
    this.logService.info('Indus Consent ', consent);

    if (consent.length > 0) {
      let indusConsentEvent = new EngageEvent();
      indusConsentEvent.engageEventType = 'IndusConsent';
      indusConsentEvent.engagementId = this.engagementId;
      indusConsentEvent.collaborationSiteId = this.engagementList.collaborationSiteId;
      indusConsentEvent.engagementName = this.engagementList.engagementName;
      indusConsentEvent.createdBy = this.userObject.loggedInUserEmail;
      indusConsentEvent.indusConsent = consent;
      this.logService.info('Event data ', indusConsentEvent);

      this.engagementService.GenerateEventInEventGrid(indusConsentEvent).subscribe(
        result => {
          this.logService.info('Indus Consent sent to Event Grid.', result);
        }, error => {
          this.logService.error('Error in sending Indus Consent event to Event Grid.', error);
        }
      );
    }
  }

  HandleEngagementConsentInsertData(index: number) {
    this.engagementConsentInsertModelArray.engagementConsent[index].isDeleted = !this.engagementConsentModel[index].isDeleted;
    this.engagementConsentModel[index].isDeleted = !this.engagementConsentModel[index].isDeleted;
  }

  MapEngagementConsentModelWithInsertModel() {
    this.engagementConsentInsertModelArray.engagementConsent = [];
    this.engagementConsentModel.forEach(element => {

      this.engagementConsentInsertModelArray.engagementConsent.push(new EngagementConsentInsertModel(this.userObject.loggedInUserEmail, this.engagementId, element.id, element.isDeleted));
    });
    console.log(JSON.stringify(this.engagementConsentInsertModelArray));
  }

  InsertUpdateConsentData() {


    this.engagementService.InsertUpdateEngagementConsent(this.engagementConsentInsertModelArray).subscribe(
      result => {
        console.log(result);
        this.GetEngagementConsentDetails();
        if (result === 'Success') {
          $("#ConsentMessage").text('Consent updated successfully');
          this.isConsentSaveClicked = true;
        } else {
          this.isConsentSaveClicked = false;
        }
      }, (error) => {
        console.error('Client Consent', error);
        this.logService.log("error in inserting or updating engagement consent details for the engagement" + this.engagementId);
        $("#ConsentMessage").text('');
      }
    );
  }

  ResetConsentData() {
    this.GetEngagementConsentDetails();
    if (this.engagementConsentModel != null) {
      $("#ConsentMessage").text('Consent reset successfully');
    }
    else {
      $("#ConsentMessage").text('');
    }
  }

  ApplyAutoCloseoutSitesPolicyOnManualClose(siteUrls: string[]) {
    this.loadingScreenService.startLoading();
    let applySitePolicyRequestModel: ApplySitePolicyRequestModel = {
      primarySiteUrl: this.primaryCollaborationDetails.siteURL,
      siteUrls: siteUrls,
      resetSiteStartDate: false
    };

    this.subscriptions.push(this.sharepointService.ApplyAutoCloseoutSitesPolicyOnManualClose(this.engagementId, applySitePolicyRequestModel).subscribe(result => {
      this.loadingScreenService.startLoading();
      this.isEngagementUpdateInProcess = false;
      this.getEngagementCollaborationsById(this.engagementId);
    },
      err => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      }));
  }

  DisplayVoidEngagementDialog(engagementId: number) {
    this.isEngSummaryScreen = true;
    let dialogDesc: string = '';
    let dialogInput = '';
    const dialogType = 'danger';
    const action = EngagementDialogTitles.VoidEngagement;
    let engId: string = String(engagementId);
    let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false, engId, loggedInUserEmail);
  }
  ProcessVoidEngagement() {
    this.isEngSummaryScreen = false;
    const engObj = new EngagementList();
    engObj.status = EngagementStatus.EngVoided;
    engObj.engagementId = this.engagementId;
    engObj.LastModifiedBy = this.userObject.loggedInUserEmail; engObj.LastModifiedByName = this.userObject.loggedInUserFullName;
    this.isEngagementClosed = true;
    //Update engagement status
    this.loadingScreenService.startLoading();
    this.UpdateEngagementStatusData.engagementList = engObj;
    this.UpdateEngagementStatusData.isEngContinuanceEligible = this.IsContinuanceEligible;
    this.UpdateEngagementStatusInDB();
  }

  toggleEngagementClosureRequiredTabs() {
    if (this.closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout?.length > 0) {
      let isAllOpen = true;
      let isAllClosed = true;
      this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.forEach(section => { //for checking all opend or all closed 
        switch (section.code) {
          case CloseoutSections.RM:
            if (this.engagementCloseoutRetention?.currentState == 'expand') {
              isAllClosed = false;
            } else {
              isAllOpen = false
            }
            break;
          case CloseoutSections.FAI:
            if (this.engagementCloseoutArchivalComponent?.currentState == 'expand') {
              isAllClosed = false;
            } else {
              isAllOpen = false
            }
            break;
          case CloseoutSections.FCC:
            if (this.engagementCloseoutCleanUpChecklistComponent?.currentState == 'expand') {
              isAllClosed = false;
            } else {
              isAllOpen = false
            }
            break;
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey?.currentState == 'expand') {
              isAllClosed = false;
            } else {
              isAllOpen = false
            }
            break;
          case CloseoutSections.ALC:
            if (this.EngagementCloseoutAuditChecklistComponent?.currentState == 'expand')
              isAllClosed = false;
            else {
              isAllOpen = false
            }
            break;
          case CloseoutSections.QC:
            if (this.engagementCloseoutQualityChecklistComponent?.currentState == 'expand')
              isAllClosed = false;
            else {
              isAllOpen = false
            }
            break;
        }
      });
      // if (isAllClosed || isAllOpen)
      this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.forEach(section => {
        switch (section.code) {
          case CloseoutSections.RM:
            if (this.engagementCloseoutRetention != undefined) {
              if (isAllClosed)
                this.engagementCloseoutRetention.toggleUp(true);
              else if (isAllOpen)
                this.engagementCloseoutRetention.toggleUpDown(true);
              else {
                if (this.engagementCloseoutRetention.currentState == 'collapse') {
                  this.engagementCloseoutRetention.toggleUp(true);
                }
              }
            }
            break;
          case CloseoutSections.FAI:
            if (this.engagementCloseoutArchivalComponent != undefined) {
              if (isAllClosed)
                this.engagementCloseoutArchivalComponent.toggleUp(true);
              else if (isAllOpen)
                this.engagementCloseoutArchivalComponent.toggleUpDown(true);
              else {
                if (this.engagementCloseoutArchivalComponent.currentState == 'collapse') {
                  this.engagementCloseoutArchivalComponent.toggleUp(true);
                }
              }
            }
            break;
          case CloseoutSections.FCC:
            if (this.engagementCloseoutCleanUpChecklistComponent != undefined) {
              if (isAllClosed)
                this.engagementCloseoutCleanUpChecklistComponent.toggleUp(true);
              else if (isAllOpen)
                this.engagementCloseoutCleanUpChecklistComponent.toggleUpDown(true);
              else {
                if (this.engagementCloseoutCleanUpChecklistComponent.currentState == 'collapse') {
                  this.engagementCloseoutCleanUpChecklistComponent.toggleUp(true);
                }
              }
            }
            break;
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey != undefined) {
              if (isAllClosed)
                this.engagementCloseoutClientVoiceSurvey.toggleUp(true);
              else if (isAllOpen)
                this.engagementCloseoutClientVoiceSurvey.toggleUpDown(true);
              else {
                if (this.engagementCloseoutClientVoiceSurvey.currentState == 'collapse') {
                  this.engagementCloseoutClientVoiceSurvey.toggleUp(true);
                }
              }
            }
            break;
          case CloseoutSections.ALC:
            if (this.EngagementCloseoutAuditChecklistComponent != undefined) {
              if (isAllClosed)
                this.EngagementCloseoutAuditChecklistComponent.toggleUp(true);
              else if (isAllOpen)
                this.EngagementCloseoutAuditChecklistComponent.toggleUpDown(true);
              else {
                if (this.EngagementCloseoutAuditChecklistComponent.currentState == 'collapse') {
                  this.EngagementCloseoutAuditChecklistComponent.toggleUp(true);
                }
              }
            }
            break;
          case CloseoutSections.QC:
            if (this.engagementCloseoutQualityChecklistComponent != undefined) {
              if (isAllClosed)
                this.engagementCloseoutQualityChecklistComponent.toggleUp(true);
              else if (isAllOpen)
                this.engagementCloseoutQualityChecklistComponent.toggleUpDown(true);
              else {
                if (this.engagementCloseoutQualityChecklistComponent.currentState == 'collapse') {
                  this.engagementCloseoutQualityChecklistComponent.toggleUp(true);
                }
              }
            }
            break;
        }
      });
    }
  }

  resetEngagementClosureRequiredTasksSection() {
    if (this.closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout?.length > 0) {
      this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.forEach(section => {
        switch (section.code) {
          case CloseoutSections.RM:
            if (this.engagementCloseoutRetention != undefined) {
              this.engagementCloseoutRetention.toggleUpDown(false);
              this.engagementCloseoutRetention.showRequiredWarning = false;
            }
            break;
          case CloseoutSections.FAI:
            if (this.engagementCloseoutArchivalComponent != undefined) {
              this.engagementCloseoutArchivalComponent.toggleUpDown(false);
              this.engagementCloseoutArchivalComponent.showRequiredWarning = false;
              this.engagementCloseoutArchivalComponent.isEditRFC = false;
            }
            break;
          case CloseoutSections.FCC:
            if (this.engagementCloseoutCleanUpChecklistComponent != undefined) {
              this.engagementCloseoutCleanUpChecklistComponent.toggleUpDown(false);
              this.engagementCloseoutCleanUpChecklistComponent.showRequiredWarning = false;
            }
            break;
          case CloseoutSections.QC:
            if (this.engagementCloseoutQualityChecklistComponent != undefined) {
              this.engagementCloseoutQualityChecklistComponent.toggleUpDown(false);
              this.engagementCloseoutQualityChecklistComponent.showRequiredWarning = false;
            }
            break;
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey != undefined) {
              this.engagementCloseoutClientVoiceSurvey.toggleUpDown(false);
              this.engagementCloseoutClientVoiceSurvey.showRequiredWarning = false;
            }
          case CloseoutSections.ALC:
            if (this.EngagementCloseoutAuditChecklistComponent != undefined) {
              this.EngagementCloseoutAuditChecklistComponent.toggleUpDown(false);
              this.EngagementCloseoutAuditChecklistComponent.showRequiredWarning = false;
            }
        }
      });
    }

    if (this.closeoutSectionStructureAndVisibility?.optionalTasks?.length > 0) {
      this.closeoutSectionStructureAndVisibility.optionalTasks.forEach(section => {
        switch (section.code) {
          case CloseoutSections.RM:
            if (this.engagementCloseoutRetention != undefined) {
              this.engagementCloseoutRetention.toggleUpDown(false);
            }
            break;
          case CloseoutSections.FAI:
            if (this.engagementCloseoutArchivalComponent != undefined) {
              this.engagementCloseoutArchivalComponent.toggleUpDown(false);
            }
            break;
          case CloseoutSections.FCC:
            if (this.engagementCloseoutCleanUpChecklistComponent != undefined) {
              this.engagementCloseoutCleanUpChecklistComponent.toggleUpDown(false);
            }
            break;
          case CloseoutSections.QC:
            if (this.engagementCloseoutQualityChecklistComponent != undefined) {
              this.engagementCloseoutQualityChecklistComponent.toggleUpDown(false);
            }
            break;
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey != undefined) {
              this.engagementCloseoutClientVoiceSurvey.toggleUpDown(false);
            }

            break;
          case CloseoutSections.ALC:
            if (this.EngagementCloseoutAuditChecklistComponent != undefined) {
              this.EngagementCloseoutAuditChecklistComponent.toggleUpDown(false);
            }
        }
      });
    }
  }

  refreshWarningsVisibilityForRequiredSection() {
    if (this.closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout?.length > 0) {
      this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.forEach(section => {
        switch (section.code) {
          case CloseoutSections.RM:
            if (this.engagementCloseoutRetention != undefined) {
              this.engagementCloseoutRetention.showRequiredWarning = !section.isCompleted;
            }
            break;
          case CloseoutSections.FAI:
            if (this.engagementCloseoutArchivalComponent != undefined) {
              this.engagementCloseoutArchivalComponent.showRequiredWarning = !section.isCompleted;
            }
            break;
          case CloseoutSections.FCC:
            if (this.engagementCloseoutCleanUpChecklistComponent != undefined) {
              this.engagementCloseoutCleanUpChecklistComponent.showRequiredWarning = !section.isCompleted;
            }
            break;
          case CloseoutSections.QC:
            if (this.engagementCloseoutQualityChecklistComponent != undefined) {
              this.engagementCloseoutQualityChecklistComponent.showRequiredWarning = !section.isCompleted;
            }
            break;
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey != undefined) {
              this.engagementCloseoutClientVoiceSurvey.showRequiredWarning = !section.isCompleted;
            }
            break;
          case CloseoutSections.ALC:
            if (this.EngagementCloseoutAuditChecklistComponent != undefined) {
              this.EngagementCloseoutAuditChecklistComponent.showRequiredWarning = !section.isCompleted;
            }
        }
      });
    }
  }

  toggleRequiredTabs() {
    if (this.closeoutSectionStructureAndVisibility?.requiredTasks?.length > 0) {
      this.closeoutSectionStructureAndVisibility.requiredTasks.forEach(section => {
        switch (section.code) {
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey != undefined) {
              this.engagementCloseoutClientVoiceSurvey.toggleUp(true);
            }
            break;
        }
      });
    }
  }

  toggleOptionalTabs() {
    if (this.closeoutSectionStructureAndVisibility?.optionalTasks?.length > 0) {
      this.closeoutSectionStructureAndVisibility.optionalTasks.forEach(section => {
        switch (section.code) {
          case CloseoutSections.IM:
            this.engagementCloseoutInsightMetrixComponent.toggleUp(true);
            break;
          case CloseoutSections.ALC:
            this.EngagementCloseoutAuditChecklistComponent.toggleUp(true);
            break;
        }
      });
    }
  }

  private applyAbandonSitePolicy() {
    this.loadingScreenService.startLoading();
    if (this.engagementList.siteUrl != '' && this.engagementList.siteUrl != null) {
      this._sharepointService.ApplyAbandonSitePolicy(this.engagementList.siteUrl).subscribe(status => {
        this.loadingScreenService.stopLoading();
      },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    }
  }
  initializeApproveDenyExtensionModel() {
    this.approveDenyExtensionViewModal = new ApproveDenyExtensionViewModal();
    this.approveDenyExtensionModal = new ApproveDenyExtensionModal();
    this.approveDenyExtensionModal.engagementId = this.engagementId;
    this.approveDenyExtensionModal.approveDenyByName = this.userObject.loggedInUserFullName;
    this.approveDenyExtensionModal.approveDenyByEmail = this.userObject.loggedInUserEmail;
    this.approveDenyExtensionModal.status = this.engagementDueDateExtensionPendingModel?.status;
    this.approveDenyExtensionModal.denyReason = this.engagementDueDateExtensionPendingModel?.denyReason;
    this.approveDenyExtensionViewModal.approveDenyExtensionModal = new ApproveDenyExtensionModal();
    this.approveDenyExtensionViewModal.approveDenyExtensionModal = this.approveDenyExtensionModal;
  }

  getEngagementDueDateExtensions(engagementId: number) {
    this.engService.GetEngagementDueDateExtensions(engagementId).subscribe(
      result => {
        this.engagementDueDateExtensions = result;
        console.log('engagementDueDateExtensions ', this.engagementDueDateExtensions);
        if (this.engagementDueDateExtensions != undefined && this.engagementDueDateExtensions.length > 0) {
          this.engagementDueDateExtensionPendingModel =
            this.engagementDueDateExtensions.find(ext => ext.isActive && ext.status == 'Pending');
          console.log('engagementDueDateExtensionPendingModel ', this.engagementDueDateExtensionPendingModel);
          if (this.engagementDueDateExtensionPendingModel != undefined && this.engagementDueDateExtensionPendingModel.status == 'Pending') {
            this.updateExtensionDueDateRequest()
          }
        }
      });
    this.isExtensionHistory = false;
  }

  engagementClosure() {
    this.switchTabPanelIcons('engCloseoutCheckList');
    this.OnHDQuestionAnswerLoaded(true);//To enable other tabs by default when closeout tab is loaded on page load.
    this.EngagementClosureValidation();
  }

  EngagementClosureValidation() {
    this.isCloseButtonClicked = true;
    if (
      this.engagementList.completionDate == null &&
      this.engagementList.serviceLine == 'Advisory' &&
      this.isNonAttestServiceLineEngagement
    ) {
      this.isCompletionDateIndicated = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    else {
      this.isCompletionDateIndicated = true;
    }
    if (this.isCompletionDateIndicated) {
      if (this.engagementCloseoutConfiguration.length === 0) {
        // If configurations length is zero, directly invoke the engagement closure
        this.EngagementClosure(EngagementDialogTitles.CloseEng);
      }
      else {
        //Get Latest Engagement Closeout Configurations before start Engagement Closure Validation
        //      this.anyPendingEngagementCloseoutRequiredTask = false;
        this.checkPendingRequiredTaskOnCloseOutClick = true;
        this.alreadyInvoked = false;
        this.getEngagementCloseoutConfigurations();
      }
    }
  }

  updateExtensionDueDateRequest() {
    this.loadingScreenService.startLoading();
    this.approveDenyExtensionModal.isAutoDenied = true;
    this.approveDenyExtensionModal.extentionId = this.engagementDueDateExtensionPendingModel.extentionId;
    this.approveDenyExtensionModal.engagementId = this.engagementId;
    this.approveDenyExtensionModal.approveDenyByName = this.userObject.loggedInUserFullName;
    this.approveDenyExtensionModal.approveDenyByEmail = this.userObject.loggedInUserEmail;
    this.approveDenyExtensionModal.status = 'Deny';
    this.approveDenyExtensionModal.denyReason = 'Auto Denied for Closeout Process.'
    this.engService.UpdateExtensionDueDateRequest(this.approveDenyExtensionModal).subscribe(
      updateExtensionModalResult => {
        if (updateExtensionModalResult === 'Success') {
          this.getEngagementCloseoutConfigurations();
          this.getEngagementDueDateExtensions(this.engagementId);
          this.initializeApproveDenyExtensionModel();
          this.loadingScreenService.stopLoading();
        } else {
          this.loadingScreenService.stopLoading();

        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        const errorMsg = 'Error while UpdateExtensionDueDateRequest : ' + this.approveDenyExtensionModal;
        console.error(errorMsg, error);
      });
  }


  ExpandCloseOutChecklist() {
    if (this.engagementCloseoutRetention != undefined) {
      if (this.engagementCloseoutRetention.currentState == 'expand') {
        this.engagementCloseoutRetention.toggleDown();
      }
    }
  }

  collapseRequiredChecklistSection(excludeSection: string) {
    if (this.closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout?.length > 0) {
      this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.forEach(section => {
        switch (section.code) {
          case CloseoutSections.RM:
            if (this.engagementCloseoutRetention != undefined && CloseoutSections.RM != excludeSection) {
              this.engagementCloseoutRetention.toggleUpDown(false);
            }
            break;
          case CloseoutSections.FAI:
            if (this.engagementCloseoutArchivalComponent != undefined && CloseoutSections.FAI != excludeSection) {
              this.engagementCloseoutArchivalComponent.toggleUpDown(false);
            }
            break;
          case CloseoutSections.FCC:
            if (this.engagementCloseoutCleanUpChecklistComponent != undefined && CloseoutSections.FCC != excludeSection) {
              this.engagementCloseoutCleanUpChecklistComponent.toggleUpDown(false);
            }
            break;
          case CloseoutSections.QC:
            if (this.engagementCloseoutQualityChecklistComponent != undefined && CloseoutSections.QC != excludeSection) {
              this.engagementCloseoutQualityChecklistComponent.toggleUpDown(false);
            }
            break;
          case CloseoutSections.CS:
            if (this.engagementCloseoutClientVoiceSurvey != undefined && CloseoutSections.CS != excludeSection) {
              this.engagementCloseoutClientVoiceSurvey.toggleUpDown(false);
            }
            break;
          case CloseoutSections.ALC:
            if (this.EngagementCloseoutAuditChecklistComponent != undefined && CloseoutSections.ALC != excludeSection) {
              this.EngagementCloseoutAuditChecklistComponent.toggleUpDown(false);
            }
            break;
        }
      });
    }
  }


  CheckRealizationValueIsGreater(realization: number, solutionCode: string) {
    let result: boolean = false;
    let maxRealization: number = 0;
    if (solutionCode != null  && solutionCode != "" && solutionCode != undefined
      && this.engagementList?.status != null  &&  this.engagementList?.status != "" 
      &&  this.engagementList?.status != undefined && solutionCode === "ADV029" &&  
      (this.engagementList?.status?.toLocaleLowerCase() === "active" || 
      this.engagementList?.status?.toLocaleLowerCase() === "active (pending cms codes)" || 
      this.engagementList?.status?.toLocaleLowerCase() === "active (pending signature)" )){
        result = true;
      }else{
        for (let i = 0; i < this.engagementAssignmentList.length; i++) {
          if (this.engagementAssignmentList[i].isPrimaryAssignment) {
            maxRealization = this.engagementAssignmentList[i].realization;
          }
        }
        result = (maxRealization <= realization) ? true : false;
      }
      return result;
  }

  fillWorkspaceDocumentList(workspaceModel: WorkspaceModel) {
    let workspaceDocumentViewModel = new WorkspaceDocumentViewModel();
    workspaceDocumentViewModel.workspaceDocumentId = workspaceModel.workspaceDocumentId;
    workspaceDocumentViewModel.engagementId = workspaceModel.engagementId;
    workspaceDocumentViewModel.workspaceId = workspaceModel.workspaceId;
    workspaceDocumentViewModel.workItemId = workspaceModel.hdWorkItemId;
    workspaceDocumentViewModel.documentId = '';
    workspaceDocumentViewModel.documentName = '';
    workspaceDocumentViewModel.mimeType = '';
    workspaceDocumentViewModel.workItemVersionId = workspaceModel.hdWorkItemVersionId;
    workspaceDocumentViewModel.templatePackageVersionId = workspaceModel.hdTemplatePackageVersionId;
    workspaceDocumentViewModel.documentAliasName = workspaceModel.documentAliasName;
    workspaceDocumentViewModel.lastActivityBy = workspaceModel.lastActivityBy;
    workspaceDocumentViewModel.lastActivityDate = workspaceModel.lastActivityDate;
    workspaceDocumentViewModel.orginalHDWorkItemVersionId = workspaceModel.orginalHDWorkItemVersionId;
    workspaceDocumentViewModel.orginalHDWorkItemId = workspaceModel.orginalHDWorkItemId;
    workspaceDocumentViewModel.isPrimaryWorkspaceId = workspaceModel.isPrimaryWorkspaceId;
    workspaceDocumentViewModel.engagementState = workspaceModel.engagementState;
    workspaceDocumentViewModel.hdCurrentTempVersion = workspaceModel.hdCurrentTempVersion;
    workspaceDocumentViewModel.hdTemplatePackageId = workspaceModel.hdTemplatePackageId;
    const documentAliasNameChars = [...workspaceModel.documentAliasName];
    // let name = ""
    // documentAliasNameChars.forEach(element => {
    //   if (element == ' ') {
    //     name += "\xa0\ ";
    //     name = name.slice(0, -1);
    //   } else {
    //     name += element;
    //   }
    // });
    // workspaceDocumentViewModel.documentAliasName = name.trim(); // to avoid spaces multiple string issue
    if (workspaceModel.engagementState == 'Execution') { // Add Documet based on engagement state
      this.executionWorkspaceDocumentList.push(workspaceDocumentViewModel);
    }
    else
      this.workspaceDocumentList.push(workspaceDocumentViewModel);
  }

  WorkspaceDocumentChange() {
    const selectedWorkspaceDocumentText = $('#ddWorkspaceDocument option:selected').text().trim();
    if (selectedWorkspaceDocumentText == undefined || selectedWorkspaceDocumentText == '') {
      this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.isPrimaryWorkspaceId === true);
    }
    else {
      this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.documentAliasName === selectedWorkspaceDocumentText);
    }
    if (this.isrevisedresponsetabactive) {
      this.QuestionSummaryDisplay();
    }
    else {
      this.QuestionSummaryDisplayForOriginal();
    }

    this.ValidateHdTemplateCache(this.activeTabName);
  }
  exeWorkspaceDocumentChange() {
    const selectedWorkspaceDocumentText = $('#exeWorkspaceDocument option:selected').text().trim();
    if (selectedWorkspaceDocumentText != undefined && selectedWorkspaceDocumentText.length > 0) {
      this.selectedSecondaryWorkspaceDocument = this.executionWorkspaceDocumentList.find(docItem => docItem.documentAliasName === selectedWorkspaceDocumentText);
      this.exeQuestionSummaryDisplay();
      this.exePreviewDocument();
      this.ValidateHdTemplateCache(this.activeTabName);
    }
  }

  GetWorkspaceDocumentCountbyEngId() {
    var engagementState = 'Acceptance';
    this.workspaceService.GetWorkspaceDocumentCountbyEngId(this.engagementList.engagementId, engagementState).subscribe(
      resultValue => {
        this.workspaceDocumentCount = resultValue;
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  getSecondaryWorkspaceData(engagementId: number) {
    this.loadingScreenService.startLoading();
    this.workspaceService.getSecondaryWorkspaceDataByEngId(engagementId).subscribe(
      resultModel => {
        this.secondaryWorkspaceDataList = resultModel.secondaryWorkspaceDataList;
        this.executionWorkspaceDocumentList = [];
        if (this.secondaryWorkspaceDataList.length > 0) {
          this.secondaryWorkspaceDataList.forEach(workspace => {
            this.allWorkspaceDataList.push(JSON.parse(JSON.stringify(workspace)));
            this.fillWorkspaceDocumentList(workspace);
          });
        }
        if (this.activeTabName == 'executionDocuments') {
          if (this.executionWorkspaceDocumentList.length > 0) {
            this.selectedSecondaryWorkspaceDocument = this.executionWorkspaceDocumentList[0];
            this.selectedExeWorkspaceDocumentEorkspaceId = this.selectedSecondaryWorkspaceDocument.workspaceId;
          }
          this.exeQuestionSummaryDisplay();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
      });
  }

  getEngagementCloseoutConfigurations() {
    this.engagementService.GetEngagementCloseoutConfigurations(this.engagementId).subscribe(configurations => {
      this.setCloseoutVisibility(configurations);
    },
      err => {
        console.error(err);
      });
  }

  private setCloseoutVisibility(engagementCloseoutConfigurations: EngagementCloseoutConfiguration[]) {
    this.engagementCloseoutConfiguration = engagementCloseoutConfigurations;
    if (engagementCloseoutConfigurations && engagementCloseoutConfigurations.length > 0) {
      //Check ClientSurvey section exists
      if (this.checkIfSectionExists(engagementCloseoutConfigurations, CloseoutSections.CS)) {
        this.IsRequiredToSendClientVoiceSurvey();
      }
      else {
        // //Set sections which are required for engagement closeout
        this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout =
          engagementCloseoutConfigurations.filter(section =>
            section.code === CloseoutSections.RM
            || section.code === CloseoutSections.FAI
            || section.code === CloseoutSections.FCC
            || section.code === CloseoutSections.QC
            || section.code === CloseoutSections.ALC);
        if (this.engagementList.isSuccessorFirmEnabled) {
          this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout = engagementCloseoutConfigurations.filter(section =>
            section.code != CloseoutSections.CS);
        }


        this.checkPendingRequiredTask();
        this.setOptionalSectionsVisibility();
      }
    }
  }

  setClientVoiceVisibility(isClientVoiceSurveyRequired: boolean) {
    if (this.engagementCloseoutConfiguration && this.engagementCloseoutConfiguration.length > 0) {
      this.closeoutSectionStructureAndVisibility.requiredTasks = [];
      this.closeoutSectionStructureAndVisibility.optionalTasks = [];
      //Set ClientVoice required/optional
      if (isClientVoiceSurveyRequired) {
        this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout =
          this.engagementCloseoutConfiguration.filter(section =>
            section.code === CloseoutSections.RM
            || section.code === CloseoutSections.FAI
            || section.code === CloseoutSections.FCC
            || section.code === CloseoutSections.QC
            || section.code === CloseoutSections.CS);
      }
      else {
        this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout =
          this.engagementCloseoutConfiguration.filter(section =>
            section.code === CloseoutSections.RM
            || section.code === CloseoutSections.FAI
            || section.code === CloseoutSections.FCC
            || section.code === CloseoutSections.QC
          );

        if (!this.engagementList.isSuccessorFirmEnabled) {
          this.closeoutSectionStructureAndVisibility.optionalTasks = this.engagementCloseoutConfiguration.filter(section =>
            section.code === CloseoutSections.CS);
        }
      }
      let task = this.engagementCloseoutConfiguration.find(section =>
        section.code === CloseoutSections.ALC);
      if (task) { //closeout checklist
        if (this.engagementList.status != "Closed")//&&this.engagementList.LastModifiedBy=='')
        {
          this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.push(task);
        }
        else if (this.engagementList.status == "Closed") {
          if (task.isCompleted && this.engagementList.lastModifiedDate > task.modifiedOn)
            this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.push(task);
          else
            this.closeoutSectionStructureAndVisibility.requiredTasks.push(task);
        }
      }
      if (this.engagementList.isSuccessorFirmEnabled) {
        this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout = this.engagementCloseoutConfiguration.filter(section =>
          section.code != CloseoutSections.CS);
        let task = this.closeoutSectionStructureAndVisibility.requiredTasks.find(section =>
          section.code === CloseoutSections.ALC); //check if audit closeout added in requiredTasks if yes then remove from required task 
        if (task) {
          this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout = this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.filter(section =>
            section.code !== CloseoutSections.ALC);
        }
      }

      // //Check any anyEngagementCloseoutRequiredTaskExists
      if (this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.length > 0) {
        this.anyEngagementCloseoutRequiredTaskExists = true;
        this.checkPendingRequiredTask();
      } else {
        this.checkPendingRequiredTask();
      }
    }
  }

  checkPendingRequiredTask() {
    if (this.checkPendingRequiredTaskOnCloseOutClick) {
      this.refreshWarningsVisibilityForRequiredSection();
      if (this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.
        filter(section => !section.isCompleted && section.code !== 'FAI')?.length > 0) {
        this.anyPendingEngagementCloseoutRequiredTask = true;
      } else {
        this.anyPendingEngagementCloseoutRequiredTask = false;
        let faiPending = this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout
          .filter(section => section.code == 'FAI' && !section.isCompleted)?.length;
        if (this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.
          filter(section => !section.isCompleted)?.length > 0) {
          this.invokeEngagementClosure(faiPending);
        } else {
          this.invokeEngagementClosure(faiPending);
        }
      }
    }
    else {
      this.anyPendingEngagementCloseoutRequiredTask = false;
    }
  }

  invokeEngagementClosure(faiPending: number) {
    const faiPendingcount = faiPending;
    const completedSections = this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout
      .filter(section => section.isCompleted)?.length;
    const totalTasks = this.closeoutSectionStructureAndVisibility.requiredForEngagementCloseout.length;
    if ((faiPendingcount == 1 && completedSections == totalTasks - 1)) {
      if (!this.alreadyInvoked) {
        this.EngagementClosure(EngagementDialogTitles.CloseEng);
        this.alreadyInvoked = true;
      }
    } else if ((totalTasks == completedSections && faiPendingcount != 1)) {
      if (!this.alreadyInvoked) {
        this.EngagementClosure(EngagementDialogTitles.CloseEng);
        this.alreadyInvoked = true;
      }
    }
  }

  setOptionalSectionsVisibility() {
    if (this.engagementCloseoutConfiguration && this.engagementCloseoutConfiguration.length > 0
      && this.engagementCloseoutConfiguration.filter(section =>
        section.code === CloseoutSections.IM)?.length > 0) {
      this.closeoutSectionStructureAndVisibility.optionalTasks.push(this.engagementCloseoutConfiguration.filter(section => section.code === CloseoutSections.IM)[0]);
    }
  }

  IsRequiredToSendClientVoiceSurvey() {

    this.engagementService.GetEngagementClientVoiceDetailByEngId(this.engagementId).subscribe(
      engagementClientVoiceDetailViewModel => {

        this.loadingScreenService.startLoading();
        this.previousClientVoiceSurveyRequestModel.clientCode = this.engagementList.clientCode;
        this.previousClientVoiceSurveyRequestModel.clientName = this.engagementList.clientName;
        this.previousClientVoiceSurveyRequestModel.engagementClientVoiceServiceLineDetails = engagementClientVoiceDetailViewModel.engagementClientVoiceServiceLineDetails;
        this.previousClientVoiceSurveyRequestModel.engagementClientVoice = engagementClientVoiceDetailViewModel.engagementClientVoice;
        console.log('previousClientVoiceSurveyRequestModel==>', this.previousClientVoiceSurveyRequestModel);
        this.clientvoiceService.IsRequiredToSendClientVoiceSurvey(this.previousClientVoiceSurveyRequestModel).subscribe(result => {

          this.loadingScreenService.stopLoading();
          if (result && result.statusCode == '200') {
            if (result.response.previousSurveySentDate) {
              this.lastSurveySentDateForClient = new Date(this.datePipe.transform(result.response.previousSurveySentDate, 'MM/dd/yyyy', 'utc', null));
            }
            this.isRequiredToSendClientVoiceSurvey = result.response.isRequiredToSendClientVoiceSurvey;
          }
          else {
            this.logService.error(result.status);
          }
          this.setClientVoiceVisibility(this.isRequiredToSendClientVoiceSurvey);
          this.setOptionalSectionsVisibility();
        },
          err => {
            this.logService.error(err);
            this.loadingScreenService.stopLoading();
          });

      });
  }

  checkIfSectionExists(sections: EngagementCloseoutConfiguration[], sectionCode: string): boolean {
    if (sections && sections.length > 0) {
      return sections.filter(section => section.code == sectionCode)?.length > 0;
    }
    return false;
  }

  getSection(sectionToSearch: string): EngagementCloseoutConfiguration {
    if (this.engagementCloseoutConfiguration?.length > 0) {
      return this.engagementCloseoutConfiguration.filter(section => section.code == sectionToSearch)?.[0];
    }
  }
  displayDialogForEngCompletionDate() {
    let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
    const dialogType = 'information';
    let dialogTitle: string = 'Completion date';
    let dialogDesc: string = 'Enter an estimated or actual completion date. The engagement team should select "actual" when the deliverables have been provided and/or services are complete.';
    let bigTitle: boolean = false;
    let dialogInput = '';
    let buttonCount: number = 2;
    this.displayDialogForEngCompletionDateConfirmationPopUp(dialogType, dialogTitle, dialogDesc, buttonCount, dialogInput, bigTitle, loggedInUserEmail);
  }

  displayDialogForEngCompletionDateConfirmationPopUp(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number,
    _dialogInput: string, bigTitle: boolean, loggedInUserEmail: string) {
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '',
      dialogButton: _buttonCount,
      modalDisplay: true,
      engagementId: this.engagementId,
      plannedClosureDate: this.datepipe.transform(this.engagementList.plannedClosureDate, 'MM/dd/yyyy'),
      dialogBigTitle: bigTitle,
      loggedInUserEmail: loggedInUserEmail
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayEngDialogNotification(modalJSON);
  }

  DocumentsValidation() {
    var documentsMatching = false;
    if (this.engagementESignature.sharePointDocuments.length != this.initialEngagementESignature.sharePointDocuments.length) {
      documentsMatching = false;
      return documentsMatching;
    }
    this.engagementESignature.sharePointDocuments.forEach(doc => {
      var matchdocindex = this.initialEngagementESignature.sharePointDocuments.findIndex(doc1 => (doc1.sharePointDocumentId == doc.sharePointDocumentId && doc1.sharePointDocumentName == doc.sharePointDocumentName));
      if (matchdocindex == -1) {
        documentsMatching = false;
        return documentsMatching;
      }
      else {
        documentsMatching = true;
      }
    });
    return documentsMatching;
  }
  selectedDocumentChecking() {
    var documentIndex = 0;
    this.spDocumentList.forEach(doc => {
      documentIndex = this.engagementESignature.sharePointDocuments.findIndex(doc1 => doc1.sharePointDocumentId == doc.documentId);
      if (documentIndex != -1) {
        doc.isChecked = true;
      }
      else {
        doc.isChecked = false;
      }
    });

  }
  //validation for Signer Field

  copyPasteTextValidationForSignerField(): boolean {
    if (this.engagementESignature.handlerName) {
      var regex = new RegExp("^[a-zA-Z ]+$");
      if (regex.test(this.engagementESignature.handlerName)) {
        return true;
      }
      else {

        this.engagementESignature.handlerName = "";
        return false;
      }
    }
    else {

    }
    return false;

  }

  validationForSignerField(e) {
    // Accept only alpha numerics, not special characters
    var regex = new RegExp("^[a-zA-Z ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    else {
      e.preventDefault();
      return false;
    }
  }

  UpdateValidationArrayForNewClientTeamMember() {
    for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
      this.arrValidationForNewClientTeamMember[t].firstName = $('#txtFirstName_' + t).val();
      this.arrValidationForNewClientTeamMember[t].lastName = $('#txtLastName_' + t).val();
      this.arrValidationForNewClientTeamMember[t].emailId = $('#txtClientEmail_' + t).val();
      this.arrValidationForNewClientTeamMember[t].clientEmailId = $('#txtconfirmClientEmail_' + t).val();
      this.arrValidationForNewClientTeamMember[t].title = $('#txtClientTitle_' + t).val();
      this.arrValidationForNewClientTeamMember[t].permissionId = $('#drpClientTeamMemberPermissions_' + t).val();
      this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = false;
      this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
    }
  }

  ValidateClientEmailField(t, siteId) {
    this.UpdateValidationArrayForNewClientTeamMember();

    if (this.arrValidationForNewClientTeamMember[t].emailId === '') {
      $('#errorLabelEmail' + t).attr('style', 'display:block');
      $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
    } else {
      $('#errorLabelEmail' + t).attr('style', 'display:none');
    }
    if (!this.checkValidEmail(this.arrValidationForNewClientTeamMember[t].emailId)) {
      this.IsValidInputClient = false;
      this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = true;
    } else {
      this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = false;
    }

    this.ValidateClientEmailAlreadyExist(siteId);

    //Confirm email check
    if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
      if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
        this.IsValidInputClient = false;
        $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
      } else {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
        if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
          this.IsValidInputClient = false;
        }
        else {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        }
      }
    }
  }

  ValidateClientEmailAlreadyExist(siteId) {
    // check if new client member email is matching with existing  client team member email.
    if (this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers != undefined) {

      for (let j = 0; j < this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers.length; j++) {
        for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
          if ($('#txtClientEmail_' + t).val() ===
            this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers[j].emailId
            && this.currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers[j].collaborationSiteId == siteId) {
            this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = true;
            this.cmsDataMatchClient = true;
            $('#userExist' + t).attr('style', 'display:block');
            break;
          }
          if (this.cmsDataMatchClient === false || this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient === false) {
            $('#userExist' + t).attr('style', 'display:none');
          }
        }  //newClientTeamMemberList
      }//clientTeamMemberList
    }

    // check duplicate emailid Recrods
    for (let t = 0; t < this.arrValidationForNewClientTeamMember.length; t++) {
      if (this.CheckDuplicateElement(this.arrValidationForNewClientTeamMember, 'emailId',
        this.arrValidationForNewClientTeamMember[t].emailId)) {
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = true;
        this.IsValidInputClient = false;
      } else {
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
      }
    }
  }

  ValidateConfirmClientEmailField(t, siteId) {
    this.UpdateValidationArrayForNewClientTeamMember();
    this.ValidateClientEmailAlreadyExist(siteId);
    if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
      if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
        this.IsValidInputClient = false;
        $('#userExistClient' + t).attr('style', 'display:none');
        $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
      } else {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
        if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
          $('#userExistClient' + t).attr('style', 'display:none');

          this.IsValidInputClient = false;
        }
        else {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          $('#userExistClient' + t).attr('style', 'display:none');

        }
      }
    }
    else {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:block');
      $('#userExistClient' + t).attr('style', 'display:none');
      $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
      this.IsValidInputClient = false;
    }
  }

  CallPowerAutomateToProcessClientMemberNotification(siteId: number) {
    let powerAutomateRequestModel = new PowerAutomateRequestModel();
    powerAutomateRequestModel.engageEnviornment = "";
    powerAutomateRequestModel.collaborationSiteId = siteId;

    this.engagementService.GeneratePowerAutomateTriggerOnClientUserCreated(powerAutomateRequestModel)
      .subscribe(
        resultString => {
          console.log('GeneratePowerAutomateTriggerOnClientUserSaved=>', resultString);
        }
      );
  }

  UpdateEngagementCollaborationsData() {
    let isAdminAccestoGetEngData: string = 'no';
    if (this.isloggedInUserAdmin ||
      (!this.isloggedInUserAdmin && this.isEngDetailINDUSStaffingManagersHaveAccess)) {
      isAdminAccestoGetEngData = 'yes';
    }

    this.engagementService.getEngagementCollaborationsById(this.engagementId, isAdminAccestoGetEngData)
      .subscribe(
        result => {
          this.engagementCollaborationsViewModel = result;
          this.engagementList = this.engagementCollaborationsViewModel.engagement;
          this.engagementAssignmentList = this.engagementCollaborationsViewModel.engagementAssignmentList;
          this.teamMemberList = this.engagementCollaborationsViewModel.teamMemberList;
          this.collaborationSiteList = this.engagementCollaborationsViewModel.collaborationSiteList;
          this.clientTeamMemberList = this.engagementCollaborationsViewModel.clientTeamMemberList;
          this.collaborationSiteMembersList = this.engagementCollaborationsViewModel.collaborationSiteMembersList;
          this.collaborationClientSiteList = this.engagementCollaborationsViewModel.collaborationClientSiteList;
          this.CreateUIForCollaborationSpace();
        });
  }


  //Opportunity Work
  openAssignmentModelWithOneViewOpportunity() {
    if (this.isOpportunityServiceFeatureFlagEnabled) {
      // if (this.engagementList.opportunityId != '' && this.engagementList.opportunityId != undefined) {
      //this.engagementList.parentSolutionCodeId = 2993;
      this.BuildNFetchClientOpportunityByEngagementId();
      // }
      //  else {
      //   this.SetOpportunityModelValuesAndOpenPopUp()
      // }
    }
    else {
      this.defaultEngAssignment.solutionCodeId = null;
      this.PopulateDefaultAssignmentModel();
    }
  }

  BuildNFetchClientOpportunityByEngagementId() {
    this.selectedclientName = this.engagementList.clientName
    this.selectedclientCode = this.engagementList.clientCode;
    // if (this.selectedclientCode != null && this.selectedclientCode !== undefined && this.selectedclientCode != '') {
    //   this.selectedclientName = this.selectedclientName + ' (' + this.selectedclientCode + ')';
    // }
    this.clientOneViewAccountId = this.engagementList.oneViewAccountId;

    if (this.primarySelectedSolutionCode != undefined) {
      this.selectedSolutionCode = this.primarySelectedSolutionCode.solutionCode;
      this.selectedsolutionCodeWithDesc = this.primarySelectedSolutionCode.solutionCode + ' - ' + this.primarySelectedSolutionCode.solutionCodeDescription;
    }

    let searchOpportunityByEngagementIdRequestModel: SearchOpportunityByEngagementIdRequestModel = new SearchOpportunityByEngagementIdRequestModel();
    searchOpportunityByEngagementIdRequestModel.engagementId = this.engagementList.engagementId;
    searchOpportunityByEngagementIdRequestModel.primarySolutionCode = this.primarySelectedSolutionCode.solutionCode;
    let opportunityServiceMiniModelList: OpportunityServiceMiniModel[] = [];
    this.arrEngagementAssignment.filter(model => model.opportunityServiceId != '').forEach(item => {
      let opportunityServiceMiniModel: OpportunityServiceMiniModel = new OpportunityServiceMiniModel();
      opportunityServiceMiniModel.OpportunityServiceId = item.opportunityServiceId;
      opportunityServiceMiniModel.isPrimary = item.isPrimaryAssignment;
      opportunityServiceMiniModel.isPartOfSecondary = true;
      opportunityServiceMiniModelList.push(opportunityServiceMiniModel);
    });
    searchOpportunityByEngagementIdRequestModel.opportunityServiceMiniModelList = opportunityServiceMiniModelList;

    let secSolutionCodeList: string[] = [];
    if (this.secondarySolutionCodeList != undefined) {
      this.secondarySolutionCodeList.filter(code => code.seq != 0).forEach(item => {
        secSolutionCodeList.push(item.solutionCode);
      });
    }

    searchOpportunityByEngagementIdRequestModel.secSolutionCodeList = secSolutionCodeList;
    // $("#modal-Opportunity").modal('show');
    this.GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel);
  }

  SetOpportunityModelValuesAndOpenPopUp() {
    this.validateOpportunityService = false;
    this.selectedclientName = this.engagementList.clientName;
    this.selectedclientCode = this.engagementList.clientCode;
    if (this.selectedclientCode != null && this.selectedclientCode !== undefined && this.selectedclientCode != '') {
      this.selectedclientName = this.selectedclientName + ' (' + this.selectedclientCode + ')';
    }
    this.clientOneViewAccountId = this.engagementList.oneViewAccountId;
    if (this.primarySelectedSolutionCode != undefined) {
      this.selectedSolutionCode = this.primarySelectedSolutionCode.solutionCode;
      this.selectedsolutionCodeWithDesc = this.primarySelectedSolutionCode.solutionCode + ' - ' + this.primarySelectedSolutionCode.solutionCodeDescription;
    }

    if ((this.selectedclientCode != '' || this.clientOneViewAccountId != '')
      && this.selectedSolutionCode != '') {
      let secSolutionCodeList: string[] = [];
      if (this.secondarySolutionCodeList != undefined) {
        this.secondarySolutionCodeList.filter(code => code.seq != 0).forEach(item => {
          secSolutionCodeList.push(item.solutionCode);
        });
      }

      this.engagementService.GetInterchangeableSolutionCodes(this.engagementList.parentSolutionCodeId).subscribe(result => {
        this.interchangeableSolutionCodes = result;
        let interchangeableSolutionCodeList: string[] = [];
        if (this.interchangeableSolutionCodes != undefined) {
          this.interchangeableSolutionCodes.forEach(item => {
            interchangeableSolutionCodeList.push(item.solutionCode);
          });
        }
        console.log('Interchangable solutioncode ids:' + interchangeableSolutionCodeList);

        let searchOpportunityByRequestModel: SearchOpportunityByRequestModel =
          new SearchOpportunityByRequestModel(String(this.primarySelectedSolutionCode.solutionCode),
            this.selectedclientCode,
            secSolutionCodeList,
            this.clientOneViewAccountId,
            this.primarySelectedSolutionCode.isAllAdvisory,
            this.primarySelectedSolutionCode.isAllAudit,
            this.primarySelectedSolutionCode.isAllTax,
            this.primarySelectedSolutionCode.solutionCodeType, interchangeableSolutionCodeList);
        $("#modal-Opportunity").modal('show');
        this.GetClientOpportunity(searchOpportunityByRequestModel);
      });
    }
    else //If Client code and One view Account id BLANK then open default Assignemnt section
    {
      this.showDefaultAssignmentBox();
    }
  }


  GetClientOpportunity(searchOpportunityByRequestModel: SearchOpportunityByRequestModel) {
    this.loadingScreenService.startLoading();
    this.clientOpportunityList = [];
    this.noOpportunityMsg = '';
    this.clientService.GetClientOpportunity(searchOpportunityByRequestModel).subscribe(result => {
      this.oneViewClientOpportunityViewModelWrapper = result.response;
      this.clientOpportunityList = this.oneViewClientOpportunityViewModelWrapper.oneViewClientOpportunityViewModelList.filter(opp => opp.opportunityId != null);
      if (this.clientOpportunityList.length > 0 &&
        !this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
        this.noOpportunityMsg = 'No opportunity services match the primary solution code, only related service offerings. Select any that are to be included along with the primary solution code assignment.';
      } else if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary) {
        this.noOpportunityMsg = 'There are no opportunities related to this solution code in oneView. An opportunity will automatically be created in oneView once the engagement is created.';
      }
      else {
        this.noOpportunityMsg = '';
      }
      let opportunityServiceRequestModel: OpportunityServiceRequestModelByEngagementId = new OpportunityServiceRequestModelByEngagementId();
      opportunityServiceRequestModel.clientCode = searchOpportunityByRequestModel.clientCode;
      opportunityServiceRequestModel.engagementId = this.engagementList.engagementId;
      //Fetch all opportunityServiceIds
      this.clientOpportunityList.forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(s => {
          if (s.opportunityServiceId != null) {
            // Create a new opportunityServiceList object for each iteration
            let opportunityServiceList = {
              opportunityServiceId: s.opportunityServiceId,
              osaCount: s.osaCount != null ? s.osaCount : undefined
            };

            // Push the new opportunityServiceList object to opportunityServiceRequestModel
            opportunityServiceRequestModel.opportunityServiceList.push(opportunityServiceList);
          }
        });
      });
      if (opportunityServiceRequestModel.opportunityServiceList.length > 0) {
        this.engagementService.CheckClientOpportunityServiceAssignedByEngagementID(opportunityServiceRequestModel).subscribe(result1 => {
          let updatedServicelist = result1.opportunityServiceList;
          console.log('updatedServicelist after CheckClientOpportunityServiceAssignedByEngagementID ==>', updatedServicelist);
          //Assigned updated value for isAssigned
          if (updatedServicelist != null) {
            this.clientOpportunityList.forEach(oopElement => {
              oopElement.opportunityServiceList.forEach(service => {
                updatedServicelist.forEach(item => {
                  if (item.opportunityServiceId == service.opportunityServiceId) {
                    service.isAssigned = item.isAssigned;
                  }
                })
              })
            });
          }
          console.log('After update clientOpportunityList ==>', this.clientOpportunityList);
        }, (error) => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
      }

      console.log('Pop up clientOpportunityList ==>', this.clientOpportunityList);
      this.loadingScreenService.stopLoading();
    },
      (error) => {
        this.loadingScreenService.stopLoading();
        console.log('Error while getting response GetClientOpportunity func ', error);
      });
  }

  showDefaultAssignmentBox() {
    if (this.engagementList.opportunityId != '' && this.engagementList.opportunityId != undefined) {
      this.BuildNFetchClientOpportunityByEngagementId();
    } else {
      this.PopulateDefaultAssignmentModel();
    }
  }

  PopulateDefaultAssignmentModel() {
    this.isVisibledefaultAssignmentDiv = true;
    this.isVisibleNewAssignmentDIV = false;
    this.defaultEngAssignment.solutionCodeId = null;
    setTimeout(() => {
      this.controlInitialization();
      $('#txtAssignmentName').focus();
      $('.error-color').hide();
    }, 100);
  }

  // showDefaultAssignmentBox() {
  //   this.isVisibledefaultAssignmentDiv = true;
  //   this.isVisibleNewAssignmentDIV = false;
  //   this.defaultEngAssignment.solutionCodeId = null;
  //   setTimeout(() => {
  //     this.controlInitialization();
  //     $('#txtAssignmentName').focus();
  //     $('.error-color').hide();
  //   }, 100);
  // }

  GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel: SearchOpportunityByEngagementIdRequestModel) {
    this.loadingScreenService.startLoading();
    this.clientOpportunityList = [];
    this.noOpportunityMsg = '';
    this.clientService.GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel).subscribe(result => {
      this.oneViewClientOpportunityViewModelWrapper = result.response;
      if (this.oneViewClientOpportunityViewModelWrapper != null) {
        this.clientOpportunityList = this.oneViewClientOpportunityViewModelWrapper.oneViewClientOpportunityViewModelList.filter(opp => opp.opportunityId != null);
        if (this.clientOpportunityList.length == 0) {
          this.PopulateDefaultAssignmentModel();
        }
        else {
          $("#modal-Opportunity").modal('show');
          if (this.clientOpportunityList.length > 0 &&
            !this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            this.noOpportunityMsg = 'No opportunity services match the primary solution code, only related service offerings. Select any that are to be included along with the primary solution code assignment.';
          } else if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary) {
            this.noOpportunityMsg = 'There are no opportunities related to this solution code in oneView. An opportunity will automatically be created in oneView once the engagement is created.';
          }
          else {
            this.noOpportunityMsg = '';
          }
          let opportunityServiceRequestModel: OpportunityServiceRequestModelByEngagementId = new OpportunityServiceRequestModelByEngagementId();
          opportunityServiceRequestModel.clientCode = this.selectedclientCode;
          opportunityServiceRequestModel.engagementId = this.engagementList.engagementId;
          let opportunityServiceList: OpportunityServiceList = new OpportunityServiceList();
          //Fetch all opportunityServiceIds      
          this.clientOpportunityList.forEach(oopElement => {
            oopElement.opportunityServiceList.forEach(s => {
              if (s.opportunityServiceId != null) {
                // Create a new opportunityServiceList object for each iteration
                let opportunityServiceList = {
                  opportunityServiceId: s.opportunityServiceId,
                  osaCount: s.osaCount != null ? s.osaCount : undefined
                };

                // Push the new opportunityServiceList object to opportunityServiceRequestModel
                opportunityServiceRequestModel.opportunityServiceList.push(opportunityServiceList);
              }
            });
          });

          if (opportunityServiceRequestModel.opportunityServiceList.length > 0) {
            this.engagementService.CheckClientOpportunityServiceAssignedByEngagementID(opportunityServiceRequestModel).subscribe(result1 => {
              let updatedServicelist = result1.opportunityServiceList;
              console.log('updatedServicelist after CheckClientOpportunityServiceAssigned ==>', updatedServicelist);
              //Assigned updated value for isAssigned
              if (updatedServicelist != null) {
                this.clientOpportunityList.forEach(oopElement => {
                  oopElement.opportunityServiceList.forEach(service => {
                    updatedServicelist.forEach(item => {
                      if (item.opportunityServiceId == service.opportunityServiceId) {
                        service.isAssigned = item.isAssigned;
                        service.isContinuanceCarryFrwdAssignment = item.isContinuanceCarryFrwdAssignment;
                      }
                    })
                  })
                });
              }
              console.log('After update clientOpportunityList ==>', this.clientOpportunityList);
            }, (error) => {
              this.loadingScreenService.stopLoading();
              this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
            });
          }
        }
        console.log('Pop up clientOpportunityList ==>', this.clientOpportunityList);
        this.loadingScreenService.stopLoading();
      }
      else {
        console.log('Error while fatching opportunityService ==>', result.status);
        this.PopulateDefaultAssignmentModel();
        this.loadingScreenService.stopLoading();
      }
    },
      (error) => {
        this.PopulateDefaultAssignmentModel();
        this.loadingScreenService.stopLoading();
        console.log('Error while getting response GetClientOpportunity func ', error);
      });
  }

  CancelOpportunityPopUp() {
    this.originalParentSolutionCodeId = 0;
    if ((this.engagementList.opportunityId == '' || this.engagementList.opportunityId == undefined) &&
      this.arrEngagementAssignment.length == 0) {
      this.engagementList.opportunityId = undefined;
      //this.engagement.opportunityOptOut = true;
      this.clientOpportunityList = [];
      this.arrEngagementAssignment = [];
      this.validateOpportunityService = false;
      this.noOpportunityMsg = '';
      //  $('#hdnKey' + this.SolutionCodeMappedValue + 0).val('');
      //  $('#txtValue' + this.SolutionCodeMappedValue + 0).val('');
      this.restartAssignment();
      this.isVisibledefaultAssignmentDiv = false;
    } else {
      $("#modal-Opportunity").modal('hide');
    }
  }

  restartAssignment() {
    // clear Assignment
    // this.engagement.opportunityId = undefined;
    // this.arrEngagementAssignment = [];
    // this.isAssignmentNameExistForClient = false;
    // this.duplicateAssignmentName = false;
    // this.RefreshAssignmentModel();
  }
  CheckOpportunityValidation() {
    if (this.engagementList.opportunityId == '' || this.engagementList.opportunityId == undefined) {
      if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted) {
        this.validateOpportunityService = true;
        let selecteOpportunity = this.clientOpportunityList.find(o => o.opportunityServiceList.find(s => s.isChecked))
        console.log('selecteOpportunity ', selecteOpportunity);

        if (selecteOpportunity == undefined) {
          this.oneViewClientOpportunityViewModelWrapper.isOpportunitySelected = false;
          //this.engagement.opportunityOptOut = (selecteOpportunity.opportunityId != undefined)? false: true;
        } else {
          this.validateOpportunityService = false;
          this.engagementList.opportunityId = selecteOpportunity.opportunityId;
          // this.engagement.opportunityOptOut = (selecteOpportunity.opportunityId != undefined)? false: true;
          return true;
        }
      } else {
        this.validateOpportunityService = false;
        return true;
      }
    } else {
      return true;
    }
  }

  OkOpportunityPopUp() {
    if (this.CheckOpportunityValidation()) {
      $("#modal-Opportunity").modal('hide');
      if (this.engagementList.opportunityId != '' && this.engagementList.opportunityId != undefined) {
        this.BuildAssignmentWithSelecetdOpportunityService();
      }
      else {
        this.defaultEngAssignment.solutionCodeId = null;
        this.PopulateDefaultAssignmentModel();
      }
    }
  }


  isInterimAssignmenrExists(opportunityServiceId: string) {
    if (this.arrEngagementAssignment.
      find(assignment => assignment.assignmentCodeId == 0
        && assignment.opportunityServiceId == opportunityServiceId) == undefined) {
      return false;
    } else {
      return true;
    }
  }

  BuildAssignmentWithSelecetdOpportunityService() {
    if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted) {
      this.isVisibledefaultAssignmentDiv = false;
      let filiterSelectedOpportunityServices: OpportunityServiceModel[] = [];
      this.clientOpportunityList.forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(service => {
          if (service.isChecked) {
            filiterSelectedOpportunityServices.push(service);
          }
        })
      });

      console.log('filiterSelectedOpportunityServices ==>', filiterSelectedOpportunityServices);
      let filiterSelectedOpportunityServicesLength: number = filiterSelectedOpportunityServices.length;

      if (filiterSelectedOpportunityServicesLength > 0) {

        for (let index = 0; index < filiterSelectedOpportunityServicesLength; index++) {
          let selectedOpportunity = this.clientOpportunityList.find(o => o.opportunityServiceList.find(s => s.opportunityServiceId == filiterSelectedOpportunityServices[index].opportunityServiceId));
          this.LoadAssignmentWithOpportunityService(filiterSelectedOpportunityServices[index], selectedOpportunity);
        }

      }
    } else {
      this.objectInitialization();
      this.PopulateDefaultAssignmentModel();
    }
  }



  LoadAssignmentWithOpportunityService(oopService: OpportunityServiceModel, selectedOpportunity: OneViewClientOpportunityViewModel) {
    this.openDefaultAssignmentBox();
    this.defaultEngAssignment = null;
    const defaultSelectedengagementType = new KeyValue();
    defaultSelectedengagementType.key = '';
    defaultSelectedengagementType.value = '';
    let selectedsolutionCode = this.secondarySolutionCodeList.find(s => s.solutionCode == oopService.solutionCode);
    const clientLocation = this.ClientLocations.find(element => element.typeValue === selectedOpportunity.gtOfficeLocation);

    this.defaultEngAssignment = {
      solutionCodeListData: this.secondarySolutionCodeList.filter(solutionCode => solutionCode.status == 'A'),
      solutionCode: oopService.solutionCode,
      selectedsolutionCode: selectedsolutionCode,
      solutionCodeId: selectedsolutionCode.childSolutionCodeId,
      solutionCodeDescription: selectedsolutionCode.solutionCodeDescription,
      iscontingentData: this.contingentkeyValueList,
      engagementTypeData: this.engtypekeyValueList,
      selectedengagementType: defaultSelectedengagementType,
      assignmentCodeId: 0,
      engagementId: 0,
      assignmentName: '',
      realization: null,
      netFees: oopService.PriceAmt,
      createdBy: '',
      createdDate: new Date(),
      lastModifiedBy: '',
      lastModifiedDate: new Date(),
      assignmentAdministrator: '',
      adminEmailId: '',
      adminContactNo: '',
      cmsEPPMDName: '',
      cmsEPPMDEmailId: '',
      engagementTypeId: '0',
      isContingent: null,
      isPrimaryAssignment: false,
      netFeesInCurrencyFormat: oopService.PriceAmtFormattedValue,
      collaborationSiteId: 0,
      engagementStartDate: (selectedOpportunity.expectedStartDate != undefined || selectedOpportunity.expectedStartDate != '') ? selectedOpportunity.expectedStartDate : null,
      engagementEndDate: (selectedOpportunity.estCloseDate != undefined || selectedOpportunity.estCloseDate != '') ? selectedOpportunity.estCloseDate : null,
      officeLocationCodeId: (clientLocation != undefined) ? clientLocation.id : 0,
      officeLocationCodeName: (clientLocation != undefined) ? clientLocation.typeValue : '',
      estimatedGrossMargin: null,
      clientBillingContactName: '',
      clientBillingContactTitleId: 0,
      clientBillingContactEmail: '',
      collaborationSiteName: '',
      estimatedGrossMarginInCurrencyFormat: '',
      clientBillingContactTitle: '',
      assignmentCode: null,
      clientCode: (this.engagementList.clientCode != null && this.engagementList.clientCode != undefined && this.engagementList.clientCode.length > 0) ? this.engagementList.clientCode : null,
      assignmentCodeStatus: '',
      isContinuanceCarryFrwdAssignment: false,
      solutionCodeOnSigature: false,
      priorYearAssignmentName: '',
      isGrossMarginRequire: false,
      eqcrName: '',
      eqcrEmailId: '',
      isEditEQCR: false,
      newEQCRName: '',
      newEQCREmailId: '',
      isvalidEQCR: true,
      isValidEQCRBusinessRule: true,
      isEQCRExists: false,
      isAssignmentFinanceDataEditable: 'null',
      isEngagementStartDateRequired: false,
      isEngagementEndDateRequired: false,
      opportunityServiceId: oopService.opportunityServiceId,
      status: null
    };

    if (this.engagementList.clientId > 0) {
      if (this.defaultClientBillingContact !== undefined) {
        if ((this.defaultClientBillingContact.clientBillingEmail !== undefined ||
          this.defaultClientBillingContact.clientBillingEmail !== null) ||
          (this.defaultClientBillingContact.clientBillingName !== undefined ||
            this.defaultClientBillingContact.clientBillingName !== null)) {
          this.defaultEngAssignment.clientBillingContactEmail = this.defaultClientBillingContact.clientBillingEmail;
          this.defaultEngAssignment.clientBillingContactName = this.defaultClientBillingContact.clientBillingName;
        }
        if (this.defaultClientBillingContact.clientBillingTitleId > 0) {
          this.defaultEngAssignment.clientBillingContactTitleId = this.defaultClientBillingContact.clientBillingTitleId;
        }
      }
    }

    setTimeout(() => {
      $('.EstimatedStartDate').val(this.defaultEngAssignment.engagementStartDate);
      $('.EstimatedEndDate').val(this.defaultEngAssignment.engagementEndDate);
    }, 100);

    console.log('Selected defaultEngAssignment ==>', this.defaultEngAssignment);
  }

  OpportunityServiceClick(opportunityId: string, opportunityServiceId: string, isChecked: boolean, row: number, col: number) {
    //check/uncheck selected service
    this.clientOpportunityList.find(o => o.opportunityId == opportunityId).
      opportunityServiceList.find(s => s.opportunityServiceId == opportunityServiceId).isChecked = true;

    //unselect isOpportunityServicelisted check box if anyone opportunityService selected
    if (this.clientOpportunityList.find(o => o.opportunityId == opportunityId).
      opportunityServiceList.find(s => s.opportunityServiceId == opportunityServiceId).isChecked) {
      this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted = false;
    }
    if (this.engagementList.opportunityId == '' || this.engagementList.opportunityId == undefined) {
      this.clientOpportunityList.filter(o => o.opportunityId == opportunityId).forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(os => {
          oopElement.opportunityServiceList.find(x => x.isPrimary).isChecked = true;
          if (!os.isPrimary && this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            if (this.secondarySolutionCodeList.find(item => item.solutionCode == os.solutionCode) != undefined) {
              if (os.isChecked) {
                os.isChecked = oopElement.opportunityServiceList.find(x => x.isPrimary).isChecked;
              }
              ///This is to enable/disable check box for selected service
              os.isEnabled = oopElement.opportunityServiceList.find(x => x.isPrimary).isChecked;
            }
          }
        }
        )
      });

      //uncheck unselected service
      let exceptSelectedclientOpportunityList = this.clientOpportunityList.filter(e => e.opportunityId != opportunityId);
      exceptSelectedclientOpportunityList.forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(e => {
          e.isChecked = false;
          if (!e.isPrimary && this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            e.isEnabled = false;
          }
        }
        )
      });
    }
    console.log('clientOpportunityList after check click==>', this.clientOpportunityList);
  }

  OpportunityServiceNotListed(isOpportunityServicelisted: boolean) {
    this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted = !isOpportunityServicelisted;
    //unselect if any opportunityService selected
    this.clientOpportunityList.forEach(oopElement => {
      oopElement.opportunityServiceList.forEach(e => {
        if (e.isChecked) {
          e.isChecked = false;
        }
        if (this.engagementList.opportunityId == undefined) {
          if (!e.isPrimary && this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            e.isEnabled = false;
          }
        }
      }
      )
    });
  }

  /*Sharepoint Lable Feature */
  displaySharepointFileLabelPopup(document: SPDocumentList) {  //UI event on file select
    this.sharePointSelectedFile = document;
    this.sharePointSelectedFileLabel = document.label;
    $('#modal-SharepointFileLabel').modal({ backdrop: 'static', keyboard: false });
    $("#modal-SharepointFileLabel").modal('show');
  }
  addSharepointFileLabel(labelName: string) {
    this.sharePointSelectedFileLabel = labelName;
    let workspace = new WorkspaceSupplementalDoc()
    workspace.workspaceId = this.engagementList.workspaceId;
    workspace.sourceFilePath = this.sharePointSelectedFile.documentUrl;
    workspace.sourceFileName = this.sharePointSelectedFile.documentName;
    workspace.label = this.sharePointSelectedFileLabel;
    workspace.createdby = this.userObject.userName;
    workspace.WorkspaceSupplementalDocId = this.sharePointSelectedFile.documentId;
    this.workspaceService.UploadSharepointFile(workspace).subscribe(success => {
      this.setSharepointFileLabelUI(this.sharePointSelectedFile.documentId);
      this.updateSpFilterFiles();
      this.clearSharepointFileUploadData();
    }, err => {
      console.error("Unable to upload sharepoint file label.", err);
      this.clearSharepointFileUploadData();
    });

    $("#modal-SharepointFileLabel").modal('hide');
  }
  cancelSharepointFileLabel() {
    this.clearSharepointFileUploadData();
  }
  sharePointLabelSelected(labelName: string) {
    this.sharePointSelectedFileLabel = labelName;
  }
  setSharepointFileLabelUI(documentId: number) {
    for (let i = 0; i < this.spDocumentList?.length; i++) {
      if (this.spDocumentList[i].documentId == documentId) {
        this.spDocumentList[i].label = this.sharePointSelectedFileLabel;
      }
    }
  }

  clearSharepointFileUploadData() {
    this.sharePointSelectedFile = undefined;
    this.sharePointSelectedFileLabel = "";
  }
  getSharepointFileLabels() {
    this.applicationMasterService.GetSharepointFileLabels().subscribe(applicationMaster => {
      if (applicationMaster && applicationMaster?.length > 0) {
        this.sharePointApplicationMasterLabels = applicationMaster;
        this.sharePointApplicationMasterLabelsFiltered = applicationMaster;
      }
    }, err => {
      console.error('Not able to load the sharepoint file label application master', err);
    })
  }
  attacheLabelToFiles() {
    this.workspaceService.GetSharepointFiles(this.engagementList.workspaceId).subscribe(workspaceDocs => {
      if (workspaceDocs && workspaceDocs.length > 0) {
        for (let i = 0; i < this.spDocumentList?.length; i++) {
          workspaceDocs.forEach(workspaceDoc => {
            if (workspaceDoc.sourceFileName == this.spDocumentList[i].documentName && workspaceDoc.sourceFilePath == this.spDocumentList[i].documentUrl) {
              this.spDocumentList[i].label = workspaceDoc.label;
              this.spDocumentList[i].workspaceId = workspaceDoc.workspaceId;
              return;
            }
          });
        }
      }
      this.updateSpFilterFiles();
    }, error => {
      console.error("unable to load sharepoint label data", error);
    })
  }
  updateSpFilterFiles() {
    let unlabelFound = false;
    this.sharePointApplicationMasterLabelsFiltered = []
    this.spDocumentList.forEach(spdoc => {
      this.sharePointApplicationMasterLabels.forEach(spAml => {
        if (spdoc.label == spAml.value) {
          this.sharePointApplicationMasterLabelsFiltered.push(spAml);
          return;
        } else {
          unlabelFound = true;
        }
      })
    });
    this.sharePointApplicationMasterLabelsFiltered = [...new Set(this.sharePointApplicationMasterLabelsFiltered)];
    this.sharePointApplicationMasterLabelsFiltered.sort((a, b) => a.value.localeCompare(b.value))
    // this.sharePointSelectedFileLabelForFilter = this.sharePointSelectedFileLabelForFilter;
  }
  getSharepointFileLabelFeatureFlag() {
    this.engagementService.GetSharepointFileLabelFeatureFlag().subscribe(result => {
      this.isSharepointFileLabelFeatureFlagEnabled = result;
    }, error => {
      console.error("unable to get sharepoint file label feature flag." + error)
    });
  }

  /*END Sharepoint Lable Feature */

  /*START Excecution Document */
  exePreviewDocument() {
    this.selectedSecondaryWorkspaceDocument = this.executionWorkspaceDocumentList.find(docItem => docItem.workspaceId === this.selectedExeWorkspaceDocumentEorkspaceId);
    this.loadingScreenService.startLoading();
    this.hdService.getHDDocumentListtoPreview(this.selectedSecondaryWorkspaceDocument.workItemId).subscribe(
      result => {
        this.executionWorkspaceDocumentList.filter(x => x.workItemId == this.selectedSecondaryWorkspaceDocument.workItemId)
          .forEach(x => { x.documentId = result[0].documentId, x.mimeType = result[0].mimeType });
        this.getDocumentPreviewContent(result[0].documentId, this.selectedSecondaryWorkspaceDocument.workItemId, this.selectedSecondaryWorkspaceDocument.workspaceId);
      });

  }

  getDocumentPreviewContent(documentid: string, workItemId: string, workspaceId: string) {
    if (workspaceId != 'undefined' && workspaceId != "" && workspaceId != null) {
      this.engagementService.GetEngagementPreviewDocumentFromSPByWorkspaceId(workspaceId, 'Active')
        .subscribe(result => {
          if (result) {
            this.isDocExported = true;
            if (result.siteUrl != null && result.siteUrl != '') {
              this._sharepointService.GetDocumentsFromFolderInLibrary(result.siteUrl, result.folderPath)
                .subscribe(SPDocumentList => {
                  if (SPDocumentList) {
                    var selectedDocument = SPDocumentList.filter(spDoc => {
                      const parts = spDoc.documentName.split('.');
                      const docNameWithoutExtension = parts.slice(0, -1).join('.');
                      const docNameWithoutSpaces = docNameWithoutExtension.replace(/\s/g, '');
                      const lowercaseDocName = docNameWithoutSpaces.toLowerCase();

                      const documentAliasName = result.fileName;
                      const docAliasWithoutSpaces = documentAliasName.replace(/\s/g, '');
                      const lowercaseDocAliasName = docAliasWithoutSpaces.toLowerCase();

                      return lowercaseDocName === lowercaseDocAliasName
                    })[0];
                  }
                  if (selectedDocument) {
                    this.isDocExistsSP = true;
                    if (selectedDocument.type == 'docx' || selectedDocument.type == 'doc'
                      || selectedDocument.type == 'pptx' || selectedDocument.type == 'ppt'
                      || selectedDocument.type == 'pdf' || selectedDocument.type == 'xlsx' || selectedDocument.type == 'xls') {
                      $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
                      $('.ui-widget-overlay.ui-front').remove();
                      this.loadingScreenService.stopLoading();

                      const url = selectedDocument.hyperLink;
                      $("#executionDocumentPreview").attr('src', url);
                      this.sharepointExeDocumentPreviewNotFound = false;

                    }
                  }
                  else {
                    if ($("#executionDocumntPreview") != undefined) {
                      $("#executionDocumentPreview").attr('src', "");
                    }
                    this.sharepointExeDocumentPreviewNotFound = true;
                    this.loadingScreenService.stopLoading();
                  }
                })

            }
            return;
          }
        });
    }

    this.isDocExported = false;
    this.sharepointExeDocumentPreviewNotFound = false;
    for (var i = 0; i < this.executionWorkspaceDocumentList.length; i++) {
      if (this.executionWorkspaceDocumentList[i].documentId == documentid) {
        var doctype = this.executionWorkspaceDocumentList[i].mimeType.toLowerCase();
        if (doctype == "word") {
          this.hdService.getDocumentContentName(documentid, workItemId)
            .subscribe((data: any) => {
              $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
              $('.ui-widget-overlay.ui-front').remove();
              this.loadingScreenService.stopLoading();
              var today = new Date();
              var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
              var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();
              var dateTime = date + ' ' + time;
              console.log("To check datetime on workspace while binding iframe src: ", dateTime);
              const url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + this.IntegrationAPIEndPoint + '/tempfiles/' + data + workItemId + '.docx?Version =' + dateTime;
              $("#executionDocumentPreview").attr('src', url);
            });
          break;
        }
        else if (doctype == "pdf") {
          this.hdService.getDocumentContent(documentid, workItemId)
            .subscribe((data: any) => {
              $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
              $('.ui-widget-overlay.ui-front').remove();
              this.loadingScreenService.stopLoading();
              let TYPED_ARRAY = new Uint8Array(data);
              //const STRING_CHAR= String.fromCharCode.apply(null, TYPED_ARRAY); works for small size of file.
              var STRING_CHAR = TYPED_ARRAY.reduce((data1, byte) => {
                return data1 + String.fromCharCode(byte);
              }, '');
              const url = "data:application/pdf;base64," + STRING_CHAR;
              $("#executionDocumentPreview").attr('src', url);
            });
          break;
        }
      }
    }
  }


  displayConfirmationPopUpDelete(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number,
    _dialogInput: string, IsAbandonModal: boolean, IsDeclineModal: boolean, bigTitle: boolean, engId: string = '0',
    loggedInUserEmail: string, reviewNoteIsFinalApproval: boolean = false, reviewNoteIsEP: boolean = false) {
    var deleteDocModalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '',
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogAbandonModal: IsAbandonModal,
      dialogDeclineModal: IsDeclineModal,
      dialogBigTitle: bigTitle,
      dialogReviewNoteIsFinalApproval: reviewNoteIsFinalApproval,
      dialogReviewNoteIsEP: reviewNoteIsEP,
      engagementId: engId,
      loggedInUserEmail: loggedInUserEmail
    };
    const deleteDocModalJSON = JSON.stringify(deleteDocModalObj);
    this.dataService.displayDialogNotification(deleteDocModalJSON);
  }
  confirmModalToremoveSecondaryWorkspace() {
    if (this.selectedSecondaryWorkspaceDocument.workspaceId != undefined) {
      let dialogDesc: string = '';
      let bigTitle: boolean = false;
      let dialogInput = '';
      const dialogType = 'danger';
      const action = EngagementDialogTitles.RemoveExecutionDocument;
      dialogDesc = `Are you sure you want to delete this document from Engage?
                    Documents that have been exported outside of Engage will not be 
                    deleted and must be deleted manually. This cannot be undone.`;
      this.displayConfirmationPopUpDelete(dialogType, action, dialogDesc, 2, dialogInput, false, false, bigTitle,
        '0', this.userObject.loggedInUserEmail);

    }
  }
  exportSPDocConfirmationPopup() {
    this.loadingScreenService.startLoading();
    if (this.selectedSecondaryWorkspaceDocument.workspaceId != 'undefined' && this.selectedSecondaryWorkspaceDocument.workspaceId != "" && this.selectedSecondaryWorkspaceDocument.workspaceId != null) {
      this.engagementService.GetEngagementPreviewDocumentFromSPByWorkspaceId(this.selectedSecondaryWorkspaceDocument.workspaceId, 'Deleted')
        .subscribe(result => {
          if (result) {
            if (result.siteUrl != null && result.siteUrl != '') {
              this._sharepointService.GetDocumentsFromFolderInLibrary(result.siteUrl, result.folderPath)
                .subscribe(SPDocumentList => {
                  if (SPDocumentList) {
                    var selectedDocument = SPDocumentList.filter(spDoc => {
                      const parts = spDoc.documentName.split('.');
                      const docNameWithoutExtension = parts.slice(0, -1).join('.');
                      const docNameWithoutSpaces = docNameWithoutExtension.replace(/\s/g, '');
                      const lowercaseDocName = docNameWithoutSpaces.toLowerCase();

                      const documentAliasName = result.fileName;
                      const docAliasWithoutSpaces = documentAliasName.replace(/\s/g, '');
                      const lowercaseDocAliasName = docAliasWithoutSpaces.toLowerCase();

                      return lowercaseDocName === lowercaseDocAliasName
                    })[0];
                  }
                  if (selectedDocument) {
                    this.loadingScreenService.stopLoading();
                    this.setDialogContent("warning", EngagementDialogTitles.ExportDocument);
                  }
                  else {
                    this.exportExecuationPhaseAddedDocument()
                  }
                });
            }
          }
          else {
            this.exportExecuationPhaseAddedDocument()
          }
        });
    }
  }

  exportExecuationPhaseAddedDocument() {
    this.spExceutionDocExportURL = this.engagementList.siteUrl + "/" + this.spGeneralFolderPath;
    this.sharepointSiteDocumentModel.files = [];
    this.sharepointSiteDocumentModel.documentType = 'Execution';
    this.sharepointSiteDocumentModel.documentFolderPath = this.engagementList.acceptanceFolderPath;
    this.sharepointSiteDocumentModel.documentFolderPath = this.spGeneralFolderPath;
    this.sharepointSiteDocumentModel.siteURL = this.engagementList.siteUrl;
    this.sharepointSiteDocumentModel.useremailId = this.userObject.loggedInUserEmail;
    this.loadingScreenService.startLoading();
    let selectedDocworkItemId: string[] = []
    selectedDocworkItemId.push(this.selectedSecondaryWorkspaceDocument.workItemId)
    this.hdService.GetDocumentsByteArray(selectedDocworkItemId).subscribe(spDocumentsResponse => {
      spDocumentsResponse.forEach(document => {
        let FileByteArrayStr = String(document.fileByteArray);
        let bin = atob(FileByteArrayStr);
        let bytes = [];
        for (let i = 0; i < bin.length; i++) {
          bytes.push(bin.charCodeAt(i));
        }
        let documentName = this.selectedSecondaryWorkspaceDocument.documentAliasName + ".docx"
        this.sharepointSiteDocumentModel.files.push({
          fileName: documentName,
          fileByteArray: bytes
        });
      });

      this._sharepointService.AddDocumentsToLibrary(this.sharepointSiteDocumentModel).subscribe(docResult => {
        this.loadingScreenService.stopLoading();
        if (docResult == 'Success') {
          $('#modal-exportDocumentCompleted').modal({ backdrop: 'static', keyboard: false });
          $("#modal-exportDocumentCompleted").modal('show');

          let workspaceDocument: IWantToDocumentsReference = new IWantToDocumentsReference();
          if (this.engagementId != 0 || this.engagementId != null) {
            workspaceDocument.engagementId = this.engagementId;
          }
          workspaceDocument.workspaceId = this.selectedSecondaryWorkspaceDocument.workspaceId;
          workspaceDocument.folderPath = this.spGeneralFolderPath;
          workspaceDocument.fileName = this.selectedSecondaryWorkspaceDocument.documentAliasName;
          workspaceDocument.createdBy = this.userObject.loggedInUserFullName;
          workspaceDocument.status = 'Add';
          this.engagementService.addUpdateDocumentReference(workspaceDocument).subscribe(
            result => {
              this.GetExcecutionDocumentsfromSPLibrary(this.engagementList.siteUrl, this.spGeneralFolderPath);
              this.isDocExported = true;
            },
            error => {
              this.loadingScreenService.stopLoading();
              this._alertService.add('Export document failed! Please contact system administrator.', 'danger', null);
            })


        } else {
          this._alertService.add('Export document failed! Please contact system administrator.', 'danger', null);
        }
      },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Export document failed! Please contact system administrator.', 'danger', null);
        });
    }, error => {
      this.loadingScreenService.stopLoading();
      this.workspaceService.storeErrorLog("WorkspaceId ::" + this.selectedSecondaryWorkspaceDocument.workspaceId + " Message ::" + error).subscribe(result => {
        console.warn(result);
      });
      this._alertService.add('Get document failed! Please contact system administrator.', 'danger', null);
    });

  }

  removeExecutionDocsFromSharepoint() {
    let docs = this.executionWorkspaceSPDocumentList.filter(x => x.documentName.substring(0, x.documentName.length - 5) == this.selectedSecondaryWorkspaceDocument.documentAliasName)
    if (docs.length > 0) {
      this.loadingScreenService.startLoading();
      let siteURL = docs[0].siteURL
      let documentUrl = docs[0].documentUrl
      this.sharepointService.deleteDocument(siteURL, documentUrl).subscribe(
        result => {
          this.GetExcecutionDocumentsfromSPLibrary(this.engagementList.siteUrl, this.spGeneralFolderPath);
        },
        error => {
          console.error('Error in removing execution Workspace SharePoint Document', error);
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    }
  }

  GetExcecutionDocumentsfromSPLibrary(siteURL: string, documentFolderPath: string) {
    this.loadingScreenService.startLoading();
    this._sharepointService.getAllDocumentsfromSPLibrary(siteURL, documentFolderPath).subscribe(
      result => {
        this.executionWorkspaceSPDocumentList = result;
        this.loadingScreenService.stopLoading();
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  MarkQnARecordDeleted(engagementId: number, workItemId: string) { // a mark questions and answer is deleted (softdelete)
    let record = new QnARecordForDelete();
    record.engagementId = engagementId;
    record.workItemId = workItemId;
    record.updatedBy = this.userObject.userName;
    this.workspaceService.MarkQnARecordAsDeleted(record).subscribe(
      result => {
        this.removeExecutionDocumetsFromLocalList();
        this.loadingScreenService.stopLoading();
      },
      error => {
        this.loadingScreenService.stopLoading();
        console.error('Error in MarkQnARecordDeleted execution doc', error);
      }
    );
  }

  removeExecutionSecondaryWorkspaceDocumets() { //remove the execution added document from secondary worksapce andn delete the secondary workspace (softdelete)
    this.removeSecondaryWorkspace = new RemoveSecondaryWorkspaceModel();
    this.removeSecondaryWorkspace.workspaceId = this.selectedSecondaryWorkspaceDocument.workspaceId;
    this.removeSecondaryWorkspace.updatedBy = this.userObject.userName;
    this.loadingScreenService.startLoading();
    this.workspaceService.RemoveSecondaryWorkspace(this.removeSecondaryWorkspace).subscribe(
      resultMsg => {
        if (resultMsg === 'Success') {
          this.MarkQnARecordDeleted(this.engagementList.engagementId, this.selectedSecondaryWorkspaceDocument.workItemId);// after successfuly removed workspace mark questions and answer is deleted (softdelete)
          if (this.isDocExported) {
            this.removeSPDocFromEngage(this.selectedSecondaryWorkspaceDocument.workspaceId, EngagementDialogTitles.RemoveExecutionDocument);

          }

        } else if (resultMsg === 'Failure') {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('secondary exceution worksapce could not be removed !', 'danger');
        }
      },
      error => {
        console.error('Error while removing secondary worksapce', error);
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Error while removing secondary worksapce of execution!', 'danger');
      });
  }

  removeExecutionDocumetsFromLocalList() { //repopulate the dropwdown of document and select latest one
    let tempExecutionWorkspaceDocuments = this.executionWorkspaceDocumentList.filter(obj => obj.workspaceId !== this.selectedSecondaryWorkspaceDocument.workspaceId);
    if (tempExecutionWorkspaceDocuments.length > 0) {
      this.selectedSecondaryWorkspaceDocument = tempExecutionWorkspaceDocuments[0];
      this.executionWorkspaceDocumentList = tempExecutionWorkspaceDocuments;
      this.selectedExeWorkspaceDocumentEorkspaceId = this.selectedSecondaryWorkspaceDocument.workspaceId;
      this.exePreviewDocument();
    } else {
      this.executionWorkspaceDocumentList = [];
      this.selectedSecondaryWorkspaceDocument = undefined;
    }
  }

  editExecutionQuestionnaire() {
    this.loadingScreenService.startLoading();
    if (this.selectedSecondaryWorkspaceDocument.workspaceId != 'undefined' && this.selectedSecondaryWorkspaceDocument.workspaceId != "" && this.selectedSecondaryWorkspaceDocument.workspaceId != null) {
      this.engagementService.GetEngagementPreviewDocumentFromSPByWorkspaceId(this.selectedSecondaryWorkspaceDocument.workspaceId, 'Deleted')
        .subscribe(result => {
          if (result) {
            if (result.siteUrl != null && result.siteUrl != '') {
              this._sharepointService.GetDocumentsFromFolderInLibrary(result.siteUrl, result.folderPath)
                .subscribe(SPDocumentList => {
                  if (SPDocumentList) {
                    var selectedDocument = SPDocumentList.filter(spDoc => {
                      const parts = spDoc.documentName.split('.');
                      const docNameWithoutExtension = parts.slice(0, -1).join('.');
                      const docNameWithoutSpaces = docNameWithoutExtension.replace(/\s/g, '');
                      const lowercaseDocName = docNameWithoutSpaces.toLowerCase();

                      const documentAliasName = result.fileName;
                      const docAliasWithoutSpaces = documentAliasName.replace(/\s/g, '');
                      const lowercaseDocAliasName = docAliasWithoutSpaces.toLowerCase();

                      return lowercaseDocName === lowercaseDocAliasName
                    })[0];

                  }
                  if (selectedDocument) {
                    this.loadingScreenService.stopLoading();
                    this.setDialogContent('warning', EngagementDialogTitles.EditQuestionnaire);

                  }

                })
            }
          }
          else {
            this.loadingScreenService.stopLoading();
            this.navigateEditQuestionnaire();
          }
        })
    }


  }

  navigateEditQuestionnaire() {
    const selectedWorkspaceDocumentText = $('#exeWorkspaceDocument option:selected').text().trim();
    if (selectedWorkspaceDocumentText != undefined && selectedWorkspaceDocumentText.length > 0) {
      this.selectedSecondaryWorkspaceDocument = this.executionWorkspaceDocumentList.find(docItem => docItem.documentAliasName === selectedWorkspaceDocumentText);
    }
    else {
      this.selectedSecondaryWorkspaceDocument = this.executionWorkspaceDocumentList[0];
    }
    this.router.navigate(['NewDocumentInterviews/' + this.selectedSecondaryWorkspaceDocument.workspaceId]);
  }
  removeSPDocFromEngage(workspaceId: string, dialogTitle: string) {
    let workspaceDocument: IWantToDocumentsReference = new IWantToDocumentsReference();
    workspaceDocument.workspaceId = workspaceId;
    workspaceDocument.createdBy = this.userObject.loggedInUserFullName;
    workspaceDocument.status = 'Delete'
    this.engagementService.addUpdateDocumentReference(workspaceDocument).subscribe(
      result => {
        if (dialogTitle === EngagementDialogTitles.EditQuestionnaire) {
          this.navigateEditQuestionnaire();
        }

      }, error => {
        console.error('Error while removing secondary worksapce from sharepoint', error);
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Error while removing secondary worksapce from sharepoint!', 'danger');
      });

  }
  setDialogContent(type: string, title: string) {
    let description: string;
    let button: number = 2;
    let bigTitle = false;
    switch (title) {
      case EngagementDialogTitles.EditQuestionnaire:
        description = "Editing the questionnaire while a linked document exists will lead to a situation where the preview in Engage does not match the contents of the previously exported file. Exporting this new document will replace the file that currently exists in SharePoint. Are you sure you want to continue?";
        button = 2;
        bigTitle = true;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.ExportDocument:
        description = "This will replace a file that was previously linked in SharePoint. Are you sure you want to continue?";
        button = 2;
        bigTitle = true;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      default:
        break;
    }
  }
  displayDialog(type: string, title: string, description: string, button: number, bigTitle: boolean, isContinuanceEngExists: boolean = false) {
    var modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogBigTitle: bigTitle,
    };
    var modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called information');
    this.dataService.displayDialogNotification(modalJSON);
  }
  ResetIwantToUnsavedFlag() {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setUnsavedChangesModel(dialogJSON);
    this.dataService.setClientIdForPrePopulate(-1);
    this.dataService.setEngagementIdForPrePopulate(-1);
  }

  OnHDQuestionAnswerLoaded(status) {
    this.IsHDQuestionAnswerLoaded = status;
  }
  /*END Excecution Document */

  /** START sharepoint file picker */

  openSharepointFilePicker() {
    this.sharepointFilePickerComponent.getSharepointLibraryListItems();
    $('#modal-SharepointFilePicker').modal({ backdrop: 'static', keyboard: false });
    $("#modal-SharepointFilePicker").modal('show');
  }
  cancelSharepointFilePicker() {
    $("#modal-SharepointFilePicker").modal('hide');
  }
  okSharepointFilePicker(sharepointListItem: SharepointListItem) {
    this.selectedSecondaryWorkspaceDocument
    let dr = new IWantToDocumentsReference();
    dr.workspaceId = this.selectedSecondaryWorkspaceDocument.workspaceId;
    const parts = sharepointListItem.name.split('.');
    const docNameWithoutExtension = parts.slice(0, -1).join('.');
    dr.fileName = docNameWithoutExtension;
    dr.lastModifedBy = this.userObject.loggedInUserFullName;
    dr.folderPath = this.spSharedDocumentsPath + sharepointListItem.rootFolderPath;
    this.engagementService.updateDocumentReference(dr).subscribe(result => {
      if (result) {
        $("#modal-SharepointFilePicker").modal('hide');
        this.exePreviewDocument();
        this.sharepointExeDocumentPreviewNotFound = false;
      }
    }, error => {
      console.error("Error in update sharepoint broken link", error);
    })
    let fullpath = this.engagementList.siteUrl + sharepointListItem.rootFolderPath;
  }
  /*END  sharepoint file picker*/

  /** Load Data for 30 Days Notifcation START*/
  getOwnersMembersFromSharepointSiteActiveDirectoryGroups() {
    //SiteCollaborationMembers
    this.SiteCollaborationMembersList = [];
    this.collaborationSiteList.forEach(site => {
      let siteCollaborationMembers: SiteCollaborationMembers = new SiteCollaborationMembers();
      siteCollaborationMembers.SiteUniqueId = site.siteUniqueID;
      this._activedirectoryService.GetADOwnerUsersByGroups(site.siteUniqueID).subscribe(result => {
        siteCollaborationMembers.SiteOwners = result.adUserDetails as any as AzureADUser[];
        this._activedirectoryService.GetADMemberUsersByGroups(site.siteUniqueID).subscribe(result => {
          siteCollaborationMembers.SiteMembers = result.adUserDetails as any as AzureADUser[];
          if (site.msTeamUrl?.length > 0) {
            siteCollaborationMembers.IsTeamsConverted = true;
            this._activedirectoryService.GetUsersFromADGroup(site.siteUniqueID).subscribe(result => {
              siteCollaborationMembers.UsersDetails = result[0].usersDetails;
              this.SiteCollaborationMembersList.push(siteCollaborationMembers);
            })
          } else {
            siteCollaborationMembers.IsTeamsConverted = false;
            this.SiteCollaborationMembersList.push(siteCollaborationMembers);
          }
        })
      });
    });
  }
  /** Load Data for 30 Days Notifcation START*/

  /** HotDocs Caching issue fixes START*/
  ValidateHdTemplateCache(documentType: string) {
    let selectedWorkspacedocModel: WorkspaceDocumentViewModel = this.selectedWorkspaceDocumentItem;
    if (documentType == "executionDocuments") {
      selectedWorkspacedocModel = this.selectedSecondaryWorkspaceDocument;
    }

    var canReload = this.hdService.canReloadPageForHotdocsTemplate(
      selectedWorkspacedocModel.documentAliasName,
      selectedWorkspacedocModel.hdTemplatePackageId,
      selectedWorkspacedocModel.hdCurrentTempVersion
    );
    if (canReload) {
      this.ReloadPage(documentType, selectedWorkspacedocModel);
    }
  }

  private SetUIStateBeforePageReload(documentType: string) {
    sessionStorage.setItem('documentType', documentType);

    if (this.lastRedirectedFromCreateClient) {
      sessionStorage.setItem('lastRedirectedFromCreateClient', "true");
    }

    if (this.lastIwantToHeading) {
      sessionStorage.setItem('lastIwantToHeading', this.lastIwantToHeading);
    }

    if (this.isConveneProjectsExists) {
      sessionStorage.setItem('isConveneProjectsExists', this.isConveneProjectsExists.toString());
    }
  }

  private ReloadPage(documentType: string, selectedWorkspacedocModel: WorkspaceDocumentViewModel) {
    //Reload page so the saved hotdocs template version with answers will be displayed in UI properly. 

    this.SetUIStateBeforePageReload(documentType);

    this.hdService.RemoveHdTemplateVersionListInBrowserSession();
    this.hdService.UpdateHdTemplateVersionInBrowserSession(
      selectedWorkspacedocModel.documentAliasName,
      selectedWorkspacedocModel.hdTemplatePackageId,
      selectedWorkspacedocModel.hdCurrentTempVersion
    );

    sessionStorage.setItem('isPageReloaded', "true");
    HD$.ReattachSession();
  }
  private RestoreUIStateAfterPageLoaded() {
    var isPageReloadedSession = sessionStorage.getItem("isPageReloaded");
    if (isPageReloadedSession != null && isPageReloadedSession != undefined) {

      var documentType = sessionStorage.getItem('documentType');
      if (documentType == 'acceptance') {
        this.tabName = 'acceptance';
      } else if (documentType == 'executionDocuments') {
        this.tabName = 'Documents';
      }

      var lastRedirectedFromCreateClient = sessionStorage.getItem('lastRedirectedFromCreateClient');
      if (lastRedirectedFromCreateClient != null && lastRedirectedFromCreateClient != undefined) {
        this.lastRedirectedFromCreateClient = "true";
      }

      var lastIwantToHeading = sessionStorage.getItem('lastIwantToHeading');
      if (lastIwantToHeading != null && lastIwantToHeading != undefined) {
        this.lastIwantToHeading = lastIwantToHeading;
      }

      var isConveneProjectsExists = sessionStorage.getItem('isConveneProjectsExists');
      if (isConveneProjectsExists != null && isConveneProjectsExists != undefined) {
        this.isConveneProjectsExists = true;
      }

      sessionStorage.removeItem('documentType');
      sessionStorage.removeItem('lastRedirectedFromCreateClient');
      sessionStorage.removeItem('lastIwantToHeading');
      sessionStorage.removeItem('isConveneProjectsExists');
      sessionStorage.removeItem('isPageReloaded');
    }
  }

  private RestoreUIStateAfterPageLoadedForQualityChecklist() {
    var isPageReloadedSession = sessionStorage.getItem("isTaxQualityChecklistReloaded");
    if (isPageReloadedSession != null && isPageReloadedSession != undefined) {
      this.tabName = 'Closeout';
    }
  }
  /** HotDocs Caching issue fixes END*/
  ResetClientConsentOptions() {
    this._clientConsentService.ResetClientConsentOptions(this.engagementId).subscribe(result => {
      console.log('ResetClientConsentOptions', result)
      var Action = '';
      if (result == true) {
        Action = 'Update';
      }
      this.getClientConsentOptions(Action);
    }, error => {
      console.log('ResetClientConsentOptions', error);
      this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    });
  }

  ResetClientConsentDialog() {
    if (this.engagementList.engagementPartner.toLowerCase() == this.userObject.userName.toLowerCase() || this.isloggedInUserAdmin == 'yes') {
      let dialogDesc: string = '';
      let bigTitle: boolean = false;
      let dialogInput = '';
      const dialogType = 'danger';
      const action = ConsentResetDialogTitle.ResetClientConsent;
      dialogDesc = `Are you sure you want to reset the client data consent option to the default for your solution code? This will reset the client data consent options to reflect an unmodified agreement from the firm's standard template for this service.`;
      this.displayConfirmationPopUpDelete(dialogType, action, dialogDesc, 2, dialogInput, false, false, bigTitle,
        '0', this.userObject.loggedInUserEmail);
    }
  }
  EngagementDataConsent(e) {
    this.clientConsentFlagId = e.target.id;
    if (e.target.value == "No") {
      this.isClientConsentModified = true;
      this.applicationMasterCode = e.target.value;
      if (this.isClientConsentAgreed) {
        this.ResetClientConsentDialog();
      }
      else {
        this.ResetClientConsentOptions();
      }
      this.UpdateClientConsentFlag(e.target.id);
    }
    else if (e.target.value == "Yes") {
      this.previousClientConsentFlagId = this.clientConsentFlagId;
      this.applicationMasterCode = e.target.value;
      if (!this.isClientConsentModified) {
        this.ResetClientConsentOptions();
      }
      if (this.isRequiredConsentData) {
        setTimeout(() => {
          this.EditEngagementDataConsent();
        }, 1000);
      }
      this.UpdateClientConsentFlag(e.target.id);
    }
    else if (e.target.value == "NA") {
      this.isClientConsentModified = true;
      this.previousClientConsentFlagId = this.clientConsentFlagId;
      this.applicationMasterCode = e.target.value;
      this.UpdateClientConsentFlag(e.target.id);
      this.ResetClientConsentOptionsToSystemDefault();
    }
    this.isRequiredConsentData = true;
  }
  EditEngagementDataConsent() {
    this.arrPrimaryClientConsentOptions = [];
    this.arrSecondaryClientConsentOptions = [];
    this.arrclientConsentOptions = [];
    this.clientConsentOptionsPrimaryForPopUp = JSON.parse(JSON.stringify(this.clientConsentOptions.filter(x => x.isPrimaryConsent == true)));
    this.clientConsentOptionsSecondaryForPopUp = JSON.parse(JSON.stringify(this.clientConsentOptions.filter(x => x.isPrimaryConsent == false)));
    this.bindPrimaryConsentOptionOnEdit();
    $("#modal-EgagementDataConentPopUp").modal('show');

  }

  CancelEgagementDataConsentPopUp() {
    this.arrPrimaryClientConsentOptions = [];
    this.arrSecondaryClientConsentOptions = [];
    this.arrclientConsentOptions = [];
    this.clientConsentOptionsPrimaryForPopUp = [];
    this.clientConsentOptionsSecondaryForPopUp = [];
    this.arrclientConsentOptions = [];
    this.IsCheckedVal = true;
    $("#modal-EgagementDataConentPopUp").modal('hide');
  }
  getPrimaryConsentOptionsVal(event) {
    const ClientConsentOptionsModel = new UpdateClientConsentOptions();
    ClientConsentOptionsModel.isChecked = event.target.checked;
    ClientConsentOptionsModel.clientConsentId = event.target.id;
    ClientConsentOptionsModel.engagementId = this.engagementId;
    ClientConsentOptionsModel.checkedBy = this.userObject.loggedInUserEmail;
    ClientConsentOptionsModel.modifiedBy = this.userObject.loggedInUserEmail;
    if (this.arrPrimaryClientConsentOptions.length > 0) {
      this.arrPrimaryClientConsentOptions = [];
    }
    this.arrPrimaryClientConsentOptions.push(ClientConsentOptionsModel);
    this.IsCheckedVal = true;
  }
  bindPrimaryConsentOptionOnEdit() {
    var selectedItem = this.clientConsentOptionsPrimary.filter(x => x.isChecked == true);
    const ClientConsentOptionsModel = new UpdateClientConsentOptions();
    if (selectedItem.length > 0) {
      ClientConsentOptionsModel.isChecked = selectedItem[0].isChecked;
      ClientConsentOptionsModel.clientConsentId = selectedItem[0].clientConsentId;
      ClientConsentOptionsModel.engagementId = this.engagementId;
      ClientConsentOptionsModel.checkedBy = this.userObject.loggedInUserEmail;
      ClientConsentOptionsModel.modifiedBy = this.userObject.loggedInUserEmail;
      if (this.arrPrimaryClientConsentOptions.length > 0) {
        this.arrPrimaryClientConsentOptions = [];
      }
      this.arrPrimaryClientConsentOptions.push(ClientConsentOptionsModel);
    }
  }
  updateClientConsentOptions() {
    var primaryCheckedCount = this.arrPrimaryClientConsentOptions.filter(x => x.isChecked == true).length;
    if (primaryCheckedCount > 0) {
      this.IsCheckedVal = true;
      this.clientConsentOptionsPrimaryForPopUp.forEach(element => {
        var result = this.arrPrimaryClientConsentOptions.filter(x => x.clientConsentId == element.clientConsentId);
        const ClientConsentOptionsModel = new UpdateClientConsentOptions();
        ClientConsentOptionsModel.isChecked = result.length == 0 ? false : result[0].isChecked;
        ClientConsentOptionsModel.clientConsentId = element.clientConsentId;
        ClientConsentOptionsModel.engagementId = this.engagementId;
        ClientConsentOptionsModel.checkedBy = this.userObject.loggedInUserEmail;
        ClientConsentOptionsModel.modifiedBy = this.userObject.loggedInUserEmail;
        this.arrclientConsentOptions.push(ClientConsentOptionsModel);

      });

      this.clientConsentOptionsSecondaryForPopUp.forEach(element => {
        const ClientConsentOptionsModel = new UpdateClientConsentOptions();
        ClientConsentOptionsModel.isChecked = element.isChecked;
        ClientConsentOptionsModel.clientConsentId = element.clientConsentId;
        ClientConsentOptionsModel.engagementId = this.engagementId;
        ClientConsentOptionsModel.checkedBy = this.userObject.loggedInUserEmail;
        ClientConsentOptionsModel.modifiedBy = this.userObject.loggedInUserEmail;
        this.arrclientConsentOptions.push(ClientConsentOptionsModel);
      });

      // this.arrclientConsentOptions.push(this.arrPrimaryClientConsentOptions[0]);
      if (this.arrclientConsentOptions.length > 0) {
        this.loadingScreenService.startLoading();
        this._clientConsentService.UpdateClientConsentOptionsData(this.arrclientConsentOptions).subscribe(result => {
          console.log('UpdateClientConsentOptions result', result);
          var Action = '';
          if (result == "true") {
            Action = 'Update';
          }
          this.getClientConsentOptions(Action);
        }, error => {
          this.loadingScreenService.stopLoading()
          console.log('UpdateClientConsentOptions result', error);
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
        this.loadingScreenService.stopLoading();
      }
      $("#modal-EgagementDataConentPopUp").modal('hide');
      this.clientConsentOptionsPrimaryForPopUp = [];
      this.clientConsentOptionsSecondaryForPopUp = [];
    }
    else {
      this.IsCheckedVal = false;
    }
  }

  getClientConsentOptions(Action) {
    this._clientConsentService.GetClientConsentOptions(this.engagementId).subscribe(result => {
      this.clientConsentOptions = result;
      this.clientConsentOptionsPrimary = JSON.parse(JSON.stringify(this.clientConsentOptions.filter(x => x.isPrimaryConsent == true)));
      this.clientConsentOptionsSecondary = JSON.parse(JSON.stringify(this.clientConsentOptions.filter(x => x.isPrimaryConsent == false)));
      if (Action == 'Update') {
        this.GenerateClientConsentEvent();
      }
      console.log('Clientconsent result', this.clientConsentOptions);
      console.log('ClientconsentFor Pop-up result', this.clientConsentOptionsForPopup);
    }, error => {
      console.log('Client consent  result', error);
    });
  }

  GetClientConsentFlagByEngId() {
    this._clientConsentService.GetClientConsentFlagByEngId(this.engagementId).subscribe(result => {
      this.getApplicationMasterDataByAppName();
      this.clientConsentFlagId = parseInt(result);
      this.isClientConsentAgreed = !this.isNullOrUndefined(result) && result != "";
      this.isClientConsentModified = !this.isNullOrUndefined(result) && result != "";
      this.previousClientConsentFlagId = parseInt(result);
      console.log('Get clientConsentFlagId result', this.clientConsentFlagId);
    }, error => {
      console.log('GetClientConsentFlagByEngId  result', error);
      this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    });
  }

  UpdateClientConsentFlag(clientConsentFlag: number) {
    let clientConsentFlagUpdateModel = new ClientConsentFlagUpdateModel();
    clientConsentFlagUpdateModel.EngagementId = this.engagementId;
    clientConsentFlagUpdateModel.ClientConsentFlag = clientConsentFlag;
    this._clientConsentService.UpdateClientConsentFlag(clientConsentFlagUpdateModel).subscribe(result => {
      console.log('UpdateClientConsentFlag result', result);
      this.isClientConsentAgreed = true;
    }, error => {
      console.log('UpdateClientConsentFlag result', error);
      this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    });
  }

  UpdateIndusConsentInConsentTab(isIndus: boolean, collaborationSiteId: number) {
    if (!isIndus) {
      this._clientConsentService.GetCollaborationSiteMembersForConsentTab(collaborationSiteId).subscribe(CollaborationSiteMembers => {
        console.log('CollaborationSiteMembers', CollaborationSiteMembers);
        //1. Fetch Indusconsent ADGroups
        this.adUsersService.GetINDUSADGroups(RoleNames.INDUSConsent).subscribe(
          adGroupList => {
            this.indusADGroupsList = adGroupList;
            console.log('Indus ADGroups list : ', this.indusADGroupsList);
            if (CollaborationSiteMembers != undefined) {
              //2. Exclude EP from Collaboration Site Members List
              let CollabSiteMembersListExcludeEPAndEQCR =
                CollaborationSiteMembers.filter(member => member.memberRoleId != this.getTeamMemberRoleIdByCode('EP') && member.memberRoleId != this.getTeamMemberRoleIdByCode('PR'));
              if (CollabSiteMembersListExcludeEPAndEQCR.length > 0) {
                //3. Collect Members other than EP and EQCR and push  into indusMembersListArray
                CollabSiteMembersListExcludeEPAndEQCR.forEach(element => {
                  this.indusTeamMemberData = {
                    azureADUsers: this.indusADGroupsList,
                    workspaceMemberId: 1,
                    memberEmail: element.memberEmailId,
                    memberName: element.memberName,
                    isIndusMember: false
                  };
                  this.indusMembersListArray.push(this.indusTeamMemberData);
                });

                //4. To get valid indus member from team members list i.e CollabSiteMembersListExcludeEPAndEQCR
                let CollabSiteMembersExcludeEPAndEQCR: IndusADUsersListViewModel = new IndusADUsersListViewModel();
                if (this.indusMembersListArray != null && this.indusMembersListArray.length > 0) {
                  CollabSiteMembersExcludeEPAndEQCR.indusADUsersList = this.indusMembersListArray;
                  this.adUsersService.getValidIndusTeamMemberList(CollabSiteMembersExcludeEPAndEQCR).subscribe(
                    ValidIndusTeamMemberList => {
                      console.log('ValidIndusTeamMemberList==>', ValidIndusTeamMemberList);
                      //5. Pass valid indus members emailid list to remove from CollaborationSiteMembers table
                      if (ValidIndusTeamMemberList.indusADUsersList != null && ValidIndusTeamMemberList.indusADUsersList.length > 0) {
                        let indusMemberEmailIds: Array<IndusMemberEmailIds> = [];

                        ValidIndusTeamMemberList.indusADUsersList.forEach(element => {
                          let indusMemberEmailId = new IndusMemberEmailIds();
                          indusMemberEmailId.memberEmailId = element.memberEmail;
                          indusMemberEmailIds.push(indusMemberEmailId);
                        });

                        this.updateINDUSConsentFlag.engagementId = this.engagementId;
                        this.updateINDUSConsentFlag.collaborationSiteId = collaborationSiteId;
                        this.updateINDUSConsentFlag.isIndus = isIndus;
                        this.updateINDUSConsentFlag.lastModifiedBy = this.userObject.loggedInUserEmail;
                        this.updateINDUSConsentFlag.indusMemberEmailIds = indusMemberEmailIds;

                        this._clientConsentService.UpdateIndusConsentInConsentTab(this.updateINDUSConsentFlag).subscribe(result => {
                          console.log('UpdateIndusConsentInConsentTab status : ', result);
                        },
                          error => {
                            console.log('Error while updating Indus flag in UpdateIndusConsentInConsentTab func ', error);
                          });
                      }
                    },
                    error => {
                      this.indusADGroupsList = null;
                      console.log('Error in getting response from GetIndusTeamMembers func ', error);
                    }
                  );
                }
              }
            }
          },
          error => {
            this.indusADGroupsList = null;
            console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
          });
      },
        error => {
          console.log('Error in getting response from GetCollaborationSiteMembersForConsentTab func ', error);
        });
      this.GenerateIndusConsentEvent('No')
    } else {
      this.GenerateIndusConsentEvent('Yes')
      this.updateINDUSConsentFlag.engagementId = this.engagementId;
      this.updateINDUSConsentFlag.collaborationSiteId = collaborationSiteId;
      this.updateINDUSConsentFlag.isIndus = isIndus;
      this.updateINDUSConsentFlag.lastModifiedBy = this.userObject.loggedInUserEmail;
      this.updateINDUSConsentFlag.indusMemberEmailIds = null;

      this._clientConsentService.UpdateIndusConsentInConsentTab(this.updateINDUSConsentFlag).subscribe(result => {
        console.log('UpdateIndusConsentInConsentTab status : ', result);
      });
    }

  }

  getApplicationMasterDataByAppName() {
    this.applicationMasterService.GetClientConsentDataTerm().subscribe(
      result => {
        this.clientConsentdataterm = result;
        this.filterApplicationCode = result.filter(x => x.applicationMasterId == this.clientConsentFlagId);
        this.applicationMasterCode = this.filterApplicationCode[0].code;
        console.log('Clientconsent Application Master Code:', this.applicationMasterCode);
        console.log('Clientconsent Data Term result:', this.clientConsentdataterm);
      },
      error => {
        console.log('Client consent  result', error);
      });
  }
  ResetClientConsentOptionsToSystemDefault() {
    this._clientConsentService.ResetClientConsentOptionsToSystemDefault(this.engagementId).subscribe(result => {
      console.log('ResetClientConsentOptionsToSystemDefault', result)
      var Action = '';
      if (result == true) {
        Action = 'Update';
      }
      this.getClientConsentOptions(Action);
    }, error => {
      console.log('ResetClientConsentOptionsToSystemDefault', error);
      this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    });
  }

  loadMemberFirmDetails(engagementId) {
    this.engService.GetEditEngagementDetailsById(engagementId)
      .subscribe(
        result => {
          this.engagements = result;
          if (this.engagements?.memberFirmMappingId > 0) //if engagement is member firm then load member firm details
            this.memberFirmService.getMemberFirmDetailsById(this.engagements?.memberFirmMappingId).subscribe(memberFirmData => {
              this.memberFirmData = memberFirmData
              if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {
                this.teamMemberRoleName = this.memberFirmData.indusConsentShortRoleName;
              } else {
                this.teamMemberRoleName = this.memberFirmData?.teamMemberShortRoleName;
              }
            })
        })
  }
  assignAzureADGroupsByMemberFirm() {
    if (this.azureADUsersArrayListMemberFirm == undefined) {
      let role;
      if (this.currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) {
        role = this.memberFirmData.indusConsentShortRoleName;
      } else {
        role = this.memberFirmData?.teamMemberShortRoleName;
      }
      this.adUsersService.GetAzureADGroupsByRoleShortName(role).subscribe(
        result => {
          this.azureADUsersArrayListMemberFirm = result;
          this.azureADUsersArrayListMemberFirm.azureADUsers?.forEach(resu => {
            this.assignAdminList.azureADUsers.push(resu);
          });
          this.assignAzureADGroupsByAdmin();
        }
      );
    }
    else {
      this.azureADUsersArrayListMemberFirm.azureADUsers?.forEach(resu => {
        this.assignAdminList.azureADUsers.push(resu);
      });
      this.assignAdminList.azureADUsers = this.assignAdminList.azureADUsers.filter((item,
        index) => this.assignAdminList.azureADUsers.indexOf(item) === index);
    }
  }

  assignAzureADGroupsByAdmin() {
    if (this.isloggedInUserAdmin == 'yes' && this.memberFirmData?.memberFirmMappingId != undefined) {
      if (this.azureADUsersArrayListAdmin == undefined)
        this.adUsersService.GetAzureADGroupsByRoleShortName(RoleNames.StaffMember.toString()).subscribe(
          result => {
            this.azureADUsersArrayListAdmin = result;
            this.azureADUsersArrayListAdmin.azureADUsers?.forEach(resu => {
              this.assignAdminList.azureADUsers.push(resu);
            });
            this.assignAdminList.roleName = RoleNames.StaffMember.toString();
          }
        );
      else {
        this.azureADUsersArrayListAdmin.azureADUsers?.forEach(resu => {
          this.assignAdminList.azureADUsers.push(resu);
        });
        this.assignAdminList.roleName = RoleNames.StaffMember.toString();
      }
    }
  }

  isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  getCLMGoverningAgrementNameStatus() {
    this.gaNameAndStatusRequest = new GoverningAgreementNameAndStatusRequest();
    this.gaNameAndStatusRequest.engagementID = "" + this.engagementId;
    this.clmService.getGoverningAgreementNameAndStatusByEngagementId(this.gaNameAndStatusRequest).subscribe(result => {
      if (result.statusCode == "OK") {
        this.gaNameAndStatusResponse = result;
        this.setCLMGoverningAgrementNameStatus();
        this.injectCLMCloseButtonDynamically();
        this.extractNameAndStatus(this.gaNameAndStatusResponse.htmlData);
      }
      this.insertCLMApiLog(this.engagementId);
    });
  }

  injectCLMCloseButtonDynamically() {// inject CLM close buttton if secof and third popup clicked
    setTimeout(() => { // wating  to load clm popup html
      var subject = document.querySelector(".clmmodal-body");
      const elc = document.getElementById('clmpopupCloseButton')
      if (elc == undefined && subject != undefined) { // if not in acceptance tab the subject is used to null check
        subject.insertAdjacentHTML(
          "afterbegin",
          `<button id="clmpopupCloseButton" type="button" (click)="clmPopupClosebuttonClicked()"  class="close clm-close"><span  class="close-text mr-1">Close </span><span  class="modal-close-btn">×</span></button>`,
        );
        setTimeout(() => { // wating  to load clm popup lose button html
          const el = document.getElementById('clmpopupCloseButton');
          el.addEventListener('click', this.clmPopupClosebuttonClicked.bind(this))
        }, 100);
      }
    }, 500);
  }
  clmPopupClosebuttonClicked(event) { //close butto clicked of clm Popup
    document.getElementById('myModal').style.display = 'none';
  }

  insertCLMApiLogSendGA(engagementId: number, request: ClmSignedDocumentRequest, respose: CLMResponse, documentname: string, fileType: string, emptyFile: boolean = false) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.SendGoverningAgreement;
    request.attachment = [];
    clmApiLog.requestPayload = JSON.stringify(request);
    clmApiLog.responseBody = JSON.stringify(respose);
    clmApiLog.createdBy = this.userObject.loggedInUserEmail;
    let converted = "";
    let originalPdfFileName = "";
    if (fileType == "docx") {
      originalPdfFileName = documentname.replace(".pdf", ".docx");
      converted = " and old file name - " + originalPdfFileName
    } else
      if (fileType == "doc") {
        originalPdfFileName = documentname.replace(".pdf", ".doc");
        converted = " and old file name - " + originalPdfFileName;
      }
    if (respose.statusCode == "OK") {
      clmApiLog.errorCode = "OK";
      clmApiLog.errorDescription = "Successfully sent Governing Agreement file name - " + documentname + converted;
    }
    else {
      clmApiLog.errorCode = respose.statusCode;
      if (emptyFile) // if file is not downloaed from sharepoint set true
        clmApiLog.errorDescription = "Failed to retrive Governing Agreement file name - " + documentname + converted + ". Error - " + respose.htmlData;
      else
        clmApiLog.errorDescription = "Failed to send Governing Agreement file name - " + documentname + converted;
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("Failed to insert api log for Governing Agreement Name And Status Response");
      }
    })
  }
  insertCLMApiLog(engagementId: number) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.GoverningAgreementStatus;
    clmApiLog.requestPayload = JSON.stringify(this.gaNameAndStatusRequest);
    clmApiLog.responseBody = JSON.stringify(this.gaNameAndStatusResponse);
    clmApiLog.createdBy = this.userObject.loggedInUserEmail;
    if (this.gaNameAndStatusResponse.statusCode != "OK") {
      clmApiLog.errorCode = this.gaNameAndStatusResponse.statusCode;
      clmApiLog.errorDescription = "Failed to get Governing Agreement Name And Status Response on Engagement Details Page.";
    }
    else {
      clmApiLog.errorCode = "";
      clmApiLog.errorDescription = "";
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("Failed to insert api log for Governing Agreement Name And Status Response");
      }
    })
  }
  setCLMGoverningAgrementNameStatus() {
    if (this.isCLMFeatureEnabled)
      if (this.gaNameAndStatusResponse != undefined) {
        setTimeout(() => { // setting 1ms await to load the UI first then UI tag inject 
          if (this.clmGoverningAgreementStatusDiv != undefined) { // if signature is completed the collab tab loaded
            this.clmGoverningAgreementStatusDiv.nativeElement.innerHTML = this.gaNameAndStatusResponse.htmlData;
            this.PopupDisplay(this.gaNameAndStatusResponse.htmlData);
            this.injectCLMCloseButtonDynamically();
          }
        }, 1);
      } else {
        this.getCLMGoverningAgrementNameStatus();
      }
  }
  PopupDisplay(htmlurl: string) {
    var newDiv = document.createElement("div");
    newDiv.innerHTML = htmlurl;
    var scripts = newDiv.getElementsByTagName('script');
    for (var ix = 0; ix < scripts.length; ix++) {
      let clmScript: HTMLScriptElement = document.createElement('script');
      clmScript.async = true;
      clmScript.innerHTML = scripts[ix].text;
      document.head.prepend(clmScript);
    }
  }
  getCLMFeatureFlag() {
    this.clmService.getCLMFeatureFlag().subscribe(result => {
      this.isCLMFeatureEnabled = result;
      if (this.isCLMFeatureEnabled) {
        this.getCLMGoverningAgrementNameStatus();
        this.getCLMDetails();
      }
    });
  }

  // Administrative close pop-up
  AdministrativeClose() {
    var dialogDesc = 'This will forego the usual closure process and the engagement\'s status will displayed as "Closed" in Engage. Administrative closes will still be distinguishable in reporting outside of Engage.';
    this.displayDialogForAdministrativeClose('information', EngagementDialogTitles.AdministrativeClose, dialogDesc, 2,
      '', false, this.engagementId, this.userObject.loggedInUserEmail);
  }

  displayDialogForAdministrativeClose(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number,
    _dialogInput: string, _bigTitle: boolean, engId: number, _loggedInUserEmail: string) {
    this.loadingScreenService.stopLoading();
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '',
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogBigTitle: _bigTitle,
      engagementId: engId,
      loggedInUserEmail: _loggedInUserEmail,
      isAdministrativeClose: true
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
  }

  insertEngAdministrativeClose() {
    this.engagementAdministrativeCloseModel = new EngagementAdministrativeCloseModel();
    this.engagementAdministrativeCloseModel.engagementId = this.engagementId;
    this.engagementAdministrativeCloseModel.administrativeName = this.userObject.loggedInUserFullName;
    this.engagementAdministrativeCloseModel.administrativeEmail = this.userObject.loggedInUserEmail;

    this.engagementService.InsertEngagementAdministrativeClose(this.engagementAdministrativeCloseModel).subscribe(result => {
      result == "Success" ? console.log("Administrative close details insertion Success") : console.log("Administrative close details insertion Failed");
    });
  }

  redirectToCorrectEngagementState(engagmentid: number) {
    let request = new EngagementStatusViewModel();
    request.engagementId = engagmentid;
    var isTaxQualityChecklistReloadedSession = sessionStorage.getItem("isTaxQualityChecklistReloaded");
    if (!(isTaxQualityChecklistReloadedSession == "true")) {
      this.engagementService.getEngagementStatus(request).subscribe({
        next: result => {
          let prevPath = this.dataService.previousUrl.getValue();
          let redirectURL = this.engagementService.getRedirectPathByEngagementStatus(result);
          let isHardRefreshPageReloaded = sessionStorage.getItem('isHardRefreshPageReloadedS');
          let prevPathItems = prevPath.split("/");
          if (prevPathItems?.length == 3 && prevPath.includes("EngagementDetail")) { // for tab selection
            redirectURL += prevPathItems[2];
          }
          let isLoginLogoutURL = false;
          if (prevPath?.includes("Login?returnUrl=")) { // for login redirect use case
            isLoginLogoutURL = true;
          }
          this.activatedRoute.snapshot;
          if (redirectURL?.length > 0 && !
            prevPath?.includes(redirectURL) && (prevPath == undefined || isLoginLogoutURL)
            && !isHardRefreshPageReloaded) {
            this.router.navigate([redirectURL]);
            sessionStorage.removeItem('isHardRefreshPageReloadedS');
          }
          else {
            sessionStorage.setItem('isHardRefreshPageReloadedS', "true"); // for page refresh issue
          }
        }
      })
    }
  }
  startLoadedeachSecondForSendFile() {
    setTimeout(() => {
      if (this.clmLsDownloadFileFromSharepoitisRunning) {
        this.loadingScreenService.startLoading();
        this.startLoadedeachSecondForSendFile();
      }
    }, 1);
  }

  getEngagementDocumentsByEngId(engId: number) {
    this.documentService.getEngagementDocumentsByEngId(engId).subscribe({
      next:
        result => {
          this.documentListDataFromDB = result;
        },
      error: err => console.log('Error in loading the docs list information from DB.')
    });
  }

  getCLMDetails() {
    this.clmService.getCLMDetails(this.engagementId).subscribe({
      next: result => {
        this.clmDetails = result;
        this.extractNameAndStatus(this.gaNameAndStatusResponse?.htmlData);
      }, error: err => {
        console.error("error  to load clm details from db", err);
      }
    })
  }
  extractNameAndStatus(htmlData: string) {
    if (htmlData) {
      const cleanedHtmlData = htmlData.replace(/<style[\s\S]*?<\/style>/gi, '');
      const parser = new DOMParser();
      const doc = parser.parseFromString(cleanedHtmlData, 'text/html');
      const GAName = doc.querySelector('strong');
      if (GAName) {
        if (this.clmDetails == undefined)
          this.clmDetails = new CLMDetails();
        this.clmDetails.name = GAName.textContent.trim();
        const textNode = GAName.nextSibling;
        if (textNode && textNode.nodeType === Node.TEXT_NODE) {
          const text = textNode.textContent.trim();
          const match = text.match(/\((.*?)\)/);
          if (match) {
            this.clmDetails.status = (match[1]);
          }
        }
      }
    }
  }

  DisplayDialog(type: string, title: string, description: string
    , button: number, bigTitle: boolean) {
    const modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogInput: '',
      dialogBigTitle: bigTitle
    };
    const modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called ', type);
    this.dataService.displayDialogNotification(modalJSON);
  }
}// end component

import { TeamMember } from "../Engagement/TeamMember";

export class ChangeOrderModel {
    changeOrderId: number;
    engagementId: number;
    changeOrderName: string;
    workspaceId: string;
    isFinancialUpdate: boolean;
    estimateCompletionDate: string;
    fee: number;
    grossMargin: number;
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    ApprovedDate: Date;
    status: string;
    teamMembers: TeamMember[]
}
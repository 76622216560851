 &nbsp; &nbsp;  &nbsp;
 <span class="mr-1 font-weight-bold link-text-blue" (click)="ProcessAction('Edit')" *ngIf="!isEditMode && !memberFirmFlag" >Edit  &nbsp;-&nbsp;</span>
 <span class="mr-1  font-weight-bold link-text-blue" (click)="ProcessAction('Remove')" *ngIf="!isEditMode && !memberFirmFlag"> Remove</span> 
 <span class="ml-1 font-weight-bold link-text-blue"  (click)="UpdateClientLevelDocument()" *ngIf="isEditMode && !memberFirmFlag" >Save &nbsp;-&nbsp;</span> 
 <span class="ml-1  font-weight-bold link-text-blue" (click)="CancelEdit()" *ngIf="isEditMode && !memberFirmFlag" > Cancel</span>
  <p class="mb-0 edp-font-13 alert-pre" *ngIf="isEditMode">
  <span class="">Description: </span>{{selectedClientLevelDocument.documentDescription}}
   </p>
   <p class="mb-0 edp-font-13" *ngIf="isEditMode">
   <span class="">GT agreement owner: </span>
   <span class="link-text-blue contactUser cursor-pointer font-weight-bold" id="GTAgreementOwner_{{selectedClientLevelDocument?.clientLevelDocumentId}}"
   (click)="displayContactPopoverAtChild(selectedClientLevelDocument?.gtAgreementOwnerEmail, 'GTAgreementOwner_'+selectedClientLevelDocument?.clientLevelDocumentId)">
   {{selectedClientLevelDocument.gtAgreementOwnerName}}
    </span>
   </p>
    <!--Edit Action Div Start-->

 <div class="row m-0 mt-1 mb-0" id="EditGoverningAgreement_{{updatedClientLevelDocument.clientLevelDocumentId}}" [ngClass]="{'div-disabled': !isEditMode}">
  <div class="col-md-6 col-lg-4 col-xl-4">
    <label>Type<span class="mandatory"> *</span></label>
    <div class="selectdiv">
      <select class="dropdown rounded-0 " name="type" id="agreementType" [(ngModel)]="updatedClientLevelDocument.documentSubTypeId">
        <option [value]="undefined" disabled hidden>Select document type</option>
        <option *ngFor="let type of subDocumentTypes" [value]="type.applicationMasterId" >{{ type.value }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6 col-lg-4 col-xl-4" *ngIf="isEditMode && !isTemplateDisabledAtChild && !isEngagementParent">
    <label for="templates">Mapped engagement template<span class="mandatory"> *</span></label>
    <div class="col-md-12 px-0 select-slim">
      <div class="selectdiv">
        <select class="dropdown rounded-0" id="ddTemplate" name="template" [(ngModel)]="updatedClientLevelDocument.templatePackageId">
          <option value="undefined" disabled hidden>Select a template</option>
          <option *ngFor="let template of templateListAtChild" [value]=template.templatePackageId>
            {{template.name}}
          </option>
        </select>
      </div>
      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isInvalidSubDocTypeAtChild">
        Type field is required
      </div>
    </div>
    <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isTempateNotSelected">
      Select a template
    </div>
  </div>
  <div class="row m-0 mt-3" [ngClass]="{'div-disabled': !isEditMode}">
    <div class="col-md-6 col-lg-4 col-xl-4" [ngClass]="{'div-disabled': !isEditMode}">
      <label for="msaEffectiveDate">Effective date<span class="mandatory"> *</span></label>
      <app-calendercontrol id="msaEffectiveDate" class="effective-date"
       [txtDate]="msaEffectiveDateCntrlIdAtChild">
      </app-calendercontrol>
      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isEffectiveDateEmptyAtChild">
        Effective date field is required
      </div>
      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="!isvalidEFDChild">
        Effective date should be valid
      </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-4" [ngClass]="{'div-disabled': !isEditMode}">
      <label for="msaExpirationDate">Expiration date</label>
      <app-calendercontrol id="msaExpirationDate" 
      [txtDate]="msaExpirationDateCntrlIdAtChild"
       class="expiration-date"></app-calendercontrol>
      <div *ngIf="isInvalidMSAExpirationDateAtChild || isInvalidMSAEffectiveDateAtChild" class="error-color">
        Expiration date should be current/future date and greater than effective date
      </div>
      <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="!isvalidEXDChild">
        Expiration date should be valid
      </div>
    </div>
    <div class="col-xl-4 col-md-6 " [ngClass]="{'div-disabled': !isEditMode}">
      <label><span class="label">GT agreement owner
      </span></label>
      <div class="input-group mw-100">
        <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=false
          [roleShortName]="GTAgreementOwnerRoleNameAtChild" [labelCtrlName]="GTAgreementOwnerlblCtrlNameAtChild" [isLabelVisible]="false"
          [InputCtrlIdIndex]="peoplepickerIndexAtChild" [PeoplePickerModel]="updatedClientLevelDocument.gtAgreementOwnerName"
          [userEmail]="updatedClientLevelDocument.gtAgreementOwnerEmail" [IsValidateUserWithADGroup]="validateGTAgreementOwnerAtChild">
        </app-people-picker>

        <div class="input-group-append input-height">
          <button type="button" id="PPIcon_{{peoplepickerIndexAtChild}}" 
            class="mw-100 people-picker-button text-center" (click)="refreshAgreementOwnerPopupAtChild(updatedClientLevelDocument.clientLevelDocumentId,'txtPeoplePicker_')">
            <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="GT agreement owner" />
          </button>
        </div>

      </div>
      <div class="form-control-feedback error-color mb-0" *ngIf="!isvalidGTAgreementOwnerAtChild">
        <p>GT agreement owner should be valid</p>
      </div>
    </div>
  </div>
    <div class="col-md-12 mt-3" [ngClass]="{'div-disabled': !isEditMode}">
      <label>Description </label>
      <input class="form-control rounded-0 border input-height bg-white" id="txtDocumentDescription_{{updatedClientLevelDocument.clientLevelDocumentId}}" name="txtDocumentDescription"
        placeholder="Enter description" type="text" [(ngModel)]="updatedClientLevelDocument.documentDescription" maxlength="200" autocomplete="off">
        <div *ngIf="updatedClientLevelDocument.documentDescription && updatedClientLevelDocument.documentDescription.length > 200" class="error-color">
          Description character length exceeded(Allowed 200 chars)
        </div>
    </div>
   <!--Start GT Agreemwnt Owner POPUP-->
   <div id="modal-Child-GTAgreementOwner_{{updatedClientLevelDocument.clientLevelDocumentId}}" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Select GT agreement owner </span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectGTAgreementOwnerAtChild();">
            <span class="close-text mr-1">Close </span>
            <span class="modal-close-btn">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span id="GTAgreementOwnerErrorMessageAtChild_{{updatedClientLevelDocument.clientLevelDocumentId}}" class="ErrorFont"></span>
  
          <div class="input-group nav-searchbox-length pb-2">
            <input type="text" id="searchGTAgreementOwnerNameAtChild_{{updatedClientLevelDocument.clientLevelDocumentId}}" (keydown.enter)="searchPartnerNamesAtChild('searchGTAgreementOwnerNameAtChild');"
              class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for GT Agreement Owner"
              autocomplete="off">
            <span class="input-group-append nav-seachBox">
              <div class="input-group-text" (click)="searchPartnerNamesAtChild('searchGTAgreementOwnerNameAtChild');">
                <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
              </div>
            </span>
          </div>
          <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
          <div class="tableScroll mt-2" *ngIf="updatedClientLevelDocument.clientLevelDocumentId == callingPopupId">
            <table id="tblGTAgreementOwnerAtChild_{{updatedClientLevelDocument.clientLevelDocumentId}}" class="table  table-hover" aria-describedby="GTAgreementOwner">
              <thead>
                <tr>
                  <th scope="col"> Name</th>
                  <th scope="col"> Email </th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Line of Business</th>
                  <th scope="col">Service Line</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr (click)='selectGTAgreementOwnerAtChild(i,$event)' id="trGTAgreementOwnerAtChild" name="trGTAgreementOwnerAtChild"
                  *ngFor="let member of ADUsersList let i=index">
                  <td>
                    <span id='spGTAgreementOwnerdisplayNameAtChild{{i}}'>{{member.displayName}}</span>
                  </td>
                  <td> <span id='spGTAgreementOwnermailAtChild{{i}}'>{{member.mail}}</span>
                  </td>
                  <td>
                    <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                  </td>
                  <td> <span id='spGTAgreementOwnerbusinessPhonesAtChild{{i}}'>{{member.businessPhones}}</span>
                  </td>
                  <td> <span id='splineOfBusinessAtChild{{i}}'>{{member.lineOfBusiness}}</span>
                  </td>
                  <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                  </td>
                  <td> <span id='splocation{{i}}'>{{member.location}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  
        </div>
        <div class="modal-footer">
          <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
            style="width: 150px; text-align: center;" (click)="deselectGTAgreementOwnerAtChild();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="Cancel" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
            (click)="setGTAgreementOwnerAtChild();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--End GT Agreemwnt Owner POPUP-->
</div>          
<!--Edit Action Div End-->

<!--Contact Popup-->
<app-contact-popup [contactEmailId]="ContactEmailId"></app-contact-popup>


<app-loading-screen></app-loading-screen>
<div class="containerDiv" *ngIf="!isMemberFirm">
  
  <app-alerts></app-alerts>
  <div class="row pageTitleParent m-0 mt-3">
    <div class="col-md-8 pl-0">
      <h2 class="pageTitle largerFontSize2">Search</h2>
    </div>   
    <div class="col-md-4 pr-0">
      <app-shortcut></app-shortcut>
    </div>
  </div>
  
  <div class="row m-0 mt-4">
    <div class="col-md-12 px-0">
      <div class="row m-0">
        <div class="col=lg-2 col-md-2 pl-2">
          <label>Service lines</label>
        </div>
        <div class="col-lg-2 col-md-3 pl-2">
          <label>Type</label>
        </div>
        <div class="col-lg-8 col-md-7 pl-2">
          <label>Search term</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0 mb-4" id="searchPanel">
    <div class="col-lg-2 col-md-2 px-0">
      <div class="serviceLineTypesDD serviceLineCurve">
        <div class="selectdiv ">
          <select id="ddServiceLine" [(ngModel)]="selectedServiceLine" class="dropdown rounded-0 form-control">
            <option *ngFor="let services of serviceLines" [value]="services.key">
              {{services.value}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-3 px-0">
      <div class="serviceLineTypesDD">
        <div class="selectdiv">
          <select id="ddSearchTypesDropdown" [(ngModel)]="selectedSearchType"
            (change)="changeSearchType(selectedSearchType)" class="dropdown rounded-0 form-control">
            <option *ngFor="let stypes of searchTypes" [value]="stypes.key">
              {{stypes.value}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-md-7 px-0">
      <div class="input-group nav-searchbox-length pb-2">
        <input id="txtSearchTerm" class="form-control border-0 nav-seachBox" type="search" placeholder=""
          (keydown.enter)="Search()" [(ngModel)]="searchTerm" autocomplete="off">
        <button class="input-group-append" (click)="Search()">
          <div class="input-group-text ">
            <img src="../../../../assets/images/EDP/ClientSearch/search-24px-white.svg" alt="Search" 
                    style="cursor: pointer; margin-right: 3px;">
          </div>
        </button>
      </div>
      <label id="errorLabelSearchTerm" class="error-color d-none">
        Search term can not be empty.
      </label>
    </div>
  </div>

  <div class="card-container search-body-section">
    <!-- search-list Start -->
    <div class="row m-0 mt-4">
      <div class="col-lg-2 col-md-12 pl-0">
        <section>
          <ul class="expand-section" *ngIf="selectedSearchType=='CL'">
            <li (click)="expandTeamDetails('CL_ClientNames','divCL_Client',$event)">
              <span>Client</span>
              <div id="divCL_Client" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="CL_ClientNames" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sClientNameList, let i=index">
                  <input name="CL_clientId-{{i}}" id="CL_clientId-{{i}}" value="{{item.isChecked}}" type="checkbox"
                    [(ngModel)]="item.isChecked" (change)="SearchWithFilters(item.clientName, 'clientName', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label " for="CL_clientId-{{i}}"> {{item.clientName}}</label>
                </div>
              </div>
            </li>

            <li (click)="expandTeamDetails('CL_ClientProgram','divCL_ClientProgram',$event)">
              <span>Program</span>
              <div id="divCL_ClientProgram" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="CL_ClientProgram" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sClientProgramList">
                  <input name="programId-{{item.clientProgramId}}" id="programId-{{item.clientProgramId}}"
                    type="checkbox" value="{{item.isChecked}}" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.clientProgramId, 'clientProgramId', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label "
                    for="programId-{{item.clientProgramId}}">{{item.clientProgram}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('CL_Status','divCL_Status',$event)">
              <span>Status</span>
              <div id="divCL_Status" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="CL_Status" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sClientStatusList">
                  <input name="clientStatusId-{{item.clientStatusId}}" id="clientStatusId-{{item.clientStatusId}}"
                    type="checkbox" value="{{item.isChecked}}" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.clientStatusId, 'clientStatusId', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label "
                    for="clientStatusId-{{item.clientStatusId}}">{{item.clientStatus}}</label>
                </div>
              </div>
            </li>
          </ul>
          <ul class="expand-section" *ngIf="selectedSearchType=='ENG'">
            <li (click)="expandTeamDetails('ENG_ClientNames','divENG_Client',$event)">
              <span>Client</span>
              <div id="divENG_Client" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ENG_ClientNames" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sClientNameList, let i=index">
                  <input name="ENG_clientId-{{i}}" id="ENG_clientId-{{i}}" type="checkbox" value="{{item.isChecked}}"
                    class="custom-control-input align-self-start" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.clientName, 'clientName', $event)">
                  <label class="custom-control-label " for="ENG_clientId-{{i}}">
                    {{item.clientName}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ENG_EngagementNames','divENG_Engagement',$event)">
              <span>Engagement</span>
              <div id="divENG_Engagement" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ENG_EngagementNames" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sEngagementNameList, let i=index">
                  <input name="ENG_engId-{{i}}" id="ENG_engId-{{i}}" type="checkbox" value="{{item.isChecked}}"
                    class="custom-control-input align-self-start" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.engagementName, 'engagementName', $event)">
                  <label class="custom-control-label " for="ENG_engId-{{i}}">
                    {{item.engagementName}}</label>
                </div>
              </div>
            </li>

            <li (click)="expandTeamDetails('ENG_EPPMD','divENG_EPPMD',$event)">
              <span>EPPMD</span>
              <div id="divENG_EPPMD" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ENG_EPPMD" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.seppmdList, let i=index">
                  <input name="programId-{{i}}" id="programId-{{i}}" type="checkbox" value="{{item.isChecked}}"
                    [(ngModel)]="item.isChecked" (change)="SearchWithFilters(item.eppmd, 'eppmd', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label " for="programId-{{i}}">{{item.eppmd}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ENG_SolutionCodes','divENG_SolutionCode',$event)">
              <span>Solution code</span>
              <div id="divENG_SolutionCode" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ENG_SolutionCodes" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sSolutionCodeList">
                  <input name="solutionCodeId-{{item.solutionCodeId}}" id="solutionCodeId-{{item.solutionCodeId}}"
                    [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.solutionCode, 'solutionCodeId', $event)" type="checkbox"
                    value="{{item.isChecked}}" class="custom-control-input align-self-start">
                  <label class="custom-control-label "
                    for="solutionCodeId-{{item.solutionCodeId}}">{{item.solutionCode}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ENG_Status','divENG_Status',$event)">
              <span>Status</span>
              <div id="divENG_Status" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ENG_Status" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sEngagementStatusList, let i=index">
                  <input name="engStatusId-{{i}}" id="engStatusId-{{i}}" type="checkbox" value="{{item.isChecked}}"
                    [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.engagementStatus, 'engagementStatus', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label " for="engStatusId-{{i}}">{{item.engagementStatus}}</label>
                </div>
              </div>
            </li>
          </ul>
          <ul class="expand-section" *ngIf="selectedSearchType=='ASIGN'">
            <li (click)="expandTeamDetails('ASIGN_AssignmentNames','divASIGN_Assignment',$event)">
              <span>Assignment</span>
              <div id="divASIGN_Assignment" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ASIGN_AssignmentNames" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sAssignmentNameList, let i=index">
                  <input name="ASIGN_asignId-{{i}}" id="ASIGN_asignId-{{i}}" type="checkbox" value="{{item.isChecked}}"
                    class="custom-control-input align-self-start" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.assignmentName, 'assignmentName', $event)">
                  <label class="custom-control-label " for="ASIGN_asignId-{{i}}">
                    {{item.assignmentName}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ASIGN_ClientNames','divASIGN_Client',$event)">
              <span>Client</span>
              <div id="divASIGN_Client" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ASIGN_ClientNames" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sClientNameList, let i=index">
                  <input name="ASIGN_clientId-{{i}}" id="ASIGN_clientId-{{i}}" type="checkbox"
                    value="{{item.isChecked}}" class="custom-control-input align-self-start"
                    [(ngModel)]="item.isChecked" (change)="SearchWithFilters(item.clientName, 'clientName', $event)">
                  <label class="custom-control-label " for="ASIGN_clientId-{{i}}">
                    {{item.clientName}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ASIGN_EngagementNames','divASIGN_Engagement',$event)">
              <span>Engagement</span>
              <div id="divASIGN_Engagement" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ASIGN_EngagementNames" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sEngagementNameList, let i=index">
                  <input name="ASIGN_engId-{{i}}" id="ASIGN_engId-{{i}}" type="checkbox" value="{{item.isChecked}}"
                    class="custom-control-input align-self-start" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.engagementName, 'engagementName', $event)">
                  <label class="custom-control-label " for="ASIGN_engId-{{i}}">
                    {{item.engagementName}}
                  </label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ASIGN_SolutionCodes','divASIGN_SolutionCode',$event)">
              <span>Solution code</span>
              <div id="divASIGN_SolutionCode" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ASIGN_SolutionCodes" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sSolutionCodeList">
                  <input name="solutionCodeId-{{item.solutionCodeId}}" id="solutionCodeId-{{item.solutionCodeId}}"
                    type="checkbox" value="{{item.isChecked}}" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.solutionCode, 'solutionCodeId', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label " for="solutionCodeId-{{item.solutionCodeId}}">
                    {{item.solutionCode}}</label>
                </div>
              </div>
            </li>
            <li (click)="expandTeamDetails('ASIGN_Status','divASIGN_Status',$event)">
              <span>Status</span>
              <div id="divASIGN_Status" class="filterImgOpen">
              </div>
              <div class="w-100 mt-1 sub-section-dd" id="ASIGN_Status" style="display:none;">
                <div class="d-flex justify-content-start my-1 custom-control custom-checkbox"
                  *ngFor="let item of searchPageViewModel.sAssignmentCodeStatusList, let i=index">
                  <input name="assignStatusId-{{i}}" id="assignStatusId-{{i}}" type="checkbox"
                    value="{{item.isChecked}}" [(ngModel)]="item.isChecked"
                    (change)="SearchWithFilters(item.assignmentCodeStatus, 'assignmentCodeStatus', $event)"
                    class="custom-control-input align-self-start">
                  <label class="custom-control-label " for="assignStatusId-{{i}}">{{item.assignmentCodeStatus}}</label>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <div class="col-lg-10 col-md-12 bg-edp-body px-0">
        <div class="row m-0 my-3">
          <div id="resultsLabel" class="col-md-6 font-weight-bold edp-font-16 " *ngIf="TotalResultCount > 0">
            Results <span>{{this.listViewCurrentPageNumber}}-{{listViewnoOfrecordsPerPage}} of
              {{TotalResultCount}}</span> for {{searchTerm}}
          </div>
        </div>
        <div class="m-3">
          <div *ngIf="selectedSearchType == 'CL'">
            <div
              *ngIf="searchPageViewModel.clientSearchResultList!=null && searchPageViewModel.clientSearchResultList !== undefined">
              <table id="tblClientResult" class="listViewDataTable">
                <thead>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{clientHeaderModelList[0].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of clientHeaderModelList[0].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(clientHeaderModelList[0].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>

                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{clientHeaderModelList[1].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of clientHeaderModelList[1].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(clientHeaderModelList[1].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>

                  </th>
                  <th scope="col" class="table-sorted-desc hide-me">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{clientHeaderModelList[2].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of clientHeaderModelList[2].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(clientHeaderModelList[2].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>

                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{clientHeaderModelList[3].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of clientHeaderModelList[3].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(clientHeaderModelList[3].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>

                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{clientHeaderModelList[4].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of clientHeaderModelList[4].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(clientHeaderModelList[4].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody *ngIf="searchPageViewModel.clientSearchResultList.length>0">
                  <tr *ngFor="let client of searchPageViewModel.clientSearchResultList">
                    <td>
                      <a target="_blank" *ngIf="isloggedInUserAdmin == 'yes' && client.isUserMember == true" class="link"
                        (click)="navigateClient(client.clientId)">
                        {{client.clientName}}
                      </a>
                      <a target="_blank" *ngIf="isloggedInUserAdmin == 'yes' && client.isUserMember == false" class="link"
                        (click)="navigateClient(client.clientId)">
                        {{client.clientName}}
                      </a>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && client.isUserMember == true" class="link"
                        (click)="navigateClient(client.clientId)">
                        {{client.clientName}}
                      </a>
                      <span *ngIf="isloggedInUserAdmin != 'yes' && client.isUserMember == false && isQualityUserLoggedIn != 'yes'">
                        {{client.clientName}}
                      </span>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && client.isUserMember == false && isQualityUserLoggedIn == 'yes'" class="link"
                        (click)="navigateClient(client.clientId)">
                        {{client.clientName}}
                      </a>
                    </td>
                    <td>{{client.clientCode}}</td>
                    <td class="hide-me">{{client.clientProgram}}</td>
                    <td>{{client.serviceLineList}}</td>
                    <td>{{client.clientStatus}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="no-data"
              *ngIf="(searchPageViewModel.clientSearchResultList!=null || searchPageViewModel.clientSearchResultList != undefined) && TotalResultCount==0">
              <span> {{DataMessage}}</span>
            </div>
          </div>
          <!-- end Client Search ResultList -->
          <!-- start Engagement Search ResultList -->
          <div *ngIf="selectedSearchType == 'ENG'">
            <div
              *ngIf="searchPageViewModel.engagementSearchResultList!=null && searchPageViewModel.engagementSearchResultList !== undefined">
              <table id="tblEngagementResult" class="listViewDataTable">
                <thead>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{engagementHeaderModelList[0].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of engagementHeaderModelList[0].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(engagementHeaderModelList[0].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{engagementHeaderModelList[1].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of engagementHeaderModelList[1].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(engagementHeaderModelList[1].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc hide-me">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{engagementHeaderModelList[2].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of engagementHeaderModelList[2].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(engagementHeaderModelList[2].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{engagementHeaderModelList[3].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of engagementHeaderModelList[3].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(engagementHeaderModelList[3].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{engagementHeaderModelList[4].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of engagementHeaderModelList[4].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(engagementHeaderModelList[4].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{engagementHeaderModelList[5].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of engagementHeaderModelList[5].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(engagementHeaderModelList[5].headerId)"
                            [ngClass]="{isDisabled:TotalResultCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                </thead>

                <tbody *ngIf="searchPageViewModel.engagementSearchResultList.length>0">
                  <tr *ngFor="let engagement of searchPageViewModel.engagementSearchResultList">
                    <td>
                      <a target="_blank" *ngIf="isloggedInUserAdmin == 'yes' && engagement.isUserMember == true"
                        class="link" (click)="navigateEngagement(engagement.engagementId)">
                        {{engagement.engagementName}}
                      </a>
                      <a target="_blank"
                        *ngIf="isloggedInUserAdmin == 'yes' && engagement.isUserMember == false && engagement.engagementStatus != 'Not started'"
                        class="link" (click)="navigateEngagement(engagement.engagementId)">
                        {{engagement.engagementName}}
                      </a>
                      <span
                        *ngIf="isloggedInUserAdmin == 'yes' && engagement.isUserMember == false && engagement.engagementStatus == 'Not started'"
                        >
                        {{engagement.engagementName}}
                      </span>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && engagement.isUserMember == true"
                        class="link" (click)="navigateEngagement(engagement.engagementId)">
                        {{engagement.engagementName}}
                      </a>
                      <span *ngIf="isloggedInUserAdmin != 'yes' && isQualityUserLoggedIn != 'yes' &&
                      engagement.isUserMember == false &&  !isINDUSStaffingManagerLoggedIn">
                        {{engagement.engagementName}}
                      </span>
                      <a target="_blank"
                        *ngIf="(isQualityUserLoggedIn == 'yes' || isINDUSStaffingManagerLoggedIn) && isloggedInUserAdmin != 'yes' && engagement.isUserMember == false &&
                        engagement.engagementStatus != 'Not started' && engagement.engagementStatus != 'Draft'"
                        class="link" (click)="navigateEngagement(engagement.engagementId)">
                        {{engagement.engagementName}}
                      </a>
                      <span *ngIf="(isQualityUserLoggedIn == 'yes' || isINDUSStaffingManagerLoggedIn) && isloggedInUserAdmin != 'yes' && engagement.isUserMember == false &&
                      (engagement.engagementStatus == 'Not started' || engagement.engagementStatus == 'Draft')">
                        {{engagement.engagementName}}
                      </span>
                    </td>
                    <td>
                      <a target="_blank" *ngIf="isloggedInUserAdmin == 'yes'" class="link"
                        (click)="navigateClient(engagement.clientId)">
                        {{engagement.clientName}}
                      </a>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && engagement.isUserMember == true"
                        class="link" (click)="navigateClient(engagement.clientId)">
                        {{engagement.clientName}}
                      </a>
                      <span *ngIf="isloggedInUserAdmin != 'yes' && engagement.isUserMember == false && isQualityUserLoggedIn != 'yes' " >
                        {{engagement.clientName}}
                      </span>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && engagement.isUserMember == false && isQualityUserLoggedIn == 'yes'"
                        class="link" (click)="navigateClient(engagement.clientId)">
                        {{engagement.clientName}}
                      </a>
                    </td>
                    <td class="hide-me">{{engagement.clientCode}}</td>
                    <td>{{engagement.solutionCode}}</td>
                    <td>{{engagement.eppmd}}</td>
                    <td>{{engagement.engagementStatus}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="no-data"
              *ngIf="(searchPageViewModel.engagementSearchResultList!=null || searchPageViewModel.engagementSearchResultList !== undefined) && TotalResultCount==0">
              <span> {{DataMessage}}</span>
            </div>
          </div>
          <!-- end Engagement Search ResultList -->
          <!-- start Assignment Search ResultList -->
          <div *ngIf="selectedSearchType == 'ASIGN'">
            <div *ngIf="searchPageViewModel.assignmentSearchResultList!=null &&
            searchPageViewModel.assignmentSearchResultList !== undefined">
              <table id="tblAssignmentResult" class="listViewDataTable">
                <thead>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{assignmentHeaderModelList[0].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of assignmentHeaderModelList[0].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(assignmentHeaderModelList[0].headerId)"
                            [ngClass]="{isDisabled:listCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc hide-me">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{assignmentHeaderModelList[1].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of assignmentHeaderModelList[1].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(assignmentHeaderModelList[1].headerId)"
                            [ngClass]="{isDisabled:listCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{assignmentHeaderModelList[2].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of assignmentHeaderModelList[2].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(assignmentHeaderModelList[2].headerId)"
                            [ngClass]="{isDisabled:listCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{assignmentHeaderModelList[3].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of assignmentHeaderModelList[3].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(assignmentHeaderModelList[3].headerId)"
                            [ngClass]="{isDisabled:listCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{assignmentHeaderModelList[4].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of assignmentHeaderModelList[4].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(assignmentHeaderModelList[4].headerId)"
                            [ngClass]="{isDisabled:listCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="table-sorted-desc">
                    <div class="row p-0 m-0 th-height">
                      <div class="col-md-10 p-0 m-0">
                        {{assignmentHeaderModelList[5].headerName}}
                      </div>
                      <div class="col-md-2 p-0 m-0 text-right">
                        <div *ngFor="let img of assignmentHeaderModelList[5].imageModelList">
                          <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                            (click)="HeaderSort(assignmentHeaderModelList[5].headerId)"
                            [ngClass]="{isDisabled:listCount == 0}">
                            <img src="{{img.imagePath}}" alt="Sorting" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </th>
                </thead>
                <tbody *ngIf="searchPageViewModel.assignmentSearchResultList.length>0">
                  <tr *ngFor="let assignment of searchPageViewModel.assignmentSearchResultList">
                    <td>{{assignment.assignmentName}}</td>
                    <td class="hide-me">{{assignment.assignmentCode}}</td>
                    <td>
                      <a target="_blank"
                        *ngIf="isloggedInUserAdmin == 'yes' && assignment.isUserMember == true "
                        class="link" (click)="navigateEngagement(assignment.engagementId)">
                        {{assignment.engagementName}}
                      </a>
                      <span
                      *ngIf="isloggedInUserAdmin == 'yes' && assignment.isUserMember == false && assignment.engagementStatus != 'Not started'"
                      class="link" (click)="navigateEngagement(assignment.engagementId)">
                      {{assignment.engagementName}}
                    </span>
                      <span
                        *ngIf="isloggedInUserAdmin == 'yes' && assignment.isUserMember == false && assignment.engagementStatus == 'Not started'"
                        >
                        {{assignment.engagementName}}
                      </span>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && assignment.isUserMember == true"
                        class="link" (click)="navigateEngagement(assignment.engagementId)">
                        {{assignment.engagementName}}
                      </a>
                      <span *ngIf="isloggedInUserAdmin != 'yes' && assignment.isUserMember == false && isQualityUserLoggedIn != 'yes' && !isINDUSStaffingManagerLoggedIn">
                        {{assignment.engagementName}}
                      </span>
                      <a target="_blank"
                        *ngIf="(isQualityUserLoggedIn == 'yes' || isINDUSStaffingManagerLoggedIn) && isloggedInUserAdmin != 'yes' && assignment.isUserMember == false &&
                        assignment.engagementStatus != 'Not started' && assignment.engagementStatus != 'Draft'"
                        class="link" (click)="navigateEngagement(assignment.engagementId)">
                        {{assignment.engagementName}}
                      </a>
                      <span *ngIf="(isQualityUserLoggedIn == 'yes' || isINDUSStaffingManagerLoggedIn) && isloggedInUserAdmin != 'yes' && assignment.isUserMember == false &&
                      (assignment.engagementStatus == 'Not started' || assignment.engagementStatus == 'Draft')">
                        {{assignment.engagementName}}
                      </span>
                    </td>
                    <td>
                      <a target="_blank" *ngIf="isloggedInUserAdmin == 'yes'" class="link"
                        (click)="navigateClient(assignment.clientId)">
                        {{assignment.clientName}}
                      </a>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && assignment.isUserMember == true"
                        class="link" (click)="navigateClient(assignment.clientId)">
                        {{assignment.clientName}}
                      </a>
                      <span *ngIf="isloggedInUserAdmin != 'yes' && assignment.isUserMember == false && isQualityUserLoggedIn != 'yes'">
                        {{assignment.clientName}}
                      </span>
                      <a target="_blank" *ngIf="isloggedInUserAdmin != 'yes' && assignment.isUserMember == false && isQualityUserLoggedIn == 'yes'"
                        class="link" (click)="navigateClient(assignment.clientId)">
                        {{assignment.clientName}}
                      </a>
                    </td>
                    <td>{{assignment.solutionCode}}</td>
                    <td>{{assignment.assignmentCodeStatus}}</td>
                  </tr>
                </tbody>

              </table>
            </div>
            <div class="no-data" *ngIf="(searchPageViewModel.assignmentSearchResultList!=null ||
            searchPageViewModel.assignmentSearchResultList !== undefined) && TotalResultCount==0">
                <span>  {{DataMessage}}</span>
              </div>
          </div>
       
          <!-- end Assignment Search ResultList -->
          <!-- ListView Pagination Start -->
          <div id="listviewPager" class="d-flex justify-content-center text-center pagination-section"
            *ngIf="TotalResultCount > 0 ">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
              <li>
                <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="listViewSetPage(1)">
                  <img src="../../../../assets/images/EDP/Engagement/first_arrow.png"  alt="First" />
                </a>
              </li>
              <li>
                <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
                  (click)="listViewSetPage(pager.currentPage - 1)">
                  <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png"  alt="Previous" />
                </a>

              </li>
              <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a (click)="listViewSetPage(page)">{{page}}</a>
              </li>

              <li>
                <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                  (click)="listViewSetPage(pager.currentPage + 1)">
                  <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="Next" />
                </a>
              </li>
              <li>
                <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                  (click)="listViewSetPage(pager.totalPages)">
                  <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="Last" />
                </a>
              </li>

            </ul>
            <div>
              <select id="drprecordsPerPage" name="drprecordsPerPage" (change)="listViewSetPage(1)" class="page-counts">
                <option *ngFor="let rcdcount of noOfrecordsPerPageList" value="{{rcdcount}}">{{rcdcount}}</option>
              </select>
            </div>

          </div>
          <!-- ListView Pagination End -->
        </div>
      </div>
    </div>

    <!-- search-list End-->
  </div> 
</div>
<div *ngIf="isMemberFirm" class="alert-danger col-md-12">
  <span class="msg-icon">
    <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="Access Error">
  </span>
  <span class="d-inline-block align-middle msg-text">
    You do not have access to view details !
  </span>
</div>

import { DatePipe } from '@angular/common';
import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';

import { EngagementArchivalInfoViewModel } from 'src/app/model/Engagement/EngagementArchivalInfoViewModel';
import { UpdateEngagementCloseoutConfigurationModel } from 'src/app/model/Engagement/Engagements';
import { EngagementTeamMember, RFCMember } from 'src/app/model/Engagement/RFCMember';
import { ApproveDenyExtensionModal, ApproveDenyExtensionViewModal, EngagementDueDateExtension } from 'src/app/model/Engagement/EngagementDueDateExtension';
import { CloseoutSections, EngagementCloseoutConfiguration } from 'src/app/model/EngagementListModel';
import { EngagementService } from 'src/app/services/EngagementService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { EngagementDuedateExtensionComponent } from '../engagement-duedate-extension/engagement-duedate-extension.component';
import { EngageEvent } from 'src/app/model/Engagement/EngageEvent';
declare var $;
@Component({
  selector: 'app-engagement-closeout-archival',
  templateUrl: './engagement-closeout-archival.component.html',
  styleUrls: ['./engagement-closeout-archival.component.css']
})
export class EngagementCloseoutArchivalComponent implements OnInit {

  engagementArchivalInfoViewModel: EngagementArchivalInfoViewModel = new EngagementArchivalInfoViewModel();
  isImageEnabled: boolean = false;
  currentState: string = 'collapse';
  showRequiredWarning: boolean = false;
  @Input() sectionConfiguration: EngagementCloseoutConfiguration;
  @Input() solutionCodeId: number;
  isEditRFC: boolean;
  teamMembers: EngagementTeamMember[];
  selectedTeamMember: EngagementTeamMember;
  engagementDueDateExtensions: EngagementDueDateExtension[];
  engagementDueDateExtensionsHistory: EngagementDueDateExtension[];
  engagementDueDateExtensionsApprDenyList: EngagementDueDateExtension[];
  engagementDueDateExtensionPendingModel: EngagementDueDateExtension;
  approveDenyExtensionModal: ApproveDenyExtensionModal;
  approveDenyExtensionViewModal: ApproveDenyExtensionViewModal;
  fileCloseOutEngagementId: number = 0;
  isValidRFC: boolean;
  userObject: any;
  engagementDueDateExtensionComponentRef: any;
  isExtensionHistory: boolean;
  originalCloseoutDueDate: Date;
  engagementDueDateExtensionsApproveDenyList: EngagementDueDateExtension[] = [];
  @Input() isEngagementClosed: boolean;
  @Input() isloggedInUserPSD: boolean;
  @Input() isloggedInUserAdmin: string = 'no';
  @Input() engagementPartner: string;
  isloggedInUserEngagementMember: boolean;
  isDenyReasonInValid: boolean = false;
  @Input() set engagementId(engagementId: number) {
    if (engagementId != undefined && engagementId != null && engagementId > 0) {
      this.fileCloseOutEngagementId = engagementId;
      this.getEngagementArchivalInformation();
      console.log("selectEngagementId : ", this.fileCloseOutEngagementId);
      this.getEngagementTeamMember(this.fileCloseOutEngagementId);
    }
  }
  @Output() collapseEvent: EventEmitter<string> = new EventEmitter<string>();
  constructor(private engService: EngagementService,
    public datepipe: DatePipe, private loadingService: LoadingScreenService,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver) {
    this.isEditRFC = false;
    this.isValidRFC = true;
  }

  ngOnInit(): void {
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    this.getEngagementDueDateExtensions(this.fileCloseOutEngagementId);
    this.initializeApproveDenyExtensionModel();
    if (this.sectionConfiguration.isDefaultOpen) {
      this.toggleDown();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      switch (propName) {
        case 'isEngagementClosed':
          this.isEngagementClosed = change.currentValue;
          break;
        case 'isloggedInUserPSD':
          this.isloggedInUserPSD = change.currentValue;
          break;
        case 'isloggedInUserAdmin':
          this.isloggedInUserAdmin = JSON.stringify(change.currentValue);
          break;
        default:
          break;
      }
    }
    console.log('isEngagementClosed', this.isEngagementClosed);
    console.log('isloggedInUserPSD', this.isloggedInUserPSD);
    console.log('isloggedInUserAdmin', this.isloggedInUserAdmin);
  }

  initializeApproveDenyExtensionModel() {
    this.approveDenyExtensionViewModal = new ApproveDenyExtensionViewModal();
    this.approveDenyExtensionModal = new ApproveDenyExtensionModal();
    this.approveDenyExtensionModal.engagementId = this.fileCloseOutEngagementId;
    this.approveDenyExtensionModal.approveDenyByName = this.userObject.loggedInUserFullName;
    this.approveDenyExtensionModal.approveDenyByEmail = this.userObject.loggedInUserEmail;
    this.approveDenyExtensionModal.status = '';
    this.approveDenyExtensionModal.denyReason = '';
    this.approveDenyExtensionViewModal.approveDenyExtensionModal = new ApproveDenyExtensionModal();
    this.approveDenyExtensionViewModal.approveDenyExtensionModal = this.approveDenyExtensionModal;
  }

  getEngagementArchivalInformation() {
    this.engService.GetEngagementArchivalInformation(this.fileCloseOutEngagementId).subscribe(
      result => {
        this.engagementArchivalInfoViewModel = result;
        console.log('getEngagementArchivalInformation ', result);
      },
      error => {
        this.engagementArchivalInfoViewModel = null;
      })
  }

  toggleUp(flag: boolean) {
    this.isImageEnabled = true;
    this.isExtensionHistory = false;
    this.CancelRFC();
    this.getEngagementTeamMember(this.fileCloseOutEngagementId);
    this.getEngagementCloseoutConfigurations();
    this.getEngagementDueDateExtensions(this.fileCloseOutEngagementId);
    if (!flag) {
      if (this.fileCloseOutEngagementId != undefined && this.fileCloseOutEngagementId != null && this.fileCloseOutEngagementId > 0) {
        this.getEngagementCloseoutConfigurations();
        console.log("selectEngagementId : ", this.fileCloseOutEngagementId);
        this.collapseEvent.emit(this.currentState);
      }
      this.currentState = 'expand';
    }
    this.currentState = 'expand';
    $('#collapseOne').show();
  }

  toggleUpDown(flag: boolean) {
    this.isImageEnabled = false;
    this.currentState = 'collapse';
    $('#collapseOne').hide();
    this.getEngagementDueDateExtensions(this.fileCloseOutEngagementId);
  }

  toggleDown() {
    this.isImageEnabled = false;
    this.currentState = 'collapse';
    $('#collapseOne').hide();
    this.getEngagementCloseoutConfigurations();
  }

  CancelRFC() {
    this.isEditRFC = false;
  }

  changeRFC() {
    let member = new RFCMember();
    member.engagementId = this.fileCloseOutEngagementId;
    member.memberName = this.selectedTeamMember.memberName;
    member.memberEmailId = this.selectedTeamMember.memberEmailId;
    member.loggedInUser = this.userObject.loggedInUserEmail;
    member.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engService.UpdateRFC(member).subscribe(
      result => {
        console.log(result);
      },
      error => {
        console.error(error);
      });

    this.updateEngagementCloseoutConfigurations();
    this.isEditRFC = false;
  }

  editRFC() {
    this.isEditRFC = true;
    if (this.engagementArchivalInfoViewModel.rfcEmailId != null) {
      this.selectedTeamMember = this.teamMembers.filter(x => x.memberEmailId == this.engagementArchivalInfoViewModel.rfcEmailId)[0];
    }
  }

  getEngagementDueDateExtensions(engagementId: number) {
    this.engService.GetEngagementDueDateExtensions(engagementId).subscribe(
      result => {
        this.engagementDueDateExtensions = result;
        console.log('engagementDueDateExtensions ', this.engagementDueDateExtensions);
        if (this.engagementDueDateExtensions != undefined && this.engagementDueDateExtensions.length > 0) {
          this.engagementDueDateExtensionPendingModel =
            this.engagementDueDateExtensions.find(ext => ext.isActive && ext.status == 'Pending');
          this.engagementDueDateExtensionsHistory = this.engagementDueDateExtensions.filter(ext => ext.status == 'Approve' || ext.status == 'Deny');
          console.log('engagementDueDateExtensionsHistory ', this.engagementDueDateExtensionsHistory);
          console.log('engagementDueDateExtensionPendingModel ', this.engagementDueDateExtensionPendingModel);
          this.originalCloseoutDueDate = this.engagementDueDateExtensions.find(ext => ext.status == 'Original').extensionDueDate;
        }
      });
    this.isExtensionHistory = false;
  }

  getEngagementTeamMember(engagementId: number) {
    this.engService.GetCollaborationTeamMembers(engagementId).subscribe(
      result => {
        this.teamMembers = result;
        console.log('getEngagementTeamMember ', this.teamMembers);
        this.CheckUserMembership();
      });
  }

  getEngagementCloseoutConfigurations() {
    this.engService.GetEngagementCloseoutConfigurations(this.fileCloseOutEngagementId).subscribe(engagementCloseoutConfigurations => {
      if (engagementCloseoutConfigurations && engagementCloseoutConfigurations.length > 0) {
        this.sectionConfiguration = engagementCloseoutConfigurations.find(section =>
          section.code === CloseoutSections.FAI);
        //this.showRequiredWarning = !this.sectionConfiguration.isCompleted;  
        if (this.sectionConfiguration.isCompleted) {
          this.showRequiredWarning = false;
        }
        this.getEngagementArchivalInformation();
      }
    },
      err => {
        console.error('getEngagementCloseoutConfigurations ', err);
      });
  }

  updateEngagementCloseoutConfigurations() {
    let isCompleted: boolean = false;

    if (this.engagementArchivalInfoViewModel.closeoutDueDate != null
      && this.engagementArchivalInfoViewModel.rfcEmailId != null) {
      isCompleted = true;
    }

    this.loadingService.startLoading();
    let updateEngCloseoutConfigModel: UpdateEngagementCloseoutConfigurationModel = new UpdateEngagementCloseoutConfigurationModel();
    updateEngCloseoutConfigModel.engagementId = this.fileCloseOutEngagementId;
    updateEngCloseoutConfigModel.isCompleted = isCompleted;
    updateEngCloseoutConfigModel.sectionShortName = this.sectionConfiguration.code;
    updateEngCloseoutConfigModel.loggedInUser = this.userObject.loggedInUserEmail;
    updateEngCloseoutConfigModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engService.UpdateEngagementCloseoutConfigurations(updateEngCloseoutConfigModel).subscribe(
      updateEngResult => {
        if (updateEngResult === 'Success') {
          console.log('updateEngagementCloseoutConfigurations save successfully');
          this.getEngagementCloseoutConfigurations();
          this.loadingService.stopLoading();
        }
      },
      error => {
        this.loadingService.stopLoading();
        const errorMsg = 'Error while updateEngagementCloseoutConfigurations for section : ' + this.sectionConfiguration.value;
        console.error(errorMsg, error);
        //this.logService.log(errorMsg);
      });
  }
  engagementDueDateExtensionHistory() {
    this.isExtensionHistory = true;
    $('#engagementDueDateExtensionPendingModelDiv').hide();
    this.getEngagementDueDateExtensionsHistory(this.fileCloseOutEngagementId);
  }

  closeHistory() {
    $('#engagementDueDateExtensionPendingModelDiv').show();
    this.isExtensionHistory = false;
  }

  getEngagementDueDateExtensionsHistory(engagementId: number) {

    this.engService.GetEngagementDueDateExtensions(engagementId).subscribe(
      result => {
        this.engagementDueDateExtensions = result;

        this.engagementDueDateExtensionsApproveDenyList =
          this.engagementDueDateExtensions.filter(ext => ext.status == 'Approve' || ext.status == 'Deny');

        console.log('engagementDueDateExtensionsApproveDenyList', this.engagementDueDateExtensionsApproveDenyList);
      });
  }

  async DisplayEngagementDueDateExtension() {
    this.loadingService.startLoading();
    setTimeout(() => {
      const componentFactorya = this.cfr.resolveComponentFactory(EngagementDuedateExtensionComponent);
      this.engagementDueDateExtensionComponentRef = this.viewContainerRef.createComponent(componentFactorya);
      this.engagementDueDateExtensionComponentRef.instance.engagementId = this.fileCloseOutEngagementId;
      this.engagementDueDateExtensionComponentRef.instance.countOfExtensions = this.engagementDueDateExtensions.length;
      this.engagementDueDateExtensionComponentRef.instance.closeoutDueDateOriginal = this.engagementArchivalInfoViewModel.closeoutDueDate;
      this.engagementDueDateExtensionComponentRef.instance.solutionCodeId = this.solutionCodeId;


      this.engagementDueDateExtensionComponentRef.instance.showEngagementDuedateExtensionComponentModal();
      this.loadingService.stopLoading();

      this.engagementDueDateExtensionComponentRef.instance.closeEngagementDuedateExtensionComponent.subscribe(isTrue => {
        this.loadingService.stopLoading();
        this.viewContainerRef.clear();
        this.getEngagementDueDateExtensions(this.fileCloseOutEngagementId);
        this.getEngagementCloseoutConfigurations();
      });
    }, 1000);
  }



  showHideDenyExtensionDiv(flag: boolean) {
    const div = 'extensionDenyDiv';
    const span = 'approveDenyLinkSpan';

    if (flag) {
      if ($('#' + div).is(':hidden')) {
        $('#' + div).show();
      }
      if ($('#' + span).is(':visible')) {
        $('#' + span).hide();
      }

    } else {
      if ($('#' + div).is(':visible')) {
        $('#' + div).hide();
      }
      if ($('#' + span).is(':hidden')) {
        $('#' + span).show();
      }
    }
    this.approveDenyExtensionViewModal.approveDenyExtensionModal.denyReason = '';
  }
  cancelDenyExtension() {
    this.approveDenyExtensionViewModal.isValidDenyReason = true;
    this.approveDenyExtensionViewModal.approveDenyExtensionModal.denyReason = '';
    this.showHideDenyExtensionDiv(false);
  }

  denyExtension() {
    this.approveDenyExtensionModal.status = 'Deny';
    this.showHideDenyExtensionDiv(true);
  }

  approveExtension() {
    this.approveDenyExtensionModal.status = 'Approve';
    this.approveDenyExtensionViewModal.approveDenyExtensionModal.denyReason = null;
    this.updateExtensionDueDateRequest();
  }

  denyExtensionClick() {
    if (this.approveDenyExtensionViewModal.approveDenyExtensionModal.denyReason.length == 0) {
      this.approveDenyExtensionViewModal.isValidDenyReason = false;
    } else {
      this.approveDenyExtensionViewModal.isValidDenyReason = true;
    }

    if (this.approveDenyExtensionViewModal.isValidDenyReason) {
      this.updateExtensionDueDateRequest();
    }
  }

  updateExtensionDueDateRequest() {
    this.loadingService.startLoading();
    this.isDenyReasonInValid = false;
    this.approveDenyExtensionModal.extentionId = this.engagementDueDateExtensionPendingModel.extentionId;
    this.approveDenyExtensionModal.engagementId = this.fileCloseOutEngagementId;
    this.approveDenyExtensionModal.approveDenyByName = this.userObject.loggedInUserFullName;
    this.approveDenyExtensionModal.approveDenyByEmail = this.userObject.loggedInUserEmail;
    this.approveDenyExtensionModal.denyReason = this.approveDenyExtensionViewModal.approveDenyExtensionModal.denyReason;
    if (this.approveDenyExtensionModal.status == 'Approve' || (this.approveDenyExtensionModal.status == 'Deny' && this.approveDenyExtensionModal.denyReason.trim())) {
      this.isDenyReasonInValid = false;
      this.engService.UpdateExtensionDueDateRequest(this.approveDenyExtensionModal).subscribe(
        updateExtensionModalResult => {
          if (updateExtensionModalResult === 'Success') {
            this.showHideDenyExtensionDiv(false);
            this.getEngagementCloseoutConfigurations();
            this.getEngagementDueDateExtensions(this.fileCloseOutEngagementId);
            this.getEngagementDueDateExtensionsHistory(this.fileCloseOutEngagementId);
            this.loadingService.stopLoading();
          } else {
            this.loadingService.stopLoading();

          }
        },
        error => {
          this.loadingService.stopLoading();
          const errorMsg = 'Error while UpdateExtensionDueDateRequest : ' + this.approveDenyExtensionModal;
          console.error(errorMsg, error);
        });
    }
    else {
      this.isDenyReasonInValid = true;
      this.loadingService.stopLoading();

    }
  }

  CheckUserMembership() {
    const localUserName = this.userObject.userName.toLowerCase();
    this.isloggedInUserEngagementMember = false;
    if (this.engagementPartner != undefined &&
      this.engagementPartner.toLowerCase() === localUserName) {
      this.isloggedInUserEngagementMember = true;
    } else if (this.teamMembers != null
      && this.teamMembers.length > 0) {
      console.log('teamMembers ===> ', this.teamMembers);
      for (let i = 0; i < this.teamMembers.length; i++) {
        if (this.teamMembers[i].memberEmailId.toLowerCase() === localUserName) {
          this.isloggedInUserEngagementMember = true;
          break;
        }
      }
    }
  }
}

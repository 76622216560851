<div class="modal fade" id="engagement-duedate-extension" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
  <div class="modal-dialog" role="extension">
    <div class="modal-content ">
      <div class="message-dialog-wrapper">
        <div class="message-dialog-container">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="closeEngagementDuedateExtensionComponentModal()">
            <span style="font-size: 15px;vertical-align: middle;margin-right: 3px;">Close</span>
            <span style="vertical-align: top;" aria-hidden="true">&times;</span>
          </button>
          <div class="d-flex justify-content-center mt-3">
            <div class="message-background-icon information">
              <img src="../../../../assets/images/EDP/MessageDialog/information.svg" class="message-icon"
                alt="information" />
            </div>
          </div>
          <div class="d-flex text-left message-title information ml-2 pl-2 pt-2">Would you like to extend the closeout
            of collaboration spaces?</div>
          <div class="message-description desc-height">
            <label class="custom-checkbox chkBox" for="isEPPMDApprovedExtension">
              <input type="checkbox" id="isEPPMDApprovedExtension" name="isEPPMDApprovedExtension"
                value="{{isEPPMDApprovedExtension}}" [(ngModel)]="isEPPMDApprovedExtension">
              <span class="geekmark"></span>
              <span class="ml-3">I have recieved EPPMD approval for this extension. <span
                class="mandatory">*</span></span>
            </label>
            <!-- <label for="isEPPMDApprovedExtension" class="edp-font-12 mb-0">I have recieved EPPMD approval for this
              extension.</label> -->

            <div class="extension-date mt-3">
              <label for="EstimatedGM">Select new date for closeout of collaboration spaces <span
                  class="mandatory">*</span></label>
              <app-calendercontrol id="ExtensionDate" [txtDate]="ExtensionDate" class="eng-extension-date">
              </app-calendercontrol>

            </div>

            <div class="messsage-extension-reason mt-3">
              <label>Please enter the rationale for needing an extension. <span
                class="mandatory">*</span></label>
              <textarea class="form-control edp-font-14" cols="10" rows="4" maxlength="2000" style="resize:none;border-radius: 0px;"
                id="btnDeclineInputReason msg-dialog-extensionReason"
                [(ngModel)]="extensionDueDateModal.extensionReason"></textarea>
              <!-- <input type="text" id="btnDeclineInputReason msg-dialog-extensionReason"
                class="form-control txtbox-height" [(ngModel)]="extensionDueDateModal.extensionReason"> -->
            </div>
            <div>
              <span class="error-color" id="error-extension-details" *ngIf="isInvalidValuesPresent">
                {{errorDescription}}
              </span>
            </div>

            <div class="mt-3 text-right">
              <button (click)="closeEngagementDuedateExtensionComponentModal()"
                class="btn SecondaryButton rounded-0 information CancelBtn mr-3">
                <span class="d-inline-block mr-1 align-top">
                  <img src="../../../../assets/images/EDP/MessageDialog/cancel-information-24px.svg" alt="CANCEL" />
                </span>
                <span class="d-inline-block align-middle">Cancel</span>
              </button>
              <button (click)="SendExtensionRequest()" class="btn rounded-0 information">
                <span class="d-inline-block mr-1 align-top">
                  <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
                </span>
                <span class="d-inline-block align-middle">Request extension</span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { SearchPageService } from '../../../services/searchPageService';
import { KeyValue } from '../../../model/KeyValue';
import { SearchPageViewModel, EnumSearchTypes } from 'src/app/model/SearchPage/SearchPageViewModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ImageModel, HeaderModel } from 'src/app/model/EngagementListModel';
import { PagerService } from 'src/app/services/pager.service';
import { EngagementService } from 'src/app/services/EngagementService';
import { Engagements, EngagementStatus } from 'src/app/model/Engagement/Engagements';
import { DataService } from 'src/app/services/data.service';
import { RoleNames } from 'src/app/model/Role';
declare var $;

@Component({
  selector: 'app-search-list-view',
  templateUrl: './search-list-view.component.html',
  styleUrls: ['./search-list-view.component.css'],
  providers: [SearchPageService]
})
export class SearchListViewComponent implements OnInit, OnDestroy {
  userObject: any;
  userName: string;
  serviceLines: KeyValue[] = [];
  searchTypes: KeyValue[] = [];
  selectedServiceLine: string = 'ALL';
  selectedSearchType: string = 'CL';
  selectedSearchTypeName: string = "Clients";
  isSearchFilter = false;
  searchTerm: string = '';
  searchPageViewModel: SearchPageViewModel = new SearchPageViewModel();
  selClientName: string = null; selEngagementName: string = null; selAssignmentName: string = null;
  selClientProgramId: string = null;
  selClientStatusId: string = null; selSolutionCodeId: string = null;
  selEngagementStatus: string = null; selAssignmentCodeStatus: string = null;
  selEPPMD: string = null;
  selectedEngagement: Engagements = new Engagements();
  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  clientHeaderModelList: HeaderModel[] = [];
  engagementHeaderModelList: HeaderModel[] = [];
  assignmentHeaderModelList: HeaderModel[] = [];
  listViewnoOfrecordsPerPage: number = 20;
  listViewCurrentPageNumber: number = 1;
  noOfrecordsPerPageList: number[];
  isloggedInUserAdmin: string;
  orderByColumn: string;
  orderByType: string;
  // -- Pager service -- */
  // array of all items to be paged
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  TotalResultCount = 0;
  DataMessage: string;
  // -- Pager service End-- */
  alert: Alert;
  GlobalSearchEventSubscription: Subscription = null;
  NavSearchObj: any;
  isQualityUserLoggedIn: string;
  isINDUSStaffingManagerLoggedIn : boolean = false;
  isMemberFirm: boolean = false;
  constructor(private searchPageService: SearchPageService, private _alertService: AlertsService,
    private datepipe: DatePipe, private loadingScreenService: LoadingScreenService,
    private engService: EngagementService,
    private pagerService: PagerService, private engagementService: EngagementService
    , private route: Router, private dataService: DataService) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    let nameJSON = JSON.parse(localStorage.getItem("currentUser"));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
    }
    // this.DataMessage = 'Loading...';
    this.GlobalSearchEventSubscription = this.dataService.getClickGlobalSearchEvent().subscribe(resultObj => {
      console.log('this.GlobalSearchEventsubscription> ', this.GlobalSearchEventSubscription);
      console.log('resultObj => ', resultObj);
      this.NavSearchObj = resultObj;
      if (this.NavSearchObj != null && this.NavSearchObj != undefined) {
        this.selectedServiceLine = this.NavSearchObj.ServiceLine;
        this.selectedSearchType = this.NavSearchObj.SearchType;
        this.searchTerm = this.NavSearchObj.SearchTerm;
      }
      this.changeSearchType(this.NavSearchObj.SearchType);
      this.Search();
    });
  }

  ngOnInit() {
    this._alertService.remove(this.alert);
    this.setPageRecordRange();

    this.IsLoggedinUserSystemAdmin();
    this.GetServiceLines();
    this.ResetFilters();
    this.setDefaultInitialization();
    this.setIsMemberFirm();
  } // end ngOnInit
  ngOnDestroy() {
    this.GlobalSearchEventSubscription.unsubscribe();
  }
  GetServiceLines() {
    this.searchPageService.GetServiceLines().subscribe(services => {
      this.serviceLines = services;
      this.searchPageService.GetSearchTypes().subscribe(stypes => {
        this.searchTypes = stypes;
        this.setGlobalSearchValues();
      });
    });
  }

  GetSearchTypes() {
    this.searchPageService.GetSearchTypes().subscribe(stypes => {
      this.searchTypes = stypes;
      this.setGlobalSearchValues();
    });
  }
  setGlobalSearchValues() {
    this.selectedServiceLine = $('#ddGServiceLine').val();
    this.selectedSearchType = $('#ddGSearchTypesDropdown').val();
    this.searchTerm = $('#txtGlobalSearch').val();
    this.LoadListViewHeaders();
    this.setDefaultInitialization();
    this.GetSearchPageResultsCount();
  }

  GetSearchPageResults() {
    this.loadingScreenService.startLoading();
    
    if (!this.isSearchFilter) {
      this.searchPageViewModel.clientSearchResultList = [];
      this.searchPageViewModel.engagementSearchResultList = [];
      this.searchPageViewModel.assignmentSearchResultList = [];
      this.searchPageViewModel.sIndustryTypeList = [];
      this.searchPageViewModel.sClientProgramList = [];
      this.searchPageViewModel.sStrucTypeList = [];
      this.searchPageViewModel.sClientStatusList = [];
      this.searchPageViewModel.sClientNameList = [];
      this.searchPageViewModel.sEngagementNameList = [];
      this.searchPageViewModel.sEngagementStatusList = [];
      this.searchPageViewModel.sAssignmentNameList = [];
      this.searchPageViewModel.sAssignmentCodeStatusList = [];
      this.searchPageViewModel.seppmdList = [];
      this.searchPageViewModel.scmseppmdList = [];
      this.searchPageViewModel.sSolutionCodeList = [];
    }
    // if (this.searchTerm !== '') {
    this.searchPageService.GetSearchPageResults(this.selectedServiceLine, this.selectedSearchType,
      this.searchTerm?.trim(), this.selClientProgramId, this.selClientStatusId,
      this.selClientName, this.selEngagementName, this.selAssignmentName, this.selSolutionCodeId,
      this.selEngagementStatus, this.selAssignmentCodeStatus, this.selEPPMD,
      this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage, this.isloggedInUserAdmin,
      this.orderByColumn, this.orderByType)
      .subscribe(result => {
        this.loadingScreenService.stopLoading();
        if (!this.isSearchFilter) {
          this.searchPageViewModel = result;
        } else {
          this.searchPageViewModel.clientSearchResultList = result.clientSearchResultList;
          this.searchPageViewModel.engagementSearchResultList = result.engagementSearchResultList;
          this.searchPageViewModel.assignmentSearchResultList = result.assignmentSearchResultList;
        }
        $('#resultsLabel').removeClass('d-none');
        console.log('SearchPageViewModel => ', this.searchPageViewModel);
        this.setInitialsForListView();
      }, () => {
        this.loadingScreenService.stopLoading();
        this.DataMessage = 'No Data Available';
      });
    // } else {
    //   // search term is empty
    //   this.ResetFilters();
    //   this.loadingScreenService.stopLoading();
    //   this.DataMessage = ' No Data Available';
    //   $('#errorLabelSearchTerm').removeClass('d-none');
    // }

  }
  
  GetSearchPageResultsCount() {
    let CLType = false;
    let ENGType = false;
    let ASIGNType = false;
    if (this.selectedSearchType === EnumSearchTypes.CL) {
      CLType = true;
    } else if (this.selectedSearchType === EnumSearchTypes.ENG) {
      ENGType = true;
    } else if (this.selectedSearchType === EnumSearchTypes.ASIGN) {
      ASIGNType = true;
    }
    this.loadingScreenService.startLoading();
    // if (this.searchTerm !== '') {
    this.searchPageService.GetSearchPageResultsCount(this.selectedServiceLine, this.selectedSearchType, this.searchTerm?.trim(),
      this.selClientProgramId, this.selClientStatusId,
      this.selClientName, this.selEngagementName, this.selAssignmentName, this.selSolutionCodeId,
      this.selEngagementStatus, this.selAssignmentCodeStatus, this.selEPPMD, this.isloggedInUserAdmin)
      .subscribe(resultRecordCount => {
        this.loadingScreenService.stopLoading();
        if (resultRecordCount > 0) {
          $('#listviewPager').removeAttr('style');
          this.TotalResultCount = resultRecordCount;
          this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
        } else {
          this.TotalResultCount = 0;
          this.DataMessage = ' No Data Available';
          if (CLType) {
            this.searchPageViewModel.clientSearchResultList = [];
          } else if (ENGType) {
            this.searchPageViewModel.engagementSearchResultList = [];
          } else if (ASIGNType) {
            this.searchPageViewModel.assignmentSearchResultList = [];
          }

        }
      }, () => {
        this.loadingScreenService.stopLoading();
        this.DataMessage = ' No Data Available';
      });
    // } else {
    //   // search term is empty
    //   this.ResetFilters();
    //   this.loadingScreenService.stopLoading();
    //   this.DataMessage = ' No Data Available';
    //   $('#errorLabelSearchTerm').removeClass('d-none');
    // }
  }
  Search() {
    let searchKeyword = $('#txtSearchTerm').val();
    if (searchKeyword != '' && searchKeyword != undefined && searchKeyword != this.searchTerm) {
      this.searchTerm = searchKeyword;
    }
   
    if (this.selectedServiceLine !== '' && this.selectedSearchType !== '') {
      // $('#errorLabelSearchTerm').addClass('d-none');
      this.isSearchFilter = false;
      this.listViewCurrentPageNumber = 1;
      this.ResetFilters();
      this.GetSearchPageResultsCount();
    }
    // else {
    //   $('#errorLabelSearchTerm').removeClass('d-none');
    //   $('#resultsLabel').addClass('d-none');
    //   this.ResetFilters();
    // }

  }
  changeSearchType(selSearchType: string) {
    console.log(selSearchType);

    $('#resultsLabel').addClass('d-none');
    $('#errorLabelSearchTerm').addClass('d-none');
    $('#listviewPager').attr('style', 'display:none !important;');
    this.ResetFilters();
    this.setDefaultInitialization();
    this.LoadListViewHeaders();
  }
  SearchWithFilters(filterValue: any, filterType: string, event: any) {
    this.isSearchFilter = true;
    this.listViewCurrentPageNumber = 1;
    if (filterType === 'clientName') {
      this.selClientName = null;
      this.searchPageViewModel.sClientNameList.forEach(item => {
        if (item.isChecked) {
          if (this.selClientName == null) {
            this.selClientName = item.clientName.trim() + ';';
          } else {
            this.selClientName = this.selClientName + item.clientName.trim() + ';';
          }
        }
      });
      if (this.selClientName != null && this.selClientName.includes(';')) {
        this.selClientName = this.selClientName.slice(0, -1);
      }
      console.log(this.selClientName);

    }
    if (filterType === 'engagementName') {
      this.selEngagementName = null;
      this.searchPageViewModel.sEngagementNameList.forEach(item => {
        if (item.isChecked) {
          if (this.selEngagementName == null) {
            this.selEngagementName = item.engagementName.trim() + ';';
          } else {
            this.selEngagementName = this.selEngagementName + item.engagementName.trim() + ';';
          }
        }
      });
      if (this.selEngagementName != null && this.selEngagementName.includes(';')) {
        this.selEngagementName = this.selEngagementName.slice(0, -1);
      }
      console.log(this.selEngagementName);

    }
    if (filterType === 'assignmentName') {
      this.selAssignmentName = null;
      this.searchPageViewModel.sAssignmentNameList.forEach(item => {
        if (item.isChecked) {
          if (this.selAssignmentName == null) {
            this.selAssignmentName = item.assignmentName.trim() + ';';
          } else {
            this.selAssignmentName = this.selAssignmentName + item.assignmentName.trim() + ';';
          }
        }
      });
      if (this.selAssignmentName != null && this.selAssignmentName.includes(';')) {
        this.selAssignmentName = this.selAssignmentName.slice(0, -1);
      }
      console.log(this.selEngagementName);
    }

    if (filterType === 'clientProgramId') {
      this.selClientProgramId = null;
      this.searchPageViewModel.sClientProgramList.forEach(item => {
        if (item.isChecked) {
          if (this.selClientProgramId == null) {
            this.selClientProgramId = item.clientProgramId.toString().trim() + ',';
          } else {
            this.selClientProgramId = this.selClientProgramId + item.clientProgramId.toString().trim() + ',';
          }
        }
      });
      if (this.selClientProgramId != null && this.selClientProgramId.includes(',')) {
        this.selClientProgramId = this.selClientProgramId.slice(0, -1);
      }
      console.log(this.selClientProgramId);
    }

    if (filterType === 'clientStatusId') {
      this.selClientStatusId = null;
      this.searchPageViewModel.sClientStatusList.forEach(item => {
        if (item.isChecked) {
          if (this.selClientStatusId == null) {
            this.selClientStatusId = item.clientStatusId.toString().trim() + ',';
          } else {
            this.selClientStatusId = this.selClientStatusId + item.clientStatusId.toString().trim() + ',';
          }
        }
      });
      if (this.selClientStatusId != null && this.selClientStatusId.includes(',')) {
        this.selClientStatusId = this.selClientStatusId.slice(0, -1);
      }
      console.log(this.selClientStatusId);
    }
    if (filterType === 'solutionCodeId') {
      this.selSolutionCodeId = null;
      this.searchPageViewModel.sSolutionCodeList.forEach(item => {
        if (item.isChecked) {
          if (this.selSolutionCodeId == null) {
            this.selSolutionCodeId = item.solutionCode.trim() + ',';
          } else {
            this.selSolutionCodeId = this.selSolutionCodeId + item.solutionCode.toString().trim() + ',';
          }
        }
      });
      if (this.selSolutionCodeId != null && this.selSolutionCodeId.includes(',')) {
        this.selSolutionCodeId = this.selSolutionCodeId.slice(0, -1);
      }
      console.log(this.selSolutionCodeId);
    }
    if (filterType === 'engagementStatus') {
      this.selEngagementStatus = null;
      this.searchPageViewModel.sEngagementStatusList.forEach(item => {
        if (item.isChecked) {
          if (this.selEngagementStatus == null) {
            this.selEngagementStatus = item.engagementStatus.trim() + ',';
          } else {
            this.selEngagementStatus = this.selEngagementStatus + item.engagementStatus.trim() + ',';
          }
        }
      });
      if (this.selEngagementStatus != null && this.selEngagementStatus.includes(',')) {
        this.selEngagementStatus = this.selEngagementStatus.slice(0, -1);
      }
      console.log(this.selEngagementStatus);
    }
    if (filterType === 'assignmentCodeStatus') {
      this.selAssignmentCodeStatus = null;
      this.searchPageViewModel.sAssignmentCodeStatusList.forEach(item => {
        if (item.isChecked) {
          if (this.selAssignmentCodeStatus == null) {
            this.selAssignmentCodeStatus = item.assignmentCodeStatus.trim() + ',';
          } else {
            this.selAssignmentCodeStatus = this.selAssignmentCodeStatus + item.assignmentCodeStatus.trim() + ',';
          }
        }
      });
      if (this.selAssignmentCodeStatus != null && this.selAssignmentCodeStatus.includes(',')) {
        this.selAssignmentCodeStatus = this.selAssignmentCodeStatus.slice(0, -1);
      }
      console.log(this.selEngagementStatus);
    }
    if (filterType === 'eppmd') {
      this.selEPPMD = null;
      this.searchPageViewModel.seppmdList.forEach(item => {
        if (item.isChecked) {
          if (this.selEPPMD == null) {
            this.selEPPMD = item.eppmd.trim() + ';';
          } else {
            this.selEPPMD = this.selEPPMD + item.eppmd.trim() + ';';
          }
        }
      });
      if (this.selEPPMD != null && this.selEPPMD.includes(';')) {
        this.selEPPMD = this.selEPPMD.slice(0, -1);
      }
      console.log(this.selEPPMD);
    }

    if (filterValue !== null || filterValue !== undefined || filterValue !== '' || filterValue > 0) {
      this.GetSearchPageResultsCount();
    }
  }
  ResetFilters() {
    this.selClientName = null; this.selEngagementName = null; this.selAssignmentName = null;
    this.selClientProgramId = null;
    this.selClientStatusId = null; this.selSolutionCodeId = null;
    this.selEngagementStatus = null; this.selAssignmentCodeStatus = null;
    this.selEPPMD = null;
    this.searchPageViewModel.clientSearchResultList = [];
    this.searchPageViewModel.engagementSearchResultList = [];
    this.searchPageViewModel.assignmentSearchResultList = [];
    this.searchPageViewModel.sIndustryTypeList = [];
    this.searchPageViewModel.sClientProgramList = [];
    this.searchPageViewModel.sStrucTypeList = [];
    this.searchPageViewModel.sClientStatusList = [];
    this.searchPageViewModel.sClientNameList = [];
    this.searchPageViewModel.sEngagementNameList = [];
    this.searchPageViewModel.sEngagementStatusList = [];
    this.searchPageViewModel.sAssignmentNameList = [];
    this.searchPageViewModel.sAssignmentCodeStatusList = [];
    this.searchPageViewModel.seppmdList = [];
    this.searchPageViewModel.scmseppmdList = [];
    this.searchPageViewModel.sSolutionCodeList = [];
  }
  // pagination and sorting methods for client, engagement and assignments search data results
  setInitialsForListView() {
    if (this.selectedSearchType === EnumSearchTypes.CL) {
      if (this.searchPageViewModel.clientSearchResultList.length === 0) {
        this.clientHeaderModelList[0].imageModelList[0].isVisible = true;
        this.clientHeaderModelList[0].imageModelList[1].isVisible = false;
        this.clientHeaderModelList[0].imageModelList[2].isVisible = false;
      }
    } else if (this.selectedSearchType === EnumSearchTypes.ENG) {
      if (this.searchPageViewModel.engagementSearchResultList.length === 0) {
        this.engagementHeaderModelList[0].imageModelList[0].isVisible = true;
        this.engagementHeaderModelList[0].imageModelList[1].isVisible = false;
        this.engagementHeaderModelList[0].imageModelList[2].isVisible = false;
      }
    } else if (this.selectedSearchType === EnumSearchTypes.ASIGN) {
      if (this.searchPageViewModel.assignmentSearchResultList.length === 0) {
        this.assignmentHeaderModelList[0].imageModelList[0].isVisible = true;
        this.assignmentHeaderModelList[0].imageModelList[1].isVisible = false;
        this.assignmentHeaderModelList[0].imageModelList[2].isVisible = false;
      }
    }
  }

  setDefaultInitialization() {

    if (this.selectedSearchType === EnumSearchTypes.CL) {
      this.orderByColumn = 'ClientName';
    } else if (this.selectedSearchType == EnumSearchTypes.ENG) {
      this.orderByColumn = 'EngagementName';
    } else if (this.selectedSearchType == EnumSearchTypes.ASIGN) {
      this.orderByColumn = 'AssignmentName';
    }
    this.orderByType = 'asc';
  }

  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isloggedInUserAdmin = 'yes';
          console.log('isloggedInUserAdmin => ', this.isloggedInUserAdmin);
          break;
        }
        if (this.userObject.role[i].shortRoleName === RoleNames.QualityGroup) {
          this.isQualityUserLoggedIn = 'yes';
          console.log('isQualityUserLoggedIn => ', this.isQualityUserLoggedIn);
          break;
        }
        if(this.userObject.role[i].shortRoleName === RoleNames.INDUSStaffingManagers){
          this.isINDUSStaffingManagerLoggedIn = true;
          console.log('isNDUSStaffingManagerLoggedIn => ', this.isINDUSStaffingManagerLoggedIn);
        }
      }
    }
  }

  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(40);
    this.noOfrecordsPerPageList.push(60);
  }

  LoadListViewHeaders() {
    this.headerImageModelList = [];
    this.clientHeaderModelList = [];
    this.engagementHeaderModelList = [];
    this.assignmentHeaderModelList = [];
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    // setting header values for client, engagement and assignment result set
    if (this.selectedSearchType === EnumSearchTypes.CL) {
      this.headerModel = {
        headerId: 1,
        headerName: 'Client name',
        headerValue: 'ClientName',
        headerClick: true,
        imageModelList: this.headerImageModelList
      };
      this.clientHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 2,
        headerName: 'Client code',
        headerValue: 'ClientCode',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.clientHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 3,
        headerName: 'Program',
        headerValue: 'ClientProgram',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.clientHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));


      this.headerModel = {
        headerId: 4,
        headerName: 'Service lines',
        headerValue: 'ServiceLineList',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.clientHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 5,
        headerName: 'Status',
        headerValue: 'ClientStatus',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.clientHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      console.log('clientHeaderModelList ==>', this.clientHeaderModelList);

    } else if (this.selectedSearchType === EnumSearchTypes.ENG) {
      this.headerModel = {
        headerId: 1,
        headerName: 'Engagement name',
        headerValue: 'EngagementName',
        headerClick: true,
        imageModelList: this.headerImageModelList
      };
      this.engagementHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      this.headerModel = {
        headerId: 2,
        headerName: 'Client name',
        headerValue: 'ClientName',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.engagementHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 3,
        headerName: 'Client code',
        headerValue: 'ClientCode',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.engagementHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 4,
        headerName: 'Primary Solution code',
        headerValue: 'SolutionCode',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.engagementHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      this.headerModel = {
        headerId: 5,
        headerName: 'EPPMD',
        headerValue: 'EPPMD',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.engagementHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 6,
        headerName: 'Status',
        headerValue: 'EngagementStatus',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.engagementHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      console.log("engagementHeaderModelList=>", this.engagementHeaderModelList);

    } else if (this.selectedSearchType === EnumSearchTypes.ASIGN) {
      this.headerModel = {
        headerId: 1,
        headerName: 'Assignment name',
        headerValue: 'AssignmentName',
        headerClick: true,
        imageModelList: this.headerImageModelList
      };
      this.assignmentHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      this.headerModel = {
        headerId: 2,
        headerName: 'Assignment code',
        headerValue: 'AssignmentCode',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.assignmentHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      this.headerModel = {
        headerId: 3,
        headerName: 'Engagement name',
        headerValue: 'EngagementName',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.assignmentHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      this.headerModel = {
        headerId: 4,
        headerName: 'Client name',
        headerValue: 'ClientName',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.assignmentHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      this.headerModel = {
        headerId: 5,
        headerName: 'Solution code',
        headerValue: 'SolutionCode',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.assignmentHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

      this.headerModel = {
        headerId: 6,
        headerName: 'Status',
        headerValue: 'AssignmentCodeStatus',
        headerClick: false,
        imageModelList: this.headerImageModelList
      };
      this.assignmentHeaderModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
      console.log('this.assignmentHeaderModelList=>', this.assignmentHeaderModelList);
    }
  }

  HeaderSort(headerid: number) {
    if (this.selectedSearchType === EnumSearchTypes.CL) {
      for (let i = 0; i < this.clientHeaderModelList.length; i++) {
        if (this.clientHeaderModelList[i].headerId === headerid) {
          this.clientHeaderModelList[i].headerClick = true;
          if ((this.clientHeaderModelList[i].imageModelList[0].isVisible) || (this.clientHeaderModelList[i].imageModelList[2].isVisible)) {
            this.clientHeaderModelList[i].imageModelList[0].isVisible = false;
            this.clientHeaderModelList[i].imageModelList[1].isVisible = true;
            this.clientHeaderModelList[i].imageModelList[2].isVisible = false;
          } else if (this.clientHeaderModelList[i].imageModelList[1].isVisible) {
            this.clientHeaderModelList[i].imageModelList[0].isVisible = false;
            this.clientHeaderModelList[i].imageModelList[1].isVisible = false;
            this.clientHeaderModelList[i].imageModelList[2].isVisible = true;
          }
        } else {
          this.clientHeaderModelList[i].headerClick = false;
          for (let j = 0; j < this.clientHeaderModelList[i].imageModelList.length; j++) {
            this.clientHeaderModelList[i].imageModelList[0].isVisible = true;
            this.clientHeaderModelList[i].imageModelList[1].isVisible = false;
            this.clientHeaderModelList[i].imageModelList[2].isVisible = false;
          }
        }
      }

      let headerObj = this.clientHeaderModelList.find(c => c.headerClick === true);
      this.orderByColumn = headerObj.headerValue;
      for (let k = 0; k < headerObj.imageModelList.length; k++) {
        if (headerObj.imageModelList[k].isVisible === true) {
          this.orderByType = headerObj.imageModelList[k].imageValue;
          break;
        }
      }
      this.listViewSetPage(1, this.listViewnoOfrecordsPerPage);
    } else if (this.selectedSearchType === EnumSearchTypes.ENG) {
      for (let i = 0; i < this.engagementHeaderModelList.length; i++) {
        if (this.engagementHeaderModelList[i].headerId === headerid) {
          this.engagementHeaderModelList[i].headerClick = true;
          if ((this.engagementHeaderModelList[i].imageModelList[0].isVisible) || (this.engagementHeaderModelList[i].imageModelList[2].isVisible)) {
            this.engagementHeaderModelList[i].imageModelList[0].isVisible = false;
            this.engagementHeaderModelList[i].imageModelList[1].isVisible = true;
            this.engagementHeaderModelList[i].imageModelList[2].isVisible = false;
          } else if (this.engagementHeaderModelList[i].imageModelList[1].isVisible) {
            this.engagementHeaderModelList[i].imageModelList[0].isVisible = false;
            this.engagementHeaderModelList[i].imageModelList[1].isVisible = false;
            this.engagementHeaderModelList[i].imageModelList[2].isVisible = true;
          }
        } else {
          this.engagementHeaderModelList[i].headerClick = false;
          for (let j = 0; j < this.engagementHeaderModelList[i].imageModelList.length; j++) {
            this.engagementHeaderModelList[i].imageModelList[0].isVisible = true;
            this.engagementHeaderModelList[i].imageModelList[1].isVisible = false;
            this.engagementHeaderModelList[i].imageModelList[2].isVisible = false;
          }
        }
      }

      let headerObj = this.engagementHeaderModelList.find(c => c.headerClick === true);
      this.orderByColumn = headerObj.headerValue;
      for (let k = 0; k < headerObj.imageModelList.length; k++) {
        if (headerObj.imageModelList[k].isVisible === true) {
          this.orderByType = headerObj.imageModelList[k].imageValue;
          break;
        }
      }
      this.listViewSetPage(1, this.listViewnoOfrecordsPerPage);
    } else if (this.selectedSearchType === EnumSearchTypes.ASIGN) {
      for (let i = 0; i < this.assignmentHeaderModelList.length; i++) {
        if (this.assignmentHeaderModelList[i].headerId === headerid) {
          this.assignmentHeaderModelList[i].headerClick = true;
          if ((this.assignmentHeaderModelList[i].imageModelList[0].isVisible) || (this.assignmentHeaderModelList[i].imageModelList[2].isVisible)) {
            this.assignmentHeaderModelList[i].imageModelList[0].isVisible = false;
            this.assignmentHeaderModelList[i].imageModelList[1].isVisible = true;
            this.assignmentHeaderModelList[i].imageModelList[2].isVisible = false;
          } else if (this.assignmentHeaderModelList[i].imageModelList[1].isVisible) {
            this.assignmentHeaderModelList[i].imageModelList[0].isVisible = false;
            this.assignmentHeaderModelList[i].imageModelList[1].isVisible = false;
            this.assignmentHeaderModelList[i].imageModelList[2].isVisible = true;
          }
        } else {
          this.assignmentHeaderModelList[i].headerClick = false;
          for (let j = 0; j < this.assignmentHeaderModelList[i].imageModelList.length; j++) {
            this.assignmentHeaderModelList[i].imageModelList[0].isVisible = true;
            this.assignmentHeaderModelList[i].imageModelList[1].isVisible = false;
            this.assignmentHeaderModelList[i].imageModelList[2].isVisible = false;
          }
        }
      }

      let headerObj = this.assignmentHeaderModelList.find(c => c.headerClick === true);
      this.orderByColumn = headerObj.headerValue;
      for (let k = 0; k < headerObj.imageModelList.length; k++) {
        if (headerObj.imageModelList[k].isVisible === true) {
          this.orderByType = headerObj.imageModelList[k].imageValue;
          break;
        }
      }
      this.listViewSetPage(1, this.listViewnoOfrecordsPerPage);
    } // end if searchtype = assignment

  } // end HeaderSort method
  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewnoOfrecordsPerPage = recordsPerPage;
    } else {
      this.listViewnoOfrecordsPerPage = Number(inputDrpValue);
    }
    if (this.listViewCurrentPageNumber !== 0) {
      this.GetSearchPageResults();
      this.pager = this.pagerService.getPager(this.TotalResultCount,
        this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
    } else {
      $('#previous').off('click');
      $('#first').off('click');
    }
  }
  // end pagination and sorting section.
  // start navigate to Engagement  Details
  navigateEngagement(engagementId: number) {

    this.engagementService.GetEditEngagementDetailsById(engagementId).subscribe(
      result => {
        this.selectedEngagement = result;

        switch (this.selectedEngagement.status) {
          case 'Not started':
            //EditEnagement\ID
            this.route.navigate(['EditEngagement/' + this.selectedEngagement.engagementId]);
            break;
          case 'Draft':
            //Interviews
            this.route.navigate(['Interviews/' + this.selectedEngagement.workspaceId]);
            break;
          case 'InReview':
          case 'Declined':
          case 'Abandoned':
            //Review\WorkSpaceId
            this.route.navigate(['Review/' + this.selectedEngagement.workspaceId]);
            break;
          case 'RP rule engine error':
            //Review\WorkSpaceId
            this.route.navigate(['Review/' + this.selectedEngagement.workspaceId]);
            break;
          case 'Pending CMS codes':
          case 'CMS code error':
          case 'Active':
          case 'Closed':
          case EngagementStatus.EngActivePendingSignature:
          case EngagementStatus.EngVoided:
            this.route.navigate(['EngagementDetail/' + this.selectedEngagement.engagementId]);
          //EnagementDetails\ID
          default:
            //Default;
            break;
        }
      },
      err => {
        console.log('Error in getting workspace status.', err);
      }
    );
  } // end navigate to Engagement  Details
  // start navigate to Client Details
  navigateClient(clientId: number) {
    if (clientId > 0 && clientId !== undefined) {
      this.route.navigate(['ClientDetails/' + clientId]);
    }
  }
  // end navigate to Client Details
  expandTeamDetails(divDetailsId: string, imageControlId: string, event: any) {
    console.log(event.currentTarget);
    if (event.currentTarget.localName === 'li') {
      $(event.currentTarget).toggleClass('expanded');
    } else {
      var targetId = event.currentTarget.id;
      $('#' + targetId).parent('li').toggleClass('expanded');
    }
    $('#' + divDetailsId).toggle(500);
    $('#' + imageControlId).toggleClass('filterImgClose');
  }
  
  setIsMemberFirm() {
    this.isMemberFirm = this.userObject.isMemberFirmFeatureEnabled;
  }
}

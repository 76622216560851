<div class="tab-content tabDesign">
  <div class="tab-pane active" id="doc_myengagements">
    <div class="table-summary">
      <form class="form-inline">
        <!-- My Client title -->
        <div class="My-Clients-40 col-md-2">
          <span *ngIf="ShowClientName">My</span> &nbsp;Engagement Letters&nbsp;({{documentListDataFromDB?.length }})
        </div>
        <!-- My Client title -->
        <div class="col-md-7 displayCardFilter">
          <div class="CardViewFilters hide">
            <div id="ClientNameCardViewFiltersForEL" class="dropdown cardViewFiltersDisplay" *ngIf="ShowClientName">
              <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
                Clients <span class="caret"></span>
              </button>
              <ul id="ClientNameFilterULForEL" class="dropdown-menu ClientNameFilterjsForEL filterlist toggleDropdown"
                role="menu" aria-labelledby="menu1">
                <li class="input-group searchTextboxdesign">
                  <a class="text-muted">
                    <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png"
                      alt="Search" />
                  </a>
                  <input id="filterClientNameBoxForEL" class="form-control searchtextbox"
                    onkeyup="filterFunction('filterClientNameBoxForEL','ClientNameFilterULForEL')" type="text"
                    placeholder="Search" aria-label="Search" />
                </li>
                <li>
                  <input type="checkbox" class="CheckAllClientsEL" checked data-attribute="chkAll-ClientName" />&nbsp;All
                  Clients
                </li>
              </ul>
            </div> &nbsp;&nbsp;

            <div id="EngagementCardViewFiltersForEL" class="dropdown cardViewFiltersDisplay" *ngIf="ShowEngName">
              <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
                Engagements
                <span class="caret"></span>
              </button>
              <ul id="EngagementFilterULForEL" class="dropdown-menu EngagementFilterjsForEL filterlist toggleDropdown"
                role="menu" aria-labelledby="menu1">
                <div class="input-group searchTextboxdesign">
                  <a class="text-muted">
                    <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png"
                      alt="Search" />
                  </a>
                  <input id="filterEngagementBoxForEL" class="form-control searchtextbox"
                    onkeyup="filterFunction('filterEngagementBoxForEL','EngagementFilterULForEL')" type="text"
                    placeholder="Search" aria-label="Search" />
                </div>
                <li>
                  <input type="checkbox" class="CheckAllEngEL" checked data-attribute="chkAll-Eng" />&nbsp;All Engagements
                </li>
              </ul>
            </div>&nbsp;&nbsp;

            <div id="DocNameCardViewFiltersForEL" class="dropdown cardViewFiltersDisplay">
              <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
                Doc Name
                <span class="caret"></span>
              </button>
              <ul id="DocNameFilterULForEL" class="dropdown-menu DocNameFilterjsForEL filterlist toggleDropdown" role="menu"
                aria-labelledby="menu1">
                <div class="input-group searchTextboxdesign">
                  <a class="text-muted">
                    <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png"
                      alt="Search" />
                  </a>
                  <input id="filterDocNameBoxForEL" class="form-control searchtextbox"
                    onkeyup="filterFunction('filterDocNameBoxForEL','DocNameFilterULForEL')" type="text" placeholder="Search"
                    aria-label="Search" />
                </div>
                <li>
                  <input type="checkbox" class="CheckAllDocName" checked data-attribute="chkAll-DocName" />&nbsp;All Doc
                  Name
                </li>
              </ul>
            </div>&nbsp;&nbsp;
            <div id="DocTypeCardViewFiltersForEL" class="dropdown cardViewFiltersDisplay">
              <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
                Doc Type
                <span class="caret"></span>
              </button>
              <ul id="DocTypeFilterULForEL" class="dropdown-menu DocTypeFilterjsForEL filterlist toggleDropdown" role="menu"
                aria-labelledby="menu1">
                <div class="input-group searchTextboxdesign">
                  <a class="text-muted">
                    <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png"
                      alt="Search" />
                  </a>
                  <input id="filterDocTypeBoxForEL" class="form-control searchtextbox"
                    onkeyup="filterFunction('filterDocTypeBoxForEL','DocTypeFilterULForEL')" type="text" placeholder="Search"
                    aria-label="Search" />
                </div>
                <li>
                  <input type="checkbox" class="CheckAllDocType" checked data-attribute="chkAll-DocType" />&nbsp;All Doc
                  Type
                </li>
              </ul>
            </div>&nbsp;&nbsp;
            <div id="AuthorCardViewFiltersForEL" class="dropdown cardViewFiltersDisplay" *ngIf="ShowAuthor">
              <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
                Author/Signer
                <span class="caret"></span>
              </button>
              <ul id="AuthorFilterULForEL" class="dropdown-menu AuthorFilterjsForEL filterlist toggleDropdown" role="menu"
                aria-labelledby="menu1">
                <div class="input-group searchTextboxdesign">
                  <a class="text-muted">
                    <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png"
                      alt="Search" />
                  </a>
                  <input id="filterAuthorBoxForEL" class="form-control searchtextbox"
                    onkeyup="filterFunction('filterAuthorBoxForEL','AuthorFilterULForEL')" type="text" placeholder="Search"
                    aria-label="Search" />
                </div>
                <li>
                  <input type="checkbox" class="CheckAllAuthor" checked data-attribute="chkAll-Author" />&nbsp;All
                  Author/Signor
                </li>
              </ul>
            </div>&nbsp;&nbsp;

            <div id="StatusCardViewFiltersForEL" class="dropdown cardViewFiltersDisplay">
              <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
                Status
                <span class="caret"></span>
              </button>
              <ul id="StatusFilterULForEL" class="dropdown-menu StatusFilterjsForEL filterlist toggleDropdown" role="menu"
                aria-labelledby="menu1">
                <div class="input-group searchTextboxdesign">
                  <a class="text-muted">
                    <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png"
                      alt="Search" />
                  </a>
                  <input id="filterStatusBoxForEL" class="form-control searchtextbox"
                    onkeyup="filterFunction('filterStatusBoxForEL','StatusFilterULForEL')" type="text" placeholder="Search"
                    aria-label="Search" />
                </div>
                <li>
                  <input type="checkbox" class="CheckAllStatusForEL" checked data-attribute="chkAll-Status" />&nbsp;All
                  Status
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- List View and Card View buttons -->
        <div class="col-md-3">
          <!-- Search Button -->
          <img class="text-muted resetHyperlink" src="../../../../assets/images/listview/search.png" alt="search" />
          <input class="form-control searchbox" type="text" placeholder="Search" aria-label="Search"
                 (keyup)="onKeyup($event,'searchDatajs')" />
          <!-- Search Button -->


          <a class="ShowCardViewjs" (click)="ToggleListAndCardView('Card');">
            <img class="CardViewImg pointerCursor" src="../../../../assets/images/listview/card-view_selected.png"
                 alt="Card View" />
          </a>&nbsp;

          <a class="ShowListViewjs" (click)="ToggleListAndCardView('List');">
            <img class="ListViewImg pointerCursor" src="../../../../assets/images/listview/list-view_un-selected.png"
                 alt="List View" />
          </a>&nbsp;


        </div>
        <!-- List View and Card View buttons -->
      </form>
    </div>

    <!-- List View and card view main container start -->
    <div class="MainContainer">
      <div class="table-responsive tb">
        <!--ListView Design Start-->
        <div class="ListViewContainer" style="display:none">
          <table class="table table-light tableData tableDataDoc ResponsiveDataTable">
            <caption>List view table</caption>
            <thead>
              <tr>
                <th scope="col" *ngIf="ShowClientName" class="table-sorted-desc">
                  Client Name
                </th>
                <th scope="col" *ngIf="ShowEngName" class="table-sortable-desc">
                  Eng Name
                </th>
                <th scope="col" class="table-sortable-desc">Document(s)</th>
                <th scope="col" class="table-sortable-desc">Document Type</th>
                <th scope="col" class="table-sortable-desc">Status</th>
                <th scope="col" class="table-sortable-desc">
                  Last Activity Date
                </th>
                <th scope="col" class="table-sortable-desc" *ngIf="ShowModifiedBY">
                  Modified By
                </th>
                <td></td>
              </tr>
            </thead>

            <tbody class="display" *ngIf="documentListDataFromDB?.length != 0">
              <tr class="searchDatajs" *ngFor="let list of documentListDataFromDB">
                <td data-label="Client Name:" *ngIf="ShowClientName">
                  <a href="ClientSummary/{{list.clientId}}"
                     class="link">{{list.clientName}}</a>
                </td>
                <td data-label="Engagement Name:" *ngIf="ShowEngName">
                  <a href="EngagementSummary/{{list.engagementId}}" class="link">
                    {{list.engagementName}}
                  </a>
                </td>
                <td data-label="Document(s):">
                  <a *ngIf="list.status != 'Draft' " href="Workspace/{{list.workspaceId}}" class="link">{{list.documentName}}</a>
                  <a *ngIf="list.status === 'Draft'" >{{list.documentName}}</a>
                </td>
                <td data-label="Document Type:"> {{list.documentType }} </td>
                <td data-label="Status:">
                  {{list.status}}
                </td>
                <td data-label="Last Activity Date:"> {{list.lastActivityDate | date:'MM/dd/yyyy | hh:mm a'}} </td>
                <td data-label="Modified By:" *ngIf="ShowModifiedBY">{{list.createdby}}</td>
                <td>
                  <div *ngIf="list.status != 'Draft' ">
                    <a href="/Review/{{list.workspaceId}}" class="link">
                      Go to
                      Workspace
                      <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="workspace" />
                    </a>
                  </div>
                  <div *ngIf="list.status == 'Draft' ">
                    <a id="{{list.workspaceId}}" class="link" (click)="setWorkspaceId(list.workspaceId)">
                      Show Interviews
                    </a> <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Interviews" />
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="documentListDataFromDB?.length == 0">
              <tr>
                <td colspan="7" class="no-data-available text-center">No Data Available</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--ListView Design End-->
        <!--CardView Design Start-->
        <div class="cardViewContainer row marginLeftZero">
          <div class="card-deck-wrapper col-md-12">
            <div class="card-deck">
              <div class="card searchDatajs FilterDocDatajs cardviewDesign" *ngFor="let list of documentListDataFromDB">
                <div class="card-body text-align-left">
                  <a *ngIf="list.status != 'Draft' " href="Workspace/{{list.workspaceId}}"
                     class="CardViewText CardViewHeaderFont DocNamejsForEL">{{ list.documentName }}</a>
                     <a *ngIf="list.status === 'Draft' "
                     class="CardViewText CardViewHeaderFont DocNamejsForEL">{{ list.documentName }}</a>
                     <br />
                  <span class="CardViewText CardViewHeaderFont">Doc Type:</span><span class="DocTypejsForEL CardViewText CardViewHeaderFont">
                    {{ list.documentType }}
                  </span>
                  <hr />
                  <a href="ClientSummary/{{list.clientId}}" *ngIf="ShowClientName"
                     class="CardViewHeaderFont marginZero ClientNamejsForEL ClientNamejsForFilter">
                    {{ list.clientName }}
                  </a><br *ngIf="ShowEngName" />
                  <div *ngIf="ShowEngName">
                    <a href="EngagementSummary/{{list.engagementId}}"
                       class="CardViewHeaderFont EngagementjsForEL">{{ list.engagementName }}</a><br />
                  </div>

                  <span>Status:</span><span class="StatusjsForEL">{{ list.status }}</span><br />
                  <span *ngIf="ShowAuthor">Author/Signor:</span><span *ngIf="ShowAuthor" class="AuthorjsForEL">
                    {{
              list.createdby
                    }}
                  </span><br *ngIf="ShowAuthor" />
                  <span>Last Activity:</span><span>
                    {{list.lastActivityBy}}<br />
                    {{list.lastActivityDate | date:'MM/dd/yyyy | hh:mm a'}}
                  </span>
                  <hr />
                  <div style="margin-top:5px;">
                    <a href="#" (click)="setWorkspaceIdForActivityLog(list.workspaceId)">
                      Activity Log &nbsp;&nbsp;
                      <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Activity Log" />
                    </a>
                    <span class="float-right" *ngIf="list.status != 'Draft' ">
                      <a href="/Review/{{list.workspaceId}}"
                         class="link">
                        Go to Workspace
                        <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Workspace" />
                      </a>
                    </span>
                    <span *ngIf="list.status == 'Draft' " class="float-right">
                      <a id="{{list.workspaceId}}" class="link" (click)="setWorkspaceId(list.workspaceId)">
                        Show Interviews
                        <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Interview" />
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div id="NoDataMessageDiv" class="text-center width100" *ngIf="documentListDataFromDB?.length == 0">
                No Data Available
              </div>
              <div id="NoDataMessage" class="hide text-center width100">
                No Data Available
              </div>
            </div>
            <!--CardView Design End-->
          </div>
        </div>
        <!-- List View and card view main container End-->
      </div>
      <!--end Document tab-->

    </div>
    <!--tab-content-->
  </div>
</div>
<br />

<!-- <app-activity-log [workspace]="selectedWorkspaceId"></app-activity-log> -->
<!--tab-content-->

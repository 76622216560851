export class DocumentListModel {
    clientName: string;
    EngName: string;
    Documents: string;
    DocumentType: string;
    Status: string;
    LastActivityDate: string;
    ModifiedBy: string;
    Author: string;
}

export class DocumentList {
    workspaceId: string;
    clientId: number;
    engagementId: number;
    hdDocumentId: string;
    documentName: string;
    documentAliasName:string;
    documentType: string;
    clientName: string;
    engagementName: string;
    engagementPartner: string;
    status: string;
    createdby: string;
    createdon: Date;
    solutionCode: string;
    myReviewsCount: number;
    openReviews: string;
    lastActivityBy: string;
    lastActivityDate: Date;
    templateType:string;
}
import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { KeyValue } from 'src/app/model/KeyValue';

import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { AutoCompleteMappedSource } from 'src/app/model/Common/AutoCompleteMappedSource';
declare var $;

@Component({
  selector: 'app-auto-complete-text-box',
  templateUrl: './auto-complete-text-box.component.html',
  styleUrls: ['./auto-complete-text-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteTextBoxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutoCompleteTextBoxComponent),
      multi: true,
    }
  ],
})
export class AutoCompleteTextBoxComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {

  @Input() source: string;
  @Input() MappedSourceValue: string;
  @Input() name: string;
  @Input('value') val: string;
  @Input() MappedSource: string;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Input() Sequence: number;
  @Input() NoRecordFoundFor: boolean;

  private parseError: boolean;
  public isValidValueError: boolean;
  private selectedValueFlag: boolean;
  private isDisplayed: boolean;
  userName: string;
  clientSource: string;
  keyValueList: KeyValue[] = [];
  filterKeyValueList: KeyValue[] = [];
  userObject: any;
  mappedSourcePlaceholder: string;
  isloggedInUserAdmin: string;
  queryField: UntypedFormControl = new UntypedFormControl();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (!$(event.target).is('.autocomplete-dropdown-btn')) {
        this.hideSelect();
      }
    }
  }

  constructor(private _configService: ConfigurationService, private dataService: DataService,
              private router: Router, private eRef: ElementRef) {
    this.isValidValueError = false;
    this.selectedValueFlag = false;
    this.isDisplayed = true;
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    let nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      }
  }

  propagateChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.propagateChange(val);
    this.onTouched();
  }

  ngOnInit() {
    this.IsLoggedinUserSystemAdmin();
    console.log('ngOnInit func called ', this.NoRecordFoundFor);    
    console.log('ngOnInit MappedSourceValue', this.MappedSourceValue);  
    if (this.NoRecordFoundFor) {
      this.isDisplayed = false;
    }
    this.InitializePlaceholder();
    this.InitializeDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(' change InputValue ==> ', this.MappedSourceValue);
    for (const propName in changes) {      
      if (propName === 'MappedSourceValue' && this.source =='SICCode' ) {
        if (this.MappedSourceValue != undefined && this.MappedSourceValue != '') {
          this.filterKeyValueList.forEach(item => {
            let itemArr = item.value.split('-');
            if (Number(itemArr[0].trim()) == Number(this.MappedSourceValue.trim())) {
              this.selectedValue(null, item);
            }
          })
        } else {
          const resetItem = {key: 0, value: ''}
          this.selectedValue(null, resetItem);
        }         
      }
      if (propName === 'MappedSourceValue' && this.source =='ReferralSource' ) {
        if (this.MappedSourceValue != undefined && this.MappedSourceValue != '') {
          this.filterKeyValueList.forEach(item => {
            if (item.value.trim()== this.MappedSourceValue.trim())
            {
              this.selectedValue(null, item);
            }
          })
        } else {
          const resetItem = {key: 0, value: ''}
          this.selectedValue(null, resetItem);
        }         
      }     
        
    }
  }
  
  IsLoggedinUserSystemAdmin() {
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
        for (let i = 0; i < this.userObject.role.length; i++) {
          if (this.userObject.role[i].shortRoleName === 'SA' ) {
            this.isloggedInUserAdmin = 'yes';
            break;
          }
          else
          {
            this.isloggedInUserAdmin = 'no';
          }
        }
    }
  }
  InitializePlaceholder() {
    switch (this.MappedSource) {
      case AutoCompleteMappedSource.InitiateEngagementSolutionCode:
      this.mappedSourcePlaceholder = 'Enter a primary solution code or select from the list';
      break;
      case AutoCompleteMappedSource.CreateClientSICCode:
      this.mappedSourcePlaceholder = 'Enter a SIC Codes code or select from the list';
      break;
      case AutoCompleteMappedSource.CreateClientReferralSource:
      this.mappedSourcePlaceholder = 'Referral Source';
      break;
      case AutoCompleteMappedSource.SolutionCodeConfiguration:
      this.mappedSourcePlaceholder = 'Select secondary code';
      break;
      case AutoCompleteMappedSource.ClientMappedValue:
      case AutoCompleteMappedSource.ClientCodeCardMappedValue:
      case AutoCompleteMappedSource.ClientCodeListMappedValue:
      case AutoCompleteMappedSource.ClientListMappedValue:
      case AutoCompleteMappedSource.ContinuanceListMappedValue:
      this.mappedSourcePlaceholder = 'Select client';
      break;
      default:
      break;
    }
  }

  ShowNoRecordMatchMessage(action: string) {
    const divObj = $('#NoRecordMatchFoundFor' + this.MappedSource + this.Sequence);
    if (action === 'hide') {
      divObj.hide();
    } else {
      divObj.show();
    }
  }

  InitializeDataSource() {
    console.log(this.name, this.MappedSource, this.Sequence, ' sequence of control');
    this.ShowNoRecordMatchMessage('hide');
    $('.filter-select.' + this.MappedSource + this.Sequence).hide();
    console.log('hide the message in ngOnInit ', this.source, $('#NoRecordMatchFoundFor' + this.MappedSource + this.Sequence));
    if (this.source != 'Client') {
      this.BindDataSource(this.source);
      this.queryField.valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged()
        ).subscribe(queryField => this.filterResult(queryField));
    }
  }

  BindDataSource(source: string) {
    if(this.source =='UniqueClientCodeForClient') {
      this.clientSource = 'ClientsForClient' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
      this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          setTimeout(() => {
            this.hideSelect();
          }, 200);
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else if(this.source == 'UniqueClientCodeForEngagement') {
        this.clientSource = 'ClientsForEngagement' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
        this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          setTimeout(() => {
            this.hideSelect();
          }, 200);
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else if(this.source == 'UniqueClientCodeForContinuance') {
        this.clientSource = 'ClientsForContinuance' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
        this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          setTimeout(() => {
            this.hideSelect();
          }, 200);
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else {
      this._configService.GetListBySource(source).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          setTimeout(() => {
            this.hideSelect();
          }, 200);
        }
      },
        err => {
          console.log('Error ', err);
        });
    }
  }

  hideSelect() {
    $('.' + this.source).hide();
    $('.filter-select.' + this.MappedSource + this.Sequence).hide();
    this.ShowNoRecordMatchMessage('hide');
    this.isDisplayed = false;
  }

  filterResult(filter: string) {
    this.ShowNoRecordMatchMessage('hide');
    $('.filter-select.' + this.MappedSource + this.Sequence).hide();
    let filterLength: number;
    if (filter) {
      filterLength = filter.length;
    } else {
      filterLength = 0
    }
    if (filterLength > 0) {
      $('.' + this.source).show();
      this.filterKeyValueList = this.keyValueList
        .filter(item => item.value.substring(0, filterLength).toLowerCase() === filter.toLowerCase())
      $('.filter-select.' + this.MappedSource + this.Sequence).show();
      if (this.filterKeyValueList.length == 1) {
        this.selectedValue(null, this.filterKeyValueList[0]);
      }
    } else {
      $('#txtValue' + this.MappedSource).val('');
      $('#hdnKey' + this.MappedSource).val('');
      $('.filter-select.' + this.MappedSource + this.Sequence).hide();
      this.change.emit('');
    }
  }

  selectedValue(event, item) {
    this.selectedValueFlag = true;
    this.parseError = false;
    console.log('Selected Value ', item);
    if (item.key != 0) {
      $('#txtValue' + this.MappedSource + this.Sequence).val(item.value);
      $('#hdnKey' + this.MappedSource + this.Sequence).val(item.key);
    } else {
      $('#txtValue' + this.MappedSource + this.Sequence).val('');
      $('#hdnKey' + this.MappedSource + this.Sequence).val('');
      this.BindDataSource(this.source);

    }
    this.ShowNoRecordMatchMessage('hide');
    $('.filter-select.' + this.MappedSource + this.Sequence).hide();
    this.change.emit(item.value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  validate(control: AbstractControl): ValidationErrors {
    return (!this.parseError) ? null : {
      required: {
        valid: this.parseError,
      }
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
    // throw new Error("Method not implemented.");
  }

  // change events from the textBox
  onChange(event) {
    if(this.source === AutoCompleteMappedSource.UniqueClientCodeForClient) {
      this.clientSource = 'ClientsForClient' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
      this.filterKeyValueList = [];
      this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
          this.processChange(event);
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else if(this.source === AutoCompleteMappedSource.UniqueClientCodeForEngagement) {
      this.clientSource = 'ClientsForEngagement' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
      this.filterKeyValueList = [];
      this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
          this.processChange(event);
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else if(this.source === AutoCompleteMappedSource.UniqueClientCodeForContinuance) {
      this.clientSource = 'ClientsForContinuance' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
      this.filterKeyValueList = [];
      this._configService.GetListBySource(this.clientSource).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
          this.processChange(event);
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else {
      this.filterKeyValueList = [];
      this._configService.GetListBySource(this.source).subscribe(result => {
        this.keyValueList = result;
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
          this.processChange(event);
        }
      },
        err => {
          console.log('Error ', err);
        });
    }
  }

  onKeyUp(event) {
    this.selectedValueFlag = false;
    console.log("keyup invoked");
    if(event.keyCode === 8 && (event.target.value === '' || event.target.value === undefined)){
      console.log("keyup backspace invoked");
      this.BindDataSource(this.source);
    }
    if((event.ctrlKey||event.metaKey) && event.keyCode==88 && (event.target.value === '' || event.target.value === undefined)){
      console.log("keyup CTRL+X invoked");
      this.BindDataSource(this.source);
    }
    if(event.keyCode === 46 && (event.target.value === '' || event.target.value === undefined)){
      console.log("keyup delete invoked");
      this.BindDataSource(this.source);
    }
  }

  private processChange(event) {
    console.log('onChange event fired ', event, this.selectedValueFlag);
    if (!this.selectedValueFlag) {
      // get value from text area
      const newValue = event.target.value;
      try {
        // parse it to json
        this.val = this.validateValue(newValue);
      } catch (ex) {
        // set parse error if it fails
        this.isValidValueError = true;
      }

      this.propagateChange(this.val)
    }
  }

  private validateValue(value: string) {
    console.log('validateValue func called ', this.source, value);
    if (value.length > 0) {
      this._configService.GetIdByValueFromSource(this.source, value).subscribe(
        res => {
          if (res > 0) {
            const item = {
              key: res,
              value: value
            }
            this.selectedValue(null, item);
          } else {
            console.log('inside else for validateValue ', res);
            this.isValidValueError = true;
            const resetItem = {
              key: 0,
              value: ''
            }
            this.selectedValue(null, resetItem);
          }
        }
      );
    } else {
      this.isValidValueError = false;

    }
    return value;
  }

  NavigateToCreateClient() {
    this.dataService.setReturnUrlForCreateClient(this.router.url);
    this.router.navigate(['/NewClient']);
  }

  clearErrorMessage() {
    this.isValidValueError = false;
    this.isDisplayed = false;
  }
  onSearch(value: string) {
    this.isValidValueError = false;
    this.ShowNoRecordMatchMessage('hide');
    this.selectedValueFlag = false;
    console.log('Auto complete Text value on click', value);
    if (this.isDisplayed) {
      this.hideSelect();
    } else {
      this.showFilterList();
    }
    // if (value == "" || value.length == 0) {
    //   if (this.isDisplayed) {
    //     this.hideSelect();
    //   } else {
    //     this.showFilterList();
    //   }
    // } else {
    //   if (this.isDisplayed) {
    //     this.hideSelect();
    //   } else {
    //     this.showFilterList();
    //   }
    // }
  }

  showFilterList() {
    $('.filter-select.' + this.MappedSource + this.Sequence).show();
    this.isDisplayed = true;
    $('.filter-select.' + this.MappedSource + this.Sequence).scrollTop(0);
  }
  setPlaceholderValue() {
    this.InitializePlaceholder();
    $('#txtValue' + this.MappedSource + this.Sequence).attr('placeholder', this.mappedSourcePlaceholder);
  }
}

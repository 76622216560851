import { AssignmentUniqueDataModel } from './../model/assignment-unique-data.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appConfigService';
import { Assignments } from '../model/assignments.model';
import { AssignmentFilter } from '../model/assignment-filter.model';

@Injectable({
  providedIn: 'root'
})
export class AssignmentDashboardService {

  apiEndPoint: string;
  integrationAPIEndPoint: string;
  userName: string;
  fullName: string;
  assignmentList: Assignments = new Assignments();
  constructor(private environment: AppConfigService, private http: HttpClient) {
    this.apiEndPoint = this.environment.config.DATA.API_END_POINT.API_URL;
    this.integrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.fullName = nameJSON.loggedInUserFullName;
    }
  }

  getAssignmentsListView(assignmentFilter: AssignmentFilter, sortLastActivityDate: number,
                         pageNumber: number, noOfRecordsPerPage: number,
                         isLoggedInUserAdmin: string, orderByColumn: string, orderByType: string
  ): Observable<Assignments[]> {
    assignmentFilter.loggedInUser = this.userName;
    return this.http.post<Assignments[]>(`${this.apiEndPoint}/AssignmentDashboard/GetAllAssignmentsByFilters/${isLoggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}/${sortLastActivityDate}`, assignmentFilter);
  }

  getMyAssignmentByUserCount(isLoggedInUserAdmin: string, assignmentFilter: AssignmentFilter):
    Observable<number> {
    // tslint:disable-next-line: max-line-length
    assignmentFilter.loggedInUser = this.userName;
    return this.http.post<number>(`${this.apiEndPoint}/AssignmentDashboard/GetAllAssignmentByUserCount/${isLoggedInUserAdmin}`, assignmentFilter);
  }

  getAssignmentFilterSectionUniqueData(isLoggedInUserAdmin: string): Observable<AssignmentUniqueDataModel> {
    return this.http.post<AssignmentUniqueDataModel>(this.apiEndPoint +
      '/AssignmentDashboard/GetAllAssignmentUniqueDataByUser', { userName: this.userName,isLoggedInUserAdmin: isLoggedInUserAdmin});
  }
}

import { Injectable } from '@angular/core';
import { AppConfigService } from '../appConfigService';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EngagementESignatureRequestModel } from 'src/app/model/Engagement/EngagementESignatureRequestModel';
import { EngagementESignature } from 'src/app/model/Engagement/EngagementESignature';
import { EngagementESignatureResponseModel } from 'src/app/model/Engagement/EngagementESignatureResponseModel';
import { ESignersModel } from 'src/app/model/Engagement/ESignersModel';
import { DocuSignTemplateModel } from 'src/app/model/Engagement/DocuSignTemplateModel';

@Injectable({
  providedIn: 'root'
})
export class ESignatureService {

  IntegrationAPIEndPoint: string;
  ApiEndPoint: string;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.ApiEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
   }

  SignDocument(engagementESignatureRequestModel: EngagementESignatureRequestModel): Observable<EngagementESignatureResponseModel>{
    return this.http.post<EngagementESignatureResponseModel>(this.IntegrationAPIEndPoint + "/esignature/SignDocument", engagementESignatureRequestModel);  
  }

  DiscardEnvelope(envelopeId: string, disacardRemark: string){
    return this.http.post<any>(this.IntegrationAPIEndPoint + `/esignature/DiscardEnvelope?envelopeId=${envelopeId}&discardRemark=${disacardRemark}`, null);
  }

  AddEngagementESignatureDetails(engagementESignature:EngagementESignature){
    return this.http.post<EngagementESignature>(this.ApiEndPoint + "/Envelope/AddEngagementSignatureDetails", engagementESignature);
  }

  GetESigners(engagementId: number, documentName: string){
    return this.http.get<ESignersModel[]>(`${ this.ApiEndPoint }/Envelope/GetESigners?engagementId=${ engagementId }&documentAliasName=${ documentName }`);
  }
  GetDocuSignTemplate(engagementId: number){
    return this.http.get<DocuSignTemplateModel[]>(`${ this.ApiEndPoint }/Envelope/GetDocuSignTemplate?engagementId=${ engagementId }`);
  }

}

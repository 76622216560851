export class Alert {
    message: string;
    messageType: string;
    debug: string;
  }

  export class AlertProcessAudit {
    engagementId: number;
    message: string;
    messageType: string;
    debug: string;
    isSuccess: boolean;
  }
  
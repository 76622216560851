import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ClientService } from 'src/app/services/clientservice';
import { ClientContactPopupModel } from 'src/app/model/Popup/ClientContactPopupModel';

@Component({
  selector: 'app-client-contact-popup',
  templateUrl: './client-contact-popup.component.html',
  styleUrls: ['./client-contact-popup.component.css']
})
export class ClientContactPopupComponent implements OnInit, OnChanges {

  client: ClientContactPopupModel;
  @Input() clientId: number;
 

  constructor(private clientService: ClientService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);
      console.log('CC ngOnChanges ', curVal, prevVal, curVal == null, curVal == 'null');
      if (curVal == 'null') {
        this.client = null;
      }
      else {
        if (curVal != prevVal) {
          this.getClientContactsInfoForPopup(parseInt(curVal));
        }
      }
    }
  }

  getClientContactsInfoForPopup(clientId: number) {
    this.clientService.getClientContactsInfoForPopup(clientId)
      .subscribe(
        result => {
          this.client = result;
          console.log('getClientContactsInfoForPopup func called ', result, this.client);
        },
        error => {
          this.client = null;
        }
      );
  }
  ClosePopover() {
    this.client=null;
  }
}

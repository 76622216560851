<section class="filter-wrapper">
  <div class="input-group mw-100 autocomplete-div">
    <input #autoSearch class="form-control py-1 rounded-0 autocomplete-inputbox input-height" type="text"
      placeholder="{{mappedSourcePlaceholder}}" (focus)="this.mappedSourcePlaceholder=''" (blur)="setPlaceholderValue()"
      [formControl]="queryField" id="txtValue{{MappedSource}}{{Sequence}}" name="txtValue{{MappedSource}}{{Sequence}}"
      autocomplete="off" value="" [name]="name" [(ngModel)]="value" (change)="onChange($event)"
      (keyup)="onKeyUp($event)" />
    <div class="input-group-append autocomplete-dropdown-btnParent">
      <button type="button" class="mw-100 autocomplete-dropdown-btn input-height"
        (mousedown)="onSearch(autoSearch.value)">
        <img src="../../../../assets/images/icons/dropdownarrow.svg" alt="Search" />
      </button>
    </div>
  </div>
  <input type="hidden" id="hdnKey{{MappedSource}}{{Sequence}}" />
  <ul class="filter-select {{MappedSource}}{{Sequence}}">
    <li class="filter-select-list" *ngFor="let keyValue of filterKeyValueList"
      (mousedown)="selectedValue($event, keyValue)" [value]="keyValue.value">
      {{keyValue.value}}
    </li>
  </ul>
  <div class="form-control-feedback error-color" *ngIf="isValidValueError">
    <p>Please select valid value from list. </p>
  </div>
</section>
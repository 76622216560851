export class HdWorkspaceDocumentModel {
    documentId: string;
    assembledOrder: number;
    name: string;
    fileName: string;
    mimeType: string;
    createdDate: Date;
    assemblySessionId: string;
    hasContent: boolean;
    workItemId : string;
    workspaceId :string;
    loggedInUserFullName : string;

}
export class WorkspaceDocumentModel {
    workspaceDocumentId: string;
    workspaceId: string;
    hdDocumentId: string;
    hdDocumentName: string;
    hdMimeType: string;
    hdAssemblySession: string;
    createdBy: string;
    createdOn: Date;
    updatedBy: string;
    updatedOn: Date;
    isActive: boolean;
    tileId:number;
    tileName:string;
    loggedInUserFullName:string;
    hdTemplatePackageVersionId: string;
    documentAliasName: string = null;
    engagementState: string;
}

export class WorkspaceDocumentViewModel {    
    workspaceDocumentId: number;
    engagementId: number;
    workspaceId: string;
    workItemId: string
    documentId: string;
    documentName: string;
    mimeType: string;  
    workItemVersionId: string;
    templatePackageVersionId: string;
    documentAliasName: string;   
    lastActivityBy: string;
    lastActivityDate: Date;
    orginalHDWorkItemVersionId: string;
    orginalHDWorkItemId: string;
    isPrimaryWorkspaceId: boolean;
    engagementState: string;
    hdTemplatePackageId: string;
    hdCurrentTempVersion: number;
}
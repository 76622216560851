export class ConveneApiLogModel {
    engagementId: number;
    requestType: string;
    httpMethod: string;
    requestPayload: string;
    responseBody: string;
    isSentSuccessfully: boolean;
    status: boolean;
    statusCode: string;
    statusDescription: string;
    loggedInUser: string;
}

export enum ConveneRequestTypes {
    SendClosureNotification = 'SendClosureNotification',
    GetConveneProjects = 'GetConveneProjects'
}

export enum RequestMethods {
    RequestHttpGet = 'HttpGet',
    RequestHttpPost = 'HttpPost'
}

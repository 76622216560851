import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfigService } from "./appConfigService";
import { ClientConsentFlagUpdateModel, ClientConsentOptions, UpdateClientConsentDataModel, UpdateClientConsentOptions } from "../model/ClientConsent";
import { Observable } from "rxjs";
import { CollaborationSiteMembersForConsentTab, UpdateINDUSConsentFlag } from "../model/IndusConsentModel";

@Injectable()
export class ClientConsentService {
    APIEndPoint: string;
  
    constructor(private enviornment: AppConfigService, private http: HttpClient) {
        this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;        
    }

    GetClientConsentOptions(engagementId: number): Observable<ClientConsentOptions[]> {        
        return this.http.get<ClientConsentOptions[]>(this.APIEndPoint + "/ClientConsent/GetClientConsentOptions/"+ engagementId);
    }

    GetClientConsentFlagByEngId(engagementId: number): Observable<string> {   
        const requestOptions: Object = {
            responseType: 'text'
          }     
        return this.http.get<string>(this.APIEndPoint + "/ClientConsent/GetClientConsentFlagByEngId?engagementId="+ engagementId, requestOptions);
    }

    UpdateClientConsentFlag(model: ClientConsentFlagUpdateModel) {        
        return this.http.post(this.APIEndPoint + "/ClientConsent/UpdateClientConsentFlag", model, { responseType: 'text' });
    }

    UpdateClientConsentOptionsData(clientConsentModel: UpdateClientConsentOptions[]) {      
       let updateClientConsentDataModel:UpdateClientConsentDataModel=new UpdateClientConsentDataModel() ;
       updateClientConsentDataModel.updateClientConsentOptions=clientConsentModel;
        return this.http.post(this.APIEndPoint + "/ClientConsent/UpdateClientConsentOptions", updateClientConsentDataModel, { responseType: 'text' });
    }
    GetCollaborationSiteMembersForConsentTab(collaborationSiteId: number) {
        return this.http.get<CollaborationSiteMembersForConsentTab[]>(this.APIEndPoint + "/ClientConsent/GetCollaborationSiteMembersForConsentTab?CollaborationSiteId="+ collaborationSiteId);
    }
    UpdateIndusConsentInConsentTab(updateINDUSConsentFlag: UpdateINDUSConsentFlag) {
        return this.http.post(this.APIEndPoint + "/ClientConsent/UpdateIndusConsentInConsentTab", updateINDUSConsentFlag, { responseType: 'text' });
    }
    ResetClientConsentOptions(engagementId: number): Observable<boolean>{        
        return this.http.post<boolean>(this.APIEndPoint + `/ClientConsent/ResetClientConsentOptions/${engagementId}`,engagementId);
    }
    ResetClientConsentOptionsToSystemDefault(engagementId: number): Observable<boolean>{        
        return this.http.post<boolean>(this.APIEndPoint + `/ClientConsent/ResetClientConsentOptionsToSystemDefault/${engagementId}`,engagementId);
    }
    
}
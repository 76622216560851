
export class EngagementConsentInsertModel {
  constructor(public userEmail: string,
    public engagementId: number,
    public consentId: number,
    public isDeleted: boolean) {
  }
}

export class ConsentArray {
  engagementConsent: EngagementConsentInsertModel[] = [];
}

export class EngagementConsentModel {
  id: number;
  code: string;
  levelHeading: string;
  levelDescription: string;
  displayOrder: number;
  isDeleted: boolean;
}


<div class="message-dialog-wrapper" *ngIf="isHidden">
  <div class="message-dialog-container"
  [ngClass]="{'message-dialog-container-lr':  isConveneProjectsExists && !isUpdateQuestionnaireExecutionPhase && !isAdministrativeClose }">
    <div class="close-dialog" *ngIf="messageTitle != QuestionnaireDialogTitles.QueNewVersion && !isContinuanceEngagementExists 
    && messageTitle != workspaceInterviewsStatus" 
    (click)="closeDialog()">
      <span class="d-inline-block align-middle">Close</span>
      <span class="d-inline-block ml-1 align-top">
        <img src="../../../../assets/images/EDP/MessageDialog/close-24px.svg" alt="Close">
      </span>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <div class="message-background-icon {{messageType}}">
        <img src="../../../../assets/images/EDP/MessageDialog/{{messageType}}.svg" class="message-icon"
          alt="{{messageType}}" />
      </div>
    </div>
    <div class="d-flex justify-content-center message-title {{messageType}} pt-2" *ngIf="messageTitle != iWantToUnsavedMessageTitle"
    [ngClass]="{'message-title-update-question': isUpdateQuestionnaireExecutionPhase}"
    >{{messageTitle}}</div>
    <div class="d-flex justify-content-center message-title {{messageType}} pt-2" *ngIf="messageTitle == iWantToUnsavedMessageTitle"
    [ngClass]="{'message-title-update-question': isUpdateQuestionnaireExecutionPhase}"
    >{{unsavedMessageTtitle}}</div>
    
    <div class="message-description desc-height" *ngIf="!IsAbandonModal && !IsDeclineModal && !IsCloseEngagementModal && !IsBigDialogTitle && !isUpdateQuestionnaireExecutionPhase && !isContinuanceEngagementExists">
      {{messageDescription}}</div>
    <div class="message-description decline-height"
      *ngIf="!IsAbandonModal && !IsDeclineModal && !IsCloseEngagementModal && IsBigDialogTitle && !IsVoidEngagementModal && !isUpdateQuestionnaireExecutionPhase && !isContinuanceEngagementExists">
      {{messageDescription}}</div>
    
    <div class="message-description desc-height"    
     *ngIf="IsCloseEngagementModal && !isConveneProjectsExists">      
      You are about to close this engagement. Please make sure you have complied with the proper closure guidelines defined by your service line. 
      Once complete, click 'Ok' to close the engagement.
    </div>    
    
    <div class="message-description desc-height-sm"    
     *ngIf="IsCloseEngagementModal && isConveneProjectsExists">      
     You are about to close this engagement. Please make sure you have complied with the proper closure guidelines defined by your service line. 
     Closing this engagement will affect the following applications:
    </div>

    <div class="message-description desc-height"    
     *ngIf="IsVoidEngagementModal && !isConveneProjectsExists">      
     You are about to void this engagement. Please confirm any open assignment codes have been closed.
    </div>

    <div class="message-description desc-height-sm"    
     *ngIf="IsVoidEngagementModal && isConveneProjectsExists">      
     You are about to void this engagement. Please confirm any open assignment codes have been closed. 
     Voiding this engagement will delete the following Convene sites after 30 days:
    </div>
    
    <div class="message-description decline-height" *ngIf="IsDeclineModal && !isConveneProjectsExists">
      <span>Declining this engagement acceptance/continuance will reject the engagement. <span class="font-bold">This
          action cannot be undone. </span>All related files will be retained as read-only and ultimately deleted according
        to firm data retention policies.</span>
    </div>

    <div class="messsage-decline-reason ml-3 mr-3 pt-2" *ngIf="IsDeclineModal && !isConveneProjectsExists">
      <span class="smallFontSize14">Reason for decline </span>
      <input type="text" id="btnDeclineInputReason msg-dialog-declinedReason" class="form-control txtbox-height"
        [(ngModel)]="messageInput">
      <span class="error-color d-none" id="msg-dialog-declinedValidationText" *ngIf="IsDeclineModal && !isConveneProjectsExists">
        Please provide decline reason
      </span>
    </div>

    <div class="message-description decline-height" *ngIf="IsDeclineModal && isConveneProjectsExists">
      <span>Declining this engagement acceptance/continuance will reject the engagement. <span class="font-bold">This
          action cannot be undone. </span>All related files will be retained as read-only and ultimately deleted according
        to firm data retention policies.</span>
        <span>
          Declining the engagement may also affect the following applications:
        </span>
    </div>


    <div class="message-description desc-height"    
     *ngIf="IsAbandonModal && !isConveneProjectsExists">      
     This cannot be undone. This engagement will be marked as “Abandoned” and documents will become read-only.
      The engagement will be removed from Engage after 90 days.
    </div>

    <div class="message-description desc-height-sm"    
     *ngIf="IsAbandonModal && isConveneProjectsExists">      
     <span>This cannot be undone. This engagement will be marked as “Abandoned” and documents will become read-only.
        The engagement will be removed from Engage after 90 days.</span>
     <span> Abandoning the engagement may also affect the following applications:</span>
    </div>

    <div class="message-description desc-height"    
      *ngIf="isUpdateQuestionnaireExecutionPhase">      
      <b>You will only be able to update questions related to contract terms and conditions.</b> Updating the responses will reset the engagement to "Team review" and will have to be approved again.
     </div>

     <div class="message-description desc-height" *ngIf="searchClientReqModel != undefined && modalButtons==4">
      The duplicate results displayed below include accounts tracked in oneView. Clicking “Use selected” will return you to the Create new engagement page with this client. Selecting an account with no existing client code will replace all data in the form with available data from oneView for this account. If none of the duplicate results are desired, click the “Skip” button to continue as is.
    </div>

    <div class="message-description desc-height" *ngIf="searchClientReqModel != undefined && modalButtons==1">
      Information entered matches information used on existing clients. You have already selected a client from OneView. Please review your information to make sure this is a unique entry.
    </div>

    <div class="message-description" *ngIf="(IsCloseEngagementModal || IsDeclineModal || IsAbandonModal || IsVoidEngagementModal)  && isConveneProjectsExists">
      <div class="tableData2">
        <table class="m-0 p-0">         
          <thead>
            <tr>
              <th scope="col" class="table-sorted-desc pop-t-sm-col">Application</th>
              <th scope="col" class="table-sorted-desc pop-t-lr-col">Name</th>
              <th scope="col" class="table-sorted-desc pop-t-md-col">Contact</th>
            </tr>
          </thead>
          <tbody class="display">
            <tr *ngFor="let project of conveneProjectList">
              <td class="align-text-top" data-label="Application:">Convene</td>
              <td class="pr-2 align-text-top" data-label="Name:">{{project.projectName}}</td>
              <td class="pr-1 align-text-top" data-label="Contact:">
                {{project.contactName}}               
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
     </div>

    <div class="message-description" *ngIf="matchingClientList != undefined && matchingClientList.length > 0">
      <div class="matchingClientsData mb-3">
        <table class="mt-2 p-0">
          <thead>
            <tr>
              <th scope="col" class="table-sorted-desc pop-t-xs-col" *ngIf="modalButtons == 4"></th>
              <th scope="col" class="table-sorted-desc pop-t-sm-col" *ngIf="(matchingClientList[0].clientFieldName != 'gt_cmsaccountname')">{{clientHeaderModel.header0}}</th>
              <th scope="col" class="table-sorted-desc pop-t-sm-col">{{clientHeaderModel.header1}}</th>
              <th scope="col" class="table-sorted-desc pop-t-lr-col">{{clientHeaderModel.header2}}</th>
              <th scope="col" class="table-sorted-desc pop-t-md-col">{{clientHeaderModel.header3}}</th>               
            </tr>
          </thead>
          <tbody class="display">
            <tr *ngFor="let client of matchingClientList">
              <td class="align-text-top" data-label="Field Name:" *ngIf="modalButtons == 4">
                <input type="radio" name="requestClientOptions" id="AccId_{{client.accountid}}"
                  [value]="client.accountid" ng-control="requestTriggersOptions"
                  (change)="clickOnSelectedClient(client.accountid, client.accountnumber)">
              </td>
              <td class="pr-2 align-text-top" data-label="Client name:" *ngIf="(client.clientFieldName != 'gt_cmsaccountname')">{{client.gt_cmsaccountname}}</td> 
              <td class="align-text-top" data-label="Field Name:">{{client.clientFieldName == 'gt_cmsaccountname' ? client.gt_cmsaccountname : client.clientFieldSearchText}}</td>
              <td class="pr-2 align-text-top" data-label="Client Name:" style="color: #00a7b5;">{{client.name}}</td>
              <td class="pr-1 align-text-top" data-label="Cleint Code:">{{client.accountnumber}}</td>              
            </tr>
          </tbody>
        </table>
      </div>
      <span class="error-color d-none" id="msg-dialog-oneviewAccount-notselected">
        Please select oneView account to proceed. 
      </span>
      <span class="error-color d-none" id="msg-dialog-oneviewAccount-closedclient">
        Client is closed. Submit a Centralized Billing Share request to re-open the client code before creating a new client.
      </span>
    </div>

     <div class="message-description" *ngIf="IsCloseEngagementModal && isConveneProjectsExists">      
     Once complete, click 'Ok' to close the engagement.
    </div>
    <!-- <div class="messsage-decline-reason ml-3 mr-3 pt-2" *ngIf="IsDeclineModal && isConveneProjectsExists">
      <span class="smallFontSize14">Reason for decline </span>
      <input type="text" id="btnDeclineInputReason msg-dialog-declinedReason" class="form-control txtbox-height"
        [(ngModel)]="messageInput">
      <span class="error-color d-none" id="msg-dialog-declinedValidationText" *ngIf="IsDeclineModal && isConveneProjectsExists">
        Please provide decline reason
      </span>
    </div> -->
    <div class="messsage-decline-reason ml-3 mr-3 pt-2" *ngIf="(IsDeclineModal && isConveneProjectsExists) || IsVoidEngagementModal">
      <span class="smallFontSize14" *ngIf="IsDeclineModal">Reason for decline </span>
      <span class="smallFontSize14" *ngIf="IsVoidEngagementModal">Reason for voiding this engagement </span>
      <input type="text" id="btnDeclineInputReason msg-dialog-declinedReason" class="form-control txtbox-height"
        [(ngModel)]="messageInput">
      <span class="error-color d-none" id="msg-dialog-declinedValidationText" *ngIf="IsDeclineModal && isConveneProjectsExists">
        Please provide decline reason
      </span>
      <span class="error-color d-none" id="msg-dialog-declinedValidationText" *ngIf="IsVoidEngagementModal ">
        Please provide reason
      </span>
    </div>
    <div class="message-description" *ngIf="IsVoidEngagementModal">      
      Once complete, click 'OK' to void the engagement.
     </div>

     <div class="message-description desc-height"    
      *ngIf="isContinuanceEngagementExists">      
        <p>There is a record on the continuance dashboard for this client and primary solution code combination. If applicable, the CMS assignment code cannot be pre-requested if a new engagement is started.</p>
        <br>
        <p>Please check your
          <a  style="text-decoration: underline; cursor: pointer;" routerLink="{{continuanceDashBoardUrl}}" target="_self">continuance dashboard </a>
          or contact a member of the prior engagement team before proceeding.</p>
        <br>
        <p>Would you still like to create a new engagement?</p>
     </div>
     
     <div class="d-flex pr-3 pt-3" [ngClass]="{true: 'justify-content-end', false: 'justify-content-center'}[(modalButtons==4 || modalButtons==1) && searchClientReqModel != undefined]" [ngSwitch]="modalButtons">
      <div *ngSwitchCase="'2'">
        <button (click)="Cancel()" class="btn SecondaryButton rounded-0 {{messageType}} mr-4 ml-4">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/cancel-{{messageType}}-24px.svg" alt="CANCEL" />
          </span>
          <span class="d-inline-block align-middle">CANCEL</span>
        </button>
        <button (click)="Ok()" class="btn rounded-0 {{messageType}}">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
      <div *ngSwitchCase="'3'">
        <button (click)="Cancel()" class="btn rounded-0 SecondaryButton {{messageType}} mr-2">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/cancel-24px.svg" alt="Tertiary" />
          </span>
          <span class="d-inline-block align-middle">Tertiary</span>
        </button>
        <button (click)="Cancel()" class="btn rounded-0 SecondaryButton {{messageType}} mr-2">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/cancel-24px.svg" alt="Secondary" />
          </span>
          <span class="d-inline-block align-middle">Secondary</span>
        </button>
        <button (click)="Ok()" class="btn rounded-0 {{messageType}}">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="Primary" />
          </span>
          <span class="d-inline-block align-middle"> Primary</span>
        </button>
      </div>
      <div *ngSwitchCase="'4'">
        <button (click)="Skip()" class="btn SecondaryButton rounded-0 {{messageType}} mr-4">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/cancel-{{messageType}}-skip-24px.svg" alt="CANCEL" />
          </span>
          <span class="d-inline-block align-middle">Skip</span>
        </button>
        <button (click)="Ok()" class="btn rounded-0 {{messageType}}">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
          </span>
          <span class="d-inline-block align-middle">Use selected</span>
        </button>
      </div>
      <div *ngSwitchCase="'1'">
        <button (click)="Ok()" class="btn rounded-0 {{messageType}}">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
      <div *ngSwitchDefault>
        <button (click)="Ok()" class="btn rounded-0 {{messageType}}">
          <span class="d-inline-block mr-1 align-top">
            <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>    
  </div>
</div>

<section class="filter-wrapper">
  <div class="row">
    <div class="col-12 col-xs-12 col-md-12 col-lg-12 pr-0 pl-0">
      <label>Enter search term</label>
      <span class="d-flex">
        <input #autoSearch type="text" id="txtValue{{MappedSource}}{{Sequence}}" name="txtValue{{MappedSource}}{{Sequence}}"
          autocomplete="off" value="" [name]="name" [(ngModel)]="value"
          class="form-control rounded-0 textBoxDesign input-height"
          (keydown.enter)="onSearch(autoSearch.value, $event);$event.preventDefault();" maxlength="40"
          placeholder="{{clientNamePlaceholderText}}" (focus)="this.clientNamePlaceholderText=''"
          (blur)="this.clientNamePlaceholderText = 'Enter a client name'"
          (keyup)="onSearch(autoSearch.value, $event);$event.preventDefault();"
          (blur)="onSearch(autoSearch.value, $event);$event.preventDefault();" />
        <span class="link-oneview-check hide" (click)="SkipAccountSearch()">Skip</span>
        <span class="icon-dupe-check-search" id="DuplicateCheckOneviewAccountSearch" (click)="onSearch(autoSearch.value, $event);$event.preventDefault();"></span>
      </span>
      <div class="icon-container">
        <em id='inlineLoader' class=""></em>
      </div>
      <input type="hidden" id="hdnKey{{MappedSource}}{{Sequence}}" />
      <input type="hidden" id="hdnKeyCode{{MappedSource}}{{Sequence}}" />
    </div>
  </div>
  <div class="row filter-list">
    <div class="col-12 col-xs-12 col-md-12 col-lg-12">
      <div class="filter-select {{MappedSource}}{{Sequence}} p-0">
        <div class="row w-100 oneview-client-smart-search-header">
          <div class="col-md-2 pr-0 mr-0" style="max-width: 10%;"></div>
          <div class="col-md-2 font-weight-bold text-decoration-underline">Account Name</div>
          <div class="col-md-2 font-weight-bold text-decoration-underline">Client Name</div>
          <div class="col-md-2 font-weight-bold text-decoration-underline">AKA/DBA</div>
          <!-- <div class="col-md-1 font-weight-bold text-decoration-underline">Client Code</div> -->
          <div class="col-md-2 font-weight-bold text-decoration-underline">Headquarters</div>
          <div class="col-md-2 font-weight-bold text-decoration-underline">Web site</div>          
        </div>
        <div class="row w-100" *ngFor="let client of clientList">
          <div class="col-md-2 pr-0 mr-0" style="max-width: 10%;"><span class="usethislink" (mousedown)="selectedValue($event, client)">Use this one</span></div>
          <div class="col-md-2" innerHTML="{{ client.gt_cmsaccountname | highlight : autoSearch.value}}"></div>
          <div class="col-md-2" innerHTML="{{ client.name  | highlight : autoSearch.value}} {{  client.accountnumber==null ?'': '(' +client.accountnumber+ ')' | highlight : autoSearch.value}}"></div>
          <div class="col-md-2" innerHTML="{{ client.hs_akadba | highlight : autoSearch.value}}"></div>
          <!-- <div class="col-md-1" innerHTML="{{ client.accountnumber | highlight : autoSearch.value}}">
          </div> -->
          <div class="col-md-2" innerHTML="{{ client.customeHeadquarter | highlight : autoSearch.value}}"></div>
          <div class="col-md-2" innerHTML="{{ client.websiteurl | highlight : autoSearch.value}}"></div>          
        </div>
        <div class="row w-100" *ngIf="clientList.length === 0">
          <div class="col-md-12 text-center pt-3">No results found in oneView</div>
        </div>
      </div>
    </div>
  </div>
</section>
export class SolutionCodeMappingModel {

    public solutionCodeId: number;

    public secodarySolutionCodeIdList: string = "";

    public solutionCode: string ='';

    public solutionCodeDescription: string ="";

    public solutionCodeLongName: string;

    public solutionCodeType : string;

    public solutionCodeTypeId: number;

    public SolutionCodeStatusId: number;

    public status: string;

    public isAudit: boolean;

    public isTax: boolean;

    public isAdvisory: boolean;

    public secodarySolutionCodes: string;

    public secodarySolutionCodesCount: number;

    public secodarySolutionCodeIds: string;

    public statusDesc: string;

    // tslint:disable-next-line: variable-name
    public re_acceptance_eligibility: boolean = false;

    public isPeriodRequire: boolean = false;

    public fye_tye: string;

    public userId: string;

    public isAllAdvisory: boolean = false;

    public isAllAudit: boolean = false;

    public isAllTax: boolean = false;

    public serviceLinesText: string;

    public SecondarySolutionCodesArr: string[];

    public uponApproval: boolean;

    public onSignature: boolean;
    
    public isGrossMarginRequire: boolean = false;
    
    public carryForwadIndusConsent: boolean = false;

    public preRequestDuringContinuance: boolean = false;

    public isEngagementStartDateRequired: boolean = false;

    public isEngagementEndDateRequired: boolean = false;
    public isAttest: number = 0;
    public isAttested?: number = 0;
    
    public updateQuestionnaireAfterAcceptance: boolean = false;
    public closurepromttargetdays: string = "";
    public frequencyofprompt: string = "";
    public allowMultipleTemplates: boolean = false;
    public noTimeChargedDays :string="";
    public IsPreRequestOptedOut: boolean = false;
}

export class SolutionCodeMasterModel {

    public solutionCodeId: number;

    public solutionCode: string = "";

    public solutionCodeDescription: string ="";

    public isvalid : boolean = true;
}

export class SolutionCodeStatusModel {
    id: number;
    typeValue: string;
    isChecked: boolean;
}

export class arrayMultiSelect {
    public item_id: string;
    public item_text: string;
}
export class RequestTriggerOptions{
  optionValue: number;
}

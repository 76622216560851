export class EngagementClientVoice {
    engagementClientVoiceId: number;
    engagementId: number;
    engagement_ChangedName: string;
    surveyDate: Date;
    isSurveyOptOut:boolean;
    optOutReason: string;
    delegateName: string;
    delegateEmailId: string;
    createdBy: string;
    createdDate: Date;
    CreatedByName:  string;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    lastModifiedByName: string;
    isCompleted: boolean;
}

export class EngagementClientVoiceTeamMember {
        engagementClientVoiceTeamMemberId: number;
        engagementClientVoiceId: number;        
        firstName: string;  
        lastName: string;    
        memberEmail: string;    
        memberTitle: string;
        contactGuid: string;
        createdBy: string;
        createdOn: Date;
        lastModifiedBy: string;
        lastModifiedDate: Date;
}

export class PreviousClientVoiceSurveyRequestModel {
    clientName: string;
    clientCode: string;
    engagementClientVoiceServiceLineDetails: EngagementClientVoiceServiceLineDetail;
    engagementClientVoice:EngagementClientVoice;
}

export class EngagementClientVoiceServiceLineDetail 
{
    engagementId: number;
    solutionCodeId: number;
    solutionCode: string;
    solutionCodeDescription: string;
    isTax: boolean;
    isAudit: boolean;
    isAdvisory: boolean;
    engagementName: string;
    engagementPartner: string;
    engagementPartnerName: string;
    clientName: string;
    clientCode: string;
}

export class EngagementClientVoiceDetailViewModel
{
    engagementClientVoice: EngagementClientVoice;
    engagementClientVoiceTeamMemberList: EngagementClientVoiceTeamMember[];   
    engagementClientVoiceServiceLineDetails: EngagementClientVoiceServiceLineDetail; 
}

export class EngagementClientVoiceUserAccess
{
    UserName: string;    
    UserEmail: string;
}
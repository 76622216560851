export class ClientSearch {
    clientId: number;
    clientName: string;
    clientCode: string;
    clientStatus: string;
    oneViewAccountId: string;
    websiteAddress: string;
    city: string;
    stateCode: string;
    customeHeadquarter: string;
}

<div *ngFor="let alert of alertsService.alerts">
    <div class="alert alert-{{alert.messageType | lowercase}} alert-dismissible fade show" role="alert">
      <span class="msg-icon">
        <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="">
      </span>
      <span class="d-inline-block align-middle msg-text">
        {{alert.message}}
      </span>
      </div>
  </div>

export class TeamMemberPermission {
  id: number;
  typeValue: string;
}

export class ClientMemberPermission {
  id: number;
  typeValue: string;
}

export class ClientMemberPermissionModel {
  id: number;
  code: string
  typeValue: string;
}

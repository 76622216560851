import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AzureADUsersList } from 'src/app/model/AzureADUserList';
import { ASQMember, ASQMemberModel, AzureADGroups, AzureADUserRoleGroup } from 'src/app/model/AzureADUsersArrayList';
import { DueDateExtensionRequestModal } from 'src/app/model/Engagement/EngagementDueDateExtension';
import { RoleNames } from 'src/app/model/Role';
import { solutionCodePSDMaping } from 'src/app/model/SolutionCodePSD';
import { AdUsersService } from 'src/app/services/adusers.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EngagementService } from 'src/app/services/EngagementService';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { LogService } from 'src/app/services/log.service';
declare var $;

@Component({
  selector: 'app-engagement-duedate-extension',
  templateUrl: './engagement-duedate-extension.component.html',
  styleUrls: ['./engagement-duedate-extension.component.css']
})
export class EngagementDuedateExtensionComponent implements OnInit {
  extension_EngagementId: number;
  ExtensionDate: string;
  extensionDueDateModal: DueDateExtensionRequestModal = new DueDateExtensionRequestModal();
  extensionDueDateModalOriginal: DueDateExtensionRequestModal = new DueDateExtensionRequestModal();
  userObject: any;
  isEPPMDApprovedExtension: boolean = false;
  isInvalidValuesPresent: boolean = false;
  errorDescription: string;
  extension_Count: number;
  closOutDueDate_Original: Date;
  PSDMapingList: solutionCodePSDMaping[] = [];


  @Input() set engagementId(id: number) {
    if (id !== undefined && id !== null && id > 0) {
      this.extension_EngagementId = id;
    }
  }

  @Input() set countOfExtensions(id: number) {
    if (id !== undefined && id !== null) {
      this.extension_Count = id;
    }
  }

  @Input() set closeoutDueDateOriginal(id: Date) {
    if (id !== undefined && id !== null) {
      this.closOutDueDate_Original = id;
    }
  }

  @Input() solutionCodeId: number;



  @Output() closeEngagementDuedateExtensionComponent: EventEmitter<boolean> = new EventEmitter<boolean>();

  azureADUserRoleGroupList: AzureADUserRoleGroup[] =[];  
  aSQMemberModel: ASQMemberModel= new ASQMemberModel();
  constructor(
    private loadingScreenService: LoadingScreenService,
    private engagementService: EngagementService,
    private logService: LogService,
    public datepipe: DatePipe,
    private configureService: ConfigurationService,
    private graphSerivce: GraphService,
    private adUsersService: AdUsersService

  ) { }

  ngOnInit(): void {
    this.ExtensionDate = 'ExtensionDate';
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    this.getPSDMembersDetails();

  }

  closeEngagementDuedateExtensionComponentModal() {
    this.extensionDueDateModal = null;
    $('#engagement-duedate-extension').modal('hide');
    this.loadingScreenService.stopLoading();
    this.closeEngagementDuedateExtensionComponent.emit(true);
  }

  showEngagementDuedateExtensionComponentModal() {
    $('#engagement-duedate-extension').modal({
      backdrop: false,
      show: true
    });
    $('#engagement-duedate-extension').modal('show');
  }

  SendExtensionRequest() {
    this.loadingScreenService.startLoading();
    this.extensionDueDateModal.engagementId = this.extension_EngagementId;
    this.extensionDueDateModal.extensionDueDate = $('.ExtensionDate').val();
    this.extensionDueDateModal.requestedByEmail = this.userObject.loggedInUserEmail;
    this.extensionDueDateModal.requestedByName = this.userObject.loggedInUserFullName;
    this.extensionDueDateModal.status = 'Pending';
    this.extensionDueDateModal.aSQMemberModel = this.aSQMemberModel;
    
    this.BusinessValidation();
    if (this.extension_Count == 0) {
      this.extensionDueDateModalOriginal.engagementId = this.extension_EngagementId;
      this.extensionDueDateModalOriginal.extensionDueDate = this.closOutDueDate_Original;
      this.extensionDueDateModalOriginal.requestedByEmail = this.userObject.loggedInUserEmail;
      this.extensionDueDateModalOriginal.requestedByName = this.userObject.loggedInUserFullName;
      this.extensionDueDateModalOriginal.status = 'Original';
      this.extensionDueDateModalOriginal.extensionReason = 'Original record';
      this.extensionDueDateModalOriginal.aSQMemberModel = undefined;

      this.SendExtensionRequestApiCall(this.extensionDueDateModalOriginal);
    }
    this.SendExtensionRequestApiCall(this.extensionDueDateModal);




  }


  BusinessValidation() {
    this.isInvalidValuesPresent = false;

    var currentDate = new Date();
      //Eppmd approval validation
      if (this.isEPPMDApprovedExtension) {
        if (this.extensionDueDateModal != null && this.extensionDueDateModal != undefined) {
          //EngagementId validation
          if (this.extensionDueDateModal.engagementId == null || this.extensionDueDateModal == undefined || this.extensionDueDateModal.engagementId <= 0) {
            this.isInvalidValuesPresent = true;
            this.errorDescription = 'Invalid engagement id.';
            return;
          }


          //Extension due date validation
          if (this.extensionDueDateModal.extensionDueDate == null || this.extensionDueDateModal.extensionDueDate == undefined) {
            this.isInvalidValuesPresent = true;
            this.errorDescription = 'Invalid extension due date.';
            return;
          } else {
            if ($('.ExtensionDate').val() == '') {
              this.isInvalidValuesPresent = true;
              this.errorDescription = 'Extension due date required.';
              return;
            }
            let date1 = new Date(this.extensionDueDateModal.extensionDueDate);
            let same = date1.getTime() === currentDate.getTime();
            if (same || date1 < currentDate) {
              this.isInvalidValuesPresent = true;
              this.errorDescription = 'Extension due date must be greater than current date.';
              return;
            }

            let date2 = new Date(this.extensionDueDateModal.extensionDueDate);
            let same2 = date1.getTime() === currentDate.getTime();
            if (same2 || date2 < new Date(this.closOutDueDate_Original)) {
              this.isInvalidValuesPresent = true;
              this.errorDescription = 'Extension due date must be greater than closeout due date.';
              return;
            }


          }


          //Requested user validation
          if (this.extensionDueDateModal.requestedByEmail == null || this.extensionDueDateModal.requestedByEmail == undefined || this.extensionDueDateModal.requestedByEmail == '') {
            this.isInvalidValuesPresent = true;
            this.errorDescription = 'Invalid logged in user.';
            return;
          }

          //Extension reason validation
          if (this.extensionDueDateModal.extensionReason == null || this.extensionDueDateModal.extensionReason == undefined || !this.extensionDueDateModal.extensionReason.trim()) {
            this.isInvalidValuesPresent = true;
            this.errorDescription = 'Invalid extension reason.';
            return;
          }




        }
        else {
          this.isInvalidValuesPresent = true;
          this.errorDescription = 'Extension details incorrcet.';
        }

      }
      else {
        this.isInvalidValuesPresent = true;
        this.errorDescription = 'EPPMD approval required.';
      }   
  }


  
  GetASQMembers() {
    this.loadingScreenService.startLoading(); 
    this.adUsersService.GetAzureQualityADGroupsByRoleShortName(RoleNames.QualityGroup)
      .subscribe(
        result => {
          this.azureADUserRoleGroupList = result.azureADUsers;          
          if (this.azureADUserRoleGroupList.length > 0)  {
            console.log('azureADUserRoleGroup', this.azureADUserRoleGroupList);
          }
         let onlyIsAdvisoryGroupList = this.azureADUserRoleGroupList.filter(ex => ex.isAdvisory);
           
           let qaulityGroups: AzureADGroups = new AzureADGroups();
           let azureADUsersList: AzureADUsersList[] =[];  
           qaulityGroups.roleName = RoleNames.QualityGroup;
           onlyIsAdvisoryGroupList.forEach(grp => {            
            let grpItem : AzureADUsersList = new AzureADUsersList() ;
            grpItem.adDetaiId = grp.adDetaiId;
            grpItem.azureADMappedRoleName = grp.azureADMappedRoleName;
            grpItem.isAdvisory = grp.isAdvisory;
            grpItem.isAttest = grp.isAttest;
            grpItem.isAudit = grp.isAudit;
            grpItem.isTax = grp.isTax;
            grpItem.roleId = grp.roleId;
            azureADUsersList.push(grpItem);
          })            
          
          if (azureADUsersList.length > 0) {
            qaulityGroups.azureADUsers = azureADUsersList;
            this.graphSerivce.getAzureAdUsersByGroups(qaulityGroups, RoleNames.QualityGroup)
              .subscribe(
                qaulityGroupsUserList => {                 
                  this.loadingScreenService.stopLoading();

                  let aSQMembers: ASQMember[] = [];
                  qaulityGroupsUserList.forEach(asq => {            
                    let aSQMember: ASQMember = new ASQMember();
                    aSQMember.aSQName  = asq.displayName;
                    aSQMember.aSQEmailId = asq.mail;
                    aSQMembers.push(aSQMember);
                  }) 
                  this.aSQMemberModel.aSQMembersList = aSQMembers;   
                  console.log('qaulityGroupsUserList ==>', this.aSQMemberModel);               
                });
          }          
        },
        () => {          
          this.loadingScreenService.stopLoading();         
        }
      );
  }

  SendExtensionRequestApiCall(extensionData: DueDateExtensionRequestModal) {
    if (this.isEPPMDApprovedExtension && !this.isInvalidValuesPresent) {
      this.engagementService.ADDUpdateExtensionDueDateRequest(extensionData).subscribe(result => {
        if (result === 'Success') {
          this.isInvalidValuesPresent = false;
          this.closeEngagementDuedateExtensionComponentModal();
        }
        else {
          this.loadingScreenService.stopLoading();
          this.isInvalidValuesPresent = true;
          this.errorDescription = 'Error while sending the extension request.';
        }

      },
        err => {
          this.logService.error(err);
          this.isInvalidValuesPresent = true;
          this.errorDescription = 'Error while sending the extension request.';
          this.loadingScreenService.stopLoading();
        });
    } else {
      this.loadingScreenService.stopLoading();
    }


  }

  getPSDMembersDetails() {
    this.configureService.getPSDMappingData(this.solutionCodeId).subscribe(result => {
      if (result != null) {
        this.PSDMapingList = result;
      }
    if (this.PSDMapingList.length ==0 || this.PSDMapingList ==undefined || this.PSDMapingList==null)
      this.GetASQMembers();
    }, error => {
      this.loadingScreenService.stopLoading();
    });
  }




}

export class Applicationmaster {
  applicationMasterId: number;
  applicationCode: number;
  applicationName: string;
  code: string;
  value: string;
  active: boolean;
  displayOrder: number;
  createdBy: string;
  createdOn: string;
  lastModifiedBy: string;
  lastModifiedOn: string;
}

export enum AppMasterCodes {
  PUBCO = 'PUBCO',
  SECAU = 'SECAU',
  SOW = 'SOW',
  MSA = 'MSA',
  EngagementsLetters = 'EngagementsLetters'
}

export class CalendarDays {
  id: number;
  value: number;
}
export enum ApplicationName {
  ClientType = 1,
  ClientProgram = 2,
  IndustryType = 3,
  StockExchange = 4,
  ReferralSource = 5,
  SolutionCodeType = 6,
  SolutionCodeStatus = 7,
  ClientStatus = 8,
  TeamMemberRole = 9,
  SubDocumentType = 11,
  TeamMemberPermission = 13
}


export class AppCodes{
  code: string;
  codeValue: number;
}

import { EngagementService } from '../../../services/EngagementService';
import { Assignments } from '../../../model/assignments.model';
import { Clients } from '../../../model/clients';
import { ClientService } from '../../../services/clientservice';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-review-engagement',
  templateUrl: './review-engagement.component.html',
  styleUrls: ['./review-engagement.component.css']
})
export class ReviewEngagementComponent implements OnInit, AfterViewInit {

  @Input() lastUpdatedBy: string;
  // tslint:disable-next-line: variable-name
  private _clientId = 0;
  @Input() set clientId(clientId: number) {
    if (clientId !== undefined && clientId !== null && clientId > 0) {
      this._clientId = clientId;
      this.getClientDetails();
    }
  }

  // tslint:disable-next-line: variable-name
  private _engagementId = 0;
  @Input() set engagementId(engagementId: number) {
    if (engagementId !== undefined && engagementId !== null && engagementId > 0) {
      this._engagementId = engagementId;
      this.getEngagementAssignments();
    }
  }
  client: Clients = null;
  assignmentsList: Assignments[] = [];
  selectContactEmailId: string;
  controlPopoverId: string;

  constructor(private clientService: ClientService, private engagementService: EngagementService) { }

  ngOnInit() {
  }

  getClientDetails() {
    this.clientService.getClientInfo(this._clientId).subscribe(result => {
      this.client = result;
    });
  }

  getEngagementAssignments() {
    this.engagementService.GetReviewEngagementAssignmentsById(this._engagementId).subscribe(result => {
      this.assignmentsList = result;
    });
  }
  
  displayContactPopover(selectContactEmailId: string, controlPopoverId: string) {
    this.selectContactEmailId = selectContactEmailId;
    this.controlPopoverId = controlPopoverId;
    this.hidePopUp();
    this.bindContactPopup();
    this.checkForContactContent('contact-popup');
    $('#' + this.controlPopoverId).popover('show');

  }
  checkForContactContent(contact) {
    const timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 900);
  }

  hidePopUp() {
    $('.contactUser').popover('hide');
  }

  bindContactPopup() {
    $('.contactUser').popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',    
      boundary: 'viewport',
      content: function () {
        return '<div class="mx-auto" style="width: 200px;">Loading ...</div>';
      }
    });
  }
  ngAfterViewInit() {
    $('html').on('click', function (e) {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        $('.contactUser').popover('hide');
      }
    });

    $('body').on('click', (e) => {
      //did not click a popover toggle or popover
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          $('.contactUser').popover('hide');

        }
      }
    });
  }
}

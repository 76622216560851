import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from '../services/workspace.service';
import { PrimaryWorkspaceModel } from '../model/Engagement/CreateEngagementData';
import { Employee } from '../model/Workspace/Employee';
import { SearchEmployeeModel } from '../model/Workspace/SearchEmployeeModel';
import { AssignmentRequestList } from '../model/Workspace/AssignmentRequestList';
import { AlertsService } from '../services/alerts.service';
import { Router } from '@angular/router';
import { CMSService } from '../services/cmsservice.service';
import { GraphService } from '../services/Integration/graphservice.service';
import { LoadingScreenService } from '../services/loading-screen.service';
import { AssignmentResponseList } from '../model/Workspace/AssignmentResponse';
import { Alert } from '../model/Alert';

@Component({
  selector: 'app-process-cms-error-assignment',
  templateUrl: './process-cms-error-assignment.component.html',
  styleUrls: ['./process-cms-error-assignment.component.css']
})
export class ProcessCmsErrorAssignmentComponent implements OnInit {

  alert: Alert = new Alert();

  constructor(private workspaceService: WorkspaceService,
    private _alertService: AlertsService,
    private route: Router,
    private cmsService: CMSService,
    private graphSerivce: GraphService,
    private loadingScreenService: LoadingScreenService) { }

  workspaceId: string;
  workspaceModel: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  employeeList: Employee[] = [];
  userInfo: any;
  placeholder: string = "Enter review id";

  ngOnInit() {
    this._alertService.remove(this.alert);
  }
  processCMSErrorRequest() {
    this._alertService.remove(this.alert);
    this.workspaceService.ProcessCMSErrorRequest(this.workspaceModel.workspaceId).subscribe(
      result => {
        console.log('process assignment request result : ', result);
        if (result) {
          this.onApprove();
        } else {
          this._alertService.remove(this.alert);
          const message = 'The engagement could not be processed for CMS request as it did not satisfy the needed criteria';
          console.error(message);
          this._alertService.add(message, 'danger', null);
        }
      },
      error => {
        console.error('Error processing process cms error request ', error);
      });
  }

  onApprove() {
    this.workspaceService.CheckRequestSentForClient(this.workspaceModel.workspaceId.toString(), 0, 1).subscribe(result => {
      if (result === 1) {
        this.loadingScreenService.startLoading();
        //passing second parameter(assignmentCodeId) as 0 in review case
        this.workspaceService.getPrimaryAssignment(this.workspaceModel.workspaceId.toString(), null, null)
          .subscribe(
            assignmentRequestList => {
              if (assignmentRequestList.length > 0) {
                for (const assignmentRequest of assignmentRequestList) {
                  console.log('data from getPrimaryAssignment ', assignmentRequest);

                  if (!this.checkUserExistance(assignmentRequest.initiated_By_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.initiated_By_Email));
                  }
                  if (!this.checkUserExistance(assignmentRequest.client_Administrator_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.client_Administrator_Email));
                  }
                  if (!this.checkUserExistance(assignmentRequest.assignment_Administrator_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.assignment_Administrator_Email));
                  }
                  if (!this.checkUserExistance(assignmentRequest.engagement_Partner_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.engagement_Partner_Email));
                  }
                }

                console.log('To get the employee ids for given user list ', this.employeeList);

                const searchEmployee = new SearchEmployeeModel();
                searchEmployee.employees = this.employeeList;

                this.graphSerivce.GetEmployeeIdForUserList(searchEmployee).subscribe(
                  finalEmployeeList => {

                    console.log('API response for employee ids for given user list ', finalEmployeeList);
                    for (const assignmentRequestInner of assignmentRequestList) {
                      assignmentRequestInner.initiated_By
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.initiated_By_Email)
                          .employeeId;
                      assignmentRequestInner.client_Administrator
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.client_Administrator_Email)
                          .employeeId;
                      assignmentRequestInner.assignment_Administrator
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.assignment_Administrator_Email)
                          .employeeId;
                      assignmentRequestInner.engagement_Partner
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.engagement_Partner_Email)
                          .employeeId;

                      console.log('Updated Assignment Request ', assignmentRequestList);
                    }
                    const request = new AssignmentRequestList();
                    request.list = assignmentRequestList;
                    this.cmsService.PostPrimaryAssignment(request).subscribe(
                      assignmentResponse => {
                        let responseList = new AssignmentResponseList();
                        responseList.list = assignmentResponse;
                        console.log('Successfully submission of CMS Primary Assignment.', assignmentResponse);
                        this.workspaceService.storeCMSLog(responseList).subscribe(
                          logResult => {

                            for (let i = 0; i < assignmentResponse.length; i++) {
                              this.workspaceService
                                .updateFirstAssignmentStatus(assignmentResponse[i].responseStatus, assignmentResponse[i].assignmentCodeId)
                                .subscribe(
                                  statusResult => {
                                    console.log(statusResult);
                                  }
                                );
                            }

                            let finalStatus = '';
                            finalStatus = assignmentResponse.some(x => x.requestStatus == "Failure") ? "Failure" : "Success";
                            this._alertService.remove(this.alert);
                            const message = finalStatus == 'Failure'
                              ? 'The engagement could not be processed for CMS request'
                              : 'The engagement is successfully processed for CMS request';
                            const mType = finalStatus == 'Failure' ? 'danger' : 'success';
                            this._alertService.add(message, mType, null);

                            console.log('storeCMSLog func response ', logResult);
                          },
                          logError => {
                            this.loadingScreenService.stopLoading();
                            console.log('Error in getting the response in storeCMSLog in EDP API ', logError);
                          }

                        );
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        console.log('Error in getting the response in PostPrimaryAssignment in CMS Integration API ', error);
                      }
                    );
                  },
                  error => {
                    this.loadingScreenService.stopLoading();
                    console.log('Error in getting the response in GetEmployeeIdForUserList in Graph API ', error);
                  }
                );
              }
              else {
                console.log('No assignment found. So redirect to my engagements.');
                this.loadingScreenService.stopLoading();
                this.route.navigate(['/MyEngagements']);
              }

            },
            error => {
              this.loadingScreenService.stopLoading();
              console.log('Error in getting the response in getPrimaryAssignment in API ', error);
            });
        this.loadingScreenService.stopLoading();

      } else {
        if (result === 2) {
          this._alertService.remove(this.alert);
          const message = 'The engagement marked as E-Sign then CMS Request cannot be sent.';
          console.error(message);
          this._alertService.add(message, 'danger', null);
        }
      }
    },
      err => {
        this.loadingScreenService.stopLoading();
        console.error('Error processing CheckRequestSentForClient ', err);
      });
  }

  checkUserExistance(email: string): boolean {
    return this.employeeList.some(r => r.employeeEmailId === email);
  }
}

import { Component, ComponentFactoryResolver, HostListener, OnInit, ViewContainerRef ,ElementRef,ViewChild} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { of, Observable, Subscription, forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EngagementService } from 'src/app/services/EngagementService';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { DecisionsRuleEngineService } from 'src/app/services/Integration/decisions-rule-engine.service';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';
import { AdUsersService } from 'src/app/services/adusers.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { CollaborationSiteService } from 'src/app/services/collaboration-site.service';
import { DataService } from 'src/app/services/data.service';
import { DataModificationService } from 'src/app/services/datamodification.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ReacceptanceClientsService } from 'src/app/services/reacceptanceClientsService';
import { SharepointService } from 'src/app/services/sharepoint.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { WorkspaceReviewPointService } from 'src/app/services/workspaceReviewPointService';
import { Alert } from 'src/app/model/Alert';
import { QuestionnaireDialogTitles, WorkspaceInterviewsStatus, WorkspaceModel } from 'src/app/model/WorkspaceModel';
import { DataModificationModuleNames, EngagementDialogTitles } from 'src/app/model/Engagement/Engagements';
import { EngagementList } from 'src/app/model/EngagementListModel';
import { SPDocumentList, SPSiteMetadata } from 'src/app/model/SharePoiint/SharePointSite';
import { Applicationmaster } from 'src/app/model/ApplicationMaster';
import { ContinuanceEngagementWorkItemModel, ContinuanceStatus } from 'src/app/model/ReacceptanceClientListModel';
import { HDQuestionBankData, HDQuestionnaireModel } from 'src/app/model/HDWorkItemModel';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { ReviewResponseModel } from 'src/app/model/Workspace/ReviewResponseModel';
import { ChangeOrderService } from 'src/app/services/change-order.service';
import { ChangeOrderModel } from 'src/app/model/change-order/change-order-model';
import { EngagementChangeOrdersModel } from 'src/app/model/change-order/engagement-change-orders-model';
import { CLMService } from 'src/app/services/clm.service';
import { GoverningAgreementNameAndStatusRequest } from 'src/app/model/CLM/governing-agreemen-request';
import { GoverningAgreementNameAndStatusResponse } from 'src/app/model/CLM/governing-agreement-response';
import { parse } from 'path';
import { CLMApiLog } from 'src/app/model/CLM/clm-api-log';
import { CLMApiLogrRequestType } from 'src/app/model/enums/clm-api-log-request-type.enum';

import { KeyValue } from 'src/app/model/KeyValue';
import { SharePointWrapper } from 'src/app/model/sharepoint-metadata-wrapper';
import { HotDocsVariableMapping, HotDocsVariableMappingList, HDSessionAnsToEngModel } from 'src/app/model/HotDocsVariableMapping';
import { SharePointMetadataMapping } from 'src/app/model/sharepoint-metadata-mapping';

declare var HD$;
declare var $;

@Component({
  selector: 'app-change-order-interview',
  templateUrl: './change-order-interview.component.html',
  styleUrls: ['./change-order-interview.component.css']
})
export class ChangeOrderInterviewComponent implements OnInit {

  workspaceOpenReviewPointsCount: number;
  InterviewList: InterviewModel;
  HDInProgressAlertLimitInHours: any;
  interviewOptions: any;
  IntegrationAPIEndPoint: string;
  SaveSessionFlagCheck = false;
  TemplatePackageDetails: TemplatePackageModel = new TemplatePackageModel();
  isInterviewLoaded: boolean = false;

  CurrentWorkspaceId: string;
  isCurrentWorkspacePrimary: boolean = true;
  primaryWorkspaceId: string = null;
  WorkspaceDetails: WorkspaceModel = new WorkspaceModel();
  alert: Alert;
  myQuestionnaireLoadingInterval: any = null;
  CurrentInterviewsStatus: any;
  CurrentWorkspaceStatus = '';
  engagementId = 0;
  isInterviewsScreen = false;
  isHiddenProgressModal: boolean = false;
  QuestionnaireInProgressHDSession: boolean = false;
  lastdateTime: any;
  currentEngagement: EngagementList = new EngagementList();
  AppliedTnC: boolean = false;
  sharePointURL = '';
  applyFilterOnceAfterDataLoad: boolean;
  DataMessage: string;

  supplementalDocCount: number = null;
  supplementalDocumentList: SPDocumentList[] = [];
  supplementalDocCountOnLoad: number = 0;
  isSharepointFileLabelFeatureFlagEnabled: boolean = false;
  sharePointSelectedFileLabel = ""
  sharePointSelectedFileLabelForFilter = "All";
  sharePointSelectedFile: SPDocumentList;
  sharePointApplicationMasterLabels: Applicationmaster[] = [];
  sharePointApplicationMasterLabelsFiltered: Applicationmaster[] = [];
  sharePointAddFileFlag: boolean = false;

  continuanceWorkItemData: ContinuanceEngagementWorkItemModel = new ContinuanceEngagementWorkItemModel();
  isContinuanceEligible = false; // this need to revisist
  redirectedFromEditEngagement = false; // this need to revisist

  displayNewVersionDialog = true;
  questionBankData: HDQuestionBankData = new HDQuestionBankData();
  questionnaireModel: HDQuestionnaireModel = new HDQuestionnaireModel();
  isTemplateVersionChanged: boolean = false;

  userInfo: any;
  private modalConfirmationDialogRef: Subscription = null;
  engagementName: string;
  engagementChangeOrders: EngagementChangeOrdersModel;
  changeOrders: ChangeOrderModel[];
  selectedChangeOrderId: number;
  parentEngagementId: number = 0;
  isRedirectedFromCreateDoc: boolean;
  FinishSessionFlagCheck: boolean = false;
  ForceUserToCompleteSession: boolean = false;
  EditEngagementBtnClicked: boolean = false;
  forceCompleteSession_RequestedNavigationUrl: string;
  hdVariableMappingData: HotDocsVariableMapping[] = [];
  hdVariableMappingList: HotDocsVariableMappingList = new HotDocsVariableMappingList();
  hdSessionAnswersToEngage: HDSessionAnsToEngModel = new HDSessionAnsToEngModel();
  @ViewChild('clmGoverningAgreementStatusDiv') clmGoverningAgreementStatusDiv: ElementRef;
  isCLMFeatureEnabled: boolean = false;
  gaNameAndStatusResponse: GoverningAgreementNameAndStatusResponse;
  gaNameAndStatusRequest: GoverningAgreementNameAndStatusRequest;
  gaNameAndStatusRequestTimmerFlag: boolean = false;

  private modalFinishDialogRef: Subscription = null;
  dialogTitle = '';
  dialogButtonName = '';

  savebuttonClicked: boolean = false;


  constructor(private hdService: HotDocsService,
    private route: Router,
    private workspaceService: WorkspaceService,
    private applicationMasterService: ApplicationMasterService,
    private environment: AppConfigService,
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private loadingService: LoadingScreenService,
    private alertService: AlertsService,
    private reacceptanceService: ReacceptanceClientsService,
    private engService: EngagementService,
    private sharepointService: SharepointService,
    private data: DataService,
    private decisionRuleEngineService: DecisionsRuleEngineService,
    private adUsersService: AdUsersService,
    private datePipe: DatePipe,
    private collaborationSiteService: CollaborationSiteService,
    private activedirectoryService: ActiveDirectoryService,
    private dataModification: DataModificationService,
    private graphSerivce: GraphService,
    private viewContainerRef: ViewContainerRef,
    private httpClient: HttpClient, private _sharepointService: SharepointService,
    private workspaceReviewPointService: WorkspaceReviewPointService,
    private changeOrderService: ChangeOrderService,
    private clmService: CLMService) { }

  ngOnInit() {
    const contactPopup = $('#contact-popup');
    contactPopup.hide();
    const jsonString = localStorage.getItem('currentUser');
    this.userInfo = JSON.parse(jsonString);
    this.alertService.remove(this.alert);
    this.activeRoute.paramMap.subscribe(params => {
      this.CurrentWorkspaceId = (params.get('workspaceId')).toString();
    });
    if (this.CurrentWorkspaceId != null || this.CurrentWorkspaceId !== 'undefined') {
      this.getEngagementChangeOrdersByWorkSpaceId(this.CurrentWorkspaceId);
      this.getWorkspaceDetailsById();
    }
    this.PerformDialogAction();
    this.dataService.getNewVersionDialogVisibility.subscribe(
      isVisible => {
        this.displayNewVersionDialog = isVisible;
      });
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    // Configure the interview options; this registers the 'OnSessionComplete' event that fires when the user clicks the
    // interview 'Finish' button. It then posts the data from the interview to your 'CompleteAssemblySession' endpoint.
    // You will add the 'CompleteAssemblySession' endpoint in the next topic, 'Completing an Assembly Session'.
    // Note that the value for the 'Container' property must match the ID of your interview container div at the top of the page.

    // Attach the assembly session to the interview
    this.currentEngagement.siteRequestStatus = '';
    // this.GetEngagementDetailsByWorkspaceId(this.CurrentWorkspaceId);
    this.initiateConfirmationPopUp();
    this.currentEngagement.siteRequestStatus = '';

    $('#questionnaireLoadSpinner').addClass('spinner');  //Set loader for buttons section while interview gets loaded.

    this.myQuestionnaireLoadingInterval = setInterval(() => {
      // Check if interviews loaded 
      if (!this.isInterviewLoaded) {
        $('#questionnaireLoadSpinner').removeClass('spinner');
        this.alertService.add('Issue in loading questionnaire. Please refresh questionnaire screen.', 'danger', null);
        this.loadingService.stopLoading();
        clearInterval(this.myQuestionnaireLoadingInterval);
      }
    }, 60 * 1000);
  }

  PerformDialogAction() {
    this.modalFinishDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData && this.isInterviewsScreen) {
          const modal = JSON.parse(dialogData);
          const dialogTitle = modal.dialogTitle;
          const buttonName = modal.dialogButtonName;
          if (buttonName.length > 0 &&
            (dialogTitle === QuestionnaireDialogTitles.FinishInterview ||
              dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions ||
              dialogTitle === QuestionnaireDialogTitles.CancelQuestionnaire ||
              dialogTitle === WorkspaceInterviewsStatus.ReviewStatus ||
              dialogTitle === QuestionnaireDialogTitles.QueNewVersion ||
              dialogTitle === QuestionnaireDialogTitles.InputErrors ||
              dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmation)) {
            this.dialogTitle = dialogTitle;
            this.dialogButtonName = buttonName;
            this.processDialogAction(this.dialogTitle, this.dialogButtonName);
          }
          console.log('Display Finish Dialog message in interviews screen ', dialogTitle);
        }
      });
  }
  initiateConfirmationPopUp() {
    this.modalConfirmationDialogRef = this.data.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dialogTitle = dialogModal.dialogTitle;
          if (dialogTitle === 'Remove document') {
            // this.RemoveSupplDoc();  /// this need to revisit
          }
        }
      });
  }

  getWorkspaceDetailsById() {
    if (this.CurrentWorkspaceId != null) {
      this.loadingService.startLoading();
      this.workspaceService.getWorkspaceById(this.CurrentWorkspaceId).subscribe(result => {
        if (result.isPrimaryWorkspaceId) {
          this.isCurrentWorkspacePrimary = true;
          this.primaryWorkspaceId = this.CurrentWorkspaceId;
        } else {
          this.isCurrentWorkspacePrimary = false;
        }
        this.WorkspaceDetails = result;
        this.RestoreUIStateAfterPageLoaded();
        this.processGetWorkspaceDetailsById();

      }, error => {
        this.DisplaySystemAlert();
      });
    }
  }
  processGetWorkspaceDetailsById() {
    if (this.WorkspaceDetails !== null && this.WorkspaceDetails !== undefined) {
      this.engagementId = this.WorkspaceDetails.engagementId;
      this.CurrentWorkspaceStatus = this.WorkspaceDetails.status;
      if (this.WorkspaceDetails.updateQuestionnaireID != null &&
        this.WorkspaceDetails.updateQuestionnaireID != undefined &&
        this.WorkspaceDetails.updateQuestionnaireID != '') {
        this.AppliedTnC = true;
      }
    }
    if (this.isValidWorkspaceStatus()) {
      if (this.WorkspaceDetails.engagementId > 0 || this.WorkspaceDetails.engagementId != null) {
        this.StoreDataModification(this.WorkspaceDetails.engagementId);
        this.dataModification.IsInProgressDataModificationExists(this.WorkspaceDetails.engagementId, DataModificationModuleNames.ChangeOrderQuestionnaire).subscribe(
          questionnaireInprogressResult => {
            if (questionnaireInprogressResult.editStatus) {
              this.QuestionnaireInProgressHDSession = true;
            }
            this.GetEngagementDetailsByWorkspaceId(this.CurrentWorkspaceId);
          }, error => {
            this.DisplaySystemAlert();
          });
      } else {
        this.DisplaySystemAlert();
      }
    } else {
      this.loadingService.stopLoading();
      this.setDialogContent('danger', WorkspaceInterviewsStatus.ReviewStatus);
    }
  }

  GetEngagementDetailsByWorkspaceId(workspaceId: string) {
    this.lastdateTime = this.datePipe.transform(new Date(), "MMM d, y, h:mm:ss a");
    return this.engService.GetEngagementDetailsByworkspaceId(workspaceId).subscribe(
      result => {
        console.log('GetEngagementDetailsByworkspaceId==>', result);
        if (result !== null) {
          this.currentEngagement = result;
          this.primaryWorkspaceId = result.primaryWorkspaceId;
          this.AppliedTnC = this.currentEngagement.appliedTnC;
          console.log("this.this.currentEngagement====>", this.currentEngagement.engagementId);
          this.sharePointURL = this.currentEngagement.siteUrl;
          if (this.currentEngagement.siteRequestStatus === 'Completed' ||
            this.currentEngagement.siteRequestStatus === 'PartialCompleted') {
            this.GetAllDocumentsfromSPLibrary(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath);
          }
          this.getContinuanceData(); // this need to revisit
          // this.SetLoggedInUserRole();  //this need to udpate
          // this.getWorkspaceMembers();  // this need to update
          //this.getWorkspaceReviewPoints();
        }
      },
      error => {
        this.currentEngagement = null;
        this.DisplaySystemAlert();
      }
    );
  }
  getContinuanceData() {
    this.reacceptanceService.getContinuanceEngWorkItemData(this.WorkspaceDetails.engagementId).subscribe(
      result => {
        this.continuanceWorkItemData = result;
        console.log('Continuance eng workitem data', this.continuanceWorkItemData);
        // get workitem data of continuance reacceptance to load PY Q&A data by passing workitem id of PY Workspace
        // if (this.continuanceWorkItemData.continuanceStatus === ContinuanceStatus.Draft &&
        //   this.continuanceWorkItemData.reacceptanceEngagementId > 0 &&
        //   this.continuanceWorkItemData.engagementId > 0) {
        //   this.isContinuanceEligible = true;
        //   this.WorkspaceDetails.continuanceWorkItemId = this.continuanceWorkItemData.continuanceWorkItemId;
        //   this.WorkspaceDetails.continuanceTemplateChangeAnsCarryForward = this.continuanceWorkItemData.continuanceTemplateChangeAnsCarryForward;
        //   this.WorkspaceDetails.isTemplateChanged = this.continuanceWorkItemData.isTemplateChanged;
        // } else {
        this.isContinuanceEligible = false;
        // }
        this.processGetInterviews();
      }, error => {
        this.DisplaySystemAlert();
      });
  }

  processGetInterviews() {
    if (this.WorkspaceDetails.hdWorkItemId != null) {
      this.setCurrentInterviewStatus();
      this.CurrentWorkspaceStatus = this.WorkspaceDetails.status;

      // If Engagement is in EP or RP review and User edits engagement then we need to check template version mismatch.
      // If mismatch found, new version popup will be displayed and clicking on OK will update the latest template version in db and reloads page.
      // Other use case fixed: Since latest version is updated in db, when EP edits engagement (RP review or EP review status), it will not reset to team review. The status will now be set to EP Review which is expected.
      var CanCheckTemplateVersion = false;
      if (this.redirectedFromEditEngagement &&
        (this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.EPPMDReview || this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.RPInReview || this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.EPReviewPending)
      ) {
        CanCheckTemplateVersion = true;
      }

      // if review status is In Review then reset approvals and set review status to Team review on Finish Interviews click
      if (this.redirectedFromEditEngagement && this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.RPInReview) {
        this.redirectedFromEditEngagement = false;
      }
      // if review status is In Review then reset approvals and set review status to Team review on Finish Interviews click
      if (this.redirectedFromEditEngagement && this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.DraftSession) {
        this.redirectedFromEditEngagement = false;
      }
      console.log('Current Workspace Status :', this.CurrentWorkspaceStatus);
      if (CanCheckTemplateVersion) {
        this.CheckTemplateVersion(this.WorkspaceDetails);
      } else if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.CreateSession || this.WorkspaceDetails.engagementState === "ChangeOrder" ||
        (!this.isCurrentWorkspacePrimary && this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession)) {
        this.ValidateHdTemplateCache();
        this.GetQuestionBankData(this.WorkspaceDetails);
      } else {
        this.CheckTemplateVersion(this.WorkspaceDetails);
      }
    }
  }

  CheckTemplateVersion(currentWorkspaceData) {
    if (this.displayNewVersionDialog) {
      this.hdService.checkTemplateVersion(currentWorkspaceData).subscribe(result => {
        if (result) {
          this.isTemplateVersionChanged = result;
          this.loadingService.stopLoading();
          this.setDialogContent('warning', QuestionnaireDialogTitles.QueNewVersion);
        } else {
          this.GetQuestionBankData(currentWorkspaceData);
        }
      }, error => {
        this.DisplaySystemAlert();
      });
    } else {
      //this.isTemplateVersionChanged = true;
      this.GetQuestionBankData(currentWorkspaceData);
    }
  }


  GetQuestionBankData(workspaceData: WorkspaceModel) {
    this.loadingService.startLoading();
    this.engService.GetQuestionBankData(this.WorkspaceDetails.engagementId).subscribe(
      result => {
        if (result.engageDataList.length > 0) {
          this.questionBankData = result;
          this.questionnaireModel.tnCWorkItemId = this.WorkspaceDetails.orginalHDWorkItemId;
          this.questionnaireModel.appliedTnC = this.AppliedTnC;

          this.getInterviewsSession(workspaceData);
        }
      },
      error => {
        this.DisplaySystemAlert();
      });
  }

  getInterviewsSession(workspaceDetails) {
    if (workspaceDetails.hdWorkItemId != null && this.questionBankData != null) {
      this.questionnaireModel.questionBankData = this.questionBankData;
      if (!this.isCurrentWorkspacePrimary && this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession) {
        //This is only for first time load of secondary document
        workspaceDetails.continuanceWorkItemId = this.currentEngagement.primaryWorkitemId;
      }
      this.questionnaireModel.workspace = workspaceDetails;
      this.hdService.getInterviews(this.questionnaireModel).subscribe(
        result => {
          workspaceDetails.hdCoreSessionId = result.coreSessionId;
          workspaceDetails.hdWorkItemVersionId = result.hdaSessionId;
          workspaceDetails.id = workspaceDetails.workspaceId;
          this.WorkspaceDetails.status = this.CurrentWorkspaceStatus;
          this.WorkspaceDetails.hdWorkItemVersionId = result.hdaSessionId;
          this.WorkspaceDetails.hdCoreSessionId = result.coreSessionId;
          this.InterviewList = result;
          this.AttachInterviewSession();
        },
        err => {
          this.DisplaySystemAlert();
        });
    }
  }

  AttachInterviewSession() {
    console.log(this.InterviewList);
    this.loadingService.stopLoading();
    this.interviewOptions = {
      Container: 'hdPreviewDivChangeOrder',
      Theme: 'default.css',
      OnInit: () => {
        HD$.RegisterHandler('OnSessionComplete',
          (e) => {
            console.log('Finish Session called on OnSessionComplete complete handler');
            let xhr = new XMLHttpRequest();
            const url = this.IntegrationAPIEndPoint + '/Hotdocs/CompleteAssemblySession/' + this.WorkspaceDetails.hdWorkItemId;

            xhr.open('POST', url, true);

            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onreadystatechange = function () {

              if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log(this.status);
              }
            };
            xhr.send(JSON.stringify({ WorkItemId: this.WorkspaceDetails.hdWorkItemId }));
            this.StoreReviewResponse();

          });
        $('button.hd-nav-btn.hd-nav-finish.hd-nav-lff-3').css('display', 'none');

        HD$.RegisterHandler('OnHDStart',
          (eventArgs: any) => {
            HD$.SetVisibility('Toolbar', false);
            $('#questionnaireLoadSpinner').removeClass('spinner');
            this.isInterviewLoaded = true;
          });

      },
      DisableAnswerSummary: true,
      OutlineWidth: 0,
      TemplateTitleOverride: ' ',
      LeaveWarning: false,
      RequiredAsterisk: true,
    };

    HD$.AttachSession(
      this.InterviewList.coreSessionId,
      this.InterviewList.serviceMetadataUrl,
      this.interviewOptions
    );
  }

  StoreReviewResponse() {
    let reviewResponseHtml = HD$.GetAnswerSummary();
    const responseModel = new ReviewResponseModel();
    responseModel.createdBy = this.userInfo.userName;
    responseModel.reviewResponseHtml = reviewResponseHtml;
    responseModel.engagementId = this.engagementId;
    responseModel.workspaceId = this.WorkspaceDetails.workspaceId;
    responseModel.workItemId = this.WorkspaceDetails.hdWorkItemId;
    responseModel.isUpdatedQuestionnaire = this.currentEngagement.appliedTnC;
    this.workspaceService.storeReviewResponseLog(responseModel).subscribe(
      result => {
        this.loadingService.stopLoading()
        console.log('storeReviewResponseLog func ', result);
      },
      error => {
        this.loadingService.stopLoading()
        console.error('storeReviewResponseLog func ', error);
        this.workspaceService.storeErrorLog(error);
      }
    );
  }
  /** HotDocs Caching issue fixes START*/
  ValidateHdTemplateCache() {
    var canReload = this.hdService.canReloadPageForHotdocsTemplate(
      this.WorkspaceDetails.documentAliasName,
      this.WorkspaceDetails.hdTemplatePackageId,
      this.WorkspaceDetails.hdCurrentTempVersion
    );
    if (canReload) {
      this.ReloadPage(this.WorkspaceDetails);
    }
  }

  private ReloadPage(selectedWorkspacedocModel: WorkspaceModel) {
    //Reload page so the saved hotdocs template version with answers will be displayed in UI properly. 

    this.SetUIStateBeforePageReload();

    this.hdService.RemoveHdTemplateVersionListInBrowserSession();
    this.hdService.UpdateHdTemplateVersionInBrowserSession(
      selectedWorkspacedocModel.documentAliasName,
      selectedWorkspacedocModel.hdTemplatePackageId,
      selectedWorkspacedocModel.hdCurrentTempVersion
    );

    sessionStorage.setItem('isPageReloaded', "true");
    HD$.ReattachSession();
  }

  private RestoreUIStateAfterPageLoaded() {
    var isPageReloadedSession = sessionStorage.getItem("isPageReloaded");
    if (isPageReloadedSession != null && isPageReloadedSession != undefined) {

      var canDisplayNewVersionDialog = sessionStorage.getItem('canDisplayNewVersionDialog');
      if (canDisplayNewVersionDialog != null && canDisplayNewVersionDialog != undefined) {
        this.displayNewVersionDialog = false;
      }

      var isRedirectedFromEditEngagement = sessionStorage.getItem('isRedirectedFromEditEngagement');
      if (isRedirectedFromEditEngagement != null && isRedirectedFromEditEngagement != undefined) {
        this.redirectedFromEditEngagement = true;
      }

      sessionStorage.removeItem('canDisplayNewVersionDialog');
      sessionStorage.removeItem('isRedirectedFromEditEngagement');
      sessionStorage.removeItem('isPageReloaded');
    }
  }
  private SetUIStateBeforePageReload() {
    if (this.displayNewVersionDialog == false) {
      sessionStorage.setItem('canDisplayNewVersionDialog', "false")
    }

    if (this.redirectedFromEditEngagement) {
      sessionStorage.setItem('isRedirectedFromEditEngagement', "true");
    }
  }

  setCurrentInterviewStatus() {
    switch (this.WorkspaceDetails.status) {
      case WorkspaceInterviewsStatus.DraftSession:
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.DraftSession;
        break;
      case WorkspaceInterviewsStatus.EPReviewPending:
      case WorkspaceInterviewsStatus.EPPMDReview:
      case WorkspaceInterviewsStatus.RPInReview:
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.CompleteSession;
        break;
      default:
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.DraftSession;
        break;
    }
    if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.DraftSession &&
      (this.WorkspaceDetails.hdCoreSessionId === null ||
        this.WorkspaceDetails.hdCoreSessionId === 'undefined' ||
        this.WorkspaceDetails.hdCoreSessionId === '')) {
      this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.CreateSession;
    }
    if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.CompleteSession &&
      this.redirectedFromEditEngagement) {
      this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.CreateSession;
    }
    this.WorkspaceDetails.setAnsFlag = this.CurrentInterviewsStatus;
  }

  // SetLoggedInUserRole() { // this need to update
  //   this.workspaceService.getMembers(this.primaryWorkspaceId).subscribe(
  //     memberResult => {
  //       this.memberList = memberResult;
  //       console.log(this.memberList.length);
  //       console.log(this.memberList);
  //       if (this.memberList.length > 0) {
  //         for (var i in this.memberList) {
  //           if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
  //             if (this.memberList[i].teamMemberRole == EnumRoles.EngagementPartner) {
  //               this.IsUserEP = true;
  //             } else if (this.memberList[i].teamMemberRole === EnumRoles.TeamMember ||
  //               this.memberList[i].teamMemberRole === EnumRoles.EQCR ||
  //               this.memberList[i].teamMemberRole === EnumRoles.Creator ||
  //               this.memberList[i].teamMemberRole === EnumRoles.StaffMember) {
  //               this.IsUserTM = true;
  //             }
  //             break;
  //           }
  //         }
  //       }
  //     });
  // }
  GetAllDocumentsfromSPLibrary(siteURL: string, documentFolderPath: string) {
    this.loadingService.startLoading();
    this.sharepointService.getAllDocumentsfromSPLibrary(siteURL, documentFolderPath).subscribe(
      result => {
        this.supplementalDocumentList = result;
        this.attacheLabelToFiles();
        this.supplementalDocCountOnLoad = this.supplementalDocumentList.length;
        this.supplementalDocCount = this.supplementalDocumentList.length;
        if (this.supplementalDocCount === 0) {
          this.DataMessage = 'No supplemental documents available';
        } else if (this.supplementalDocCount >= 1) {
          this.DataMessage = 'Loading...';
        } else {
          console.log('supplementalDocCount', this.supplementalDocCount);
          this.DataMessage = '';
        }

        this.applyFilterOnceAfterDataLoad = true;
        this.loadingService.stopLoading();
      },
      error => {
        this.DisplaySystemAlert();
        this.applyFilterOnceAfterDataLoad = false;
      });
  }
  attacheLabelToFiles() {

    this.workspaceService.GetSharepointFiles(this.primaryWorkspaceId).subscribe(workspaceDocs => {
      if (workspaceDocs && workspaceDocs.length > 0)
        for (let i = 0; i < this.supplementalDocumentList?.length; i++) {
          workspaceDocs.forEach(workspaceDoc => {
            if (workspaceDoc.sourceFileName == this.supplementalDocumentList[i].documentName && workspaceDoc.sourceFilePath == this.supplementalDocumentList[i].documentUrl) {
              this.supplementalDocumentList[i].label = workspaceDoc.label;
              this.supplementalDocumentList[i].workspaceId = workspaceDoc.workspaceId;
              return;
            }
          });
        }
      this.updateSpFilterFiles();
    }, error => {
      console.error("unable to load sharepoint label data", error);
    })
  }
  updateSpFilterFiles() {
    let unlabelFound = false;
    this.sharePointApplicationMasterLabelsFiltered = []
    this.supplementalDocumentList.forEach(spdoc => {
      this.sharePointApplicationMasterLabels.forEach(spAml => {
        if (spdoc.label == spAml.value) {
          this.sharePointApplicationMasterLabelsFiltered.push(spAml);
          return;
        } else {
          unlabelFound = true;
        }
      })
    });
    this.sharePointApplicationMasterLabelsFiltered.forEach(result => {
      if (result.value != this.sharePointSelectedFileLabelForFilter) {
        this.sharePointSelectedFileLabelForFilter = 'All';
      }
    });
    this.sharePointApplicationMasterLabelsFiltered = [...new Set(this.sharePointApplicationMasterLabelsFiltered)];
    this.sharePointApplicationMasterLabelsFiltered.sort((a, b) => a.value.localeCompare(b.value))
    // this.sharePointSelectedFileLabelForFilter = this.sharePointSelectedFileLabelForFilter;
  }

  StoreDataModification(engagementId: number) {
    this.dataModification.StoreDataModification(engagementId, DataModificationModuleNames.ChangeOrderQuestionnaire).subscribe(
      result => {
        console.log('Store Engagement Id ', engagementId, ' Modification Status ', result);
      }, error => {
        console.error('StoreDataModification error ', error);
      }
    );
  }

  getExecutionWorkspaceStatus(btnName: string) {
    this.savebuttonClicked = true;
    this.loadingService.stopLoading();
    this.alertService.remove(this.alert);
    this.workspaceService.GetWorkspaceCurrentStatus(this.CurrentWorkspaceId).subscribe(status => {
      if (btnName === 'FinishBtn') {
        this.CurrentWorkspaceStatus = status;
        if (this.CurrentWorkspaceStatus !== WorkspaceInterviewsStatus.Abandoned &&
          this.CurrentWorkspaceStatus !== WorkspaceInterviewsStatus.Declined) {
          this.FindOkButton('FinishBtn');
        }
      }
    }, error => {
      this.DisplaySystemAlert();
    });

  }
  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.isRedirectedFromCreateDoc = this.dataService.getUnsavedDialogVisibility();
    if (this.isRedirectedFromCreateDoc) {
      this.ForceUserToCompleteSession = true;
      this.forceCompleteSession_RequestedNavigationUrl = nextState.url;
      this.processDialogAction(EngagementDialogTitles.UnsavedChangesConfirmation, 'create');
      return of(false);
    }
    if (!this.FinishSessionFlagCheck && !this.SaveSessionFlagCheck && !this.EditEngagementBtnClicked) {
      if (!this.ForceUserToCompleteSession) {
        this.forceCompleteSession_RequestedNavigationUrl = nextState.url;
        //this.FindOkButton('CloseSessionBtn');
        this.setDialogContent("information", EngagementDialogTitles.UnsavedChangesConfirmation);
        return of(false);
      } else {
        return of(true);
      }
    }
    else {
      return of(true);
    }
  }



  processDialogAction(title: string, btnName: string) {
    switch (title) {
      case QuestionnaireDialogTitles.FinishInterview:
        if (btnName === 'ok') {
          this.FindOkButton('FinishBtn');
        }
        break;
      case QuestionnaireDialogTitles.UnAnsQuestions:
        if (btnName === 'ok') {
          this.SaveAssemblySession();
          this.data.setUnsavedDialogVisibility(false);
          this.ForceUserToCompleteSession = true;
        } else {
          this.ResetCompleteSessionFlag();
          this.processHDButton('cancel');
        }
        break;
      case QuestionnaireDialogTitles.CancelQuestionnaire: //need to revisit
      case WorkspaceInterviewsStatus.ReviewStatus:
        if (btnName === 'ok') {
          this.RedirectUser();
        }
        break;
      case QuestionnaireDialogTitles.QueNewVersion:
        if (btnName === 'ok') {
          this.UpdateTemplateVersion();
        }
        break;
      case QuestionnaireDialogTitles.InputErrors:
        if (btnName === 'ok') {
          this.processHDButton(btnName);
        }
        break;
      case EngagementDialogTitles.UnsavedChangesConfirmation:
        if (!this.ForceUserToCompleteSession && btnName === 'ok') {
          this.FindOkButton('CloseSessionBtn');
        }
        else if (btnName === 'create') {
          this.FindOkButton('NewDocCreateBtn');
        }
        else if (btnName === 'cancel' || btnName === 'close') {
          this.ResetCompleteSessionFlag();
        }
        this.ResetIwantToUnsavedFlag();
        break
      default:
        break;
    }

  }

  RedirectUser() {
    this.loadingService.stopLoading();
    this.CleartTimerAlert();

    if (this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.DraftSession &&
      this.CurrentWorkspaceStatus != '' &&
      this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.Abandoned &&
      this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.Approved &&
      this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.Declined) {
      this.route.navigate(['/Review/' + this.primaryWorkspaceId]); // this need to revisit
    } else {
      this.route.navigate(['MyEngagements']);
    }
  }

  CleartTimerAlert() {
    clearInterval(this.myQuestionnaireLoadingInterval);
    this.alertService.remove(this.alert);
  }

  ResetIwantToUnsavedFlag() {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setUnsavedChangesModel(dialogJSON);
    this.dataService.setClientIdForPrePopulate(-1);
    this.dataService.setEngagementIdForPrePopulate(-1);

  }


  UpdateTemplateVersion() {
    this.loadingService.startLoading();
    this.hdService.getTemplatePackageDetails(this.WorkspaceDetails.hdTemplatePackageId).subscribe(
      TemplateDetail => {
        this.TemplatePackageDetails = TemplateDetail;
        console.log('Template Live version data on template version check', this.TemplatePackageDetails.liveVersionNumber);
        this.WorkspaceDetails.showFutureMessage = false;
        this.WorkspaceDetails.hdLastVersionCheckedAgainst = this.TemplatePackageDetails.liveVersionNumber;
        this.WorkspaceDetails.hdCurrentTempVersion = this.TemplatePackageDetails.liveVersionNumber;
        this.workspaceService.updateTemplateVersionData(this.WorkspaceDetails).subscribe(
          result1 => {
            if (this.SaveSessionFlagCheck) {
              window.location.reload();
            } else {
              this.GetQuestionBankData(this.WorkspaceDetails);
            }
          }, error => {
            this.DisplaySystemAlert();
          });
      });

  }

  processHDButton(btnName: string) {
    let i = 0;
    if (btnName === 'cancel') {
      i = 1;
      this.FinishSessionFlagCheck = false;
    }
    const buttonSet = $('.ui-dialog').children().children()[4];
    if (buttonSet != null || buttonSet == undefined) {
      let okButton = $(buttonSet).children()[i];
      $(okButton).attr('id', 'btnok');
      $(okButton).click();
    }
  }


  SaveAssemblySession() {
    this.loadingService.startLoading();
    setTimeout(() => {
      let buttonSet = $('.ui-dialog').children().children()[4];
      if (buttonSet != null || buttonSet == undefined) {
        let okButton = $(buttonSet).children()[0];
        $(okButton).attr('id', 'btnOk');
        $(okButton).click();
        setTimeout(() => {
          $('.ui-dialog-content .ui-widget-content').css('display', 'none');
          $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
          $('.ui-widget-overlay.ui-front').remove();
          if (this.SaveSessionFlagCheck) {
            setTimeout(() => {
              this.processSaveSession();
            }, 50);
          } else if (this.FinishSessionFlagCheck) {
            this.GetAllHDVariableMapping();
          } else if (this.EditEngagementBtnClicked) {
            // this.EditEngagement();   
          } else if (this.ForceUserToCompleteSession) {
            this.RedirectToNextState();
          }
          // delay time for answer processing then will close the session.
        }, 4000);
      }
    }, 1000);
  }

  processSaveSession() {
    HD$.DetachSession();
    this.WorkspaceDetails.status = WorkspaceInterviewsStatus.DraftSession;
    this.getWorkspaceDetailsById();
    this.updateSharePointMetadata();
    $('#interview-save-success').removeClass('d-none');
    $('#interview-save-success').addClass('d-block');
  }

  GetAllHDVariableMapping() {
    this.loadingService.startLoading();
    this.engService.GetAllHDVariableMapping(this.WorkspaceDetails.hdTemplatePackageId).subscribe(mappingData => {
      if (mappingData.length > 0) {
        this.hdVariableMappingData = mappingData;
        this.hdVariableMappingData.forEach(x => x.engagementId = this.WorkspaceDetails.engagementId);
        this.hdVariableMappingData.forEach(x => x.workItemId = this.WorkspaceDetails.hdWorkItemId);
      } else {
        this.hdVariableMappingData = [];
      }
      this.GetHDAnswersToVariableMapping();
    },
      error => {
        this.DisplaySystemAlert();
      });
  }

  GetHDAnswersToVariableMapping() {
    this.hdVariableMappingList.hDVariableMappingList = this.hdVariableMappingData;
    this.hdVariableMappingList.workItemId = this.WorkspaceDetails.hdWorkItemId;
    this.hdVariableMappingList.engagementId = this.engagementId;
    this.hdService.GetHDAnswersToEngage(this.hdVariableMappingList).subscribe(hdAnsData => {
      if (hdAnsData != null) {
        this.hdSessionAnswersToEngage = hdAnsData;
        if (this.hdSessionAnswersToEngage.hdAnstoEngList == null ||
          this.hdSessionAnswersToEngage.hdAnstoEngList == undefined ||
          this.hdSessionAnswersToEngage.hdAnstoEngList.length <= 0) {
          this.hdSessionAnswersToEngage.hdAnstoEngList = [];
        }
      }
      // this.SetWorkspaceDataToStatusUpdate(); this need to revisit
      if (this.savebuttonClicked)
        {
          this.loadingService.stopLoading();
        }
        // this.RedirectUser() // this is temporray redirect this need to udpate after new URL may be based on aboe method if not reditected

    },
      error => {
        this.DisplaySystemAlert();
      });
  }


  updateSharePointMetadata() {
    const collaborationSiteIdRequest = this.collaborationSiteService.getCollaborationSiteId(this.engagementId);
    const sharePointMetadataRequest = this.engService.getMetadataList();
    forkJoin([collaborationSiteIdRequest, sharePointMetadataRequest]).subscribe(responseList => {
      const metadata: SPSiteMetadata[] = responseList[1];
      const collaborationSiteId = responseList[0];
      const sharepointMetadata: SPSiteMetadata[] = [];
      this.engService.getSharePointMetadataMapping(this.engagementId, collaborationSiteId)
        .subscribe((sharePointData: SharePointMetadataMapping) => {
          sharePointData.propertiesMapping.forEach(item => {
            metadata.forEach(element => {
              if (item.value === element.name) {
                element.value = sharePointData.metadata[item.key];
                sharepointMetadata.push(element);
              }
            });
          });
          const sharePointWrapper = new SharePointWrapper();
          sharePointWrapper.siteURL = sharePointData.metadata.siteUrl;
          this.sharePointURL = sharePointData.metadata.siteUrl;
          console.log(this.sharePointURL);
          sharePointWrapper.metadataList = sharepointMetadata;
          this.engService.updateSiteMetadata(sharePointWrapper).subscribe(status => {
            console.log('Metadata saved in interviews component', status);
          });
        });
    });
  }

  RedirectToNextState() {
    //this.route.navigate([this.forceCompleteSession_RequestedNavigationUrl]);
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([this.forceCompleteSession_RequestedNavigationUrl]);
    });
  }
  setUnsavedChangesForIwantTo() {
    if (!this.FinishSessionFlagCheck && !this.SaveSessionFlagCheck && !this.EditEngagementBtnClicked) {
      if (!this.ForceUserToCompleteSession) {
        const dialogData = {
          fromComponent: 'interviews',
          isUnsavedChangesExist: true
        };
        this.data.setUnsavedDialogVisibility(false);
        const dialogJSON = JSON.stringify(dialogData);
        this.data.setUnsavedChangesModel(dialogJSON);
      }
    }
  }

  FindOkButton(BtnValue: string) {
    this.EditEngagementBtnClicked = false;
    this.FinishSessionFlagCheck = false;
    this.SaveSessionFlagCheck = false;
    this.ForceUserToCompleteSession = false;
    this.isHiddenProgressModal = true;
    if (BtnValue == 'FinishBtn') {
      this.FinishSessionFlagCheck = true;
    } else if (BtnValue === 'CloseSessionBtn') {
      this.ForceUserToCompleteSession = true;
    }
    else if (BtnValue === 'NewDocCreateBtn') {
      this.ForceUserToCompleteSession = true;

    }
    this.setUnsavedChangesForIwantTo();
    $('button.hd-nav-btn.hd-nav-finish.hd-nav-lff-3').click();
    let uiDialog: any;
    let divTitleBar: any;
    let spanTitle: any;
    let uiDialogTitle = '';

    setTimeout(() => {
      uiDialog = $('.ui-dialog');
      if (uiDialog.length == 1) {
        divTitleBar = uiDialog.children()[0];

      } else {
        const visibleDialog = $('.ui-dialog ').filter(function () {
          return $(this).css('display') !== 'none';
        });
        divTitleBar = visibleDialog.children()[0];
      }
      spanTitle = $(divTitleBar).children()[1];
      uiDialogTitle = $(spanTitle).html();

      if (uiDialogTitle === 'Input errors') {
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.setDialogContent('danger', QuestionnaireDialogTitles.InputErrors);
      } else if (uiDialogTitle === 'Unanswered questions') {
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.setDialogContent('warning', QuestionnaireDialogTitles.UnAnsQuestions);

      }
      else {
        this.isHiddenProgressModal = false;
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.loadingService.stopLoading();
        if (BtnValue == 'FinishBtn') {
          this.GetAllHDVariableMapping();
        } else if (BtnValue == 'SaveBtn') {
          this.SaveAssemblySession();
        } else if (BtnValue == 'EditBtn') {
          // this.EditEngagement();  this need to revisit after edit change order
        } else if (BtnValue === 'CloseSessionBtn') {
          this.RedirectToNextState();
        }
        else if (BtnValue === 'NewDocCreateBtn') {
          this.data.setUnsavedDialogVisibility(false);
          this.ForceUserToCompleteSession = true;
          this.RedirectToNextState();
        }
      }
    }, 5000);
  }


  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if ($.event) {
      event.stopImmediatePropagation();
    } else {
      return true;
    }
  }
  ResetCompleteSessionFlag() {
    if (this.ForceUserToCompleteSession) {
      this.ForceUserToCompleteSession = false;
      this.forceCompleteSession_RequestedNavigationUrl = '';
    }
  }
  DisplaySystemAlert() {
    this.alertService.remove(this.alert);
    this.loadingService.stopLoading();
    clearInterval(this.myQuestionnaireLoadingInterval);
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }
  isValidWorkspaceStatus() {
    if (this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Abandoned ||
      this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Declined) {
      return false;
    } else {
      return true;
    }
  }

  setDialogContent(type: string, title: string) {
    this.isInterviewsScreen = true;
    let description: string;
    let button = 2;
    let bigTitle = false;
    switch (title) {
      case QuestionnaireDialogTitles.InputErrors:
        this.isHiddenProgressModal = false;
        description = 'You must resolve one or more input errors before you can proceed.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.FinishInterview:
        description = 'Based on the information gathered and the evaluation of engagement acceptance risk,' +
          ' it is recommended that this client engagement be accepted by the firm.' +
          ' A workspace will be created and all acceptance documentation is ready for EPPMD review.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.UnAnsQuestions:
        this.isHiddenProgressModal = false;
        description = 'You have unanswered questions. Click "OK" if you would like to proceed. Click "Cancel" to return to the questionnaire.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.CancelQuestionnaire:
        description = 'Data entered during this session will be lost. Press OK to continue.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case WorkspaceInterviewsStatus.ReviewStatus:
        this.isHiddenProgressModal = false;
        description = 'The status of this engagement changed during your review.  Click OK to view the most current information.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.QueNewVersion:
        this.isHiddenProgressModal = false;
        description = 'This engagement questionnaire must be updated to the current version. ' +
          'Previous responses will be preserved where possible. Click OK to view the updated questionnaire and responses.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.UnsavedChangesConfirmation:
        description = 'While the questionnaire responses are autosaved, leaving now may result in recent changes not being saved. Click “Cancel” to go back and manually save your updates. Click “OK” to proceed without manually saving them.';
        button = 2;
        bigTitle = true;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      default:
        break;
    }

  }
  DisplayDialog(type: string, title: string, description: string
    , button: number, bigTitle: boolean) {
    const modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogInput: '',
      dialogBigTitle: bigTitle
    };
    const modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called ', type);
    this.dataService.displayDialogNotification(modalJSON);
  }
  getEngagementChangeOrdersByWorkSpaceId(workSpaceId: string) {

    return this.changeOrderService.getEngagementChangeOrdersByWorkSpaceId(workSpaceId)
      .subscribe({
        next: result => {

          this.engagementChangeOrders = result;
          if (this.engagementChangeOrders != null) {
            this.parentEngagementId = this.engagementChangeOrders.engagementId;
            this.engagementName = this.engagementChangeOrders.engagementName;
            this.changeOrders = this.engagementChangeOrders.changeOrders?.filter(x => x.status == 'Approved');
            // this.changeorderskeyValueList = this.getKeyValuePair(this.engagementChangeOrders.changeOrders?.find(x => x.status == 'Approved'), 'changeOrderId', 'changeOrderName' ,'approvedDate');       
            this.getCLMGoverningAgrementNameStatus();
          }
        }, error: err => {
          console.error("failed  to load Engagement ChangeOrders -getEngagementChangeOrdersByWorkSpaceId ", err);

        }
      })
  }
  formatDate(date: any) {
    const formattedDate = new Date(date);
    const d = formattedDate.getDate();
    let m = formattedDate.getMonth();
    m += 1;  // JavaScript months are 0-11
    const y = formattedDate.getFullYear();
    const finalFormattedDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
    return (finalFormattedDate);
  }
  getKeyValuePair(array: any, keyProperty: string, valueProperty, valuePropertyprefix) {
    let keyValueList: KeyValue[] = [];
    array.forEach(element => {
      let kv = new KeyValue();
      kv.key = element[keyProperty];
      if (valuePropertyprefix != null || valuePropertyprefix != 'undefined')
        kv.value = element[valuePropertyprefix] + '+' + element[valueProperty];
      else
        kv.value = element[valueProperty];

      keyValueList.push(kv);
    });
    return keyValueList;

  }

  onChangeOrdersChange() { }
  PopupDisplay(htmlurl: string) {
    var newDiv = document.createElement("div");
    newDiv.innerHTML = htmlurl;
    var scripts = newDiv.getElementsByTagName('script');
    for (var ix = 0; ix < scripts.length; ix++) {
      let clmScript: HTMLScriptElement = document.createElement('script');
      clmScript.async = true;
      clmScript.innerHTML = scripts[ix].text;
      document.head.prepend(clmScript);
    }
  }

  insertCLMApiLog(engagementId: number) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.GoverningAgreementStatus;
    clmApiLog.requestPayload = JSON.stringify(this.gaNameAndStatusRequest);
    clmApiLog.responseBody = JSON.stringify(this.gaNameAndStatusResponse);
    clmApiLog.createdBy = this.userInfo.loggedInUserEmail;
    if (this.gaNameAndStatusResponse.statusCode != "OK") {
      clmApiLog.errorCode = this.gaNameAndStatusResponse.statusCode;
      clmApiLog.errorDescription = "Failed to get Governing Agreement Name And Status Response  on Interview Page.";
    }
    else {
      clmApiLog.errorCode = "";
      clmApiLog.errorDescription = "";
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("Failed to insert api log for Governing Agreement Name And Status Response")
      }
    })
  }
  public getCLMGoverningAgrementNameStatus() {
    this.gaNameAndStatusRequest = new GoverningAgreementNameAndStatusRequest();
    let engagementIdTemp = this.parentEngagementId.toString();
    this.gaNameAndStatusRequest.engagementID = engagementIdTemp;
    if (this.parentEngagementId > 0)
      this.clmService.getGoverningAgreementNameAndStatusByEngagementId(this.gaNameAndStatusRequest).subscribe(result => {
        if (result.statusCode == "OK") {
          this.gaNameAndStatusResponse = result;
          this.clmGoverningAgreementStatusDiv.nativeElement.innerHTML = this.gaNameAndStatusResponse.htmlData;
          //this.PopupDisplay(this.gaNameAndStatusResponse.htmlData);
          //this.injectCLMCloseButtonDynamically();
        }
        this.insertCLMApiLog(this.engagementId);
      });
  }
}

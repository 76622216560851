import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { listviewModel, ClientUniqueDataModel } from '../model/listviewModel'
import { AppConfigService } from './appConfigService';
import { KeyValue } from '../model/KeyValue';
import { ClientCardViewFilterModel, ClientListViewFilterModel } from '../model/client-filter-model';
@Injectable()
export class listviewService {
  APIEndPoint: string;
  IntegrationAPIEndPoint: string;

  userName: string = '';
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    let jsonString = localStorage.getItem("currentUser");
    var jsonUserObj = JSON.parse(jsonString);
    if (jsonUserObj != null) {
      this.userName = jsonUserObj.userName;
    }
  }

  getListView(): Observable<listviewModel[]> {
    return this.http.get<listviewModel[]>(this.APIEndPoint + "/Client/" + this.userName);
  }
  getMyClientsCardView(clientCardViewFilterModel: ClientCardViewFilterModel,
    pageNumber: number, noOfRecordsPerPage: number,
    isloggedInUserAdmin: string, orderByColumn: string, orderByType: string
  ): Observable<listviewModel[]> {
    clientCardViewFilterModel.loggedInUser = this.userName;
    return this.http.post<listviewModel[]>(`${this.APIEndPoint}/Client/GetMyClientsByUsersWithFilters/${isloggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}`, clientCardViewFilterModel);
  }
  getMyClientsListView(clientListViewFilterModel: ClientListViewFilterModel,
    pageNumber: number, noOfRecordsPerPage: number,
    isloggedInUserAdmin: string, orderByColumn: string, orderByType: string
  ): Observable<listviewModel[]> {
    clientListViewFilterModel.loggedInUser = this.userName;
    return this.http.post<listviewModel[]>(`${this.APIEndPoint}/Client/GetMyClientsByUsersWithFilters/${isloggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}`, clientListViewFilterModel);
  }

  GetAllClientsUniqueDataByUser(isloggedInUserAdmin: string): Observable<ClientUniqueDataModel> {
    return this.http.post<ClientUniqueDataModel>(this.APIEndPoint +
      '/Client/GetAllClientsUniqueDataByUser', { userName: this.userName, isloggedInUserAdmin: isloggedInUserAdmin });
  }
  getMyClientsCardByUserCount(isloggedInUserAdmin: string,
    clientCardViewFilterModel: ClientCardViewFilterModel):
    Observable<number> {
    clientCardViewFilterModel.loggedInUser = this.userName;
    return this.http.post<number>(`${this.APIEndPoint}/Client/GetMyClientsByUserCount/${isloggedInUserAdmin}`, clientCardViewFilterModel);
  }
  getMyClientsListByUserCount(isloggedInUserAdmin: string,
    clientListViewFilterModel: ClientListViewFilterModel):
    Observable<number> {
    clientListViewFilterModel.loggedInUser = this.userName;
    return this.http.post<number>(`${this.APIEndPoint}/Client/GetMyClientsByUserCount/${isloggedInUserAdmin}`, clientListViewFilterModel);
  }

  getMyMSAList(): Observable<listviewModel[]> {
    return this.http.post<listviewModel[]>(this.APIEndPoint + '/Client/MyMSA', { userName: this.userName });
  }

  GetServiceLines(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/SearchPage/GetServiceLines/');
  }
  GetSearchTypes(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/SearchPage/GetSearchTypes/');
  }
}

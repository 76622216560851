import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharepointListItem } from 'src/app/model/SharePoiint/sharepoint-list-item';
import { SharepointListItemsModel } from 'src/app/model/SharePoiint/sharepoint-list-items-model';
import { SharepointListItemsRequestModel } from 'src/app/model/SharePoiint/sharepoint-list-items-request-model';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { SharepointService } from 'src/app/services/sharepoint.service';

@Component({
  selector: 'app-sharepoint-file-picker',
  templateUrl: './sharepoint-file-picker.component.html',
  styleUrls: ['./sharepoint-file-picker.component.css']
})
export class SharepointFilePickerComponent implements OnInit {
  @Input('siteUrl') siteUrl: string = "";
  @Input('engagementName') engagementName: string = "";
  @Input('clientId') clientId: string = " ";
  @Input('clientName') clientName: string = "";
  @Input('libraryNames') libraryNames: string = "";
  @Output() close = new EventEmitter();
  @Output() ok = new EventEmitter<SharepointListItem>();
  siteId: string;
  sharepointListItems: SharepointListItemsModel;
  selectedsharepointItems: SharepointListItem[];
  breadcrumSPItems: SharepointListItem[] = [];
  currentSelectedFolder: SharepointListItem;
  currentSelectedFile: SharepointListItem;
  selectedDocumentLibraryName = "Engagement Documents"
  constructor(private sharepointService: SharepointService, private loadingScreenService: LoadingScreenService) { }

  ngOnInit() {
    this.siteId = this.getSiteIdfromsiteUrl(this.siteUrl);
    this.getSharepointLibraryListItems();
  }

  cancelClicked(event) { //popup cancel click event
    this.close.emit(event);
    this.generalClicked();
  }

  okClicked() { // popup ok clicked event
    if (this.currentSelectedFile != undefined) {
      this.currentSelectedFile.rootFolderPath = this.currentSelectedFile.documentFullPath.substring(this.siteId.length, this.currentSelectedFile.documentFullPath.length);
      this.currentSelectedFile.rootFolderPath = this.currentSelectedFile.rootFolderPath.substring(0, this.currentSelectedFile.rootFolderPath.length - this.currentSelectedFile.name.length - 1);
      this.ok.emit(this.currentSelectedFile);
    }
  }

  getSiteIdfromsiteUrl(siteUrl: string) { // get site unique id from URL 
    return siteUrl.substring(siteUrl.indexOf("/sites/") + 7, siteUrl.length);
  }

  selectedDocument(seletedDocument: SharepointListItem) {//breadcrum method to select any folder and assign its subfolder to view
    if (seletedDocument.documentType == "Folder") {
      this.currentSelectedFile = undefined;
      this.getSharepointItemByItemId(this.sharepointListItems.sharepointListItems[0], seletedDocument.itemId);
      this.selectedsharepointItems = seletedDocument.children;
      let tempItem = seletedDocument;
      this.breadcrumSPItems.push(tempItem);
      this.unselectAllFiles();
    }
    else {
      this.currentSelectedFile = seletedDocument;
      for (let i = 0; i < this.selectedsharepointItems.length; i++) {
        if (this.selectedsharepointItems[i].itemId == this.currentSelectedFile.itemId) {
          this.selectedsharepointItems[i].isSelected = true;
        }
        else {
          this.selectedsharepointItems[i].isSelected = false;
        }
      }
    }
  }

  getSharepointLibraryListItems() {//get sharepoint folder from backend
    let sharepointListItemsRequestModel = new SharepointListItemsRequestModel();
    sharepointListItemsRequestModel.SiteId = this.siteId;
    sharepointListItemsRequestModel.DocumentLibraryName = this.selectedDocumentLibraryName;
    this.loadingScreenService.startLoading();
    this.sharepointService.getSharepointLibraryListItems(sharepointListItemsRequestModel).subscribe(result => {
      this.loadingScreenService.stopLoading();
      if (result) {
        this.sharepointListItems = result;
        this.selectedsharepointItems = this.sharepointListItems.sharepointListItems[0].children;
      }
    }, error => {
      this.loadingScreenService.stopLoading();
      console.error("failed to load sharepoint files information.", error);
    })
  }

  getSharepointItemByItemId(sharepointListItem: SharepointListItem, searchItemId) {//searching for folder in directory
    // if (sharepointListItem) {
    if (sharepointListItem.itemId == searchItemId) {
      this.currentSelectedFolder = sharepointListItem;
    }
    else if (sharepointListItem.children.length > 0) {
      sharepointListItem.children.forEach((n) => { this.getSharepointItemByItemId(n, searchItemId); })
    }
    // }
  }

  breadClicked(sharepointItem: SharepointListItem) { //breadcurm click event 
    if (sharepointItem.itemId != this.currentSelectedFolder.itemId) {
      let temp = this.breadcrumSPItems;
      temp.forEach(element => {
        if (element.itemId == sharepointItem.itemId) {
          this.breadcrumSPItems.pop();
          return;
        } else {
          this.breadcrumSPItems.pop();
        }
      });
      this.selectedsharepointItems = sharepointItem.children;
    }
  }

  generalClicked() {  //breadcrum general click event
    this.breadcrumSPItems = [];
    this.currentSelectedFile = undefined;
    this.selectedsharepointItems = this.sharepointListItems.sharepointListItems[0].children;
    this.unselectAllFiles();
  }

  unselectAllFiles() { // unselecting all the files from directory structure
    for (let i = 0; i < this.selectedsharepointItems.length; i++) {
      this.selectedsharepointItems[i].isSelected = false;
    }
  }

}

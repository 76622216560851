import { Component, OnInit } from '@angular/core';
import { AlertProcessAddProfessionalsEngagement } from 'src/app/model/AlertProcessAddProfessionalsEngagement';
import { UtilityDoc, UtilityDocument, UtilityInsertModel, UtilityModelData, UtilityUpdatedADGroupCheckModel, UtilityValidationModel } from 'src/app/model/DocumentUtility';
import { User } from 'src/app/model/User';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ProcessAddProfessionalsEngagementService } from 'src/app/services/process-add-professionals-engagement.service';

@Component({
  selector: 'app-process-add-paraprofessionals-engagement',
  templateUrl: './process-add-paraprofessionals-engagement.component.html',
  styleUrls: ['./process-add-paraprofessionals-engagement.component.css']
})
export class ProcessAddParaprofessionalsEngagementComponent implements OnInit {
  utilityDocument : UtilityDocument = new UtilityDocument();
  utilityDoc : UtilityDoc = new UtilityDoc();
  utilityModelData : UtilityModelData = new UtilityModelData();
  validatedModelData : UtilityValidationModel = new UtilityValidationModel();
  utilityInsertModel : UtilityInsertModel = new UtilityInsertModel();
  utilityValidationModel : UtilityValidationModel = new UtilityValidationModel();
  utilityValidationModelArray : UtilityValidationModel = new UtilityValidationModel();
  validatedData : UtilityValidationModel = new UtilityValidationModel();
  user: User;
  isExcelDocument: boolean = false;
  chunkSize:  number = 100;
  counter: number = 0;

  constructor(private authService: AuthService,
     private utilityService: ProcessAddProfessionalsEngagementService,
     private _loadingScreenService: LoadingScreenService) {
    this.getAuthUser();
    

   }

  ngOnInit() {
    this.alerts = [];
    this.utilityModelData.adGroupCheckModel = [];
    this.utilityModelData.excelProcessModel = [];
  }
  private getAuthUser() {
    this.user = this.authService.getUserInfo();
  }

  onSelectFile(Files: FileList) {   
    this.alerts = [];
     
    let intArrayObj;
    let fileToUpload = Files[0];
    let fileType = fileToUpload.type;
    if(fileType.toLowerCase() === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.isExcelDocument = true;
      intArrayObj = new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.readAsArrayBuffer(fileToUpload);
      }).then(
        (result: ArrayBuffer) => {        
          intArrayObj = new Uint8Array(result);
          this.utilityDoc.fileByteArray = Array.from(intArrayObj);
          this.utilityDoc.fileName = fileToUpload.name;
          this.utilityDocument.files = [];
          this.utilityDocument.files[0] = this.utilityDoc;
          this.utilityDocument.userEmailId = this.user.loggedInUserEmail;
          this.utilityDocument.userName = this.user.loggedInUserFullName;
        }
      );

    }
    else{
      this.isExcelDocument = false;
      this.add('',"Upload excel document only","danger",null);
    }
   
  }

  processAddProfessionalsEngagement() {
    this.alerts = [];
    this._loadingScreenService.startLoading();
    this.utilityService.UtilityUploadExcel(this.utilityDocument).subscribe(
      result => {
        if(result === null || result.adGroupCheckModel == null){
          this._loadingScreenService.stopLoading();
          this.add('','Failed to upload the excel data','danger',null);
        }else {
        this.utilityModelData.adGroupCheckModel = result.adGroupCheckModel;
        this.utilityModelData.excelProcessModel = result.excelProcessModel;
        if(result.adGroupCheckModel.length === 0){
          this._loadingScreenService.stopLoading();
          this.add('',
            'Total Records for processing '+result.excelProcessModel[0].rows+' out of that '+result.adGroupCheckModel.length
            +' got Imported and '+(result.excelProcessModel[0].rows - result.adGroupCheckModel.length)+
            ' got errored Out. Process ID = '+result.excelProcessModel[0].id+''
            ,'success',null);
        }
        else {
        
        if (this.utilityModelData.adGroupCheckModel.length > 0) {
          this.utilityValidationModel.adGroupCheckModel = this.utilityModelData.adGroupCheckModel;
          this.utilityService.ValidateExcelDataAcrossAD(this.utilityValidationModel).subscribe(
            result => {
              if(result === null || result.adGroupCheckModel == null){
                this._loadingScreenService.stopLoading();
                this.add('','Failed to upload the excel data','danger',null);
              }else {
              if(result.adGroupCheckModel.length > 0){
                this.validatedData.adGroupCheckModel = result.adGroupCheckModel;
                this.utilityValidationModelArray.adGroupCheckModel = [];
                for(let i = 0; i < result.adGroupCheckModel.length; i += this.chunkSize){
                this.utilityValidationModelArray.adGroupCheckModel = result.adGroupCheckModel.slice(i,i+this.chunkSize);

                this.utilityService.AddTheValidatedDataToADGroups(this.utilityValidationModelArray).subscribe(result => {
                  this.counter++;
                  var batchCount = Math.ceil(this.validatedData.adGroupCheckModel.length/this.chunkSize);
             
              if(batchCount == this.counter){
                this.counter = 0;
                batchCount = 0;
                this.utilityInsertModel.adGroupCheckModel = this.validatedData.adGroupCheckModel;
                this.utilityInsertModel.userEmail = this.user.loggedInUserEmail;
                this.utilityService.InsertValidatedDataToEngagements(this.utilityInsertModel).subscribe(
                  result => {
                  if(result === null){
                    this._loadingScreenService.stopLoading();
                    this.add('','Failed to upload the excel data','danger',null);
                  }else{
                    this._loadingScreenService.stopLoading();
                  this.add('',
                    'Total Records for processing '+result.excelProcessModel[0].rows+' out of that '+result.adGroupCheckModel.length
                    +' got Imported and '+(result.excelProcessModel[0].rows - result.adGroupCheckModel.length)+
                    ' got errored Out. Process ID = '+result.excelProcessModel[0].id+''
                    ,'success',null);
                  }},
                  error => {
                    
                    this._loadingScreenService.stopLoading();
                    this.add('','Failed to upload the excel data','danger',null);
                  }
                );
              }
                },
                error => {
                  this._loadingScreenService.stopLoading();
                  this.counter = 0;
                this.utilityValidationModelArray.adGroupCheckModel = [];
                  this.add('','Failed to upload the excel data','danger',null);
                }
                );
              }
              
              }
            }},
            error => {
              this._loadingScreenService.stopLoading();
              this.add('','Failed to upload the excel data','danger',null);
            }
          );
          
        }
      }
    }
      },
      error => {
        this._loadingScreenService.stopLoading();
        this.add('','Failed to upload the excel data','danger',null);
      });
  }
  

 //------------------ Alert section -----------------------------------------------
 alerts: AlertProcessAddProfessionalsEngagement[] = [];
 alert: AlertProcessAddProfessionalsEngagement;
 isSuccess: boolean = false;
 add(documentName: string, message: string, messageType: string, debug: string) {
   //this.isSuccess = messageType == 'success';
   this.alerts.push({ documentName: documentName, message: message, messageType: messageType, debug: debug, isSuccess: messageType == 'success' });
 } 

}

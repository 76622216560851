export class DocumentServiceLine {
    id:number;
    value:string;
}
export class SolutionFamily {
    value:string;
}
export class TemplateDetails {
    templatePackageId:string;
    templateName:string;
}
export class DocumentDetais {
    fileName:string;
    fileByteArray:any;
}

export class UserDetails{
    loggedInUserEmail:string;
}
export class UserClientDetails{
    loggedInUserEmail:string;
    clientId:number;
}
export class tempalteDetailsRequestModel{
    IndependenceConsiderations:string;
    SolutionFamily:string;
    TemplateCategory:string;
}
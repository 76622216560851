export class KeyRelationshipPartners {
  keyRelationshipPartnerId: number;
  clientId: number;
  relationshipPartnerName: string;
  relationshipPartnerRole: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
}

export class KeyRelationshipPartnersWithRoleList {
  keyRelationshipPartnerId: number;
  clientId: number;
  relationshipPartnerName: string;
  relationshipPartnerRole: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  partnerRoles: string[];
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
}

export class GoverningAgreemenRequest {
    "oneView Account ID":string;
    clientName:string;
    engagementName:string;
    solutionCode:string;
    industryType:string;
    engagementPPMDEmail:string;
    addressLine1:string;
    addressLine2:string;
    addressLine3:string;
    addressLine4:string;
    city:string;
    state:string;
    zipcode:string;
    country:string;
    engagementid:number;
}

export class GoverningAgreementNameAndStatusRequest{
    engagementID: string;
}

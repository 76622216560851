import { ClientTeamMembers } from "../Engagement/ClientTeamMember";

export class ManualRPAddModel {
  member: TeamMembers = new TeamMembers();
  engagementPartnerOfficeLocation: string;
}

export class AutoRPReplaceModel {
  member: TeamMembers = new TeamMembers();
  oldMemberEmailId: string;
  engagementPartnerOfficeLocation: string;
  engagementStage: string;
}

export class TeamMembers {
  id: number;
  workspaceId: string;
  memberName: string;
  teamMemberRole: string;
  isReviewer: number;
  roleId: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  memberEmailId: string;
  memeberContactNo: string;
  approvalStatus: string;
  oldreviewerName: string;
  loggedInUserFullName: string;
  jobTitle: string;
  collaborationSiteId: number;
  memberType: string;
  memberRoleName: string;
  memberRoleDescription: string;
  roleCode: string;
  teamMemberRoleName: string;  
  isNotified?: boolean;
  engageWorkflowStage: string;
  notificationTrigger: string;
  tier: number;
  isMemberDelegate: boolean;
  isKeyTeamMember?: boolean;
  keyTeamMemberRoleId?: number;
  description?: string;
}

export class TeamMemberForReviewer {
  id: number;
  workspaceId: string;
  memberName: string;
  teamMemberRole: string;
  isReviewer: number;
  roleId: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  memberEmailId: string;
  memeberContactNo: string;
  approvalStatus: string;
  oldreviewerName: string;
  loggedInUserFullName: string;
  isLoggedInUserhasTransferButtonVisible: boolean;
  jobTitle: string;
  memberType: string;
  memberRoleName: string;
  teamMemberRoleName: string;
  memberRoleDescription: string;  
  isNotified?: boolean;
  engageWorkflowStage: string;
  notificationTrigger: string;
  tier: number;
}

export enum TeamMemberStatus {
  Pending = 'Pending',
  SendToEPPMD = 'Send to EPPMD',
  Approved = 'Approved',
  Declined = 'Declined',
  Abandoned = 'Abandoned',
  NotApplicable = 'NA'
}

export class TeamMemberEPModel {
  epMembers: TeamMembers[] = [];
  teamMembers: TeamMembers[] = [];  
  clientTeamMemberList: ClientTeamMembers[] = [];  
}


export class WorkspaceMemberRemoveModel {
    memberId: number;
    memberEmailIdToRemove: string;
    loggedInUserEmailId: string;
    engagementPartnerOfficeLocation: string;
    engagementStage: string;
}

export class RemoveClientTeamMemberModel{
  clientTeamMemberId: number;
  loggedInUser: string;
  loggedInUserFullName: string; 
}

export class PowerAutomateRequest{
  id: number;  
  emailId: string; 
}

export class PowerAutomateRequestModel{
  engageEnviornment : string;
  collaborationSiteId : number
}
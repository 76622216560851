export class RFCMember {
    engagementId: number;
    memberName: string;
    memberEmailId: string;
    loggedInUser: string;
    loggedInUserFullName:string;
    
}

export class EngagementTeamMember
{
    memberName: string;
    memberEmailId: string;
    isPrimaryAssignmentAdmin: boolean;
}

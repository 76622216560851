export class ClientAdministrators {
  clientContactId: number;
  clientId: number;
  contactName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
}

import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { ConfigurationService } from 'src/app/services/configuration.service';
import { ClientService } from 'src/app/services/clientservice';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { OneViewClientDetailModel } from 'src/app/model/Client/OneViewClientDetail';
import { NewClient } from 'src/app/model/Client/NewClient';
import { DataService } from 'src/app/services/data.service';
declare var $;

@Component({
  selector: 'oneview-client-smart-search',
  templateUrl: './oneview-client-smart-search.component.html',
  styleUrls: ['./oneview-client-smart-search.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneviewClientSmartSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneviewClientSmartSearchComponent),
      multi: true,
    }
  ]
})
export class OneviewClientSmartSearchComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() source: string;
  @Input() name: string;
  @Input('value') val: string;
  @Input() MappedSource: string;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Input() Sequence: number;
 // @Input() IsAutoPopulate: boolean;
  @Input() MappedSourceClientNameValue: boolean;
  @Output() validation: EventEmitter<string> = new EventEmitter<string>();
  @Output() newClientOption: EventEmitter<string> = new EventEmitter<string>();
  @Output("clientChange") clientChange: EventEmitter<any> = new EventEmitter();
  @Output("OnSkipAccountSearch") OnSkipAccountSearch: EventEmitter<any> = new EventEmitter();
  private parseError: boolean;
  clientList: OneViewClientDetailModel[] = [];
  client: NewClient = new NewClient();
  previousSearchTerm: string;
  currentPageNo: number;
  prevPageNo: number;
  selectedValueFlag: boolean;
  IsClientNameChanged: boolean = false;
  IsPreviousClientSearchOnEnter: boolean = false;
  IsUseThisNameClicked: boolean = false;
  IsTypeAheadSearch: boolean = false;
  clientNamePlaceholderText: string = "Enter a client name";

  constructor(
    private dataService: DataService,
    private clientService: ClientService,
    private loadingScreenService: LoadingScreenService,
    private clientSearchRef: ElementRef
  ) {
    this.previousSearchTerm = '';
    this.selectedValueFlag = false;
  }

  propagateChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.propagateChange(val);
    this.onTouched();
  }

  ngOnInit() {
    this.showFilterSection('hide');
    var isclientNameExist = false;
    this.dataService.getNewClientName.subscribe(clientName => {
      if (clientName != "clientName" && clientName != '') {
        this.client.clientName = clientName.substring(0, 40).trim();
        isclientNameExist = true;
      }
    });
    setTimeout(() => {
      this.InitalizeDataSource();
      if (isclientNameExist) {
        $('#txtValue' + this.MappedSource + this.Sequence).val(this.client.clientName);
        this.getClientList(this.client.clientName, 3);
        this.dataService.setNewClientName('');
      }
    }, 300);
  }

  showFilterSection(action: string) {
    const divObj = $('.row.filter-list');
    if (action === 'hide') {
      this.newClientOption.emit('hide');
      divObj.hide();
      $(".link-oneview-check").addClass('hide');
    }
    else {
      this.newClientOption.emit('show');
      divObj.show();
      $(".link-oneview-check").removeClass('hide');
      const divFilterObj = $('.filter-select.' + this.MappedSource + this.Sequence + '.p-1');
      divFilterObj.scrollTop(0);
    }
  }

  InitalizeDataSource() {
    this.showFilterSection('hide');
  }

  onSearch(filter: string, event) {
    //When client search is triggered on enter event or duplicate box click event and if clicking on outside search is happening again by blur event.
    //Below if condition code will resolve the above problem.
    this.IsTypeAheadSearch = false;
    $('#inlineLoader').removeClass('loader');
    if (event != undefined && event != null && event.type == 'click' && this.IsUseThisNameClicked == true) {
      this.IsUseThisNameClicked = false;
      return;
    }
    else if (event != undefined && event != null && event.type == 'blur' && this.IsPreviousClientSearchOnEnter == true) {
      this.IsPreviousClientSearchOnEnter = false;
      return;
    }
    else if (event != undefined && event != null && (event.code == 'Enter') || (event.type == 'click')) {
      this.IsPreviousClientSearchOnEnter = true;
      this.getClientList(filter, 3);
    }
    else if (event != undefined && event != null && (event.type == 'keyup') && (event.keyCode != 37) && (event.keyCode != 39) && (event.keyCode != 38) && (event.keyCode != 40)) {
      this.IsPreviousClientSearchOnEnter = true;
      this.IsTypeAheadSearch = true;
      this.getClientList(filter, 5);
    }
    else {
      this.IsPreviousClientSearchOnEnter = false;
    }
  }

  /**
   *  This function is to return the list of clients based on the passed search term.
   *  Also handle the previous search term for tracking purpose. Clents
   * 
   */
  getClientList(filter: string, length: number) {
    let filterLength: number;
    filter = filter.trim();
    if (filter) {
      filterLength = filter.length;
    } else {
      filterLength = 0;
    }
    if (filterLength >= length) {
      this.parseError = true;
      if (this.IsTypeAheadSearch) {
        $('#inlineLoader').addClass('loader');
      } else {
        this.loadingScreenService.startLoading();
      }
      this.clientService.GetOneViewClients(filter).subscribe(result => {
        if (this.IsTypeAheadSearch) {
          $('#inlineLoader').removeClass('loader');
        } else {
          this.loadingScreenService.stopLoading();
        }

        if ($('#txtValue' + this.MappedSource + 0).val().length == 0) {
          this.showFilterSection('hide');
        }
        else if (result && result.statusCode == "200" && result.response.length === 0) {
          this.clientList = result.response;
          this.showFilterSection('show');
        } else {
          this.showFilterSection('show');
          this.clientList = result.response;
          this.populateHeadquartes();
          if (this.clientList.length === 0) {
            this.parseError = false;
          } else {
            if (this.clientList[0].name.toLowerCase() === filter.toLowerCase()) {
              $('#hdnKey' + this.MappedSource + this.Sequence).val(this.clientList[0].accountid);
            }
            this.showFilterSection('show');
            this.selectedValueFlag = false;
          }

          if (this.previousSearchTerm !== filter) {
            this.previousSearchTerm = filter;
          }
          this.validation.emit('');
          this.loadingScreenService.stopLoading();
        }
      });

    }
    else {
      // this.resetClientSelection();
      this.showFilterSection('hide');

    }
  }

  resetClientSelection() {
    this.parseError = false;
    $('#txtValue' + this.MappedSource + this.Sequence).val('');
    $('#hdnKey' + this.MappedSource + this.Sequence).val('');
    this.clientList = [];
    this.change.emit('');
  }

  hideSelect() {
    $('.' + this.source).hide();
    this.showFilterSection('hide');
  }

  selectedValue(event, item) {
    this.dataService.setOneViewClientObject(item);
    this.IsClientNameChanged = true;
    this.parseError = false;
    this.selectedValueFlag = true;
    this.clientList = [];
    this.showFilterSection('hide');
    event.stopPropagation();
    this.clientChange.emit(item.value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  validate(control: AbstractControl): ValidationErrors {
    return (!this.parseError) ? null : {
      required: {
        valid: this.parseError,
      }
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
  }

  cleareErrorMessage() {
    this.validation.emit('');
  }

  SkipAccountSearch() {
    this.OnSkipAccountSearch.emit();
    this.showFilterSection('hide');
  }
  populateHeadquartes() {
    // customeHeadquarter
    for (let index = 0; index < this.clientList.length; index++) {
      if (this.clientList[index].address1_stateorprovince != undefined) {
        this.clientList[index].customeHeadquarter = this.clientList[index].address1_city + ', ' + this.clientList[index].address1_stateorprovince
      } else if (this.clientList[index].address1_city != undefined) {
        this.clientList[index].customeHeadquarter = this.clientList[index].address1_city;
      }
    }
  }
}
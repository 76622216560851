export class ConveneProjectRequestModel {
    engageEngagementId: number;
    initiatedBy: string;
}

export class ConveneProjectViewModel {
    conveneProjectRequest: ConveneProjectRequest;
    conveneProjectResponse: ConveneProjectResponse;
}

export class ConveneProjectRequest
{
    projectsUrl: string;
    requestPayload : ConveneProjectRequestModel;
}

export class ConveneProjectResponse
{
    statusCode: string;
    errorMessage: string;
    status: boolean;
    data: ConveneProject[];
}

export class ConveneProject
{
    projectName: string;
    contactName: string;
    contactEmail: string;
}
import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

declare var $;

@Component({
  selector: 'app-calendercontrol',
  templateUrl: './calendercontrol.component.html',
  styleUrls: ['./calendercontrol.component.css']
})
export class CalendercontrolComponent implements OnInit {
  @Input() txtDate: string;
  @Input() showCustomPlaceholder: boolean = false;
  @Input() placeholder: string = '';
  DatePickedValue: string;
  placeholderValue: string = 'mm/dd/yyyy';
  @Input() disabled: boolean;
  @Input() disableWeekend: boolean;  
  @Input() set selectedDate(dateString: string) {
    if (dateString != undefined && dateString != null && dateString != '') {
      this.CurrentSelectedDate = new Date(dateString);
      this.FormattedDate = this.datepipe.transform(this.CurrentSelectedDate, 'MM-dd-yyyy');
      console.log("Formatted date at calender control : ", this.CurrentSelectedDate, this.FormattedDate);
    }
  }
  FormattedDate: any;
  CurrentSelectedDate: Date;
  constructor(public datepipe: DatePipe) { }

  ngOnInit() {

    setTimeout(() => {

      $(':input[data-inputmask-alias]').inputmask(
        "mm/dd/yyyy", {
        yearrange: {
          minyear: 1100,
          maxyear: 9999
        }
      }
      );
      var txtCtrlName = this.txtDate;
      $('#' + txtCtrlName).datepicker(
        {
          orientation: 'auto left',
          format: 'mm/dd/yyyy',
          autoclose: true,
          todayHighlight: true,
          updateViewDate: true,
          startDate: '01/07/1100',
          endDate: '12/31/9999',
          daysOfWeekDisabled: [0,6]
        }

      );

      //disableWeekend True
      if (this.disableWeekend) {
        $('#imgcalendar_' + txtCtrlName).on('click', function (e) {
          $('#' + txtCtrlName).datepicker(
            {
              orientation: 'auto left',
              format: 'mm/dd/yyyy',
              autoclose: true,
              todayHighlight: true,
              updateViewDate: true,
              startDate: '01/07/1100',
              endDate: '12/31/9999',
              daysOfWeekDisabled: [0,6],
              onSelect: function () {
                var selected = $(this).val();
                alert(selected);
              }
            }
          );
          //$('#' + txtCtrlName).datepicker({ beforeShowDay: $.datepicker.noWeekends });
          $('#' + txtCtrlName).datepicker('show');
          $('#' + txtCtrlName).css('color', '#495057');
  
          /* below code added for calendar top and bottom position issue */
          // Issue occurs because header is fixed, We can remove if header is not fixed */
          var $this = $(this);
          var calendarHeight = $('.datepicker').outerHeight();
          var top = $this.offset().top - $this.outerHeight(); // for bottom orientation
          var autotop = $this.offset().top - $this.outerHeight() - calendarHeight; // for top orientation
  
          if ($('.datepicker').hasClass('datepicker-orient-top')) {
            setTimeout(function () {
              $('.datepicker-orient-top').css({
                'top': autotop
              });
            }, 10);
          }
  
          setTimeout(function () {
            $('.datepicker-orient-bottom').css({
              'top': top + 65
            });
          }, 10);
        });
        /* end code for calendar position issue */
      }
      //disableWeekend false
      else   {
        $('#imgcalendar_' + txtCtrlName).on('click', function (e) {
          $('#' + txtCtrlName).datepicker(
            {
              orientation: 'auto left',
              format: 'mm/dd/yyyy',
              autoclose: true,
              todayHighlight: true,
              updateViewDate: true,
              startDate: '01/07/1100',
              endDate: '12/31/9999',             
              onSelect: function () {
                var selected = $(this).val();
                alert(selected);
              }
            }
          );
          //$('#' + txtCtrlName).datepicker({ beforeShowDay: $.datepicker.noWeekends });
          $('#' + txtCtrlName).datepicker('show');
          $('#' + txtCtrlName).css('color', '#495057');
  
          /* below code added for calendar top and bottom position issue */
          // Issue occurs because header is fixed, We can remove if header is not fixed */
          var $this = $(this);
          var calendarHeight = $('.datepicker').outerHeight();
          var top = $this.offset().top - $this.outerHeight(); // for bottom orientation
          var autotop = $this.offset().top - $this.outerHeight() - calendarHeight; // for top orientation
  
          if ($('.datepicker').hasClass('datepicker-orient-top')) {
            setTimeout(function () {
              $('.datepicker-orient-top').css({
                'top': autotop
              });
            }, 10);
          }
  
          setTimeout(function () {
            $('.datepicker-orient-bottom').css({
              'top': top + 65
            });
          }, 10);
        });
        /* end code for calendar position issue */
      }

    

      $('#' + txtCtrlName).on('focus', function (e) {
        $('#' + txtCtrlName).datepicker('destroy');
        $('#' + txtCtrlName).css('color', '#495057');
      });

      $('#' + txtCtrlName).on('click', function (e) {
        $('#' + txtCtrlName).datepicker('destroy');
        $('#' + txtCtrlName).css('color', 'black');
      });

      $('#' + txtCtrlName)
        .mouseup(function () {
          $('#' + txtCtrlName).datepicker('destroy');
        })
        .mousedown(function () {
          $('#' + txtCtrlName).datepicker('destroy');
        });

      $('#' + txtCtrlName).on('focusout', function (e) {
        var selecteddate = $('.' + txtCtrlName).val();
        if (selecteddate.length == 0) {
          $('#' + txtCtrlName).css('color', '#C7C7C7');
        }
      });

    }, 50);

    if (this.showCustomPlaceholder) {
      this.placeholderValue = this.placeholder;
    }
  }

}

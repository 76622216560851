import { Component, Input, OnInit } from '@angular/core';
import { AutoCompleteForDocument } from 'src/app/model/Common/AutoCompleteMappedSource';
import { TemplateMaintenanceService } from 'src/app/services/template-maintenance.service';
import { ClientService } from 'src/app/services/clientservice';
import { EngagementService } from 'src/app/services/EngagementService';
import { KeyValue } from 'src/app/model/KeyValue';
declare var $;

@Component({
  selector: 'app-auto-complete-dropdown',
  templateUrl: './auto-complete-dropdown.component.html',
  styleUrls: ['./auto-complete-dropdown.component.css']
})
export class AutoCompleteDropdownComponent implements OnInit {
  public isValidValueError: boolean;
  private selectedValueFlag: boolean;
  private isDisplayed: boolean;
  @Input() MappedSource: string;
  //@Input() Sequence: number;
  @Input() source: string;
  @Input() NoRecordFoundFor: boolean;
  loggedInUser: any;
  clientName: any;
  isloggedInUserAdmin: boolean;
  mappedSourcePlaceholder: string;
  keyValueList: any;
  filterKeyValueList: any;
  value: any;
  //MappedSourceData = { 'clients': 'ClientsForDocument', 'engagement': 'EngagementsForDocument', 'documentType': 'DocumentTypeForDocument' }
  userObject: any;
  clientId: number;  
  engagementId: number;

  constructor(private engagementService: EngagementService, private clientService: ClientService,
    private templateMaintenanceService: TemplateMaintenanceService) {
    this.isValidValueError = false;
    this.isDisplayed = true;
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    let nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.loggedInUser = nameJSON.userName;
    }
  }

  ngOnInit(): void {
    if (this.NoRecordFoundFor) {
      this.isDisplayed = false;
    }
    this.InitializePlaceholder();
    this.InitializeDataBind();
  }

  InitializePlaceholder() {
    switch (this.MappedSource) {
      case AutoCompleteForDocument.ClientsForDocument:
        this.mappedSourcePlaceholder = 'Enter a client name or select from the list';
        break;
      case AutoCompleteForDocument.EngagementsForDocument:
        this.mappedSourcePlaceholder = 'Enter a engagement name or select from the list';
        break;
      case AutoCompleteForDocument.DocumentTypeForDocument:
        this.mappedSourcePlaceholder = 'Enter document type name or select from the list';
        break;
    }
  }

  InitializeDataBind() {
    this.BindDataSource();
  }

  onKeyUp(event) {
    this.selectedValueFlag = false;
    console.log("keyup invoked");
    if (event.keyCode === 8 && (event.target.value === '' || event.target.value === undefined)) {
      console.log("keyup backspace invoked");
      this.BindDataSource();
    }
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 88 && (event.target.value === '' || event.target.value === undefined)) {
      console.log("keyup CTRL+X invoked");
      this.BindDataSource();
    }
    if (event.keyCode === 46 && (event.target.value === '' || event.target.value === undefined)) {
      console.log("keyup delete invoked");
      this.BindDataSource();
    }
  }

  BindDataSource() {
    if (this.MappedSource === AutoCompleteForDocument.ClientsForDocument) {
      //this.clientName = 'test';
      this.isloggedInUserAdmin = true;
      this.clientService.GetClientsByActiveEngagementOfUser(this.loggedInUser, this.isloggedInUserAdmin).subscribe(result => {
        console.log('result', result);
        this.keyValueList = this.getMethood(result, 'clientId', 'clientName');
        console.log('keyValueList', this.keyValueList);
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else if (this.MappedSource === AutoCompleteForDocument.EngagementsForDocument) {
      this.clientId = 386372;
      this.isloggedInUserAdmin = true;
      this.engagementService.GetActiveEngagementsByClientOfUser(this.loggedInUser, this.clientId, this.isloggedInUserAdmin).subscribe(result => {
        console.log('result', result)
        this.keyValueList = this.getMethood(result, 'engagementId', 'engagementName');
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
        }
      },
        err => {
          console.log('Error ', err);
        });
    } else if (this.MappedSource === AutoCompleteForDocument.DocumentTypeForDocument) {
      this.engagementId = 4286;
      this.isloggedInUserAdmin = true;
      this.templateMaintenanceService.GetTemplatesByEngagementsOfUser(this.loggedInUser, this.engagementId, this.isloggedInUserAdmin).subscribe(result => {
        console.log('result', result)
        this.keyValueList = this.getMethood(result, 'templatePackageId', 'templateName');
        this.filterKeyValueList = this.keyValueList;
        if (this.filterKeyValueList.length > 0) {
          this.isValidValueError = false;
        }
      },
        err => {
          console.log('Error ', err);
        });
    }
  }

  getMethood(array: any, keyProperty: string, valueProperty) {
    let keyValueList: KeyValue[] = [];
    array.forEach(element => {
      let kv = new KeyValue();
      kv.key = element[keyProperty];
      kv.value = element[valueProperty];
      keyValueList.push(kv);
    });
    return keyValueList;
  }
  
  onChange(event) {
    this.BindDataSource();
  }  

  onSearch(value: string) {this.isValidValueError = false;
    this.ShowNoRecordMatchMessage('hide');
    this.selectedValueFlag = false;
    console.log('Auto complete dropdown value on click', value);
    if (this.isDisplayed) {
      this.hideSelect();
    } else {
      this.showFilterList();
    }
  }

  showFilterList() {
    $('.filter-select.' + this.MappedSource).show();
    this.isDisplayed = true;
    $('.filter-select.' + this.MappedSource).scrollTop(0);
  }

  hideSelect() {
    $('.' + this.source).hide();
    $('.filter-select.' + this.MappedSource).hide();
    this.ShowNoRecordMatchMessage('hide');
    this.isDisplayed = false;
  }

  ShowNoRecordMatchMessage(action: string) {
    const divObj = $('#NoRecordMatchFoundFor' + this.MappedSource);
    if (action === 'hide') {
      divObj.hide();
    } else {
      divObj.show()
    }
  }
  
  setPlaceholderValue() {
    console.log('MappedSource : ' + this.MappedSource);
    console.log('MappedSource : ' + this.mappedSourcePlaceholder);
    $('#txtValue' + this.MappedSource).attr('placeholder', this.mappedSourcePlaceholder);
  }

  selectedValue(event, item) {
    this.selectedValueFlag = true;
    //this.parseError = false;
    console.log('Selected Value ', item);
    if (item.key != 0) {
      $('#txtValue' + this.MappedSource).val(item.value);
      $('#hdnKey' + this.MappedSource).val(item.key);
    } else {
      $('#txtValue' + this.MappedSource).val('');
      $('#hdnKey' + this.MappedSource).val('');
      this.BindDataSource();
    }
    this.ShowNoRecordMatchMessage('hide');
    $('.filter-select.' + this.MappedSource).hide();
    //this.change.emit(item.value);
  }

}

import { EngagementAssignment } from 'src/app/model/Engagement/EngagementAssignments';
import { TeamMember } from 'src/app/model/Engagement/TeamMember';
import { ClientTeamMembers } from './Engagement/ClientTeamMember';
import { EngagementList } from './EngagementListModel';
export class CollaborationSite {
  collaborationSiteId: number;
  collaborationSiteName: string;
  engagementId: number;
  clientId: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedOn: string;
  isPrimaryCollaboration: boolean;
  siteURL: string;
  siteUniqueID: string;
  spPermissionStatus: string;
  siteRequestStatus: string;
  isLoggedInUserPartOfCollaboration: boolean;
  siteMemberCount: number = 0;
  isIndus: boolean = false;
  msTeamUrl: string;
  collaborationSiteCount: number = 0;
}

export class EngagementCallModel {  
  engagementId: number;
  userName: string;  
}

export class CollaborationSiteViewModel {
  engagementAssignmentList: EngagementAssignment[];
  teamMemberList: TeamMember[];
  clientTeamMemberList: ClientTeamMembers[];
  collaborationSite: CollaborationSite;
  newTeamMemberList: ADGroupUsersSyncCollabMembers[];
  newClientTeamMemberList: ClientTeamMembers[];
  collaborationSiteMembersList: CollaborationSiteMembers[];
  adGroupUsersSyncCollabMembers: ADGroupUsersSyncCollabMembers[];
  adClientSiteUsersSyncEngageClientMmembers: ADClientSiteUsersSyncEngageClientMmembers[];
  collaborationClientSite: CollaborationClientSite;
  engagement: EngagementList;  
  collaborationSpacePriorYearViewModelList :CollaborationSpacePriorYearViewModel[];
}

export class CurrentCollaborationSiteViewModel {
  index: number;
  currentCollaborationSiteViewModel: CollaborationSiteViewModel;
}

export class CollaborationSiteMembers {
  collaborationSiteMembersId: number;
  collaborationSiteId: number;
  memberName: string;
  memberEmailId: string;
  memberRoleId: number;
  memberPermissionId: number;
  keyMemberRoleId: number;
  isKeyTeamMember: boolean;  
  description: string;
}

export class CollaborationSiteMembersWithClientMemberModel {
  loggedInUser: string;
  engagementId: number;
  collaborationSiteId: number;
  adGroupUsersSyncCollabMembersList: ADGroupUsersSyncCollabMembers[];
  adClientSiteUsersSyncEngageClientMmembersList: ADClientSiteUsersSyncEngageClientMmembers[];
  collaborationSiteIndus: boolean;    
  collaborationSiteMsTeamUrl: string;
  iNDUSConsent:string;
  loggedInUserFullName: string
}

export class CollaborationClientSite {
  collaborationSiteId: number;
  collaborationSiteName: string;
  engagementId: number
  clientId: number;
  siteURL: string;
  siteUniqueID: string;
  sPPermissionStatus: string;
  siteRequestStatus: string;
  siteMemberCount: number;
}

export class ADClientSiteUsersSyncEngageClientMmembers {
  engagementId: number;
  collaborationSiteId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  title: string;
  permissionId: number;
  invitationurl: string;
  status: string;
}

export class ADGroupUsersSyncCollabMembers {
  collaborationSiteMembersId: number;
  collaborationSiteId: number;
  userName: string;
  memberEmailId: string;
  memberRoleId: number;
  groupName: string
  jobtitle: string;
  serviceLine: string;
  memberPermissionId: number;
  validateUser: boolean;
  keyMemberRoleId: number;
  isKeyTeamMember: boolean;  
  description: string;
}

export class CollaborationSiteIndusModel {
  collaborationSiteId: number;
  isIndus: boolean;  
  updatedby: string;
}

export class UpdateEngagementSiteRequestModel {  
  engagementId:number;
  engagementSiteRequestId: number;
  statusResult: string;
}

export class RemoveIndusCollaborationSiteMember {
  CollaborationSiteTeamMembers: CollaborationSiteMembers[];
  engagementId: number;
  loggedInUser: string;
  isContinuanceEligible: boolean;
}

export class CollaborationSpacePriorYearViewModel
{
    engagementId : number;
    currentCollaborationSiteId : number;
    pYCollaborationSiteID : number;
    collaborationSiteName: number;
    siteURL : string;
}

export class CollaborationSpacePriorYearMappingModel {  
  engagementId: number;
  collaborationSiteId: number;
  collaborationSiteName: string;
  siteURL: string;
  siteUniqueID: string;
  taxYear: Date;
  mappingExists: boolean = false;
}

export class CollaborationSpacePriorYearMappingModelWrapper {  
  listobj: CollaborationSpacePriorYearMappingModel[]=[];
}
export class UpdateCollaborationSpacePriorYearMappingModel {        
    selectedCollaborationSiteId: number;
    pYCollaborationSiteId: number;
    updateMapping: boolean = false;
    updatedBy: string;
}

export class UpdateCollaborationSpacePriorYearMappingModelWrapper {   
    updateCollaborationSpacePriorYearMappingModels : UpdateCollaborationSpacePriorYearMappingModel[];    
}

export class CollabrartionPermissionManagementModel
{
    currentSiteUniqueID: string;
    pYCollabSiteUniqueIDList: string[] = [];
    pYCollabSiteUniqueIDListForRemove: string[] = [];
}

export class ClientMmembersModel {
  engagementId: number;
  collaborationSiteId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  title: string;
  permissionId: number;
  invitationurl: string;
}

export class AddClientMmembersModel {
  loggedInUser: string;  
  loggedInUserFullName: string
  clientMmembersModelList: ClientMmembersModel[];
}
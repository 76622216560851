<div class="document-template-dialog-wrapper" *ngIf="isHidden">
  <div class="document-template-dialog-container" [ngClass]="{'with-max-height': isSaveSelected}">
    <div class="close-dialog">
      <div class="d-inline-block ml-1 align-top">
        <button class="btn btn-link btn-sm py-0" (click)="closeDialog($event)">
          Close
        </button>
      </div>
      <span class="d-inline-block ml-1 align-top">
        <img src="../../../../assets/images/EDP/MessageDialog/close-24px.svg" alt="Close" (click)="closeDialog($event)">
      </span>
    </div>
    <div class="row m-0 p-0 pb-2">
      <div class="col-md-12 col-xl-12 pl-0 pr-0">
        <h6 style="color:#433434;"><strong> Download template(s)</strong></h6>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Service Line </span> <span class="label mandatory">*</span></label>
        <div class="radio-buttons-container">
          <label *ngFor="let option of serviceLine; let i = index" class="custom-radio"
            [ngStyle]="{'color': option === 'Audit' || option === 'Tax' ? 'lightGrey' : 'inherit'}">
            <input type="radio" name="serviceLine" [(ngModel)]="selectedOption" [value]="option" [checked]="i === 0"
              (change)="onServiceLineChange()" [disabled]="option === 'Audit' || option === 'Tax'"
              class="custom-radio1" />
            {{ option }}
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <div>
          <label><span class="label">Primary solution family </span><span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
              <select class="dropdown rounded-0" id="ddPrimarySolutionFamily" name="PrimarySolutionFamily" [(ngModel)]="selectedSolutionFamily"
               (click)="closeIndependenceConsiderationPopover();clearTemplate()">
              <option value="Select...">Select...</option>
              <option *ngFor="let solutions of primarySolutionFamily" [value]='solutions'>
                {{solutions}}
              </option>
            </select>
          </div>
          </div>
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="isPrimarySolutionFamily">
          <span>Primary Solution Family is a required field.</span>
        </div>
      </div>

    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Independence considerations </span><span class="label mandatory">*</span></label>
        &nbsp;
        <div id="tooltip-container">
          <div>
            <img id="tooltip-image" src="../../../../assets/images/Client/QuestionMarkToolTip.svg" alt="tooltip"
                (click)="toggleIndependenceConsiderationPopoverVisiblePopover()">
                <div class="custom-tooltip" [class.show]="independenceConsiderationPopoverVisible">
                  <div>  
                    <b>Canvas: </b>
                      <a href="{{ sharePointUrlIndependenceConsideration }}" target="_blank">{{ independenceConsiderationText }}  </a>
                      <div class="close-dialog">
                        <span class="d-inline-block ml-1 align-top">
                        <img src="../../../../assets/images/EDP/MessageDialog/close-24px.svg" alt="" (click)="closeIndependenceConsiderationPopover()">
                      </span>
                      </div>
                  </div>
                </div>
          </div>
        </div>
        <div class="radio-buttons-container"  >
          <label *ngFor="let option of templateConsiderations; let i = index" class="custom-radio">
           
            <input type="radio" name="independenceConsiderations" [(ngModel)]="selectedConsiderations" [value]="option"
            [checked]="i === 0" (click)="clearTemplate(); closeIndependenceConsiderationPopover()" />
          {{ option }}
          <span class="checkmark" ></span>
           
          
              
          </label>
         
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="isConsiderations">
          <span>Independence considerations is a required field.</span>
        </div>
      </div>

    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <div>
          <label><span class="label">Select a category </span><span class="label mandatory">*</span></label>
          <div class="selectdiv">
            <div class="dropdown">
              <input type="text" id="checkbox" [(ngModel)]="selectCategory" placeholder="Select..."
                (click)="toggleCheckbox(); closeIndependenceConsiderationPopover()" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" [readonly]="isDropdownOpen">
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="onDropdownClick($event)">
                <div *ngFor="let category of templateCategory" class="dropdown-item">
                  <div class="custom-control custom-checkbox" (click)="onCheckboxChange(category)">
                    <input type="checkbox" [checked]="isChecked(category)" class="custom-control-input"
                      (change)="toggleItem(category)">
                    <label class="custom-control-label"></label>
                    <span style="vertical-align: middle;">{{ category }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" *ngIf="isCategory">
          <span>Category is a required field.</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <div >
          <label><span class="label">Select a template </span><span class="label mandatory">*</span></label>
          <div class="selectdiv"  >
            <div class ="dropdown">
              <input type="text" id="checkbox" [(ngModel)]="checkedTemplate" placeholder="Select..."
                (click)="toggleCheckboxTemplate(); closeIndependenceConsiderationPopover()" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" [readonly]="isDropdownOpen">
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="onDropdownClick($event)">
                <div *ngFor="let template of templates" class="dropdown-item">
                  <div class="custom-control custom-checkbox" (click)="onTemplateChange(template)">
                    <input type="checkbox" [checked]="isTemplateChecked(template)" class="custom-control-input"
                      (change)="toggleItem(template)">
                    <label class="custom-control-label"></label>
                    <span style="vertical-align: middle;">{{ template }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" *ngIf="isTemplate">
          <span>Template is a required field.</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Destination </span><span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
            <select class="dropdown rounded-0" id="ddDestination" name="Destination" [(ngModel)]="selectedDestination"
              (change)="onDestinationChange()" (click)="closeIndependenceConsiderationPopover()">
              <option value="Select..." >Select...</option>
              <option *ngFor="let destination of destinations" [value]='destination'>
                {{destination}}
              </option>
            </select>
            <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
              *ngIf="isDestination">
              <span>Destination is a required field.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0" *ngIf="isSaveSelected">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Client </span><span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
            <select class="dropdown rounded-0" id="ddClients" name="Clients" [(ngModel)]="clientId"
              (change)="onClientsChange()" (click)="closeIndependenceConsiderationPopover()">
              <option [ngValue]="-1">Select...</option>
              <option *ngFor="let clients of clientkeyValueList" [ngValue]=clients.key>
                {{clients.value}}
              </option>
            </select>
          </div>
          <!-- <app-auto-complete-dropdown [MappedSource]="MappedSourceData.clients" [NoRecordFoundFor]="false"></app-auto-complete-dropdown> -->
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="isClient && isSaveSelected ">
          <span>Client is a required field.</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0" *ngIf="isSaveSelected">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Engagement </span><span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
            <select class="dropdown rounded-0" id="ddEngagements" name="Engagements" [(ngModel)]="engagementId" (click)="closeIndependenceConsiderationPopover()">
              <option [ngValue]="-1">Select...</option>
              <option *ngFor="let engagements of engagementkeyValueList" [ngValue]=engagements.key>
                {{engagements.value}}
              </option>
            </select>
          <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
            *ngIf="isEngagement && isSaveSelected ">
            <span>Engagement is a required field.</span>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col-xl-12">&nbsp;</div>
    </div>
    <div class="d-flex justify-content-end px-0">
      <div>
        <button class="btn SecondaryButton rounded-0 information mr-4" (click)="Cancel()">
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button class="btn rounded-0 information" (click)="DownlodTemplateWorkItem()" *ngIf="!isSaveSelected">
          <span class="d-inline-block align-middle">Download</span>
        </button>
        <button class="btn rounded-0 information" (click)="SaveTemplateWorkItem()" *ngIf="isSaveSelected">
          <span class="d-inline-block align-middle">Save</span>
        </button>
      </div>
      
    </div>
    
    
    



  </div><!-- modal-dialog -->
</div>
<!--modal -->

<!--Export Document Saved POPUP-->
<app-export-document-saved-popup>
</app-export-document-saved-popup>
<!--Export document Saved POPUP-->
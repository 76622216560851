export class solutionCodePSDMaping {
    id: number
    solutioncodeId: number;
    psdName: string;
    psdEmailId: string;
    createdBy: string;
    createdOn: Date;
    updatedBy: string;
    updatedOn: Date;
}
export class PostMappingPSDModel {
    psdMappinglist: solutionCodePSDMaping[];
}

export class PSDDataForUserCheck {
    id: number
    solutioncodeId: number;
    psdName: string;
    psdEmailId: string;
    psdDelegateMemberName: string;
    psdDelegateMemberEmailId: string; 
    createdBy: string;
    createdOn: Date;
    updatedBy: string;
    updatedOn: Date;
}
import { CollaborationSiteService } from '../../../services/collaboration-site.service';
import { SharePointMetadataMapping } from '../../../model/sharepoint-metadata-mapping';
import { SPSiteMetadata } from '../../../model/SharePoiint/SharePointSite';
import { Component, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { DatePipe } from '@angular/common';

import { HotDocsService } from '../../../services/HotDocsService';

import { WorkspaceService } from '../../../services/workspace.service';
import { TeamMembers, TeamMemberForReviewer, TeamMemberEPModel, ManualRPAddModel, WorkspaceMemberRemoveModel, AutoRPReplaceModel, RemoveClientTeamMemberModel, PowerAutomateRequest, PowerAutomateRequestModel } from '../../../model/Workspace/TeamMembers';
import { WorkspaceDiscussion } from 'src/app/model/Workspace/WorkspaceDiscussion';
import { WorkspaceComments } from 'src/app/model/Workspace/WorkspaceComments';
import { WorkspaceSupplementalDoc } from 'src/app/model/Workspace/WorkspaceSupplementalDoc';
import { SupplementalDocCategory } from 'src/app/model/Workspace/SupplementalDocCategory';
import { WorkspaceReviewPointService } from '../../../services/workspaceReviewPointService';
import { WorkspaceReviewPoint, ImageModel, WorkspaceReviewPointModel, WorkspaceReviewPointComment, ReviewPointsWithCommentsModel, ReviewNoteSearchableUser, ReviewNoteMentionedUser } from '../../../model/WorkspaceReviewPoint';

import { InterviewModel } from 'src/app/model/InterviewModel';
import { HdWorkspaceDocumentModel, WorkspaceDocumentModel, WorkspaceDocumentViewModel } from 'src/app/model/WorkspaceDocumentModel';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkspaceModel, WorkspaceInterviewsStatus, QuestionnaireDialogTitles, RemoveSecondaryWorkspaceModel, IndusDialogTitles } from 'src/app/model/WorkspaceModel';
import { AzureADUsersArrayList, SearchGroupUserViewModel, IndusADUsersViewModel, IndusADUsersListViewModel, AzureADGroups } from 'src/app/model/AzureADUsersArrayList';
import { MembersListArray } from 'src/app/model/MembersListArray';
import { AdUsersService } from 'src/app/services/adusers.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { EnumKeyTeamMemberRoleCodes, TeamMemberRole } from 'src/app/model/Engagement/TeamMemberRole';
import { EngagementService } from 'src/app/services/EngagementService';
import { RoleNames, EnumRoles, RevievingProfessionalType } from 'src/app/model/Role';
import { RPNotificationModel, WorkspaceStatusUpdateModel } from 'src/app/model/WorkspaceStatusUpdateModel';
import { WorkspaceApprovalRejectionDetails } from 'src/app/model/WorkspaceApprovalRejectionDetails';
import { AppConfigService } from 'src/app/services/appConfigService';
import { HDConfigurationModel } from 'src/app/model/HDConfigurationModel';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { AlertsService } from 'src/app/services/alerts.service';
import { CMSService } from 'src/app/services/cmsservice.service';
import { Employee } from 'src/app/model/Workspace/Employee';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { SearchEmployeeModel } from 'src/app/model/Workspace/SearchEmployeeModel';
import { Subscription, forkJoin, of } from 'rxjs';
import { AssignmentRequestList } from 'src/app/model/Workspace/AssignmentRequestList';
import { MembersList } from 'src/app/model/MembersList';
import { SharepointSiteDocumentModel, SPDocument, DocumentParamModel, SPDocumentList } from 'src/app/model/SharePoiint/SharePointSite';
import { EngagementList } from 'src/app/model/EngagementListModel';
import { SharepointService } from 'src/app/services/sharepoint.service';
import { ADGroup, ADUser, UpdatePermissionsModel, UpdatePermissionsModelData, UsersListEmailModel } from 'src/app/model/SharePoiint/ActiveDirectory';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';
import { RPDisplayModel } from 'src/app/model/Workspace/RPDisplayModel';
import { Alert } from 'src/app/model/Alert';
import { ADClientSiteUsersSyncEngageClientMmembers, AddClientMmembersModel, ClientMmembersModel, CollaborationSiteMembers, CollaborationSiteViewModel } from 'src/app/model/CollaborationSite';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { AzureADGroupRoleShortNamesModel, AzureADUsersList } from 'src/app/model/AzureADUserList';
import { SharePointWrapper } from 'src/app/model/sharepoint-metadata-wrapper';
import { TeamMember } from 'src/app/model/Engagement/TeamMember';
import { AssignmentResponseList } from 'src/app/model/Workspace/AssignmentResponse';
import { Engagement, NotificationOnlyRequestModel } from 'src/app/model/decisions/notifications/notification-request';
import { NotificationResponseViewModel } from 'src/app/model/decisions/notifications/notification-response-viewmodel';
import { DecisionsNotificationProfessionalsListModel } from 'src/app/model/Workspace/DecisionsNotificationProfessionalsListModel';
import { DecisionsRequestTypes, NotificationOnlyRemoveRemarks, NotificationWorkflowStage } from 'src/app/model/Workspace/DecisionsApiLogModel';
import { DecisionsRuleEngineService } from 'src/app/services/Integration/decisions-rule-engine.service';
import { IndusFlags, RPPermissionModel, UpdateINDUSFlag } from 'src/app/model/engagements';

import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReviewResponseModel } from 'src/app/model/Workspace/ReviewResponseModel';
import { DataModificationModuleNames, EngagementCollabData, EngagementStatus, Engagements } from 'src/app/model/Engagement/Engagements';
import { ApproveRejectWorkspaceResultModel, AutoRpMembersWithDelegate, AutoRpMembersWithDelegateModel, DecisionsRPRequestModel } from 'src/app/model/Workspace/DecisionsRPRequestModel';
import { DecisionsRPReponseViewModel } from 'src/app/model/Workspace/DecisionsRPResponseViewModel';
import { DecisionsRPListModel } from 'src/app/model/Workspace/DecisionsRPListModel';
import { ContinuanceEngagementValidationModel } from 'src/app/model/continuanceEngagementValidationModel';
import { ReviewNoteFilterBy, ReviewNoteStatus, WhoCanResolveReviewNote } from 'src/app/model/Workspace/ReviewNote';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { WorkItemDetailsModel } from 'src/app/model/HDWorkItemModel';
import { DataModificationService } from 'src/app/services/datamodification.service';
import { ClientMemberPermission, ClientMemberPermissionModel, TeamMemberPermission } from 'src/app/model/Engagement/TeamMemberPermission';
import { CloseSiteUrl } from 'src/app/model/SharePoiint/close-site-url-list';
import { ConveneApiLogModel, ConveneRequestTypes, RequestMethods } from 'src/app/model/ConveneIntegration/convene-api-log-model';
import { ClosureRequestModel } from 'src/app/model/ConveneIntegration/closure-request-model';
import { ClosureService } from 'src/app/services/Integration/closure.service';
import { QnARecordForDelete } from 'src/app/model/Workspace/QnARecordForDelete';
import { ClientTeamMembers } from 'src/app/model/Engagement/ClientTeamMember';
import { EngageEvent } from 'src/app/model/Engagement/EngageEvent';
import { LogService } from 'src/app/services/log.service';
import { catchError } from 'rxjs/operators';
import { ReviewNotesComponent } from '../review-notes/review-notes.component';
import { AddWorkspaceDocumentComponent } from '../add-workspace-document/add-workspace-document.component';
import { CloseoutConfigurationRequest } from 'src/app/model/decisions/closeout/closeout-configuration-request';
import { AppCodes, Applicationmaster } from 'src/app/model/ApplicationMaster';
import { DataModification } from 'src/app/model/data-modification-model';
import { CloseoutSectionConfigResponse, SolutionCodeConfigResponse } from 'src/app/model/decisions/solutioncodeproperties/Solutioncodeproperties-response';
import { MemberFirmData } from 'src/app/model/MemberFirm/member-firm-data';
import { MemberFirmService } from 'src/app/services/member-firm.service';
import { AuditPartnerDialogTypes } from 'src/app/model/enums/audit-partner-dialog-types.enum';
import { SolutionCodeDetailsModel } from 'src/app/model/solution-code-details-model';
import { RemoveAuditPartnerModel } from 'src/app/model/Workspace/ap-hotdocs-answer-and-count.model';
import { AuditPartnerDialogComponent } from 'src/app/common/modal/audit-partner-dialog/audit-partner-dialog.component';
import { GoverningAgreementNameAndStatusRequest } from 'src/app/model/CLM/governing-agreemen-request';
import { GoverningAgreementNameAndStatusResponse } from 'src/app/model/CLM/governing-agreement-response';
import { CLMService } from 'src/app/services/clm.service';
import { CLMApiLog } from 'src/app/model/CLM/clm-api-log';
import { CLMApiLogrRequestType } from 'src/app/model/enums/clm-api-log-request-type.enum';
import { EngagementStatusViewModel } from 'src/app/model/Engagement/engagement-status-view-model';
import { ClientConsent } from 'src/app/model/Client/client-consent';
import { ClientConsentRequest } from 'src/app/model/Client/client-consent-request';
import { ClientService } from 'src/app/services/clientservice';
import { ClientDeleteModel } from 'src/app/model/Client/client-delete-model';
import { CLMDetails } from 'src/app/model/CLM/clm-details';
import { FilterTaskRequestModel } from 'src/app/model/avepoint/filter-task-request-model';
import { EngagementChangeOrdersModel } from 'src/app/model/change-order/engagement-change-orders-model';
import { ChangeOrderService } from 'src/app/services/change-order.service';

declare var $;
declare var HD$;
let imagePlus;
let imageMinus;

class localCombinedRoleArrayWithMemberTypeModel {
  role: string;
  memberType: string;
}

interface RPDeterminationAssignmentsDetail {
  assignmentCodeId: number;
  engagementId: number;
  solutionCodeId: number;
  solutionCode: string;
  netFees: number;
  estimatedGrossMargin?: number;
  cmsppmdEmailId: string;
  eqcrEmailId: string;
  engagementPartnerEmailId: string;
}

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css']
})
export class WorkspaceComponent implements OnInit, AfterViewInit, OnDestroy {
  [x: string]: any;
  imagePathPlus: string;
  imagePathMinus: string;
  reviewerList: TeamMembers[] = [];
  teamsMembersList: TeamMemberEPModel = new TeamMemberEPModel();
  reviewersListForRP: TeamMemberForReviewer[] = [];
  newReviewer: any = {};
  reviewer: TeamMembers = new TeamMembers();
  memberList: TeamMembers[] = [];
  teamMember: TeamMembers = new TeamMembers();
  reviewerADUsersList: AzureADUsersArrayList;
  reviewersListArray: MembersList[];
  teamMembersListArray: MembersList[];
  reviewersTransferListArray: MembersListArray;
  memberADUsersList: AzureADUsersArrayList;
  membersListArray: MembersListArray;
  memberListArray: MembersList[];
  DocumentContent: any;
  PriviewData: Uint8Array;
  sanitizedImageData: any;
  sessionid: string;
  docName: string;
  imageBlobUrl: any;
  InterviewList: InterviewModel = new InterviewModel();
  DocumentPreviewList: HdWorkspaceDocumentModel[] = [];
  workspaceDocumentList: WorkspaceDocumentViewModel[] = [];
  selectedWorkspaceDocumentItem = new WorkspaceDocumentViewModel();
  WorkspaceDocumentPreviewList: WorkspaceDocumentModel[] = [];
  ConvertedBlob: Blob;
  DocumentPreviewListCheck: boolean = false;
  currentWorkespaceMemberId: number;
  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  InterviewsDataOnWorkspace: WorkspaceModel = new WorkspaceModel();
  QuestionnaireData: WorkspaceModel = new WorkspaceModel();
  teamMemberRoleList: TeamMemberRole[] = [];
  workspaceteamMemberRoleList: TeamMemberRole[] = [];
  isLoggedinUserCreatedby: boolean;
  IntegrationAPIEndPoint: string;
  EditQuestionnaireData: WorkspaceModel = new WorkspaceModel();
  isLoggedInUserSystemAdmin: boolean = false;
  isTransferButtonVisibleForMe: boolean = false;
  currentWorkSpaceStatus: string;
  employeeList: Employee[] = [];
  TeamReviewStatus: string = '';
  EPPMDReviewStatus: string = '';
  RPInReviewStatus: string = '';
  isUserEP = false;
  isUserRP = false;
  isUserTM = false;
  private modalConfirmationDialogRef: Subscription = null;
  TeamMemberButtonSectionVisible: boolean = false;
  EPPMDButtonSectionVisible: boolean = false;
  RPButtonSectionVisible: boolean = false;
  isLoggedInUserCR_AA_CMSEP = false;
  lastUpdatedByForReviewResponse: string;
  OpenReviewCount: number;
  AbandonedTitle = WorkspaceInterviewsStatus.AbandonedTitle;
  SendToEPPMDTitle = WorkspaceInterviewsStatus.SendToEPPMDTitle;
  EPDeclinedTitle = WorkspaceInterviewsStatus.EPDeclinedTitle;
  RPDeclinedTitle = WorkspaceInterviewsStatus.RPDeclinedTitle;
  EPApprovedTitle = WorkspaceInterviewsStatus.EPApprovedTitle;
  RPApprovedTitle = WorkspaceInterviewsStatus.RPApprovedTitle;
  EditQuestionnaireTitle = WorkspaceInterviewsStatus.EditQuestionnaire;
  validateTM: boolean[] = new Array(15);
  selectedRPIndex: number;
  imageModel: ImageModel = new ImageModel();
  workspaceReviewPointModel: WorkspaceReviewPointModel = new WorkspaceReviewPointModel();
  ReviewPointModelImages: ImageModel[] = [];
  public workspaceReviewPointModelList: Array<WorkspaceReviewPointModel>;
  public workspaceReviewPointModelListOriginal: Array<WorkspaceReviewPointModel>;
  solutionCodeConfigResponse: CloseoutSectionConfigResponse = new CloseoutSectionConfigResponse();
  hasAnyWorkpaceApproval: boolean = false;
  spDocument: SPDocument = new SPDocument();
  sharepointSiteDocumentModel: SharepointSiteDocumentModel = new SharepointSiteDocumentModel();
  addReviewerIsInProgress: boolean = false;
  addTeamMemberIsInProgress: boolean = false;
  documentParamModel: DocumentParamModel = new DocumentParamModel();
  currentfileName: string;
  discussionandReviewPointsCount: number;
  adminNameCtrl: string;
  teamMemberCtrl: string;
  teamMemberEmailCtrl: string;
  autoRPDisplayModel: RPDisplayModel[] = [];
  manualRPDisplayModel: RPDisplayModel[] = [];
  rpDisplayModel: RPDisplayModel = new RPDisplayModel;
  currentTeamMemberIndex: any;
  teamMemberRoleName: string;
  indusMembersListArray: Array<IndusADUsersViewModel> = [];
  indusMembersListArrayOnFinalApproval: Array<IndusADUsersViewModel> = [];
  indusADGroupsList: AzureADUsersList[] = [];
  indusTeamMemberData: IndusADUsersViewModel = new IndusADUsersViewModel();
  validIndusMemberList: TeamMembers[] = [];
  notificationOnlyProfessionals: RPDisplayModel[] = [];
  isPureNotificationOnlyProfessional: boolean = false;
  isPureRP: boolean;
  workspaceIdForSummaryReport: string;
  siteUrlForSummaryReport: string;
  isDownloadForSummaryReport: boolean;
  sharepointSiteURL: string;
  applicationBaseURL: string;
  isNewClientRequest: boolean;
  isDeclinedRequest: boolean;
  engageSupportCanvasLink: string = '';
  continuanceEngagementValidationModel: ContinuanceEngagementValidationModel[] = [];
  isQualityUserLoggedIn: boolean = true;
  isQualityMemeberLoggedIn: boolean = false;
  countOfQualityGroupRole: number = 0;

  subscription: Subscription;
  isCollapsibleLeftNavVisible: boolean;
  whoCanResolveReviewNote = WhoCanResolveReviewNote;

  keyteamMemberRoleFromAppMstList: Applicationmaster[] = []
  keyTeamMemberRoleCodes: AppCodes[] = [];
  isKeyTeamMember: boolean = false;
  KeyTeamMemberRoleId: number = 0;
  KeyDescription: string = '';
  isAudit: boolean;
  isValid: boolean;

  @ViewChild('reviewNoteDiv', { static: false }) reviewNoteDiv: ElementRef;
  @ViewChild('reviewNoteCommentDiv', { static: false }) reviewNoteCommentDiv: ElementRef;
  reviewNoteMentionedUser: ReviewNoteMentionedUser[] = [];
  reviewNoteSearchableUsers: ReviewNoteSearchableUser[] = [];
  reviewNoteSearchableUsersFromQualityGroups: ReviewNoteSearchableUser[] = [];
  HDWorkitemDetails_Review: WorkItemDetailsModel = new WorkItemDetailsModel();
  ReviewInProgressHDSession: boolean = false;
  reviewInProgressEditEngagementMembers: string = "";
  dataModificationDetails: DataModification = new DataModification();
  solutionCodes: string[] = [];
  ReviewInProgressEditEngagement: boolean = false;
  workspaceMemberRemoveModel: WorkspaceMemberRemoveModel = new WorkspaceMemberRemoveModel();
  HDInProgressAlertLimitInHours: any;

  isReplaceRP: boolean = false;
  isAddRP: boolean = false;
  indexofReplaceReviewerRoleName: number;
  indexofReplaceReviewerMemberMail: number;
  roleNameOfReplace: string;
  approveRejectWorkspaceResultModel: ApproveRejectWorkspaceResultModel = new ApproveRejectWorkspaceResultModel();

  membersListArrayForTeamMembers: any;

  selectedClientTeamMemberId: number;
  clientMemberPermissions: ClientMemberPermission[] = [];
  clientMemberPermissionModelList: ClientMemberPermissionModel[] = [];
  collaborationSiteViewModel = new CollaborationSiteViewModel();
  newClientTeamMemberList: Array<ClientTeamMembers> = [];
  newClientTeamMember: any = {};
  defaultClientPermissionId: number
  arrValidationForNewClientTeamMember: any = [];
  clientMmembersModelList: ClientMmembersModel[] = [];
  addClientMmembersModel: AddClientMmembersModel = new AddClientMmembersModel();
  isReplaceRpOkButtonEnabled: boolean = true;
  reviewNoteComponentRef: any;
  addWorkspaceDocumentComponentRef: any;
  secondaryWorkspaceDataList: WorkspaceModel[] = [];
  allWorkspaceDataList: WorkspaceModel[] = [];
  addDocumentsLimit: Number = 0;
  isAddDocumentLimitExceeded: boolean = false;
  uploadFileValidationError: boolean;
  uploadFileNameFormat: RegExp = /["*:;<>?\/|#%~&\\]/;
  uploadFileSize: number = 20971520  //In Bytes (=20MB)

  isSharepointFileLabelFeatureFlagEnabled: boolean = false;
  sharePointSelectedFileLabel = ""
  sharePointSelectedFileLabelForFilter = "All";
  sharePointSelectedFile: SPDocumentList;
  sharePointApplicationMasterLabels: Applicationmaster[] = [];
  sharePointAddFileFlag: boolean = false;
  sharePointApplicationMasterLabelsFiltered: Applicationmaster[] = [];

  isMemberFirm: boolean = false;
  memberFirmData: MemberFirmData;
  engagements: Engagements;
  azureADUsersArrayListMemberFirm: AzureADUsersArrayList;

  isApprove: boolean = false;
  IsSolutionCodeConfigFeatureEnabled: boolean = false;
  IsUserAccessPermission: boolean = false;
  IsMember: boolean = false;

  showAuditPopup: boolean = false;
  dialogDescriptionType: string;
  rpcount: number = 0;
  solutionCodeDetails: SolutionCodeDetailsModel;
  @ViewChild('auditPartnerDialog', { static: false }) auditPartnerDialog: AuditPartnerDialogComponent;

  @ViewChild('clmGoverningAgreementStatusReviewDiv') clmGoverningAgreementStatusReviewDiv: ElementRef;
  isCLMFeatureEnabled: boolean = false;
  gaNameAndStatusResponse: GoverningAgreementNameAndStatusResponse;
  gaNameAndStatusRequest: GoverningAgreementNameAndStatusRequest;
  skipRedirect: boolean = false;
  engStatus: EngagementStatusViewModel;
  clmDetails: CLMDetails;


  isClientConsentFeatureEnabled: boolean = false;
  isClientConsentWarningEnabled: boolean = false;
  isChangeOrderFeatureEnabled: boolean = false;

  //Change Order variable
  engagementState: string = 'Acceptance';
  workspaceType: string = "E";
  engagementChangeOrders: EngagementChangeOrdersModel;


  constructor(private workspaceService: WorkspaceService,
    private activatedRoute: ActivatedRoute,
    private workspaceReviewPointService: WorkspaceReviewPointService,
    private applicationMasterService: ApplicationMasterService,
    private hdService: HotDocsService, private data: DataService, private loadingScreenService: LoadingScreenService,
    private adUsersService: AdUsersService, private _engService: EngagementService,
    private appService: AppConfigService,
    private _alertService: AlertsService,
    private router: Router,
    private cmsService: CMSService,
    private graphSerivce: GraphService,
    public datepipe: DatePipe, private _sharepointService: SharepointService,
    private _activedirectoryService: ActiveDirectoryService,
    private collaborationSiteService: CollaborationSiteService,
    private decisionRuleEngineService: DecisionsRuleEngineService,
    private dataModification: DataModificationService,
    private closureService: ClosureService,
    private logService: LogService,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private memberFirmService: MemberFirmService,
    private configurationService: ConfigurationService,
    private el: ElementRef,
    private clmService: CLMService,
    private _clientService: ClientService,
    private changeOrderService: ChangeOrderService
  ) {
    this.imagePathPlus = '../../../assets/images/workspace/accordian-plus.png'
    this.imagePathMinus = '../../../assets/images/workspace/accordian-minus.png';
    this.IntegrationAPIEndPoint = this.appService.config.DATA.API_END_POINT.INTEGRATION_API_URL;

    imagePlus = this.imagePathPlus;
    imageMinus = this.imagePathMinus;
    this.workspaceOpenReviewPointsCount = 0;
    this.NoReviewNotesMsg = 'No review notes';
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.applicationBaseURL = this.appService.config.DATA.AZURE_AD_AUTHENTICATION.LOGIN_REDIRECT_URL;
    this.isDeclinedRequest = false;
    this.isDownloadForSummaryReport = false;
    this.isNewClientRequest = false;
    this.addDocumentsLimit = Number(this.appService.config.ADD_MULTIPLE_DOCUMENT_LIMIT) <= 0 ? 1 : Number(this.appService.config.ADD_MULTIPLE_DOCUMENT_LIMIT);
    this.isPureRP = false;
  }

  workspaceDiscussionList: WorkspaceDiscussion[];
  workspaceDiscussion: WorkspaceDiscussion = new WorkspaceDiscussion();
  modalWorkspaceDiscussion: WorkspaceDiscussion = new WorkspaceDiscussion();
  worspaceCommentsList: WorkspaceComments[];
  workspaceComment: WorkspaceComments = new WorkspaceComments();
  modalWorkspaceComment: WorkspaceComments = new WorkspaceComments();
  workspaceSupplementalDoc: WorkspaceSupplementalDoc = new WorkspaceSupplementalDoc();
  modalSupplementalDocCategories: SupplementalDocCategory[];
  EditMode: boolean = false;
  workspaceReviewPoints: WorkspaceReviewPoint[] = [];
  reviewPointsWithCommentsModel: ReviewPointsWithCommentsModel = new ReviewPointsWithCommentsModel();

  workspaceReviewPointsCount: number;
  workspaceOpenReviewPointsCount: number;
  NoReviewNotesMsg: string
  workspaceReviewPointsForInsert: WorkspaceReviewPoint[] = [];
  currentworkspaceReviewPoints: WorkspaceReviewPoint = new WorkspaceReviewPoint();
  currentworkspaceReviewPointComment: WorkspaceReviewPointComment = new WorkspaceReviewPointComment();
  workspaceReviewPointCommentForInsert: WorkspaceReviewPointComment[] = [];
  workspaceReviewPointComments: WorkspaceReviewPointComment[] = [];
  workspaceModel: WorkspaceModel = new WorkspaceModel();

  reviewPointId: number;
  CurrentWorkspaceId: string;
  CurrentWorkItemId: string;
  workspaceId: string;
  workspaceStatusUpdateViewModel: WorkspaceStatusUpdateModel = new WorkspaceStatusUpdateModel();
  workspaceApprovalRejectionDetails: WorkspaceApprovalRejectionDetails = new WorkspaceApprovalRejectionDetails();
  isApproveRejectVisibleForMe: boolean;
  isWorkspaceApprovedOrRejected: boolean;
  isLoggedInUserCanApproveOrReject: boolean;
  isLoggedInUserFinalApprover: boolean = false;
  isUserBeingRemoveFinalApprover: boolean = false;
  hasAnyRPThanOneBeingRemove: boolean = false;
  isUserLastRP: boolean = false
  userInfo: any;
  isEditEngagementButtonVisibleForMe: boolean = false;
  isEditQuestionairButtonVisibleForMe: boolean = false;
  isApproveRejectButtonVisibleForMe: boolean = false;
  isInfoMessageVisibleForMe: boolean = false;
  CurrentDocumentId: string;
  isAbondonButtonVisibleForMe: boolean = false;
  interviewOptions: any;
  HdConfigDetails: HDConfigurationModel = new HDConfigurationModel();
  temp: any;
  currentReviewerForTransfer: number;
  idLoggedInUserHasWorkspaceAccess: boolean = false;
  InterviewsDataOnShowInterview: WorkspaceModel = new WorkspaceModel();
  TemplatePackageDetails: TemplatePackageModel = new TemplatePackageModel();
  workspaceIdForActivityLog: string;
  workspaceIdForQuestionnarieSummary: string;
  shouldNotifyEPPMD: boolean = false;
  isEPPMDDecline: boolean = false;
  isRPDecline: boolean = false;
  isEPPMDApprove: boolean = false;
  isRPApproved: boolean = false;
  workItemIdForReviewResponse: string;
  templatePackageVersionIdForReviewResponse: string;
  selectContactEmailId: string;
  selectedTMIndex: string;
  isEPPMDAbandon: boolean = false;
  isTMAbandon: boolean = false;
  isSendToEPPMDBtnDisable: boolean = false;
  isEPPMDDeclineBtnDisable: boolean = false;
  isRPDeclineBtnDisable: boolean = false;
  isEPPMDApproveBtnDisable: boolean = false;
  isRPApprovedBtnDisable: boolean = false;
  isWorkspaceScreen: boolean = false;
  isEditQueNewVersion: boolean = false;
  isNewVersionForCR_CMSEP_AA: boolean = false;
  currentEngagement: EngagementList = new EngagementList();
  supplementalDocumentList: SPDocumentList[] = [];
  permLibraryDocumentList: SPDocumentList[] = [];
  currentDocument: SPDocumentList = new SPDocumentList();
  alert: Alert = new Alert();
  hasSiteProvisioned: boolean = false;
  IsLoggedInUserIsTM_EP: boolean = false;
  sendCMSRequestOnSignature: boolean = false;
  collaborationSiteMembersList: CollaborationSiteMembers[] = [];
  engagementId: number = 0;
  applyFilterOnceAfterDataLoad: boolean;
  clientId: number = 0;
  workItemVersionIdReviewResponse: string;
  isLoggedInUserIsAutoOrManualRP: boolean = false;
  isEPPMDAsRP: boolean = true;
  isEngagmentRPRuleEngineError: boolean = false;
  isAbandonDisabled: boolean = false;
  maxCharactersAllowedForReviewNoteDescription: number = 500;
  warningCharacterCount: number = 475;
  isINDUSStaffingManagerLoggedIn: boolean = false;
  isReviewINDUSStaffingManagerHasAccess: boolean = false;
  enagagementCollaborationDetails: EngagementCollabData = new EngagementCollabData();

  epRoleName: string;
  assignAdminRoleName: string;
  eplblCtrlName: string;
  teamMemberlblCtrlName: string;
  assignAdminlblCtrlName: string;
  azureADUsersArrayList: AzureADUsersArrayList = new AzureADUsersArrayList();
  teamMemberPermissions: TeamMemberPermission[];
  defaultPermissionId: number;
  isConveneProjectsExists: boolean = false;
  orginalworkItemIdForReviewResponse: string;
  orginalworkItemVersionIdReviewResponse: string;
  workspaceDocumentCount: number = 0;
  currentlyProcessingEngageSummaryReportName: string = '';
  multipleReportGenerationInProgress: boolean = false;
  currentDocumentProcessingIndex: number = 0;
  multipleDocumentGenerationLoaderToken: any;
  UnansweredQuestionCheckInProgress: boolean = false;
  UnansweredQuestionCheckDialogType: string = '';
  UnansweredQuestionCheckAction: string = '';
  UnansweredQuestionCheckIsDeclineModal: boolean = false;
  UnansweredQuestionCheckLoaderToken: any;
  isPermLibraryFeatureFlagEnabled: boolean = false;
  IsDecisionCallNeeded: boolean = false;
  isIndusFlag: boolean;
  isIndusFlagEnabled: boolean;
  IsIndusCurrent: boolean;
  updateINDUSFlag: UpdateINDUSFlag = new UpdateINDUSFlag();
  indusFlags: IndusFlags = new IndusFlags();
  currentIndusFlag: boolean;
  oldIndusFlag: boolean;

  ngOnInit() {
    this._alertService.remove(this.alert);
    this.epRoleName = RoleNames.EngagementPartner.toString();
    this.teamMemberRoleName = RoleNames.StaffMember.toString();
    this.assignAdminRoleName = RoleNames.AssignmentAdministrator.toString();
    this.eplblCtrlName = 'engagement PPMD';
    this.teamMemberlblCtrlName = 'team member';
    this.assignAdminlblCtrlName = 'assignment administrator';
    let jsonString = localStorage.getItem("currentUser");
    this.userInfo = JSON.parse(jsonString);
    this.getChangeOrderFeatureFlag();
    this.IsLoggedinUserSystemAdmin();
    this.GetTeamMemberPermissions();
    this.getClientMemberPermissions();

    this.data.CurrentWorkspaceId.subscribe(workspaceId => this.CurrentWorkspaceId = workspaceId);
    this.data.currentWorkItemId.subscribe(workItemId => this.CurrentWorkItemId = workItemId);
    this.data.getConveneProject$.subscribe(isExists => this.isConveneProjectsExists = isExists);
    console.log('Workspace Id' + this.CurrentWorkspaceId);
    this.activatedRoute.paramMap.subscribe(params => {
      this.workspaceId = (params.get('workspaceId')).toString();
      this.redirectToCorrectEngagementState(this.workspaceId);
      this.GetAllMembersFromADGroups(this.workspaceId);
      this.getWorkspaceData(this.workspaceId);
      this.getHDConfigData();
      $('.ui-dialog-content .ui-widget-content').css('display', 'none');
      $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
      $('.ui-widget-overlay.ui-front').remove();
    });

    this.GetPrimarySolutionCodeisAuditByWorkspaceId(this.workspaceId);
    this.GetEngagementDetailsByworkspaceId(this.workspaceId);
    this.getWorkspaceDetails(this.workspaceId);
    this.GetWorkspaceDiscussions(this.workspaceId);
    this.getWorkspaceReviewPoints();
    $('div[data-parent*="#supplAccordion"]').on("click", function () {
      var refDiv = $(this).attr("data-target");
      if (refDiv === "#billingInfo") {
        if ($(refDiv).hasClass("show")) {
          $(this)
            .find(".img-accordian")
            .attr("src", imagePlus);
        } else {
          $(this)
            .find(".img-accordian")
            .attr("src", imageMinus);
        }
      }
      if (refDiv === "#feesInfo") {
        if ($(refDiv).hasClass("show")) {
          $(this)
            .find(".img-accordian")
            .attr("src", imagePlus);
        } else {
          $(this)
            .find(".img-accordian")
            .attr("src", imageMinus);
        }
      }
      if (refDiv === "#auditInfo") {
        if ($(refDiv).hasClass("show")) {
          $(this)
            .find(".img-accordian")
            .attr("src", imagePlus);
        } else {
          $(this)
            .find(".img-accordian")
            .attr("src", imageMinus);
        }
      }
    });

    this.employeeList = [];
    this.initinateConfirmationPopUp();

    const contactPopup = $('#contact-popup');
    contactPopup.hide();

    this.selectContactEmailId = null;
    this.applyFilterOnceAfterDataLoad = false;
    this.getWorkspaceReviewPointsCount();

    this._engService.GetPremLibraryFeatureFlag().subscribe(result => {
      this.isPermLibraryFeatureFlagEnabled = result;
    });

    this.getSharepointFileLabelFeatureFlag();
    this.getSharepointFileLabels();
    this.data.setCloseReviewNotes(false);

    this.subscription =
      this.data.isCollapsibleLeftNavVisible$.subscribe(value => {
        this.isCollapsibleLeftNavVisible = value
      });

    this.invokeUpdateUser();
    this.setIsMemberFirm();

    this.getKeyTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and Key team member addition

  }
  getChangeOrderFeatureFlag() {
    this.workspaceService.getChangeOrderFeatureFlag().subscribe({
      next: result => {
        this.changeOrderFeatureFlag = result;
        this.getActivityTypeByWorkSpaceId();
      }
      , error: er => {
        this.changeOrderFeatureFlag = false;
        //console.log("failed to load changeOrderFeatureFlag");
      }
    })
  }
  CheckUserIsPrimaryWorkspaceMember() {
    return this._engService.CheckUserIsMember(this.currentEngagement.engagementId,
      this.userInfo.loggedInUserEmail).subscribe(member => {
        if (member) {
          this.IsMember = true;
        }
        this.IsUserAccessPermission = (this.IsMember ? true :
          (this.isLoggedInUserSystemAdmin ? true :
            (this.isQualityMemeberLoggedIn ? true : false)));


        if (!this.IsUserAccessPermission) {

          this.alertServiceFun('You do not have access to view details !', 'danger');
        }

      })
  }

  invokeUpdateUser() {
    this.workspaceService.getAuthUser();
  }

  GetHDWorkItemDetails(action: string, isDeclineModal: boolean, isRPApprovedAction: boolean = false) {
    this.loadingScreenService.startLoading();
    const editEngPromise = this.dataModification.IsInProgressDataModificationExists(this.CurrentWorkspaceData.engagementId, DataModificationModuleNames.EditEngagement);
    const editQuePromise = this.dataModification.IsInProgressDataModificationExists(this.CurrentWorkspaceData.engagementId, DataModificationModuleNames.Questionnaire);
    forkJoin([editEngPromise, editQuePromise]).subscribe(
      responses => {
        this.ReviewInProgressEditEngagement = responses[0].editStatus;
        this.ReviewInProgressHDSession = responses[1].editStatus;
        if (this.ReviewInProgressEditEngagement && this.ReviewInProgressHDSession) { // if editing on both interview or edit engagement page  
          this.dataModificationDetails = responses[0];
          this.dataModificationDetails.editingMembers = responses[0].editingMembers.concat(responses[1].editingMembers)
          this.dataModificationDetails.editingMembers = [...new Set(this.dataModificationDetails.editingMembers)];
          this.setEditingTeamMembers();
        } else {
          if (this.ReviewInProgressEditEngagement) {
            this.dataModificationDetails = responses[0];
            this.setEditingTeamMembers();
          } else if (this.ReviewInProgressHDSession) {
            this.dataModificationDetails = responses[1];
            this.setEditingTeamMembers();
          }
        }
        if (!this.ReviewInProgressHDSession && !this.ReviewInProgressEditEngagement) {
          this.workspaceActionButtonClick(action, isDeclineModal, isRPApprovedAction);
        } else {
          this.loadingScreenService.stopLoading();
        }
      }, error => {
        this.loadingScreenService.stopLoading();
        console.error('IsInProgressModification error ', error);
      });
  }

  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userInfo);
    this.countOfQualityGroupRole = 0;
    if (this.userInfo != null && this.userInfo.role != null && this.userInfo.role.length > 0) {
      for (let i = 0; i < this.userInfo.role.length; i++) {
        if (this.userInfo.role[i].shortRoleName === RoleNames.SystemAdministrator) {
          this.isLoggedInUserSystemAdmin = true;
        }
        if (this.userInfo.role[i].shortRoleName == RoleNames.QualityGroup) {
          this.countOfQualityGroupRole = this.countOfQualityGroupRole + 1;
          this.isQualityUserLoggedIn = true;
          this.isQualityMemeberLoggedIn = true;
        }
        if (this.userInfo.role[i].shortRoleName == RoleNames.INDUSStaffingManagers) {
          this.isINDUSStaffingManagerLoggedIn = true;
        }
      } //End of For  
    }// End of If
  }

  ngOnDestroy() {
    this.ResetIwantToUnsavedFlag();
    $('.lastModifiedBy').popover('hide');
    this._alertService.remove(this.alert);
    this.modalConfirmationDialogRef?.unsubscribe();
    this._alertService.alerts = [];
    this.reviewNoteComponentRef = null;
    this.subscription?.unsubscribe();
  }

  ngAfterViewChecked() {
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.applyFilterOnceAfterDataLoad = false;
      if (this.currentEngagement.appliedTnC) {
        $('#reviewReponseNavItem').tab('show');
      } else {
        $('#reviewEngagementAssignment').tab('show');
      }
    }
  }

  BindContactPopup() {
    $('.lastModifiedBy').popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',
      boundary: 'viewport',
      content: function () {
        console.log('BindContactPopup func called ', $('#contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  checkForContactContent(contact) {
    var timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 3000);
  }



  DisplayContactPopup() {
    this.selectContactEmailId = this.workspaceModel.lastActivityByEmail;
    this.BindContactPopup();
    this.checkForContactContent('contact-popup');
    $('.lastModifiedBy').popover('show');
  }

  initinateConfirmationPopUp() {
    this.modalConfirmationDialogRef = this.data.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dailogTitle = dialogModal.dialogTitle;
          const buttonName = dialogModal.dialogButtonName;
          switch (dailogTitle) {
            case WorkspaceInterviewsStatus.EditEngagement:
              this.navigateToEditEngagementOnConfirmation(dialogModal);
              break;
            case WorkspaceInterviewsStatus.EditQuestionnaire:
              this.startProcessforEditQuestionnaire(buttonName);
              break;
            case WorkspaceInterviewsStatus.SendToEPPMDTitle:
              this.startProcessforSendToEPPMD(dialogModal);
              break;
            case WorkspaceInterviewsStatus.AbandonedTitle:
              this.startProcessforAbandon(dialogModal);
              break;
            case WorkspaceInterviewsStatus.RPDeclinedTitle:
              this.startProcessforDeclined(WorkspaceInterviewsStatus.RPDeclinedTitle, dialogModal);
              break;
            case WorkspaceInterviewsStatus.EPDeclinedTitle:
              this.startProcessforDeclined(WorkspaceInterviewsStatus.EPDeclinedTitle, dialogModal);
              break;
            case WorkspaceInterviewsStatus.EPApprovedTitle:
              this.startProcessforApprove(WorkspaceInterviewsStatus.EPApprovedTitle, dialogModal);
              break;
            case WorkspaceInterviewsStatus.RPApprovedTitle:
              this.startProcessforApprove(WorkspaceInterviewsStatus.RPApprovedTitle, dialogModal);
              break;
            case WorkspaceInterviewsStatus.ReviewStatus:
              this.closeDialogAndReload();
              break;
            case 'Remove RP':
              this.removeReviewer(this.currentWorkespaceMemberId);
              break;
            case 'Remove team member':
              this.removeTeamMember(this.currentWorkespaceMemberId);
              break;
            case 'Remove client team member':
              this.removeclientTeamMember(this.selectedClientTeamMemberId);
              break;
            case 'Remove document':
              this.RemoveSupplDoc();
              break;
            case QuestionnaireDialogTitles.QueNewVersion:
              if (this.isWorkspaceScreen) {
                this.UpdateTemplateVersionData();
                break;
              }
            case QuestionnaireDialogTitles.UnAnsQuestions:
              if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.EPReviewPending &&
                buttonName === 'ok') {
                setTimeout(() => {
                  this.loadingScreenService.startLoading();
                  if (this.showAuditPopup && !this.currentEngagement.appliedTnC) {
                    this.loadingScreenService.stopLoading();
                    this.showAuditPartnerPopupDialog("EP");
                  } else
                    this.setSendToEPPMDDialog();
                }, 400);
              } else if (buttonName === 'cancel' || buttonName === 'close') {
                this.isApprove = false;
                console.log('this.isApprove status set to false');
              }
              break;
            case WorkspaceInterviewsStatus.OpenReviewNotes:
              if (!dialogModal.dialogReviewNoteIsFinalApproval &&
                buttonName === 'ok') {
                this.processApproval(dialogModal.dialogReviewNoteIsEP);
              } else if (buttonName === 'cancel' || buttonName === 'close' ||
                (dialogModal.dialogReviewNoteIsFinalApproval && buttonName === 'ok')) {
                this.isApprove = false;
                console.log('this.isApprove status set to false');
              }
              break;
            case 'Delete document?':
              this.removeSecondaryWorkspace();
              break;
            case IndusDialogTitles.RecordINDUSconsent:
              this.UpdateIndusFlagForReviewPage(true);
              break;
            case IndusDialogTitles.RemoveINDUSconsent:
              this.UpdateIndusFlagForReviewPage(false);
              break;
            default:
              console.log('Default No Action');
              break;
          }
        }
      });
  }

  closeDialogAndReload() {
    window.location.reload();
  }

  navigateToEditEngagementOnConfirmation(dialogModel: any) {
    const buttonName = dialogModel.dialogButtonName;
    if (buttonName === 'cancel' || buttonName === 'close') {
      this.isApprove = false;
      console.log('this.isApprove status set to false');
    } else {
      this.router.navigate(['EditEngagement/' + this.CurrentWorkspaceData.engagementId]);
    }
  }

  startProcessforEditQuestionnaire(buttonName: string) {
    this.loadingScreenService.stopLoading();
    if (buttonName) {
      if (buttonName === 'ok') {
        console.log('Edit Questionnaire button click');
        if (this.isEditQueNewVersion) {
          this.data.setNewVersionDialogVisibility(false);
        }
        this.NavigateToInterview();
      } else if (buttonName === 'cancel' || buttonName === 'close') {
        this.isApprove = false;
        console.log('this.isApprove status set to false');
      }
    }
  }

  startProcessforSendToEPPMD(dialogModel: any) {
    const buttonName = dialogModel.dialogButtonName;
    if (buttonName === 'ok') {
      this.IsDecisionCallNeeded = true;
      this.initiateProcessforSendToEPPMD();
    } else if (buttonName === 'cancel' || buttonName === 'close') {
      this.isApprove = false;
      console.log('this.isApprove status set to false');
    }
  }

  initiateProcessforSendToEPPMD() {
    this.workspaceService.getAuthUser();
    let shortRoleName = '';
    this.loadingScreenService.startLoading();
    this.workspaceStatusUpdateViewModel.status = WorkspaceInterviewsStatus.SendToEPPMD;
    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    shortRoleName = this.findCurrentUserRolesInWorkspace();
    this.workspaceStatusUpdateViewModel.roleShortName = shortRoleName.trim();
    //Set System Admin role as short name when admin dose sent to EPPMD
    if ((!this.isUserTM && !this.isUserEP) && this.isLoggedInUserSystemAdmin) {
      this.workspaceStatusUpdateViewModel.roleShortName = 'SA';
    }

    this.workspaceService.UpdateWorkspaceStatusData(this.workspaceStatusUpdateViewModel).subscribe(
      result => {
        this.approveRejectWorkspaceResultModel = result;

        this.updateSharePointMetadata();
        this.loadingScreenService.stopLoading();
        if (this.approveRejectWorkspaceResultModel.approveRejectResult === 'Success') {
          this.shouldNotifyEPPMD = true;
          $('#btnSendToEPPMD').attr('disabled', true);
          this.UpdateDecisionsRPList(null, WorkspaceInterviewsStatus.SendToEPPMDTitle, null);
          this.GetDecisionsNotificationPayload(this.engagementId, NotificationOnlyRemoveRemarks.EPPMDReview, false);

        } else {
          this.shouldNotifyEPPMD = false;
        }
      },
      err => {
        console.log('Error in updating workspace status.', err);
        this.loadingScreenService.stopLoading();
      }
    );
  }

  startProcessforDeclined(status: string, dialogModal: any) {
    let dialogInput = '';
    let buttonName = '';
    if (dialogModal != null) {
      dialogInput = dialogModal.DeclinedReasonInput;
      buttonName = dialogModal.dialogButtonName;
    }
    if (buttonName) {
      if (buttonName === 'ok') {
        console.log(dialogModal.dialogTitle + ' button click');
        this.initinateProcessforDeclined(dialogInput);
      } else if (buttonName === 'cancel' || buttonName === 'close') {
        this.isApprove = false;
        console.log('this.isApprove status set to false');
      }
    }
  }

  initinateProcessforDeclined(dialogInput: string) {
    let resuletext = '';
    let shortRoleName = '';
    this.loadingScreenService.startLoading();
    this.workspaceStatusUpdateViewModel.status = WorkspaceInterviewsStatus.Declined;
    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    this.workspaceStatusUpdateViewModel.declinedReason = dialogInput;
    shortRoleName = this.findCurrentUserRolesInWorkspace();
    this.workspaceStatusUpdateViewModel.roleShortName = shortRoleName.trim();
    this.workspaceService.UpdateWorkspaceStatusData(this.workspaceStatusUpdateViewModel).subscribe(
      result => {
        this.approveRejectWorkspaceResultModel = result;
        this.updateSharePointMetadata();
        this.deleteAwaitingApprovalClientsForNoEngagement();
        this.isDeclinedRequest = true;
        this.generateMultipleSummaryReports(0);
        this.loadingScreenService.stopLoading();
        if (this.approveRejectWorkspaceResultModel.approveRejectResult === 'Success') {
          this.setRetentionLabel();
          this.deleteClientSpaceOnEngDeclinedAbandoned();
          this.rejectTheAvepointTasks();
        }
        if (this.isConveneProjectsExists) {
          this.SendEngagementClosureNotification();
        }
        else {
          // skip to Send Engagement ClosureNotification in convene
          this.loadingScreenService.stopLoading();
        }
      },
      err => {
        console.log('Error in updating workspace status.', err);
        this.loadingScreenService.stopLoading();
      }
    );
  }

  startProcessforApprove(status: string, dialogModal: any) {
    const buttonName = dialogModal.dialogButtonName;
    if (buttonName === 'ok') {
      if (status == WorkspaceInterviewsStatus.EPApprovedTitle) {
        this.IsDecisionCallNeeded = true;
      }
      console.log('Approve button click');
      this.initinateProcessforApprove(status);
    } else if (buttonName === 'cancel' || buttonName === 'close') {
      this.isApprove = false;
      console.log('this.isApprove status set to false');
    }
  }

  //Pass 2nd input parameter as true if we removed last RP approver
  initinateProcessforApprove(status: string, isFinalApproverRemoved: boolean = false) {
    this.workspaceService.getAuthUser();
    let approvalInterval = setInterval(() => this.loadingScreenService.startLoading(), 1);
    let resuletext = '';
    let shortRoleName = '';
    let dialogType: string = 'information';
    let isDeclineModal: boolean = false;

    this.loadingScreenService.startLoading();

    if (isFinalApproverRemoved) {
      this.workspaceStatusUpdateViewModel.status = WorkspaceInterviewsStatus.EngFinalApproverRemoved;
      status = WorkspaceInterviewsStatus.RPApprovedTitle;
    }
    else {
      this.workspaceStatusUpdateViewModel.status = WorkspaceInterviewsStatus.Approved;
    }

    this.UpdateDecisionsRPList(dialogType, status, isDeclineModal);

    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    shortRoleName = this.findCurrentUserRolesInWorkspace();
    this.workspaceStatusUpdateViewModel.roleShortName = shortRoleName.trim();
    this.adUsersService.getUserDetailsByEmailId(this.currentEngagement.engagementPartner).subscribe(user => {
      if (user) {
        this.workspaceStatusUpdateViewModel.engagementPartnerOfficeLocation = user.officeLocation;
      }

      this.workspaceService.UpdateWorkspaceStatusData(this.workspaceStatusUpdateViewModel).subscribe(
        result => {
          this.approveRejectWorkspaceResultModel = result;
          if (status === WorkspaceInterviewsStatus.EPApprovedTitle) {
            // For Advisory(Atest- No Atest), need to fetch RP Delegate from Decision
            if (this.approveRejectWorkspaceResultModel.autoRpMembersWithDelegateList.length > 0) {
              this.getRPDelegateFromDecisionAndUpdateInEngage(this.approveRejectWorkspaceResultModel.autoRpMembersWithDelegateList, this.workspaceStatusUpdateViewModel.engagementPartnerOfficeLocation);
            }
            else {
              // call RPPermission for All RPs with Delegate members ONLY for EPPMD approval case                
              // For Tax, Audit and Advisory(Not fetched RP Records)
              this.getReviewersAndUpdateRPPermission();
            }
          }
          this.updateSharePointMetadata();
          clearInterval(approvalInterval);
          this.loadingScreenService.stopLoading();
          if (this.approveRejectWorkspaceResultModel.approveRejectResult === 'Success') {
            if (status === WorkspaceInterviewsStatus.EPApprovedTitle) {
              this.isEPPMDApproveBtnDisable = true;
              this.isEPPMDApprove = true;
              this.GetINDUSTeamMembersFromAD();
              if (this.isLoggedInUserFinalApprover || isFinalApproverRemoved) {
                //this.ContinuanceEngagementCheck();
                this.isApprove = true;
                this.ProcessINDUSAllTeamMembersRemoval();
              }
              else {
                this.GetDecisionsNotificationPayload(this.engagementId, NotificationOnlyRemoveRemarks.RPReview, false);
              }
            }

            if (status === WorkspaceInterviewsStatus.RPApprovedTitle) {
              this.isRPApprovedBtnDisable = true;
              this.isRPApproved = true;
              if (this.isLoggedInUserFinalApprover || isFinalApproverRemoved) {
                //this.ContinuanceEngagementCheck();
                this.isApprove = true;
                this.ProcessINDUSAllTeamMembersRemoval();
              }
            }
          }
        },
        err => {
          console.log('Error in updating workspace status.', err);
          clearInterval(approvalInterval);
          this.loadingScreenService.stopLoading();
        }
      );
    },
      error => {
        console.log(error);
        clearInterval(approvalInterval);
        this.loadingScreenService.stopLoading();
      });
  }

  GenerateIndusConsentEvent(consent: string) {
    this.logService.info('Indus Consent ', consent);

    if (consent.length > 0) {
      let indusConsentEvent = new EngageEvent();
      indusConsentEvent.engageEventType = 'IndusConsent';
      indusConsentEvent.engagementId = this.engagementId;
      indusConsentEvent.collaborationSiteId = this.currentEngagement.collaborationSiteId;
      indusConsentEvent.engagementName = this.CurrentWorkspaceData.engagementName;
      indusConsentEvent.createdBy = this.userInfo.userName;
      indusConsentEvent.indusConsent = consent;
      this.logService.info('Event data ', indusConsentEvent);

      this._engService.GenerateEventInEventGrid(indusConsentEvent).subscribe(
        result => {
          this.logService.info('Indus Consent sent to Event Grid.', result);
        }, error => {
          this.logService.error('Review - EngagementId ' + this.engagementId + ' Error in sending Indus Consent event to Event Grid.', error);
        }
      );
    }
  }

  getRPDelegateFromDecisionAndUpdateInEngage(autoRpMembersWithDelegateList: AutoRpMembersWithDelegate[], engagementPartnerOfficeLocation: string) {
    let autoRpMembersWithDelegateModel: AutoRpMembersWithDelegateModel = new AutoRpMembersWithDelegateModel();
    autoRpMembersWithDelegateModel.workspaceId = this.workspaceId;
    autoRpMembersWithDelegateModel.loggedInUser = this.userInfo.userName;
    autoRpMembersWithDelegateModel.loggedInUserFullName = this.userInfo.loggedInUserFullName;
    autoRpMembersWithDelegateModel.autoRpMembersWithDelegateList = autoRpMembersWithDelegateList;

    let rPNotificationModel: RPNotificationModel = new RPNotificationModel();
    rPNotificationModel.workspaceId = this.workspaceId;
    rPNotificationModel.loggedInUser = this.userInfo.userName;
    rPNotificationModel.loggedInUserFullName = this.userInfo.loggedInUserFullName;
    rPNotificationModel.engagementPartnerOfficeLocation = engagementPartnerOfficeLocation;

    this.decisionRuleEngineService.GetRpMembersWithDelegate(autoRpMembersWithDelegateModel).subscribe(
      result => {
        autoRpMembersWithDelegateModel = result;
        if (autoRpMembersWithDelegateModel !== null && autoRpMembersWithDelegateModel !== undefined) {
          if (autoRpMembersWithDelegateModel.autoRpMembersWithDelegateList.length > 0) {
            this.workspaceService.ReplaceRpMembersWithDelegate(autoRpMembersWithDelegateModel).subscribe(
              result => {
                let replaceResult = result;
                if (replaceResult === 'Success') {
                  //1. call RPPermission for All RPs with Delegate members ONLY for EPPMD approval case
                  //2. Send mail notification to all available Auto RP's after RP replacing from delegate received from Decision
                  this.sendEmailNotificationAllAutoRPs(rPNotificationModel);
                  this.getReviewersAndUpdateRPPermission();
                }
              },
              err => {
                console.log('Error in updating workspace Member RP delgate.', err);
              }
            );
          }
          else
          //1. if RP delegate member not found then need to call below process for existing Auto RP members
          //2. Send mail notification to all available Auto RP's after EP has approved and once fetch RP delegate member from Decision
          {
            this.sendEmailNotificationAllAutoRPs(rPNotificationModel);
            this.getReviewersAndUpdateRPPermission();
          }
        }
      },
      error => {
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  sendEmailNotificationAllAutoRPs(rPNotificationModel: RPNotificationModel) {
    this.workspaceService.SendEmailNotificationAllAutoRPs(rPNotificationModel).subscribe(
      result => {
        console.log('sendEmailNotificationAllAutoRPs ==>', result);
      },
      err => {
        console.log(err);
        console.log('sendEmailNotificationAllAutoRPs ==> Error');
      });
  }
  setIsMemberFirm() {
    this.isMemberFirm = this.userInfo.isMemberFirmFeatureEnabled;
  }

  GetContinuanceEngagementValidationDetails() {
    this._engService.GetContinuanceEngagementValidationDetails(this.currentEngagement.engagementId).subscribe(result => {
      this.continuanceEngagementValidationModel = result;
    },
      err => {
        console.log(err);
      });
  }

  ContinuanceEngagementCheck() {
    if (this.isApprove) {
      if (this.currentEngagement.appliedTnC) {
        this._engService.SaveContinuaceEngagementApproval(this.currentEngagement.engagementId, this.userInfo.userName).subscribe(result => {
          this.UploadDocumentToSharepoint();
        },
          err => {
            console.log(err);
          });
        this.StoreReviewResponse();
      }
      else {
        if (this.continuanceEngagementValidationModel
          && this.continuanceEngagementValidationModel.length > 0
          && this.continuanceEngagementValidationModel[0].reacceptanceEngagementId > 0
          && this.continuanceEngagementValidationModel[0].preRequestForContinuance) {

          let PYAssignments = this.continuanceEngagementValidationModel.filter(item => item.isContinuanceCarryFrwdAssignment == false);
          if (PYAssignments && PYAssignments.length > 0) {
            this.PreRequisiteCheckForCMSRequest();
          }
          else {
            this._engService.SaveContinuaceEngagementApproval(this.currentEngagement.engagementId, this.userInfo.userName).subscribe(result => {
              this.UploadDocumentToSharepoint();
            },
              err => {
                console.log(err);
              });
          }
        }
        else {
          this.PreRequisiteCheckForCMSRequest();
        }
      }
    } else {
      this.workspaceService.getTeamMembers(this.workspaceId).subscribe(
        teamMemberResult => {
          this.teamsMembersList = teamMemberResult;
          this.loadingScreenService.stopLoading();
        });
    }
  }

  PreRequisiteCheckForCMSRequest() {
    this.loadingScreenService.startLoading();
    this.workspaceService.SendCMSRequestOnSignature(this.currentEngagement.engagementId).subscribe(sendCMSRequestOnSignatureCompletion => {
      if (sendCMSRequestOnSignatureCompletion) {
        this.UploadDocumentToSharepoint();
      } else {
        this.onApprove();
      }
    },
      err => {
        this.loadingScreenService.stopLoading();
      });
  }

  startProcessforAbandon(dialogModal: any) {
    if (dialogModal != null) {
      const btnName = dialogModal.dialogButtonName;
      if (btnName === 'ok') {
        this.initinateProcessforAbandon();
      } else if (btnName === 'cancel' || btnName === 'close') {
        this.isApprove = false;
        console.log('this.isApprove status set to false');
      }
    }
  }

  setRetentionLabel() {
    if (this.currentEngagement.siteUrl != '' && this.currentEngagement.siteUrl != null) {
      this._sharepointService.SetRetentionLabel(this.currentEngagement.siteUrl).subscribe(status => {
        console.log('Retention label applied to sharepoint site', status);
      },
        err => {
          console.error('Error in setRetentionLabel ', err);
        }
      );
    }
    else {
      console.log('Error in applying etention label  to sharepoint site');
    }
  }

  initinateProcessforAbandon() {
    let resuletext: string = '';
    let shortRoleName = '';
    this.loadingScreenService.startLoading();
    this.workspaceStatusUpdateViewModel.status = WorkspaceInterviewsStatus.Abandoned;
    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    shortRoleName = this.findCurrentUserRolesInWorkspace();
    this.workspaceStatusUpdateViewModel.roleShortName = shortRoleName.trim();
    //Set System Admin role as short name when admin dose sent to eppmd
    if ((!this.isUserTM && !this.isUserEP) && this.isLoggedInUserSystemAdmin) {
      this.workspaceStatusUpdateViewModel.roleShortName = 'SA';
    }
    this.workspaceService.UpdateWorkspaceStatusData(this.workspaceStatusUpdateViewModel).subscribe(
      result => {
        this.approveRejectWorkspaceResultModel = result;
        this.updateSharePointMetadata();
        this.deleteAwaitingApprovalClientsForNoEngagement();
        this.loadingScreenService.stopLoading();
        if (this.approveRejectWorkspaceResultModel.approveRejectResult === 'Success') {
          this.setRetentionLabel();
          this.deleteClientSpaceOnEngDeclinedAbandoned();
          this.MarkQnARecordDeleted(this.CurrentWorkspaceData.engagementId, '');
          this.applyAbandonSitePolicy();
          sessionStorage.removeItem('isHardRefreshPageReloadedW');
          this.router.navigate(['MyEngagements']);
        } else {
          this.shouldNotifyEPPMD = false;
        }
        if (this.isConveneProjectsExists) {
          this.SendEngagementClosureNotification();
        }
        else {
          // skip to Send Engagement ClosureNotification in convene
          this.loadingScreenService.stopLoading();
        }
      },
      err => {
        console.log('Error in updating workspace status.', err);
        this.loadingScreenService.stopLoading();
      }
    );
  }
  updateSharePointMetadata() {
    const collaborationSiteIdRequest = this.collaborationSiteService.getCollaborationSiteId(this.engagementId);
    const sharePointMetadataRequest = this._engService.getMetadataList();
    let updateSharePointMetadataLoaderToken = setInterval(() => this.loadingScreenService.startLoading(), 1);
    forkJoin([collaborationSiteIdRequest, sharePointMetadataRequest]).subscribe(responseList => {
      const metadata: SPSiteMetadata[] = responseList[1];
      const collaborationSiteId = responseList[0];
      const sharepointMetadata: SPSiteMetadata[] = [];
      this._engService.getSharePointMetadataMapping(this.engagementId, collaborationSiteId)
        .subscribe((sharePointData: SharePointMetadataMapping) => {
          sharePointData.propertiesMapping.forEach(item => {
            metadata.forEach(element => {
              if (item.value === element.name) {
                element.value = sharePointData.metadata[item.key];
                sharepointMetadata.push(element);
              }
            });
          });
          const sharePointWrapper = new SharePointWrapper();
          sharePointWrapper.siteURL = sharePointData.metadata.siteUrl;
          sharePointWrapper.metadataList = sharepointMetadata;
          this._engService.updateSiteMetadata(sharePointWrapper).subscribe(status => {
            clearInterval(updateSharePointMetadataLoaderToken);
            this.loadingScreenService.stopLoading();
            console.log('Metadata saved in workspace component', status);
          },
            error => {
              clearInterval(updateSharePointMetadataLoaderToken);
              this.loadingScreenService.stopLoading();
            });
        },
          error => {
            clearInterval(updateSharePointMetadataLoaderToken);
            this.loadingScreenService.stopLoading();
          });
    },
      error => {
        clearInterval(updateSharePointMetadataLoaderToken);
        this.loadingScreenService.stopLoading();
      });
  }

  ngAfterViewInit() {
    $('body').on('click', function (e) {
      console.log('body click event fired', $(e.target).is('.contact-close'));
      const target = e.target;
      const parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          console.log('condition met');
          $('.lastModifiedBy').popover('hide');
        }
      }
    });
    this.ConfigureReviewNoteSearch();
  }

  getReviewers() {
    this.reviewersListForRP = [];
    return this.workspaceService.getReviewers(this.workspaceId).subscribe(
      result => {
        this.reviewerList = result;
        this.BuildRPsDisplayModel();
        this.ConfigureReviewNoteSearch();
        console.log(this.reviewerList.length);
        console.log(this.reviewerList);

        if (this.reviewerList.length > 0) {
          for (var i in this.reviewerList) {

            if (this.reviewerList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
              this.idLoggedInUserHasWorkspaceAccess = true;
              console.log('have Access', this.idLoggedInUserHasWorkspaceAccess);
              break;
            }

          }
          for (var j = 0, length = this.reviewerList.length; j < length; j++) {

            this.newReviewer = {
              id: this.reviewerList[j].id,
              workspaceId: this.reviewerList[j].workspaceId,
              memberName: this.reviewerList[j].memberName,
              teamMemberRole: this.reviewerList[j].teamMemberRole,
              isReviewer: this.reviewerList[j].isReviewer,
              roleId: this.reviewerList[j].roleId,
              createdBy: this.reviewerList[j].createdBy,
              createdOn: this.reviewerList[j].createdOn,
              updatedBy: this.reviewerList[j].updatedBy,
              updatedOn: this.reviewerList[j].updatedOn,
              memberEmailId: this.reviewerList[j].memberEmailId,
              memeberContactNo: this.reviewerList[j].memeberContactNo,
              approvalStatus: this.reviewerList[j].approvalStatus,
              oldreviewerName: this.reviewerList[j].oldreviewerName,
              loggedInUserFullName: this.reviewerList[j].loggedInUserFullName,
              isLoggedInUserhasTransferButtonVisible: (this.reviewerList[j].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase() || this.isLoggedInUserSystemAdmin),
              jobTitle: this.reviewerList[j].jobTitle
            }
            this.reviewersListForRP.push(this.newReviewer);
          }

        }
      },
      () => {
        this.reviewerList = null;
      }
    );
  }

  getMembers() {
    return this.workspaceService.getMembers(this.workspaceId).subscribe(
      result => {
        this.memberList = result;
        this.ConfigureReviewNoteSearch();
        console.log(this.memberList.length);
        console.log(this.memberList);
        if (this.memberList.length > 0) {
          for (var i in this.memberList) {
            if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
              this.idLoggedInUserHasWorkspaceAccess = true;
              this.IsLoggedInUserIsTM_EP = true;
              console.log('have Access', this.idLoggedInUserHasWorkspaceAccess);
              break;
            }
          }
        }
      },
      () => {
        this.memberList = null;
        this.ConfigureReviewNoteSearch();
      }
    );
  }

  GetWorkspaceDiscussions(workspaceId: string) {
    return this.workspaceService.GetWorkspaceDiscussions(workspaceId).subscribe(
      result => {
        this.workspaceDiscussionList = result;
        this.loadingScreenService.stopLoading();
        console.log(result);
      },
      () => {
        this.workspaceDiscussionList = null;
      }
    );
  }
  selectTeamMember(index) {
    $('#tblTeamMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedRPIndex = index;
  }
  refreshTeamMemberPopup() {
    this.CloseReviewNotesPanel();
    this.removeRowSelection('tblTeamMemberAD', 'searchTeamMemberName', 'TeamMemberErrorMessage', '');
    $('#clientErrorMessage').text('');
    $('#KeyTeamMemberRoleErrorMessage').text('');
    this.KeyTeamMemberRoleId = 0;
  }
  searchUsers(searchTextBox, tableId: string) {
    console.log('searchUsers func called', searchTextBox, tableId);
    const searchText = $('#' + searchTextBox).val();
    if (tableId === "tblMemberAD") {
      this.GetTeamMembers(searchText);
    }
  }
  searchTeamMembers(searchTextBox, tableId: string) {
    $("#TeamMemberErrorMessage").text('');
    console.log('searchUsers func called', searchTextBox, tableId);
    const searchText = $('#' + searchTextBox).val();
    if (tableId === "tblTeamMemberAD") {
      this.GetTeamMembersList(searchText);
    }
  }
  GetTeamMembers(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForTeamMembers = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForTeamMembers = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }
  GetTeamMembersList(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    if (this.memberFirmData?.memberFirmMappingId != undefined && this.isLoggedInUserSystemAdmin) {
      searchModel.isMemberFirm = true;
      searchModel.memberFirmDomain = [];
      searchModel.memberFirmDomain.push(this.memberFirmData?.memberDomain)
    }
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.teamMembersListArray = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.teamMembersListArray = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }
  setTeamMember() {
    let index = this.selectedTMIndex;
    if (Number(index) >= 0) {
      const selectedMemberName = $('#spMemberdisplayName' + index).text();
      const selectedMemberEmail = $('#spMembermail' + index).text();
      const selectedMemberPhone = $('#spMemberbusinessPhones' + index).text();
      console.log('setTeamMember func called', selectedMemberName, selectedMemberEmail, selectedMemberPhone, this.teamMemberEmailCtrl);
      $("#TeamMemberErrorMessage").text('');
      var countofAddedTM = 0;
      for (var i in this.teamMembersList) {
        if (this.teamMembersList[i].memberEmailId == selectedMemberEmail) {
          countofAddedTM = countofAddedTM + 1;
        }
      }
      if (countofAddedTM == 0) {
        $("#TeamMemberErrorMessage").text('');
        $('#' + this.teamMemberCtrl).val(selectedMemberName);
        $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val(selectedMemberEmail);
        this.teamMembersList[this.currentTeamMemberIndex].memberName = selectedMemberName;
        this.teamMembersList[this.currentTeamMemberIndex].memberEmailId = selectedMemberEmail;
        this.teamMembersList[this.currentTeamMemberIndex].memeberContactNo = selectedMemberPhone;
        this.validateTM.forEach((value, memberIndex, array) => array[this.currentTeamMemberIndex] = true);
        console.log('Validate Team Members ', this.validateTM);
      }
      else {
        $("#TeamMemberErrorMessage").text('Selected Team Member is already added');
      }
    }
    this.membersListArrayForTeamMembers = null;
  }
  refreshModalDiscussion() {
    console.log('calling refresh function to get discussion data');
    this.resetWorkspaceDiscussionFields();
    this.loadingScreenService.startLoading();
    this.GetWorkspaceDiscussions(this.workspaceId);
  }
  refreshModalsuppUploadDoc() {
    this.GetSupplementalDocCategories();
  }
  refreshModalReviewers(type: string, idRole: number, id: number) {
    this.CloseReviewNotesPanel();
    this.removeRowSelection('tblReviewerAD', 'searchReviewerName', 'ReviewerErrorMessage', 'ReplaceIndicatorMessage');
    switch (type) {
      case 'replace':
        this.isReplaceRP = true;
        this.isAddRP = false;
        break;
      case 'add':
        this.isReplaceRP = false;
        this.isAddRP = true;
        break;
    }
    this.indexofReplaceReviewerMemberMail = id;
    this.indexofReplaceReviewerRoleName = idRole;
    this.isReplaceRpOkButtonEnabled = true;

    /*
    if (this.reviewersListArray == null || this.reviewersListArray == undefined) {
      this.GetReviewingProfessionalsFromAD();
    }
    */
  }
  refreshModalTeamMembers() {
    if (this.teamMemberRoleList == null || this.teamMemberRoleList == undefined || this.teamMemberRoleList.length == 0) {
      this.GetTeamMemberRoles();
    }
    this.removeRowSelection('tblMemberAD', 'searchMemberName', 'TMErrorMessage', '');
    this.teamMember.roleId = null;

    if (this.membersListArray == null || this.membersListArray == undefined) {
      this.GetTeamMembersFromAD();
    }
  }
  expandGivenReply(collapsibleId: string) {
    $('#reply' + collapsibleId).collapse('show');
  }
  AddWorkspaceDiscussion(workspaceDiscussion: WorkspaceDiscussion) {
    this.loadingScreenService.startLoading();
    if (workspaceDiscussion.discussion === '' || workspaceDiscussion.discussion === undefined) {
      this.loadingScreenService.stopLoading();
      return;
    }

    workspaceDiscussion.workspaceId = this.workspaceId;
    this.workspaceService
      .AddWorkspaceDiscussion(workspaceDiscussion)
      .subscribe(result => {
        console.log(result);
        setTimeout(() => {
          this.GetWorkspaceDiscussions(this.workspaceId);
          this.resetWorkspaceDiscussionFields();
        }, 50);
      });
  }
  AddWorkspaceComment(discussionId: string, workspaceComment: WorkspaceComments, comment: string) {
    workspaceComment.discussionId = discussionId;
    this.loadingScreenService.startLoading();
    this.workspaceService
      .AddWorkspaceComment(workspaceComment, comment)
      .subscribe(result => {
        console.log('is comment added' + result);
        setTimeout(() => {
          this.GetWorkspaceDiscussions(this.workspaceId);
          this.resetWorkspaceDiscussionFields();
        }, 50);
      });

  }

  getWorkspaceReviewPoints() {
    var _workspaceId = this.workspaceId
    return this.workspaceReviewPointService
      .getReviewPointsWithComments(this.workspaceId, false)
      .subscribe(
        result => {
          var list = result.reviewPointList.filter(x => x.status == 1);
          this.workspaceReviewPointService.setReviewNotesCount(list.length);
          this.getWorkspaceReviewPointsCount();
        },
        (error) => {
          this.workspaceReviewPoints = null;
        }
      );
  }

  GetReviewPointDetails(reviewPointId: number) {
    $("#modal-ViewReviewPoints").modal("show");
    this.currentworkspaceReviewPointComment.comments = null;
    return this.workspaceReviewPointService
      .getWorkspaceReviewPointById(reviewPointId)
      .subscribe(
        result => {
          this.currentworkspaceReviewPoints = result;
          setTimeout(() => {
            this.checkCurrentLoggedinUserisCreatedBy(this.currentworkspaceReviewPoints.createdBy);
            this.GetReviewPointComments(reviewPointId);
          }, 50);
        },
        () => {
          this.currentworkspaceReviewPoints = null;
        }
      );
  }

  GetReviewPointComments(reviewPointId: number) {
    this.reviewPointId = reviewPointId;
    return this.workspaceReviewPointService
      .getWorkspaceReviewPointComments(reviewPointId)
      .subscribe(
        result => {
          this.workspaceReviewPointComments = result;

          if (this.workspaceReviewPointModelList.length > 0) {

            for (var i = 0; i < this.workspaceReviewPointModelList.length; i++) {

              if (this.workspaceReviewPointModelList[i].workspaceReviewPoint.reviewPointId === reviewPointId) {

                this.workspaceReviewPointModelList[i].workspaceReviewPointComments.splice(0, this.workspaceReviewPointModelList[i].workspaceReviewPointComments.length)
                this.workspaceReviewPointModelList[i].workspaceReviewPointComments = this.workspaceReviewPointComments;
                this.workspaceReviewPointModelList[i].workspaceReviewPointCommentCount = this.workspaceReviewPointComments.length;
                this.workspaceReviewPointModelList[i].workspaceReviewPoint.membersMentionedInReviewNote = this.workspaceReviewPointComments[0].membersMentionedInReviewNote;
                console.log('new added Comments', this.workspaceReviewPointModelList[i].workspaceReviewPointComments);
                break;
              }
            }
          }

        },
        () => {
          this.workspaceReviewPointComments = null;
        }
      );
  }

  resetWorkspaceDiscussionFields() {
    this.modalWorkspaceDiscussion.discussion = '';
    this.modalWorkspaceComment.description = '';
    this.modalWorkspaceComment.comments = [];
  }

  flilWorkspaceDocumentList(workspaceModel: WorkspaceModel, hdWorkspaceDocumentModel: HdWorkspaceDocumentModel) {
    //this.workspaceDocumentList = [];
    let workspaceDocumentViewModel = new WorkspaceDocumentViewModel();
    workspaceDocumentViewModel.workspaceDocumentId = workspaceModel.workspaceDocumentId;
    workspaceDocumentViewModel.engagementId = workspaceModel.engagementId;
    workspaceDocumentViewModel.workspaceId = workspaceModel.workspaceId;
    workspaceDocumentViewModel.workItemId = workspaceModel.hdWorkItemId;
    workspaceDocumentViewModel.documentId = hdWorkspaceDocumentModel.documentId;
    workspaceDocumentViewModel.documentName = hdWorkspaceDocumentModel.name;
    workspaceDocumentViewModel.mimeType = hdWorkspaceDocumentModel.mimeType;
    workspaceDocumentViewModel.workItemVersionId = workspaceModel.hdWorkItemVersionId;
    workspaceDocumentViewModel.templatePackageVersionId = workspaceModel.hdTemplatePackageVersionId
    workspaceDocumentViewModel.documentAliasName = workspaceModel.documentAliasName;
    workspaceDocumentViewModel.lastActivityBy = workspaceModel.lastActivityBy;
    workspaceDocumentViewModel.lastActivityDate = workspaceModel.lastActivityDate;
    workspaceDocumentViewModel.orginalHDWorkItemVersionId = workspaceModel.orginalHDWorkItemVersionId;
    workspaceDocumentViewModel.orginalHDWorkItemId = workspaceModel.orginalHDWorkItemId;
    workspaceDocumentViewModel.isPrimaryWorkspaceId = workspaceModel.isPrimaryWorkspaceId;
    this.workspaceDocumentList.push(workspaceDocumentViewModel);
    console.log("workspaceDocumentList==>", this.workspaceDocumentList);
  }

  WorkspaceDocumentChange() {
    const selectedWorkspaceDocumentText = $('#ddWorkspaceDocument option:selected').text().trim();
    if (selectedWorkspaceDocumentText == undefined || selectedWorkspaceDocumentText == '') {
      if (this.changeOrderFeatureFlag) {
        if (this.engagementState === 'ChangeOrder') {
          this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.isPrimaryWorkspaceId === false);
        } else {
          this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.isPrimaryWorkspaceId === true);
        }
      }
      else {
        this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.isPrimaryWorkspaceId === true);
      }
    }
    else {
      this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.documentAliasName === selectedWorkspaceDocumentText);
      this.PreviewDocument(this.selectedWorkspaceDocumentItem.workspaceId, this.selectedWorkspaceDocumentItem.documentId, this.selectedWorkspaceDocumentItem.workItemId);
      this.QuestionSummaryDisplay();
      console.log('CurrentWorkspaceData', this.CurrentWorkspaceData);
    }

    this.currentlyProcessingEngageSummaryReportName = this.selectedWorkspaceDocumentItem.documentAliasName;
  }
  editQuestionnaireClick() {
    if (this.selectedWorkspaceDocumentItem.isPrimaryWorkspaceId) {
      this.workspaceActionButtonClick(this.EditQuestionnaireTitle, false);
    } else {
      //this.route.navigate(['Interviews/' + this.selectedWorkspaceDocumentItem.workspaceId]);
      setTimeout(() => {
        let bigTitle = true;
        let dialogInput = '';
        if (this.hasAnyWorkpaceApproval) {
          let dialogDesc = 'Editing the questionnaire will restart the approval process. All prior approvals will be removed and a new set of approvals will be required once the questionnaire is resubmitted. Do you want to continue?';
          this.displayConfirmationPopUp('warning', this.EditQuestionnaireTitle, dialogDesc, 2, dialogInput, false,
            false, bigTitle, '0', this.userInfo.loggedInUserEmail);
        } else {
          this.startProcessforEditQuestionnaire('ok');
        }
      }, 100);
    }
  }

  getDocumentListtoPreview() {
    console.log('getDocumentListtoPreview func called', this.CurrentWorkspaceData);
    this.hdService.getHDDocumentListtoPreview(this.CurrentWorkspaceData.hdWorkItemId).subscribe(
      result => {
        for (const iterator of result) {
          iterator.name = iterator.name.substring(0, 25);
        }
        this.DocumentPreviewList = result;
        this.workspaceDocumentList = [];
        this.flilWorkspaceDocumentList(this.CurrentWorkspaceData, result[0]);
        this.DocumentPreviewList[0].workspaceId = this.CurrentWorkspaceData.workspaceId;
        if (this.workspaceDocumentCount > 1) {
          this.getSecondaryWorkspaceData(this.CurrentWorkspaceData.engagementId);
        }
        this.getDocumentPreviewContent(this.DocumentPreviewList[0].documentId, this.DocumentPreviewList[0].workItemId);
        this.CurrentDocumentId = this.DocumentPreviewList[0].documentId;
        this.DocumentPreviewListCheck = true;
        this.applyFilterOnceAfterDataLoad = true;
        this.QuestionSummaryDisplayOnLoad();

        this.RestoreUIStateAfterPageLoaded();

        //check template version on load at Team review stage
        if (this.CurrentWorkspaceData.status === WorkspaceInterviewsStatus.EPReviewPending) {
          this.CheckTemplateVersion('onLoad', false);
        } else {
          this.ValidateHdTemplateCache();
        }
        this.isLoggedInUserIsPureNotificationOnlyProfesional();
        setTimeout(() => {
          this.WorkspaceDocumentChange();
        }, 1000);
        this.loadingScreenService.stopLoading();
      },
      error => {
        this.loadingScreenService.stopLoading();
      });
  }

  PreviewDocument(workspaceId, documentid, workItemId) {
    this.getDocumentPreviewContent(documentid, workItemId);
    this.CurrentDocumentId = documentid;
  }

  getDocumentPreviewContent(documentid: string, workItemId: string) {
    for (var i = 0; i < this.workspaceDocumentList.length; i++) {
      if (this.workspaceDocumentList[i].documentId == documentid) {
        var doctype = this.workspaceDocumentList[i].mimeType.toLowerCase();
        if (doctype == "word") {
          this.hdService.getDocumentContentName(documentid, workItemId)
            .subscribe((data: any) => {
              $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
              $('.ui-widget-overlay.ui-front').remove();
              this.loadingScreenService.stopLoading();
              var today = new Date();
              var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
              var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();
              var dateTime = date + ' ' + time;
              console.log("To check datetime on workspace while binding iframe src: ", dateTime);
              const url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + this.IntegrationAPIEndPoint + '/tempfiles/' + data + workItemId + '.docx?Version =' + dateTime;
              $("#DocumentPreview").attr('src', url);
            });
          break;
        }
        else if (doctype == "pdf") {
          this.hdService.getDocumentContent(documentid, workItemId)
            .subscribe((data: any) => {
              $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
              $('.ui-widget-overlay.ui-front').remove();
              this.loadingScreenService.stopLoading();
              let TYPED_ARRAY = new Uint8Array(data);
              //const STRING_CHAR= String.fromCharCode.apply(null, TYPED_ARRAY); works for small size of file.
              var STRING_CHAR = TYPED_ARRAY.reduce((data1, byte) => {
                return data1 + String.fromCharCode(byte);
              }, '');
              const url = "data:application/pdf;base64," + STRING_CHAR;
              $("#DocumentPreview").attr('src', url);
            });
          break;
        }
      }
    }

  }
  resetWorkspaceSupplementalDocFields() {
  }
  closeSuppUploadDocModal() {
    $('#modal-suppUploadDoc').modal('hide');
  }

  GetSupplementalDocCategories() {
    return this.workspaceService.GetSupplementalDocCategories().subscribe(
      result => {
        this.modalSupplementalDocCategories = result;
      },
      () => {
        this.modalSupplementalDocCategories = null;
      }
    );
  }

  upload(files) {
    if (files.length === 0) {
      return;
    }
    const formData = new FormData();
    const selectedCategory = $('#SuppCategoryType option:selected')[0].value;
    let fileName = '';
    for (let file of files) {
      formData.append(file.name, file);
      fileName = file.name;
    }

    this.workspaceService.postFile(fileName, selectedCategory, this.workspaceId, this.workspaceSupplementalDoc).subscribe(
      r => {
        console.log(r);
      },
      err => {
        console.log(err);
      }
    );
    this.closeSuppUploadDocModal();
  }


  // get workspace details for given client and engagement=

  getWorkspaceDetails(workspaceId: string) {
    this.workspaceService.getWorkspaceDetails(workspaceId).subscribe(
      result => {
        this.workspaceModel = result;
        this.solutionCodes = this.workspaceModel.solutionCode.split(',');
        if (this.workspaceModel != null) {

          this._clientService.getClientConsentFeatureFlag().subscribe(result => {
            this.isClientConsentFeatureEnabled = result;
            if (this.isClientConsentFeatureEnabled) {
              if (this.workspaceModel != null || this.workspaceModel != undefined) {
                if (this.workspaceModel.clientCode?.length > 0 && this.workspaceModel.oneViewAccountId?.length > 0) {
                  this.getClientConsent(this.workspaceModel.clientCode, this.workspaceModel.oneViewAccountId);
                }
              }
            }
          });
          if (this.workspaceModel.status === WorkspaceInterviewsStatus.EPReviewPending) {
            this.TeamReviewStatus = this.workspaceModel.status;
            this.EPPMDReviewStatus = '';
            this.RPInReviewStatus = '';
          } else if (this.workspaceModel.status === WorkspaceInterviewsStatus.EPPMDReview) {
            this.EPPMDReviewStatus = this.workspaceModel.status;
            this.TeamReviewStatus = '';
            this.RPInReviewStatus = '';
          } else if (this.workspaceModel.status === WorkspaceInterviewsStatus.RPInReview) {
            this.RPInReviewStatus = this.workspaceModel.status;
            this.TeamReviewStatus = '';
            this.EPPMDReviewStatus = '';
          }
          this.workspaceHasAnyApproval();
        }
        console.log('getWorkspaceDetails func called ', this.workspaceModel, result);
        // retrieve reviewers and members]

        let IsEP: boolean = false;
        let IsRP: boolean = false;

        this.workspaceService.getMembers(this.workspaceId).subscribe(
          memberResult => {
            this.memberList = memberResult;
            console.log(this.memberList.length);
            console.log(this.memberList);
            if (this.memberList.length > 0) {
              for (var i in this.memberList) {
                if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
                  this.idLoggedInUserHasWorkspaceAccess = true;
                  this.IsLoggedInUserIsTM_EP = true;
                  console.log('have Access', this.idLoggedInUserHasWorkspaceAccess);
                  if (this.memberList[i].teamMemberRole == EnumRoles.EngagementPartner) {
                    IsEP = true;
                  }
                  break;
                }
              }
            }

            // calling get reviewers to avoid multiple parallel async calls.
            this.reviewersListForRP = [];
            this.workspaceService.getReviewers(this.workspaceId).subscribe(
              reviewerResult => {
                this.reviewerList = reviewerResult;
                this.BuildRPsDisplayModel();
                this.ConfigureReviewNoteSearch();
                if (this.reviewerList.length > 0) {
                  for (var i in this.reviewerList) {
                    if (this.reviewerList[i].memberEmailId?.toLowerCase() == this.userInfo.userName.toLowerCase()) {
                      this.idLoggedInUserHasWorkspaceAccess = true;

                      if (this.reviewerList[i].memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional) {
                        IsRP = true;
                        break;
                      }
                    }
                  }

                  if (this.idLoggedInUserHasWorkspaceAccess) {
                    console.log('have Access', this.idLoggedInUserHasWorkspaceAccess);
                  }
                  this.isEPPMDAsRP = (IsEP && IsRP);

                  for (var j = 0, length = this.reviewerList.length; j < length; j++) {
                    this.newReviewer = {
                      id: this.reviewerList[j].id,
                      workspaceId: this.reviewerList[j].workspaceId,
                      memberName: this.reviewerList[j].memberName,
                      teamMemberRole: this.reviewerList[j].teamMemberRole,
                      isReviewer: this.reviewerList[j].isReviewer,
                      roleId: this.reviewerList[j].roleId,
                      createdBy: this.reviewerList[j].createdBy,
                      createdOn: this.reviewerList[j].createdOn,
                      updatedBy: this.reviewerList[j].updatedBy,
                      updatedOn: this.reviewerList[j].updatedOn,
                      memberEmailId: this.reviewerList[j].memberEmailId,
                      memeberContactNo: this.reviewerList[j].memeberContactNo,
                      approvalStatus: this.reviewerList[j].approvalStatus,
                      oldreviewerName: this.reviewerList[j].oldreviewerName,
                      loggedInUserFullName: this.reviewerList[j].loggedInUserFullName,
                      isLoggedInUserhasTransferButtonVisible: (this.reviewerList[j].memberEmailId?.toLowerCase() == this.userInfo.userName.toLowerCase() || this.isLoggedInUserSystemAdmin),
                      jobTitle: this.reviewerList[j].jobTitle
                    }
                    this.reviewersListForRP.push(this.newReviewer);
                  }
                }
                this.VerifyLoggedInUserWorkpaceRole();

                setTimeout(() => {
                  this.CheckUserIsPrimaryWorkspaceMember();
                }, 200);

                this.displayLabelForDisabledButton();

              },
              () => {
                this.reviewerList = null;
              }
            );
            // calling get Team Members to avoid multiple parallel async calls.
            this.reviewersListForRP = [];
            this.workspaceService.getTeamMembers(this.workspaceId).subscribe(
              teamMemberResult => {
                this.teamsMembersList = teamMemberResult;
                setTimeout(() => {
                  if (this.teamsMembersList.clientTeamMemberList != null) {
                    $("#clientTeamMemberDiv").show();
                  }
                  else {
                    $("#clientTeamMemberDiv").hide();
                  }
                }, 1000);
              },
              () => {
                this.teamsMembersList = null;
              }
            );
          },
          () => {
            this.memberList = null;
            this.ConfigureReviewNoteSearch();
          }
        );


        // end retrieval of reviewers and members


        setTimeout(() => {
          if (this.workspaceModel.status == 'Approved' || this.workspaceModel.status == 'Declined' || this.workspaceModel.status == 'Abandoned') {
            this.isWorkspaceApprovedOrRejected = true;
          }
          else {
            this.isWorkspaceApprovedOrRejected = false;
          }
          this.IsApproveRejectButtonVisibleForMe();
          if (this.countOfQualityGroupRole == 0) {
            this.isQualityUserLoggedIn = false;
          }
        }, 1000);
      },
      () => {
        this.workspaceModel = null;
        this.loadingScreenService.stopLoading();
      });

  }


  alertServiceFun(message, type) {
    this._alertService.remove(this.alert);
    this._alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  GetReviewingProfessionalsFromAD(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.reviewersListArray = userList;
        },
        (error) => {
          this.reviewersListArray = null;
          this.loadingScreenService.stopLoading();
          console.log('Error in getting response from GetReviewingProfessionals func ', error);
        });
    /*
    this.adUsersService
      .GetReviewingProfessionals()
      .subscribe(
        reviewerList => {
          this.reviewerADUsersList = reviewerList;
          this.adUsersService.getAzureAdUsersByGroups(this.reviewerADUsersList, RoleNames.ReviewingProfessional)
            .subscribe(
              userList => {
                this.reviewersListArray = userList;
                this.loadingScreenService.stopLoading();
              });

        },
        error => {
          this.reviewerADUsersList = null;
          console.log('Error in getting response from GetReviewingProfessionals func ', error);
        }
      );
      */
  }
  GetTeamMembersFromAD() {
    this.loadingScreenService.startLoading();
    this.adUsersService
      .GetTeamMembersList()
      .subscribe(
        teamMemberList => {
          this.memberADUsersList = teamMemberList;
          this.adUsersService.getAzureAdUsersByGroups(this.memberADUsersList, RoleNames.StaffMember)
            .subscribe(
              userList => {
                this.membersListArray = userList;
                this.loadingScreenService.stopLoading();
              });

        },
        error => {
          this.memberADUsersList = null;
          console.log('Error in getting response from GetTeamMembers func ', error);
        }
      );
  }

  searchReviewerNames(searchControl: string) {
    $("#ReviewerErrorMessage").text('');
    const searchText = $('#' + searchControl).val();
    this.GetReviewingProfessionalsFromAD(searchText);
  }

  searchMemberNames(event) {
    $('#' + event.currentTarget.id).keyup(function () {
      const searchText = this.value.toLowerCase().trim();

      $('#tblMemberAD tr').each(function (index) {
        if (!index) return;
        $(this).find('td').each(function () {
          var id = $(this).text().toLowerCase().trim();
          var not_found = (id.indexOf(searchText) === -1);
          $(this).closest('tr').toggle(!not_found);
          return not_found;
        });
      });
    });
  }

  ResetReviewer() {
    this.reviewersListArray = null;
    this.selectedRPIndex = null;
  }

  ResetTeamMember() {
    this.teamMembersListArray = null;
    this.selectedRPIndex = null;
    this.isReplaceRpOkButtonEnabled = true;
    if (this.isAudit) {
      this.ResetKeyTeamMember();
    }
  }

  selectReviewer(index) {

    $('#tblReviewerAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedRPIndex = index;

    const newRpName = $('#spReviewerDisplayName' + index).text();
    this.roleNameOfReplace = this.autoRPDisplayModel[this.indexofReplaceReviewerRoleName].roleDescription;
    let oldRPName = this.autoRPDisplayModel[this.indexofReplaceReviewerRoleName].teamMembers[this.indexofReplaceReviewerMemberMail].memberName;

    $("#ReviewerErrorMessage").text('');
    $("#ReplaceIndicatorMessage").text('For ' + this.roleNameOfReplace + ', reviewing professional ' + newRpName + ' selected to replace ' + oldRPName);


  }

  selectMember(index) {

    $('#tblMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    const selectedMemberName = $('#spMemberdisplayName' + index).text();
    const selectedMemberEmail = $('#spMembermail' + index).text();
    const selectedMemberPhone = $('#spMemberbusinessPhones' + index).text();
    const selectedMemberTitle = $('#spMembertitle' + index).text();

    this.teamMember.memberName = selectedMemberName;
    this.teamMember.memberEmailId = selectedMemberEmail;
    this.teamMember.memeberContactNo = selectedMemberPhone;
    this.teamMember.isReviewer = 0;
    this.teamMember.workspaceId = this.workspaceId;
    this.teamMember.jobTitle = selectedMemberTitle;
  }

  AddReviewer(reviewer: TeamMembers) {
    this.isReplaceRpOkButtonEnabled = false;
    let index = this.selectedRPIndex;
    let isGivenUserGroupMember = false;
    if (Number(index) >= 0) {
      this.addReviewerIsInProgress = true;
      const selectedMemberName = $('#spReviewerDisplayName' + index).text();
      const selectedMemberEmail = $('#spReviewerMail' + index).text();
      const selectedMemberPhone = $('#spReviewerBusinessPhones' + index).text();
      const selectedMemberTitle = $('#spReviewerTitle' + index).text();

      this.reviewer.memberName = selectedMemberName;
      this.reviewer.memberEmailId = selectedMemberEmail;
      this.reviewer.memeberContactNo = selectedMemberPhone;
      this.reviewer.jobTitle = selectedMemberTitle;
      this.reviewer.isReviewer = 1;
      this.reviewer.workspaceId = this.workspaceId;
      this.reviewer.roleId = 0;

      let manualRPAddModel: ManualRPAddModel = new ManualRPAddModel();
      let autoRPReplaceModel: AutoRPReplaceModel = new AutoRPReplaceModel();
      if (this.isAddRP) {
        manualRPAddModel.member = this.reviewer;
      }
      else if (this.isReplaceRP) {
        autoRPReplaceModel.member = this.reviewer;
      }

      $("#ReviewerErrorMessage").text('');
      var countofAddedReviewer = 0;
      for (var i in this.reviewerList) {
        if (this.isAddRP) {
          if (this.reviewerList[i].memberEmailId == reviewer.memberEmailId && this.reviewerList[i].memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional) {
            countofAddedReviewer = countofAddedReviewer + 1;
          }
        }
        else if (this.isReplaceRP) {
          if (this.reviewerList[i].memberEmailId == reviewer.memberEmailId && this.reviewerList[i].memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewerList[i].memberRoleDescription === this.roleNameOfReplace) {
            countofAddedReviewer = countofAddedReviewer + 1;
          }
        }
      }
      if (countofAddedReviewer == 0) {
        let isReviewerExist = true;
        const searchUsers: MembersList[] = [];
        const searchGroupUserViewModel = new SearchGroupUserViewModel();
        const roleShortName = (this.roleNameOfReplace === EnumRoles.AuditPartner) ?
          RoleNames.AuditPartner.toString() : RoleNames.ReviewingProfessional.toString();
        const addTeamMember = new MembersList();
        addTeamMember.displayName = selectedMemberName;
        addTeamMember.mail = selectedMemberEmail;
        searchUsers[0] = addTeamMember;
        this.adUsersService.GetAzureADGroupsByRoleShortName(roleShortName).subscribe(adGroups => {
          this.azureADUsersArrayList = adGroups;
          this.azureADUsersArrayList.roleName = roleShortName;
          searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
          searchGroupUserViewModel.user = searchUsers[0];
          this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(isUserValid => {
            isGivenUserGroupMember = isUserValid;
            if (isGivenUserGroupMember) {
              this.adUsersService.getUserDetailsByEmailId(this.currentEngagement.engagementPartner).subscribe(user => {
                if (user) {
                  if (this.isAddRP) {
                    manualRPAddModel.engagementPartnerOfficeLocation = user.officeLocation;
                  } else if (this.isReplaceRP) {
                    autoRPReplaceModel.oldMemberEmailId = this.autoRPDisplayModel[this.indexofReplaceReviewerRoleName].teamMembers[this.indexofReplaceReviewerMemberMail].memberEmailId;
                    autoRPReplaceModel.engagementPartnerOfficeLocation = user.officeLocation;
                    autoRPReplaceModel.member.memberRoleDescription = this.roleNameOfReplace;
                    autoRPReplaceModel.engagementStage = this.workspaceModel.status;
                  }
                }
                if (this.isAddRP) {
                  this.workspaceService.addWorkspaceMember(manualRPAddModel).subscribe(addWorkspaceMemberResponse => {
                    this.isUserBeingRemoveFinalApprover = false;
                    this.isRPApprovedActionTriggered = false;
                    console.log(addWorkspaceMemberResponse);
                    setTimeout(() => {
                      this.getReviewersAndUpdateRPPermission();
                      this.getReviewers();
                      this.reviewersListArray = null;
                      this.addReviewerIsInProgress = false;
                      $("#modal-addReviewer").modal('hide');
                    }, 50);
                  });
                } else if (this.isReplaceRP) {
                  this.workspaceService.replaceWorkspaceMember(autoRPReplaceModel).subscribe(result => {
                    this.isUserBeingRemoveFinalApprover = false;
                    this.isRPApprovedActionTriggered = false;
                    console.log(result);
                    setTimeout(() => {
                      this.getReviewersAndUpdateRPPermission();
                      this.getReviewers();
                      this.reviewersListArray = null;
                      this.addReviewerIsInProgress = false;
                      $("#modal-addReviewer").modal('hide');
                    }, 50);
                  });
                }
              },
                error => {
                  console.log('Erro while getting user office location', error);
                  this.isReplaceRpOkButtonEnabled = true;
                });
            } else {
              $("#ReplaceIndicatorMessage").text('');
              $("#ReviewerErrorMessage").text('User is not part of the reviewing professional role.');
              this.isReplaceRpOkButtonEnabled = true;
            }
          },
            error => {
              console.log('Error in validating user with AD Group ', error);
              this.isReplaceRpOkButtonEnabled = true;
            });
        });

      }
      else {
        $("#ReplaceIndicatorMessage").text('');
        $("#ReviewerErrorMessage").text('Selected reviewer is already added.');
        $("#modal-addReviewer").modal('show');
        this.addReviewerIsInProgress = false;
        this.isReplaceRpOkButtonEnabled = true;
      }
    }
  }
  GetTeamMemberPermissions() {
    this.applicationMasterService.GetTeamMemberPermissions().subscribe(
      result => {
        this.teamMemberPermissions = result;
        console.log('this.teamMemberPermissions ==>', this.teamMemberPermissions);
        this.teamMemberPermissions.forEach(permission => {
          if (permission.typeValue === 'Site owner') {
            this.defaultPermissionId = permission.id
          }
        })
      }
    );
  }

  ValidateTeamMember(index: number) {
    if (index != null && Number(index) >= 0) {
      this.isValid = true;
    } else {
      this.isValid = false;
      $("#TeamMemberErrorMessage").text('Select team member.');
    }

    if (this.isAudit && this.KeyTeamMemberRoleId == 0) {
      this.isValid = false;
      $('#KeyTeamMemberRoleErrorMessage').text('Select role.');
    } else {
      $('#KeyTeamMemberRoleErrorMessage').text('');
    }
  }

  AddTeamMember(reviewer: TeamMembers) {
    let index = this.selectedRPIndex;
    let isGivenUserGroupMember = false;
    this.ValidateTeamMember(index);

    if (this.isValid) {
      this.addTeamMemberIsInProgress = true;
      const selectedMemberName = $('#spMemberDisplayName' + index).text();
      const selectedMemberEmail = $('#spTeamMemberMail' + index).text();
      const selectedMemberTitle = $('#spTeamMemberJobTitle' + index).text();
      const selectedMemberPhone = $('#spMemberBusinessPhones' + index).text();

      this.reviewer.memberName = selectedMemberName;
      this.reviewer.memberEmailId = selectedMemberEmail;
      this.reviewer.memeberContactNo = selectedMemberPhone;
      this.reviewer.jobTitle = selectedMemberTitle;
      this.reviewer.isReviewer = 0;
      this.reviewer.workspaceId = this.workspaceId;
      this.reviewer.roleId = 0;
      this.reviewer.isKeyTeamMember = this.isKeyTeamMember;
      this.reviewer.keyTeamMemberRoleId = this.KeyTeamMemberRoleId;
      this.reviewer.description = this.KeyDescription;

      var countofAddedReviewer = 0;

      if (this.teamsMembersList.epMembers != null) {
        for (let i = 0; i < this.teamsMembersList.epMembers.length; i++) {
          if (this.teamsMembersList.epMembers[i].memberEmailId == this.reviewer.memberEmailId) {
            countofAddedReviewer = countofAddedReviewer + 1;
          }
        }
      }
      if (this.teamsMembersList.teamMembers != null) {
        for (let j = 0; j < this.teamsMembersList.teamMembers.length; j++) {
          if (this.teamsMembersList.teamMembers[j].memberEmailId == this.reviewer.memberEmailId) {
            countofAddedReviewer = countofAddedReviewer + 1;
          }
        }
      }
      if (countofAddedReviewer == 0) {

        $("#TeamMemberErrorMessage").text('');
        // add code to validate selected user against defined AD group.

        const searchUsers: MembersList[] = [];
        const searchGroupUserViewModel = new SearchGroupUserViewModel();
        let roleShortName: string;
        if (this.isMemberFirm) {
          roleShortName = this.isIndusFlag == false ? this.userInfo?.memberFirmTeamMemberShortRoleName : this.userInfo?.memberFirmIndusConsentShortRoleName;
        }
        else {
          roleShortName = this.isIndusFlag == false ? RoleNames.StaffMember.toString() : RoleNames.INDUSConsent.toString();
        }
        const addTeamMember = new MembersList();
        addTeamMember.displayName = selectedMemberName;
        addTeamMember.mail = selectedMemberEmail;
        searchUsers[0] = addTeamMember;
        this.adUsersService.GetAzureADGroupsByRoleShortName(roleShortName).subscribe(result => {
          this.azureADUsersArrayList = result;
          this.azureADUsersArrayList.roleName = roleShortName;
          this.assignAzureADGroupsByMemberFirm();
          searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
          searchGroupUserViewModel.user = searchUsers[0];
          this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(isUserValid => {
            isGivenUserGroupMember = isUserValid;
            if (isGivenUserGroupMember) {
              this.workspaceService.addWorkspaceTeamMember(this.reviewer).subscribe(result => {
                console.log(result);
                setTimeout(() => {
                  this.workspaceService.getTeamMembers(this.workspaceId).subscribe(
                    teamMemberResult => {
                      this.teamsMembersList = teamMemberResult;
                      this.teamMembersListArray = null;
                      this.addTeamMemberIsInProgress = false;
                      this.ConfigureReviewNoteSearch();
                      setTimeout(() => {
                        this.workspaceService.getCollabrationTeamMembers(this.currentEngagement.engagementId).subscribe(
                          collabrationTeamMemberResult => {
                            this.collaborationSiteMembersList = collabrationTeamMemberResult;
                            if (this.collaborationSiteMembersList != null) {
                              let siteMembersGroups = this.collaborationSiteMembersList.reduce((r, a) => {
                                r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                                return r;
                              }, {});

                              console.log("siteMembersGroups", siteMembersGroups);
                              let onwwerList: CollaborationSiteMembers[] = [];
                              let memberList: CollaborationSiteMembers[] = [];

                              let siteGroupTypes = Object.keys(siteMembersGroups);

                              siteGroupTypes.forEach(type => {
                                if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                                  onwwerList = this.collaborationSiteMembersList.filter(member =>
                                    Number(member.memberPermissionId) === Number(type));
                                } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                                  memberList = this.collaborationSiteMembersList.filter(member =>
                                    Number(member.memberPermissionId) === Number(type));
                                }
                              })

                              // Site Onwers Email List
                              let siteOwnerEmailIds: string[] = [];
                              onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                              //Site Members Email List
                              let siteMemberEmailIds: string[] = [];
                              memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                              let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                              let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
                              let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

                              OwnerListEmailModel.groupType = 'Owner';
                              OwnerListEmailModel.groupUsersEmail = [];
                              siteOwnerEmailIds.forEach(emailid => {
                                let adUser: ADUser = new ADUser();
                                adUser.firstName = '';
                                adUser.lastName = '';
                                adUser.userEmailId = emailid;
                                OwnerListEmailModel.groupUsersEmail.push(adUser);
                              })

                              MemberListEmailModel.groupType = 'Member';
                              MemberListEmailModel.groupUsersEmail = [];
                              siteMemberEmailIds.forEach(emailid => {
                                let adUser: ADUser = new ADUser();
                                adUser.firstName = '';
                                adUser.lastName = '';
                                adUser.userEmailId = emailid;
                                MemberListEmailModel.groupUsersEmail.push(adUser);
                              });

                              collbSiteUsersUpdatePermissionModel.siteType = "EngagementSite";
                              collbSiteUsersUpdatePermissionModel.siteUniqueID = this.currentEngagement.siteUniqueID;
                              collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
                              collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
                              let updatePermissionsModelList: UpdatePermissionsModel[] = [];
                              updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);

                              // Button Update Collbration
                              //4. Call Active Directory API to update latest collaborationSite Member Ids                            
                              let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
                              updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;
                              this._activedirectoryService.UpdatePermissionsInAcceptance(updatePermissionsModelData).subscribe(
                                result => {
                                },
                                error => {
                                  this.loadingScreenService.stopLoading();
                                  this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                                });
                            }
                          },
                          () => {
                            this.teamsMembersList = null;
                            this.teamMembersListArray = null;
                          }
                        );
                        $("#modal-TeamMember").modal('hide');
                      }, 50);
                    },
                    () => {
                      this.teamsMembersList = null;
                      this.teamMembersListArray = null;
                    }
                  );
                }, 100);
              });
            } else {
              $("#TeamMemberErrorMessage").text('User is not part of the team member role.');
            }
          },
            error => {
              console.log('Error in validating user with AD group ', error);
            });
        });

      }
      else {
        $("#TeamMemberErrorMessage").text('Selected team member is already added.');
        $("#searchTeamMemberName").val('');
        this.teamMembersListArray = null;
        this.addTeamMemberIsInProgress = false;
      }
      if (this.isAudit) {
        this.ResetKeyTeamMember();
      }
    }
  }

  ResetKeyTeamMember() {
    this.isKeyTeamMember = false;
    this.KeyTeamMemberRoleId = 0;
    this.KeyDescription = '';
    $('#keyTeamMemberDescription').css('display', 'none');
  }


  AddMember(teamMember: TeamMembers) {
    $("#TMErrorMessage").text('');
    var countofAddedTM = 0;
    for (var i in this.memberList) {
      if (this.memberList[i].memberEmailId == teamMember.memberEmailId) {
        countofAddedTM = countofAddedTM + 1;
      }
    }
    if (countofAddedTM == 0) {
      $("#TMErrorMessage").text('');

      let manualRPAddModel: ManualRPAddModel = new ManualRPAddModel();
      this.adUsersService.getUserDetailsByEmailId(this.currentEngagement.engagementPartner).subscribe(user => {
        if (user) {
          manualRPAddModel.engagementPartnerOfficeLocation = user.officeLocation;
        }
        this.workspaceService.addWorkspaceMember(manualRPAddModel).subscribe(result => {
          console.log('teamMember Added ' + result);
          setTimeout(() => {
            this.getMembers();
            $("#modal-addTeamMember").modal('hide');
          }, 50);
        });
      },
        error => {
          console.log('Error while getting user office location', error);
        });
    }
    else {
      $("#TMErrorMessage").text('Selected Team Member is already added.');
      $("#modal-addTeamMember").modal('show');
    }

  }
  getWorkspaceData(id: string) {
    this.loadingScreenService.startLoading();
    this.workspaceService.getWorkspaceById(id).subscribe(
      result => {
        result.setAnsFlag = "update";
        this.CurrentWorkspaceData = result;
        this.allWorkspaceDataList.push(JSON.parse(JSON.stringify(this.CurrentWorkspaceData)));
        this.EditQuestionnaireData = result;
        if (this.engagementState != undefined) {
          this.GetWorkspaceDocumentCountbyEngId();
        }


        this.getWorkspaceReviewPoints();
        this.IsInProgressModification(this.CurrentWorkspaceData.engagementId);
        this.GetIndusFlagForReviewPage(this.CurrentWorkspaceData.engagementId);
      });
  }
  getSecondaryWorkspaceData(engagementId: number) {
    this.loadingScreenService.startLoading();
    this.workspaceService.getSecondaryWorkspaceDataByEngId(engagementId).subscribe(
      resultModel => {
        this.secondaryWorkspaceDataList = resultModel.secondaryWorkspaceDataList;
        console.log("secondaryWorkspaceDataList ==>", this.secondaryWorkspaceDataList);
        if (this.secondaryWorkspaceDataList.length > 0) {
          this.secondaryWorkspaceDataList.forEach(workspace => {
            this.allWorkspaceDataList.push(JSON.parse(JSON.stringify(workspace)));
            this.getSecondaryWorkspaceDocumentList(workspace);
          });
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
      });
  }

  confirmModalToremoveSecondaryWorkspace() {
    this.CloseReviewNotesPanel();
    if (this.selectedWorkspaceDocumentItem.workspaceId != undefined) {
      let dialogDesc: string = '';
      let bigTitle: boolean = false;
      let dialogInput = '';
      const dialogType = 'information';
      const action = 'Delete document?';
      dialogDesc = 'Are you sure you want to delete this document? This cannot be undone.';
      this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false, bigTitle,
        '0', this.userInfo.loggedInUserEmail);
    }
  }

  removeSecondaryWorkspace() {
    this.loadingScreenService.startLoading();
    let removeSecondaryWorkspaceModel: RemoveSecondaryWorkspaceModel = new RemoveSecondaryWorkspaceModel();
    removeSecondaryWorkspaceModel.workspaceId = this.selectedWorkspaceDocumentItem.workspaceId;
    removeSecondaryWorkspaceModel.updatedBy = this.userInfo.userName;
    this.workspaceService.RemoveSecondaryWorkspace(removeSecondaryWorkspaceModel).subscribe(
      resultMsg => {
        console.log("resultMsg ==>", resultMsg);
        if (resultMsg === 'Success') {
          this.getWorkspaceData(this.workspaceId);
          this.MarkQnARecordDeleted(this.currentEngagement.engagementId, this.selectedWorkspaceDocumentItem.workItemId);
        } else if (resultMsg === 'Failure') {
          this.alertServiceFun('secondary worksapce could not be removed !', 'danger');
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Error while removing secondary worksapce !', 'danger');
      });
  }

  getSecondaryWorkspaceDocumentList(workspace: WorkspaceModel) {
    console.log('getSecondaryWorkspaceDocumentList func called', workspace.hdWorkItemId);
    this.hdService.getHDDocumentListtoPreview(workspace.hdWorkItemId).subscribe(
      result => {
        result[0].workspaceId = workspace.workspaceId;
        //  this.DocumentPreviewList.push(result[0]);
        this.flilWorkspaceDocumentList(workspace, result[0]);
      },
      error => {
        this.loadingScreenService.stopLoading();
      });
  }

  removeMember(memberId: number) {
    this.workspaceMemberRemoveModel.memberEmailIdToRemove = this.teamsMembersList.teamMembers.filter(user => user.id == memberId)[0].memberEmailId;
    this.workspaceMemberRemoveModel.loggedInUserEmailId = this.userInfo.userName;
    this.workspaceMemberRemoveModel.memberId = memberId;
    this.workspaceService.removeMember(this.workspaceMemberRemoveModel).subscribe(result => {
      console.log('teamMember removed ' + result);
      setTimeout(() => {
        this.getMembers();
      }, 50);
    });
  }

  InitinateUpdateRPPermission(rpPermissionModel: RPPermissionModel) {
    let rpPermissionInterval = setInterval(() => this.loadingScreenService.startLoading(), 1);
    this._activedirectoryService.UpdateRPPermission(rpPermissionModel).subscribe(
      result => {
        console.log('UpdateRPPermissionResult ==>', result);
        clearInterval(rpPermissionInterval);
        this.loadingScreenService.stopLoading();
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  getReviewersAndUpdateRPPermission() {
    let tempReviewerList: TeamMembers[] = [];
    let updateRPPermissionInterval = setInterval(() => this.loadingScreenService.startLoading(), 1);
    this.workspaceService.getReviewers(this.workspaceId).subscribe(
      result => {
        this.reviewerList = result;
        tempReviewerList = result;
        this.BuildRPsDisplayModel();
        tempReviewerList = tempReviewerList.filter(member => member.isReviewer == 1
          && member.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional);
        let rpPermissionModel: RPPermissionModel = new RPPermissionModel();
        rpPermissionModel.primarySiteUniqueId = this.currentEngagement.siteUniqueID;
        if (tempReviewerList && tempReviewerList.length > 0) {
          //Populate Decision RP's into rpPermissionModel
          tempReviewerList.forEach(member => {
            if (!rpPermissionModel.rpApprovers.includes(member.memberEmailId)) {
              rpPermissionModel.rpApprovers.push(member.memberEmailId);
            }
          });
          //Pass rpPermissionModel to AD group updation              
        }
        this.InitinateUpdateRPPermission(rpPermissionModel);
        clearInterval(updateRPPermissionInterval);
        this.loadingScreenService.stopLoading();
      },
      error => {
        clearInterval(updateRPPermissionInterval);
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      }
    );
  }

  removeReviewer(reviewerId: number) {
    this.adUsersService.getUserDetailsByEmailId(this.currentEngagement.engagementPartner).subscribe(user => {
      this.workspaceMemberRemoveModel.engagementPartnerOfficeLocation = user.officeLocation;
      this.workspaceMemberRemoveModel.memberEmailIdToRemove = this.reviewerList.filter(reviewer => reviewer.id == reviewerId)[0].memberEmailId;
      this.workspaceMemberRemoveModel.loggedInUserEmailId = this.userInfo.userName;
      this.workspaceMemberRemoveModel.memberId = reviewerId;
      this.workspaceMemberRemoveModel.engagementStage = this.workspaceModel.status;

      //When Review response is not loaded and RP is Final reviewer then 
      //do not proceed for final RP removal/Approval and display error message
      if (this.isUserBeingRemoveFinalApprover && !this.IsHDQuestionAnswerLoaded) {
        this.DisplayHotDocsNoAnswerSummaryAlert()
      }
      else {
        this.workspaceService.removeMember(this.workspaceMemberRemoveModel).subscribe(result => {
          console.log('reviewer removed ' + result);
          setTimeout(() => {
            this.getReviewersAndUpdateRPPermission();
            this.getReviewers();

            if (this.isUserBeingRemoveFinalApprover) {
              this.workspaceActionButtonClick(WorkspaceInterviewsStatus.RPApprovedTitle, false);
            }
          }, 50);
        });
      }
    },
      error => {
        console.error(error);
      });
  }

  removeTeamMember(teamMemberId: number) {
    this.workspaceMemberRemoveModel.memberEmailIdToRemove = this.teamsMembersList.teamMembers.filter(user => user.id == teamMemberId)[0].memberEmailId;
    this.workspaceMemberRemoveModel.loggedInUserEmailId = this.userInfo.userName;
    this.workspaceMemberRemoveModel.memberId = teamMemberId;
    this.workspaceMemberRemoveModel.engagementStage = this.workspaceModel.status;
    this.workspaceService.removeMember(this.workspaceMemberRemoveModel).subscribe(result => {
      setTimeout(() => {
        this.workspaceService.getTeamMembers(this.workspaceId).subscribe(
          teamMemberResult => {
            this.teamsMembersList = teamMemberResult;

            if (this.teamsMembersList.clientTeamMemberList != null) {
              $("#clientTeamMemberDiv").show();
            }
            else {
              $("#clientTeamMemberDiv").hide();
            }
            this.ConfigureReviewNoteSearch();
            this.collaborationSiteMembersList = null;
            setTimeout(() => {
              this.workspaceService.getCollabrationTeamMembers(this.currentEngagement.engagementId).subscribe(
                collabrationTeamMemberResult => {
                  this.collaborationSiteMembersList = collabrationTeamMemberResult;
                  if (this.collaborationSiteMembersList != null) {
                    let siteMembersGroups = this.collaborationSiteMembersList.reduce((r, a) => {
                      r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                      return r;
                    }, {});

                    console.log("siteMembersGroups", siteMembersGroups);
                    let onwwerList: CollaborationSiteMembers[] = [];
                    let memberList: CollaborationSiteMembers[] = [];

                    let siteGroupTypes = Object.keys(siteMembersGroups);

                    siteGroupTypes.forEach(type => {
                      if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                        onwwerList = this.collaborationSiteMembersList.filter(member =>
                          Number(member.memberPermissionId) === Number(type));
                      } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                        memberList = this.collaborationSiteMembersList.filter(member =>
                          Number(member.memberPermissionId) === Number(type));
                      }
                    })

                    // Site Onwers Email List
                    let siteOwnerEmailIds: string[] = [];
                    onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                    //Site Members Email List
                    let siteMemberEmailIds: string[] = [];
                    memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                    let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                    let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
                    let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

                    OwnerListEmailModel.groupType = 'Owner';
                    OwnerListEmailModel.groupUsersEmail = [];
                    siteOwnerEmailIds.forEach(emailid => {
                      let adUser: ADUser = new ADUser();
                      adUser.firstName = '';
                      adUser.lastName = '';
                      adUser.userEmailId = emailid;
                      OwnerListEmailModel.groupUsersEmail.push(adUser);
                    })

                    MemberListEmailModel.groupType = 'Member';
                    MemberListEmailModel.groupUsersEmail = [];
                    siteMemberEmailIds.forEach(emailid => {
                      let adUser: ADUser = new ADUser();
                      adUser.firstName = '';
                      adUser.lastName = '';
                      adUser.userEmailId = emailid;
                      MemberListEmailModel.groupUsersEmail.push(adUser);
                    })


                    collbSiteUsersUpdatePermissionModel.siteType = "EngagementSite";
                    collbSiteUsersUpdatePermissionModel.siteUniqueID = this.currentEngagement.siteUniqueID;
                    collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
                    collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
                    let updatePermissionsModelList: UpdatePermissionsModel[] = [];
                    updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);

                    // Button Update Collbration
                    //4. Call Active Directory API to update latest collaborationSite Member Ids                    
                    let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
                    updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;
                    this._activedirectoryService.UpdatePermissionsInAcceptance(updatePermissionsModelData).subscribe(
                      result => {
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                      });
                  }
                },
                () => {
                  this.teamsMembersList = null;
                  this.teamMembersListArray = null;
                }
              );
            }, 100);
          },
          () => {
            this.teamsMembersList = null;
          }
        );
      }, 50);
    });
  }

  QuestionSummaryDisplayOnLoad() {
    console.log('QuestionSummaryDisplay func ', this.CurrentWorkspaceData);
    if (this.CurrentWorkspaceData != null) {
      this.workItemIdForReviewResponse = this.CurrentWorkspaceData.hdWorkItemId;
      this.workItemVersionIdReviewResponse = this.CurrentWorkspaceData.hdWorkItemVersionId;
    }
    this.lastUpdatedByForReviewResponse = "Last updated by " + this.CurrentWorkspaceData.lastActivityBy + " on " + this.datepipe.transform(this.CurrentWorkspaceData.lastActivityDate, 'MM/dd/yyyy');
  }

  QuestionSummaryDisplay() {
    console.log('QuestionSummaryDisplay func ', this.selectedWorkspaceDocumentItem);
    if (this.selectedWorkspaceDocumentItem != null) {
      this.workItemIdForReviewResponse = this.selectedWorkspaceDocumentItem.workItemId;
      this.workItemVersionIdReviewResponse = this.selectedWorkspaceDocumentItem.workItemVersionId;
      this.originalworkItemIdForReviewResponse = null;
      this.originalworkItemVersionIdReviewResponse = null;
    }
    this.lastUpdatedByForReviewResponse = "Last updated by " + this.CurrentWorkspaceData.lastActivityBy + " on " + this.datepipe.transform(this.CurrentWorkspaceData.lastActivityDate, 'MM/dd/yyyy');
  }

  QuestionSummaryDisplayForOriginal() {
    console.log('QuestionSummaryDisplayForOriginal func ', this.CurrentWorkspaceData);
    if (this.CurrentWorkspaceData != null) {
      this.originalworkItemIdForReviewResponse = this.CurrentWorkspaceData.orginalHDWorkItemId;
      this.originalworkItemVersionIdReviewResponse = this.CurrentWorkspaceData.orginalHDWorkItemVersionId;
      this.workItemIdForReviewResponse = null;
      this.workItemVersionIdReviewResponse = null
    }
    this.lastUpdatedByForReviewResponse = "Last updated by " + this.CurrentWorkspaceData.lastActivityBy + " on " + this.datepipe.transform(this.CurrentWorkspaceData.lastActivityDate, 'MM/dd/yyyy');
    // this.MeatballMenuInitialization(this.CurrentWorkspaceData.workspaceId,'',
    //this.CurrentWorkspaceData.orginalHDWorkItemId,this.CurrentWorkspaceData.orginalHDWorkItemVersionId)
  }

  closeQuestionSummaryDisplay() {
    this.workItemIdForReviewResponse = null;
    this.templatePackageVersionIdForReviewResponse = null;
  }

  displayReviewEngagement() {
    this.clientId = parseInt(this.CurrentWorkspaceData.clientId, 10);
    this.engagementId = this.CurrentWorkspaceData.engagementId;
    this.getCLMFeatureFlag();
  }

  NavigateToInterview() {
    if (this.selectedWorkspaceDocumentItem.isPrimaryWorkspaceId || this.selectedWorkspaceDocumentItem.workspaceId == undefined) {
      this.router.navigate(['Interviews/' + this.workspaceId]);
    } else {
      this.router.navigate(['Interviews/' + this.selectedWorkspaceDocumentItem.workspaceId]);
    }
  }
  confirmModalRemoveMember(memberId: number) {
    this.CloseReviewNotesPanel();
    this.loadingScreenService.startLoading();
    this.isUserBeingRemoveFinalApprover = false;
    this.isRPApprovedActionTriggered = false;
    this.workspaceService.getAllMembers(this.workspaceId).subscribe(
      members => {

        //Check if except member being requested to delete, is there any other members exists whose approval is pending
        this.isUserBeingRemoveFinalApprover = (members && members.filter(member => member.id != memberId && member.approvalStatus == 'Pending').length == 0 && members.filter(member => member.id != memberId && member.teamMemberRole == EnumRoles.EngagementPartner && member.approvalStatus == 'Approved').length > 0);
        this.hasAnyRPThanOneBeingRemove = (members && members.filter(member => member.id != memberId && member.isReviewer == 1 && member.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional).length > 0);
        if (this.isUserBeingRemoveFinalApprover) {

          this.OpenReviewCount = 0;

          this.workspaceReviewPointService.getWorkspaceReviewPoints(this.workspaceId).subscribe(
            result => {
              this.loadingScreenService.stopLoading();

              this.workspaceReviewPoints = result;
              let retval: number = 0;
              $.map(this.workspaceReviewPoints, function (elementOfArray) {
                if (elementOfArray.status == ReviewNoteStatus.Open) {
                  retval = retval + 1;
                }
              });
              this.OpenReviewCount = retval;
              if (this.OpenReviewCount > 0) {
                this.loadingScreenService.stopLoading();
                let dialogDesc: string = '';
                let bigTitle: boolean = false;
                let dialogInput = '';
                let buttonCount = 1;
                let dialogType: string = '';
                let action: string = '';
                let isDeclineModal: boolean = false;

                if (this.OpenReviewCount === 1) {
                  dialogType = 'danger';
                  action = WorkspaceInterviewsStatus.OpenReviewNotes;
                  buttonCount = 1;
                  dialogDesc = 'There is one open review note. Before final engagement approval, all review notes must be resolved.';
                } else if (this.OpenReviewCount > 1) {
                  dialogType = 'danger';
                  action = WorkspaceInterviewsStatus.OpenReviewNotes;
                  buttonCount = 1;
                  dialogDesc = 'There are ' + this.OpenReviewCount + ' review notes. Before final engagement approval, all review notes must be resolved.';
                }

                this.displayConfirmationPopUp(dialogType, action, dialogDesc, buttonCount, dialogInput, false, isDeclineModal,
                  bigTitle, '0', this.userInfo.loggedInUserEmail, true, false);
              }
              else {
                this.displayConfirmModalRemoveMember(memberId);
              }
            },
            err => {
              this.loadingScreenService.stopLoading();
              console.error(err);
            });
        }
        else {
          this.displayConfirmModalRemoveMember(memberId);
        }
      },
      err => {
        this.isUserBeingRemoveFinalApprover = false;
        console.error(err);
        this.loadingScreenService.stopLoading();
      });
  }

  displayConfirmModalRemoveMember(memberId: number) {
    this.currentWorkespaceMemberId = memberId;
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    const dialogType = 'information';
    const action = 'Remove RP';
    //dialogDesc = 'Do you want to remove a selected reviewing professional ?';
    dialogDesc = 'Are you sure you want to remove the selected reviewing professional?';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false, bigTitle,
      '0', this.userInfo.loggedInUserEmail);
  }

  confirmModalRemoveTeamMember(memberId: number) {
    this.CloseReviewNotesPanel();
    this.currentWorkespaceMemberId = memberId;
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    const dialogType = 'information';
    const action = 'Remove team member';
    dialogDesc = 'Do you want to remove a selected team member ?';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false, bigTitle,
      '0', this.userInfo.loggedInUserEmail);
  }

  confirmModalToRemoveClientTeamMember(clientMemberId: number) {
    this.CloseReviewNotesPanel();
    this.selectedClientTeamMemberId = clientMemberId;
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    const dialogType = 'information';
    const action = 'Remove client team member';
    dialogDesc = 'Do you want to remove a selected client team member ?';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false, bigTitle,
      '0', this.userInfo.loggedInUserEmail);
  }

  GetTeamMemberRoles() {
    return this._engService.GetTeamMemberRoles().subscribe(
      res => {
        this.teamMemberRoleList = res;
        res.forEach(element => {
          if (element.typeValue != 'Engagement Partner') {
            this.workspaceteamMemberRoleList.push(element);
          }
        });
      });
  }

  checkCurrentLoggedinUserisCreatedBy(createdBy: string) {
    this.isLoggedinUserCreatedby = this.workspaceService.checkCurrentLoggedinUserIsCreatedBy(createdBy);
    console.log('isLoggedinUserCreatedby ==>', this.isLoggedinUserCreatedby);
    return this.isLoggedinUserCreatedby
  }

  WorkspaceStatusUpdate(status: string) { // update workspace status as well engagement status
    this.workspaceStatusUpdateViewModel.status = status;
    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    let shortRoleName = '';
    shortRoleName = this.findCurrentUserRolesInWorkspace();
    this.workspaceStatusUpdateViewModel.roleShortName = shortRoleName.trim();
    //Set System Admin role as short name when admin dose sent to EPPMD
    if ((!this.isUserTM && !this.isUserEP) && this.isLoggedInUserSystemAdmin) {
      this.workspaceStatusUpdateViewModel.roleShortName = 'SA';
    }
    this.workspaceService.UpdateWorkspaceStatusData(this.workspaceStatusUpdateViewModel).subscribe(
      result => {
        this.approveRejectWorkspaceResultModel = result;
        if (this.approveRejectWorkspaceResultModel.approveRejectResult != "Success") {
          console.log("Error in updating workpsace status,", this.workspaceStatusUpdateViewModel)
        }
      });
  }
  IsApproveRejectButtonVisibleForMe() {
    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    if (this.workspaceStatusUpdateViewModel != null) {
      return this.workspaceService.IsApproveRejectButtonVisibleForMe(this.workspaceStatusUpdateViewModel).subscribe(
        res => {
          this.workspaceApprovalRejectionDetails = res;
          this.ApproveRejectButtonsVisibility();
          console.log("workspaceApprovalRejectionDetails", this.workspaceApprovalRejectionDetails);
        }, error => {
          console.log(error);
        });
    }
  }

  IsLoggedInUserLastApproverAndHasOpenReviewPoints(status) {
    this.loadingScreenService.startLoading();
    this.workspaceStatusUpdateViewModel.workspaceId = this.workspaceId;
    this.workspaceStatusUpdateViewModel.status = status;
    return this.workspaceService.IsLoggedInUserLastApprover(this.workspaceId, this.userInfo.userName).subscribe(
      res => {
        if (!this.isLoggedInUserCanApproveOrReject && status != 'Abandoned') {
          $("#modalOpenReviewPointsForWorkspace").modal('show');
          this.loadingScreenService.stopLoading();
        }
        else {
          $("#modalOpenReviewPointsForWorkspace").modal('hide');
          if (status == 'Approved') {
            $('#ApproveConfirmationModal').modal('show');
            $('#RejectConfirmationModal').modal('hide');
            $('#AbondonedConfirmationModal').modal('hide');
          }

          else if (status == 'Rejected') {
            $('#RejectConfirmationModal').modal('show');
            $('#ApproveConfirmationModal').modal('hide');
            $('#AbondonedConfirmationModal').modal('hide');
          }
          else if (status == 'Abandoned') {
            $('#RejectConfirmationModal').modal('hide');
            $('#ApproveConfirmationModal').modal('hide');
            $('#AbondonedConfirmationModal').modal('show');
          }
          this.loadingScreenService.stopLoading();
        }
      });
  }

  CloseAlertPopup() {
    $("#modalOpenReviewPointsForWorkspace").modal('hide');
    $("#modalCanTransferRP").modal('hide')
  }
  convertToBoolean(input: string): boolean | undefined {
    try {
      return JSON.parse(input);
    }
    catch (e) {
      return undefined;
    }
  }

  ApproveRejectButtonsVisibility() {
    this.InformationMessageVisibility();
    if (this.workspaceApprovalRejectionDetails.isVisibleForMe && !this.isWorkspaceApprovedOrRejected
      && this.workspaceApprovalRejectionDetails.roleNameForWorkspace == 'SM') {
      this.isApproveRejectButtonVisibleForMe = false;
      this.isEditQuestionairButtonVisibleForMe = true;
      this.isAbondonButtonVisibleForMe = true;
    }
    else if (this.workspaceApprovalRejectionDetails.isVisibleForMe && !this.isWorkspaceApprovedOrRejected
      && this.workspaceApprovalRejectionDetails.roleNameForWorkspace == 'RP') {
      this.isAbondonButtonVisibleForMe = false;
      this.isApproveRejectButtonVisibleForMe = true;
      this.isEditQuestionairButtonVisibleForMe = true;
    }
    else if (this.workspaceApprovalRejectionDetails.isVisibleForMe && !this.isWorkspaceApprovedOrRejected) {
      this.isApproveRejectButtonVisibleForMe = true;
      this.isEditQuestionairButtonVisibleForMe = true;
      this.isAbondonButtonVisibleForMe = true;
    }
  }

  InformationMessageVisibility() {
    if (!this.workspaceApprovalRejectionDetails.isVisibleForMe && !this.isWorkspaceApprovedOrRejected
      && this.workspaceApprovalRejectionDetails.roleNameForWorkspace == 'SM') {
      this.isInfoMessageVisibleForMe = false;
      this.isEditQuestionairButtonVisibleForMe = true;
      this.isAbondonButtonVisibleForMe = true;
    }
    else if (!this.workspaceApprovalRejectionDetails.isVisibleForMe && !this.isWorkspaceApprovedOrRejected && this.workspaceApprovalRejectionDetails.approvalStatus != 'Pending') {
      this.isInfoMessageVisibleForMe = true;
      this.isEditQuestionairButtonVisibleForMe = false;
      this.isAbondonButtonVisibleForMe = false;
    }
  }

  CloseAddReviewerAlertPopup() {
    $("#ErrorMessageForCannotAddEPAsReviewerModal").modal('hide');
  }
  cancelApproveReject(modalId) {
    $("#" + modalId).modal('hide');
  }

  getHDConfigData() {
    this.hdService.getHDConfigurationDetails().subscribe(
      result => {
        this.HdConfigDetails = result;
      });
  }
  TransferReviewingProfessional(reviewer: any) {
    this.currentReviewerForTransfer = reviewer.id;
    $(".nameForTitle").html(reviewer.memberName);
    var countOfUserHasOpenReviewPoints = 0;
    $.map(this.workspaceReviewPoints, function (elementOfArray) {
      if (elementOfArray.createdBy == reviewer.memberEmailId && elementOfArray.status == 1) {
        countOfUserHasOpenReviewPoints = countOfUserHasOpenReviewPoints + 1;
      }
    });
    if (countOfUserHasOpenReviewPoints > 0) {
      $("#modalCanTransferRP").modal('show');
    }
    else {
      $("#TransferReviewingProfessionalModal").modal('show');
      this.removeRowSelection('tblTransferReviewerAD', 'searchTransferReviewerName', '', '');
      this.refreshModalTransferReviewers();
    }
  }
  searchTransferReviewerNames(event) {
    $('#' + event.currentTarget.id).keyup(function () {
      const searchText = this.value.toLowerCase().trim();

      $('#tblTransferReviewerAD tr').each(function (index) {
        if (!index) return;
        $(this).find('td').each(function () {
          var id = $(this).text().toLowerCase().trim();
          var not_found = (id.indexOf(searchText) === -1);
          $(this).closest('tr').toggle(!not_found);
          return not_found;
        });
      });
    });
  }

  selectTransferReviewer(index) {

    $('#tblTransferReviewerAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    const selectedMemberName = $('#spTransferReviewerdisplayName' + index).text();
    const selectedMemberEmail = $('#spTransferReviewermail' + index).text();
    const selectedMemberPhone = $('#spTransferReviewerbusinessPhones' + index).text();

    this.reviewer.memberName = selectedMemberName;
    this.reviewer.memberEmailId = selectedMemberEmail;
    this.reviewer.memeberContactNo = selectedMemberPhone;
    this.reviewer.isReviewer = 1;
    this.reviewer.workspaceId = this.workspaceId;
    this.reviewer.roleId = 0;
    $("#btnUpdateTransferReviewingProfessional").attr('disabled', false);
  }

  removeReviewersWhichareAlreadyAdded() {
    this.temp = this.reviewersTransferListArray;
    if (this.temp != undefined) {
      for (var j = 0, m = this.reviewerList.length; j < m; j++) {
        this.removeItem(this.temp, this.reviewerList[j].memberName)
      }
    }

    console.log(this.temp);
    console.log(this.reviewersTransferListArray, "Tranfer RP");
  }

  UpdateTransferReviewingProfessional(reviewer: TeamMembers) {
    if (this.memberList.filter(c => c.teamMemberRole == 'Engagement Partner')[0].memberEmailId != reviewer.memberEmailId) {
      reviewer.id = this.currentReviewerForTransfer;


      reviewer.oldreviewerName = $(".nameForTitle").html();
      this.loadingScreenService.startLoading();
      this.workspaceService.updateWorkspaceReviewers(reviewer).subscribe(result => {
        console.log(result);
        setTimeout(() => {
          this.getReviewers();
          this.loadingScreenService.stopLoading();
          window.location.reload();

        }, 30);
      });
    }
    else {
      $("#ErrorMessageForCannotAddEPAsReviewerModal").modal('show');
    }
  }

  refreshModalTransferReviewers() {
    this.GetTranferReviewingProfessionalsFromAD();
  }
  GetTranferReviewingProfessionalsFromAD() {
    this.loadingScreenService.startLoading();
    this.adUsersService
      .GetReviewingProfessionals()
      .subscribe(
        reviewerList => {
          this.reviewerADUsersList = reviewerList;
          this.adUsersService.getAzureAdUsersByGroups(this.reviewerADUsersList, RoleNames.ReviewingProfessional)
            .subscribe(
              userList => {
                this.reviewersTransferListArray = userList;
                this.removeReviewersWhichareAlreadyAdded();
                this.loadingScreenService.stopLoading();
              });

        },
        error => {
          this.reviewerADUsersList = null;
          console.log('Error in getting response from GetReviewingProfessionals func ', error);
        }
      );
  }
  removeItem(array, item) {
    for (var i in array) {
      if (array[i].displayName == item) {
        array.splice(i, 1);
        break;
      }
    }
  }

  removeRowSelection(tableId, textboxId, errorMessageId, replaceMessageId) {
    $('#' + textboxId).val('');
    if (errorMessageId != '') {
      $("#" + errorMessageId).text('');
    }

    if (replaceMessageId != '') {
      $("#" + replaceMessageId).text('');
    }

    var searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }
  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }

  checkSpacesatBegining(event, index) {
    let text = '';
    if (event.target.id == 'txtReviewNoteDescription') {
      text = event.currentTarget.textContent.trim();
    }
    else {
      text = event.currentTarget.value.trim();
    }

    if (text.length === 0) {
      if (event.target.id == 'txtReviewNoteSubject') {
        this.currentworkspaceReviewPoints.subject = null;
      }
      else if (event.target.id == 'txtReviewNoteDescription') {
        this.currentworkspaceReviewPoints.description = null;
      }
      else if (event.target.id == 'txtNewComment_' + index) {
        this.currentworkspaceReviewPointComment.comments = null;
      }
    }
    else if (text.length > 0) {
      if (event.target.id == 'txtReviewNoteSubject') {
        this.currentworkspaceReviewPoints.subject = text;
      }
      else if (event.target.id == 'txtReviewNoteDescription') {
        this.currentworkspaceReviewPoints.description = text;
      }
      else if (event.target.id == 'txtNewComment_' + index) {
        this.currentworkspaceReviewPointComment.comments = text;
      }
    }
  }

  checkIfAllowToTypeCharacter(event) {
    if (event.which != 36  //Home
      && event.which != 35 //End
      && event.which != 46 //Delete
      && event.which != 8  //Backspace
      && event.which != 37 //Left arrow
      && event.which != 38 //up arrow
      && event.which != 39 //right arrow
      && event.which != 40 //down aarow
      && ((!event.ctrlKey && event.which != 65) || (!event.ctrlKey && event.which != 90) || (!event.ctrlKey && event.which != 88) || (!event.ctrlKey && event.which != 67) || (!event.ctrlKey && event.which != 86)) //select all, undo, cut, copy, paste
      && event.which != 17 //ctrl
      && event.currentTarget.textContent.length + 1 > this.maxCharactersAllowedForReviewNoteDescription) {
      event.preventDefault();
    }
  }

  setRemainingCharacterCount(event, label) {
    if (event.currentTarget.textContent.length == 0) {
      $('#' + label).text(this.maxCharactersAllowedForReviewNoteDescription + ' characters');
      $('#' + label).removeClass('warning-review-note-label');
    }
    else {
      $('#' + label).text(this.maxCharactersAllowedForReviewNoteDescription - event.currentTarget.textContent.length + ' characters remaining');
      if (event.currentTarget.textContent.length >= this.warningCharacterCount) {
        $('#' + label).addClass('warning-review-note-label');
      }
      else {
        $('#' + label).removeClass('warning-review-note-label');
      }
    }
  }

  verifyPastedContent(event, label) {
    if ((event.clipboardData.getData('text') + event.currentTarget.textContent).length > this.maxCharactersAllowedForReviewNoteDescription) {
      event.preventDefault();
    }
    else {
      let clipboardContentLenght = (event.clipboardData.getData('text') + event.currentTarget.textContent).length;
      let remainingChars = this.maxCharactersAllowedForReviewNoteDescription - clipboardContentLenght;
      $('#' + label).text(remainingChars + ' characters remaining');
      if (clipboardContentLenght >= this.warningCharacterCount) {
        $('#' + label).addClass('warning-review-note-label');
      }
      else {
        $('#' + label).removeClass('warning-review-note-label');
      }
    }
  }

  checkSpaceChartBegin(event) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      this.modalWorkspaceDiscussion.discussion = null;
      return false;
    }
    else if (text.length > 0) {
      this.modalWorkspaceDiscussion.discussion = text;
    }
  }

  checkSpaceChartBeginInTableRow(event, i, recordListName: string) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (recordListName === 'comments') {
        this.modalWorkspaceComment.comments[i] = null;
        return false;
      }
    }
    else if (text.length > 0) {
      if (recordListName === 'comments') {
        this.modalWorkspaceComment.comments[i] = text;
        return false;
      }
    }
  }

  showActivityLog() {
    console.log('showActivityLog funca called');
    this.workspaceIdForActivityLog = this.workspaceId;
  }

  onCleared(cleared: boolean) {
    if (cleared) {
      this.workspaceIdForActivityLog = null;
    }
  }

  checkUserExistance(email: string): boolean {
    return this.employeeList.some(r => r.employeeEmailId === email);
  }

  onApprove() {
    this.loadingScreenService.startLoading();
    const emitsCheckRequestSentForClient =
      this.workspaceService.CheckRequestSentForClient(this.workspaceModel.workspaceId.toString(), 0, 1);
    emitsCheckRequestSentForClient.pipe(
      catchError(err => {
        this.loadingScreenService.stopLoading();
        console.error('Error Occur in CheckRequestSentForClient ', err);
        this.workspaceService.storeErrorLog
          ("WorkspaceId ::" + this.workspaceModel.workspaceId.toString() + " Message ::" + err + "::" +
            JSON.stringify(this.userInfo))
          .subscribe(
            result => { console.log(result); }
          );

        return of(-1);
      }
      )
    ).subscribe(result => {
      if (result == 1) {
        this.workspaceService.getPrimaryAssignment(this.workspaceModel.workspaceId.toString(), null, null)
          .subscribe(
            assignmentRequestList => {
              if (assignmentRequestList.length > 0 && assignmentRequestList != null) {
                for (const assignmentRequest of assignmentRequestList) {
                  console.log('data from getPrimaryAssignment ', assignmentRequest);

                  if (!this.checkUserExistance(assignmentRequest.initiated_By_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.initiated_By_Email));
                  }
                  if (!this.checkUserExistance(assignmentRequest.client_Administrator_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.client_Administrator_Email));
                  }
                  if (!this.checkUserExistance(assignmentRequest.assignment_Administrator_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.assignment_Administrator_Email));
                  }
                  if (!this.checkUserExistance(assignmentRequest.engagement_Partner_Email)) {
                    this.employeeList.push(new Employee('0', assignmentRequest.engagement_Partner_Email));
                  }
                }

                console.log('To get the employee ids for given user list ', this.employeeList);

                const searchEmployee = new SearchEmployeeModel();
                searchEmployee.employees = this.employeeList;

                this.graphSerivce.GetEmployeeIdForUserList(searchEmployee).subscribe(
                  finalEmployeeList => {

                    console.log('API response for employee ids for given user list ', finalEmployeeList);
                    for (const assignmentRequestInner of assignmentRequestList) {
                      assignmentRequestInner.initiated_By
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.initiated_By_Email)
                          .employeeId;
                      assignmentRequestInner.client_Administrator
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.client_Administrator_Email)
                          .employeeId;
                      assignmentRequestInner.assignment_Administrator
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.assignment_Administrator_Email)
                          .employeeId;
                      assignmentRequestInner.engagement_Partner
                        = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.engagement_Partner_Email)
                          .employeeId;

                      console.log('Updated Assignment Request ', assignmentRequestList);
                    }
                    const request = new AssignmentRequestList();
                    request.list = assignmentRequestList;
                    this.cmsService.PostPrimaryAssignment(request).subscribe(
                      assignmentResponse => {
                        const responseList = new AssignmentResponseList();
                        responseList.list = assignmentResponse;
                        console.log('Successfully submission of CMS Primary Assignment.', assignmentResponse);
                        this.workspaceService.storeCMSLog(responseList).subscribe(
                          logResult => {

                            for (const item of assignmentResponse) {
                              this.workspaceService
                                .updateFirstAssignmentStatus(item.responseStatus, item.assignmentCodeId)
                                .subscribe(
                                  statusResult => { console.log(statusResult); },
                                  error => {
                                    this.loadingScreenService.stopLoading();
                                    console.error('Error in updateFirstAssignmentStatus', error);
                                  }
                                );
                            }

                            console.log('storeCMSLog func response ', logResult);
                          },
                          logError => {
                            this.loadingScreenService.stopLoading();
                            console.error('Error in getting the response in storeCMSLog in Engage API ', logError);
                            this.workspaceService.storeErrorLog
                              ("WorkspaceId ::" + this.workspaceModel.workspaceId.toString() + " Message ::" + logError + "::" +
                                JSON.stringify(this.userInfo))
                              .subscribe(
                                result => { console.log(result); }
                              );

                          }

                        );
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        console.log('Error in getting the response in PostPrimaryAssignment in CMS Integration API ', error);
                      }
                    );
                  },
                  error => {
                    this.loadingScreenService.stopLoading();
                    console.error('Error in getting the response in GetEmployeeIdForUserList in Graph API ', error);
                  }
                );
              }
              else {
                this.loadingScreenService.stopLoading();
                var errorMsg = 'assignmentRequestList not found for workspace id: ' + this.workspaceModel.workspaceId.toString();
                console.error(errorMsg);
                this.workspaceService.storeErrorLog
                  ("WorkspaceId ::" + this.workspaceModel.workspaceId.toString() + " Message ::" + errorMsg + "::" +
                    JSON.stringify(this.userInfo))
                  .subscribe(
                    result => { console.log(result); }
                  );

              }
            },
            error => {
              this.loadingScreenService.stopLoading();
              console.error('Error in getting the response in getPrimaryAssignment in API ', error);
            });
        this.loadingScreenService.stopLoading();

      } else {
        if (result == 2) {
          const message = "Invalid case CMS Request cannot be sent.";
          console.error(message);
          this.workspaceService.storeErrorLog
            ("WorkspaceId ::" + this.workspaceModel.workspaceId + " Message ::" + message + "::" +
              JSON.stringify(this.userInfo))
            .subscribe(
              result => { console.log(result); }
            );

        } else if (result == 0) {
          this.isNewClientRequest = true;
        }
      }
    },
      err => {
        this.loadingScreenService.stopLoading();
        this.workspaceService.storeErrorLog("WorkspaceId ::" + this.workspaceModel.workspaceId + " Message ::" + err).subscribe
          (
            result => { console.log(result); }
          );
        console.error('Error in CheckRequestSentForClient ', err);
      },
      () => {
        console.log('call UploadDocumentToSharepoint on completion event');
        this.UploadDocumentToSharepoint();
      });

  }


  GetEngagementDetailsByworkspaceId(workspaceId: string) {
    return this._engService.GetEngagementDetailsByworkspaceId(workspaceId).subscribe(
      result => {
        this.currentEngagement = result;
        this.data.setSiteUniqueIDDetails(this.currentEngagement.siteUniqueID);
        this.initTheAuditPartnerPopup();
        this.loadMemberFirmDetails(this.currentEngagement.engagementId);
        // this.deleteAuditPartnerIfAnswerNo();
        if (this.currentEngagement.siteRequestStatus === null ||
          this.currentEngagement.siteRequestStatus === undefined ||
          this.currentEngagement.siteRequestStatus === '' ||
          this.currentEngagement.siteRequestStatus !== 'Completed') {
          this.isAbandonDisabled = true;
        }
        this.isEngagmentRPRuleEngineError = this.currentEngagement.status === EngagementStatus.EngRPRuleEngineError;
        if (this.isEngagmentRPRuleEngineError) {
          this.SetEngageSupportCanvasLink();
        }
        this.sharepointSiteURL = this.currentEngagement.siteUrl;
        console.log('currentEngagement ==>', this.currentEngagement);
        this._engService.GetEditEngagementCollabDetailsById(this.currentEngagement.engagementId).subscribe(
          collabDetails => {
            this.enagagementCollaborationDetails = collabDetails;
            if (this.enagagementCollaborationDetails.carryForwadINDUSConsent &&
              this.enagagementCollaborationDetails.isIndus &&
              this.isINDUSStaffingManagerLoggedIn &&
              this.currentEngagement.reacceptanceEngagementId != null &&
              this.currentEngagement.reacceptanceEngagementId > 0) {
              this.isReviewINDUSStaffingManagerHasAccess = true;
            }
            this.GetIndusFlagForReviewPageBySolutionCode(this.currentEngagement.parentSolutionCodeId);
            this.GetContinuanceEngagementValidationDetails();
            this.GetAllDocumentsfromSPLibrary(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath);
          }, error => {
            console.error('Error getting engagement collaboration', error);
          });
      },
      () => {
        this.currentEngagement = null;
      }
    );
  }

  bin2String(array) {
    var result = "";
    for (var i = 0; i < array.length; i++) {
      result += String.fromCharCode(parseInt(array[i], 2));
    }
    return result;
  }

  HightlightSection(option: string) {
    const supplementalDocuments = $('#supplemental-documents');
    const teamMembers = $('#team-members');
    const reviewingProfessionals = $('#reviewing-professionals');
    // const reviewNotes = $('#review-notes');
    const reviewNotesBadge = $('#review-notes-badge');
    const notificationOnlyProfessionals = $('#notification-only-professionals');
    const PermDocuments = $('#perm-library');
    const permlibrarysharepointurl = $('#perm-library-sharepointurl');
    const suplementDocumentSharepointUrl = $('#supplemental-document-sharepointurl');
    $('#clientErrorMessage').text('');


    // this.showHideAddReviewNote('hide');

    switch (option) {
      case 'supplemental-documents':
        teamMembers.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        reviewingProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotes.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-blue.svg');
        supplementalDocuments.attr('src', '../../../../assets/images/EDP/Workspace/folder_open-24px-white.svg');
        notificationOnlyProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotesBadge.removeClass('badge-primary');
        // supplementalSharepointDocuments.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo-white.svg');

        permlibrarysharepointurl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        suplementDocumentSharepointUrl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo-white.svg');
        PermDocuments.attr('src', '../../../assets/images/EDP/Workspace/perm-library-icon-blue.svg');
        break;
      case 'team-members':
        reviewingProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotes.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-blue.svg');
        supplementalDocuments.attr('src', '../../../../assets/images/EDP/Workspace/folder_open-24px-blue.svg');
        teamMembers.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-white.svg');
        notificationOnlyProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotesBadge.removeClass('badge-primary');
        //supplementalSharepointDocuments.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        permlibrarysharepointurl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        suplementDocumentSharepointUrl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        PermDocuments.attr('src', '../../../assets/images/EDP/Workspace/perm-library-icon-blue.svg');
        break
      case 'reviewing-professionals':
        // reviewNotes.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-blue.svg');
        supplementalDocuments.attr('src', '../../../../assets/images/EDP/Workspace/folder_open-24px-blue.svg');
        teamMembers.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        reviewingProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-white.svg');
        notificationOnlyProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        //  reviewNotesBadge.removeClass('badge-primary');
        //supplementalSharepointDocuments.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        permlibrarysharepointurl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        suplementDocumentSharepointUrl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        PermDocuments.attr('src', '../../../assets/images/EDP/Workspace/perm-library-icon-blue.svg');
        break;
      case 'notification-only-professionals':
        // reviewNotes.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-blue.svg');
        supplementalDocuments.attr('src', '../../../../assets/images/EDP/Workspace/folder_open-24px-blue.svg');
        teamMembers.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        reviewingProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        notificationOnlyProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-white.svg');
        //  reviewNotesBadge.removeClass('badge-primary');
        //supplementalSharepointDocuments.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        permlibrarysharepointurl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        suplementDocumentSharepointUrl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        PermDocuments.attr('src', '../../../assets/images/EDP/Workspace/perm-library-icon-blue.svg');
        break;
      case 'perm-library':
        teamMembers.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        reviewingProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotes.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-blue.svg');
        supplementalDocuments.attr('src', '../../../../assets/images/EDP/Workspace/folder_open-24px-blue.svg');
        notificationOnlyProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotesBadge.removeClass('badge-primary');


        //supplementalSharepointDocumentswhite.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo-white.svg');
        PermDocuments.attr('src', '../../../assets/images/EDP/Workspace/perm-library-icon-White.svg');
        permlibrarysharepointurl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo-white.svg');
        suplementDocumentSharepointUrl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        if ((this.currentEngagement.siteRequestStatus === 'Completed' || this.currentEngagement.siteRequestStatus === 'PartialCompleted') && !(this.currentEngagement.clientCode == '' || this.currentEngagement.clientCode == null)) {
          this.OpenNewTabForPermLibrary();
        }
        break;
      default:
        supplementalDocuments.attr('src', '../../../../assets/images/EDP/Workspace/folder_open-24px-white.svg');
        teamMembers.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        reviewingProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotes.attr('src', '../../../../assets/images/EDP/Workspace/library_add_check-24px-white.svg');
        notificationOnlyProfessionals.attr('src', '../../../../assets/images/EDP/Workspace/people_alt-24px-blue.svg');
        // reviewNotesBadge.addClass('badge-primary');
        permlibrarysharepointurl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        suplementDocumentSharepointUrl.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        PermDocuments.attr('src', '../../../assets/images/EDP/Workspace/perm-library-icon-blue.svg');
        // supplementalSharepointDocuments.attr('src', '../../../assets/images/EDP/Engagement/sharepoint-logo.svg');
        break;
    }
  }
  VerifyLoggedInUserWorkpaceRole() {
    this.isUserEP = false;
    this.isUserRP = false;
    this.isUserTM = false;
    // check  member status
    this.memberList.forEach(element => {
      if (element.memberEmailId.toLowerCase() === this.userInfo.userName.toLowerCase()) {
        if (element.isReviewer === 0 && element.teamMemberRole === EnumRoles.EngagementPartner) {
          this.isUserEP = true;
          this.isUserTM = true;
        } else if (element.isReviewer === 0 && element.teamMemberRole !== EnumRoles.EngagementPartner && element.teamMemberRole !== null) {
          this.isUserTM = true;
          this.isLoggedInUserCR_AA_CMSEP = false;
        }
      }
    });
    this.reviewerList.forEach(element => {
      if (element.memberEmailId?.toLowerCase() === this.userInfo.userName.toLowerCase()) {
        if (element.isReviewer === 1) {
          this.isUserRP = true;
          this.isLoggedInUserCR_AA_CMSEP = false;
        }
      }
    });
    //check workspace status and assign values to status variables to show appropriate button list.
    if (this.TeamReviewStatus === WorkspaceInterviewsStatus.EPReviewPending) {
      // set visbility statues based on combinations for Team Review Status
      if (this.isUserTM) {
        if (this.isUserEP) {
          this.TeamMemberButtonSectionVisible = true;
          this.EPPMDButtonSectionVisible = true;
          this.RPButtonSectionVisible = false;
          if (this.isUserRP) {
            this.RPButtonSectionVisible = false;
          }
        } else {
          this.TeamMemberButtonSectionVisible = true;
          this.RPButtonSectionVisible = false;
          this.EPPMDButtonSectionVisible = false;
          // make send and decline disabled.
        }
      } else if (this.isUserEP) {
        this.EPPMDButtonSectionVisible = true;
      } else if (this.isUserRP) {
        this.RPButtonSectionVisible = true;
      }

    } else if (this.EPPMDReviewStatus === WorkspaceInterviewsStatus.EPPMDReview) {
      // set visbility statues based on combinations for EPPMD Review Status
      if (this.isUserTM) {
        if (this.isUserEP) {
          this.TeamMemberButtonSectionVisible = false;
          this.EPPMDButtonSectionVisible = true;
          this.RPButtonSectionVisible = false;
          if (this.isUserRP) {
            this.RPButtonSectionVisible = true;
          }
        } else {
          // only team member
          this.TeamMemberButtonSectionVisible = true;
          this.EPPMDButtonSectionVisible = false;
          this.RPButtonSectionVisible = false;
        }
      } else if (this.isUserEP) {
        this.EPPMDButtonSectionVisible = true;
        this.TeamMemberButtonSectionVisible = false;
        this.RPButtonSectionVisible = false;
      } else if (this.isUserRP) {
        this.RPButtonSectionVisible = true;
        this.TeamMemberButtonSectionVisible = false;
        this.EPPMDButtonSectionVisible = false;
      }
    } else if (this.RPInReviewStatus === WorkspaceInterviewsStatus.RPInReview) {
      // set visbility statues based on combinations for RP Review Status
      if (this.isUserTM) {
        if (this.isUserEP) {
          this.TeamMemberButtonSectionVisible = false;
          this.EPPMDButtonSectionVisible = true;
          this.RPButtonSectionVisible = false;
          if (this.isUserRP) {
            this.RPButtonSectionVisible = true;
            this.EPPMDButtonSectionVisible = false;
            this.TeamMemberButtonSectionVisible = false;
          }
        } else {
          this.TeamMemberButtonSectionVisible = true;
          this.EPPMDButtonSectionVisible = false;
          this.RPButtonSectionVisible = false;
          // make send and decline disabled.
        }
      } else if (this.isUserEP) {
        this.EPPMDButtonSectionVisible = true;
        this.TeamMemberButtonSectionVisible = false;
        this.RPButtonSectionVisible = false;
      }
      //TO Superseed RP Role
      if (this.isUserRP) {
        this.RPButtonSectionVisible = true;
        this.EPPMDButtonSectionVisible = false;
        this.TeamMemberButtonSectionVisible = false;
      }
    }
    //Set Button Visibility For Admin 
    switch (this.workspaceModel.status) {
      case WorkspaceInterviewsStatus.EPReviewPending:
      case WorkspaceInterviewsStatus.EPPMDReview:
        if (!this.isUserTM && !this.isUserEP && this.isLoggedInUserSystemAdmin) {
          this.TeamMemberButtonSectionVisible = true;
          if (this.workspaceModel.status === WorkspaceInterviewsStatus.EPPMDReview) {
            this.isSendToEPPMDBtnDisable = true;
          }
        }
        break;
      case WorkspaceInterviewsStatus.RPInReview:
        if (!this.isUserTM && !this.isUserEP && this.isLoggedInUserSystemAdmin && !this.isUserRP) {
          this.TeamMemberButtonSectionVisible = true;
          this.isSendToEPPMDBtnDisable = true;
        } else if (!this.isUserEP && !this.isUserTM && this.isUserRP && this.isLoggedInUserSystemAdmin) {
          this.TeamMemberButtonSectionVisible = false;
          this.RPButtonSectionVisible = true;
        }
        break;
      default:
        break;
    }
  }

  displayConfirmationPopUp(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number,
    _dialogInput: string, IsAbandonModal: boolean, IsDeclineModal: boolean, bigTitle: boolean, engId: string = '0',
    loggedInUserEmail: string, reviewNoteIsFinalApproval: boolean = false, reviewNoteIsEP: boolean = false) {
    this.loadingScreenService.stopLoading();
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '',
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogAbandonModal: IsAbandonModal,
      dialogDeclineModal: IsDeclineModal,
      dialogBigTitle: bigTitle,
      dialogReviewNoteIsFinalApproval: reviewNoteIsFinalApproval,
      dialogReviewNoteIsEP: reviewNoteIsEP,
      engagementId: engId,
      loggedInUserEmail: loggedInUserEmail

    };
    const modalJSON = JSON.stringify(modalObj);
    this.data.displayDialogNotification(modalJSON);
  }

  isRPLastApprover() {
    this.workspaceService.IsLoggedInUserLastApprover(this.workspaceId, this.userInfo.userName).subscribe(
      res => {
      });
    return this.isUserLastRP;
  }
  isValidateSiteURL(siteURL: string) {
    return (siteURL !== null && siteURL !== undefined && siteURL !== '');
  }

  isValidOperation(status: string) {
    let retval: boolean = true;
    switch (status) {
      case WorkspaceInterviewsStatus.EditEngagement:
        if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Abandoned ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Declined ||
          this.isEngagmentRPRuleEngineError) {
          retval = false;
        }
        break;
      case WorkspaceInterviewsStatus.EditQuestionnaire:
      case WorkspaceInterviewsStatus.Abandoned:
      case WorkspaceInterviewsStatus.EPDeclinedTitle:
      case WorkspaceInterviewsStatus.RPDeclinedTitle:
        if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Abandoned ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Declined ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Approved) {
          retval = false;
        }
        break;
      case WorkspaceInterviewsStatus.SendToEPPMDTitle:
        if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Abandoned ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Declined ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.EPPMDReview ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Approved ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.RPInReview ||
          !this.hasSiteProvisioned ||
          this.isEngagmentRPRuleEngineError) {
          retval = false;
        }
        else {
          if (this._alertService.alerts.includes(this.alert)) {
            this._alertService.remove(this.alert);
          }
        }
        break;
      case WorkspaceInterviewsStatus.EPApprovedTitle:
        if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Abandoned ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.RPInReview ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Declined ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.EPReviewPending ||
          this.isEngagmentRPRuleEngineError) {
          retval = false;
        }
        break;
      case WorkspaceInterviewsStatus.RPApprovedTitle:
        if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Abandoned ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.Declined ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.EPReviewPending ||
          this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.EPPMDReview ||
          this.isEngagmentRPRuleEngineError) {
          retval = false;
        }
        break;
    }
    return retval;
  }

  isRPApprovedActionTriggered: boolean = false;

  workspaceActionButtonClick(action: string, isDeclineModal: boolean, isRPApprovedAction: boolean = false) {
    this.CloseReviewNotesPanel();
    if (this.currentEngagement.appliedTnC) {
      $('#reviewReponseNavItem').tab('show');
      this.QuestionSummaryDisplay();
    }
    this.loadingScreenService.startLoading();
    this.isRPApprovedActionTriggered = isRPApprovedAction;
    this.workspaceService.GetWorkspaceCurrentStatus(this.workspaceId).subscribe(
      result => {
        this.currentWorkSpaceStatus = result;
        console.log('GetWorkspaceCurrentStatus func called ', this.currentWorkSpaceStatus, result);

        if (action == WorkspaceInterviewsStatus.SendToEPPMDTitle) {
          this.HasSiteProvisionedForWorkspace(action, isDeclineModal);
        }
        else {
          // Validate button operation
          if (this.isValidOperation(action)) {
            if (this.CurrentWorkspaceData.status === WorkspaceInterviewsStatus.EPReviewPending) {
              this.CheckTemplateVersion(action, isDeclineModal);
            } else {
              this.proccedForOKButtonAction(action, isDeclineModal);
            }
          } else {
            this.doNotproccedForOKbuttonAction(false, action);
          }
          // }, 500);
        }
      },
      err => {
        console.log('Error in updating workspace status.', err);
        this.loadingScreenService.stopLoading();
      }
    );
  }

  workspaceCloseButtonClick() {
    this.router.navigate(['MyApproval']);
  }

  proccedForOKButtonAction(action: string, isDeclineModal: boolean) {
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    var buttonCount = 2;
    const dialogType = 'information';
    switch (action) {
      case WorkspaceInterviewsStatus.EditEngagement:
        this.ProcessEditEngagement(action, isDeclineModal);
        break;
      case WorkspaceInterviewsStatus.EditQuestionnaire:
        //Show popup iff new version is available or when stage is RP inreview
        setTimeout(() => {
          bigTitle = true;
          if (this.isEditQueNewVersion || this.hasAnyWorkpaceApproval) {
            dialogDesc = 'Editing the questionnaire will restart the approval process. All prior approvals will be removed and a new set of approvals will be required once the questionnaire is resubmitted. Do you want to continue?';
            this.displayConfirmationPopUp('warning', action, dialogDesc, buttonCount, dialogInput, false,
              isDeclineModal, bigTitle, '0', this.userInfo.loggedInUserEmail);
          } else {
            this.startProcessforEditQuestionnaire('ok');
          }
        }, 100);
        break;
      case WorkspaceInterviewsStatus.AbandonedTitle:
        bigTitle = true;
        dialogDesc = 'This cannot be undone. This engagement will be marked as “Abandoned” and documents will become read-only. The engagement will be removed from Engage after 90 days.';
        this.displayConfirmationPopUp('warning', action, dialogDesc, buttonCount, dialogInput, true,
          isDeclineModal, bigTitle, String(this.currentEngagement.engagementId), this.userInfo.loggedInUserEmail);
        break;
      case WorkspaceInterviewsStatus.SendToEPPMDTitle:
        if (this.workspaceDocumentCount > 1 && !this.selectedWorkspaceDocumentItem.isPrimaryWorkspaceId) {
          this.setPrimaryHotDocsTemplate(dialogType, action, isDeclineModal);
        }
        else {
          this.UnansweredQuestionCheckForApproval(dialogType, action, isDeclineModal);
        }
        break;
      case WorkspaceInterviewsStatus.RPDeclinedTitle:
      case WorkspaceInterviewsStatus.EPDeclinedTitle:
        bigTitle = false;
        dialogDesc = 'Declining this engagement acceptance/continuance will reject the engagement. This action cannot be undone. All related files will be retained as read-only and ultimately deleted according to firm data retention policies.';
        this.displayConfirmationPopUp('danger', action, dialogDesc, buttonCount, dialogInput, false,
          isDeclineModal, bigTitle, String(this.currentEngagement.engagementId), this.userInfo.loggedInUserEmail);
        break;
      case WorkspaceInterviewsStatus.EPApprovedTitle:
        if (this.workspaceDocumentCount > 1 && !this.selectedWorkspaceDocumentItem.isPrimaryWorkspaceId) {
          this.setPrimaryHotDocsTemplate(dialogType, action, isDeclineModal);
        }
        else {
          this.UnansweredQuestionCheckForApproval(dialogType, action, isDeclineModal);
        }
        break;
      case WorkspaceInterviewsStatus.RPApprovedTitle:
        this.UnansweredQuestionCheckForApproval(dialogType, action, isDeclineModal);
        break;
      case QuestionnaireDialogTitles.QueNewVersion:
        dialogDesc = 'This engagement questionnaire must be updated to the current version.  Previous responses will be preserved where possible.  Click OK to view the updated questionnaire and responses.';
        if (this.engStatus.status == "Team review" || this.engStatus.status == "EPPMD review"
          || this.engStatus.status == "RP InReview" || this.engStatus.status == "InReview"
        )
          this.displayConfirmationPopUp('warning', QuestionnaireDialogTitles.QueNewVersion, dialogDesc,
            1, '', false, false, bigTitle, '0', this.userInfo.loggedInUserEmail);
        break;
      case QuestionnaireDialogTitles.UnAnsQuestions:
        dialogDesc = 'There are unanswered questions. Approvals cannot be obtained until all questions are answered. Click OK to continue.';
        var type = 'warning';
        buttonCount = 2;
        if (this.currentWorkSpaceStatus === WorkspaceInterviewsStatus.RPInReview ||
          this.currentWorkSpaceStatus !== WorkspaceInterviewsStatus.EPReviewPending
        ) {
          type = 'danger';
          buttonCount = 1;
          dialogDesc = 'There are unanswered questions. You cannot approve an engagement until all questions have been answered.';
        }
        this.displayConfirmationPopUp(type, QuestionnaireDialogTitles.UnAnsQuestions, dialogDesc, buttonCount, '',
          false, false, bigTitle, '0', this.userInfo.loggedInUserEmail);
        break;
      default:
        console.log('Default No button Action');
        break;
    }
  }

  setPrimaryHotDocsTemplate(dialogType: string, action: string, isDeclineModal: boolean) {
    console.log('setPrimaryHotDocsTemplate', this.selectedWorkspaceDocumentItem);
    this.UnansweredQuestionCheckLoaderToken = setInterval(() => this.loadingScreenService.startLoading(), 1);
    this.UnansweredQuestionCheckInProgress = true;
    this.UnansweredQuestionCheckDialogType = dialogType;
    this.UnansweredQuestionCheckAction = action;
    this.isDeclineModal = isDeclineModal;
    this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.filter(document => document.isPrimaryWorkspaceId == true)[0];
    $('#ddWorkspaceDocument').val(this.selectedWorkspaceDocumentItem.workspaceDocumentId);
    this.tempWorkspaceId = this.selectedWorkspaceDocumentItem.workspaceId;
    this.PreviewDocument(this.selectedWorkspaceDocumentItem.workspaceId, this.selectedWorkspaceDocumentItem.documentId, this.selectedWorkspaceDocumentItem.workItemId);
    this.QuestionSummaryDisplay();
  }

  UnansweredQuestionCheckForApproval(dialogType: string, action: string, isDeclineModal: boolean) {
    this.loadingScreenService.startLoading();
    //adding timeout to load HD session
    setTimeout(() => {
      //Check if any unanswered questions are present if yes then display warning
      let unansweredResultSet = HD$;
      let unansweredQnACount: number = 0;
      //if undefined HD$ then display warning 
      if (unansweredResultSet == undefined && unansweredResultSet == null) {
        this.proccedForOKButtonAction(QuestionnaireDialogTitles.UnAnsQuestions, false);
      } else { // else Check unanswered count and process action
        unansweredResultSet = HD$.GetProgress();
        unansweredQnACount = unansweredResultSet.Total - unansweredResultSet.Answered;
        if (action === WorkspaceInterviewsStatus.EPApprovedTitle) {
          if (unansweredQnACount > 0) {
            this.proccedForOKButtonAction(QuestionnaireDialogTitles.UnAnsQuestions, false);
          } else {
            if (this.showAuditPopup && !this.currentEngagement.appliedTnC) {
              this.loadingScreenService.stopLoading();
              this.showAuditPartnerPopupDialog("RP");

            } else
              this.UpdateDecisionsRPList(dialogType, action, isDeclineModal);
          }
        } else if (action === WorkspaceInterviewsStatus.SendToEPPMDTitle) {
          if (unansweredQnACount > 0) {
            this.proccedForOKButtonAction(QuestionnaireDialogTitles.UnAnsQuestions, false);
          } else {
            if (this.showAuditPopup && !this.currentEngagement.appliedTnC) {
              this.loadingScreenService.stopLoading();
              this.showAuditPartnerPopupDialog("EP");

            } else
              this.setSendToEPPMDDialog();
          }
        }
        else if (action === WorkspaceInterviewsStatus.RPApprovedTitle) {
          if (unansweredQnACount > 0) {
            this.proccedForOKButtonAction(QuestionnaireDialogTitles.UnAnsQuestions, false);
          } else {
            this.preRequisiteCheckAndDisplayConfirmationPopUpForRPApproved(dialogType, action, isDeclineModal);
          }
        }
      }
    }, 5000);
  }

  setSendToEPPMDDialog() {
    var dialogDesc = 'Based on the information gathered and the preliminary evaluation of engagement risk, this engagement is ready for review by the Engagement PPMD.';
    this.displayConfirmationPopUp('information', WorkspaceInterviewsStatus.SendToEPPMDTitle, dialogDesc, 2,
      '', false, false, false, '0', this.userInfo.loggedInUserEmail);
  }

  preRequisiteCheckAndDisplayConfirmationPopUpForEPApproved(dialogType: string, action: string, isDeclineModal: boolean) {
    this.loadingScreenService.stopLoading();
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    let buttonCount = 2;
    this.OpenReviewCount = 0;

    this.workspaceReviewPointService.getWorkspaceReviewPoints(this.workspaceId).subscribe(
      result => {
        this.workspaceReviewPoints = result;
        let retval: number = 0;
        $.map(this.workspaceReviewPoints, function (elementOfArray) {
          if (elementOfArray.status == 1) {
            retval = retval + 1;
          }
        });
        this.OpenReviewCount = retval;

        this.workspaceService.IsLoggedInUserLastApprover(this.workspaceId, this.userInfo.userName).subscribe(isLastApprover => {
          this.isLoggedInUserFinalApprover = Boolean(isLastApprover);

          if (this.isUserEP) {
            dialogDesc = 'Based on the information gathered and the evaluation of engagement risk, it is recommended that this engagement be accepted/approved by the firm. I certify that all questions have been answered appropriately and all documentation is complete and ready for review by any Reviewing Professionals, if applicable.';
          } else if (this.isUserRP) {
            dialogDesc = 'Based on the information reviewed and my evaluation of engagement risk, I approve that this engagement be accepted by the firm.'
          }

          if (this.isLoggedInUserFinalApprover) {
            if (this.OpenReviewCount === 1) {
              dialogType = 'danger';
              action = WorkspaceInterviewsStatus.OpenReviewNotes;
              buttonCount = 1;
              dialogDesc = 'There is one open review note. Before final engagement approval, all review notes must be resolved.';
            } else if (this.OpenReviewCount > 1) {
              dialogType = 'danger';
              action = WorkspaceInterviewsStatus.OpenReviewNotes;
              buttonCount = 1;
              dialogDesc = 'There are ' + this.OpenReviewCount + ' review notes. Before final engagement approval, all review notes must be resolved.';
            }
          }
          else if (this.OpenReviewCount > 0) {

            dialogType = 'warning';
            action = WorkspaceInterviewsStatus.OpenReviewNotes;
            buttonCount = 2;

            if (this.OpenReviewCount === 1) {
              //dialogDesc = 'You are approving this with open review notes (one open review note). Before final RP approval all review notes must be resolved.';
              dialogDesc = 'You are approving this with one open review note. Before final RP approval all review notes must be resolved.';
            } else if (this.OpenReviewCount > 1) {
              //dialogDesc = `You are approving this with open review notes (${this.OpenReviewCount} open review notes). Before final RP approval all review notes must be resolved.`;
              dialogDesc = 'You are approving this with open review notes. Before final RP approval all review notes must be resolved.';
            }
          }

          this.displayConfirmationPopUp(dialogType, action, dialogDesc, buttonCount, dialogInput, false,
            isDeclineModal, bigTitle, '0', this.userInfo.loggedInUserEmail, this.isLoggedInUserFinalApprover, true);
        });
      },
      () => {
        this.workspaceReviewPoints = null;
      }
    )
  }

  preRequisiteCheckAndDisplayConfirmationPopUpForRPApproved(dialogType: string, action: string, isDeclineModal: boolean) {
    if (this.isUserBeingRemoveFinalApprover && !this.isRPApprovedActionTriggered) {
      this.initinateProcessforApprove(WorkspaceInterviewsStatus.RPApprovedTitle, true);
    }
    else {

      let dialogDesc: string = '';
      let bigTitle: boolean = false;
      let dialogInput = '';
      this.OpenReviewCount = 0;
      let buttonCount = 2;
      let dialogAction = action;
      this.workspaceReviewPointService.getWorkspaceReviewPoints(this.workspaceId).subscribe(
        result => {
          this.workspaceReviewPoints = result;
          let retval: number = 0;
          $.map(this.workspaceReviewPoints, function (elementOfArray) {
            if (elementOfArray.status == ReviewNoteStatus.Open) {
              retval = retval + 1;
            }
          });
          this.OpenReviewCount = retval;
          this.workspaceService.IsLoggedInUserLastApprover(this.workspaceId, this.userInfo.userName).subscribe(
            res => {
              this.isLoggedInUserFinalApprover = Boolean(res);
              if (this.isLoggedInUserFinalApprover) { // Final RP Case
                if (this.OpenReviewCount === 0) {
                  dialogDesc = 'Based on the information reviewed and my evaluation of engagement risk, I approve that this engagement be accepted by the firm.';
                } else if (this.OpenReviewCount === 1) {
                  dialogType = 'danger';
                  dialogAction = WorkspaceInterviewsStatus.OpenReviewNotes;
                  buttonCount = 1;
                  dialogDesc = 'There is one open review note. As the final Reviewing Professional you must ensure all review notes are resolved prior to approval.';
                } else if (this.OpenReviewCount > 1) {
                  dialogType = 'danger';
                  dialogAction = WorkspaceInterviewsStatus.OpenReviewNotes;
                  buttonCount = 1;
                  dialogDesc = 'There are ' + this.OpenReviewCount + ' open review notes. As the final Reviewing Professional you must ensure all review notes are resolved prior to approval';
                }
              } else {  // Not Final RP Case
                if (this.OpenReviewCount === 0) {
                  dialogDesc = 'Based on the information reviewed and my evaluation of engagement risk, I approve that this engagement be accepted by the firm.';
                } else if (this.OpenReviewCount === 1) {
                  dialogType = 'warning';
                  dialogAction = WorkspaceInterviewsStatus.OpenReviewNotes;
                  //dialogDesc = 'You are approving this with open review notes (1 open review note). Before final RP approval all review notes must be resolved.';
                  dialogDesc = 'You are approving this with one open review note. Before final RP approval all review notes must be resolved.';
                } else if (this.OpenReviewCount > 1) {
                  dialogType = 'warning';
                  dialogAction = WorkspaceInterviewsStatus.OpenReviewNotes;
                  //dialogDesc = `You are approving this with open review notes (${this.OpenReviewCount} open review notes). Before final RP approval all review notes must be resolved.`;
                  dialogDesc = 'You are approving this with open review notes. Before final RP approval all review notes must be resolved.';
                }
              }
              this.displayConfirmationPopUp(dialogType, dialogAction, dialogDesc, buttonCount, dialogInput, false, isDeclineModal,
                bigTitle, '0', this.userInfo.loggedInUserEmail, this.isLoggedInUserFinalApprover, false);
            },
            error => {
              this.loadingScreenService.stopLoading();
            });
        },
        error => {
          this.workspaceReviewPoints = null;
          this.loadingScreenService.stopLoading();
        }
      );
    }
  }

  processApproval(isEP: boolean) {

    this.loadingScreenService.startLoading();

    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    let buttonCount = 2;
    let dialogType: string = 'information';
    let dialogAction: string;
    let isDeclineModal: boolean = false;

    if (isEP) {
      dialogAction = WorkspaceInterviewsStatus.EPApprovedTitle;
      if (this.isUserEP) {
        dialogDesc = 'Based on the information gathered and the evaluation of engagement risk, it is recommended that this engagement be accepted/approved by the firm. I certify that all questions have been answered appropriately and all documentation is complete and ready for review by any Reviewing Professionals, if applicable.';
      } else if (this.isUserRP) {
        dialogDesc = 'Based on the information reviewed and my evaluation of engagement risk, I approve that this engagement be accepted by the firm.';
      }
    }
    else {
      dialogAction = WorkspaceInterviewsStatus.RPApprovedTitle;
      dialogDesc = 'Based on the information reviewed and my evaluation of engagement risk, I approve that this engagement be accepted by the firm.';
    }

    setTimeout(() => {
      this.loadingScreenService.stopLoading();
      this.displayConfirmationPopUp(dialogType, dialogAction, dialogDesc, buttonCount, dialogInput, false, isDeclineModal,
        bigTitle, '0', this.userInfo.loggedInUserEmail, false, false);
    }, 1000);
  }

  doNotproccedForOKbuttonAction(isDeclineModal: boolean, action: string) {
    this.loadingScreenService.stopLoading();
    if (WorkspaceInterviewsStatus.SendToEPPMDTitle == action && !this.hasSiteProvisioned) {
      this.alert.message = "The collaboration space must be created before review and approval can proceed. Please retry in a few minutes before contacting support.";
      this.alert.messageType = "danger";
      this.alert.debug = null;
      if (this._alertService.alerts.includes(this.alert)) {
        this._alertService.remove(this.alert);
      }
      this._alertService.alerts.push(this.alert);
    }
    else {
      if (this._alertService.alerts.includes(this.alert)) {
        this._alertService.remove(this.alert);
      }
      let dialogDesc: string = '';
      let bigTitle: boolean = false;
      const dialogType = 'danger';
      dialogDesc = 'The status of this engagement changed during your review.  Click OK to view the most current information.';
      this.router.navigate(['Review/' + this.workspaceId]);
      this.displayConfirmationPopUp(dialogType, 'Review Status', dialogDesc, 1, '', false, isDeclineModal, bigTitle, '0',
        this.userInfo.loggedInUserEmail);
    }
  }

  displayLabelForDisabledButton() {
    if (this.TeamReviewStatus === WorkspaceInterviewsStatus.EPReviewPending) {
      this.memberList.forEach(element => {
        if (element.isReviewer === 0 && element.teamMemberRole !== EnumRoles.EngagementPartner &&
          element.teamMemberRole !== null) {
          if (
            element.approvalStatus === WorkspaceInterviewsStatus.Approved) {
            this.isSendToEPPMDBtnDisable = true;
          }
        }
      });
      this.reviewerList.forEach(element => {
        if (element.memberEmailId.toLowerCase() === this.userInfo.userName.toLowerCase() && element.isReviewer === 1) {
          this.isRPApprovedBtnDisable = true;
        }
      });
    }
    if (this.EPPMDReviewStatus === WorkspaceInterviewsStatus.EPPMDReview) {
      this.memberList.forEach(element => {
        if (element.teamMemberRole === EnumRoles.EngagementPartner && element.approvalStatus === WorkspaceInterviewsStatus.Approved) {
          this.isEPPMDApproveBtnDisable = true;
        }
        if (element.isReviewer === 0 && element.teamMemberRole !== EnumRoles.EngagementPartner &&
          element.teamMemberRole !== null) {
          this.isSendToEPPMDBtnDisable = true;
        }
      });
      this.reviewerList.forEach(element => {
        if (element.memberEmailId.toLowerCase() === this.userInfo.userName.toLowerCase()) {
          if (element.isReviewer === 1) {
            this.isRPApprovedBtnDisable = true;
          }
        }
      });
    }
    if (this.RPInReviewStatus === WorkspaceInterviewsStatus.RPInReview) {
      this.memberList.forEach(element => {
        if (element.teamMemberRole === EnumRoles.EngagementPartner) {
          this.isEPPMDApproveBtnDisable = true;
        }
        if (element.isReviewer === 0 && element.teamMemberRole !== EnumRoles.EngagementPartner &&
          element.teamMemberRole !== null) {
          this.isSendToEPPMDBtnDisable = true;
        }
      });

      if (this.reviewerList != null && this.reviewerList && this.reviewerList.length > 0) {
        if (this.reviewerList.filter(member =>
          member.isReviewer == 1
          && member.memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()
          && member.approvalStatus.toUpperCase() == WorkspaceInterviewsStatus.Pending.toUpperCase()
        ).length > 0) {
          this.isRPApprovedBtnDisable = false;
        }
        else {
          this.isRPApprovedBtnDisable = true;
        }
      }

    }
    if (this.workspaceModel.status === WorkspaceInterviewsStatus.Declined) {
      this.memberList.forEach(element => {
        if (element.approvalStatus === WorkspaceInterviewsStatus.Declined) {
          this.isEPPMDDecline = true;
        }
        if (element.isReviewer === 0 && element.teamMemberRole !== EnumRoles.EngagementPartner &&
          element.teamMemberRole !== null) {
          this.shouldNotifyEPPMD = true;
        }
      });
      this.reviewerList.forEach(element => {
        if (element.approvalStatus === WorkspaceInterviewsStatus.Declined) {
          this.isRPDecline = true;
        }
      });
    } // end if WorkspaceInterviewsStatus.Declined
    if (this.workspaceModel.status === WorkspaceInterviewsStatus.Abandoned) {
      this.memberList.forEach(element => {
        if (element.teamMemberRole === EnumRoles.EngagementPartner) {
          this.isEPPMDAbandon = true;
        } else if (element.isReviewer === 0 && element.teamMemberRole !== EnumRoles.EngagementPartner && element.teamMemberRole !== null) {
          this.isTMAbandon = true;
          this.shouldNotifyEPPMD = true;
        }
      });
    }
  }

  workspaceHasAnyApproval() {
    switch (this.workspaceModel.status) {
      case WorkspaceInterviewsStatus.EPReviewPending:
      case WorkspaceInterviewsStatus.EPPMDReview:
        this.hasAnyWorkpaceApproval = false;
        break;
      case WorkspaceInterviewsStatus.RPInReview:
        this.hasAnyWorkpaceApproval = true;
        break;
      default:
        break;
    }
  }


  CheckTemplateVersion(action, isDeclineModal) {
    this.CurrentWorkspaceData.setAnsFlag = WorkspaceInterviewsStatus.CompleteSession;
    this.hdService.checkTemplateVersion(this.CurrentWorkspaceData).subscribe(result => {
      if (result && (this.isUserEP || this.isUserTM || this.isLoggedInUserSystemAdmin)) {
        switch (action) {
          case WorkspaceInterviewsStatus.EditQuestionnaire:
            this.isEditQueNewVersion = true;
            this.proccedForOKButtonAction(action, isDeclineModal);
            break;
          case WorkspaceInterviewsStatus.SendToEPPMD:
          case WorkspaceInterviewsStatus.EPDeclinedTitle:
          case WorkspaceInterviewsStatus.Abandoned:
          case WorkspaceInterviewsStatus.EPApprovedTitle:
          case 'onLoad':
            this.isEditQueNewVersion = false;
            this.isWorkspaceScreen = true;
            this.proccedForOKButtonAction(QuestionnaireDialogTitles.QueNewVersion, isDeclineModal);
            break;
          default:
            this.proccedForOKButtonAction(action, isDeclineModal);
            break;
        }
      }
      else {
        this.ValidateHdTemplateCache();
        this.proccedForOKButtonAction(action, isDeclineModal);
      }
    }, error => {
      this.loadingScreenService.stopLoading();
      this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
    });
  }
  UpdateTemplateVersionData() {
    this.data.setNewVersionDialogVisibility(false);
    this.NavigateToInterview();
  }

  ProcessEditEngagement(action, isDeclineModal) {
    this.loadingScreenService.stopLoading();
    this.workspaceService.getMembers(this.workspaceId).subscribe(
      memberResult => {
        this.memberList = memberResult;
        console.log(this.memberList.length);
        console.log(this.memberList);
        if (this.memberList.length > 0) {
          for (var i in this.memberList) {
            if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
              this.idLoggedInUserHasWorkspaceAccess = true;
              this.IsLoggedInUserIsTM_EP = true;
              console.log('have Access', this.idLoggedInUserHasWorkspaceAccess);
              break;
            }
          }
        }

        // calling get reviewers to avoid multiple parallel async calls.
        // this.reviewersListForRP = [];
        // this.workspaceService.getReviewers(this.workspaceId).subscribe(
        //   reviewerResult => {
        //     this.reviewerList = reviewerResult;
        //     this.ConfigureReviewNoteSearch();
        //     if (this.reviewerList.length > 0) {
        //       for (var i in this.reviewerList) {
        //         if (this.reviewerList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
        //           this.idLoggedInUserHasWorkspaceAccess = true;
        //           console.log('have Access', this.idLoggedInUserHasWorkspaceAccess);
        //           break;
        //         }
        //       }
        //       for (var j = 0, length = this.reviewerList.length; j < length; j++) {
        //         this.newReviewer = {
        //           id: this.reviewerList[j].id,
        //           workspaceId: this.reviewerList[j].workspaceId,
        //           memberName: this.reviewerList[j].memberName,
        //           teamMemberRole: this.reviewerList[j].teamMemberRole,
        //           isReviewer: this.reviewerList[j].isReviewer,
        //           roleId: this.reviewerList[j].roleId,
        //           createdBy: this.reviewerList[j].createdBy,
        //           createdOn: this.reviewerList[j].createdOn,
        //           updatedBy: this.reviewerList[j].updatedBy,
        //           updatedOn: this.reviewerList[j].updatedOn,
        //           memberEmailId: this.reviewerList[j].memberEmailId,
        //           memeberContactNo: this.reviewerList[j].memeberContactNo,
        //           approvalStatus: this.reviewerList[j].approvalStatus,
        //           oldreviewerName: this.reviewerList[j].oldreviewerName,
        //           loggedInUserFullName: this.reviewerList[j].loggedInUserFullName,
        //           isLoggedInUserhasTransferButtonVisible: (this.reviewerList[j].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase() || this.isLoggedInUserSystemAdmin),
        //           jobTitle: this.reviewerList[j].jobTitle
        //         }
        //         this.reviewersListForRP.push(this.newReviewer);
        //       }
        //     }
        //   },
        //   () => {
        //     this.reviewerList = null;
        //   }
        // );

        if (this.isEditQueNewVersion) {
          this.data.setNewVersionDialogVisibility(false);
        }

        switch (this.currentWorkSpaceStatus) {
          case WorkspaceInterviewsStatus.EPReviewPending:
          case WorkspaceInterviewsStatus.EPPMDReview:
            if (this.memberList.length > 0 && (this.idLoggedInUserHasWorkspaceAccess || this.isLoggedInUserSystemAdmin)) {
              this.router.navigate(['EditEngagement/' + this.CurrentWorkspaceData.engagementId]);
            }
            break;
          case WorkspaceInterviewsStatus.RPInReview:
            if (this.memberList.length > 0 && (this.idLoggedInUserHasWorkspaceAccess || this.isLoggedInUserSystemAdmin)) {
              //Show popup for edit engagement confirmation
              let dialogDesc = '', buttonCount = 2, dialogInput = '';
              let bigTitle: boolean = false;
              dialogDesc = 'Editing engagement information will restart the approval process. Upon completion of the edit, all existing approvals will be removed and a new set of approvals will be required.  Do you want to continue?';
              this.displayConfirmationPopUp('warning', action, dialogDesc, buttonCount, dialogInput, false, isDeclineModal,
                bigTitle, '0', this.userInfo.loggedInUserEmail);
            }
            break;
        }
      },
      () => {
        this.memberList = null;
        this.ConfigureReviewNoteSearch();
      }
    );


  }

  UploadDocumentToSharepoint() {
    var documentUploadInterval = setInterval(() => { this.loadingScreenService.startLoading(); }, 1);

    this.sharepointSiteDocumentModel.files = [];
    this.sharepointSiteDocumentModel.documentType = 'Acceptance';
    this.sharepointSiteDocumentModel.documentFolderPath = this.currentEngagement.acceptanceFolderPath;
    this.sharepointSiteDocumentModel.siteURL = this.currentEngagement.siteUrl;
    this.sharepointSiteDocumentModel.useremailId = this.userInfo.loggedInUserEmail;

    this.hdService.GetDocumentsByteArray(this.workspaceDocumentList.map(doc => doc.workItemId)).subscribe(spDocumentsResponse => {

      if (this.currentEngagement.appliedTnC && spDocumentsResponse?.length > 0) {
        let primaryDocument = this.workspaceDocumentList.filter(document => document.isPrimaryWorkspaceId)[0];
        let primaryDocumentByteArrayResponse = spDocumentsResponse.filter(document => document.workItemId == primaryDocument.workItemId)[0];
        let FileByteArrayStr = String(primaryDocumentByteArrayResponse.fileByteArray);
        let bin = atob(FileByteArrayStr);

        let bytes = [];
        for (let i = 0; i < bin.length; i++) {
          bytes.push(bin.charCodeAt(i));
        }

        //In case of AppliedTnC change name to documentName_Revised
        let documentName: string = `${this.workspaceDocumentList.filter(doc => doc.workItemId == primaryDocument.workItemId)[0]?.documentAliasName}.docx`;
        if (documentName != null && documentName != '') {
          documentName = documentName.substr(0, documentName.lastIndexOf('.')) + " Revised" + documentName.substr(documentName.lastIndexOf('.'), documentName.length);
        }

        this.sharepointSiteDocumentModel.files.push({
          fileName: documentName,
          fileByteArray: bytes
        });
      }
      else {
        spDocumentsResponse.forEach(document => {

          let FileByteArrayStr = String(document.fileByteArray);
          let bin = atob(FileByteArrayStr);

          let bytes = [];
          for (let i = 0; i < bin.length; i++) {
            bytes.push(bin.charCodeAt(i));
          }

          let documentName: string = `${this.workspaceDocumentList.filter(doc => doc.workItemId == document.workItemId)[0]?.documentAliasName}.docx`;

          this.sharepointSiteDocumentModel.files.push({
            fileName: documentName,
            fileByteArray: bytes
          });

        });
      }

      // call Sharepoiint API
      this._sharepointService.AddDocumentsToLibrary(this.sharepointSiteDocumentModel).subscribe(docResult => {
        clearInterval(documentUploadInterval);
        this.loadingScreenService.startLoading();
      },
        error => {
          clearInterval(documentUploadInterval);
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });

    },
      error => {
        clearInterval(documentUploadInterval);
        this.loadingScreenService.stopLoading();
        this.workspaceService.storeErrorLog("WorkspaceId ::" + this.workspaceModel.workspaceId + " Message ::" + error).subscribe
          (
            result => { console.log(result); }
          );
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      },
      () => {
        // On complete of the subscription         
        console.log('After the upload of HotDocs document ');
        this.generateMultipleSummaryReports(0);
      });

    //Independant call to send notifications
    this.GetDecisionsNotificationPayload(this.engagementId, NotificationOnlyRemoveRemarks.RPApproved, true);
  }

  displayNewClientRequest() {

    const modalObj = {
      dialogType: 'information',
      dialogTitle: 'New client request',
      dialogDescription: 'An existing engagement is already in the process of requesting a client assignment code for this new client. '
        + 'Subsequent code requests will be processed in the order received.',
      dialogRedirectUrl: '/MyEngagements',
      dialogButton: 1,
      modalDisplay: true
    };
    const modalJSON = JSON.stringify(modalObj);
    this.data.displayDialogNotification(modalJSON);
  }
  redirectToMyEngagements() {
    sessionStorage.removeItem('isHardRefreshPageReloadedW');
    this.router.navigate(['/MyEngagements']);
  }

  uploadSupplementalFileToSharepoint(filename: string, fileByteArray: any) {
    this.spDocument = {
      fileName: filename,
      fileByteArray: fileByteArray,
    };

    this.sharepointSiteDocumentModel.files = [];
    this.sharepointSiteDocumentModel.files[0] = this.spDocument;
    this.sharepointSiteDocumentModel.documentType = 'Supplemental';
    this.sharepointSiteDocumentModel.documentFolderPath = this.currentEngagement.supplementalFolderPath;
    this.sharepointSiteDocumentModel.siteURL = this.currentEngagement.siteUrl
    this.sharepointSiteDocumentModel.useremailId = this.userInfo.userName;

    // call Sharepoiint API
    this._sharepointService.AddDocumentsToLibrary(this.sharepointSiteDocumentModel).subscribe(
      result => {
        const strResult = result;
        console.log("strResult==>", strResult);
        if (strResult.toLowerCase().trim() === 'success') {
          this.GetAllDocumentsfromSPLibraryForSuppl(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath);
        }
      },
      error => {
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        this.clearSharepointFileUploadData();
      });
  }

  onSelectFile(Files: FileList) {
    this.uploadFileValidationError = false;
    let fileToUpload = Files[0];
    this.currentfileName = fileToUpload.name;
    let isSpecialCharFound = this.uploadFileNameFormat.test(this.currentfileName)
    if (fileToUpload.size > this.uploadFileSize || isSpecialCharFound) {
      this.uploadFileValidationError = true;
      $("#input-file").val('');
      return true;
    }
    let intArrayObj;
    intArrayObj = new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result)
      };
      fr.readAsArrayBuffer(fileToUpload);
    }).then(
      (result: ArrayBuffer) => {
        $("#uploadfileDiv").removeClass("d-none");
        $("#input-file").attr('disabled', 'disabled');
        $("#fileuploadlbl").removeClass('cursor-pointer');
        $('input[type=file]').attr('disabled', true);
        $('#fileuploadlbl, [for="fileuploadlbl"]').addClass('disabled');
        $("#modal-SharepointFileLabel").modal('hide');
        intArrayObj = new Uint8Array(result);
        this.uploadSupplementalFileToSharepoint(fileToUpload.name, Array.from(intArrayObj));
        $("#input-file").val('');
      }
    );

  }

  GetAllDocumentsfromSPLibrary(siteURL: string, documentFolderPath: string) {
    this.loadingScreenService.startLoading();
    this._sharepointService.getAllDocumentsfromSPLibrary(siteURL, documentFolderPath).subscribe(
      result => {
        this.supplementalDocumentList = result;
        this.attacheLabelToFiles();
        console.log(' this.supplementalDocumentList =>', this.supplementalDocumentList);
        this.loadingScreenService.stopLoading();
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  GetAllDocumentsfromSPLibraryForSuppl(siteURL: string, documentFolderPath: string) {
    this._sharepointService.getAllDocumentsfromSPLibrary(siteURL, documentFolderPath).subscribe(
      result => {
        this.supplementalDocumentList = result;
        if (this.sharePointAddFileFlag) {
          this.prepareSharepointFileLabelForNewFile();
        } else {
          this.attacheLabelToFiles();
        }
        console.log(' this.supplementalDocumentList =>', this.supplementalDocumentList);
        $('#uploadfileDiv').addClass('d-none');
        $('#input-file').removeAttr('disabled');
        $("#fileuploadlbl").addClass('cursor-pointer');
        $('#fileuploadlbl, [for="fileuploadlbl"]').addClass('enabled').removeClass('disabled');
      },
      error => {
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  confirmModalRemovSuppDoc(document: SPDocumentList) {
    this.CloseReviewNotesPanel();
    this.currentDocument = document;
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    const dialogType = 'information';
    const action = 'Remove document';
    dialogDesc = 'Are you sure you want to delete this file ?';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false,
      bigTitle, '0', this.userInfo.loggedInUserEmail);
  }

  RemoveSupplDoc() {
    this.loadingScreenService.startLoading();
    let workspace = new WorkspaceSupplementalDoc()
    workspace.workspaceId = this.currentEngagement.primaryWorkspaceId;
    workspace.sourceFilePath = this.currentDocument.documentUrl;
    workspace.sourceFileName = this.currentDocument.documentName

    this._sharepointService.deleteDocument(this.currentDocument.siteURL, this.currentDocument.documentUrl).subscribe(
      result => {
        const strResult = result;
        console.log("strResult==>", strResult);
        if (strResult.toLowerCase().trim() === 'success') {
          this.deleteSharepointFileLabel(workspace);
          this._sharepointService.getAllDocumentsfromSPLibrary(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath)
            .subscribe(
              docResult => {
                this.supplementalDocumentList = docResult;
                this.attacheLabelToFiles();
                console.log('this.supplementalDocumentList =>', this.supplementalDocumentList);
                this.loadingScreenService.stopLoading();
              },
              error => {
                this.DisplaySystemAlert();
                this.applyFilterOnceAfterDataLoad = false;
              });
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      })
  }

  deleteSharepointFileLabel(workspace: WorkspaceSupplementalDoc) {
    this.workspaceService.DeleteSharepointFile(workspace).subscribe(result => {
      const strResult = result;
      console.log("strResult==>", strResult);
    }, err => {
      console.error("Unable to delete sharepoint file label.", err);

    });
  }


  createAndUpdatePermissionsModel(editedWorkspaceMembers: TeamMember[], siteUniqueID: string) {

    // Get List of Groups based on Permission Drop down(GROUP By) //
    let siteMembersGroups = editedWorkspaceMembers.reduce((r, a) => {
      r[a.teamMemberPermissionId] = [...r[a.teamMemberPermissionId] || [], a];
      return r;
    }, {});

    console.log("siteMembersGroups", siteMembersGroups);
    let onwwerList: TeamMember[] = [];
    let memberList: TeamMember[] = [];
    let siteGroupTypes = Object.keys(siteMembersGroups);

    siteGroupTypes.forEach(type => {
      if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
        onwwerList = editedWorkspaceMembers.filter(member =>
          Number(member.teamMemberPermissionId) === Number(type));
      } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
        memberList = editedWorkspaceMembers.filter(member =>
          Number(member.teamMemberPermissionId) === Number(type));
      }
    })
    // Collect site onwer email List //
    let siteOwnerEmailIds: string[] = [];
    onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

    // Collect site member email List //
    let siteMemberEmailIds: string[] = [];
    memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

    let EngagementSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
    let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
    let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();
    let updatePermissionsModelList: UpdatePermissionsModel[] = []

    OwnerListEmailModel.groupType = 'Owner';
    OwnerListEmailModel.groupUsersEmail = [];
    siteOwnerEmailIds.forEach(emailid => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = '';
      adUser.lastName = '';
      adUser.userEmailId = emailid;
      OwnerListEmailModel.groupUsersEmail.push(adUser);
    })

    MemberListEmailModel.groupType = 'Member';
    MemberListEmailModel.groupUsersEmail = [];
    siteMemberEmailIds.forEach(emailid => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = '';
      adUser.lastName = '';
      adUser.userEmailId = emailid;
      MemberListEmailModel.groupUsersEmail.push(adUser);
    })

    EngagementSiteUsersUpdatePermissionModel.siteType = "EngagementSite"
    EngagementSiteUsersUpdatePermissionModel.siteUniqueID = siteUniqueID;
    EngagementSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
    EngagementSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
    updatePermissionsModelList.push(EngagementSiteUsersUpdatePermissionModel);
    console.log('updatePermissionsModelList ==>', updatePermissionsModelList);
    //1. Final Call Call Active Directory API to update latest collaborationSite Member Ids
    this.UpdatePermissions(updatePermissionsModelList);
  }

  UpdatePermissions(updatePermissionsModeList: UpdatePermissionsModel[]) {
    let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
    updatePermissionsModelData.updatePermissionsModel = updatePermissionsModeList;

    this._activedirectoryService.UpdatePermissionsInAcceptance(updatePermissionsModelData).subscribe(
      result => {
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }


  BuildRPsDisplayModel() {
    let strRoleArray: string[] = [];
    let strNOPRoleArray: string[] = [];
    let strCombinedRoleArrayWithMemberType: localCombinedRoleArrayWithMemberTypeModel[] = [];

    this.autoRPDisplayModel = [];
    this.manualRPDisplayModel = [];
    this.notificationOnlyProfessionals = [];

    let NOPRPs = this.reviewerList.filter(member => member.memberType == RevievingProfessionalType.AutoNotificationOnlyProfessional);

    if (NOPRPs && NOPRPs.length > 0) {
      NOPRPs.forEach(member => {
        if (!strNOPRoleArray.includes(member.memberRoleName)) {
          strNOPRoleArray.push(member.memberRoleName);
          strCombinedRoleArrayWithMemberType.push({ role: member.memberRoleName, memberType: member.memberType });
        }
      });
    }

    this.reviewerList.forEach(member => {
      if (member.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && !strRoleArray.includes(member.memberRoleName)) {
        strRoleArray.push(member.memberRoleName);
        strCombinedRoleArrayWithMemberType.push({ role: member.memberRoleName, memberType: member.memberType });
      }
    });

    strCombinedRoleArrayWithMemberType.forEach(role => {
      this.rpDisplayModel = new RPDisplayModel();
      let filteredNotificationOnlyProfs: TeamMembers[] = [];
      let memberWithSameGroup = this.reviewerList.filter(rpMembers => rpMembers.memberRoleName == role.role && rpMembers.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional);
      this.rpDisplayModel.hasGroupApproved = memberWithSameGroup.filter(member => member.approvalStatus.toUpperCase() == WorkspaceInterviewsStatus.Approved.toUpperCase()).length > 0;
      if (role.memberType == RevievingProfessionalType.AutoNotificationOnlyProfessional) {
        filteredNotificationOnlyProfs = this.reviewerList.filter(member => member.memberRoleName == role.role && member.isReviewer == 1 && member.memberType == RevievingProfessionalType.AutoNotificationOnlyProfessional);
      }


      if (role.role != null && role.role != '' && filteredNotificationOnlyProfs && filteredNotificationOnlyProfs.length > 0) {
        this.rpDisplayModel = new RPDisplayModel();
        this.rpDisplayModel.roleName = filteredNotificationOnlyProfs[0].memberRoleName;
        this.rpDisplayModel.roleDescription = filteredNotificationOnlyProfs[0].memberRoleDescription;
        this.rpDisplayModel.teamMembersType = filteredNotificationOnlyProfs[0].memberType;
        this.rpDisplayModel.teamMembers = filteredNotificationOnlyProfs;
        this.notificationOnlyProfessionals.push(this.rpDisplayModel);
      }
      else if (role.role == null) {
        let filteredManuallyRP = memberWithSameGroup.filter(member => member.isReviewer == 1 && member.memberType == RevievingProfessionalType.Manual);
        if (filteredManuallyRP && filteredManuallyRP.length > 0) {
          this.rpDisplayModel.roleName = "Manually added";
          this.rpDisplayModel.roleDescription = "Manually added";
          this.rpDisplayModel.teamMembersType = "Manually added";
          this.rpDisplayModel.teamMembers = filteredManuallyRP;
          this.manualRPDisplayModel.push(this.rpDisplayModel);
        }
      }
      else {
        this.rpDisplayModel.roleName = memberWithSameGroup[0].memberRoleName;
        this.rpDisplayModel.roleDescription = memberWithSameGroup[0].memberRoleDescription;
        this.rpDisplayModel.teamMembersType = memberWithSameGroup[0].memberType;
        if (memberWithSameGroup.filter(member => member.approvalStatus.toUpperCase() == WorkspaceInterviewsStatus.Approved.toUpperCase()).length > 0) {
          this.rpDisplayModel.teamMembers = memberWithSameGroup.filter(member => member.approvalStatus.toUpperCase() == WorkspaceInterviewsStatus.Approved.toUpperCase());
        }
        else {
          this.rpDisplayModel.teamMembers = memberWithSameGroup;
        }
        this.autoRPDisplayModel.push(this.rpDisplayModel);
      }
    });

    if (this.autoRPDisplayModel && this.autoRPDisplayModel.length > 0) {
      this.autoRPDisplayModel = this.sortRPDisplayModel(this.autoRPDisplayModel);
    }
  }

  sortRPDisplayModel(rpDisplayModel: RPDisplayModel[]): RPDisplayModel[] {
    return rpDisplayModel.sort((a, b) => ((a.teamMembers[0] && b.teamMembers[0] && a.teamMembers[0].tier - b.teamMembers[0].tier)
      || (a.teamMembers[0] && b.teamMembers[0] && a.teamMembers[0].memberRoleDescription.localeCompare(b.teamMembers[0].memberRoleDescription))));
  }

  HasSiteProvisionedForWorkspace(action: string, isDeclineModal: boolean) {
    this.workspaceService.HasSiteProvisionedForWorkspace(this.workspaceId).subscribe(siteProvisioningStatus => {
      this.hasSiteProvisioned = siteProvisioningStatus;
      setTimeout(() => {
        // Validate button operation
        if (this.isValidOperation(action)) {
          this.CheckTemplateVersion(action, isDeclineModal);
        } else {
          this.doNotproccedForOKbuttonAction(false, action);
        }
      }, 500);
    });
  }

  findCurrentUserRolesInWorkspace() {
    let shortRoleName = '';
    if (this.isUserRP === true) {
      shortRoleName = shortRoleName + 'RP' + ',';
    }
    if (this.isUserEP === true) {
      shortRoleName = shortRoleName + 'EP' + ',';
      shortRoleName = shortRoleName + 'SM' + ',';
    } else if (this.isUserTM === true) {
      shortRoleName = shortRoleName + 'SM' + ',';
    }
    return shortRoleName;
  }
  setTeamMemberListtoValidate() {
    //Get Team members those are eligible to indus consent removal process
    for (let i = 0; this.teamsMembersList.teamMembers.length > i; i++) {
      if (this.teamsMembersList.teamMembers[i].teamMemberRoleName !== EnumRoles.AssignmentAdministrator &&
        this.teamsMembersList.teamMembers[i].teamMemberRoleName !== EnumRoles.CMSEPPMD &&
        this.teamsMembersList.teamMembers[i].teamMemberRoleName !== EnumRoles.Creator &&
        this.teamsMembersList.teamMembers[i].teamMemberRoleName !== EnumRoles.EQCR) {
        this.indusTeamMemberData = {
          azureADUsers: this.indusADGroupsList,
          workspaceMemberId: this.teamsMembersList.teamMembers[i].id,
          memberEmail: this.teamsMembersList.teamMembers[i].memberEmailId,
          memberName: this.teamsMembersList.teamMembers[i].memberName,
          isIndusMember: false
        };
        this.indusMembersListArray.push(this.indusTeamMemberData);
      }
    }
  }
  GetINDUSTeamMembersFromAD() {
    this.loadingScreenService.startLoading();
    this.workspaceService.getTeamMembers(this.workspaceId).subscribe(
      teamMemberResult => {
        this.teamsMembersList = teamMemberResult;
        this.adUsersService.GetINDUSADGroups(RoleNames.GTUSINDUSALL).subscribe(
          adGroupList => {
            this.indusADGroupsList = adGroupList;
            this.ValidateIndusMemberList();
          },
          error => {
            this.loadingScreenService.stopLoading();
            this.indusADGroupsList = null;
            console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
          });
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.indusADGroupsList = null;
        console.log('Error in getting response from GetINDUSTeamMembersFromAD func ', error);
      }
    );
  }

  ValidateIndusMemberList() {
    this.setTeamMemberListtoValidate();
    let memerList: IndusADUsersListViewModel = new IndusADUsersListViewModel();
    if (this.indusMembersListArray != null && this.indusMembersListArray.length > 0) {
      let indusMemberLoaderToken = setInterval(() => this.loadingScreenService.startLoading(), 1);
      memerList.indusADUsersList = this.indusMembersListArray;
      this.adUsersService.getValidIndusTeamMemberList(memerList)
        .subscribe(
          userList => {
            clearInterval(indusMemberLoaderToken);
            if (userList.indusADUsersList != null && userList.indusADUsersList.length > 0) {
              userList.indusADUsersList.forEach(element => {
                this.teamsMembersList.teamMembers.forEach(x => {
                  if (element.workspaceMemberId == x.id && element.isIndusMember) {
                    this.validIndusMemberList.push(x);
                  }
                });
              });
              console.log(userList);
              let teamMembersList: TeamMemberEPModel = new TeamMemberEPModel();
              teamMembersList.epMembers = [];
              teamMembersList.teamMembers = this.validIndusMemberList;
              this.RemoveIndusTemMembers(teamMembersList);
            } else {
              this.loadingScreenService.stopLoading();
            }
          },
          error => {
            clearInterval(indusMemberLoaderToken);
            this.loadingScreenService.stopLoading();
            this.indusADGroupsList = null;
            console.log('Error in getting response from GetIndusTeamMembers func ', error);
          }
        );
    } else {
      this.loadingScreenService.stopLoading();
    }
  }
  RemoveIndusTemMembers(teamMembersList: TeamMemberEPModel) {
    this.workspaceService.removeIndusMembers(teamMembersList).subscribe(
      result => {
        if (+result < 0) {
          //setting second parameter as false
          this._engService.GetEngagementTeamMembersById(this.CurrentWorkspaceData.engagementId, false).subscribe(
            teamMemberResult => {
              this.loadingScreenService.stopLoading();
              this.createAndUpdatePermissionsModel(teamMemberResult, this.currentEngagement.siteUniqueID);
            });
        } else {
          this.loadingScreenService.stopLoading();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.indusADGroupsList = null;
        console.log('Error in getting response from RemoveIndusTemMembers func ', error);
      });
  }

  ValidateUserWithADGroup() {
    const searchUsers: MembersList[] = [];
    const searchGroupUserViewModel = new SearchGroupUserViewModel();
    const roleShortName = RoleNames.StaffMember.toString();
    const addTeamMember = new MembersList();
    let isGivenUserGroupMember = false;
    addTeamMember.displayName = this.PeoplePickerModel;
    addTeamMember.mail = this.userEmail;
    searchUsers[0] = addTeamMember;
    this.adUsersService.GetAzureADGroupsByRoleShortName(roleShortName).subscribe(result => {
      this.azureADUsersArrayList = result;
      this.azureADUsersArrayList.roleName = roleShortName;

      searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
      searchGroupUserViewModel.user = searchUsers[0];
      this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(isUserValid => {
        isGivenUserGroupMember = isUserValid;
        return isGivenUserGroupMember;
      },
        error => {
          console.log('Error in ValidateUserWithADGroup ', error);
        });
    });
    if (isGivenUserGroupMember) {
      return isGivenUserGroupMember;
    }
  }

  FileTypeValidation(fileToUpload: File) {
    let fileTypeList = ['gif', 'png', 'jpg', 'jpeg',
      'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'office'];

    var ext = fileToUpload.name.split('.').pop().toLowerCase();
    for (let i = 0; i < fileTypeList.length; i++) {
      if (fileTypeList[i].includes(ext)) {
        return true;
      }
    }
    const restrictedFileTypes = ['', ' ', 'exe', 'htm', 'html', 'js', 'php', 'asp', 'aspx', 'x-msdownload', 'x-zip-compressed'];
    if ($.inArray(fileToUpload.type, restrictedFileTypes) == -1) {
      return false;
    }
  }
  DownloadDocument(documentId: number) {
    var selectedDocument = this.supplementalDocumentList.filter(document => document.documentId == documentId);
    if (selectedDocument != null && selectedDocument.length > 0) {
      var bytesArray = this.base64ToArrayBuffer(selectedDocument[0].documentBytes);
      var fileType = this.GetFileType(selectedDocument[0].documentName);
      this.saveByteArray(selectedDocument[0].documentName, fileType, bytesArray);
    }
  }

  GetFileType(fileName) {
    let checkFileType = fileName.split('.').pop();
    var fileType;
    switch (checkFileType) {
      case "txt": fileType = "text/plain"; break;
      case "pdf": fileType = "application/pdf"; break;
      case "doc": fileType = "application/vnd.ms-word"; break;
      case "docx": fileType = "application/vnd.ms-word"; break;
      case "xls": fileType = "application/vnd.ms-excel"; break;
      case "xlsx": fileType = "application/vnd.ms-excel"; break;
      case "ppt": fileType = "application/vnd.ms-powerpoint"; break;
      case "pptx": fileType = "application/vnd.ms-powerpoint"; break;
      case "png": fileType = "image/png"; break;
      case "jpg": fileType = "image/jpeg"; break;
      case "jpeg": fileType = "image/jpeg"; break;
      case "gif": fileType = "image/gif"; break;
      case "csv": fileType = "text/csv"; break;
    }
    return fileType;
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  saveByteArray(fileName, fileType, byte) {
    var blob = new Blob([byte], { type: fileType });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  //Get notification request payload
  GetDecisionsNotificationPayload(engagementId: number, remark: string, isFinalRPApproval: boolean) {
    this.loadingScreenService.startLoading();
    this._engService.GetNotificationPayload(engagementId).subscribe((payload: Engagement) => {

      var notificaitonRequest: NotificationOnlyRequestModel = new NotificationOnlyRequestModel();
      notificaitonRequest.notificationRequest = payload;

      this.adUsersService.getDecisionUserDetailsByEmailId(notificaitonRequest.notificationRequest.engagementPartnerEmail).subscribe(user => {
        notificaitonRequest.notificationRequest.engagementPartnerOffice = user.officeLocation;

        let continuanceStatus: boolean = this.currentEngagement.reacceptanceEngagementId > 0;
        notificaitonRequest.notificationRequest.continuanceFlag = continuanceStatus;

        //Get notification only professionals from decisions
        this.decisionRuleEngineService.SendNotifications(notificaitonRequest).subscribe((sendNotificationResponse: NotificationResponseViewModel) => {

          //Save notification only professionals
          this.workspaceService.SaveNotificationsOnlyProfessionals(this.BuildNotificationOnlyProfessionalsList(sendNotificationResponse, DecisionsRequestTypes.SendNotification, remark)).subscribe((sendNotificationResponseCount: number) => {

            //----------------------
            if (!isFinalRPApproval) {
              this.decisionRuleEngineService.GetNotifications(notificaitonRequest).subscribe((getNotificationResponse: NotificationResponseViewModel) => {

                //Save notification only professionals
                this.workspaceService.SaveNotificationsOnlyProfessionals(this.BuildNotificationOnlyProfessionalsList(getNotificationResponse, DecisionsRequestTypes.GetNotification, remark)).subscribe((getNotificationResponseCount: number) => {
                  //responseCount
                  this.loadingScreenService.stopLoading();
                },
                  error => {
                    this.loadingScreenService.stopLoading();
                    console.error(error);
                  });
              },
                error => {
                  this.loadingScreenService.stopLoading();
                  console.error(error);
                });
            }
            else {
              this.GetCloseoutConfigurations();
              this.loadingScreenService.stopLoading();
            }
            //-------------------------

          },
            error => {
              this.loadingScreenService.stopLoading();
              console.error(error);
            });
        },
          error => {
            this.loadingScreenService.stopLoading();
            console.error(error);
          });
      },
        error => {
          this.loadingScreenService.stopLoading();
          console.error(error);
        });
    },
      error => {
        this.loadingScreenService.stopLoading();
        console.error(error);
      });
  }

  BuildNotificationOnlyProfessionalsList(notificationResponseViewModel: NotificationResponseViewModel, requestType: string, remark: string): DecisionsNotificationProfessionalsListModel {
    let decisionsNotificationProfessionalsListModel: DecisionsNotificationProfessionalsListModel = new DecisionsNotificationProfessionalsListModel();

    decisionsNotificationProfessionalsListModel.decisionsRPList = [];
    decisionsNotificationProfessionalsListModel.workspaceId = this.workspaceId;
    decisionsNotificationProfessionalsListModel.isSuccess = notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.isSuccess;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.workspaceId = this.workspaceId;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.loggedInUser = this.userInfo.userName;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.requestType = requestType;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.remark = remark;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.action = notificationResponseViewModel.request.request.notificationRequest.action;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.requestPayload = JSON.stringify(notificationResponseViewModel.request);
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.responseBody = JSON.stringify(notificationResponseViewModel.response);
    decisionsNotificationProfessionalsListModel.isFinalApproval = this.isLoggedInUserFinalApprover;
    decisionsNotificationProfessionalsListModel.decisionRequestType = requestType;
    decisionsNotificationProfessionalsListModel.errorCode = notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.errorCode;
    decisionsNotificationProfessionalsListModel.errorDescription = notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.errorDescription;

    if (notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.isSuccess && notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications) {
      if (notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications
        && notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications.length > 0) {
        notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications.forEach(notification => {
          if (notification.recipients && notification.recipients.length > 0) {
            notification.recipients.forEach(recipient => {
              if (recipient.users && recipient.users.length > 0) {
                recipient.users.forEach(user => {
                  let obj: TeamMembers = new TeamMembers();
                  obj.workspaceId = this.workspaceId;
                  obj.memberName = user.name;
                  obj.isReviewer = 1;
                  obj.roleId = 0;
                  obj.createdBy = this.userInfo.userName;
                  obj.memberEmailId = user.email;
                  obj.collaborationSiteId = 0;
                  obj.memberRoleName = recipient.role;
                  obj.memberRoleDescription = recipient.role;
                  obj.notificationTrigger = notification.notificationTrigger;
                  obj.memberType = RevievingProfessionalType.AutoNotificationOnlyProfessional;
                  obj.engageWorkflowStage = this.GetNotificationWorkflowStage(notification.notificationTrigger);
                  decisionsNotificationProfessionalsListModel.decisionsRPList.push(obj);
                });
              }
            });
          }
        });
      }
    }

    return decisionsNotificationProfessionalsListModel;
  }

  GetNotificationWorkflowStage(action: string) {
    switch (action.toLowerCase()) {
      case WorkspaceInterviewsStatus.EPReviewPending.toLowerCase():
        return NotificationWorkflowStage.FinishQuestionnaire;
      case WorkspaceInterviewsStatus.EPPMDReview.toLowerCase():
        return NotificationWorkflowStage.TeamReviewApproved;
      case WorkspaceInterviewsStatus.RPInReview.toLowerCase():
        return NotificationWorkflowStage.EPPMDReviewApproved;
      case WorkspaceInterviewsStatus.Approved.toLowerCase():
        return NotificationWorkflowStage.FinalApproved;
    }
  }

  isLoggedInUserIsPureNotificationOnlyProfesional() {
    let IsTeamMember = false;
    let IsRP = false;
    let IsNOP = false;

    this.isLoggedInUserIsAutoOrManualRP = false;
    var i = 0, j = 0;
    if (this.memberList && this.memberList.length > 0) {
      for (i = 0; i < this.memberList.length; i++) {
        if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
          IsTeamMember = true;
          break;
        }
      }
    }

    if (this.reviewerList && this.reviewerList.length > 0) {
      for (j = 0; j < this.reviewerList.length; j++) {
        if (this.reviewerList[j].memberEmailId?.toLowerCase() == this.userInfo.userName.toLowerCase()
          && this.reviewerList[j].teamMemberRole == EnumRoles.NotificationOnlyProfessional
          && this.reviewerList[j].memberType == RevievingProfessionalType.AutoNotificationOnlyProfessional) {
          IsNOP = true;
        } else if (this.reviewerList[j].memberEmailId?.toLowerCase() == this.userInfo.userName.toLowerCase()) {
          IsRP = true;
        }
      }
    }

    this.isLoggedInUserIsAutoOrManualRP = IsRP;

    this.isPureNotificationOnlyProfessional = (!IsTeamMember && !IsRP && IsNOP && !this.isLoggedInUserSystemAdmin);
    this.isPureRP = (!IsTeamMember && IsRP && !IsNOP && !this.isLoggedInUserSystemAdmin);
  }

  getActivityTypeByWorkSpaceId() {
    this.workspaceService.getActivityTypeByWorkSpaceId(this.workspaceId).subscribe(
      type => {
        this.workspaceType = type;
        if (type === 'C') {
          this.engagementState = 'ChangeOrder'
          this.getEngagementChangeOrdersByWorkSpaceId(this.workspaceId);
        }
        else {
          this.engagementState = 'Acceptance'
        }
      });
  }
  GetWorkspaceDocumentCountbyEngId() {
    // var engagementState = 'Acceptance';
    this.workspaceService.GetWorkspaceDocumentCountbyEngId(this.CurrentWorkspaceData.engagementId, this.engagementState).subscribe(
      resultValue => {
        this.workspaceDocumentCount = resultValue;
        this.isAddDocumentLimitExceeded = (this.workspaceDocumentCount - 1) >= +this.addDocumentsLimit;
        console.log('this.workspaceDocumentCount', this.workspaceDocumentCount);
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }


  SetWorkspaceIdForEngageSummaryReport(isDownload: boolean) {
    console.log('SetWorkspaceIdForEngageSummaryReport func called',
      this.workspaceId, this.currentEngagement.siteUrl, isDownload,
      this.isDeclinedRequest, this.isNewClientRequest);
    let tempWorkspaceId: string;
    if (this.selectedWorkspaceDocumentItem.isPrimaryWorkspaceId) {
      tempWorkspaceId = this.workspaceId;
    } else {
      tempWorkspaceId = this.selectedWorkspaceDocumentItem.workspaceId;
    }

    if (!this.isDeclinedRequest && !this.isNewClientRequest) {
      const emitsCheckRequestSentForClientInRpt =
        this.workspaceService.CheckRequestSentForClient(tempWorkspaceId.toString(), 0, 1);
      emitsCheckRequestSentForClientInRpt.pipe(
        catchError(err => {
          console.error('Error Occur in CheckRequestSentForClient ', err);
          this.workspaceService.storeErrorLog
            ("WorkspaceId ::" + this.workspaceId.toString() + " Message ::" + err + "::" +
              JSON.stringify(this.userInfo))
            .subscribe(
              result => { console.log(result); }
            );

          return of(-1);
        })
      ).subscribe(result => {
        if (result == 0) {
          this.isNewClientRequest = true;
        }
        this.siteUrlForSummaryReport = this.currentEngagement.siteUrl;
        this.workspaceIdForSummaryReport = tempWorkspaceId;
        this.isDownloadForSummaryReport = isDownload;
      },
        err => {
          this.loadingScreenService.stopLoading();
          console.error('CheckRequestSentForClient failed', err);
        }, () => {
          console.log('Event on complete');
        }
      );
    }
    else {
      this.siteUrlForSummaryReport = this.currentEngagement.siteUrl;
      this.workspaceIdForSummaryReport = tempWorkspaceId;
      this.isDownloadForSummaryReport = isDownload;
    }
  }

  UpdateReviewResponseDisplay($event) {
    if (!this.currentEngagement.appliedTnC) {
      $('#OriginalreviewReponseNavItem').tab('show');
      this.QuestionSummaryDisplayForOriginal();
    }
    else {
      $('#reviewReponseNavItem').tab('show');
      this.QuestionSummaryDisplay();
    }
    setTimeout(
      () => {
        this.UploadEngageSummaryReportToSharepoint($event);
      }, 3000
    );
  }

  UploadEngageSummaryReportToSharepoint(loadStatus: string) {
    this.loadingScreenService.startLoading();
    console.log('UploadEngageSummaryReportToSharepoint ', loadStatus);
    if (loadStatus == 'Success') {
      console.log('UploadEngageSummaryReportToSharepoint func called - start ');
      const FirstPageHeaderSection = document.getElementById('FirstPageHeaderSection').innerHTML;
      const PageHeaderSection = document.getElementById('PageHeaderSection').innerHTML;
      const ClientSection = document.getElementById('ClientSection').innerHTML;
      const EngagementSection = document.getElementById('EngagementSection').innerHTML;
      const AssignmentSection = document.getElementById('AssignmentSection').innerHTML;
      const TemplateSection = document.getElementById('TemplateSection').innerHTML;
      const ApprovalSection = document.getElementById('ApprovalSection').innerHTML;
      const NotificationSection = document.getElementById('NotificationSection').innerHTML;
      const AttachmentSection = document.getElementById('AttachmentSection').innerHTML;
      const ReportGeneratedOn = document.getElementById('ReportGeneratedOn').innerHTML;
      const GASection = document.getElementById('GASection').innerHTML;

      const hdcollection = document.getElementsByClassName('hd-answer-page');
      if (hdcollection.length > 0) {
        let answerSummaryElement = document.getElementsByClassName('hd-answer-page')[0].innerHTML;
        const searchTerm1 = /<td class="HDASAnsValue" style=" width:70% ">/gi;
        answerSummaryElement = answerSummaryElement
          .replace(searchTerm1, '<td class="HDASAnsValue" style="width:160px;border:1px solid #E0E0E0">');
        const searchTerm2 = /&nbsp;/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm2, '');
        const searchTerm3 = /font-size:125%;/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm3, 'font-size:18;');
        const searchTerm4 = /<td class="HDASVarPrompt" style="">/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm4, '<td class="HDASVarPrompt" style="border: 1px solid #E0E0E0;">');
        const searchTerm5 = /<td class="HDASDlgTitle" colspan="2">/gi;
        answerSummaryElement = answerSummaryElement
          .replace(searchTerm5, '<td class="HDASDlgTitle" colspan="2" style="border:1px solid #E0E0E0">');
        const searchTerm6 = /<td class="HDASDlgElem" style="" colspan="2">/gi;
        answerSummaryElement = answerSummaryElement
          .replace(searchTerm6, '<td class="HDASDlgElem" style="border:1px solid #E0E0E0" colspan="2">');
        const searchTerm7 = /<td style="" colspan="2">/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm7, '<td style="border:1px solid #E0E0E0" colspan="2">');

        const searchTerm = /width="100%"/gi;
        answerSummaryElement = answerSummaryElement.replace(searchTerm, '')
        const startIndex = answerSummaryElement.indexOf("<table");

        const endIndex = answerSummaryElement.length;
        const answerSummaryContent = answerSummaryElement.substring(startIndex, endIndex);


        const pageBreakHtml = '<div class="pdf-pagebreak-before"> </div>';

        let finalHtml = FirstPageHeaderSection + PageHeaderSection + ClientSection + EngagementSection
          + pageBreakHtml + PageHeaderSection + AssignmentSection
          + pageBreakHtml + PageHeaderSection + TemplateSection + answerSummaryContent
          + pageBreakHtml + PageHeaderSection + ApprovalSection + NotificationSection + AttachmentSection;
        if (this.clmDetails?.name?.length > 0) {
          finalHtml = FirstPageHeaderSection + PageHeaderSection + ClientSection + EngagementSection
            + pageBreakHtml + PageHeaderSection + AssignmentSection
            + pageBreakHtml + PageHeaderSection + GASection + TemplateSection + answerSummaryContent
            + pageBreakHtml + PageHeaderSection + ApprovalSection + NotificationSection + AttachmentSection;
        }

        const html = htmlToPdfmake(finalHtml, {
          tableAutoSize: true
        });

        const footerhtml = this.isDownloadForSummaryReport ? htmlToPdfmake(ReportGeneratedOn) : "";

        pdfMake.fonts = {
          GTWalsheimProRegular: {
            normal: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Regular.ttf',
            bold: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Bold.ttf',
            italics: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Regular.ttf',
            bolditalics: this.applicationBaseURL + '/assets/font/GT-Walsheim-Pro-Bold-Oblique.ttf'
          },
        }

        const docDefinition = {
          footer: footerhtml,
          content: html,
          styles: {
            PageHeader: {

            },
            ReportTitle: {
              bold: true,
              color: '#4F2D7F'
            },
            HDASDlgTitle: {
              fillColor: '#F5F5F5',
              bold: true,
              color: '#4F2D7F',
              fontSize: 12,
              width: '675px',
              alignment: 'center'
            },
            TableHeader: {
              fillColor: '#F5F5F5',
              bold: true,
              color: '#4F2D7F',
              width: '675px',
              fontSize: 12,
              alignment: 'center'
            },
            TableSubHeader: {
              fillColor: '#F5F5F5',
              fontSize: 10
            },
            TableSubHeaderLocation: {
              fillColor: '#F5F5F5',
              fontSize: 10,
              alignment: 'center'
            },
            FieldHeader: {
              fillColor: '#F5F5F5',
              fontSize: 10
            },
            FieldText: {
              fontSize: 10
            },
            FieldTextBold: {
              fontSize: 10,
              bold: true

            },
            HDASVarPrompt: {
              fontSize: 10
            },
            HDASAnsValue: {
              fontSize: 10
            },
            HDASDlgElem: {
              fontSize: 10
            },
            FooterText: {
              fontSize: 10,
              alignment: 'right'
            }
          },
          pageBreakBefore: function (currentNode) {
            return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
          },
          defaultStyle: {
            font: 'GTWalsheimProRegular'
          }
        };

        let filename: string = 'Engage Summary.pdf';
        if (this.currentEngagement.appliedTnC) {
          filename = 'Engage Summary Revised Responses.pdf';
        }

        if (this.workspaceDocumentCount > 1) {
          filename = `${this.currentlyProcessingEngageSummaryReportName} - ${filename}`;
        }

        if (this.isDownloadForSummaryReport) {
          this.loadingScreenService.stopLoading();
          pdfMake.createPdf(docDefinition).download(filename);
          this.siteUrlForSummaryReport = null;
          this.workspaceIdForSummaryReport = null;
          this.isDownloadForSummaryReport = false;

        } else {
          console.log('Generate PDF using pdfmake - 1)  Start');
          const pdfDocGenerator = pdfMake.createPdf(docDefinition);
          pdfDocGenerator.getBuffer((data) => {
            // console.log('Engage Summary Report PDF - Byte Array ', data);
            console.log('Generate PDF using pdfmake - 1.1) Get Byte Array');
            const fileBytes = [].slice.call(data);
            this.spDocument = {
              fileName: filename,
              fileByteArray: fileBytes
            };

            this.sharepointSiteDocumentModel.files = [];
            this.sharepointSiteDocumentModel.files[0] = this.spDocument;
            this.sharepointSiteDocumentModel.documentType = 'Acceptance';
            this.sharepointSiteDocumentModel.documentFolderPath = this.currentEngagement.acceptanceFolderPath;
            this.sharepointSiteDocumentModel.siteURL = this.currentEngagement.siteUrl
            this.sharepointSiteDocumentModel.useremailId = this.userInfo.userName;

            console.log('Generate PDF using pdfmake - 1.2) Uploading to sharepoint', this.currentEngagement.siteUrl, filename);
            // call Sharepoiint API
            this._sharepointService.AddDocumentsToLibrary(this.sharepointSiteDocumentModel).subscribe(
              result => {
                const strResult = result;
                console.log("strResult==>", strResult);
                console.log('Generate PDF using pdfmake - 2) Completed');
                this.loadingScreenService.stopLoading();

                if (!this.isNewClientRequest || this.isDeclinedRequest) {
                  if (!this.currentEngagement.appliedTnC && this.workspaceDocumentList?.length > (this.currentDocumentProcessingIndex + 1) && this.multipleReportGenerationInProgress) {
                    //Generate summary report for next additional document
                    this.generateMultipleSummaryReports(++this.currentDocumentProcessingIndex);
                  }
                  else {
                    clearInterval(this.multipleDocumentGenerationLoaderToken);
                    this.multipleReportGenerationInProgress = false;
                    this.redirectToMyEngagements();
                  }
                } else {
                  clearInterval(this.multipleDocumentGenerationLoaderToken);
                  this.multipleReportGenerationInProgress = false;
                  this.displayNewClientRequest();
                }
              });

          },
            error => {
              this.loadingScreenService.stopLoading();
              console.log('Generate PDF using pdfmake - 3) Failure');
              console.error('Error in uploading document ', error);
              this.workspaceService.storeErrorLog("EngagementId ::" + this.currentEngagement.engagementId + " Message ::" + error).subscribe
                (
                  result => { console.log(result); }
                );
              this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
            },
            () => {
              console.log('UploadEngageSummaryReportToSharepoint func complete ', this.isNewClientRequest);
            });
        }

      } else {
        console.error('Could not load HotDocs answer summary');
        this.DisplayHotDocsNoAnswerSummaryAlert();
      }
    }

    console.log('UploadEngageSummaryReportToSharepoint func called - end ');
  }
  //Share Point Document For Supplemental Documents
  OpenNewTab() {
    var SplitsharePointUrl = this.sharepointSiteURL.split("/");
    var SiteUniqueid = SplitsharePointUrl[4];
    var win = window.open(this.sharepointSiteURL + '/Acceptance Documents/Forms/AllItems.aspx?id=/sites/' + SiteUniqueid + '/Acceptance Documents/Supplemental', '_blank');
    win.focus();
  }
  //Share point url For Perm Library
  OpenNewTabForPermLibrary() {
    var win = window.open(this.sharepointSiteURL + '/SitePages/PermLibrary.aspx', '_blank');
    win.focus();
  }

  IsHDQuestionAnswerLoaded: boolean = false;
  OnHDQuestionAnswerLoaded(status) {
    this.IsHDQuestionAnswerLoaded = status;

    if (this.multipleReportGenerationInProgress && this.workspaceDocumentList?.length > this.currentDocumentProcessingIndex) {
      this.SetWorkspaceIdForEngageSummaryReport(false);
    }
    else if (this.UnansweredQuestionCheckInProgress) {
      this.UnansweredQuestionCheckInProgress = false;
      clearInterval(this.UnansweredQuestionCheckLoaderToken);
      this.UnansweredQuestionCheckForApproval(this.UnansweredQuestionCheckDialogType, this.UnansweredQuestionCheckAction, this.UnansweredQuestionCheckIsDeclineModal);
    }
  }

  decisionRPRequest: DecisionsRPRequestModel = new DecisionsRPRequestModel();
  decisiosnRPResponseViewModel: DecisionsRPReponseViewModel = new DecisionsRPReponseViewModel();
  decisionsRPListModel: DecisionsRPListModel = new DecisionsRPListModel();

  UpdateDecisionsRPList(dialogType, action, isDeclineModal) {
    this.loadingScreenService.startLoading();
    this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerEmail = this.currentEngagement.engagementPartner;
    this.decisionRPRequest.decisionsRPRequest.engageRequest.hotDocsDocumentId = this.currentEngagement.primaryWorkitemId;
    this.decisionRPRequest.decisionsRPRequest.engageRequest.clientCode = this.currentEngagement.clientCode;

    this.adUsersService.getDecisionUserDetailsByEmailId(this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerEmail).subscribe(user => {
      this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerOffice = user.officeLocation;

      let continuanceStatus: boolean = this.currentEngagement.reacceptanceEngagementId > 0;
      this.decisionRPRequest.decisionsRPRequest.engageRequest.continuanceFlag = continuanceStatus;

      this.decisionRPRequest.decisionsRPRequest.engageRequest.solutionCodeAssignments = [];
      this._engService.GetRPDeterminationAssignmentsDetailByWorkspaceId(this.workspaceModel.workspaceId).subscribe((response: RPDeterminationAssignmentsDetail[]) => {

        response.forEach(assignmentDetail => {
          this.decisionRPRequest.decisionsRPRequest.engageRequest.solutionCodeAssignments.push({
            cmsppmdEmail: assignmentDetail.cmsppmdEmailId.split(','),
            eqcrEmail: assignmentDetail.eqcrEmailId.split(','),
            estimatedGrossMargin: assignmentDetail.estimatedGrossMargin,
            estimatedNetFees: assignmentDetail.netFees,
            solutionCode: assignmentDetail.solutionCode
          });
        });

        this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementID = response[0].engagementId.toString();

        this.decisionRuleEngineService.GetReviewingProfessionals(this.decisionRPRequest).subscribe(result => {
          this.decisiosnRPResponseViewModel = result;

          let decisionsRPListModel: DecisionsRPListModel;
          decisionsRPListModel = this.BuildAutoRPListFromResponse(this.decisiosnRPResponseViewModel)
          this.InsertAutoRPList(dialogType, action, isDeclineModal, decisionsRPListModel);
        },
          error => {
            this.DisplaySystemAlert();
          });
      },
        err => {
          this.DisplaySystemAlert();
        });
    },
      err => {
        this.DisplaySystemAlert();
      });
  }

  BuildAutoRPListFromResponse(autoRPResponseViewModel: DecisionsRPReponseViewModel) {

    this.decisionsRPListModel.decisionsRPList = [];
    this.decisionsRPListModel.isSuccess = autoRPResponseViewModel.autoRPResponse.engageReturn.isSuccess;
    this.decisionsRPListModel.decisionsApiLogModel.workspaceId = this.workspaceModel.workspaceId;
    this.decisionsRPListModel.decisionsApiLogModel.loggedInUser = this.userInfo.userName;
    this.decisionsRPListModel.decisionsApiLogModel.requestType = DecisionsRequestTypes.AutoRP;
    this.decisionsRPListModel.decisionsApiLogModel.requestPayload = JSON.stringify(autoRPResponseViewModel.autoRPRequest);
    this.decisionsRPListModel.decisionsApiLogModel.responseBody = JSON.stringify(autoRPResponseViewModel.autoRPResponse);
    this.decisionsRPListModel.workspaceId = this.workspaceModel.workspaceId;
    this.decisionsRPListModel.riskPoints = autoRPResponseViewModel.autoRPResponse.engageReturn.riskPoints;
    this.decisionsRPListModel.errorCode = autoRPResponseViewModel.autoRPResponse.engageReturn.errorCode;
    this.decisionsRPListModel.errorDescription = autoRPResponseViewModel.autoRPResponse.engageReturn.errorDescription;
    this.decisionsRPListModel.loggedInUserFullName = this.userInfo.loggedInUserFullName;
    this.decisionsRPListModel.isDecisionsValuePersisted = this.isUserEP ? false : (this.isUserTM ? true : (this.isLoggedInUserSystemAdmin ? false : true));

    if (autoRPResponseViewModel != null && autoRPResponseViewModel.autoRPResponse.engageReturn.reviewingProfessionals != null && autoRPResponseViewModel.autoRPResponse.engageReturn.reviewingProfessionals.length > 0) {
      autoRPResponseViewModel.autoRPResponse.engageReturn.reviewingProfessionals.forEach(role => {
        role.users.forEach(user => {
          let obj: TeamMembers = new TeamMembers();
          obj.workspaceId = this.workspaceModel.workspaceId;
          obj.memberName = user.name;
          obj.isReviewer = 1;
          obj.roleId = 0;
          obj.createdBy = this.userInfo.userName;
          obj.memberEmailId = user.email;
          obj.collaborationSiteId = 0;
          obj.memberRoleName = role.roleName;
          obj.memberRoleDescription = role.roleDescription;
          obj.memberType = RevievingProfessionalType.Auto;
          obj.tier = role.tier;
          this.decisionsRPListModel.decisionsRPList.push(obj);
        });
      });
    }

    return this.decisionsRPListModel;
  }

  InsertAutoRPList(dialogType, action, isDeclineModal, decisionRPListModel: DecisionsRPListModel) {

    if (((this.CurrentWorkspaceData.status === WorkspaceInterviewsStatus.EPReviewPending
      && action == WorkspaceInterviewsStatus.SendToEPPMDTitle) ||
      (this.CurrentWorkspaceData.status === WorkspaceInterviewsStatus.EPPMDReview
        && action == WorkspaceInterviewsStatus.EPApprovedTitle))
      && this.IsDecisionCallNeeded == true) {
      decisionRPListModel.engageUIStatus = this.CurrentWorkspaceData.status;
      this.workspaceService.SaveDecisionDetails(decisionRPListModel).subscribe(saveDecisionDetailsResponse => {
        //Get latest engagement details to reflect 'RP rule engine error' immediatly
        this._engService.GetEngagementDetailsByworkspaceId(this.workspaceId).subscribe(
          getEngagementDetailsByworkspaceIdResponse => {
            this.currentEngagement = getEngagementDetailsByworkspaceIdResponse;
            this.isEngagmentRPRuleEngineError = this.currentEngagement.status === EngagementStatus.EngRPRuleEngineError;
            if (this.isEngagmentRPRuleEngineError) {
              this.SetEngageSupportCanvasLink();
            }

            if (action === WorkspaceInterviewsStatus.EPApprovedTitle) {
              // For Advisory(Atest- No Atest), need to fetch RP Delegate from Decision
              if (this.approveRejectWorkspaceResultModel.autoRpMembersWithDelegateList.length > 0) {
                this.getRPDelegateFromDecisionAndUpdateInEngage(this.approveRejectWorkspaceResultModel.autoRpMembersWithDelegateList, this.workspaceStatusUpdateViewModel.engagementPartnerOfficeLocation);
              }
            }
          },
          err => {
            console.log(err);
          });
      },
        error => {
          this.DisplaySystemAlert();
        });
    }

    else {
      //If current approval is of type 'EPPMD' then process approval further as have fetched and saved
      //latest RP list from Decisions
      if (action == WorkspaceInterviewsStatus.EPApprovedTitle) {
        this.preRequisiteCheckAndDisplayConfirmationPopUpForEPApproved(dialogType, action, isDeclineModal);
      }

      // -----new code for ReviewersAndUpdateRPPermission 
      //and call ONLY in case of action other than EPPMD approval(i.e team member approval)
      if (action != WorkspaceInterviewsStatus.EPApprovedTitle) {
        this.getReviewersAndUpdateRPPermission();
      }
    }
  }

  DisplaySystemAlert() {
    this._alertService.remove(this.alert);
    this.loadingScreenService.stopLoading();
    this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  DisplayHotDocsNoAnswerSummaryAlert() {
    this._alertService.remove(this.alert);
    this.loadingScreenService.stopLoading();
    this._alertService.add('Issue with HotDocs review responses. Please contact suppport.', 'danger', null);
  }

  SetEngageSupportCanvasLink() {
    this._engService.GetEngageSupportCanvasLink().subscribe(result => {
      this.engageSupportCanvasLink = result;
    },
      err => {
        console.log(err);
      });
  }

  OpenEngageSupportCanvasLink() {
    var win = window.open(this.engageSupportCanvasLink, '_blank');
    win.focus();
  }

  // displayReviewNoteValidations: boolean = false;
  // displayReviewNoteReplyValidations: boolean = false;
  // isValidReviewNoteReplyMessage: boolean = false;
  // isValidReviewNoteSubject: boolean = false;
  // isValidReviewNoteMessage: boolean = false;
  // isValidReviewNoteWhoResolve: boolean = false;
  // isAddReviewNoteFormOpen: boolean = false;

  // IsValidReviewNote() {
  //   let isValid = false;

  //   this.currentworkspaceReviewPoints.description = this.reviewNoteDiv.nativeElement.innerText.trim();
  //   this.currentworkspaceReviewPoints.description = this.currentworkspaceReviewPoints.description.replace(/\n/g, '<br/>');

  //   this.currentworkspaceReviewPoints.membersMentionedInReviewNote = this.GetMembersMentionedInReviewNote(this.reviewNoteDiv.nativeElement.innerHTML);

  //   this.isValidReviewNoteSubject = this.currentworkspaceReviewPoints.subject && this.currentworkspaceReviewPoints.subject.trim() != '';
  //   this.isValidReviewNoteMessage = this.currentworkspaceReviewPoints.description && this.currentworkspaceReviewPoints.description.trim() != '';
  //   this.isValidReviewNoteWhoResolve = this.currentworkspaceReviewPoints.whoCanResolve && this.currentworkspaceReviewPoints.whoCanResolve != '';
  //   this.currentworkspaceReviewPoints.createdBy = this.userInfo.userName;

  //   isValid = (this.isValidReviewNoteSubject && this.isValidReviewNoteMessage && this.isValidReviewNoteWhoResolve)

  //   this.displayReviewNoteValidations = !isValid;

  //   return isValid;
  // }

  // CanResolve(createdBy: string, whoCanResolve) {

  //   let allowedToResolveReviewNote: boolean = false;


  //   // If logged in user is pure NOP, do not allow to resolve
  //   if (this.isPureNotificationOnlyProfessional) {
  //     allowedToResolveReviewNote = false;
  //   }

  //   // Creator - If logged in user is creator of review note or system admin allow him to resolve
  //   // Allowed - Creator(TM/EP/RP), System Admin
  //   // Not Allowed - Non-Creator(TM/EP/RP), Pure NOP
  //   else if (this.isLoggedInUserSystemAdmin || createdBy.toLowerCase() == this.userInfo.userName.toLowerCase()) {
  //     allowedToResolveReviewNote = true;
  //   }

  //   // Any RP - Check if logged in user is EP or RP (We consider EP as RP)
  //   // Allowed - Creator(TM/EP/RP), EP, RP, System Admin
  //   // Not Allowed - Non-Creator(TM/EP/RP), TM, Pure NOP
  //   else if (whoCanResolve == WhoCanResolveReviewNote.AnyRP && (this.isUserRP || this.isUserEP)) {
  //     allowedToResolveReviewNote = true;
  //   }

  //   // Anyone - Check if logged in user is not NOP and has workspace acces
  //   // Allowed - Creator, TM, EP, RP, System Admin
  //   // Not Allowed - Pure NOP
  //   else if (whoCanResolve == WhoCanResolveReviewNote.Anyone && (this.isUserTM || this.isUserRP || this.isUserEP)) {
  //     allowedToResolveReviewNote = true;
  //   }

  //   return allowedToResolveReviewNote;
  // }

  // reviewNoteConfigurations = new Tribute({
  //   values: this.reviewNoteSearchableUsers,
  //   selectTemplate: function (item) {
  //     return ('<span contenteditable="false" class="inner-textarea-link" [ngClass]="{true:\'red-and-bold\'}[item.original.email==this.userInfo.userName]" selected-user-email="' + item.original.email + '">@' + item.original.value + '</span>');
  //   },
  //   menuContainer: document.body,
  //   noMatchTemplate: function () {
  //     return '<span style:"visibility: hidden;"></span>';
  //   }
  // });



  ConfigureReviewNoteSearch() {
    this.reviewNoteSearchableUsers = [];

    setTimeout(() => {

      if (this.reviewerList && this.reviewerList.length > 0) {
        this.reviewerList.forEach(user => {
          if (user.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }

      if (this.teamsMembersList.teamMembers && this.teamsMembersList.teamMembers.length > 0) {
        this.teamsMembersList.teamMembers.forEach(user => {
          if (user.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }

      if (this.teamsMembersList.epMembers && this.teamsMembersList.epMembers.length > 0) {
        this.teamsMembersList.epMembers.forEach(user => {
          if (user.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }

      if (this.reviewNoteSearchableUsersFromQualityGroups && this.reviewNoteSearchableUsersFromQualityGroups.length > 0) {
        this.reviewNoteSearchableUsersFromQualityGroups.forEach(user => {
          if (this.reviewNoteSearchableUsers.filter(item => item.email == user.email).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.key, value: user.value, email: user.email });
          }
        })
      }
    }, 1000);
  }

  // sortSearchableReviewNoteUsers() {
  //   this.reviewNoteSearchableUsers.sort((a, b) => {
  //     if (a.key < b.key) {
  //       return -1;
  //     }
  //     if (a.key > b.key) {
  //       return 1;
  //     }

  //     // names must be equal
  //     return 0;
  //   });  }

  //Action to handler after a user mention in review note
  // onMentioned(mentionedEvent: string, editableDivId, label) {
  //   var editableDivElement = $('#' + editableDivId);
  //   if (editableDivElement.text().length > this.maxCharactersAllowedForReviewNoteDescription) {
  //     var htmlContent = editableDivElement.html().toString();
  //     var currentUserLastIndex = htmlContent.lastIndexOf('<span');
  //     editableDivElement.html(htmlContent.substring(0, currentUserLastIndex - 1));
  //     setTimeout(() => {
  //       this.setEndOfContenteditable(editableDivId);
  //     }, 100);
  //     console.info(mentionedEvent);
  //   }
  //   else {
  //     let divContentLenght = editableDivElement.text().length;
  //     let remainingChars = this.maxCharactersAllowedForReviewNoteDescription - divContentLenght;
  //     $('#' + label).text(remainingChars + ' characters remaining');
  //     if (divContentLenght >= this.warningCharacterCount) {
  //       $('#' + label).addClass('warning-review-note-label');
  //     }
  //     else {
  //       $('#' + label).removeClass('warning-review-note-label');
  //     }
  //   }
  // }

  // setEndOfContenteditable(contentEditableElementId) {
  //   let range, selection;
  //   let contentEditableElement = document.getElementById(contentEditableElementId);
  //   if (document.createRange) {
  //     range = document.createRange();
  //     range.selectNodeContents(contentEditableElement);
  //     range.collapse(false);
  //     selection = window.getSelection();
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   }
  // }

  // GetMembersMentionedInReviewNote(commentHtmlString: string): ReviewNoteMentionedUser[] {
  //   this.reviewNoteMentionedUser = [];
  //   let reviewNoteHtmlString = '';
  //   let searchableUsers: ReviewNoteSearchableUser[] = [];
  //   let mentionedUser: ReviewNoteMentionedUser = new ReviewNoteMentionedUser();
  //   reviewNoteHtmlString = commentHtmlString;

  //   //Check total user exists
  //   var userCount = (reviewNoteHtmlString.match(/selected-user-email/g) || []).length;

  //   if (userCount > 0) {

  //     var selectedUserEmail = '';

  //     //Retrieve each user email
  //     for (var userIndex = reviewNoteHtmlString.indexOf('selected-user-email'); userIndex >= 0 && userCount > 0; userIndex = reviewNoteHtmlString.indexOf('selected-user-email', userIndex + 1), --userCount) {

  //       selectedUserEmail = '';
  //       for (var i = userIndex + 'selected-user-email'.length + 2; i <= reviewNoteHtmlString.length; i++) {
  //         if (reviewNoteHtmlString[i] == "\"") {
  //           break;
  //         }
  //         selectedUserEmail += reviewNoteHtmlString[i];
  //       }
  //       searchableUsers = this.reviewNoteSearchableUsers.filter(user => user.email == selectedUserEmail);
  //       if (selectedUserEmail != ''
  //         && searchableUsers.length > 0
  //         && this.reviewNoteMentionedUser.filter(user => user.userEmailId == selectedUserEmail).length == 0) {
  //         this.reviewNoteMentionedUser.push({ ...mentionedUser, userName: searchableUsers[0].value, userEmailId: searchableUsers[0].email });
  //       }
  //     }
  //   }

  //   return this.reviewNoteMentionedUser;
  // }

  GetAllMembersFromADGroups(workspaceId: string) {

    this.loadingScreenService.startLoading();

    // Get service line specific quality groups based on primary solution code
    this.adUsersService.GetQaulityADGroupsByWorkspaceId(workspaceId).subscribe(adGroups => {

      this.loadingScreenService.startLoading();

      if (adGroups && adGroups.length > 0) {

        let qaulityGroups: AzureADGroups = new AzureADGroups();
        qaulityGroups.azureADUsers = adGroups;

        this.graphSerivce.getAzureAdUsersByGroups(qaulityGroups, RoleNames.QualityGroup).subscribe(
          qaulityUsers => {
            if (qaulityUsers && qaulityUsers.length > 0) {
              qaulityUsers.forEach(user => {
                if (this.reviewNoteSearchableUsersFromQualityGroups.filter(addedUser => addedUser.email == user.mail).length == 0) {
                  this.reviewNoteSearchableUsersFromQualityGroups.push({ key: user.displayName, value: user.displayName, email: user.mail });
                }
              });

              // Just to make sure to include quality people if it take too much time to load quality people
              this.ConfigureReviewNoteSearch();
            }

            this.loadingScreenService.stopLoading();
          },
          err => {
            this.loadingScreenService.stopLoading();
            console.error(err);
          });
      }
      else {
        this.loadingScreenService.stopLoading();
      }
    },
      err => {
        this.loadingScreenService.stopLoading();
        console.error(err);
      });
  }

  IsInProgressModification(engagementId: number) {
    this.dataModification.IsInProgressDataModificationExists(engagementId, DataModificationModuleNames.EditEngagement).subscribe(
      notificationResult => {
        console.log('IsInProgressModification Engagement Id ', engagementId,
          ' Modification Status ', notificationResult);
        this.dataModificationDetails = notificationResult;
        if (notificationResult.editStatus) {
          this.ReviewInProgressEditEngagement = true;
        } else {
          this.ReviewInProgressEditEngagement = false;
        }
        this.dataModification.IsInProgressDataModificationExists(this.CurrentWorkspaceData.engagementId, DataModificationModuleNames.Questionnaire).subscribe(
          questionnaireInprogressResult => {
            this.dataModificationDetails = questionnaireInprogressResult;
            if (questionnaireInprogressResult.editStatus) {
              this.ReviewInProgressHDSession = true;
            } else {
              this.ReviewInProgressHDSession = false;
            }
            if (this.ReviewInProgressEditEngagement && this.ReviewInProgressHDSession) { // if editing on both interview or edit engagement page  
              this.dataModificationDetails.editingMembers = notificationResult.editingMembers.concat(questionnaireInprogressResult.editingMembers)
              this.dataModificationDetails.editingMembers = [...new Set(this.dataModificationDetails.editingMembers)];
              this.setEditingTeamMembers();
            } else {
              if (this.ReviewInProgressEditEngagement) {
                this.dataModificationDetails = notificationResult;
                this.setEditingTeamMembers();
              } else if (this.ReviewInProgressHDSession) {
                this.dataModificationDetails = questionnaireInprogressResult;
                this.setEditingTeamMembers();
              }
            }
          }, error => {
            this.loadingScreenService.stopLoading();
            console.error('IsInProgressModification error ', error);
          });
        this.displayReviewEngagement();
        console.log('getWorkspaceData func called ', this.CurrentWorkspaceData, this.CurrentWorkspaceData.hdWorkItemId);
        setTimeout(() => {
          $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
          $('.ui-widget-overlay.ui-front').remove();

          this.getDocumentListtoPreview();
        }, 2000
        );
      }, error => {
        console.error('IsInProgressModification error ', error);
      }
    );
  }
  setEditingTeamMembers() {
    this.skipRedirect = true;
    this.reviewInProgressEditEngagementMembers = this.dataModificationDetails.editingMembers?.join(", ");
    // if (this.dataModificationDetails.editingMembers?.length == 1) {
    this.reviewInProgressEditEngagementMembers = this.reviewInProgressEditEngagementMembers + '.';
    // } else {
    //   this.reviewInProgressEditEngagementMembers = this.reviewInProgressEditEngagementMembers + '....';
    // }
  }

  deleteClientSpaceOnEngDeclinedAbandoned() {
    this._engService.getEngagementClientSiteUrl(this.CurrentWorkspaceData.engagementId).subscribe((engagementClientSiteUrlList: string[]) => {
      const closeSiteUrl = new CloseSiteUrl();
      closeSiteUrl.siteUrls = [];
      if (engagementClientSiteUrlList !== undefined && engagementClientSiteUrlList !== null) {
        closeSiteUrl.siteUrls = engagementClientSiteUrlList;
      }
      if (closeSiteUrl.siteUrls.length > 0) {
        this._engService.closeSite(closeSiteUrl).subscribe((closeSiteStatus) => {
          console.log('Success close site');
        },
          err => {
            this.loadingScreenService.stopLoading();
            this.logService.error('Review - EngagementId ' + this.engagementId + ' Delete client space on engagemwent declined abandoned process failed.', err);
          });
      }
    },
      err => {
        this.loadingScreenService.stopLoading();
        this.logService.error('Review - EngagementId ' + this.engagementId + ' Get engagement client siteurl list failed.', err);
      });
  }

  MarkQnARecordDeleted(engagementId: number, workItemId: string) {
    let record = new QnARecordForDelete();
    record.engagementId = engagementId;
    record.workItemId = workItemId;
    record.updatedBy = this.userInfo.userName;
    this.workspaceService.MarkQnARecordAsDeleted(record).subscribe(
      result => {
        console.log('MarkQnARecordDeleted response as ', result);
      },
      error => {
        console.error('Error in MarkQnARecordDeleted', error)
      }
    )
  }

  ProcessINDUSAllTeamMembersRemoval() {
    this.loadingScreenService.startLoading();
    let azureADRoleShortNames: AzureADGroupRoleShortNamesModel = new AzureADGroupRoleShortNamesModel();
    azureADRoleShortNames.baseADGroupRoleShortName = RoleNames.INDUSConsent;
    azureADRoleShortNames.fromADGroupRoleShortName = RoleNames.GTUSINDUSALL;
    let ProcessINDUSAllTeamMembersRemovalLoaderToken = setInterval(() => this.loadingScreenService.startLoading(), 1);
    this.adUsersService.GetADGroupsDeltaByRoleShortName(azureADRoleShortNames).subscribe(
      adGroupList => {
        //this.indusADGroupsList = adGroupList;
        let indusTeamMemberDataOnFinalApproval: IndusADUsersViewModel = new IndusADUsersViewModel()
        for (let i = 0; this.teamsMembersList.teamMembers?.length > i; i++) {
          indusTeamMemberDataOnFinalApproval = {
            azureADUsers: adGroupList,
            workspaceMemberId: this.teamsMembersList.teamMembers[i].id,
            memberEmail: this.teamsMembersList.teamMembers[i].memberEmailId,
            memberName: this.teamsMembersList.teamMembers[i].memberName,
            isIndusMember: false
          };
          this.indusMembersListArrayOnFinalApproval.push(indusTeamMemberDataOnFinalApproval);
        }
        let memerList: IndusADUsersListViewModel = new IndusADUsersListViewModel();
        if (this.indusMembersListArrayOnFinalApproval != null && this.indusMembersListArrayOnFinalApproval.length > 0) {
          memerList.indusADUsersList = this.indusMembersListArrayOnFinalApproval;
          this.adUsersService.getValidIndusTeamMemberList(memerList)
            .subscribe(
              userList => {
                clearInterval(ProcessINDUSAllTeamMembersRemovalLoaderToken);
                if (userList.indusADUsersList != null && userList.indusADUsersList.length > 0) {
                  let validMembersList: TeamMembers[] = [];
                  userList.indusADUsersList.forEach(element => {
                    this.teamsMembersList.teamMembers.forEach(x => {
                      if (element.workspaceMemberId == x.id && element.isIndusMember) {
                        validMembersList.push(x);
                      }
                    });
                  });
                  let teamMembersList: TeamMemberEPModel = new TeamMemberEPModel();
                  teamMembersList.epMembers = [];
                  teamMembersList.teamMembers = validMembersList;
                  this.workspaceService.removeIndusMembers(teamMembersList).subscribe(
                    result => {
                      if (+result < 0) {
                        //setting second parameter as false
                        this._engService.GetEngagementTeamMembersById(this.CurrentWorkspaceData.engagementId, false).subscribe(
                          teamMemberResult => {
                            this.createAndUpdatePermissionsModel(teamMemberResult, this.currentEngagement.siteUniqueID);
                            this.ContinuanceEngagementCheck();
                          });
                      } else {
                        this.ContinuanceEngagementCheck();
                      }
                    },
                    error => {
                      this.loadingScreenService.stopLoading();
                      this.indusADGroupsList = null;
                      console.log('Error in getting response from RemoveIndusTemMembers func ', error);
                    });
                } else {
                  this.ContinuanceEngagementCheck();
                }
              },
              error => {
                this.loadingScreenService.stopLoading();
                this.indusADGroupsList = null;
                console.log('Error in getting response from GetIndusTeamMembers func ', error);
              }
            );
        } else {
          clearInterval(ProcessINDUSAllTeamMembersRemovalLoaderToken);
          this.ContinuanceEngagementCheck();
        }
      },
      error => {
        clearInterval(ProcessINDUSAllTeamMembersRemovalLoaderToken);
        this.loadingScreenService.stopLoading();
        this.indusADGroupsList = null;
        console.log('Error in reseting collaboration site indus flag  ', error);
      });

    if (this.isIndusFlag) {
      this.GenerateIndusConsentEvent('No');
    }
  }

  SendEngagementClosureNotification() {
    this.loadingScreenService.startLoading();
    let conveneApiLogModel: ConveneApiLogModel = new ConveneApiLogModel();
    let closureRequestModel: ClosureRequestModel = new ClosureRequestModel();
    this._engService.getRetentionSecAppliedAndSuccessorFirmFlags(this.currentEngagement.siteUniqueID).subscribe(result => {
      this._engService.getEngagementActualCloseoutInDays(result.isRetentionSectionApplied).subscribe(noofdays => {

        closureRequestModel.engageEngagementId = this.CurrentWorkspaceData.engagementId;
        closureRequestModel.closeOutInitiatedBy = this.userInfo.loggedInUserEmail;
        closureRequestModel.closingOutTimespan = noofdays.actualCloseoutInDays;
        closureRequestModel.engageEngagementStatus = this.workspaceStatusUpdateViewModel.status;

        this.closureService.Notify(closureRequestModel).subscribe(closureViewModel => {
          conveneApiLogModel.requestPayload = JSON.stringify(closureViewModel.closureNotificationRequest);
          conveneApiLogModel.responseBody = JSON.stringify(closureViewModel.closureNotificationResponse);
          conveneApiLogModel.engagementId = this.CurrentWorkspaceData.engagementId;
          conveneApiLogModel.httpMethod = RequestMethods.RequestHttpPost;
          conveneApiLogModel.requestType = ConveneRequestTypes.SendClosureNotification;
          conveneApiLogModel.loggedInUser = this.userInfo.loggedInUserEmail;
          conveneApiLogModel.status = closureViewModel.closureNotificationResponse.status;
          conveneApiLogModel.statusCode = closureViewModel.closureNotificationResponse.statusCode;
          conveneApiLogModel.statusDescription = closureViewModel.closureNotificationResponse.errorMessage;

          //  this.loadingScreenService.startLoading();
          this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
            if (affectedRows > 0) {
              //success      
              this.loadingScreenService.stopLoading();
            }
          },
            err => {
              this.loadingScreenService.stopLoading();
              console.error(err);
            });
        });
      });
    },
      (err) => {
        conveneApiLogModel.requestPayload = JSON.stringify(closureRequestModel);
        conveneApiLogModel.responseBody = JSON.stringify(err);
        conveneApiLogModel.engagementId = this.CurrentWorkspaceData.engagementId;
        conveneApiLogModel.httpMethod = RequestMethods.RequestHttpPost;
        conveneApiLogModel.requestType = ConveneRequestTypes.SendClosureNotification;
        conveneApiLogModel.isSentSuccessfully = false;
        conveneApiLogModel.loggedInUser = this.userInfo.loggedInUserEmail;
        conveneApiLogModel.status = false;
        conveneApiLogModel.statusCode = '400';
        conveneApiLogModel.statusDescription = err;
        this.loadingScreenService.startLoading();
        this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
          if (affectedRows > 0) {
            //success
          }
          this.loadingScreenService.stopLoading();
        },
          (errorDetails) => {
            this.loadingScreenService.stopLoading();
            console.log(err);
          });
      });
  }

  StoreReviewResponse() {
    let reviewResponseHtml = HD$.GetAnswerSummary();
    const responseModel = new ReviewResponseModel();
    responseModel.createdBy = this.userInfo.userName;
    responseModel.reviewResponseHtml = reviewResponseHtml;
    responseModel.engagementId = this.engagementId;
    responseModel.workItemId = this.CurrentWorkspaceData.hdWorkItemId;
    responseModel.workspaceId = this.CurrentWorkspaceData.workspaceId;
    responseModel.isUpdatedQuestionnaire = this.currentEngagement.appliedTnC;
    this.workspaceService.storeReviewResponseLog(responseModel).subscribe(
      result => {
        console.log('storeReviewResponseLog func ', result);
      },
      error => {
        console.error('storeReviewResponseLog func ', error);
        this.workspaceService.storeErrorLog("WorkspaceId ::" + this.CurrentWorkspaceData.workspaceId + " Message ::" + error).subscribe
          (
            result => { console.log(result); }
          );
      }
    );
  }

  loadClientTeamMembersByEngagementId(engId: number, isMsgDisplay: boolean = false) {
    this._engService.GetClientTeamMembersByEngagementId(engId)
      .subscribe(
        clientTeamMembers => {
          this.teamsMembersList.clientTeamMemberList = clientTeamMembers;
          console.log('clientTeamMemberList==>', this.teamsMembersList.clientTeamMemberList);
          if (this.teamsMembersList.clientTeamMemberList.length > 0) {
            $("#clientTeamMemberDiv").show();
          }
          else {
            $("#clientTeamMemberDiv").hide();
          }
          this.callPowerAutomateToProcessClientMemberNotification()
        },
        () => {
          this.teamsMembersList.clientTeamMemberList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }

  callPowerAutomateToProcessClientMemberNotification() {
    let powerAutomateRequestModel = new PowerAutomateRequestModel();
    powerAutomateRequestModel.engageEnviornment = "";
    powerAutomateRequestModel.collaborationSiteId = this.currentEngagement.collaborationSiteId;

    this._engService.GeneratePowerAutomateTriggerOnClientUserCreated(powerAutomateRequestModel)
      .subscribe(
        resultString => {
          console.log('GeneratePowerAutomateTriggerOnClientUserCreated=>', resultString);
          this.resetClientTeamMemberPopup();
          $("#modal-addClientTeamMembers").modal('hide');
          this.loadingScreenService.stopLoading();
        },
        () => {
          this.teamsMembersList.clientTeamMemberList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }

  getClientTeamMembersByEngagementId(engId: number, isMsgDisplay: boolean = false) {
    this.loadingScreenService.startLoading();
    return this._engService.GetClientTeamMembersByEngagementId(engId)
      .subscribe(
        clientTeamMembers => {
          this.teamsMembersList.clientTeamMemberList = clientTeamMembers;
          console.log('clientTeamMemberList==>', this.teamsMembersList.clientTeamMemberList);
          if (this.teamsMembersList.clientTeamMemberList.length > 0) {
            $("#clientTeamMemberDiv").show();
          }
          else {
            $("#clientTeamMemberDiv").hide();
          }

          //1. <== Start process to build sharepoint update permission modal ====>
          this.collaborationSiteMembersList = null;
          setTimeout(() => {
            this.workspaceService.getCollabrationTeamMembers(engId).subscribe(
              collabrationTeamMemberResult => {
                this.collaborationSiteMembersList = collabrationTeamMemberResult;
                if (this.collaborationSiteMembersList != null) {
                  let siteMembersGroups = this.collaborationSiteMembersList.reduce((r, a) => {
                    r[a.memberPermissionId] = [...r[a.memberPermissionId] || [], a];
                    return r;
                  }, {});

                  console.log("siteMembersGroups", siteMembersGroups);
                  let onwwerList: CollaborationSiteMembers[] = [];
                  let memberList: CollaborationSiteMembers[] = [];

                  let siteGroupTypes = Object.keys(siteMembersGroups);

                  siteGroupTypes.forEach(type => {
                    if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                      onwwerList = this.collaborationSiteMembersList.filter(member =>
                        Number(member.memberPermissionId) === Number(type));
                    } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                      memberList = this.collaborationSiteMembersList.filter(member =>
                        Number(member.memberPermissionId) === Number(type));
                    }
                  })

                  // Site Onwers Email List
                  let siteOwnerEmailIds: string[] = [];
                  onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

                  //Site Members Email List
                  let siteMemberEmailIds: string[] = [];
                  memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); })

                  let collbSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                  let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
                  let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();

                  OwnerListEmailModel.groupType = 'Owner';
                  OwnerListEmailModel.groupUsersEmail = [];
                  siteOwnerEmailIds.forEach(emailid => {
                    let adUser: ADUser = new ADUser();
                    adUser.firstName = '';
                    adUser.lastName = '';
                    adUser.userEmailId = emailid;
                    OwnerListEmailModel.groupUsersEmail.push(adUser);
                  })

                  MemberListEmailModel.groupType = 'Member';
                  MemberListEmailModel.groupUsersEmail = [];
                  siteMemberEmailIds.forEach(emailid => {
                    let adUser: ADUser = new ADUser();
                    adUser.firstName = '';
                    adUser.lastName = '';
                    adUser.userEmailId = emailid;
                    MemberListEmailModel.groupUsersEmail.push(adUser);
                  })
                  collbSiteUsersUpdatePermissionModel.siteType = "EngagementSite";
                  collbSiteUsersUpdatePermissionModel.siteUniqueID = this.currentEngagement.siteUniqueID;
                  collbSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
                  collbSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
                  let updatePermissionsModelList: UpdatePermissionsModel[] = [];
                  updatePermissionsModelList.push(collbSiteUsersUpdatePermissionModel);
                  //1. <== End  process to build sharepoint update permission modal for Team member ===>
                  // Assemble UpdatePermissionModel  //

                  // Get List of Groups based on Permission Drop down(R/RW/RWD) //
                  let clientSiteMembersGroups = this.teamsMembersList.clientTeamMemberList.reduce((r, a) => {
                    r[a.permissionId] = [...r[a.permissionId] || [], a];
                    return r;
                  }, {});

                  console.log("clientSiteMembersGroups", clientSiteMembersGroups);

                  let RPermissionUserList: ClientTeamMembers[] = [];
                  let RWPermissionUserList: ClientTeamMembers[] = [];
                  let RWDPermissionUserList: ClientTeamMembers[] = [];

                  let clientSiteGroupTypes = Object.keys(clientSiteMembersGroups);

                  clientSiteGroupTypes.forEach(type => {
                    if ((this.clientMemberPermissionModelList.filter(permission => permission.id == Number(type))[0].code) === 'R') {
                      RPermissionUserList = this.teamsMembersList.clientTeamMemberList.filter(member =>
                        Number(member.permissionId) === Number(type));
                    } else if ((this.clientMemberPermissionModelList.filter(pr => pr.id == Number(type))[0].code) === 'RW') {
                      RWPermissionUserList = this.teamsMembersList.clientTeamMemberList.filter(member =>
                        Number(member.permissionId) === Number(type));
                    } else if ((this.clientMemberPermissionModelList.filter(pr => pr.id == Number(type))[0].code) === 'RWD') {
                      RWDPermissionUserList = this.teamsMembersList.clientTeamMemberList.filter(member =>
                        Number(member.permissionId) === Number(type));
                    }
                  })

                  // Assemble UpdatePermissionModel  //
                  let clientSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
                  let RUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();
                  let RWUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();
                  let RWDUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();

                  // Collect client site READ Permission User Email ID List //
                  RUserEmailIdModel.groupType = 'R';
                  RUserEmailIdModel.groupUsersEmail = [];
                  RPermissionUserList.forEach(rUser => {
                    let adUser: ADUser = new ADUser();
                    adUser.firstName = rUser.firstName;
                    adUser.lastName = rUser.lastName;
                    adUser.userEmailId = rUser.emailId;
                    RUserEmailIdModel.groupUsersEmail.push(adUser);
                  })

                  // Collect client site READ/WRITE Permission User Email ID List //
                  RWUserEmailIdModel.groupType = 'RW';
                  RWUserEmailIdModel.groupUsersEmail = [];
                  RWPermissionUserList.forEach(rwUser => {
                    let adUser: ADUser = new ADUser();
                    adUser.firstName = rwUser.firstName;
                    adUser.lastName = rwUser.lastName;
                    adUser.userEmailId = rwUser.emailId;
                    RWUserEmailIdModel.groupUsersEmail.push(adUser);
                  })

                  // Collect client site READ/WRITE/DELETE Permission User Email ID List //
                  RWDUserEmailIdModel.groupType = 'RWD';
                  RWDUserEmailIdModel.groupUsersEmail = [];
                  RWDPermissionUserList.forEach(rwdUser => {
                    let adUser: ADUser = new ADUser();
                    adUser.firstName = rwdUser.firstName;
                    adUser.lastName = rwdUser.lastName;
                    adUser.userEmailId = rwdUser.emailId;
                    RWDUserEmailIdModel.groupUsersEmail.push(adUser);
                  })

                  clientSiteUsersUpdatePermissionModel.siteType = "ClientSite";
                  clientSiteUsersUpdatePermissionModel.siteUniqueID = "CS-" + this.currentEngagement.siteUniqueID;
                  clientSiteUsersUpdatePermissionModel.usersListEmail.push(RUserEmailIdModel);
                  clientSiteUsersUpdatePermissionModel.usersListEmail.push(RWUserEmailIdModel);
                  clientSiteUsersUpdatePermissionModel.usersListEmail.push(RWDUserEmailIdModel);
                  updatePermissionsModelList.push(clientSiteUsersUpdatePermissionModel);
                  //2. <== End process for ClientSite =========================================>
                  console.log('updatePermissionsModelList', updatePermissionsModelList);

                  // Button Update Collbration
                  //4. Call Active Directory API to update latest collaborationSite Member Ids                    
                  let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
                  updatePermissionsModelData.updatePermissionsModel = updatePermissionsModelList;
                  this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
                    result => {
                      if (isMsgDisplay) {
                        // $('#member-save-success').removeClass('d-none');                        
                        // this.newClientTeamMemberList = [];
                        // this.arrValidationForNewClientTeamMember = [];
                        //this.addClientTeamMember();
                        this.resetClientTeamMemberPopup();
                        $("#modal-addClientTeamMembers").modal('hide');
                      }
                      this.loadingScreenService.stopLoading();
                    },
                    error => {
                      this.loadingScreenService.stopLoading();
                      this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                    });
                }
              },
              () => {
                this.teamsMembersList = null;
                this.teamMembersListArray = null;
              }
            );
          }, 100);
          //END        
        },
        () => {
          this.teamsMembersList.clientTeamMemberList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }

  getClientMemberPermissions() {
    this.applicationMasterService.GetClientMemberPermissions().subscribe(
      result => {
        this.clientMemberPermissions = result;
        this.clientMemberPermissionModelList = [];
        this.clientMemberPermissions.forEach(pr => {
          let codeValueArr = pr.typeValue.split('|');
          let tempModel: ClientMemberPermissionModel = new ClientMemberPermissionModel();
          tempModel.id = pr.id;
          tempModel.code = codeValueArr[0];
          tempModel.typeValue = codeValueArr[1];
          this.clientMemberPermissionModelList.push(tempModel);
          if (codeValueArr[0] === 'R') {
            this.defaultClientPermissionId = pr.id;
          }
        })
        console.log('clientMemberPermissionModelList', this.clientMemberPermissionModelList);
      }
    );
  }

  removeclientTeamMember(clientTeamMemberId: number) {
    this.loadingScreenService.startLoading();
    let removeClientTeamMemberModel: RemoveClientTeamMemberModel = new RemoveClientTeamMemberModel();
    removeClientTeamMemberModel.clientTeamMemberId = clientTeamMemberId;
    removeClientTeamMemberModel.loggedInUser = this.userInfo.userName;
    removeClientTeamMemberModel.loggedInUserFullName = this.userInfo.loggedInUserFullName;

    this._engService.RemoveClientTeamMember(removeClientTeamMemberModel).subscribe(
      updateClientTeamMemmbersResult => {

        if (updateClientTeamMemmbersResult === 'Success') {
          this.getClientTeamMembersByEngagementId(this.CurrentWorkspaceData.engagementId, false);

        } else if (updateClientTeamMemmbersResult === 'Failure') {
          this.alertServiceFun('client team member could not be removed !', 'danger');
        }
      },
      () => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Error while removing client team member !', 'danger');
      }
    );
  }

  removeClientNewTeamMember(index) {
    this.newClientTeamMemberList.splice(index, 1);
    this.arrValidationForNewClientTeamMember.splice(index, 1);
    this.arrValidationForNewClientTeamMember[index].isInvalidClientTeamMemberEmail = false;
    this._alertService.remove(this.alert);
    return true;
  }

  refreshClientNewTeamMemberPopup() {
    if ((this.currentEngagement.siteRequestStatus === 'Completed' || this.currentEngagement.siteRequestStatus === 'PartialCompleted')) {
      this.CloseReviewNotesPanel();
      this.resetClientTeamMemberPopup();
      this.addClientTeamMember();
      $('#clientErrorMessage').text('');
      $('#modal-addClientTeamMembers').modal('show')
    }
    if (!(this.currentEngagement.siteRequestStatus === 'Completed' || this.currentEngagement.siteRequestStatus === 'PartialCompleted')) {
      $('#modal-addClientTeamMembers').modal('hide');
      $('#clientErrorMessage').text('Site provisioning in progress.');
    }
  }


  addClientTeamMember() {
    if (this.newClientTeamMemberList != null) {
      this._alertService.remove(this.alert);
      this.newClientTeamMember = {
        engagementId: this.currentEngagement.engagementId,
        collaborationSiteId: this.currentEngagement.collaborationSiteId,
        firstName: '',
        lastName: '',
        emailId: '',
        title: '',
        permissionId: this.defaultClientPermissionId
      };

      this.newClientTeamMemberList.push(this.newClientTeamMember);

      const ValidNewClientTeamMember = {
        firstName: '',
        lastName: '',
        emailId: '',
        title: '',
        permissionId: 0,
        collaborationSiteId: 0,
        isNewUserDuplicate: false,
        isInvalidClientTeamMemberEmail: false,
        cmsDataMatchClient: false,
        IsValidInputClient: false
      };
      this.arrValidationForNewClientTeamMember.push(ValidNewClientTeamMember);
    }
  }

  SaveClientTeamMember() {
    this._alertService.remove(this.alert);
    this.loadingScreenService.startLoading();
    $('#member-save-success').addClass('d-none');
    $('#manageTeam-close-cancel-cap').text('Close');
    // <===== Client Team Member Validation Process Start =====>
    this.TeamMemberManipulationTrigger = true;
    this.cmsDataMatchClient = false;
    this.IsValidInputClient = true;
    this.clientMmembersModelList = [];
    if (this.newClientTeamMemberList.length > 0) {
      // Check Control Validations
      for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
        this.arrValidationForNewClientTeamMember[t].firstName = $('#txtFirstName_' + t).val();
        this.arrValidationForNewClientTeamMember[t].lastName = $('#txtLastName_' + t).val();
        this.arrValidationForNewClientTeamMember[t].emailId = $('#txtClientEmail_' + t).val();
        this.arrValidationForNewClientTeamMember[t].clientEmailId = $('#txtconfirmClientEmail_' + t).val();
        this.arrValidationForNewClientTeamMember[t].title = $('#txtClientTitle_' + t).val();
        this.arrValidationForNewClientTeamMember[t].permissionId = $('#drpClientTeamMemberPermissions_' + t).val();
        this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = false;
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
        this.arrValidationForNewClientTeamMember[t].IsValidInputClient = false;

        if (this.arrValidationForNewClientTeamMember[t].firstName === '') {
          $('#errorLabelFirstName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelFirstName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].lastName === '') {
          $('#errorLabelLastName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelLastName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].emailId === '') {
          $('#errorLabelEmail' + t).attr('style', 'display:block');
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        } else {
          $('#errorLabelEmail' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
          $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
          if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
            this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
            this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
            $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
            $('#userExistClient' + t).attr('style', 'display:none');

          } else {
            this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
            if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
              $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
              $('#userExistClient' + t).attr('style', 'display:none');
              this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
            }
            else {
              $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
              $('#userExistClient' + t).attr('style', 'display:none');
            }
          }
        }
        else {
          $('#errorLabelConfirmEmail' + t).attr('style', 'display:block');
          $('#userExistClient' + t).attr('style', 'display:none');
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }

        if (this.arrValidationForNewClientTeamMember[t].permissionId === null) {
          $('#errorLabelClientTeamMemberPermission' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelClientTeamMemberPermission' + t).attr('style', 'display:none');
        }

        if (this.arrValidationForNewClientTeamMember[t].firstName === '' || this.arrValidationForNewClientTeamMember[t].lastName === ''
          || this.arrValidationForNewClientTeamMember[t].emailId === ''
          || (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId)
          || this.arrValidationForNewClientTeamMember[t].permissionId === null) {
          this.arrValidationForNewClientTeamMember[t].IsValidInputClient = false;
          //this.IsValidInputClient = false;
        } else {
          // this.IsValidInputClient = true; 
          this.arrValidationForNewClientTeamMember[t].IsValidInputClient = true;
        }

        if (!this.checkValidEmail(this.arrValidationForNewClientTeamMember[t].emailId)) {
          //this.IsValidInputClient = false;         
          this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = true;
        } else {
          this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = false;
        }
      }
      // check duplicate emailid Recrods
      for (let t = 0; t < this.arrValidationForNewClientTeamMember.length; t++) {
        if (this.CheckDuplicateElement(this.arrValidationForNewClientTeamMember, 'emailId',
          this.arrValidationForNewClientTeamMember[t].emailId)) {
          this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = true;
          //this.IsValidInputClient = false;
        } else {
          this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
        }
      }

      // check if new client member email is matching with existing  client team member email.
      if (this.teamsMembersList.clientTeamMemberList != undefined) {

        for (let j = 0; j < this.teamsMembersList.clientTeamMemberList.length; j++) {
          for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
            if ($('#txtClientEmail_' + t).val() != undefined) {
              if ($('#txtClientEmail_' + t).val().toLowerCase() ===
                this.teamsMembersList.clientTeamMemberList[j].emailId.toLowerCase()
                && this.teamsMembersList.clientTeamMemberList[j].collaborationSiteId == this.currentEngagement.collaborationSiteId) {
                this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = true;
                // this.cmsDataMatchClient = true;
                $('#userExist' + t).attr('style', 'display:block');
                break;
              }
            }

            if (this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient === false) {
              $('#userExist' + t).attr('style', 'display:none');
            }
          }  //newClientTeamMemberList
        }//clientTeamMemberList
      }

      if (this.arrValidationForNewClientTeamMember.some(item => item.isInvalidClientTeamMemberEmail === true)
        ||
        this.arrValidationForNewClientTeamMember.some(item => item.IsValidInputClient === false)) {
        this.IsValidInputClient = false;
      }

      if (this.arrValidationForNewClientTeamMember.some(item => item.cmsDataMatchClient === true)) {
        this.cmsDataMatchClient = true;
      }

      if (this.IsValidInputClient && !this.cmsDataMatchClient) {

        if (this.newClientTeamMemberList != null && this.newClientTeamMemberList.length > 0) {
          for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
            let clientMmembersModel: ClientMmembersModel = {
              engagementId: this.currentEngagement.engagementId,
              collaborationSiteId: this.currentEngagement.collaborationSiteId,
              firstName: $('#txtFirstName_' + t).val(),
              lastName: $('#txtLastName_' + t).val(),
              emailId: $('#txtClientEmail_' + t).val(),
              title: this.newClientTeamMemberList[t].title,
              permissionId: this.newClientTeamMemberList[t].permissionId,
              invitationurl: ''
            }
            this.clientMmembersModelList.push(clientMmembersModel);
          }
        }

        this.addClientMmembersModel.loggedInUser = this.userInfo.userName;
        this.addClientMmembersModel.loggedInUserFullName = this.userInfo.loggedInUserFullName;
        this.addClientMmembersModel.clientMmembersModelList = this.clientMmembersModelList;

        this._engService.AddClientTeamMembers(this.addClientMmembersModel)
          .subscribe(
            updateResult => {
              console.log('AddClientTeamMembers Result', updateResult);
              if (updateResult === 'Success') {
                this.loadClientTeamMembersByEngagementId(this.CurrentWorkspaceData.engagementId, true);
              } else if (updateResult === 'Failure') {
                this.alertServiceFun('client team member(s) could not be added !', 'danger');
              }

            },
            error => {
              this.loadingScreenService.stopLoading();
              this.alertServiceFun('Error while adding client team member(s) !', 'danger');
            });

      } // end check for cmsmatchdata and valid input
      else {
        this.loadingScreenService.stopLoading();
      }

    } // Top if newClientTeamMemberList.length > 0
  }

  checkValidEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(email))
      return true;
    else
      return false;
  }
  validateConfirmEmail(clientEmailId: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(clientEmailId)) {
      return true;
    } else {
      return false;
    }
  }

  resetClientTeamMemberPopup() {
    this.newClientTeamMemberList = [];
    this.arrValidationForNewClientTeamMember = [];
    this.clientMmembersModelList = [];
    $('#member-save-success').addClass('d-none');
  }

  private CheckDuplicateElement(array, property, value) {
    let foundElements: any = [];
    let elCount = 0;
    let isDuplicate = false;
    for (let element of array) {

      //toLowerCase()
      if (element[property] != null && value != null && value != '') {
        if (element[property].toLowerCase() === value.toLowerCase()) {
          elCount = elCount + 1;
          if (elCount === 1) {
            foundElements.push(element);
          } else if (elCount > 1) {
            isDuplicate = true;
            break;
          }
        }
      }

    }
    return isDuplicate;
  }

  private applyAbandonSitePolicy() {
    this.loadingScreenService.startLoading();
    if (this.currentEngagement.siteUrl != '' && this.currentEngagement.siteUrl != null) {
      this._sharepointService.ApplyAbandonSitePolicy(this.currentEngagement.siteUrl).subscribe(status => {
        this.loadingScreenService.stopLoading();
      },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    }
  }
  async DisplayReviewNotes() {
    this.data.setCollapsibleLeftNav(true);
    this.data.setCloseReviewNotes(false);
    $('.containerDiv').addClass('compressRevContainer');
    $('#btnEditReviewContainer').addClass('colEditReviewContainer');
    $('#btnDecAppReviewContainer').addClass('colDecAppReviewContainer');
    $('#btnTeamReviewContainer').addClass('colTeamReviewContainer');
    $('#delDocumentBtn').addClass('colDelDocumentBtn');
    $('.add-review-section').addClass('addProf');

    //Load review notes component
    this.loadingScreenService.startLoading();
    setTimeout(() => {
      const componentFactorya = this.cfr.resolveComponentFactory(ReviewNotesComponent);
      this.reviewNoteComponentRef = this.viewContainerRef.createComponent(componentFactorya);
      this.reviewNoteComponentRef.instance.isUserEP = this.isUserEP;
      this.reviewNoteComponentRef.instance.isUserRP = this.isUserRP;
      this.reviewNoteComponentRef.instance.isPureNotificationOnlyProfessional = this.isPureNotificationOnlyProfessional;
      this.reviewNoteComponentRef.instance.isUserTM = this.isUserTM;
      this.reviewNoteComponentRef.instance.reviewId = this.engagementId;
      this.reviewNoteComponentRef.instance.reviewNoteSearchableUsers = this.reviewNoteSearchableUsers;
      this.reviewNoteComponentRef.instance.selectedWorkspaceDocumentId = this.selectedWorkspaceDocumentItem.documentAliasName;
      this.reviewNoteComponentRef.instance.showReviewNoteModal();
      this.loadingScreenService.stopLoading();

      this.reviewNoteComponentRef.instance.closeReviewNotesModal.subscribe(isTrue => {
        this.loadingScreenService.stopLoading();
        this.viewContainerRef.clear();
      });

    }, 1000);
  }

  getWorkspaceReviewPointsCount() {
    this.workspaceReviewPointService.getReviewNotesCount().subscribe(points => {
      this.workspaceOpenReviewPointsCount = points;
    });
  }

  get ActionPopoverPositioningCSSClass(): string {
    let displayMultiDocsAddOption = (this.workspaceModel.isAllowMultipleTemplates
      && !this.isAddDocumentLimitExceeded && !this.currentEngagement.appliedTnC
      && this.workspaceModel.status != 'Declined' && this.workspaceModel.status != 'Abandoned'
      && this.currentEngagement.status != 'RP rule engine error'
      && !this.isPureNotificationOnlyProfessional && !this.isPureRP)
    if (!this.workspaceOpenReviewPointsCount || this.workspaceOpenReviewPointsCount == 0) {
      this.CreateReportdropdown('review-note-count-0');
      return displayMultiDocsAddOption ? 'review-note-count-0-multi-docs' : 'review-note-count-0';
    }
    else if (this.workspaceOpenReviewPointsCount == 1) {
      this.CreateReportdropdown('review-note-count-1');
      return displayMultiDocsAddOption ? 'review-note-count-1-multi-docs' : 'review-note-count-1';
    }
    else if (this.workspaceOpenReviewPointsCount > 1 && this.workspaceOpenReviewPointsCount < 10) {
      this.CreateReportdropdown('review-note-count-2-9');
      return displayMultiDocsAddOption ? 'review-note-count-2-9-multi-docs' : 'review-note-count-2-9';
    }
    else if (this.workspaceOpenReviewPointsCount > 9 && this.workspaceOpenReviewPointsCount < 100) {
      this.CreateReportdropdown('review-note-count-10-99');
      return displayMultiDocsAddOption ? 'review-note-count-10-99-multi-docs' : 'review-note-count-10-99';
    }
    else if (this.workspaceOpenReviewPointsCount > 99 && this.workspaceOpenReviewPointsCount < 1000) {
      this.CreateReportdropdown('review-note-count-100-999');
      return displayMultiDocsAddOption ? 'review-note-count-100-999-multi-docs' : 'review-note-count-100-999';
    }
  }
  get ActionPopoverArrowPositioningCSSClass(): string {
    let displayMultiDocsAddOption = (this.workspaceModel.isAllowMultipleTemplates &&
      !this.isAddDocumentLimitExceeded && !this.currentEngagement.appliedTnC
      && this.workspaceModel.status != 'Declined' && this.workspaceModel.status != 'Abandoned'
      && this.currentEngagement.status != 'RP rule engine error'
      && !this.isPureNotificationOnlyProfessional && !this.isPureRP)
    if (!this.workspaceOpenReviewPointsCount || this.workspaceOpenReviewPointsCount == 0) {
      return displayMultiDocsAddOption ? 'review-note-upper-arrow-count-0-multi-docs' : 'review-note-upper-arrow-count-0';
    }
    else if (this.workspaceOpenReviewPointsCount == 1) {
      return displayMultiDocsAddOption ? 'review-note-upper-arrow-count-1-multi-docs' : 'review-note-upper-arrow-count-1';
    }
    else if (this.workspaceOpenReviewPointsCount > 1 && this.workspaceOpenReviewPointsCount < 10) {
      return displayMultiDocsAddOption ? 'review-note-upper-arrow-count-2-9-multi-docs' : 'review-note-upper-arrow-count-2-9';
    }
    else if (this.workspaceOpenReviewPointsCount > 9 && this.workspaceOpenReviewPointsCount < 100) {
      return displayMultiDocsAddOption ? 'review-note-upper-arrow-count-10-99-multi-docs' : 'review-note-upper-arrow-count-10-99';
    }
    else if (this.workspaceOpenReviewPointsCount > 99 && this.workspaceOpenReviewPointsCount < 1000) {
      return displayMultiDocsAddOption ? 'review-note-upper-arrow-count-100-999-multi-docs' : 'review-note-upper-arrow-count-100-999';
    }
  }

  //#region add workspace document 
  async DisplayAddWorkspaceDocument() {
    this.CloseReviewNotesPanel();
    //Load add workspace document component
    const componentFactorya = this.cfr.resolveComponentFactory(AddWorkspaceDocumentComponent);
    this.addWorkspaceDocumentComponentRef = this.viewContainerRef.createComponent(componentFactorya);
    this.addWorkspaceDocumentComponentRef.instance.engagementId = this.engagementId;
    this.addWorkspaceDocumentComponentRef.instance.siteUniqueID = this.currentEngagement.siteUniqueID;
    this.addWorkspaceDocumentComponentRef.instance.parentSolutionCodeId = this.currentEngagement.parentSolutionCodeId;
    this.addWorkspaceDocumentComponentRef.instance.showAddWorkspaceDocumentModal();

    this.addWorkspaceDocumentComponentRef.instance.closeAddWorkspaceDocument.subscribe(isTrue => {
      this.viewContainerRef.clear();
    });
  }
  //#endregion

  generateMultipleSummaryReports(multipleDocumentListIndex: number) {

    if ((multipleDocumentListIndex + 1) <= this.workspaceDocumentList?.length) {

      if (this.currentEngagement.appliedTnC) {
        this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.filter(document => document.isPrimaryWorkspaceId)[0];
      }
      else {
        this.selectedWorkspaceDocumentItem = this.workspaceDocumentList[multipleDocumentListIndex];
      }

      this.currentDocumentProcessingIndex = multipleDocumentListIndex;
      this.currentlyProcessingEngageSummaryReportName = this.selectedWorkspaceDocumentItem.documentAliasName;
      this.PreviewDocument(this.selectedWorkspaceDocumentItem.workspaceId, this.selectedWorkspaceDocumentItem.documentId, this.selectedWorkspaceDocumentItem.workItemId);
      this.QuestionSummaryDisplay();
      console.log('CurrentWorkspaceData', this.selectedWorkspaceDocumentItem);

      if (!this.multipleReportGenerationInProgress) {
        this.multipleDocumentGenerationLoaderToken = setInterval(() => this.loadingScreenService.startLoading(), 1);
        this.SetWorkspaceIdForEngageSummaryReport(false);
      }

      this.multipleReportGenerationInProgress = true;
    }
  }

  GetCloseoutConfigurations() {
    this.loadingScreenService.startLoading();
    let closeoutConfigurationRequest: CloseoutConfigurationRequest = {
      solutionCode: this.workspaceModel.primarySolutionCode
    };
    this._engService.GetSolutionCodeConfigFeatureFlag().subscribe(result => {
      this.IsSolutionCodeConfigFeatureEnabled = result;
      if (result) {
        this.decisionRuleEngineService.GetSolutionCodeProperties(closeoutConfigurationRequest).subscribe(configurations => {
          this.solutionCodeConfigResponse.engagementId = this.currentEngagement.engagementId;
          this.solutionCodeConfigResponse.loggedInUser = this.userInfo.userName;
          this.solutionCodeConfigResponse.sectioncodes = configurations.solutionCodeProperties.sectionCodes;
          // configurations.engagementId = this.currentEngagement.engagementId;
          // configurations.loggedInUser = this.userInfo.userName;
          this._engService.AddEngagementCloseoutConfigurationsFromDecision(this.solutionCodeConfigResponse).subscribe(status => {

            this.loadingScreenService.stopLoading();
          },
            error => {
              this.loadingScreenService.stopLoading();
              console.error(error);
            }
          );


        },
          error => {
            this.loadingScreenService.stopLoading();
          }
        );
      }
      else {
        this.decisionRuleEngineService.GetCloseoutConfigurations(closeoutConfigurationRequest).subscribe(configurations => {

          configurations.engagementId = this.currentEngagement.engagementId;
          configurations.loggedInUser = this.userInfo.userName;
          this._engService.AddEngagementCloseoutConfigurations(configurations).subscribe(status => {
            this.loadingScreenService.stopLoading();
          },
            error => {
              this.loadingScreenService.stopLoading();
              console.error(error);
            });
        },
          error => {
            this.loadingScreenService.stopLoading();
          });
      }
    });

  }

  UpdateValidationArrayForNewClientTeamMember() {
    for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
      this.arrValidationForNewClientTeamMember[t].firstName = $('#txtFirstName_' + t).val();
      this.arrValidationForNewClientTeamMember[t].lastName = $('#txtLastName_' + t).val();
      this.arrValidationForNewClientTeamMember[t].emailId = $('#txtClientEmail_' + t).val();
      this.arrValidationForNewClientTeamMember[t].clientEmailId = $('#txtconfirmClientEmail_' + t).val();
      this.arrValidationForNewClientTeamMember[t].title = $('#txtClientTitle_' + t).val();
      this.arrValidationForNewClientTeamMember[t].permissionId = $('#drpClientTeamMemberPermissions_' + t).val();
      this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = false;
      this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
      this.arrValidationForNewClientTeamMember[t].IsValidInputClient = false;
    }
  }

  ValidateClientEmailField(t, siteId) {
    this.UpdateValidationArrayForNewClientTeamMember();

    if (this.arrValidationForNewClientTeamMember[t].emailId === '') {
      $('#errorLabelEmail' + t).attr('style', 'display:block');
      $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
    } else {
      $('#errorLabelEmail' + t).attr('style', 'display:none');
    }

    if (!this.checkValidEmail(this.arrValidationForNewClientTeamMember[t].emailId)) {
      //this.IsValidInputClient = false;         
      this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = true;
    } else {
      this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = false;
    }

    this.ValidateClientEmailAlreadyExist();

    //Confirm email check
    if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
      if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
        this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');

      } else {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
        if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }
        else {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        }
      }
    }
  }

  ValidateClientEmailAlreadyExist() {
    // check if new client member email is matching with existing  client team member email.
    if (this.teamsMembersList.clientTeamMemberList != undefined) {

      for (let j = 0; j < this.teamsMembersList.clientTeamMemberList.length; j++) {
        for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
          if ($('#txtClientEmail_' + t).val() != undefined) {
            if ($('#txtClientEmail_' + t).val().toLowerCase() ===
              this.teamsMembersList.clientTeamMemberList[j].emailId.toLowerCase()
              && this.teamsMembersList.clientTeamMemberList[j].collaborationSiteId == this.currentEngagement.collaborationSiteId) {
              this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = true;
              // this.cmsDataMatchClient = true;
              $('#userExist' + t).attr('style', 'display:block');
              break;
            }
          }

          if (this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient === false) {
            $('#userExist' + t).attr('style', 'display:none');
          }
        }  //newClientTeamMemberList
      }//clientTeamMemberList
    }

    // check duplicate emailid Recrods
    for (let t = 0; t < this.arrValidationForNewClientTeamMember.length; t++) {
      if (this.CheckDuplicateElement(this.arrValidationForNewClientTeamMember, 'emailId',
        this.arrValidationForNewClientTeamMember[t].emailId)) {
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = true;
        //this.IsValidInputClient = false;
      } else {
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
      }
    }
  }

  ValidateConfirmClientEmailField(t) {
    this.UpdateValidationArrayForNewClientTeamMember();

    if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
      if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
        this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        $('#userExistClient' + t).attr('style', 'display:none');

      } else {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
        if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
          $('#userExistClient' + t).attr('style', 'display:none');
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }
        else {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          $('#userExistClient' + t).attr('style', 'display:none');
        }
      }
    }
    else {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:block');
      $('#userExistClient' + t).attr('style', 'display:none');
      $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
      this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
    }

    this.ValidateClientEmailAlreadyExist();
  }

  /*Sharepoint Lable Feature */
  displaySharepointFileLabelPopup(document: SPDocumentList) {  //UI event on file select
    this.CloseReviewNotesPanel();
    this.sharePointSelectedFile = document;
    this.sharePointSelectedFileLabel = document.label;
    this.sharePointAddFileFlag = false;
    $('#modal-SharepointFileLabel').modal({ backdrop: 'static', keyboard: false });
    $("#modal-SharepointFileLabel").modal('show');
  }
  okSharepointFileLabel(labelName: string) {
    this.sharePointSelectedFileLabel = labelName;
    if (this.sharePointAddFileFlag == false) {
      let workspace = new WorkspaceSupplementalDoc();
      workspace.workspaceId = this.currentEngagement.primaryWorkspaceId;
      workspace.sourceFilePath = this.sharePointSelectedFile.documentUrl;
      workspace.sourceFileName = this.sharePointSelectedFile.documentName;
      workspace.label = this.sharePointSelectedFileLabel;
      workspace.createdby = this.userInfo.userName;
      workspace.WorkspaceSupplementalDocId = this.sharePointSelectedFile.documentId;
      this.addSharepointFileLabel(workspace);
      $("#modal-SharepointFileLabel").modal('hide');
    }
    else {
      $("#modal-SharepointFileLabel").modal('hide');
      $("#input-file").click();
    }
  }
  sharePointLabelSelected(labelName: string) {
    this.sharePointSelectedFileLabel = labelName;
  }
  AddNewFileClicked() {
    this.CloseReviewNotesPanel();
    if (this.isSharepointFileLabelFeatureFlagEnabled) {
      this.sharePointSelectedFileLabel = "";
      this.sharePointSelectedFileLabelForFilter = "All";
      this.sharePointAddFileFlag = true;
      $('#modal-SharepointFileLabel').modal({ backdrop: 'static', keyboard: false });
      $("#modal-SharepointFileLabel").modal('show');
    }
    else {
      $("#input-file").click();
    }

  }
  cancelSharepointFileLabel() {
    this.clearSharepointFileUploadData();
  }
  clearSharepointFileUploadData() {
    this.sharePointSelectedFile = undefined;
    this.sharePointSelectedFileLabel = "";
    this.sharePointAddFileFlag == false;
  }
  attacheLabelToFiles() {
    this.workspaceService.GetSharepointFiles(this.currentEngagement.primaryWorkspaceId).subscribe(workspaceDocs => {
      if (workspaceDocs && workspaceDocs.length > 0)
        for (let i = 0; i < this.supplementalDocumentList?.length; i++) {
          workspaceDocs.forEach(workspaceDoc => {
            if (workspaceDoc.sourceFileName == this.supplementalDocumentList[i].documentName && workspaceDoc.sourceFilePath == this.supplementalDocumentList[i].documentUrl) {
              this.supplementalDocumentList[i].label = workspaceDoc.label;
              this.supplementalDocumentList[i].workspaceId = workspaceDoc.workspaceId;
              return;
            }
          });
        }
      this.updateSpFilterFiles();
    }, error => {
      console.error("unable to load sharepoint label data", error);
    })
  }
  updateSpFilterFiles() {
    let unlabelFound = false;
    this.sharePointApplicationMasterLabelsFiltered = []
    this.supplementalDocumentList.forEach(spdoc => {
      this.sharePointApplicationMasterLabels.forEach(spAml => {
        if (spdoc.label == spAml.value) {
          this.sharePointApplicationMasterLabelsFiltered.push(spAml);
          return;
        } else {
          unlabelFound = true;
        }
      })
    });
    this.sharePointApplicationMasterLabelsFiltered.forEach(result => {
      if (result.value != this.sharePointSelectedFileLabelForFilter) {
        this.sharePointSelectedFileLabelForFilter = 'All';
      }
    });
    this.sharePointApplicationMasterLabelsFiltered = [...new Set(this.sharePointApplicationMasterLabelsFiltered)];
    this.sharePointApplicationMasterLabelsFiltered.sort((a, b) => a.value.localeCompare(b.value))
    // this.sharePointSelectedFileLabelForFilter = this.sharePointSelectedFileLabelForFilter;
  }
  prepareSharepointFileLabelForNewFile() {
    let workspace = new WorkspaceSupplementalDoc();
    workspace.workspaceId = this.currentEngagement.primaryWorkspaceId;
    this.supplementalDocumentList.forEach(doc => {
      if (doc.documentName == this.currentfileName && doc.documentUrl.indexOf(this.sharepointSiteDocumentModel.documentFolderPath) >= 0 &&
        doc.documentUrl.indexOf(this.sharepointSiteDocumentModel.siteURL) >= 0) {
        workspace.sourceFilePath = doc.documentUrl
        workspace.sourceFileName = doc.documentName
        workspace.label = this.sharePointSelectedFileLabel;
        workspace.createdby = this.userInfo.userName;
        workspace.WorkspaceSupplementalDocId = doc.documentId;
        return;
      }
    });
    this.addSharepointFileLabel(workspace);
  }
  addSharepointFileLabel(workspace: WorkspaceSupplementalDoc) {
    this.workspaceService.UploadSharepointFile(workspace).subscribe(success => {
      this.attacheLabelToFiles();
      this.clearSharepointFileUploadData();
    }, err => {
      console.error("Unable to upload sharepoint file label.", err);
      this.clearSharepointFileUploadData();
    });
  }
  getSharepointFileLabels() {
    this.applicationMasterService.GetSharepointFileLabels().subscribe(applicationMaster => {
      if (applicationMaster && applicationMaster?.length > 0) {
        this.sharePointApplicationMasterLabels = applicationMaster;
        this.sharePointApplicationMasterLabelsFiltered = applicationMaster;
      }
    }, err => {
      console.error('Not able to load the sharepoint file label application master', err);
    })
  }
  getSharepointFileLabelFeatureFlag() {
    this._engService.GetSharepointFileLabelFeatureFlag().subscribe(result => {
      this.isSharepointFileLabelFeatureFlagEnabled = result;
    }, error => {
      console.error("unable to get sharepoint file label feature flag." + error)
    });
  }
  ResetIwantToUnsavedFlag() {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.data.setUnsavedChangesModel(dialogJSON);
    this.data.setClientIdForPrePopulate(-1);
    this.data.setEngagementIdForPrePopulate(-1);
  }

  /*END Sharepoint Lable Feature */

  CloseReviewNotesPanel() {
    if (this.isCollapsibleLeftNavVisible) {
      this.data.setCloseReviewNotes(true);
      this.data.setCollapsibleLeftNav(false);
      $('.containerDiv').removeClass('compressRevContainer');
    }
  }

  GetIndusFlagForReviewPage(engagementid: number) {
    this._engService.getIndusFlagForReviewPage(engagementid).subscribe(result => {
      this.isIndusFlag = result;
    }, error => {
      console.error("unable to fetch data from this method GetIndusFlagForReviewPage()." + error);
    });
  }

  GetIndusFlagForReviewPageBySolutionCode(solutionCode: number) {
    this._engService.getIndusFlagForReviewPageBySolutionCode(solutionCode).subscribe(result => {
      this.isIndusFlagEnabled = result;
    }, error => {
      console.error("unable to fetch data from this method GetIndusFlagForReviewPage()." + error);
    });
  }

  GetCollaborationAuditTrail(engagementid: number) {
    this._engService.getCollaborationAuditTrail(engagementid).subscribe(result => {
      console.log("indus flags", JSON.parse(JSON.stringify(result)));
      var indusFlagResult = JSON.parse(JSON.stringify(result));
      if (indusFlagResult != null) {
        let dialogDesc: string = '';
        let bigTitle: boolean = false;
        var buttonCount = 2;
        this.currentIndusFlag = indusFlagResult.currentIndusFlag;
        this.oldIndusFlag = indusFlagResult.oldIndusFlag;

        if ((this.currentEngagement.reacceptanceEngagementId != null && this.currentEngagement.reacceptanceEngagementId > 0) && (this.oldIndusFlag == false)) {
          dialogDesc = 'Recording INDUS consent requires a signed consent form or Statement of Work covering the period of these proposed services, uploaded to Supplemental Documents. Click OK to confirm and record consent.';
        }
        else if ((this.currentEngagement.reacceptanceEngagementId != null && this.currentEngagement.reacceptanceEngagementId > 0) && (this.oldIndusFlag == true)) {
          dialogDesc = 'You are reactivating INDUS consent that was carried forward from prior year but subsequently removed. Click OK to confirm and record consent.';
        }
        this.displayConfirmationPopUp('information', IndusDialogTitles.RecordINDUSconsent, dialogDesc, buttonCount, '',
          false, false, bigTitle, '0', this.userInfo.loggedInUserEmail);
      }
    }, error => {
      console.error("unable to fetch data from this method GetCollaborationAuditTrail()." + error);
    });
  }


  UpdateIndusFlagForReviewPage(isIndus: boolean) {
    this.updateINDUSFlag.engagementId = this.engagementId;
    this.updateINDUSFlag.isIndus = isIndus;
    this.updateINDUSFlag.lastModifiedBy = this.userInfo.loggedInUserEmail;
    this._engService.updateIndusFlagForReviewPage(this.updateINDUSFlag).subscribe(result => {
      console.log("UpdateIndusFlagForReviewPage() method called and the result is: " + result);
      this.GetIndusFlagForReviewPage(this.CurrentWorkspaceData.engagementId);
      if (isIndus == false) {
        this.ProcessINDUSAllTeamMembersRemoval();
      } else if (isIndus == true) {
        this.GenerateIndusConsentEvent('Yes');
      }
    }, error => {
      console.error("unable to update INDUS flag." + error);
    });
  }

  /** HotDocs Caching issue fixes START*/

  ValidateHdTemplateCache() {
    var canReload = this.hdService.canReloadPageForHotdocsTemplate(
      this.CurrentWorkspaceData.documentAliasName,
      this.CurrentWorkspaceData.hdTemplatePackageId,
      this.CurrentWorkspaceData.hdCurrentTempVersion
    );
    if (canReload) {
      this.ReloadPage(this.CurrentWorkspaceData);
    }
  }

  private SetUIStateBeforePageReload() {
    if (this.displayNewVersionDialog == false) {
      sessionStorage.setItem('canDisplayNewVersionDialog', "false")
    }

    if (this.redirectedFromEditEngagement) {
      sessionStorage.setItem('isRedirectedFromEditEngagement', "true");
    }
  }

  private ReloadPage(selectedWorkspacedocModel: WorkspaceModel) {
    //Reload page so the saved hotdocs template version with answers will be displayed in UI properly. 

    this.SetUIStateBeforePageReload();

    this.hdService.RemoveHdTemplateVersionListInBrowserSession();
    this.hdService.UpdateHdTemplateVersionInBrowserSession(
      selectedWorkspacedocModel.documentAliasName,
      selectedWorkspacedocModel.hdTemplatePackageId,
      selectedWorkspacedocModel.hdCurrentTempVersion
    );

    sessionStorage.setItem('isPageReloaded', "true");
    HD$.ReattachSession();
  }

  private RestoreUIStateAfterPageLoaded() {
    var isPageReloadedSession = sessionStorage.getItem("isPageReloaded");
    if (isPageReloadedSession != null && isPageReloadedSession != undefined) {
      sessionStorage.removeItem('isPageReloaded');
    }
  }
  /** HotDocs Caching issue fixes END*/

  INDUSmodelDailog(action: string) {
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    var buttonCount = 2;
    switch (action) {
      case IndusDialogTitles.RecordINDUSconsent:
        if (this.currentEngagement.reacceptanceEngagementId != null && this.currentEngagement.reacceptanceEngagementId > 0) {
          this.GetCollaborationAuditTrail(this.CurrentWorkspaceData.engagementId);
        } else {
          dialogDesc = 'Recording INDUS consent for a new client/prospect requires a signed consent form or NDA with third-party consent language from the client/prospect, uploaded to Supplemental Documents. Click OK to confirm and record consent.';
          this.displayConfirmationPopUp('information', IndusDialogTitles.RecordINDUSconsent, dialogDesc, buttonCount, '',
            false, false, bigTitle, '0', this.userInfo.loggedInUserEmail);
        }
        break;
      case IndusDialogTitles.RemoveINDUSconsent:
        dialogDesc = 'Removing INDUS consent will remove INDUS team members from this engagement (if applicable) and restrict the addition of new INDUS team members until consent is reactivated. Click OK to proceed with removing consent.';
        this.displayConfirmationPopUp('danger', IndusDialogTitles.RemoveINDUSconsent, dialogDesc, buttonCount, '',
          false, false, bigTitle, '0', this.userInfo.loggedInUserEmail);
        break;
      default:
        console.log('Default No button Action');
        break;
    }
  }

  CreateReportdropdown(reviewNotescount: string) {
    if ((this.currentEngagement.siteRequestStatus === 'Completed' || this.currentEngagement.siteRequestStatus === 'PartialCompleted')
      && this.workspaceModel.isAllowMultipleTemplates && this.currentEngagement.status != 'Abandoned' && this.currentEngagement.status != 'Declined') {
      $('.dropdown-menu').addClass('' + reviewNotescount + '');
      this.removeOrAddClass(reviewNotescount, true);
    } else if (this.isIndusFlagEnabled && this.currentEngagement.siteRequestStatus != null && this.currentEngagement.status != 'Abandoned' && this.currentEngagement.status != 'Declined') {
      $('.dropdown-menu').addClass('' + reviewNotescount + '');
      this.removeOrAddClass(reviewNotescount, true);
    } else {
      $('.dropdown-menu').removeClass('' + reviewNotescount + '');
      this.removeOrAddClass(reviewNotescount, false);
    }
  }

  removeOrAddClass(classname: string, isRemove: boolean) {
    switch (classname) {
      case 'review-note-count-0':
        isRemove == false ? $('.dropdown-menu').addClass('review-note-0') : $('.dropdown-menu').removeClass('review-note-0');
        break;
      case 'review-note-count-1':
        isRemove == false ? $('.dropdown-menu').addClass('review-note-1') : $('.dropdown-menu').removeClass('review-note-1');
        break;
      case 'review-note-count-2-9':
        isRemove == false ? $('.dropdown-menu').addClass('review-note-2-9') : $('.dropdown-menu').removeClass('review-note-2-9');
        break;
      case 'review-note-count-10-99':
        isRemove == false ? $('.dropdown-menu').addClass('review-note-10-99') : $('.dropdown-menu').removeClass('review-note-10-99');
        break;
      case 'review-note-count-100-999':
        isRemove == false ? $('.dropdown-menu').addClass('review-note-100-999') : $('.dropdown-menu').removeClass('review-note-100-999');
        break;
      default:
        break;
    }
  }
  loadMemberFirmDetails(engagementId) {
    this._engService.GetEditEngagementDetailsById(engagementId)
      .subscribe(
        result => {
          this.engagements = result;
          if (this.engagements?.memberFirmMappingId > 0) //if engagement is member firm then load member firm details
            this.memberFirmService.getMemberFirmDetailsById(this.engagements?.memberFirmMappingId).subscribe(memberFirmData => {
              this.memberFirmData = memberFirmData
              this.assignAzureADGroupsByMemberFirm()
            })

        })
  }
  assignAzureADGroupsByMemberFirm() {
    if (this.azureADUsersArrayListMemberFirm == undefined) {
      if (this.engagements?.memberFirmMappingId > 0 || this.isMemberFirm) {
        let role;
        if (this.isIndusFlag) {
          role = this.memberFirmData?.indusConsentShortRoleName;
        } else {
          role = this.memberFirmData?.teamMemberShortRoleName;
        }
        this.adUsersService.GetAzureADGroupsByRoleShortName(role).subscribe(
          result => {
            this.azureADUsersArrayListMemberFirm = result;
            this.azureADUsersArrayListMemberFirm.azureADUsers?.forEach(resu => {
              this.azureADUsersArrayList.azureADUsers.push(resu);
            });
          }
        );
      }
    }
    else {
      this.azureADUsersArrayListMemberFirm.azureADUsers?.forEach(resu => {
        this.azureADUsersArrayList.azureADUsers.push(resu);
      });
      this.azureADUsersArrayList.azureADUsers = this.azureADUsersArrayList.azureADUsers.filter((item,
        index) => this.azureADUsersArrayList.azureADUsers.indexOf(item) === index);
    }
  }

  getKeyTeamMemberRoleList() {
    let applicationName = 'KeyTeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        result.forEach(element => {
          const keyTeamMemberRoleCode = new AppCodes();
          keyTeamMemberRoleCode.code = element.code;
          keyTeamMemberRoleCode.codeValue = element.applicationMasterId;
          this.keyTeamMemberRoleCodes.push(keyTeamMemberRoleCode);
          //this.applicationmasterListIE.push(element);
          this.keyteamMemberRoleFromAppMstList.push(element);
        });
        this.keyteamMemberRoleFromAppMstList = this.keyteamMemberRoleFromAppMstList.sort((a, b) => Number(a.applicationMasterId) - Number(b.applicationMasterId));
        console.log('Key team member : ' + result);
      }
    );
  }

  OnKeyTeamMemberChkboxChecked(event) {
    (event.target.checked && this.KeyTeamMemberRoleId == this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) ?
      $('#keyTeamMemberDescription').css('display', 'block') : $('#keyTeamMemberDescription').css('display', 'none');
  }

  getKeyTeamMemberRoleIdByCode(Code: string): number {
    return this.keyTeamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }

  OnKeyRoleChange(event) {
    this.KeyTeamMemberRoleId = Number(event.target.value);
    if (this.KeyTeamMemberRoleId == 0) {
      $('#KeyTeamMemberRoleErrorMessage').text('Select role.');
    } else {
      $('#KeyTeamMemberRoleErrorMessage').text('');
    }
    if (this.isKeyTeamMember == true &&
      this.KeyTeamMemberRoleId == this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) {
      $('#keyTeamMemberDescription').css('display', 'block');
    } else {
      this.KeyDescription = '';
      $('#keyTeamMemberDescription').css('display', 'none');
    }
  }

  GetPrimarySolutionCodeisAuditByWorkspaceId(WorkspaceId: string) {
    this.workspaceService.getisAuditFlagByWorkspaceId(WorkspaceId).subscribe(result => {
      this.isAudit = result;
      console.log('Primary solutioncode is Audit : ' + this.isAudit);
    });
  }

  showAuditPartnerPopupDialog(type: string) { // show audit partner dialog for on Send To Eppmd or Eppmd Approve
    if (type == "EP") {
      if (this.solutionCodeDetails.isAdvisory)
        this.dialogDescriptionType = AuditPartnerDialogTypes.AdvisoryEPPMDReview;
      else if (this.solutionCodeDetails.isTax)
        this.dialogDescriptionType = AuditPartnerDialogTypes.TaxEPPMDReview;
      else if (this.solutionCodeDetails.isAudit)
        this.dialogDescriptionType = AuditPartnerDialogTypes.AuditEPPMDReview;
    } else if (type == "RP") {
      if (this.solutionCodeDetails.isAdvisory)
        this.dialogDescriptionType = AuditPartnerDialogTypes.AdvisoryRPReview;
      else if (this.solutionCodeDetails.isTax)
        this.dialogDescriptionType = AuditPartnerDialogTypes.TaxRPReview;
      else if (this.solutionCodeDetails.isAudit)
        this.dialogDescriptionType = AuditPartnerDialogTypes.AuditRPReview;
    }
    $('#modal-AuditPartner').modal({ backdrop: 'static', keyboard: false });
    $("#modal-AuditPartner").modal('show');
    this.auditPartnerDialog.getAuditPartnerDetails(this.workspaceId);
  }

  cancelAuditPartnerClicked() { // after audit partner popup closed cancle
    $("#modal-AuditPartner").modal('hide');
  }
  okAuditPartnerClicked(event: MembersList) { // after audit partner popup closed with ok
    $("#modal-AuditPartner").modal('hide');
    if (event.mail != undefined) {
      if (this.dialogDescriptionType == AuditPartnerDialogTypes.AdvisoryEPPMDReview || this.dialogDescriptionType == AuditPartnerDialogTypes.TaxEPPMDReview || this.dialogDescriptionType == AuditPartnerDialogTypes.AuditEPPMDReview) {
        this.IsDecisionCallNeeded = true;
        this.initiateProcessforSendToEPPMD();
      }
      else if (this.dialogDescriptionType == AuditPartnerDialogTypes.AdvisoryRPReview || this.dialogDescriptionType == AuditPartnerDialogTypes.TaxRPReview || this.dialogDescriptionType == AuditPartnerDialogTypes.AuditRPReview) {
        this.IsDecisionCallNeeded = true;
        this.initinateProcessforApprove(WorkspaceInterviewsStatus.EPApprovedTitle);
      }
      this.getReviewers();
    }
  }


  initTheAuditPartnerPopup() { // initing the popup values solution code type and show popup if hotdocs variable value is yes


    this.configurationService.getSolutioncodeDetailsBySolutionCodeId(this.currentEngagement.parentSolutionCodeId).subscribe(result => {
      this.solutionCodeDetails = result;
    });
    this.workspaceService.getValidAuditPartnerForEngagement(this.workspaceId).subscribe(result => {
      this.showAuditPopup = result.hdAnswer;
      this.rpcount = result.rpCount;
    }, err => {
    })
  }

  ExistingAUditPartner(): string {
    const ExistingAP = this.reviewerList.find(professional => professional.memberRoleName === 'AUD_PR');
    return ExistingAP ? ExistingAP.memberEmailId : '';
  }

  getCLMGoverningAgrementNameStatus() {
    this.gaNameAndStatusRequest = new GoverningAgreementNameAndStatusRequest();
    var engagementId = this.engagementId.toString();
    this.gaNameAndStatusRequest.engagementID = engagementId;
    this.clmService.getGoverningAgreementNameAndStatusByEngagementId(this.gaNameAndStatusRequest).subscribe(result => {
      if (result.statusCode == "OK") {

        this.gaNameAndStatusResponse = result;
        if (this.clmGoverningAgreementStatusReviewDiv != undefined) {
          this.clmGoverningAgreementStatusReviewDiv.nativeElement.innerHTML = this.gaNameAndStatusResponse.htmlData;
          this.PopupDisplay(this.gaNameAndStatusResponse.htmlData);
          this.injectCLMCloseButtonDynamically();
          this.extractNameAndStatus(this.gaNameAndStatusResponse.htmlData);
        }
        else {
          console.warn("CLM Governing agreements  not loaded properly ");
        }
      }
      this.insertCLMApiLog(this.engagementId);
    });
  }

  injectCLMCloseButtonDynamically() {// inject CLM close buttton if secof and third popup clicked
    setTimeout(() => { // wating  to load clm popup html
      var subject = document.querySelector(".clmmodal-body");
      const elc = document.getElementById('clmpopupCloseButton')
      if (elc == undefined) {
        subject.insertAdjacentHTML(
          "afterbegin",
          `<button id="clmpopupCloseButton" type="button" (click)="clmPopupClosebuttonClicked()"  class="close clm-close"><span  class="close-text mr-1">Close </span><span  class="modal-close-btn">×</span></button>`,
        );
        setTimeout(() => { // wating  to load clm popup lose button html
          const el = document.getElementById('clmpopupCloseButton');
          el.addEventListener('click', this.clmPopupClosebuttonClicked.bind(this))
        }, 100);
      }
    }, 500);
  }

  clmPopupClosebuttonClicked(event) { //close butto clicked of clm Popup
    document.getElementById('myModal').style.display = 'none';
  }

  insertCLMApiLog(engagementId: number) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.GoverningAgreementStatus;
    clmApiLog.requestPayload = JSON.stringify(this.gaNameAndStatusRequest);
    clmApiLog.responseBody = JSON.stringify(this.gaNameAndStatusResponse);
    clmApiLog.createdBy = this.userInfo.loggedInUserEmail;
    if (this.gaNameAndStatusResponse.statusCode != "OK") {
      clmApiLog.errorCode = this.gaNameAndStatusResponse.statusCode;
      clmApiLog.errorDescription = "Failed to get Governing Agreement Name And Status Response  on Review Page.";
    }
    else {
      clmApiLog.errorCode = "";
      clmApiLog.errorDescription = "";
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("Failed to insert api log for Governing Agreement Name And Status Response")
      }
    })
  }

  PopupDisplay(htmlurl: string) {
    var newDiv = document.createElement("div");
    newDiv.innerHTML = htmlurl;
    var scripts = newDiv.getElementsByTagName('script');
    for (var ix = 0; ix < scripts.length; ix++) {
      let clmScript: HTMLScriptElement = document.createElement('script');
      clmScript.async = true;
      clmScript.innerHTML = scripts[ix].text;
      document.head.prepend(clmScript);
    }
  }
  getCLMFeatureFlag() {
    this.clmService.getCLMFeatureFlag().subscribe(result => {
      this.isCLMFeatureEnabled = result;
      if (this.isCLMFeatureEnabled) {
        this.getCLMGoverningAgrementNameStatus();
        this.getCLMDetails();
      }
    });
  }

  redirectToCorrectEngagementState(workspaceId: string) {
    let request = new EngagementStatusViewModel();
    request.workspaceId = workspaceId;
    let isInProgressDataModificationExistsSub = this.dataModification.IsInProgressDataModificationExists(null, DataModificationModuleNames.Questionnaire, workspaceId);
    let getEngagementStatusSub = this._engService.getEngagementStatus(request);
    forkJoin([isInProgressDataModificationExistsSub, getEngagementStatusSub]).subscribe({
      next: result => {
        let questionnaireInprogressResult = result[0];
        this.engStatus = result[1];
        if (questionnaireInprogressResult.editStatus == true && questionnaireInprogressResult.editingMembers?.length > 0)
          this.skipRedirect = true;
        let prevPath = this.data.previousUrl.getValue();
        let redirectURL = this._engService.getRedirectPathByEngagementStatus(this.engStatus);
        let isHardRefreshPageReloaded = sessionStorage.getItem('isHardRefreshPageReloadedW');
        let isLoginLogoutURL = false;
        if (prevPath?.includes("Login?returnUrl=")) {
          isLoginLogoutURL = true;
        }
        if (!this.skipRedirect && !isHardRefreshPageReloaded) {
          this.activatedRoute.snapshot;
          if (redirectURL?.length > 0 && !prevPath?.includes(redirectURL) && (prevPath == undefined || isLoginLogoutURL)) {
            this.router.navigate([redirectURL]);
            sessionStorage.removeItem('isHardRefreshPageReloadedW');
          }
          else {
            sessionStorage.setItem('isHardRefreshPageReloadedW', "true");
          }
        }
      }
    })
  }

  getClientConsent(clientCode, oneViewAccountId) {
    let clientConsentRequest = new ClientConsentRequest();
    clientConsentRequest.clientCode = clientCode;
    clientConsentRequest.oneViewAccountId = oneViewAccountId;
    if (oneViewAccountId?.length > 0 && this.clientConsent?.hs_AccountId != oneViewAccountId)
      this._clientService.getClientConsent(clientConsentRequest).subscribe({
        next: result => {
          if (result.status = 'success') {
            this.isClientConsentWarningEnabled = result.response;
          }
          else {
            console.warn("Client Consent : " + result.status)
          }
        }, error: err => {
          console.error("Client Consent erro: " + err)
        }
      })
  }
  deleteAwaitingApprovalClientsForNoEngagement() {
    let cm = new ClientDeleteModel();
    cm.clientId = this.clientId;
    cm.engagementId = this.engagementId;
    this._clientService.deleteClientsForEngagement(cm).subscribe({
      next: result => {
      }, error: err => {
        console.error("Error in soft deleting the client", err);
      }
    })
  }

  getCLMDetails() {
    this.clmService.getCLMDetails(this.engagementId).subscribe({
      next: result => {
        this.clmDetails = result;
        this.extractNameAndStatus(this.gaNameAndStatusResponse?.htmlData);
      }, error: err => {
        console.error("error  to load clm details from db", err);
      }
    })
  }
  extractNameAndStatus(htmlData: string) {
    if (htmlData) {
      const cleanedHtmlData = htmlData.replace(/<style[\s\S]*?<\/style>/gi, '');
      const parser = new DOMParser();
      const doc = parser.parseFromString(cleanedHtmlData, 'text/html');
      const GAName = doc.querySelector('strong');
      if (GAName) {
        if (this.clmDetails == undefined)
          this.clmDetails = new CLMDetails();
        this.clmDetails.name = GAName.textContent.trim();
        const textNode = GAName.nextSibling;
        if (textNode && textNode.nodeType === Node.TEXT_NODE) {
          const text = textNode.textContent.trim();
          const match = text.match(/\((.*?)\)/);
          if (match) {
            this.clmDetails.status = (match[1]);
          }
        }
      }
    }
  }
  rejectTheAvepointTasks() {
    let filterTaskRequestModel = new FilterTaskRequestModel();
    filterTaskRequestModel.siteURL = this.sharepointSiteURL;
    filterTaskRequestModel.taskApprovalStatus = 1; // for pending approve
    this._sharepointService.getFilterdTask(filterTaskRequestModel).subscribe({
      next: result => {
        if (result.avePointTaskDetails.length > 0) {
          for (let index = 0; index < result.avePointTaskDetails.length; index++) {
            result.avePointTaskDetails[index].rejectComment = "Engagement is declined.";
          }
          this._sharepointService.rejectTasks(result).subscribe({
            next: (result) => {
            }, error: (err) => {
              console.error("failed to get the avepoint tasks", err);
            },
          })
        }
      }, error: er => {
        console.error("failed to get the avepoint tasks", er);
      }
    })
  }

  getEngagementChangeOrdersByWorkSpaceId(workSpaceId: string) {
    return this.changeOrderService.getEngagementChangeOrdersByWorkSpaceId(workSpaceId, 0)
      .subscribe({
        next: result => {
          this.engagementChangeOrders = result;
        }, error: err => {
          console.error("failed  to load Engagement ChangeOrders -getEngagementChangeOrdersByWorkSpaceId ", err);

        }
      })
  }

}
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appConfigService';
import { Injectable } from '@angular/core';
import { CollaborationSiteMembers, CollaborationSpacePriorYearMappingModel, CollaborationSpacePriorYearViewModel, UpdateCollaborationSpacePriorYearMappingModelWrapper } from '../model/CollaborationSite';

@Injectable({
  providedIn: 'root'
})
export class CollaborationSiteService {

  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  userName: string;
  fullName: string;
  constructor(private environment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = environment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    console.log(nameJSON, nameJSON != null);
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.fullName = nameJSON.loggedInUserFullName;
    }
  }
  getCollaborationSiteId(engagementId: number): Observable<number> {
    return this.http.get<number>(`${this.APIEndPoint}/CollaborationSite/GetCollaborationSiteId/${engagementId}`);
  }
 
  getAllCollaborationSpacePriorYearMappingData(collaborationSiteId: number): Observable<CollaborationSpacePriorYearMappingModel[]> { 
    return this.http.post<CollaborationSpacePriorYearMappingModel[]>(`${this.APIEndPoint}/CollaborationSite/GetAllCollaborationSpacePriorYearMappingData/${collaborationSiteId}`, collaborationSiteId);
  }

  updateCollaborationSpacePriorYearData(updateCollaborationSpacePriorYearMappingModelWrapper: UpdateCollaborationSpacePriorYearMappingModelWrapper) {  
    return this.http.post<boolean>(this.APIEndPoint + '/CollaborationSite/UpdateCollaborationSpacePriorYearData', updateCollaborationSpacePriorYearMappingModelWrapper);    
  }

  getCollaborationSpacePriorYearList(engagementId: number): Observable<CollaborationSpacePriorYearViewModel[]> {    
    return this.http.get<CollaborationSpacePriorYearViewModel[]>(this.APIEndPoint + '/CollaborationSite/GetCollaborationSpacePriorYearList/'+ engagementId);
  }

  CollaborationSiteMembers(collaborationSiteId: number): Observable<CollaborationSiteMembers[]> {     
    return this.http.get<CollaborationSiteMembers[]>(this.APIEndPoint + '/CollaborationSite/CollaborationSiteMembers/'+ collaborationSiteId);
  }

}

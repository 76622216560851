export class TeamMemberRole {
  id: number;
  typeValue: string;
}

export enum EnumTeamMemberRoleCodes {
  P = 'P',
  S = 'S',
  R = 'R',
  PR = 'PR',
  A = 'A',
  EP = 'EP',
  C = 'C',
  CMSEP = 'CMSEP'
}

export enum EnumKeyTeamMemberRoleCodes {
  OTH = 'OTH'
}

<div class="tab-content">
  <div class="tab-pane active" id="myengagements">
    <div id="CardViewTableSummary" class="table-summary">
      <form class="form-inline">
        <div class="My-Clients-40 col-md-2">
          <span *ngIf="ShowClientName">My</span> &nbsp;Other Docs&nbsp;({{engagementList?.length}})
        </div>
        <!-- My Client title -->
        <div class="CardViewFiltersForotherdoc col-md-9">
          <div *ngIf="ShowClientName" id="ClientNameCardViewFiltersForotherdoc" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Clients <span class="caret"></span>
            </button>
            <ul id="ClientNameFilterULForotherdoc" class="dropdown-menu ClientNameFilterjsForotherdoc filterlist toggleDropdown"
              role="menu" aria-labelledby="menu1">
              <li class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterClientNameBoxForotherdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForotherdoc('filterClientNameBoxForotherdoc','ClientNameFilterULForotherdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </li>
              <li>
                <input type="checkbox" class="CheckAllClientsForotherdoc" checked data-attribute="chkAll-ClientNameForotherdoc" />&nbsp;All
                Clients
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="EngagementCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Engagements
              <span class="caret"></span>
            </button>
            <ul id="EngagementFilterULForotherdoc" class="dropdown-menu EngagementFilterjsForotherdoc filterlist toggleDropdown"
              role="menu" aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterEngagementBoxForotherdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForotherdoc('filterEngagementBoxForotherdoc','EngagementFilterULForotherdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllEngForotherdoc" checked data-attribute="chkAll-EngagementForotherdoc" />&nbsp;All Engagements
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="SolutionCodeCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Solution Code
              <span class="caret"></span>
            </button>
            <ul id="SolutionCodeFilterULfilterSolutionCodeBoxForotherdoc"
              class="dropdown-menu SolutionCodeFilterjsForotherdoc filterlist toggleDropdown" role="menu"
              aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterSolutionCodeBoxForotherdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForotherdoc('filterSolutionCodeBoxForotherdoc','SolutionCodeFilterULfilterSolutionCodeBoxForotherdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllSolutionCodeForotherdoc" checked
                  data-attribute="chkAll-SolutionCodeForotherdoc" />&nbsp;All Solution Code
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="StatusCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Status
              <span class="caret"></span>
            </button>
            <ul id="StatusFilterULForotherdoc" class="dropdown-menu StatusFilterjsForotherdoc filterlist toggleDropdown"
              role="menu" aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterStatusBoxStatusFilterULForotherdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForotherdoc('filterStatusBoxStatusFilterULForotherdoc','StatusFilterULForotherdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllStatusForotherdoc" checked data-attribute="chkAll-StatusForotherdoc" />&nbsp;All Status
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="EPCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              EP
              <span class="caret"></span>
            </button>
            <ul id="EPFilterULForotherdoc" class="dropdown-menu EPFilterjsForEngForotherdoc filterlist toggleDropdown" role="menu"
              aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterEPBoxForotherdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForotherdoc('filterEPBoxForotherdoc','EPFilterULForotherdoc')" type="text" placeholder="Search"
                  aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllEPForotherdoc" checked data-attribute="chkAll-EPForotherdoc" />&nbsp;All EP
              </li>
            </ul>
          </div>
           &nbsp;&nbsp;
           <!-- Search Button -->
           <img class="text-muted resetHyperlink" src="../../../../assets/images/listview/search.png" alt="search"/>
           <input class="form-control searchbox" type="text" placeholder="Search" aria-label="Search" (keyup)="onKeyup($event,'searchDatajs')"/>
           <!-- Search Button -->

        </div>

        <!-- List View and Card View buttons -->
        <div class="push col-md-1">

          <a class="ShowCardViewjs" (click)="ToggleListAndCardViewForotherdoc('Card');">
            <img class="CardViewImgForotherdoc pointerCursor" alt="Card View"
              src="../../../../assets/images/listview/card-view_selected.png" />&nbsp;
          </a>
          <a class="ShowListViewjs" (click)="ToggleListAndCardViewForotherdoc('List');">
            <img class="ListViewImgForotherdoc pointerCursor" alt="List View"
              src="../../../../assets/images/listview/list-view_un-selected.png" />&nbsp;
          </a>


        </div>
        <!-- List View and Card View buttons -->
      </form>
    </div>

    <!-- Start MainContainer -->
    <!-- List View and card view main container start -->
    <div class="MainContainer">
      <div class="table-responsive tb">
        <!--ListView Design Start-->
        <div class="ListViewContainerForotherdoc" style="display:none">
          <table class="table table-light tableData tableDataEngForotherdoc ResponsiveDataTable" aria-describedby="Engagement List for Other Documents">
            <thead>
              <tr>
                <th *ngIf="ShowClientName" scope="col" class="table-sorted-desc">Client Name</th>
                <th scope="col" class="table-sortable-desc">Eng Name</th>
                <th scope="col" class="table-sortable-desc">Solution Code</th>
                <th scope="col" class="table-sortable-desc">Status</th>
                <th scope="col" class="table-sortable-desc">EP</th>
                <th scope="col" class="table-sortable-desc">Last Activity Date</th>
                <th scope="col" class="table-sortable-desc">FYE</th>
              </tr>
            </thead>
            <tbody class="display" *ngIf="engagementList?.length != 0">
              <tr class="searchDatajs" *ngFor="let list of engagementList">
                <td data-label="Client Name:" *ngIf="ShowClientName"><a href="ClientSummary/{{list.clientId}}" class="link">{{list.clientName}}</a>
                </td>
                <td data-label="Eng Name:"><a href="EngagementSummary/{{list.engagementId}}" class="link"> {{list.engagementName}} </a></td>
                <td data-label="Solution Code:"> {{list.solutionCode}} </td>
                <td data-label="Status:"> {{list.status }} </td>
                <td data-label="Engagement Partner:">
                  <a id="ClientRP" style="color:black" class="ShowRPDetailsjsForotherdoc" data-trigger="focus" tabindex="0"
                    data-toggle="popover" rel="popover" data-placement="right">
                    {{list.engagementPartnerName}}
                  </a>
                  <label class="hide hiddenEPId">{{list.engagementPartnerName}}</label>
                </td>
                <td data-label="Last Activity Date:"> {{list.lastModifiedDate | date: "MM/dd/yyyy | HH:mm a"}} </td>
                <td data-label="FYE:"> {{list.taxYear | date: "MM/dd/yyyy"}} </td>
              </tr>

            </tbody>
            <tbody *ngIf="engagementList.length == 0">
              <tr>
                <td colspan="7" class="no-data-available text-center">No Data Available</td>
              </tr>
            </tbody>

          </table>

          <!-- pager start-->
          <!-- pager End-->
        </div>
        <!--ListView Design End-->

        <!--CardView Design Start-->
        <div class="cardViewContainerForotherdoc row marginLeftZero">

          <div class="card-deck-wrapper col-md-12">
            <div class="card-deck">
              <div class="card searchDatajs FilterEngDatajsForotherdoc" *ngFor="let list of engagementList">
                  <div class="card-body">
                      <img class="float-right cardviewImg" alt="Engagement Name"
                      src="../../../../assets/images/Engagement/TaxSymphonyLink.png" />
                      <a href="EngagementSummary/{{list.engagementId}}"
                        class="CardViewText CardViewHeaderFont EngagementjsForotherdoc">{{ list.engagementName}}</a><br/>
                      <a href="ClientSummary/{{list.clientId}}" *ngIf="ShowClientName"
                      class="CardViewHeaderFont marginZero ClientNamejsForotherdoc ClientNameEngForotherdoc">
                      {{ list.clientName }}</a><br *ngIf="ShowClientName"/>
                      <hr/>
                      <span><strong>Solution Code:</strong></span><span class="SolutionCodejsForotherdoc">
                        {{ list.solutionCode }}</span><br/>
                      <span><strong>Status:</strong></span><span class="StatusjsForFilterForotherdoc">
                        {{ list.status }}</span><br/>
                        <span><strong>EP:</strong></span><span class="EPjsForFilterForotherdoc">{{ list.engagementPartnerName }}</span><br/>
                        <hr/>
                       <br/>
                       <div style="display:contents">
                            <img class="float-left cardviewImg cardViewFiltersDisplay" alt="Engagements Review"
                              src="../../../../assets/images/MyClientCardView/Engagements_Review.png" />
                          &nbsp;
                            <a id="ViewDocuments" class="showDocumentDataDetailsjsOtherDoc marginZero" style="color:black"
                              data-toggle="modal" href="#ActiveDocumentsModal" (mouseover)="setEngagementId(list.engagementId)" >Active Workspaces
                              <strong>({{list.activeWorkspaceCount}})</strong>
                            </a>&nbsp;
                            <img class="float-right cardviewImgAcitveEng" alt="Card View"
                              src="../../../../assets/images/MyClientCardView/arrow_card-view.png" />

                            </div>
                      </div>
              </div>
              <div id="NoDataMessageDivForotherdoc" class="text-center width100" *ngIf="engagementList?.length == 0">
                No Data Available
              </div>
              <div id="NoDataMessageForotherdoc" class="hide text-center width100">No Data Available</div>
          </div>


            </div>
        </div>
        </div>
        <!--CardView Design End-->

      </div>

    <!-- List View and card view main container End-->
    <!-- end MainContainer -->
  </div>
  <!--end myengagements tab-->

</div>
<br />
<br />
<!--tab-content-->

<!-- <app-document-list-popup [engagementId]="selectedEngagementId"></app-document-list-popup>

<app-relationship-partner-popup [clientId]="selectedKRPClientId"></app-relationship-partner-popup> -->

<input type="hidden" id="recordCount" value="{{popupRecordCount}}"/>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ClientVoiceSurveyOptOutRequest, ClientVoiceSurveyRequest } from 'src/app/model/ClientVoice/ClientVoiceRequestsModel';
import { ClientVoiceContact, ClientVoiceEngagementResponse, ClientVoiceResponse, ClientVoiceSurveyStatusResponse } from 'src/app/model/ClientVoice/ClientVoiceResponsesModel';
import { PreviousClientVoiceSurveyRequestModel } from 'src/app/model/Engagement/EngagementClientVoice';
import { AppConfigService } from '../appConfigService';

@Injectable({
  providedIn: 'root'
})
export class ClientvoiceService {

  private clientVoiceAPIEndPoint: string;

  constructor(private enviornment: AppConfigService, private http: HttpClient) { 
    this.clientVoiceAPIEndPoint = `${enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL}/ClientVoiceSurvey`;
  }

  SendSurvey(clientVoiceSurveyRequest: ClientVoiceSurveyRequest): Observable<ClientVoiceResponse<ClientVoiceEngagementResponse>> {
    return this.http.post<ClientVoiceResponse<ClientVoiceEngagementResponse>>(`${this.clientVoiceAPIEndPoint}/SendSurvey`, clientVoiceSurveyRequest);
  }

  SendSurveyOptOut(clientVoiceSurveyOptOutRequest: ClientVoiceSurveyOptOutRequest): Observable<ClientVoiceResponse<ClientVoiceEngagementResponse>> {
    return this.http.post<ClientVoiceResponse<ClientVoiceEngagementResponse>>(`${this.clientVoiceAPIEndPoint}/SendSurveyOptOut`, clientVoiceSurveyOptOutRequest);
  }

  IsRequiredToSendClientVoiceSurvey(previousClientVoiceSurveyRequestModel: PreviousClientVoiceSurveyRequestModel): Observable<ClientVoiceResponse<ClientVoiceSurveyStatusResponse>> {
    return this.http.post<ClientVoiceResponse<ClientVoiceSurveyStatusResponse>>(`${this.clientVoiceAPIEndPoint}/IsRequiredToSendClientVoiceSurvey`, previousClientVoiceSurveyRequestModel).pipe(retry(3));
  }

  GetClientContacts(clientCode: string): Observable<ClientVoiceResponse<ClientVoiceContact[]>> {
    return this.http.get<ClientVoiceResponse<ClientVoiceContact[]>>(`${this.clientVoiceAPIEndPoint}/GetClientContacts/${clientCode}`).pipe(retry(3));
  }
}

<div id="ShowClientWorkspace" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="height: 390px;width: 600px;top: 75px;left: 135px;">
      <div class="modal-header modal-lg">

        <h4 class="modal-title">Active Documents</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body modal-lg">
        <div style="height:300px; overflow-y:scroll;">
          <table id="ClientWorkspaceTableDataId" class="DataList1 table ">
            <thead>
              <tr>
                <th scope="col">Document Name</th>
                <th scope="col">Status</th>
                <th scope="col">Last Activity</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let document of clientWorkspaceDocumentList;let i = index">
                <td>{{document.documentName}}</td>
                <td>{{document.status}}</td>
                <td>{{document.lastActivityDate | date:'MM/dd/yyyy'}}</td>
                <td>
                  <div *ngIf="document.status != 'Draft' "><a href="/Review/{{document.workspaceId}}" class="showInterviewLink">Go
                      to Workspace
                      <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Workspace" /></a>
                  </div>
                  <div *ngIf="document.status == 'Draft' ">
                    <a id="{{document.workspaceId}}" class="showInterviewLink"
                      (click)="getWorkspaceDatails(document.workspaceId)">Show
                      Interviews
                    </a> <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Interviews" />
                  </div>
                </td>
              </tr>

            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<input type="text" class="hide" id="HiddenWorkspaceIdonClientWorkspacePopup">


<div class="modal fade" id="modal-id">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        <h4 class="modal-title">Modal title</h4>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

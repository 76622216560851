


export class UtilityDocument {
    files: UtilityDoc[];
    userEmailId : string;
    userName : string;
}




export class UtilityDoc {
    fileName: string;
    fileByteArray: number[]    
}

export class UtilityUpdatedADGroupCheckModel
{
      id: number;  
      bulkImportProcessID : number; 
      engagementId  : number;
      teamMemberName  : string;
      teamMemberEmail  : string;
      status  : string;
      errorDescriptionId : number; 
      siteUniqueId: string;
      siteURL: string;
      category: string;
      roles: string;
      isMemberFirm: boolean;
}

export class UtilityExcelProcessModel
{
    id : number;
    fileName  : string;
    rows  : number;
    
}

export class UtilityModelData
{
    adGroupCheckModel  : UtilityUpdatedADGroupCheckModel[] = [];
    excelProcessModel  : UtilityExcelProcessModel[] = [];


}


export class UtilityValidationModel
{
    adGroupCheckModel  : UtilityUpdatedADGroupCheckModel[] = [];

}

export class UtilityInsertModel
{
    adGroupCheckModel  : UtilityUpdatedADGroupCheckModel[] = [];
    userEmail : string;

}

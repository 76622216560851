export class SecondarySolutionCode {
    secondarySolutionCodeId: number;
    parentSolutionCodeId: number;
    childSolutionCodeId: number;
    solutionCode: string;
    solutionCodeDescription: string;
    solutionCodeLongName: string;
    status: string;
    isAudit: boolean;
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string
    lastModifiedDate: Date;
    isGrossMarginRequire: boolean;
    defaultIndusConsent: boolean;
    isEngagementStartDateRequired?: boolean = false;
    isEngagementEndDateRequired?: boolean = false;
    isTax?: boolean;
    isAdvisory?: boolean;
    seq: number;
    isAllAdvisory: boolean;
    isAllAudit: boolean;  
    isAllTax: boolean;
    solutionCodeType: string;
}
import { SolutionCodeProperties } from "./solutioncodeproperties";

export class SolutionCodeConfigResponse {
    issuccess:boolean;
    errorCode: string;
    errorDescription: string;
    request: string;
    response: string;
    engagementId: number;
    loggedInUser: string;
    solutionCodeProperties:SolutionCodeProperties;
}



export class CloseoutSectionConfigResponse {
    issuccess:boolean;
    errorCode: string;
    errorDescription: string;
    request: string;
    response: string;
    engagementId: number;
    loggedInUser: string;
    sectioncodes:string[];
}

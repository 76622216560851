import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfigService } from './appConfigService';


import { AzureADUsersArrayList, SearchGroupUserViewModel, IndusADUsersListViewModel, AzureADUserRoleGroup, AzureADUserRoleGroupList } from '../model/AzureADUsersArrayList';
import { MembersListArray } from '../model/MembersListArray';
import { MembersList } from '../model/MembersList';
import { User } from '../model/User';
import { AzureADGroupRoleShortNamesModel, AzureADUsersList } from '../model/AzureADUserList';
@Injectable({
  providedIn: 'root'
})
export class AdUsersService {
  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  adUsersList: AzureADUsersArrayList;
  membersListArray: MembersListArray;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  getEPList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetEPList');
  }
  GetRelationshipPartners(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetRelationshipPartners');
  }
  GetReviewingProfessionals(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetReviewingProfessionals');
  }
  GetTeamMembersList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetTeamMembersList');
  }
  getAssigmentAdminList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetAssigmentAdminList');
  }  
  getAzureAdUsersByGroups(azureADGroups: AzureADUsersArrayList, roleName: string): Observable<MembersListArray> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    azureADGroups.roleName = roleName;
    return this.http.post<MembersListArray>(this.IntegrationAPIEndPoint + '/Graph/UserByGroup', azureADGroups, { headers: headers });
  }
  getUserDetailsByEmailId(emailId: string): Observable<User> {
    return this.http.post<User>(this.IntegrationAPIEndPoint + '/Graph/UserByEmail', {EmailId: emailId});
  }
  GetAzureADGroupsByRoleShortName(roleShortName: string): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetADGroups/' + roleShortName);
  }

  GetAzureQualityADGroupsByRoleShortName(roleShortName: string): Observable<AzureADUserRoleGroupList> {
    return this.http.get<AzureADUserRoleGroupList>(this.APIEndPoint + '/AzureAD/GetADGroups/' + roleShortName);
  }
    
  GetUserInformationByDisplayName(searchGroupUserViewModel: SearchGroupUserViewModel): Observable<MembersList[]> {
    return this.http.post<MembersList[]>(this.IntegrationAPIEndPoint + '/Graph/UserByDisplayName/' , searchGroupUserViewModel);
  }

  FindUserInGroupMembers(searchGroupUserViewModel: SearchGroupUserViewModel): Observable<boolean> {
    return this.http.post<boolean>(this.IntegrationAPIEndPoint + '/Graph/SearchUserInGroupMembersV2', searchGroupUserViewModel);
  }
  getDecisionUserDetailsByEmailId(emailId: string): Observable<User> {
    return this.http.post<User>(this.IntegrationAPIEndPoint + '/Graph/DecisionUserByEmail', { EmailId: emailId });
  }
  GetINDUSADGroups(roleShortName: string): Observable<AzureADUsersList[]> {
    return this.http.get<AzureADUsersList[]>(this.APIEndPoint + '/AzureAD/GetINDUSADGroups/' + roleShortName);
  }
  
  getValidIndusTeamMemberList(indusADUsersList : IndusADUsersListViewModel): Observable<IndusADUsersListViewModel> {
    return this.http.post<IndusADUsersListViewModel>(this.IntegrationAPIEndPoint + '/Graph/GetValidIndusTeamMemberList' , indusADUsersList);
  }
  GetAllINDUSADGroups(): Observable<AzureADUsersList[]> {
    return this.http.get<AzureADUsersList[]>(this.APIEndPoint + '/AzureAD/GetAllINDUSADGroups');
  }

  GetQaulityADGroupsByWorkspaceId(workspaceId: string): Observable<AzureADUsersList[]> {
    return this.http.get<AzureADUsersList[]>(`${this.APIEndPoint}/AzureAD/GetQaulityADGroupsByWorkspaceId/${workspaceId}`);
  }
  GetADGroupsDeltaByRoleShortName(azureADGroupsShortName: AzureADGroupRoleShortNamesModel): Observable<AzureADUsersList[]> {
    return this.http.post<AzureADUsersList[]>(this.APIEndPoint + '/AzureAD/GetADGroupsDeltaByRoleShortName' , azureADGroupsShortName);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-export-document-completed-popup',
  templateUrl: './export-document-completed-popup.component.html',
  styleUrls: ['./export-document-completed-popup.component.css']
})
export class ExportDocumentCompletedPopupComponent implements OnInit {

  @Input('exportedDocSharepointURL') exportedDocSharepointURL: string = "";
  @Output() close = new EventEmitter();
  @Output() ok = new EventEmitter<string>();

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exportedDocSharepointURL) {
      this.exportedDocSharepointURL = changes.exportedDocSharepointURL.currentValue??'';
    }
  }

  ngOnInit() {
  }


  okClicked() {
      this.ok.emit();
  }
  cancelClicked(event) {
    this.close.emit(event);
  }

}

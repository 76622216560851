export class DecisionsApiLogModel{
    workspaceId: string;
    requestType: string;
    requestPayload: string;
    responseBody: string;
    loggedInUser: string;
    action: string;
    remark: string;
}

export enum DecisionsRequestTypes {
    AutoRP = "AutoRP",
    TemplateDetermination = "TemplateDetermination",
    GetNotification = "GetNotification",
    SendNotification = "SendNotification"
}

export enum NotificationOnlyRemoveRemarks {
    TeamReview = "Questionnaire finished. Engagement is pending with TM",
    EPPMDReview = "Engagement is pending with EP",
    EPPMDAsFinalApproval = "EPPMD as final approval",
    RPReview = "Engagement is pending with RP",
    RPApproved = "Final approval received",
    TMChanged = "Team member list modified",
    EPPMDChanged = "EPPMD changed"
}

export enum NotificationWorkflowStage {
    FinishQuestionnaire = "OnFinishedQuestionnaire",
    TeamReviewApproved = "OnTeamReviewApproval",
    EPPMDReviewApproved = "OnEPPMDApproval",
    FinalApproved = "OnFinalApproval"
}

export enum TemplateTypeEnum {
    Standalone = 'SU',
    FullAgreement = 'FA',
    ComponentTemplate = 'CT',
    Bypass = 'BY'
}
export enum EngagementStateConfig {
    Acceptance= 'AO',
    Execution = 'EO',
    Both = 'Both',
    DownloadTemplate='DT'
    
}
export enum IndependanceConsiderationCode {
    SEC= 'SEC',
    NonSec= 'Non-SEC',
    Both= 'Both'
}
export enum TemplateCategoryCode {
    EL='EL',
    Sow='SOWs',
    Other='Others'
}

<div class="row pageShortcutParent" *ngIf="iShortcutCount > 6">
  <div class="col-md-12">
    <app-shortcut></app-shortcut>
  </div>
</div>

<div class="row pb-3 mx-0 mt-3" [class.pageTitleParentExtraMargin]="iShortcutCount > 6"
  [class.pageTitleParent]="iShortcutCount <= 6">
  <div class="col-md-10 pl-0">
    <h2 class="pageTitle largerFontSize2">My assignments</h2>
  </div>
  <div class="col-md-2 pr-0" *ngIf="iShortcutCount <= 6">
    <app-shortcut></app-shortcut>
  </div>
</div>
<div class="bg-edp-body card-container position-relative">

  <!-- List View and card view main container start -->
  <!--ListView Design Start-->
  <div class="Eng-ListView-Div">
    <div class="d-flex p-2">
      <div class="mr-auto ml-1">
        <div class="d-flex flex-row bd-highlight">
          <a id="filterAssignmentListView" class="anchoroutline filter-tab" (click)="showHideListFilterPanel('Show')" data-placement="bottom"
            target="_blank">
            Filter
          </a>
          <a id="showAllAssignmentListView" class="anchoroutline showall-tab" (click)="showAllAssignmentsWithNoFilters()" data-placement="bottom"
            target="_blank">
            Show all
          </a>
        </div>
      </div>
    </div>
    <!-- Accordian view start -->
    <section id="viewFilter" class="filter-panel">
      <div class="row m-0 bg-edp-body">
        <div class="col-md-12 mx-1">
          <div class="row m-0 pb-4">
            <div class="col-md-4 filter-partition pr-lg-5 pr-md-3">
              <label class="font-weight-bold mb-1">Client name</label>
              <div class="selectdiv">
                <select class="dropdown rounded-0 form-control" name="ddListClients"
                  [(ngModel)]="assignmentFilter.clientId">
                  <option value="0" disabled hidden>Select client</option>
                  <option *ngFor="let clients of clientsList" [value]="clients.clientId">
                    {{clients.clientName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 filter-partition px-lg-5 px-md-3">
              <label class="font-weight-bold mb-1">Solution code</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" name="ddListSolutionCode"
                  [(ngModel)]="assignmentFilter.solutionCodeId">
                  <option value="0" disabled hidden>Select solution code</option>
                  <option *ngFor="let solutionCode of solutionCodeList" [value]="solutionCode.solutionCodeId">
                    {{solutionCode.solutionCodeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 pl-lg-5 pl-md-3">
              <label class="font-weight-bold mb-1">EPPMD</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" name="ddListEppmd"
                  [(ngModel)]="assignmentFilter.eppmdEmail">
                  <option value="" disabled hidden>Select EPPMD</option>
                  <option *ngFor="let eppmd of eppmdList" [value]="eppmd.eppmdEmail">
                    {{eppmd.eppmdName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row m-0 pb-4">
            <div class="col-md-4 filter-partition pr-lg-5 pr-md-3">
              <label class="font-weight-bold mb-1">CMS PPMD</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" name="ddListEppmd"
                  [(ngModel)]="assignmentFilter.cmseppmdEmail">
                  <option value="" disabled hidden>Select CMS PPMD</option>
                  <option *ngFor="let cmseppmd of cmseppmdList" [value]="cmseppmd.cmseppmdEmail">
                    {{cmseppmd.cmseppmdName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 filter-partition px-lg-5 px-md-3">
              <label class="font-weight-bold mb-1">Industry</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" name="ddListIndustryType"
                  [(ngModel)]="assignmentFilter.industryTypeId">
                  <option value="0" disabled hidden>Select industry</option>
                  <option *ngFor="let industry of industryList" [value]="industry.industryTypeId">
                    {{industry.industryTypeName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 pl-lg-5 pl-md-3">
              <label class="font-weight-bold mb-1">Assignment status</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" [(ngModel)]="assignmentFilter.assignmentStatus"
                  name="ddListEngagementStatus">
                  <option value="" disabled hidden>Select assignment status</option>
                  <option *ngFor="let assignmentStatus of assignmentStatusList"
                    [value]="assignmentStatus.assignmentStatusId">
                    {{assignmentStatus.assignmentStatusName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row m-0 pb-4">
            <div class="col-md-4 filter-partition pr-lg-5 pr-md-3">
              <label class="font-weight-bold mb-1">Engagement name</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" name="ddListEngagementName"
                  [(ngModel)]="assignmentFilter.engagementId">
                  <option value="0" disabled hidden>Select Engagement name</option>
                  <option *ngFor="let engagementName of engagementNameList" [value]="engagementName.engagementId">
                    {{engagementName.engagementName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4  px-lg-5 px-md-3">
              <label class="font-weight-bold mb-1">EQCR</label>
              <div class="selectdiv ">
                <select class="dropdown rounded-0 form-control" name="ddListEQCR"
                  [(ngModel)]="assignmentFilter.eqcrEmail">
                  <option value="" disabled hidden>Select EQCR</option>
                  <option *ngFor="let eqcr of eqcrList" [value]="eqcr.eqcrEmail">
                   {{eqcr.eqcrName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-self-end text-right m-0 pb-4">         
          <div class="col-lg-8 col-md-4 filter-partition px-lg-3 px-md-3 m-0">
            <button type="button" id="resetAssignmentListViewFiliter" (click)="showHideListFilterPanel('Reset')"
              class="SecondaryButton filter-button-lst">
              <span class="d-inline-block align-middle">Reset</span>
            </button>
          </div>
        
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
            <button type="button" (click)="showHideListFilterPanel('Hide')" class="SecondaryButton filter-button-lst"
              style="margin-right: 25px;">
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
          </div>
        
          <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
            <button type="button" (click)="getAllAssignmentListByFilters()" class="PrimaryButton filter-button-lst mr-3">
              <span class="d-inline-block align-middle">Apply</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- Accordian view end -->
    <div  class="MainContainer m-3">
      <div class="tableData1">
        <div class="tableData2">
          <table  class="listViewDataTable ResponsiveDataTable assignment-list-view">
            <thead>
              <tr>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[0].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[0].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col ">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[1].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[1].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[2].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[2].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[3].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[3].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[4].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[4].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[5].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[5].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[5].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[6].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[6].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[6].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-sc-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[7].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[7].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[7].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-sc-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[8].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[8].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[8].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[9].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[9].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[9].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-hg-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[10].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[10].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[10].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc t-ia-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[11].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[11].imageModelList">
                        <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                          (click)="headerSort(headerModelList[11].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                          <img src="{{img.imagePath}}" alt="column-sort" />
                        </a>
                      </div>
                    </div>
                  </div>
                </th>
                <th scope="col" class="table-sorted-desc text-center t-sm-col">
                  <div class="row p-0 m-0 th-height">
                    <div class="col-md-10 p-0 m-0">
                      {{headerModelList[12].headerName}}
                    </div>
                    <div class="col-md-2 p-0 m-0 text-right">
                      <div *ngFor="let img of headerModelList[10].imageModelList">
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="display" *ngIf="listCount > 0">
              <tr class="searchDatajs"
                [ngClass]="(selectedAssignmentCodeId === list.assignmentCodeId)?'tr-expanded-height':''"
                *ngFor="let list of assignmentsList; let id=index"
                class="position-relative">

                <td data-label="Client name :" class="t-hg-col ">
                  <a routerLink="/ClientDetails/{{list.clientId}}" class="link"> {{list.clientName}} </a>
                </td>
                <td data-label="Engagement name :" class="t-hg-col">
                  <a (click)="navigateEngagement(list.engagementId)" class="link"> {{list.engagementName}} </a>
                </td>
                <td data-label="Assignment name:" class="t-hg-col"> {{list.assignmentName }}
                </td>
                <td data-label="EPPMD:" class="t-hg-col">
                  <span class="cmsppmdcontactPopup mr-4" id="eppmdContactPopupTable_{{list.assignmentCodeId}}"
                        (mousedown)="displayEPPMDContactPopupTable(list.assignmentCodeId, list.engagementPartner)">
                        {{list.engagementPartnerName}}
                      </span>
                  <input type="hidden" id="listViewtxtengId" value="" />
                </td>
                <td data-label="CMS PPMD:" class="t-hg-col">
                  <span class="cmsppmdcontactPopup link" id="cmsppmdContactPopup_{{list.assignmentCodeId}}"
                    (mousedown)="displayContactPopup(list.assignmentCodeId, list.cmseppmdEmailId)">{{list.cmsppmd}}
                  </span>
                  <input type="hidden" id="listViewtxtengId" value="" />
                </td>
                <td data-label="EQCR:" class="t-hg-col">
                  <span class="cmsppmdcontactPopup link" id="eqcrContactPopupTable_{{list.assignmentCodeId}}"
                    (mousedown)="displayEQCRContactPopupTable(list.assignmentCodeId, list.assignmentEQCREmailId)">{{list.assignmentEQCR}}
                  </span>
                  <input type="hidden" id="listViewtxtengId" value="" />
                </td>
                <td data-label="Assignment administrator:" class="t-hg-col">
                  <span class="cmsppmdcontactPopup link" id="cardViewEPPMDcontactPopup_{{list.assignmentCodeId}}"
                    (mousedown)="displayCardViewContactPopup(list.assignmentCodeId, list.adminEmailId)">{{list.assignmentAdministrator }}
                  </span>
                  <input type="hidden" id="cardViewtxtengId" value="" />
                </td>
                <td data-label="Solution code:" class="t-sc-col"> {{list.solutionCode }}
                </td>
                <td data-label="Status:" class="t-sc-col"> {{list.status }} </td>
                <td data-label="INDUS approved:" class="t-hg-col">  {{list.indusApproved}}</td>
                <td data-label="Assignment code:" class="t-hg-col"> {{list.assignmentCode }} </td>
                <td data-label="Last activity:" class="t-ia-col"> {{list.lastActivityDate | date:'MM/dd/yyyy' }} </td>
                <td data-label="." class="text-center t-sm-col text-white">
                  <a *ngIf="isValidateSiteURL(list.siteURL)" href="{{list.siteURL}}" target="_blank">
                    <img alt="sharepoint" src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" />
                  </a>
                </td>
              </tr>

            </tbody>
            <div *ngIf="listCount == 0 || listCount == null">
              <div class="no-data-available no-data">{{listDataMessage}}</div>
            </div>
          </table>

          <!-- pager start-->
          <!-- pager End-->
        </div>
      </div>
      <!-- ListView Pagination Start -->
      <div class="d-flex justify-content-center text-center pagination-section" *ngIf="listCount > 0">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
          <li>
            <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="listViewSetPage(1)">
              <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first-page" />
            </a>
          </li>
          <li>
            <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
              (click)="listViewSetPage(pager.currentPage - 1)">
              <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous-page" />
            </a>

          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="listViewSetPage(page)">{{page}}</a>
          </li>

          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.currentPage + 1)">
              <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next-page" />
            </a>
          </li>
          <li>
            <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
              (click)="listViewSetPage(pager.totalPages)">
              <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last-page" />
            </a>
          </li>
        </ul>
        <div>
          <select id="drprecordsPerPage" [(ngModel)]="listViewNoOfRecordsPerPage" name="drpRecordsPerPage" (change)="listViewSetPage(1)" class="page-counts">
            <option *ngFor="let listViewNoOfRecordsPerPage of noOfRecordsPerPageList" value="{{listViewNoOfRecordsPerPage}}">{{listViewNoOfRecordsPerPage}}</option>
          </select>
        </div>

      </div>
      <!-- ListView Pagination End -->
    </div>
    <!-- MainContainer End -->
  </div>
  <!--ListView Design End-->

</div>
<!-- List View and card view main container End-->
<!-- end MainContainer -->
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>

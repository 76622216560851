import { WorkspaceModel } from './WorkspaceModel';

export class HotDocsVariableMapping {
    hotDocsVariableMappingId: number;
    engageColumnName: string;
    hDVariableName: string;
    inputType: string;
    isActive: boolean;
    engagementId: number;
    workItemId: string;
}

export class HotDocsAnswersToEngage {
    hotDocsVariableMappingId: number;
    hotDocsAnswersToEngageId: number;
    value: string;
    engagementId: number;
    createdBy: string;
}
export class HotDocsVariableMappingList {
    hDVariableMappingList: HotDocsVariableMapping[];
    engagementId: number;
    workItemId: string;
}

export class UpdateStatusAddHDAnsToEngage {
    hDAnswersToEngage: HotDocsAnswersToEngage[];
    workspace: WorkspaceModel;
    hdDocumentId: string;
    hdDocumentTitle: string;
}
export class HDSessionAnsToEngModel {
    hdAnstoEngList: HotDocsAnswersToEngage[];
    workItemVersionId: string;
    templatePackageVersionId: string;
    documentId: string;
    title: string;
}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert } from 'src/app/model/Alert';
import { PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { RetentionExtensionModel } from 'src/app/model/Engagement/RetentionExtentionModel';
import { CloseoutSections, EngagementCloseoutConfiguration } from 'src/app/model/EngagementListModel';
import { HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { HDSessionAnsToEngModel, HotDocsVariableMapping, HotDocsVariableMappingList, UpdateStatusAddHDAnsToEngage } from 'src/app/model/HotDocsVariableMapping';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { WorkspaceDocumentModel } from 'src/app/model/WorkspaceDocumentModel';
import { WorkspaceModel } from 'src/app/model/WorkspaceModel';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { EngagementService } from 'src/app/services/EngagementService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
declare var $;
@Component({
  selector: 'app-engagement-closeout-retention',
  templateUrl: './engagement-closeout-retention.component.html',
  styleUrls: ['./engagement-closeout-retention.component.css']
})
export class EngagementCloseoutRetention implements OnInit {

  isImageEnabled: boolean = false;
  isRequiredForFileCloseOut: boolean = true;
  fileCloseoutInterviewOptions: any;
  IntegrationAPIEndPoint: string;
  fileCloseoutInterviewList: InterviewModel;
  isHiddenProgressModal: boolean = false;
  isFileCloseOutScreen: boolean = false;

  fileCloseOutEngagementId: number = 0;
  createWorkItemDetails: HDWorkItemModel = new HDWorkItemModel();
  addWorkspaceDocumentList: WorkspaceDocumentModel[] = [];
  workItemDetails: HDWorkItemModel = new HDWorkItemModel();
  workspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  engSiteUniqueId: string;
  currentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  alert: Alert;
  fileCloseoutHDTemplatePackageId: string;
  interviewSavedSuccess: boolean = false;
  hdVariableMappingList: HotDocsVariableMappingList = new HotDocsVariableMappingList();
  hdVariableMappingData: HotDocsVariableMapping[] = [];
  hdSessionAnswersToEngage: HDSessionAnsToEngModel = new HDSessionAnsToEngModel();
  TemplatePackageDetails: TemplatePackageModel = new TemplatePackageModel();
  addUpdateStatusHDAnswers: UpdateStatusAddHDAnsToEngage = new UpdateStatusAddHDAnsToEngage();
  isRetentionExtensionRequired: boolean = true;
  noOfYearsExtention: number = 0
  yearsList: number[];
  retentionExtensionModel: RetentionExtensionModel = new RetentionExtensionModel();

  showRequiredWarning: boolean = false;
  showExtensionRequiredMsg: boolean = false;
  @Input()
  sectionConfiguration: EngagementCloseoutConfiguration;

  @Input() set engagementId(engid: number) {
    if (engid != undefined && engid != null && engid > 0) {
      this.fileCloseOutEngagementId = engid;
      console.log("selectEngagementId : ", this.fileCloseOutEngagementId);
    }
  }

  @Input() set siteUniqueID(siteId: string) {
    if (siteId != null && siteId != undefined && siteId != '') {
      this.engSiteUniqueId = siteId;
    }
  }

  @Input() set closeoutHDTemplatePackageId(templateId: string) {
    if (templateId != null && templateId != undefined && templateId != '') {
      this.fileCloseoutHDTemplatePackageId = templateId;
    }
  }

  @Output() collapseEvent: EventEmitter<string> = new EventEmitter<string>();
  currentState: string;
  userObject: any;
  engagementCloseoutConfiguration: EngagementCloseoutConfiguration[] = [];
  isSaveChecklist: boolean = false;
  constructor(private environment: AppConfigService,
    private loadingService: LoadingScreenService,
    private alertService: AlertsService,
    private engagementService: EngagementService
  ) {
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.currentState = 'collapse';
    this.isImageEnabled = false;
  }

  ngOnInit(): void {
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    if (this.sectionConfiguration.isDefaultOpen) {
      this.toggleDown();
    }
    this.setYearList();
    this.getRetentionExtensionData();
  }

  ngOnDestroy() {
    this.loadingService.stopLoading();
  }

  toggleUp(flag: boolean) {
    this.isImageEnabled = true;
    this.currentState = 'expand';
    if (!flag) {
      this.currentState = 'expand';
      this.isSaveChecklist = false;
      this.collapseEvent.emit(this.currentState);
    }
    $('#collapseOne').show();
  }

  toggleDown() {
    this.interviewSavedSuccess = false;
    this.isImageEnabled = false;
    this.currentState = 'collapse';
    $('#collapseOne').hide();
  }

  toggleUpDown(flag: boolean) {   
    this.isImageEnabled = false;
    this.currentState = 'collapse';
    $('#collapseOne').hide();
  }
  
  DisplaySystemAlert() {
    this.alertService.remove(this.alert);
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  onIsRetentionExtensionRequiredClick(selectedValue: string) {
    // alert(selectedValue);
    this.isSaveChecklist = false;
    this.showExtensionRequiredMsg = false;
    if (selectedValue === "Yes") {
      this.isRetentionExtensionRequired = true;
    }
    else if (selectedValue === "No") {
      this.isRetentionExtensionRequired = false;
    }
  }

  changeYear() {
    this.isSaveChecklist = false;
  }

  setYearList() {
    this.yearsList = [];
    this.yearsList.push(8);
    this.yearsList.push(9);
    this.yearsList.push(10);
    this.yearsList.push(11);
    this.yearsList.push(12);
    this.yearsList.push(13);
    this.yearsList.push(14);
    this.yearsList.push(15);
    this.yearsList.push(16);
    this.yearsList.push(17);
    this.yearsList.push(18);
    this.yearsList.push(19);
    this.yearsList.push(20);

  }

  getRetentionExtensionData() {
    this.retentionExtensionModel.isGetExtentionData = true;
    this.retentionExtensionModel.isRetentionExtensionRequired = false;
    this.retentionExtensionModel.noOfYearsExtension = 0;
    this.retentionExtensionModel.engagementId = this.fileCloseOutEngagementId;
    this.retentionExtensionModel.sectionShortName = this.sectionConfiguration.code;
    this.retentionExtensionModel.loggedInUser = this.userObject.loggedInUserEmail;
    this.retentionExtensionModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engagementService.GetAddUpdateRetentionExtention(this.retentionExtensionModel).subscribe(result => {
      console.log(result);
      this.isRetentionExtensionRequired = result.isRetentionExtensionRequired;
      this.noOfYearsExtention = result.noOfYearsExtension;      
    }, error => {
      this.DisplaySystemAlert();
    });
  }

  SaveChecklist() {
    if (this.isRetentionExtensionRequired && this.noOfYearsExtention == 0) {
      this.showExtensionRequiredMsg = true;
    } else {
      this.showExtensionRequiredMsg = false;
    }

    if (!this.showExtensionRequiredMsg) {
      this.retentionExtensionModel.isGetExtentionData = false;
      this.retentionExtensionModel.isRetentionExtensionRequired = this.isRetentionExtensionRequired;
      this.retentionExtensionModel.noOfYearsExtension = this.noOfYearsExtention;
      this.retentionExtensionModel.engagementId = this.fileCloseOutEngagementId;
      this.retentionExtensionModel.sectionShortName = this.sectionConfiguration.code;
      this.retentionExtensionModel.loggedInUser = this.userObject.loggedInUserEmail;
      this.retentionExtensionModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

      this.engagementService.GetAddUpdateRetentionExtention(this.retentionExtensionModel).subscribe(result => {
        console.log(result);
        this.isRetentionExtensionRequired = result.isRetentionExtensionRequired;
        this.noOfYearsExtention = result.noOfYearsExtension;
        this.isSaveChecklist = true;
        this.getEngagementCloseoutConfigurations();
      }, error => {
        this.DisplaySystemAlert();
      });

    }

  }

  getEngagementCloseoutConfigurations() {
    this.engagementService.GetEngagementCloseoutConfigurations(this.fileCloseOutEngagementId).subscribe(engagementCloseoutConfigurations => {
      if (engagementCloseoutConfigurations && engagementCloseoutConfigurations.length > 0) {
        this.sectionConfiguration = engagementCloseoutConfigurations.find(section =>
          section.code === CloseoutSections.RM);
          this.showRequiredWarning = !this.sectionConfiguration.isCompleted;
      }
    },
      err => {
        console.error(err);
      });
  }

}
<div class="containerDiv">
  <!-- Page Title row -->
  <div class="d-flex justify-content-between pageTitleParent pb-3 mw-100">
    <div class="text-center">
      <h2 class=" float-md-left pageTitle largerFontSize2">Process CMS Error Request</h2>
    </div>
    <div class="text-center">
      <span class="float-md-right">
        <app-shortcut></app-shortcut>
      </span>
    </div>
  </div>
  <!-- End Page Title row -->
  <!-- Page Body row -->
  <div class="row py-3 ml-0">
    <div class="col-md-6">
      <label for="reviewId">Review Id </label>
      <input type="text" id="reviewId" class="form-control rounded-0 border engagement-name input-height" maxlength="40"
        placeholder="{{placeholder}}" (focus)="this.placeholder=''" (blur)="this.placeholder = 'Enter review id'"
        name="reviewId" [(ngModel)]="workspaceModel.workspaceId" autocomplete="off" />
    </div>
    <div class="col-md-3 padding-left-0">
      <div class="">
        <div class="">
          <div><label>&nbsp;</label></div>
          <button id="btnProcessCMSErrorRequest" class="btn PrimaryButton rounded-0 w-100 input-height" type="button"
            (click)="processCMSErrorRequest()">
            Process
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Page Body row -->
  <!-- Page Message row -->
  <div class="row m-0 pt-4">
    <div class="col-md-12 px-0">
      <b>Note :-</b> This utility primary purpose is to handle CMS code error for existing clients only (i.e. a client
      that has client code ).<br />
      Request to avoid processing for new clients via this utility.<br />
    </div>
    <div class="col-md-12 px-0">
      <app-alerts></app-alerts>
    </div>
  </div>
  <!-- End Page Message row -->
</div>
import { PrimaryWorkspaceModel } from "../Engagement/CreateEngagementData";
import { WorkspaceDocumentModel } from "../WorkspaceDocumentModel";

export class CloseoutChecklistWorkspaceModel
{
    engagementId: number;
    workspaceData: PrimaryWorkspaceModel;
    workspaceDocuments: WorkspaceDocumentModel[];
    createdBy: string;
    isCloseOutChecklist: boolean = false;
    sectionId:number;
    code:string;
}
export class EngagementAuditChecklistViewModel {
    engagementId: number;
    conversationDate: any;
    improvementDate: any;
    loggedInUser: string;
    loggedInUserFullName: string;
    isConversationDateFuture: boolean = true;
    isImprovementDateFuture: boolean = true;
    isConversationDateBack: boolean = true;
    isImprovementDateBack: boolean = true;
    isConversationDateEmpty: boolean = true;
    isImprovementDateEmpty: boolean = true;
    isValidAuditChecklistDates: boolean = true;
    sectionShortName: string;
    surveyDate: Date;
    isSaved: boolean = true;
    createdDate:Date;//engagement create Date
}

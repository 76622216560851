import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClosureRequestModel } from 'src/app/model/ConveneIntegration/closure-request-model';
import { ClosureViewModel } from 'src/app/model/ConveneIntegration/closure-view-model';
import { ConveneApiLogModel } from 'src/app/model/ConveneIntegration/convene-api-log-model';
import { ConveneProjectRequestModel, ConveneProjectViewModel } from 'src/app/model/ConveneIntegration/convene-projects';
import { AppConfigService } from '../appConfigService';

@Injectable({
  providedIn: 'root'
})
export class ClosureService {

  private IntegrationAPIEndPoint: string;
  private ApiEndPoint: string;

  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.ApiEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
  }

  Notify(payload: ClosureRequestModel): Observable<ClosureViewModel> {
    return this.http.post<ClosureViewModel>(`${this.IntegrationAPIEndPoint}/Closure/Notify`, payload);
  }

  SaveClosureNotificationResponse(payload: ConveneApiLogModel): Observable<number>{
    return this.http.post<number>(`${this.ApiEndPoint}/Engagement/SaveClosureNotification`, payload);
  }
  
  GetProjects(conveneProjectRequestModel: ConveneProjectRequestModel): Observable<ConveneProjectViewModel> {    
    return this.http.post<ConveneProjectViewModel>(`${this.IntegrationAPIEndPoint}/Closure/GetProjects`, conveneProjectRequestModel);
  }
}

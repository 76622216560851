import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReviewNoteStatus } from 'src/app/model/Workspace/ReviewNote';

@Pipe({name: 'ReviewNoteCurrentUsernameTransformPipe'})
export class ReviewNoteCurrentUsernameTransformPipe implements PipeTransform {

  private loggedInUserFullName: string = '';

  constructor(private _sanitizer: DomSanitizer) {

  }

  transform(value: any, status:any): any {
    
    let jsonString = localStorage.getItem("currentUser");
    let userInfo = JSON.parse(jsonString);
    let tranformedText = value;

    if(userInfo) {
      this.loggedInUserFullName = '@' + userInfo.loggedInUserFullName;
    }

    if(value.includes(this.loggedInUserFullName) && status == ReviewNoteStatus.Open) {

      tranformedText = this._sanitizer.bypassSecurityTrustHtml(value.replace(new RegExp(this.loggedInUserFullName, 'g'), '<span class="review-note-mentioneduser-as-currentuser">'+ this.loggedInUserFullName +'</span>'));
    }

    return tranformedText;
  }
}


<div id="client-contact-popup" *ngIf="client != null" class="d-none">
  <div class="contact-close float-right">
    Close <img src="../../../../assets/images/EDP/Workspace/close-24px.svg" alt="Close" />
  </div>
  <div class="contact-popup-container">    
    <div class="contact-name">
      {{client.contactName}}
    </div>
    <div class="contact-job-title">
      Grant Thornton • {{client.jobTitle}}
    </div>
    <div class="contact-email mt-1">
      Email: <a href="mailto:{{client.email}}">{{client.email}}</a>
    </div>
    <div class="contact-phone">
      Phone: <a href="tel:+1{{client.phoneNumber}}">(+1) {{client.phoneNumber}}</a>
    </div>
  </div>
</div>
import { ClientLevelDocument } from './ClientLevelDocument';

export class EditClient {
  clientId: number;
  clientCode: string;
  clientName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  stateId: number;
  stateCode: string;
  stateName: string;
  countryId: number;
  countryCode: string;
  countryName: string;
  zipCode: string;
  clientStatus: string;
  phoneNumber: string;
  fax: string;
  relationshipPartnerName: string;
  relationshipPartnerEMail: string;
  clientAdministratorContactName: string;
  clientAdministratorEmail: string;
  businessClientContact: string;
  clientContactName: string;
  clientContactTitle: string;
  clientContactEmail: string;
  clientBillingName: string;
  clientBillingTitle: string;
  clientBillingEmail: string;
  publicPrivate: string;
  clientProgram: string;
  clientType: string;
  serviceLine: [
    string
  ];
  isAudit: boolean;
  isTax: boolean;
  isAdvisory: boolean;
  lastActivityDate: string;
  fye: string;
  industryType: string;
  restrictedList: string;
  companyType: string;
  region: string;
  stockExchange: string;
  tickerSymbol: string;
  status: string;
  clientStatusId: number;
  email: string;
  sicCodeId: number;
  referralSource: string;
  clientRepositoryUrl: string;
  isNewClient: boolean;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  statusColor: string;
  serviceLineList: string;
  serviceLineFlags: string;
  clientTypeId: number;
  clientProgramId: number;
  industryTypeId: number;
  stockExchangeId: number;
  referralSourceId: number;
  fyeMonth: number;
  fyeMonthName: string;
  sicCode: string;
  isAgreement: boolean;
  isMSA: boolean;  
  isClientDisabled: boolean;
  structEntityId: number;
  structEntity: string;
  websiteAddress: string;
  contactTypeCodeId: number;
  billingContactTitleCodeId: number;
  oneViewAccountId:string;
  clientLevelDocument: ClientLevelDocument;
}
  
export class ClientLevelDocument{
   clientLevelDocumentId: number;
   clientId: number;
   documentSubTypeId: number;
   documentSubTypeCode: string;
   documentSubTypeValue: string;
   templatePackageId: string;
   templateName: string;
   templateVersion: number;
   effectiveDate: Date;
   expirationDate: Date;
   documentDescription: string;
   createdOn: Date;
   createdBy: string;
   updatedOn: Date;
   updatedBy: string;
   gtAgreementOwnerName: string;
   gtAgreementOwnerEmail: string;
   inProgressEngagementCount: number;
   selectedActionName: string = null;
   engLevelCustonMSA: boolean;
}

export enum DocumentSubTypesEnum {
   ExistsElsewhere = 'Document exists elsewhere',
   NoOptionExists = 'Create new engagement letter or MSA, or no governing agreement applies (e.g., inbound interfirm engagement)',
   CustomMSACode = 'CMSA',
   ByPassCode = 'BY'
}

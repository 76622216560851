import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { KeyValue } from '../model/KeyValue';
import { NavigationRoleGridModel } from '../model/Navigation/NavigationRoleGridModel';
import { AppConfigService } from './appConfigService';
import { NavigationRoleViewModel } from '../model/Navigation/NavigationRoleViewModel';
import { DocumentTypeListModel, DocumentTemplateMappingListModel } from '../model/DocumentTypeListModel';
import { SolutionCodeMappingModel, SolutionCodeMasterModel, SolutionCodeStatusModel } from 'src/app/model/SolutionCodeMappingModel';
import { SolutionCodeSearchFilter } from 'src/app/model/SolutionCodeSearchFilter'
import { solutionCodeType } from '../model/solutionCodeType';
import { MappingTemplatePackageModel, PostMappingTemplatePackageModel } from '../model/TemplatePackageModel';
import { ClientSearch } from '../model/Client/ClientSearch';
import { CardsRoleMapping } from '../model/CardsRoleMapping';
import { ListBySourceModel } from '../model/list-by-source-model';
import { PostMappingPSDModel, PSDDataForUserCheck, solutionCodePSDMaping } from '../model/SolutionCodePSD';
import { PsdMembersWithDelegate } from '../model/Workspace/DecisionsRPRequestModel';
import { SolutionCodeDetailsModel } from '../model/solution-code-details-model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  APIEndPoint: string;
  userName: string;
  isMemberFirm: boolean;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.initializeUserObject();
  }

  initializeUserObject() {
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.isMemberFirm = nameJSON.isMemberFirmFeatureEnabled;
    }    
  }

  getRolesList(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/Configuration/Roles');
  }

  getNavigationBasedRoleList(): Observable<NavigationRoleGridModel[]> {
    return this.http.get<NavigationRoleGridModel[]>(this.APIEndPoint + '/Configuration/NavigationBasedRoleList');
  }

  getNavigationInformationById(navigationId: number): Observable<NavigationRoleViewModel> {
    return this.http.get<NavigationRoleViewModel>(this.APIEndPoint + '/Configuration/NavigationInformation/' + navigationId);
  }

  getNavigationBasedRolesById(navigationId: number): Observable<number[]> {
    return this.http.get<number[]>(this.APIEndPoint + '/Configuration/NavigationRoleList/' + navigationId);
  }

  postNavigationBasedRoles(formData: NavigationRoleViewModel): Observable<KeyValue> {
    return this.http.post<KeyValue>(this.APIEndPoint + '/Configuration/SaveNavigationBasedRoles', formData);
  }

  putNavigationBasedRoles(formData: NavigationRoleViewModel) {
    return this.http.put(this.APIEndPoint + '/Configuration/SaveNavigationBasedRoles', formData);
  }

  deleteNavigationBasedRoles(navigationId: number) {
    return this.http.get(this.APIEndPoint + '/Configuration/RemoveNavigationInformation/' + navigationId);
  }

  getCardTypeList(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/Configuration/CardType');
  }

  getCardList(cardTypeId: number): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/Configuration/CardsByType?cardTypeId=' + cardTypeId.toString());
  }

  getDocumentTypeList(): Observable<DocumentTypeListModel> {
    return this.http.get<DocumentTypeListModel>(this.APIEndPoint + '/Configuration/DocumenTypeList');
  }
  InsertCardRoleMapping(cardRoleMappingData: CardsRoleMapping) {
    cardRoleMappingData.username = this.userName;
    return this.http.post(this.APIEndPoint + '/Configuration/InsertUpdateCardRoleMapping', cardRoleMappingData);
  }

  getcardRoleMappingList(): Observable<CardsRoleMapping[]> {
    return this.http.get<CardsRoleMapping[]>(this.APIEndPoint + '/Configuration/GetAllCardsandRoleMappingList');
  }
  deleteCardRoleMapping(cardRoleMapping: CardsRoleMapping) {
    return this.http.post(this.APIEndPoint + '/Configuration/DeleteCardRoleMapping', cardRoleMapping);
  }
  getcardRoleMappingById(cardRoleMapping: CardsRoleMapping): Observable<CardsRoleMapping> {
    return this.http.post<CardsRoleMapping>(this.APIEndPoint + '/Configuration/GetCardRoleMappingById', cardRoleMapping);
  }
  UpdateCardRoleMapping(cardRoleMappingData: CardsRoleMapping) {
    cardRoleMappingData.username = this.userName;
    return this.http.post(this.APIEndPoint + '/Configuration/InsertUpdateCardRoleMapping', cardRoleMappingData);
  }

  getSolutionCodeMappingListView(pageNumber: number, noOfrecordsPerPage: number,
    solutionCodeSearchFilter: SolutionCodeSearchFilter,
    orderByColumn: string, orderByType: string): Observable<SolutionCodeMappingModel[]> {
    return this.http.post<SolutionCodeMappingModel[]>(this.APIEndPoint + "/Configuration/GetSolutionCodeMappingList?pageNumber=" + pageNumber + "&noOfrecordsPerPage=" + noOfrecordsPerPage + "&orderByColumn=" + orderByColumn + "&orderByType=" + orderByType, solutionCodeSearchFilter);
  }

  // getSolutionCodesById(SolutionCodeId: number): Observable<SolutionCodeMappingModel> {
  //   return this.http.get<SolutionCodeMappingModel>(this.APIEndPoint + '/Configuration/GetSolutionCodesById?SolutionCodeId=' + SolutionCodeId);
  // }

  getSecondarySolutionCodeMapping(solutionCodeId: number): Observable<SolutionCodeMasterModel[]> {
    return this.http.get<SolutionCodeMasterModel[]>(this.APIEndPoint + '/Configuration/GetSecondarySolutionCodeMapping?solutionCodeId=' + solutionCodeId);
  }

  updateSolutionCodeMapping(SolutionCodeMappingData: SolutionCodeMappingModel) {
    SolutionCodeMappingData.userId = this.userName;
    return this.http.post(this.APIEndPoint + '/Configuration/InsertUpdateSolutionCodeMapping', SolutionCodeMappingData);
  }

  getSolutionCodeType(): Observable<solutionCodeType[]> {
    return this.http.get<solutionCodeType[]>(this.APIEndPoint + "/ApplicationMaster/GetSolutionCodeType", { withCredentials: true });
  }

  getSolutionCodeMasterData(): Observable<SolutionCodeMasterModel[]> {
    return this.http.get<SolutionCodeMasterModel[]>(this.APIEndPoint + "/Configuration/GetSolutionCodeDetails", { withCredentials: true });
  }

  getAllSolutionCodeListCount(solutionCodeSearchFilter: SolutionCodeSearchFilter): Observable<number> {
    return this.http.post<number>(this.APIEndPoint + "/Configuration/GetAllSolutionCodeListCount", solutionCodeSearchFilter);
  }

  getSolutionCodeStatus(): Observable<SolutionCodeStatusModel[]> {
    return this.http.get<SolutionCodeStatusModel[]>(this.APIEndPoint + "/ApplicationMaster/GetSolutionCodeStatus", { withCredentials: true });
  }

  getSecondarySolutionCodeDetails(): Observable<SolutionCodeMasterModel[]> {
    return this.http.get<SolutionCodeMasterModel[]>(this.APIEndPoint + "/Configuration/GetSecondarySolutionCodeDetails", { withCredentials: true });
  }

  GetListBySource(source: string): Observable<KeyValue[]> {
    let listBySourceModel: ListBySourceModel = new ListBySourceModel();
    let stringArray = source.split('_');

    listBySourceModel.source = stringArray[0];

    if (stringArray.length > 1 && !this.isMemberFirm) {
       listBySourceModel.username = stringArray[1];
       listBySourceModel.isLoggedInUserAdmin = stringArray[2];      
    }
    else if(stringArray.length > 1 && this.isMemberFirm)
    {
       listBySourceModel.username = stringArray.slice(1,-1).join();
       listBySourceModel.isLoggedInUserAdmin = stringArray[stringArray.length - 1];
    }

    return this.http.post<KeyValue[]>(this.APIEndPoint + '/Configuration/GetListBySource', listBySourceModel);
  }

  GetIdByValueFromSource(source: string, inputtedText: string): Observable<number> {
    return this.http.get<number>(this.APIEndPoint
      + '/Configuration/GetIdByValueFromSource'
      + '?Source=' + source
      + '&InputString=' + inputtedText);
  }

  deleteTemplateMapping(documentTypeId: number, subDocumentTypeId: number) {
    return this.http.get(this.APIEndPoint + '/Configuration/DeleteTemplateMapping?DocumentTypeId=' + documentTypeId + "&SubDocumentTypeId=" + subDocumentTypeId);
  }

  getDocumentTemplateMapping(): Observable<DocumentTemplateMappingListModel[]> {
    return this.http.get<DocumentTemplateMappingListModel[]>(this.APIEndPoint + '/Configuration/GetDocumentTemplateMapping');
  }

  getMappingTemplatePackages(documentTypeId: number, subDocumentTypeId: number): Observable<MappingTemplatePackageModel[]> {
    return this.http.get<MappingTemplatePackageModel[]>(this.APIEndPoint + '/Configuration/GetMappingTemplatePackagesById?DocumentTypeId=' + documentTypeId + '&SubDocumentTypeId=' + subDocumentTypeId);
  }

  updateTemplateMapping(templateMappingData: PostMappingTemplatePackageModel) {
    return this.http.post(this.APIEndPoint + '/Configuration/UpdateTemplateMapping', templateMappingData,
      { responseType: 'text' });
  }

  isSolutionCodeShortNameExists(solutioncodeshortname: string): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + '/Configuration/IsSolutionCodeShortNameExists/' + solutioncodeshortname);
  }

  GetClients(clientSearch: ClientSearch): Observable<ClientSearch[]> {
    return this.http.post<ClientSearch[]>(this.APIEndPoint + '/Configuration/ClientsBySearchTerm/', clientSearch);
  }

  getSolutionCodeTemplateMappingData(documentCategory: string, solutionCodeId: number, isExecutionPhase: boolean): Observable<MappingTemplatePackageModel[]> {
    return this.http.get<[MappingTemplatePackageModel]>(this.APIEndPoint + "/Configuration/GetSolutionCodeTemplateMappingData/" + documentCategory + '/' + solutionCodeId + '/' + isExecutionPhase);
  }
  insertupdatePSDMapping(PSDMappingData: PostMappingPSDModel) {
    return this.http.post(this.APIEndPoint + '/Configuration/InsertUpdatePSDMapping', PSDMappingData,
      { responseType: 'text' });
  }
  getPSDMappingData(solutionCodeId: number) {
    return this.http.get<[solutionCodePSDMaping]>(this.APIEndPoint + "/Configuration/GetPSDMappingData/" + solutionCodeId);
  }

  getPSDMappingDataByEngId(engagementId: number) {
    return this.http.get<[PSDDataForUserCheck]>(this.APIEndPoint + "/Configuration/GetPSDMappingDataByEngId/" + engagementId);
  }

  deletePSDMappingData(solutionCodeId: number) {
    return this.http.delete<string>(this.APIEndPoint + "/Configuration/DeletePSDMappingData/" + solutionCodeId);
  }

  AddUpdatePSDDelegation(psdMembersWithDelegate: PsdMembersWithDelegate) {
    return this.http.post(this.APIEndPoint + '/Configuration/AddUpdatePSDDelegation', psdMembersWithDelegate,
      { responseType: 'text' });
  }
  getSolutioncodeDetailsBySolutionCodeId(workspaceId:number):Observable<SolutionCodeDetailsModel>{
    return this.http.get<SolutionCodeDetailsModel>(this.APIEndPoint + "/Configuration/GetSolutioncodeDetailsBySolutionCodeId/" + workspaceId);
  }
}

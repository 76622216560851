import { Injectable } from '@angular/core';
import { AppConfigService } from '../appConfigService';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Employee } from 'src/app/model/Workspace/Employee';
import { SearchEmployeeModel } from 'src/app/model/Workspace/SearchEmployeeModel';
import { ContactPopupModel } from 'src/app/model/Popup/ContactPopupModel';
import { AzureADGroups } from 'src/app/model/AzureADUsersArrayList';
import { MembersList } from 'src/app/model/MembersList';

@Injectable({
    providedIn: 'root'
})
export class GraphService {

    IntegrationAPIEndPoint: string;
    constructor(private environment: AppConfigService, private http: HttpClient) {
        this.IntegrationAPIEndPoint = environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    }

    GetEmployeeIdForUserList(searchEmployee: SearchEmployeeModel): Observable<Employee[]> {
        const httpHeaders = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        const options = {
            headers: httpHeaders
        };
        return this.http.post<Employee[]>(this.IntegrationAPIEndPoint + '/Graph/EmployeeIds/', searchEmployee, options);
    }

    GetContactInfo(contactEmailId: string): Observable<ContactPopupModel> {
        return this.http.post<ContactPopupModel>(this.IntegrationAPIEndPoint + '/Graph/UserByEmail',
            { EmailId: contactEmailId });
    }

    getAzureAdUsersByGroups(azureADGroups: AzureADGroups, roleName: string): Observable<MembersList[]> {
        const requestHeaders = new HttpHeaders().set('Content-type', 'application/json');
        azureADGroups.roleName = roleName;
        return this.http.post<MembersList[]>(this.IntegrationAPIEndPoint + '/Graph/UserByGroup', azureADGroups,
            { headers: requestHeaders });
    }

    GetUserById(azureAdUserId: string): Observable<ContactPopupModel> {
        return this.http.post<ContactPopupModel>(this.IntegrationAPIEndPoint + '/Graph/GetUserById',
            { id: azureAdUserId });
    }
}

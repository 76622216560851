import { Injectable } from '@angular/core';
import { AppConfigService } from './appConfigService';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoverningAgreemenRequest, GoverningAgreementNameAndStatusRequest } from '../model/CLM/governing-agreemen-request';
import { GoverningAgreementNameAndStatusResponse, GoverningAgreementResponse } from '../model/CLM/governing-agreement-response';
import { CLMResponse } from '../model/CLM/clm-response';
import { EngagementLinkRequest } from '../model/CLM/engagement-link-request';
import { CLMDetails } from '../model/CLM/clm-details';
import { CLMApiLog } from '../model/CLM/clm-api-log';
import { ClmSignedDocumentRequest } from '../model/CLM/clm-signed-document-request';

@Injectable({
  providedIn: 'root'
})
export class CLMService {
  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  constructor(private environment: AppConfigService, private http: HttpClient) {
    this.IntegrationAPIEndPoint = environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.APIEndPoint = this.environment.config.DATA.API_END_POINT.API_URL;
  }

  getCLMFeatureFlag(): Observable<boolean> {
    return this.http.get<boolean>(this.IntegrationAPIEndPoint + '/CLM/GetCLMFeatureFlag');
  }

  getAllGoverningAgreementsById(governingAgreemenRequest: GoverningAgreemenRequest): Observable<GoverningAgreementResponse> {
    return this.http.post<GoverningAgreementResponse>(this.IntegrationAPIEndPoint + '/CLM/GetAllGoverningAgreementsById', governingAgreemenRequest);
  }
  getAllGoverningAgreementsLinked(governingAgreemenRequest: GoverningAgreemenRequest): Observable<GoverningAgreementResponse> {
    return this.http.post<GoverningAgreementResponse>(this.IntegrationAPIEndPoint + '/CLM/getAllGoverningAgreementsLinked', governingAgreemenRequest);
  }
  linkEngagementtoCLM(engagementLinkRequest: EngagementLinkRequest): Observable<CLMResponse> {
    return this.http.post<CLMResponse>(this.IntegrationAPIEndPoint + '/CLM/LinkEngagementtoCLM', engagementLinkRequest);
  }

  insertCLMDetails(cLMDetails: CLMDetails) {
    return this.http.post(this.APIEndPoint + '/CLM/InsertCLMDetails', cLMDetails, { responseType: 'text' });
  }

  getGoverningAgreementNameAndStatusByEngagementId(governingAgreementNameAndStatusRequest: GoverningAgreementNameAndStatusRequest): Observable<GoverningAgreementNameAndStatusResponse> {
    return this.http.post<GoverningAgreementNameAndStatusResponse>(this.IntegrationAPIEndPoint + '/CLM/GetGoverningAgreementNameAndStatusByEngagementId', governingAgreementNameAndStatusRequest);
  }
  insertCLMApiLog(clmApiLog: CLMApiLog): Observable<string> {
    return this.http.post<string>(this.APIEndPoint + '/CLM/InsertCLMApiLog', clmApiLog);
  }
  sendSignedDocumentsToCLM(signedDocumentRequest: ClmSignedDocumentRequest): Observable<CLMResponse> {
    if (signedDocumentRequest != null && signedDocumentRequest.effectiveDate != null && signedDocumentRequest.effectiveDate != undefined) {
      signedDocumentRequest.effectiveDate = new Date(Date.UTC(signedDocumentRequest.effectiveDate.getFullYear(), signedDocumentRequest.effectiveDate.getMonth(), signedDocumentRequest.effectiveDate.getDate()));
    }
    return this.http.post<CLMResponse>(this.IntegrationAPIEndPoint + '/CLM/SendSignedDocumentsToCLM', signedDocumentRequest);
  }

  getCLMDetails(engagementId: number): Observable<CLMDetails> {
    return this.http.get<CLMDetails>(this.APIEndPoint + '/CLM/GetCLMDetails/' + engagementId);
  }
}

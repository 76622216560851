export class AssignmentResponse
{
    api: string;
    requestBody: string;
    requestBy: string;
    requestDate: Date;
    requestStatus: string;
    responseBody: string;
    responseDate: Date;
    responseStatus: string;
    assignmentCodeId: number;
    errorDetail: string;
    responseBodyForMoreThanOneAssignment: string;
}

export class AssignmentResponseList
{
    list: AssignmentResponse[];
}
import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from "@angular/common/http";
//import { environment } from 'src/environments/environment'

import { Observable } from "rxjs";
import { DocumentListModel, DocumentList } from '../model/DocumentListModel'
import { EngagementList, EngagementApprovalsDataModel, DelegatedApprovalsDataModel } from '../model/EngagementListModel'
import { AppConfigService } from './appConfigService';

@Injectable()
export class MyReviewsService {
  APIEndPoint: string;
  userName: string;
  engagement: EngagementList = new EngagementList();
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    let nameJSON = JSON.parse(localStorage.getItem("currentUser"));
    if (nameJSON != null)
      this.userName = nameJSON.userName;
  }

  getAllApprovalsByUserCount(awaitingImmediateApproval: boolean, awaitingTieredApproval: boolean, approved: boolean, declined: boolean): Observable<number> {
    return this.http.post<number>(this.APIEndPoint +
      '/Engagement/GetAllApprovalByUserCount', { userName: this.userName, awaitingImmediateApproval: awaitingImmediateApproval, awaitingTieredApproval: awaitingTieredApproval, approved: approved, declined: declined});
  }
  getAllDelegatedApprovalsByUserCount(): Observable<number> {
    return this.http.post<number>(this.APIEndPoint +
      '/Engagement/GetAllDelegatedApprovalByUserCount', { userName: this.userName});
  }

  getAllApprovalsByUser(pageNumber: number, noOfRecordsPerPage: number,
                        orderByColumn: string, orderByType: string, awaitingImmediateApproval: boolean, awaitingTieredApproval: boolean, approved: boolean, declined: boolean
  ): Observable<EngagementApprovalsDataModel[]> {
  return this.http.post<EngagementApprovalsDataModel[]>(this.APIEndPoint + '/Engagement/GetAllApprovalsByUser',
  { pageNumber: pageNumber, noOfRecordsPerPage: noOfRecordsPerPage, userName: this.userName, orderByColumn: orderByColumn, orderByType: orderByType, awaitingImmediateApproval: awaitingImmediateApproval, awaitingTieredApproval: awaitingTieredApproval, approved: approved, declined: declined});
  }
  getAllDelegatedApprovalsByUser(pageNumber: number, noOfRecordsPerPage: number,
    orderByColumn: string, orderByType: string
  ): Observable<DelegatedApprovalsDataModel[]> {
  return this.http.post<DelegatedApprovalsDataModel[]>(this.APIEndPoint + '/Engagement/GetAllDelegatedApprovalsByUser',
  { pageNumber: pageNumber, noOfRecordsPerPage: noOfRecordsPerPage, userName: this.userName, orderByColumn: orderByColumn, orderByType: orderByType});
  }
}
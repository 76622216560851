import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReacceptanceClientListModel, ReacceptanceListModel } from "src/app/model/ReacceptanceClientListModel";
import { ReacceptanceClientsService } from "../../services/reacceptanceClientsService";
import { ContinuanceDialogTitles, InitiateNewEngagement } from "src/app/model/InitiateNewEngagement";
import { NewClient } from "src/app/model/Client/NewClient";
import { AlertsService } from "../../services/alerts.service";
import { EngagementCollabData, Engagements } from "src/app/model/Engagement/Engagements";
import { AzureADUsersArrayList, IndusADUsersListViewModel, IndusADUsersViewModel } from 'src/app/model/AzureADUsersArrayList';
import 'src/assets/Style/excel-bootstrap-table-filter-bundle.js';
import { Alert } from "src/app/model/Alert";
import { AdUsersService } from 'src/app/services/adusers.service';
import { InitiateReacceptanceEngagementsViewModel } from 'src/app/model/InitiateReacceptanceEngagementsViewModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ImageModel, HeaderModel } from 'src/app/model/EngagementListModel';
import { PagerService } from 'src/app/services/pager.service';
import { NewEngagementData } from 'src/app/model/Engagement/CreateEngagementData';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { EngagementService } from 'src/app/services/EngagementService';
import { SecondarySolutionCode } from 'src/app/model/Engagement/SecondarySolutionCodes';
import { EngagementAssignment } from 'src/app/model/Engagement/EngagementAssignments';
import { AssignmentTeamMember, TeamMember } from 'src/app/model/Engagement/TeamMember';
import { KeyValue } from 'src/app/model/KeyValue';
import { BooleanKeyValue } from 'src/app/model/BooleanKeyValue';
import { RoleNames } from 'src/app/model/Role';
import { MembersList } from 'src/app/model/MembersList';
import { SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { Subscription } from 'rxjs/internal/Subscription';
import { FieldValidator } from 'src/app/model/Engagement/FieldValidator';
import { ContinuanceUniqueDataModel, SolutionCodeModels, EngagementPartnerModels } from 'src/app/model/ContinuanceFilterModel';
import { ContinuanceListViewFilterModel } from 'src/app/model/continuance-filter-model';
import { UserPreferenceData } from 'src/app/model/UserPreferenceModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NewYearOpportunityServiceUpdateModel, NewYearOpportunityUpdateRequestModel } from 'src/app/model/Client/CreateClientViewModel';
import { ClientService } from 'src/app/services/clientservice';
import { AzureADGroupRoleShortNamesModel } from 'src/app/model/AzureADUserList';
import { TeamMemberEPModel, TeamMembers } from 'src/app/model/Workspace/TeamMembers';
import { IndusFlags, UpdateINDUSFlag } from 'src/app/model/engagements';
declare var $;

@Component({
  selector: 'app-myreacceptanceengagements',
  templateUrl: './myreacceptanceengagements.component.html',
  styleUrls: ['./myreacceptanceengagements.component.css']
})
export class MyreacceptanceengagementsComponent implements OnInit, OnDestroy {
  secondarySolutionCodeList: SecondarySolutionCode[] = [];
  secondarySolutionCode: SecondarySolutionCode[] = [];
  solutionCodeListData: SecondarySolutionCode[] = [];
  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];
  iShortcutCount: number;
  globalClientId: number = 0;
  selectedReacceptanceEngagementId = 0;
  searchedUser: SearchGroupUserViewModel = new SearchGroupUserViewModel();
  listDataMessage: string;
  orderByColumn: string;
  azureADUsersArrayList: AzureADUsersArrayList = new AzureADUsersArrayList();
  public engagementAssignmentList: Array<EngagementAssignment> = [];
  orderByType: string;
  listCount: number = null;
  arrValidationForEngagement: FieldValidator[] = [];
  DataMessage: string;
  peoplepickerIndex: number = 0;
  EpCtrl: string;
  CMSEPCtrl: string;
  ASAdminCtrl: string;
  userName: string;
  isloggedInUserAdmin: string;
  listViewNoOfrecordsPerPage: number = 10;
  listViewCurrentPageNumber: number = 1;
  intFlag: number = 0;
  arrReacceptanceListArray: ReacceptanceListModel[] = [];
  arrSecondarySolutionCode: SecondarySolutionCode[] = [];
  newEngAssignment: any = {};
  noOfrecordsPerPageList: number[];
  reacceptanceEngagementCount: number = 0;
  applyFilterOnceAfterDataLoad: boolean = false;
  reacceptanceClients: ReacceptanceClientListModel[] = [];
  reacceptanceClientForInitiate: ReacceptanceClientListModel = new ReacceptanceClientListModel();
  initiateNewEngagement: InitiateNewEngagement = new InitiateNewEngagement();
  alert: Alert;
  SolutionCodeMappedValue: string;
  fnCallBack: any;
  client: NewClient = new NewClient();
  engagement: Engagements = new Engagements();
  isPeriodRequire: boolean = false;
  membersListArrayForEP: MembersList[];
  engtypekeyValueList: KeyValue[] = [];
  epRoleName: string;
  membersListArrayForTeamMembers: MembersList[];
  membersListArray: MembersList[];
  validateEP: boolean;
  removeFlag: boolean = false;
  selectedEPIndex: string;
  isVisibleNewAssignmentDIV = false;
  isVisibledefaultAssignmentDiv = true;
  validateCMSEP: boolean;
  isSaveBtnClicked: boolean = false;
  currentEPIndex: number;
  userObject: any;
  clientId: string = '';
  eplblCtrlName: string;
  objectArray: [string, any][];
  isvalidEngEPPMDName: boolean = true;
  roleShortName: string;
  continuanceListCount: number = 0;
  private modalConfirmationDialogRef: Subscription = null;
  contingentkeyValueList: BooleanKeyValue[] = [];
  public arrEngagementAssignment: Array<EngagementAssignment>;
  initiateReacceptanceEngagements: InitiateReacceptanceEngagementsViewModel = new InitiateReacceptanceEngagementsViewModel();
  createEngagement: NewEngagementData = new NewEngagementData();
  selectedReacceptanceClient: ReacceptanceClientListModel;
  isDisabled: boolean = false;
  continuanceFilterUniqueDataList: ContinuanceUniqueDataModel;
  engagementPartnerList: EngagementPartnerModels[] = [];
  solutionCodeList: SolutionCodeModels[] = [];
  ContinuanceListMappedValue: string;
  continuanceListViewFilterModel: ContinuanceListViewFilterModel;
  reacceptanceIdOnInitiate: number = 0;

  userPreferenceData: UserPreferenceData[];
  continuanceUserPreference: UserPreferenceData = null;
  userPrefData: UserPreferenceData;
  clientSource: string;
  keyValueList: KeyValue[];
  clientFilterModel: KeyValue[];
  isOpportunityServiceFeatureFlagEnabled: boolean = false;
  updateINDUSFlag: UpdateINDUSFlag = new UpdateINDUSFlag();
  teamsMembersList: TeamMemberEPModel = new TeamMemberEPModel();
  indusMembersListArrayOnFinalApproval: Array<IndusADUsersViewModel> = [];
  isCurrentEngagementIsIndus: boolean = false;
  engagementCollabData: EngagementCollabData = new EngagementCollabData();
  carryForwadINDUSConsent: boolean;
  currentEngagementId: any;
  isMemberFirm: boolean = false;

  constructor(private _configService: ConfigurationService, private reacceptanceService: ReacceptanceClientsService
    , private engService: EngagementService
    , private loadingService: LoadingScreenService
    , private data: DataService
    , private _alertService: AlertsService
    , private adUsersService: AdUsersService
    , private pagerService: PagerService
    , private azureService: AdUsersService, private loadingScreenService: LoadingScreenService, private route: Router
    , private _workspaceService: WorkspaceService
    , private clientService: ClientService) {
    const IShortcutCount = localStorage.getItem('iShortcutCount');
    this.iShortcutCount = JSON.parse(IShortcutCount);
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    this.userName = this.userObject.loggedInUserEmail;
    this.DataMessage = 'Loading...';
    this.ContinuanceListMappedValue = 'ContinuanceListMappedValue';
    this.listDataMessage = 'Loading...';
    this.SolutionCodeMappedValue = "InitiateEngagementSolutionCode";
    this.userObject = JSON.parse(jsonString);
    var userPreferenceString = localStorage.getItem('userpreferencelist');
    this.userPreferenceData = JSON.parse(userPreferenceString);
    console.log(this.userPreferenceData);
    if (this.userPreferenceData.length > 0) {
      this.userPreferenceData.forEach(value => {
        if (value.dashBoardName === "Continuance") {
          this.continuanceUserPreference = value;
        }
      });
    }

  }
  // -- Pager service -- */
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  cardViewPager: any = {};
  // paged items
  pagedItems: any[];
  // -- Pager service End-- */
  ngOnInit() {
    this.continuanceListViewFilterModel = new ContinuanceListViewFilterModel(0, '', 0, this.userName);
    if (this.userPreferenceData.length > 0 && this.continuanceUserPreference != null) {
      this.continuanceListViewFilterModel = JSON.parse(this.continuanceUserPreference.listViewFilterList);
      this.orderByColumn = JSON.parse(this.continuanceUserPreference.listViewSortingList).orderByColumn;
      this.listViewNoOfrecordsPerPage = this.continuanceUserPreference.listViewPageNumber;
      if (this.listViewNoOfrecordsPerPage == undefined || this.listViewNoOfrecordsPerPage == null) {
        this.listViewNoOfrecordsPerPage = 10;
      }
      this.orderByType = JSON.parse(this.continuanceUserPreference.listViewSortingList).orderByType;
    }
    this.setPageRecordRange();
    this.setDefaultInitialization();
    this.LoadListViewHeaders();
    this.IsLoggedinUserSystemAdmin();
    this.MandatoryFieldValidationForEngagement();
    if (this.continuanceListViewFilterModel.clientId > 0 || this.continuanceListViewFilterModel.engagementPartner != "" ||
      this.continuanceListViewFilterModel.solutionCodeId > 0) {
      $("#filterContinuanceListView").addClass("partialActive");
    }
    this.getAllReacceptanceEngagementsByUserCount();
    //remove alert data on load
    this.eplblCtrlName = 'engagement PPMD';
    this._alertService.remove(this.alert);
    this.epRoleName = RoleNames.EngagementPartner.toString();
    this.initinateConfirmationPopUp();
    this.GetAzureADGroupsByRoleShortName(this.epRoleName.toString());
    this._configService.initializeUserObject();
    this.GetContinuanceFilterSectionUniqueData();
    this.GetClientsUniqueDataForFilters();
    //$("#initiate-continuance-click");

    this.engService.GetOpportunityServiceFeatureFlag().subscribe(result => {
      this.isOpportunityServiceFeatureFlagEnabled = result;
    });

    this.setIsMemberFirm();
  }
  GetContinuanceFilterSectionUniqueData() {
    this.reacceptanceService.GetContinuanceFilterSectionUniqueData(this.isloggedInUserAdmin).subscribe(
      result => {
        this.continuanceFilterUniqueDataList = result;
        this.engagementPartnerList = this.continuanceFilterUniqueDataList.engagementPartnerList;
        this.solutionCodeList = this.continuanceFilterUniqueDataList.solutionCodeList;
      },
      error => {
      }
    );
  }
  GetClientsUniqueDataForFilters() {
    this.clientSource = 'ClientsForContinuance' + '_' + this.userName + '_' + this.isloggedInUserAdmin;
    this._configService.GetListBySource(this.clientSource).subscribe(result => {
      this.keyValueList = result;
      this.clientFilterModel = this.keyValueList;
    },
      err => {
        console.log('Error ', err);
      });
  }
  ShowHideListFilterPanel(SelectedView: string) {
    switch (SelectedView) {
      case 'Show':
        $('#listViewFilterSection').show();
        $("#filterContinuanceListView").addClass("active");
        $("#showAllContinuanceListView").removeClass("active");

        break;
      case 'Hide':
        $('#listViewFilterSection').hide();
        $("#filterContinuanceListView").removeClass("active");
        $("#showAllContinuanceListView").removeClass("active");

        break;
      case 'HideWithCancel':
        $('#listViewFilterSection').hide();
        this.ClearListViewFilterFields();
        $("#filterContinuanceListView").removeClass("active");
        $("#showAllContinuanceListView").removeClass("active");

        break;
      case 'Reset':
        this.ResetListViewFilterFields();
        if (!(this.continuanceListViewFilterModel.clientId > 0 || this.continuanceListViewFilterModel.engagementPartner != "" ||
          this.continuanceListViewFilterModel.solutionCodeId > 0)) {
          $("#filterContinuanceListView").removeClass("partialActive");
        }
        this.InsertUpdateUserPreferenceData();
        this.getAllReacceptanceEngagementsByUserCount();
        break;
      default:
        $('#listViewFilterSection').hide();
        $("#filterContinuanceListView").removeClass("active");
        $("#showAllContinuanceListView").removeClass("active");
        break;
    }
  }
  ClearListViewFilterFields() {
    this.continuanceListViewFilterModel = new ContinuanceListViewFilterModel(0, '', 0, this.userName);
    $('#txtValueContinuanceListMappedValue0').val("");
    $('#ddListEngagementPartner').val("");
    $('#ddListPrimarySolutionCode').val(0);
    $('#hdnKeyContinuanceListMappedValue0').val('');
    $('#listViewFilterSection').hide();
    this.getAllReacceptanceEngagementsByUserCount();
  }
  ResetListViewFilterFields() {
    this.continuanceListViewFilterModel = new ContinuanceListViewFilterModel(0, '', 0, this.userName);
    this.listViewCurrentPageNumber = 1;
    $('#txtValueContinuanceListMappedValue0').val("");
    $('#ddListEngagementPartner').val("");
    $('#ddListPrimarySolutionCode').val(0);
    $('#hdnKeyContinuanceListMappedValue0').val('');
  }
  GetAllContinuanceListByFilters() {
    this.listViewCurrentPageNumber = 1;
    this.InsertUpdateUserPreferenceData();
    this.getAllReacceptanceEngagementsByUserCount();
    $('#listViewFilterSection').hide();
    $("#filterContinuanceListView").removeClass("active");
    if (this.continuanceListViewFilterModel.clientId > 0 || this.continuanceListViewFilterModel.engagementPartner != "" ||
      this.continuanceListViewFilterModel.solutionCodeId > 0) {
      $("#filterContinuanceListView").addClass("partialActive");
    }
    if (!(this.continuanceListViewFilterModel.clientId > 0 || this.continuanceListViewFilterModel.engagementPartner != "" ||
      this.continuanceListViewFilterModel.solutionCodeId > 0)) {
      $("#filterContinuanceListView").removeClass("partialActive");
    }
  }
  ShowAllContinuanceWithNoFilters() {
    this.continuanceListViewFilterModel = new ContinuanceListViewFilterModel(0, '', 0, this.userName);
    this.listViewCurrentPageNumber = 1;
    this.ClearListViewFilterFields();
    this.InsertUpdateUserPreferenceData();
    this.getAllReacceptanceEngagementsByUserCount();
    $("#filterContinuanceListView").removeClass("active");
    if (!(this.continuanceListViewFilterModel.clientId > 0 || this.continuanceListViewFilterModel.engagementPartner != "" ||
      this.continuanceListViewFilterModel.solutionCodeId > 0)) {
      $("#filterContinuanceListView").removeClass("partialActive");
    }
    $("#showAllContinuanceListView").addClass("active");
  }
  GetAzureADGroupsByRoleShortName(epRoleName: string) {
    //if (this.epRoleName == "SA") {
    this.adUsersService.GetAzureADGroupsByRoleShortName(epRoleName).subscribe(
      result => {
        this.azureADUsersArrayList = result;
      }
    );
    //}
  }
  InsertUpdateUserPreferenceData() {
    var listViewFilterList: any;
    var listViewSortList: any;
    if (this.userPreferenceData.length > 0 && this.continuanceUserPreference != null) {
      this.continuanceUserPreference.userEmail = this.userObject.loggedInUserEmail;
      this.continuanceUserPreference.dashBoardName = 'Continuance';
      this.continuanceUserPreference.dashboardViewType = 'List';
      listViewFilterList = this.continuanceListViewFilterModel;
      listViewSortList = this.continuanceListViewFilterModel;
      listViewSortList.orderByColumn = this.orderByColumn;
      listViewSortList.orderByType = this.orderByType;
      this.continuanceUserPreference.listViewFilterList = JSON.stringify(listViewFilterList);
      this.continuanceUserPreference.listViewSortingList = JSON.stringify(listViewSortList);
      this.continuanceUserPreference.listViewPageNumber = this.listViewNoOfrecordsPerPage;
      this.userPrefData = this.continuanceUserPreference;
      let index = this.userPreferenceData.findIndex(obj => obj.dashBoardName === "Continuance");
      this.userPreferenceData[index] = this.userPrefData;
      localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


      this.engService.InsertUpdateUserPreferenceData(this.userPrefData).subscribe(result => {
        console.log(result);
      });
    }
    if ((this.userPreferenceData.length == 0 || this.userPreferenceData.length > 0) && this.continuanceUserPreference == null) {
      var datatoinsert = new UserPreferenceData();
      listViewFilterList = this.continuanceListViewFilterModel;
      listViewSortList = this.continuanceListViewFilterModel;
      listViewSortList.orderByColumn = this.orderByColumn;
      listViewSortList.orderByType = this.orderByType;
      datatoinsert.userEmail = this.userObject.loggedInUserEmail;
      datatoinsert.dashBoardName = 'Continuance';
      datatoinsert.dashboardViewType = 'List';
      datatoinsert.cardViewPageNumber = this.listViewNoOfrecordsPerPage;
      datatoinsert.listViewPageNumber = this.listViewNoOfrecordsPerPage;
      datatoinsert.cardViewFilterList = JSON.stringify(listViewFilterList);
      datatoinsert.cardViewSortingList = JSON.stringify(listViewSortList);
      datatoinsert.listViewFilterList = JSON.stringify(listViewFilterList);
      datatoinsert.listViewSortingList = JSON.stringify(listViewSortList);
      this.userPreferenceData.push(datatoinsert);
      localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


      this.engService.InsertUpdateUserPreferenceData(datatoinsert).subscribe(result => {
        console.log(result);
      });
    }

  }
  ngOnDestroy() {
    this.globalClientId = 0;
    this.modalConfirmationDialogRef?.unsubscribe();
  }
  initinateConfirmationPopUp() {
    var isDelete: boolean = false;
    this.modalConfirmationDialogRef = this.data.getModalDetails$.subscribe(

      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dailogTitle = dialogModal.dialogTitle;
          const dialogButtonName = dialogModal.dialogButtonName;
          if (dailogTitle == ContinuanceDialogTitles.RemoveContinuance &&
            dialogButtonName === 'ok') {
            if (this.globalClientId > 0 && this.removeFlag == true) {
              this.DeleteReacceptanceData();
            }
          }
          else {
            isDelete = true;
            var selfId = $('#ddListEditRemove' + this.globalClientId);
            selfId.val(selfId.find('option[selected]').val());
          }
          if (dailogTitle == ContinuanceDialogTitles.UpdatedSolutioncCode &&
            dialogButtonName === 'ok') {
            isDelete = false;
            this.UpdateReacceptanceClientData(this.globalClientId);
          }
          else {
            if (!isDelete) {
              $('#divEditRemove' + this.globalClientId).hide();
            }
            var selfId = $('#ddListEditRemove' + this.globalClientId);
            selfId.val(selfId.find('option[selected]').val());
          }

          //Check for initiate continuance engagement title
          if (dailogTitle === ContinuanceDialogTitles.InitiateContinuanceEngagement &&
            dialogButtonName === 'ok') {
            this.initiateContinuanceEngagement(this.reacceptanceIdOnInitiate);
          } else if (dailogTitle === ContinuanceDialogTitles.InitiateContinuanceEngagement &&
            dialogButtonName === 'cancel') {
            this.isDisabled = false;
            this.reacceptanceIdOnInitiate = 0;
            this.loadingScreenService.stopLoading();
          }
        }
      });
  }
  DeleteReacceptanceData() {
    this.removeFlag = false;
    this.reacceptanceService.deleteReacceptance(this.globalClientId)
      .subscribe(
        result => {
          if (result == "Success") {

            this.getAllReacceptanceEngagementsByUserCount();

          }
        },
        error => {
          this.globalClientId = 0;
          this.removeFlag = false;
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
  }
  getUpdatedValue(id: string) {
    let retval: any = '';

    this.objectArray.forEach(([key, Keyvalue]) => {
      if (key == id) {
        switch (key) {
          case 'selectedengagementType':
            retval = Keyvalue.value;
            break;
          case 'selectedsolutionCode':
            retval = Keyvalue.childSolutionCodeId;
            break;
          case 'isContingent':
            if (Keyvalue == null) {
              retval = Keyvalue;
            } else {
              retval = Boolean(Keyvalue);
            }
            break;
          default:
            retval = Keyvalue;
            break;
        }
      }
    });
    return retval;
  }
  checkMandatoryValidations(fieldValidationArray, modalValidateArray) {
    let retVal: boolean = true;
    this.objectArray = Object.entries(modalValidateArray);
    for (let i = 0; i < fieldValidationArray.length; i++) {

      fieldValidationArray[i].setvalues(fieldValidationArray[i].fieldId,
        this.getUpdatedValue(fieldValidationArray[i].fieldId), true);
    }


    for (let i = 0; i < fieldValidationArray.length; i++) {
      if (!fieldValidationArray[i].isvalid) {
        retVal = false;
        break;
      }
    }
    return retVal;
  }

  MandatoryFieldValidationForEngagement() {
    this.arrValidationForEngagement = [];
    //if (this.isSaveBtnClicked) {
    this.arrValidationForEngagement.push(new FieldValidator('engagementPartnerName', this.reacceptanceClientForInitiate.engagementPartnerName, false));
    this.arrValidationForEngagement.push(new FieldValidator('solutionCode', this.reacceptanceClientForInitiate.solutionCode, false));
    //}
  }
  SetEngagementValues(clientId: number) {
    this.reacceptanceClientForInitiate.engagementPartner = $('#UserHiddenProps_EP_' + clientId + ' #hdnUserEmail').val();
    this.reacceptanceClientForInitiate.engagementPartnerName = $('#UserHiddenProps_EP_' + clientId + ' #hdnUserName').val();
    this.isvalidEngEPPMDName = ($('#hdnValidInput_EP_' + clientId).val()) === 'true' ? true : false;
  }
  SaveContinuanceData(clientId: number) {
    var existingValue = $('input#hdn_SolutionCodeId_' + clientId).val();
    var newValue = $('#hdn_NewSolutionCodeId_' + clientId).val();
    if (existingValue != newValue && newValue != null && newValue != '') {
      this.solutionCodeUpdateButtonClick(ContinuanceDialogTitles.UpdatedSolutioncCode, false);
    }
    else {
      this.UpdateReacceptanceClientData(clientId);
    }
  }
  UpdateReacceptanceClientData(clientId: number) {
    this.MandatoryFieldValidationForEngagement();
    this.SetEngagementValues(clientId);
    this.reacceptanceClientForInitiate.reacceptanceEngagementId = clientId;
    var epName = $('input#hdn_EngagementPartnerName_' + clientId).val();
    var epEMail = $('input#hdn_EngagementPartnerEMail_' + clientId).val();
    var scId = $('input#hdn_SolutionCodeScId_' + clientId).val();
    if ($('#UserHiddenProps_EP_' + clientId + ' #hdnUserName').val() == '') {
      $('#UserHiddenProps_EP_' + clientId + ' #hdnUserName').val(epName);
      this.reacceptanceClientForInitiate.engagementPartnerName = epName;
    }
    else {
      this.reacceptanceClientForInitiate.engagementPartnerName
        = $('#UserHiddenProps_EP_' + clientId + ' #hdnUserName').val()
    }
    if ($('#UserHiddenProps_EP_' + clientId + ' #hdnUserEmail').val() == '') {
      $('#UserHiddenProps_EP_' + clientId + ' #hdnUserEmail').val(epEMail);
      this.reacceptanceClientForInitiate.engagementPartner = epEMail;
    }
    else {
      this.reacceptanceClientForInitiate.engagementPartner
        = $('#UserHiddenProps_EP_' + clientId + ' #hdnUserEmail').val();
    }
    if ($('#hdnKey' + this.SolutionCodeMappedValue + clientId).val() == '') {
      this.reacceptanceClientForInitiate.solutionCodeId = scId;
    }
    else {
      const selectedSolutionCodehdnKeyValue = $('#hdnKey' + this.SolutionCodeMappedValue + clientId).val().split('-!');
      this.reacceptanceClientForInitiate.solutionCodeId
        = parseInt(selectedSolutionCodehdnKeyValue[0]);
    }
    this.reacceptanceClientForInitiate.solutionCode = $('#txtValueInitiateEngagementSolutionCode' + clientId).val();
    if (this.checkMandatoryValidations(this.arrValidationForEngagement, this.reacceptanceClientForInitiate) && this.isvalidEngEPPMDName) {
      this.loadingScreenService.startLoading();
      this.reacceptanceService.UpdateReacceptanceClientData(this.reacceptanceClientForInitiate)
        .subscribe(
          result => {
            if (result == "Success") {
              this.listViewCurrentPageNumber = 1;
              this.getAllReacceptanceEngagementsByUserCount();
            }

          },
          error => {
            this.loadingScreenService.stopLoading();
            this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
          });
    }
  }

  toggleDisplay(clientId: string) {
    var splitStr = clientId.split("_");
    if (this.intFlag == 0) {
      this.intFlag = Number(splitStr[1]);
    }
    else {
      if (this.intFlag != Number(splitStr[1])) {
        this.globalClientId = 0;
        $('#imgArrow' + this.intFlag).show();
        var self = $('#ddListEditRemove' + splitStr[1]);
        self.val(self.find('option[selected]').val());
        $('.cssEditRemove').hide();
        $('.cssSaveCancel').hide();
        $('#engagementPartnerNameLevel' + this.intFlag).show();
        $('#solutionCodeLevel' + this.intFlag).show();
        $('#engagementPartnerNamePeoplePicker' + this.intFlag).hide();
        $('#solutionCodePeoplePicker' + this.intFlag).hide();
        this.intFlag = Number(splitStr[1]);
      }
    }
    if (splitStr[0] == "dot") {
      $('#imgArrow' + splitStr[1]).hide();
      $('#divEditRemove' + splitStr[1]).show();
    }
    else if (splitStr[0] == "saveCancel") {
      this.globalClientId = 0;
      var selfId = $('#ddListEditRemove' + splitStr[1]);
      selfId.val(selfId.find('option[selected]').val());
      $('#divSaveCancel' + splitStr[1]).hide();
      $('#engagementPartnerNameLevel' + splitStr[1]).show();
      $('#solutionCodeLevel' + splitStr[1]).show();
      $('#engagementPartnerNamePeoplePicker' + splitStr[1]).hide();
      $('#solutionCodePeoplePicker' + splitStr[1]).hide();
      $('#imgArrow' + splitStr[1]).show();
      $('#hdn_NewSolutionCodeId_' + splitStr[1]).val('');
    }
  }

  onChangeSolutionCode(clientId: number) {
    var existingValue = $('input#hdn_SolutionCodeId_' + clientId).val();
    var newValue = $('#txtValueInitiateEngagementSolutionCode' + clientId).val();
    if (existingValue != newValue && newValue != null && newValue != '') {
      $('input#hdn_NewSolutionCodeId_' + clientId).val(newValue);
    }
    else {
      $('input#hdn_NewSolutionCodeId_' + clientId).val(existingValue);
    }
    $('#txtValueInitiateEngagementSolutionCode' + clientId).removeClass('text-danger');
    this.AddSecondarySolutionCode();
  }
  onChangeEditRemove(value: string, clientId: number) {
    if (value == "edit") {
      this.globalClientId = clientId;
      this.selectedReacceptanceEngagementId = clientId;
      setTimeout(() => {
        var epName = $('input#hdn_EngagementPartnerName_' + clientId).val();
        var epEMail = $('input#hdn_EngagementPartnerEMail_' + clientId).val();
        $('#UserHiddenProps_EP_' + clientId + ' #hdnUserName').val(epName);
        $('#UserHiddenProps_EP_' + clientId + ' #hdnUserEmail').val(epEMail);
        this.engagement.engagementPartner = epEMail;
        this.engagement.engagementPartnerName = epName;
        this.validateEP = true;

        if (this.isMemberFirm) {
          $('#txtPeoplePicker_EP_' + clientId).attr('disabled', true);
        }

        var isUserExists = $('input#hdn_IsUserExists_' + clientId).val();
        var isSolutionCodeStatus = $('input#hdn_IsSolutionCodeStatus_' + clientId).val();
        var isReAcceptanceEligibility = $('input#hdn_isReAcceptanceEligibility_' + clientId).val();
        var scName = $('input#hdn_SolutionCode_' + clientId).val();
        var scDescription = $('input#hdn_SolutionCodeDesc_' + clientId).val();
        var selfEPName = $('#txtPeoplePicker_EP_' + clientId);
        var selfSCName = $('#txtValueInitiateEngagementSolutionCode' + clientId);
        selfEPName.val(epName);
        selfSCName.val(scName + ' - ' + scDescription);
        if (isUserExists == 'false') {
          selfEPName.attr('class', 'form-control inputPicker input-height rounded-0 ng-pristine ng-valid text-danger ng-touched');
        }
        else {
          selfEPName.attr('class', 'form-control inputPicker input-height rounded-0 ng-pristine ng-valid ng-touched');
        }
        if (isSolutionCodeStatus == 'I' && isReAcceptanceEligibility == 'false') {
          selfSCName.attr('class', 'form-control inputPicker input-height rounded-0 ng-pristine ng-valid text-danger ng-touched');
        }
        else if (isSolutionCodeStatus == 'I' && isReAcceptanceEligibility == 'true') {
          selfSCName.attr('class', 'form-control inputPicker input-height rounded-0 ng-pristine ng-valid text-danger ng-touched');
        }
        else if (isSolutionCodeStatus == 'A' && isReAcceptanceEligibility == 'false') {
          selfSCName.attr('class', 'form-control inputPicker input-height rounded-0 ng-pristine ng-valid text-danger ng-touched');
        }
        else if (isSolutionCodeStatus == 'A' && isReAcceptanceEligibility == 'true') {
          selfSCName.attr('class', 'form-control inputPicker input-height rounded-0 ng-pristine ng-valid ng-touched');
        }
        $('.cssEditRemove').hide();
        $('#engagementPartnerNameLevel' + clientId).hide();
        $('#solutionCodeLevel' + clientId).hide();
        $('#divSaveCancel' + clientId).show();
        $('#engagementPartnerNamePeoplePicker' + clientId).show();
        $('#solutionCodePeoplePicker' + clientId).show();
      });
    } else if (value == "remove") {
      this.removeFlag = true;
      this.globalClientId = clientId;
      $('#engagementPartnerNameLevel' + clientId).show();
      $('#solutionCodeLevel' + clientId).show();
      $('#engagementPartnerNamePeoplePicker' + clientId).hide();
      $('#solutionCodePeoplePicker' + clientId).hide();
      this.workspaceActionButtonClick(ContinuanceDialogTitles.RemoveContinuance, false);
    } else if (value == "cancel") {
      this.globalClientId = 0;
      var selfId = $('#ddListEditRemove' + clientId);
      selfId.val(selfId.find('option[selected]').val());
      $('#divEditRemove' + clientId).hide();
      $('#engagementPartnerNameLevel' + clientId).show();
      $('#solutionCodeLevel' + clientId).show();
      $('#engagementPartnerNamePeoplePicker' + clientId).hide();
      $('#solutionCodePeoplePicker' + clientId).hide();
      $('#imgArrow' + clientId).show();
    }
  }
  workspaceActionButtonClick(action: string, isDeclineModal: boolean) {
    this.loadingScreenService.startLoading();
    this.loadingScreenService.stopLoading();
    let dialogDesc: string = '';
    let dialogInput = '';
    const buttonCount = 2;
    const dialogType = 'information';
    dialogDesc = 'Are you sure to remove this engagement from your continuance dashboard?';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, buttonCount, dialogInput, isDeclineModal);
  }
  solutionCodeUpdateButtonClick(action: string, isDeclineModal: boolean) {
    this.loadingScreenService.startLoading();
    this.loadingScreenService.stopLoading();
    let dialogDesc: string = '';
    let dialogInput = '';
    const buttonCount = 2;
    const dialogType = 'information';
    dialogDesc = 'Are you sure to update the Solution Code? Assignment information will not be pre-populated with prior year solution code data.';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, buttonCount, dialogInput, isDeclineModal);
  }
  setInitiateConfirmationMessage(selectedReacceptanceEngagementId: number, cmsClientCode: string) {
    this.isDisabled = false;
    this.reacceptanceIdOnInitiate = selectedReacceptanceEngagementId;

    let dialogDesc: string = '';
    let dialogInput = '';
    let dialogTitle = '';
    let buttonCount = 2;

    this.clientService.GetClientDetailsByClientCode(cmsClientCode).subscribe(client => {
      if (client.clientStatus == 'Closed') {
        const dialogType = 'warning';
        dialogTitle = ContinuanceDialogTitles.ClosedClientContinuance;
        dialogDesc = 'Client is closed. Submit a Centralized Billing Share request to re-open the client code.';
        buttonCount=1;
        this.displayConfirmationPopUp(dialogType, dialogTitle, dialogDesc, buttonCount, dialogInput, true);
      } else {

        const dialogType = 'information';
        dialogTitle = ContinuanceDialogTitles.InitiateContinuanceEngagement;
        dialogDesc = 'Are you sure you want to continue? Once created, this record will be removed from your Continuance dashboard.';
        this.displayConfirmationPopUp(dialogType, dialogTitle, dialogDesc, buttonCount, dialogInput, true);
      }
    })

  }

  displayConfirmationPopUp(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number, _dialogInput: string, bigTitle: boolean) {
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '',
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogDeclineModal: false,
      dialogBigTitle: bigTitle
    };
    const modalJSON = JSON.stringify(modalObj);
    this.data.displayDialogNotification(modalJSON);
  }

  searchUsers(searchTextBox, tableId: string) {
    console.log('searchUsers func called', searchTextBox, tableId);
    const searchText = $('#' + searchTextBox).val();
    if (tableId === "tblEPAD") {
      this.getEPList(searchText);
    }
  }


  getEPList(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForEP = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForEP = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  setEP() {
    console.log('setEP func called ', this.selectedEPIndex, Number(this.selectedEPIndex) >= 0, this.EpCtrl, this.CMSEPCtrl);
    let index = this.selectedEPIndex;
    if (Number(index) >= 0) {
      const selectedEPName = $('#spEPdisplayName' + index).text();
      const selectedEPEmail = $('#spEPmail' + index).text();
      const selectedEPPhone = $('#spEPbusinessPhones' + index).text();
      if (this.EpCtrl !== '') {
        $('#' + this.EpCtrl).val(selectedEPName);
        $('#UserHiddenProps_EP_' + this.globalClientId + ' #hdnUserName').val(selectedEPName);
        $('#UserHiddenProps_EP_' + this.globalClientId + ' #hdnUserEmail').val(selectedEPEmail);
        //$('#hdnValidInput_EP' + '_' + this.peoplepickerIndex).val(true);

      }
      $('#' + this.CMSEPCtrl).val(selectedEPName);
      $('#UserHiddenProps_EP_' + this.globalClientId + ' #hdnUserName').val(selectedEPName);
      $('#UserHiddenProps_EP_' + this.globalClientId + ' #hdnUserEmail').val(selectedEPEmail);
      $('#hdnValidInput_AS_EP' + '_' + this.peoplepickerIndex).val(true);
      $("#EPErrorMessage").text('');
      if (this.EpCtrl === '' && this.CMSEPCtrl !== '') {
        this.validateCMSEP = true;
      }
      if (this.engagement.engagementPartner !== selectedEPEmail && this.EpCtrl !== '') {
        $("#EPErrorMessage").text('');
        this.engagement.engagementPartner = selectedEPEmail.trim();
        this.engagement.engagementPartnerName = selectedEPName.trim();
        this.engagement.epContactno = selectedEPPhone.trim();
        this.validateEP = true;
      }
      else {
        $("#EPErrorMessage").text('Selected EP is already added');
      }
    }
    this.membersListArrayForEP = null;
  }

  selectEP(index) {
    $('#tblEPAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedEPIndex = index;
    console.log('selectEP func called ', this.selectedEPIndex);
  }

  deselectEP() {
    // $('#' + this.EpCtrl).val('');
    // $('#' + this.EpCtrl).innerText = '';
    $('#' + this.CMSEPCtrl).val('');
    $('#' + this.CMSEPCtrl).innerText = '';
    this.membersListArrayForEP = null;
  }

  refreshEPPopup(textboxName: string) {
    var splitStr = textboxName.split("_");
    this.removeRowSelection('tblEPAD', 'searchEPName', 'EPErrorMessage');
    let lastIndex = this.peoplepickerIndex;
    this.EpCtrl = splitStr[0] + '_' + this.epRoleName + '_' + this.globalClientId;
    $('txtPeoplePicker_' + this.epRoleName + '_' + this.globalClientId).removeClass('text-danger');
    //this.CMSEPCtrl = textboxName + 'AS_' + this.epRoleName + '_' + this.peoplepickerIndex;
    this.currentEPIndex = lastIndex;
    this.selectedEPIndex = null;
    this.validateEP = false;
    this.membersListArrayForEP = null;
    if ($('#' + this.EpCtrl).hasClass('text-danger')) {
      $('#' + this.EpCtrl).removeClass('text-danger');
    }
  }
  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $('#' + errorMessageId).text('');
    const searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }
  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }
  AddSecondarySolutionCode() {
    $('#EngNamediv').removeClass('hide');
  }

  getBoolean(value) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  getAllReacceptanceEngagementsByUserCount() {
    this.loadingScreenService.startLoading();
    this.reacceptanceService.getAllReacceptanceEngagementsByUserCount(this.isloggedInUserAdmin,
      this.continuanceListViewFilterModel).subscribe(
        result => {
          this.globalClientId = 0;
          this.reacceptanceEngagementCount = result;
          if (this.reacceptanceEngagementCount > 0) {
            // initialize to page 1
            this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage);
          }
          else {
            this.loadingScreenService.stopLoading();
            this.reacceptanceEngagementCount = 0;
            this.reacceptanceClients = null;
            if (this.reacceptanceEngagementCount === 0) {
              this.listDataMessage = ' No Data Available';
            }
          }
        },
        error => {
          this.loadingScreenService.stopLoading();
        }
      );
  }
  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewNoOfrecordsPerPage = recordsPerPage;
    } else {
      this.listViewNoOfrecordsPerPage = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();
    if (this.listViewCurrentPageNumber != 0) {
      this.getReacceptanceEngagementListView('List', this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage,
        this.orderByColumn, this.orderByType);

      this.pager = this.pagerService.getPager(this.reacceptanceEngagementCount, this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage);
      console.log('pager ==>', this.pager);
    } else {
      $('#previous').off('click');
      $('#first').off('click');
      this.loadingScreenService.stopLoading();
    }
  }

  ShowHideIcon(solutionCodeStatus: string, isEngagementPartner: boolean, isUserExist: boolean,
    isReAcceptanceEligibility: boolean, flag: number) {
    if ((flag > 0 || isEngagementPartner == true)
      && solutionCodeStatus == 'A'
      && isUserExist == true
      && isReAcceptanceEligibility == true) {
      return true;
    }
    else {
      return false;
    }
  }

  ShowHideDots() {
    if (this.isloggedInUserAdmin === 'yes')
      return true;
    else
      return false;
  }

  getReacceptanceEngagementListView(SelectedView: string, pageNumber: number, listViewNoOfrecordsPerPage: number, orderBy: string, orderType: string) {
    // this.loadingScreenService.startLoading();
    this.reacceptanceService.getReacceptanceEngagementListView(this.continuanceListViewFilterModel,
      pageNumber, listViewNoOfrecordsPerPage,
      this.isloggedInUserAdmin, orderBy,
      orderType).subscribe(
        result => {
          this.reacceptanceClients = result;
          for (let rowItems of this.reacceptanceClients) {
            if (rowItems.engagementPartner.trim().toLowerCase() === this.userName.trim().toLowerCase() || rowItems.flag > 0) {
              rowItems.isEngagementPartner = true;
            }
            else {
              rowItems.isEngagementPartner = false;
            }
          }
          //if (this.isloggedInUserAdmin == 'yes') {
          for (let obj of this.reacceptanceClients) {
            obj.isUserExist = true;
          }

          this.loadingScreenService.stopLoading();

          // for (let obj of this.reacceptanceClients) {
          //   let searchGroupUserViewModel = new SearchGroupUserViewModel();
          //   let searchUsers: MembersList[] = [];
          //   let addTeamMember = new MembersList();
          //   addTeamMember.displayName = obj.engagementPartnerName;
          //   addTeamMember.mail = obj.engagementPartner;
          //   searchUsers[0] = addTeamMember;
          //   searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayList;
          //   searchGroupUserViewModel.user = searchUsers[0];
          //   if(this.arrReacceptanceListArray.length == 0){
          //     const instance = new ReacceptanceListModel();
          //     instance.engagementPartner = obj.engagementPartner;
          //     this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(
          //       isUserValid => {
          //         if (isUserValid) {
          //           instance.isUserExist = true;
          //           obj.isUserExist = true;
          //         }
          //         else {
          //           instance.isUserExist = false;
          //           obj.isUserExist = false;
          //         }
          //         this.arrReacceptanceListArray.push(instance);
          //         console.log(obj);
          //       },
          //       error => {
          //         console.log('Error in ValidateUserWithADGroup ', error);
          //         this.loadingScreenService.stopLoading();
          //       }
          //     );
          //   }
          //   else{
          //     if (!this.arrReacceptanceListArray.find(x => x.engagementPartner === obj.engagementPartner)) {
          //       const model = new ReacceptanceListModel();
          //       model.engagementPartner = obj.engagementPartner;
          //       this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe(
          //         isUserValid => {
          //           if (isUserValid) {
          //             model.isUserExist = true;
          //             obj.isUserExist = true;
          //           }
          //           else {
          //             model.isUserExist = false;
          //             obj.isUserExist = false;
          //           }
          //           this.arrReacceptanceListArray.push(model);
          //           console.log(obj);
          //         },
          //         error => {
          //           console.log('Error in ValidateUserWithADGroup ', error);
          //           this.loadingScreenService.stopLoading();
          //         }
          //       );
          //     }
          //     else{
          //       for(let i = 0; i < this.arrReacceptanceListArray.length; i++)
          //       {
          //         if(obj.engagementPartner == this.arrReacceptanceListArray[i].engagementPartner)
          //         {
          //           obj.isUserExist = this.arrReacceptanceListArray[i].isUserExist;
          //           break;
          //         }
          //       }
          //     }
          //       searchGroupUserViewModel.azureADUsers = null;
          //       searchGroupUserViewModel = null;
          //   }
          // }
          //}
          if (this.listCount === 0) {
            this.listDataMessage = ' No Data Available';
          } else if (this.listCount === null) {
            this.DataMessage = 'Loading...';
          } else { this.listDataMessage = ''; }
        },
        error => {
          console.log('Error in ValidateUserWithADGroup ', error);
          this.loadingScreenService.stopLoading();
        });
  }
  setDefaultInitialization() {
    this.applyFilterOnceAfterDataLoad = false;
    this.isloggedInUserAdmin = 'no';
    this.orderByColumn = 'CreatedOn';
    this.orderByType = 'DESC';
    if (this.userPreferenceData.length > 0 && this.continuanceUserPreference != null) {
      this.orderByColumn = JSON.parse(this.continuanceUserPreference.listViewSortingList).orderByColumn;
      if (this.orderByColumn == undefined || this.orderByColumn == null) {
        this.orderByColumn = 'CreatedOn';
      }
      this.orderByType = JSON.parse(this.continuanceUserPreference.listViewSortingList).orderByType;
      if (this.orderByType == undefined || this.orderByType == null) {
        this.orderByType = 'DESC';
      }
    }
  }
  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isloggedInUserAdmin = 'yes';
          this.roleShortName = 'SM';
          break;
        }
        else {
          this.isloggedInUserAdmin = 'no';
          this.roleShortName = 'SM';
        }
      }
    }
  }
  getReacceptanceClients() {
    return this.reacceptanceService
      .getMyReacceptanceClients()
      .subscribe(
        result => {
          this.reacceptanceClients = result;
          this.applyFilterOnceAfterDataLoad = true;
        },
        error => {
          this.reacceptanceClients = null;
        }
      );
  }
  HeaderSort(headerid: number) {
    for (let i = 0; i < this.headerModelList.length; i++) {
      if (this.headerModelList[i].headerId === headerid) {
        this.headerModelList[i].headerClick = true;
        if ((this.headerModelList[i].imageModelList[0].isVisible) || (this.headerModelList[i].imageModelList[2].isVisible)) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = true;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        } else if (this.headerModelList[i].imageModelList[1].isVisible) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = true;
        }
      } else {
        this.headerModelList[i].headerClick = false;
        for (let j = 0; j < this.headerModelList[i].imageModelList.length; j++) {
          this.headerModelList[i].imageModelList[0].isVisible = true;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        }
      }
    }

    let headerObj = this.headerModelList.find(c => c.headerClick === true);
    this.orderByColumn = headerObj.headerValue;
    for (let k = 0; k < headerObj.imageModelList.length; k++) {
      if (headerObj.imageModelList[k].isVisible === true) {
        this.orderByType = headerObj.imageModelList[k].imageValue;
        break;
      }
    }
    this.InsertUpdateUserPreferenceData();
    this.listViewSetPage(1, this.listViewNoOfrecordsPerPage);
  }
  LoadListViewHeaders() {
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.headerModel = {
      headerId: 1,
      headerName: 'Client name',
      headerValue: 'ClientName',
      headerClick: true,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'ClientName') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = true;
        this.headerModelList[0].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = false;
        this.headerModelList[0].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 2,
      headerName: 'Prior engagement name',
      headerValue: 'EngagementName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'EngagementName') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = true;
        this.headerModelList[1].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = false;
        this.headerModelList[1].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 3,
      headerName: 'Client code',
      headerValue: 'CMSClientCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'CMSClientCode') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = true;
        this.headerModelList[2].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = false;
        this.headerModelList[2].imageModelList[2].isVisible = true;
      }
    }
    this.headerModel = {
      headerId: 4,
      headerName: 'Active date',
      headerValue: 'CreatedOn',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'CreatedOn') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = true;
        this.headerModelList[3].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = false;
        this.headerModelList[3].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 5,
      headerName: 'Prior end',
      headerValue: 'PeriodEndDate',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'PeriodEndDate') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = true;
        this.headerModelList[4].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = false;
        this.headerModelList[4].imageModelList[2].isVisible = true;
      }
    }
    this.headerModel = {
      headerId: 6,
      headerName: 'Engagement PPMD',
      headerValue: 'EngagementPartnerName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'EngagementPartnerName') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = true;
        this.headerModelList[5].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = false;
        this.headerModelList[5].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 7,
      headerName: 'Primary solution code',
      headerValue: 'SolutionCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'EngagementPartnerName') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = true;
        this.headerModelList[6].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = false;
        this.headerModelList[6].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 8,
      headerName: 'Actions',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
  }

  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(10);
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(40);
    this.noOfrecordsPerPageList.push(60);
    this.noOfrecordsPerPageList.push(80);
    this.noOfrecordsPerPageList.push(100);
  }

  SetDefaultCardView() {
    $('.cardViewContainer').show();
    $('.Eng-ListView-Div').hide();
    $('.Eng-CardView-Div').show();
  }

  initiateContinuanceEngagement(reacceptanceId: number) {
    this.isDisabled = true;
    this.reacceptanceIdOnInitiate = 0;
    this.loadingScreenService.startLoading();
    if (reacceptanceId > 0) {
      this.selectedReacceptanceClient = this.reacceptanceClients.find(element => element.reacceptanceEngagementId == reacceptanceId);
      this.setEngagementBasicData();
      this.reacceptanceService.initiatereacceptanceEngagement(this.engagement).subscribe(
        result => {
          this._alertService.remove(this.alert);
          if (result.length > 0 && result !== null) {
            this.currentEngagementId = result;
            if (this.engagement.parentSolutionCodeId !== undefined && this.engagement.parentSolutionCodeId !== null) {
              this.engService.getCarryForwardIndusFlagBySolutionCode(this.engagement.parentSolutionCodeId).subscribe(IndusFlag => {
                if (!IndusFlag) {
                  this.RemoveINDUSconsent(IndusFlag)
                }
                else {
                  this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
                }

              });
            }


          }
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
          this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
        }
      );
    }

  }

  RemoveINDUSconsent(indusFlag: boolean) {
    this.updateINDUSFlag.engagementId = this.currentEngagementId;
    this.updateINDUSFlag.isIndus = indusFlag;
    this.updateINDUSFlag.lastModifiedBy = this.userObject.loggedInUserEmail;
    this.engService.updateIndusFlagForReviewPage(this.updateINDUSFlag).subscribe(response => {
      this.engService.getIndusFlagForReviewPage(this.currentEngagementId).subscribe(result => {
        if (!result) {
          this.ProcessINDUSAllTeamMembersRemoval();
        }
        else {
          this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
        }
      }, error => {
        console.error("unable to fetch data from this method GetIndusFlagForReviewPage()." + error);
        this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
      });

    },
      error => {
        console.error("unable to update INDUS flag." + error);
        this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
      });

  }



  ProcessINDUSAllTeamMembersRemoval() {
    this.loadingScreenService.startLoading();
    let azureADRoleShortNames: AzureADGroupRoleShortNamesModel = new AzureADGroupRoleShortNamesModel();
    azureADRoleShortNames.baseADGroupRoleShortName = RoleNames.INDUSConsent;
    azureADRoleShortNames.fromADGroupRoleShortName = RoleNames.GTUSINDUSALL;
    //let ProcessINDUSAllTeamMembersRemovalLoaderToken = setInterval(() => this.loadingScreenService.startLoading(), 1);
    // this.data.loadMultiple([this.adUsersService.GetADGroupsDeltaByRoleShortName(azureADRoleShortNames)]).subscribe(
      this.adUsersService.GetINDUSADGroups(RoleNames.INDUSConsent).subscribe(
      adGroupList => {
        //this.indusADGroupsList = adGroupList;
        let indusTeamMemberDataOnFinalApproval: IndusADUsersViewModel = new IndusADUsersViewModel()
        this._workspaceService.getWorkspaceByEngagementId(this.currentEngagementId).subscribe(
          workspace => {
            this._workspaceService.getTeamMembers(workspace.workspaceId).subscribe(
              teamMemberResult => {
                this.teamsMembersList = teamMemberResult;
                for (let i = 0; this.teamsMembersList.teamMembers?.length > i; i++) {
                  indusTeamMemberDataOnFinalApproval = {
                    azureADUsers: adGroupList,
                    workspaceMemberId: this.teamsMembersList.teamMembers[i].id,
                    memberEmail: this.teamsMembersList.teamMembers[i].memberEmailId,
                    memberName: this.teamsMembersList.teamMembers[i].memberName,
                    isIndusMember: false
                  };
                  this.indusMembersListArrayOnFinalApproval.push(indusTeamMemberDataOnFinalApproval);
                }
                let memerList: IndusADUsersListViewModel = new IndusADUsersListViewModel();
                if (this.indusMembersListArrayOnFinalApproval != null && this.indusMembersListArrayOnFinalApproval.length > 0) {
                  memerList.indusADUsersList = this.indusMembersListArrayOnFinalApproval;
                  this.adUsersService.getValidIndusTeamMemberList(memerList)
                    .subscribe(
                      userList => {
                        //clearInterval(ProcessINDUSAllTeamMembersRemovalLoaderToken);
                        if (userList.indusADUsersList != null && userList.indusADUsersList.length > 0) {
                          let validMembersList: TeamMembers[] = [];
                          userList.indusADUsersList.forEach(element => {
                            this.teamsMembersList.teamMembers.forEach(x => {
                              if (element.workspaceMemberId == x.id && element.isIndusMember) {
                                validMembersList.push(x);
                              }
                            });
                          });
                          let teamMembersList: TeamMemberEPModel = new TeamMemberEPModel();
                          teamMembersList.epMembers = [];
                          teamMembersList.teamMembers = validMembersList;
                          this._workspaceService.removeIndusMembers(teamMembersList).subscribe(
                            result => {
                              this.engService.GetEditEngagementCollabDetailsById(this.currentEngagementId).subscribe(collab => {
                                this.engagementCollabData = collab;
                                this.isCurrentEngagementIsIndus = this.engagementCollabData.isIndus;
                                this.carryForwadINDUSConsent = this.engagementCollabData.carryForwadINDUSConsent;
                                this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
                              });

                              console.log('successfully removed IndusMembers');
                            },
                            error => {
                              this.loadingScreenService.stopLoading();
                              console.log('Error in getting response from RemoveIndusTemMembers func ', error);
                              this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
                            });
                        }
                        else {
                          this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
                        }
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        console.log('Error in getting response from GetIndusTeamMembers func ', error);
                        this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
                      }
                    );
                }
                else {
                  this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
                }
              });
          });
      },
      error => {
        //clearInterval(ProcessINDUSAllTeamMembersRemovalLoaderToken);
        this.loadingScreenService.stopLoading();
        console.log('Error in reseting collaboration site indus flag  ', error);
        this.checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(this.isOpportunityServiceFeatureFlagEnabled);
      });


  }

  setEngagementBasicData() {
    this.engagement.clientName = this.selectedReacceptanceClient.clientName;
    this.engagement.clientId = this.selectedReacceptanceClient.clientId;
    this.engagement.engagementName = this.selectedReacceptanceClient.engagementName;
    this.engagement.engagementPartner = this.selectedReacceptanceClient.engagementPartner;
    this.engagement.engagementPartnerName = this.selectedReacceptanceClient.engagementPartnerName;
    this.engagement.parentSolutionCodeId = this.selectedReacceptanceClient.solutionCodeId;
    this.engagement.reacceptanceEngagementId = this.selectedReacceptanceClient.reacceptanceEngagementId;
    this.engagement.engagementId = this.selectedReacceptanceClient.engagementId
  }

  checkUpdateOpportunityDetailsOnNewlyCreatedEngagement(isOpportunityServiceFeatureFlagEnabled: boolean) {
    if (isOpportunityServiceFeatureFlagEnabled) {
      this.UpdateOpportunityDetailsOnNewlyCreatedEngagement(this.currentEngagementId);
    }

    else {
      this.redirectToEditEngagement(this.currentEngagementId);
    }
  }

  alertServiceFun(message, type) {
    this._alertService.remove(this.alert);
    this._alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  UpdateOpportunityDetailsOnNewlyCreatedEngagement(newEngagementId) {
    this.clientService.GetNewOpportunityDetailsByPreviousYearEngagementId(this.selectedReacceptanceClient.engagementId).subscribe(result => {
      if (result.response.oneViewClientOpportunityViewModelList.length > 0) {
        let newYearOpportunityResponse = result.response.oneViewClientOpportunityViewModelList[0];

        let newYearOpportunityUpdateRequestModel = new NewYearOpportunityUpdateRequestModel();
        newYearOpportunityUpdateRequestModel.clientId = this.selectedReacceptanceClient.clientId;
        newYearOpportunityUpdateRequestModel.oldEngagementId = this.selectedReacceptanceClient.engagementId;
        newYearOpportunityUpdateRequestModel.newEngagementId = newEngagementId;
        newYearOpportunityUpdateRequestModel.opportunityId = newYearOpportunityResponse.opportunityId;
        newYearOpportunityUpdateRequestModel.expectedStartDate = newYearOpportunityResponse.expectedStartDate;
        newYearOpportunityUpdateRequestModel.estCloseDate = newYearOpportunityResponse.estCloseDate;
        newYearOpportunityUpdateRequestModel.gtOfficeLocation = newYearOpportunityResponse.gtOfficeLocation;

        newYearOpportunityResponse.opportunityServiceList.forEach(opportunityService => {
          let newYearOpportunityServiceModel = new NewYearOpportunityServiceUpdateModel();
          newYearOpportunityServiceModel.opportunityServiceId = opportunityService.opportunityServiceId;
          newYearOpportunityServiceModel.previousYearOpportunityServiceId = opportunityService.previousYearOpportunityServiceId;
          newYearOpportunityServiceModel.previousOppServiceEngageAssignmentIds = opportunityService.previousOppServiceEngageAssignmentIds;
          newYearOpportunityServiceModel.solutionCode = opportunityService.solutionCode;
          newYearOpportunityUpdateRequestModel.opportunityServiceList.push(opportunityService);
        });

        this.engService.UpdateNewYearOpportunityDetails(newYearOpportunityUpdateRequestModel).subscribe(result1 => {
          console.log('UpdateNewYearOpportunityDetails response => ', result1);
          this.redirectToEditEngagement(newEngagementId);
        }, (error) => {
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
      } else {
        console.log('GetNewOpportunityDetailsByPreviousYearEngagementId returned 0 record.');
        this.redirectToEditEngagement(newEngagementId);
      }
    },
      (error) => {
        console.log('Error while getting response UpdateOpportunityDetailsOnNewlyCreatedEngagement func ', error);
      });
  }

  redirectToEditEngagement(engagementId) {
    this._alertService.remove(this.alert);
    this.data.setIsRedirectedFromInitiateReacceptance('true');
    this.loadingScreenService.stopLoading();
    this.route.navigate(['EditEngagement/' + engagementId]);
  }

  setIsMemberFirm() {
    this.isMemberFirm = this.userObject.isMemberFirmFeatureEnabled;
  }
}

<div class="containerDiv">
  <nav aria-label="breadcrumb" class="pt-3">
    <ol class="breadcrumb">
      <li class="breadcrumb-item disabled">
        <label>
          Basic info
          <span>
            <img style="vertical-align: top;"
              src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Basic info" />
          </span>
        </label>
      </li>
      <li class="breadcrumb-item active">
        <label>
          Questionnaire
          <span>
            <img style="vertical-align: top;"
              src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Questionnaire" />
          </span>
        </label>
      </li>
      <li class="breadcrumb-item disabled">
        Review
      </li>
    </ol>
  </nav>

  <!-- Page Title row  START-->
  <div class="d-flex justify-content-between pageTitleParent pb-0 mw-100">
    <div class="text-center">
      <h2 class=" float-md-left pageTitle largerFontSize2">Questionnaire</h2>
    </div>
    <div class="text-center">
      <span class="float-md-left">
        <app-shortcut></app-shortcut>
      </span>

      <span class="float-right mt-1 ml-2 mr-2 font-weight-bold"
        *ngIf="workspaceOpenReviewPointsCount > 0">{{workspaceOpenReviewPointsCount}}</span>
    </div>
  </div>
    <div class="d-flex justify-content-between pt-0 pb-3">
    Template: {{InterviewList?.templateName}}
  </div>
  <!-- Engagement Details  START--> 
  <div id="engagementDetailHeaderDiv" class="d-flex justify-content-between pt-0 pb-0">
    <h6 class="form-heading-text">Engagement details</h6>
  </div>
  <div class="col-md-12 bg-white pl-0 pr-0 pb-3">
    <div class="row">
      <div class="col-xl-3 col-md-4 pt-3">
    <label><span class="label"></span>Parent Engagement :</label> 
    </div>
    <div class="col-xl-3 col-md-4 pt-3">
    <a class="eng-pointer-cursor" [routerLink]="['/EngagementDetail/',parentEngagementId]" target="_blank">{{engagementName}}</a>
  </div>
</div>
    <div class="col-md-12 bg-white pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-xl-3 col-md-4 pt-3">
          <label><span class="label"></span>Previously approved change orders:</label> 
       </div>
        <div class="col-xl-4 col-md-4 pr-0 pt-3">
          <div class="selectdiv">   
            <select id="drpselectChangeOrderId" name="selectChangeOrderId"  class="dropdown rounded-0"
              [(ngModel)]="selectedChangeOrderId" (change)="onChangeOrdersChange()">
              <option [ngValue]="-1" selected = true>Select ChangeOrder</option>
              <option *ngFor="let changeorderInfo of changeOrders" [ngValue]=changeorderInfo.changeOrderId>
                {{formatDate(changeorderInfo.approvedDate)}}{{' + '}} {{ changeorderInfo.changeOrderName }}
              </option>
            </select>
          </div>  
        </div>
        <div class="col-xl-3 col-md-4 pr-0 pt-3">
      <button  type="button" class="btn view-button w-50 text-center">
        <a class="eng-pointer-cursor" [routerLink]="['/ChangeOrderDetail/',selectedChangeOrderId]" target="_blank"><span  class=" align-middle"> view</span></a>
      </button>
      </div>
      </div>
    </div>

  <!-- Engagement Details  END-->
  <div class="row mx-0 my-3">
    <div #clmGoverningAgreementStatusDiv></div>
  </div>
  <!-- Page Title row END-->
  <div class="bg-edp-body p-1">
    <div id="hdPreviewDivChangeOrder" class="p-1"></div>
  </div>
   
  <!-- Page Footer row START-->
  <div class="d-flex pb-4 pt-4">
    <div class="mr-auto pl-0 pt-2 pr-2">
      <button  class="btn btn-width SecondaryButton w-100 text-center" type="button"
        (click)="getExecutionWorkspaceStatus('EditBtn')">
        <img src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left"
          style="width: 18px;height:18px;margin-right:2px;">
        <span class="d-inline-block align-middle">Edit Change Order</span>
      </button>
    </div>
    <div class="pl-3 pt-2 pr-0">
      <button class="btn btn-wide PrimaryButton w-100 text-center" type="button"
        (click)="getExecutionWorkspaceStatus('FinishBtn')">
        <span class="d-inline-block align-middle font-weight-bold">
          Save and go to review<img src="assets/images/EDP/Engagement/caret-right-white.svg" alt="caret-right"
            style="width: 18px;height:18px;margin-bottom: 3px;">
        </span>
      </button>
    </div>
    <div class="pl-3 pt-2 pr-0">
      <em class="pl-2 pt-2 pr-0 text-center" id='questionnaireLoadSpinner'></em>
    </div>

  </div>
  <!-- Page Footer row END-->
</div>



<!-- work in progress Modal Start-->
<div class="message-dialog-wrapper" id="message-InProgress" *ngIf="isHiddenProgressModal">
  <div class="message-dialog-container">
    <div class="d-flex justify-content-center mt-4">
      <div class="message-background-icon information">
        <img src="../../../../assets/images/EDP/MessageDialog/information.svg" class="message-icon" alt="information" />
      </div>
    </div>
    <div class="d-flex justify-content-center message-title information pt-2">Working on it...</div>
    <div class="message-description mt-5">
      <span>
        Engage is currently processing your template. This may take a few seconds depending on the complexity of the
        document. You will be redirected as soon as this process is complete.
      </span>
    </div>
  </div>
</div>
<!-- work in progress Modal END-->
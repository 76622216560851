export class CLMApiLog{
    id:number
    engagementId:number
    requestType:string
    requestPayload:string
    responseBody:string
    createdBy:string
    createdDate?:Date
    modifiedBy:string
    modifiedDate?:Date
    isActive:boolean
    errorCode:string
    errorDescription:string
}

<div id="EngageSummaryReport">
  <div id="FirstPageHeaderSection">
    <img id="logo" class="logo" style="width:181px;" src="../../../assets/images/SummaryReport/logo.jpg"
      alt="Grant Thornton">
    <hr style="border-top:1px solid #E0E0E0;">
    <h3 class="ReportTitle">
      ENGAGE SUMMARY REPORT
    </h3>
    <br />
  </div>
  <div *ngIf="report != null; else NoDataFoundSummaryReport">
    <div id="PageHeaderSection">
      <table class="PageHeader">
        <tr>
          <td class="FieldText" style="width:330px;border:0px;">Legal Name: <span
              class="FieldTextBold">{{report.reportHeader.legalName}}</span></td>
          <td class="FieldText" style="width:330px;border:0px;">Business Line: <span
              class="FieldTextBold">{{report.reportHeader.businessLine}}</span></td>
        </tr>
        <tr>
          <td class="FieldText" style="border:0px;">Process: <span
              class="FieldTextBold">{{report.reportHeader.process}}</span></td>
          <td class="FieldText" style="border:0px;">Status: <span
              class="FieldTextBold">{{report.reportHeader.status}}</span></td>
        </tr>
      </table>
      <br />
    </div>
    <div id="ClientSection">
      <table>
        <thead>
          <tr>
            <td colspan="4" class="TableHeader" style="border: 1px solid #E0E0E0;">Client Information</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Client Type
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{report.reportHeader.clientType}}
            </td>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Stock Exchange
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{report.reportHeader.stockExchange}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Responsible Office
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.responsibleOffice}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Website
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.webSite}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Marketing Industry
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.marketingIndustry}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Industry Sector Code
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.industrySectorCode}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Entity Type
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.entityType}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Industry Sector Name
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.industrySectorName}}
            </td>
          </tr>
          <tr>
            <td colspan="4" class="TableSubHeaderLocation" style="border: 1px solid #E0E0E0;">Location</td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Address-1
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.streetAddress1}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Address-2
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.streetAddress2}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Address-3
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.streetAddress3}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Address-4
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.streetAddress4}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              City
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.city}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              State
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.state}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Country
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.country}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Postal Code
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.postalCode}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Year end
            </td>
            <td class="FieldText" colspan="3" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.clientFiscalYear}}
            </td>
          </tr>
        </tbody>
      </table>
      <br>
    </div>
    <div id="EngagementSection">
      <table>
        <thead>
          <tr>
            <td colspan="4" class="TableHeader" style="border: 1px solid #E0E0E0;">Engagement Information</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Engagement Name
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{report.reportHeader.engagementName}}
            </td>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Partner
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{report.reportHeader.partner}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Primary Solution Code
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.primarySolutionCode}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Engagement Date
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{report.reportHeader.engagementDate}}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="AssignmentSection">
      <table *ngIf="report.reportAssignments.length>0">
        <thead>
          <tr>
            <td colspan="4" class="TableHeader" style="border: 1px solid #E0E0E0;">Assignment Information</td>
          </tr>
        </thead>
        <tbody *ngFor="let item of report.reportAssignments">
          <tr>
            <td colspan="4" class="TableSubHeader" style="border: 1px solid #E0E0E0;">{{item.assignmentType}} Assignment
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Assignment Name
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{item.assignmentName}}
            </td>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              CMS PPMD
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{item.cmsppmd}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Engagement Type
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.engagementType}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Assignment Administrator
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.assignmentAdministrator}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Solution Code
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.solutionCode}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Assignment EQCR
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.assignmentEQCR}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Estimated Start Date
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.estimatedStartDate}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Realization %
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.realization}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Estimated End Date
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.estimatedEndDate}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Estimated Net Fees
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.estimatedNetFees|currency:'USD':'symbol':'0.0'}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Gross Margin %
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.estimatedGrossMargin}}
            </td>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Contingent
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{item.contingent}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Closest GT Office
            </td>
            <td class="FieldText" colspan="3" style="border: 1px solid #E0E0E0;">
              {{item.closestGTOffice}}
            </td>

          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="GASection">
      <table>
        <thead>
          <tr>
            <th colspan="2" class="TableHeader" style="border: 1px solid #E0E0E0;">Governing agreement(s)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;width: 30%;">
              Document Name
            </td>
            <td class="FieldText w-100" style="border: 1px solid #E0E0E0;width: 70%;">
              {{clmDetails?.name}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Document Status
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{clmDetails?.status}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Type of agreement
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{clmDetails?.gaType}}
            </td>
          </tr>
          <tr>
            <td class="FieldHeader" style="border: 1px solid #E0E0E0;">
              Effective date
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{gaEffectiveDate}}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="TemplateSection">
      <table>
        <thead>
          <tr>
            <td colspan="4" class="TableHeader" style="border: 1px solid #E0E0E0;">Template Details</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Template Name
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{report.reportHeader.templateSelected}}
            </td>
            <td class="FieldHeader" style="width:160px;border: 1px solid #E0E0E0;">
              Version
            </td>
            <td class="FieldText" style="width:160px;border: 1px solid #E0E0E0;">
              {{report.reportHeader.templateVersion}}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="ApprovalSection">
      <table *ngIf="report.reportApprovals.length>0">
        <thead>
          <tr>
            <th colspan="3" class="TableHeader" style="border: 1px solid #E0E0E0;">Approvals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="TableSubHeader" style="width:210px;border: 1px solid #E0E0E0;">Role</td>
            <td class="TableSubHeader" style="width: 210px;border: 1px solid #E0E0E0;">Name</td>
            <td class="TableSubHeader" style="width: 230px;border: 1px solid #E0E0E0;">Date and Time of Approval</td>
          </tr>
          <tr *ngFor="let approval of report.reportApprovals">
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{approval.role}}
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{approval.userName}}
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{approval.approvedDateTime}}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="NotificationSection">
      <table *ngIf="report.reportNotifications.length>0">
        <thead>
          <tr>
            <th colspan="2" class="TableHeader" style="border: 1px solid #E0E0E0;">Notification only Professionals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="TableSubHeader" style="width:330px;border: 1px solid #E0E0E0;">Name</td>
            <td class="TableSubHeader" style="width:330px;border: 1px solid #E0E0E0;">Role</td>
          </tr>
          <tr *ngFor="let notify of report.reportNotifications">
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{notify.name}}
            </td>
            <td class="FieldText" style="border: 1px solid #E0E0E0;">
              {{notify.role}}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="AttachmentSection">
      <table>
        <thead>
          <tr>
            <th class="TableHeader" colspan="2" style="border: 1px solid #E0E0E0;">Document Attachments</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="TableSubHeader" style="width:330px;border: 1px solid #E0E0E0;">Acceptance</td>
            <td class="TableSubHeader" style="width:330px;border: 1px solid #E0E0E0;">Description</td>
          </tr>
          <tr *ngFor="let document of acceptanceDocuments">
            <td class="FieldText" style="width:330px;border: 1px solid #E0E0E0;">
              {{document.documentName}}
            </td>
            <td class="FieldText" style="width:330px;border: 1px solid #E0E0E0;">

            </td>
          </tr>
          <tr *ngIf="supplementalDocuments.length>0">
            <td class="TableSubHeader" colspan="2" style="border: 1px solid #E0E0E0;">Supplemental Documents</td>
          </tr>
          <tr *ngFor="let document of supplementalDocuments">
            <td class="FieldText" colspan="2" style="border: 1px solid #E0E0E0;">
              {{document.documentName}}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
    <div id="ReportGeneratedOn">
      <span class="FooterText" style="margin-right:20px;">
        Report generated on : {{reportDate}}
      </span>
    </div>
  </div>
</div>
<ng-template #NoDataFoundSummaryReport>
  <div>
    No Data Found.
  </div>
</ng-template>
<div id="modal-exportDocumentCompleted" class="mt-4 modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-0">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancelClicked($event)">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>        
      </div>      
      <div class="d-flex justify-content-center">
        <div class="information message-background-icon">
          <img class="message-icon" src="../../../../assets/images/EDP/MessageDialog/icon-check-purple.svg"
            alt="information">
        </div>
      </div>
      <div class="d-flex justify-content-center message-title information pt-2 mb-3">
        Export complete
      </div>
      <div class="modal-body">
          <div class="message-description decline-height ml-5 mr-5">
            <p>
            The file has been exported to the <a target="_blank" href="{{exportedDocSharepointURL}}" >General folder</a> within the
            Engagement Documents folder located in this engagement's
            Sharepoint site.
          </p>
          </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex pr-3 pt-0 justify-content-center mt-0 mb-3">
          <button type="button" class="PrimaryButtonBlue" style="width:200px; text-align: center; "
            (click)="okClicked()" data-dismiss="modal">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
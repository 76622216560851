import { EditClient } from './EditClient';
import { ClientAdministrators } from './ClientAdministrators';
import { KeyRelationshipPartners } from './KeyRelationshipPartners';
import { ClientLevelDocument } from './ClientLevelDocument';

export class EditClientViewModel{
    client: EditClient = new EditClient();
    clientAdministrator: ClientAdministrators = new ClientAdministrators();
    //keyRelationshipPartner: KeyRelationshipPartners = new KeyRelationshipPartners();
    loggedInUserFullName: string;
    IsSystemAdmin: boolean
    clientLevelDocuments: ClientLevelDocument[] = [];
}
<div id="modal-AuditPartner" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content"
      [ngClass]="{'modal-min-max-height-EP': dialogDescriptionType=='AdvisoryEPPMDReview'||dialogDescriptionType=='TaxEPPMDReview' || dialogDescriptionType =='AuditEPPMDReview', 
      'modal-min-max-height-RP': dialogDescriptionType == 'AdvisoryRPReview'||dialogDescriptionType == 'TaxRPReview' ||dialogDescriptionType == 'AuditRPReview'} ">
      <div class="modal-header">
        <span class="modal-title">
          <div class="d-flex justify-content-center">
            <div class="information message-background-icon ">
              <img class="message-icon" src="../../../../assets/images/EDP/MessageDialog/information.svg"
                alt="information">
            </div>
          </div>
          <div class="d-flex justify-content-center message-title information pt-2 ml-0">
            {{title}}
          </div>
        </span>
      </div>
      <div class="modal-body">
        <div>
          <div class="message-description decline-height ml-4 mr-4">

            <div *ngIf="dialogDescriptionType=='AdvisoryEPPMDReview'||dialogDescriptionType=='TaxEPPMDReview' ||dialogDescriptionType=='AuditEPPMDReview'">
              Based on the information gathered and the preliminary evaluation of
              engagement risk, this engagement is almost ready for review by the
              Engagement PPMD.
              <br>
              <br>
              <span class="heading-purple-eppmd">Select the Audit partner/MD</span>
              <br>
              <span>
                An engagement team member has indicated that this engagement is for an
                audit or attest client (or affiliate). This engagement must be approved
                by the Audit partner/MD.
              </span>
            </div>
            <div *ngIf="dialogDescriptionType=='AdvisoryRPReview'||dialogDescriptionType=='TaxRPReview' ||dialogDescriptionType=='AuditRPReview'">
              Based on the information gathered and the evaluation of engagement risk, it
              is recommended this engagement be accepted/approved by the firm.
              However, be aware of the following:
              <br>
              <br>
              <span class="heading-purple-eppmd">Select the Audit partner/MD</span>
              <br>
              <span>
                An engagement team member has indicated that this engagement is for an
                audit or attest client (or affiliate). This engagement must be approved
                by the Audit partner/MD.
              </span>
            </div>
            <br>
            <label for="apRoleName">Select the Audit partner/MD<span class="mandatory">*</span></label>
            <div class="input-group mw-100">
              <app-people-picker class="eng-people-picker" [roleShortName]="roleName" [labelCtrlName]="auditPartnerlblCtrlName"
                [isLabelVisible]="false" [InputCtrlIdIndex]="1" [PeoplePickerModel]="memberName"
                [userEmail]="memberemailid" [IsValidateUserWithADGroup]="validateAuditPartner" [hideHinttext]="true"
                (validPeopleSelected)="updateTeamMembersList($event)" (assignUser)="assignUser($event)">
              </app-people-picker>
              <div class="input-group-append input-height">
                <button type="button" id="audit-partner-pp-popup" data-toggle="modal" data-target="#modal-AuditParnterTeamMember" 
                data-keyboard="false" data-backdrop="static"
                  class="mw-100 people-picker-button text-center"
                  (click)="refreshTeamMemberPopup()">
                  <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Audit partner" />
                </button>
              </div>
            </div>
            <span class="label error-color"
              *ngIf="validationEror">{{errorText}}</span>
            <div *ngIf="dialogDescriptionType=='AdvisoryRPReview' || dialogDescriptionType=='TaxRPReview' || dialogDescriptionType=='AuditRPReview'" class="mt-3">
              <span>I certify that all questionnaire questions have been answered appropriately
                and all documentation not related to the governing agreements is complete
                and ready for review by any Reviewing Professionals, if applicable.
              </span>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex pt-2 justify-content-end w-100 mt-2 mb-3 mr-3 ">
          <button type="button" class="SecondaryButton" data-dismiss="modal" style="width: 150px; text-align: center;"
            (click)="cancelClicked()">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-white.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButton ml-3" style="width: 150px; text-align: center; "
            (click)="okClicked()">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="modal-AuditParnterTeamMember" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select Audit partner/MD</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectTeamMember();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="AuditParnterErrorMessage" *ngIf="showAuditParnterErrorMessage" class="error-color">{{auditParnterErrorMessage}}</span>
        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchAuditParntnerName" [(ngModel)]="searchAuditParntnerName"
            (keydown.enter)="searchUsers();"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for Audit partner/MD">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers();">

              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblMemberAD" class="table  table-hover" aria-describedby="Members">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col"> Job Title</th>
                <th scope="col"> Phone</th>
                <th scope="col"> Line of Business</th>
                <th scope="col"> Service Line</th>
                <th scope="col"> Location</th>
              </tr>
            </thead>
            <tbody>
              <tr id="trTeamMembers_{{i}}" name="trTeamMembers_{{i}}"
                *ngFor="let member of membersListArrayForTeamMembers let i=index"
                (click)='selectAuditPartner(i,member)'>
                <td id="TeamMemberList{{i}}">
                  <span id='spMemberdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='spMembermail{{i}}'>{{member.mail}}</span>
                </td>
                <td id="TeamMemberList{{i}}">
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td id="TeamMemberList{{i}}">
                  <span id='spMemberbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td id="TeamMemberList{{i}}"> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deselectTeamMember();">
          Cancel
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;"
          (click)="setTeamMember();">OK</button>
      </div>
    </div>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberFirmData } from '../model/MemberFirm/member-firm-data';
import { AppConfigService } from './appConfigService';

@Injectable({
    providedIn: 'root'
})
export class MemberFirmService {

    APIEndPoint: string;
    IntegrationAPIEndPoint: string;

    constructor(private enviornment: AppConfigService, private http: HttpClient) {
        this.APIEndPoint = this.enviornment.config.DATA.API_END_POINT.API_URL;
        this.IntegrationAPIEndPoint = this.enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    }

    getMemberFirmDetails(userPrincipalName: string): Observable<MemberFirmData[]> {
        return this.http.get<MemberFirmData[]>(this.APIEndPoint + '/MemberFirm/GetMemberFirmDetails/' + userPrincipalName);
    }

    GetMemberFirmFeatureFlag() {
        return this.http.get<boolean>(this.IntegrationAPIEndPoint + '/User/GetMemberFirmFeatureFlag');
    }
    getMemberFirmDetailsById(memberFirmMappingId: number): Observable<MemberFirmData> {
        return this.http.get<MemberFirmData>(this.APIEndPoint + '/MemberFirm/GetMemberFirmDetailsById/' + memberFirmMappingId);
    }
}

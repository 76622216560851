export class EngagementCardViewFilterModel {
    constructor(public clientId: number,
                public solutionCodeId: number,
                public eppmdEmail: string,
                public industryTypeId: number,
                public engagementStatusId: string,
                public sortClientId: boolean,
                public sortSolutionCodeId: boolean,
                public sortEppmdEmailId: boolean,
                public sortIndustryTypeId: boolean,
                public sortEngagementStatusId: boolean,
                public sortLastActivityDate: boolean,
                public loggedInUser: string) {

    }
}

export class EngagementListViewFilterModel {
    constructor(public clientId: number,
                public solutionCodeId: number,
                public eppmdEmail: string,
                public industryTypeId: number,
                public engagementStatusId: string,
                public sortClientId: boolean,
                public sortSolutionCodeId: boolean,
                public sortEppmdEmailId: boolean,
                public sortIndustryTypeId: boolean,
                public sortEngagementStatusId: boolean,
                public sortLastActivityDate: boolean,
                public loggedInUser: string) {

    }
}

export class EngagementCardViewForClientFilterModel {
    constructor(public solutionCodeId: number,
                public eppmdEmail: string,
                public industryTypeId: number,
                public engagementStatusId: string,
                public sortSolutionCodeId: boolean,
                public sortEppmdEmailId: boolean,
                public sortIndustryTypeId: boolean,
                public sortEngagementStatusId: boolean,
                public sortLastActivityDate: boolean,
                public loggedInUser: string) {

    }
}

export class EngagementListViewForClientFilterModel {
    constructor(public solutionCodeId: number,
                public eppmdEmail: string,
                public industryTypeId: number,
                public engagementStatusId: string,
                public sortSolutionCodeId: boolean,
                public sortEppmdEmailId: boolean,
                public sortIndustryTypeId: boolean,
                public sortEngagementStatusId: boolean,
                public sortLastActivityDate: boolean,
                public loggedInUser: string) {

    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './appConfigService';
import { DataModification } from '../model/data-modification-model';

@Injectable({
    providedIn: 'root'
})
export class DataModificationService {

    APIEndPoint: string;
    userName: string;
    userNameDetail: string;
    constructor(private environment: AppConfigService, private http: HttpClient) {
        this.APIEndPoint = environment.config.DATA.API_END_POINT.API_URL;
        const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
        if (nameJSON != null) {
            this.userName = nameJSON.userName;
            this.userNameDetail = nameJSON.lastName + ' ' + nameJSON.firstName;
        }
    }

    IsInProgressDataModificationExists(Id: number, module: string, workspaceId: string = null): Observable<DataModification> {
        const modification = { moduleName: module, engagementId: Id, createdBy: this.userName, workspaceId: workspaceId };
        return this.http.post<DataModification>(
            this.APIEndPoint + '/DataModification/IsInProgressDataModification/', modification
        );
    }

    StoreDataModification(Id: number, module: string): Observable<string> {
        const modification = { moduleName: module, engagementId: Id, createdBy: this.userName, createdByName: this.userNameDetail };
        const requestOptions: object = {
            responseType: 'text'
        };

        return this.http.post<string>(
            this.APIEndPoint + '/DataModification/StoreDataModification', modification, requestOptions
        );
    }

    UpdateDataModification(engId: number, module: string): Observable<string> {
        const modification = { moduleName: module, engagementId: engId, createdBy: this.userName, createdByName: this.userNameDetail };
        const requestOptions: object = {
            responseType: 'text'
        };

        return this.http.post<string>(
            this.APIEndPoint + '/DataModification/UpdateDataModification', modification, requestOptions
        );
    }
}

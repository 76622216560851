export class NotificaitonRequest {
    getNotificationsURL: string;
    request: NotificationOnlyRequestModel = new NotificationOnlyRequestModel();
}
export class NotificationOnlyRequestModel {
    sessionid: string;
    outputtype: string;
    notificationRequest: Engagement = new Engagement();
}

export class Engagement {
    clientName: string;
    clientCode: string;
    tickerSymbol: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    phoneNumber: string;
    fax: string;
    clientStatus: string;
    sICCode: string;
    industryType: string;
    fYE: string;
    websiteAddress: string;
    clientType: string;
    clientProgram: string;
    stockExchange: string;
    referralSource: string;
    fYEMonth: string;
    fYEDay: string;
    clientContactName: string;
    clientContactTitle: string;
    clientContactEmail: string;
    clientBillingName: string;
    clientBillingTitle: string;
    clientBillingEmail: string;
    firstName: string;
    middleName: string;
    lastName: string;
    engagementId: number;
    engagementName: string;
    solutionCode: string;
    taxYear: Date | string | null;
    engagementPartnerEmail: string;
    engagementPartnerName: string;
    engagementPartnerOffice: string;
	continuanceFlag: boolean;
    createdBy: string;
    createdDate: Date | string;
    action: string;
    siteURL: string;
    hotDocsId: string;
    engagementWorkspaceUrl: string;
    assignments: Assignment[] = [];
}

export class Assignment {
    assignmentName: string;
    assignmentCode: string;
    assignmentSolutionCode: string;
    realization: number;
    netFees: number;
    assignmentAdministrator: string;
    adminEmail: string;
    cMSEPPMDName: string;
    cMSEPPMDEmail: string;
    isContingent: boolean;
    isPrimaryAssignment: boolean;
    assignmentCodeStatus: string;
    engagementStartDate: Date | string | null;
    engagementEndDate: Date | string | null;
    clientOfficeLocation: string;
    estimatedGrossMargin: number | null;
    collaborationSiteURL: string;
    clientBillingContactName: string;
    clientBillingContactTitle: string;
    clientBillingContactEmail: string;
    eQCRName: string;
    eQCREmail: string;
}
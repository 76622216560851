import { TeamMember } from "src/app/model/Engagement/TeamMember";
import { Engagements } from "src/app/model/Engagement/Engagements";
import { WorkspaceDocumentModel } from '../WorkspaceDocumentModel';
import { ClientLevelDocument } from '../Client/ClientLevelDocument';
import { ClientTeamMembers } from "./ClientTeamMember";

export class NewEngagementData {
  engagements: Engagements;
  teamMember: TeamMember[];
  engagementAssignment: EngagementAssignmentWithoutSCode[];
  loggedInUserFullName: string;
  workspaceData: PrimaryWorkspaceModel;
  workspaceDocuments: WorkspaceDocumentModel[];
  clientLevelDocument: ClientLevelDocument;
  acceptanceFolderPath: string;
  supplementalFolderPath: string;
  defaultIndusConsent: boolean;
  IsLoggedInUserSystemAdmin: boolean;
  clientMembers: ClientTeamMembers[];
}

export class EngagementAssignmentWithoutSCode {
  assignmentCodeId: number;
  engagementId: number;
  solutionCodeId: number;
  assignmentName: string;
  realization: number;
  netFees: number;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  assignmentCode: string;
  assignmentAdministrator: string;
  adminEmailId: string;
  adminContactNo: string;
  cmsEPPMDName: string;
  cmsEPPMDEmailId: string;
  engagementTypeId: number;
  isContingent: boolean;
  isPrimaryAssignment: boolean;
  collaborationSiteId: number;
  engagementStartDate: Date;
  engagementEndDate: Date;
  officeLocationCodeId: number;
  estimatedGrossMargin: number;
  clientBillingContactName: string;
  clientBillingContactTitleId: number;
  clientBillingontactEmail: string;
  eqcrName: string;
  eqcrEmailId: string;
  loggedInUserName: string;
  opportunityServiceId: string;
  status: string;
}

export class NewEngAssignmentWrapperModel {
  newEngagementAssignment: EngagementAssignmentWithoutSCode;
  opportunityId: string;
}

export class PrimaryWorkspaceModel {
  workspaceId: string;
  hdWorkGroupId: string;
  hdWorkItemId: string;
  hdTemplatePackageId: string;
  hdCurrentTempVersion: number;
  status: string;
  hdTemplatePackageVersionId: string;
  hdTemplateName: string;
}

export class UpdateEngagementTnCModel {
  engagementId: number;
  workspaceData: PrimaryWorkspaceModel;
  loggedInUserEmail: string;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from 'src/app/model/Alert';
import { PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { HDWorkItemDocumentModel, HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { AddWorkspaceDocumentValidationModel, MappingTemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { CloseoutChecklistWorkspaceModel } from 'src/app/model/Workspace/CloseoutChecklistWorkspaceModel';
import { WorkspaceDocumentModel } from 'src/app/model/WorkspaceDocumentModel';
import { WorkspaceInterviewsStatus } from 'src/app/model/WorkspaceModel';
import { AlertsService } from 'src/app/services/alerts.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentService } from 'src/app/services/DocumentService';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
declare var $;

@Component({
  selector: 'app-add-workspace-document',
  templateUrl: './add-workspace-document.component.html',
  styleUrls: ['./add-workspace-document.component.css'],
  providers: [DocumentService]
})
export class AddWorkspaceDocumentComponent implements OnInit {

  addWorkspace_EngagementId: number = 0;
  addWorkspace_SiteUniqueId: string;
  createWorkItemDetails: HDWorkItemModel = new HDWorkItemModel();
  addWorkspaceDocumentList: WorkspaceDocumentModel[] = [];
  workItemDetails: HDWorkItemModel = new HDWorkItemModel();
  workspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  addWorkspace_SolutionCodeId: number = 0;
  addWorkspace_CreateNewEng_documentCategory: string = 'Create New Engagement';
  addWorkspace_TemplateList: MappingTemplatePackageModel[] = [];
  addWorkspace_InsertWorspaceDeatils: CloseoutChecklistWorkspaceModel = new CloseoutChecklistWorkspaceModel();
  addWorkspace_SelectedData: AddWorkspaceDocumentValidationModel = new AddWorkspaceDocumentValidationModel();
  tabNamePlaceholderText: string = "Enter an tab name";

  @Output() closeAddWorkspaceDocument: EventEmitter<boolean> = new EventEmitter<boolean>();
  addWorkspace_IsTemplateSelected: boolean;
  addWorkspace_PrimaryWorkspaceStatus: string;

  @Input() set parentSolutionCodeId(id: number) {
    if (id !== undefined && id !== null && id > 0) {
      this.addWorkspace_SolutionCodeId = id;
      this.getTemplateMappingData();
    }
  }

  @Input() set engagementId(id: number) {
    if (id !== undefined && id !== null && id > 0) {
      this.addWorkspace_EngagementId = id;
    }
  }

  @Input() set siteUniqueID(siteId: string) {
    if (siteId != null && siteId != undefined && siteId != '') {
      this.addWorkspace_SiteUniqueId = siteId;
    }
  }

  constructor(
    private loadingService: LoadingScreenService,
    private hdService: HotDocsService,
    private workspaceService: WorkspaceService,
    private alertService: AlertsService,
    private _configService: ConfigurationService,
    private route: Router,
    private documentService: DocumentService) { }

  ngOnInit(): void {
  }

  CreateWorkitem() {
    this.workspaceService.getWorkspaceByEngagementId(this.addWorkspace_EngagementId).subscribe(
      workspaceResult => {
        this.addWorkspace_PrimaryWorkspaceStatus = workspaceResult.status;
        this.workspaceService.getMaxWorkspaceId().subscribe(
          result => {
            if (result <= 0) {
              result = 1;
            }
            this.createWorkItemDetails.workspaceCode = result + 3;
            this.createWorkItemDetails.siteUniqueId = this.addWorkspace_SiteUniqueId + '_Secondary_';
            this.createWorkItemDetails.templatePackageId = this.addWorkspace_SelectedData.templatePackageId;
            this.ProcessWorkItemCreation();
          },
          error => {
            this.DisplaySystemAlert();
          });
      },
      error => {
        this.DisplaySystemAlert();
      });

  }

  ProcessWorkItemCreation() {
    this.hdService.GetCreateWorkItemDocumentData(this.createWorkItemDetails).subscribe(
      workItemResult => {
        this.workItemDetails = workItemResult.hdWorkItem;
        this.workspaceData.hdWorkGroupId = this.workItemDetails.workGroupId;
        this.workspaceData.hdWorkItemId = this.workItemDetails.id;
        this.workspaceData.hdTemplatePackageId = this.workItemDetails.templatePackageId;
        this.workspaceData.hdCurrentTempVersion = this.workItemDetails.templateCurrentVersion;
        this.workspaceData.status = this.addWorkspace_PrimaryWorkspaceStatus;
        this.workspaceData.hdTemplatePackageVersionId = this.workItemDetails.templatePackageVersionId;

        this.getDocumentListtoPreview(workItemResult);
      },
      error => {
        this.DisplaySystemAlert();
      });

  }

  getDocumentListtoPreview(workItemResult: HDWorkItemDocumentModel) {
    if (workItemResult.hdWorkItem.id != null) {
      this.addWorkspaceDocumentList = [];
      var DocumentList = new WorkspaceDocumentModel();
      workItemResult.hdWorkspaceDocumentList.forEach(x => {
        DocumentList.hdDocumentId = x.documentId;
        DocumentList.hdDocumentName = x.name;
        DocumentList.hdMimeType = x.mimeType;
        DocumentList.hdAssemblySession = x.assemblySessionId;
        DocumentList.isActive = true;
        DocumentList.hdTemplatePackageVersionId = this.workspaceData.hdTemplatePackageVersionId;
        DocumentList.documentAliasName = this.addWorkspace_SelectedData.documentAliasName;
        DocumentList.engagementState = 'Acceptance';
        this.addWorkspaceDocumentList.push(DocumentList);
      });
      this.CreateWorkspaceInDB();
    }
  }
  CreateWorkspaceInDB() {
    this.addWorkspace_InsertWorspaceDeatils.engagementId = this.addWorkspace_EngagementId;
    this.addWorkspace_InsertWorspaceDeatils.workspaceData = this.workspaceData;
    this.addWorkspace_InsertWorspaceDeatils.workspaceDocuments = this.addWorkspaceDocumentList;
    this.addWorkspace_InsertWorspaceDeatils.isCloseOutChecklist = false;
    this.workspaceService.InsertCloseoutChecklistWorkspace(this.addWorkspace_InsertWorspaceDeatils).subscribe(
      result => {
        this.loadingService.stopLoading();
        console.log("New Secondary document workspaceId: ", result);
        if (result != null && result != undefined && result != '') {
          this.closeAddWorkspaceDocumentModal();
          this.route.navigate(['Interviews/' + result]);
        }
      },
      error => {
        this.DisplaySystemAlert();
      });
  }
  DisplaySystemAlert() {
    this.alertService.remove(new Alert());
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }
  getTemplateMappingData() {
    this._configService.getSolutionCodeTemplateMappingData(this.addWorkspace_CreateNewEng_documentCategory, this.addWorkspace_SolutionCodeId, true).subscribe(result => {
      if (result.length > 0) {
        this.addWorkspace_TemplateList = result;
      } else {
        this.addWorkspace_TemplateList = [];
      }
    });
  }
  closeAddWorkspaceDocumentModal() {
    $('#add-workspace-document-modal').modal('hide');
    this.closeAddWorkspaceDocument.emit(true);
  }

  showAddWorkspaceDocumentModal() {
    $('#add-workspace-document-modal').modal({
      backdrop: 'static'
    });
    $('#add-workspace-document-modal').modal('show');
  }

  validateAddWorkspaceDocument() {
    this.addWorkspace_SelectedData.IsTemplateSelected = true;
    this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty = false;
    this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = false;
    this.addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded = false;
    if (this.addWorkspace_SelectedData.templatePackageId == null ||
      this.addWorkspace_SelectedData.templatePackageId == undefined ||
      this.addWorkspace_SelectedData.templatePackageId == '') {
      this.addWorkspace_SelectedData.IsTemplateSelected = false;
    }
    this.addWorkspace_SelectedData.documentAliasName = this.addWorkspace_SelectedData.documentAliasName.trim();
    if (this.addWorkspace_SelectedData.documentAliasName == null ||
      this.addWorkspace_SelectedData.documentAliasName == undefined ||
      this.addWorkspace_SelectedData.documentAliasName == '') {
      this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty = true;
    } else if (this.addWorkspace_SelectedData.documentAliasName.trim().length > 50) {
      this.addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded = true;
    }

    if (this.addWorkspace_SelectedData.IsTemplateSelected &&
      !this.addWorkspace_SelectedData.IsDocumentAliasNameEmpty &&
      !this.addWorkspace_SelectedData.IsDocumentAliasMaxLengthExceeded) {
      this.addWorkspace_SelectedData.engagementId = this.addWorkspace_EngagementId;
      this.documentService.getCountOfDocumentAliasNameByEngagementId(this.addWorkspace_SelectedData).subscribe(
        count => {
          if (count > 0) {
            this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = true;
          } else {
            this.loadingService.startLoading();
            this.CreateWorkitem();
          }
        },
        err => {
          this.DisplaySystemAlert();
        });
    }
  }
  onTemplateChange() {
    if (this.addWorkspace_TemplateList.length > 0) {
      this.addWorkspace_SelectedData.documentAliasName = this.addWorkspace_TemplateList.find(x =>
        x.templatePackageId === this.addWorkspace_SelectedData.templatePackageId).templateName;
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Applicationmaster } from 'src/app/model/ApplicationMaster';
import { ApplicationMasterService } from 'src/app/services/application-master.service';

@Component({
  selector: 'app-sharepoint-file-label',
  templateUrl: './sharepoint-file-label.component.html',
  styleUrls: ['./sharepoint-file-label.component.css']
})
export class SharepointFileLabelComponent implements OnInit, OnChanges {

  @Input('sharePointSelectedFileLabel') sharePointSelectedFileLabel: string = "";
  @Output() close = new EventEmitter();
  @Output() ok = new EventEmitter<string>();
  sharePointApplicationMasterLabels: Applicationmaster[] = [];
  validationError: boolean = false;

  constructor(private applicationMasterService: ApplicationMasterService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharePointSelectedFileLabel) {
      this.sharePointSelectedFileLabel = changes.sharePointSelectedFileLabel.currentValue??'';
    }
  }

  ngOnInit() {
    this.loadSharepointLabelData();
  }

  loadSharepointLabelData() {
    this.applicationMasterService.GetSharepointFileLabels().subscribe(applicationMaster => {
      if (applicationMaster && applicationMaster?.length > 0) {
        this.sharePointApplicationMasterLabels = applicationMaster;
      }
    }, err => {
      console.error('Not able to load the sharepoint file label application master', err);
    })
  }
  okClicked() {
    this.validateLabel();
    if (this.validationError == false) {
      this.ok.emit(this.sharePointSelectedFileLabel);
    }
  }
  cancelClicked(event) {
    this.sharePointSelectedFileLabel="";
    this.validationError = false;
    this.close.emit(event);
  }
  validateLabel() {
    if (this.sharePointSelectedFileLabel == undefined || this.sharePointSelectedFileLabel == "") {
      this.validationError = true;
    } else {
      this.validationError = false;
    }
  }

}

export class ClmSignedDocumentRequest {
    "oneView Account ID":string;
    oneViewAccountID: string;
    attachment: any[];
    agreementType: string;
    clientName: string;
    engagementName: string;
    solutionCode: string;
    industryType: string;
    engagementPPMDEmail: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    effectiveDate: Date;
    expirationDate: Date;
    expirationcheckboxvalue: string;
    governmentEntity: string;
    jurisdiction: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    contractnotes: string;
    contractRequestorEmail: string;
    engagementID: string;
}

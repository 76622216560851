import { DatePipe } from '@angular/common';
import { EngagementService } from 'src/app/services/EngagementService';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { DelegateApproval } from 'src/app/model/Common/DelegateApproval';
import { MembersList } from 'src/app/model/MembersList';
import { RoleNames } from 'src/app/model/Role';
import { PsdMembersWithDelegate } from 'src/app/model/Workspace/DecisionsRPRequestModel';
import { AdUsersService } from 'src/app/services/adusers.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DataService } from 'src/app/services/data.service';
import { DelegateService } from 'src/app/services/delegate.service';
import { DecisionsRuleEngineService } from 'src/app/services/Integration/decisions-rule-engine.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { LogService } from 'src/app/services/log.service';

declare var $;

@Component({
  selector: 'app-delegate-dialog',
  templateUrl: './delegate-dialog.component.html',
  styleUrls: ['./delegate-dialog.component.css'],
  providers: [DelegateService]
})
export class DelegateDialogComponent implements OnInit, OnChanges {

  isHidden: boolean;
  //isDelayHidden: boolean;
  messageTitle: string;
  @Input() modalDisplay: boolean;
  @Output() closeClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() okClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelClick: EventEmitter<string> = new EventEmitter<string>();
  isBeginningDateAdded: boolean;
  isBeginningDateRequired: boolean;
  isEndingDateAdded: boolean;
  isEndingDateRequired: boolean;
  isDelegateSelected: boolean;
  isDelegateValid: boolean;
  EndingDateCtrlId: string;
  BeginningDateCtrlId: string;
  assignDelegateRoleName: string;
  assignDelegateLabelCtrlName: string;
  peoplePickerIndex: number;
  delegateDisplayUserName: string;
  delegateDisplayUserEmail: string;
  validateDelegate: boolean;
  isDataPopulated: boolean;
  delegateFromUserName: string;
  delegateStatusMessage: string;
  currentDelegateIndex: number;
  selectedDelegateIndex: string;

  membersListArrayForDelegate: MembersList[];
  DateCompareResult: number;
  isDelegateSameAsLoggedInUser: boolean;
  userName: string;
  isButtonDisabled: boolean;
  CurrentCompareResult: number;
  lineOfBusiness: string;
  isPeoplePickerDisabled: boolean;
  isAdvisoryRetentionFeatureFlagEnabled:boolean;
 


  constructor(private engagementService: EngagementService,
    private dataService: DataService, private logService: LogService, private datePipe: DatePipe,
    private delegateService: DelegateService, private loadingScreenService: LoadingScreenService,
    private adUsersService: AdUsersService, private decisions: DecisionsRuleEngineService,
    private configureService: ConfigurationService
  ) {
    this.isHidden = false;
    //this.isDelayHidden = false;
    this.isBeginningDateAdded = false;
    this.isBeginningDateRequired = false;
    this.isEndingDateAdded = false;
    this.isEndingDateRequired = false;
    this.isDelegateSelected = false;
    this.isDelegateValid = false;
    this.validateDelegate = false;
    this.isDataPopulated = false;
    this.delegateStatusMessage = '';
    this.isDelegateSameAsLoggedInUser = false;
    this.isButtonDisabled = false;
    this.lineOfBusiness = '';
    this.isPeoplePickerDisabled = false;
    this.isAdvisoryRetentionFeatureFlagEnabled=true;
  }

  ngOnInit() {
    this.logService.log('ngOnInit of Delegate Dialog ', this.modalDisplay);
    this.isHidden = this.modalDisplay;
    this.BeginningDateCtrlId = 'beginningDate';
    this.EndingDateCtrlId = 'endingDate';
    this.assignDelegateRoleName = RoleNames.Delegate.toString();
    this.assignDelegateLabelCtrlName = 'delegate';
    this.peoplePickerIndex = 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logService.log('ngOnChange ', changes);
    for (const propName in changes) {
      if (propName === 'modalDisplay') {
        const change = changes[propName];
        let curVal = JSON.stringify(change.currentValue);
        const prevVal = JSON.stringify(change.previousValue);
        this.logService.log('ngOnChange func called in Delegate Dialog Component', curVal, prevVal);
        if (curVal) {
          curVal = curVal.replace(/['"]+/g, '');
          if (curVal !== prevVal) {
            this.isHidden = (/true/i).test(curVal);
            if (this.isHidden) {
              this.delegateService.InitializeUserDetails();
              const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
              if (nameJSON != null) {
                this.userName = nameJSON.userName;
                this.lineOfBusiness = nameJSON.lineOfBusiness == null ? 'NA' : nameJSON.lineOfBusiness;
              }
              this.delegateStatusMessage = '';
              //this.GetDelegate(this.lineOfBusiness, this.isDelayHidden);
              this.GetDelegate(this.lineOfBusiness,false);
            }
          }
        }
      }
    }
  }

  closeDialog(event: Event) {
    console.log('closeDialog ', event);
    this.logService.log('closeDialog button called');
    const result = { buttonName: 'close', dialogTitle: this.messageTitle };
    this.closeClick.emit(JSON.stringify(result));
    this.hideModal();
  }

  Ok() {

    const delegate = new DelegateApproval();
    delegate.beginningDate = $('.' + this.BeginningDateCtrlId).val();
    delegate.endingDate = $('.' + this.EndingDateCtrlId).val();
    delegate.delegateToName = $('#txtPeoplePicker_DL_1').val();
    delegate.delegateToEmailId = $('#UserHiddenProps_DL_1 #hdnUserEmail').val();

    const flag = this.CheckValidation(delegate);
    console.log('Validation Result', flag);

    if (flag) {
      // const delegationId = $('#DelegationId').val();
      // if (delegationId > 0) {
      //   delegate.memberDelegationId = delegationId;
      //   this.delegateService.UpdateDelegateApproval(delegate).subscribe(
      //     updatedTimeEvent => {
      //       console.log('result of UpdateDelegateApproval ', updatedTimeEvent);
      //       this.ShowMessage('Delegation updated successfully');
      //       this.GetDelegate(this.lineOfBusiness);
      //     }, error => {
      //       console.error('error in UpdateDelegateApproval', error);
      //     }
      //   );
      // } else {
      this.delegateService.StoreDelegateApproval(delegate).subscribe(
        storedTimeEvent => {
          console.log('result of StoreDelegateApproval ', storedTimeEvent);
          this.ShowMessage('Delegation recorded successfully');
          this.GetDelegate(this.lineOfBusiness,true);
          //this.GetDelegate(this.lineOfBusiness, this.isDelayHidden);
        }, error => {
          console.error('error in StoreDelegateApproval', error);
        }
      );
      //}
    }
  }

  CheckValidation(delegate: DelegateApproval): boolean {
    console.log('CheckValidation ', delegate);

    this.isBeginningDateRequired = delegate.beginningDate === '';
    this.isEndingDateRequired = delegate.endingDate === '';
    this.isDelegateSelected = delegate.delegateToName === '';
     var name=$('#txtPeoplePicker_DL_1').val();
    // 1. Validate the beginning date with current date
    const currentDate = new Date().toDateString();
    this.CurrentCompareResult = this.compareDate(currentDate, delegate.beginningDate);

    if (this.CurrentCompareResult <= 0) {
      // 2. Validate the date range selection
      this.DateCompareResult = this.compareDate(delegate.beginningDate, delegate.endingDate);
       if(name!="" && !this.isPeoplePickerDisabled)
       {
        if (this.DateCompareResult <= 0 && !this.isPeoplePickerDisabled) {
          // 3. Validate the Delegate selected
          this.isDelegateValid = !Boolean(JSON.parse($('#hdnValidInput_DL_1').val()));
           
          if (this.isDelegateValid) {
            // 4. Validate the Delegate should not be same as logged in user
            this.isDelegateSameAsLoggedInUser = delegate.delegateToEmailId === this.userName;
          } else {
            this.isDelegateSameAsLoggedInUser = false;
          }
        }
       }
       else if(this.isPeoplePickerDisabled){
        if (this.DateCompareResult <= 0 && !this.isPeoplePickerDisabled) {
          // 3. Validate the Delegate selected
          this.isDelegateValid = !Boolean(JSON.parse($('#hdnValidInput_DL_1').val()));
           
          if (this.isDelegateValid) {
            // 4. Validate the Delegate should not be same as logged in user
            this.isDelegateSameAsLoggedInUser = delegate.delegateToEmailId === this.userName;
          } else {
            this.isDelegateSameAsLoggedInUser = false;
          }
        }
       }
        else{
          this.isDelegateSelected=true;
        this.isDelegateSameAsLoggedInUser=false;
        this.isDelegateValid=false;
        return false;
        }
      }
  
    console.log('Validation  ', this.CurrentCompareResult, this.DateCompareResult,
      this.CurrentCompareResult <= 0, this.DateCompareResult <= 0,
      !this.isDelegateValid, this.isDelegateSameAsLoggedInUser);

    return this.CurrentCompareResult <= 0 && this.DateCompareResult <= 0 &&
      (this.isPeoplePickerDisabled ? true :
        !this.isDelegateValid &&
        !this.isDelegateSameAsLoggedInUser);
  }

  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  private compareDate(date1: string, date2: string): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) {
      return 0;
    }

    // Check if the first is greater than second
    if (d1 > d2) {
      return 1;
    }

    // Check if the first is less than second
    if (d1 < d2) {
      return -1;
    }
  }


  ShowMessage(message: string) {
    this.delegateStatusMessage = message;
  }

  CheckObjectIsEmpty(delegate: DelegateApproval) {
    return (delegate.beginningDate === '' && delegate.endingDate === ''
      && delegate.delegateToName === '' && delegate.delegateToEmailId === '');
  }

  //GetDelegate(lob: string, isHidden: boolean) {
  GetDelegate(lob: string, isCalledFromStoreDelegate: boolean) {
    this.delegateService.GetDelegateApproval(lob).subscribe(
      result => {
        console.log('GetDelegateApproval ', result);
        if (result != null && result.createdBy != null) {
          $('.' + this.BeginningDateCtrlId).val(this.datePipe.transform(result.beginningDate, 'MM/dd/yyyy'));
          $('.' + this.EndingDateCtrlId).val(this.datePipe.transform(result.endingDate, 'MM/dd/yyyy'));
          this.delegateDisplayUserName = result.delegateToName;
          this.delegateDisplayUserEmail = result.delegateToEmailId;
          this.delegateFromUserName = result.delegateFromEmailId;
          this.isPeoplePickerDisabled = result.isDisabled;
          this.validateDelegate = true;
          this.isDataPopulated = true;
          if (+result.timeEvent <= 0) {
            this.isButtonDisabled = true;
          }
         
          
          
          $('#DelegationId').val(result.memberDelegationId);
        } else {
          this.isPeoplePickerDisabled = result.isDisabled;
          this.ClearDelegate();
        }

        if(isCalledFromStoreDelegate)
        {
          this.savePsdDelegation();
        }
        //this.isHidden = isHidden;
      },
      error => {
        console.error('error in GetDelegateApproval', error);
      }
    );
  }

  ClearDelegate() {
    $('.' + this.BeginningDateCtrlId).val('');
    $('.' + this.EndingDateCtrlId).val('');
    this.delegateDisplayUserName = '';
    this.delegateDisplayUserEmail = '';
    $('#hdnValidInput_DL_1').val(false);
    this.delegateStatusMessage = '';
    this.isButtonDisabled = false;
    $('#DelegationId').val(0);
    this.isDelegateSameAsLoggedInUser = false;
    this.CurrentCompareResult = null;
    this.DateCompareResult = null;
    this.isBeginningDateRequired = false;
    this.isEndingDateRequired = false;
    this.isDelegateSelected = false;
    this.isDelegateValid=false;
  
  }

  Cancel() {
    this.ClearDelegate();

    if (this.isDataPopulated) {
      this.delegateService.ClearDelegateApproval(this.delegateFromUserName).subscribe(
        clearResult => {
          console.log('result of ClearDelegateApproval ', clearResult);
          this.ShowMessage('Delegation cleared successfully');
        }, error => {
          console.error('error in ClearDelegateApproval', error);
        }
      );
      this.isDataPopulated = false;
    }
    this.logService.log('Cancel button called');
    this.cancelClick.emit('Cancel');
  }

  hideModal() {
    this.isHidden = false;
    //this.isDelayHidden = false;
    const modalObj = {
      displayDialog: false
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.setDelegateDialogDetails(modalJSON);
  }

  deSelectDelegate() {
    this.delegateDisplayUserName = '';
    this.delegateDisplayUserEmail = '';
    $('#modal-Delegate').modal('hide');
  }

  searchUsers(searchTextBox: string) {
    const searchText = $('#' + searchTextBox).val();
    this.getDelegateList(searchText);
  }

  selectDelegate(index: string) {
    $('#tblAssAdminAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedDelegateIndex = index;
  }

  setDelegate() {
    const index = this.selectedDelegateIndex;
    if (Number(index) >= 0) {
      const selectedDelegateName = $('#delegateDisplayName' + index).text();
      const selectedDelegateEmail = $('#delegateEmail' + index).text();
      const selectedDelegatePhone = $('#delegateBusinessPhone' + index).text();
      this.delegateDisplayUserName = selectedDelegateName;
      this.delegateDisplayUserEmail = selectedDelegateEmail;
      this.validateDelegate = true;
      $('#DelegateErrorMessage').text('');

      $('#DelegateErrorMessage').text('');
      if (this.assignDelegateLabelCtrlName !== '') {
        $('#' + this.assignDelegateLabelCtrlName).val(selectedDelegateName);
        $('#UserHiddenProps_DL_1 #hdnUserName').val(selectedDelegateName);
        $('#UserHiddenProps_DL_1 #hdnUserEmail').val(selectedDelegateEmail);
        $('#UserHiddenProps_DL_1 #hdnUserPhone').val(selectedDelegatePhone);
        $('#hdnValidInput_DL_1').val(true);
      }
    }
    this.membersListArrayForDelegate = null;
  }

  refreshDelegatePopup(textBoxName: string) {
    this.removeRowSelection('tblDelegate', 'searchDelegate', 'DelegateErrorMessage');
    const lastIndex = this.peoplePickerIndex;
    $('#modal-Delegate').modal('show');
    this.currentDelegateIndex = lastIndex;
    this.selectedDelegateIndex = null;
    this.validateDelegate = false;
    this.membersListArrayForDelegate = null;
    if ($('#' + this.assignDelegateLabelCtrlName).hasClass('text-danger')) {
      $('#' + this.assignDelegateLabelCtrlName).removeClass('text-danger');
    }
  }

  removeRowSelection(tableId, textBoxId, errorMessageId) {
    $('#' + textBoxId).val('');
    $('#' + errorMessageId).text('');
    const searchText = $('#' + textBoxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) { return; }
      $(this).find('td').each(function () {
        const id = $(this).text().toLowerCase().trim();
        const notFound = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!notFound);
        return notFound;
      });
    });
  }

  getDelegateList(searchTerm: string) {
    this.loadingScreenService.startLoading();
    const searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForDelegate = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForDelegate = null;
          this.loadingScreenService.stopLoading();
        });
  }

  savePsdDelegation()
  {
     //psd delegation logic
     if( this.isPeoplePickerDisabled)
     {
       var psddelegateinfo = new PsdMembersWithDelegate();
       psddelegateinfo.psdEmailId = this.delegateFromUserName;

       this.decisions.GetPsdMembersWithDelegate(psddelegateinfo).subscribe(result => {
         if (result !== null && result !== undefined && result.psdDelegateMemberEmailId.trim() &&
            result.psdDelegateMemberEmailId !== undefined) 
           {
             this.configureService.AddUpdatePSDDelegation(result).subscribe(delegation =>{
               if(delegation === 'Success')
               {
                 this.logService.log('PSD delegation added successfully');
               } else{
           this.logService.log('error while saving psd delegate information');

               }

             }, error =>{
           this.logService.log('error while saving psd delegate information');
             });
           }
           else{
           this.logService.log('error while fetching psd delegate information');

           }

       },error => {
           this.logService.log('error while fetching psd delegate information'+error);
       });
     }
  }

}



import { EngagementAssignment } from './Engagement/EngagementAssignments';
import { TeamMember } from './Engagement/TeamMember';
import { CollaborationSite, CollaborationSiteMembers, CollaborationClientSite, CollaborationSpacePriorYearViewModel } from './CollaborationSite';
import { ClientTeamMembers } from './Engagement/ClientTeamMember';
import { EngagementESignature } from './Engagement/EngagementESignature';
import { SecondarySolutionCode } from './Engagement/SecondarySolutionCodes';
import { ClientVoiceTeamMember } from './Engagement/Engagements';
import { SiteCollaborationMembers } from './ClosureNotification/site-collaboration-members.model';

export class EngagementListModel {
  clientName: string;
  EngName: string;
  SolutionCode: string;
  Status: string;
  EP: string;
  LastActivityDate: string;
}

export class EngagementList {
  engagementId: number;
  clientId: number;
  engagementName: string;
  assignmentCode: string;
  solutionCode: string;
  solutionCodeDescription: string;
  engagementPartner: string;
  engagementPartnerName: string;
  taxYear: Date;
  status: string;
  clientName: string;
  clientCode: string;
  LastModifiedBy: string;
  LastModifiedDate: Date;
  activeWorkspaceCount: number;
  lastActivityBy: string;
  lastActivityDate: Date;
  epContactno: string;
  assignmentCodeStatus: string;
  parentSolutionCode: string;
  parentSolutionCodeId: number;
  ePPMD: string;
  ePPMDEmailID: string;
  assignmentsCount: number;
  clientBillingName: string;
  continuanceEligibility: boolean;
  workspaceId: string;
  isLoggedInUserPartOfWorkSpace: boolean
  siteUrl: string;
  siteUniqueID: string;
  siteRequestStatus: string;
  LastModifiedByName: string;
  isloggedInUserAdmin: string;
  reacceptanceEngagementId?: number;
  engagementSiteRequestId: number;
  requestId: string;
  collaborationSiteId: number
  acceptanceFolderPath: string;
  supplementalFolderPath: string;
  isAssignmentExec: boolean = false;
  workspaceOpenReviewPointsCount: number;
  workspaceRespondedReviewPointsCount: number;
  updateQuestionnaireAfterAcceptance: boolean;
  appliedTnC: boolean = false;
  updateQuestionnaireAfterAcceptanceTemplateMaintainance: boolean;
  isSPLinkAvailableOnlyForQualityAndAdmin: boolean = false;
  isSPLinkUnavailableForAll: boolean = false;
  ePPMDOfficeLocation: string;
  isSuccessorFirmEnabled: boolean = false;
  closeoutHDTemplatePackageId: string = null;
  qualityHDTemplatePackageId:string=null;
  sectionId:number;
  plannedClosureDate: Date;
  serviceLine: string;
  primaryWorkspaceId: string;
  primaryWorkitemId: string;
  primaryTemplatePackageId: string;
  completionDate: Date;
  commaSeperatedSolutionCodes: string;
  commaSeparatedSolutionCodeDescription:string;
  opportunityId: string;
  oneViewAccountId: string;
  conversationDate: Date;
  improvementDate: Date;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
}

export class OrderByTypeList {
  value: string;
  name: string;
}

export class HeaderModel {
  headerId: number;
  headerName: string;
  headerValue: string;
  headerClick: boolean = false;
  imageModelList: ImageModel[] = null;
}

export class ImageModel {
  imageName: string;
  imageValue: string;
  imagePath: string;
  isVisible: boolean = false;
}


export enum ContactCardControl {
  listViewdiv = 'ePPMDcontactPopup_',
  cardViewDiv = 'cardViewEPPMDcontactPopup_',
  close = 'contact-close',
  popup = 'contact-popup'
}

export enum ContactCardClick {
  cardViewInput = 'cardViewtxtengId',
  listViewInput = 'listViewtxtengId',
}
export class CollaborationAssignment {
  SourceCollaborationSiteId: number;
  AssignmentCodeId: number;
  DestinationCollaborationSiteId: number;
  isAssignmentExit: boolean;
  updatedBy: string;
}
export class CollaborationTeamMember {
  SourceCollaborationSiteId: number;
  TeamMemberId: number;
  MemberName: string;
  MemberEmail: string;
  PermissionId: number;
  DestinationCollaborationSiteId: number;
  isMemberExit: boolean;
  updatedBy: string;
  workspaceId: string;
  TeamMemberRole: number;
}


export class CollaborationSiteTeamMember {
  SourceCollaborationSiteId: number;
  collaborationSiteMembersId: number;
  memberName: string;
  memberEmailId: string;
  memberRoleId: number;
  memberPermissionId: number;
  DestinationCollaborationSiteId: number;
  isMemberExit: boolean;
}

export class CollaborationClientTeamMember {
  SourceCollaborationSiteId: number;
  ClientTeamMemberId: number;
  FirstName: string;
  LastName: string;
  ClientMemberEmail: string;
  Title: string;
  PermissionId: number;
  DestinationCollaborationSiteId: number;
  isMemberExit: boolean;
  updatedBy: string;

}

export class UpdatedAssignmentMembersViewModel {
  engagementId: number;
  workspaceId: string;
  collaborationAssignments: CollaborationAssignment[];
  collaborationClientTeamMembers: CollaborationClientTeamMember[];
  collaborationSiteTeamMembers: CollaborationSiteTeamMember[];
  acceptanceFolderPath: string;
  supplementalFolderPath: string;
}

export class EngagementCollaborationsViewModel {
  engagement: EngagementList;
  engagementAssignmentList: EngagementAssignment[];
  teamMemberList: TeamMember[];
  collaborationSiteList: CollaborationSite[];
  clientTeamMemberList: ClientTeamMembers[];
  engagementESignature: EngagementESignature;
  collaborationSiteMembersList: CollaborationSiteMembers[];
  collaborationClientSiteList: CollaborationClientSite[]
  secondarySolutionCodeList: SecondarySolutionCode[] = [];
  collaborationSpacePriorYearViewModelList: CollaborationSpacePriorYearViewModel[];
  clientVoiceTeamMemberList: ClientVoiceTeamMember[];
}

export class EngagementApprovalsDataModel {
  engagementId: number;
  clientId: number;
  clientName: string;
  engagementName: string;
  solutionCode: string;
  eppmd: string;
  eppmdEmailId: string;
  datePosted: Date;
  status: string;
  workspaceId: string;
  siteURL: string;
  workspaceOpenReviewPointsCount: number;
  workspaceRespondedReviewPointsCount: number;
  approverStatus: number;
  reacceptanceEngagementId?: number;

}

export class DelegatedApprovalsDataModel {
  engagementId: number;
  clientId: number;
  clientName: string;
  engagementName: string;
  delegated: string;
  delegatedEmailId: string;
  decisionDate: Date;
  workspaceId: string;
  status: string;
  siteURL: string;

}

export class UpdateEngagementNameMode {
  engagementId: number;
  engagementName: string;
  collaborationSiteId: number;
  collaborationSiteName: string;

}

export class ContinuanceEligibilityModel {
  engagementId: number;
  hdWorkItemId: string;
  hdTemplatePackageId: string;
  workspaceId: string;
  solutionCode: string;
}

export class UpdateEngagementStatusModel {
  engagementList: EngagementList;
  isEngContinuanceEligible: boolean;
  engagementVoidedReason: string;
  siteCollaborationMembersList: SiteCollaborationMembers[];
}

export class ClientVoiceTeamMemberViewModel {
  clientVoiceTeamMemberList: ClientVoiceTeamMember[];
}

export class EngagementCloseoutConfiguration {
  id: number;
  sectionId: number;
  code: string;
  value: string;
  isDefaultOpen: boolean;
  isCompleted: boolean;
  createdOn: Date
  modifiedOn: Date
}

export enum CloseoutSections {
  RM = "RM",                     // Retention Period  
  FAI = "FAI",                   // File archive Information  
  CS = "CS",                     // ClientVoice survey  
  IM = "IM",                     // Insight metrics  
  USIRR = "USIRR",               // Update skills in Rapid RM        
  RPMF = "RPMF",                 // Request performance management feedback      
  PPMFTM = "PPMFTM",             // Provide performance management feedback for a team member          
  CCSQ = "CCSQ",                 // Create a case study or qualification      
  PCRCIGTREFDB = "PCRCIGTREFDB", // Provide client reference or contact information for the GT references databases                      
  FCC = "FCC",                   // File clean-up checklist    
  ALC = "ALC" ,                  // Audit Lifecycle Checklist
  QC="QC"                        // Quality Checklist

}

export class CloseoutSectionVisibility {
  requiredForEngagementCloseout: EngagementCloseoutConfiguration[] = [];
  requiredTasks: EngagementCloseoutConfiguration[] = [];
  optionalTasks: EngagementCloseoutConfiguration[] = [];
}

export class IWantToDocumentsReference {
  engagementId: number
  fileGuid: string;
  fileName: string;
  folderPath: string;
  isDeleted: boolean;
  siteUrl: string;
  workspaceId: string;
  createdBy: string;
  lastModifedBy: string
  status: string;
}

export class ServiceLineFlagDetails {
  isSuccessorFirmApplied: boolean;
  isRetentionSectionApplied: boolean;
}

export class EngagementActualCloseoutInDays {
  actualCloseoutInDays: number;
}
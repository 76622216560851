export class WorkspaceReviewPoint {
    reviewPointId: number

    userName: string

    subject: string

    description: string

    workspaceId: string

    status:number

    createdBy: string

    createdOn: Date

    lastModifiedBy: string

    lastModifiedOn: Date

    name:string

    statusName: string

    loggedInUserFullName:string
    
    whoCanResolve: string;
    membersMentionedInReviewNote: ReviewNoteMentionedUser[] = [];  
}

export class WorkspaceReviewPointComment {
    reviewPointCommentsId: number

    reviewPointId: number

    comments: string

    createdBy: string

    createdOn: Date

    lastModifiedBy: string

    lastModifiedOn: Date

    name:string

    workSpaceId: string

    subject: string

    loggedInUserFullName:string    
    membersMentionedInReviewNote: ReviewNoteMentionedUser[] = [];  
}


export class ImageModel {
    imageName: string;

    imageValue: string;

    imagePath: string;

    isVisible: boolean = false;
  }

export class WorkspaceReviewPointModel {
    workspaceReviewPoint: WorkspaceReviewPoint;

    workspaceReviewPointCommentCount: number;

    imageModelList: ImageModel[] = null;

    replyFlag: boolean = false;

    replyComment : string = null;

    isLoggedinUserCreatedReviewPoint:boolean = false;  

    workspaceReviewPointComments: WorkspaceReviewPointComment[] = null;
}

export class ReviewPointsWithCommentsModel {
    reviewPointList: WorkspaceReviewPoint[] = null;
    
    reviewPointCommentsList: WorkspaceReviewPointComment[] = null;
}

export class ReviewNoteSearchableUser {
    key: string;
    value: string;
    email: string;
}

export class ReviewNoteMentionedUser {
    id? : number;
    reviewNoteId: number;
    reviewPointCommentsId?: number;
    userName: string;
    userEmailId: string;
    dateTime?: Date;
    createdBy: string;
}  

export class ReviewNoteEngagementDetailModel {
    engagementName : string;
    clientName : string;
    solutionCode : string;
    ReacceptanceEngagementId : number;
    siteURL : string;
}
<table id="DocumentTableDataId" class="DataList1 table hide">
  <thead>
    <tr>
      <th scope="col">Document Name</th>
      <th scope="col">Status</th>
      <th scope="col">Last Activity</th>
      <th scope="col" class="customWidth"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let document of documentList">
      <td scope="row">{{document.documentName}}</td>
      <td>{{document.status}}</td>
      <td>{{document.lastActivityDate | date:'MM/dd/yyyy'}}</td>
      <td class="customWidth">
        <div *ngIf="document.status == 'Not started' || document.status == null"><a
            href="/StaticQuestions/{{document.workspaceId}}" class="link">Continue Workspace
            <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Workspace" /></a>
        </div>
        <div *ngIf="document.status != 'Draft' && document.status != 'Not started' && document.status != null "><a
            href="/Review/{{document.workspaceId}}" class="link">Go to Workspace
            <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Workspace" /></a>
        </div>
        <div *ngIf="document.status == 'Draft' ">
          <a id="{{document.workspaceId}}"
            onClick="document.getElementById('HiddenWorkspaceIdonDocListPopup').value = this.id;"
            class="{{document.workspaceId}} link">Show Interviews
          </a> <img class="" src="../../../../assets/images/Document/arrow_card-view.png" alt="Interview" />
        </div>
      </td>
    </tr>
  </tbody>
</table>
<input type="text" class="hide" id="HiddenWorkspaceIdonDocListPopup">


export class AuditPartnerModel {
    workspaceId: string;
    memberName: string;
    memberEmailId:string;
    loggedInUser:string;
    loggedInUserFullName:string;
}
export class AuditPartnerStatusModel {
    prevAPEmailId:string;
    newAPEmailId:string;
    status:string;
}
export class WorkspaceAuditPartner {
    updatedDateTime:string;
    isToDeleteAuditPartner:boolean;
    deletedAuditPartnerEmailId:string;
}
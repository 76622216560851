<!--Start Sharepoint File Label POPUP-->
<div id="modal-SharepointFileLabel" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">
          <div class="d-flex justify-content-end">
            <div class="information message-background-icon mr-5">
              <img class="message-icon" src="../../../../assets/images/EDP/MessageDialog/information.svg"
                alt="information">
            </div>
          </div>
          <div class="d-flex justify-content-end message-title information pt-2 ml-5">
            Assign label.
          </div>
        </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancelClicked($event)">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>

          <div class="message-description decline-height ml-5 mr-5">
            Files added to supplemental documents must be labeled. Select from the list below.

            <div class="row mt-3">
              <div class="col-12 col-sm-12 col-lg-12">
                <label for="sharepointFileLabel">Select label:<span class="mandatory">*</span></label>
                <div class="selectdiv">
                  <select class="dropdown rounded-0 " name="sharepointFileLabel" id="sharepointFileLabel"
                    #sharepointFileLabel="ngModel" [(ngModel)]="sharePointSelectedFileLabel" (change)="validateLabel()">
                    <option *ngFor="let sharePointApplicationMasterLabel of sharePointApplicationMasterLabels"
                      [value]="sharePointApplicationMasterLabel.value">
                      {{sharePointApplicationMasterLabel.value}}
                    </option>
                  </select>
                </div>
                <div *ngIf="validationError" class="error-color">
                  Label field is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex pr-3 pt-3 justify-content-center mt-3 mb-3">
          <button type="button" class="SecondaryButtonBlue mr-4" data-dismiss="modal"
            style="width: 150px; text-align: center;" (click)="cancelClicked($event)">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-information-24px.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButtonBlue" style="width: 150px; text-align: center; "
            (click)="okClicked()">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
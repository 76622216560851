export class Engagements {
  engagementId: number;
  clientId: number;
  taxYear: Date;
  parentSolutionCodeId: number;
  engagementName: string;
  engagementPartner: string;
  engagementPartnerName: string;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  status: string;
  epContactno: string;
  clientName: string;
  solutionCode: string;
  clientCode: string;
  reacceptanceEngagementId: number;
  assignmentAdministratorName: string;
  administratorEmailId: string;
  agreementType: string;
  workspaceId: string;
  siteURL: string;
  siteUniqueID: string;
  isByPass: boolean;
  clientLevelDocumentId: number;
  isPeriodEndDateRequired?: boolean;
  preRequestDuringContinuance?: boolean;
  preRequestForContinuance?: boolean;
  ePPMDOfficeLocation: string;
  opportunityId: string;
  priorYearEngagementId: number
  IsPreRequestOptedOut?:boolean;
  memberFirmMappingId?: number;
  sysID:string;
  gaCategory:string;
 // opportunityOptOut: boolean = false;
  constructor() {
    this.engagementName = '';
    this.siteURL = '';
    this.siteUniqueID = '';
  }
}

export enum EngagementDialogTitles {
  CreateEng = 'Create engagement',
  CancelEng = 'Are you sure you want to leave this page?',
  CloseEng = 'Closing an engagement',
  SendClosure = 'Send engagement closure',
  RemoveAssignment = 'Remove assignment',
  SubmitAssignment = 'Submit assignment',
  UnsavedChangesConfirmation = 'You may have unsaved changes.',
  UnsavedChangesConfirmationIWantTo = 'You may have unsaved changes i want to.',
  SaveEngagement = 'Unable to save changes',
  ReEditQuestionnaire = 'Are you sure you want to update the terms and conditions?',
  EngagementClosed = 'Closed',
  UpdateInProgress = 'Questionnaire changes in progress',
  VoidEngagement = 'Void this engagement',
  ContinuanceEngExists = 'Continuance engagement located',
  RemoveExecutionDocument = "Delete document?",
  EditQuestionnaire = "Edit questionnaire?",
  ExportDocument = "Export document?",
  InterChangeableSolutionCodes = "Interchangeable solution code",
  AdministrativeClose = 'Are you sure you want to close this engagement for administrative purposes?',
  AbandonedEngagement = 'Are you sure you want to abandon this engagement?'
}
export enum EngagementStatus {
  EngNotStarted = 'Not started',
  EngDraft = 'Draft',
  EngInReview ='InReview',
  EngClosed = 'Closed',
  EngPendingCMSCode ='Pending CMS codes',
  EngActive ='Active',
  EngDeclined ='Declined',
  EngCmsCodeError = 'CMS code error',
  EngAbandoned = 'Abandoned',
  EngSiteError = 'Site Provisioning Error',
  EngRPRuleEngineError = 'RP rule engine error',
  EngActivePendingSignature = 'Active (pending signature)',
  EngActivePendingCMSCode = 'Active (pending CMS codes)',
  EngVoided = 'Voided'
}

export class EngagementStatusRequestModel 
     {        
        engagementId : number;
        status: string;
        updatedBy : string;
    }

    export enum AssignmentCodeStatus {
      Pending = 'Pending',
      OpenActive = 'Open/Active',
      RequestSent ='Request sent',
      Closed = 'Closed',
      ValidationError ='Validation error',
      Rejected ='Rejected',
      Failure ='Failure'
    }

    export enum DataModificationModuleNames {
      EditEngagement = 'Edit Engagement',
      Questionnaire = 'Edit Questionnaire',
      ChangeOrderQuestionnaire = 'Change Order Edit Questionnaire'
    }


    export class EngagementCollabData
    {
        engagementId: number;
        engagementName: string;
        collaborationSiteId: number;
        collaborationSiteName: string;
        isIndus : boolean;
        carryForwadINDUSConsent: boolean;
        siteRequestStatus: string = '';
    }

    export class UpdateQuestionnairePreCheckModel {
    
      currentStatus  : string;
      appliedTncCheck  : boolean;
      CollaborationSitesCount : number;

  }
    
export class EngagementClientVoiceViewModel {
  engagementId: number
  engagement_ChangedName: string;
  surveyDate: Date;
  isSurveyOptOut: boolean;
  optOutReason: string;
  loggedInUser: string;
  loggedInUserFullName: string;
  delegateName: string;
	delegateEmailId: string;
  sectionShortName: string;
  clientVoiceTeamMembers: ClientVoiceTeamMember[];  
}

export class EngagementClientVoiceUIViewModel {
  engagementId: number
  engagement_ChangedName: string;
  surveyDate: Date;
  isSurveyOptOut: boolean;
  optOutReason: string;
  loggedInUser: string;
  delegateName: string;
	delegateEmailId: string;
  clientVoiceTeamMembers: ClientVoiceTeamMember[];
  isValidDelegate: boolean = true;
  isValidoptOutReason: boolean = true;  
  optOutReasonSaved: boolean = false;
  isDelegateSaved: boolean = false;  
  isValidSurveyDate: boolean = true;
  isValidEngName: boolean = true;
  isValidRecipient: boolean = true;
  isSurveySaved: boolean = false;
  isloggedInUserSameAsEPPMD = false;
  isSurveyDateFutureDt: boolean = true;
  isDrpRecipientExistsInList: boolean = false;
  isDuplicateEmailExistsInList: boolean = false;
  isFirstOrLastNameBlankInList: boolean = false;
  isValidrecipientEmail:boolean = true;
  isValidrecipientCount:boolean = true;
}

export class EngagementClientVoiceDelegateViewModel {
  engagementId: number   
  delegateName: string;
	delegateEmailId: string;
  loggedInUser: string; 
  loggedInUserFullName: string;
  engagementName: string;
}

export class EngagementClientVoiceOptViewModel {
  engagementId: number   
  isSurveyOptOut: boolean;
  optOutReason: string;
  loggedInUser: string; 
  loggedInUserFullName: string;
  sectionShortName: string;
}

export class ClientVoiceTeamMember {
    fullName: string;
    firstName: string;
    lastName: string;
    memberEmail: string;
    memberTitle: string;
    contactGuid: string;
    editMemberEmail: string;
    editFirstName: string;
    editLastName: string;
    isRecipientsEdit: boolean = false;
    isValidMemberEmail: boolean = true;  
    isValidMemberEmailText: boolean = true;
    isEmailExistsInList: boolean = false;   
    isValidFirstName: boolean = true;
    isValidLastName: boolean = true;
    isRowInvalid:boolean = false;
}

export class ClientVoiceTeamViewMember {
  firstName: string;
  lastName: string;
  memberEmail: string;
  memberTitle: string;
  contactGuid: string;
  isValidFirstName: boolean = true;
  isValidLastName: boolean = true;
  isValidMemberEmail: boolean = true;  
  isValidMemberEmailText :boolean= true;
  isRecipientExistsInList:boolean = false;
}

export class UpdateEngagementCloseoutConfigurationModel {
  engagementId: number  
  isCompleted: boolean;
  sectionShortName: string;  
  loggedInUser: string;
  loggedInUserFullName: string;
}

export class UpdateworkItemVersionModel {
  engagementId: number;
  workItemId: string;
  workItemVersionsId: string;
  loggedInUser: string;
  loggedInUserFullName: string;
}

export enum ConsentResetDialogTitle {
  ResetClientConsent = 'Reset client consent options?'
}

export class MemberFirmEngagementPrefillData {
  memberFirmMappingId: number;
  memberFirmCountry: string;
  memberFirmADGroup: string;
  clientCode: string;
  clientId: number;
  clientName: string;
  solutionCodeId: number;
  solutionCode: string;
  solutionCodeDescription: string;
  epEmail: string;
  epName: string;
  aaName: string;
  aaEmail: string;
  engagementTypeId: string;
  engagementType: string;
  contingent: boolean;
  realization: number;
  netFees: number;
  isPeriodEndDateRequired: boolean;
  templatePackageId: string;
  templateName: string;
  isActive: boolean;
}

export class MemberFirmEngagementPrefillDataRequestModel {
  memberFirmADGroup: string;
}
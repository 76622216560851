import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/model/Alert';
import { PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { RetentionExtensionModel } from 'src/app/model/Engagement/RetentionExtentionModel';
import { CloseoutSections, EngagementCloseoutConfiguration } from 'src/app/model/EngagementListModel';
import { HDWorkItemDocumentModel, HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { HDSessionAnsToEngModel, HotDocsVariableMapping, HotDocsVariableMappingList, UpdateStatusAddHDAnsToEngage } from 'src/app/model/HotDocsVariableMapping';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { CloseoutChecklistWorkspaceModel } from 'src/app/model/Workspace/CloseoutChecklistWorkspaceModel';
import { WorkspaceDocumentModel } from 'src/app/model/WorkspaceDocumentModel';
import { QuestionnaireDialogTitles, WorkspaceInterviewsStatus, WorkspaceModel } from 'src/app/model/WorkspaceModel';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { DataService } from 'src/app/services/data.service';
import { EngagementService } from 'src/app/services/EngagementService';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { LogService } from 'src/app/services/log.service';
import { UpdateEngagementCloseoutConfigurationModel, UpdateworkItemVersionModel } from 'src/app/model/Engagement/Engagements';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { PrePopulateDetailsForQuestionnaire } from 'src/app/model/Engagement/PrePopulateDetailsForQuestionnaire';
import { CloseoutResponseModel } from 'src/app/model/Workspace/CloseoutResponseModel';

declare var HD$;
declare var $;

@Component({
  selector: 'app-engagement-closeout-quality-checklist',
  templateUrl: './engagement-closeout-quality-checklist.component.html',
  styleUrls: ['./engagement-closeout-quality-checklist.component.css']
})
export class EngagementCloseoutQualityChecklistComponent implements OnInit,OnDestroy {
  isImageEnabled: boolean = false;
  currentState: string = 'collapse';
  showRequiredWarning: boolean = false;
  @Input() set engagementId(engagementId: number) {
    if (engagementId != undefined && engagementId != null && engagementId > 0) {
      this.qualityCloseOutEngagementId = engagementId;
      console.log("selectEngagementId : ", this.qualityCloseOutEngagementId);
    }
  }

  @Input() set siteUniqueID(siteId: string) {
    if (siteId != null && siteId != undefined && siteId != '') {
      this.engSiteUniqueId = siteId;
    }
  }

  @Input() set qualityHDTemplatePackageId(templateId: string) {
    if (templateId != null && templateId != undefined && templateId != '') {
      this.qualityCloseoutHDTemplatePackageId = templateId;
    }
  }
  

  @Input() sectionConfiguration: EngagementCloseoutConfiguration;
  @Input() isEngagementClosed: boolean;
  @Input() solutionCode: string;
  @Output() collapseEvent: EventEmitter<string> = new EventEmitter<string>();
  isRequiredForFileCloseOut: boolean = true;
  qualityCloseoutInterviewOptions: any;
  IntegrationAPIEndPoint: string;
  qualityCloseoutInterviewList: InterviewModel;
  isFileCloseOutScreen: boolean = false;
  private modalFinishDialogRef: Subscription = null;
  qualityCloseOutEngagementId: number = 0;
  createWorkItemDetails: HDWorkItemModel = new HDWorkItemModel();
  addWorkspaceDocumentList: WorkspaceDocumentModel[] = [];
  workItemDetails: HDWorkItemModel = new HDWorkItemModel();
  workspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  engSiteUniqueId: string;
  currentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  alert: Alert;
  qualityCloseoutHDTemplatePackageId: string;
  interviewSavedSuccess: boolean = false;
  hdVariableMappingList: HotDocsVariableMappingList = new HotDocsVariableMappingList();
  hdVariableMappingData: HotDocsVariableMapping[] = [];
  hdSessionAnswersToEngage: HDSessionAnsToEngModel = new HDSessionAnsToEngModel();
  TemplatePackageDetails: TemplatePackageModel = new TemplatePackageModel();
  addUpdateStatusHDAnswers: UpdateStatusAddHDAnsToEngage = new UpdateStatusAddHDAnsToEngage();
  isRetentionExtensionRequired: boolean = true;
  noOfYearsExtention: number = 0
  yearsList: number[];
  retentionExtensionModel: RetentionExtensionModel = new RetentionExtensionModel();
  userObject: any;
  currentWorkItemVersionsId: string;
  closeoutResponce: CloseoutResponseModel = new CloseoutResponseModel();
  closeoutWorkspaceData: WorkspaceModel = new WorkspaceModel();
  @Input() secondarySolutionCode: string;
  @Input() descriptionPrimaryAssignmentCode: string;
  @Input() descriptionSecondaryAssignmentCode: string;
  @Input() periodEndDate: Date;
  constructor(private environment: AppConfigService,
    private loadingService: LoadingScreenService,
    private hdService: HotDocsService,
    private dataService: DataService,
    private workspaceService: WorkspaceService,
    private alertService: AlertsService,
    private engagementService: EngagementService,
    private logService: LogService
  ) {
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }



  ngOnInit(): void {
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));

    var isTaxQualityChecklistReloadedSession = sessionStorage.getItem("isTaxQualityChecklistReloaded");

    if (this.sectionConfiguration.isDefaultOpen) {
      this.toggleDown();
    } else if (isTaxQualityChecklistReloadedSession != null && isTaxQualityChecklistReloadedSession != undefined) {
      this.toggleUp(true);
    }
    this.PerformDialogAction();
  }

  toggleUp(flag: boolean) {
    this.CloseHDSession();

    var isTaxQualityChecklistReloadedSession = sessionStorage.getItem("isTaxQualityChecklistReloaded");
    if (isTaxQualityChecklistReloadedSession != null && isTaxQualityChecklistReloadedSession != undefined) {
      sessionStorage.removeItem('isTaxQualityChecklistReloaded');
    }
    else {
      sessionStorage.setItem('isTaxQualityChecklistReloaded', "true");
      this.hdService.RemoveHdTemplateVersionListInBrowserSession(); //Remove template version list from browser session to handle caching issue.
      HD$.ReattachSession();
    }

    this.isImageEnabled = true;
    this.currentState = 'expand';
    $('#collapseOne').show();
    if (!flag) {
      this.currentState = 'expand';
      this.collapseEvent.emit(this.currentState);
    }
    this.GetCloseoutChecklistWorkspaceData();
  }

  toggleDown() {
    this.CloseHDSession();
    this.isImageEnabled = false;
    this.currentState = 'collapse';
    $('#collapseOne').hide();
  }

  toggleUpDown(flag: boolean) {
    this.isImageEnabled = false;
    this.currentState = 'collapse';
    $('#collapseOne').hide();
  }

  ngOnDestroy() {
    this.CloseHDSession();
  }

  AttachInterviewSession() {
    this.qualityCloseoutInterviewOptions = {
      Container: 'hdEngDetailQuestionnaireDivForQuality',
      Theme: 'default.css',
      OnInit: () => {
        HD$.RegisterHandler('OnSessionComplete',
          (e) => {
            console.log('Finish Session called on OnSessionComplete complete handler');
            let xhr = new XMLHttpRequest();
            const url = this.IntegrationAPIEndPoint + '/Hotdocs/CompleteAssemblySession/' + this.currentWorkspaceData.hdWorkItemId;

            xhr.open('POST', url, true);

            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onreadystatechange = function () {

              if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log(this.status);
              }
            };
            xhr.send(JSON.stringify({ WorkItemId: this.currentWorkspaceData.hdWorkItemId }));
          });
        $('button.hd-nav-btn.hd-nav-finish.hd-nav-lff-3').css('display', 'none');

        HD$.RegisterHandler('OnHDStart',
          (eventArgs: any) => {
            HD$.SetVisibility('Toolbar', false);
            $('#questionnaireLoadSpinner').removeClass('spinner');
            $('.hd-dlg-title-cell').css('display', 'none');
            $('#hdNavBar').css('display', 'none');
          });

      },
      DisableAnswerSummary: true,
      TemplateTitleOverride: ' ',
      LeaveWarning: false,
      RequiredAsterisk: true,
    };

    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();
    var dateTime = date + ' ' + time;
    HD$.AttachSession(
      this.qualityCloseoutInterviewList.coreSessionId,
      this.qualityCloseoutInterviewList.serviceMetadataUrl + '?version=' + dateTime,
      this.qualityCloseoutInterviewOptions
    );
  }

  
GetInterviewsSession() {
    this.interviewSavedSuccess = false;
    let detail = new PrePopulateDetailsForQuestionnaire();
    detail.hdWorkItemId = this.currentWorkspaceData.hdWorkItemId;
    detail.primarySolutionCode = this.solutionCode;
    detail.secondarySolutionCode = this.secondarySolutionCode;
    detail.descriptionPrimaryAssignmentCode=this.descriptionPrimaryAssignmentCode;
    detail.descriptionSecondaryAssignmentCode=this.descriptionSecondaryAssignmentCode;
    detail.periodEndDate=this.periodEndDate;
    this.hdService.GetEngagementDetailQuestionnaire(detail).subscribe(
      result => {
        this.loadingService.stopLoading();
        this.qualityCloseoutInterviewList = result;
        this.currentWorkspaceData.hdWorkItemVersionId = result.hdaSessionId;
        this.currentWorkspaceData.hdCoreSessionId = result.coreSessionId;
        this.AttachInterviewSession();
      });
  }

  getWorkItemVersionsUpdateInEngage() {
    this.hdService.GetWorkItemVersions(this.currentWorkspaceData.hdWorkItemId).subscribe(
      result => {
        this.currentWorkItemVersionsId = result;
        if (this.currentWorkItemVersionsId)
          this.updateWorkItemVersions();

      },
      err => {
        this.DisplaySystemAlert();
      });
  }

  saveQualityCheckList() {
    $('button.hd-nav-btn.hd-nav-finish.hd-nav-lff-3').click();
    this.loadingService.startLoading();
    let uiDialog: any;
    let divTitleBar: any;
    let spanTitle: any;
    let uiDialogTitle = '';
    let closeoutStatus = '';

    setTimeout(() => {
      uiDialog = $('.ui-dialog');
      if (uiDialog.length == 1) {
        divTitleBar = uiDialog.children()[0];

      } else {
        const visibleDialog = $('.ui-dialog ').filter(function () {
          return $(this).css('display') !== 'none';
        });
        divTitleBar = visibleDialog.children()[0];
      }
      spanTitle = $(divTitleBar).children()[1];
      uiDialogTitle = $(spanTitle).html();
      if (uiDialogTitle === 'Input errors') {
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.setDialogContent('danger', QuestionnaireDialogTitles.InputErrors);
        closeoutStatus = this.CloseoutQnAStatus();
      } else if (uiDialogTitle === 'Unanswered questions') {
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.setDialogContent('warning', QuestionnaireDialogTitles.UnAnsQuestions);
        closeoutStatus = this.CloseoutQnAStatus();
      } else {
        this.interviewSavedSuccess = true;
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.loadingService.startLoading();
        closeoutStatus = this.CloseoutQnAStatus();
        setTimeout(() => {
          this.loadingService.stopLoading();
          this.GetAllHDVariableMapping();
        }, 2000);
      }
      this.insertCloseoutQualityChecklistQnALog(closeoutStatus);
      this.getWorkItemVersionsUpdateInEngage();
      this.updateEngagementCloseoutConfigurations();
    }, 2000);
  }

  setDialogContent(type: string, title: string) {
    this.isFileCloseOutScreen = true;
    let description: string;
    let button = 2;
    let bigTitle = false;
    switch (title) {
      case QuestionnaireDialogTitles.InputErrors:
        description = 'You must resolve one or more input errors before you can proceed.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.UnAnsQuestions:
        description = 'You have unanswered questions that may affect the review process. Click “OK” if you would like to proceed anyway. Click “Cancel” to return to the questionnaire.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      default:
        break;
    }

  }

  DisplayDialog(type: string, title: string, description: string
    , button: number, bigTitle: boolean) {
    const modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogInput: '',
      dialogBigTitle: bigTitle
    };
    const modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called ', type);
    this.dataService.displayDialogNotification(modalJSON);
  }
  processHDButton(btnName: string) {
    let i = 0;
    if (btnName === 'cancel') {
      i = 1;
    }
    const buttonSet = $('.ui-dialog').children().children()[4];
    if (buttonSet != null || buttonSet == undefined) {
      let okButton = $(buttonSet).children()[i];
      $(okButton).attr('id', 'btnok');
      $(okButton).click();
    }
  }

  PerformDialogAction() {
    this.modalFinishDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData && this.isFileCloseOutScreen) {
          const modal = JSON.parse(dialogData);
          const dialogTitle = modal.dialogTitle;
          const buttonName = modal.dialogButtonName;
          if (buttonName.length > 0 &&
            (dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions ||
              dialogTitle === QuestionnaireDialogTitles.InputErrors)) {
            this.isFileCloseOutScreen = false;
            this.processDialogAction(dialogTitle, buttonName);
          }
          console.log('Display Finish Dialog message in interviews screen ', dialogTitle);
        }
      });
  }
  processDialogAction(title: string, btnName: string) {
    switch (title) {
      case QuestionnaireDialogTitles.UnAnsQuestions:
        if (btnName === 'ok') {
          this.loadingService.startLoading();
          this.SaveAssemblySession();
        } else {
          this.processHDButton('cancel');
        }
        break;
      case QuestionnaireDialogTitles.InputErrors:
        if (btnName === 'ok') {
          this.processHDButton(btnName);
        }
        break;
      default:
        break;
    }

  }
  SaveAssemblySession() {
    setTimeout(() => {
      let buttonSet = $('.ui-dialog').children().children()[4];
      if (buttonSet != null || buttonSet == undefined) {
        let okButton = $(buttonSet).children()[0];
        $(okButton).attr('id', 'btnOk');
        $(okButton).click();
        setTimeout(() => {
          $('.ui-dialog-content .ui-widget-content').css('display', 'none');
          $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
          $('.ui-widget-overlay.ui-front').remove();
          this.processSaveSession();
          // delay time for answer processing then will close the session.
        }, 4000);
      }
    }, 1000);
  }
  processSaveSession() {
    this.currentWorkspaceData.status = WorkspaceInterviewsStatus.InProgressWorkspace;
    this.currentWorkspaceData.id = this.currentWorkspaceData.workspaceId;
    this.workspaceService.UpdateWorkspaceStatus(this.currentWorkspaceData).subscribe(result => {
      this.interviewSavedSuccess = true;
      setTimeout(() => {
        this.loadingService.stopLoading();
        this.GetAllHDVariableMapping();
      }, 5000);
    });
  }

  CloseHDSession() {
    HD$.DetachSession();
  }

  CreateWorkItem() {
    this.workspaceService.getMaxWorkspaceId().subscribe(
      result => {
        if (result <= 0) {
          result = 1;
        }
        this.createWorkItemDetails.workspaceCode = result + 4;
        this.createWorkItemDetails.siteUniqueId = this.engSiteUniqueId + '_QualityChecklist';
        this.createWorkItemDetails.templatePackageId = this.qualityCloseoutHDTemplatePackageId;
        this.ProcessWorkItemCreation();
      },
      error => {
        this.loadingService.stopLoading();
        this.DisplaySystemAlert();
      });

  }

  ProcessWorkItemCreation() {
    this.hdService.GetCreateWorkItemDocumentData(this.createWorkItemDetails).subscribe(
      workItemResult => {
        this.workItemDetails = workItemResult.hdWorkItem;
        this.workspaceData.hdWorkGroupId = this.workItemDetails.workGroupId;
        this.workspaceData.hdWorkItemId = this.workItemDetails.id;
        this.workspaceData.hdTemplatePackageId = this.workItemDetails.templatePackageId;
        this.workspaceData.hdCurrentTempVersion = this.workItemDetails.templateCurrentVersion;
        this.workspaceData.status = WorkspaceInterviewsStatus.DraftSession;
        this.workspaceData.hdTemplatePackageVersionId = this.workItemDetails.templatePackageVersionId;

        this.getDocumentListToPreview(workItemResult);
      },
      error => {
        this.loadingService.stopLoading();
        this.DisplaySystemAlert();
      });

  }

  getDocumentListToPreview(workItemResult: HDWorkItemDocumentModel) {
    if (workItemResult.hdWorkItem.id != null) {
      this.addWorkspaceDocumentList = [];
      var DocumentList = new WorkspaceDocumentModel();
      workItemResult.hdWorkspaceDocumentList.forEach(x => {
        DocumentList.hdDocumentId = x.documentId;
        DocumentList.hdDocumentName = x.name;
        DocumentList.hdMimeType = x.mimeType;
        DocumentList.hdAssemblySession = x.assemblySessionId;
        DocumentList.isActive = true;
        DocumentList.hdTemplatePackageVersionId = this.workspaceData.hdTemplatePackageVersionId;
        this.addWorkspaceDocumentList.push(DocumentList);
      });
      this.CreateWorkspaceInDB();
      //this.GetInterviewsSession();
    }
  }
  CreateWorkspaceInDB() {
    let closeoutCheckListWorkspace: CloseoutChecklistWorkspaceModel = new CloseoutChecklistWorkspaceModel();
    closeoutCheckListWorkspace.engagementId = this.qualityCloseOutEngagementId;
    closeoutCheckListWorkspace.workspaceData = this.workspaceData;
    closeoutCheckListWorkspace.workspaceDocuments = this.addWorkspaceDocumentList;
    closeoutCheckListWorkspace.isCloseOutChecklist = true;
    closeoutCheckListWorkspace.code=CloseoutSections.QC;
    this.workspaceService.InsertCloseoutChecklistWorkspace(closeoutCheckListWorkspace).subscribe(
      result => {
        this.GetCloseoutChecklistWorkspaceData();
      },
      error => {
        this.loadingService.stopLoading();
        this.DisplaySystemAlert();
      });
  }
  
  GetCloseoutChecklistWorkspaceData() {
    if (this.qualityCloseoutHDTemplatePackageId != null &&
      this.qualityCloseoutHDTemplatePackageId != undefined &&
      this.qualityCloseoutHDTemplatePackageId != '') {
      this.loadingService.startLoading();
      this.workspaceService.GetCloseoutChecklistWorkspaceData(this.qualityCloseOutEngagementId,CloseoutSections.QC).subscribe(fileCloseoutWorkspace => {
        this.currentWorkspaceData = fileCloseoutWorkspace;
        if (this.currentWorkspaceData != null &&
          this.currentWorkspaceData.hdWorkItemId != null &&
          this.currentWorkspaceData.hdWorkItemId != undefined &&
          this.currentWorkspaceData.hdWorkItemId != '') {
          if (!this.isEngagementClosed) {
            this.GetInterviewsSession();
          } else { this.loadingService.stopLoading(); }
        } else {
          this.CreateWorkItem();
        }
      });
    }
  }
 
  DisplaySystemAlert() {
    this.alertService.remove(this.alert);
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  GetAllHDVariableMapping() {
    this.loadingService.startLoading();
    this.engagementService.GetAllHDVariableMapping(this.currentWorkspaceData.hdTemplatePackageId).subscribe(mappingData => {
      if (mappingData.length > 0) {
        this.hdVariableMappingData = mappingData;
        this.hdVariableMappingData.forEach(x => x.engagementId = this.currentWorkspaceData.engagementId);
        this.hdVariableMappingData.forEach(x => x.workItemId = this.currentWorkspaceData.hdWorkItemId);
        this.GetHDAnswersToVariableMapping();
      } else {

        this.hdVariableMappingData = [];
        this.toggleDown();
        this.loadingService.stopLoading();
      }
    },
      error => {
        console.error('Error in GetAllHDVariableMapping ', error);
        this.DisplaySystemAlert();
        this.loadingService.stopLoading();
      });
  }

  GetHDAnswersToVariableMapping() {
    this.hdVariableMappingList.hDVariableMappingList = this.hdVariableMappingData;
    this.hdVariableMappingList.workItemId = this.currentWorkspaceData.hdWorkItemId;
    this.hdVariableMappingList.engagementId = this.engagementId;
    this.hdService.GetHDAnswersToEngage(this.hdVariableMappingList).subscribe(hdAnsData => {
      if (hdAnsData != null) {
        this.hdSessionAnswersToEngage = hdAnsData;
        if (this.hdSessionAnswersToEngage.hdAnstoEngList == null ||
          this.hdSessionAnswersToEngage.hdAnstoEngList == undefined ||
          this.hdSessionAnswersToEngage.hdAnstoEngList.length <= 0) {
          this.hdSessionAnswersToEngage.hdAnstoEngList = [];
          this.toggleDown();
          this.loadingService.stopLoading();
        }
        else {
          this.UpdateWorkspaceInDB();
        }
      }
    },
      error => {
        this.DisplaySystemAlert();
        this.loadingService.stopLoading();
      });
  }

  UpdateWorkspaceInDB() {
    this.loadingService.startLoading();
    setTimeout(() => {
      this.addUpdateStatusHDAnswers.hDAnswersToEngage = this.hdSessionAnswersToEngage.hdAnstoEngList;
      this.currentWorkspaceData.hdTemplatePackageVersionId = this.hdSessionAnswersToEngage.templatePackageVersionId;
      this.currentWorkspaceData.hdWorkItemVersionId = this.hdSessionAnswersToEngage.workItemVersionId;
      this.addUpdateStatusHDAnswers.hdDocumentId = this.hdSessionAnswersToEngage.documentId;
      this.addUpdateStatusHDAnswers.hdDocumentTitle = this.hdSessionAnswersToEngage.title;
      this.addUpdateStatusHDAnswers.workspace = this.currentWorkspaceData;
      this.workspaceService.AddUpdateStatusHDAnsToEngageFileCloseout(this.addUpdateStatusHDAnswers).subscribe(result => {
        console.log(result);
        this.loadingService.stopLoading();
        this.toggleDown();
      }, error => {
        this.DisplaySystemAlert();
        this.loadingService.stopLoading();
      });
    }, 3000);
  }

  onIsRetentionExtensionRequiredClick(selectedValue: string) {
    // alert(selectedValue);
    if (selectedValue === "Yes") {
      this.isRetentionExtensionRequired = true;
    }
    else if (selectedValue === "No") {
      this.isRetentionExtensionRequired = false;
    }
  }

  updateEngagementCloseoutConfigurations() {
    let unansweredResultSet = HD$;
    let unansweredQnACount: number = 0;
    let isCompleted: boolean = false;
    if (unansweredResultSet == undefined && unansweredResultSet == null) {
      isCompleted = false
    } else { // else Check unanswered count and process action
      unansweredResultSet = HD$.GetProgress();
      unansweredQnACount = unansweredResultSet.Total - unansweredResultSet.Answered;
      if (unansweredQnACount > 0) {
        isCompleted = false;
      } else {
        isCompleted = true;
      }
    }

    this.loadingService.startLoading();
    let updateEngCloseoutConfigModel: UpdateEngagementCloseoutConfigurationModel = new UpdateEngagementCloseoutConfigurationModel();
    updateEngCloseoutConfigModel.engagementId = this.qualityCloseOutEngagementId;
    updateEngCloseoutConfigModel.isCompleted = isCompleted;
    updateEngCloseoutConfigModel.sectionShortName = this.sectionConfiguration.code;
    updateEngCloseoutConfigModel.loggedInUser = this.userObject.loggedInUserEmail;
    updateEngCloseoutConfigModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engagementService.UpdateEngagementCloseoutConfigurations(updateEngCloseoutConfigModel).subscribe(
      updateEngResult => {
        if (updateEngResult === 'Success') {
          console.log('updateEngagementCloseoutConfigurations save successfully');
          this.getEngagementCloseoutConfigurations();
          this.loadingService.stopLoading();
        }
      },
      error => {
        this.loadingService.stopLoading();
        const errorMsg = 'Error while updateEngagementCloseoutConfigurations for section : ' + this.sectionConfiguration.value;
        console.error(errorMsg, error);
        this.logService.log(errorMsg);
      });
  }


  updateWorkItemVersions() {
    this.loadingService.startLoading();
    let updateworkItemVersionModel: UpdateworkItemVersionModel = new UpdateworkItemVersionModel();
    updateworkItemVersionModel.engagementId = this.qualityCloseOutEngagementId;
    updateworkItemVersionModel.workItemId = this.currentWorkspaceData.hdWorkItemId;
    updateworkItemVersionModel.workItemVersionsId = this.currentWorkItemVersionsId;
    updateworkItemVersionModel.loggedInUser = this.userObject.loggedInUserEmail;
    updateworkItemVersionModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engagementService.UpdateWorkItemVersion(updateworkItemVersionModel).subscribe(
      updateworkItemVersionResult => {
        if (updateworkItemVersionResult === 'Success') {
          console.log('UpdateWorkItemVersions save successfully');
          this.loadingService.stopLoading();
        }
      },
      error => {
        this.loadingService.stopLoading();
        const errorMsg = 'Error while UpdateWorkItemVersions for section : ' + this.sectionConfiguration.value;
        console.error(errorMsg, error);
        this.logService.log(errorMsg);
      });
  }

  getEngagementCloseoutConfigurations() {
    this.engagementService.GetEngagementCloseoutConfigurations(this.qualityCloseOutEngagementId).subscribe(engagementCloseoutConfigurations => {
      if (engagementCloseoutConfigurations && engagementCloseoutConfigurations.length > 0) {
        this.sectionConfiguration = engagementCloseoutConfigurations.find(section =>
          section.code === CloseoutSections.QC);
        //this.showRequiredWarning = !this.sectionConfiguration.isCompleted;
        if (this.sectionConfiguration.isCompleted) {
          this.showRequiredWarning = false;
        }
      }
    },
      err => {
        console.error(err);
      });
  }

  insertCloseoutQualityChecklistQnALog(closeoutStatus: string) {
    let totalQuestions = HD$;
    let unansweredQuestions: number = 0;
    totalQuestions = HD$.GetProgress();
    unansweredQuestions = totalQuestions.Total - totalQuestions.Answered;
    this.workspaceService.GetCloseoutChecklistWorkspaceData(this.qualityCloseOutEngagementId,CloseoutSections.QC)
      .subscribe(fileCloseoutQnAWorkspace => {
        this.closeoutWorkspaceData = fileCloseoutQnAWorkspace;
        console.log('Closeout workspace data:' + this.closeoutWorkspaceData);
        if (this.closeoutWorkspaceData != null &&
          this.closeoutWorkspaceData.hdWorkItemId != null &&
          this.closeoutWorkspaceData.hdWorkItemId != undefined &&
          this.closeoutWorkspaceData.hdWorkItemId != '') {
          this.closeoutResponce.EngagementId = this.closeoutWorkspaceData.engagementId;
          this.closeoutResponce.HDWorkItemId = this.closeoutWorkspaceData.hdWorkItemId;
          this.closeoutResponce.TemplatePackageId = this.closeoutWorkspaceData.hdTemplatePackageId;
          this.closeoutResponce.TemplatePackageVersionId = this.closeoutWorkspaceData.hdTemplatePackageVersionId;
          this.closeoutResponce.TemplateVersion = this.closeoutWorkspaceData.hdCurrentTempVersion;
          this.closeoutResponce.TemplateName = this.closeoutWorkspaceData.hdTemplateName;
          this.closeoutResponce.ResponseHTML = HD$.GetAnswerSummary();
          this.closeoutResponce.CreatedBy = this.userObject.loggedInUserEmail;
          this.closeoutResponce.CloseoutStatus = closeoutStatus;
          this.closeoutResponce.Total = totalQuestions.Total;
          this.closeoutResponce.Answered = totalQuestions.Answered;
          this.closeoutResponce.Unanswered = unansweredQuestions;
          this.workspaceService.StoreCloseoutQualityCheckListResponseLog(this.closeoutResponce).subscribe(Response => {
            console.log('Closeout QnA insert responce: ' + Response);
          });
        }
      });
  }

  CloseoutQnAStatus() {
    let unansweredResultSet = HD$;
    let unansweredQnACount: number = 0;
    let completionStatus: string;
    // Check unanswered count and process action
    unansweredResultSet = HD$.GetProgress();
    unansweredQnACount = unansweredResultSet.Total - unansweredResultSet.Answered;
    if (unansweredQnACount > 0 && unansweredQnACount < unansweredResultSet.Total) {
      completionStatus = 'Started';
    } else if (unansweredQnACount == 0) {
      completionStatus = 'Complete';
    } else {
      completionStatus = 'NotStarted';
    }
    return completionStatus;
  }
}

import { Component, OnInit } from '@angular/core';
import { navigationIds, NavigationIdWithGreyImageNames, NavigationIdWithPurpleImageNames } from '../leftnav/leftnav.component';
declare var $;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  UserObject: any;
  isSmallDevice = false;
  screenWidth: number;
  screenHeight: number;
  replacedImageBasePath = '/assets/images/EDP/LeftSidebar/purple/';
  originalImageBasePath = '/assets/images/EDP/LeftSidebar/grey/';
  imageType = '.svg';
  originalImagePath = '';
  newImagePath = '';
  originalImgClass = 'imgchange';
  newImgClass = 'imgReplace';

  constructor() {
    const jsonString = localStorage.getItem('currentUser');
    this.UserObject = JSON.parse(jsonString);

  }

  ngOnInit() {
    this.screenWidth = window.screen.width;
    this.screenHeight = window.screen.height;
    if ((this.screenWidth >= 767 && this.screenWidth <= 1300) && (this.screenHeight >= 767 && this.screenHeight <= 1300)) {
      this.isSmallDevice = true;
    }
    setTimeout(() => {
      $('.list-group-item').click(function () {
        var menuitems = $('.nav-tabs> #sideMenuItem');
        var childMenu = $(menuitems).children();
        $(childMenu).each(function (i, obj) {
          if ($(obj).hasClass('active')) {
            $(obj).removeClass('active');
          }
        });
      });
    }, 50);
  }

  RemoveLeftNavActiveTabs(navItem: string){
    $('.list-group-item').click(function () {
      var menuitems = $('.nav-tabs> #sideMenuItem');
      var childMenu = $(menuitems).children();
      
      $(childMenu).each(function (i, obj) {
        if ($(obj).hasClass('active')) {
          $(obj).removeClass('active');
        }
      });
    });
        this.originalImagePath = this.originalImageBasePath + NavigationIdWithGreyImageNames.Home + this.imageType;
        $('.' + this.newImgClass ).attr('src', this.originalImagePath);
        this.addActiveMenuStyle(this.newImgClass, navItem)
    }
    addActiveMenuStyle(replacedImgClass: string, navItem: string) {
      var _newImagePath = this.newImagePath;
      var _newImageBasePath = this.replacedImageBasePath;
      var _imageType = this.imageType;
  
      var menuitems = $('.nav-tabs> #sideMenuItem');
      var childMenu = $(menuitems).children();
      $(childMenu).each(function (i, obj) {
          
          var anchoredImg = $(obj).find('img');
          if ($(anchoredImg).hasClass(replacedImgClass)) {
            $(anchoredImg).removeClass(replacedImgClass);
            // replace it with original image.
            
          }
          var prevMenuItemHref = $(obj).attr('href');
  
            if (prevMenuItemHref.toLowerCase().includes(navItem.toLowerCase())) {
              $(anchoredImg).addClass(replacedImgClass);
              if(navItem.toLowerCase().includes(navigationIds.MyClients.toLowerCase()))
              _newImagePath = _newImageBasePath + NavigationIdWithPurpleImageNames.MyClients + _imageType;
              else if(navItem.toLowerCase().includes(navigationIds.MyEngagements.toLowerCase()))
              _newImagePath = _newImageBasePath + NavigationIdWithPurpleImageNames.MyEngagements + _imageType;

              $(anchoredImg).attr('src', _newImagePath);

              }
            if($(obj).attr('href') == '/'+navItem){
              $(obj).addClass('active');
            }
      });
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../services/clientservice';
import { Clients } from '../../model/clients';
import { ActionTiles } from '../../model/actionTiles';
import '../clientsummary/client-summary.js';
import { DataService } from 'src/app/services/data.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

declare var $;

@Component({
  selector: 'app-clientsummary',
  templateUrl: './clientsummary.component.html',
  styleUrls: ['./clientsummary.component.css']
})
export class ClientsummaryComponent implements OnInit {

  components = [];

  currentScreenName: string;
  isQualityUserLoggedIn: string = 'no';

  constructor(private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private loadingScreenService: LoadingScreenService
  ) {
    const IShortcutCount = localStorage.getItem('iShortcutCount');
    this.iShortcutCount = JSON.parse(IShortcutCount);
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
  }
  iShortcutCount: number;
  isloggedInUserAdmin: string;
  client: Clients;
  actionTiles: ActionTiles;
  clientId: number = 0;
  selectedKRPClientId: number;
  isAuditFlag: boolean = false;
  isAdvisoryFlag: boolean = false;
  isTaxFlag: boolean = false;
  timerId: any;
  lastRedirectedFromCreateClient: string;
  IwantToHeading: string;
  lastIwantToHeading: string;
  currentClientId: number;
  audit: string;
  tax: string;
  userObject: any;
  advisory: string;
  selectContactEmailId: string;
  applyFilterOnceAfterDataLoad: boolean;
  selectedPopoverId: string;
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.clientId = +params.get('clientId');
      $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
      $('.ui-widget-overlay.ui-front').remove();
    });
    this.dataService.lastRedirectedFromCreateClient.subscribe(returnedFromCreateClient =>
      this.lastRedirectedFromCreateClient = returnedFromCreateClient);
    this.dataService.currentIwantToHeading.subscribe(heading => this.lastIwantToHeading = heading);

    // set current route Url as NewClient returnUrl for Client/Enagement Actions.
    this.dataService.setReturnUrlForCreateClient(this.router.url);
    $('body').click(function () {
      $('#iWantToMenu').removeClass('setiWantToMenuPosition');
      $('#iWantToMenu').removeClass('show');
    });
    if (this.lastRedirectedFromCreateClient === 'true') {
      $('#InitiateNewEng').modal('show');
      this.IwantToHeading = this.lastIwantToHeading;
    }
    if (this.clientId != 0) {
      this.dataService.setClientIdForPrePopulate(this.clientId);
      this.getClientInfo(this.clientId);
      this.getActionTiles();
    }
    var clientRPPopup = $("#popover-content-ParternerDetails");
    clientRPPopup.hide();
    this.dataService.setIsRedirectedFromCreateClient('false');
    this.currentScreenName = "ClientSummary";
    this.IsLoggedinUserSystemAdmin();
  }

  createContactPopupDiv(controlIdText: string) {
    $('#' + controlIdText).popover({
      html: true,
      placement: 'top',
      tigger: 'manual',
      viewport: { selector: 'body', padding: 0 },
      content: function () {
        console.log('BindContactPopup func called ', $('#contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  createBusinessClientContactPopupDiv(controlIdText: string) {
    $('#' + controlIdText).popover({
      html: true,
      placement: 'top',
      tigger: 'manual',
      viewport: { selector: 'body', padding: 0 },
      content: function () {
        console.log('BindContactPopup func called ', $('#client-contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  BindContactPopup() {
    this.createContactPopupDiv('relationshipPartnerNamePopup');
    this.createContactPopupDiv('cmsAdminNamePopup');
  }

  /*
  ngAfterViewChecked() {
    console.log('inside ', this.applyFilterOnceAfterDataLoad);
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.BindContactPopup();
      this.applyFilterOnceAfterDataLoad = false;
    }
  }
  */


  DisplayContactPopup(popoverId: string, email: string) {
    this.selectContactEmailId = email;
    this.selectedPopoverId = popoverId;
    this.hideAllPopUp();
    this.checkForRelationshipPartnerNameContent('contact-popup');
    $('#' + this.selectedPopoverId).popover('show');
  }

  hideAllPopUp() {
    $('#relationshipPartnerNamePopup').popover('hide');
    $('#cmsAdminNamePopup').popover('hide');
  }

  checkForBusinessClientContactContent(contact) {
    var timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForBusinessClientContactContent(contact);
      }
    }, 2000);
  }

  ngAfterViewInit() {
    this.closeContactPopupCardView();
  }

  closeContactPopupCardView() {
    $('html').on('click', (e) => {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        $('#' + this.selectedPopoverId).popover('hide');
      }
    });
    $('body').on('click', (e) => {
      const target = e.target;
      const parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          $('#' + this.selectedPopoverId).popover('hide');
        }
      }
    });
  }

  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isloggedInUserAdmin = 'yes';
          break;
        }
      }
      //check logged in user belongs to quality group
      for (let e of this.userObject.role) {
        if (e.shortRoleName === 'QG') {
          this.isQualityUserLoggedIn = 'yes';
          console.log('isQualityUserLoggedI => ', this.isQualityUserLoggedIn);
          break;
        }
      }
    }
  }

  checkForRelationshipPartnerNameContent(contact) {
    var timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForRelationshipPartnerNameContent(contact);
      }
    }, 2000);
  }

  getClientInfo(clientId: number) {
    this.loadingScreenService.startLoading();
    return this.clientService.getClientInfo(clientId)
      .subscribe(
        result => {
          this.client = result;

          let arr = result.serviceLineList.split(',');
          this.audit = "Audit";
          this.tax = "Tax";
          this.advisory = "Advisory";
          if ((arr[2] == "NotDefined") || (arr[0] == "" && arr[1] == "" && arr[2] == "")) {
            this.isAdvisoryFlag = false;
            this.isTaxFlag = false;
            this.isAuditFlag = false;
          }
          else if (arr[0] != "" && arr[1] != "" && arr[2] != "") {
            this.isAdvisoryFlag = true;
            this.isTaxFlag = true;
            this.isAuditFlag = true;
          }
          else if (arr[0] == "" && arr[1] != "" && arr[2] != "") {
            this.isAdvisoryFlag = true;
            this.isTaxFlag = true;
            this.isAuditFlag = false;
          }
          else if (arr[0] != "" && arr[1] == "" && arr[2] != "") {
            this.isAdvisoryFlag = true;
            this.isTaxFlag = false;
            this.isAuditFlag = true;
          }
          else if (arr[0] != "" && arr[1] != "" && arr[2] == "") {
            this.isAdvisoryFlag = false;
            this.isTaxFlag = true;
            this.isAuditFlag = true;
          }
          else if (arr[0] != "" && arr[1] == "" && arr[2] == "") {
            this.isAdvisoryFlag = false;
            this.isTaxFlag = false;
            this.isAuditFlag = true;
          }
          else if (arr[0] == "" && arr[1] != "" && arr[2] == "") {
            this.isAdvisoryFlag = false;
            this.isTaxFlag = true;
            this.isAuditFlag = false;
          }
          else if (arr[0] == "" && arr[1] == "" && arr[2] != "") {
            this.isAdvisoryFlag = true;
            this.isTaxFlag = false;
            this.isAuditFlag = false;
          }
          setTimeout(() => {
            this.BindContactPopup();
            this.setClientId();
            this.loadingScreenService.stopLoading();
          }, 500);
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.client = null;
        }
      );
  }

  getActionTiles() {
    return this.clientService.getActionTiles()
      .subscribe(
        result => {
          this.actionTiles = result
        },
        error => {
          this.client = null;
        }
      );
  }

  setKRPClientId(clientId: number) {
    this.selectedKRPClientId = clientId;
  }

  removeKRPClient() {
    this.selectedKRPClientId = null;
  }

  showIWantToAction(event) {
    if (event.currentTarget.innerText == 'Create New Engagement') {
      this.router.navigate(['NewEngagement']);
    }
    else {
      this.router.navigate(['/IWantTo/' + event.currentTarget.innerText]);
    }
  }

  showActionContent(currentactionTile: string) {
    $('#clientUl').children().removeClass('activeTab');
    $("a[href*='" + currentactionTile + "']").parent().addClass('activeTab');
  }

  setClientId() {
    this.currentClientId = this.clientId;
  }

  BindPopup() {
    $(".showClientWorkspacejs").click(function () {
      $('#ShowClientWorkspace').modal('show');
    });
  }

  hideWorkspace() {
    $('.showClientWorkspacejs').flyout('hide');
  }

  EditClient() {
    this.router.navigate(['/EditClient/', this.clientId]);
  }

  OpenClientWebsite(url: string) {
    if (url != null && url != undefined && url != '') {
      if (url.includes('http') && url.substr(0, 10).includes("//")) {
        window.open(url, '_blank');
      }
      else {
        window.open('//' + url, '_blank');
      }
    }

  }

  showChildLoader(isLoading: string) {
    console.log('===> Client Detail ', isLoading, isLoading === 'true');
    if (isLoading === 'true') {
      this.loadingScreenService.startLoading();
    } else {
      this.loadingScreenService.stopLoading();
    }
  }
  ResetIwantToUnsavedFlag()
  {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setUnsavedChangesModel(dialogJSON);
    this.dataService.setClientIdForPrePopulate(-1);
    this.dataService.setEngagementIdForPrePopulate(-1);
  }
  ngOnDestroy()
  {
    this.ResetIwantToUnsavedFlag();
  }

}

export class engagements {
    engagementId: number;
    engagementName: string;
    clientName: string;
    clientCode: number;
    assignmentCode: string;
    solutionCode: string;
    EP: string;
    lastActivity: string;
    lastModified: string;
    FYE: string;
}
export class RPPermissionModel {
    rpApprovers: string[] = [];
    primarySiteUniqueId: string;
}
export class ExecutionDocumentEnagments {
    engagementId: number;
    engagementName: string;
    siteUniqueID:string; 
    eppmd:string;
    primarySolutionCode: string;
}

export class UpdateINDUSFlag{
    engagementId: number;
    isIndus: boolean;
    lastModifiedBy: string;
}

export class IndusFlags{
    oldIndusFlag: boolean;
    currentIndusFlag: boolean;
}
export class DownloadTemplateEnagments {
    engagementId: number;
    engagementName: string;
    siteUniqueID:string; 
    eppmd:string;
    primarySolutionCode:string;
}

export class EngagementAdministrativeCloseModel{
    engagementId: number;
    administrativeName: string;
    administrativeEmail: string;
}
import { IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AppConfigService } from "../../appConfigService";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function MSALInstanceFactory(configService: AppConfigService): IPublicClientApplication {
   
    const config = JSON.parse(configService.loadAppConfig());
 
    return new PublicClientApplication({
        auth: {
            clientId: config.DATA.AZURE_AD_AUTHENTICATION.CLIENT_ID,
            authority: `https://login.microsoftonline.com/${config.DATA.AZURE_AD_AUTHENTICATION.TENANT}`,
            redirectUri :  config.DATA.AZURE_AD_AUTHENTICATION.LOGIN_REDIRECT_URL,
            navigateToLoginRequestUrl: true
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE,  // set to true for IE 11
        }
    });
}

<div class="new-document-dialog-wrapper" *ngIf="isHidden">
  <div class="new-document-dialog-container">
    <div class="close-dialog">
      <div class="d-inline-block ml-1 align-top">
        <button class="btn btn-link btn-sm py-0" (click)="closeDialog($event)">
          Close
        </button>
      </div>
      <span class="d-inline-block ml-1 align-top">
        <img src="../../../../assets/images/EDP/MessageDialog/close-24px.svg" alt="Close" (click)="closeDialog($event)">
      </span>
    </div>
    <div class="row m-0 p-0 pb-2">
      <div class="col-md-12 col-xl-12 pl-0 pr-0">
        <label>I want to...</label>
        <h6 style="color:#433434;"><strong> Create a new document</strong></h6>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Client name </span> <span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
            <select class="dropdown rounded-0" id="ddClients" name="Clients" [(ngModel)]="clientId"
              (change)="onClientsChange()">
              <option [ngValue]="-1">Select a client name or client code</option>
              <option *ngFor="let clients of clientkeyValueList" [ngValue]=clients.key>
                {{clients.value}}
              </option>
            </select>
          </div>
          <!-- <app-auto-complete-dropdown [MappedSource]="MappedSourceData.clients" [NoRecordFoundFor]="false"></app-auto-complete-dropdown> -->
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="isClientSelected">
          <span>Client is a required field.</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Engagement name </span> <span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
            <select class="dropdown rounded-0" id="ddEngagements" name="Engagements" [(ngModel)]="engagementId"
              (change)="onEngagementsChange()">
              <option [ngValue]="-1">Select an engagement name</option>
              <option *ngFor="let engagements of engagementkeyValueList" [ngValue]=engagements.key>
                {{engagements.value}}
              </option>
            </select>
          </div>
          <!-- <app-auto-complete-dropdown [MappedSource]="MappedSourceData.engagement"
            [NoRecordFoundFor]="false"></app-auto-complete-dropdown> -->
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="isEngagementSelected">
          <span>Engagement name is a required field.</span>
        </div>
        <div id="mandatoryMessageForDocumentLimit" class="form-control-feedback error-color"
          *ngIf="isAddDocumentLimitExceeded">
          <span>This engagement has reached the maximum number of additional documents</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Document type </span> <span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <div class="selectdiv">
            <select class="dropdown rounded-0" id="ddDocuments" name="Documents"
              [(ngModel)]="addWorkspace_SelectedData.templatePackageId" (change)="onDocumentChange()"
              [disabled]="isDisableDocumentType">
              <option value="-1">{{documentTypeText}}</option>
              <option *ngFor="let documents of DocumentkeyValueList" [value]=documents.key>
                {{documents.value}}
              </option>
            </select>
          </div>
          <!-- <app-auto-complete-dropdown [MappedSource]="MappedSourceData.documentType"
            [NoRecordFoundFor]="false"></app-auto-complete-dropdown> -->
        </div>
        <div id="errorMessageForAdditionalDocument" class="form-control-feedback error-color"
          *ngIf="isDocumentTypeExist">
          <span>Additional documents are not available for the selected solution code</span>
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="addWorkspace_SelectedData.IsTemplateSelected">
          <span>Document template is a required field.</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-xl-12 col-md-12 pt-2 pl-0 pr-0">
        <label><span class="label">Document name </span> <span class="label mandatory">*</span></label>
        <div class="input-group mw-100">
          <input type="text" id="txtdocumentName"
            class="edp-font-14 form-control rounded-0 border document-alias-name input-height" maxlength="50"
            placeholder="{{newDocumentPlaceholderText}}" (focus)="this.newDocumentPlaceholderText=''"
            (blur)="this.newDocumentPlaceholderText = 'Enter document name...'" autocomplete="off" [(ngModel)]="documentName"
            [disabled]="isDisableDocumentName" />
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
          *ngIf="addWorkspace_SelectedData.IsDocumentAliasNameEmpty;else specialCharactersFound">
          <span>Document name is a required field. </span>
        </div>

        <ng-template #specialCharactersFound>
          <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
            *ngIf="isSpecialCharactesFound;else duplicateTabName">
            <span>Document name has special characters.</span>
          </div>
        </ng-template>
        <ng-template #duplicateTabName>
          <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
            *ngIf="addWorkspace_SelectedData.IsDocumentAliasNameDuplicate">
            <span>A document with the same name already exists.</span>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">&nbsp;</div>
    </div>
    <div class="d-flex justify-content-end px-0">
      <div>
        <button (click)="Cancel()" class="btn SecondaryButton rounded-0 information mr-4">
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button (click)="validateData()" class="btn rounded-0 information" [disabled]="isDisabled">
          <span class="d-inline-block align-middle">Create</span>
        </button>
      </div>
    </div>
  </div><!-- modal-dialog -->
</div>
<!--modal -->
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable,forkJoin } from 'rxjs';
import { OneViewClientDetailModel } from '../model/Client/OneViewClientDetail';
import { LoadingScreenService } from './loading-screen.service';
@Injectable()
export class DataService {

  private cardTitleSource = new BehaviorSubject('New Card Title');
  private workspaceId = new BehaviorSubject('WorkspaceId');
  private workItemId = new BehaviorSubject('WorkItemId');
  private roleInfoSource = new BehaviorSubject('');

  private data: any = undefined;
  //private isRedirectedFromNewDoc =false;
  private oneViewClientDetailModel: OneViewClientDetailModel;
  private isRedirectedFromNewEngagement = new BehaviorSubject('false');
  private clientIdForEngagement = new BehaviorSubject('clientId');
  private IwantToHeadingForClient = new BehaviorSubject('IwantToHeading');
  private isRedirectedFromCreateClient = new BehaviorSubject('false');
  private returnUrlForCreateClient = new BehaviorSubject('returnUrl');
  private clientIdForPrePopulate = new BehaviorSubject(0);
  private engagementIdForPrePopulate = new BehaviorSubject(0);
  private unsavedChangesCheck = new BehaviorSubject('');
  private loadingScreenService=new LoadingScreenService();

  currentCardTitle = this.cardTitleSource.asObservable();
  CurrentWorkspaceId = this.workspaceId.asObservable();
  currentWorkItemId = this.workItemId.asObservable();
  isRedirectedFrom = this.isRedirectedFromNewEngagement.asObservable();
  currentClientId = this.clientIdForEngagement.asObservable();
  currentClientIdForPrePopulate = this.clientIdForPrePopulate.asObservable();
  currentEngagementIdForPrePopulate = this.engagementIdForPrePopulate.asObservable();
  checkForUnsavedChanges = this.unsavedChangesCheck.asObservable();

  currentRoleInfo = this.roleInfoSource.asObservable();
  currentIwantToHeading = this.IwantToHeadingForClient.asObservable();
  lastRedirectedFromCreateClient = this.isRedirectedFromCreateClient.asObservable();
  lastPageUrl = this.returnUrlForCreateClient.asObservable();
  private isRedirectedFromInitiateReacceptance = new BehaviorSubject('false');
  isRedirectedFromReacceptance = this.isRedirectedFromInitiateReacceptance.asObservable();

  private clientNameForEngagement = new BehaviorSubject('clientName');
  currentClientName = this.clientNameForEngagement.asObservable();

  private isNewVersionDialogVisible = new BehaviorSubject(true);
  getNewVersionDialogVisibility = this.isNewVersionDialogVisible.asObservable();

  private isUnsavedDialogVisible = false;
  //getUnsavedDialogVisibility = this.isUnsavedDialogVisible.asObservable();



  private isRedirectedFromEditEngagement = new BehaviorSubject(false);
  isRedirectedFromEditEng = this.isRedirectedFromEditEngagement.asObservable();

  private newClientName = new BehaviorSubject('clientName');
  getNewClientName = this.newClientName.asObservable();
  private subjectSearch = new Subject<any>();

  // Message Dialog - Start
  private displayDialogNotificationSource = new BehaviorSubject('');
  displayDialogNotification$ = this.displayDialogNotificationSource.asObservable();

  private setDialogData = new BehaviorSubject('');
  getModalDetails$ = this.setDialogData.asObservable();
  // Message Dialog - End

  // Engagement Message Dialog - Start
  private displayEngDialogNotificationSource = new BehaviorSubject('');
  displayEngDialogNotification$ = this.displayEngDialogNotificationSource.asObservable();

  private setEngDialogData = new BehaviorSubject('');
  getEngModalDetails$ = this.setEngDialogData.asObservable();
  // Engagement Message Dialog - End

  // Set method setConveneProjectData to get result
  private setConveneProjectData = new BehaviorSubject(false);
  getConveneProject$ = this.setConveneProjectData.asObservable();
  // - End

  // Delegate Dialog - Start
  private displayDelegateDialogNotificationSource = new BehaviorSubject('');
  displayDelegateDialogNotification$ = this.displayDelegateDialogNotificationSource.asObservable();

  private setDelegateDialogData = new BehaviorSubject('');
  getDelegateModalDetails$ = this.setDelegateDialogData.asObservable();
  // Delegate Dialog - End

  // New document Dialog - Start
  private displayNewDocumentDialogNotificationSource = new BehaviorSubject('');
  displayNewDocumentDialogNotification$ = this.displayNewDocumentDialogNotificationSource.asObservable();

  private setNewDocumentDialogData = new BehaviorSubject('');
  getNewDocumentModalDetails$ = this.setNewDocumentDialogData.asObservable();
  //  New document Dialog - End

  // document template Dialog - Start
  private displayDocumentTemplateDialogNotificationSource = new BehaviorSubject('');
  displayDocumentTemplateDialogNotification$ = this.displayDocumentTemplateDialogNotificationSource.asObservable();

  private setDocumentTemplateDialogData = new BehaviorSubject('');
  getDocumentTemplateModalDetails$ = this.setDocumentTemplateDialogData.asObservable();
  //  document Template Dialog - End


  // To collapse left nav when review notes panel open - Start
  private isCollapsibleLeftNavVisible = new BehaviorSubject<boolean>(false);
  isCollapsibleLeftNavVisible$ = this.isCollapsibleLeftNavVisible.asObservable();
  // To collapse left nav when review notes panel open - End

  // To close review notes panel - Start
  private isCloseReviewNotes = new BehaviorSubject<boolean>(false);
  isCloseReviewNotes$ = this.isCloseReviewNotes.asObservable();
  // To close review notes panel - End


  //Set SiteUniqueID of Engagement
  private setSiteUniqueID = new BehaviorSubject('');
  getSiteUniqueID = this.setSiteUniqueID.asObservable();


  public CLMGALinkRequestCompleted = new BehaviorSubject('');
  public CLMGALinkRequestProcessing = new BehaviorSubject(false);

  public previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  changeCardTitle(title: string) {
    this.cardTitleSource.next(title);
  }

  get roleInfoSource$() {
    return this.roleInfoSource.asObservable();
  }

  updatedRoleInfo(roleInfo: string) {
    this.roleInfoSource.next(roleInfo);
  }

  setOneViewClientObject(modelData: OneViewClientDetailModel) {
    this.oneViewClientDetailModel = modelData;
  }

  getOneViewClientObject(): any {
    return this.oneViewClientDetailModel;
  }

  setData(data: any) {
    this.data = data;
  }

  getData(): any {
    return this.data;
  }

  setUnsavedDialogVisibility(isVisible: boolean) {
    this.isUnsavedDialogVisible = isVisible;
  }

  getUnsavedDialogVisibility() {
    return this.isUnsavedDialogVisible;
  }
  // setisRedirectedFromCreateNewDoc(isRedirected: boolean) {
  //   this.isRedirectedFromNewDoc=isRedirected;
  // }
  // getisRedirectedFromCreateNewDoc(){
  //   return this.isRedirectedFromNewDoc;
  // }
  setWorkspaceId(workspaceId: string) {
    this.workspaceId.next(workspaceId);
  }
  setWorkItemId(workItemId: string) {
    this.workItemId.next(workItemId);
  }
  setIsRedirectedFromEngagement(isRedirected: string) {
    this.isRedirectedFromNewEngagement.next(isRedirected);
  }

  setClientIdForEngagement(clientId: string) {
    this.clientIdForEngagement.next(clientId);
  }

  setClientIdForPrePopulate(clientId: number) {
    this.clientIdForPrePopulate.next(clientId);
  }

  setEngagementIdForPrePopulate(engagementId: number) {
    this.engagementIdForPrePopulate.next(engagementId);
  }

  setUnsavedChangesModel(model: string) {
    this.unsavedChangesCheck.next(model);
  }
  setIwantToHeadingForClient(IwantToHeading: string) {
    this.IwantToHeadingForClient.next(IwantToHeading);
  }

  setIsRedirectedFromCreateClient(isRedirected: string) {
    this.isRedirectedFromCreateClient.next(isRedirected);
  }
  setReturnUrlForCreateClient(returnUrl: string) {
    this.returnUrlForCreateClient.next(returnUrl);
  }
  setIsRedirectedFromInitiateReacceptance(isRedirected: string) {
    this.isRedirectedFromInitiateReacceptance.next(isRedirected);
  }

  setConveneProjectDetails(isConveneProjectsExists: boolean) {
    this.setConveneProjectData.next(isConveneProjectsExists);
    console.log('Message dialog title ', isConveneProjectsExists);
  }
  displayDialogNotification(notify: string) {
    console.log('displayDialogNotification ', notify);
    this.displayDialogNotificationSource.next(notify);
  }

  setDialogDetails(title: string) {
    this.setDialogData.next(title);
    console.log('Message dialog title ', title);
  }

  displayEngDialogNotification(jsonString: string) {
    console.log('displayEngDialogNotification ', jsonString);
    this.displayEngDialogNotificationSource.next(jsonString);
  }

  setEngDialogDetails(title: string) {
    this.setEngDialogData.next(title);
    console.log('Engagement Message dialog title ', title);
  }

  setClientNameForEngagement(clientName: string) {
    this.clientNameForEngagement.next(clientName);
  }
  setNewVersionDialogVisibility(isVisible: boolean) {
    this.isNewVersionDialogVisible.next(isVisible);
  }
  setRedirectedFromEditEngagement(isRedirected: boolean) {
    this.isRedirectedFromEditEngagement.next(isRedirected);
  }

  setNewClientName(clientName: string) {
    this.newClientName.next(clientName);
  }
  triggerGlobalSearch(object: any) {
    this.subjectSearch.next(object);
  }

  getClickGlobalSearchEvent(): Observable<any> {
    return this.subjectSearch.asObservable();
  }

  displayDelegateDialogNotification(notify: string) {
    console.log('displayDelegateDialogNotification ', notify);
    this.displayDelegateDialogNotificationSource.next(notify);
  }

  setDelegateDialogDetails(title: string) {
    this.setDelegateDialogData.next(title);
    console.log('Delegate dialog title ', title);
  }

  displayNewDocumentDialogNotification(notify: string) {
    console.log('displayNewDocumentDialogNotification ', notify);
    this.displayNewDocumentDialogNotificationSource.next(notify);
  }

  setNewDocumentDialogDetails(title: string) {
    this.setNewDocumentDialogData.next(title);
    console.log('New document dialog title ', title);
  }

  displayDocumentTemplateDialogNotification(notify: string) {
    console.log('displayDocumentTemplateDialogNotification ', notify);
    this.displayDocumentTemplateDialogNotificationSource.next(notify);
  }

  setDocumentTemplateDialogDetails(title: string) {
    this.setDocumentTemplateDialogData.next(title);
    console.log('document template dialog title ', title);
  }
  setCollapsibleLeftNav(isVisible: boolean) {
    this.isCollapsibleLeftNavVisible.next(isVisible);
  }

  setCloseReviewNotes(isVisible: boolean) {
    this.isCloseReviewNotes.next(isVisible);
  }
  setSiteUniqueIDDetails(siteUniqueID: string) {
    this.setSiteUniqueID.next(siteUniqueID);
  }

  get getCLMGALinkRequestCompleted$() {
    return this.CLMGALinkRequestCompleted.asObservable();
  }

  setCLMGALinkRequestCompleted(status: string) {
    this.CLMGALinkRequestCompleted.next(status);
  }
  get getCLMGALinkRequestProcessing$() {
    return this.CLMGALinkRequestProcessing.asObservable();
  }

  setCLMGALinkRequestProcessing(status: boolean) {
    this.CLMGALinkRequestProcessing.next(status);
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
  loadMultiple(observables: Observable<any>[]): Observable<any[]> {
    // Start the spinner
    this.loadingScreenService.startLoading;
 
    // Return a new observable which forks all the input observables
    return new Observable(observer => {
      forkJoin(observables).subscribe(
        data => {
          // All observables completed successfully
          //this.loadingScreenService.stopLoading;
          observer.next(data);
          observer.complete();
        },
        error => {
          // Handle error
          console.error(error);
          this.loadingScreenService.stopLoading;
          observer.error(error);
        },
        () => {
          this.loadingScreenService.stopLoading;
        }
      );
    });
  }
}

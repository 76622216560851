import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MembersList } from 'src/app/model/MembersList';
import { RoleNames } from 'src/app/model/Role';
import { AuditPartnerModel, AuditPartnerStatusModel } from 'src/app/model/Workspace/audit-partner.model';
import { AuditPartnerDialogTitles, AuditPartnerDialogTypes } from 'src/app/model/enums/audit-partner-dialog-types.enum';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { DataService } from 'src/app/services/data.service';
import { ADGroup, ADUser } from 'src/app/model/SharePoiint/ActiveDirectory';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';
import { AzureADUsersArrayList, SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { AdUsersService } from 'src/app/services/adusers.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
declare var $;
@Component({
  selector: 'app-audit-partner-dialog',
  templateUrl: './audit-partner-dialog.component.html',
  styleUrls: ['./audit-partner-dialog.component.css']
})
export class AuditPartnerDialogComponent implements OnInit, OnChanges {

  title: string = AuditPartnerDialogTypes.AdvisoryEPPMDReview;
  @Output() close = new EventEmitter();
  @Output() ok = new EventEmitter<MembersList>();
  memberemailid: string;
  memberName: string;
  @Input() dialogDescriptionType = AuditPartnerDialogTypes.AdvisoryEPPMDReview;
  @Input() workspaceId: string;
  @Input() loggedInUserEmail: string;
  isMemberFirm: false;
  roleName: string = RoleNames.AuditPartner;
  selectedMember = new MembersList();
  invalidselectedMember;
  validationEror = false;
  auditPartnerStatusModel: AuditPartnerStatusModel = new AuditPartnerStatusModel();
  validateAuditPartner: boolean;
  isValidUser: boolean;
  auditPartnerlblCtrlName: string;
  showValidationError: boolean = false;
  errorText: string = '';

  membersListArrayForTeamMembers: MembersList[];
  searchAuditParntnerName: string = "";
  showAuditParnterErrorMessage:boolean=false;
  auditParnterErrorMessage:string="";
  selectAuditPartnerFromPopup: MembersList;
  azureADUsersArrayListAuditPartner: AzureADUsersArrayList;
  isGivenUserGroupMember: boolean;
  constructor(private workspaceService: WorkspaceService,
    private _activedirectoryService: ActiveDirectoryService,
    private dataService: DataService,
    private adUsersService: AdUsersService,
    private loadingScreenService: LoadingScreenService) {
    this.validateAuditPartner = false;
  }
  ngOnInit() {
    this.validateAuditPartner = false;
    this.setDialogTitle();
    this.getAuditPartnerDetails(this.workspaceId);
    this.auditPartnerlblCtrlName = 'the Audit partner/MD';
    this.assignAzureADGroupsForAuditParnter();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.validateAuditPartner = false;
    console.log('ngOnChange ', changes);
    for (const propName in changes) {
      if (propName === 'dialogDescriptionType') {
        this.setDialogTitle();
        this.getAuditPartnerDetails(this.workspaceId);
      }
    }
    this.auditPartnerlblCtrlName = 'the Audit partner/MD';
  }

  updateTeamMembersList(event) {
    this.selectedMember = JSON.parse(event);
    this.validateUser();
  }

  assignUser(event) {
    if (event != undefined) {
      this.validationEror = false;
      this.invalidselectedMember = JSON.parse(event);
    }
  }

  okClicked() {
    this.validateUser();
    if (this.validationEror || this.invalidselectedMember?.data?.mail == undefined) {
      this.validationEror = true;
    } else {
      this.validationEror = false;
      this.addUpdateAuditPartner();
    }
  }

  cancelClicked() {
    this.initDetails();
  }
  initDetails() {
    this.validationEror = false;
    this.selectedMember = new MembersList();
    this.memberName = "";
    this.memberemailid = "";
    this.invalidselectedMember = new MembersList()
  }

  validateUser() {
    this.isValidUser = Boolean(JSON.parse($('#hdnValidInput_AP_1').val().toString()));
    if (this.selectedMember.mail == undefined || $('#txtPeoplePicker_AP_1').val() == '' ||
      $('#txtPeoplePicker_AP_1').val() == undefined || !this.isValidUser) {
      this.validationEror = true;
      $('#txtPeoplePicker_AP_1').addClass("text-danger");
      if (!this.isValidUser && $('#txtPeoplePicker_AP_1').val() == '' ||
        $('#txtPeoplePicker_AP_1').val() == undefined) {
        this.errorText = 'Select the Audit partner/MD';
      } else if (!this.isValidUser) {
        this.errorText = 'User is not part of the Audit partner/MD role';
      }
    } else {
      this.validationEror = false;
      $('#txtPeoplePicker_AP_1').removeClass("text-danger");
    }
  }
  setDialogTitle() {
    if (this.dialogDescriptionType == AuditPartnerDialogTypes.AdvisoryEPPMDReview)
      this.title = AuditPartnerDialogTitles.AdvisoryEPPMDReview;
    if (this.dialogDescriptionType == AuditPartnerDialogTypes.TaxEPPMDReview)
      this.title = AuditPartnerDialogTitles.TaxEPPMDReview;
    if (this.dialogDescriptionType == AuditPartnerDialogTypes.AuditEPPMDReview)
      this.title = AuditPartnerDialogTitles.AuditEPPMDReview;
    if (this.dialogDescriptionType == AuditPartnerDialogTypes.AdvisoryRPReview)
      this.title = AuditPartnerDialogTitles.AdvisoryRPReview;
    if (this.dialogDescriptionType == AuditPartnerDialogTypes.TaxRPReview)
      this.title = AuditPartnerDialogTitles.TaxRPReview;
    if (this.dialogDescriptionType == AuditPartnerDialogTypes.AuditRPReview)
      this.title = AuditPartnerDialogTitles.AuditRPReview;
  }

  addUpdateAuditPartner() {
    let auditPartnerModel = new AuditPartnerModel();
    auditPartnerModel.memberName = this.selectedMember.displayName;
    auditPartnerModel.memberEmailId = this.selectedMember.mail;
    auditPartnerModel.workspaceId = this.workspaceId;
    this.workspaceService.AddAuditPartner(auditPartnerModel).subscribe(result => {
      if (result) {
        this.auditPartnerStatusModel = result;
        let currentEngSiteUniqueID = '';
        this.dataService.getSiteUniqueID.subscribe(siteUniqueID => {
          currentEngSiteUniqueID = siteUniqueID;
          if (this.auditPartnerStatusModel.status === 'Updated') {
            let prevAPEmailId = this.auditPartnerStatusModel.prevAPEmailId;
            let newAPEmailId = this.auditPartnerStatusModel.newAPEmailId;

            //Add NewAP to ADGroup and Delete PrevAP From ADGroup

            let addADUser: ADUser = new ADUser();
            addADUser.firstName = '';
            addADUser.lastName = '';
            addADUser.userEmailId = newAPEmailId;

            let deleteADUser: ADUser = new ADUser();
            deleteADUser.firstName = '';
            deleteADUser.lastName = '';
            deleteADUser.userEmailId = prevAPEmailId;

            let ADDUserADGroups: ADGroup = {
              groupUsersEmail: [addADUser],
              groupType: 'Member',
            };

            //Call Active Directory API to Add and Delete AP in ADGroup                        
            this._activedirectoryService.AddUsersToADGroup(currentEngSiteUniqueID + "_Owners", ADDUserADGroups).subscribe(
              result => {
                let DeleteUserADGroups: ADGroup = {
                  groupUsersEmail: [deleteADUser],
                  groupType: 'Member',
                };
                this._activedirectoryService.DeleteUsersToADGroup(currentEngSiteUniqueID + "_Owners", DeleteUserADGroups).subscribe(
                  result => {
                    this.ok.emit(this.selectedMember);
                  });
              });
          } else if (this.auditPartnerStatusModel.status == 'Added') {
            let adUser: ADUser = new ADUser();
            adUser.firstName = '';
            adUser.lastName = '';
            adUser.userEmailId = this.auditPartnerStatusModel.newAPEmailId

            let UserADGroups: ADGroup = {
              groupUsersEmail: [adUser],
              groupType: 'Member',
            };

            //Call Active Directory API to update latest collaborationSite Member Ids                           
            this._activedirectoryService.AddUsersToADGroup(currentEngSiteUniqueID + "_Owners", UserADGroups).subscribe(
              result => {
                this.ok.emit(this.selectedMember);
              });
          } else {
            this.ok.emit(this.selectedMember);
          }
        });
      } else {
        console.error("failed to update audit partner", auditPartnerModel)
      }
    }, err => {
      console.error("failed to update audit partner", auditPartnerModel)
    });
  }

  getAuditPartnerDetails(workspaceId: string) {
    this.workspaceService.getAuditPartnerDetails(workspaceId).subscribe(result => {
      if (result.memberEmailId != null && result.memberName != null) {
        this.selectedMember.displayName = result.memberName;
        this.selectedMember.mail = result.memberEmailId;
        this.memberName = result.memberName;
        this.memberemailid = result.memberEmailId;
        this.validateAuditPartner = true;
        this.invalidselectedMember = { data: { displayName: result.memberName, mail: result.memberEmailId } };
        this.isValidUser = Boolean(JSON.parse($('#hdnValidInput_AP_1').val().toString()));
        if (this.isValidUser) {
          this.validationEror = false;
        }
      } else {
        $('#txtPeoplePicker_AP_1').val('');
        this.initDetails();
      }
    });
  }

  deselectTeamMember() { // Cleare the selected data in PP popup
    this.membersListArrayForTeamMembers = null;
    this.selectAuditPartnerFromPopup = null;
    this.searchAuditParntnerName = "";
    this.isGivenUserGroupMember = false;
    this.showAuditParnterErrorMessage=false;
  }

  setTeamMember() {  // Setting the data in PP popup case
    if(this.searchAuditParntnerName?.length>0){
      // if(this.selectAuditPartnerFromPopup.mail==this.selectedMember.mail)
      // // {
      //   this.showAuditParnterErrorMessage=true;
      //   this.auditParnterErrorMessage="Selected AP is already added";
      // }
      {
        this.selectedMember = this.selectAuditPartnerFromPopup;
        this.invalidselectedMember = { data: { displayName: this.selectedMember.displayName, mail: this.selectedMember.mail } };
        $('#txtPeoplePicker_AP_1').val(this.selectedMember.displayName)
        $('#hdnValidInput_AP_1').val(this.isGivenUserGroupMember);
        this.validateUser();
        $('#modal-AuditParnterTeamMember').modal('hide');
      }
    }
    else{
      this.showAuditParnterErrorMessage=true;
      this.auditParnterErrorMessage="Please Select User"
    }
  }
  refreshTeamMemberPopup() {  // Cleare the selected data in PP popup
    this.deselectTeamMember();
  }
  searchUsers() { // search  Users data in PP popup case
    this.GetTeamMembers(this.searchAuditParntnerName);
    this.showAuditParnterErrorMessage=false;
  }
  selectAuditPartner(index: any, member: MembersList) { // in PP popup case
    $('#trTeamMembers_' + index).addClass('highlight').siblings().removeClass('highlight');
    this.selectAuditPartnerFromPopup = member;
    this.validateSelectedUserWithAzureAD(this.selectAuditPartnerFromPopup);
  }

  GetTeamMembers(searchTerm: string) { // in PP popup case get user from AD
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    searchModel.azureADUsers = this.azureADUsersArrayListAuditPartner;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe({
        next: (userList) => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForTeamMembers = userList;
        }, error: (err) => {
          console.log('Error getting result ', err);
          this.membersListArrayForTeamMembers = null;
          this.loadingScreenService.stopLoading();
          console.error('Something went wrong! Please contact system administrator.', 'danger');
        }
      });
  }
  assignAzureADGroupsForAuditParnter() {
    this.adUsersService.GetAzureADGroupsByRoleShortName(RoleNames.AuditPartner.toString()).subscribe({
      next: result => {
        this.azureADUsersArrayListAuditPartner = result;
        this.azureADUsersArrayListAuditPartner.roleName = RoleNames.AuditPartner.toString();
      }
    });
  }

  validateSelectedUserWithAzureAD(searchUser: MembersList) {
    let searchGroupUserViewModel = new SearchGroupUserViewModel();
    this.isGivenUserGroupMember = false;
    searchGroupUserViewModel.azureADUsers = this.azureADUsersArrayListAuditPartner;
    searchGroupUserViewModel.user = searchUser;

    this.adUsersService.FindUserInGroupMembers(searchGroupUserViewModel).subscribe({
      next: isUserValid => {
        this.isGivenUserGroupMember = isUserValid;
      }
    });
  }
}

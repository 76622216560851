export class ClientCardViewFilterModel {
    constructor(public clientId: number,
                public structEntityId: number,
                public programId: number,
                public industryTypeId: number,
                public clientStatusId: number,
                public sortClientId: boolean,
                public sortProgramId: boolean,
                public sortClientStatusId: boolean,
                public sortLastActivityDate: boolean,
                public loggedInUser: string) {

    }
}

export class ClientListViewFilterModel {
    constructor(public clientId: number,
        public structEntityId: number,
        public programId: number,
        public industryTypeId: number,
        public clientStatusId: number,
        public sortClientId: boolean,
        public sortProgramId: boolean,
        public sortClientStatusId: boolean,
        public sortLastActivityDate: boolean,
        public loggedInUser: string) {

    }
}
export class TemplateMappingModel {
    documentTypeId: number;
    // documentType: string;
    // documentCategory: string;
    templateMappingId:number;
    templatePackageId:string;
    templateName:string;
    templateVersion :number;
    createdBy: string;
    createdOn: Date;
    lastModifiedBy: string;
    lastModifiedOn: Date;
}


export class DocumentTypeListModel {
    documentTypeId: number;
    documentType: string;
    documentCategory: string;
}

export class DocumentTemplateMappingListModel {
  documentTypeId: number;
  documentType: string;
  subDocumentTypeId: number;
  subDocumentType: string;
  templates: string;
  templateItemList: TemplateModel[];
}

export class TemplateModel {
  name: string;
}

export enum DocumentCatgories {
  ClientDeliverable='Client Deliverable',
ConflictWaiver='Conflict Waiver',
AccessLetter='Access Letter',
LegalDocument='Legal Document',
ConsentLetter='Consent Letter',
Memo='Memo',
AcceptanceDocument='Acceptance Document',
OtherDocuments='Other Documents'
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EngagementService } from 'src/app/services/EngagementService';
import { EngagementListModel, EngagementList } from 'src/app/model/EngagementListModel';
import 'src/assets/Style/excel-bootstrap-table-filter-bundle.js';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { AppConfigService } from 'src/app/services/appConfigService';

declare var $;
declare function applyListView(fnCallBack);
declare function ShowAllClientNameForotherdoc();
declare function ShowAllEngagementsForotherdoc();
declare function ShowAllSolutionCodeForotherdoc();
declare function ShowAllEPForotherdoc();
declare function ShowAllStatusForotherdoc();
declare function CheckUncheckAllForotherdoc(CheckAllStatusForotherdoc, CheckStatusForotherdoc);
declare function setValueToEPContactCard(rpName);


@Component({
  selector: 'app-engagement-list-view-forotherdoc',
  templateUrl: './engagement-list-view-forotherdoc.component.html',
  styleUrls: ['./engagement-list-view-forotherdoc.component.css'],
  providers: [EngagementService]
})
export class EngagementListViewForotherdocComponent implements OnInit {

  passedClientId: number;
  selectedKRPClientId: number;
  selectedEngagementId: number;

  applyFilterOnceAfterDataLoad: boolean = false;
  page: number[] = [];
  englistViewList: EngagementListModel[] = [];
  engagementList: EngagementList[] = [];
  contactDetailList: any;
  contactinstance: any;
  fnCallBack: any;
  ShowClientName: boolean = true;
  popupRecordCount: number;

  constructor(private _englist: EngagementService, private route: Router,
    private loadingScreenService: LoadingScreenService, private enviornment: AppConfigService) {
    this.popupRecordCount = enviornment.config.DATA.SHOW_CLOSE_BUTTON_RECORD_LIMIT;
  }

  ngOnInit() {
    if (this.route.url.indexOf('client-summary') !== -1) {

      const urlElement = this.route.url.split('/');
      this.passedClientId = +urlElement[urlElement.length - 1];
      this.ShowClientName = false;
    }
    this.SetDefaultCardViewForotherdoc();
    this.fnCallBack = function () {
      ShowAllClientNameForotherdoc();
      ShowAllEngagementsForotherdoc();
      ShowAllSolutionCodeForotherdoc();
      ShowAllStatusForotherdoc();
      ShowAllEPForotherdoc();
      CheckUncheckAllForotherdoc('CheckAllClientsForotherdoc', 'CheckClientsForotherdoc');
      CheckUncheckAllForotherdoc('CheckAllEngForotherdoc', 'CheckEngagementForotherdoc');
      CheckUncheckAllForotherdoc('CheckAllSolutionCodeForotherdoc', 'CheckSolutionCodeForotherdoc');
      CheckUncheckAllForotherdoc('CheckAllStatusForotherdoc', 'CheckStatusForotherdoc');
      CheckUncheckAllForotherdoc('CheckAllEPForotherdoc', 'CheckEPForotherdoc');


      $('.DocumentDataTable').DataTable({
        bLengthChange: false,
        searching: false,
        iDisplayLength: 5
      });
    };

    $(".ShowRPDetailsjs").each(function (i, obj) {
      $(this).mouseover(function () {
        $(this).css("curser", "initial");
        $(this).css("color", "red");
      });
    });

    $(".ShowRPDetailsjs").each(function (i, obj) {
      $(this).mouseleave(function () {
        $(this).css("curser", "default");
        $(this).css("color", "black");
      });
    });

    // /* Code to show Relationship Partener Popup */
    var clientRPPopup = $("#popover-content-EPDetails");
    clientRPPopup.hide();

    if (this.route.url.indexOf('client-summary') !== -1) {
      this.getEnagementListByClientforOtherDoc(this.passedClientId);
    }
    else {
      this.getEngagementList();
    }
  }

  ngAfterViewChecked() {
    var clientRPPopup = $("#popover-content-EPDetails");

    $('.ShowRPDetailsjs').click(function (i, obj) {
      $(this).popover({
        html: true,
        content: function () {
          var a = $(this).find('.hiddenEPId').text();
          setValueToEPContactCard(a);
          return clientRPPopup.html();
        }
      });
    });

    if (this.applyFilterOnceAfterDataLoad) {
      this.ApplyFilters();
      this.applyFilterOnceAfterDataLoad = false;
    }
  }

  // end ngoninit
  getEngagementList() {
    this._englist.getEngagementListView().subscribe(
      result => {
        this.engagementList = result;
        console.log(this.englistViewList);
        console.log('getEngagementListView');
        this.applyFilterOnceAfterDataLoad = true;
      },
      err => console.log('Error in loading the client list information.'));
  }

  getEnagementListByClientforOtherDoc(clientId: number) {
    this._englist.getEnagementListByClientforOtherDoc(clientId).subscribe(
      result => {
        this.engagementList = result;
        console.log(this.englistViewList);
        //console.log('getEnagementListByClientforOtherDoc');
        this.applyFilterOnceAfterDataLoad = true;
      },
      err => console.log('Error in loading the engagement list information by client for Other Doc', err));
  }

  ApplyFilters() {
    applyListView(this.fnCallBack);
    $('.tableData').excelTableFilter();
    var count = 0;
    $(".showDocumentDataDetailsjsOtherDoc").each(function (i, obj) {

      $(this).flyout({
        title: 'Active Documents',
        placement: 'right',
        content: function () {
          let flyOutId = $(this).parent().attr('id');
          let flyOutInfo = $("#DocumentTableDataId").html();
          let recordCount = $('tr', $("#DocumentTableDataId").find('tbody')).length;
          let recordLimit = $('#recordCount').val();
          let hideCloseButton = recordCount > recordLimit ? "" : "d-none";
          console.log('Flyout ', flyOutId, recordCount, recordLimit, hideCloseButton);
          //console.log(flyOutInfo);
          var flyOutHtml = `
            <div>
              <button type="button" class="btn btn-secondary btn-sm pull-right"
                onclick="$(${flyOutId}).hide(); return false;">
                Close
              </button>
              <table id="DocumentTableDataId${count}" class="table DataList1" role="grid">
               ${flyOutInfo}
              </table>
              <button type="button" class="btn btn-secondary btn-sm pull-right ${hideCloseButton}"
                onclick="$(${flyOutId}).hide(); return false;">
                Close
              </button>
            </div>`;
          return flyOutHtml;
        },
        html: true,
        trigger: 'manual'
      }).click(function () {
        setTimeout(() => {
          $(".showDocumentDataDetailsjsOtherDoc").each(function (p, q) {
            $(this).flyout('hide');
          });
          $(this).flyout('show');
        }, 1000);
      }).dblclick(function () {
        setTimeout(() => {
          $(this).flyout('hide');
        }, 1000);
      });
      count++;
    });
    count = 0;
  }

  ToggleListAndCardViewForotherdoc(SelectedView) {
    switch (SelectedView) {
      case "Card":
        this.SetDefaultCardViewForotherdoc();
        break;
      case "List":
        $(".CardViewImgForotherdoc").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
        $(".ListViewImgForotherdoc").attr("src", "../../../../assets/images/listview/list-view_selected.png");
        $(".ListViewContainerForotherdoc").show();
        $(".cardViewContainerForotherdoc").hide();
        $(".CardViewFiltersForotherdoc").hide();
        this.ApplyListFilterstableDataEngForotherdoc();
        break;
      default:
        this.SetDefaultCardViewForotherdoc();
        break;
    }
  }
  ApplyListFilterstableDataEngForotherdoc() {
    $('.tableDataEngForotherdoc').excelTableFilter();
    $(".a-to-z").text("Ascending");
    $(".dropdown-filter-sort span.a-to-z").after("<img src='../../../../assets/images/listview/asending_1.svg' id='ascImage' class='FloatImage'> ");
    $(".z-to-a").text("Descending");
    $(".dropdown-filter-sort span.z-to-a").after("<img src='../../../../assets/images/listview/desending_1.svg' id='descImage' class='FloatImage'>");
    $("img.FloatImage").css({
      "float": "right",
      "margin-top": "6px",
      "color": "#4f2d7e",
      'background-repeat': 'none'
    });
  }

  SetDefaultCardViewForotherdoc() {
    $(".CardViewImgForotherdoc").attr("src", "../../../../assets/images/listview/card-view_selected.png");
    $(".ListViewImgForotherdoc").attr("src", "../../../../assets/images/listview/list-view_un-selected.png");
    $(".cardViewContainerForotherdoc").show();
    $(".ListViewContainerForotherdoc").hide();
    $(".CardViewFiltersForotherdoc").show();
    $(".CardViewFiltersForotherdoc").css('display', 'inherit');
  }

  setKRPClientId(clientId: number) {
    this.selectedKRPClientId = clientId;
  }

  setEngagementId(engagementId: number) {
    console.log('setEngagementId ', engagementId);
    this.selectedEngagementId = engagementId;
  }

  onKeyup(event, classname) {
    const text = event.currentTarget.value;
    const value = text.toLowerCase();
    $('.' + classname).filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      var originalFilterLength = $(".searchDatajs").length;
      var hiddenFilterdata = $('.searchDatajs').filter(function () {
        return $(this).css('display') == 'none';
      }).length;

      if (originalFilterLength == hiddenFilterdata) {
        $("#NoDataMessageForotherdoc").removeClass('hide');
      }
      else { $("#NoDataMessageForotherdoc").addClass('hide'); }
    });
  }
}

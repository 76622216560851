export class FieldValidator {
  fieldId: string;
  fieldValue: string;
  isvalid: boolean = false;
  apply: boolean = false;

  constructor(fieldId: string, fieldValue: any , apply: boolean)
  {
    this.setvalues(fieldId, fieldValue, apply);
  }

  setvalues(fieldId: string, fieldValue: any , apply: boolean) {
    this.fieldId = fieldId;
    this.fieldValue = fieldValue;
    this.apply = apply;
    if ((this.fieldId =='stateId' && this.fieldValue == undefined) ||
      (this.fieldId =='countryId' && this.fieldValue == undefined) )    
    {
      this.isvalid = false;
    } else if (this.empty(this.fieldValue)) {
      this.isvalid = false;
    } else {
      this.isvalid = true;
    }
  }



empty(e) {

  switch (e) {
    case '':
        return true;
        break;
    case 0:
        return true;
        break;
    case '0':
      return true;
      break;
    case null:
        return true;
        break;
    case false:
          return false;
          break;
    case typeof(e) == 'undefined':
          return true;
          break;
    default:
      return false;
 }
}
}

import { Observable } from 'rxjs';
import { TemplateMaintenance } from './../model/template-maintenance/template-maintenance.model';
import { AppConfigService } from './appConfigService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TemplateMaintenanceService {
  apiEndPoint: string;
  userName: string;
  userFullName: string;
  constructor(environmentService: AppConfigService, private http: HttpClient) {
    this.apiEndPoint = environmentService.config.DATA.API_END_POINT.API_URL;
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.userFullName = nameJSON.loggedInUserFullName;
    }
  }

  saveTemplateMaintenance(templateMaintenane: TemplateMaintenance) {
    templateMaintenane.createdBy = this.userName;
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${this.apiEndPoint}/TemplateMaintenance/SaveTemplateMaintenance`, templateMaintenane, { responseType: 'text' });
  }

  getTemplateMaintenanceByTemplatePackageId(templatePackageId: string): Observable<TemplateMaintenance> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<TemplateMaintenance>(`${this.apiEndPoint}/TemplateMaintenance/GetTemplateMaintenanceByTemplatePackageId/${templatePackageId}`);
  }

  getAllTemplateMaintenanceData(): Observable<TemplateMaintenance[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<TemplateMaintenance[]>(`${this.apiEndPoint}/TemplateMaintenance/getAllTemplateMaintenanceData`);
  }
  getTemplateMaintenanceDataByDocSubType(documentSubType: string): Observable<TemplateMaintenance[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<TemplateMaintenance[]>(`${this.apiEndPoint}/TemplateMaintenance/GetTemplateMaintenanceDataByDocSubType/${documentSubType}`);
  }
  getTemplateMaintenanceDataByCLMType(documentSubType: string): Observable<TemplateMaintenance[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<TemplateMaintenance[]>(`${this.apiEndPoint}/TemplateMaintenance/GetTemplateMaintenanceDataByCLMType/${documentSubType}`);
  }

  GetTemplatesByEngagementsOfUser(
    loggedInUser: string,
    engagementId: number,
    isloggedInUserAdmin: boolean
  ): Observable<TemplateMaintenance> {
    return this.http.get<TemplateMaintenance>(
      `${this.apiEndPoint}/TemplateMaintenance/GetTemplatesByEngagementsOfUser/${loggedInUser}/${engagementId}/${isloggedInUserAdmin}`
    );
  }
}

<app-loading-screen></app-loading-screen>

<div class="containerDiv">
  <!-- Page Title row -->
  <div class="d-flex justify-content-between pageTitleParent pb-3 mw-100">
    <div class="text-center">
      <h2 class=" float-md-left pageTitle largerFontSize2">Process add professionals engagement</h2>
    </div>
    <div class="text-center">
      <span class="float-md-right">
        <app-shortcut></app-shortcut>
      </span>
    </div>
  </div>
  <!-- End Page Title row -->

  <div class="row py-3">
    <div class="col-md-6">
      <label for="document"><strong>Upload document</strong> </label>
      <input id="document" name="document" class="form-control rounded-0 border  input-height"
       type="file" (change)="onSelectFile($event.target.files)" (click)="$event.target.value=null"/>

    </div>
    <div class="col-md-3 padding-left-0">
      <div class="">
        <div class="">
          
          <div><label>&nbsp;</label></div>
          <button id="btnProcessAddProfessionalsEngagement" class="btn PrimaryButton rounded-0 w-100 input-height"
            type="button" (click)="isExcelDocument && processAddProfessionalsEngagement()">
            Process
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngFor="let alert of alerts;let id=index;">
  <div class="alert alert-{{alert.messageType | lowercase}} alert-dismissible fade show" role="alert">
    <span class="msg-icon">
      <img *ngIf="!alert.isSuccess" src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="warning">
      <img *ngIf="alert.isSuccess" src="assets/images/EDP/AuditEngagement/audit-engagement-process-success.png" alt="success">
    </span>
    <span class="d-inline-block align-middle msg-text">
     {{alert.message}}
    </span>
  </div>
</div>
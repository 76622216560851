import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { HotDocsService } from '../../services/HotDocsService';
import { AlertsService } from '../../services/alerts.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../../model/User';
import { Alert } from "src/app/model/Alert";
import { DataService } from 'src/app/services/data.service';

declare var $;
@Component({
  selector: 'app-admin-configuration',
  templateUrl: './admin-configuration.component.html',
  styleUrls: ['./admin-configuration.component.css'],
  providers: [ConfigurationService, HotDocsService]
})
export class AdminConfigurationComponent implements OnInit {

  alertClient: Alert;
  isRoleValidationDisplayed: boolean = false;
  isDisplayConfiguration: boolean = false;
  userName = '';
  isUserAgentIE: number;

  constructor(
    private private_alertService: AlertsService, private private_authService: AuthService,
    private dataService: DataService) {

    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);

    this.isDisplayConfiguration = false;
    const user: User = private_authService.getUserInfo();

    if (user != null) {
      this.userName = user.firstName + ' ' + user.lastName;
      if (user.role && user.role.length > 0) {
        for (let i = 0; i < user.role.length; i++) {
          if (user.role[i].shortRoleName === 'SA') {
            this.isDisplayConfiguration = true;
            break;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.showhideContainer();  
  }
  

  showhideContainer() {
    if (!this.isDisplayConfiguration) {
      $('#configbreadcrumb').hide();
      $('#configmaincontainer').hide();
    }    
    $('#configShortcutImgJs').attr('src', '../../../assets/images/EDP/Configuration/shortcuts_blue.svg');
    $('#TemplateMappingContainer').hide();
    $('#ConfigNavigationContainer').hide();
    $('#ConfigReviewingPartnersContainer').hide();
    $('#SolutionCodeConfigScreen').show();    

    $('.TemplateMappingContainerjs').click(function () {
      $('#configShortcutImgJs').attr('src', '../../../assets/images/EDP/Configuration/shortcuts_white.svg');
      $('#configNavigationImgJs').attr('src', '../../../assets/images/EDP/Configuration/config-navigation-blue.svg');
      $('#configRPImgJs').attr('src', '../../../assets/images/EDP/Configuration/solution-code-tab.png');
      $('#ConfigNavigationContainer').hide();
      $('#ConfigReviewingPartnersContainer').hide();
      $('#SolutionCodeConfigScreen').hide();
      $('#TemplateMappingContainer').show();
    });
    $('.ConfigNavigationContainerjs').click(function () {
      $('#configNavigationImgJs').attr('src', '../../../assets/images/EDP/Configuration/config-navigation-white.svg');
      $('#configShortcutImgJs').attr('src', '../../../assets/images/EDP/Configuration/shortcuts_blue.svg');
      $('#configRPImgJs').attr('src', '../../../assets/images/EDP/Configuration/solution-code-tab.png');
      $('#TemplateMappingContainer').hide();
      $('#ConfigReviewingPartnersContainer').hide();
      $('#SolutionCodeConfigScreen').hide();
      $('#ConfigNavigationContainer').show();
    });
    $('.ConfigReviewingPartnersContainerJS').click(function () {
      $('#configRPImgJs').attr('src', '../../../assets/images/EDP/Configuration/people_alt-white.svg');
      $('#configNavigationImgJs').attr('src', '../../../assets/images/EDP/Configuration/config-navigation-blue.svg');
      $('#configShortcutImgJs').attr('src', '../../../assets/images/EDP/Configuration/shortcuts_blue.svg');
      $('#TemplateMappingContainer').hide();
      $('#ConfigNavigationContainer').hide();
      $('#SolutionCodeConfigScreen').hide();
      $('#ConfigReviewingPartnersContainer').show();
    });

    $('.SolutionCodeConfigScreenjs').click(function () {
      $('#configNavigationImgJs').attr('src', '../../../assets/images/EDP/Configuration/config-navigation-blue.svg');
      $('#configShortcutImgJs').attr('src', '../../../assets/images/EDP/Configuration/shortcuts_blue.svg');
      $('#configRPImgJs').attr('src', '../../../assets/images/EDP/Configuration/solution-code-tab-white.png');

      $('#TemplateMappingContainer').hide();
      $('#ConfigNavigationContainer').hide();
      $('#ConfigReviewingPartnersContainer').hide();
      $('#SolutionCodeConfigScreen').show();
    });

  }

  clearMessage() {
    this.private_alertService.remove(this.alertClient);
  }

  showDialog(dialogType: string, dialogTitle: string) {
    var modalObj = { 
      dialogType: dialogType, 
      dialogTitle: dialogTitle, 
      dialogDescription : 'All modals should have short, descriptive titles and their content text should explain ' +
                          'to the user what happened that caused it. For danger and alert items, a possible solution ' +
                          'should be offered.',
      dialogRedirectUrl : dialogType === 'success' ? '/Home' : '',
      dialogButton: 1,
      modalDisplay: true 
    }; 
    var modalJSON = JSON.stringify(modalObj); 
    console.log(' showDialog func called ', dialogType);
    this.dataService.displayDialogNotification(modalJSON);
  }

  showDialogWith2Button(dialogType: string, dialogTitle: string) {
    var modalObj = { 
      dialogType: dialogType, 
      dialogTitle: dialogTitle, 
      dialogDescription : 'All modals should have short, descriptive titles and their content text should explain ' +
                          'to the user what happened that caused it. For danger and alert items, a possible solution ' +
                          'should be offered.',
      dialogRedirectUrl : dialogType === 'success' ? '/Home' : '',
      dialogButton: 2,
      modalDisplay: true 
    }; 
    var modalJSON = JSON.stringify(modalObj); 
    console.log(' showDialog func called ', dialogType);
    this.dataService.displayDialogNotification(modalJSON);
  }

  showDialogWith3Button(dialogType: string, dialogTitle: string) {
    var modalObj = { 
      dialogType: dialogType, 
      dialogTitle: dialogTitle, 
      dialogDescription : 'All modals should have short, descriptive titles and their content text should explain ' +
                          'to the user what happened that caused it. For danger and alert items, a possible solution ' +
                          'should be offered.',
      dialogRedirectUrl : dialogType === 'success' ? '/Home' : '',
      dialogButton: 3,
      modalDisplay: true 
    }; 
    var modalJSON = JSON.stringify(modalObj); 
    console.log(' showDialog func called ', dialogType);
    this.dataService.displayDialogNotification(modalJSON);
  }
}


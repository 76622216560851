export class AssignmentFilter {
    constructor(public clientId: number,
                public solutionCodeId: number,
                public eppmdEmail: string,
                public cmseppmdEmail: string,
                public industryTypeId: number,
                public assignmentStatus: string,
                public eqcrEmail: string,
                public engagementId: number,
                public loggedInUser: string) {

    }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EngagementService } from 'src/app/services/EngagementService';
import { EngagementList, ImageModel, HeaderModel, ContactCardControl, ContactCardClick } from 'src/app/model/EngagementListModel';
import { PagerService } from "src/app/services/pager.service";
import { Engagements, EngagementStatus } from 'src/app/model/Engagement/Engagements';
import { EngagementUniqueDataModel, ClientModel, SolutionCodeModel, StatusModel, EppmdModel, IndustryModel } from 'src/app/model/EngagementFilterModel';
import { EngagementCardViewFilterModel, EngagementListViewFilterModel, EngagementCardViewForClientFilterModel, EngagementListViewForClientFilterModel } from 'src/app/model/engagement-filter-model';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { UserPreferenceData } from 'src/app/model/UserPreferenceModel';

declare var $;

@Component({
  selector: 'app-engagement-list-view',
  templateUrl: './engagement-list-view.component.html',
  styleUrls: ['./engagement-list-view.component.css'],
  providers: [EngagementService, LoadingScreenService, PagerService]
})
export class EngagementListViewComponent implements OnInit, OnDestroy {

  isMyEngagementLevel: boolean = false;
  passedClientId: number = 0;
  selectedKRPClientId: number;
  selectedEngagementId: number;
  engagementList: EngagementList[] = [];
  engagementCardList: EngagementList[] = [];
  ShowClientName: boolean = true;
  iShortcutCount: number;
  engagementCount: number = 0;
  engagementListCount: number = 0;
  engagementCountByClientId: number = 0;
  engagementListCountByClientId: number = 0;
  listViewnoOfrecordsPerPage: number = 20;
  cardViewnoOfrecordsPerPage: number = 20;
  listViewCurrentPageNumber: number = 1;
  cardViewCurrentPageNumber: number = 1;
  noOfrecordsPerPageList: number[];
  isloggedInUserAdmin: string;
  orderByColumn: string;
  orderByType: string;
  cardVieworderByColumn: string;
  cardVieworderByType: string;

  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];
  userObject: any;
  selectContactEmailId: string;
  applyFilterOnceAfterDataLoad: boolean;
  listCount: number = null;
  cardCount: number = null;
  DataMessage: string;
  listDataMessage: string;
  selectedEngagement: Engagements = new Engagements();
  engagementFilterUniqueDataList: EngagementUniqueDataModel;
  engagementFilterUniqueDataByClientIdList: EngagementUniqueDataModel;
  clientsList: ClientModel[] = [];
  solutionCodeList: SolutionCodeModel[] = [];
  eppmdList: EppmdModel[] = [];
  industryList: IndustryModel[] = [];
  engagementStatusList: StatusModel[] = [];
  solutionCodeListForClient: SolutionCodeModel[] = [];
  eppmdListForClient: EppmdModel[] = [];
  industryListForClient: IndustryModel[] = [];
  engagementStatusListForClient: StatusModel[] = [];
  ClientMappedValue: string;
  ClientListMappedValue: string;
  userName: string;
  clientId: number = 0;
  clientsId: string = '';
  solutionCodeId: number = 0;
  eppmdEMail: string = '';
  industryId: number = 0;
  engagementStatusName: string = '';

  currentSolutionCodeForClientId: number = 0;

  listViewCount: number = 0;

  currentListSolutionCodeIdForClient: number = 0;

  sortOrderByType: string = 'DESC';
  sortOrderByTypeForClient: string = 'DESC';

  engagementCardViewFilterModel: EngagementCardViewFilterModel;
  engagementListViewFilterModel: EngagementListViewFilterModel;

  engagementCardViewForClientFilterModel: EngagementCardViewForClientFilterModel;
  engagementListViewForClientFilterModel: EngagementListViewForClientFilterModel;

  isListFlag: boolean = false;
  isClientEngagements: boolean = false;
  userPreferenceData: UserPreferenceData[];
  engagementUserPreference: UserPreferenceData = null;
  clientdetailUserPreference: UserPreferenceData = null;
  dashBoardViewtypeName: string = 'Card';
  isQualityUserLoggedIn: boolean = false;
  isCardViewApplyResetButtonClicked : boolean = false;
  isListViewApplyResetButtonClicked : boolean = false;
  isCardViewSortApplyResetButtonClicked : boolean = false;
  isClientCardViewApplyResetButtonClicked : boolean = false;
  isClientListViewApplyResetButtonClicked : boolean = false;
  isClientCardViewSortApplyResetButtonClicked : boolean = false;

  public get EngagementStatus(): typeof EngagementStatus {
    return EngagementStatus;
  }

  @Output() passLoading = new EventEmitter<string>();

  constructor(private engService: EngagementService, private route: Router,
    private loadingService: LoadingScreenService, private pagerService: PagerService) {
    const IShortcutCount = localStorage.getItem('iShortcutCount');
    this.iShortcutCount = JSON.parse(IShortcutCount);
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    var userPreferenceString = localStorage.getItem('userpreferencelist');
    this.userPreferenceData = JSON.parse(userPreferenceString);
    console.log(this.userPreferenceData);
    if (this.userPreferenceData.length > 0) {
      this.userPreferenceData.forEach(value => {
        if (value.dashBoardName === "MyEngagements") {
          this.engagementUserPreference = value;
        }
        if (value.dashBoardName === "ClientDetails") {
          this.clientdetailUserPreference = value;
        }
      });
    }
    this.DataMessage = 'Loading...';
    this.listDataMessage = 'Loading...';
    this.ClientMappedValue = 'ClientMappedValue';
    this.ClientListMappedValue = 'ClientListMappedValue';
    let nameJSON = JSON.parse(localStorage.getItem("currentUser"));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
    }
  }

  // -- Pager service -- */
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  cardViewPager: any = {};
  // paged items
  pagedItems: any[];
  // -- Pager service End-- */

  ngOnInit() {
    if (this.route.url.indexOf('ClientDetails') !== -1) {
      this.isMyEngagementLevel = true;
      const urlElement = this.route.url.split('/');
      this.passedClientId = +urlElement[urlElement.length - 1];
      //this.ShowClientName = false;
    }
    else {
      this.isMyEngagementLevel = false;
    }
    this.IsLoggedinUserSystemAdmin();
    this.engagementCardViewFilterModel = new EngagementCardViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, true, this.userName);
    this.engagementListViewFilterModel = new EngagementListViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, false, this.userName);
    if (this.userPreferenceData.length > 0 && this.engagementUserPreference != null && this.passedClientId <= 0) {
      this.engagementCardViewFilterModel = JSON.parse(this.engagementUserPreference.cardViewFilterList);
      this.sortOrderByType = JSON.parse(this.engagementUserPreference.cardViewSortingList).orderByType;
      if (this.sortOrderByType == undefined || this.sortOrderByType == null) {
        this.sortOrderByType = 'DESC';
      }
      this.engagementListViewFilterModel = JSON.parse(this.engagementUserPreference.listViewFilterList);
      this.orderByColumn = JSON.parse(this.engagementUserPreference.listViewSortingList).orderByColumn;
      this.cardViewnoOfrecordsPerPage = this.engagementUserPreference.cardViewPageNumber;
      if (this.cardViewnoOfrecordsPerPage == undefined || this.cardViewnoOfrecordsPerPage == null) {
        this.cardViewnoOfrecordsPerPage = 20;
      }
      this.listViewnoOfrecordsPerPage = this.engagementUserPreference.listViewPageNumber;
      if (this.listViewnoOfrecordsPerPage == undefined || this.listViewnoOfrecordsPerPage == null) {
        this.listViewnoOfrecordsPerPage = 20;
      }
      this.orderByType = JSON.parse(this.engagementUserPreference.listViewSortingList).orderByType;
      this.dashBoardViewtypeName = this.engagementUserPreference.dashboardViewType;
      if (this.dashBoardViewtypeName == undefined || this.dashBoardViewtypeName == null) {
        this.dashBoardViewtypeName = 'Card';
      }
    }
    this.engagementCardViewForClientFilterModel = new EngagementCardViewForClientFilterModel(0, '', 0, '', false, false, false, false, true, this.userName);
    this.engagementListViewForClientFilterModel = new EngagementListViewForClientFilterModel(0, '', 0, '', false, false, false, false, false, this.userName);
    if (this.userPreferenceData.length > 0 && this.clientdetailUserPreference != null && this.passedClientId > 0) {
      this.engagementCardViewForClientFilterModel = JSON.parse(this.clientdetailUserPreference.cardViewFilterList);
      this.sortOrderByTypeForClient = JSON.parse(this.clientdetailUserPreference.cardViewSortingList).sortOrderByTypeForClient;
      if (this.sortOrderByTypeForClient == undefined || this.sortOrderByTypeForClient == null) {
        this.sortOrderByTypeForClient = 'DESC';
      }
      this.engagementListViewForClientFilterModel = JSON.parse(this.clientdetailUserPreference.listViewFilterList);
      this.orderByColumn = JSON.parse(this.clientdetailUserPreference.listViewSortingList).orderByColumn;
      this.cardViewnoOfrecordsPerPage = this.clientdetailUserPreference.cardViewPageNumber;
      if (this.cardViewnoOfrecordsPerPage == undefined || this.cardViewnoOfrecordsPerPage == null) {
        this.cardViewnoOfrecordsPerPage = 20;
      }
      this.listViewnoOfrecordsPerPage = this.clientdetailUserPreference.listViewPageNumber;
      if (this.listViewnoOfrecordsPerPage == undefined || this.listViewnoOfrecordsPerPage == null) {
        this.listViewnoOfrecordsPerPage = 20;
      }
      this.orderByType = JSON.parse(this.clientdetailUserPreference.listViewSortingList).orderByType;
      this.dashBoardViewtypeName = this.clientdetailUserPreference.dashboardViewType;
      if (this.dashBoardViewtypeName == undefined || this.dashBoardViewtypeName == null) {
        this.dashBoardViewtypeName = 'Card';
      }
    }
    this.setPageRecordRange();
    this.setDefaultInitialization();
    this.LoadListViewHeaders();
    var contactPopup = $('#contact-popup');
    contactPopup.hide();

    if (this.route.url.indexOf('ClientDetails') !== -1) {
      this.isMyEngagementLevel = true;
      //this.getEngagementListByClient(this.passedClientId);
      this.GetMyEngagementUniqueDataByClientId(this.passedClientId);
    } else {
      this.isMyEngagementLevel = false;
    }
    this.GetEngagementFilterSectionUniqueData();
    this.isClientEngagements = this.route.url.indexOf('ClientDetails') !== -1;
    if (this.dashBoardViewtypeName === 'Card') {
      setTimeout(() => {
        this.SetUserPreferenceCardDropDownListValues();
      }, 500);
    }
    if (this.dashBoardViewtypeName === 'List') {
      this.SetUserPreferenceListDropDownListValues();
    }

  }


  SetUserPreferenceCardDropDownListValues() {
    $('.Eng-CardView-Div').show();
    $('.Eng-ListView-Div').hide();
    if (this.passedClientId > 0) {
      this.getMyEngagementCountByClientId('OnInit', this.passedClientId);
      if (this.engagementCardViewForClientFilterModel.solutionCodeId > 0 || this.engagementCardViewForClientFilterModel.industryTypeId > 0 ||
        this.engagementCardViewForClientFilterModel.engagementStatusId != "" || this.engagementCardViewForClientFilterModel.eppmdEmail != "") {
        $("#filterEngagementCardView").addClass("partialActive");
      }
      if (this.engagementCardViewForClientFilterModel.sortSolutionCodeId || this.engagementCardViewForClientFilterModel.sortIndustryTypeId ||
        this.engagementCardViewForClientFilterModel.sortEngagementStatusId || this.engagementCardViewForClientFilterModel.sortEppmdEmailId || this.engagementCardViewForClientFilterModel.sortLastActivityDate) {
        $("#sortEngagementCardView").addClass("partialActive");
      }



    } else {
      this.getMyEngagementByUserCount('OnInit');
      if (this.engagementCardViewFilterModel.clientId > 0 || this.engagementCardViewFilterModel.solutionCodeId > 0 || this.engagementCardViewFilterModel.industryTypeId > 0 ||
        this.engagementCardViewFilterModel.engagementStatusId != "" || this.engagementCardViewFilterModel.eppmdEmail != "") {
        $("#filterEngagementCardView").addClass("partialActive");
      }
      if (this.engagementCardViewFilterModel.sortClientId || this.engagementCardViewFilterModel.sortSolutionCodeId || this.engagementCardViewFilterModel.sortIndustryTypeId ||
        this.engagementCardViewFilterModel.sortEngagementStatusId || this.engagementCardViewFilterModel.sortEppmdEmailId || this.engagementCardViewFilterModel.sortLastActivityDate) {
        $("#sortEngagementCardView").addClass("partialActive");
      }

    }



  }
  SetUserPreferenceListDropDownListValues() {
    $('.Eng-CardView-Div').hide();
    $('.Eng-ListView-Div').show();
    if (this.passedClientId > 0) {
      this.getMyEngagementListCountByClientId('OnInit', this.passedClientId);
      if (this.engagementListViewForClientFilterModel.solutionCodeId > 0 || this.engagementListViewForClientFilterModel.industryTypeId > 0 ||
        this.engagementListViewForClientFilterModel.engagementStatusId != "" || this.engagementListViewForClientFilterModel.eppmdEmail != "") {
        $("#filterEngagementListView").addClass("partialActive");
      }


    } else {
      this.getMyEngagementListByUserCount();
      if (this.engagementListViewFilterModel.clientId > 0 || this.engagementListViewFilterModel.solutionCodeId > 0 || this.engagementListViewFilterModel.industryTypeId > 0 ||
        this.engagementListViewFilterModel.engagementStatusId != "" || this.engagementListViewFilterModel.eppmdEmail != "") {
        $("#filterEngagementListView").addClass("partialActive");
      }

    }


  }

  GetEngagementFilterSectionUniqueData() {
    this.engService.GetEngagementFilterSectionUniqueData(this.isloggedInUserAdmin).subscribe(
      result => {
        this.engagementFilterUniqueDataList = result;
        this.clientsList = this.engagementFilterUniqueDataList.clientsList;
        this.solutionCodeList = this.engagementFilterUniqueDataList.solutionCodeList;
        this.eppmdList = this.engagementFilterUniqueDataList.eppmdList;
        this.industryList = this.engagementFilterUniqueDataList.industryList;
        this.engagementStatusList = this.engagementFilterUniqueDataList.engagementStatusList;
      },
      error => {
      }
    );
  }

  GetMyEngagementUniqueDataByClientId(clientId: number) {
    this.engService.GetMyEngagementUniqueDataByClientId(this.isloggedInUserAdmin, clientId).subscribe(
      result => {
        this.engagementFilterUniqueDataByClientIdList = result;
        this.solutionCodeListForClient = this.engagementFilterUniqueDataByClientIdList.solutionCodeList;
        this.eppmdListForClient = this.engagementFilterUniqueDataByClientIdList.eppmdList;
        this.industryListForClient = this.engagementFilterUniqueDataByClientIdList.industryList;
        this.engagementStatusListForClient = this.engagementFilterUniqueDataByClientIdList.engagementStatusList;
      },
      error => {
      }
    );
  }
  ShowAllEngagementsWithNoFilters(paramView: string) {
    this.cardViewCurrentPageNumber = 1;
    this.listViewCurrentPageNumber = 1;
    if (paramView == "CardView") {
      if (this.passedClientId > 0) {
        this.setDefaultInitialization();
        this.ClearCardViewFilterFieldsForClient();
        this.ClearCardViewSortFieldsForClientView();
        this.getMyEngagementCountByClientId('OnInit', this.passedClientId);
        $("#filterEngagementCardView").removeClass("active");
        $("#sortEngagementCardView").removeClass("active");
        $("#showAllEngagementCardView").addClass("active");
        if (!(this.engagementCardViewForClientFilterModel.solutionCodeId > 0 || this.engagementCardViewForClientFilterModel.industryTypeId > 0 ||
          this.engagementCardViewForClientFilterModel.engagementStatusId != "" || this.engagementCardViewForClientFilterModel.eppmdEmail != "")) {
          $("#filterEngagementCardView").removeClass("partialActive");
        }
        if (!(this.engagementCardViewForClientFilterModel.sortSolutionCodeId || this.engagementCardViewForClientFilterModel.sortIndustryTypeId ||
          this.engagementCardViewForClientFilterModel.sortEngagementStatusId || this.engagementCardViewForClientFilterModel.sortEppmdEmailId || this.engagementCardViewForClientFilterModel.sortLastActivityDate)) {
          $("#sortEngagementCardView").removeClass("partialActive");
        }
        this.InsertUpdateUserPreferenceData();
      }
      else {
        this.setDefaultInitialization();
        this.ClearCardViewFilterFields();
        this.ClearCardViewSortFields();
        this.InsertUpdateUserPreferenceData();
        this.getMyEngagementByUserCount('ShowAll');
        $("#filterEngagementCardView").removeClass("active");
        $("#sortEngagementCardView").removeClass("active");
        $("#showAllEngagementCardView").addClass("active");
        if (!(this.engagementCardViewFilterModel.clientId > 0 || this.engagementCardViewFilterModel.solutionCodeId > 0 || this.engagementCardViewFilterModel.industryTypeId > 0 ||
          this.engagementCardViewFilterModel.engagementStatusId != "" || this.engagementCardViewFilterModel.eppmdEmail != "")) {
          $("#filterEngagementCardView").removeClass("partialActive");
        }
        if (!(this.engagementCardViewFilterModel.sortClientId || this.engagementCardViewFilterModel.sortSolutionCodeId || this.engagementCardViewFilterModel.sortIndustryTypeId ||
          this.engagementCardViewFilterModel.sortEngagementStatusId || this.engagementCardViewFilterModel.sortEppmdEmailId || this.engagementCardViewFilterModel.sortLastActivityDate)) {
          $("#sortEngagementCardView").removeClass("partialActive");
        }

      }
    }
    if (paramView == "ListView") {
      if (this.passedClientId > 0) {
        this.setDefaultInitialization();
        this.ClearListViewFilterFieldsForClient();
        this.getMyEngagementListCountByClientId('OnInit', this.passedClientId);
        $("#filterEngagementListView").removeClass("active");
        if (!(this.engagementListViewForClientFilterModel.solutionCodeId > 0 || this.engagementListViewForClientFilterModel.industryTypeId > 0 ||
          this.engagementListViewForClientFilterModel.engagementStatusId != "" || this.engagementListViewForClientFilterModel.eppmdEmail != "")) {
          $("#filterEngagementListView").removeClass("partialActive");
        }
        $("#showAllEngagementListView").addClass("active");
        this.InsertUpdateUserPreferenceData();
      }
      else {
        this.setDefaultInitialization();
        this.ClearListViewFilterFields();
        this.LoadListViewHeaders();
        this.InsertUpdateUserPreferenceData();
        this.getMyEngagementListByUserCount();
        $("#filterEngagementListView").removeClass("active");
        if (!(this.engagementListViewFilterModel.clientId > 0 || this.engagementListViewFilterModel.solutionCodeId > 0 || this.engagementListViewForClientFilterModel.industryTypeId > 0 ||
          this.engagementListViewForClientFilterModel.engagementStatusId != "" || this.engagementListViewForClientFilterModel.eppmdEmail != "")) {
          $("#filterEngagementListView").removeClass("partialActive");
        }
        $("#showAllEngagementListView").addClass("active");

      }
    }
  }

  GetAllEngagementsByFiltersForClient() {
    this.isClientCardViewApplyResetButtonClicked = true;
    this.cardViewCurrentPageNumber = 1;
    this.getMyEngagementCountByClientId('ApplyClick', this.passedClientId);
    $("#filterEngagementCardView").removeClass("active");
    if (this.engagementCardViewForClientFilterModel.solutionCodeId > 0 || this.engagementCardViewForClientFilterModel.industryTypeId > 0 ||
      this.engagementCardViewForClientFilterModel.engagementStatusId != "" || this.engagementCardViewForClientFilterModel.eppmdEmail != "") {
      $("#filterEngagementCardView").addClass("partialActive");
    }
    if (this.engagementCardViewForClientFilterModel.sortSolutionCodeId || this.engagementCardViewForClientFilterModel.sortIndustryTypeId ||
      this.engagementCardViewForClientFilterModel.sortEngagementStatusId || this.engagementCardViewForClientFilterModel.sortEppmdEmailId || this.engagementCardViewForClientFilterModel.sortLastActivityDate) {
      $("#sortEngagementCardView").addClass("partialActive");
    }

    this.InsertUpdateUserPreferenceData();
    $('#cardViewFilterSectionForMyClient').hide();
  }

  GetAllEngagementsListByFiltersForClientView() {
    this.isClientListViewApplyResetButtonClicked = true;
    this.listViewCurrentPageNumber = 1;
    this.InsertUpdateUserPreferenceData();
    this.getMyEngagementListCountByClientId('ApplyClick', this.passedClientId);
    $("#filterEngagementListView").removeClass("active");
    if (this.engagementListViewForClientFilterModel.solutionCodeId > 0 || this.engagementListViewForClientFilterModel.industryTypeId > 0 ||
      this.engagementListViewForClientFilterModel.engagementStatusId != "" || this.engagementListViewForClientFilterModel.eppmdEmail != "") {
      $("#filterEngagementListView").addClass("partialActive");
    }


    $('#listViewFilterSectionForClient').hide();
  }

  GetAllEngagementsByFilters() {
    this.isCardViewApplyResetButtonClicked = true;
    this.cardViewCurrentPageNumber = 1;
    this.getMyEngagementByUserCount('ApplyClick');
    $("#filterEngagementCardView").removeClass("active");
    if (this.engagementCardViewFilterModel.clientId > 0 || this.engagementCardViewFilterModel.solutionCodeId > 0 || this.engagementCardViewFilterModel.industryTypeId > 0 ||
      this.engagementCardViewFilterModel.engagementStatusId != "" || this.engagementCardViewFilterModel.eppmdEmail != "") {
      $("#filterEngagementCardView").addClass("partialActive");
    }

    this.InsertUpdateUserPreferenceData();
    $('#cardViewFilterSection').hide();
  }
  GetAllSortedEngagementsByFilters() {
    this.isCardViewSortApplyResetButtonClicked = true;
    this.sortOrderByType = $('#ddListOrderByType').val();
    this.cardViewCurrentPageNumber = 1;
    this.getMyEngagementByUserCount('ApplyClick');
    $("#sortEngagementCardView").removeClass("active");
    if (this.engagementCardViewFilterModel.sortClientId || this.engagementCardViewFilterModel.sortSolutionCodeId || this.engagementCardViewFilterModel.sortIndustryTypeId ||
      this.engagementCardViewFilterModel.sortEngagementStatusId || this.engagementCardViewFilterModel.sortEppmdEmailId || this.engagementCardViewFilterModel.sortLastActivityDate) {
      $("#sortEngagementCardView").addClass("partialActive");
    }
    this.InsertUpdateUserPreferenceData();
    $('#cardViewSortSection').hide();
  }

  onReset_GetAllSortedEngagementsByFilters() {
    this.sortOrderByType = $('#ddListOrderByType').val();
    this.cardViewCurrentPageNumber = 1;
    this.getMyEngagementByUserCount('ApplyClick');
  }
  GetAllSortedEngagementsByFiltersForClient() {
    this.isClientCardViewSortApplyResetButtonClicked = true;
    this.sortOrderByTypeForClient = $('#ddListOrderByTypeForClient').val();
    this.cardViewCurrentPageNumber = 1;
    this.getMyEngagementCountByClientId('ApplyClick', this.passedClientId);
    $("#sortEngagementCardView").removeClass("active");
    if (this.engagementCardViewForClientFilterModel.solutionCodeId > 0 || this.engagementCardViewForClientFilterModel.industryTypeId > 0 ||
      this.engagementCardViewForClientFilterModel.engagementStatusId != "" || this.engagementCardViewForClientFilterModel.eppmdEmail != "") {
      $("#filterEngagementCardView").addClass("partialActive");
    }
    if (this.engagementCardViewForClientFilterModel.sortSolutionCodeId || this.engagementCardViewForClientFilterModel.sortIndustryTypeId ||
      this.engagementCardViewForClientFilterModel.sortEngagementStatusId || this.engagementCardViewForClientFilterModel.sortEppmdEmailId || this.engagementCardViewForClientFilterModel.sortLastActivityDate) {
      $("#sortEngagementCardView").addClass("partialActive");
    }
    this.InsertUpdateUserPreferenceData();
    $('#cardViewSortSectionForClient').hide();
  }

  GetAllEngagementsListByFilters() {
    this.isListViewApplyResetButtonClicked = true;
    this.listViewCurrentPageNumber = 1;
    this.getMyEngagementListByUserCount();
    $("#filterEngagementListView").removeClass("active");
    if (this.engagementListViewFilterModel.clientId > 0 || this.engagementListViewFilterModel.solutionCodeId > 0 || this.engagementListViewFilterModel.industryTypeId > 0 ||
      this.engagementListViewFilterModel.engagementStatusId != "" || this.engagementListViewFilterModel.eppmdEmail != "") {
      $("#filterEngagementListView").addClass("partialActive");
    }
    this.InsertUpdateUserPreferenceData();
    $('#listViewFilterSection').hide();
  }

  ShowHideFilterPanel(SelectedView: string,) {
    switch (SelectedView) {
      case 'Show':
        if (this.passedClientId > 0) {
          this.isClientCardViewApplyResetButtonClicked = false;
          $('#cardViewFilterSectionForMyClient').show();
          $('#cardViewSortSectionForClient').hide();
          $("#filterEngagementCardView").addClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");
        }
        else {
          this.isCardViewApplyResetButtonClicked = false;
          $('#cardViewFilterSection').show();
          $('#cardViewSortSection').hide();
          $("#filterEngagementCardView").addClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");
        }
        break;
      case 'Hide':
        if (this.passedClientId > 0) {
          this.isCardViewApplyResetButtonClicked = false;
          $('#cardViewFilterSectionForMyClient').hide();
          $('#cardViewSortSection').hide();
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");
        }
        else {
          $('#cardViewFilterSection').hide();
          $('#cardViewSortSection').hide();
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");
        }
        break;
      case 'HideWithCancel':
        if (this.passedClientId > 0) {
          $('#cardViewFilterSectionForMyClient').hide();
          $('#cardViewSortSection').hide();
          this.ClearCardViewFilterFieldsForClient();
          this.getMyEngagementCountByClientId('ApplyClick', this.passedClientId);
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");
        }
        else {
          this.ClearCardViewFilterFields();
          $('#cardViewFilterSection').hide();
          $('#cardViewSortSection').hide();
          this.getMyEngagementByUserCount('OnInit');
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        break;
      case 'Reset':

        if (this.passedClientId > 0) {
          this.isClientCardViewApplyResetButtonClicked = true;
          this.ResetCardViewFilterFieldsForClient();
          if (!(this.engagementCardViewForClientFilterModel.solutionCodeId > 0 || this.engagementCardViewForClientFilterModel.industryTypeId > 0 ||
            this.engagementCardViewForClientFilterModel.engagementStatusId != "" || this.engagementCardViewForClientFilterModel.eppmdEmail != "")) {
            $("#filterEngagementCardView").removeClass("partialActive");
          }
          this.InsertUpdateUserPreferenceData();
          this.getMyEngagementCountByClientId('ApplyClick', this.passedClientId);

        }
        else {
          this.isCardViewApplyResetButtonClicked = true;
          this.ResetCardViewFilterFields();
          if (!(this.engagementCardViewFilterModel.clientId > 0 || this.engagementCardViewFilterModel.solutionCodeId > 0 || this.engagementCardViewFilterModel.industryTypeId > 0 ||
            this.engagementCardViewFilterModel.engagementStatusId != "" || this.engagementCardViewFilterModel.eppmdEmail != "")) {
            $("#filterEngagementCardView").removeClass("partialActive");
          }
          this.InsertUpdateUserPreferenceData();
          this.getMyEngagementByUserCount('OnInit');
        }
        break;
      default:
        $('#cardViewFilterSection').hide();
        $("#filterEngagementCardView").removeClass("active");
        $("#sortEngagementCardView").removeClass("active");
        $("#showAllEngagementCardView").removeClass("active");

        break;
    }
  }

  ShowHideListFilterPanel(SelectedView: string) {
    switch (SelectedView) {
      case 'Show':
        if (this.passedClientId > 0) {
          this.isClientListViewApplyResetButtonClicked = false;
          $('#listViewFilterSectionForClient').show();
          $('#listViewFilterSection').hide();
          $("#filterEngagementListView").addClass("active");
          $("#showAllEngagementListView").removeClass("active");

        }
        else {
          this.isListViewApplyResetButtonClicked = false;
          $('#listViewFilterSection').show();
          $("#filterEngagementListView").addClass("active");
          $("#showAllEngagementListView").removeClass("active");

        }
        break;
      case 'Hide':
        if (this.passedClientId > 0) {
          this.isClientListViewApplyResetButtonClicked = false;
          $('#listViewFilterSectionForClient').hide();
          $('#listViewFilterSection').hide();
          $("#filterEngagementListView").removeClass("active");
          $("#showAllEngagementListView").removeClass("active");


        }
        else {
          this.isListViewApplyResetButtonClicked = false;
          $('#listViewFilterSection').hide();
          $("#filterEngagementListView").removeClass("active");
          $("#showAllEngagementListView").removeClass("active");

        }
        break;
      case 'HideWithCancel':
        if (this.passedClientId > 0) {
          $('#listViewFilterSectionForClient').hide();
          $('#listViewSortSection').hide();
          this.ClearListViewFilterFieldsForClient();
          this.getMyEngagementListCountByClientId('ApplyClick', this.passedClientId);
          $("#filterEngagementListView").addClass("active");
          $("#showAllEngagementListView").removeClass("active");

        }
        else {
          this.ClearListViewFilterFields();
          $('#cardViewFilterSection').hide();
          $('#cardViewSortSection').hide();
          this.getMyEngagementListByUserCount();
          $("#filterEngagementListView").removeClass("active");
          $("#showAllEngagementListView").removeClass("active");

        }
        break;
      case 'Reset':
        if (this.passedClientId > 0) {
          this.isClientListViewApplyResetButtonClicked = true;
          this.ResetListViewFilterFieldsForClient();
          if (!(this.engagementListViewForClientFilterModel.solutionCodeId > 0 || this.engagementListViewForClientFilterModel.industryTypeId > 0 ||
            this.engagementListViewForClientFilterModel.engagementStatusId != "" || this.engagementListViewForClientFilterModel.eppmdEmail != "")) {
            $("#filterEngagementListView").removeClass("partialActive");
          }
          this.InsertUpdateUserPreferenceData();
          this.getMyEngagementListCountByClientId('ApplyClick', this.passedClientId);

        }
        else {
          this.isListViewApplyResetButtonClicked = true;
          this.ResetListViewFilterFields();
          if (!(this.engagementListViewFilterModel.clientId > 0 || this.engagementListViewFilterModel.solutionCodeId > 0 || this.engagementListViewFilterModel.industryTypeId > 0 ||
            this.engagementListViewFilterModel.engagementStatusId != "" || this.engagementListViewFilterModel.eppmdEmail != "")) {
            $("#filterEngagementListView").removeClass("partialActive");
          }
          this.InsertUpdateUserPreferenceData();

          this.getMyEngagementListByUserCount();
        }
        break;
      default:
        $('#listViewFilterSection').hide();
        $("#filterEngagementListView").removeClass("active");
        $("#showAllEngagementListView").removeClass("active");
        $("#filterEngagementCardView").removeClass("active");
        $("#showAllEngagementCardView").removeClass("active");

        break;
    }
  }

  ShowHideFilterSortPanel(SelectedView: string) {
    switch (SelectedView) {
      case 'Show':
        if (this.passedClientId > 0) {
          this.isClientCardViewSortApplyResetButtonClicked = false;
          $('#cardViewSortSectionForClient').show();
          $('#cardViewFilterSectionForMyClient').hide();
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").addClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        else {
          this.isCardViewSortApplyResetButtonClicked = false;
          $('#cardViewSortSection').show();
          $('#cardViewFilterSection').hide();
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").addClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        break;
      case 'Hide':
        if (this.passedClientId > 0) {
          this.isClientCardViewSortApplyResetButtonClicked = false;
          $('#cardViewSortSectionForClient').hide();
          $('#cardViewFilterSection').hide();
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        else {
          this.isCardViewSortApplyResetButtonClicked = false;
          $('#cardViewSortSection').hide();
          $('#cardViewFilterSection').hide();
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        break;
      case 'HideWithCancel':
        if (this.passedClientId > 0) {
          this.setDefaultInitialization();
          this.ClearCardViewSortFieldsForClientView();
          this.getMyEngagementCountByClientId('OnInit', this.passedClientId);
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        else {
          this.setDefaultInitialization();
          this.ClearCardViewSortFields();
          this.getMyEngagementByUserCount('OnInit');
          $("#filterEngagementCardView").removeClass("active");
          $("#sortEngagementCardView").removeClass("active");
          $("#showAllEngagementCardView").removeClass("active");

        }
        break;
      case 'Reset':
        if (this.passedClientId > 0) {
          this.isClientCardViewSortApplyResetButtonClicked = true;
          this.setDefaultInitialization();
          this.ResetCardViewSortFieldsForClientView();
          if (!(this.engagementCardViewForClientFilterModel.sortSolutionCodeId || this.engagementCardViewFilterModel.sortIndustryTypeId ||
            this.engagementCardViewForClientFilterModel.sortEngagementStatusId || this.engagementCardViewForClientFilterModel.sortEppmdEmailId || this.engagementCardViewForClientFilterModel.sortLastActivityDate)) {
            $("#sortEngagementCardView").removeClass("partialActive");
          }
          this.InsertUpdateUserPreferenceData();
          this.getMyEngagementCountByClientId('OnInit', this.passedClientId);

        }
        else {
          this.isCardViewSortApplyResetButtonClicked = true;
          this.setDefaultInitialization();
          this.ResetCardViewSortFields();
          if (!(this.engagementCardViewFilterModel.sortClientId || this.engagementCardViewFilterModel.sortSolutionCodeId || this.engagementCardViewFilterModel.sortIndustryTypeId ||
            this.engagementCardViewFilterModel.sortEngagementStatusId || this.engagementCardViewFilterModel.sortEppmdEmailId || this.engagementCardViewFilterModel.sortLastActivityDate)) {
            $("#sortEngagementCardView").removeClass("partialActive");
          }
          this.InsertUpdateUserPreferenceData();
          this.onReset_GetAllSortedEngagementsByFilters();

        }
        break;
      default:
        $('#cardViewSortSectionForClient').hide();
        $('#cardViewSortSection').hide();
        $("#filterEngagementCardView").removeClass("active");
        $("#sortEngagementCardView").removeClass("active");
        $("#showAllEngagementCardView").removeClass("active");
        break;
    }
  }
  ClearCardViewFilterFields() {
    this.engagementCardViewFilterModel = new EngagementCardViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, true, this.userName);
    $('#txtValueClientMappedValue0').val("");
    $('#ddClientsForEngagementList').val(0);
    $('input:hidden').val('');
    $('#ddSolutionCodeList').val(0);
    $('#ddEppmdList').val('');
    $('#ddIndustryTypeList').val(0);
    $('#ddEngagementStatusList').val('');
    $('#cardViewFilterSection').hide();
  }

  ResetCardViewFilterFields() {
    this.engagementCardViewFilterModel = new EngagementCardViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, true, this.userName);
    this.cardViewCurrentPageNumber = 1;
    $('#txtValueClientMappedValue0').val("");
    $('#ddClientsForEngagementList').val(0);
    $('input:hidden').val('');
    $('#ddSolutionCodeList').val(0);
    $('#ddEppmdList').val('');
    $('#ddIndustryTypeList').val(0);
    $('#ddEngagementStatusList').val('');
    //$('#cardViewFilterSection').hide();
  }

  ClearCardViewFilterFieldsForClient() {
    this.engagementCardViewForClientFilterModel = new EngagementCardViewForClientFilterModel(0, '', 0, '', false, false, false, false, false, this.userName);
    $('#ddSolutionCodeListForClient').val(0);
    $('#ddEppmdListForClient').val('');
    $('#ddIndustryTypeListForClient').val(0);
    $('#ddEngagementStatusListForClient').val('');
    $('#cardViewFilterSectionForMyClient').hide();
  }
  ResetCardViewFilterFieldsForClient() {
    this.engagementCardViewForClientFilterModel = new EngagementCardViewForClientFilterModel(0, '', 0, '', false, false, false, false, true, this.userName);
    this.cardViewCurrentPageNumber = 1;
    $('#ddSolutionCodeListForClient').val(0);
    $('#ddEppmdListForClient').val('');
    $('#ddIndustryTypeListForClient').val(0);
    $('#ddEngagementStatusListForClient').val('');
  }

  ClearListViewFilterFieldsForClient() {
    this.engagementListViewForClientFilterModel = new EngagementListViewForClientFilterModel(0, '', 0, '', false, false, false, false, false, this.userName);
    $('#ddListSolutionCodeForClientListView').val(0);
    $('#ddListEppmdForClientListView').val(0);
    $('#ddListIndustryTypeForClientListView').val(0);
    $('#ddListEngagementStatusForClientListView').val('');
    $('#listViewFilterSectionForMyClient').hide();
  }
  ResetListViewFilterFieldsForClient() {
    this.engagementListViewForClientFilterModel = new EngagementListViewForClientFilterModel(0, '', 0, '', false, false, false, false, false, this.userName);
    this.listViewCurrentPageNumber = 1;
    $('#ddListSolutionCodeForClientListView').val(0);
    $('#ddListEppmdForClientListView').val("");
    $('#ddListIndustryTypeForClientListView').val(0);
    $('#ddListEngagementStatusForClientListView').val('');
  }
  ClearListViewFilterFields() {
    this.engagementListViewFilterModel = new EngagementListViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, false, this.userName);
    $('#txtValueClientListMappedValue0').val("");
    $('#ddListClientsForEngagement').val(0);
    $('input:hidden').val('');
    $('#ddListSolutionCode').val(0);
    $('#ddListEppmd').val('');
    $('#ddListIndustryType').val(0);
    $('#ddListEngagementStatus').val('');
    $('#listViewFilterSection').hide();
  }
  ResetListViewFilterFields() {
    this.engagementListViewFilterModel = new EngagementListViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, false, this.userName);
    this.listViewCurrentPageNumber = 1;
    $('#txtValueClientListMappedValue0').val("");
    $('#ddListClientsForEngagement').val(0);
    $('input:hidden').val('');
    $('#ddListSolutionCode').val(0);
    $('#ddListEppmd').val('');
    $('#ddListIndustryType').val(0);
    $('#ddListEngagementStatus').val('');
  }
  ClearCardViewSortFields() {
    this.engagementCardViewFilterModel = new EngagementCardViewFilterModel(0, 0, '', 0, '', false, false, false, false, false, true, this.userName);
    $('#chkClientName').prop('checked', false);
    $('#chkSolutionCode').prop('checked', false);
    $('#chkEppmd').prop('checked', false);
    $('#chkIndustryType').prop('checked', false);
    $('#chkEngagementStatus').prop('checked', false);
    $('#chkLastActivityDate').prop('checked', true);
    $('#cardViewSortSection').hide();
    $('#ddListOrderByType').val('DESC');
    this.sortOrderByType = 'DESC';
  }

  ResetCardViewSortFields() {
    this.engagementCardViewFilterModel.sortClientId = false;
    this.engagementCardViewFilterModel.sortSolutionCodeId = false;
    this.engagementCardViewFilterModel.sortEppmdEmailId = false;
    this.engagementCardViewFilterModel.sortIndustryTypeId = false;
    this.engagementCardViewFilterModel.sortEngagementStatusId = false;
    this.engagementCardViewFilterModel.sortLastActivityDate = true;
    $('#chkClientName').prop('checked', false);
    $('#chkSolutionCode').prop('checked', false);
    $('#chkEppmd').prop('checked', false);
    $('#chkIndustryType').prop('checked', false);
    $('#chkEngagementStatus').prop('checked', false);
    $('#chkLastActivityDate').prop('checked', true);
    $('#ddListOrderByType').val('DESC');
  }

  ClearCardViewSortFieldsForClientView() {
    this.engagementCardViewForClientFilterModel = new EngagementCardViewForClientFilterModel(0, '', 0, '', false, false, false, false, true, this.userName);
    $('#chkSolutionCodeForClient').prop('checked', false);
    $('#chkEppmdForClient').prop('checked', false);
    $('#chkIndustryTypeForClient').prop('checked', false);
    $('#chkEngagementStatusForClient').prop('checked', false);
    $('#chkLastActivityDateForClient').prop('checked', true);
    $('#cardViewSortSectionForClient').hide();
    $('#ddListOrderByTypeForClient').val('DESC');
  }

  ResetCardViewSortFieldsForClientView() {
    this.engagementCardViewForClientFilterModel.sortSolutionCodeId = false;
    this.engagementCardViewForClientFilterModel.sortEppmdEmailId = false;
    this.engagementCardViewForClientFilterModel.sortIndustryTypeId = false;
    this.engagementCardViewForClientFilterModel.sortEngagementStatusId = false;
    this.engagementCardViewForClientFilterModel.sortLastActivityDate = true;
    this.cardViewCurrentPageNumber = 1;
    $('#chkSolutionCodeForClient').prop('checked', false);
    $('#chkEppmdForClient').prop('checked', false);
    $('#chkIndustryTypeForClient').prop('checked', false);
    $('#chkEngagementStatusForClient').prop('checked', false);
    $('#chkLastActivityDateForClient').prop('checked', true);
    $('#ddListOrderByTypeForClient').val('DESC');
  }

  ngAfterViewChecked() {
    // console.log('inside ', this.applyFilterOnceAfterDataLoad);
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.BindContactPopup();
      this.applyFilterOnceAfterDataLoad = false;
    }
  }


  closeContactPopupListView() {
    $('html').on('click', function (e) {
      console.log('first', $(e.target).parents().is('.popover.in'));
      console.log('second', $(e.target).data('original-title'));

      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#' + ContactCardClick.listViewInput).val();
        $('#ePPMDcontactPopup_' + id).popover('hide');
        console.log('xxx11', $('#ePPMDcontactPopup_' + id));
        console.log('xxx', '#' + ContactCardControl.listViewdiv + id);
      }
    });

    $('body').on('click', function (e) {
      console.log('body click event fired', $(e.target).is('.' + ContactCardControl.close));
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.listViewInput).val();
          $('#ePPMDcontactPopup_' + id).popover('hide');
        }
      }
    });

  }

  closeContactPopupCardView() {

    $('html').on('click', function (e) {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#cardViewtxtengId').val();
        $('#cardViewEPPMDcontactPopup_' + id).popover('hide');
      }
    });

    $('body').on('click', function (e) {
      //did not click a popover toggle or popover
      console.log('body click event fired', $(e.target).is('.contact-close'));
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          console.log('condition met');
          console.log('listViewtxtengId ==>', $('#cardViewtxtengId').val());
          const id = $('#cardViewtxtengId').val();
          $('#cardViewEPPMDcontactPopup_' + id).popover('hide');
        }
      }
    });

  }

  ngAfterViewInit() {
    this.closeContactPopupListView();
    this.closeContactPopupCardView();
  }

  setDefaultInitialization() {
    this.applyFilterOnceAfterDataLoad = false;
    this.orderByColumn = 'LastActivityDate';
    this.orderByType = 'DESC';
    if (this.userPreferenceData.length > 0 && this.engagementUserPreference != null && this.passedClientId <= 0) {
      this.orderByColumn = JSON.parse(this.engagementUserPreference.listViewSortingList).orderByColumn;
      if (this.orderByColumn == undefined || this.orderByColumn == null) {
        this.orderByColumn = 'LastActivityDate';
      }
      this.orderByType = JSON.parse(this.engagementUserPreference.listViewSortingList).orderByType;
      if (this.orderByType == undefined || this.orderByType == null) {
        this.orderByType = 'DESC';
      }
    }
    if (this.userPreferenceData.length > 0 && this.clientdetailUserPreference != null && this.passedClientId > 0) {
      this.orderByColumn = JSON.parse(this.clientdetailUserPreference.listViewSortingList).orderByColumn;
      if (this.orderByColumn == undefined || this.orderByColumn == null) {
        this.orderByColumn = 'LastActivityDate';
      }
      this.orderByType = JSON.parse(this.clientdetailUserPreference.listViewSortingList).orderByType;
      if (this.orderByType == undefined || this.orderByType == null) {
        this.orderByType = 'DESC';
      }
    }
    this.cardVieworderByColumn = 'LastActivityDate';
    this.cardVieworderByType = 'DESC';
  }

  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userObject);
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isloggedInUserAdmin = 'yes';
          break;
        }
        else {
          this.isloggedInUserAdmin = 'no';
        }
        if (this.userObject.role[i].shortRoleName == 'QG') {
          this.isQualityUserLoggedIn = true;
        }
      }
    }
  }

  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(10);
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(40);
    this.noOfrecordsPerPageList.push(60);
    this.noOfrecordsPerPageList.push(80);
    this.noOfrecordsPerPageList.push(100);
  }

  getMyEngagementCountByClientId(isOnInitFlag: string, clientId: number) {
    this.loadingService.startLoading();
    this.engService.getMyEngagementCountByClientId(this.engagementCardViewForClientFilterModel,
      this.isloggedInUserAdmin,
      clientId).subscribe(
        result => {
          this.engagementCountByClientId = result;
          if (isOnInitFlag == 'OnInit') {
            this.cardCount = result;
          }
          this.loadingService.stopLoading();
          if (this.engagementCountByClientId > 0) {
            // initialize to page 1
            this.cardViewSetPageByClientId(this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage, clientId);
          }
          else {
            this.cardCount = 0;
            this.loadingService.stopLoading();
            this.engagementCardList = null;
            if (this.cardCount === 0) {
              this.DataMessage = ' No Data Available';
            }
          }
        },
        error => {
          this.loadingService.stopLoading();
        }
      );
  }

  getMyEngagementListCountByClientId(isOnInitFlag: string, clientId: number) {
    this.loadingService.startLoading();
    this.engService.getMyEngagementCountByClientId(this.engagementListViewForClientFilterModel,
      this.isloggedInUserAdmin,
      clientId).subscribe(
        result => {
          this.engagementListCountByClientId = result;
          this.loadingService.stopLoading();
         
          if (isOnInitFlag == 'OnInit') {
            this.listViewCount = result;
          }
          if (this.engagementListCountByClientId > 0) {
            // initialize to page 1
            this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
          }
          else {
            this.listCount = 0;
            this.engagementList = null;
            if (this.listCount === 0) {
              this.listDataMessage = ' No Data Available';
            }
            this.loadingService.stopLoading();
          }
        },
        error => {
          this.loadingService.stopLoading();
        }
      );
  }

  getMyEngagementByUserCount(isOnInitFlag: string) {
    this.startLoading();
    this.engService.getMyEngagementCardViewByUserCount(this.isloggedInUserAdmin, this.engagementCardViewFilterModel).subscribe(
      result => {
        this.engagementCount = result;
        this.stopLoading();
        if (isOnInitFlag == 'OnInit') {
          this.listViewCount = result;
        }
        if (this.engagementCount > 0) {
          this.cardViewSetPage(this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
        }
        else {
          this.cardCount = 0;
          this.engagementCardList = null;
          if (this.cardCount === 0) {
            this.DataMessage = ' No Data Available';
          }
        }
      },
      error => {
        this.stopLoading();
      }
    );
  }
  getMyEngagementListByUserCount() {
    this.startLoading();
    this.engService.getMyEngagementListViewByUserCount(this.isloggedInUserAdmin, this.engagementListViewFilterModel).subscribe(
      result => {
        this.engagementListCount = result;
        this.stopLoading();
        if (this.engagementListCount > 0) {
          this.isListFlag = true;
          this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
        }
        else {
          this.isListFlag = false;
          this.listCount = 0;
          this.engagementList = null;
          if (this.listCount === 0) {
            this.listDataMessage = ' No Data Available';
          }
        }
      },
      error => {
        this.stopLoading();
      }
    );
  }

  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service this.passedClientId
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewnoOfrecordsPerPage = recordsPerPage;
    } else {
      this.listViewnoOfrecordsPerPage = Number(inputDrpValue);
    }

    this.InsertUpdateUserPreferenceData();


    if (this.listViewCurrentPageNumber != 0) {
      if (this.passedClientId > 0) {
        this.getMyEngagementListViewByClientId(this.engagementListViewForClientFilterModel,
          this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage,
          this.orderByColumn, this.orderByType, this.passedClientId);

        this.pager = this.pagerService.getPager(this.engagementListCountByClientId,
          this.listViewCurrentPageNumber,
          this.listViewnoOfrecordsPerPage);
      }
      else {
        this.getMyEngagementListView(this.listViewCurrentPageNumber,
          this.listViewnoOfrecordsPerPage,
          this.orderByColumn, this.orderByType);
        if (this.isListFlag == true) {
          this.pager = this.pagerService.getPager(this.engagementListCount, this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
        }
        else {
          this.pager = this.pagerService.getPager(this.listViewCount, this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
        }
      }
      console.log('pager ==>', this.pager);
    } else {
      $('#previous').off('click');
      $('#first').off('click');
    }
  }
  cardViewSetPageByClientId(pageNumber: number, recordsPerPage: number, clientId: number) {
    // get CardViewpager object from service
    this.cardViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#cardViewdrprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.cardViewnoOfrecordsPerPage = recordsPerPage;
    } else {
      this.cardViewnoOfrecordsPerPage = Number(inputDrpValue);
    }
    if (this.cardViewCurrentPageNumber != 0) {
      if (this.sortOrderByTypeForClient != null && this.sortOrderByTypeForClient != "") {
        this.cardVieworderByType = this.sortOrderByTypeForClient;
      }
      this.getMyEngagementCardViewByClientId(this.engagementCardViewForClientFilterModel,
        this.cardViewCurrentPageNumber,
        this.cardViewnoOfrecordsPerPage,
        this.cardVieworderByColumn,
        this.cardVieworderByType, clientId);
      this.cardViewPager = this.pagerService.getPager(this.engagementCountByClientId, this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
      console.log('cardViewPager ==>', this.cardViewPager);
    } else {
      $('#cardViewPrevious').off('click');
      $('#cardViewFirst').off('click');
    }
  }

  cardViewSetPage(pageNumber: number, recordsPerPage: number) {
    // get CardViewpager object from service
    this.cardViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#cardViewdrprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.cardViewnoOfrecordsPerPage = recordsPerPage;
    } else {
      this.cardViewnoOfrecordsPerPage = Number(inputDrpValue);
    }
    this.InsertUpdateUserPreferenceData();
    if (this.cardViewCurrentPageNumber != 0) {
      if (this.passedClientId > 0) {
        this.getMyEngagementCardViewByClientId(this.engagementCardViewForClientFilterModel,
          this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage,
          this.cardVieworderByColumn, this.cardVieworderByType, this.passedClientId);
        this.cardViewPager = this.pagerService.getPager(this.engagementCountByClientId, this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
      }
      else {
        if (this.sortOrderByType != null && this.sortOrderByType != "") {
          this.cardVieworderByType = this.sortOrderByType;
        }
        this.getMyEngagementCardView(this.cardViewCurrentPageNumber,
          this.cardViewnoOfrecordsPerPage,
          this.cardVieworderByColumn, this.cardVieworderByType);
        this.cardViewPager = this.pagerService.getPager(this.engagementCount, this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage);
      }
      console.log('cardViewPager ==>', this.cardViewPager);
    } else {
      $('#cardViewPrevious').off('click');
      $('#cardViewFirst').off('click');
    }
  }

  setInitialsForListView() {
    if (this.listCount === 0) {
      this.headerModelList[0].imageModelList[0].isVisible = true;
      this.headerModelList[0].imageModelList[1].isVisible = false;
      this.headerModelList[0].imageModelList[2].isVisible = false;
    }
  }

  LoadListViewHeaders() {
    this.headerModelList = [];
    this.headerImageModelList = [];
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Desending',
      imageValue: 'DESC',
      imagePath: '../../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.headerModel = {
      headerId: 1,
      headerName: 'Client name',
      headerValue: 'ClientName',
      headerClick: true,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'ClientName') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = true;
        this.headerModelList[0].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[0].imageModelList[0].isVisible = false;
        this.headerModelList[0].imageModelList[1].isVisible = false;
        this.headerModelList[0].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 2,
      headerName: 'Engagement name',
      headerValue: 'EngagementName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'EngagementName') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = true;
        this.headerModelList[1].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[1].imageModelList[0].isVisible = false;
        this.headerModelList[1].imageModelList[1].isVisible = false;
        this.headerModelList[1].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 3,
      headerName: 'Primary solution code',
      headerValue: 'ParentSolutionCode',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'ParentSolutionCode') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = true;
        this.headerModelList[2].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[2].imageModelList[0].isVisible = false;
        this.headerModelList[2].imageModelList[1].isVisible = false;
        this.headerModelList[2].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 4,
      headerName: 'EPPMD',
      headerValue: 'EPPMD',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'EPPMD') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = true;
        this.headerModelList[3].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[3].imageModelList[0].isVisible = false;
        this.headerModelList[3].imageModelList[1].isVisible = false;
        this.headerModelList[3].imageModelList[2].isVisible = true;
      }
    }


    this.headerModel = {
      headerId: 5,
      headerName: 'Assignments',
      headerValue: 'AssignmentsCount',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'AssignmentsCount') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = true;
        this.headerModelList[4].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[4].imageModelList[0].isVisible = false;
        this.headerModelList[4].imageModelList[1].isVisible = false;
        this.headerModelList[4].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 6,
      headerName: 'Last activity',
      headerValue: 'LastActivityDate',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'LastActivityDate') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = true;
        this.headerModelList[5].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[5].imageModelList[0].isVisible = false;
        this.headerModelList[5].imageModelList[1].isVisible = false;
        this.headerModelList[5].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 7,
      headerName: 'Status',
      headerValue: 'Status',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'Status') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = true;
        this.headerModelList[6].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[6].imageModelList[0].isVisible = false;
        this.headerModelList[6].imageModelList[1].isVisible = false;
        this.headerModelList[6].imageModelList[2].isVisible = true;
      }
    }

    this.headerModel = {
      headerId: 8,
      headerName: '',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 9,
      headerName: 'Indicators',
      headerValue: '',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 10,
      headerName: 'Period end',
      headerValue: 'TaxYear',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    if (this.orderByColumn === 'TaxYear') {
      if (this.orderByType == 'ASC') {
        this.headerModelList[9].imageModelList[0].isVisible = false;
        this.headerModelList[9].imageModelList[1].isVisible = true;
        this.headerModelList[9].imageModelList[2].isVisible = false;
      }
      if (this.orderByType == 'DESC') {
        this.headerModelList[9].imageModelList[0].isVisible = false;
        this.headerModelList[9].imageModelList[1].isVisible = false;
        this.headerModelList[9].imageModelList[2].isVisible = true;
      }
    }

    console.log('headerModelList ==>', this.headerModelList);
  }

  HeaderSort(headerid: number) {
    for (let i = 0; i < this.headerModelList.length; i++) {
      if (this.headerModelList[i].headerId === headerid) {
        this.headerModelList[i].headerClick = true;
        if ((this.headerModelList[i].imageModelList[0].isVisible) || (this.headerModelList[i].imageModelList[2].isVisible)) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = true;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        } else if (this.headerModelList[i].imageModelList[1].isVisible) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = true;
        }
      } else {
        this.headerModelList[i].headerClick = false;
        for (let j = 0; j < this.headerModelList[i].imageModelList.length; j++) {
          this.headerModelList[i].imageModelList[0].isVisible = true;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        }
      }
    }

    let headerObj = this.headerModelList.find(c => c.headerClick === true);
    this.orderByColumn = headerObj.headerValue;
    for (let k = 0; k < headerObj.imageModelList.length; k++) {
      if (headerObj.imageModelList[k].isVisible === true) {
        this.orderByType = headerObj.imageModelList[k].imageValue;
        break;
      }
    }
    this.InsertUpdateUserPreferenceData();
    this.listViewSetPage(1, this.listViewnoOfrecordsPerPage);
  }

  getMyEngagementCardViewByClientId(engagementCardViewForClientFilterModel
    : EngagementCardViewForClientFilterModel,
    pageNumber: number, listViewnoOfrecordsPerPage, orderBy,
    orderType, clientId: number) {
      this.loadingService.startLoading();
    // orderBy = null;
    this.engService.getMyEngagementCardViewByClientId(engagementCardViewForClientFilterModel,
      pageNumber, listViewnoOfrecordsPerPage,
      this.isloggedInUserAdmin, orderBy, orderType, clientId).subscribe(
        result => {
          this.engagementCardList = result;
          console.log("engagementCardList ==>", this.engagementCardList);
          this.cardCount = this.engagementCardList.length;
          if (this.cardCount === 0) {
            this.DataMessage = ' No Data Available';
          this.loadingService.stopLoading();
          } else if (this.cardCount === null) {
            this.DataMessage = 'Loading...';
          this.loadingService.stopLoading();
          } else { this.DataMessage = '';
          this.loadingService.stopLoading();
         }

          this.applyFilterOnceAfterDataLoad = true;
        },
        err =>  {
          console.log('Error in loading the client list information.');
          this.loadingService.stopLoading();
      }
        );
  }

  getMyEngagementListViewByClientId(engagementListViewForClientFilterModel
    : EngagementListViewForClientFilterModel,
    pageNumber: number, listViewnoOfrecordsPerPage, orderBy,
    orderType, clientId: number) {
      this.loadingService.startLoading();
    this.engService.getMyEngagementListViewByClientId(engagementListViewForClientFilterModel,
      pageNumber, listViewnoOfrecordsPerPage,
      this.isloggedInUserAdmin, orderBy, orderType, clientId).subscribe(
        result => {
          this.engagementList = result;
          this.listCount = this.engagementList.length;
          if (this.listCount === 0) {
            this.listDataMessage = ' No Data Available';
          this.loadingService.stopLoading();

          } else if (this.listCount === null) {
            this.DataMessage = 'Loading...';
          this.loadingService.stopLoading();

          } else { this.listDataMessage = ''; 
          this.loadingService.stopLoading();

        }

          this.applyFilterOnceAfterDataLoad = true;
        },
        err => {console.log('Error in loading the client list information.');
        this.loadingService.stopLoading();

      }
        );
  }

  getMyEngagementCardView(pageNumber: number,
    listViewnoOfrecordsPerPage, orderBy, orderType) {
    this.startLoading();
    this.engService.getMyEngagementCardView(this.engagementCardViewFilterModel, pageNumber,
      listViewnoOfrecordsPerPage,
      this.isloggedInUserAdmin, orderBy, orderType).subscribe(
        result => {
          this.engagementCardList = result;
          console.log("engagementCardList ==>", this.engagementCardList);
          this.stopLoading();
          this.cardCount = this.engagementCardList.length;
          if (this.cardCount === 0) {
            this.DataMessage = ' No Data Available';
          } else if (this.cardCount === null) {
            this.DataMessage = 'Loading...';
          } else { this.DataMessage = ''; }

          this.applyFilterOnceAfterDataLoad = true;
        },
        err => console.log('Error in loading the client list information.'));
  }

  getMyEngagementListView(pageNumber: number,
    listViewnoOfrecordsPerPage, orderBy, orderType) {
    this.startLoading();
    this.engService.getMyEngagementListView(this.engagementListViewFilterModel, pageNumber,
      listViewnoOfrecordsPerPage, this.isloggedInUserAdmin, orderBy, orderType)
      .subscribe(
        result => {
          this.engagementList = result;
          console.log("engagementList ==>", this.engagementList);
          this.stopLoading();
          this.listCount = this.engagementList.length;
          if (this.listCount === 0) {
            this.listDataMessage = ' No Data Available';
          } else if (this.listCount === null) {
            this.DataMessage = 'Loading...';
          } else { this.listDataMessage = ''; }

          this.applyFilterOnceAfterDataLoad = true;
          this.setInitialsForListView();
        },
        err => console.log('Error in loading the client list information.'));
  }


  navigateEngagement(engagementId: number) {

    this.engService.GetEditEngagementDetailsById(engagementId).subscribe(
      result => {
        this.selectedEngagement = result;
        console.log('status ', this.selectedEngagement, this.selectedEngagement.status);
        switch (this.selectedEngagement.status) {
          case 'Not started':
            //EditEnagement\ID
            this.route.navigate(['EditEngagement/' + this.selectedEngagement.engagementId]);
            break;
          case 'Draft':
            //Interviews
            this.route.navigate(['Interviews/' + this.selectedEngagement.workspaceId]);
            break;
          case 'InReview':
          case 'Declined':
          case 'Abandoned':
            //Review\WorkSpaceId        
            this.route.navigate(['Review/' + this.selectedEngagement.workspaceId]);
            break;
          case 'Pending CMS codes':
          case 'CMS code error':
          case 'Active':
          case 'Closed':
          case EngagementStatus.EngActivePendingSignature:
          case EngagementStatus.EngVoided:
            this.route.navigate(['EngagementDetail/' + this.selectedEngagement.engagementId]);
          //EnagementDetails\ID 
          default:
            //Default;
            break;
        }
      },
      err => {
        console.log('Error in getting workspace status.', err);
      }
    );
  }

  navigateEngagement1(engagementId: number, SelectedView: string) {

    var selectedEngagementModel;

    if (SelectedView == 'Card') {
      selectedEngagementModel = this.engagementCardList.find(e => e.engagementId === engagementId);
    }
    else if (SelectedView == 'List') {
      selectedEngagementModel = this.engagementList.find(e => e.engagementId === engagementId);
    }
    console.log('status ', selectedEngagementModel, selectedEngagementModel.status);

    switch (selectedEngagementModel.status) {
      case 'Not started':
        //EditEnagement\ID
        this.route.navigate(['EditEngagement/' + selectedEngagementModel.engagementId]);
        break;
      case 'Draft':
        //Interviews
        this.route.navigate(['Interviews/' + selectedEngagementModel.workspaceId]);
        break;
      case 'InReview':
      case 'Declined':
      case 'Abandoned':
        //Review\WorkSpaceId        
        this.route.navigate(['Review/' + selectedEngagementModel.workspaceId]);
        break;
      case 'Pending CMS codes':
      case 'CMS code error':
      case 'Active':
      case 'Closed':
      case EngagementStatus.EngActivePendingSignature:
      case EngagementStatus.EngVoided: 
        this.route.navigate(['EngagementDetail/' + selectedEngagementModel.engagementId]);
      //EnagementDetails\ID 
      default:
        //Default;
        break;
    }
  }

  getEngagementListByClient(clientId: number) {
    this.engService.getEngagementListViewByClient(clientId).subscribe(
      result => {
        this.engagementList = result;
        //console.log(this.englistViewList);
        this.applyFilterOnceAfterDataLoad = true;
      },
      err => console.log('Error in loading the engagement list information by client.', err));
  }

  InsertUpdateUserPreferenceData() {
    //alert(this.dashBoardViewtypeName);
    var userPrefData: UserPreferenceData;
    var cardViewFilterList: any;
    var cardViewSortList: any;
    var listViewFilterList: any;
    var listViewSortList: any;
    var datatoinsert = new UserPreferenceData();
    if (this.userPreferenceData.length > 0 && this.engagementUserPreference != null && this.passedClientId <= 0) {
      this.engagementUserPreference.userEmail = this.userObject.loggedInUserEmail;
      this.engagementUserPreference.dashBoardName = 'MyEngagements';
      this.engagementUserPreference.dashboardViewType = this.dashBoardViewtypeName;
      cardViewFilterList = this.engagementCardViewFilterModel;
      cardViewSortList = this.engagementCardViewFilterModel;
      listViewFilterList = this.engagementListViewFilterModel;
      listViewSortList = this.engagementListViewFilterModel;
      cardViewSortList.orderByType = $('#ddListOrderByType').val();

      listViewSortList.orderByColumn = this.orderByColumn;
      listViewSortList.orderByType = this.orderByType;
      this.engagementUserPreference.cardViewFilterList = JSON.stringify(cardViewFilterList);
      this.engagementUserPreference.cardViewSortingList = JSON.stringify(cardViewSortList);
      this.engagementUserPreference.cardViewPageNumber = this.cardViewnoOfrecordsPerPage;
      this.engagementUserPreference.listViewFilterList = JSON.stringify(listViewFilterList);
      this.engagementUserPreference.listViewSortingList = JSON.stringify(listViewSortList);
      this.engagementUserPreference.listViewPageNumber = this.listViewnoOfrecordsPerPage;
      userPrefData = this.engagementUserPreference;
      let index = this.userPreferenceData.findIndex(obj => obj.dashBoardName === "MyEngagements");
      this.userPreferenceData[index] = userPrefData;

      localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


      this.engService.InsertUpdateUserPreferenceData(userPrefData).subscribe(result => {
        console.log(result);
      });
    }
    if (this.userPreferenceData.length > 0 && this.clientdetailUserPreference != null && this.passedClientId > 0) {
      this.clientdetailUserPreference.userEmail = this.userObject.loggedInUserEmail;
      this.clientdetailUserPreference.dashBoardName = 'ClientDetails';
      this.clientdetailUserPreference.dashboardViewType = this.dashBoardViewtypeName;
      cardViewFilterList = this.engagementCardViewForClientFilterModel;
      cardViewSortList = this.engagementCardViewForClientFilterModel;
      listViewFilterList = this.engagementListViewForClientFilterModel;
      listViewSortList = this.engagementListViewForClientFilterModel;
      cardViewSortList.sortOrderByTypeForClient = $('#ddListOrderByTypeForClient').val();

      listViewSortList.orderByColumn = this.orderByColumn;
      listViewSortList.orderByType = this.orderByType;
      this.clientdetailUserPreference.cardViewFilterList = JSON.stringify(cardViewFilterList);
      this.clientdetailUserPreference.cardViewSortingList = JSON.stringify(cardViewSortList);
      this.clientdetailUserPreference.cardViewPageNumber = this.cardViewnoOfrecordsPerPage;
      this.clientdetailUserPreference.listViewFilterList = JSON.stringify(listViewFilterList);
      this.clientdetailUserPreference.listViewSortingList = JSON.stringify(listViewSortList);
      this.clientdetailUserPreference.listViewPageNumber = this.listViewnoOfrecordsPerPage;
      userPrefData = this.clientdetailUserPreference;
      let index = this.userPreferenceData.findIndex(obj => obj.dashBoardName === "ClientDetails");
      this.userPreferenceData[index] = userPrefData;
      localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


      this.engService.InsertUpdateUserPreferenceData(userPrefData).subscribe(result => {
        console.log(result);
      });
    }
    if ((this.userPreferenceData.length == 0 || this.userPreferenceData.length > 0) && this.engagementUserPreference == null && this.passedClientId <= 0) {

      cardViewFilterList = this.engagementCardViewFilterModel;
      cardViewSortList = this.engagementCardViewFilterModel;
      listViewFilterList = this.engagementListViewFilterModel;
      listViewSortList = this.engagementListViewFilterModel;
      cardViewSortList.orderByType = $('#ddListOrderByType').val();

      listViewSortList.orderByColumn = this.orderByColumn;
      listViewSortList.orderByType = this.orderByType;
      datatoinsert.userEmail = this.userObject.loggedInUserEmail;
      datatoinsert.dashBoardName = 'MyEngagements';
      datatoinsert.dashboardViewType = this.dashBoardViewtypeName;
      datatoinsert.cardViewPageNumber = this.cardViewnoOfrecordsPerPage;
      datatoinsert.listViewPageNumber = this.listViewnoOfrecordsPerPage;
      datatoinsert.cardViewFilterList = JSON.stringify(cardViewFilterList);
      datatoinsert.cardViewSortingList = JSON.stringify(cardViewSortList);
      datatoinsert.listViewFilterList = JSON.stringify(listViewFilterList);
      datatoinsert.listViewSortingList = JSON.stringify(listViewSortList);
      this.userPreferenceData.push(datatoinsert);
      localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


      this.engService.InsertUpdateUserPreferenceData(datatoinsert).subscribe(result => {
        console.log(result);
      });
    }
    if ((this.userPreferenceData.length == 0 || this.userPreferenceData.length > 0) && this.clientdetailUserPreference == null && this.passedClientId > 0) {
      cardViewFilterList = this.engagementCardViewForClientFilterModel;
      cardViewSortList = this.engagementCardViewForClientFilterModel;
      listViewFilterList = this.engagementListViewForClientFilterModel;
      listViewSortList = this.engagementListViewForClientFilterModel;
      cardViewSortList.sortOrderByTypeForClient = $('#ddListOrderByTypeForClient').val();

      listViewSortList.orderByColumn = this.orderByColumn;
      listViewSortList.orderByType = this.orderByType;
      datatoinsert.userEmail = this.userObject.loggedInUserEmail;
      datatoinsert.dashBoardName = 'ClientDetails';
      datatoinsert.dashboardViewType = this.dashBoardViewtypeName;
      datatoinsert.cardViewPageNumber = this.cardViewnoOfrecordsPerPage;
      datatoinsert.listViewPageNumber = this.listViewnoOfrecordsPerPage;
      datatoinsert.cardViewFilterList = JSON.stringify(cardViewFilterList);
      datatoinsert.cardViewSortingList = JSON.stringify(cardViewSortList);
      datatoinsert.listViewFilterList = JSON.stringify(listViewFilterList);
      datatoinsert.listViewSortingList = JSON.stringify(listViewSortList);
      this.userPreferenceData.push(datatoinsert);
      localStorage.setItem('userpreferencelist', JSON.stringify(this.userPreferenceData));


      this.engService.InsertUpdateUserPreferenceData(datatoinsert).subscribe(result => {
        console.log(result);
      });
    }
  }

  ToggleListAndCardView(SelectedView: string,) {
    $("#showAllEngagementCardView").removeClass("active");
    $("#showAllEngagementListView").removeClass("active");
    switch (SelectedView) {
      case 'Card':
        $('.Eng-ListView-Div').hide();
        $('.Eng-CardView-Div').show();
        if (this.passedClientId > 0) {
          $('#listViewFilterSectionForClient').hide();
          this.dashBoardViewtypeName = "Card";
          if(!this.isClientListViewApplyResetButtonClicked){
            $("#filterEngagementListView").removeClass("partialActive");
            $("#filterEngagementListView").removeClass("active");
      this.engagementListViewForClientFilterModel = JSON.parse(this.clientdetailUserPreference.listViewFilterList);
            
            }
         
          this.InsertUpdateUserPreferenceData();
          this.getMyEngagementCountByClientId('Togggle',this.passedClientId);
          //this.cardViewSetPageByClientId(this.cardViewCurrentPageNumber, this.cardViewnoOfrecordsPerPage, this.passedClientId);
          if (this.engagementCardViewForClientFilterModel.solutionCodeId > 0 || this.engagementCardViewForClientFilterModel.industryTypeId > 0 ||
            this.engagementCardViewForClientFilterModel.engagementStatusId != "" || this.engagementCardViewForClientFilterModel.eppmdEmail != "") {
            $("#filterEngagementCardView").addClass("partialActive");
          }
          if (this.engagementCardViewForClientFilterModel.sortSolutionCodeId || this.engagementCardViewForClientFilterModel.sortIndustryTypeId ||
            this.engagementCardViewForClientFilterModel.sortEngagementStatusId || this.engagementCardViewForClientFilterModel.sortEppmdEmailId || this.engagementCardViewForClientFilterModel.sortLastActivityDate) {
            $("#sortEngagementCardView").addClass("partialActive");
          }
        }
        else {
          $('#listViewFilterSection').hide();
          this.dashBoardViewtypeName = "Card";
          if(!this.isListViewApplyResetButtonClicked){
            $("#filterEngagementListView").removeClass("partialActive");
            $("#filterEngagementListView").removeClass("active");
      this.engagementListViewFilterModel = JSON.parse(this.engagementUserPreference.listViewFilterList);

            }
     
          this.InsertUpdateUserPreferenceData();
          this.getMyEngagementByUserCount('Toggle');
          $("#filterEngagementCardView").removeClass("active");
          if (this.engagementCardViewFilterModel.clientId > 0 || this.engagementCardViewFilterModel.solutionCodeId > 0 || this.engagementCardViewFilterModel.industryTypeId > 0 ||
            this.engagementCardViewFilterModel.engagementStatusId != "" || this.engagementCardViewFilterModel.eppmdEmail != "") {
            $("#filterEngagementCardView").addClass("partialActive");
          }
          if (this.engagementCardViewFilterModel.sortClientId || this.engagementCardViewFilterModel.sortSolutionCodeId || this.engagementCardViewFilterModel.sortIndustryTypeId ||
            this.engagementCardViewFilterModel.sortEngagementStatusId || this.engagementCardViewFilterModel.sortEppmdEmailId || this.engagementCardViewFilterModel.sortLastActivityDate) {
            $("#sortEngagementCardView").addClass("partialActive");
          }
        }
        this.SetDefaultCardView();
        break;
      case 'List':
        $('.Eng-ListView-Div').show();
        $('.Eng-CardView-Div').hide();
        if (this.passedClientId > 0) {
          $('#cardViewFilterSectionForMyClient').hide();
          $('#cardViewSortSectionForClient').hide();
          this.dashBoardViewtypeName = "List";
          if(!this.isClientCardViewApplyResetButtonClicked){
            $("#filterEngagementCardView").removeClass("partialActive");
            $("#filterEngagementCardView").removeClass("active");

            this.engagementCardViewForClientFilterModel = JSON.parse(this.clientdetailUserPreference.cardViewFilterList);
            }
            if(!this.isClientCardViewSortApplyResetButtonClicked){
        $("#sortEngagementCardView").removeClass("active");
              this.engagementCardViewForClientFilterModel = JSON.parse(this.clientdetailUserPreference.cardViewFilterList);
              this.sortOrderByTypeForClient = JSON.parse(this.clientdetailUserPreference.cardViewSortingList).sortOrderByTypeForClient;
              if (this.sortOrderByTypeForClient == undefined || this.sortOrderByTypeForClient == null) {
                this.sortOrderByTypeForClient = 'DESC';
              }
            }
          this.InsertUpdateUserPreferenceData();
          //this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);
          this.getMyEngagementListCountByClientId('Toggle',this.passedClientId);
          if (this.engagementListViewForClientFilterModel.solutionCodeId > 0 || this.engagementListViewForClientFilterModel.industryTypeId > 0 ||
            this.engagementListViewForClientFilterModel.engagementStatusId != "" || this.engagementListViewForClientFilterModel.eppmdEmail != "") {
            $("#filterEngagementListView").addClass("partialActive");
          }

        } else {
          this.dashBoardViewtypeName = "List";
          if(!this.isCardViewApplyResetButtonClicked){
            $("#filterEngagementCardView").removeClass("partialActive");
            $("#filterEngagementCardView").removeClass("active");
            this.engagementCardViewFilterModel = JSON.parse(this.engagementUserPreference.cardViewFilterList);
            }
            if(!this.isCardViewSortApplyResetButtonClicked){
        $("#sortEngagementCardView").removeClass("active");
            this.engagementCardViewFilterModel = JSON.parse(this.engagementUserPreference.cardViewFilterList);
              this.sortOrderByType = JSON.parse(this.engagementUserPreference.cardViewSortingList).orderByType;
              if (this.sortOrderByType == undefined || this.sortOrderByType == null) {
                this.sortOrderByType = 'DESC';
              }
            }
          this.InsertUpdateUserPreferenceData();
          this.listViewSetPage(this.listViewCurrentPageNumber, this.listViewnoOfrecordsPerPage);

          $("#filterEngagementListView").removeClass("active");
          if (this.engagementListViewFilterModel.clientId > 0 || this.engagementListViewFilterModel.solutionCodeId > 0 || this.engagementListViewFilterModel.industryTypeId > 0 ||
            this.engagementListViewFilterModel.engagementStatusId != "" || this.engagementListViewFilterModel.eppmdEmail != "") {
            $("#filterEngagementListView").addClass("partialActive");
          }

          $('#cardViewFilterSection').hide();
          $('#cardViewSortSection').hide();

        }



        break;
      default:
        this.SetDefaultCardView();
        break;
    }
  }

  SetDefaultCardView() {
    $('.cardViewContainer').show();
    $('.Eng-ListView-Div').hide();
    $('.Eng-CardView-Div').show();
  }

  setKRPClientId(clientId: number) {
    this.selectedKRPClientId = clientId;
  }

  setEngagementId(engagementId: number) {
    console.log('setEngagementId ', engagementId);
    this.selectedEngagementId = engagementId;
  }

  SetContactPopup(emailid: string) {
    this.selectContactEmailId = emailid;
  }

  createContactPopupDiv(controlIdText: string, id: number) {
    console.log('engagementId ==>', id);
    $('#' + controlIdText + id).popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',    
      boundary: 'viewport',
      content: function () {
        console.log('createContactPopupDiv func called ', $('#contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  BindContactPopup() {
    this.engagementList.forEach(element => {
      this.createContactPopupDiv('ePPMDcontactPopup_', element.engagementId);
    });
    this.engagementCardList.forEach(element => {
      this.createContactPopupDiv('cardViewEPPMDcontactPopup_', element.engagementId);
    });
  }

  DisplayContactPopup(engagementid: number, emailid: string) {
    this.selectContactEmailId = emailid;
    $('#listViewtxtengId').val(engagementid);
    // Close the pop up if any pop up onen eariler
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#ePPMDcontactPopup_' + engagementid).popover('show');
  }

  DisplayCardViewContactPopup(engagementid: number, emailid: string) {
    this.selectContactEmailId = emailid;
    $('#cardViewtxtengId').val(engagementid);
    // Close the pop up if any pop up onen eariler
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#cardViewEPPMDcontactPopup_' + engagementid).popover('show');
  }

  hideAllPopUp() {
    if (this.engagementList !== null && this.engagementList !== undefined) {
      this.engagementList.forEach(element => {
        $('#ePPMDcontactPopup_' + element.engagementId).popover('hide');
      });
    }
    if (this.engagementCardList !== null && this.engagementCardList !== undefined) {
      this.engagementCardList.forEach(element => {
        $('#cardViewEPPMDcontactPopup_' + element.engagementId).popover('hide');
      });
    }
  }

  ngOnDestroy() {
    this.hideAllPopUp();
  }

  checkForContactContent(contact) {
    var timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 2000);
  }

  startLoading() {
    console.log('start loading ',this.isMyEngagementLevel );
    if (this.isMyEngagementLevel) {
      this.passLoading.emit('true');
    } else {
      this.loadingService.startLoading();
    }
  }

  stopLoading() {
    console.log('stop loading ',this.isMyEngagementLevel );
    if (this.isMyEngagementLevel) {
      this.passLoading.emit('false');
    } else {
      this.loadingService.stopLoading();
    }
  }

}

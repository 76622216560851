export class CLMDetails {
    CLMDetailsId:number;
    engagementId:number;
    uniqueGUID:string;
    gaCategory:string;
    category:string;
    sysID:string;
    gaType:string;
    effectiveDate:Date;
    baseDetails:string;
    createdBy:string;
    createdDate:Date;
    lastModifiedBy:string;
    lastModifiedDate:Date;
    name:string;
    status:string
}

/* Code to show Relationship Partner Popup */
$(document).ready(function () {

  $('#clientUl').on('click','li',function(e){
   e.preventDefault();
   $("#clientActionTiles ul.nav li.activeTab").removeClass('activeTab')
   $(this).addClass('activeTab')
   });

  $(".arrow-left").click(function () {

    $(".expand").css("display", "none"); // left arrow and other to be hidden elements
    $("#clientActionTiles ul.nav li.hide").show();
    $(".arrow-right").css("display", "block");
    $("#clientInfo").removeAttr("class");
    $("#clientInfo").addClass("col-md-3");
    $("#clientActionTiles").removeAttr("class");
    $("#clientActionTiles").addClass("col-md-8");
    $("#clientActionTiles ul.nav li.tilesBox").attr("style", "width:150px");

  });
  $(".arrow-right").click(function () {
    if ($("#clientActionTiles ul.nav li.tilesBox").attr("style") == "width:150px") {
      $("#clientActionTiles ul.nav li.tilesBox").removeAttr("style");
    }
    $(".expand").css("display", "block");
    $("#clientActionTiles ul.nav li.hide").hide();
    $(".arrow-right").css("display", "none");

    $("#clientInfo").removeAttr("class");
    $("#clientInfo").addClass("col-md-4");
    $("#clientActionTiles").removeAttr("class");
    $("#clientActionTiles").addClass("col-md-7");
  });
});







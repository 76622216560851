import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from './appConfigService';
import { SharepointSiteModel, SharepointSiteDocumentModel, SPDocumentList, DecisionsTemplateRequestModel, DecisionsTemplateResponseModel, AvepointResponseModel, SharepointSiteAndUpdateSharePointMetadataModel, AvepointResponseDataModel, ApplySitePolicyRequestModel, SharepointUploadSiteDocumentModel } from '../model/SharePoiint/SharePointSite';
import { CollabrartionPermissionManagementModel } from '../model/CollaborationSite';
import { mergeMap } from 'rxjs/operators';
import { EngagementService } from './EngagementService';
import { SharepointUserProperties } from '../model/SharePoiint/sharepoint-user-properties';
import { SharepointListItemsRequestModel } from '../model/SharePoiint/sharepoint-list-items-request-model';
import { SharepointListItemsModel } from '../model/SharePoiint/sharepoint-list-items-model';
import { SharepointDocumentContentRequest } from '../model/SharePoiint/sharepoint-document-content-request';
import { CLMDocumentList } from '../model/CLM/clm-document-list';
import { AvepointTaskListModel } from '../model/avepoint/avepoint-task-list-model';
import { FilterTaskRequestModel } from '../model/avepoint/filter-task-request-model';

@Injectable({
  providedIn: 'root'
})
export class SharepointService {
  IntegrationAPIEndPoint: string;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private enviornment: AppConfigService, private engagementService: EngagementService) {
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  CreateProvisionSite(sharepointSiteModel: SharepointSiteModel): Observable<AvepointResponseModel> {
    return this.http.post<AvepointResponseModel>(this.IntegrationAPIEndPoint + "/api/Avepoint/ProvisionSite/", sharepointSiteModel, { responseType: 'json' as 'json' });
  }

  CreateProvisionSiteAndUpdateSharePointMetadata(sharepointSiteAndUpdateSharePointMetadataModel: SharepointSiteAndUpdateSharePointMetadataModel): Observable<AvepointResponseDataModel> {
    return this.http.post<AvepointResponseDataModel>(this.IntegrationAPIEndPoint + "/api/Avepoint/CreateProvisionSiteAndUpdateSharePointMetadata/", sharepointSiteAndUpdateSharePointMetadataModel, { responseType: 'json' as 'json' });
  }

  AddDocumentsToLibrary(sharepointSiteDocument: SharepointSiteDocumentModel): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/api/SharePoint/AddDocumentsToLibrary/',
      sharepointSiteDocument, { responseType: 'text' as 'json' });
  }
  uploaddocumentstolib(sharepointSiteDocument: SharepointSiteDocumentModel): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/api/SharePoint/uploaddocumentstolib/',
      sharepointSiteDocument, { responseType: 'text' as 'json' });
  }
  uploadDocumentToSharepoint(sharepointSiteDocument: SharepointUploadSiteDocumentModel): Observable<string> {//Upload file through graph API
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/api/SharePoint/UploadDocumentToSharepoint',
      sharepointSiteDocument, { responseType: 'text' as 'json' });
  }

  UploadAngularFiletoLib(sharepointSiteDocument: SharepointSiteDocumentModel): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/api/SharePoint/UploadAngularFiletoLib/',
      sharepointSiteDocument, { responseType: 'text' as 'json' });
  }
  SetRetentionLabel(siteURL: string): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/api/SharePoint/ApplyRetentionLabel?siteUrl=' + siteURL, null, requestOptions);
  }

  getAllDocumentsfromSPLibrary(siteURL: string, documentFolderPath: string): Observable<SPDocumentList[]> {
    return this.http.get<SPDocumentList[]>(this.IntegrationAPIEndPoint + '/api/SharePoint/GetAllDocumentsInLibrary?siteURL=' + siteURL + '&documentFolderPath=' + documentFolderPath);
  }

  GetDocumentsFromFolderInLibrary(siteURL: string, documentFolderPath: string): Observable<SPDocumentList[]> {
    return this.http.get<SPDocumentList[]>(this.IntegrationAPIEndPoint +
      '/api/SharePoint/GetDocumentsFromFolderInLibrary' +
      '?siteURL=' + siteURL + '&documentFolderPath=' + documentFolderPath);
  }

  deleteDocument(siteURL: string, documentUrl: string): Observable<string> {
    return this.http.get<string>(this.IntegrationAPIEndPoint + '/api/SharePoint/DeleteDocument?siteURL=' + siteURL + '&documentUrl=' + documentUrl, { responseType: 'text' as 'json' });
  }

  // GetAllDocumentsInLibraryWithContent(siteURL: string, documentFolderPath: string): Observable<SPDocumentList[]> {    
  //   return this.http.get<SPDocumentList[]>(this.IntegrationAPIEndPoint + '/api/SharePoint/GetAllDocumentsInLibraryWithContent?siteURL=' + siteURL + '&documentFolderPath=' + documentFolderPath);
  // }

  GetDecisionsTemplate(decisionsTemplateRequest: DecisionsTemplateRequestModel): Observable<DecisionsTemplateResponseModel> {
    return this.http.post<DecisionsTemplateResponseModel>(this.IntegrationAPIEndPoint + "/api/Avepoint/GetDecisionsTemplate/", decisionsTemplateRequest, { responseType: 'json' as 'json' });
  }

  GetSiteStatus(requestId: string): Observable<string> {
    return this.http.get<string>(this.IntegrationAPIEndPoint + '/api/Avepoint/GetSiteStatus?requestId=' + requestId, { responseType: 'text' as 'json' });
  }

  ContinuanceSitePermissionManagementAddRemove(collabrartionPermissionManagementModel: CollabrartionPermissionManagementModel): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint + "/api/SharePoint/ContinuanceSitePermissionManagementAddRemove/", collabrartionPermissionManagementModel, { responseType: 'text' as 'json' });
  }

  ApplyAutoCloseoutSitesPolicyOnManualClose(engagementId: number, applySitePolicyRequestModel: ApplySitePolicyRequestModel): Observable<string> {
    return this.engagementService.IsAssignmentTaskActive(engagementId)
      .pipe(mergeMap(isAssignmentTaskActive => this.http.post<string>(this.IntegrationAPIEndPoint + "/api/Avepoint/ApplyAutoCloseoutSitesPolicyOnManualClose",
        {
          primarySiteUrl: applySitePolicyRequestModel.primarySiteUrl,
          siteUrls: applySitePolicyRequestModel.siteUrls,
          resetSiteStartDate: !isAssignmentTaskActive
        },
        { responseType: 'text' as 'json' })));
  }

  ApplyAbandonSitePolicy(primarySiteUrl: string): Observable<string> {
    return this.http.get<string>(this.IntegrationAPIEndPoint + `/api/Avepoint/ApplyAbandonSitePolicy?primarySiteUrl=${primarySiteUrl}`, { responseType: 'text' as 'json' });
  }
  GetUserPropertiesByEmail(userEmail: string): Observable<SharepointUserProperties> {
    return this.http.get<SharepointUserProperties>(this.IntegrationAPIEndPoint + `/api/SharePoint/GetUserPropertiesByEmail?email=${userEmail}`);
  }
  getSharepointLibraryListItems(sharepointListItemsRequestModel: SharepointListItemsRequestModel): Observable<SharepointListItemsModel> {
    return this.http.post<SharepointListItemsModel>(this.IntegrationAPIEndPoint + '/api/SharePoint/GetSharepointLibraryListItems/',
      sharepointListItemsRequestModel);
  }
  getSharepointDocumentContent(sharepointDocumentContentRequest: SharepointDocumentContentRequest): Observable<CLMDocumentList[]> {
    return this.http.post<CLMDocumentList[]>(this.IntegrationAPIEndPoint + '/api/SharePoint/GetSharepointDocumentContent', sharepointDocumentContentRequest);
  }
  getFilterdTask(filterTaskRequestModel: FilterTaskRequestModel): Observable<AvepointTaskListModel> {
    return this.http.post<AvepointTaskListModel>(this.IntegrationAPIEndPoint + '/api/Avepoint/GetFilterdTask', filterTaskRequestModel);
  }
  rejectTasks(avepointTaskListModel: AvepointTaskListModel) {
    return this.http.post(this.IntegrationAPIEndPoint + '/api/Avepoint/RejectTasks', avepointTaskListModel, { responseType: 'text' as 'json' });
  }
}

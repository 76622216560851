<div class="row pageTitleParent">
  <div class="col-md-12 pt-3">
    <h2 class="pageTitle largerFontSize2">Configure</h2>
  </div>
</div>
<div class="config-navmenu mt-1 mb-4">
  <ul class="nav  config-navbar-Design">
    <li class="nav-item config-navitemdesign solution-btn d-none">
      <a class="nav-link active SolutionCodeConfigScreenjs  px-5" data-toggle="pill"
        href="#SolutionCodeConfigScreen" (click)="clearMessage();" routerLink = "solution-code">
        <img class="config-shortcutimg mr-2" id="configRPImgJs"
          src="../../../assets/images/EDP/Configuration/solution-code-tab-white.png" alt="Solution codes" />
        <span class="pos-t-2">Solution codes</span> 
      </a>
    </li>
    <li class="nav-item config-navitemdesign template-btn ">
      <a class="nav-link TemplateMappingContainerjs px-5" data-toggle="pill" routerLink = "template-maintenance">
        <img class="config-shortcutimg mr-2" id="configShortcutImgJs"
          src="../../../assets/images/EDP/Configuration/shortcuts_blue.svg" alt="Shortcuts" />
         <span class="pos-t-2">Template-Maintenance</span> 
        </a>
    </li>     
  </ul>
</div>
<hr>

<router-outlet></router-outlet>
<div id="configmaincontainer" class="col-md-12 px-0">
  <div class="configurationNavbarData">


    <div id="ConfigNavigationContainer">
      <div class="config-infoParent">
        <div class="row config-info-title mediumFontSize2"> Navigation</div>
        <div class="row config-info">
          <p class="config-info-description smallFontSize17">In progress... </p>
          &nbsp; &nbsp;
        </div>
      </div>    
    </div>

    <div id="ConfigReviewingPartnersContainer">
      <div class="config-infoParent">
        <div class="row config-info-title mediumFontSize2"> Reviewing partners</div>
        <div class="row config-info">
          <p class="config-info-description smallFontSize17">In progress... </p>
          &nbsp; &nbsp;
          <h3 class="p-3">Modals</h3>
          <div class="row pd-5">
            <div class="col-md-3">
              <button class="btn SecondaryButton"
                (click)="showDialog('success', 'This is a success title')">Success</button>
            </div>
            <div class="col-md-3">
              <button class="btn SecondaryButton"
                (click)="showDialog('warning','This is an alert title')">Warning</button>
            </div>
            <div class="col-md-3">
              <button class="btn SecondaryButton"
                (click)="showDialog('danger','This is a danger title')">Danger</button>
            </div>
            <div class="col-md-3">
              <button class="btn SecondaryButton"
                (click)="showDialog('information','This is a success title')">Information</button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">&nbsp;</div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <button class="btn SecondaryButton"
                (click)="showDialogWith2Button('information','This is a two-button title')">Information with 2
                buttons</button>
            </div>
            <div class=" col-md-3">
              <button class="btn SecondaryButton"
                (click)="showDialogWith3Button('information','This is a three-button info title')">Information with 3
                buttons</button>
            </div>
          </div>
        </div>
      </div>   
    </div>
  </div>
</div>

<div *ngIf=" !isDisplayConfiguration">
  <h4 style="text-align: center; background: bisque;margin-top: 20px;">
    Access denied because the user does not have system System Administrator role
  </h4>
</div>
export class SolutionCodeSearchFilter {
    public solutionCode: string = "";
    public solutionCodeDescription: string = "";
    public solutionCodeTypeId: number;
    public solutionCodeStatusId: string;
    public secodarySolutionCodeIdList : string ="";
    public isAudit : boolean = false;
    public isTax : boolean = false;
    public isAdvisory: boolean = false;
    public isNone : boolean = false;
}

export class TemplateMaintenance {
    constructor(
        public templatePackageId: string,
        public templateName: string,
        public templateVersion: number,
        public status: boolean = false,
        public displayName: string = '',
        public isTax: boolean = false,
        public isAudit: boolean = false,
        public isAdvisory: boolean = false,
        public isGTStandardMSA: boolean = false,
        public isUniversalEngagementLetter: boolean = false,
        public isTaxEvergreen: boolean = false,
        public templateTypeId: number = 0,
        public templateTypeCode: string = '',
        public templateTypeName: string = '',
        public serviceLine: string = '',
        public createdBy: string = '',
        public engagementStateConfigId:number = 0,
        public engagementStateName: string = '',
        public engagementStatecode:string='',
        public independanceConsiderationId:number = 0,
        public templateCategoryId:number = 0,
        public independenceConsiderationCode:string = '',
        public templateCategoryCode:string = '') {
        


    }
}

<div class="card">
    <div class="card-header justify-content-between d-flex border-bottom-0">
        <div class="card-heading">
            <img src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive" 
            *ngIf="sectionConfiguration.isCompleted" alt="Completed" 
            style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" 
            width="20" height="20" />
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" class="img-responsive" 
            *ngIf="showRequiredWarning" alt="Warning" 
            style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" 
            width="20" height="20" />
            <a class="card-link">
                <h5 class="edp-font-14 edp-font-color font-weight-bold">Quality guidesheet</h5>
            </a>
        </div>
        <div class="blank-card"></div>
        <div class="card-icon" (click)="toggleUp()" *ngIf="!isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/desc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
        <div class="card-icon" (click)="toggleDown()" *ngIf="isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/asc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
    </div>
    <div id="collapseOne" data-parent="#accordion" *ngIf="isImageEnabled">
      <div class="card-body">
        <div class="bg-edp-body p-1" *ngIf="!isEngagementClosed">
          <div id="hdEngDetailQuestionnaireDivForQuality" class="p-1"></div>
          <div class="d-flex pb-4 pt-4">
            <div class="pl-3 pt-2 pr-0"
              *ngIf="qualityCloseoutHDTemplatePackageId ==  null || 
                                                    qualityCloseoutHDTemplatePackageId == undefined || qualityCloseoutHDTemplatePackageId == ''">
              Template mapping not found.
            </div>
            <div class="pl-3 pt-2 pr-0" *ngIf="qualityCloseoutHDTemplatePackageId !=  null && 
                                                    qualityCloseoutHDTemplatePackageId != undefined && qualityCloseoutHDTemplatePackageId != ''">
              <button class="btn btn-width PrimaryButton w-100 text-center" 
               [disabled]="isEngagementClosed" type="button"
                (click)="saveQualityCheckList()">
                <span class="d-inline-block align-middle"> Save changes </span>
              </button>
            </div>
          </div>
        </div>

        <div class="" *ngIf="isEngagementClosed">          
            <app-review-response [hdWorkItemVersionId]="currentWorkspaceData.hdWorkItemVersionId"
              [hdWorkItemId]="currentWorkspaceData.hdWorkItemId" [isAppliedTnC]="false" [index]="1">
            </app-review-response>
        </div>
      </div>
    </div>
</div>


import { UsersDetails } from "../SharePoiint/ActiveDirectory"
import { AzureADUser } from "./azure-ad-user.mode"

export class SiteCollaborationMembers{
    SiteUniqueId:string
    IsTeamsConverted:boolean
    SiteOwners:AzureADUser[]
    SiteMembers:AzureADUser[]
    UsersDetails:UsersDetails[]
}

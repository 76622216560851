import { ClientTypesEnum } from './../../../model/Client/client-types.enum';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ReacceptanceClientsService } from 'src/app/services/reacceptanceClientsService';
import { ClientService } from 'src/app/services/clientservice';
import { CountryService } from 'src/app/services/country.service';
import { AdUsersService } from 'src/app/services/adusers.service';
import { DataService } from 'src/app/services/data.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { StateService } from 'src/app/services/state.service';
import { LogService } from 'src/app/services/log.service';
import { SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { MembersList } from 'src/app/model/MembersList';
import { EditClient } from 'src/app/model/Client/EditClient';
import { FieldValidator } from 'src/app/model/Engagement/FieldValidator';
import { KeyRelationshipPartners } from 'src/app/model/Client/KeyRelationshipPartners';
import { RoleNames } from 'src/app/model/Role';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { Applicationmaster } from 'src/app/model/ApplicationMaster';
import { ClientAdministrators } from 'src/app/model/Client/ClientAdministrators';
import { EditClientViewModel } from 'src/app/model/Client/EditClientViewModel';
import { ClientTypes } from 'src/app/model/Client/ClientTypes';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { Alert } from 'src/app/model/Alert';
import { ClientLevelDocument } from 'src/app/model/Client/ClientLevelDocument';
import { CLMService } from 'src/app/services/clm.service';
declare var $;

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit, OnDestroy{
  userObject: any;
  isloggedInUserAdmin: boolean = false;

  clientId: number;
  client: EditClient = new EditClient();
  clientLevelDocument: ClientLevelDocument = new ClientLevelDocument();
  clientAdministrator: ClientAdministrators = new ClientAdministrators();
  keyRelationshipPartner: KeyRelationshipPartners = new KeyRelationshipPartners();
  editClientViewModel: EditClientViewModel = new EditClientViewModel();

  clientProgramTypes: Applicationmaster[] = [];
  contactTypes: Applicationmaster[] = [];
  subDocumentTypes: Applicationmaster[] = [];

  selectedKRPIndex: number;
  KRPRoleName: string;
  KRPlblCtrlName: string;
  peoplepickerIndex: number = 0;
  KRPCtrl: string;
  validateKRP = false;
  KRPmembersListArray = null;
  currentPartnerListRowIndex: number;
  keyRelationshipPartnersList: KeyRelationshipPartners[] = [];
  arrControlValidation: FieldValidator[] = [];

  adminRoleName: string;
  isPersonalClientType: boolean = false;

  isValidClientProgram: boolean = true;
  isValidWebsiteUrl: boolean = true;
  isValidBusinessContactEmail: boolean = true;
  isValidBillingContactName: boolean = true;
  isValidBillingContactTitle: boolean = true;
  isValidBillingContactEmail: boolean = true;
  isValidMSAType: boolean;
  isInvalidMSAEffectiveDate: boolean;
  isInvalidMSAExpirationDate: boolean;

  clientTypes: ClientTypes[] = [];

  msaEffectiveDateCntrlId: string = "msaEffectiveDate";
  msaExpirationDateCntrlId: string = "msaExpirationDate";

  strMSAEffectiveDate: string;
  strMSAExpirationDate: string;

  objectArray: [string, any][];

  isKRPUpdated: boolean = false;

  templateList: TemplatePackageModel[] = [];
  alert: Alert;
  isSubDocumentTypeControlsDisabled: boolean = true;
  isTemplateDisabled: boolean = true;
  isTempateNotSelected: boolean = false;
  isEffectiveDateEmpty: boolean = false;
  isExpriationDateEmpty: boolean = false;

  isCLMFeatureEnabled: boolean = false;


  GTAgreementOwnerRoleName: string;
  GTAgreementOwnerlblCtrlName: string;
  validateGTAgreementOwner: boolean;
  selectedGTAgreementOwnerIndex: number;
  GTAgreementOwnerNameCtrl: string;
  isvalidGTAgreementOwner: boolean = true;
  GTAgreementOwnerCtrl: string;
  selectContactEmailId: string;
  controlPopoverId: string;
  currentComponentName: string = "EditClient";
  selectedActionName: string ='';
  isClientLevelActionPerformed: boolean = false;
  selectedAgreementData: ClientLevelDocument = new ClientLevelDocument();
  isvalidEFD: boolean = true;
  isvalidEXD: boolean = true;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
    private clientService: ClientService,
    private reacceptanceClientsService: ReacceptanceClientsService,
    private countryService: CountryService,
    private stateService: StateService,
    private adUsersService: AdUsersService,
    private _alertService: AlertsService,
    private dataService: DataService,
    private router: Router,
    private CLMService: CLMService,
    private logService: LogService,
    private applicationMasterService: ApplicationMasterService,
    private _hdService: HotDocsService) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
  }


  ngOnInit() {
    this._alertService.remove(this.alert);
    this.KRPRoleName = RoleNames.KeyRelationshipPartner;
    // purposely keep krp role as we don't have mapping in ad for CMS Client Administrator
    this.adminRoleName = RoleNames.ClientAdministrator;
    this.KRPlblCtrlName = 'key relationship leader';
    this.GTAgreementOwnerRoleName = RoleNames.GTAgreementOwner;
    this.GTAgreementOwnerlblCtrlName = 'GT agreement owner';

    this.CLMService.getCLMFeatureFlag().subscribe(result => {
      this.isCLMFeatureEnabled = result;
    });

    this.route.paramMap.subscribe(params => {
      this.clientId = +params.get('clientId');
    });
    this.dataService.setClientIdForPrePopulate(this.clientId); 
    this.IsLoggedinUserSystemAdmin();
    this.GetClientEditDetailsById();
    //this.GetClientProgramTypes();
    //this.GetKeyRelationshipPartnersByClientId(this.clientId);
    this.GetContactTypes();
    this.GetMSATypes();
    this.GetTemplatePackages();
    this.InitalizeFields();
  }
  ngOnDestroy() {
    this._alertService.remove(this.alert);
    this._alertService.alerts = [];
    this.ResetIwantToUnsavedFlag();
  }

  ParseDateToString(dateToParse) {
    var d = new Date(dateToParse);

    var date = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();

    return month + "/" + date + "/" + year;
  }

  GetClientEditDetailsById() {
    this.loadingScreenService.startLoading();
    this.clientService.GetClientEditDetailById(this.clientId).subscribe(clientDetails => {
      this.loadingScreenService.stopLoading();
      this.editClientViewModel = clientDetails;

      this.client = this.editClientViewModel.client;

      if (this.client.billingContactTitleCodeId == 0)
        this.client.billingContactTitleCodeId = <any>('undefined');

      this.clientAdministrator = this.editClientViewModel.clientAdministrator;
      // if (this.editClientViewModel.keyRelationshipPartner !== null
      //   && this.editClientViewModel.keyRelationshipPartner !== undefined) {
      //   this.keyRelationshipPartner = this.editClientViewModel.keyRelationshipPartner;
      // }
      
      this.GetClientTypes();
    },
      err => {
        this.loadingScreenService.stopLoading();
        this.logService.error('Failed to load client details', err);
      });
     
  }

  GetClientProgramTypes() {
    this.applicationMasterService.GetAllClientPrograms().subscribe(
      result => {
        this.clientProgramTypes = this.sortClientProgramTypes(result);
      },
      error => {
        this.clientProgramTypes = null;
      }
    );
  }

  sortClientProgramTypes(clientProgramTypes: Applicationmaster[]): Applicationmaster[] {
    return clientProgramTypes.sort((a, b) => {
      if (a.displayOrder < b.displayOrder)
        return -1;
      if (a.displayOrder > b.displayOrder)
        return 1;
      return 0;
    });
  }

  refreshModalPartnersList(index: number, textboxName: string) {
    this.removeRowSelection('tblKRPAD', 'searchPartnerName', 'PartnerNameErrorMessage');
    index = this.peoplepickerIndex;
    this.logService.debug('calling refresh function to get Partners data');

    this.KRPCtrl = textboxName + this.KRPRoleName + '_' + this.peoplepickerIndex;
    this.currentPartnerListRowIndex = index;
    this.validateKRP = false;
    this.KRPmembersListArray = null;
  }

  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $("#" + errorMessageId).text('');
    var searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }

  searchPartnerNames(searchControlName: string) {
    const searchText = $('#' + searchControlName).val();
    this.GetRelationshipPartnersFromAD(searchText);
  }

  GetRelationshipPartnersFromAD(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.KRPmembersListArray = userList;
        },
        (error) => {
          this.KRPmembersListArray = null;
          this.loadingScreenService.stopLoading();
          console.log('Error in getting response GetRelationshipPartners func ', error);
        });
  }

  selectPartner(index, event) {
    $('#tblKRPAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedKRPIndex = index;
  }

  deselectPartner() {
    $('#searchPartnerName').val('');
    this.KRPmembersListArray = null;
    this.keyRelationshipPartnersList = null;
    this.selectedKRPIndex = null;
  }

  setPartner() {
    let index = this.selectedKRPIndex;
    if (Number(index) >= 0) {
      this.isKRPUpdated = true;
      this.keyRelationshipPartnersList = [];
      const selectedPartnerName = $('#spdisplayName' + index).text();
      const selectedPartnerEmail = $('#spmail' + index).text();
      const selectedPartnerTitle = $('#spjobtitle' + index).text();
      const selectedPartnerPhone = $('#spbusinessPhones' + index).text();

      this.keyRelationshipPartner.relationshipPartnerName = selectedPartnerName;
      this.keyRelationshipPartner.email = selectedPartnerEmail;
      this.keyRelationshipPartner.phoneNumber = selectedPartnerPhone;
      this.keyRelationshipPartner.jobTitle = selectedPartnerTitle;

      this.keyRelationshipPartnersList.push(this.keyRelationshipPartner);
      $('#' + this.KRPCtrl).val(selectedPartnerName);
      $('#UserHiddenProps_KRP_0 #hdnUserName').val(selectedPartnerName);
      $('#UserHiddenProps_KRP_0 #hdnUserEmail').val(selectedPartnerEmail);
      $('#UserHiddenProps_KRP_0 #hdnUserPhone').val(selectedPartnerPhone);
      $('#UserHiddenProps_CLA_0 #hdnJobTitle').val(selectedPartnerTitle);
      this.validateKRP = true;
    }
  }

  GetKeyRelationshipPartnersByClientId(requestedClientId: number) {
    this.clientService.GetKeyRelationshipPartnersByClientId(requestedClientId).subscribe(result => {
      this.keyRelationshipPartnersList = result;
      console.log('received partnerList info =>', this.keyRelationshipPartnersList);
    },
      error => {
        this.keyRelationshipPartnersList = null;
      });
  }

  IsLoggedinUserSystemAdmin() {
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName.trim().toUpperCase() === RoleNames.SystemAdministrator) {
          this.isloggedInUserAdmin = true;
          break;
        }
      }
    }
  }

  GetContactTypes() {
    this.applicationMasterService.GetAllClientContactTypes().subscribe(result => {
      this.contactTypes = result.filter(contactType => contactType.code != 'BILL');
    },
      err => {
        this.logService.error('Failed to load contact types', err);
      });
  }

  GetMSATypes() {
    this.applicationMasterService.GetSubDocumentTypes().subscribe(result => {
      this.subDocumentTypes = result;
    },
      err => {
        this.logService.error('Failed to load subDocumentTypes');
      })
  }

  GetClientTypes() {
    this.clientService.GetClientTypes().subscribe(clientTypes => {
      this.clientTypes = clientTypes
      if (this.clientTypes.length > 0) {
        const currentClientType = this.clientTypes.filter(ctype => ctype.id === this.client.clientTypeId)[0];
        this.isPersonalClientType = currentClientType.typeValue.trim().toLowerCase() === ClientTypesEnum.Personal.toLowerCase();
      }
    },
      err => {
        this.logService.error("Get client types failed...", err);
      });
  }

  ValidateBusinessEmail(email) {
    return this.isValidBusinessContactEmail = this.CheckValidEmail(email);
  }

  ValidateBillingEmail(email) {
    return this.isValidBillingContactEmail = this.CheckValidEmail(email);
  }

  ValidateWebsiteAddress(website) {
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/igm;
    if (regex.test(website))
      return true;
    else
      return false;
  }

  validateDate(date: any, dateName: string) 
  {
    let isSpace = false;
    console.log("passed date is ====="+date);
    if(date === ""){isSpace = true;}else{isSpace = false;}
    let date1 = new Date(date);
    if(isNaN(date1.getTime()) && !isSpace) {
      if(dateName === 'EFD'){
        this.isvalidEFD = false;
      }
      if(dateName === 'EXD'){
        this.isvalidEXD = false;
      }
    }
  }

  GetDateFromString(date) {
    //format date to  mm/dd/yy
    let strDateArray = date.split('/');
    let dateObject = new Date(strDateArray[2], parseInt(strDateArray[0]) - 1, strDateArray[1]);
    return dateObject;
  }

  CheckValidEmail(email: string) {

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(email))
      return true;
    else
      return false;
  }

  UpdateClient() {
    this.isvalidEFD = true;
    this.isvalidEXD = true;
    var effectiveDate = $('.' + this.msaEffectiveDateCntrlId).val();
    var expirationDate = $('.' + this.msaExpirationDateCntrlId).val();
    if(this.isloggedInUserAdmin) {
      // this.validateDate(effectiveDate,'EFD');
      // this.validateDate(expirationDate,'EXD');
    }
    this.loadingScreenService.startLoading();
    //ClientProgram
    //webSite if not empty
    //Business email
    //Billing Name, Title, Email
    //MSA Type
    //MSA Effective/Expiration date
    //Link to documents
    //Document description

    this.isValidClientProgram = this.client.clientProgramId > 0;
    if (this.client.websiteAddress != null && this.client.websiteAddress != '') {
      this.isValidWebsiteUrl = this.ValidateWebsiteAddress(this.client.websiteAddress)
    }
    else {
      this.isValidWebsiteUrl = true;
    }
    if (this.client.clientContactEmail != '' && this.ValidateBusinessEmail(this.client.clientContactEmail)) {
      this.isValidBusinessContactEmail = true;
    }
    else {
      this.isValidBusinessContactEmail = false
    }

    if (this.client.clientBillingName == null || this.client.clientBillingName == '') {
      this.isValidBillingContactName = false;
    }
    else {
      this.isValidBillingContactName = true;
    }

    this.isValidBillingContactTitle = this.client.billingContactTitleCodeId > 0;

    if (this.client.clientBillingEmail != '' && this.ValidateBillingEmail(this.client.clientBillingEmail)) {
      this.isValidBillingContactEmail = true;
    }
    else {
      this.isValidBillingContactEmail = false
    }
    let KRPValue = $('#txtPeoplePicker_' + this.KRPRoleName + '_' + this.peoplepickerIndex).val();
    let isKRPValid = false;

    let hasChanged = $('#txtPeoplePicker_' + this.KRPRoleName + '_' + this.peoplepickerIndex).hasClass('ng-dirty');

    if ((KRPValue !== ''
      && KRPValue !== undefined
      && (this.isKRPUpdated && $('#hdnValidInput_KRP' + '_' + this.peoplepickerIndex).val() === 'false'))
      || (hasChanged == true && KRPValue != '' && $('#hdnValidInput_KRP' + '_' + this.peoplepickerIndex).val() === 'false')) {
      $('#KRPValid').removeClass('d-none');
      $('#KRPValid').addClass('d-block');
      isKRPValid = false;
    }
    else {
      $('#KRPValid').removeClass('d-block');
      $('#KRPValid').addClass('d-none');
      isKRPValid = true;
    }

    if (this.clientLevelDocument.documentSubTypeId > 0) {
      this.clientLevelDocument.gtAgreementOwnerName = $('#txtPeoplePicker_AO_0').val();
      this.clientLevelDocument.gtAgreementOwnerEmail = $('#UserHiddenProps_AO_0 #hdnUserEmail').val();
      if(this.clientLevelDocument.gtAgreementOwnerName != '' && this.clientLevelDocument.gtAgreementOwnerName != null &&
        this.clientLevelDocument.gtAgreementOwnerEmail != '' && this.clientLevelDocument.gtAgreementOwnerEmail != null ){
        this.validateGTAgreementOwner = true; 
      } else {
        this.validateGTAgreementOwner = false;
      }
      if (!this.ValidateSubDocumentTypeSection()) {
        this.loadingScreenService.stopLoading();
        return;
      }
    }
    if(!this.isvalidEFD){
      this.loadingScreenService.stopLoading();
    }
    if(!this.isvalidEXD){
      this.loadingScreenService.stopLoading();
    }

    if (this.isValidClientProgram && this.isValidWebsiteUrl && this.isValidBusinessContactEmail && this.isValidBillingContactName
      && this.isValidBillingContactEmail && this.isValidBillingContactTitle && this.isValidBusinessContactEmail && isKRPValid && this.isvalidEFD && this.isvalidEXD) {
      this.client = this.editClientViewModel.client;
      this.client.createdBy = this.userObject.userName;
      this.client.updatedBy = this.userObject.userName;
      this.clientAdministrator = this.editClientViewModel.clientAdministrator;
      //this.keyRelationshipPartner = this.editClientViewModel.keyRelationshipPartner;

      if (this.clientLevelDocument.templatePackageId && this.clientLevelDocument.templatePackageId != null && this.clientLevelDocument.templatePackageId != null) {
        let selectedTemplate = this.templateList.filter(template => template.templatePackageId == this.clientLevelDocument.templatePackageId)[0];
        this.clientLevelDocument.templateName = selectedTemplate.name;
        this.clientLevelDocument.templateVersion = selectedTemplate.liveVersionNumber;
      }

      this.client.clientLevelDocument = this.clientLevelDocument;
      this.editClientViewModel.client = this.client;
      this.editClientViewModel.clientAdministrator = this.clientAdministrator;
      this.editClientViewModel.loggedInUserFullName = this.userObject.loggedInUserFullName;
      this.editClientViewModel.IsSystemAdmin = this.isloggedInUserAdmin;

      if ($('#hdnUserName').val() != '') {
        this.keyRelationshipPartner = new KeyRelationshipPartners();
        this.keyRelationshipPartner.relationshipPartnerName = $('#hdnUserName').val();
        this.keyRelationshipPartner.email = $('#hdnUserEmail').val();
        this.keyRelationshipPartner.phoneNumber = $('#hdnUserPhone').val();
        this.keyRelationshipPartner.jobTitle = $('#hdnJobTitle').val();

        //this.editClientViewModel.keyRelationshipPartner = this.keyRelationshipPartner;
      }
      else if (this.isKRPUpdated || hasChanged) {
        //this.editClientViewModel.keyRelationshipPartner = null;
      }

      this.clientService.UpdateClient(this.editClientViewModel).subscribe(resutl => {
        this.router.navigate(['/ClientDetails/', this.clientId]);
        this.loadingScreenService.stopLoading();
      },
        err => {
          this.logService.error('Client update failed', err);
          this.loadingScreenService.stopLoading();
          this._alertService.add('Client update failed', 'danger', null);
        });
    }
    else
      this.loadingScreenService.stopLoading();
  }

  Cancel() {
    this._alertService.remove(this.alert);
    this.router.navigate(['/ClientDetails/', this.clientId]);
  }

  GetTemplatePackages() {
    this._hdService.getTemplatePackages().subscribe(result => {
      this.templateList = result;
    },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.remove(this.alert);
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  InitalizeFields() {
    this.clientLevelDocument.clientId = this.client.clientId;
    this.clientLevelDocument.documentSubTypeId = undefined;
    this.clientLevelDocument.templatePackageId = undefined;
  }

  SelectedSubDocumentTypeChanged() {
    if (this.clientLevelDocument.documentSubTypeId > 0) {
      this.isSubDocumentTypeControlsDisabled = false;
      let selectedSubDocumentType = this.subDocumentTypes.filter(documentType => documentType.applicationMasterId == this.clientLevelDocument.documentSubTypeId)[0];
      if (selectedSubDocumentType.code != 'CMSA') {
        this.isTemplateDisabled = true;
        this.clientLevelDocument.templatePackageId = undefined;
      }
      else {
        this.isTemplateDisabled = false;
      }
    }
    else {
      this.isTemplateDisabled = true;
      this.isEffectiveDateEmpty = false;
      this.isExpriationDateEmpty = false;
      this.isTempateNotSelected = false;
      this.isSubDocumentTypeControlsDisabled = true;
      this.clientLevelDocument.templatePackageId = undefined;
      $('.' + this.msaEffectiveDateCntrlId).val('');
      $('.' + this.msaExpirationDateCntrlId).val('');
    }
  }

  ValidateSubDocumentTypeSection() {
    let status = false;
    this.isvalidGTAgreementOwner = true;
    this.isEffectiveDateEmpty = $('.' + this.msaEffectiveDateCntrlId).val() == '';
    if (!this.isEffectiveDateEmpty) {
      this.ValidateMSAEffectiveDate();
      this.ValidateMSAExpirationDate();
    }

    let selectedSubDocumentType = this.subDocumentTypes.filter(documentType => documentType.applicationMasterId == this.clientLevelDocument.documentSubTypeId)[0];

    if (selectedSubDocumentType.code == 'CMSA'
      && (this.clientLevelDocument.templatePackageId == undefined || this.clientLevelDocument.templatePackageId == '' || this.clientLevelDocument.templatePackageId == null)) {
      this.isTempateNotSelected = true;
    }
    else {
      this.isTempateNotSelected = false;
    }
    //Check if gt agreement owner is valid
    if((this.clientLevelDocument.gtAgreementOwnerName != '' && this.clientLevelDocument.gtAgreementOwnerName != null) &&
    (this.clientLevelDocument.gtAgreementOwnerEmail == '' || this.clientLevelDocument.gtAgreementOwnerEmail == null)){
    this.isvalidGTAgreementOwner = false;
    this.clientLevelDocument.gtAgreementOwnerName = '';
    this.clientLevelDocument.gtAgreementOwnerEmail = '';
  }
    status = !this.isEffectiveDateEmpty && !this.isTempateNotSelected && !this.isInvalidMSAEffectiveDate && !this.isInvalidMSAExpirationDate && this.isvalidGTAgreementOwner;
    return status;
  }

  ValidateMSAEffectiveDate() {
    let effectiveDate = $('.' + this.msaEffectiveDateCntrlId).val();
    this.clientLevelDocument.effectiveDate = this.GetDateFromString(effectiveDate);
    this.isInvalidMSAEffectiveDate = this.clientLevelDocument.effectiveDate > this.clientLevelDocument.expirationDate;
  }

  ValidateMSAExpirationDate() {
    this.isExpriationDateEmpty = $('.' + this.msaExpirationDateCntrlId).val() == '';
    if(!this.isExpriationDateEmpty){
      let expirationDate = $('.' + this.msaExpirationDateCntrlId).val();
      let rawDate = new Date();
      let today = new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate());
      this.clientLevelDocument.expirationDate = this.GetDateFromString(expirationDate);
      this.isInvalidMSAExpirationDate = (this.clientLevelDocument.expirationDate < today || this.clientLevelDocument.expirationDate < this.clientLevelDocument.effectiveDate);
    }
  }
  DeleteClientLevelDocument(clientLevelDocumentId : number){
    this.loadingScreenService.startLoading();
    this.clientService.DeleteClientLevelDocument(clientLevelDocumentId).subscribe(deleteResult =>{
      if(deleteResult){
        console.log(deleteResult);
        this.editClientViewModel.clientLevelDocuments = [];
      }
      this.clientService.GetClientLevelDocuments(this.clientId).subscribe(
        result => {
          this.editClientViewModel.clientLevelDocuments= result;
          this.loadingScreenService.stopLoading();
        }, error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
    },
    error => {
      this.loadingScreenService.stopLoading();
      this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
    });

  }
  deselectGTAgreementOwner() {
    $('#' + this.GTAgreementOwnerNameCtrl).val('');
    $('#' + this.GTAgreementOwnerNameCtrl).innerText = '';
    $('#' + this.GTAgreementOwnerCtrl).val('');
    $('#' + this.GTAgreementOwnerCtrl).innerText = '';
  }
  selectGTAgreementOwner(index) {
    $('#tblGTAgreementOwner').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedGTAgreementOwnerIndex = index;
    console.log('selectGTAgreementOwner func called ', this.selectedGTAgreementOwnerIndex);
  }
  setGTAgreementOwner() {
    let index = this.selectedGTAgreementOwnerIndex;
    if (Number(index) >= 0) {
      const selectedGTAgreementOwnerName = $('#spGTAgreementOwnerdisplayName' + index).text();
      const selectedGTAgreementOwnerEmail = $('#spGTAgreementOwnermail' + index).text();
      const selectedEGTAgreementOwnerPhone = $('#spGTAgreementOwnerbusinessPhones' + index).text();
      this.clientLevelDocument.gtAgreementOwnerEmail = selectedGTAgreementOwnerEmail;
      this.clientLevelDocument.gtAgreementOwnerName = selectedGTAgreementOwnerName;
      this.validateGTAgreementOwner = true;
      $("#GTAgreementOwnerErrorMessage").text('');
      if (this.GTAgreementOwnerCtrl !== '') {
        $('#' + this.GTAgreementOwnerCtrl).val(selectedGTAgreementOwnerName);
        $('#txtPeoplePicker_AO_'+this.peoplepickerIndex).val(selectedGTAgreementOwnerName);
        $('#UserHiddenProps_AO_0 #hdnUserName').val(selectedGTAgreementOwnerName);
        $('#UserHiddenProps_AO_0 #hdnUserEmail').val(selectedGTAgreementOwnerEmail);
        $('#UserHiddenProps_AO_0 #hdnUserPhone').val(selectedEGTAgreementOwnerPhone);
        $('#hdnValidInput_AO_0').val(true);
      }

      if (this.GTAgreementOwnerCtrl !== '') {
        this.clientLevelDocument.gtAgreementOwnerName = selectedGTAgreementOwnerName;
        this.clientLevelDocument.gtAgreementOwnerEmail = selectedGTAgreementOwnerEmail;
      }
    }
    this.KRPmembersListArray = null;
  }
  refreshGTAgreementOwnerPopup(textboxName: string) {
    this.removeRowSelection('tblGTAgreementOwner', 'searchGTAgreementOwnerName', 'GTAgreementOwnerErrorMessage');
    this.GTAgreementOwnerCtrl = textboxName + this.GTAgreementOwnerRoleName + '_' + this.peoplepickerIndex;
    this.selectedGTAgreementOwnerIndex = null;
    this.validateGTAgreementOwner = false;
    this.KRPmembersListArray = null;
    if ($('#' + this.GTAgreementOwnerCtrl).hasClass('text-danger')) {
      $('#' + this.GTAgreementOwnerCtrl).removeClass('text-danger');
    }
  }
  displayContactPopover(emailId: string, popoverControlId: string) {
    this.selectContactEmailId = emailId;
    this.controlPopoverId = popoverControlId;
    this.hidePopUp();
    this.bindContactPopup();
    this.checkForContactContent('contact-popup');
    $('#' + this.controlPopoverId).popover('show');

  }
  checkForContactContent(contact) {
    const timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 900);
  }
  hidePopUp() {
    $('.contactUser').popover('hide');
  }

  bindContactPopup() {
    $('.contactUser').popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',    
      boundary: 'viewport',
      content: function () {
        return '<div class="mx-auto" style="width: 200px;">Loading ...</div>';
      }
    });
  }
  ngAfterViewInit() {
    $('html').on('click', function (e) {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        $('.contactUser').popover('hide');
      }
    });

    $('body').on('click', (e) => {
      //did not click a popover toggle or popover
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          $('.contactUser').popover('hide');

        }
      }
    });
  }
  updateClientLevelDocumentList(event : ClientLevelDocument[]) {
    console.log('Updated Client level doc list ==>>',event);
    this.editClientViewModel.clientLevelDocuments = event;
  }
  ProcessAction(actionName : string, selectedAgreement: ClientLevelDocument){
    if(actionName === 'Edit' || actionName === 'Remove'){
      this.isClientLevelActionPerformed = true;
      this.selectedActionName = actionName;
      this.selectedAgreementData = selectedAgreement;
    }
    
  }
  ResetIwantToUnsavedFlag()
  {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setUnsavedChangesModel(dialogJSON);
    this.dataService.setClientIdForPrePopulate(-1);
    this.dataService.setEngagementIdForPrePopulate(-1);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SPDocumentList } from 'src/app/model/SharePoiint/SharePointSite';

@Pipe({
  name: 'filterSpfileLabel',
  pure:false
})
export class FilterSpfileLabelPipe implements PipeTransform { //Sharepoint files filter by label 

  transform(documents: SPDocumentList[], labelSearchText: string): SPDocumentList[] {
    if (!documents) { // return empty documents if documents is falsy
      return [];
    }
    if (labelSearchText && labelSearchText == "undefined" || labelSearchText == "All") {// return the original documents if labelSearchText is empty/all 
      return documents;
    }
    else {
      if (labelSearchText == "Unlabeled") { // return the filter by unlabeled searchtext  label
        return documents.filter(doc => doc.label == undefined || doc.label == '');
      }
      else { // return the filter by selected searchtext  label
        return documents.filter(doc => doc.label == labelSearchText);
      }
    }
  }

}

<section class="filter-wrapper">
  <div class="row">
    <div class="col-12 col-xs-12 col-md-12 col-lg-12 pr-0 pl-0">
      <input #search type="text" id="txtValue{{MappedSource}}{{Sequence}}" name="txtValue{{MappedSource}}{{Sequence}}"
         autocomplete="off"  [name]="name" [(ngModel)]="val"
        class="form-control rounded-0 textBoxDesign input-height"
        (keydown.enter)="onSearch(search.value);$event.preventDefault();"
        placeholder="{{clientNamePlaceholderText}}" (focus)="this.clientNamePlaceholderText=''" (blur)="this.clientNamePlaceholderText='Enter a client name or client code'"
        (keydown.backspace)="onBackspace($event)" />
      <input type="hidden" id="hdnKey{{MappedSource}}{{Sequence}}" />
      <input type="hidden" id="hdnKeyCode{{MappedSource}}{{Sequence}}" />
      <input type="hidden" id="hdnclientOneViewAccountId{{MappedSource}}{{Sequence}}" />
    </div>
  </div>
  <div class="row filter-list">
    <div class="col-12 col-xs-12 col-md-12 col-lg-12">
      <div class="filter-select {{MappedSource}}{{Sequence}} p-0">
        <div class="row w-100 client-smart-search-header">
          <div class="col-md-5 font-weight-bold text-decoration-underline">Client Name</div>
          <!-- <div class="col-md-1 font-weight-bold text-decoration-underline">Client Code</div> -->
          <div class="col-md-2 font-weight-bold text-decoration-underline">Headquarters</div>
          <div class="col-md-3 font-weight-bold text-decoration-underline">Web site</div>
          <div class="col-md-2 font-weight-bold text-decoration-underline">Status</div>
        </div>
        <div class="row w-100" *ngFor="let client of clientList" (mousedown)="selectedValue($event, client)">
          <div class="col-md-5" innerHTML="{{ client.clientName | highlight : search.value}} {{  client.clientCode==null ?'': '(' +client.clientCode+ ')' | highlight : search.value}}">
          </div>
          <!-- <div class="col-md-1" innerHTML="{{ client.clientCode | highlight : search.value}}">
          </div> -->
          <div class="col-md-2" innerHTML="{{ client.customeHeadquarter | highlight : search.value}}">
          </div>
          <div class="col-md-3" innerHTML="{{ client.websiteAddress | highlight : search.value}}">
          </div>
          <div class="col-md-2"
            [ngClass]="{'color-closed-status': client.clientStatus === 'Closed', '': client.clientStatus !== 'Closed'}">
            {{client.clientStatus}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
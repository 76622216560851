import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { IWantToList } from '../model/IWantToList';
import { NavBar } from '../model/NavBar';
import { AppConfigService } from './appConfigService';
import { WorkspaceReviewPoint, WorkspaceReviewPointComment, ReviewPointsWithCommentsModel } from '../model/WorkspaceReviewPoint';


@Injectable()
export class WorkspaceReviewPointService {
    APIEndPoint: string;
    //workspaceId:string = "97D605FB-BAC7-4769-9FAC-8B4E66580005";
    userName: string;
    name: string;

    private reviewNotesCount$ = new BehaviorSubject<number>(0);
    reviewPointsWithCommentsModel: ReviewPointsWithCommentsModel = new ReviewPointsWithCommentsModel();
    
    constructor(private enviornment: AppConfigService, private http: HttpClient) {
        this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
        console.log('API End Point URL ', this.APIEndPoint);
        let nameJSON = JSON.parse(localStorage.getItem("currentUser"));
        if (nameJSON != null) {
            this.userName = nameJSON.userName;
            this.name = nameJSON.loggedInUserFullName;
        }
    }

    getWorkspaceReviewPoints(workspaceId: string): Observable<WorkspaceReviewPoint[]> {
        return this.http.get<WorkspaceReviewPoint[]>(this.APIEndPoint + '/WorkspaceReviewPoints/GetWorkspaceReviewPoints/' + workspaceId);
    }

    getReviewPointsWithComments(workspaceId: string, isRequestFromReviewNoteComponent: boolean): Observable<ReviewPointsWithCommentsModel> {
        return this.http.get<ReviewPointsWithCommentsModel>(this.APIEndPoint + '/WorkspaceReviewPoints/GetReviewPointWithCommentsByWorkspace/' + workspaceId + '/'+ isRequestFromReviewNoteComponent);
    }

    InsertReviewPoints(workspaceReviewPoint: WorkspaceReviewPoint): Observable<WorkspaceReviewPoint> {

        workspaceReviewPoint.userName = this.userName;
        workspaceReviewPoint.createdBy = this.userName;
        workspaceReviewPoint.lastModifiedBy = this.userName;
        workspaceReviewPoint.status = 1;
        workspaceReviewPoint.name = this.name;

        return this.http.post<WorkspaceReviewPoint>(this.APIEndPoint + "/WorkspaceReviewPoints/InsertReviewPoints", workspaceReviewPoint)
    }
    getWorkspaceReviewPointById(reviewPointId: number): Observable<WorkspaceReviewPoint> {
        return this.http.get<WorkspaceReviewPoint>(this.APIEndPoint + '/WorkspaceReviewPoints/GetWorkspaceReviewPointsById/' + reviewPointId);
    }

    InsertReviewPointComments(workspaceReviewPointcomments: WorkspaceReviewPointComment): Observable<WorkspaceReviewPointComment> {

        workspaceReviewPointcomments.createdBy = this.userName;
        workspaceReviewPointcomments.lastModifiedBy = this.userName;
        workspaceReviewPointcomments.name = this.name;
        return this.http.post<WorkspaceReviewPointComment>(this.APIEndPoint + "/WorkspaceReviewPoints/InsertReviewPointComments", workspaceReviewPointcomments)
    }
    getWorkspaceReviewPointComments(reviewPointId: number): Observable<WorkspaceReviewPointComment[]> {
        return this.http.get<WorkspaceReviewPointComment[]>(this.APIEndPoint + '/WorkspaceReviewPoints/GetWorkspaceReviewPointComments/' + reviewPointId);
    }

    getWorkspaceReviewPointCommentsByWorkspace(workspaceId: string): Observable<WorkspaceReviewPointComment[]> {
        return this.http.get<WorkspaceReviewPointComment[]>(this.APIEndPoint + '/WorkspaceReviewPoints/GetWorkspaceReviewPointCommentsByWorkspace/' + workspaceId);
    }
        
    ResolveReviewPoint(workspaceReviewPoint: WorkspaceReviewPoint): Observable<WorkspaceReviewPoint> {
        workspaceReviewPoint.lastModifiedBy = this.userName;

        return this.http.post<WorkspaceReviewPoint>(this.APIEndPoint + "/WorkspaceReviewPoints/ResolveReviewPoint", workspaceReviewPoint)
    }

    getReviewNotesCount() : Observable<number> {
        return this.reviewNotesCount$;
    }

    setReviewNotesCount(count: number){
        this.reviewNotesCount$.next(count);
    }
}

export class CloseoutResponseModel {
    EngagementId: number;
    ResponseHTML: string;
    HDWorkItemId: string;
    TemplatePackageId: string;
    TemplatePackageVersionId: string;
    TemplateVersion: number;
    TemplateName: string;
    CreatedBy: string;
    CloseoutStatus: string;
    Total: number;
    Answered: number;
    Unanswered: number;
}
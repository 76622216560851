<!-- Start Container -->
<app-loading-screen></app-loading-screen>
<div class="clientContainer containerDiv">
  <!-- Page Title row -->
  <div class="d-flex justify-content-between pageTitleParent pb-3 mw-100 mt-3">
    <div class="text-center">
      <h2 class=" float-md-left pageTitle largerFontSize2" *ngIf="EditMode==false">Create new client</h2>
      <h2 class="float-md-left pageTitle largerFontSize2" *ngIf="EditMode==true">Edit client</h2>
    </div>
    <div class="text-center">
      <span class="float-md-right">
        <app-shortcut></app-shortcut>
      </span>
    </div>
  </div>
  <!-- End Page Title row -->


  <form novalidate #CreateClientForm="ngForm">
    <!--Main Div under CreateClientForm-->
    <div class="bg-edp-body pb-3">

      <div class="oneviewAccountSearchDivMain">
        <div class="col-md-12 bg-white oneviewAccountSearchDivInner">
          <div class="col-12 col-sm-12 col-lg-12">
            <label> <b>Search oneView</b></label>
            <div class="pb-2">The search results displayed below will include accounts tracked in oneView. Clicking “Use
              this one” on an account with an existing client code will return you to the Create new engagement page
              with this client. Selecting an account with no existing client code will pull available oneView data into
              the form below. If none of the search results are desired, fill in the name of the client and click the
              “Skip” button.</div>
            <oneview-client-smart-search #ClientSearch name="ClientName" id="ClientName" source="ClientWithShortNm"
              [MappedSourceClientNameValue]="mappedSourceClientNameValue" [MappedSource]="MappedValue"
              (validation)="ClientValidation($event)" [Sequence]="0" (clientChange)="clientChange()"
              (OnSkipAccountSearch)="OnSkipAccountSearch()">
            
            </oneview-client-smart-search>
            <div id="clientValidationMessage" class="engagement-sample-text error-color d-none"></div>
          </div>
        </div>
      </div>

      <div class="clientCreationDiv hide">
        <div class="row pb-2 pt-0 ml-0">
          <div class="col-12 col-sm-12 col-lg-12">
            <input type="hidden" id="hdnIsOneViewClientLinked" value=false />
            <div id="divSelectedAccountText" class="pt-2">Selected oneView account: <b><span
                  id="spanSelectedAccountName">None selected</span></b>&nbsp;&nbsp;&nbsp;<span class="usethislink"
                (click)="confirmModalResetOneViewClientConnection()">Reset</span></div>
          </div>
        </div>
        <div class="row pt-2 pb-3 ml-0">
          <div class="col-6 col-sm-12 col-lg-6">
            <label>Client type <span class="mandatory">*</span></label>
            <div class="selectdiv">
              <select class="dropdown rounded-0 " [(ngModel)]="client.clientTypeId" id="ddClientType"
                name="ddClientType" (change)="onClientTypeChange()">
                <option *ngFor="let item of clientTypes" [value]="item.id">
                  {{item.typeValue}}
                </option>
              </select>
            </div>
            <div *ngIf="!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply" class="error-color">
              Client type is required.
            </div>
          </div>
        </div>


        <div class="row py-3 ml-0" *ngIf="!isPersonalClientType">
          <div class="col-12 col-sm-12 col-lg-12">
            <label> Client name <span class="mandatory">*</span></label>
            <span class="d-flex">
              <input type="text" id="txtClientName" name="txtClientName" class="form-control rounded-0 border input-height"
                placeholder="Enter a client name" #txtClientName="ngModel" [(ngModel)]="clientViewModel.clientNameModel.clientName"
                (blur)="CheckClientNameDuplicates($event);$event.preventDefault();"
                (keydown.enter)="CheckClientNameDuplicates($event);$event.preventDefault();"
                (keyup)="blockScriptTags($event)"
                [ngModelOptions]="{standalone: true}" maxlength="40" />
              <span class="icon-dupe-check" (click)="CheckClientNameDuplicates(null);$event.preventDefault();"></span>
            </span>
            <div class="error-color" id="divClientnameOneViewErrorMsg"
              *ngIf="clientViewModel.clientNameModel.isFieldExistsInOneView">
              {{clientViewModel.clientNameModel.fieldValidationMessage}}
            </div>
            <div id="divClientname_line1OneViewDuplicateErrorMsg"
              *ngIf="clientViewModel.clientNameModel.canShowDuplicateNotFoundMessage">
              {{clientViewModel.clientNameModel.duplicateNotFoundMessage}}
            </div>
            <div id="clientNameDuplicateExistsInOneViewAccount" class="error-color d-none">
              A duplicate of this client name already exists for an account in oneView. 
            </div>
            <div class="error-color" *ngIf="!arrControlValidation[17]?.isvalid && arrControlValidation[17]?.apply">
              Client name is required
            </div>
            <!-- <a class="link-text-blue edp-font-14 ml-1 sap-bar" (click)="useThisOneClick(true)">use This One</a> -->
            <div class="error-color" *ngIf="invalidvalues!='' && invalidvalues!=null">
              <p><span *ngIf="invalidvalues.length === 1">'{{invalidvalues}}' is not allowed.</span>
                <span *ngIf="invalidvalues.length > 1"> Cannot create client name that begins '{{invalidvalues}}'</span></p>
            </div>
          </div>
        </div>

        <div class="row py-3 ml-0" *ngIf="isPersonalClientType==true">
          <div class="col-md-4">
            <label> First name <span class="mandatory">*</span></label>
            <input type="text" id="txtFirstName" name="txtFirstName" class="form-control rounded-0 border input-height"
              placeholder="First name" #firstname="ngModel" [(ngModel)]="client.firstName"
              [ngModelOptions]="{standalone: true}" maxlength="20" />
            <div class="error-color" *ngIf="!arrControlValidation[17]?.isvalid && arrControlValidation[17]?.apply">
              First name is required
            </div>
          </div>

          <div class="col-md-4">
            <label> Middle name <span class=""></span></label>
            <input type="text" id="txtMiddleName" name="txtMiddleName"
              class="form-control rounded-0 border input-height" placeholder="Middle name"
              [(ngModel)]="client.middleName" maxlength="20" />
          </div>

          <div class="col-md-4">
            <label> Last name <span class="mandatory">*</span></label>
            <input type="text" id="txtLastName" name="txtLastName" class="form-control rounded-0 border input-height"
              placeholder="Last name" #lastname="ngModel" [(ngModel)]="client.lastName" maxlength="30" />
            <div class="error-color" *ngIf="!arrControlValidation[18]?.isvalid && arrControlValidation[18]?.apply">
              Last name is required
            </div>
          </div>
        </div>

        <div class="row py-3 ml-0">
          <div class="col-6 col-lg-6">
            <label>Industry <span class="mandatory">*</span></label>
            <div class="selectdiv">
              <select class="dropdown rounded-0 " name="ddIndustry" id="ddIndustry" [(ngModel)]="client.industryTypeId">
                <option [value]="0" selected disabled hidden>Select from existing industries </option>
                <option *ngFor="let industry of industryTypes " [value]="industry.id">
                  {{industry.typeValue}}
                </option>
              </select>
            </div>
            <div *ngIf="!arrControlValidation[15]?.isvalid && arrControlValidation[15]?.apply" class="error-color">
              Industry type field is required
            </div>
          </div>

          <div class="col-6 col-lg-6">
            <label> SIC code <span class="mandatory">*</span></label>
            <app-auto-complete-text-box name="ddSicCodes" id="ddSicCodes" source="SICCode"
              [MappedSourceValue]="mappedSourceSicValue" [MappedSource]="SICCodeMappedValue" ngModel
              #ddSicCodes="ngModel" [Sequence]="0" [NoRecordFoundFor]="false">
            </app-auto-complete-text-box>
            <div *ngIf="!arrControlValidation[16]?.isvalid && arrControlValidation[16]?.apply" class="error-color">
              SIC code field is required
            </div>
          </div>
        </div>
        <div class="row py-3 ml-0">
          <div class="col-6 col-sm-12 col-lg-6">
            <label>Structure/entity <span class="mandatory">*</span></label>
            <div class="selectdiv">
              <select class="dropdown rounded-0 " name="ddStructEntity" id="ddStructEntity"
                [(ngModel)]="client.structEntityId" (ngModelChange)="StructEntityChange(client.structEntityId, true)">
                <option disabled hidden selected [value]="0"> Select from existing entities </option>
                <option *ngFor="let entity of structEntities" [value]="entity.id">
                  {{entity.typeValue}}
                </option>

              </select>
            </div>
            <div *ngIf="!arrControlValidation[1]?.isvalid && arrControlValidation[1]?.apply" class="error-color">
              Structure/entity field is required
            </div>
          </div>
        </div>
        <div class="row py-3 ml-0" *ngIf="!isPersonalClientType">
          <div class="col-md-6">
            <label for="">Stock exchange <span *ngIf="!isValidStockExchange" class="mandatory">*</span></label>
            <div class="selectdiv">
              <select [ngClass]="{stockExchangeDisabled:ValidateStock()}" class="dropdown rounded-0 " name="ddStockVal"
                id="ddStockVal" #ddStockVal="ngModel" [(ngModel)]="client.stockExchangeId" [disabled]="ValidateStock()">
                <option [value]="undefined" selected disabled hidden>Select a stock exchange </option>
                <option *ngFor="let stock of stockExchangeValues " [value]=stock.id>
                  {{stock.typeValue}}
                </option>
              </select>
            </div>
            <div class="client-stockExchange-requiredText d-none error-color" id="stockExchangeRequired">
              Stock exchange field is required
            </div>
          </div>
          <div class="col-md-6">
            <label for="">Ticker symbol <span *ngIf="!isValidTickerSymbol" class="mandatory">*</span> </label>
            <span class="d-flex">
              <input class="form-control rounded-0 border input-height" type="text" id="txtTicketSymbol"
                name="txtTicketSymbol" [(ngModel)]="clientViewModel.tickerSymbolModel.tickerSymbol" #ticker="ngModel"
                [disabled]=ValidateStock() (blur)="checkSpaceChartBegin($event)" placeholder="Ticker symbol"
                maxlength="20" />
              <span id="iconDupeCheckTicketSymbol" class="icon-dupe-check" [class.disabled]=ValidateStock()
                (click)="validateClientField('tickersymbol', true, true);"></span>
            </span>
            <div class="error-color" id="divTickerSymbolOneViewErrorMsg"
              *ngIf="clientViewModel.tickerSymbolModel.isFieldExistsInOneView">
              {{clientViewModel.tickerSymbolModel.fieldValidationMessage}}
            </div>
            <div id="ddivTickerSymbolOneViewNoDuplicateErrorMsg"
              *ngIf="clientViewModel.tickerSymbolModel.canShowDuplicateNotFoundMessage">
              {{clientViewModel.tickerSymbolModel.duplicateNotFoundMessage}}
            </div>
            <div class="error-color d-none" id="tickerSymbolRequired">
              Ticker symbol field is required
            </div>
          </div>
        </div>

        <!-- <div class="row py-3 ml-0">
        <div class="col-6 col-sm-12 col-lg-6">
          <label>Client program <span class="mandatory">*</span></label>
          <div class="selectdiv">
            <select class="dropdown rounded-0 " name="ddClientProgram" id="ddClientProgram" #ddClientProgram="ngModel"
              [(ngModel)]="client.clientProgramId">
              <option [value]="undefined" selected disabled hidden>Select from existing client programs </option>
              <option *ngFor="let clientprogram of clientProgramTypes " [value]=clientprogram.applicationMasterId>
                {{clientprogram.value}}
              </option>
            </select>
          </div>
          <div *ngIf="!arrControlValidation[2]?.isvalid && arrControlValidation[2]?.apply" class="error-color">
            Client program field is required
          </div>
        </div>

      </div> -->

        <div class="row py-3 ml-0">
          <div class="col-6 col-sm-12 col-lg-6">

            <div>
              <label>Referral source <span class="mandatory">*</span></label>
              <img class="referralSourceTooltip pr-0" alt="Tooltip" style="float: right;" data-toggle="tooltip"
                data-html="true" data-placement="right" data-custom-class="customTooltipClientReferal"
                title="<p align='right'>In an effort to correctly track Client Referrals, it is critical that we choose the correct source when opening a new client. This will aid in helping our firm leadership and US Global Client Services proactively monitor and support new global pursuits.</p>">
            </div>
            <div class="pb-2">Referral sources can include existing sources and GTI members firms. Please select "Grant
              Thornton Marketing Efforts" if your source does not currently exist.</div>
            <app-auto-complete-text-box name="ddSourceType" id="ddSourceType" source="ReferralSource"
              [MappedSourceValue]="mappedSourceReferralValue" [MappedSource]="ReferralSourceMappedValue" ngModel
              #ddSourceType="ngModel" [Sequence]="0" [NoRecordFoundFor]="false">
            </app-auto-complete-text-box>
            <div *ngIf="!arrControlValidation[3]?.isvalid && arrControlValidation[3]?.apply" class="error-color">
              Referral source field is required
            </div>
          </div>

        </div>

        <div class="row py-3 ml-0">
          <div class="col-6 col-sm-12 col-lg-6">
            <label>Fiscal year end month <span class="mandatory">*</span></label>
            <div class="selectdiv">
              <select class="dropdown rounded-0 " name="ddMonth" id="ddMonth" #ddMonth="ngModel"
                [(ngModel)]="client.fyeMonth" [value]="client.fyeMonth" (ngModelChange)="SelectDay(client.fyeMonth)">
                <option [value]="0" selected disabled hidden>Select month </option>
                <option *ngFor="let mnth of fiscalYearMonthList " [value]=mnth.monthNo>
                  {{mnth.monthName}}
                </option>
              </select>
            </div>
            <div *ngIf="!arrControlValidation[4]?.isvalid && arrControlValidation[4]?.apply" class="error-color">
              Please select a month
            </div>
          </div>
        </div>

        <div *ngIf="EditMode==true">
          <div class="row">
            <div class="col-md-2 fiscalYE">
              <label>CMS Client Code</label>
            </div>
            <div class="col-md-5">
              <label *ngIf="client.clientCode!=null" id="lblClientCode" name="lblClientCode"
                [(ngModel)]="client.clientCode" #clientcode="ngModel"></label>
            </div>
          </div>
          <br>
        </div>
        <div *ngIf="EditMode==true" class="row py-3">
          <div class="col-md-5 fiscalYE">
            <label for="">Client Status </label>
            <div class="selectdiv">
              <select class="dropdown rounded-0 " name="ddClientStatus" id="ddClientStatus" disabled="disabled"
                [(ngModel)]="client.clientStatusId">
                <option *ngFor="let clientStatus of clientStatusList" [value]=clientStatus.id>
                  {{clientStatus.typeValue}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row py-3 ml-0">
          <div class="col-3 col-sm-6 col-lg-3">
            <label for="">Phone number <span class="mandatory">*</span></label>
            <span class="d-flex">
              <input class="form-control rounded-0 border input-height" type="text" name="txtPhone" id="txtPhone"
                maxlength="15" minlength="10" value="clientViewModel.telephone1Model.telephone1"
                [(ngModel)]="clientViewModel.telephone1Model.telephone1" (keypress)="checkCharCodeForNumber($event)"
                (blur)="validateClientField('telephone1', true, false);"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                placeholder="Phone number" />
              <span class="icon-dupe-check" (click)="validateClientField('telephone1', true, true);"></span>
            </span>
            <span>Hint- Phone number format (123-456-7890)</span>
            <div class="error-color" id="divTelephone1OneErrorMsg"
              *ngIf="clientViewModel.telephone1Model.isFieldExistsInOneView">
              {{clientViewModel.telephone1Model.fieldValidationMessage}}
            </div>
            <div id="divTelephone1OneViewNoDuplicateErrorMsg"
              *ngIf="clientViewModel.telephone1Model.canShowDuplicateNotFoundMessage">
              {{clientViewModel.telephone1Model.duplicateNotFoundMessage}}
            </div>

            <div *ngIf="!arrControlValidation[5]?.isvalid && arrControlValidation[5]?.apply" class="error-color">
              Phone number field is required
            </div>
            <div class="error-color d-none" id="invalid-phone">
              phone number is invalid and/or not in correct format
            </div>
          </div>

          <div class="col-3 col-sm-6 col-lg-3">
            <label for="">Fax number</label>
            <span class="d-flex">
              <input class="form-control rounded-0 border input-height" type="text" id="txtFax" name="txtFax"
                [maxlength]="12" [minlength]="10" value="client.fax" pattern="" [(ngModel)]="client.fax" #fax="ngModel"
                (keypress)="checkCharCodeForNumber($event)" placeholder="Fax number" />
            </span>
          </div>
        </div>
        <!-- address fields -->
        <section>
          <div class="row p-3 ml-0 mr-0">
            <div class="col-6 col-sm-12 col-lg-6 addressFields">
              <div class="row ml-0">
                <div class="col-md-12">
                  <label for="">Address 1 <span class="mandatory">*</span></label>
                  <span class="d-flex">
                    <input class="form-control rounded-0 border input-height" type="text" name="txtaddressLine1"
                      id="txtaddressLine1" value="" pattern="" title="" maxlength="60"
                      [(ngModel)]="clientViewModel.addressLine1Model.address1_line1" #addressLine1="ngModel"
                      (blur)="checkSpaceChartBegin($event)"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      placeholder="Address 1" />
                    <span class="icon-dupe-check" (click)="validateClientField('address1_line1', true, true);"></span>
                  </span>
                  <div class="error-color" id="divAddress1_line1OneViewErrorMsg"
                    *ngIf="clientViewModel.addressLine1Model.isFieldExistsInOneView">
                    {{clientViewModel.addressLine1Model.fieldValidationMessage}}
                  </div>
                  <div id="divAddress1_line1OneViewDuplicateErrorMsg"
                    *ngIf="clientViewModel.addressLine1Model.canShowDuplicateNotFoundMessage">
                    {{clientViewModel.addressLine1Model.duplicateNotFoundMessage}}
                  </div>
                  <div *ngIf="!arrControlValidation[6]?.isvalid && arrControlValidation[6]?.apply" class="error-color">
                    Address 1 field is required
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label for="">Address 2</label>
                  <input class="form-control rounded-0 border input-height" type="text" name="txtaddressLine2"
                    id="txtaddressLine2" value="" pattern="" maxlength="60" [(ngModel)]="client.addressLine2"
                    #addressLine2="ngModel" (blur)="checkSpaceChartBegin($event)"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    placeholder="Address 2" />
                </div>
              </div>

              <div class="row ml-0">
                <div class="col-md-12 mt-3">
                  <label for="">Address 3</label>
                  <input class="form-control rounded-0 border input-height" type="text" name="txtaddressLine3"
                    id="txtaddressLine3" value="" pattern="" maxlength="60" [(ngModel)]="client.addressLine3"
                    #addressLine3="ngModel" (blur)="checkSpaceChartBegin($event)"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    placeholder="Address 3" />
                </div>
                <div class="col-md-12 mt-3">
                  <label for="">Address 4</label>
                  <input class="form-control rounded-0 border input-height" type="text" name="txtaddressLine4"
                    id="txtaddressLine4" value="" pattern="" maxlength="60" [(ngModel)]="client.addressLine4"
                    #addressLine4="ngModel" (blur)="checkSpaceChartBegin($event)"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    placeholder="Address 4" />
                </div>
                <div class="col-md-12 mt-3">
                  <label for="">Web site</label>
                  <span class="d-flex">
                    <input class="form-control rounded-0 border input-height" type="text" name="txtWebsite"
                      id="txtWebsite" value="clientViewModel.webSiteModel.webSite" maxlength="100"
                      [(ngModel)]="clientViewModel.webSiteModel.webSite" #website="ngModel" maxlength="100"
                      (blur)="checkSpaceChartBegin($event)"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      autocomplete="off" placeholder="http:www.url.com" autocomplete="off">
                    <span class="icon-dupe-check" (click)="validateClientField('hs_domainname', true, true);"></span>
                  </span>
                  <div class="error-color" id="divWebSiteurlOneViewErrorMsg"
                    *ngIf="clientViewModel.webSiteModel.isFieldExistsInOneView">
                    {{clientViewModel.webSiteModel.fieldValidationMessage}}
                  </div>
                  <div id="divWebSiteurlOneViewNoDuplicateErrorMsg"
                    *ngIf="clientViewModel.webSiteModel.canShowDuplicateNotFoundMessage">
                    {{clientViewModel.webSiteModel.duplicateNotFoundMessage}}
                  </div>
                  <div *ngIf="!isValidWebsiteAddress" class="error-color">
                    Web site url is not valid
                  </div>
                </div>
              </div>
              <div class="row ml-0">
                <div class="col-md-6 mt-3">
                  <label for="">City <span class="mandatory">*</span></label>
                  <input class="form-control rounded-0 border input-height" type="text" name="txtCity" id="txtCity"
                    value="" maxlength="60" pattern="" [(ngModel)]="client.city" #city="ngModel"
                    (blur)="checkSpaceChartBegin($event)" placeholder="City" />
                  <div *ngIf="!arrControlValidation[9]?.isvalid && arrControlValidation[9]?.apply" class="error-color">
                    City field is required
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <label for="">Zip code <span class="mandatory">*</span></label>
                  <input class="form-control rounded-0 border input-height" type="text" name="txtZipCode"
                    id="txtZipCode" value="" minlength="10" maxlength="10" pattern="" [(ngModel)]="client.zipCode"
                    #zipCode="ngModel" (keypress)="numberOnly($event)" placeholder="Zip code" />
                  <div *ngIf="!arrControlValidation[10]?.isvalid && arrControlValidation[10]?.apply"
                    class="error-color">
                    Zip code field is required
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <label for="country">Country <span class="mandatory">*</span></label>
                  <div class="selectdiv">
                    <select class="dropdown rounded-0 " id="ddCountry" name="ddCountry" #ddCountry="ngModel"
                      [(ngModel)]="client.countryId" (ngModelChange)="GetStateMasters();">
                      <option [value]="undefined" selected disabled hidden>Select a country </option>
                      <option *ngFor="let country of countries " [value]="country.countryId">
                        {{country.countryName}}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="!arrControlValidation[7]?.isvalid && arrControlValidation[7]?.apply" class="error-color">
                    Please select a country
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <label for="country">State <span class="mandatory">*</span></label>
                  <div class="selectdiv">
                    <select class="dropdown rounded-0 " name="ddState" id="ddState" #ddState="ngModel"
                      [(ngModel)]="client.stateId">
                      <option [value]="undefined" selected disabled hidden>Select a state </option>
                      <option *ngFor="let state of states " [value]="state.stateId">
                        {{state.stateName}}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="!arrControlValidation[8]?.isvalid && arrControlValidation[8]?.apply" class="error-color">
                    Please select a state
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <!-- end address fields -->

        <!--Start Client Contact Details -->
        <div class="clientContacts mt-4">
          <h5 class="ml-3">Client contacts </h5>
          <div class="row py-3 ml-0">
            <div class="col-md-4">
              <label>Business contact <span class="mandatory">*</span></label>
              <!-- <span class="d-flex">              
              <input class="form-control rounded-0 border input-height" placeholder="Name" type="text"
                [(ngModel)]="clientViewModel.businessContactFullNameModel.businesscontactfullname" maxlength="50"
                name="txtBusinessContactName" id="txtBusinessContactName" (blur)="checkSpaceChartBegin($event)">
                <span class="icon-dupe-check" *ngIf="clientViewModel.businessContactFullNameModel.isFieldExistsInOneView" (click)="DisplayDialogForFieldExistswithClients('businesscontactfullname');"></span>
            </span>
            <div class="error-color" id="divContactFullNameOneViewErrorMsg" *ngIf="clientViewModel.businessContactFullNameModel.isFieldExistsInOneView">
              {{clientViewModel.businessContactFullNameModel.fieldValidationMessage}}
            </div>
            -->
              <input class="form-control rounded-0 border input-height" placeholder="Name" type="text"
                [(ngModel)]="client.clientContactName" maxlength="50" name="txtBusinessContactName"
                id="txtBusinessContactName" (blur)="checkSpaceChartBegin($event)">
              <div class="error-color" *ngIf="!arrControlValidation[11]?.isvalid && arrControlValidation[11]?.apply">
                Business contact name field is required
              </div>
            </div>

            <div class="col-md-4">
              <label>Title <span class="mandatory">*</span></label>
              <div class="selectdiv">
                <select class="dropdown rounded-0 " name="ddClientContact" id="ddClientContact"
                  #ddClientContact="ngModel" [(ngModel)]="client.contactTypeCodeId">
                  <option [value]="undefined" selected disabled hidden>Select business contact title</option>
                  <option *ngFor="let contactType of contactTypes" [value]="contactType.applicationMasterId">
                    {{contactType.value}}
                  </option>
                </select>
              </div>
              <div
                *ngIf="!arrControlValidation[businessContactTitleControlIndex]?.isvalid && arrControlValidation[billingContactTitleControlIndex]?.apply"
                class="error-color">
                Business contact title field is required
              </div>
            </div>
            <div class="col-md-4">
              <label>Email <span class="mandatory">*</span></label>
              <span class="d-flex">
                <input placeholder="Email" class="form-control rounded-0 border input-height"
                  [(ngModel)]="clientViewModel.businessContactEmailModel.businesscontactemail"
                  name="txtBusinessContactEmail" id="txtBusinessContactEmail" maxlength="50"
                  (blur)="checkSpaceChartBegin($event)">
                <span class="icon-dupe-check" (click)="validateClientField('businessContactEmail', true, true);"></span>
              </span>
              <div class="error-color" id="divContactEmailOneViewErrorMsg"
                *ngIf="clientViewModel.businessContactEmailModel.isFieldExistsInOneView">
                {{clientViewModel.businessContactEmailModel.fieldValidationMessage}}
              </div>
              <div id="divContactEmailOneViewNoDuplicateErrorMsg"
                *ngIf="clientViewModel.businessContactEmailModel.canShowDuplicateNotFoundMessage">
                {{clientViewModel.businessContactEmailModel.duplicateNotFoundMessage}}
              </div>
              <div class="error-color"
                *ngIf="(!arrControlValidation[12]?.isvalid && arrControlValidation[12]?.apply) || isInvalidClientContactEmail">
                Email field is required and in valid format
              </div>
            </div>
          </div>
          <div class="row py-3 ml-0">
            <div class="col-md-4">
              <label>Billing contact <span class="mandatory">*</span></label>
              <!-- <span class="d-flex">              
              <input class="form-control rounded-0 border input-height" placeholder="Name" type="text" id="txtBillingcontactName"
                name="txtBillingcontactName" [(ngModel)]="clientViewModel.billingContactFullNameModel.billingContactFullName"
                maxlength="50" (blur)="checkSpaceChartBegin($event)">  
                <span class="icon-dupe-check" *ngIf="clientViewModel.billingContactFullNameModel.isFieldExistsInOneView" (click)="DisplayDialogForFieldExistswithClients('billingContactFullName');"></span>              
            </span>
            <div class="error-color" id="divBillingcontactNameOneViewErrorMsg" *ngIf="clientViewModel.billingContactFullNameModel.isFieldExistsInOneView">
              {{clientViewModel.billingContactFullNameModel.fieldValidationMessage}}
            </div> -->
              <input class="form-control rounded-0 border input-height" placeholder="Name" type="text"
                id="txtBillingcontactName" name="txtBillingcontactName" [(ngModel)]="client.clientBillingName"
                maxlength="50" (blur)="checkSpaceChartBegin($event)">
              <div class="error-color" *ngIf="!arrControlValidation[13]?.isvalid && arrControlValidation[13]?.apply">
                Billing contact name field is required
              </div>
            </div>

            <div class="col-md-4">
              <label>Title <span class="mandatory">*</span></label>
              <div class="selectdiv">
                <select class="dropdown rounded-0 " name="ddBillingClientContact" id="ddBillingClientContact"
                  #ddBillingClientContact="ngModel" [(ngModel)]="client.billingContactTitleCodeId">
                  <option [value]="undefined" selected disabled hidden>Select billing contact title</option>
                  <option *ngFor="let contactType of billingContactTypes" [value]="contactType.applicationMasterId">
                    {{contactType.value}}
                  </option>
                </select>
              </div>
              <div
                *ngIf="!arrControlValidation[billingContactTitleControlIndex]?.isvalid && arrControlValidation[billingContactTitleControlIndex]?.apply"
                class="error-color">
                Billing contact title field is required
              </div>
            </div>

            <div class="col-md-4">
              <label>Email <span class="mandatory">*</span></label>
              <span class="d-flex">
                <input placeholder="Email" class="form-control rounded-0 border input-height"
                  id="txtBillingcontactEmail" name="txtBillingcontactEmail" maxlength="50"
                  [(ngModel)]="clientViewModel.billingContactEmailModel.billingContactEmail"
                  (blur)="checkSpaceChartBegin($event)">
                <span class="icon-dupe-check" (click)="validateClientField('billingContactEmail', true, true);"></span>
              </span>
              <div class="error-color" id="divBillingcontactEmailOneViewErrorMsg"
                *ngIf="clientViewModel.billingContactEmailModel.isFieldExistsInOneView">
                {{clientViewModel.billingContactEmailModel.fieldValidationMessage}}
              </div>
              <div id="divBillingcontactEmailOneViewNoDuplicateErrorMsg"
                *ngIf="clientViewModel.billingContactEmailModel.canShowDuplicateNotFoundMessage">
                {{clientViewModel.billingContactEmailModel.duplicateNotFoundMessage}}
              </div>
              <div class="error-color"
                *ngIf="(!arrControlValidation[14]?.isvalid && arrControlValidation[14]?.apply) || isInvalidClientBillingEmail">
                Email field is required and in valid format
              </div>
            </div>
          </div>
        </div>
        <!-- end Client contact fields -->

        <!-- Start GT Contacts Section -->
        <div class="GT-Contacts mt-4">
          <h5 class="ml-3">GT contacts </h5>
          <div class="row py-3 ml-0">
            <div class="col-6 col-sm-6 col-lg-6 engagament-PPMDDiv">
              <label for="lblengPeoplePicker">CMS client administrator <span class="mandatory">*</span></label>

              <!--start CMS admin people picker-->
              <div class="input-group mw-100">
                <app-people-picker class="eng-people-picker" id="engPeoplePicker" [roleShortName]="adminRoleName"
                  [labelCtrlName]="AdminlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                  [userEmail]="clientViewModel.cmsClientAdministratorEmail"
                  [PeoplePickerModel]="clientViewModel.cmsClientAdministratorFullname"
                  [IsValidateUserWithADGroup]="validateCMSCA" (validPeopleSelected)="onCMSAdminSelected($event)">
                </app-people-picker>

                <div class="input-group-append input-height">
                  <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                    data-target="#modal-AssignmentAdministrator" data-keyboard="false" data-backdrop="static"
                    class="mw-100 people-picker-button text-center"
                    (click)="refreshCMSAdminPopup(peoplepickerIndex,'txtPeoplePicker_')">

                    <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="CMS client administrator" />
                  </button>
                </div>
              </div>

              <div class="error-color assignmentAdminMandatoryCheck d-none" id="AdminRequired">
                <p>CMS client administrator field is a required </p>
              </div>
              <div class="error-color assignmentAdminMandatoryCheck d-none" id="AdminValid">
                <p>CMS Client administrator should be valid </p>
              </div>

              <!--end CMS admin people picker -->
            </div>
          </div>
          <!--start KRP people picker-->
          <!-- <div class="row py-3 ml-0">
          <div class="col-6 col-sm-6 col-lg-6 engagament-PPMDDiv">
            <label for="epRoleName">Key relationship leader</label>
            <div class="input-group mw-100">
              <app-people-picker class="eng-people-picker" [roleShortName]="KRPRoleName" [userEmail]="keyRelationshipPartner?.email" [PeoplePickerModel]="keyRelationshipPartner?.relationshipPartnerName"
                [labelCtrlName]="KRPlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                [IsValidateUserWithADGroup]="validateKRP">
              </app-people-picker>

              <div class="input-group-append input-height">
                <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                  data-target="#modal-RelationshipPartnersList" data-keyboard="false" data-backdrop="static"
                  class="mw-100 people-picker-button text-center"
                  (click)="refreshModalPartnersList(peoplepickerIndex,'txtPeoplePicker_')">
                  <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Key relationship leader" />
                </button>
              </div>
            </div>

            <div class="error-color d-none" id="KRPValid">
              <p>Key relationship leader should be valid </p>
            </div>
          </div>
        </div> -->
          <!--end KRP people picker-->
        </div>
        <!-- end GT contacts fields -->

        <!--end Main Div under CreateClientForm-->

      </div>
    </div>
        <!-- End KRP Details -->
        <app-alerts name='clientAlert' id='clientAlert'></app-alerts>
        <div class="row clientCreationDiv hide justify-content-end pt-3 text-right m-0">
          <div class="col-xl-3 col-md-4 pl-0 pr-3">
            <button type="button" class="btn SecondaryButton w-100 text-center" (click)="Cancel()">Cancel </button>
          </div>
          <div class="col-xl-3 col-md-4 px-0">
            <button type="button" class="btn PrimaryButton w-100 text-center" (click)="AddNewClient()">
              <span>
                <img class="d-inline-block mr-1" src="../../../assets/images/EDP/Interviews/add_box.svg"
                  alt="Add new client" />
              </span>
              <span class="d-inline-block align-middle font-weight-bold">Create new client</span></button>
          </div>
        </div>
  </form>

</div>
<!-- End main Container -->

<div id="modal-RelationshipPartnersList" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select Key relationship leader</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectPartner();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="PartnerNameErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchPartnerName" (keydown.enter)="searchPartnerNames('searchPartnerName');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for Key relationship leader"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchPartnerNames('searchPartnerName');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: lastname, firstname or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblKRPAD" aria-describedby="KRP" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">KRP Name</th>
                <th scope="col">Email</th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectPartner(i,$event)' id="trPartnerlist" class="trPartnerlist" style="cursor: pointer;"
                *ngFor="let member of KRPmembersListArray let i=index">
                <td>
                  <span id='spdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td>
                  <span id='spmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td>
                  <span id='spbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deselectPartner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="setPartner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="modal-AssignmentAdministrator" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select CMS client administrator </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectCMSAdmin();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="AssignmentAdminErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchCSMClientAdminName"
            (keydown.enter)="searchUsers('searchCSMClientAdminName','tblAssAdminAD');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for CMS client administrator"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchUsers('searchCSMClientAdminName','tblAssAdminAD');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: lastname, firstname or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblAssAdminAD" aria-describedby="Assignment Admin" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectAdmin(i)' id="trAssAdmins" name="trAssAdmins" style="cursor: pointer;"
                *ngFor="let member of CMSAdminmembersListArray let i=index">
                <td> <span id='spAdmindisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spAdminmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spAdminjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spAdminbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='spAdminlineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spAdminserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='spAdminlocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deselectCMSAdmin();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="setCMSACAdmin();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
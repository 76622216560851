import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  userInfo: any;
  groups: string[] = [];
  isMemberFirmEnabled: boolean = false;
  constructor(private auth: AuthService) { }

  ngOnInit() {
    const jsonString = localStorage.getItem('currentUser');
    this.userInfo = JSON.parse(jsonString);
    if (this.userInfo.activeDirectoryGroups) {
      this.groups = this.userInfo.activeDirectoryGroups.toString().split(',');
    }

    this.isMemberFirmEnabled = this.userInfo.isMemberFirmFeatureEnabled;
    console.log('isMember firm enabled flag : ' + this.isMemberFirmEnabled);
  }

  clearCacheAndLogout() {
    setTimeout(function() {
      this.auth.logout();
    }, 5000);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalSearchPageViewModel, SearchPageViewModel } from '../model/SearchPage/SearchPageViewModel'
import { AppConfigService } from './appConfigService';
import { KeyValue } from '../model/KeyValue';
@Injectable()
export class SearchPageService {
  APIEndPoint: string;
  IntegrationAPIEndPoint: string;

  userName: string = '';
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    let jsonString = localStorage.getItem("currentUser");
    var jsonUserObj = JSON.parse(jsonString);
    if (jsonUserObj != null) {
      this.userName = jsonUserObj.userName;
    }
  }

  GetSearchPageResults(serviceLineType: string, searchType: string, searchTerm: string,
    clientProgramId: string, clientStatusId: string,
    clientName: string, engagementName: string, assignmentName: string, solutionCodeId: string,
    engagementStatus: string, assignmentCodeStatus: string, EPPMD: string,
    pageNumber: number, pageSize: number, isloggedInUserAdmin: string, orderByColumn: string,
    orderByType: string): Observable<SearchPageViewModel> {
    let result = null;
    let globalSearchPageViewModel: GlobalSearchPageViewModel = new GlobalSearchPageViewModel();

    globalSearchPageViewModel.userName = this.userName;
    globalSearchPageViewModel.isloggedInUserAdmin = isloggedInUserAdmin ? isloggedInUserAdmin : null;
    globalSearchPageViewModel.serviceLineType = serviceLineType;
    globalSearchPageViewModel.searchType = searchType;
    globalSearchPageViewModel.searchTerm = searchTerm;
    globalSearchPageViewModel.pageNumber = pageNumber;
    globalSearchPageViewModel.pageSize = pageSize;
    globalSearchPageViewModel.orderByColumn = orderByColumn;
    globalSearchPageViewModel.orderByType = orderByType;
    globalSearchPageViewModel.clientProgramId = (clientProgramId !== null) ? clientProgramId : null;
    globalSearchPageViewModel.clientStatusId = (clientStatusId !== null) ? clientStatusId : null;
    globalSearchPageViewModel.solutionCodeId = (solutionCodeId !== null) ? solutionCodeId : null;
    globalSearchPageViewModel.clientName = (clientName !== null) ? clientName : null;
    globalSearchPageViewModel.engagementName = (engagementName !== null) ? engagementName : null;
    globalSearchPageViewModel.assignmentName = (assignmentName !== null) ? assignmentName : null;
    globalSearchPageViewModel.engagementStatus = (engagementStatus !== null) ? engagementStatus : null;
    globalSearchPageViewModel.assignmentCodeStatus = (assignmentCodeStatus !== null) ? assignmentCodeStatus : null;
    globalSearchPageViewModel.ePPMD = (EPPMD !== null) ? EPPMD : null;
    
    result = this.http.post<SearchPageViewModel>(this.APIEndPoint + '/SearchPage/GetSearchPageResults', globalSearchPageViewModel);
    return result;
  }
  GetSearchPageResultsCount(serviceLineType: string, searchType: string, searchTerm: string,
    clientProgramId: string, clientStatusId: string,
    clientName: string, engagementName: string, assignmentName: string, solutionCodeId: string,
    engagementStatus: string, assignmentCodeStatus: string, EPPMD: string,
    isloggedInUserAdmin: string): Observable<number> {
    let result = null;    
    let globalSearchPageViewModel: GlobalSearchPageViewModel = new GlobalSearchPageViewModel();

    globalSearchPageViewModel.userName = this.userName;
    globalSearchPageViewModel.isloggedInUserAdmin = isloggedInUserAdmin ? isloggedInUserAdmin : null;
    globalSearchPageViewModel.serviceLineType = serviceLineType;
    globalSearchPageViewModel.searchType = searchType;
    globalSearchPageViewModel.searchTerm = searchTerm ;
    globalSearchPageViewModel.clientProgramId = (clientProgramId !== null) ? clientProgramId : null;
    globalSearchPageViewModel.clientStatusId = (clientStatusId !== null) ? clientStatusId : null;
    globalSearchPageViewModel.solutionCodeId = (solutionCodeId !== null) ? solutionCodeId : null;
    globalSearchPageViewModel.clientName = (clientName !== null) ? clientName : null;
    globalSearchPageViewModel.engagementName = (engagementName !== null) ? engagementName : null;
    globalSearchPageViewModel.assignmentName = (assignmentName !== null) ? assignmentName : null;
    globalSearchPageViewModel.engagementStatus = (engagementStatus !== null) ? engagementStatus : null;
    globalSearchPageViewModel.assignmentCodeStatus = (assignmentCodeStatus !== null) ? assignmentCodeStatus : null;
    globalSearchPageViewModel.ePPMD = (EPPMD !== null) ? EPPMD : null;

    result = this.http.post<SearchPageViewModel>(this.APIEndPoint + '/SearchPage/GetSearchPageResultsCount', globalSearchPageViewModel);
    return result;
  }

  GetServiceLines(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/SearchPage/GetServiceLines/');
  }
  GetSearchTypes(): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(this.APIEndPoint + '/SearchPage/GetSearchTypes/');
  }
}

export class TemplateMaintenanceFilter {
    constructor(public isTax: boolean = false,
                public isAudit: boolean = false,
                public isAdvisory: boolean = false,
                public isStandalone: boolean = false,
                public isComponentTemplate: boolean = false,
                public isFullAgreement: boolean = false,
                public isBypass: boolean = false,
                public isGTStandardMSA: boolean = false,
                public isUniversalEngagementLetter: boolean = false,
                public isTaxEvergreen: boolean = false,
                public templateTypeCode: string = '',
                public isActive: boolean = false,
                public isInactive = false,
                public isAcceptanceonly:boolean =false,
                public isExecutiononly:boolean =false,
                public isBoth:boolean =false) {

    }
}

<div class="container-fluid">
  <div class="row">
      <div *ngIf="!signout" class="col-md-12">
          <div class="error-template">
              <h1>
                  Warning!</h1>
              <h2>
                  No access found</h2>
              <div class="error-details">
                  Pleae contact your Engage application administrator for access to this system.
              </div>
          </div>
      </div>
  </div>
</div>

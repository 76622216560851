import { SecondarySolutionCode } from 'src/app/model/Engagement/SecondarySolutionCodes';
import { KeyValue } from 'src/app/model/KeyValue';
import { BooleanKeyValue } from 'src/app/model/BooleanKeyValue';

export class EngagementAssignment {
    solutionCodeListData: SecondarySolutionCode[];
    iscontingentData: BooleanKeyValue[];
    engagementTypeData: KeyValue[];
    selectedsolutionCode: SecondarySolutionCode;
    selectedengagementType: KeyValue;
    assignmentCodeId: number;
    engagementId: number;
    solutionCodeId: number;
    assignmentName: string;
    realization: number;
    netFees: number;
    
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    assignmentCode: string;
    assignmentAdministrator: string;
    adminEmailId: string;
    adminContactNo: string;
    cmsEPPMDName: string;
    cmsEPPMDEmailId: string;
    engagementTypeId: string;
    isContingent: boolean;
    isPrimaryAssignment: boolean;
    netFeesInCurrencyFormat: string;
    collaborationSiteId: number;
    engagementStartDate: string;
    engagementEndDate: string;
    officeLocationCodeId: number;
    estimatedGrossMargin: number;
    clientBillingContactName: string;
    clientBillingContactTitleId: number;
    clientBillingContactEmail: string;
    collaborationSiteName: string;
    officeLocationCodeName: string;
    estimatedGrossMarginInCurrencyFormat: string;
    clientBillingContactTitle: string;
    clientCode: string;
    assignmentCodeStatus:string;
    isContinuanceCarryFrwdAssignment:boolean;
    solutionCodeOnSigature: boolean;
    priorYearAssignmentName: string;
    isGrossMarginRequire: boolean;
    eqcrName: string;
    eqcrEmailId: string;
    isEditEQCR: boolean = false;
    newEQCRName: string;
    newEQCREmailId: string;
    isvalidEQCR: boolean = true;
    isValidEQCRBusinessRule: boolean = true;
    isEQCRExists: boolean = false;
    budgetApproved?: boolean = null;
    isAssignmentFinanceDataEditable?: string = null;
    solutionCode: string;
    solutionCodeDescription: string;
    isEngagementStartDateRequired?: boolean = null;
    isEngagementEndDateRequired?: boolean = null;
    opportunityServiceId?: string = null;
    status: string;
}

export class UpdateAssignmentEQCRViewModel {
    assignmentCodeId: number;
    engagementId: number;
    collaborationSiteId:number;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    eqcrName: string;
    eqcrEmailId: string;
    newEQCRName: string;
    newEQCREmailId: string;
    status: string;
}

export class UpdatePreRequestAssignment {
    assignment : EngagementAssignment;    
    preRequestForContinuance : boolean;
    loggedInUserName : string; 
}

export class RemoveEngagementAssignmentModel {        
    engagementId : number;
    assignmentCodeId : number;
    loggedInUser : string;
    loggedInUserName : string; 
}
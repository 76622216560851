<app-navbar *ngIf="noAccessFlag == 1" (onSignOut)="logout()"></app-navbar>

<div class="container-fluid">
  <div id="div-loading-screen" *ngIf="!engdialogDisplay">
    <app-loading-screen></app-loading-screen>
  </div>
  <div id="main-app" class="row app m-0" *ngIf="noAccessFlag == 1">
    <div class="col-md-12 px-0">
      <div id="main-section">
        <div class="nav-col">
          <app-leftnav></app-leftnav>
        </div>
        <div class="content-col margin-all">
          <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
          <router-outlet *ngIf="!isIframe"></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-message-dialog [messageType]="dialogType" [messageTitle]="dialogTitle" [IsAbandonModal]=dialogAbandonModal
    [IsDeclineModal]=dialogDeclineModal [messageDescription]="dialogMessage" [successRedirect]="dialogRedirectUrl"
    [modalDisplay]="dialogDisplay" [modalButtons]="dialogButton" [IsCloseEngagementModal]="dialogCloseEngagementModal"
    [IsBigDialogTitle]="dialogBigTitle" [engagementId]="EngagementId" [searchClientReqModel]="SearchClientReqModel"
    [loggedInUserEmail]="LoggedInUserEmail" [isAdministrativeClose]="IsAdministrativeClose" (okClick)="okClickEvent($event)" 
    (cancelClick)="cancelClickEvent($event)" (closeClick)="closeClickEvent($event)" [IsVoidEngagementModal]="dialogVoidEngagementModal"
    [isUpdateQuestionnaireExecutionPhase]="dialogUpdateQuestionnaireExecutionPhase"
    [isContinuanceEngagementExists]="dialogisContinuanceEngagementExistsModel"
    [continuanceDashBoardUrl]="dialogcontinuanceDashBoardUrl">
  </app-message-dialog>

  <app-engagement-message-dialog [messageType]="dialogType" [messageTitle]="dialogTitle"
    [messageDescription]="dialogMessage" [successRedirect]="dialogRedirectUrl" [modalDisplay]="engdialogDisplay"
    [modalButtons]="dialogButton" [IsBigDialogTitle]="dialogBigTitle" [engagementId]="EngagementId"
    [plannedClosureDate]="plannedClosureDate" [loggedInUserEmail]="LoggedInUserEmail"
    (okEngClick)="okEngClickEvent($event)" (cancelEngClick)="cancelEngClickEvent($event)"
    (closeEngClick)="closeEngClickEvent($event)">
  </app-engagement-message-dialog>

  <app-delegate-dialog [modalDisplay]="displayDelegateDialog" (okClick)="okClickEvent($event)"
    (cancelClick)="cancelClickEvent($event)" (closeClick)="closeClickEvent($event)">
  </app-delegate-dialog>

  <app-new-document-dialog [modalDisplay]="displayNewDocumentDialog" [prePopulatedClientId]="prePopulatedClientId"
    [prePopulatedEngagementId]="prePopulatedEngagementId"></app-new-document-dialog>

    <!-- <app-document-templates-dialog [modalDisplay]="displayDocumentTemplateDialog" [prePopulatedClientId]="prePopulatedClientId" [prePopulatedEngagementId]="prePopulatedEngagementId"></app-document-templates-dialog> -->
    <app-document-templates-dialog [modalDisplay]="displayDocumentTemplateDialog"></app-document-templates-dialog>
  
  <div id="noAccessDiv" class="row" *ngIf="noAccessFlag == 2">
    <div class="row">
      <div class="col-md-12">
        <div class="center-content">
          <h1>User access unavailable</h1>
          <div class="error-details">
            Engage is having trouble logging you in. Please <a (click)="logout()" class="login-warning-links">logout</a>
            to try again.
            <br />
            If the problem persists, please contact <a class='login-warning-links'
              (click)="OpenEngageSupportCanvasLink()" rel="noopener noreferrer">Engage support</a>.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="initialLoadingDiv" class="row" *ngIf="noAccessFlag == 0">
    <div class="center-content">
      <h1>Application is Loading</h1>
      <h5>Please wait....</h5>
    </div>
  </div>

</div>
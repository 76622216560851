import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { CardListModel } from '../model/cardlistmodel';
import { AppConfigService } from './appConfigService';
import { User } from '../model/User';

@Injectable()
export class cardService {
  APIEndPoint: string;
  user: User;

  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.user = new User();
  }

  getCardList(): Observable<CardListModel[]> {
    return this.http.post<CardListModel[]>(this.APIEndPoint + "/Cards/clients", this.user);
  }

  getEngagementCardsList(): Observable<CardListModel[]> {
    return this.http.post<CardListModel[]>(this.APIEndPoint + "/Cards/engagements", this.user);
  }

  getDocumentsCardsList(): Observable<CardListModel[]> {
    return this.http.post<CardListModel[]>(this.APIEndPoint + "/Cards/documents", this.user);
  }

  getEDPSideMenu(): Observable<CardListModel[]> {
    return this.http.post<CardListModel[]>(this.APIEndPoint + "/Cards/edp-menu", this.user);
  }

  getFavoriteCardList(): Observable<CardListModel[]> {
    return this.http.post<CardListModel[]>(this.APIEndPoint + "/Cards/favorites", this.user);
  }

  MarkCardAsFavorite(cardListmodel: CardListModel): Observable<CardListModel> {
    return this.http.post<CardListModel>(this.APIEndPoint + "/Cards/MarkCardAsFavorite", cardListmodel)
  }

  RemoveFavoriteCard(cardListmodel: CardListModel): Observable<CardListModel> {
    return this.http.post<CardListModel>(this.APIEndPoint + "/Cards/RemoveFavoriteCard", cardListmodel)
  }

  getFirstRowOfFavoriteCard(): Observable<CardListModel> {
    return this.http.post<CardListModel>(this.APIEndPoint + "/Cards/getfirstFavoriteRow", this.user);
  }
}

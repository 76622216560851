<div class="row p-3 mx-0">
  <div class="col-md-12 pl-0 edp-font-light">{{lastUpdatedBy}}</div>
</div>
<div class="row ml-0 px-3">
  <div class="mb-3">
    <span class="font-weight-bold mr-4 edp-font-22">{{client?.clientName}} </span>
    <span>{{client?.clientCode}}</span>
  </div>
  <div class="col-md-12 pl-0 pb-3 seperator">
    <div>
      Type: <span class="font-weight-bold">{{client?.Private}}</span>
    </div>
    <div>
      Industry: <span class="font-weight-bold">{{client?.industryType}}</span>
    </div>
    <div>
      Structure/entity: <span class="font-weight-bold">{{client?.structEntityName}}</span>
    </div>
    <div>
      Stock exchange: <span class="font-weight-bold">{{client?.stockExchange}}</span>
    </div>
    <div>
      Program: <span class="font-weight-bold">{{client?.clientProgram}}</span>
    </div>
    <div>
      Referral source: <span class="font-weight-bold">{{client?.referralSource}}</span>
    </div>
    <div>
      Fiscal year end month: <span class="font-weight-bold">{{client?.fyeMonthName}}</span>
    </div>
  </div>
  <div class="col-md-12 pl-0 py-3 seperator">
    <div class="font-weight-bold">Contacts</div>
    <div>
      Client business contact:
      <span class="font-weight-bold">{{client?.businessClientContact}}</span>
    </div>
    <div>
      Billing business contact:
      <span class="font-weight-bold">{{client?.clientBillingName}}</span>
    </div>
    <div>
      CMS client administrator:
      <span class="font-weight-bold contactUser link-text-blue" id="clientAdmin_{{client?.clientId}}"
        (click)="displayContactPopover(client?.clientAdministratorEmail, 'clientAdmin_'+client?.clientId)">{{client?.clientAdministratorContactName}}</span>
    </div>
    <div>
      Key relationship leader:
      <!-- <span class="font-weight-bold contactUser link-text-blue" id="keyRelationshipPartner_{{client?.clientId}}"
        (click)="displayContactPopover(client?.relationshipPartnerEMail, 'keyRelationshipPartner_'+ client?.clientId)">
        {{client?.relationshipPartnerName}}</span> -->
    </div>
  </div>
</div>
<div class="row m-0 p-3">
  <div class="col-md-12 pl-0">
    <h6 class="form-heading-text">Engagement assignments</h6>
  </div>

  <div *ngIf="assignmentsList.length > 0" class="acceptance-view w-100">
    <div *ngFor="let assignmentItem of assignmentsList; let i = index" class="seperator py-4">
      <div class="row m-0 p-0 position-relative viewAssignmentsDetails">
        <p class="position-absolute"><strong>{{assignmentItem.assignmentName}}&nbsp;</strong>
          <span *ngIf="assignmentItem.clientCode!=null">
            - {{assignmentItem.clientCode}}</span><span *ngIf="assignmentItem?.assignmentCode!=null">.{{assignmentItem.assignmentCode}}</span>
          <span *ngIf="assignmentItem?.isPrimaryAssignment"> - Primary</span> </p>
        <div class="col-md-6 pl-0 mt-4">
          <div class="mb-1">{{assignmentItem.engagementTypeName}}</div>
          <div class="mb-1">{{assignmentItem.solutionCode}} -
            {{assignmentItem.solutionCodeDescription}}</div>
          <div class="mb-1">Estimated start date:
            <span class="font-weight-bold">{{assignmentItem?.engagementStartDate | date: "MM/dd/yyyy"}}</span>
          </div>
          <div class="mb-1">Collaboration space: <span class="font-weight-bold">
              {{(assignmentItem?.collaborationSiteName)}}</span>
          </div>
          <div class="mb-1">Realization %: <strong>{{assignmentItem?.realization|number:'0.0'}}%</strong></div>
          <div class="mb-1">Estimated net fees:
            <strong>{{assignmentItem?.netFees|currency:'USD':'symbol':'0.0'}}</strong>
          </div>
          <div class="mb-1">Billing contact:
            <span class="font-weight-bold"
              *ngIf="assignmentItem?.clientBillingContactName!='' && assignmentItem?.clientBillingContactTitle!=''">
              {{assignmentItem?.clientBillingContactName}},
              {{assignmentItem?.clientBillingContactTitle}}
            </span>
          </div>
          <div class="mb-1">Billing contact email:
            <span class="font-weight-bold" style="word-break: break-all;"
              *ngIf="assignmentItem?.clientBillingContactEmail!='' && assignmentItem?.clientBillingContactEmail!=null  ">
              {{assignmentItem?.clientBillingContactEmail}}
            </span>
          </div>
        </div>
        <div class="col-md-6 pl-md-0 px-xl-2 mt-4">
          <div class="mb-1">CMS PPMD:&nbsp;
            <span class="font-weight-bold contactUser link-text-blue" id="cmsEPPMD_{{assignmentItem.assignmentCodeId}}"
              (click)="displayContactPopover(assignmentItem.cmseppmdEmailId, cmsEPPMD_+ assignmentItem.assignmentCodeId)">{{assignmentItem?.cmsppmd}}</span>
          </div>
          <div class="mb-1">Assignment administrator:&nbsp;
            <span class="font-weight-bold contactUser link-text-blue" id="admin_{{assignmentItem.assignmentCodeId}}"
              (click)="displayContactPopover(assignmentItem.adminEmailId, admin_+ assignmentItem.assignmentCodeId)">{{assignmentItem?.assignmentAdministrator}}</span>
          </div>
          <div class="mb-1">Estimated end date:&nbsp;
            <span class="font-weight-bold"> {{assignmentItem?.engagementEndDate |  date: "MM/dd/yyyy"}}</span>
          </div>
          <div class="mb-1" *ngIf="assignmentItem.isContingent">Contingent fee: <strong>Yes</strong></div>
          <div class="mb-1" *ngIf="!assignmentItem.isContingent">Contingent fee: <strong>No</strong></div>
          <div class="mb-1">Estimated gross margin %:
            <strong>{{assignmentItem.estimatedGrossMargin|number:'0.0'}}%</strong>
          </div>
          <div class="mb-1">Closest GT office: <strong>{{assignmentItem.officeLocationCodeName}}</strong>
          </div>
          <div class="mb-1">Assignment EQCR:&nbsp;
            <span class="font-weight-bold contactUser link-text-blue" id="eqcr_{{assignmentItem.assignmentCodeId}}"
              (click)="displayContactPopover(assignmentItem.assignmentEQCREmailId, eqcr_+ assignmentItem.assignmentCodeId)">{{assignmentItem?.assignmentEQCR}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>
export class AzureADUsersList {
    adDetaiId: number;
    roleId: number;
    azureADMappedRoleName: string;
    isAudit: boolean;
    isTax: boolean;
    isAdvisory: boolean;
    isAttest: boolean;
}

export class AzureADGroupRoleShortNamesModel {
    baseADGroupRoleShortName: string;
    fromADGroupRoleShortName: string;
}

export class ADGroupsUserMemberOfCheckModel{
    UserPrincipalName: string;
    Id:string;
    AzureADGroupNames: string[];
  }
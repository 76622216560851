<div class="tab-content">
  <div class="tab-pane active" id="myengagements">
    <div id="CardViewTableSummary" class="table-summary">
      <form class="form-inline">
        <div class="My-Clients-40 col-md-2">
          <span *ngIf="ShowClientName">My</span> &nbsp;Agreement Docs&nbsp;({{engagementList?.length}})
        </div>
        <!-- My Client title -->
        <div class="CardViewFiltersForacptdoc col-md-9">
          <div id="EngagementCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Engagements
              <span class="caret"></span>
            </button>
            <ul id="EngagementFilterULForacptdoc" class="dropdown-menu EngagementFilterjsForacptdoc filterlist toggleDropdown"
              role="menu" aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterEngagementBoxForacptdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForacptdoc('filterEngagementBoxForacptdoc','EngagementFilterULForacptdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllEngForacptdoc" checked data-attribute="chkAll-EngagementForacptdoc" />&nbsp;All Engagements
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="SolutionCodeCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Solution Code
              <span class="caret"></span>
            </button>
            <ul id="SolutionCodeFilterULfilterSolutionCodeBoxForacptdoc"
              class="dropdown-menu SolutionCodeFilterjsForacptdoc filterlist toggleDropdown" role="menu"
              aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterSolutionCodeBoxForacptdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForacptdoc('filterSolutionCodeBoxForacptdoc','SolutionCodeFilterULfilterSolutionCodeBoxForacptdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllSolutionCodeForacptdoc" checked
                  data-attribute="chkAll-SolutionCodeForacptdoc" />&nbsp;All Solution Code
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="StatusCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              Status
              <span class="caret"></span>
            </button>
            <ul id="StatusFilterULForacptdoc" class="dropdown-menu StatusFilterjsForacptdoc filterlist toggleDropdown"
              role="menu" aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterStatusBoxStatusFilterULForacptdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForacptdoc('filterStatusBoxStatusFilterULForacptdoc','StatusFilterULForacptdoc')" type="text"
                  placeholder="Search" aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllStatusForacptdoc" checked data-attribute="chkAll-StatusForacptdoc" />&nbsp;All Status
              </li>
            </ul>
          </div>&nbsp;&nbsp;
          <div id="EPCardViewFiltersForEng" class="dropdown cardViewFiltersDisplay">
            <button class="btn filterbutton dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">
              EP
              <span class="caret"></span>
            </button>
            <ul id="EPFilterULForacptdoc" class="dropdown-menu EPFilterjsForEngForacptdoc filterlist toggleDropdown" role="menu"
              aria-labelledby="menu1">
              <div class="input-group searchTextboxdesign">
                <a class="text-muted">
                  <img class="search-icon" src="../../../../assets/images/MyClientCardView/search_icon_18px.png" alt="Search" />
                </a>
                <input id="filterEPBoxForacptdoc" class="form-control searchtextbox"
                  onkeyup="filterFunctionForacptdoc('filterEPBoxForacptdoc','EPFilterULForacptdoc')" type="text" placeholder="Search"
                  aria-label="Search" />
              </div>
              <li>
                <input type="checkbox" class="CheckAllEPForacptdoc" checked data-attribute="chkAll-EPForacptdoc" />&nbsp;All EP
              </li>
            </ul>
          </div>
           &nbsp;&nbsp;
           <!-- Search Button -->
           <img class="text-muted resetHyperlink" alt="search" src="../../../../assets/images/listview/search.png" />
           <input class="form-control searchbox" type="text" placeholder="Search" aria-label="Search" (keyup)="onKeyup($event,'searchDatajs')"/>
           <!-- Search Button -->

        </div>

        <!-- List View and Card View buttons -->
        <div class="push col-md-1">

          <a class="ShowCardViewjs" (click)="ToggleListAndCardViewForacptdoc('Card');">
            <img class="CardViewImgForacptdoc pointerCursor" alt="Card view"
              src="../../../../assets/images/listview/card-view_selected.png" />&nbsp;
          </a>
          <a class="ShowListViewjs" (click)="ToggleListAndCardViewForacptdoc('List');">
            <img class="ListViewImgForacptdoc pointerCursor" alt="List View"
              src="../../../../assets/images/listview/list-view_un-selected.png" />&nbsp;
          </a>


        </div>
        <!-- List View and Card View buttons -->
      </form>
    </div>

    <!-- Start MainContainer -->
    <!-- List View and card view main container start -->
    <div class="MainContainer">
      <div class="table-responsive tb">
        <!--ListView Design Start-->
        <div class="ListViewContainerForacptdoc" style="display:none">
          <table class="table table-light tableData tableDataEngForacptdoc ResponsiveDataTable" border="0" aria-describedby="Engagement List" >
            <thead>
              <tr>
                <th *ngIf="ShowClientName" scope="col" class="table-sorted-desc">Client Name</th>
                <th scope="col" class="table-sortable-desc">Eng Name</th>
                <th scope="col" class="table-sortable-desc">Solution Code</th>
                <th scope="col" class="table-sortable-desc">Status</th>
                <th scope="col" class="table-sortable-desc">EP</th>
                <th scope="col" class="table-sortable-desc">Last Activity Date</th>
                <th scope="col" class="table-sortable-desc">FYE</th>
              </tr>
            </thead>
            <tbody class="display" *ngIf="engagementList?.length != 0">
              <tr class="searchDatajs" *ngFor="let list of engagementList">
                <td data-label="Client Name:" *ngIf="ShowClientName"><a href="ClientSummary/{{list.clientId}}" class="link">{{list.clientName}}</a>
                </td>
                <td data-label="Eng Name:"><a href="EngagementSummary/{{list.engagementId}}" class="link"> {{list.engagementName}} </a></td>
                <td data-label="Solution Code:"> {{list.solutionCode}} </td>
                <td data-label="Status:"> {{list.status }} </td>
                <td data-label="Engagement Partner:">
                  <a id="ClientRP" style="color:black" class="ShowRPDetailsjsForacptdoc" data-trigger="focus" tabindex="0"
                    data-toggle="popover" rel="popover" data-placement="right">
                    <label class="hide hiddenEPId">{{list.engagementPartnerName}}</label>
                    {{list.engagementPartnerName}}
                  </a></td>
                <td data-label="Last Activity Date:"> {{list.lastModifiedDate | date: "MM/dd/yyyy | HH:mm a"}} </td>
                <td data-label="FYE:"> {{list.taxYear | date: "MM/dd/yyyy"}} </td>
              </tr>

            </tbody>
            <tbody *ngIf="engagementList.length == 0">
              <tr>
                <td colspan="7" class="no-data-available text-center">No Data Available</td>
              </tr>
            </tbody>

          </table>

          <!-- pager start-->
          <!-- pager End-->
        </div>
        <!--ListView Design End-->

        <!--CardView Design Start-->
        <div class="cardViewContainerForacptdoc row marginLeftZero">

          <div class="card-deck-wrapper col-md-12">
            <div class="card-deck">
              <div class="card searchDatajs FilterEngDatajsForacptdoc" *ngFor="let list of engagementList">
                  <div class="card-body">
                    <img class="float-right CardViewImg1" alt="View Engagement"
                    src="../../../../assets/images/Engagement/TaxSymphonyLink.png" />
                  <a href="EngagementSummary/{{list.engagementId}}"
                    class="CardViewText CardViewHeaderFont"><span class="EngagementjsForacptdoc">{{ list.engagementName}}</span></a><br/>
                    <a href="ClientSummary/{{list.clientId}}" *ngIf="ShowClientName"
                    class="CardViewHeaderFont marginZero ClientNamejsForacptdoc ClientNamejsForFilter ClientNameEngForacptdoc">
                    {{ list.clientName }}</a><br/>
                    <hr/>
                    <span><strong>Solution Code:</strong></span><span class="SolutionCodejsForacptdoc">
                      {{ list.solutionCode }}</span><br/>
                      <span><strong>Status:</strong></span><span class="StatusjsForFilterForacptdoc">
                        {{ list.status }}</span><br/>
                        <span><strong>EP:</strong></span><span class="EPjsForFilterForacptdoc">{{ list.engagementPartnerName }}</span>
                        <hr/>
                        <br/>

                        <div style="display:contents">
                          <img class="float-left CardViewImg1 cardViewFiltersDisplay" alt="Engagements Review"
                            src="../../../../assets/images/MyClientCardView/Engagements_Review.png" />
                            &nbsp;&nbsp;
                            <a id="ViewDocuments" class="showDocumentDataDetailsjsAcptDoc marginZero" style="color:black"
                            data-toggle="modal" href="#ActiveDocumentsModal" (mouseover)="setEngagementId(list.engagementId)" >Active Workspaces
                            <strong>({{list.activeWorkspaceCount}})</strong>
                          </a>&nbsp;
                          <img class="float-right cardviewImgAcitveEng" alt="Acitve Engagement"
                            src="../../../../assets/images/MyClientCardView/arrow_card-view.png" />
                          </div>

                  </div>
              </div>
              <div id="NoDataMessageDivForacptdoc" class="text-center width100" *ngIf="engagementList?.length == 0">
                No Data Available
              </div>
              <div id="NoDataMessageForacptdoc" class="hide text-center width100">No Data Available</div>
          </div>


            </div>


        </div>
        <!--CardView Design End-->
      </div>
    </div>
    <!-- List View and card view main container End-->
    <!-- end MainContainer -->
  </div>
  <!--end myengagements tab-->

</div>
<!--tab-content-->

<!-- <app-document-list-popup [engagementId]="selectedEngagementId"></app-document-list-popup>

<app-relationship-partner-popup [clientId]="selectedKRPClientId"></app-relationship-partner-popup> -->

<input type="hidden" id="recordCount" value="{{popupRecordCount}}"/>

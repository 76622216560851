import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { AppConfigService } from './appConfigService';
import { ReacceptanceClientListModel,ArrayOfReacceptanceClientListModel, ContinuanceEngagementWorkItemModel } from '../model/ReacceptanceClientListModel';
import { SolutionCodeListModel } from '../model/SolutionCodeListModel';
import { GTKMClient } from '../model/GTKMClient';
//import { StringifyOptions } from 'querystring'; // imported but its value is never used. So commented
import { InitiateNewEngagement } from '../model/InitiateNewEngagement';
import { AzureADUsersList } from '../model/AzureADUserList';
import { AzureADUsersArrayList } from '../model/AzureADUsersArrayList';
import { MembersList } from '../model/MembersList';
import { MembersListArray } from '../model/MembersListArray';
import { NewClient } from '../model/Client/NewClient';
import { InitiateReacceptanceEngagementsViewModel } from '../model/InitiateReacceptanceEngagementsViewModel';
import { Engagements } from "src/app/model/Engagement/Engagements";
import { ContinuanceUniqueDataModel } from '../model/ContinuanceFilterModel';
import { ContinuanceListViewFilterModel } from '../model/continuance-filter-model';

@Injectable()
export class ReacceptanceClientsService {
  APIEndPoint: string;
  userName: string;
  name: string;
  reaceptanceDataRow = new ReacceptanceClientListModel();
  IntegrationAPIEndPoint: string;
  arrayOfReacceptanceClientListModel = new ArrayOfReacceptanceClientListModel();
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    let nameJSON = JSON.parse(localStorage.getItem("currentUser"));
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.name = nameJSON.loggedInUserFullName;
    }
    //this.APIEndPoint = "https://localhost:44316/" ;//enviornment.config.DATA.API_END_POINT.API_URL;
  }
  GetContinuanceFilterSectionUniqueData(isloggedInUserAdmin: string): Observable<ContinuanceUniqueDataModel> {
    return this.http.post<ContinuanceUniqueDataModel>(this.APIEndPoint + '/ReacceptanceClients/GetAllContinuanceUniqueDataByUser',
      { userName: this.userName, isloggedInUserAdmin: isloggedInUserAdmin });
  }
  getReacceptanceClients(): Observable<ReacceptanceClientListModel[]> {
    return this.http.get<ReacceptanceClientListModel[]>(this.APIEndPoint + '/ReacceptanceClients/GetAllReacceptanceClients/');
  }
  getSolutionCodes(): Observable<SolutionCodeListModel[]> {
    return this.http.get<SolutionCodeListModel[]>(this.APIEndPoint + '/ReacceptanceClients/GetSolutionCodes/');

  }
  getClientByClientCode(clientCode: string): Observable<GTKMClient> {
    return this.http.get<GTKMClient>(this.IntegrationAPIEndPoint + "/GTKM/Client/" + clientCode);
  }
  UpdateReacceptanceClientData(reacceptaceData: ReacceptanceClientListModel) {
    reacceptaceData.lastModifiedBy = this.userName;
    return this.http.post(this.APIEndPoint + '/ReacceptanceClients/UpdateReacceptanceData', reacceptaceData, { responseType: 'text' })
  }
  deleteReacceptance(reacceptanceEngagementId:number){
    return this.http.get(this.APIEndPoint + '/ReacceptanceClients/DeleteReacceptanceClient/' + reacceptanceEngagementId, { responseType:'text' } );
  }
  getMyReacceptanceClients(): Observable<ReacceptanceClientListModel[]> {
    return this.http.post<ReacceptanceClientListModel[]>(this.APIEndPoint + '/ReacceptanceClients/GetMyReacceptanceClients', { userName: this.userName });
  }

  initiateNewEngagement(initiateNewEngagement: InitiateNewEngagement) {
    initiateNewEngagement.userName = this.userName;
    return this.http.post(this.APIEndPoint + '/ReacceptanceClients/InitiateNewEngagement', initiateNewEngagement, { responseType: 'text' })
  }
  getEPList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetEPList');
  }

  GetRelationshipPartners(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetRelationshipPartners');
  }
  GetReviewingProfessionals(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetReviewingProfessionals');
  }
  getAzureAdUsersByGroups(azureADGroups: AzureADUsersArrayList): Observable<MembersListArray> {

    const headers = new HttpHeaders().set('Content-type', 'application/json');

    return this.http.post<MembersListArray>(this.IntegrationAPIEndPoint + '/Graph/UserByGroup', azureADGroups, { headers: headers });
  }
  GetTeamMembersList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetTeamMembersList');
  }
  SaveReacceptanceData(allReacceptanceData:ReacceptanceClientListModel[]){
    for (var i = 0, l = allReacceptanceData.length; i < l; i++) {
          allReacceptanceData[i].lastModifiedBy = this.userName;
    }
    this.arrayOfReacceptanceClientListModel.reacceptanceClientListModel = allReacceptanceData;

    return this.http.post(this.APIEndPoint + '/ReacceptanceClients/SaveAllReacceptanceData',this.arrayOfReacceptanceClientListModel, { responseType:'text' });

  }
  GetClientDetailsByReacceptanceEngagementId(reacceptanceEngagementId): Observable<NewClient> {
    return this.http.get<NewClient>(this.APIEndPoint + '/ReacceptanceClients/GetClientDataByReacceptanceEngagementId/' + reacceptanceEngagementId)
  }
  initiatereacceptanceEngagement(initiateReacceptanceEngagement:Engagements){
    initiateReacceptanceEngagement.lastModifiedBy = this.userName;
    initiateReacceptanceEngagement.createdBy = this.userName;
    return this.http.post(this.APIEndPoint + '/ReacceptanceClients/InitiateReacceptanceEngagements',initiateReacceptanceEngagement, { responseType:'text' });
  }
  getAllReacceptanceEngagementsByUserCount(isLoggedInUserAdmin: string,
    continuanceListViewFilterModel: ContinuanceListViewFilterModel): Observable<number> {
      continuanceListViewFilterModel.loggedInUser = this.userName;
      return this.http.post<number>(`${this.APIEndPoint}/ReacceptanceClients/GetAllReacceptanceEngagementsByUserCount/${isLoggedInUserAdmin}`, continuanceListViewFilterModel);  
  }
  getReacceptanceEngagementListView(continuanceListViewFilterModel: ContinuanceListViewFilterModel,
                                    pageNumber: number, noOfRecordsPerPage: number,
                                    isLoggedInUserAdmin: string, orderByColumn: string, orderByType: string
                                   ): Observable<ReacceptanceClientListModel[]> {
                                    continuanceListViewFilterModel.loggedInUser = this.userName;
  return this.http.post<ReacceptanceClientListModel[]>(`${this.APIEndPoint}/ReacceptanceClients/GetAllReacceptanceEngagements/${isLoggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}`, continuanceListViewFilterModel);                                   
  }
  getContinuanceEngWorkItemData(engagementId:number): Observable<ContinuanceEngagementWorkItemModel> {
    return this.http.get<ContinuanceEngagementWorkItemModel>(this.APIEndPoint + '/ReacceptanceClients/GetContinuanceEngagementWorkItemData/' + engagementId)
  }
}

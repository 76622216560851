
   export class WorkspaceStatusUpdateModel {
    status:string;
    roleShortName:string;
    workspaceId : string;
    loggedInUser: string;
    declinedReason: string;
    engagementPartnerOfficeLocation: string;
   }

   export class RPNotificationModel {
      workspaceId : string;
      loggedInUser: string;
      loggedInUserFullName: string;
      engagementPartnerOfficeLocation: string;
     }     
   


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $;


@Component({
  selector: 'app-engagement-prerequest-popup',
  templateUrl: './engagement-prerequest-popup.component.html',
  styleUrls: ['./engagement-prerequest-popup.component.css']
})
export class EngagementPrerequestPopupComponent implements OnInit {
  userObject: any;

  @Input()  isPreRequest: boolean;

  @Output() prequestPopupButtonClick: EventEmitter<string> = new EventEmitter<string>();



  constructor(

  ) { }

  ngOnInit(): void {
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));

  }

  closeEngagementPreRequestComponentModal() {
    $('#engagement-prerquest-popup').modal('hide');
    this.prequestPopupButtonClick.emit('ClosePopup');
  }

  showEngagementDuedateExtensionComponentModal() {
    $('#engagement-prerquest-popup').modal({
      backdrop: false,
      show: true
    });
    $('#engagement-prerquest-popup').modal('show');
  }

  NonPrequestOk() 
  {
    $('#engagement-prerquest-popup').modal('hide');
    this.prequestPopupButtonClick.emit('NonPrequestOk');
  }

  PreRequestOk()
  {
    $('#engagement-prerquest-popup').modal('hide');
    this.prequestPopupButtonClick.emit('PrequestOk');
  }

  NonPrequestCancel() 
  {
    $('#engagement-prerquest-popup').modal('hide');
    this.prequestPopupButtonClick.emit('NonPrequestCancel');
  }

  PreRequestCancel()
  {
    $('#engagement-prerquest-popup').modal('hide');
    this.prequestPopupButtonClick.emit('PrequestCancel');
  }


}








import { DecisionsApiLogModel } from "./DecisionsApiLogModel";
import { TeamMembers } from "./TeamMembers";

export class DecisionsNotificationProfessionalsListModel{
    isSuccess: boolean;
    workspaceId: string;
    decisionsRPList: TeamMembers[] = []; 
    decisionsApiLogModel: DecisionsApiLogModel = new DecisionsApiLogModel();
    isFinalApproval: boolean = false;
    decisionRequestType: string;
    errorCode: string;
    errorDescription: string;
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Clients } from '../model/clients';
import { ActionTiles } from '../model/actionTiles';
import { AppConfigService } from './appConfigService';
// importing models
import { NewClient, CMSClient } from 'src/app/model/Client/NewClient';
import { ClientAdministrators } from 'src/app/model/Client/ClientAdministrators';

import { SICCodes } from 'src/app/model/Client/SICCodes';
import { ClientTypes } from 'src/app/model/Client/ClientTypes';
import { ClientProgramTypes } from '../model/Client/ClientProgramTypes';
import { IndustryType } from 'src/app/model/Client/IndustryType';
import { StockExchange } from 'src/app/model/Client/StockExchange';
import { ReferralSource } from 'src/app/model/Client/ReferralSource';
import { KeyRelationshipPartners } from '../model/Client/KeyRelationshipPartners';
import { User } from '../model/User';
import { AuthService } from './auth.service';
import { ClientOneViewAccountDetails, CreateClientViewModel, OneViewClientOpportunityViewModel, OneViewClientOpportunityViewModelWrapper, OneViewSearchFieldClientModel, SearchClientbyFieldRequestModel, SearchOpportunityByEngagementIdRequestModel, SearchOpportunityByOpportunityIdRequestModel, SearchOpportunityByRequestModel } from '../model/Client/CreateClientViewModel';
import { RelationshipPartnerPopupModel } from '../model/Popup/RelationshipPartnerPopupModel';
import { ClientStatus } from '../model/Client/ClientStatus';
import { listviewModel } from '../model/listviewModel';
import { AllClientSearchFilter } from '../model/AllClientSearchFilter';
import { Applicationmaster } from '../model/ApplicationMaster';
import { SpecialConsiderationViewModel, AddUpdateDeleteSpecialConsiderationViewModel } from '../model/Client/SpecialConsiderationViewModel';
import { EngagementListPopupModel as WorkspaceDocumentListPopupModel } from '../model/Popup/EngagementListPopupModel';
import { ClientContactPopupModel } from '../model/Popup/ClientContactPopupModel';
import { StructEntity } from '../model/Client/StructEntity';
import { ClientBillingContact } from 'src/app/model/Client/ClientBillingContact';
import { EditClientViewModel } from '../model/Client/EditClientViewModel';
import { ClientLevelDocument } from '../model/Client/ClientLevelDocument';
import { OneViewClientDetailModel, OneViewClientResponse } from '../model/Client/OneViewClientDetail';
import { ClientSearch } from '../model/Client/ClientSearch';
import { UserDetails } from '../model/DocumentTemplate';
import { ClientConsent } from '../model/Client/client-consent';
import { ClientConsentRequest } from '../model/Client/client-consent-request';
import { ClientDeleteModel } from '../model/Client/client-delete-model';


@Injectable({
  providedIn: "root"
})
export class ClientService {
  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  AppEndPoint: string;
  user: User;
  constructor(
    private environment: AppConfigService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.APIEndPoint = this.environment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.AppEndPoint =
      this.environment.config.DATA.AZURE_AD_AUTHENTICATION.LOGIN_REDIRECT_URL;
  }
  getAppEndPoint(): string {
    return this.AppEndPoint;
  }

  initializeUserDetails() {
    let nameJSON = localStorage.getItem('currentUser');
    this.user = JSON.parse(nameJSON);
  }

  getClientInfo(clientId: number): Observable<Clients> {
    return this.http.get<Clients>(
      this.APIEndPoint + "/ClientSummary/ClientInfo/" + clientId
    );
  }

  getActionTiles(): Observable<ActionTiles> {
    return this.http.get<ActionTiles>(
      this.APIEndPoint + "/ActionTiles/engagement"
    );
  }
  // get master data for creating a client.

  getSICCodes(): Observable<SICCodes[]> {
    return this.http.get<SICCodes[]>(this.APIEndPoint + "/client/sic-codes");
  }

  GetClientTypes(): Observable<ClientTypes[]> {
    return this.http.get<ClientTypes[]>(
      this.APIEndPoint + "/ApplicationMaster/client-types"
    );
  }

  GetClientProgramTypes(): Observable<ClientProgramTypes[]> {
    return this.http.get<ClientProgramTypes[]>(
      this.APIEndPoint + "/ApplicationMaster/client-program"
    );
  }
  GetIndustryTypes(): Observable<IndustryType[]> {
    return this.http.get<IndustryType[]>(
      this.APIEndPoint + "/ApplicationMaster/industry-types"
    );
  }
  GetStockExchangeValues(): Observable<StockExchange[]> {
    return this.http.get<StockExchange[]>(
      this.APIEndPoint + "/ApplicationMaster/stockexchange"
    );
  }
  GetStructEntities(): Observable<StructEntity[]> {
    return this.http.get<StructEntity[]>(
      this.APIEndPoint + "/ApplicationMaster/struct-entities"
    );
  }
  GetReferralSources(): Observable<ReferralSource[]> {
    return this.http.get<ReferralSource[]>(
      this.APIEndPoint + "/ApplicationMaster/referral-source"
    );
  }

  GetClientStatus(): Observable<ClientStatus[]> {
    return this.http.get<ClientStatus[]>(
      this.APIEndPoint + "/ApplicationMaster/client-status",
      { withCredentials: true }
    );
  }
  AddNewClient(
    createClientViewModel: CreateClientViewModel,
    editMode: boolean
  ) {
    this.initializeUserDetails();

    createClientViewModel.client.userName = this.user.userName;
    createClientViewModel.client.createdby = this.user.userName;
    createClientViewModel.loggedInUserFullName = this.user.loggedInUserFullName;
    if (editMode === true) {
      createClientViewModel.client.updatedBy = this.user.userName;
    }


    if (editMode === false) {
      return this.http.post(
        this.APIEndPoint + "/client/add-client",
        createClientViewModel,
        { responseType: 'text' }
      );
    } else if (editMode === true) {
      return this.http.post(
        this.APIEndPoint + "/client/edit-client",
        createClientViewModel,
        { responseType: 'text' }
      );
    }
  }

  GetEDPClientDetailsByName(clientSearchModel: ClientSearch): Observable<NewClient> {
    return this.http.post<NewClient>(
      this.APIEndPoint + "/Client/GetClientDetailsByClientName",
      clientSearchModel
    );
  }

  GetCMSClientDetailsByName(clientName: string): Observable<CMSClient[]> {
    return this.http.get<CMSClient[]>(
      this.IntegrationAPIEndPoint + "/CMS/" + clientName
    );
  }

  GetCMSClientDetailsByClientCode(clientCode: string): Observable<CMSClient> {
    return this.http.get<CMSClient>(
      this.IntegrationAPIEndPoint + "/CMS/Client/" + clientCode
    );
  }

  GetCMSClientDetailsByClientStatusCode(clientStatusCode: string): Observable<CMSClient> {
    return this.http.get<CMSClient>(
      this.IntegrationAPIEndPoint + "/CMS/" + clientStatusCode
    );
  }
  getKeyRelationshipPartnerList(
    clientId: number
  ): Observable<RelationshipPartnerPopupModel[]> {
    return this.http.get<RelationshipPartnerPopupModel[]>(
      this.APIEndPoint + "/Client/KeyRelationshipPartners/" + clientId
    );
  }

  GetClientDetailsById(clientId: number): Observable<NewClient> {
    return this.http.get<NewClient>(
      this.APIEndPoint + "/Client/Detail/" + clientId
    );
  }

  GetClientDetailsByClientCode(clientCode: string): Observable<NewClient> {
    return this.http.get<NewClient>(
      this.APIEndPoint + "/Client/DetailByClientCode/" + clientCode
    );
  }

  GetClientAdministratorsByClientId(clientId: number): Observable<ClientAdministrators[]> {
    return this.http.get<ClientAdministrators[]>(
      this.APIEndPoint + "/client/ClientAdministrators/" + clientId
    );
  }
  GetKeyRelationshipPartnersByClientId(
    clientId: number
  ): Observable<KeyRelationshipPartners[]> {
    return this.http.get<KeyRelationshipPartners[]>(
      this.APIEndPoint + "/client/partners/" + clientId
    );
  }

  getAllClientsListView(
    pageNumber: number,
    noOfrecordsPerPage: number,
    allclientSearchFilter: AllClientSearchFilter
  ): Observable<listviewModel[]> {
    return this.http.post<listviewModel[]>(
      this.APIEndPoint +
      "/GTKM/AllClients?pageNumber=" +
      pageNumber +
      "&noOfrecordsPerPage=" +
      noOfrecordsPerPage,
      allclientSearchFilter
    );
  }

  getAllClientsListCount(
    allclientSearchFilter: AllClientSearchFilter
  ): Observable<number> {
    return this.http.post<number>(
      this.APIEndPoint + "/Client/GetAllClientsListCount",
      allclientSearchFilter
    );
  }

  GetApplicationMasterDataById(applicationMasterId: number): Observable<Applicationmaster> {
    return this.http.get<Applicationmaster>(
      this.APIEndPoint + '/ApplicationMaster/GetApplicationMaster/' + applicationMasterId
    );
  }

  getSpecialConsideration(clientId: number): Observable<SpecialConsiderationViewModel[]> {
    return this.http.get<SpecialConsiderationViewModel[]>(
      this.APIEndPoint + '/client/GetSpecialConsideration?clientId=' + clientId
    );
  }

  addUpdateDeleteSpecialConsideration(
    AddUpdateDeleteSpecialConsideration: AddUpdateDeleteSpecialConsiderationViewModel
  ) {
    return this.http.post(
      this.APIEndPoint +
      '/Client/AddUpdateDeleteSpecialConsideration', AddUpdateDeleteSpecialConsideration, { responseType: 'text' }
    );

  }
  getWorkspaceDocumentsByClient(clientId: number): Observable<WorkspaceDocumentListPopupModel[]> {
    return this.http.get<WorkspaceDocumentListPopupModel[]>(this.APIEndPoint + '/ClientSummary/WorkspaceDocumentsByClient/' + clientId);
  }

  getClientContactList(clientId: number): Observable<ClientContactPopupModel[]> {
    return this.http.get<ClientContactPopupModel[]>(
      this.APIEndPoint + "/Client/ClientContacts/" + clientId
    );
  }

  getClientContactsInfoForPopup(clientId: number): Observable<ClientContactPopupModel> {
    return this.http.get<ClientContactPopupModel>(
      this.APIEndPoint + '/Client/ClientContactsInfo/' + clientId
    );
  }

  isClientShortNameExists(clientshortname: string): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + '/Client/IsClientShortNameExists/' + clientshortname);
  }

  getDefaultClientBillingContactDetails(clientId: number): Observable<ClientBillingContact> {
    return this.http.get<ClientBillingContact>(
      this.APIEndPoint + '/Client/DefaultClientBillingContactDetails/' + clientId
    );
  }

  GetClientEditDetailById(clientId: number): Observable<EditClientViewModel> {
    return this.http.get<EditClientViewModel>(
      this.APIEndPoint + "/Client/GetClientEditDetail/" + clientId
    );
  }

  UpdateClient(editClientViewModel: EditClientViewModel) {
    return this.http.post(
      this.APIEndPoint + "/client/edit-client",
      editClientViewModel,
      { responseType: 'text' });
  }
  GetClientLevelDocuments(clientId: number): Observable<ClientLevelDocument[]> {
    return this.http.get<ClientLevelDocument[]>(
      this.APIEndPoint + '/Client/GetClientLevelDocuments/' + clientId
    );
  }

  DeleteClientLevelDocument(clientLevelDocumentId: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    const FetchUrl: string = this.APIEndPoint + '/Client/DeleteClientLevelDocument/' + clientLevelDocumentId;
    return this.http.get<string>(FetchUrl, requestOptions);

  }
  UpdateClientLevelDocument(clientLevelDocument: ClientLevelDocument): Observable<string> {
    this.initializeUserDetails();
    const requestOptions: object = {
      responseType: 'text'
    };
    clientLevelDocument.updatedBy = this.user.loggedInUserEmail;
    return this.http.post<string>(
      this.APIEndPoint + "/client/UpdateClientLevelDocument", clientLevelDocument, requestOptions);
  }

  GetOneViewClients(searchTerm: string): Observable<OneViewClientResponse<OneViewClientDetailModel[]>> {
    return this.http.get<OneViewClientResponse<OneViewClientDetailModel[]>>(this.IntegrationAPIEndPoint + '/OneView/GetClientsBySearchText?clientSearchText=' + encodeURIComponent(searchTerm));
  }

  GetClientDetailByAccountId(accountId: string): Observable<OneViewClientResponse<OneViewClientDetailModel>> {
    return this.http.get<OneViewClientResponse<OneViewClientDetailModel>>(this.IntegrationAPIEndPoint + '/OneView/GetClientDetailByAccountId?accountId=' + accountId);
  }

  GetClientsBySearchTextField(searchClientbyFieldRequestModel: SearchClientbyFieldRequestModel) {
    return this.http.post<OneViewClientResponse<OneViewSearchFieldClientModel[]>>(this.IntegrationAPIEndPoint + '/OneView/GetClientsBySearchTextField', searchClientbyFieldRequestModel);
  }

  GetClientOpportunity(searchOpportunityByRequestModel: SearchOpportunityByRequestModel) {
    return this.http.post<OneViewClientResponse<OneViewClientOpportunityViewModelWrapper>>(this.IntegrationAPIEndPoint + '/OneView/GetClientOpportunityV2', searchOpportunityByRequestModel);
  }

  GetClientOpportunityByOpportunityId(searchOpportunityByOpportunityIdRequestModel: SearchOpportunityByOpportunityIdRequestModel) {
    return this.http.post<OneViewClientResponse<OneViewClientOpportunityViewModelWrapper>>(this.IntegrationAPIEndPoint + '/OneView/GetClientOpportunityByOpportunityId', searchOpportunityByOpportunityIdRequestModel);
  }

  GetNewOpportunityDetailsByPreviousYearEngagementId(previousYearEngagementId: number): Observable<OneViewClientResponse<OneViewClientOpportunityViewModelWrapper>> {
    return this.http.get<OneViewClientResponse<OneViewClientOpportunityViewModelWrapper>>(this.IntegrationAPIEndPoint + '/OneView/GetNewOpportunityDetailsByPreviousYearEngagementId?previousYearEngagementId=' + previousYearEngagementId);
  }

  GetClientsByActiveEngagementOfUser(
    loggedInUser: string,
    isloggedInUserAdmin: boolean
  ): Observable<Clients[]> {
    return this.http.get<Clients[]>(
      this.APIEndPoint + "/client/GetClientsByActiveEngagementOfUser/" + loggedInUser + "/" + isloggedInUserAdmin
    );
  }

  GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel: SearchOpportunityByEngagementIdRequestModel) {
    return this.http.post<OneViewClientResponse<OneViewClientOpportunityViewModelWrapper>>(this.IntegrationAPIEndPoint + '/OneView/GetClientOpportunityByEngagementId', searchOpportunityByEngagementIdRequestModel);
  }

  AddNewClientOneView(createClientViewModel: OneViewClientDetailModel): Observable<OneViewClientResponse<string>> {
    return this.http.post<OneViewClientResponse<string>>(this.IntegrationAPIEndPoint + "/OneView/AddClientInOneView", createClientViewModel);
  }

  CheckClientOneViewAccountIdExists(clientOneViewAccountID: number): Observable<any> {
    return this.http.get(this.APIEndPoint + "/Client/CheckClientOneViewAccountIdExists/" + clientOneViewAccountID);
  }

  UpdateOneViewAccountId(clientOneViewAccountIDetails: ClientOneViewAccountDetails): Observable<string> {
    this.initializeUserDetails();
    clientOneViewAccountIDetails.UpdatedBy = this.user.loggedInUserEmail;
    return this.http.post(
      this.APIEndPoint + "/Client/UpdateOneViewAccountId",
      clientOneViewAccountIDetails,
      { responseType: 'text' }
    );

  }

  GetClientsByUserId(userDetails: UserDetails): Observable<Clients[]> {
    return this.http.post<Clients[]>(this.APIEndPoint + '/Client/GetClientsByUserId', userDetails);
  }

  getClientConsent(clientConsentRequest: ClientConsentRequest): Observable<OneViewClientResponse<boolean>> {
    return this.http.post<OneViewClientResponse<boolean>>(this.IntegrationAPIEndPoint + "/OneView/GetClientConsent", clientConsentRequest);
  }
  getClientConsentFeatureFlag(): Observable<boolean> {
    return this.http.get<boolean>(this.IntegrationAPIEndPoint + '/OneView/GetClientConsentFeatureFlag');
  }

  deleteClientsForEngagement(deleteClientsForEngagement: ClientDeleteModel): Observable<ClientDeleteModel> {
    return this.http.post<ClientDeleteModel>(this.APIEndPoint + "/Client/DeleteClientsForEngagement", deleteClientsForEngagement);
  }
  getMyChangeOrderClients(loggedInUser: string, isloggedInUserAdmin: boolean): Observable<Clients[]> {
    return this.http.get<Clients[]>(this.APIEndPoint + "/client/GetMyChangeOrderClients/" + loggedInUser + "/" + isloggedInUserAdmin);
  }
}

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContactPopupModel } from 'src/app/model/Popup/ContactPopupModel';
import { GraphService } from 'src/app/services/Integration/graphservice.service';

@Component({
  selector: 'app-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.css']
})
export class ContactPopupComponent implements OnInit, OnChanges {

  contact: ContactPopupModel;
  @Input() contactEmailId: string;

  constructor(private graphService: GraphService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      const prevVal = JSON.stringify(change.previousValue);
      console.log('CC Before ngOnChanges ', curVal, prevVal, curVal == null, curVal == 'null');

      if(curVal)
      {
        curVal = curVal.replace(/["]+/g, '');
      }
      
      if (curVal === 'null') {
        this.contact = null;
      }
      else {
        console.log('CC After ngOnChanges ', curVal, prevVal, curVal == null, curVal == 'null');
        if (curVal !== prevVal) {
          this.GetContactInfo(curVal);
        }
      }
    }
  }

  GetContactInfo(contactEmailId: string) {
    this.graphService.GetContactInfo(contactEmailId)
      .subscribe(
        result => {
          this.contact = result;
          console.log('getClientContactList func called ', result, this.contact);
        },
        error => {
          this.contact = null;
        }
      );
  }

  ClosePopover() {
    this.contact=null;

  }


}

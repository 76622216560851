import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { IWantToList } from '../model/IWantToList';
import { NavBar } from '../model/NavBar';
import { AppConfigService } from './appConfigService';
import { TemplateMappingModel } from '../model/TemplateMappingModel';

@Injectable()
export class NavbarService {
    APIEndPoint: string;

    constructor(private enviornment: AppConfigService, private http: HttpClient) { 
        this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
    }

    getIWantToList(): Observable<IWantToList[]> {
        return this.http.get<IWantToList[]>(this.APIEndPoint + '/Navbar/IWantTo');
    }

    getNavBarList(): Observable<NavBar> {
        return this.http.get<NavBar>(this.APIEndPoint + "/Navbar/");  
    }

    getTemplateMappingData(documentCategory:string): Observable<TemplateMappingModel[]> {
        return this.http.get<TemplateMappingModel[]>(this.APIEndPoint + "/Navbar/GetTemplateMappingData/"+documentCategory);
    }

    SaveActivityLogOnActionSelection(actionName: string) {
        let body = '';
        return this.http.post<string>(this.APIEndPoint + "/Navbar/IWantTo/", body);
    }

}

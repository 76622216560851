import { MembersList } from '../MembersList';
import { SPDocumentList } from '../SharePoiint/SharePointSite';
import{DocuSignTemplateList} from'../Engagement/DocuSignTemplateModel';
export class SignatureMember{
    displayName: string;
    mail: string;
    mobilePhone: string;
    businessPhones: string;
    jobTitle: string;
    lineOfBusiness: string;
    serviceLine: string;
    location: string;
    employeeId: string;
}

export class EngagementESignatureRequestModel{
    envelopeId: string;
    discardRemark: string;
    envelopeSubject: string;
    acceptanceFolderPath: string;
    membersList: SignatureMember[] = [];
    signatureHandler: MembersList;
    selectedDocument: SPDocumentList[] = [];
    docuSignTemplateList:DocuSignTemplateList[]=[];
}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { Alert } from 'src/app/model/Alert';
declare var HD$;

@Component({
  selector: 'app-review-response',
  templateUrl: './review-response.component.html',
  styleUrls: ['./review-response.component.css']
})
export class ReviewResponseComponent implements OnInit {
  private workItemId = '';
  private versionId = '';
  public appliedTnC = false;
  @Input() set hdWorkItemVersionId(workItemVersionId: string) {
    if (workItemVersionId !== undefined && workItemVersionId !== null && workItemVersionId !== '') {
      this.versionId = workItemVersionId;
    }
  }
  @Input() set hdWorkItemId(workItemId: string) {
    if (workItemId !== undefined && workItemId !== null && workItemId !== '') {
      this.workItemId = workItemId;
      this.getAnswerSummary();
    }
  }
  @Input() set isAppliedTnC(isAppliedTnC: boolean) {
    if (isAppliedTnC !== undefined && isAppliedTnC !== null) {
      this.appliedTnC = isAppliedTnC;
    }
  }

  @Output() OnQuestionAnswerLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() index;
  constructor(private hdService: HotDocsService,
    private loadingService: LoadingScreenService,
    private alertService: AlertsService,
    private appConfig: AppConfigService) {
      this.index = 0;
     }
  summaryEndPints: InterviewModel;
  interviewOptions: any;
  integrationAPIEndPoint: string;
  alert: Alert;
  ngOnInit() {     
    this.integrationAPIEndPoint = this.appConfig.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  getAnswerSummary() {    
    if (this.workItemId !== null && this.workItemId !== '' &&
      this.versionId !== null && this.versionId !== '') {
        this.loadingService.startLoading();
      this.hdService.getAnswerSummary(this.workItemId, this.versionId).subscribe(
        result => {
          this.summaryEndPints = result;
          this.AttachInterviewSession();
          this.loadingService.stopLoading();
        },
        err => {
          this.DisplaySystemAlert();
        });
    }
  }
  AttachInterviewSession() {
    this.loadingService.stopLoading();
    var divToBeLoad : string;    
    if (this.appliedTnC)  {
      divToBeLoad = 'originalhdSummaryDiv';
    }  else {
      divToBeLoad = 'hdSummaryDiv'+ this.index;
    }
    
    this.interviewOptions = {
      Container: divToBeLoad,
      Theme: 'default.css',
      OnInit: () => {
        HD$.RegisterHandler('OnHDStart',
          (eventArgs: any) => {

            HD$.SetVisibility('Toolbar', false);
            HD$.SwitchView('AnswerSummary');
            this.OnQuestionAnswerLoaded.emit(true);
          });
      },
      LeaveWarning: false
    };
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();
    var dateTime = date + ' ' + time;
    HD$.AttachSession(
      this.summaryEndPints.coreSessionId,
      this.summaryEndPints.serviceMetadataUrl + '?version=' + dateTime,
      this.interviewOptions
    );
  }
  DisplaySystemAlert() {
    this.alertService.remove(this.alert);
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }
  ngOnDestroy() {
    HD$.DetachSession();
  }

}

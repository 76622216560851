import { Injectable } from '@angular/core';
import { AppConfigService } from '../appConfigService';
import { HttpClient } from '@angular/common/http';
import { AutoRpMembersWithDelegateModel, DecisionsRPRequestModel, PsdMembersWithDelegate } from 'src/app/model/Workspace/DecisionsRPRequestModel';
import { Observable } from 'rxjs';
import { DecisionsRPReponseViewModel } from 'src/app/model/Workspace/DecisionsRPResponseViewModel';
import { NotificationOnlyRequestModel } from 'src/app/model/decisions/notifications/notification-request';
import { CloseoutConfigurationRequest } from 'src/app/model/decisions/closeout/closeout-configuration-request';
import { CloseoutConfigurationResponse } from 'src/app/model/decisions/closeout/closeout-configuration-response';
import { SolutionCodeConfigResponse } from 'src/app/model/decisions/solutioncodeproperties/Solutioncodeproperties-response';
import { SolutionCodeConfigurationRequest } from 'src/app/model/decisions/solutioncodeproperties/solutioncodeproperties-request';


@Injectable({
  providedIn: 'root'
})
export class DecisionsRuleEngineService {

  IntegrationAPIEndPoint: string;
  ApiEndPoint: string;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.IntegrationAPIEndPoint = enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.ApiEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
  }

  GetReviewingProfessionals(decisionsRPRequestModel: DecisionsRPRequestModel): Observable<DecisionsRPReponseViewModel>{
    return this.http.post<DecisionsRPReponseViewModel>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/GetReviewingProfessionals", decisionsRPRequestModel);  
  }

  GetNotifications(notificationOnlyRequestModel: NotificationOnlyRequestModel):Observable<any>{
    return this.http.post<any>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/GetDisplayNotifications", notificationOnlyRequestModel);  
  }

  SendNotifications(notificationOnlyRequestModel: NotificationOnlyRequestModel):Observable<any>{
    return this.http.post<any>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/SendNotifications", notificationOnlyRequestModel);  
  }
  
  GetRpMembersWithDelegate(autoRpMembersWithDelegateModel: AutoRpMembersWithDelegateModel): Observable<AutoRpMembersWithDelegateModel>{
    return this.http.post<AutoRpMembersWithDelegateModel>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/GetRpMembersWithDelegate", autoRpMembersWithDelegateModel);  
  }

  GetCloseoutConfigurations(closeoutConfigurationRequest: CloseoutConfigurationRequest): Observable<CloseoutConfigurationResponse>{
    return this.http.post<CloseoutConfigurationResponse>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/GetCloseoutConfigurations", closeoutConfigurationRequest);  
  }

  GetPsdMembersWithDelegate(psdMembersWithDelegate: PsdMembersWithDelegate): Observable<PsdMembersWithDelegate>{
    return this.http.post<PsdMembersWithDelegate>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/GetPsdMembersWithDelegate", psdMembersWithDelegate);  
  }
  GetSolutionCodeProperties(solutionCodeConfigRequest:SolutionCodeConfigurationRequest ): Observable<SolutionCodeConfigResponse>{
    return this.http.post<SolutionCodeConfigResponse>(this.IntegrationAPIEndPoint + "/DecisionsRuleEngine/GetSolutionCodeProperties", solutionCodeConfigRequest);  
  }

}
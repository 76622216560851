import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterContentChecked } from '@angular/core';
import { EngagementService } from 'src/app/services/EngagementService';
import { EngagementListPopupModel as WorkspaceDocumentListPopupModel } from 'src/app/model/Popup/EngagementListPopupModel';
import { WorkspaceModel} from 'src/app/model/WorkspaceModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { HDConfigurationModel } from 'src/app/model/HDConfigurationModel';
import { DataService } from 'src/app/services/data.service';
declare var $;
@Component({
  selector: 'app-document-list-popup',
  templateUrl: './document-list-popup.component.html',
  styleUrls: ['./document-list-popup.component.css']
})
export class DocumentListPopupComponent implements OnInit, OnChanges, AfterContentChecked {

  documentList: WorkspaceDocumentListPopupModel[] = [];
  @Input() engagementId: number;
  PreviousWorkspaceId: string;
  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  InterviewList: InterviewModel;
  interviewOptions: any;
  IntegrationAPIEndPoint: string;
  HdConfigDetails: HDConfigurationModel = new HDConfigurationModel();
  constructor(private engagementService: EngagementService, private hdService: HotDocsService
    , private workspaceService: WorkspaceService
    , private activeRoute: ActivatedRoute
    ,private route: Router
    ,private loadingScreenService: LoadingScreenService
    ,private dataService :DataService) {}

  ngOnInit() {}
  
  ngAfterContentChecked() {
    // ...
    var currentWorkspaceId = $('#HiddenWorkspaceIdonDocListPopup').val();
    if (currentWorkspaceId != null && currentWorkspaceId != "") {
      this.loadingScreenService.startLoading();
      // this.PreviousWorkspaceId = currentWorkspaceId;
      this.getWorkspaceDatails(currentWorkspaceId);
      // if(this.PreviousWorkspaceId != currentWorkspaceId){
      //   this.getWorkspaceDatails(currentWorkspaceId);
      // }
    }

  }
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);
      //console.log('RP ngOnChanges ', curVal, prevVal, curVal == null, curVal == 'null');
      if (curVal == 'null') {
        this.documentList = null;
        this.PreviousWorkspaceId = null;
      }
      else {
        if (curVal != prevVal) {
          this.getWorkspaceDocumentsByEngagement(parseInt(curVal));
          this.PreviousWorkspaceId = null;
        }
      }
    }
  }

  getWorkspaceDocumentsByEngagement(engagementId: number) {
    this.engagementService.getWorkspaceDocumentsByEngagement(engagementId)
      .subscribe(
        result => {
          this.documentList = result;
          console.log('getWorkspaceDocumentsByEngagement func called ', engagementId, result, this.documentList);
        },
        error => {
          this.documentList = null;
        }
      );
  }
  DisplayInterviewsModal(result) {
    this.route.navigate(['Interviews/' + result.workspaceId]);
  }

  getWorkspaceDatails(id: string) {
    if (id != null) {
      $('#HiddenWorkspaceIdonDocListPopup').val("");
      $('#ActiveDocumentsModal').flyout('hide')
      $(".showDocumentDataDetailsjs").each(function (i, obj) {
        setTimeout(() => {
          $(this).flyout('hide');
        }, 1000);
      });

      this.workspaceService.getWorkspaceById(id).subscribe(
        result => {
          this.loadingScreenService.stopLoading();
          result.setAnsFlag = "update";
          this.CurrentWorkspaceData = result;
          console.log("Workitem id check on document flyout:", this.CurrentWorkspaceData.hdWorkItemId);
          if (this.CurrentWorkspaceData.hdWorkItemId != null && this.CurrentWorkspaceData.workspaceId != "00000000-0000-0000-0000-000000000000") {
            this.DisplayInterviewsModal(this.CurrentWorkspaceData);

          }
        });
    }
  }

}

import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { EngagementService } from 'src/app/services/EngagementService';
import { EngagementListPopupModel as WorkspaceDocumentListPopupModel } from 'src/app/model/Popup/EngagementListPopupModel';
import { WorkspaceModel } from 'src/app/model/WorkspaceModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ClientService } from 'src/app/services/clientservice';
import { AlertsService } from 'src/app/services/alerts.service';
declare var $;
@Component({
  selector: 'app-client-workspace-popup',
  templateUrl: './client-workspace-popup.component.html',
  styleUrls: ['./client-workspace-popup.component.css']
})
export class ClientWorkspacePopupComponent implements OnInit {

  clientWorkspaceDocumentList: WorkspaceDocumentListPopupModel[] = [];
  @Input() clientWorkspaceClientId: number;
  InterviewsData: string;
  PreviousWorkspaceId:string;
  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  constructor(private engagementService: EngagementService, private hdService :HotDocsService
    ,private workspaceService:WorkspaceService
    ,private activeRoute: ActivatedRoute,
    private route: Router
    ,private loadingScreenService: LoadingScreenService
    ,private clientService: ClientService
    ,private _alertService : AlertsService) {

     }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);
      //console.log('RP ngOnChanges ', curVal, prevVal, curVal == null, curVal == 'null');
      if (curVal == 'null') {
        this.clientWorkspaceDocumentList = null;
        this.PreviousWorkspaceId = null;
      }
      else {
        if (curVal != prevVal) {
          setTimeout(() => {
            this.getWorkspaceDocumentsByClient(parseInt(curVal));
          }, 50);

          this.PreviousWorkspaceId = null;
        }
      }
    }
  }

  getWorkspaceDocumentsByClient(clientId: number) {
    this.clientService.getWorkspaceDocumentsByClient(clientId)
      .subscribe(
        result => {
          this.clientWorkspaceDocumentList = result;
          console.log('getWorkspaceDocumentsByClient func called ', clientId, result, this.clientWorkspaceDocumentList);
        },
        error => {
          this.clientWorkspaceDocumentList = null;
        }
      );
  }
  DisplayInterviewsModal(result){
   // this.loadingScreenService.stopLoading();
    result.setAnsFlag="add";
    this.InterviewsData = result.workspaceId;
    $('#ShowClientWorkspace').modal('hide');
  }

  getWorkspaceDatails(id:string) {
if(id != null){
  this.loadingScreenService.startLoading();
  $('#HiddenWorkspaceIdonClientWorkspacePopup').val("");
  $(".showClientWorkspacejs").each(function (i, obj) {
      setTimeout(() => {
        $(this).flyout('hide');
      }, 1000);
    });

    this.workspaceService.getWorkspaceById(id).subscribe(
      result => {
        this.loadingScreenService.stopLoading();
        result.setAnsFlag = "update";
        this.CurrentWorkspaceData = result;
        this.InterviewsData = null;
        console.log("Workitem id check on document flyout:",this.CurrentWorkspaceData.hdWorkItemId);
        if(this.CurrentWorkspaceData.hdWorkItemId != null && this.CurrentWorkspaceData.workspaceId != "00000000-0000-0000-0000-000000000000"){
          this.DisplayInterviewsModal(this.CurrentWorkspaceData);
      }
      },error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
    }
  }

}

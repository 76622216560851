import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/DocumentService';
import { DocumentListModel, DocumentList } from 'src/app/model/DocumentListModel';
import { DocumentCatgories } from 'src/app/model/DocumentTypeListModel';
import { Router } from '@angular/router';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
declare var $;
declare function applyListView(fnCallBack);
//document card call
declare function ShowAllClientNameForEL();
declare function ShowAllEngagementsForEL();
declare function ShowAllDocNamesForEL();
declare function ShowAllDocTypesForEL();
declare function ShowAllStatusForEL();
declare function ShowAllAuthorForEL();
declare function CheckUncheckAll(CheckAllStatusReview, CheckStatusReview);

@Component({
  selector: 'app-engagements-letters-list-view-component',
  templateUrl: './engagements-letters-list-view-component.html',
  styleUrls: ['./engagements-letters-list-view-component.css'],
  providers: [DocumentService]
})
export class EngagementsLettersListViewComponent implements OnInit {
  applyFilterOnceAfterDataLoad: boolean = false;
  page: number[] = [];
  documentList: DocumentListModel[] = [];
  documentListDataFromDB: DocumentList[] = [];
  ShowClientName: boolean = true;
  ShowEngName: boolean = true;
  ShowModifiedBY: boolean = false;
  ShowAuthor: boolean = false;
  engagementId: number;
  fnCallBack: any;

  selectedWorkspaceId: string;

  setWorkspaceIdForActivityLog(workspaceId: string) {
    this.selectedWorkspaceId = workspaceId;
    $("#modal-activityLog").modal({
      backdrop: 'static'
    });
    return false;
  }
  constructor(private documentService: DocumentService, private route: Router, private loadingScreenService: LoadingScreenService) { }

  ngOnInit() {

    this.getSubDocumentList(DocumentCatgories.AcceptanceDocument, 'EngagementsLettersListViewComponent');

    this.fnCallBack = function () {
      ShowAllClientNameForEL();
      ShowAllEngagementsForEL();
      ShowAllDocNamesForEL();
      ShowAllDocTypesForEL();
      ShowAllAuthorForEL();
      ShowAllStatusForEL();
      CheckUncheckAll('CheckAllClientsEL', 'CheckClientForEL');
      CheckUncheckAll('CheckAllEngEL', 'CheckEngagementForEL');
      CheckUncheckAll('CheckAllDocName', 'CheckDocNameForEL');
      CheckUncheckAll('CheckAllDocType', 'CheckDocTypeForEL');
      CheckUncheckAll('CheckAllAuthor', 'CheckAuthorForEL');
      CheckUncheckAll('CheckAllStatusForEL', 'CheckStatusForEL');
    }
  }

  ngAfterViewChecked() {
    if (this.applyFilterOnceAfterDataLoad) {
      this.ApplyFilters();
      this.applyFilterOnceAfterDataLoad = false;
    }
  }

  getDocumentList() {
    this.loadingScreenService.startLoading();
    this.documentService.getMyDocumentList().subscribe(
      result => {
        this.loadingScreenService.stopLoading();
        this.documentListDataFromDB = result;
        this.applyFilterOnceAfterDataLoad = true;
      },
      err => console.log('Error in loading the client list information.'));
  }

  ApplyFilters() {
    applyListView(this.fnCallBack);
  }

  ToggleListAndCardView(SelectedView) {
    switch (SelectedView) {
      case "Card":
        this.SetDefaultCardView();
        break;
      case "List":
        $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
        $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
        $(".ListViewContainer").show();
        $(".cardViewContainer").hide();
        $(".CardViewFilters").hide();
        this.ApplyListFilters();
        break;
      default:
        this.SetDefaultCardView();
        break;
    }
  }
  ApplyListFilters() {
    $('.tableDataDoc').excelTableFilter();
    // $('.tableDataReview').excelTableFilter();
    $(".a-to-z").text("Ascending");
    $(".dropdown-filter-sort span.a-to-z").after("<img src='../../../../assets/images/listview/asending_1.svg' id='ascImage' class='FloatImage'> ");
    $(".z-to-a").text("Descending")
    $(".dropdown-filter-sort span.z-to-a").after("<img src='../../../../assets/images/listview/desending_1.svg' id='descImage' class='FloatImage'>");
    $("img.FloatImage").css({
      "float": "right",
      "margin-top": "6px",
      "color": "#4f2d7e",
      'background-repeat': 'none'
    });
  }
  SetDefaultCardView() {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_un-selected.png");
    $(".cardViewContainer").show();
    $(".ListViewContainer").hide();
    $(".CardViewFilters").show();
    $(".CardViewFilters").css('display', 'inherit');
  }

  getMyDocumentsListById(engId: number) {
    this.documentService.getMyDocumentListById(engId).subscribe(
      result => {
        this.documentListDataFromDB = result;
        console.log(this.documentListDataFromDB);
        this.applyFilterOnceAfterDataLoad = true;
      },
      err => console.log('Error in loading the client list information.'));
  }

  getSubDocumentList(documentCatgory: string, pageName: string) {
    this.loadingScreenService.startLoading();
    this.documentService.getSubDocumentList(documentCatgory, pageName).subscribe(
      result => {
        this.loadingScreenService.stopLoading();
        this.documentListDataFromDB = result;
        this.applyFilterOnceAfterDataLoad = true;
      },
      err => console.log('Error in loading the sub document list information.'));
  }

  onKeyup(event, classname) {
    const text = event.currentTarget.value;
    const value = text.toLowerCase();
    $('.' + classname).filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      var originalFilterLength = $("."+ classname).length;
      var hiddenFilterdata = $('.'+ classname).filter(function () {
        return $(this).css('display') == 'none';
      }).length;

      if (originalFilterLength == hiddenFilterdata) {
        $("#NoDataMessage").removeClass('hide');
      }
      else
      { $("#NoDataMessage").addClass('hide');}
    });
  }
  setWorkspaceId(id: string) {
    if (id != null && id != "") {
      this.route.navigate(['Interviews/' + id]);
    }
  }
}

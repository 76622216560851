import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.css']
})
export class ClientHomeComponent implements OnInit {

  carousel: string;

  constructor(private _dataService: DataService) { 
    _dataService.roleInfoSource$.subscribe(value => this.carousel = value);
    console.log('Client Home Constructor ', this.carousel);
  }

  ngOnInit() {
  }

}

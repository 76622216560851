export class DecisionsRPResponse{
    engageReturn: DecisionsRPResponseModel;
}

export class DecisionsRPResponseModel
{
    isSuccess: boolean;
    riskPoints: number;
    reviewingProfessionals: ReviewingProfessional[] = [];
    errorCode: string;
    errorDescription: string;
}

export class ReviewingProfessional
{
    roleDescription: string;
    roleName: string;
    tier: number;
    users: User[] = [];
}

export class User
{
    email: string;
    name: string;
    usid: string;
}
<div>
  <input type="text" class='form-control inputPicker input-height rounded-0' id="{{PickerTextBoxCtrlName}}"
    (keydown.enter)="GetUserInformationByDisplayName(); "  autocomplete="off" placeholder="Select {{labelCtrlName}}"
    (focus)="setPeoplePickerPlaceholderForFocus()" (blur)="setPeoplePickerPlaceholder();" [(ngModel)]="PeoplePickerModel">
  <span id="{{PickerHintLabelCtrlName}}" class="span-hint mt-1" *ngIf="hideHinttext==false">
    Hint: Last name, first name, or email.</span>
  <input type="hidden"  id="{{PickerHiddenValidInputCtrlName}}" value="{{isGivenUserGroupMember}}" />
  <div id="{{PickerUserHiddenPropsCtrlName}}">
    <input type="hidden" id="hdnUserName" value="{{searchedUser.user.displayName}}" />
    <input type="hidden" id="hdnUserEmail" value="{{searchedUser.user.mail}}" />
    <input type="hidden" id="hdnUserPhone" value="{{searchedUser.user.businessPhones}}" />
    <input type="hidden" id="hdnJobTitle" value="{{searchedUser.user.jobTitle}}" />
    <input type="hidden" id="hdnJobCode" value="{{searchedUser.user.jobCode}}" />
    <input type="hidden" id="hdnOfficeLocation" value="{{searchedUser.user.location}}" />
  </div>
  <div class="icon-container">
    <em id='{{PickerLoaderIconCtrlName}}' class=""></em>
  </div>
</div>
<div *ngIf="!(roleShortName=='IC' || roleShortName=='GIA')"  class="error-color" id='{{PickerSearchUseremptytxtboxCtrlName}}' style='display: none;'>  
  <span *ngIf="!isValidUser">{{labelRoleName}} should be valid</span>
</div>
<div *ngIf="!(roleShortName=='IC' || roleShortName=='GIA')"  class="error-color" id='{{PickerSearchUserErrorCtrlName}}' style='display: none;'>
  <span *ngIf="!isGivenUserGroupMember">User is not part of {{labelCtrlName}} role</span>
</div>

<div *ngIf="roleShortName=='IC' || roleShortName=='GIA'" class="error-color" id='{{PickerSearchUserErrorCtrlName}}' style='display: none;'>
  <span *ngIf="!isGivenUserGroupMember">This individual is not eligible to be added to an engagement team</span>
</div>

<div *ngIf="roleShortName=='AA'" class="warning-AA-text mt-1" id="{{PickerSearchUserManagerWarningCtrlName}}" style='display: none;'>
  <span>MD allowed but not recommended</span>
</div>

<ul class="list-group " id='{{PickerSearchUserListCtrlName}}' style="display:none;">

</ul>
export class SearchPageViewModel {
  clientSearchResultList: ClientSearchResult[];
  engagementSearchResultList: EngagementSearchResult[];
  assignmentSearchResultList: AssignmentSearchResult[];
  sClientNameList: SClientName[];
  sEngagementNameList: SEngagementName[];
  sAssignmentNameList: SAssignmentName[];
  sIndustryTypeList: SIndustryType[];
  sClientProgramList: SClientProgram[];
  sStrucTypeList: SStrucType[];
  sClientStatusList: SClientStatus[];
  sSolutionCodeList: SSolutionCode[];
  sEngagementStatusList: SEngagementStatus[];
  sAssignmentCodeStatusList: SAssignmentCodeStatus[];
  seppmdList: SEPPMD[];
  scmseppmdList: SCMSEPPMD[];
}
export class ClientSearchResult {
  clientId: number;
  clientName: string;
  clientCode: string;
  clientProgramId: number;
  clientProgram: string;
  clientStatusId: number;
  clientStatus: string;
  serviceLineList: string;
  isUserMember: boolean;
}
export class EngagementSearchResult {
  engagementId: number;
  clientId: number;
  clientName: string;
  clientCode: string;
  engagementName: string;
  engagementStatus: string;
  solutionCodeId: number;
  solutionCode: string;
  eppmd: string;
  isUserMember: boolean;
}
export class AssignmentSearchResult {
  assignmentCodeId: number;
  engagementId: number;
  clientId: number;
  clientName: string;
  clientCode: string;
  engagementName: string;
  assignmentName: string;
  solutionCodeId: number;
  solutionCode: string;
  assignmentCode: string;
  assignmentCodeStatus: string;
  isUserMember: boolean;
  engagementStatus: string;
}

export class SClientName {
  clientName: string;
  isChecked: boolean;
}
export class SEngagementName {
  engagementName: string;
  isChecked: boolean;
}
export class SAssignmentName {
  assignmentName: string;
  isChecked: boolean;
}
export class SIndustryType {
  industryTypeId: number;
  industryType: string;
  isChecked: boolean;
}
export class SClientProgram {
  clientProgramId: number;
  clientProgram: string;
  isChecked: boolean;
}
export class SStrucType {
  strucTypeId: number;
  strucType: string;
  isChecked: boolean;
}
export class SClientStatus {
  clientStatusId: number;
  clientStatus: string;
  isChecked: boolean;
}
export class SSolutionCode {
  solutionCodeId: number;
  solutionCode: string;
  isChecked: boolean;
}
export class SEngagementStatus {
  engagementStatus: string;
  isChecked: boolean;
}
export class SAssignmentCodeStatus {
  assignmentCodeStatus: string;
  isChecked: boolean;
}
export class SEPPMD {
  eppmd: string;
  isChecked: boolean;
}
export class SCMSEPPMD {
  cmseppmd: string;
  isChecked: boolean;
}

export enum EnumSearchTypes {
  CL = 'CL',
  ENG = 'ENG',
  ASIGN = 'ASIGN'
}

export class GlobalSearchPageViewModel {
  userName: string;
  isloggedInUserAdmin: string;
  serviceLineType: string;
  searchType: string;
  searchTerm: string;
  pageNumber: number;
  pageSize: number;
  orderByColumn: string;
  orderByType: string;
  clientProgramId: string;
  clientStatusId: string;
  solutionCodeId: string;
  clientName: string;
  engagementName: string;
  assignmentName: string;
  engagementStatus: string;
  assignmentCodeStatus: string;
  ePPMD: string;
}

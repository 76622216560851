import { AzureADUsersList } from '../model/AzureADUserList';
import { MembersList } from './MembersList';

export class AzureADUsersArrayList {
  roleName: string;
  eproleGroups: AzureADUsersList[];
  azureADUsers: AzureADUsersList[];
}

export class AzureADUserRoleGroupList
{
    azureADUsers:AzureADUserRoleGroup[]; 
}

export class AzureADUserRoleGroup {
  adDetaiId: number;
  roleId: number;
  azureADMappedRoleName: string;
  isAudit: boolean;
  isTax: boolean;
  isAdvisory:boolean;
  isAttest: boolean;  
}

export class SearchGroupUserViewModel{
   azureADUsers: AzureADUsersArrayList;
   user: MembersList;
   isMemberFirm: boolean;
   memberFirmDomain: string[];
}

export class IndusADUsersViewModel {
  azureADUsers: AzureADUsersList[];
  workspaceMemberId: number;
  memberEmail: string;
  memberName: string;
  isIndusMember: boolean;
}

export class IndusADUsersListViewModel{
  indusADUsersList: IndusADUsersViewModel[];
}

export class AzureADGroups {
  roleName: string;
  azureADUsers: AzureADUsersList[];
}

export class ASQMemberModel
{
  aSQMembersList: ASQMember[];
}

export class ASQMember {
  aSQName: string;
  aSQEmailId: string
}
export class UserPreferenceModel {
  userPreferenceData: UserPreferenceData[];
    
  }
export class UserPreferenceData {
    userEmail : string;
    dashBoardName : string;
    dashboardViewType : string;
    cardViewFilterList : string;
    cardViewSortingList : string;
    cardViewPageNumber : number;
    listViewFilterList : string;
    listViewSortingList : string;
    listViewPageNumber : number;
}
export class ApprovalSortModel {
  orderByColumn : string;
  orderByType:string;
  awaitingImmediateApprovalFlag:boolean;
  awaitingTierApprovalFlag:boolean;
  approvedFlag:boolean;
  declinedFlag:boolean;
  orderByColumnForDelegates : string;
  orderByTypeForDelegates : string;
  listViewNoOfrecordsPerPageForDelegates : number;
  isDelegateddashBoardActive : boolean;
  isMyApprovalTabActive: boolean;
  isApprovalMultiSelectFilterActive: boolean;
  isApprovalSingleSelectFilterActive: boolean;
}





<div class="card">
    <div class="card-header justify-content-between d-flex border-bottom-0">
        <div class="card-heading">
            <img src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                *ngIf="sectionConfiguration.isCompleted" alt="Completed"
                style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" width="20" height="20" />
            <img src="assets/images/EDP/Workspace/report_problem-24px.svg" class="img-responsive"
                *ngIf="showRequiredWarning" alt="Warning"
                style="vertical-align: text-top;float:left;margin-right: 5px;margin-top:-1px;" width="20" height="20" />
            <a class="card-link">
                <h5 class="edp-font-14 edp-font-color font-weight-bold">File archive information</h5>
            </a>
        </div>
        <div class="blank-card"></div>
        <div class="card-icon" (click)="toggleUp()" *ngIf="!isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/desc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
        <div class="card-icon" (click)="toggleDown()" *ngIf="isImageEnabled">
            <a class="collapsed card-link" data-toggle="collapse">
                <img src="assets/images/EDP/Engagement/asc_order.png" alt="Expand"
                    style="cursor: pointer; padding-bottom: 2px;">
            </a>
        </div>
    </div>
    <div id="collapseOne" class="card-header pt-0 mt-0" data-parent="#accordion" *ngIf="isImageEnabled">
        <div class="mb-2">Responsible for closeout of collaboration spaces:
            <span class="clickableLink font-weight-bold"
                *ngIf="!isEditRFC">{{engagementArchivalInfoViewModel.rfcName}}</span> &nbsp;&nbsp;
            <span class="clickableLink" *ngIf="!isEditRFC && !isEngagementClosed" (click)="editRFC()">Edit</span>
            <span class="nonClickableLink" *ngIf="isEngagementClosed">Edit</span>

            <div class="col-lg-5 pt-1 pl-0" *ngIf="isEditRFC">
                <div class="selectdiv selectRFC mw-100" style="display: -webkit-box;">
                    <select id="drpRFC" name="drpRFC" class="dropdown rounded-0 form-control"
                        [(ngModel)]="selectedTeamMember">
                        <option value="undefined" disabled hidden style="font-size:14px;">Select
                            team member</option>
                        <option *ngFor="let member of teamMembers" [ngValue]=member>
                            {{member.memberName}}</option>
                    </select>
                    <span class="ml-4 cursor-pointer link-text-blue" (click)="CancelRFC()">Cancel</span>
                    <span class="ml-4 font-weight-bold cursor-pointer link-text-blue" (click)="changeRFC()">Save</span>
                </div>
                <div class="form-control-feedback error-color row ml-2" *ngIf="!isValidRFC">
                    <p>Select team member</p>
                </div>
                <div class="row success-msg ml-1" *ngIf="isRFCSaved">
                    <p><strong>Team member saved successfully</strong></p>
                </div>
            </div>
        </div>
        <div>
            <span *ngIf="engagementDueDateExtensionPendingModel == undefined">Closeout of collaboration spaces due:
                <b>
                    {{datepipe.transform(engagementArchivalInfoViewModel.closeoutDueDate, 'MM/dd/yyyy')}}
                </b>
            </span>
            <span *ngIf="engagementDueDateExtensionPendingModel != undefined">
                Closeout of collaboration spaces due:<strong> Extension pending.</strong>
            </span>
            <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span *ngIf="engagementArchivalInfoViewModel.closeoutDueDate && 
                engagementDueDateExtensionPendingModel == undefined &&
                !isEngagementClosed &&
                (isloggedInUserAdmin==='no' || isloggedInUserEngagementMember)"
                class="clickableLink"
                (click)="DisplayEngagementDueDateExtension()">Extend</span>&nbsp;&nbsp;&nbsp;&nbsp; -->

            <!-- <span class="nonClickableLink" *ngIf="isloggedInUserAdmin==='yes' || isEngagementClosed">Extend</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
            <!-- <span *ngIf="engagementDueDateExtensionsHistory?.length > 0" class="clickableLink"
                (click)="engagementDueDateExtensionHistory()">History</span> -->
        </div>

        <div class="row m-0">
            <div class="col-md-12 p-0" style="background-color: #f5f5f5;">
                <div id="engagementDueDateExtensionPendingModelDiv"
                    *ngIf="engagementDueDateExtensionPendingModel != undefined" class="p-3 col-md-10">
                    <span><strong>Extension</strong></span><br>
                    <span>Requested
                        by:<strong>&nbsp;{{engagementDueDateExtensionPendingModel.requestedByName}}</strong></span><br>
                    <span>Requested extension
                        date:<strong>&nbsp;{{datepipe.transform(engagementDueDateExtensionPendingModel.extensionDueDate,
                            'MM/dd/yyyy')}}</strong></span><br>
                    <span class="text-break">Reason for extension:&nbsp;
                        {{engagementDueDateExtensionPendingModel.extensionReason}}</span><br>
                    <span id="approveDenyLinkSpan" class="mt-3" *ngIf="isloggedInUserPSD">
                        <span class="text-decoration-none clickableLink"
                            (click)="approveExtension()">Approve</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span class="text-decoration-none clickableLink" (click)="denyExtension()">Deny</span>
                    </span>
                </div>

                <div id="extensionDenyDiv" class="col-md-9" style="display: none;">

                    <span class="clickableLinkDeny"><b>Deny</b></span><br>

                    <label>Please enter the reason for denying this extension.</label>
                    <textarea class="edp-font-14 form-control" cols="10" rows="8" maxlength="2000"
                        style="background:#f5f5f5;resize:none;border-radius:0px;" id="txtextensionDeny"
                        [(ngModel)]="approveDenyExtensionViewModal.approveDenyExtensionModal.denyReason">
                </textarea>
                    <div class="form-control-feedback error-color row ml-2"
                        *ngIf="isDenyReasonInValid ">
                        <p>Enter deny reason</p>
                    </div>

                    <div class="justify-content-end d-flex  p-0 mt-3 mb-4">
                        <button id="btnextensionDenyCancel" class="btn SecondaryButton w-25  text-center btn-closeout "
                            type="button" (click)="cancelDenyExtension()">
                            Cancel
                        </button>
                        <button id="btnextensionDeny"
                            class="btn PrimaryButton w-25 text-center pl-4 btn-closeout pr-4 ml-3" type="button"
                            (click)="denyExtensionClick()">
                            Deny
                        </button>
                    </div>
                </div>

                <div *ngIf="isExtensionHistory" class="p-3 overflow-auto" style="background-color: #f5f5f5;height: 300px;">

                    <div class="row px-0">
                        <div class="col-md-6 px-3">
                            <span><strong>Extension history</strong></span><br>
                        </div>
                        <div class="col-md-6 px-0 text-right">
                            <span class="clickableLink" (click)="closeHistory()">Close</span>
                        </div>
                    </div>
                    <div *ngFor="let item of engagementDueDateExtensionsApproveDenyList">
                        <div class="row">
                            <div class="col-md-3">Requested on: </div>
                            <div class="col-md-9">
                                <strong>{{datepipe.transform(item.requestedOn,
                                    'MM/dd/yyyy')}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Requested by:</div>
                            <div class="col-md-9"><strong>{{item.requestedByName}}</strong></div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Requested extension date:</div>
                            <div class="col-md-9"><strong>{{datepipe.transform(item.extensionDueDate,
                                    'MM/dd/yyyy')}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Reason for extension:</div>
                            <div class="col-md-9 text-break">{{item.extensionReason}}
                            </div>
                        </div>

                        <div class="row" *ngIf="item.status == 'Approve'">
                            <div class="col-md-3">Result:</div>
                            <div class="col-md-9"><strong>Approved by {{item.approveDenyByName}} on
                                    {{datepipe.transform(item.approveDenyDate, 'MM/dd/yyyy')}}</strong>
                            </div>
                        </div>
                        <div class="row" *ngIf="item.status == 'Deny'">
                            <div class="col-md-3">Result:</div>
                            <div class="col-md-9 text-break"><strong>Denied by {{item.approveDenyByName}} on
                                    {{datepipe.transform(item.approveDenyDate, 'MM/dd/yyyy')}} :
                                </strong>{{item.denyReason}}
                            </div>
                        </div>
                        
                        <!-- <span>Requested on:<strong>&nbsp;{{datepipe.transform(item.requestedOn,
                            'MM/dd/yyyy')}}</strong></span><br>
                    <span>Requested by:<strong>&nbsp;{{item.requestedByName}}</strong></span><br>
                    <span>Requestd extension date:<strong>&nbsp;{{datepipe.transform(item.extensionDueDate,
                            'MM/dd/yyyy')}}</strong></span><br>
                    <span>Reason for extension:&nbsp; {{item.extensionReason}}</span><br>
                    <div *ngIf="item.status == 'Approve'">
                        <span>Result:&nbsp; <strong>Approved by {{item.approveDenyByName}} on
                                {{datepipe.transform(item.approveDenyDate, 'MM/dd/yyyy')}}</strong></span><br>
                    </div>
                    <div *ngIf="item.status == 'Deny'">
                        <span>Result:&nbsp; <strong>Denied by {{item.approveDenyByName}} on
                                {{datepipe.transform(item.approveDenyDate, 'MM/dd/yyyy')}} :
                            </strong>{{item.denyReason}}</span><br>
                    </div> -->
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-md-3">Original closeout due date:</div>
                        <div class="col-md-9"><strong>{{datepipe.transform(originalCloseoutDueDate, 'MM/dd/yyyy')}}</strong></div>
                    </div>
                    <!-- <span>Original closeout due date:&nbsp;
                        <b>{{datepipe.transform(originalCloseoutDueDate, 'MM/dd/yyyy')}}</b>
                    </span><br> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading-screen></app-loading-screen>
<div class="containerDiv">
  <div id="clientSummary" *ngIf="clientId != 0 && client">
    <div class="row mx-0 col-rev-tablet">
      <div class="col-md-12 col-lg-9 px-0">
        <span class="font-weight-bold"> {{client.clientStatus}}</span>
        <div>
          <span class="engName mr-4">{{client.clientName}} </span>
          <span>{{client.clientCode}} </span><span> &nbsp;&nbsp; </span>
          <span *ngIf="client.clientStatus != 'Closed'" class="reviewer" style="cursor:pointer;" (click)="EditClient()">
            Edit client</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == true && isTaxFlag == true && isAuditFlag == true)">
          <span class="advisoryTaxGreen">{{advisory}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{audit}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == false && isTaxFlag == true && isAuditFlag == true)">
          <span>{{advisory}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{audit}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == true && isTaxFlag == false && isAuditFlag == true)">
          <span class="advisoryTaxGreen">{{advisory}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{audit}}</span>
          &nbsp;
          <span>{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == true && isTaxFlag == true && isAuditFlag == false)">
          <span class="advisoryTaxGreen">{{advisory}}</span>
          &nbsp;
          <span>{{audit}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == true && isTaxFlag == false && isAuditFlag == false)">
          <span class="advisoryTaxGreen">{{advisory}}</span>
          &nbsp;
          <span>{{audit}}</span>
          &nbsp;
          <span>{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == false && isTaxFlag == true && isAuditFlag == false)">
          <span>{{advisory}}</span>
          &nbsp;
          <span>{{audit}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == false && isTaxFlag == false && isAuditFlag == true)">
          <span>{{advisory}}</span>
          &nbsp;
          <span class="advisoryTaxGreen">{{audit}}</span>
          &nbsp;
          <span>{{tax}}</span>
        </div>
        <div *ngIf="(isAdvisoryFlag == false && isTaxFlag == false && isAuditFlag == false)">
          <span>{{advisory}}</span>
          &nbsp;
          <span>{{audit}}</span>
          &nbsp;
          <span>{{tax}}</span>
        </div>

      </div>
      <div class="text-right col-md-5 col-lg-3 pr-0 pb-3 shortcut-menu-tablet" *ngIf="iShortcutCount <= 6">
        <app-shortcut></app-shortcut>
      </div>
    </div>
    <div class="row m-0 my-2">
      <div class="col-lg-4 col-md-6 pl-0 border-right-1">
        <div class="" id="scode">
          Type: <span class="font-weight-bold">{{client.publicPrivate}}</span>
        </div>
        <div class="" id="scode">
          Industry: <span class="font-weight-bold">{{client.industryType}}</span>
        </div>
        <div class="" id="scode">
          Structure/entity: <span class="font-weight-bold">{{client.structEntityName}}</span>
        </div>
        <div class="" id="scode">
          Stock exchange: <span class="font-weight-bold">{{client.stockExchange}}</span>
        </div>
        <div class="" id="scode">
          Program: <span class="font-weight-bold">{{client.clientProgram}}</span>
        </div>
        <div class="" id="scode">
          Referral source: <span class="font-weight-bold">{{client.referralSource}}</span>
        </div>
        <div class="" id="scode">
          Fiscal year end month: <span class="font-weight-bold">{{client.fyeMonthName}}</span>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 pr-0">
        <div class="font-weight-bold">Contacts</div>
        <div>
          Client business contact:
          <span class="font-weight-bold"> {{client.businessClientContact}} </span>
        </div>
        <div>
          Billing business contact:
          <span class="font-weight-bold">{{client.clientBillingName}}</span>
        </div>
        <div>
          CMS client administrator:
          <span class="font-weight-bold" id="cmsAdminNamePopup" class="websiteAddress"
            (mousedown)="DisplayContactPopup('cmsAdminNamePopup', client.clientAdministratorEmail)">
            {{client.clientAdministratorContactName}}
          </span>
        </div>
        <div>
          Key relationship leader:
          <!-- <span class="font-weight-bold" id="relationshipPartnerNamePopup" class="websiteAddress"
            (mousedown)="DisplayContactPopup('relationshipPartnerNamePopup', client.relationshipPartnerEMail)">
            {{client.relationshipPartnerName}}
          </span>
          <input type="hidden" id="cardViewtxtRelationshipPartnerNameId" value="" /> -->
        </div>
      </div>
    </div>
    <div class="edp-font-12 mt-3" id="scode">
      <span>Last client activity:</span>
      <span class="pl-1">{{ client.lastActivityDate | date: "MM/dd/yyyy"}}</span>
    </div>
    <div>
      <div *ngIf="!(client.clientCode == '' || client.clientCode == null)">
            <a href="{{client.permLibrarySiteUrl}}-{{client.clientCode}}"   target="_blank">
              
        <img src="../../../../assets/images/EDP/Dashboard/folder_open.svg" style="vertical-align: sub;"
        alt="Perm Library" />
        Perm Library</a>
      </div>
        <div *ngIf="(client.clientCode == '' || client.clientCode == null)">
            
          <img src="../../../../assets/images/EDP/Workspace/report_problem-24px.svg" style="height: 20px; width: 20px;" class="icons-s-doc"
            alt="MessageDialog" data-toggle="tooltip"
            data-html="true"
            title='<div class=&quot;customTooltipPerm&quot;><p>A Perm Library will be created after the client code is received from CMS.</p></div>'>
    Perm Library
</div>
  </div>
    <div role="tabpanel" class="mt-4">
      <div id="engagements" class="tab-pane show active">
        <app-engagement-list-view (passLoading)="showChildLoader($event)"></app-engagement-list-view>
      </div>
    </div>
    <ng-template #InProgressClientSummary>
      <div>
        Loading Client Summary Information...
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="clientId == 0">
  <h3>
    No record found for client.
  </h3>
</div>

<input type="hidden" id="hdnScreeName" value="{{currentScreenName}}" />
<!--popovers/popups-->
<!-- <app-relationship-partner-popup [clientId]="selectedKRPClientId" [screenName]="currentScreenName">
</app-relationship-partner-popup> -->

<!-- <app-client-workspace-popup [clientWorkspaceClientId]="currentClientId"></app-client-workspace-popup> -->

<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>
<app-client-contact-popup [clientId]="selectedKRPClientId"></app-client-contact-popup>

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CountryMaster } from '../model/CountryMaster';
import { AppConfigService } from './appConfigService';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CountryService {
  APIEndPoint: string;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
  }

  GetCountries(): Observable<CountryMaster[]> {
    return this.http.get<CountryMaster[]>(this.APIEndPoint + '/client/countries');
  }
}

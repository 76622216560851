import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { ConfigurationService } from 'src/app/services/configuration.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ClientSearch } from 'src/app/model/Client/ClientSearch';
declare var $;

@Component({
  selector: 'client-smart-search',
  templateUrl: './client-smart-search.component.html',
  styleUrls: ['./client-smart-search.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClientSmartSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ClientSmartSearchComponent),
      multi: true,
    }
  ],
})
export class ClientSmartSearchComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {

  @Input() source: string;
  @Input() name: string;
  @Input('value') val: string;
  @Input() MappedSource: string;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Input() Sequence: number;
  @Input() IsAutoPopulate: boolean;
  @Output() validation: EventEmitter<string> = new EventEmitter<string>();
  @Output() newClientOption: EventEmitter<string> = new EventEmitter<string>();
  private parseError: boolean;
  clientList: ClientSearch[] = [];
  previousSearchTerm: string;
  currentPageNo: number;
  prevPageNo: number;
  selectedValueFlag: boolean;
  filtershowFlag: boolean;
  clientNamePlaceholderText: string = "Enter a client name or client code";
  /**
   *  To handle the click event which is fired outside the component.
   *  So that we can reset the invalid value of control 
   */
  @HostListener('document:click', ['$event'])
  clickoutside(event) {
    if (!this.clientSearchRef.nativeElement.contains(event.target)) {
      // console.log('Click event outside Client Smart Search', this.selectedValueFlag, this.eRef, event, event.target);
      if (!($(event.target).is('#btnClientSearch') || $(event.target).is('#btnClientSearchBig'))
      && this.selectedValueFlag === false&&this.filtershowFlag==true) {
        this.showFilterSection('hide');
        this.filtershowFlag=false;
        this.resetClientSelection();
        this.validation.emit('');
      }
    }
  }

  constructor(
    private configService: ConfigurationService,
    private loadingScreenService: LoadingScreenService,
    private clientSearchRef: ElementRef,

  ) {
    this.previousSearchTerm = '';
    this.selectedValueFlag = false;
  }

  propagateChange: any = () => { };
  onTouched: any = () => { };

  /**
   *  To handle consider the inputted value as valid data by checking the IsAutoPopulate input field.
   *  Especially for screens (i.e. Edit Engagement, Continuance Engagement)
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      console.log('ngOnChange ', propName);
      if (changes.hasOwnProperty('IsAutoPopulate') && propName === 'IsAutoPopulate') {
        const change = changes[propName];
        let currentValue = JSON.stringify(change.currentValue);
        const previousValue = JSON.stringify(change.previousValue);
        console.log('ngOnChange func called in Client Search Component', currentValue, previousValue);
        if (currentValue) {
          currentValue = currentValue.replace(/['"]+/g, '');
          if (currentValue !== previousValue) {
            this.selectedValueFlag = (/true/i).test(currentValue);
          }
        }
      }
    }
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.propagateChange(val);
    this.onTouched();
  }

  ngOnInit() {
    console.log('ngOnInit func called ');
    setTimeout(() => {
      this.InitalizeDataSource();
    }, 300);
  }

  showFilterSection(action: string) {
    const divObj = $('.row.filter-list');
    console.log(divObj);
    if (action === 'hide') {
      this.newClientOption.emit('hide');
      console.log('hide');
      divObj.hide();
    }
    else {
      this.newClientOption.emit('show');
      divObj.show();
      const divFilterObj = $('.filter-select.' + this.MappedSource + this.Sequence + '.p-1');
      divFilterObj.scrollTop(0);
    }
  }

  InitalizeDataSource() {
    console.log(this.name, this.MappedSource, this.Sequence, ' sequence of control');
    this.showFilterSection('hide');
    this.filtershowFlag=false;
  }

  onSearch(filter: string) {
    console.log('onSearch func called');
    if (filter == undefined) {
      var clientName = this.val;
      if (clientName != undefined && clientName != "" && clientName.split('(').length > 0) {
        clientName = clientName.split('(')[0];
        clientName = clientName.trim();
      }

      this.getClientList(clientName);
    } else {
      this.getClientList(filter);
    }
  }

  /**
   *  This function is to return the list of clients based on the passed search term.
   *  Also handle the previous search term for tracking purpose. Clents
   * 
   */
  getClientList(filter: string) {
    let filterLength: number;
    filter = filter.trim();
    if (filter) {
      filterLength = filter.length;
    } else {
      filterLength = 0;
    }

    if (filterLength > 0) {
      this.parseError = true;
      this.loadingScreenService.startLoading();
      let clientSearch = new ClientSearch();
      clientSearch.clientName = filter;
      this.configService.GetClients(clientSearch).subscribe(responseResults => {
        console.log('GetClients func ', responseResults);

        if (responseResults.length === 0) {
          this.validation.emit('Client not found in search list.');
          this.showFilterSection('hide');
          this.filtershowFlag=false;
          this.newClientOption.emit('show');

          this.loadingScreenService.stopLoading();
        } else {

          this.showFilterSection('show');
          this.filtershowFlag=true;
          this.clientList = responseResults;
          this.populateHeadquartes();
          if (this.clientList.length === 0) {
            this.parseError = false;
          } else {
            if (this.clientList[0].clientName.toLowerCase() === filter.toLowerCase()) {
              $('#hdnKey' + this.MappedSource + this.Sequence).val(this.clientList[0].clientId);
              $('#hdnclientOneViewAccountId' + this.MappedSource + this.Sequence).val(this.clientList[0].oneViewAccountId);
            }
            this.showFilterSection('show');
            this.filtershowFlag=true;
            this.selectedValueFlag = false;
          }


          if (this.previousSearchTerm !== filter) {
            this.previousSearchTerm = filter;
          }
          this.validation.emit('');
          this.loadingScreenService.stopLoading();
        }
      });

    }
    else {
      this.resetClientSelection();
      this.showFilterSection('hide');
      this.filtershowFlag=false;
    }
  }

  resetClientSelection() {
    this.parseError = false;
    $('#txtValue' + this.MappedSource + this.Sequence).val('');
    $('#hdnKey' + this.MappedSource + this.Sequence).val('');
    $('#hdnclientOneViewAccountId' + this.MappedSource + this.Sequence).val('');
    this.clientList = [];
    this.change.emit('');
  }

  hideSelect() {
    $('.' + this.source).hide();
    this.showFilterSection('hide');
    this.filtershowFlag=false;
  }

  selectedValue(event, item) {
    this.parseError = false;
    console.log('Selected Value ', item);
    let clientName = (item.clientCode === null) ? item.clientName : item.clientName + ' (' + item.clientCode + ')';
    $('#txtValue' + this.MappedSource + this.Sequence).val(clientName);
    $('#hdnKeyCode' + this.MappedSource + this.Sequence).val(item.clientCode);
    $('#hdnKey' + this.MappedSource + this.Sequence).val(item.clientId);
    $('#hdnclientOneViewAccountId' + this.MappedSource + this.Sequence).val(item.oneViewAccountId);
    if (item.clientStatus === 'Closed') {
      this.validation.emit('Client is closed. Submit a Centralized Billing Share request to re-open the client code before creating a new engagement.');
    }
    this.selectedValueFlag = true;
    this.clientList = [];
    this.showFilterSection('hide');
    this.filtershowFlag=false;
    event.stopPropagation();
    this.change.emit(item.value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  validate(control: AbstractControl): ValidationErrors {
    return (!this.parseError) ? null : {
      required: {
        valid: this.parseError,
      }
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
  }

  cleareErrorMessage() {
    this.validation.emit('');
  }

  onBackspace(event) {
    const currentValue = event.target.value.trim();
    console.log('onBackspace event ', event, currentValue, currentValue.length === 0);
    if (currentValue.length === 0) {
      this.showFilterSection('hide');
      this.filtershowFlag=false;
      this.validation.emit('');
      this.selectedValueFlag = false;
    }
  }
  populateHeadquartes() {
    // customeHeadquarter
    for (let index = 0; index < this.clientList.length; index++) {
      if (this.clientList[index].stateCode != undefined) {
        this.clientList[index].customeHeadquarter = this.clientList[index].city + ', ' + this.clientList[index].stateCode
      } else if (this.clientList[index].city != undefined) {
        this.clientList[index].customeHeadquarter = this.clientList[index].city;
      }
    }
  }

  
}

export class Role {
  id: number;
  role_name: string;
  shortRoleName: string;
}

export enum RoleNames {
  CanadaStaffMember = 'CASM',
  CanadaINDUSConsent = 'CAIC',
  AssignmentAdministrator = 'AA',
  EngagementPartner = 'EP',
  KeyRelationshipPartner = 'KRP',
  PracticeLeader = 'PL',
  ReviewingProfessional = 'RP',
  StaffMember = 'SM',
  SupportManager = 'SMgr',
  SystemAdministrator = 'SA',
  CMSEPPMD = 'CMSEP',
  Creator = 'C',
  ClientAdministrator = 'CLA',
  INDUSConsent = 'IC',
  GTUSINDUSALL = 'GIA',
  AssignmentEQCR = 'EQCR',
  NotificationOnlyProfessional = 'NOP',
  GTAgreementOwner = 'AO',
  Delegate = 'DL',
  ICANDGIA = 'ICGIA',
  INDUSStaffingManagers = 'ISM',
  QualityGroup = 'QG',
  AuditPartner = 'AP'
}

export enum EnumRoles {
  AssignmentAdministrator = 'Administrator',
  EngagementPartner = 'Engagement Partner',
  KeyRelationshipPartner = 'Key Relationship Partner',
  PracticeLeader = 'Practice Leader',
  ReviewingProfessional = 'Reviewing Professional',
  StaffMember = 'Staff Member',
  SupportManager = 'Support Manager',
  SystemAdministrator = 'System Administrator',
  CMSEPPMD = 'CMS EPPMD',
  Creator = 'Creator',
  ClientAdministrator = 'CMS Client Administrator',
  Guest = 'Guest',
  TeamMember = 'Team member',
  EQCR = 'Eng qual control reviewer (EQCR)',
  NotificationOnlyProfessional = 'Notification only professionals',
  Delegate = 'Delegate',
  AuditPartner = 'Audit Partner'
}

export enum RevievingProfessionalType {
  Auto = "Auto",
  AutoNotificationOnlyProfessional = "AutoNOP",
  Manual = "Manual"
}
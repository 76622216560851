import { Component, Input, OnInit } from '@angular/core';
import { CloseoutSections, EngagementCloseoutConfiguration } from 'src/app/model/EngagementListModel';

@Component({
  selector: 'app-engagement-closeout-insight-metrix',
  templateUrl: './engagement-closeout-insight-metrix.component.html',
  styleUrls: ['./engagement-closeout-insight-metrix.component.css']
})
export class EngagementCloseoutInsightMetrixComponent implements OnInit {

  isImageEnabled: boolean = false;
  currentState: string = 'collapse';
  @Input()
  sectionConfiguration: EngagementCloseoutConfiguration;
  
  constructor() { }

  ngOnInit(): void {
    if (this.sectionConfiguration.isDefaultOpen) {
      this.toggleDown();
    }
  }
  
  toggleUp(flag: boolean) {
    this.isImageEnabled = !this.isImageEnabled;
    if (!flag) {
      this.currentState = 'expand';
    }
  }

  toggleDown() {
    this.isImageEnabled = !this.isImageEnabled;
    this.currentState = 'collapse';
  }

}

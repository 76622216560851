
export class MembersList {
  displayName: string;
  mail: string;
  mobilePhone: string;
  businessPhones: string;
  jobTitle: string;
  lineOfBusiness: string;
  serviceLine: string;
  location: string;
  jobCode: string;
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClientVoiceApiLog, ClientVoiceSurveyOptOutRequest, ClientVoiceSurveyRequest } from 'src/app/model/ClientVoice/ClientVoiceRequestsModel';
import { ClientVoiceContact } from 'src/app/model/ClientVoice/ClientVoiceResponsesModel';
import { CollaborationSiteMembers } from 'src/app/model/CollaborationSite';
import { EngagementClientVoiceDetailViewModel, EngagementClientVoiceUserAccess, PreviousClientVoiceSurveyRequestModel } from 'src/app/model/Engagement/EngagementClientVoice';
import { ClientVoiceTeamMember, ClientVoiceTeamViewMember, EngagementClientVoiceDelegateViewModel, EngagementClientVoiceOptViewModel, EngagementClientVoiceUIViewModel, EngagementClientVoiceViewModel, EngagementStatus, UpdateEngagementCloseoutConfigurationModel } from 'src/app/model/Engagement/Engagements';

import { TeamMemberRole } from 'src/app/model/Engagement/TeamMemberRole';
import { ClientVoiceTeamMemberViewModel, CloseoutSections, EngagementCloseoutConfiguration } from 'src/app/model/EngagementListModel';
import { EngagementService } from 'src/app/services/EngagementService';
import { ClientvoiceService } from 'src/app/services/Integration/clientvoice.service';
import { LogService } from 'src/app/services/log.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { RoleNames } from 'src/app/model/Role';
declare var $;

@Component({
  selector: "app-engagement-closeout-client-voice-survey",
  templateUrl: "./engagement-closeout-client-voice-survey.component.html",
  styleUrls: ["./engagement-closeout-client-voice-survey.component.css"],
})
export class EngagementCloseoutClientVoiceSurvey implements OnInit, OnDestroy {
  selectEngagementId: number;
  selectEngagementName: string;
  @Input() engagementName: string;
  @Input() collaborationSiteMembersList: CollaborationSiteMembers[];
  @Input() clientCode: string;
  @Input() clientName: string;
  @Input() engagementPartner: string;
  @Input() engagementPartnerName: string;
  @Input() engStatus: string;
  @Input() isSuccessorFirmEnabled: string;
  @Input() isEngagementClosed: boolean;
  @Input() isEngagementCloseoutDisable: boolean;
  @Input() isloggedInUserAdmin: string = 'no';
  @Output() engCloseOut: EventEmitter<string> = new EventEmitter<string>();

  @Input() set engagementId(engid: number) {
    if (engid != undefined && engid != null && engid > 0) {
      this.selectEngagementId = engid;
      console.log("selectEngagementId : ", this.selectEngagementId);
    }
  }

  clientVoiceTeamMemberViewModel: ClientVoiceTeamMemberViewModel = new ClientVoiceTeamMemberViewModel();
  engagementClientVoiceDetailViewModel: EngagementClientVoiceDetailViewModel = new EngagementClientVoiceDetailViewModel(); clientVoiceTeamMemberList: ClientVoiceTeamMember[] = [];
  engagementClientVoiceUIViewModel: EngagementClientVoiceUIViewModel = new EngagementClientVoiceUIViewModel();
  engagementClientVoiceUserAccessList: EngagementClientVoiceUserAccess[] = [];
  manualRecipient: ClientVoiceTeamViewMember = new ClientVoiceTeamViewMember();
  delegateMember: CollaborationSiteMembers = new CollaborationSiteMembers();
  clientVoiceRecipientList: ClientVoiceTeamMember[] = [];
  uniqueDelegateMembers: CollaborationSiteMembers[] = [];
  orgUniqueDelegateMembers: CollaborationSiteMembers[] = [];
  clientVoiceSurveyRequest: ClientVoiceSurveyRequest = new ClientVoiceSurveyRequest();
  clientVoiceContact: ClientVoiceContact[] = [];
  dbClientVoiceTeamMemberCount: number;
  userObject: any;
  surveyDateCtrlId: string;
  isImageEnabled: boolean = false;
  isImageEnabled1: boolean = false;
  isImageEnabled2: boolean = false;
  clientVoiceAPISuccessCode: string = '200';

  teamMemberRoleList: TeamMemberRole[] = [];
  clientVoiceApiLog: ClientVoiceApiLog = new ClientVoiceApiLog();
  isSurveryOptedCompleted: boolean = false;
  dateCompareResult: number;
  disableWeekendValue: boolean = true;
  previousClientVoiceSurveyRequestModel: PreviousClientVoiceSurveyRequestModel = new PreviousClientVoiceSurveyRequestModel();
  clientVoiceSurveyOptOutRequest: ClientVoiceSurveyOptOutRequest = new ClientVoiceSurveyOptOutRequest();
  divType: string = 'Default';
  clearSurvey: boolean = false;
  isUserEPPMDOrDelegate: boolean = false;
  public get EngagementStatus(): typeof EngagementStatus {
    return EngagementStatus;
  }

  @Output() collapseEvent: EventEmitter<string> = new EventEmitter<string>();
  currentState: string;

  @Input()
  sectionConfiguration: EngagementCloseoutConfiguration;

  @Input()
  lastSurveySentDateForClient: Date;

  @Input()
  isRequiredToSendClientVoiceSurvey: boolean = false;

  showRequiredWarning: boolean = false;

  constructor(private engagementService: EngagementService, private logService: LogService,
    private clientvoiceService: ClientvoiceService, private datePipe: DatePipe,
    private loadingScreenService: LoadingScreenService) {
    this.currentState = 'collapse';
  }

  ngOnInit(): void {

    this.surveyDateCtrlId = 'surveyDate';
    this.disableWeekendValue = true;
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    this.IsLoggedinUserSystemAdmin();
    this.getClientContactsByEngId(this.selectEngagementId);
    this.getEngagementClientVoiceDetailByEngId(this.selectEngagementId)
    this.objectInitialization();
    if (!this.isSuccessorFirmEnabled) {
      this.GetClientVoiceContacts(this.clientCode);
    }
    this.getTeamMemberRoles();
    if (this.sectionConfiguration.isDefaultOpen) {
      this.toggleDown();
    }
  }

  callEngagementClosure() {
    this.engCloseOut.emit('');
  }

  toggleUp(flag: boolean) {
    if (this.engStatus == EngagementStatus.EngVoided) {
      this.isSurveryOptedCompleted = true;
    } else {
      this.setSurveyCompleted();
    }
    $('#collapseThree').show();
    this.currentState = 'expand';
    this.isImageEnabled = true;
    if (!flag) {
      this.currentState = 'expand';
      this.collapseEvent.emit(this.currentState);
    }
  }

  toggleUpDown(flag: boolean) {
    this.isImageEnabled = false;
    $('#collapseThree').hide();
    this.currentState = 'collapse';
  }

  resetEngClientVoiceUIViewModel() {
    //Reset values
    this.engagementClientVoiceUIViewModel.isSurveySaved = false;
    this.engagementClientVoiceUIViewModel.optOutReasonSaved = false;
    this.engagementClientVoiceUIViewModel.isValidSurveyDate = true;
    this.engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList = false;
    this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList = false;
    this.engagementClientVoiceUIViewModel.isValidrecipientEmail = true;
    this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.forEach((member) => {
      member.isRowInvalid = false;
    });
  }

  toggleDown() {
    this.isImageEnabled = false;
    $('#collapseThree').hide();
    this.resetEngClientVoiceUIViewModel();

    if (this.clearSurvey) {
      this.engagementClientVoiceUIViewModel.isSurveyOptOut = true;
      this.isSurveryOptedCompleted = true;
    }
    this.currentState = 'collapse';
  }

  setSurveyCompleted() {
    var findUser =
      this.engagementClientVoiceUserAccessList.find(user => user.UserEmail ==
        this.userObject.loggedInUserEmail.toLowerCase().trim());
    if (findUser || this.isloggedInUserAdmin == 'yes') {
      return true;
    } else {
      //if Login user is not EEPMD or client voice delegate then make all functionality READ-ONLY
      this.isSurveryOptedCompleted = true;
      return false;
    }
  }


  isUserValid() {
    var findUser =
      this.engagementClientVoiceUserAccessList.find(user => user.UserEmail ==
        this.userObject.loggedInUserEmail.toLowerCase().trim());
    if (findUser) {
      return true;
    } else {
      return false;
    }
  }

  getTeamMemberRoles() {
    this.loadingScreenService.startLoading();
    this.engagementService.GetTeamMemberRoles().subscribe(
      res => {
        this.loadingScreenService.stopLoading();
        this.teamMemberRoleList = res;
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.logService.error(error);
      });
  }

  objectInitialization() {

    if (this.userObject.loggedInUserEmail.toLowerCase().trim() == this.engagementPartner.toLowerCase().trim()) {
      this.engagementClientVoiceUIViewModel.isloggedInUserSameAsEPPMD = true;
    } else {
      this.engagementClientVoiceUIViewModel.isloggedInUserSameAsEPPMD = false;
    }
    let engagementClientVoiceUserAccess: EngagementClientVoiceUserAccess = new EngagementClientVoiceUserAccess();
    engagementClientVoiceUserAccess.UserEmail = this.engagementPartner.toLowerCase().trim();
    engagementClientVoiceUserAccess.UserName = this.engagementPartnerName.toLowerCase().trim();

    if (!this.engagementClientVoiceUserAccessList.find(x => x.UserEmail.toLowerCase().trim() == this.engagementPartner.toLowerCase().trim())) {
      this.engagementClientVoiceUserAccessList.push(engagementClientVoiceUserAccess);
    }
    this.isUserEPPMDOrDelegate = this.isUserValid();
    this.resetManualRecipient();

    this.delegateMember = {
      collaborationSiteMembersId: 0,
      collaborationSiteId: 0,
      memberName: '',
      memberEmailId: '',
      memberRoleId: 0,
      memberPermissionId: 0,
      keyMemberRoleId: 0,
      isKeyTeamMember: false,
      description: ''
    }
    this.orgUniqueDelegateMembers = [];
    this.uniqueDelegateMembers =
      [...new Set(this.collaborationSiteMembersList.map(obj => obj.memberEmailId))].map(member => {
        return this.collaborationSiteMembersList.find(obj => obj.memberEmailId === member)
      }
      )
    console.log('Befor this.uniqueDelegateMembers', this.uniqueDelegateMembers);
    this.uniqueDelegateMembers.forEach(collbMember => {
      let collaborationSiteMembers: CollaborationSiteMembers = new CollaborationSiteMembers();
      collaborationSiteMembers.collaborationSiteMembersId = collbMember.collaborationSiteId;
      collaborationSiteMembers.collaborationSiteId = collbMember.collaborationSiteId;
      collaborationSiteMembers.memberName = collbMember.memberName;
      collaborationSiteMembers.memberEmailId = collbMember.memberEmailId;
      collaborationSiteMembers.memberRoleId = collbMember.memberRoleId;
      collaborationSiteMembers.memberPermissionId = collbMember.memberPermissionId;
      this.orgUniqueDelegateMembers.push(JSON.parse(JSON.stringify(collaborationSiteMembers)));
    })

    // remove EPPMD from team member list 

    this.uniqueDelegateMembers.forEach(member => {
      if (!this.engagementClientVoiceUserAccessList.find(x => x.UserEmail.toLowerCase().trim() == member.memberEmailId.toLowerCase().trim())) {
        let engagementClientVoiceUserAccess: EngagementClientVoiceUserAccess = new EngagementClientVoiceUserAccess();
        engagementClientVoiceUserAccess.UserEmail = member.memberEmailId.toLowerCase().trim();
        engagementClientVoiceUserAccess.UserName = member.memberName.toLowerCase().trim();
        this.engagementClientVoiceUserAccessList.push(engagementClientVoiceUserAccess);
      }
    })
    console.log('engagementClientVoiceUserAccessList', this.engagementClientVoiceUserAccessList);

    var removeIndex = this.uniqueDelegateMembers.map(function (item) { return item.memberEmailId; }).indexOf(this.engagementPartner);
    this.uniqueDelegateMembers.splice(removeIndex, 1);

    console.log('After this.uniqueDelegateMembers', this.uniqueDelegateMembers);
    this.engagementClientVoiceUIViewModel.isSurveyOptOut = false;
  }

  resetManualRecipient() {
    this.manualRecipient = {
      memberEmail: '',
      firstName: '',
      lastName: '',
      memberTitle: '',
      contactGuid: '',
      isValidMemberEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidMemberEmailText: true,
      isRecipientExistsInList: false
    }
  }

  getClientContactsByEngId(engId: number) {
    this.loadingScreenService.startLoading();
    return this.engagementService.GetClientContactsByEngId(engId)
      .subscribe(
        result => {
          this.loadingScreenService.stopLoading();
          this.clientVoiceTeamMemberViewModel = result;
          this.clientVoiceTeamMemberViewModel.clientVoiceTeamMemberList.forEach(member => {
            let clientVoiceTeamMember: ClientVoiceTeamMember = new ClientVoiceTeamMember();
            clientVoiceTeamMember.memberEmail = member.memberEmail;
            clientVoiceTeamMember.firstName = member.firstName;
            clientVoiceTeamMember.lastName = member.lastName;
            clientVoiceTeamMember.memberTitle = member.memberTitle;
            clientVoiceTeamMember.contactGuid = '';
            clientVoiceTeamMember.editMemberEmail = JSON.parse(JSON.stringify(member.memberEmail));
            clientVoiceTeamMember.isRecipientsEdit = false;
            clientVoiceTeamMember.editFirstName = JSON.parse(JSON.stringify(member.firstName));
            clientVoiceTeamMember.editLastName = JSON.parse(JSON.stringify(member.lastName));
            this.clientVoiceTeamMemberList.push(JSON.parse(JSON.stringify(clientVoiceTeamMember)));
          })
          this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers = this.clientVoiceTeamMemberList;
        },
        error => {
          this.engagementClientVoiceUIViewModel = null;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  removeClientVoiceTeamMember(memberIndex: number) {
    if (memberIndex >= 0) {
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.splice(memberIndex, 1);
    }

    if (this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.length == 0) {
      this.engagementClientVoiceUIViewModel.isValidrecipientCount = false;
    } else {
      this.engagementClientVoiceUIViewModel.isValidrecipientCount = true;
    }
    if (this.checkisValidrecipientEmailAndNofity()) { //  check client Voice TeamMember has any blank email ID
      if (!this.checkDuplicateEmailAndNotify()) { //Check Duplicate Email And Notify
        this.checkFirstNameLastNameBlankAndNofity(); //Check check First name and Last name Blank       
      }
    };
  }

  addRecipient() {
    this.manualRecipient.isRecipientExistsInList = false;
    this.engagementClientVoiceUIViewModel.isDrpRecipientExistsInList = false;
    if ($('#drprecipient').val() == null || $('#drprecipient').val() == '') {
      this.engagementClientVoiceUIViewModel.isValidRecipient = false;
    }
    else {
      this.engagementClientVoiceUIViewModel.isValidRecipient = true;
      let drprecipientContactGuid: string;
      drprecipientContactGuid = $('#drprecipient').val();
      var recipientObj = this.clientVoiceRecipientList.find(recipient => recipient.contactGuid == drprecipientContactGuid);
      if (recipientObj) {
        if (!this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.find(o => o.contactGuid.toLowerCase().trim() === drprecipientContactGuid.toLowerCase().trim())) {
          this.engagementClientVoiceUIViewModel.isDrpRecipientExistsInList = false;
          //trim all string properties
          for (var prop in recipientObj) {
            var value = recipientObj[prop], type = typeof value;
            if (value != null && (type == "string") && recipientObj.hasOwnProperty(prop)) {
              recipientObj[prop] = recipientObj[prop].trim();
            }
          }
          this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.unshift(JSON.parse(JSON.stringify(recipientObj)));
        } else {
          this.engagementClientVoiceUIViewModel.isDrpRecipientExistsInList = true;
        }
      }
      if (this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.length == 0) {
        this.engagementClientVoiceUIViewModel.isValidrecipientCount = false;
      } else {
        this.engagementClientVoiceUIViewModel.isValidrecipientCount = true;
      }
    }

  }

  addRecipientManually() {
    this.manualRecipient.isRecipientExistsInList = false;
    this.engagementClientVoiceUIViewModel.isDrpRecipientExistsInList = false;
    if (this.manualRecipient.memberEmail.trim() == '') {
      this.manualRecipient.isValidMemberEmail = false;
    } else {
      this.manualRecipient.isValidMemberEmail = true;
    }

    if (this.manualRecipient.firstName.trim() == '') {
      this.manualRecipient.isValidFirstName = false;
    } else {
      this.manualRecipient.isValidFirstName = true;
    }

    if (this.manualRecipient.lastName.trim() == '') {
      this.manualRecipient.isValidLastName = false;
    } else {
      this.manualRecipient.isValidLastName = true;
    }

    if (this.manualRecipient.isValidMemberEmail &&
      this.manualRecipient.isValidFirstName &&
      this.manualRecipient.isValidLastName) {
      this.manualRecipient.isValidMemberEmailText = this.validateEmail(this.manualRecipient.memberEmail);
      if (this.manualRecipient.isValidMemberEmailText) {
        if (!this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.find(o => o.memberEmail.toLowerCase().trim() === this.manualRecipient.memberEmail.toLowerCase().trim())) {
          this.manualRecipient.isRecipientExistsInList = false;
          //trim all string properties
          for (var prop in this.manualRecipient) {
            var value = this.manualRecipient[prop], type = typeof value;
            if (value != null && (type == "string") && this.manualRecipient.hasOwnProperty(prop)) {
              this.manualRecipient[prop] = this.manualRecipient[prop].trim();
            }
          }
          let tempManualRecipient: ClientVoiceTeamMember = new ClientVoiceTeamMember();
          tempManualRecipient.memberEmail = this.manualRecipient.memberEmail;
          tempManualRecipient.firstName = this.manualRecipient.firstName;
          tempManualRecipient.lastName = this.manualRecipient.lastName;
          tempManualRecipient.memberTitle = this.manualRecipient.memberTitle;
          tempManualRecipient.contactGuid = this.manualRecipient.contactGuid;
          tempManualRecipient.editMemberEmail = this.manualRecipient.memberEmail;
          tempManualRecipient.editFirstName = this.manualRecipient.firstName;
          tempManualRecipient.editLastName = this.manualRecipient.lastName;
          // Add manual Recipient at top position
          this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.unshift(tempManualRecipient);
          this.objectInitialization();
        }
        else {
          this.manualRecipient.isRecipientExistsInList = true;
        }
      }

      if (this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.length == 0) {
        this.engagementClientVoiceUIViewModel.isValidrecipientCount = false;
      } else {
        this.engagementClientVoiceUIViewModel.isValidrecipientCount = true;
      }
    }
  }

  resetClientVoiceTeamMembers() {
    this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers = [];
    let resetclientVoiceTeamMemberList: ClientVoiceTeamMember[] = [];
    this.clientVoiceTeamMemberViewModel.clientVoiceTeamMemberList.forEach(member => {
      let clientVoiceTeamMember: ClientVoiceTeamMember = new ClientVoiceTeamMember();
      clientVoiceTeamMember.memberEmail = member.memberEmail;
      clientVoiceTeamMember.firstName = member.firstName;
      clientVoiceTeamMember.lastName = member.lastName;
      clientVoiceTeamMember.memberTitle = member.memberTitle;
      clientVoiceTeamMember.editMemberEmail = member.memberEmail;
      clientVoiceTeamMember.editFirstName = member.firstName;
      clientVoiceTeamMember.editLastName = member.lastName;
      clientVoiceTeamMember.contactGuid = '';
      clientVoiceTeamMember.isRecipientsEdit = false;
      resetclientVoiceTeamMemberList.push(JSON.parse(JSON.stringify(clientVoiceTeamMember)));
    })
    this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers = resetclientVoiceTeamMemberList;
    this.engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList = false;
    this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList = false;
    this.engagementClientVoiceUIViewModel.isValidrecipientEmail = true;
    if (this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.length == 0) {
      this.engagementClientVoiceUIViewModel.isValidrecipientCount = false;
    } else {
      this.engagementClientVoiceUIViewModel.isValidrecipientCount = true;
    }
  }


  formatDate(date: any) {
    if ((date == null) || (date == undefined)) {
      this.engagementClientVoiceUIViewModel.surveyDate = null;
      return true;
    } else {
      const formattedDate = new Date(date);
      const d = formattedDate.getDate();
      let m = formattedDate.getMonth();
      m += 1;  // JavaScript months are 0-11
      const y = formattedDate.getFullYear();
      const finalFormattedDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
      if (finalFormattedDate === '1/1/1' || finalFormattedDate === '1/1/2001' ||
        finalFormattedDate == '0/0/0' || finalFormattedDate.length <= 0 || y <= 1990
        || isNaN(d) == true || isNaN(m) == true || isNaN(y) == true) {
        this.engagementClientVoiceUIViewModel.surveyDate = null;
        return true;
      } else {
        this.engagementClientVoiceUIViewModel.surveyDate = date;
        return false;
      }
    }
  }

  changeDelegate() {
    this.engagementClientVoiceUIViewModel.isDelegateSaved = false;
    this.resetManualRecipient();
  }

  changeRecipient() {
    this.engagementClientVoiceUIViewModel.isDelegateSaved = false;
    this.manualRecipient.isRecipientExistsInList = false;
    this.engagementClientVoiceUIViewModel.isDrpRecipientExistsInList = false;
    this.resetManualRecipient();
  }

  /**
     * Compares two Date objects and returns e number value that represents
     * the result:
     * 0 if the two dates are equal.
     * 1 if the first date is greater than second.
     * -1 if the first date is less than second.
     * @param date1 First date object to compare.
     * @param date2 Second date object to compare.
     */
  private compareDate(date1: string, date2: string): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) {
      return 0;
    }

    // Check if the first is greater than second
    if (d1 > d2) {
      return 1;
    }

    // Check if the first is less than second
    if (d1 < d2) {
      return -1;
    }
  }


  sendSurvey() {
    //Set default values
    this.engagementClientVoiceUIViewModel.isSurveyDateFutureDt = true;
    this.engagementClientVoiceUIViewModel.isValidSurveyDate = true;

    if ($('.surveyDate').val() == '') {
      this.engagementClientVoiceUIViewModel.isValidSurveyDate = false;
    }
    else {
      this.engagementClientVoiceUIViewModel.isValidSurveyDate = true;
      var surveyDt = $('.surveyDate').val();
      const currentDate = new Date().toDateString();
      this.dateCompareResult = this.compareDate(surveyDt, currentDate);
      if (this.dateCompareResult <= 0) {
        this.engagementClientVoiceUIViewModel.isSurveyDateFutureDt = false;
      } else {
        this.engagementClientVoiceUIViewModel.isSurveyDateFutureDt = true;
      }
      if (surveyDt === null || surveyDt === 'surveyDt' || surveyDt == '') {
        this.engagementClientVoiceUIViewModel.surveyDate = null;
      } else {
        this.engagementClientVoiceUIViewModel.surveyDate = surveyDt
      }
    }
    if (this.engagementClientVoiceUIViewModel.engagement_ChangedName == '') {
      this.engagementClientVoiceUIViewModel.isValidEngName = false;
    }
    else {
      this.engagementClientVoiceUIViewModel.isValidEngName = true;
    }
    if (this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.length == 0) {
      this.engagementClientVoiceUIViewModel.isValidrecipientCount = false;
    } else {
      this.engagementClientVoiceUIViewModel.isValidrecipientCount = true;
    }
    if (this.engagementClientVoiceUIViewModel.isValidrecipientCount &&
      this.engagementClientVoiceUIViewModel.isValidEngName &&
      this.engagementClientVoiceUIViewModel.isValidSurveyDate &&
      this.engagementClientVoiceUIViewModel.isSurveyDateFutureDt) {
      this.resetUIViewModelVAlidationFlag();
      if (this.checkisValidrecipientEmailAndNofity()) { //check client Voice TeamMember has any blank email ID
        if (!this.checkDuplicateEmailAndNotify()) { //Check Duplicate Email And Notify
          if (!this.checkFirstNameLastNameBlankAndNofity()) { //Check check First name and Last name Blank
            this.saveEngagementClientVoice();
          }
        };
      }
    }
  }

  resetUIViewModelVAlidationFlag() {
    this.engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList = false;
    this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList = false;
    this.engagementClientVoiceUIViewModel.isValidrecipientEmail = true;
    this.engagementClientVoiceUIViewModel.isValidrecipientCount = true;
  }

  checkisValidrecipientEmailAndNofity() {
    this.engagementClientVoiceUIViewModel.isValidrecipientEmail = true;
    this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.forEach((member) => {
      if (member.memberEmail == "" || member.memberEmail == null) {
        member.isRowInvalid = true;
        this.engagementClientVoiceUIViewModel.isValidrecipientEmail = false;
      } else {
        member.isRowInvalid = false;
      }
    });
    return this.engagementClientVoiceUIViewModel.isValidrecipientEmail;
  }

  checkFirstNameLastNameBlankAndNofity() {
    this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList = false;
    if (this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.find(x => x.firstName == "" || x.lastName == "")) {
      this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList = true;
    }

    if (this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList) {
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.forEach((member) => {
        if (member.firstName == "" || member.lastName == "") {
          member.isRowInvalid = true;
        } else {
          member.isRowInvalid = false;
        }
      });
    }
    return this.engagementClientVoiceUIViewModel.isFirstOrLastNameBlankInList;
  }

  checkDuplicateEmailAndNotify() {
    //Reset values
    this.engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList = false;
    this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.forEach((member) => {
      member.isRowInvalid = false;
    });

    // check email exists
    this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.forEach((outerMember, outetIndx) => {
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.forEach((innerMember, innerIndx) => {
        if (outetIndx != innerIndx && outerMember.memberEmail == innerMember.memberEmail) {
          this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[outetIndx].isRowInvalid = true;
          this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[innerIndx].isRowInvalid = true;
          this.engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList = true;
        }
      });
    });
    return this.engagementClientVoiceUIViewModel.isDuplicateEmailExistsInList;
  }

  saveEngagementClientVoice() {
    this.loadingScreenService.startLoading();
    let engagementClientVoiceViewModel: EngagementClientVoiceViewModel = new EngagementClientVoiceViewModel();
    this.engagementClientVoiceUIViewModel.isSurveyOptOut = false;
    this.engagementClientVoiceUIViewModel.optOutReason = '';
    this.engagementClientVoiceUIViewModel.optOutReasonSaved = false;
    engagementClientVoiceViewModel.engagementId = this.selectEngagementId;
    engagementClientVoiceViewModel.loggedInUser = this.userObject.loggedInUserEmail;
    engagementClientVoiceViewModel.loggedInUserFullName = this.userObject.loggedInUserFullName;
    engagementClientVoiceViewModel.engagement_ChangedName = this.engagementClientVoiceUIViewModel.engagement_ChangedName
    engagementClientVoiceViewModel.surveyDate = this.engagementClientVoiceUIViewModel.surveyDate;
    engagementClientVoiceViewModel.isSurveyOptOut = this.engagementClientVoiceUIViewModel.isSurveyOptOut;
    engagementClientVoiceViewModel.optOutReason = this.engagementClientVoiceUIViewModel.optOutReason;
    engagementClientVoiceViewModel.delegateName = '';
    engagementClientVoiceViewModel.delegateEmailId = '';
    engagementClientVoiceViewModel.sectionShortName = this.sectionConfiguration.code;
    engagementClientVoiceViewModel.clientVoiceTeamMembers = this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers;

    if (this.clearSurvey) { this.clearSurvey = false }

    this.engagementService.saveEngagementClientVoice(engagementClientVoiceViewModel).subscribe(
      updateEngResult => {
        if (updateEngResult === 'Success') {
          this.engagementClientVoiceUIViewModel.isSurveySaved = true;
          this.SendForClientSurvey();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.engagementClientVoiceUIViewModel.isSurveySaved = false;
        console.error('Client Consent', error);
        this.logService.log("error in saving Engagement Client Voice");
      });
  }

  saveEngagementClientVoiceDelegate() {
    this.engagementClientVoiceUIViewModel.isDelegateSaved = false;
    let engagementClientVoiceDelegateViewModel: EngagementClientVoiceDelegateViewModel = new EngagementClientVoiceDelegateViewModel();
    engagementClientVoiceDelegateViewModel.engagementId = this.selectEngagementId;
    engagementClientVoiceDelegateViewModel.delegateEmailId = this.engagementClientVoiceUIViewModel.delegateEmailId;
    engagementClientVoiceDelegateViewModel.delegateName = this.engagementClientVoiceUIViewModel.delegateName;
    engagementClientVoiceDelegateViewModel.loggedInUser = this.userObject.loggedInUserEmail;
    engagementClientVoiceDelegateViewModel.loggedInUserFullName = this.userObject.loggedInUserFullName;
    engagementClientVoiceDelegateViewModel.engagementName = this.engagementName;

    this.engagementService.saveEngagementClientVoiceDelegate(engagementClientVoiceDelegateViewModel).subscribe(
      updateEngDelegateResult => {
        if (updateEngDelegateResult == 'Success') {
          this.engagementClientVoiceUIViewModel.isDelegateSaved = true;

          let engagementClientVoiceUserAccess: EngagementClientVoiceUserAccess = new EngagementClientVoiceUserAccess();
          engagementClientVoiceUserAccess.UserEmail = this.engagementClientVoiceUIViewModel.delegateEmailId.toLowerCase().trim();
          engagementClientVoiceUserAccess.UserName = this.engagementClientVoiceUIViewModel.delegateName.toLowerCase().trim();

          if (!this.engagementClientVoiceUserAccessList.find(x => x.UserEmail.toLowerCase().trim() == engagementClientVoiceUserAccess.UserEmail.toLowerCase().trim())) {
            this.engagementClientVoiceUserAccessList.push(engagementClientVoiceUserAccess);
          }
          this.isUserEPPMDOrDelegate = this.isUserValid();
        }
      },
      error => {
        console.error('Client Consent', error);
        this.logService.log("error while saving engagement client voice delegate ");
      });
  }

  displayClientOption(event) {
    if (event === 'hide') {
      $('#CreateNewClient').hide();
    } else {
      $('#CreateNewClient').show();
    }
  }

  showHideClientvoiceDiv() {
    //All to send survey even Eng status is Closed 

    // if (this.engStatus === EngagementStatus.EngClosed) {
    // //  this.isSurveryOptedCompleted = true;
    // } else {
    if (this.setSurveyCompleted()) {
      this.clearSurvey = true;
      this.engagementClientVoiceUIViewModel.isSurveyOptOut = false;
      this.isSurveryOptedCompleted = false;
    }
    //}
    this.isImageEnabled = !this.isImageEnabled;
    $('#collapseThree').show();
  }

  showHideclientvoiceOptDiv_old(flag: boolean) {
    const clientvoiceOptDiv = 'oncheck_clientvoice';
    const clientvoiceSurveyDiv = 'withoutcheck_clientvoice';
    if (flag) {
      if ($('#' + clientvoiceOptDiv).is(':hidden')) {
        $('#' + clientvoiceOptDiv).show();
        $('#' + clientvoiceSurveyDiv).hide();
      }
    } else {
      if ($('#' + clientvoiceOptDiv).is(':visible')) {
        $('#' + clientvoiceOptDiv).hide();
        $('#' + clientvoiceSurveyDiv).show();
      }
    }
  }

  showHideclientvoiceOptDiv(flag: boolean) {
    this.engagementClientVoiceUIViewModel.isDelegateSaved = false;
    if (flag) {
      this.engagementClientVoiceUIViewModel.isSurveyOptOut = flag;
    } else {
      this.engagementClientVoiceUIViewModel.isSurveyOptOut = flag;
      this.engagementClientVoiceUIViewModel.optOutReason = '';
    }
  }

  toggleDiv() {
    this.engagementClientVoiceUIViewModel.isDelegateSaved = false;
    this.engagementClientVoiceUIViewModel.optOutReasonSaved = false;
  }

  SurveyOptSend() {
    if (this.engagementClientVoiceUIViewModel.optOutReason == null ||
      this.engagementClientVoiceUIViewModel.optOutReason.trim() == '') {
      this.engagementClientVoiceUIViewModel.isValidoptOutReason = false;
    } else {
      this.engagementClientVoiceUIViewModel.isValidoptOutReason = true;
      this.saveEngagementClientVoiceOpt();
    }
  }

  saveEngagementClientVoiceOpt() {
    let engagementClientVoiceOptViewModel: EngagementClientVoiceOptViewModel = new EngagementClientVoiceOptViewModel();
    engagementClientVoiceOptViewModel.engagementId = this.selectEngagementId;
    engagementClientVoiceOptViewModel.isSurveyOptOut = true;
    engagementClientVoiceOptViewModel.optOutReason = this.engagementClientVoiceUIViewModel.optOutReason.trim();
    engagementClientVoiceOptViewModel.loggedInUser = this.userObject.loggedInUserEmail;
    engagementClientVoiceOptViewModel.loggedInUserFullName == this.userObject.loggedInUserFullName;
    engagementClientVoiceOptViewModel.sectionShortName = this.sectionConfiguration.code;
    this.engagementService.saveEngagementClientVoiceOpt(engagementClientVoiceOptViewModel).subscribe(
      updateEngOptResult => {
        if (updateEngOptResult == 'Success') {
          this.engagementClientVoiceUIViewModel.optOutReasonSaved = true;
          console.log('EngagementClientVoice save successfully');
          this.getEngagementClientVoiceDetailByEngId(this.selectEngagementId);
          this.SendForClientSurveyOptOut();
        }
      },
      error => {
        console.error('Client Consent', error);
        this.logService.log("error while saving engagement client voice delegate ");
      });
  }

  setDelegate() {
    if ($('#drpDelegate').val() == null || $('#drpDelegate').val() == '') {
      this.engagementClientVoiceUIViewModel.isValidDelegate = false;
    }
    else {
      this.engagementClientVoiceUIViewModel.isValidDelegate = true;
      this.engagementClientVoiceUIViewModel.delegateEmailId = $('#drpDelegate').val();
      this.engagementClientVoiceUIViewModel.delegateName = $('#drpDelegate option:selected').text();
      this.saveEngagementClientVoiceDelegate();
    }
  }

  validateEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(email)) {
      return true;
    } else {
      return false;
    }
  }


  getEngagementClientVoiceDetailByEngId(engId: number) {
    this.loadingScreenService.startLoading();
    return this.engagementService.GetEngagementClientVoiceDetailByEngId(engId)
      .subscribe(
        result => {
          this.loadingScreenService.stopLoading();
          this.engagementClientVoiceDetailViewModel = result;
          let engagementClientVoiceUserAccess: EngagementClientVoiceUserAccess = new EngagementClientVoiceUserAccess();
          engagementClientVoiceUserAccess.UserEmail = this.engagementClientVoiceDetailViewModel.engagementClientVoice.delegateEmailId?.toLowerCase().trim();
          engagementClientVoiceUserAccess.UserName = this.engagementClientVoiceDetailViewModel.engagementClientVoice.delegateName?.toLowerCase().trim();

          if (!this.engagementClientVoiceUserAccessList.find(x => x.UserEmail?.toLowerCase().trim()
            == engagementClientVoiceUserAccess.UserEmail?.toLowerCase().trim())) {
            this.engagementClientVoiceUserAccessList.push(engagementClientVoiceUserAccess);
          }
          this.isUserEPPMDOrDelegate = this.isUserValid();

          console.log('this.engagementClientVoiceDetailViewModel==>', this.engagementClientVoiceDetailViewModel);

          this.engagementClientVoiceUIViewModel.optOutReason =
            this.engagementClientVoiceDetailViewModel.engagementClientVoice.optOutReason;
          this.engagementClientVoiceUIViewModel.isSurveyOptOut =
            this.engagementClientVoiceDetailViewModel.engagementClientVoice.isSurveyOptOut;

          this.engagementClientVoiceUIViewModel.engagement_ChangedName = this.engagementClientVoiceDetailViewModel.engagementClientVoice.engagement_ChangedName

          if (this.engagementClientVoiceUIViewModel.engagement_ChangedName == '' ||
            this.engagementClientVoiceUIViewModel.engagement_ChangedName == null) {
            this.engagementClientVoiceUIViewModel.engagement_ChangedName = this.engagementName;
          }

          if (this.engagementClientVoiceDetailViewModel.engagementClientVoice.surveyDate != null) {
            $('.surveyDate').val(this.datePipe.transform(this.engagementClientVoiceDetailViewModel.engagementClientVoice.surveyDate, 'MM/dd/yyyy'));
          }
          this.dbClientVoiceTeamMemberCount = this.engagementClientVoiceDetailViewModel.engagementClientVoiceTeamMemberList.length;


          if (this.engagementClientVoiceDetailViewModel.engagementClientVoice.isSurveyOptOut ||
            this.dbClientVoiceTeamMemberCount > 0) {
            this.isSurveryOptedCompleted = true;
          }

          if (this.engagementClientVoiceDetailViewModel.engagementClientVoice.engagementClientVoiceId > 0) {
            if (this.engagementClientVoiceDetailViewModel.engagementClientVoice.delegateEmailId != ''
              && this.engagementClientVoiceDetailViewModel.engagementClientVoice.delegateEmailId != null) {
              let engagementClientVoiceUserAccess: EngagementClientVoiceUserAccess = new EngagementClientVoiceUserAccess();
              engagementClientVoiceUserAccess.UserEmail = this.engagementClientVoiceDetailViewModel.engagementClientVoice.delegateEmailId?.toLowerCase().trim();
              engagementClientVoiceUserAccess.UserName = this.engagementClientVoiceDetailViewModel.engagementClientVoice.delegateName?.toLowerCase().trim();
              this.engagementClientVoiceUserAccessList.push(engagementClientVoiceUserAccess);
            }

            if (this.engagementClientVoiceDetailViewModel.engagementClientVoice.isSurveyOptOut) {
              this.divType = 'Opted';
            } else if (this.dbClientVoiceTeamMemberCount > 0) {
              this.divType = 'Completed';
            } else {
              this.divType = 'Default';
            }
          }
          else {
            this.divType = 'Default';
          }
        },
        error => {
          this.engagementClientVoiceUIViewModel = null;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  GetClientVoiceContacts(clientCode: string) {
    this.loadingScreenService.startLoading();
    this.clientvoiceService.GetClientContacts(clientCode).subscribe(result => {

      this.loadingScreenService.stopLoading();
      if (result && result.statusCode == this.clientVoiceAPISuccessCode) {
        this.clientVoiceContact = result.response;
        if (this.clientVoiceContact && this.clientVoiceContact.length > 0) {
          this.clientVoiceContact.forEach(member => {
            this.clientVoiceRecipientList.push({
              fullName: member.fullName, firstName: member.firstName, lastName: member.lastName, memberEmail: member.emailAddress1,
              memberTitle: member.jobTitle, contactGuid: member.contactId, isRecipientsEdit: false,
              editMemberEmail: member.emailAddress1, editFirstName: member.firstName, editLastName: member.lastName,
              isValidMemberEmail: true, isValidMemberEmailText: true,
              isEmailExistsInList: false, isValidFirstName: true, isValidLastName: true, isRowInvalid: false
            });
          });
        }
      }
      else {
        this.logService.error(result.status);
      }
    },
      err => {
        this.logService.error(err);
        this.loadingScreenService.stopLoading();
      });
  }

  SendForClientSurvey() {
    this.engagementService.GetEngagementClientVoiceDetailByEngId(this.selectEngagementId).subscribe(
      result => {
        this.engagementClientVoiceDetailViewModel = result;
        this.dbClientVoiceTeamMemberCount = result.engagementClientVoiceTeamMemberList.length;
        this.engagementClientVoiceDetailViewModel.engagementClientVoiceTeamMemberList = result.engagementClientVoiceTeamMemberList;
        if (this.dbClientVoiceTeamMemberCount > 0) {
          this.engagementClientVoiceUIViewModel.isSurveySaved = true;
        }
        if (result.engagementClientVoice.isSurveyOptOut ||
          this.dbClientVoiceTeamMemberCount > 0) {
          this.isSurveryOptedCompleted = true;
        }

        if (result.engagementClientVoice.engagementClientVoiceId > 0) {
          if (result.engagementClientVoice.isSurveyOptOut) {
            this.divType = 'Opted';
          } else if (this.dbClientVoiceTeamMemberCount > 0) {
            this.divType = 'Completed';
          } else {
            this.divType = 'Default'
          }
        }
        this.clientVoiceSurveyRequest.engagement.engagementId = result.engagementClientVoice.engagementId.toString();
        this.clientVoiceSurveyRequest.engagement.engagementName = result.engagementClientVoice.engagement_ChangedName;
        this.clientVoiceSurveyRequest.engagement.primarySolutionCode = result.engagementClientVoiceServiceLineDetails.solutionCode;
        this.clientVoiceSurveyRequest.engagement.clientCode = this.clientCode;
        this.clientVoiceSurveyRequest.engagement.clientName = this.clientName;
        this.clientVoiceSurveyRequest.engagement.eppmdName = this.engagementPartnerName;
        this.clientVoiceSurveyRequest.engagement.eppmdEmailId = this.engagementPartner;
        this.clientVoiceSurveyRequest.engagement.surveyDate = result.engagementClientVoice.surveyDate.toString();
        this.clientVoiceSurveyRequest.engagement.createdOn = result.engagementClientVoice.createdDate.toString();
        this.clientVoiceSurveyRequest.engagement.createdBy = this.userObject.loggedInUserEmail;

        this.clientVoiceSurveyRequest.clientMembers = [];
        this.clientVoiceSurveyRequest.teamMembers = [];

        result.engagementClientVoiceTeamMemberList.forEach(member => {
          let clientVoiceContact = this.clientVoiceContact.filter(contact => contact.contactId == member.contactGuid)
          let firstName = '';
          let lastName = '';
          if (clientVoiceContact && clientVoiceContact.length > 0) {
            firstName = clientVoiceContact[0].firstName;
            lastName = clientVoiceContact[0].lastName;
          }
          else {
            // let memberNameArr = member.firstName
            firstName = member.firstName;
            lastName = member.lastName;
          }

          this.clientVoiceSurveyRequest.clientMembers.push(
            {
              engagementId: result.engagementClientVoice.engagementId.toString(),
              contactGuid: member.contactGuid,
              clientFirstName: firstName,
              clientLastName: lastName,
              clientEmail: member.memberEmail,
              clientTitle: member.memberTitle,
              name: ''
            });
        });

        this.orgUniqueDelegateMembers.forEach(member => {
          this.clientVoiceSurveyRequest.teamMembers.push({
            engagementId: result.engagementClientVoice.engagementId.toString(),
            memberName: member.memberName,
            memberEmail: member.memberEmailId,
            memberRole: this.teamMemberRoleList.filter(role => role.id == member.memberRoleId)[0].typeValue,
            name: ''
          });
        });

        this.clientvoiceService.SendSurvey(this.clientVoiceSurveyRequest).subscribe(sendSurveyResponse => {
          if (sendSurveyResponse && sendSurveyResponse.statusCode == this.clientVoiceAPISuccessCode) {
            this.clientVoiceApiLog.engagementId = result.engagementClientVoice.engagementId;
            this.clientVoiceApiLog.requestPayload = JSON.stringify(this.clientVoiceSurveyRequest);
            this.clientVoiceApiLog.responseBody = JSON.stringify(sendSurveyResponse);
            this.clientVoiceApiLog.loggedInUser = this.userObject.loggedInUserEmail;
          }
          else {
            this.logService.error(sendSurveyResponse.status);
            this.clientVoiceApiLog.engagementId = result.engagementClientVoice.engagementId;
            this.clientVoiceApiLog.requestPayload = JSON.stringify(this.clientVoiceSurveyRequest);
            this.clientVoiceApiLog.responseBody = JSON.stringify(sendSurveyResponse);
            this.clientVoiceApiLog.loggedInUser = this.userObject.loggedInUserEmail;
          }

          this.engagementService.addClientVoiceApiLog(this.clientVoiceApiLog).subscribe(logResponse => {
            this.loadingScreenService.stopLoading();
            this.logService.info("ClientVoice API log saved");
            this.updateEngagementCloseoutConfigurations();
          },
            err => {
              this.loadingScreenService.stopLoading();
              this.logService.error(err);
            });
        },
          err => {
            this.clientVoiceApiLog.engagementId = result.engagementClientVoice.engagementId;
            this.clientVoiceApiLog.requestPayload = JSON.stringify(this.clientVoiceSurveyRequest);
            this.clientVoiceApiLog.responseBody = JSON.stringify(err);
            this.clientVoiceApiLog.loggedInUser = this.userObject.loggedInUserEmail;

            this.engagementService.addClientVoiceApiLog(this.clientVoiceApiLog).subscribe(logResponse => {
              this.loadingScreenService.stopLoading();
              this.logService.info("ClientVoice API log saved");
            },
              logError => {
                this.loadingScreenService.stopLoading();
                this.logService.error(logError);
              });
          });
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.logService.error(error);
        this.engagementClientVoiceUIViewModel = null;
      }
    );
  }

  SendForClientSurveyOptOut() {
    this.loadingScreenService.startLoading();
    this.engagementService.GetEngagementClientVoiceDetailByEngId(this.selectEngagementId).subscribe(
      result => {

        //Fill engagement client voice
        this.clientVoiceSurveyOptOutRequest.engagement.engagementId = result.engagementClientVoice.engagementId.toString();
        this.clientVoiceSurveyOptOutRequest.engagement.engagementName = this.engagementName;
        this.clientVoiceSurveyOptOutRequest.engagement.primarySolutionCode = result.engagementClientVoiceServiceLineDetails.solutionCode;
        this.clientVoiceSurveyOptOutRequest.engagement.clientCode = this.clientCode;
        this.clientVoiceSurveyOptOutRequest.engagement.clientName = this.clientName;
        this.clientVoiceSurveyOptOutRequest.engagement.eppmdName = this.engagementPartnerName;
        this.clientVoiceSurveyOptOutRequest.engagement.eppmdEmailId = this.engagementPartner;
        this.clientVoiceSurveyOptOutRequest.engagement.surveyDate = result.engagementClientVoice.surveyDate ? result.engagementClientVoice.surveyDate.toString() : result.engagementClientVoice.createdDate.toString();
        this.clientVoiceSurveyOptOutRequest.engagement.createdOn = result.engagementClientVoice.createdDate.toString();
        this.clientVoiceSurveyOptOutRequest.engagement.createdBy = this.userObject.loggedInUserEmail;

        //File OptOut details
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.engagementId = result.engagementClientVoice.engagementId.toString();
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.clientCode = this.clientCode;
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.ePPMDEmailId = this.engagementPartner;
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.ePPMDName = this.engagementPartnerName;
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.memberEmail = this.userObject.loggedInUserEmail;
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.memberName = this.userObject.loggedInUserFullName;
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.optOutReason = result.engagementClientVoice.optOutReason;
        this.clientVoiceSurveyOptOutRequest.clientVoiceSurveyOptOut.optOutDate = result.engagementClientVoice.createdDate.toString();

        this.loadingScreenService.startLoading();
        this.clientvoiceService.SendSurveyOptOut(this.clientVoiceSurveyOptOutRequest).subscribe(sendSurveyResponse => {

          if (sendSurveyResponse && sendSurveyResponse.statusCode == this.clientVoiceAPISuccessCode) {
            this.clientVoiceApiLog.engagementId = result.engagementClientVoice.engagementId;
            this.clientVoiceApiLog.requestPayload = JSON.stringify(this.clientVoiceSurveyOptOutRequest);
            this.clientVoiceApiLog.responseBody = JSON.stringify(sendSurveyResponse);
            this.clientVoiceApiLog.loggedInUser = this.userObject.loggedInUserEmail;
          }
          else {
            this.logService.error(sendSurveyResponse.status);
            this.clientVoiceApiLog.engagementId = result.engagementClientVoice.engagementId;
            this.clientVoiceApiLog.requestPayload = JSON.stringify(this.clientVoiceSurveyOptOutRequest);
            this.clientVoiceApiLog.responseBody = JSON.stringify(sendSurveyResponse);
            this.clientVoiceApiLog.loggedInUser = this.userObject.loggedInUserEmail;
          }

          this.loadingScreenService.startLoading();
          this.getEngagementCloseoutConfigurations();
          this.engagementService.addClientVoiceApiLog(this.clientVoiceApiLog).subscribe(logResponse => {
            this.logService.info("ClientVoice API log saved");
            this.loadingScreenService.stopLoading();
          },
            logError => {
              this.logService.error(logError);
              this.loadingScreenService.stopLoading();
            });

        },
          clientVoiceError => {
            this.clientVoiceApiLog.engagementId = result.engagementClientVoice.engagementId;
            this.clientVoiceApiLog.requestPayload = JSON.stringify(this.clientVoiceSurveyOptOutRequest);
            this.clientVoiceApiLog.responseBody = JSON.stringify(clientVoiceError);
            this.clientVoiceApiLog.loggedInUser = this.userObject.loggedInUserEmail;
            this.loadingScreenService.startLoading();
            this.engagementService.addClientVoiceApiLog(this.clientVoiceApiLog).subscribe(logResponse => {
              this.logService.info("ClientVoice API log saved");
              this.loadingScreenService.stopLoading();
            },
              logError => {
                this.logService.error(logError);
                this.loadingScreenService.stopLoading();
              });
          });
      },
      error => {
        this.logService.error(error);
        this.engagementClientVoiceUIViewModel = null;
        this.loadingScreenService.stopLoading();
      }
    );
  }

  ngOnDestroy(): void {
    this.loadingScreenService.stopLoading();
  }

  editclientVoiceTeamMember(memberIndex: number) {
    if (memberIndex >= 0) {
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isRecipientsEdit = true;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isRowInvalid = false;
    }
  }

  cancelclientVoiceTeamMember(memberIndex: number) {
    if (memberIndex >= 0) {
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].editMemberEmail =
        this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].memberEmail;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].editFirstName =
        this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].firstName;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].editLastName =
        this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].lastName;

      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isRecipientsEdit = false;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isValidMemberEmail = true;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isValidMemberEmailText = true;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isEmailExistsInList = false;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isValidFirstName = true;
      this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex].isValidLastName = true;

      if (this.checkisValidrecipientEmailAndNofity()) { //  check client Voice TeamMember has any blank email ID
        if (!this.checkDuplicateEmailAndNotify()) { //Check Duplicate Email And Notify
          this.checkFirstNameLastNameBlankAndNofity(); //Check check First name and Last name Blank       
        }
      };
    }
  }

  saveclientVoiceTeamMember(memberIndex: number) {
    var editMemberObj = this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers[memberIndex];
    //Reset with default flag values
    editMemberObj.isValidMemberEmail = true;
    editMemberObj.isValidMemberEmailText = true;
    editMemberObj.isEmailExistsInList = false;
    editMemberObj.isValidFirstName = true;
    editMemberObj.isValidLastName = true;

    // Validate Email
    if (editMemberObj.editMemberEmail.trim() == '') {
      editMemberObj.isValidMemberEmail = false;
    } else {
      editMemberObj.isValidMemberEmail = true;
    }

    // Validate First Name
    if (editMemberObj.editFirstName.trim() == '') {
      editMemberObj.isValidFirstName = false;
    } else {
      editMemberObj.isValidFirstName = true;
      editMemberObj.firstName = editMemberObj.editFirstName;
    }

    // Validate Last Name
    if (editMemberObj.editLastName.trim() == '') {
      editMemberObj.isValidLastName = false;
    } else {
      editMemberObj.isValidLastName = true;
      editMemberObj.lastName = editMemberObj.editLastName;
    }

    if (editMemberObj.isValidMemberEmail) {
      editMemberObj.isValidMemberEmailText = this.validateEmail(editMemberObj.editMemberEmail);
      if (editMemberObj.isValidMemberEmailText) {
        //if editMemberObj memberEmail and editMemberEmail is different then check existsance in list
        if (editMemberObj.memberEmail.toLowerCase().trim() != editMemberObj.editMemberEmail.toLowerCase().trim()) {
          if (!this.engagementClientVoiceUIViewModel.clientVoiceTeamMembers.find(o => o.memberEmail.toLowerCase().trim() ===
            editMemberObj.editMemberEmail.toLowerCase().trim())) {
            editMemberObj.isEmailExistsInList = false;
            editMemberObj.memberEmail = editMemberObj.editMemberEmail;
            // editMemberObj.isRecipientsEdit = false;
          }
          else {
            editMemberObj.isEmailExistsInList = true;
          }
        } else {
          editMemberObj.isEmailExistsInList = false;
          editMemberObj.memberEmail = editMemberObj.editMemberEmail;
          // editMemberObj.isRecipientsEdit = false;
        }
      }
    }

    // check all validations done and all entered data is valid then make edit mode FALSE
    if (!editMemberObj.isEmailExistsInList && editMemberObj.isValidMemberEmail &&
      editMemberObj.isValidFirstName && editMemberObj.isValidLastName &&
      editMemberObj.isValidMemberEmailText) {
      editMemberObj.isRecipientsEdit = false;
      // check validate all records
      if (this.checkisValidrecipientEmailAndNofity()) { //  check client Voice TeamMember has any blank email ID
        if (!this.checkDuplicateEmailAndNotify()) { //Check Duplicate Email And Notify
          this.checkFirstNameLastNameBlankAndNofity(); //Check check First name and Last name Blank       
        }
      }
    } else {
      editMemberObj.isRecipientsEdit = true;
    }
  }

  getEngagementCloseoutConfigurations() {
    this.engagementService.GetEngagementCloseoutConfigurations(this.selectEngagementId).subscribe(engagementCloseoutConfigurations => {
      if (engagementCloseoutConfigurations && engagementCloseoutConfigurations.length > 0) {
        this.sectionConfiguration = engagementCloseoutConfigurations.find(section =>
          section.code === CloseoutSections.CS);
        //this.showRequiredWarning = !this.sectionConfiguration.isCompleted;
        if (this.sectionConfiguration.isCompleted) {
          this.showRequiredWarning = false;
        }
      }
    },
      err => {
        console.error('getEngagementCloseoutConfigurations ', err);
      });
  }

  updateEngagementCloseoutConfigurations() {
    let isCompleted: boolean = true;

    this.loadingScreenService.startLoading();
    let updateEngCloseoutConfigModel: UpdateEngagementCloseoutConfigurationModel = new UpdateEngagementCloseoutConfigurationModel();
    updateEngCloseoutConfigModel.engagementId = this.selectEngagementId;
    updateEngCloseoutConfigModel.isCompleted = isCompleted;
    updateEngCloseoutConfigModel.sectionShortName = this.sectionConfiguration.code;
    updateEngCloseoutConfigModel.loggedInUser = this.userObject.loggedInUserEmail;
    updateEngCloseoutConfigModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engagementService.UpdateEngagementCloseoutConfigurations(updateEngCloseoutConfigModel).subscribe(
      updateEngResult => {
        if (updateEngResult === 'Success') {
          this.getEngagementCloseoutConfigurations();
          this.loadingScreenService.stopLoading();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        const errorMsg = 'Error while updateEngagementCloseoutConfigurations for section : ' + this.sectionConfiguration.value;
        console.error(errorMsg, error);
        //this.logService.log(errorMsg);
      });
  }

  IsLoggedinUserSystemAdmin() {
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      //check logged in user belongs to admin group
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === RoleNames.SystemAdministrator) {
          this.isloggedInUserAdmin = 'yes';
          break;
        } else {
          this.isloggedInUserAdmin = 'no';
        }
      }
    }
  }

}
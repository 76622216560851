import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  SolutionCodeMappingModel,
  RequestTriggerOptions,
} from "src/app/model/SolutionCodeMappingModel";
import {
  SolutionCodeMasterModel,
  SolutionCodeStatusModel,
} from "src/app/model/SolutionCodeMappingModel";
import { SolutionCodeSearchFilter } from "src/app/model/SolutionCodeSearchFilter";
import { LoadingScreenService } from "src/app/services/loading-screen.service";
import { ConfigurationService } from "../../services/configuration.service";
import { solutionCodeType } from "src/app/model/solutionCodeType";
import { AlertsService } from "../../services/alerts.service";
import { PagerService } from "src/app/services/pager.service";
import { solutionCode } from "src/app/model/SolutionCode";
import { Alert } from "src/app/model/Alert";
import { ImageModel, HeaderModel } from "src/app/model/SolutionCodeListModel";
import { HotDocsService } from "src/app/services/HotDocsService";
import {
  TemplatePackageModel,
  MappingTemplatePackageModel,
  PostMappingTemplatePackageModel,
} from "src/app/model/TemplatePackageModel";
import { MembersList } from "src/app/model/MembersList";
import { SearchGroupUserViewModel } from "src/app/model/AzureADUsersArrayList";
import { AdUsersService } from "src/app/services/adusers.service";
import { AppConfigService } from "src/app/services/appConfigService";
import { RoleNames } from "src/app/model/Role";
import {
  PostMappingPSDModel,
  solutionCodePSDMaping,
} from "src/app/model/SolutionCodePSD";

declare var $;

@Component({
  selector: "app-solutioncode-configuration",
  templateUrl: "./solutioncode-configuration.component.html",
  styleUrls: ["./solutioncode-configuration.component.css"],
  providers: [ConfigurationService],
  encapsulation: ViewEncapsulation.None, // Add this line
})
export class SolutioncodeConfigurationComponent implements OnInit {
  dropdownSecSolutionCodeList = [];
  dropdownServieLineList = [];

  ServieLineSelectedItems = [];
  SecSolutionCodeselectedItems = [];

  ServieLinedropdownSettings = {};
  SecCodedropdownSettings = {};

  pageNumber: number = 1;
  defaultPageNumber: number = 1;
  noOfrecordsPerPage: number = 20;
  solutionCodeCnt: number = null;
  editMode: boolean = false;
  currentSolutionCodeSearchFilter: SolutionCodeSearchFilter =
    new SolutionCodeSearchFilter();
  currentsolutionCodeMapping: SolutionCodeMappingModel =
    new SolutionCodeMappingModel();
  newDynamicSolutionCodeId: solutionCode = new solutionCode();
  solutionCodeMasterDataList: SolutionCodeMasterModel[] = [];
  solutionCodelistView: SolutionCodeMappingModel[] = null;
  currentsolutionCodeTypeList: solutionCodeType[] = [];
  requestTriggerOptions: RequestTriggerOptions[] = [];
  actualsolutionCodeTypeList: solutionCodeType[] = [];
  secondaryCodeIdMappingList: solutionCode[] = [];
  strList: string[] = [];
  secondaryCodeMappingListStr: string = "";
  secCodeSearchFilterList: string = "";
  secSolutionCodeList: SolutionCodeMasterModel[] = [];
  solutionCodeStatusList: SolutionCodeStatusModel[] = [];

  objmultiselect: Array<any> = [];

  mapDataValues: any[] = [];
  mapDataIdValues: any[] = [];
  alert: Alert;
  errorMessage: string;
  successMessage = "";
  isSuccessMapping = false;
  isMappingValid = true;
  showMsg = false;
  isUserAgentIE: number;
  years: string[] = ["FYE", "TYE"];
  fye_tye: string = null;
  solutionCodeShortNameCount: number = 0;
  beforeChangeSolutionCodeLongName: string = null;

  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];
  orderByColumn: string;
  orderByType: string;
  noOfrecordsPerPageList: number[];
  listDataMessage: string;
  listViewCurrentPageNumber: number = 1;
  SolutionCodeMappedValue: string;
  solutionCodeTypeList: solutionCodeType[] = [];
  solutionCodeTypeModel: solutionCodeType = new solutionCodeType();
  selectsolutionCodeModel: SolutionCodeMasterModel =
    new SolutionCodeMasterModel();
  showMessage: boolean = false;
  showMessageText: string;
  showErrorMessage: boolean;
  showErrorMessageText: string;

  isInactiveSolutionCode: boolean = false;
  isSolutionCodeAlreadyAdded: boolean = false;
  templateList: TemplatePackageModel[] = [];
  templateMappingList: MappingTemplatePackageModel[] = [];
  isTemplateAlreadyAdded: boolean = false;
  isValidTemplateSelected: boolean = true;
  isTemplateMappingExist: boolean = true;
  newTemplateMappingData: MappingTemplatePackageModel =
    new MappingTemplatePackageModel();
  UserObject: any;
  isSolutionCodeSelected: boolean = true;
  postMappingTemplatePackageModel: PostMappingTemplatePackageModel =
    new PostMappingTemplatePackageModel();
  documentCategory: string = "Create New Engagement";
  SolutionCodeFilterMappedValue: string;
  isActiveFilter = false;
  isShowActiveFilter = false;
  isSpecificTypeValid = true;
  TriggerOption = 1;

  // Added for PSD
  PSDRoleName: string;
  PSDlblCtrlName: string;
  PSDDisplayUserName = "";
  PSDDisplayUserEmail = "";
  isValidPSDSelected: boolean = true;
  isPSDMappingExist: boolean = true;
  isPSDAlreadyAdded: boolean = false;
  newPSDMappingData: solutionCodePSDMaping = new solutionCodePSDMaping();
  PSDMapingList: solutionCodePSDMaping[] = [];
  postMappingPSDModel: PostMappingPSDModel = new PostMappingPSDModel();
  PSDpeoplepickerIndex: number = 0;
  PSDNameCtrl: string;
  ASPSDCtrl: string;
  validatePSD: boolean;
  membersListArray: MembersList[];
  selectedPSDIndex: string;
  isvalidPSD: boolean = true;

  constructor(
    private _configService: ConfigurationService,
    private _alertService: AlertsService,
    private pagerService: PagerService,
    private loadingScreenService: LoadingScreenService,
    private _hdService: HotDocsService,
    private adUsersService: AdUsersService,
    private appService: AppConfigService
  ) {
    const IEversion = localStorage.getItem("isUserAgentIE");
    this.isUserAgentIE = JSON.parse(IEversion);
    this.SolutionCodeMappedValue = "SolutionCodeConfiguration";
    this.SolutionCodeFilterMappedValue = "SolutionCodeConfigurationFilter";
    this.solutionCodeTypeModel = {
      id: 0,
      typeValue: "",
    };
    // Default Initialaztion
    this.solutionCodeTypeList.push(this.solutionCodeTypeModel);
    this.solutionCodeTypeList.push(this.solutionCodeTypeModel);
    this.solutionCodeTypeList.push(this.solutionCodeTypeModel);
    this.selectsolutionCodeModel = {
      solutionCodeId: 0,
      solutionCode: "",
      solutionCodeDescription: "",
      isvalid: true,
    };

    this.initializeRequestTriggers();
    const nameJSON = JSON.parse(localStorage.getItem("currentUser"));
    if (nameJSON != null) this.UserObject = nameJSON;
  }

  // -- Pager service -- */
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  // -- Pager service End-- */

  ngOnInit() {
    this._alertService.remove(this.alert);
    this.getTemplatePackages();
    this.setDefaultInitialization();
    this.LoadListViewHeaders();
    this.setPageRecordRange();
    this.getAllSolutionCodeListCount();
    this.getSolutionCodeType();
    this.getSolutionCodeMasterData();
    this.getSolutionCodeStatus();
    this.dropdownServieLineList = [
      { ServieLine_id: 1, ServieLineItem_text: "Audit" },
      { ServieLine_id: 2, ServieLineItem_text: "Tax" },
      { ServieLine_id: 3, ServieLineItem_text: "Advisory" },
    ];

    this.ServieLinedropdownSettings = {
      singleSelection: false,
      idField: "ServieLine_id",
      textField: "ServieLineItem_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };

    this.SecCodedropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };

    $("#ddTemplate").val("0");
    this.PSDRoleName = RoleNames.StaffMember.toString();
    this.PSDlblCtrlName = "PSD name";
  }

  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(40);
    this.noOfrecordsPerPageList.push(60);
  }

  LoadListViewHeaders() {
    this.newImageModel = {
      imageName: "DEFAULT",
      imageValue: "ASC",
      imagePath:
        "../../../../assets/images/EDP/Engagement/unfold_more-24px.svg",
      isVisible: true,
    };
    this.headerImageModelList.push(
      JSON.parse(JSON.stringify(this.newImageModel))
    );

    this.newImageModel = {
      imageName: "Asending",
      imageValue: "ASC",
      imagePath: "../../../../assets/images/EDP/Engagement/asc_order.png",
      isVisible: false,
    };
    this.headerImageModelList.push(
      JSON.parse(JSON.stringify(this.newImageModel))
    );

    this.newImageModel = {
      imageName: "Desending",
      imageValue: "DESC",
      imagePath: "../../../../assets/images/EDP/Engagement/desc_order.png",
      isVisible: false,
    };
    this.headerImageModelList.push(
      JSON.parse(JSON.stringify(this.newImageModel))
    );

    this.headerModel = {
      headerId: 1,
      headerName: "Solution code",
      headerValue: "SolutionCode",
      headerClick: true,
      imageModelList: this.headerImageModelList,
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 2,
      headerName: "Description",
      headerValue: "SolutionCodeDescription",
      headerClick: false,
      imageModelList: this.headerImageModelList,
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 3,
      headerName: "Service line",
      headerValue: "ServiceLinesText",
      headerClick: false,
      imageModelList: this.headerImageModelList,
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 4,
      headerName: "Status",
      headerValue: "status",
      headerClick: false,
      imageModelList: this.headerImageModelList,
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    console.log("headerModelList ==>", this.headerModelList);
  }

  editSolutionCodeMapping(solutionCodeId, page: number) {
    this._alertService.remove(this.alert);
    this.pageNumber = page;
  }

  removeMappin_old(codeObj: solutionCode) {
    // Find the component/selected solutioncode index before remove
    const componentIndex = this.secondaryCodeIdMappingList.indexOf(codeObj);
    if (componentIndex !== -1) {
      this.secondaryCodeIdMappingList.splice(componentIndex, 1);
      this.issolutionCodeExistsInMappedList(codeObj.id.toString());
      this.isSuccessMapping = true;
      this.showMsg = true;
      return true;
    }
  }

  removeMapping(index: number) {
    if (index > -1) {
      this.secSolutionCodeList.splice(index, 1);
    }
  }

  AddSecondarySolutionMapping() {
    const selectedSolutionCodehdnKeyValue = $(
      "#hdnKey" + this.SolutionCodeMappedValue + 0
    )
      .val()
      .split("-!");
    const selectedSolutionCodeId = selectedSolutionCodehdnKeyValue[0];
    const selectedSolutionCode: string = selectedSolutionCodehdnKeyValue[1];
    const selectedSolutionCodeDesc: string = selectedSolutionCodehdnKeyValue[2];
    const inputValue = $("#txtValueSolutionCodeConfiguration0").val();

    if (
      !(selectedSolutionCode == "" || selectedSolutionCode == undefined) &&
      !(inputValue == "" || inputValue == undefined)
    ) {
      this.selectsolutionCodeModel.solutionCodeId = selectedSolutionCodeId;
      this.selectsolutionCodeModel.solutionCode = selectedSolutionCode.trim();
      this.selectsolutionCodeModel.solutionCodeDescription =
        selectedSolutionCodeDesc.trim();
      this.selectsolutionCodeModel.isvalid = true;
      this.isSolutionCodeAlreadyAdded = false;
      const tempsolutionCodeObj = this.secSolutionCodeList.find(
        (e) => e.solutionCodeId == selectedSolutionCodeId
      );
      if (tempsolutionCodeObj == undefined) {
        this.secSolutionCodeList.push(
          JSON.parse(JSON.stringify(this.selectsolutionCodeModel))
        );
        this.isSpecificTypeValid = true;
      } else {
        this.isSolutionCodeAlreadyAdded = true;
        this.isSpecificTypeValid = true;
      }
    } else {
      this.selectsolutionCodeModel.isvalid = false;
      this.isSolutionCodeAlreadyAdded = false;
    }
    $("#txtValueSolutionCodeConfiguration0").val('');
  }
  

  addItemInGridList() {
    this.secSolutionCodeList.push();
  }

  getAllSolutionCodeListCount() {
    const filterSectionName = "listViewFilterSectionForSolutionCode";
    $("#" + filterSectionName).hide();
    this.loadingScreenService.startLoading();
    this._configService
      .getAllSolutionCodeListCount(this.currentSolutionCodeSearchFilter)
      .subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          this.solutionCodeCnt = result;
          if (this.solutionCodeCnt > 0) {
            // initialize to page 1
            this.setPage(this.pageNumber, this.noOfrecordsPerPage);
          } else {
            this.listDataMessage = " No Data Available";
          }
        },
        (error) => {
          this.loadingScreenService.stopLoading();
        }
      );
    $("#listViewFilterSectionForSolutionCode").hide();
  }

  onItemChange(id: number) {
    this.isSolutionCodeAlreadyAdded = false;
    this.currentsolutionCodeMapping.solutionCodeTypeId = id;
    console.log(
      "currentsolutionCodeMappingxx ==>",
      this.currentsolutionCodeMapping
    );

    if (
      this.currentsolutionCodeMapping.solutionCodeTypeId ==
        this.solutionCodeTypeList[2].id ||
      this.currentsolutionCodeMapping.solutionCodeTypeId ==
        this.solutionCodeTypeList[1].id
    ) {
      this.currentsolutionCodeMapping.isAllAdvisory = false;
      this.currentsolutionCodeMapping.isAllAudit = false;
      this.currentsolutionCodeMapping.isAllTax = false;
    } else {
      this.secSolutionCodeList = [];
    }
  }

  onRequestTriggerChange(triggerOptions: string) {
    const triggerValue = true;
    if (triggerOptions === "uponApproval") {
      this.currentsolutionCodeMapping.uponApproval = triggerValue;
      this.currentsolutionCodeMapping.onSignature = !triggerValue;
    } else if (triggerOptions === "onSignature") {
      this.currentsolutionCodeMapping.onSignature = triggerValue;
      this.currentsolutionCodeMapping.uponApproval = !triggerValue;
    }
  }
  setDefaultInitialization() {
    this.orderByColumn = "solutionCode";
    this.orderByType = "DESC";
    this.initializeRequestTriggers();
  }

  HeaderSort(headerid: number) {
    for (let i = 0; i < this.headerModelList.length; i++) {
      if (this.headerModelList[i].headerId === headerid) {
        this.headerModelList[i].headerClick = true;
        if (
          this.headerModelList[i].imageModelList[0].isVisible ||
          this.headerModelList[i].imageModelList[2].isVisible
        ) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = true;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        } else if (this.headerModelList[i].imageModelList[1].isVisible) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = true;
        }
      } else {
        this.headerModelList[i].headerClick = false;
        for (
          let j = 0;
          j < this.headerModelList[i].imageModelList.length;
          j++
        ) {
          this.headerModelList[i].imageModelList[0].isVisible = true;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        }
      }
    }

    let headerObj = this.headerModelList.find((c) => c.headerClick === true);
    this.orderByColumn = headerObj.headerValue;
    for (let k = 0; k < headerObj.imageModelList.length; k++) {
      if (headerObj.imageModelList[k].isVisible === true) {
        this.orderByType = headerObj.imageModelList[k].imageValue;
        break;
      }
    }
    this.movePage(1, this.noOfrecordsPerPage);
  }

  getSolutionCodeMappingListView(pageNumber: number) {
    this.loadingScreenService.startLoading();
    this._configService
      .getSolutionCodeMappingListView(
        pageNumber,
        this.noOfrecordsPerPage,
        this.currentSolutionCodeSearchFilter,
        this.orderByColumn,
        this.orderByType
      )
      .subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          this.solutionCodelistView = result;
          this.initializeRequestTriggers();
        },
        (error) => {
          this.loadingScreenService.stopLoading();
          this._alertService.remove(this.alert);
          this._alertService.add(
            "Something went wrong! Please contact system administrator.",
            "danger",
            null
          );
          // document.body.scrollTop = 0; // For Safari
          // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
      );
  }

  populateSecodarySolutionCodesIntoList() {
    for (var j = 0; j < this.solutionCodelistView.length; j++) {
      this.mapDataIdValues =
        this.solutionCodelistView[j].secodarySolutionCodes.split(",");
      this.strList = [];
      for (var i = 0; i < this.mapDataIdValues.length; i++) {
        this.strList.push(this.mapDataIdValues[i]);
      }
      this.solutionCodelistView[j].SecondarySolutionCodesArr = this.strList;
    }
  }

  getSecondarySolutionCodeMapping(solutionCodeId: number) {
    this._configService
      .getSecondarySolutionCodeMapping(solutionCodeId)
      .subscribe(
        (result) => {
          this.secSolutionCodeList = result;
        },
        (error) => {
          this._alertService.remove(this.alert);
          this._alertService.add(
            "Something went wrong! Please contact system administrator.",
            "danger",
            null
          );
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
      );
  }

  reinitializeMapping() {
    if (this.currentsolutionCodeMapping) {
      if (this.currentsolutionCodeMapping.status === "A") {
        this.currentsolutionCodeMapping.statusDesc = "Active";
      } else if (this.currentsolutionCodeMapping.status === "I") {
        this.currentsolutionCodeMapping.statusDesc = "InActive";
      } else {
        this.currentsolutionCodeMapping.statusDesc =
          this.currentsolutionCodeMapping.status;
      }
    }
  }

  populateSecondaryCodeMappingList() {
    this.secondaryCodeIdMappingList = [];
    this.mapDataIdValues =
      this.currentsolutionCodeMapping.secodarySolutionCodeIds.split(",");

    for (var i = 0; i < this.mapDataIdValues.length; i++) {
      this.newDynamicSolutionCodeId = {
        id: this.mapDataIdValues[i],
      };
      this.secondaryCodeIdMappingList.push(this.newDynamicSolutionCodeId);
    }
  }

  // create dynamic rows for Solution code Mappping table
  addNewMappingRow(index) {
    this.newDynamicSolutionCodeId = { id: "" };
    this.secondaryCodeIdMappingList.push(this.newDynamicSolutionCodeId);
    this.isSuccessMapping = true;
    return true;
  }
  // end

  getSolutionCodeType() {
    this._configService.getSolutionCodeType().subscribe(
      (result) => {
        this.solutionCodeTypeList = result;
        console.log("solutionCodeTypeList ==>", this.solutionCodeTypeList);
        this.solutionCodeTypeModel.id = this.solutionCodeTypeList[1].id;
        this.solutionCodeTypeModel.typeValue =
          this.solutionCodeTypeList[1].typeValue;
      },
      (error) => {
        this._alertService.remove(this.alert);
        this._alertService.add(
          "Something went wrong! Please contact system administrator.",
          "danger",
          null
        );
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    );
  }

  solutionCodeRowClick(solutionCodeId: number, rowindex: number = -1) {
    if (rowindex > -1) {
      $(".solutionCodeRow").removeClass("active-row");
      $(`#solutionCode_${rowindex}`).addClass("active-row");
    }

    if (solutionCodeId > 0) {
      this.clearTemplateData();
      this.clearPSDData();
      this.showMessage = false;
      this.selectsolutionCodeModel.isvalid = true;
      this.isSolutionCodeAlreadyAdded = false;
      this.TriggerOption = 1;
      this.currentsolutionCodeMapping = JSON.parse(
        JSON.stringify(
          this.solutionCodelistView.find(
            (e) => e.solutionCodeId === solutionCodeId
          )
        )
      );
      this.isInactiveSolutionCode = false;
      if (this.currentsolutionCodeMapping.status != "") {
        this.isInactiveSolutionCode =
          this.currentsolutionCodeMapping.status.toUpperCase() == "INACTIVE";
      }
      if (this.currentsolutionCodeMapping.uponApproval === true) {
        this.TriggerOption = 1;
      } else if (this.currentsolutionCodeMapping.onSignature === true) {
        this.TriggerOption = 2;
      }

      if (this.currentsolutionCodeMapping.closurepromttargetdays == "-1") {
        this.currentsolutionCodeMapping.closurepromttargetdays = null;
      }
      if (this.currentsolutionCodeMapping.frequencyofprompt == "-1") {
        this.currentsolutionCodeMapping.frequencyofprompt = null;
      }
      if (this.currentsolutionCodeMapping.noTimeChargedDays == "-1") {
        this.currentsolutionCodeMapping.noTimeChargedDays = null;
      }
      this.solutionCodeTypeModel.id =
        this.currentsolutionCodeMapping.solutionCodeTypeId;
      this.solutionCodeTypeModel.typeValue =
        this.currentsolutionCodeMapping.solutionCodeType;
      console.log(
        "currentsolutionCodeMapping ==>",
        this.currentsolutionCodeMapping
      );
      this.getSecondarySolutionCodeMapping(
        this.currentsolutionCodeMapping.solutionCodeId
      );
      this.getTemplateMappingDataForSelectedSolutionCode(
        this.currentsolutionCodeMapping.solutionCodeId
      );
      this.getPSDMappingDataForSelectedSolutionCode(
        this.currentsolutionCodeMapping.solutionCodeId
      );
    }
  }

  getSolutionCodeMasterData() {
    this._configService.getSolutionCodeMasterData().subscribe(
      (result) => {
        this.solutionCodeMasterDataList = result;
      },
      (error) => {
        this._alertService.remove(this.alert);
        this._alertService.add(
          "Something went wrong! Please contact system administrator.",
          "danger",
          null
        );
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    );
  }

  searchSolutionCodeMapping() {
    this._alertService.remove(this.alert);
    this.getAllSolutionCodeListCount();
  }

  resetSearchFilters() {
    this._alertService.remove(this.alert);
    this.currentSolutionCodeSearchFilter = new SolutionCodeSearchFilter();
    this.currentSolutionCodeSearchFilter.solutionCodeTypeId =
      this.currentsolutionCodeTypeList[0].id;
    this.currentSolutionCodeSearchFilter.solutionCodeStatusId =
      this.solutionCodeStatusList[0].id.toString();
    this.solutionCodelistView = null;
    this.pageNumber = this.defaultPageNumber;
    this.ServieLineSelectedItems = [];
    this.SecSolutionCodeselectedItems = [];
  }

  movePage(pageNumber: number, recordsPerPage: number) {
    this.currentsolutionCodeMapping = new SolutionCodeMappingModel();
    this.showMessage = false;
    this.clearTemplateData();
    this.clearPSDData();
    this.setPage(pageNumber, recordsPerPage);
  }

  setPage(pageNumber: number, recordsPerPage: number) {
    // get pager object from service
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $("#drprecordsPerPage option:selected").text();
    if (inputDrpValue === "") {
      this.noOfrecordsPerPage = recordsPerPage;
    } else {
      this.noOfrecordsPerPage = Number(inputDrpValue);
    }

    if (this.listViewCurrentPageNumber != 0) {
      this.getSolutionCodeMappingListView(pageNumber);
      this.pager = this.pagerService.getPager(
        this.solutionCodeCnt,
        this.listViewCurrentPageNumber,
        this.noOfrecordsPerPage
      );
    } else {
      $("#previous").off("click");
      $("#first").off("click");
    }
  }

  searchSolutionCodeOnkeyDown(event) {
    if (event.keyCode == 13) {
      if (
        this.currentSolutionCodeSearchFilter.solutionCode ||
        this.currentSolutionCodeSearchFilter.solutionCodeDescription ||
        this.currentSolutionCodeSearchFilter.solutionCodeTypeId
      ) {
        this.getAllSolutionCodeListCount();
      }
    }
  }

  get diagnostic() {
    return JSON.stringify(this.currentsolutionCodeMapping);
  }

  setSecondaryCodesType() {
    if (
      this.currentsolutionCodeMapping.isAllTax ||
      this.currentsolutionCodeMapping.isAllAudit ||
      this.currentsolutionCodeMapping.isAllAdvisory
    ) {
      this.solutionCodeTypeModel.id = this.solutionCodeTypeList[0].id;
      this.currentsolutionCodeMapping.solutionCodeTypeId =
        this.solutionCodeTypeList[0].id;
    } else {
      this.solutionCodeTypeModel.id = this.solutionCodeTypeList[1].id;
      this.currentsolutionCodeMapping.solutionCodeTypeId =
        this.solutionCodeTypeList[1].id;
    }
  }

  onAttestSelected(selectedValue: string) {
    // alert(selectedValue);
    if (selectedValue === "Attest") {
      this.currentsolutionCodeMapping.isAttest = 1;
      this.currentsolutionCodeMapping.isAttested = 1;
    } else if (selectedValue === "NonAttest") {
      this.currentsolutionCodeMapping.isAttest = 0;
      this.currentsolutionCodeMapping.isAttested = 0;
    }
  }

  // ---- Add/Update solution code Mapping   ----
  updateSolutionCodeMapping() {
    this.showMessage = false;
    this.isSpecificTypeValid = true;
    if (this.currentsolutionCodeMapping.solutionCode != "") {
      if (
        this.currentsolutionCodeMapping.solutionCodeTypeId ==
        this.solutionCodeTypeList[2].id
      ) {
        // validation check for solution code type = specific
        if (
          this.secSolutionCodeList != null &&
          this.secSolutionCodeList.length == 0
        ) {
          this.isSpecificTypeValid = false;
        }
      }
      if (this.currentsolutionCodeMapping.isAdvisory === false) {
        this.currentsolutionCodeMapping.isAttest = 0;
        this.currentsolutionCodeMapping.isAttested = null;
      }

      if (
        this.currentsolutionCodeMapping.closurepromttargetdays === "" ||
        this.currentsolutionCodeMapping.closurepromttargetdays === null
      ) {
        this.currentsolutionCodeMapping.closurepromttargetdays = "-1";
      }

      if (
        this.currentsolutionCodeMapping.frequencyofprompt === "" ||
        this.currentsolutionCodeMapping.frequencyofprompt === null
      ) {
        this.currentsolutionCodeMapping.frequencyofprompt = "-1";
      }
      if (
        this.currentsolutionCodeMapping.noTimeChargedDays === "" ||
        this.currentsolutionCodeMapping.noTimeChargedDays === null
      ) {
        this.currentsolutionCodeMapping.noTimeChargedDays = "-1";
      }
      this.secondaryCodeMappingListStr = "";
      for (var i = 0; i < this.secSolutionCodeList.length; i++) {
        this.secondaryCodeMappingListStr +=
          this.secSolutionCodeList[i].solutionCodeId.toString() + ", ";
      }
      this.currentsolutionCodeMapping.secodarySolutionCodeIdList =
        this.secondaryCodeMappingListStr.replace(/,\s*$/, "");
      if (this.templateMappingList.length > 0) {
        if (this.isSpecificTypeValid)
          if (
            this.currentsolutionCodeMapping.isAudit ||
            this.currentsolutionCodeMapping.isTax ||
            this.currentsolutionCodeMapping.isAdvisory
          ) {
            this._configService
              .updateSolutionCodeMapping(this.currentsolutionCodeMapping)
              .subscribe(
                (result) => {
                  if (result === "Success") {
                    if (
                      this.currentsolutionCodeMapping.closurepromttargetdays ===
                      "-1"
                    ) {
                      this.currentsolutionCodeMapping.closurepromttargetdays =
                        null;
                    }

                    if (
                      this.currentsolutionCodeMapping.frequencyofprompt === "-1"
                    ) {
                      this.currentsolutionCodeMapping.frequencyofprompt = null;
                    }
                    if (
                      this.currentsolutionCodeMapping.noTimeChargedDays === "-1"
                    ) {
                      this.currentsolutionCodeMapping.noTimeChargedDays = null;
                    }
                    if (this.PSDMapingList.length > 0) {
                      this.updatePSDMappingData();
                    } else {
                      this.isPSDMappingExist = false;
                      this.deletePSDMappingData(
                        this.currentsolutionCodeMapping.solutionCodeId
                      );
                    }
                    if (this.templateMappingList.length > 0)
                      this.updateTemplateMappingData();
                    else this.displaySaveMessage();
                  } else {
                    this.showMessage = false;
                  }
                },
                (error) => {
                  this._alertService.remove(this.alert);
                  this._alertService.add(
                    "Something went wrong! Please contact system administrator.",
                    "danger",
                    null
                  );
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                }
              );
          }
      } else {
        this.isTemplateMappingExist = false;
      }
    }
  }

  checkSolutionCodeShortNameExists(event) {
    if (event.currentTarget.value.trim().length > 0) {
      if (
        this.beforeChangeSolutionCodeLongName !=
        this.currentsolutionCodeMapping.solutionCodeLongName
      ) {
        this.isSolutionCodeShortNameExists();
      }
    } else {
      this.checkSpaceChartBegin(event);
    }
  }

  checkSpaceChartBegin(event) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (event.currentTarget.name === "txtsolutionCodeDescription") {
        this.currentsolutionCodeMapping.solutionCodeDescription = null;
      } else if (event.currentTarget.name === "txtsolutionCodeLongName") {
        this.currentsolutionCodeMapping.solutionCodeLongName = null;
      }
      return false;
    } else {
      return true;
    }
  }

  issolutionCodeExistsInMappedList(solutionCodeId: string) {
    let retval = false;
    if (this.secondaryCodeIdMappingList.length > 0) {
      for (let i = 0; i < this.secondaryCodeIdMappingList.length - 1; i++) {
        if (
          this.secondaryCodeIdMappingList[i].id.toString() === solutionCodeId
        ) {
          retval = true;
          break;
        }
      }
    } else {
      retval = false;
    }

    if (retval) {
      this.isMappingValid = false;
      this._alertService.remove(this.alert);
      this._alertService.add(
        "Selected secondary solution code already mapped. Please select valid solution code",
        "danger",
        null
      );
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    } else {
      this.isMappingValid = true;
      this._alertService.remove(this.alert);
    }
  }

  isSolutionCodeShortNameExists() {
    let retvalue: boolean = false;
    this.loadingScreenService.startLoading();
    this._configService
      .isSolutionCodeShortNameExists(
        this.currentsolutionCodeMapping.solutionCodeLongName
      )
      .subscribe(
        (res) => {
          this.solutionCodeShortNameCount = res;
          if (this.solutionCodeShortNameCount === 0) {
            this._alertService.remove(this.alert);
          } else {
            this._alertService.remove(this.alert);
            this._alertService.add(
              "Solution code Short Name already exists.",
              "danger",
              null
            );
            retvalue = true;
          }
          this.loadingScreenService.stopLoading();
        },
        (error) => {
          retvalue = true;
          this.loadingScreenService.stopLoading();
          this._alertService.remove(this.alert);
          this._alertService.add(
            "Something went wrong! Please contact system administrator.",
            "danger",
            null
          );
        }
      );
    return retvalue;
  }

  getTemplatePackages() {
    this._hdService.getTemplatePackages().subscribe(
      (result) => {
        this.templateList = result;
      },
      (error) => {
        this.loadingScreenService.stopLoading();
        this._alertService.remove(this.alert);
        this._alertService.add(
          "Something went wrong! Please contact system administrator.",
          "danger",
          null
        );
      }
    );
  }
  validateTemplateMappingData() {
    var selectedTemplateId = $("#ddTemplate option:selected").val();
    if (selectedTemplateId != "0" && selectedTemplateId != null) {
      $("#ddTemplate").val("0");
      this.isValidTemplateSelected = true;
      var selectedTempData = this.templateList.find(
        (x) => x.templatePackageId === selectedTemplateId
      );
      var isFound = this.templateMappingList.findIndex(
        (x) => x.templatePackageId === selectedTemplateId
      );
      if (isFound < 0) {
        this.isTemplateAlreadyAdded = false;
        this.addTemplateMappingData(selectedTempData);
      } else {
        this.isTemplateAlreadyAdded = true;
      }
    } else {
      this.isValidTemplateSelected = false;
    }
  }

  removeTemplateMapping(index: number) {
    if (index >= 0) this.templateMappingList.splice(index, 1);
  }

  addTemplateMappingData(templateData: TemplatePackageModel) {
    if (templateData.templatePackageId.length > 0) {
      this.newTemplateMappingData = {
        templateName: templateData.name,
        templatePackageId: templateData.templatePackageId,
        templateVersion: templateData.liveVersionNumber,
        solutionCodeId: this.currentsolutionCodeMapping.solutionCodeId,
        createdBy: this.UserObject.loggedInUserEmail,
        createdOn: new Date(),
        lastModifiedBy: this.UserObject.loggedInUserEmail,
        lastModifiedOn: new Date(),
        documentTypeId: 0,
        subDocumentTypeId: 0,
        ischecked: false,
        templateMappingId: 0,
      };
      this.templateMappingList.push(this.newTemplateMappingData);
      this.isTemplateMappingExist = true;
    }
  }

  updateTemplateMappingData() {
    this.postMappingTemplatePackageModel.templatemappinglist =
      this.templateMappingList;
    this._configService
      .updateTemplateMapping(this.postMappingTemplatePackageModel)
      .subscribe(
        (result) => {
          if (JSON.parse(result) === "Success") this.displaySaveMessage();
          else {
            this._alertService.remove(this.alert);
            this._alertService.add(
              "Something went wrong! Please contact system administrator.",
              "danger",
              null
            );
          }
        },
        (error) => {
          this._alertService.remove(this.alert);
          this._alertService.add(
            "Something went wrong! Please contact system administrator.",
            "danger",
            null
          );
        }
      );
  }

  displaySaveMessage() {
    this.showMessage = true;
    this.showMessageText = "Solution code mapping saved";
    this.getAllSolutionCodeListCount();
    this.pageNumber = this.listViewCurrentPageNumber;
  }
  getTemplateMappingDataForSelectedSolutionCode(solutionCodeId) {
    this._configService
      .getSolutionCodeTemplateMappingData(this.documentCategory, solutionCodeId, false)
      .subscribe((result) => {
        if (result.length > 0) {
          this.templateMappingList = result;
          console.log(this.templateMappingList);
        }
      });
  }
  clearTemplateData() {
    $("#ddTemplate").val("0");
    this.templateMappingList = [];
    this.isValidTemplateSelected = true;
    this.isTemplateAlreadyAdded = false;
    this.isTemplateMappingExist = true;
  }
  ShowHideListFilterPanel(SelectedView: string) {
    const filterSectionName = "listViewFilterSectionForSolutionCode";
    switch (SelectedView) {
      case "Show":
        $("#" + filterSectionName).show();
        this.isActiveFilter = true;
        this.isShowActiveFilter = false;
        break;
      case "Hide":
        $("#" + filterSectionName).hide();
        this.isActiveFilter = false;
        this.isShowActiveFilter = false;
        break;
      case "HideWithCancel":
        this.ClearListViewFilterFields();
        $("#" + filterSectionName).hide();
        this.getAllSolutionCodeListCount();
        this.isActiveFilter = false;
        this.isShowActiveFilter = false;
        break;
      default:
        $("#" + filterSectionName).hide();
        break;
    }
  }

  ShowAllSolutionCodesWithNoFilters() {
    const filterSectionName = "listViewFilterSectionForSolutionCode";
    this.pageNumber = 1;
    this.noOfrecordsPerPage = 20;
    this.setDefaultInitialization();
    this.ClearListViewFilterFields();
    this.getAllSolutionCodeListCount();
    this.isActiveFilter = false;
    this.isShowActiveFilter = true;
    $("#" + filterSectionName).hide();
  }

  ClearListViewFilterFields() {
    this.currentSolutionCodeSearchFilter = new SolutionCodeSearchFilter();
    this.currentSolutionCodeSearchFilter.isAdvisory = false;
    this.currentSolutionCodeSearchFilter.isAudit = false;
    this.currentSolutionCodeSearchFilter.isTax = false;
    $("#hdnKey" + this.SolutionCodeFilterMappedValue + 0).val("");
    $("#txtValue" + this.SolutionCodeFilterMappedValue + 0).val("");
    this.solutionCodeStatusList.forEach((s) => {
      s.isChecked = false;
    });
  }
  getSolutionCodeStatus() {
    this._configService.getSolutionCodeStatus().subscribe((statuses) => {
      let solutioncodestatus = new SolutionCodeStatusModel();
      statuses.forEach((element) => {
        if (!element.typeValue.includes("All Status")) {
          solutioncodestatus = element;
          this.solutionCodeStatusList.push(solutioncodestatus);
        }
      });
    });
  }
  changeSolutionCodeStatus() {
    this.currentSolutionCodeSearchFilter.solutionCodeStatusId = "";
    this.solutionCodeStatusList.forEach((s) => {
      if (s.isChecked) {
        if (this.currentSolutionCodeSearchFilter.solutionCodeStatusId === "") {
          this.currentSolutionCodeSearchFilter.solutionCodeStatusId =
            s.id.toString() + ",";
        } else {
          this.currentSolutionCodeSearchFilter.solutionCodeStatusId =
            this.currentSolutionCodeSearchFilter.solutionCodeStatusId +
            s.id.toString() +
            ",";
        }
      }
    });
    if (
      this.currentSolutionCodeSearchFilter.solutionCodeStatusId != null &&
      this.currentSolutionCodeSearchFilter.solutionCodeStatusId.includes(",")
    ) {
      this.currentSolutionCodeSearchFilter.solutionCodeStatusId =
        this.currentSolutionCodeSearchFilter.solutionCodeStatusId.slice(0, -1);
    }
    console.log(this.currentSolutionCodeSearchFilter.solutionCodeStatusId);
  }
  OnSolutionCodeChange() {
    const selectedFilterSolutionCode = $(
      "#hdnKey" + this.SolutionCodeFilterMappedValue + 0
    ).val();
    const inputFilterValue = $(
      "#txtValue" + this.SolutionCodeFilterMappedValue + 0
    ).val();
    if (selectedFilterSolutionCode !== "" && inputFilterValue !== "") {
      this.currentSolutionCodeSearchFilter.solutionCode =
        selectedFilterSolutionCode;
    } else {
      this.currentSolutionCodeSearchFilter.solutionCode = "";
    }
  }
  initializeRequestTriggers() {
    this.requestTriggerOptions = [];
    const requestTriggerApporval = new RequestTriggerOptions();
    requestTriggerApporval.optionValue = 1;
    const requestTriggerSignature = new RequestTriggerOptions();
    requestTriggerSignature.optionValue = 2;
    this.requestTriggerOptions.push(requestTriggerApporval);
    this.requestTriggerOptions.push(requestTriggerSignature);
    this.currentsolutionCodeMapping.uponApproval = true;
    this.TriggerOption = 1;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (/[0-9]/.test(String.fromCharCode(charCode))) {
      return true;
    }
    return false;
  }

  refreshPSDPopup(index, textboxName: string) {
    this.removeRowSelection("tblPSDAD", "searchAsPSDName", "PSDErrorMessage");
    index = this.PSDpeoplepickerIndex;
    this.PSDNameCtrl =
      textboxName + this.PSDRoleName + "_" + this.PSDpeoplepickerIndex;
    this.ASPSDCtrl =
      textboxName + "AS_" + this.PSDRoleName + "_" + this.PSDpeoplepickerIndex;

    this.validatePSD = false;
    if ($("#" + this.ASPSDCtrl).hasClass("text-danger")) {
      $("#" + this.ASPSDCtrl).removeClass("text-danger");
    }
  }

  removeRowSelection(tableId, textboxId, errorMessageId) {
    $("#" + textboxId).val("");
    $("#" + errorMessageId).text("");
    const searchText = $("#" + textboxId)
      .val()
      .toLowerCase()
      .trim();
    this.searchResult(tableId, searchText);
    $("#" + tableId + " tr").each(function () {
      if ($(this).hasClass("highlight")) {
        $(this).removeClass("highlight");
      }
    });
  }

  searchResult(tableId, searchText) {
    $("#" + tableId + " tr").each(function (index) {
      if (!index) return;
      $(this)
        .find("td")
        .each(function () {
          var id = $(this).text().toLowerCase().trim();
          var not_found = id.indexOf(searchText) === -1;
          $(this).closest("tr").toggle(!not_found);
          return not_found;
        });
    });
  }

  searchUsers(searchTextBox, tableId: string) {
    console.log("searchUsers func called", searchTextBox, tableId);
    const searchText = $("#" + searchTextBox).val();
    if (tableId === "tblPSDAD") {
      this.GetPSDs(searchText);
    }
  }

  GetPSDs(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel).subscribe(
      (userList) => {
        this.loadingScreenService.stopLoading();
        this.membersListArray = userList.filter(
          (item) =>
            item.lineOfBusiness !=
            this.appService.config.DATA.LINE_OF_BUSINESS_ID_PS
        );
      },
      (error) => {
        console.log("Error getting result ", error);
        this.membersListArray = null;
        this.loadingScreenService.stopLoading();
        this.alertServiceFun(
          "Something went wrong! Please contact system administrator.",
          "danger"
        );
      }
    );
  }

  alertServiceFun(message, type) {
    this._alertService.remove(this.alert);
    this._alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  selectPSD(index) {
    $("#tblPSDAD").on("click", "tbody tr", function () {
      $(this).addClass("highlight").siblings().removeClass("highlight");
    });
    this.selectedPSDIndex = index;
  }

  setPSD() {
    // if($('#searchAsPSDName').val() != '')
    // {
    let index = this.selectedPSDIndex;
    if (Number(index) >= 0) {
      const selectedPSDName = $("#spPSDdisplayName" + index).text();
      const selectedPSDEmail = $("#spPSDmail" + index).text();
      const selectedPSDPhone = $("#spPSDbusinessPhones" + index).text();
      this.PSDDisplayUserName = selectedPSDName;
      this.PSDDisplayUserEmail = selectedPSDEmail;
      this.validatePSD = true;
      $("#PSDErrorMessage").text("");

      if (this.ASPSDCtrl !== "") {
        $("#" + this.ASPSDCtrl).val(selectedPSDName);
        $("#UserHiddenProps_AS_SM_0 #hdnUserName").val(selectedPSDName);
        $("#UserHiddenProps_AS_SM_0 #hdnUserEmail").val(selectedPSDEmail);
        $("#UserHiddenProps_AS_SM_0 #hdnUserPhone").val(selectedPSDPhone);
        $("#hdnValidInput_AA_0").val(true);
      }
    }

    $("#searchAsPSDName").val("");
    this.membersListArray = null;
    //}
    // else{
    //   $('#modal-PSD').show();
    //   $('#PSDErrorMessage').text('Enter PSD Name.');
    // }
  }

  deselectPSD() {
    this.membersListArray = null;
    $("#searchAsPSDName").val("");
    $("#" + this.PSDNameCtrl).val("");
    $("#" + this.PSDNameCtrl).innerText = "";
    $("#" + this.ASPSDCtrl).val("");
    $("#" + this.ASPSDCtrl).innerText = "";
  }

  validatePSDMappingData() {
    var selectedPSDName = $("#txtPeoplePicker_AS_SM_0").val();
    var selectedPSDEmail = $("#hdnUserEmail").val();
    this.isvalidPSD = Boolean(JSON.parse($('#hdnValidInput_AS_SM_0').val()));
    if (selectedPSDName != "") {
      const selectedTempData = {
        displayName: $("#hdnUserName").val(),
        mail: $("#hdnUserEmail").val(),
        mobilePhone: $("#hdnUserPhone").val(),
        businessPhones: "",
        jobTitle: $("#hdnJobTitle").val(),
        lineOfBusiness: "",
        serviceLine: "",
        location: $("#hdnOfficeLocation").val(),
        jobCode: $("#hdnJobCode").val(),
      };
      var isFound = this.PSDMapingList.findIndex(
        (x) => x.psdEmailId === selectedPSDEmail
      );
      if (isFound < 0 ) {
        $("#txtPeoplePicker").val("");
        $("#PSDErrorMessage").text("");
        this.isValidPSDSelected = true;
        this.isPSDAlreadyAdded = false;
        if (this.isvalidPSD) {
          this.addPSDMappingData(selectedTempData);
        }        
        $("#txtPeoplePicker_AS_SM_0").val("");
      } else {
        this.isPSDAlreadyAdded = true;
        $("#PSDErrorMessage").text("PSD is already added.");
        $("#txtPeoplePicker_AS_SM_0").val("");
      }
    } else {
      this.isValidPSDSelected = false;
      this.isvalidPSD = true;
      $("#PSDErrorMessage").text("Select PSD name");
    }
  }

  addPSDMappingData(PSDsListArray: MembersList) {
    if (PSDsListArray.displayName != "" && PSDsListArray.mail != "") {
      this.newPSDMappingData = {
        id: 0,
        solutioncodeId: this.currentsolutionCodeMapping.solutionCodeId,
        psdName: PSDsListArray.displayName,
        psdEmailId: PSDsListArray.mail,
        createdBy: this.UserObject.loggedInUserEmail,
        createdOn: new Date(),
        updatedBy: this.UserObject.loggedInUserEmail,
        updatedOn: new Date(),
      };
      this.PSDMapingList.push(this.newPSDMappingData);
      this.isPSDMappingExist = true;
    }
  }

  removePSDMapping(index: number) {
    if (index >= 0) this.PSDMapingList.splice(index, 1);
  }

  updatePSDMappingData() {
    this.postMappingPSDModel.psdMappinglist = this.PSDMapingList;
    this._configService
      .insertupdatePSDMapping(this.postMappingPSDModel)
      .subscribe(
        (result) => {
          if (JSON.parse(result) === "Success") this.displaySaveMessage();
          else {
            this._alertService.remove(this.alert);
            this._alertService.add(
              "Something went wrong! Please contact system administrator.",
              "danger",
              null
            );
          }
        },
        (error) => {
          this._alertService.remove(this.alert);
          this._alertService.add(
            "Something went wrong! Please contact system administrator.",
            "danger",
            null
          );
        }
      );
  }

  deletePSDMappingData(solutionCodeId) {
    this._configService.deletePSDMappingData(solutionCodeId).subscribe(
      (result) => {
        if (result === "Success") this.displaySaveMessage();
        else {
          this._alertService.remove(this.alert);
          this._alertService.add(
            "Something went wrong! Please contact system administrator.",
            "danger",
            null
          );
        }
      },
      (error) => {
        this._alertService.remove(this.alert);
        this._alertService.add(
          "Something went wrong! Please contact system administrator.",
          "danger",
          null
        );
      }
    );
  }

  getPSDMappingDataForSelectedSolutionCode(solutionCodeId) {
    $("#PSDErrorMessage").text("");
    this._configService
      .getPSDMappingData(solutionCodeId)
      .subscribe((result) => {
        if (result.toString != null) {
          this.PSDMapingList = result;
          console.log(this.PSDMapingList);
        }
      });
  }

  clearPSDData() {
    $("#txtPeoplePicker_AS_SM_0").val("");
    this.PSDDisplayUserName = "";
    this.PSDMapingList = [];
    this.isValidPSDSelected = true;
    this.isPSDAlreadyAdded = false;
    this.isPSDMappingExist = true;
    this.isvalidPSD = true;
  }
}

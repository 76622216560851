<div class="modal fade" id="engagement-prerquest-popup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog" role="prerequest">
      <div class="modal-content ">
        <div class="message-dialog-wrapper">
          <div class="message-dialog-container">
  
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="closeEngagementPreRequestComponentModal()">
              <span style="font-size: 15px;vertical-align: middle;margin-right: 3px;">Close</span>
              <span style="vertical-align: top;" aria-hidden="true">&times;</span>
            </button>
            <div class="d-flex justify-content-center mt-3">
              <div *ngIf="!isPreRequest" class="message-background-icon information">
                <img src="../../../../assets/images/EDP/MessageDialog/information.svg" class="message-icon"
                  alt="information" />
              </div>
              <div *ngIf="isPreRequest" class="message-background-icon warning">
                <img src="../../../../assets/images/EDP/MessageDialog/warning.svg" class="message-icon"
                  alt="warning" />
              </div>
            </div>
            <div *ngIf="!isPreRequest" class="d-flex text-center message-title information ml-2 pl-2 pr-1 pt-2">Continue without pre-requesting an assignment code?</div>
              <div *ngIf="isPreRequest" class="d-flex text-center message-title warning ml-2 pl-2 pr-1 pt-2">Use the prior assignment's information?</div>
            <div class="message-description desc-height">
              <div *ngIf="!isPreRequest" class="extension-date mt-3">
                Click “Cancel” to go back and pre-request your assignment code(s). Click “OK” to proceed to the questionnaire without pre-requesting any assignment codes.
              </div>
              
              <div *ngIf="isPreRequest" class="extension-date mt-3">
                This will reset any entered information for prior assignments to their original values. Are you sure you would like to proceed?
              </div>
              <div *ngIf="isPreRequest" class="mt-3 text-center pre-request">
                <button (click)="PreRequestCancel()"
                  class="btn SecondaryButton rounded-0 warning CancelBtn mr-3">
                  <span class="d-inline-block mr-1 align-top">
                    <img src="../../../../assets/images/EDP/MessageDialog/cancel-warning-24px.svg" alt="CANCEL" />
                  </span>
                  <span class="d-inline-block align-middle">Cancel</span>
                </button>
                <button (click)="PreRequestOk()" class="btn rounded-0 warning">
                  <span class="d-inline-block mr-1 align-top">
                    <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
                  </span>
                  <span class="d-inline-block align-middle">OK</span>
                </button>
              </div>
              <div *ngIf="!isPreRequest" class="mt-3 text-center non-pre-request">
                <button (click)="NonPrequestCancel()"
                  class="btn SecondaryButton rounded-0 information CancelBtn mr-3">
                  <span class="d-inline-block mr-1 align-top">
                    <img src="../../../../assets/images/EDP/MessageDialog/cancel-information-24px.svg" alt="CANCEL" />
                  </span>
                  <span class="d-inline-block align-middle">Cancel</span>
                </button>
                <button (click)="NonPrequestOk()" class="btn rounded-0 information">
                  <span class="d-inline-block mr-1 align-top">
                    <img src="../../../../assets/images/EDP/MessageDialog/delete-24px.svg" alt="OK" />
                  </span>
                  <span class="d-inline-block align-middle">OK</span>
                </button>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
export class DecisionsRPRequestModel{
    decisionsApiEndpoint: string;
    decisionsRPRequest: DecisionsRPRequest = new DecisionsRPRequest();
}

export class DecisionsRPRequest
{
    sessionid: string;
    outputtype: string;
    engageRequest: EngageReuest = new EngageReuest();
}
export class DecisionsAssignmentRequest{
    cmsppmdEmail: string[] = [];
    eqcrEmail: string[] = [];
    solutionCode: string;
    estimatedNetFees: number;
    estimatedGrossMargin: number;
}

export class AutoRpMembersWithDelegateModel{
    workspaceId: string;
    loggedInUser: string;
    loggedInUserFullName: string;
    autoRpMembersWithDelegateList: AutoRpMembersWithDelegate[] = [];    
}

export class ApproveRejectWorkspaceResultModel {
    approveRejectResult: string;    
    autoRpMembersWithDelegateList: AutoRpMembersWithDelegate[] = [];    
}


export class AutoRpMembersWithDelegate {    
    rpEmailId: string;
    delegateMemberName: string;
    delegateMemberEmailId: string;    
}

export class PsdMembersWithDelegate {    
    psdEmailId: string;
    psdDelegateMemberName: string;
    psdDelegateMemberEmailId: string;    
}

export class EngageReuest
{
    hotDocsDocumentId: string;
    engagementPartnerOffice: string;
    engagementPartnerEmail: string;
    engagementID: string;
    solutionCodeAssignments: DecisionsAssignmentRequest[] = [];
    continuanceFlag: boolean;
    clientCode: string;
}
import { User } from '../User';

export class NavigationRoleViewModel extends User{
    _Id: number;
    _Type: number;
    _Title: string;
    _ImagePath: string;
    _Link: string;
    _Sequence: number;
    _Roles: number[];

    constructor(
        public navigationId: number,
        public navigationTypeId: number,
        public title: string,
        public imagePath: string,
        public link: string,
        public sequence: number,
        public roles: number[]
      ) {
        super();
          this._Id = navigationId;
          this._Type = navigationTypeId;
          this._Title = title;
          this._ImagePath = imagePath;
          this._Link = link;
          this._Sequence = sequence;
          this._Roles = roles;
      }
}

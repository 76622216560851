export class TemplatePackageModel {
    templatePackageId: string;
    name: string;
    liveVersionNumber: number;
    description: string;
    isActive: boolean;
    checked: boolean;
}


export class MappingTemplatePackageModel {

  templateMappingId: number;
  documentTypeId: number;
  templatePackageId: string;
  templateName: string;
  templateVersion: number;
  createdBy: string;
  createdOn: Date;
  lastModifiedBy: string;
  lastModifiedOn: Date;
  subDocumentTypeId: number;
  ischecked: boolean;
  solutionCodeId : number;

}

export class PostMappingTemplatePackageModel {
  templatemappinglist: MappingTemplatePackageModel[];
}

export class AddWorkspaceDocumentValidationModel
{
  engagementId: number;
  templateName: string;
  templatePackageId: string;
  documentAliasName: string;
  IsTemplateSelected: boolean = true;
  IsDocumentAliasNameEmpty: boolean = false;
  IsDocumentAliasNameDuplicate: boolean = false;
  IsDocumentAliasMaxLengthExceeded: boolean = false;
}


export class ClientVoiceSurveyRequest {
    engagement: ClientVoiceEngagement = new ClientVoiceEngagement();
    teamMembers: ClientVoiceTeamMember[] = [];
    clientMembers: ClientVoiceClientMember[] = [];
}

export class ClientVoiceSurveyOptOut {
    clientCode: string;
    ePPMDName: string;
    ePPMDEmailId: string;
    memberEmail: string;
    memberName: string;
    optOutReason: string;
    optOutDate: string;
    engagementId: string;
}

export class ClientVoiceSurveyOptOutRequest {
    engagement: ClientVoiceEngagement = new ClientVoiceEngagement();
    clientVoiceSurveyOptOut: ClientVoiceSurveyOptOut = new ClientVoiceSurveyOptOut();
}

export class ClientVoiceEngagement {
    engagementId: string;
    engagementName: string;
    primarySolutionCode: string;
    clientCode: string;
    clientName: string;
    eppmdName: string;
    eppmdEmailId: string;
    surveyDate: string;
    createdBy: string;
    createdOn: string;
}

export class ClientVoiceTeamMember {
    engagementId: string;
    memberName: string;
    memberEmail: string;
    memberRole: string;
    name: string;
}

export class ClientVoiceClientMember {
    engagementId: string;
    contactGuid: string;
    clientFirstName: string;
    clientLastName: string;
    clientEmail: string;
    clientTitle: string;
    name: string;
}

export class ClientVoiceApiLog {
    engagementId: number;
    requestPayload: string;
    responseBody: string;
    loggedInUser: string;
}

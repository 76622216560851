export class IndusMemberEmailIds {
    memberEmailId: string;
}

export class UpdateINDUSConsentFlag{
    engagementId: number;
    collaborationSiteId: number;
    isIndus: boolean;
    lastModifiedBy: string;
    indusMemberEmailIds: IndusMemberEmailIds[];
}

export class CollaborationSiteMembersForConsentTab{
    collaborationSiteId: number;
    memberName: string;
    memberEmailId: string;
    memberRoleId: number;
    memberPermissionId: number;
}
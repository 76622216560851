//import { ByteDocument } from '../DocumentListModel';

import { SharePointWrapper } from "../sharepoint-metadata-wrapper";
import { UpdatePermissionsModel } from "./ActiveDirectory";

export class SharepointSiteModel {
    siteType: string;
    engagementId: number;
    siteUniqueID: string;
    department: string;
    siteTitle: string;
    primaryAdmin: string;
    primaryContact: string;
    secondaryContact: string;
    siteOwnerEmailIds: string[];
    siteMemberEmailIds: string[];
    siteVisitorEmailIds: string[];
    metadata: SPSiteMetadata[];
    clientName: string;
    collaborationSiteId: number;
    primarySiteUniqueId: string;
    decisionTemplate: DecisionTemplate[];
    clientSiteDetails: UpdatePermissionsModel;
}

export class SharepointSiteAndUpdateSharePointMetadataModel {

    sharepointSiteArr: SharepointSiteModel[];
    sharePointWrapperArr: SharePointWrapper[];
}


export class DecisionTemplate {
    templateName: string;
    policyID: string;
    policyName: string;
    acceptanceFolderPath: string;
    supplementalFolderPath: string;
}

export class SPSiteMetadata {
    id: string;
    name: string;
    value: string;
    datatype: string;
}

export class AvepointResponse {
    siteType: string;
    requestId: string;
    ticketnumber: string;
    siteUniqueId: string;
    siteUrl: string;
    sPGroups: SPGroupDetails[];
    engagementId: number;
    collaborationSiteId: number;
}
export class AvepointResponseModel {
    responseStatus: string;
    errorMessage: string;
    avepointResponseArray: AvepointResponse[] = [];
}

export class AvepointResponseDataModel {
    avepointResponseArr: AvepointResponseModel[] = [];
}

export class AvepointResponseListModel {
    avepointResponseArray: AvepointResponse[] = [];
}

export class SPGroupDetails {
    sPGroupName: string;
    aDGroupName: string[];
}

export class SharepointSiteDocumentModel {
    files: SPDocument[];
    siteURL: string;
    documentType: string;
    documentFolderPath: string;
    useremailId: string
}
export class SharepointUploadSiteDocumentModel {
    SiteId: string;
    DocumentLibraryName: string;
    DocumentPath: string;
    DocumentName: string;
    DocumentByteArray: number[];
    siteURL: string;
    documentFolderPath: string;
    useremailId: string;
}

export class SPDocument {
    fileName: string;
    fileByteArray: number[];
    workItemId?: string;
}

export class DocumentParamModel {
    documentId: string
    workItemId: string;
}

export class SPDocumentList {
    documentId: number;
    siteURL: string;
    documentName: string;
    hyperLink: string;
    documentUrl: string;
    documentEditUrl: string;
    modifiedBy: string;
    modifiedDate: string;
    type: string;
    label: string;
    workspaceId: string;
    createdDate: string;
    documentBytes: any;
    modifiedByEmail: string;
    isChecked: boolean;
}

export enum ContactCardControl {
    suppDocdiv = 'suppDocModifiedByPopup_',
    close = 'contact-close',
    popup = 'contact-popup'
}

export enum ContactCardClick {
    suppDocModifiedBy = 'modifiedByIndex'
}

export class DecisionsTemplateResponseModel {
    isSuccess: boolean;
    templateSelection: DecisionsTemplateResponse[];
}

export class DecisionsTemplateResponse {
    templateName: string;
    policyID: string;
    policyName: string;
    acceptanceFolderPath: string;
    supplementalFolderPath: string;
}

export class DecisionsTemplateRequestModel {
    sessionid: string;
    outputtype: string;
    templateRequest: SiteInputRequest[];
}

export class SiteInputRequest {
    siteClassification: string;
    solutionCode: string;
    clientCode: string;
}

export interface ApplySitePolicyRequestModel {
    primarySiteUrl: string;
    siteUrls: string[];
    resetSiteStartDate: boolean;
}
<div id="contact-popup" *ngIf="contact != null" class="d-none">
  <div class="contact-close float-right">
    Close <img src="../../../../assets/images/EDP/Workspace/close-24px.svg" alt="Close" />
  </div>
  <div class="contact-popup-container">    
    <div class="contact-name">
      {{contact.displayName}}
    </div>
    <div class="contact-job-title">
      Grant Thornton • {{contact.jobTitle}}
    </div>
    <div class="contact-email mt-1">
      Email: <a href="mailto:{{contact.mail}}">{{contact.mail}}</a>
    </div>
    <div class="contact-phone">
      Phone: <a href="tel:+1{{contact.businessPhones}}">(+1) {{contact.businessPhones}}</a>
    </div>
  </div>
</div>
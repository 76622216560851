export enum AutoCompleteMappedSource {
   InitiateEngagementSolutionCode = 'InitiateEngagementSolutionCode',
   CreateClientSICCode = 'CreateClientSICCode',
   CreateClientReferralSource = 'CreateClientReferralSource',
   SolutionCodeConfiguration = 'SolutionCodeConfiguration',
   ClientMappedValue = 'ClientMappedValue',
   ClientListMappedValue = 'ClientListMappedValue',
   ClientCodeCardMappedValue = 'ClientCodeCardMappedValue',
   ClientCodeListMappedValue = 'ClientCodeListMappedValue',
   ContinuanceListMappedValue = 'ContinuanceListMappedValue',
   UniqueClientCodeForClient = 'UniqueClientCodeForClient',
   UniqueClientCodeForEngagement = 'UniqueClientCodeForEngagement',
   UniqueClientCodeForContinuance = 'UniqueClientCodeForContinuance',
}

export enum AutoCompleteForDocument{
   ClientsForDocument = 'ClientsForDocument',
   EngagementsForDocument = 'EngagementsForDocument',
   DocumentTypeForDocument= 'DocumentTypeForDocument'
}


export class EngagementCompletionDateViewModel {   
    engagementId: number;
    completionDate:Date;
    loggedInUser: string; 
    loggedInUserName: string;
    isEngagementCompletionDate:boolean
}

export class EngCompletionDtEditViewModel {    
    editedcompletionDate: Date
    isEngCompletionDateFutureDt: boolean = true;
    isEngCompletionDateRequired: boolean = true;
    isEngCompletionDateSame : boolean = false;
    isEngCompletionDateValid: boolean = true;    
    completionDateEditMode = false;
}


export class EngCompletionTypes {
    type: string;
    value : number;
  
    constructor(type: string, value: number) {
      this.type = type;
      this.value = value;   
    }
  }
  
  export class EngCompletionViewModel {  
    engagementId: number = 0
    engCompletionTypeId: number = 0;
    engCompletionDate: Date;
    isEngCompletionTypeSelected: boolean = true;
    isCompletionDateSelected: boolean = true;
    isEngCompletionDateSame : boolean = false;
    resultMessage: string;
  }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './appConfigService';

import { ADGroup, ADGroupUsers, UpdatePermissionResponseModel, UpdatePermissionsModelData, UpdatePermissionsModelDataWithINDUSConsent } from '../model/SharePoiint/ActiveDirectory';
import { RPPermissionModel } from '../model/engagements';
import { AzureADUser } from '../model/ClosureNotification/azure-ad-user.mode';

@Injectable({
  providedIn: 'root'
})
export class ActiveDirectoryService {

  IntegrationAPIEndPoint: string;
  constructor(private http: HttpClient, private environment: AppConfigService) {
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  UpdatePermissions(updatePermissionsModelData: UpdatePermissionsModelData): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/UpdatePermissions/', updatePermissionsModelData, { responseType: 'text' as 'json' });
  }

  UpdatePermissionsWithClientUsers(updatePermissionsModelDataWithINDUSConsent: UpdatePermissionsModelDataWithINDUSConsent): Observable<UpdatePermissionResponseModel> {
    return this.http.post<UpdatePermissionResponseModel>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/UpdatePermissionsWithClientUsers/', updatePermissionsModelDataWithINDUSConsent);
  }

  GetUsersFromADGroup(siteUniqueId: string): Observable<ADGroupUsers[]> {
    return this.http.get<ADGroupUsers[]>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/GetUsersFromADGroup?siteUniqueId=' + siteUniqueId);
  }

  UpdateRPPermission(rpPermissionModel: RPPermissionModel): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/UpdateRPPermission/', rpPermissionModel, { responseType: 'text' as 'json' });
  }
  UpdatePermissionsInAcceptance(updatePermissionsModelData: UpdatePermissionsModelData): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/UpdatePermissionsInAcceptance/', updatePermissionsModelData, { responseType: 'text' as 'json' });
  }
  GetADOwnerUsersByGroups(siteUniqueID:string):Observable<any>{
    let body={
        "groups": [
          siteUniqueID+"_Owners"
        ],
        "adUserDetails": []
    }
    return this.http.post<any>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/GetADOwnerUsersByGroups', body);
  }
  GetADMemberUsersByGroups(siteUniqueID:string):Observable<any>{
    let body={
        "groups": [
          siteUniqueID+"_Members"
        ],
        "adUserDetails": []
    }
    return this.http.post<any>(this.IntegrationAPIEndPoint +
      '/api/ActiveDirectory/GetADMemberUsersByGroups', body);
  }
  AddUsersToADGroup(groupName:string,payload:ADGroup):Observable<any>{
    
    const url = `${this.IntegrationAPIEndPoint}/api/ActiveDirectory/AddAuditPartnerToADGroup?groupName=${groupName}`;
      return this.http.post<any>(url, payload);
  }
  DeleteUsersToADGroup(groupName:string,payload:ADGroup):Observable<any>{
    
    const url = `${this.IntegrationAPIEndPoint}/api/ActiveDirectory/DeleteAuditPartnerToADGroup?groupName=${groupName}`;
      return this.http.post<any>(url, payload);
  }
}

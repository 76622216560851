export class ReacceptanceClientListModel {
    reacceptanceEngagementId: number;
    cmsClientCode: string;
    clientName: string;
    engagementName: string;
    engagementPartner: string;
    engagementPartnerName: string;
    solutionCodeId: number;
    scId: string;
    solutionCode: string;
    solutionCodeStatus: string;
    solutionCodeDesc: string;
    eligibilityDate: string;
    isUserExist: boolean;
    isEngagementPartner: boolean;
    createdBy: string;
    createdOn: string;
    lastModifiedBy: string;
    lastModifiedOn: string;
    solutionCodeLongName: string;
    clientId: number;
    engagementId: number;
    isReAcceptanceEligibility: boolean;
    periodEndDate: string;
    flag: number;
}

export class ReacceptanceListModel{
    engagementPartner: string;
    isUserExist: boolean;
}

export class ArrayOfReacceptanceClientListModel{
    reacceptanceClientListModel: ReacceptanceClientListModel[];
}

export class ContinuanceEngagementWorkItemModel{
    engagementId: number;
    workspaceId:string;
    continuanceWorkItemId : string;
    hDTemplatePackageId : string;
    continuanceStatus :string;
    reacceptanceEngagementId: number;
    engagementCount: number;
    collaborationSiteid: number;
    continuanceTemplateChangeAnsCarryForward: boolean;
    isTemplateChanged:boolean;
    isIndus:boolean;
    isSiteRequestSent: boolean = false;
}

export enum ContinuanceStatus{
    Draft ="Draft",
    Pending = "Pending",
    Complete = "Complete"
}

<!-- Start Container -->
<app-loading-screen></app-loading-screen>
<div class="clientContainer containerDiv">
  <!-- Page Title row -->
  <div class="d-flex justify-content-between pageTitleParent pb-3 mw-100 mt-3">
    <div class="text-center">
      <h2 class="float-md-left pageTitle largerFontSize2">Edit client</h2>
    </div>
    <div class="text-center">
      <span class="float-md-right">
        <app-shortcut></app-shortcut>
      </span>
    </div>
  </div>
  <!-- End Page Title row -->


  <form novalidate #CreateClientForm="ngForm">
    <!--Main Div under CreateClientForm-->
    <div class="bg-edp-body pb-3">
      <div class="row py-3 ml-0">
        <div class="col-6 col-sm-12 col-lg-6">
          <label>Client type </label>
          <div class="selectdiv1 font-weight-bold">
            {{ client.clientType }}
          </div>
        </div>
      </div>

      <div class="row py-3 ml-0" *ngIf="!isPersonalClientType">
        <div class="col-6 col-sm-12 col-lg-6">
          <label> Client name </label>
          <div class="font-weight-bold">
            {{ client.clientName }}
          </div>
        </div>
      </div>

      <div class="row py-3 ml-0" *ngIf="isPersonalClientType">
        <div class="col-md-4">
          <label> First name </label>
          <div class="font-weight-bold">
            {{ client.firstName }}
          </div>
        </div>

        <div class="col-md-4">
          <label> Middle name </label>
          <div class="font-weight-bold">
            {{ client.middleName }}
          </div>
        </div>

        <div class="col-md-4">
          <label> Last name</label>
          <div class="font-weight-bold">
            {{ client.lastName }}
          </div>
        </div>
      </div>

      <div class="row py-3 ml-0">
        <div class="col-6 col-lg-6">
          <label>Industry</label>
          <div class="selectdiv1 font-weight-bold">
            {{ client.industryType }}
          </div>
        </div>
        <div class="col-6 col-lg-6">
          <label> SIC code</label>
          <div class="font-weight-bold">
            {{ client.sicCode }}
          </div>
        </div>
      </div>
      <div class="row py-3 ml-0">
        <div class="col-6 col-sm-12 col-lg-6">
          <label>Structure/entity<span class="mandatory">*</span> </label>
          <div class="selectdiv1 font-weight-bold">
            {{ client.structEntity }}
          </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-6">
          <label> Client code </label>
          <div class="font-weight-bold">
            {{ client.clientCode }}
          </div>
        </div>
      </div>
      <div class="row py-3 ml-0" *ngIf="!isPersonalClientType">
        <div class="col-md-6">
          <label for="">Stock exchange </label>
          <div class="selectdiv1 font-weight-bold">
            {{ client.stockExchange }}
          </div>

        </div>
        <div class="col-md-6">
          <label for="">Ticker symbol </label>
          <div class="font-weight-bold">
            {{ client.tickerSymbol }}
          </div>
        </div>
      </div>

      <div class="row py-3 ml-0">
        <div class="col-6 col-md-6 col-lg-6">
          <label>Client program</label>
          <div class="selectdiv">
            <select disabled class="dropdown rounded-0 " name="ddClientProgram" id="ddClientProgram" #ddClientProgram="ngModel"
              [(ngModel)]="client.clientProgramId">
              <option [value]="undefined" selected disabled hidden>Select from existing client programs </option>
              <option *ngFor="let clientprogram of clientProgramTypes" [value]="clientprogram.applicationMasterId">
                {{clientprogram.value}}
              </option>
            </select>
          </div>
          <div *ngIf="!isValidClientProgram" class="error-color">
            Client program field is required
          </div>
        </div>

      </div>

      <div class="row py-3 ml-0">
        <div class="col-6 col-sm-12 col-lg-6">
          <label>Referral source </label>
          <div class="font-weight-bold">
            {{ client.referralSource }}
          </div>
        </div>
      </div>

      <div class="row py-3 ml-0">
        <div class="col-6 col-sm-12 col-lg-6">
          <label>Fiscal year end month</label>
          <div class="selectdiv1 font-weight-bold">
            {{ client.fyeMonthName }}
          </div>
        </div>
      </div>

      <div class="row py-3 ml-0">
        <div class="col-3 col-sm-6 col-lg-3">
          <label for="">Phone number </label>
          <div class="font-weight-bold">
            {{ client.phoneNumber }}
          </div>
        </div>

        <div class="col-3 col-sm-6 col-lg-4">
          <label for="">Fax number</label>
          <div class="font-weight-bold">
            {{ client.fax }}
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-md-6 mt-3">
          <label for="">Web site</label>
          <input class="form-control rounded-0 border input-height" type="text" name="txtWebsite" id="txtWebsite"
          value=""
          maxlength="100" [(ngModel)]="client.websiteAddress" #website="ngModel" maxlength="100"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          placeholder="http:www.url.com" autocomplete="off"
          (blur)="ValidateWebsiteAddress(website)">
          <div *ngIf="!isValidWebsiteUrl" class="error-color">
            Web site url is not valid
          </div>
        </div>
      </div>
      <!-- address fields -->
      <section>
        <div class="row  ml-0 mr-0">
          <div class="col-6 col-sm-12 col-lg-6 addressFields">
            <div class="row ">
              <div class="col-md-12">
                <label for="">Address 1 <span class="mandatory">*</span></label>
                <div class="font-weight-bold">
                  {{ client.addressLine1 }}
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <label for="">Address 2</label>
                <div class="font-weight-bold">
                  {{ client.addressLine2 }}
                </div>
              </div>
            </div>
            <div class="row ml-0">
              <div class="col-md-12 mt-3 px-0">
                <label for="">Address 3</label>
                <div class="font-weight-bold">
                  {{ client.addressLine3 }}
                </div>
              </div>
              <div class="col-md-12 mt-3 px-0">
                <label for="">Address 4</label>
                <div class="font-weight-bold">
                  {{ client.addressLine4 }}
                </div>
              </div>
            </div>
            <div class="row ml-0">
              <div class="col-md-6  px-0 mt-3">
                <label for="city">City </label>
                <div class="selectdiv1 font-weight-bold">
                  {{ client.city }}
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <label for="zipCode">Zip code</label>
                <div class="selectdiv1 font-weight-bold">
                  {{ client.zipCode }}
                </div>
              </div>
              <div class="col-md-6 px-0 mt-3">
                <label for="countryName">Country</label>
                <div class="selectdiv1 font-weight-bold">
                  {{ client.countryName }}
                </div>
              </div>
              <div class="col-md-6  mt-3">
                <label for="stateName">State</label>
                <div class="selectdiv1 font-weight-bold">
                  {{ client.stateName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end address fields -->

      <!--Start Client Contact Details -->
      <div class="clientContacts mt-4">
        <h5 class="ml-3 edit-heading">Client contacts </h5>
        <div class="row py-3 ml-0">
          <div class="col-md-4">
            <label>Business contact </label>
            <div class="font-weight-bold">
              {{ client.clientContactName }}
            </div>
          </div>

          <div class="col-md-4">
            <label>Title</label>
            <div class="font-weight-bold">
              {{ client.clientContactTitle }}
            </div>
          </div>

          <div class="col-md-4">
            <label>Email <span class="mandatory">*</span></label>
            <input placeholder="Email" class="form-control rounded-0 border input-height bg-white" maxlength="80"
              id="txtBusinesscontactEmail" name="txtBusinesscontactEmail" #businessEmail="ngModel" [(ngModel)]="client.clientContactEmail" value="{{client.clientContactEmail}}"
              autocomplete="off">
              <div class="error-color" *ngIf="!isValidBusinessContactEmail">
                Email field is required and in valid format
              </div>
          </div>
        </div>
        <div class="row py-3 ml-0">

          <div class="col-md-4">
            <label>Billing contact <span class="mandatory">*</span></label>
            <input class="form-control rounded-0 border input-height bg-white" placeholder="Name" type="text"
              id="txtBillingcontactName" [(ngModel)]="client.clientBillingName" name="txtBillingcontactName"
              value="{{client.clientBillingName}}" maxlength="50">
              <div class="error-color" *ngIf="!isValidBillingContactName">
                Billing contact name field is required
              </div>
          </div>

          <div class="col-md-4">
            <label>Title <span class="mandatory">*</span></label>
            <div class="selectdiv">
              <select class="dropdown rounded-0 " name="ddClientContact" id="ddClientContact" #ddClientContact="ngModel"
                [(ngModel)]="client.billingContactTitleCodeId">
                <option [value]="undefined" selected disabled hidden>Select billing contact title</option>
                <option *ngFor="let contactType of contactTypes" [value]="contactType.applicationMasterId">
                  {{contactType.value}}
                </option>
              </select>
            </div>
            <div *ngIf="!isValidBillingContactTitle" class="error-color">
              Billing contact title field is required
            </div>
          </div>

          <div class="col-md-4">
            <label>Email <span class="mandatory">*</span></label>
            <input placeholder="Email" class="form-control rounded-0 border input-height bg-white" maxlength="80"
              id="txtBillingcontactEmail" [(ngModel)]="client.clientBillingEmail" #billingEmail="ngModel" name="txtBillingcontactEmail" value="{{client.clientBillingEmail}}"
              autocomplete="off">
              <div class="error-color" *ngIf="!isValidBillingContactEmail">
                Email field is required and in valid format
              </div>
          </div>
        </div>
      </div>
      <!-- end Client contact fields -->

      <!-- Start GT Contacts Section -->
      <div class="GT-Contacts mt-4">
        <h5 class="ml-3 edit-heading">GT contacts </h5>
        <div class="row py-3 ml-0">
          <div class="col-6 col-sm-6 col-lg-6 engagament-PPMDDiv1">
            <label for="engPeoplePicker">CMS client administrator <span class="mandatory">*</span></label>
            <!--start CMS admin people picker-->
            <div class="input-group mw-100 font-weight-bold">
              {{ client.clientAdministratorContactName }} - &nbsp; <span
                class="id-name">{{ clientAdministrator?.email }}</span>
            </div>

            <!--end CMS admin people picker -->
          </div>
        </div>
        <!--start KRP people picker-->
        <div class="row py-3 ml-0">
          <div class="col-6 col-sm-6 col-lg-6 engagament-PPMDDiv">
            <label for="epRoleName">Key relationship leader</label>
            <div class="input-group mw-100" *ngIf="false" style="pointer-events: none;">
              <app-people-picker class="eng-people-picker" [roleShortName]="KRPRoleName"
                [labelCtrlName]="KRPlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                [IsValidateUserWithADGroup]="validateKRP" [userEmail] ="keyRelationshipPartner?.email"
                [PeoplePickerModel]="keyRelationshipPartner?.relationshipPartnerName">
              </app-people-picker>

              <div class="input-group-append input-height">
                <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                  data-target="#modal-RelationshipPartnersList" data-keyboard="false" data-backdrop="static"
                  class="mw-100 people-picker-button text-center"
                  (click)="refreshModalPartnersList(peoplepickerIndex,'txtPeoplePicker_')">
                  <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Key relationship leader" />
                </button>
              </div>
            </div>
            <div class="error-color d-none" id="KRPValid">
              <p>Key relationship leader should be valid </p>
            </div>
          </div>

        </div>
        <!--end KRP people picker-->
      </div>
      <!-- <div class="px-3">
        <hr>
      </div> -->
      <!-- end GT contacts fields -->
      <div *ngIf="isloggedInUserAdmin">
        <!--start of add EL-MSA section-->
         <div class="multi-user-eng"*ngIf="!isCLMFeatureEnabled">
          <div class="row  ml-0">
            <div class="col-md-12 mt-3">
              <p class="font-weight-bold mb-2 edp-font-16">
                Governing Agreements
              </p>
              <p>
                  Please select the previously executed EL or MSA you will be using for this engagement from the items below, or if you will be using a document that is not listed, please select the “The document exists elsewhere” option and input the relevant details. If a new EL or standard Grant Thornton MSA is required for this engagement (in addition to the Statement of Work), please select “No option exists / Ignore (Audit engagement will typically select this option).”
              </p>
            </div>
            <div class="col-md-12" *ngIf="editClientViewModel.clientLevelDocuments?.length > 0">
              <p class="font-weight-bold mb-2 edp-font-16">Existing</p>
            </div>
            <div class="col-md-10 mb-2" *ngFor="let document of editClientViewModel.clientLevelDocuments">
              <div class="edp-font-14 mb-2"> 
                <span class="font-weight-bold">{{document.documentSubTypeValue}}</span>&nbsp;- Effective: {{document.effectiveDate | date: 'MM/dd/yyyy'}} - Expires: {{document.expirationDate | date: 'MM/dd/yyyy'}}
                <span class="mr-3" *ngIf="isloggedInUserAdmin && document.inProgressEngagementCount == 0" >
                <app-governing-agreement-actions class="eng-people-picker" id="governing-agreement={{document.clientLevelDocumentId}}" 
                 [clientLevelDocument]="document"
                 [parentComponentName] ="currentComponentName"
                (UpdatedClientLevelDocList)="updateClientLevelDocumentList($event)">
              </app-governing-agreement-actions>
            </span>
              </div>
              <div id="agreementDescription_{{currentComponentName}}_{{document.clientLevelDocumentId}}" >
              <p class="mb-0 edp-font-13 alert-pre">
                <span class="">Description: </span>{{document.documentDescription}}
              </p>
              <p class="mb-0 edp-font-13">
                <span class="">GT agreement owner: </span>
                <span class="link-text-blue contactUser cursor-pointer font-weight-bold" id="GTAgreementOwner_{{document?.clientLevelDocumentId}}"
                (click)="displayContactPopover(document?.gtAgreementOwnerEmail, 'GTAgreementOwner_'+ document?.clientLevelDocumentId)">
                {{document.gtAgreementOwnerName}}
              </span>
              </p>
            </div>
            </div>
            <div class="col-md-12">
              <p class="font-weight-bold mb-1">Add existing EL or MSA</p>
            </div>
            <div class="row m-0">
            <div class="col-md-6 col-lg-4 col-xl-4">
              <label>Type</label>
              <div class="selectdiv">
                <select (change)="SelectedSubDocumentTypeChanged()" class="dropdown rounded-0 " name="type" id="agreementType" [(ngModel)]="clientLevelDocument.documentSubTypeId">
                  <option [value]="undefined" selected disabled hidden>Select document type</option>
                  <option [value]="0">None</option>
                  <option *ngFor="let type of subDocumentTypes" [value]="type.applicationMasterId">{{ type.value }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-4" *ngIf="!isTemplateDisabled">
              <label for="templates">Mapped engagement template<span class="mandatory"> *</span></label>
              <div class="col-md-12 px-0 select-slim">
                <div class="selectdiv">
                  <select class="dropdown rounded-0" id="ddTemplate" name="template" [(ngModel)]="clientLevelDocument.templatePackageId">
                    <option value="undefined" disabled hidden>Select a template</option>
                    <option *ngFor="let template of templateList" [value]=template.templatePackageId>
                      {{template.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isTempateNotSelected">
                Select a template
              </div>
            </div>
          </div>
          <div class="row m-0 mt-3">
            <div class="col-md-6 col-lg-4 col-xl-4" [ngClass]="{true: 'div-disabled'}[isSubDocumentTypeControlsDisabled]">
              <label for="msaEffectiveDate">Effective date<span class="mandatory" *ngIf="!isSubDocumentTypeControlsDisabled"> *</span></label>
              <app-calendercontrol id="msaEffectiveDate" class="effective-date" [txtDate]="msaEffectiveDateCntrlId"></app-calendercontrol>
              <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="isEffectiveDateEmpty">
                Effective date field is required
              </div>
              <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="!isvalidEFD">
                Effective date should be valid
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-4" [ngClass]="{true: 'div-disabled'}[isSubDocumentTypeControlsDisabled]">
              <label for="msaExpirationDate">Expiration date</label>
              <app-calendercontrol id="msaExpirationDate" [txtDate]="msaExpirationDateCntrlId" class="expiration-date"></app-calendercontrol>
              <div *ngIf="isInvalidMSAExpirationDate || isInvalidMSAEffectiveDate" class="error-color">
                Expiration date should be current/future date and greater than effective date
              </div>
              <div class="col-md-12 px-0 select-slim" class="error-color" *ngIf="!isvalidEXD">
                Expiration date should be valid
              </div>
            </div>
            <div class="col-xl-4 col-md-6 " [ngClass]="{true: 'eng-div-disabled'}[!isDocPresentElsewhere]">
              <label><span class="label">GT agreement owner
              </span></label>
              <div class="input-group mw-100">
                <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=false
                  [roleShortName]="GTAgreementOwnerRoleName" [labelCtrlName]="GTAgreementOwnerlblCtrlName" [isLabelVisible]="false"
                  [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="clientLevelDocument.gtAgreementOwnerName"
                  [userEmail]="clientLevelDocument.gtAgreementOwnerEmail" [IsValidateUserWithADGroup]="validateGTAgreementOwner">
                </app-people-picker>

                <div class="input-group-append input-height">

                  <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal" 
                    data-target="#modal-GTAgreementOwner" data-keyboard="false" data-backdrop="static"
                    class="mw-100 people-picker-button text-center" (click)="refreshGTAgreementOwnerPopup('txtPeoplePicker_')">
                    <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="GT agreement owner" />
                  </button>
                </div>

              </div>
              <div class="form-control-feedback error-color mb-0" *ngIf="!isvalidGTAgreementOwner">
                <p>GT agreement owner should be valid</p>
              </div>
            </div>
          </div>
            <div class="col-md-12 mt-3" [ngClass]="{true: 'div-disabled'}[isSubDocumentTypeControlsDisabled]">
              <label>Description </label>
              <input class="form-control rounded-0 border input-height bg-white" id="txtDocumentDescription" name="txtDocumentDescription"
                placeholder="Enter description" type="text" [(ngModel)]="clientLevelDocument.documentDescription" maxlength="200" autocomplete="off">
                <div *ngIf="clientLevelDocument.documentDescription && clientLevelDocument.documentDescription.length > 200" class="error-color">
                  Description character length exceeded(Allowed 200 chars)
                </div>
            </div>
          </div>
        </div> 
        <!--end of add EL-MSA section-->
      </div>


    </div>
    <!--end Main Div under EditClientForm-->


    <app-alerts name='clientAlert' id='clientAlert'></app-alerts>
    <div class="row justify-content-end pt-3 text-right m-0">
      <div class="col-xl-3 col-md-4 pl-0">
        <button type="button" class="btn SecondaryButton w-100 text-center" (click)="Cancel()">Cancel </button>
      </div>
      <div class="col-xl-3 col-md-4 pr-0">
        <button type="button" class="btn PrimaryButton w-100 text-center" (click)="UpdateClient()">
          <span>
            <img class="d-inline-block mr-1" src="../../../assets/images/EDP/Interviews/add_box.svg" alt="update" />
          </span>
          <span class="d-inline-block align-middle font-weight-bold">Update client</span></button>
      </div>
    </div>
  </form>

</div>
<!-- End main Container -->

<div id="modal-RelationshipPartnersList" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select key relationship leader</span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectPartner();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="PartnerNameErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchPartnerName" (keydown.enter)="searchPartnerNames('searchPartnerName');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for key relationship leader"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchPartnerNames('searchPartnerName');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblKRPAD" aria-describedby="KRP" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">KRP Name</th>
                <th scope="col">Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectPartner(i,$event)' id="trPartnerlist" class="trPartnerlist" style="cursor: pointer;"
                *ngFor="let member of KRPmembersListArray let i=index">
                <td>
                  <span id='spdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td>
                  <span id='spmail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td>
                  <span id='spbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="deselectPartner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
          (click)="setPartner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!--Start GT Agreemwnt Owner POPUP-->
<div id="modal-GTAgreementOwner" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Select GT agreement owner </span>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectGTAgreementOwner();">
          <span class="close-text mr-1">Close </span>
          <span class="modal-close-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span id="GTAgreementOwnerErrorMessage" class="ErrorFont"></span>

        <div class="input-group nav-searchbox-length pb-2">
          <input type="text" id="searchGTAgreementOwnerName" (keydown.enter)="searchPartnerNames('searchGTAgreementOwnerName');"
            class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for GT Agreement Owner"
            autocomplete="off">
          <span class="input-group-append nav-seachBox">
            <div class="input-group-text" (click)="searchPartnerNames('searchGTAgreementOwnerName');">
              <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
            </div>
          </span>
        </div>
        <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
        <div class="tableScroll mt-2">
          <table id="tblGTAgreementOwner" class="table  table-hover" aria-describedby="GTAgreementOwner">
            <thead>
              <tr>
                <th scope="col"> Name</th>
                <th scope="col"> Email </th>
                <th scope="col">Job Title</th>
                <th scope="col">Phone</th>
                <th scope="col">Line of Business</th>
                <th scope="col">Service Line</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)='selectGTAgreementOwner(i,$event)' id="trGTAgreementOwner" name="trGTAgreementOwner"
                *ngFor="let member of KRPmembersListArray let i=index">
                <td>
                  <span id='spGTAgreementOwnerdisplayName{{i}}'>{{member.displayName}}</span>
                </td>
                <td> <span id='spGTAgreementOwnermail{{i}}'>{{member.mail}}</span>
                </td>
                <td>
                  <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                </td>
                <td> <span id='spGTAgreementOwnerbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                </td>
                <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                </td>
                <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                </td>
                <td> <span id='splocation{{i}}'>{{member.location}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
          style="width: 150px; text-align: center;" (click)="deselectGTAgreementOwner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
          </span>
          <span class="d-inline-block align-middle">Cancel</span>
        </button>
        <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
          (click)="setGTAgreementOwner();">
          <span class="d-inline-block mr-1 align-top">
            <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
          </span>
          <span class="d-inline-block align-middle">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--End GT Agreemwnt Owner POPUP-->
<!--Contact Popup-->
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>

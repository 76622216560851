import { DatePipe, getLocaleDateTimeFormat, JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CloseoutSections, EngagementCloseoutConfiguration, EngagementList } from 'src/app/model/EngagementListModel';
import { EngagementService } from 'src/app/services/EngagementService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { LogService } from 'src/app/services/log.service';
import { EngagementStatus, UpdateEngagementCloseoutConfigurationModel } from 'src/app/model/Engagement/Engagements';
import { EngagementAuditChecklistViewModel } from 'src/app/model/Engagement/EngagementAuditChecklistViewModel';
import { EngagementClientVoiceUserAccess } from 'src/app/model/Engagement/EngagementClientVoice';
declare var $;
@Component({
  selector: 'app-engagement-closeout-audit-checklist',
  templateUrl: './engagement-closeout-audit-checklist.component.html',
  styleUrls: ['./engagement-closeout-audit-checklist.component.css']
})
export class EngagementCloseoutAuditChecklistComponent implements OnInit, OnDestroy {
  selectEngagementId: number;
  selectEngagementName: string;
  @Input() engagementName: string;
  @Input() engStatus: string;
  @Input() engagementCreatedDate: Date;
  @Input() isEngagementClosed: boolean;
  @Input() isEngagementCloseoutDisable: boolean;
  @Input() isloggedInUserAdmin: string = 'no';
  @Output() engCloseOut: EventEmitter<string> = new EventEmitter<string>();
  engagement: EngagementList=new EngagementList();

  @Input() set engagementId(engid: number) {
    if (engid != undefined && engid != null && engid > 0) {
      this.selectEngagementId = engid;
    }
  }
  engagementAuditChecklistViewModel: EngagementAuditChecklistViewModel = new EngagementAuditChecklistViewModel();
  engagementClientVoiceUserAccessList: EngagementClientVoiceUserAccess[] = [];
  bvg: EngagementAuditChecklistViewModel[] = [];
  updateEngCloseoutConfigModelv: UpdateEngagementCloseoutConfigurationModel = new UpdateEngagementCloseoutConfigurationModel();
  userObject: any;
  auditchecklistDateCtrlId: string;
  auditchecklistDate2CtrlId: string;
  isImageEnabled: boolean = false;
  isImageEnabled1: boolean = false;
  isImageEnabled2: boolean = false;
  disableWeekendValue: boolean = true;
  ConversationDate: Date;
  ImprovementDate: Date;
  isuditChecklistCompleted: boolean = false;
  isFutureDateResult: number;
  isPastDateResult: number;


  clearSurvey: boolean = false;

  clientVoiceAPISuccessCode: string = '200';
  public get EngagementStatus(): typeof EngagementStatus {
    return EngagementStatus;
  }
  @Output() collapseEvent: EventEmitter<string> = new EventEmitter<string>();
  currentState: string;

  @Input()
  sectionConfiguration: EngagementCloseoutConfiguration;

  @Input()
  lastSurveySentDateForClient: Date;
  showRequiredWarning: boolean = false;
  @Input()
  audit: EngagementAuditChecklistViewModel;
  ;

  constructor(private engagementService: EngagementService, private logService: LogService,
    private datePipe: DatePipe,
    private loadingScreenService: LoadingScreenService) {
    this.currentState = 'collapse';
  }

  ngOnInit(): void {
    this.auditchecklistDateCtrlId = 'auditchecklistDate';
    this.auditchecklistDate2CtrlId = 'audit2checklist';
    this.disableWeekendValue = true;
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    if (this.sectionConfiguration.isDefaultOpen) {
      this.toggleDown();
    }
    // this.getEngagementDetailsById();
  }

  ngOnDestroy(): void {
    this.loadingScreenService.stopLoading();
  }

  toggleUp(flag: boolean) {
    this.isImageEnabled = true;
    this.GetAuditChecklistDetailsByEngId(this.selectEngagementId);
    $('#collapseTwo').show();
    this.currentState = 'expand';
    if (!flag) {
      this.engagementAuditChecklistViewModel.isSaved = false;
      this.currentState = 'expand';
      this.collapseEvent.emit(this.currentState);
    }
  }

  toggleUpDown(flag: boolean) {
    this.isImageEnabled = false;
    $('#collapseTwo').hide();
    this.currentState = 'collapse';

  }

  toggleDown() {
    this.isImageEnabled = false;
    $('#collapseTwo').hide();
    this.currentState = 'collapse';
  }

  getEngagementCloseoutConfigurations() {
    this.engagementService.GetEngagementCloseoutConfigurations(this.selectEngagementId).subscribe(engagementCloseoutConfigurations => {
      if (engagementCloseoutConfigurations && engagementCloseoutConfigurations.length > 0) {
        this.sectionConfiguration = engagementCloseoutConfigurations.find(section =>
          section.code === CloseoutSections.ALC);
        if (this.sectionConfiguration.isCompleted) {
          this.showRequiredWarning = false;
        }
      }
    },
      err => {
        console.error('getEngagementCloseoutConfigurations ', err);
      });
  }

  saveAuditChecklistDetails() {
    //Set default values
    this.cleareValidationMessages();
    let conversationdate = $('.audit2checklist').val();
    let improvementDate = $('.auditchecklistDate').val();
    const currentDate = new Date().toDateString();
    const createdDate = new Date(this.engagement.createdDate).toDateString();
    if (conversationdate == '') { //check conversion date for empty
      this.engagementAuditChecklistViewModel.isConversationDateEmpty = false;
      this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = false;
    } else {
      this.isFutureDateResult = this.compareDate(conversationdate, currentDate);
      this.isPastDateResult = this.compareDate(conversationdate, createdDate);
      if (this.isFutureDateResult == 1) {
        this.engagementAuditChecklistViewModel.isConversationDateFuture = false;
        this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = false;
      }
      if (this.isPastDateResult == -1) {
        this.engagementAuditChecklistViewModel.isConversationDateBack = false;
        this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = false;
      }
    }
    if (improvementDate == '') {//check improvement date for empty
      this.engagementAuditChecklistViewModel.isImprovementDateEmpty = false;
      this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = false;
    }
    else {
      this.isFutureDateResult = this.compareDate(improvementDate, currentDate);
      this.isPastDateResult = this.compareDate(improvementDate, createdDate);
      if (this.isFutureDateResult == 1) {
        this.engagementAuditChecklistViewModel.isImprovementDateFuture = false;
        this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = false;
      }
      if (this.isPastDateResult == -1) {
        this.engagementAuditChecklistViewModel.isImprovementDateBack = false;
        this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = false;
      }
    }
    if (this.engagementAuditChecklistViewModel.isValidAuditChecklistDates) {
      this.ConversationDate = conversationdate;
      this.ImprovementDate = improvementDate;
      let engagementAuditChecklistViewModel: EngagementAuditChecklistViewModel = new EngagementAuditChecklistViewModel();
      engagementAuditChecklistViewModel.engagementId = this.selectEngagementId;
      engagementAuditChecklistViewModel.loggedInUser = this.userObject.loggedInUserEmail;
      engagementAuditChecklistViewModel.loggedInUserFullName = this.userObject.loggedInUserFullName;
      engagementAuditChecklistViewModel.conversationDate = this.ConversationDate;
      engagementAuditChecklistViewModel.improvementDate = this.ImprovementDate;
      this.loadingScreenService.startLoading();
      this.engagementService.saveAuditChecklist(engagementAuditChecklistViewModel).subscribe(
        updateEngResult => {
          if (updateEngResult === 'Success') {
            this.loadingScreenService.startLoading();
            this.engagementAuditChecklistViewModel.isSaved = true;
            this.isuditChecklistCompleted = true;
            this.updateEngagementCloseoutConfigurations();
            this.getEngagementCloseoutConfigurations();
            this.loadingScreenService.stopLoading();
            //updateEngCloseoutConfigModel.isCompleted
            this.loadingScreenService.startLoading();

          }
        },
        error => {
          console.error('Engagement Audit Checklist save failed', error);
          this.loadingScreenService.stopLoading();
          this.engagementAuditChecklistViewModel.isSaved = false;
          this.logService.log("error in saving Engagement Audit Checklist");
        });
    }

  }
  /**
  * Compares two Date objects and returns e number value that represents
  * the result:
  * 0 if the two dates are equal.
  * 1 if the first date is greater than second.
  * -1 if the first date is less than second.
  * @param date1 First date object to compare.
  * @param date2 Second date object to compare.
  */
  private compareDate(date1: string, date2: string): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) {
      return 0;
    }

    // Check if the first is greater than second
    if (d1 > d2) {
      return 1;
    }

    // Check if the first is less than second
    if (d1 < d2) {
      return -1;
    }
  }
  resetEngAuditChecklistViewModel() {
    //Reset values
    this.engagementAuditChecklistViewModel.isSaved = false;
  }

  GetAuditChecklistDetailsByEngId(engId: number) {
    this.loadingScreenService.startLoading();
    return this.engagementService.GetAuditChecklistDetailsByEngId(engId)
      .subscribe(
        result => {
          this.loadingScreenService.stopLoading();
          this.engagementAuditChecklistViewModel = result;
          let conversationDate = this.engagementAuditChecklistViewModel.conversationDate;
          let improvementDate = this.engagementAuditChecklistViewModel.improvementDate;
          this.engagement.createdDate = this.engagementAuditChecklistViewModel?.createdDate;
          if (conversationDate && improvementDate) {
            this.engagementAuditChecklistViewModel.isSaved = false;
            this.isuditChecklistCompleted = true;
            this.engagement.conversationDate = conversationDate;
            this.engagement.improvementDate = improvementDate;
          }
          this.cleareValidationMessages();
        },
        error => {
          this.engagementAuditChecklistViewModel = null;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  updateEngagementCloseoutConfigurations() {
    let isCompleted: boolean = true;

    this.loadingScreenService.startLoading();
    let updateEngCloseoutConfigModel: UpdateEngagementCloseoutConfigurationModel = new UpdateEngagementCloseoutConfigurationModel();
    updateEngCloseoutConfigModel.engagementId = this.selectEngagementId;
    updateEngCloseoutConfigModel.isCompleted = isCompleted;
    updateEngCloseoutConfigModel.sectionShortName = this.sectionConfiguration.code;
    updateEngCloseoutConfigModel.loggedInUser = this.userObject.loggedInUserEmail;
    updateEngCloseoutConfigModel.loggedInUserFullName = this.userObject.loggedInUserFullName;

    this.engagementService.UpdateEngagementCloseoutConfigurations(updateEngCloseoutConfigModel).subscribe(
      updateEngResult => {
        if (updateEngResult === 'Success') {
          this.getEngagementCloseoutConfigurations();
          this.loadingScreenService.stopLoading();
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
        const errorMsg = 'Error while updateEngagementCloseoutConfigurations for section : ' + this.sectionConfiguration.value;
        console.error(errorMsg, error);
        this.logService.log(errorMsg);
      });
  }

  // getEngagementDetailsById() {
  //   if (this.selectEngagementId)
  //     return this.engagementService.getEngagementDetailsById(this.selectEngagementId)
  //       .subscribe(
  //         result => {
  //           this.engagement = result;
  //         },
  //         (error) => {
  //           console.error("error in  GetEditEngagementDetailsById", error);
  //         }
  //       );
  // }

  cleareValidationMessages() {
    this.engagementAuditChecklistViewModel.isConversationDateFuture = true;
    this.engagementAuditChecklistViewModel.isImprovementDateFuture = true;
    this.engagementAuditChecklistViewModel.isConversationDateBack = true;
    this.engagementAuditChecklistViewModel.isImprovementDateBack = true;
    this.engagementAuditChecklistViewModel.isConversationDateEmpty = true;
    this.engagementAuditChecklistViewModel.isImprovementDateEmpty = true;
    this.engagementAuditChecklistViewModel.isValidAuditChecklistDates = true;
  }

}

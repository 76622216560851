import { TemplateTypeEnum, EngagementStateConfig, TemplateCategoryCode } from './../../../model/template-maintenance/template-type.enum';
import { LogService } from './../../../services/log.service';
import { TemplateMaintenanceFilter } from './../../../model/template-maintenance/template-maintenance-filter';
import { TemplateMaintenanceService } from './../../../services/template-maintenance.service';
import { TemplateMaintenance } from './../../../model/template-maintenance/template-maintenance.model';
import { ImageModel, HeaderModel } from './../../../model/EngagementListModel';
import { TemplatePackageModel } from './../../../model/TemplatePackageModel';
import { AlertsService } from './../../../services/alerts.service';
import { HotDocsService } from './../../../services/HotDocsService';
import { LoadingScreenService } from './../../../services/loading-screen.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-maintenance',
  templateUrl: './template-maintenance.component.html',
  styleUrls: ['./template-maintenance.component.css']
})
export class TemplateMaintenanceComponent implements OnInit {

  hotDocTemplateList: TemplatePackageModel[] = [];
  newImageModel: ImageModel = new ImageModel();
  headerModel: HeaderModel = new HeaderModel();
  headerImageModelList: ImageModel[] = [];
  headerModelList: HeaderModel[] = [];
  templateMaintenanceList: TemplateMaintenance[] = [];
  templateMaintenanceBaseList: TemplateMaintenance[] = [];
  templateMaintenanceFilteredList: TemplateMaintenance[] = [];
  templateServiceLineFilteredList: TemplateMaintenance[] = [];
  templateTypeFilteredList: TemplateMaintenance[] = [];
  templateSubTypeFilteredList: TemplateMaintenance[] = [];
  templateStatusFilteredList: TemplateMaintenance[] = [];
  selectedTemplatePackageId: string;
  templateMaintenanceFilter: TemplateMaintenanceFilter;
  isDisplayFilter = false;
  // type of template is Component Template
  templateTypeCode = TemplateTypeEnum.ComponentTemplate;
  EngagementStatecode = EngagementStateConfig.Acceptance;
  isDisableTemplateSubType = false;
  isSubTypeFilterApplied = false;
  isTypeFilterApplied = false;
  isServiceFilterApplied = false;
  isStatusFilterApplied = false;
  noDataMessage = 'No data available';
  orderByType = 'ASC';
  isActiveFilter = false;
  isShowActiveFilter = false;

  constructor(private loadingScreenService: LoadingScreenService, private _hdService: HotDocsService, private _alertService: AlertsService,
    private templateMaintenanceService: TemplateMaintenanceService, private logService: LogService) { }

  ngOnInit() {
    this.templateMaintenanceFilter = new TemplateMaintenanceFilter();
    this.getTemplatePackages();
    this.loadListViewHeaders();

  }

  headerSort(headerId: number) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.headerModelList.length; i++) {
      if (this.headerModelList[i].headerId === headerId) {
        this.headerModelList[i].headerClick = true;
        if ((this.headerModelList[i].imageModelList[0].isVisible) || (this.headerModelList[i].imageModelList[2].isVisible)) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = true;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        } else if (this.headerModelList[i].imageModelList[1].isVisible) {
          this.headerModelList[i].imageModelList[0].isVisible = false;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = true;
        }
      } else {
        this.headerModelList[i].headerClick = false;
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.headerModelList[i].imageModelList.length; j++) {
          this.headerModelList[i].imageModelList[0].isVisible = true;
          this.headerModelList[i].imageModelList[1].isVisible = false;
          this.headerModelList[i].imageModelList[2].isVisible = false;
        }
      }
    }

    const headerObj = this.headerModelList.find(c => c.headerClick === true);
    const orderByColumn = headerObj.headerValue;
    // tslint:disable-next-line: prefer-for-of
    for (let k = 0; k < headerObj.imageModelList.length; k++) {
      if (headerObj.imageModelList[k].isVisible === true) {
        this.orderByType = headerObj.imageModelList[k].imageValue;
        break;
      }
    }
    this.templateMaintenanceListSort(orderByColumn);
  }

  templateMaintenanceListSort(orderByColumn: string) {
    if (this.orderByType === 'ASC') {
      // tslint:disable-next-line: max-line-length
      this.templateMaintenanceList.sort((a, b) => (a[orderByColumn] > b[orderByColumn]) ? 1 : ((b[orderByColumn] > a[orderByColumn]) ? -1 : 0));
    } else {
      // tslint:disable-next-line: max-line-length
      this.templateMaintenanceList.sort((a, b) => (b[orderByColumn] > a[orderByColumn]) ? 1 : ((a[orderByColumn] > b[orderByColumn]) ? -1 : 0));
    }
  }

  showHideFilterPanel(isDisplay: boolean) {
    this.isDisplayFilter = isDisplay;
    this.isActiveFilter = true;
    this.isShowActiveFilter = false;
    if (!isDisplay) {
      this.isActiveFilter = false;
      this.isShowActiveFilter = false;
      this.selectedTemplatePackageId = '';
    }
  }

  getTemplateMaintenanceListByFilters() {
    this.templateMaintenanceList = this.templateMaintenanceBaseList;
    this.isSubTypeFilterApplied = false;
    this.isTypeFilterApplied = false;
    this.isServiceFilterApplied = false;
    this.isStatusFilterApplied = false;
    this.getServiceLineFilteredList();
    if (!this.isServiceFilterApplied) {
      this.templateServiceLineFilteredList = this.templateMaintenanceList;
    }
    this.getTemplateMaintenanceTypesFilteredList();
    if (!this.isTypeFilterApplied) {
      if (this.isServiceFilterApplied) {
        this.templateTypeFilteredList = this.templateServiceLineFilteredList;
      } else {
        this.templateTypeFilteredList = this.templateMaintenanceList;
      }
    }
    this.getTemplateMaintenanceSubTypesFilteredList();
    if (!this.isSubTypeFilterApplied) {
      if (this.isTypeFilterApplied) {
        this.templateSubTypeFilteredList = this.templateTypeFilteredList;
      } else {
        if (this.isServiceFilterApplied) {
          this.templateSubTypeFilteredList = this.templateServiceLineFilteredList;
        } else {
          this.templateSubTypeFilteredList = this.templateMaintenanceList;
        }
      }
    }
    this.getTemplateMaintenanceStatusFilteredList();

    if (this.isStatusFilterApplied) {
      this.templateMaintenanceFilteredList = this.templateStatusFilteredList;
    } else {
      if (this.isSubTypeFilterApplied) {
        this.templateMaintenanceFilteredList = this.templateSubTypeFilteredList;
      } else {
        if (this.isTypeFilterApplied) {
          this.templateMaintenanceFilteredList = this.templateTypeFilteredList;
        } else {
          if (this.isServiceFilterApplied) {
            this.templateMaintenanceFilteredList = this.templateServiceLineFilteredList;
          } else {
            this.templateMaintenanceFilteredList = this.templateMaintenanceList;
          }
        }
      }
    }

    this.templateMaintenanceList = this.templateMaintenanceFilteredList.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
    this.isDisplayFilter = false;
  }

  getServiceLineFilteredList() {
    let advisoryList = [];
    let auditList = [];
    let taxList = [];
    if (this.templateMaintenanceFilter.isAdvisory) {
      advisoryList = this.templateMaintenanceList
        .filter(item => item.isAdvisory === this.templateMaintenanceFilter.isAdvisory);
      this.isServiceFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isAudit) {
      auditList = this.templateMaintenanceList
        .filter(item => item.isAudit === this.templateMaintenanceFilter.isAudit);
      this.isServiceFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isTax) {
      taxList = this.templateMaintenanceList
        .filter(item => item.isTax === this.templateMaintenanceFilter.isTax);
      this.isServiceFilterApplied = true;
    }
    this.templateServiceLineFilteredList = [...advisoryList, ...auditList, ...taxList];
  }


  getTemplateMaintenanceTypesFilteredList() {
    let standaloneList = [];
    let fullAgreementList = [];
    let componentTemplateList = [];
    let bypassList = [];
    if (this.templateMaintenanceFilter.isStandalone) {
      standaloneList = this.templateServiceLineFilteredList
        .filter(item => item.templateTypeCode === TemplateTypeEnum.Standalone);
      this.isTypeFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isFullAgreement) {
      fullAgreementList = this.templateServiceLineFilteredList
        .filter(item => item.templateTypeCode === TemplateTypeEnum.FullAgreement);
      this.isTypeFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isComponentTemplate) {
      componentTemplateList = this.templateServiceLineFilteredList
        .filter(item => item.templateTypeCode === TemplateTypeEnum.ComponentTemplate);
      this.isTypeFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isBypass) {
      bypassList = this.templateServiceLineFilteredList
        .filter(item => item.templateTypeCode === TemplateTypeEnum.Bypass);
      this.isTypeFilterApplied = true;
    }
    this.templateTypeFilteredList = [...standaloneList, ...fullAgreementList, ...componentTemplateList, ...bypassList];
  }

  getTemplateMaintenanceSubTypesFilteredList() {
    let standardList = [];
    let universalList = [];
    let taxEvergreenList = [];
    if (this.templateMaintenanceFilter.isGTStandardMSA) {
      standardList = this.templateTypeFilteredList
        .filter(item => item.isGTStandardMSA === this.templateMaintenanceFilter.isGTStandardMSA);
      this.isSubTypeFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isUniversalEngagementLetter) {
      universalList = this.templateTypeFilteredList
        .filter(item => item.isUniversalEngagementLetter === this.templateMaintenanceFilter.isUniversalEngagementLetter);
      this.isSubTypeFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isTaxEvergreen) {
      taxEvergreenList = this.templateTypeFilteredList
        .filter(item => item.isTaxEvergreen === this.templateMaintenanceFilter.isTaxEvergreen);
      this.isSubTypeFilterApplied = true;
    }
    this.templateSubTypeFilteredList = [...standardList, ...universalList, ...taxEvergreenList];
  }
  getTemplateMaintenanceStatusFilteredList() {
    let activeList = [];
    let inactiveList = [];
    if (this.templateMaintenanceFilter.isActive) {
      activeList = this.templateSubTypeFilteredList
        .filter(item => item.status === this.templateMaintenanceFilter.isActive);
      this.isStatusFilterApplied = true;
    }
    if (this.templateMaintenanceFilter.isInactive) {
      inactiveList = this.templateSubTypeFilteredList
        .filter(item => item.status === false);
      this.isStatusFilterApplied = true;
    }
    this.templateStatusFilteredList = [...activeList, ...inactiveList];

  }

  resetFilters(isCancel: boolean = false) {
    this.templateMaintenanceFilter = new TemplateMaintenanceFilter();
    this.isDisplayFilter = false;
    this.selectedTemplatePackageId = '';
    this.templateMaintenanceList = this.templateMaintenanceBaseList;
    this.isActiveFilter = false;
    this.isShowActiveFilter = true;
    if (isCancel) {
      this.isActiveFilter = false;
      this.isShowActiveFilter = false;
    }
  }

  cancelSave(templatePackageId: string) {
    this.selectedTemplatePackageId = '';
    this.templateMaintenanceList.forEach(element => {
      if (templatePackageId === element.templatePackageId) {
        element.isAdvisory = false;
        element.isAudit = false;
        element.isTax = false;
        element.isGTStandardMSA = false;
        element.isTaxEvergreen = false;
        element.isUniversalEngagementLetter = false;
        element.templateTypeCode = TemplateTypeEnum.ComponentTemplate;

      }
    });
    this.getAllTemplateMaintenance();
  }

  loadListViewHeaders() {
    this.newImageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/unfold_more-24px.svg',
      isVisible: true
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Ascending',
      imageValue: 'ASC',
      imagePath: '../../../../assets/images/EDP/Engagement/asc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.newImageModel = {
      imageName: 'Descending',
      imageValue: 'DESC',
      imagePath: '../../../../assets/images/EDP/Engagement/desc_order.png',
      isVisible: false
    };
    this.headerImageModelList.push(JSON.parse(JSON.stringify(this.newImageModel)));

    this.headerModel = {
      headerId: 1,
      headerName: 'HotDocs name',
      headerValue: 'templateName',
      headerClick: true,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 2,
      headerName: 'Version',
      headerValue: 'templateVersion',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 3,
      headerName: 'Service line',
      headerValue: 'serviceLine',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 4,
      headerName: 'Type',
      headerValue: 'templateTypeName',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 5,
      headerName: 'Status',
      headerValue: 'status',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));

    this.headerModel = {
      headerId: 6,
      headerName: '',
      headerValue: 'Edit',
      headerClick: false,
      imageModelList: this.headerImageModelList
    };
    this.headerModelList.push(JSON.parse(JSON.stringify(this.headerModel)));
    console.log('headerModelList ==>', this.headerModelList);
  }

  getTemplatePackages() {
    this._hdService.getTemplatePackages().subscribe(result => {
      this.hotDocTemplateList = result;
      if (this.hotDocTemplateList !== undefined && this.hotDocTemplateList !== null) {
        this.hotDocTemplateList.forEach(element => {
          element.isActive = true;
          const templateMaintenance = new TemplateMaintenance(element.templatePackageId, element.name,
            element.liveVersionNumber, element.isActive, element.description);
          templateMaintenance.templateTypeCode = this.templateTypeCode;
          templateMaintenance.engagementStatecode = this.EngagementStatecode;

          this.templateMaintenanceList.push(templateMaintenance);
        });
        this.getAllTemplateMaintenance();
      }
    },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  getAllTemplateMaintenance() {
    this.templateMaintenanceService.getAllTemplateMaintenanceData().subscribe(result => {
      const templateMaintenanceDbList = result;
      if (templateMaintenanceDbList !== undefined && templateMaintenanceDbList !== null) {
        templateMaintenanceDbList.forEach(item => {
          this.templateMaintenanceList.forEach(element => {
            if (item.templatePackageId === element.templatePackageId) {
              element.isAdvisory = item.isAdvisory;
              element.isTax = item.isTax;
              element.isAudit = item.isAudit;
              element.displayName = item.displayName;
              element.serviceLine = item.serviceLine.replace(/,\s*$/, '');
              element.templateTypeId = item.templateTypeId;
              element.templateTypeName = item.templateTypeName;
              if (item.templateTypeCode !== undefined && item.templateTypeCode !== null && item.templateTypeCode !== '') {
                element.templateTypeCode = item.templateTypeCode;

              }                                                                                                                                                                                   
              element.engagementStateConfigId = item.engagementStateConfigId;
              element.engagementStateName = item.engagementStateName;
              if (item.engagementStatecode !== undefined && item.engagementStatecode !== null && item.engagementStatecode !== '') {
                element.engagementStatecode = item.engagementStatecode;
              }

              element.isGTStandardMSA = item.isGTStandardMSA;

              element.isTaxEvergreen = item.isTaxEvergreen;
              element.isUniversalEngagementLetter = item.isUniversalEngagementLetter;
              element.independanceConsiderationId=item.independanceConsiderationId;
              if (item.independenceConsiderationCode !== undefined && item.independenceConsiderationCode !== null && item.independenceConsiderationCode !== '') {
                element.independenceConsiderationCode = item.independenceConsiderationCode;
              }
              element.templateCategoryId=item.templateCategoryId;
              if (item.templateCategoryCode !== undefined && item.templateCategoryCode !== null && item.templateCategoryCode !== '') {
                element.templateCategoryCode = item.templateCategoryCode;
              }


            }
          });
        });
      }
      this.templateMaintenanceBaseList = this.templateMaintenanceList;
    },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  expandRow(templatePackageId: string) {
    this.selectedTemplatePackageId = templatePackageId;
  }

  saveTemplateMaintenance(templateMaintenance: TemplateMaintenance) {
    this.selectedTemplatePackageId = '';
    if (templateMaintenance.templateTypeCode !== this.templateTypeCode) {
      templateMaintenance.isGTStandardMSA = false;
      templateMaintenance.isTaxEvergreen = false;
      templateMaintenance.isUniversalEngagementLetter = false;
    }
    if (templateMaintenance.engagementStatecode == EngagementStateConfig.Acceptance) {

      templateMaintenance.engagementStateName = 'Acceptance Only'

    }
    if (templateMaintenance.engagementStatecode == EngagementStateConfig.Execution) {

      templateMaintenance.engagementStateName = 'Execution Only'

    }
    if (templateMaintenance.engagementStatecode == EngagementStateConfig.Both) {

      templateMaintenance.engagementStateName = 'Both'

    }
    if (templateMaintenance.engagementStatecode == EngagementStateConfig.DownloadTemplate) {

      templateMaintenance.engagementStateName = 'Download Template'

    }
    if(templateMaintenance.isAdvisory == false){
      templateMaintenance.independanceConsiderationId=null;
      templateMaintenance.independenceConsiderationCode='';
      templateMaintenance.templateCategoryId=null;
      templateMaintenance.templateCategoryCode='';
      if(templateMaintenance.engagementStatecode == 'DT' || templateMaintenance.engagementStatecode == null){
       templateMaintenance.engagementStateConfigId=0;
       templateMaintenance.engagementStatecode='';
      }
      
    }

    this.templateMaintenanceService.saveTemplateMaintenance(templateMaintenance).subscribe(result => {
      this.getAllTemplateMaintenance();
    },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });

  }

}

/* Code to show Relationship Partener Popup */
$(document).ready(function () {

  $('#engUl').on('click','li',function(e){
  e.preventDefault();
  $("#engActionTiles ul.nav li.activeTab").removeClass('activeTab')
  $(this).addClass('activeTab')
  })
  
  
    var clientRPPopup = $("#popover-content-ParternerDetails");
    clientRPPopup.hide();
    $('.ShowKRP').each(function () {
  
      $(this).popover({
        html: true,
        positionFixed: false,
        content: function () {
          return clientRPPopup.html();
        }
      }); //end popover call.
    });
    $('.ShowCC').each(function () {
  
      $(this).popover({
        html: true,
        positionFixed: false,
        content: function () {
          return clientRPPopup.html();
        }
      }); //end popover call.
    });
  
    $(".arrow-left").click(function () {
  
      $(".expand").css("display", "none"); // left arrow and other to be hidden elements
      $("#engActionTiles ul.nav li.hide").show();
      $(".arrow-right").css("display", "block");
      $("#engInfo").removeAttr("class");
      $("#engInfo").addClass("col-md-3");
      $("#engActionTiles").removeAttr("class");
      $("#engActionTiles").addClass("col-md-8");
      $("#engActionTiles ul.nav li.tilesBox").attr("style", "width:150px");
  
  
    });
    $(".arrow-right").click(function () {
      if ($("#engActionTiles ul.nav li.tilesBox").attr("style") == "width:150px") {
        $("#engActionTiles ul.nav li.tilesBox").removeAttr("style");
      }
      $(".expand").css("display", "block");
      $("#engActionTiles ul.nav li.hide").hide();
      $(".arrow-right").css("display", "none");
  
      $("#engInfo").removeAttr("class");
      $("#engInfo").addClass("col-md-4");
      $("#engActionTiles").removeAttr("class");
      $("#engActionTiles").addClass("col-md-7");
    });
  
    $("#plus").click(function () {
      if ($("#plus").attr("src") === "../../../assets/images/tiles/minus.png") {
        $("#tcfid").attr("style", "visibility:hidden")
        $("#plus").attr("src", "../../../assets/images/tiles/plus.png");
      }
      else {
        $("#tcfid").attr("style", "visibility:visible")
        $("#plus").attr("src", "../../../assets/images/tiles/minus.png");
      }
  
    });
  
  });
  
  
  
  
  
  
  
  
  
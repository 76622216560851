import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { EngagementListViewComponent } from 'src/app/pages/engagement/engagement-list-view/engagement-list-view.component';
import { MyReviewsListComponent } from 'src/app/common/page/my-reviews-list/my-reviews-list.component';
import { MyreacceptanceengagementsComponent } from 'src/app/pages/myreacceptanceengagements/myreacceptanceengagements.component';
import { NoDataFoundInListComponent } from 'src/app/common/page/no-data-found-in-list/no-data-found-in-list.component';
declare var $;

@Component({
  selector: 'app-engagementlist',
  templateUrl: './engagementlist.component.html',
  styleUrls: ['./engagementlist.component.css'],
})
export class EngagementlistComponent implements OnInit, OnDestroy {


  @ViewChild('target', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  selectedNavigation: string;
  prevData: any;
  components = [];

  timerId: any;
  constructor(private data: DataService, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.getSelectedCard();
  }

  ngOnDestroy() {
    clearTimeout(this.timerId);
  }

  getSelectedCard() {
    let currentData = 'EngagementListViewComponent';
    if (currentData != this.prevData) {
      this.prevData = currentData;
      var className = this.getClassNameByPageName(currentData);
      console.log(className);
      if (className != null) {
        $("#MainDiv").html(this.addComponent(className));
      }
    }

    this.timerId = setTimeout(() => {
      this.getSelectedCard()
    }, 1000);
    //console.log('getSelectedCard func called', currentData);
  }


  addComponent(componentClass: any) {
    // Create component dynamically inside the ng-template
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const localComponent = this.container.createComponent(componentFactory);
    // Push the component so that we can keep track of which components are created
    this.components.push(localComponent);
  }

  removeComponent(componentClass: any) {
    // Find the component
    const localComponent = this.components.find((componentObj) => componentObj.instance instanceof componentClass);
    const componentIndex = this.components.indexOf(localComponent);

    if (componentIndex !== -1) {
      // Remove component from both view and array
      this.container.remove(this.container.indexOf(localComponent));
      this.components.splice(componentIndex, 1);
    }
  }

  getClassNameByPageName(pageName) {
    var className;

    this.removeComponent(EngagementListViewComponent);
    this.removeComponent(MyReviewsListComponent);
    this.removeComponent(MyreacceptanceengagementsComponent);
    this.removeComponent(NoDataFoundInListComponent);

    switch (pageName) {
      case "EngagementListViewComponent":
        className = EngagementListViewComponent;
        break;
      case "MyReviewsListComponent":
        className = MyReviewsListComponent;
        break;
      case "MyreacceptanceengagementsComponent":
        className = MyreacceptanceengagementsComponent;
        break;
      case "NoDataFoundInListComponent":
        className = NoDataFoundInListComponent;
        break;
      default:
        className = null;
        break;
    }

    return className;
  }
}

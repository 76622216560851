import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { TemplatePackageModel } from '../model/TemplatePackageModel';
import { InterviewModel } from '../model/InterviewModel';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from './appConfigService';
import { HdWorkspaceDocumentModel } from '../model/WorkspaceDocumentModel';
import { HDWorkItemModel, WorkItemDetailsModel, HDQuestionnaireModel, HDWorkItemDocumentModel, DownloadWorkItem } from '../model/HDWorkItemModel';
import { HdTemplateVersionBrowserSessionModel, SaveWorkItemAssemblySession, WorkspaceModel } from '../model/WorkspaceModel';
import { HDConfigurationModel } from '../model/HDConfigurationModel';
import { QuestionAnswer } from '../model/Workspace/QuestionAnswer';
import { SPDocument } from 'src/app/model/SharePoiint/SharePointSite';
import { HotDocsVariableMappingList, HDSessionAnsToEngModel } from '../model/HotDocsVariableMapping';
import { ContinuanceEligibilityModel } from '../model/EngagementListModel';
import { PrePopulateDetailsForQuestionnaire } from '../model/Engagement/PrePopulateDetailsForQuestionnaire';
import { DocumentDetais } from '../model/DocumentTemplate';
@Injectable({
  providedIn: 'root'
})
export class HotDocsService {
  WorkItem: HDWorkItemModel;
  IntegrationAPIEndPoint: string;
  pdfResult: any;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private environment: AppConfigService) {
    this.IntegrationAPIEndPoint = environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
  }

  getTemplatePackages(): Observable<TemplatePackageModel[]> {
    return this.http.get<TemplatePackageModel[]>(this.IntegrationAPIEndPoint + '/Hotdocs/GetTemplatePackages');
  }

  getInterviews(questionnaireData: HDQuestionnaireModel): Observable<InterviewModel> {
    return this.http.post<InterviewModel>(this.IntegrationAPIEndPoint + '/Hotdocs/GetInterviews/',
    questionnaireData, { responseType: 'json' as 'json' });
  }

  getDocumentContent(documentid: string, workItemId: string): Observable<any> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/GetDocumentContent/' + documentid + '/' + workItemId;
    return this.http.get<ArrayBuffer>(FetchUrl, { responseType: 'ArrayBuffer' as 'json' });
  }

  checkTemplateVersion(workspace: WorkspaceModel): Observable<boolean> {
    return this.http.post<boolean>(this.IntegrationAPIEndPoint + '/Hotdocs/CheckTemplateVersion/', workspace);
  }

  getHDDocumentListtoPreview(workItemId: string): Observable<HdWorkspaceDocumentModel[]> {
    return this.http.get<HdWorkspaceDocumentModel[]>(this.IntegrationAPIEndPoint + '/Hotdocs/GetDocumentList/' + workItemId);
  }

  createWorkItem(workItem: HDWorkItemModel): Observable<HDWorkItemModel> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/CreateWorkGroup/';
    return this.http.post<HDWorkItemModel>(FetchUrl, workItem, { responseType: 'json' as 'json' });
  }

  saveAssemblySession(workItem: string): Observable<boolean> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/SaveAssemblySession/' + workItem;
    return this.http.get<boolean>(FetchUrl);
  }

  completeAssemblySession(workItem: string, hdaSessionId: string): Observable<boolean> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/CompleteAssemblySession/' + workItem + '/' + hdaSessionId;
    return this.http.get<boolean>(FetchUrl);
  }

  HDCompleteAssembly(workItem: string): Observable<boolean> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/CompleteAssemblySession/' + workItem;
    return this.http.post<boolean>(FetchUrl, workItem);
  }

  getDocumentContentName(documentid: string, workItemId: string): Observable<string> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/GetDocumentContent/' + documentid + '/' + workItemId;
    return this.http.get<string>(FetchUrl, { responseType: 'text' as 'json' });
  }

  getTemplatePackageDetails(TemplatePackageId: string): Observable<TemplatePackageModel> {
    return this.http.get<TemplatePackageModel>(this.IntegrationAPIEndPoint + '/Hotdocs/GetTemplatePackageDetail/' + TemplatePackageId);
  }

  getHDConfigurationDetails(): Observable<HDConfigurationModel> {
    return this.http.get<HDConfigurationModel>(this.IntegrationAPIEndPoint + '/Hotdocs/GetHDConfiguration');
  }

  getHDWorkitemDetails(workitemId: string): Observable<WorkItemDetailsModel> {
    return this.http.get<WorkItemDetailsModel>(this.IntegrationAPIEndPoint + '/Hotdocs/GetWorkItem/' + workitemId);
  }

  saveAssemblySessionofVersionId(workitemDetails : SaveWorkItemAssemblySession): Observable<boolean> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/SaveAssemblySessionofVersion';
    return this.http.post<boolean>(FetchUrl, workitemDetails);
  }

  getReviewResponse(workitemId: string, templatePackageVersionId: string): Observable<QuestionAnswer[]> {
    return this.http.get<QuestionAnswer[]>(this.IntegrationAPIEndPoint + '/Hotdocs/ReviewResponse/' + workitemId + '/' 
    + templatePackageVersionId);
  }

  DeleteWorkItem(workItem: string): Observable<boolean> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/DeleteWorkItem/' + workItem;
    return this.http.delete<boolean>(FetchUrl);
  }

  GetDocumentByteArray(workItemId: string ): Observable<SPDocument> {
    return this.http.get<SPDocument>(this.IntegrationAPIEndPoint + '/Hotdocs/GetDocumentByteArray/' + workItemId );
  }

  GetDocumentsByteArray(workItemId: string[]): Observable<SPDocument[]> {
    let sources: Observable<SPDocument>[] = [];
    workItemId.forEach(itemId => {
      sources.push(this.GetDocumentByteArray(itemId));
    });
    return forkJoin(sources);
  }

  GetHDAnswersToEngage(hotDocsVariable: HotDocsVariableMappingList): Observable<HDSessionAnsToEngModel> {
    return this.http.post<HDSessionAnsToEngModel>(this.IntegrationAPIEndPoint + '/Hotdocs/GetHDAnswersToEngage/' , hotDocsVariable);
  }
  getAnswerSummary(workItemId: string , versionId: string): Observable<InterviewModel> {
    return this.http.get<InterviewModel>(this.IntegrationAPIEndPoint + '/Hotdocs/GetAnswerSummary/'+ workItemId + '/' + versionId,
    { responseType: 'json' as 'json' });
  }

  getAnswerFileContentAync(workitemId: string): Observable<string> {
    return this.http.get<string>(this.IntegrationAPIEndPoint + '/Hotdocs/GetAnswerFileContentAync/' + workitemId, {responseType: 'text' as 'json'});
  }
  
  GetCreateWorkItemDocumentData(workItem: HDWorkItemModel): Observable<HDWorkItemDocumentModel> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/GetCreateWorkItemDocumentData/';
    return this.http.post<HDWorkItemDocumentModel>(FetchUrl, workItem, { responseType: 'json' as 'json' });
  }
  GetContinuanceEligibility(contEligibilityData: ContinuanceEligibilityModel): Observable<boolean>{
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/GetContinuanceEligibilityCheck';
    return this.http.post<boolean>(FetchUrl, contEligibilityData, { responseType: 'json' as 'json' });
  }
  CreateWorkItemData(workItem: HDWorkItemModel): Observable<HDWorkItemModel> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/CreateWorkItemForTnC';
    return this.http.post<HDWorkItemModel>(FetchUrl, workItem, { responseType: 'json' as 'json' });
  }
 
  CreateDownloadTemplateWorkitem(downloadWorkItem: DownloadWorkItem): Observable<DocumentDetais> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/CreateDownloadTemplateWorkitem/';
    return this.http.post<DocumentDetais>(FetchUrl, downloadWorkItem, { responseType: 'json' as 'json' });
  }
  
  SaveTemplateWorkitemToSharepoint(downloadWorkItem: DownloadWorkItem): Observable<boolean> {
    const FetchUrl: string = this.IntegrationAPIEndPoint + '/Hotdocs/SaveTemplateWorkitemToSharepoint/';
    return this.http.post<boolean>(FetchUrl, downloadWorkItem, { responseType: 'json' as 'json' });
  }
  
  GetEngagementDetailQuestionnaire(detail: PrePopulateDetailsForQuestionnaire): Observable<InterviewModel> {
    return this.http.post<InterviewModel>(this.IntegrationAPIEndPoint 
      + '/Hotdocs/GetEngagementDetailQuestionnaire/',detail);
  }

  GetWorkItemVersions(HDWorkitemId: string): Observable<string>{
    return this.http.get<string>(this.IntegrationAPIEndPoint + '/Hotdocs/GetWorkItemVersions/'+HDWorkitemId,
     {responseType: 'text' as 'json'});
  }

  canReloadPageForHotdocsTemplate(templateName: string, hdTemplatePackageId: string, versionNumber): boolean {
      var browserTemplateVersion = this.GetHdTemplateVersionFromBrowserSession(templateName, hdTemplatePackageId);
    if (browserTemplateVersion == 0) {
      this.UpdateHdTemplateVersionInBrowserSession(templateName, hdTemplatePackageId, versionNumber);
      return false;
    } else if (browserTemplateVersion == versionNumber) {
      return false;
    } else {
      return true;
    }
  }

  GetHdTemplateVersionFromBrowserSession(templateName: string, hdTemplatePackageId: string): number {
    var browser_HdTemplateVersionModel: HdTemplateVersionBrowserSessionModel[] = [];
    var tempversion = 0;

    var browser_HdTemplateVersion = sessionStorage.getItem("TemplateVersionList")
    if (browser_HdTemplateVersion != null && browser_HdTemplateVersion != undefined) {
      browser_HdTemplateVersionModel = JSON.parse(browser_HdTemplateVersion);
    }
    if (browser_HdTemplateVersionModel.length > 0) {
      browser_HdTemplateVersionModel.forEach(value => {
        if (value.hdTemplatePackageId === hdTemplatePackageId) {
          tempversion = value.hdTemplateVersionNo;
        }
      });
    }

    return tempversion;
  }

  UpdateHdTemplateVersionInBrowserSession(documentAliasName: string, hdTemplatePackageId: string, hdCurrentTempVersion: number) {
    var browser_HdTemplateVersionModel: HdTemplateVersionBrowserSessionModel[] = [];
    var tempVersionNo = 0;
    var browser_HdTemplateVersion = sessionStorage.getItem("TemplateVersionList");
    if (browser_HdTemplateVersion != null && browser_HdTemplateVersion != undefined) {
      browser_HdTemplateVersionModel = JSON.parse(browser_HdTemplateVersion);
    }
    if (browser_HdTemplateVersionModel.length > 0) {
      browser_HdTemplateVersionModel.forEach(value => {
        if (value.hdTemplatePackageId === hdTemplatePackageId) {
          tempVersionNo = value.hdTemplateVersionNo;
        }
      });
    }

    if (tempVersionNo != hdCurrentTempVersion) {
      if (tempVersionNo == 0) {
        var hdTemplateVersionModelToPush = new HdTemplateVersionBrowserSessionModel();
        hdTemplateVersionModelToPush.hdTemplateVersionNo = hdCurrentTempVersion;
        hdTemplateVersionModelToPush.hdTemplateName = documentAliasName;
        hdTemplateVersionModelToPush.hdTemplatePackageId = hdTemplatePackageId;
        browser_HdTemplateVersionModel.push(hdTemplateVersionModelToPush);
      } else {
        browser_HdTemplateVersionModel.forEach(value => {
          if (value.hdTemplatePackageId === hdTemplatePackageId) {
            value.hdTemplateVersionNo = hdCurrentTempVersion;
            value.hdTemplateName = documentAliasName;
          }
        });
      }
      sessionStorage.removeItem('TemplateVersionList');
      sessionStorage.setItem('TemplateVersionList', JSON.stringify(browser_HdTemplateVersionModel));
    }
  }

  RemoveHdTemplateVersionListInBrowserSession() {
    sessionStorage.removeItem("TemplateVersionList");
  }
}
import { MsalGuardConfiguration } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    console.log('here');
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [
                'User.Read'
            ]
        },
        loginFailedRoute: '/noaccess'
    };
}
export class ClientConsentOptions{
    clientConsentId: number;
    engagementID: number;
    clientConsentOption: string;
    clientConsentOptionDescription: string;
    clientConsentOptionNumber: number;
    isDefault: boolean;
    isChecked: boolean;
    isPrimaryConsent: boolean;
}

export class ClientConsentFlagUpdateModel {
    EngagementId: number;
    ClientConsentFlag: number;
}

export class UpdateClientConsentOptions{
    clientConsentId: number;
    engagementId: number;
    isChecked: boolean;
    checkedBy:string;    
    modifiedBy:string;
}

export class UpdateClientConsentDataModel {
    updateClientConsentOptions:UpdateClientConsentOptions[]

}

export class ClientConsentDataTerm{
    applicationMasterId: number;
    applicationCode: number;
    applicationName: string;
    code: string;
    value: string;
    active: boolean;
    displayOrder: number;
    createdBy: string;
    createdOn: string;
    lastModifiedBy: string;
    lastModifiedOn: string;
}
import { WorkspaceComments } from './WorkspaceComments';

export class WorkspaceDiscussion {
  discussionId: string;
  workspaceId: string;
  discussion: string;
  userName: string;
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
  workspaceCommentsList: WorkspaceComments[];
  collapsibleId: number;
  loggedInUserFullName: string;
}

import { Component, OnInit } from '@angular/core';
import { AlertProcessAudit } from '../model/Alert';
import { SPSiteMetadata } from '../model/SharePoiint/SharePointSite';
import { SharePointMetadataMapping } from '../model/sharepoint-metadata-mapping';
import { SharePointWrapper } from '../model/sharepoint-metadata-wrapper';
import { CollaborationSiteService } from '../services/collaboration-site.service';
import { EngagementService } from '../services/EngagementService';
import { HotDocsService } from '../services/HotDocsService';
import { LoadingScreenService } from '../services/loading-screen.service';

interface AuditEngagementValidationResponse {
  statusCode: string;
  statusMessage: string;
  hdWorkItemId: string;
}


@Component({
  selector: 'app-process-audit-engagement',
  templateUrl: './process-audit-engagement.component.html',
  styleUrls: ['./process-audit-engagement.component.css']
})
export class ProcessAuditEngagementComponent implements OnInit {

  engagementId: string;
  engagementId1: number;
  engagementIdArray: any;
  assignmentCode: string = '';
  auditEngagementValidationResponse: AuditEngagementValidationResponse;
  hotdocsFieldToSearchInHotDocsAnswers: string[] = ['AUD_Existing_Assignment', 'CMS_Assignment_code'];
  avePointMetadaFieldToUpdate: string[] = ["Client Code", "Assignment Code", "Engagement Status"]
  userObject: any;
  placeholder: string = "Enter engagement Id";

  constructor(
    private _engagementService: EngagementService
    , private _loadingScreenService: LoadingScreenService
    , private _hotdocsService: HotDocsService
    , private _collaborationService: CollaborationSiteService) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
  }

  ngOnInit() {
    this.alerts = [];
  }

  processAuditEngagementRequest() {

    this.alerts = [];
    console.log("entered engagement value string is" + this.engagementId);
    if (this.engagementId != undefined) {
      console.log("length of the input" + this.engagementId.length);
      this.engagementIdArray = this.engagementId.split(",");
      this.engagementIdArray.forEach(element => {
        this.engagementId1 = Number(element);
        console.log(this.engagementId1);
        this.validateAuditEngagement(this.engagementId1);
      });


    }
  }

  validateAuditEngagement(engagementId1: number) {

    //Check if engagement id is number only and non-zero
    if (!this.validateEngagementId(engagementId1))
      return false;

    this._loadingScreenService.startLoading();

    this._engagementService.ValidateAuditEngagementAndGetHDWorkItemId(engagementId1).subscribe(response => {
      this.auditEngagementValidationResponse = response;
      if (parseInt(this.auditEngagementValidationResponse.statusCode, 10) == 0) {
        this.getHotDocsAnswerFileContent(this.auditEngagementValidationResponse.hdWorkItemId, engagementId1);
      } else {
        this.add(engagementId1, this.auditEngagementValidationResponse.statusMessage, 'danger', null);
        this._loadingScreenService.stopLoading();
      }
    },
      err => {
        this._loadingScreenService.stopLoading();
        this.add(engagementId1, 'Failed to validate engagement', 'danger', null);
      });
  }

  validateEngagementId(engagementId: number) {
    const regex = /^[0-9]{1,10}$/;
    if (engagementId != null && regex.test(engagementId.toString()) && parseInt(engagementId.toString(), 10) !== 0) {
      return true;
    } else {
      this.add(engagementId, 'Invalid engagement', 'danger', null);
      return false;
    }
  }

  getHotDocsAnswerFileContent(hotdocsWorkitemId: string, engagementId1: number) {
    this._hotdocsService.getAnswerFileContentAync(hotdocsWorkitemId).subscribe(response => {
      const parser = new DOMParser();
      const xmlDom = parser.parseFromString(response, "application/xml");
      let hotdocsFieldFound = false;

      if (xmlDom.childElementCount > 0) {

        let nodeItem;
        for (let i = 0; i < xmlDom.childNodes[0].childNodes.length; i++) {
          nodeItem = xmlDom.childNodes[0].childNodes[i];
          if (nodeItem.nodeType === 1) {
            for (let j = 0; j < this.hotdocsFieldToSearchInHotDocsAnswers.length; j++) {
              if (this.hotdocsFieldToSearchInHotDocsAnswers[j].toLocaleLowerCase() == ((nodeItem as any).attributes[0].value).trim().toLocaleLowerCase()) {
                hotdocsFieldFound = true;
                this.assignmentCode = (nodeItem.childNodes[1].textContent).trim();
                break;
              }
            }
          }
        }

        // Check if field found in hotdocs
        if (!hotdocsFieldFound) {
          this.add(engagementId1, 'Assignment code not found in hotdocs answer file', 'danger', null);
          this._loadingScreenService.stopLoading();
        }
        else {
          if (this.assignmentCode == null || this.assignmentCode == undefined || this.assignmentCode == '' || this.assignmentCode == 'undefined') {
            this.add(engagementId1, 'Assignment code is empty', 'danger', null);
            this._loadingScreenService.stopLoading();
          }
          else if (this.assignmentCode.length > 5) {
            this.add(engagementId1, 'Assignment code max allowed length is 5', 'danger', null);
            this._loadingScreenService.stopLoading();
          }
          else {
            this.updateAuditEngagementAssignmentCodeAndStatus(engagementId1);
          }
        }
      }
      else {
        this.add(engagementId1, 'Hotdocs answer file is empty', 'danger', null);
        this._loadingScreenService.stopLoading();
      }
    },
      err => {
        this._loadingScreenService.stopLoading();
        this.add(engagementId1, 'Failed to load hotdocs answers', 'danger', null);
      });
  }

  updateAuditEngagementAssignmentCodeAndStatus(engagementId1: number) {

    let updateAuditEngagementReqest = {
      engagementId: engagementId1,
      assignmentCode: this.assignmentCode,
      modifiedBy: this.userObject.userName
    }

    this._engagementService.UpdateAuditAssignmentCodeAndStatus(updateAuditEngagementReqest).subscribe(response => {
      if (response) {
        this._collaborationService.getCollaborationSiteId(engagementId1).subscribe((colloborationId: number) => {
          this.updateSharePointMetadata(colloborationId, engagementId1);
        },
          err => {
            this._loadingScreenService.stopLoading();
            this.add(engagementId1, 'Failed to load collaboration detail', 'danger', null);
          });
      }
      else {
        this.add(engagementId1, 'Failed to update engagement status and assignment code', 'danger', null);
        this._loadingScreenService.stopLoading();
      }
    },
      err => {
        this._loadingScreenService.stopLoading();
        this.add(engagementId1, 'Error while updating engagement status and assignment code', 'danger', null);
      });
  }

  updateSharePointMetadata(collaborationSiteId: number, engagementId1: number) {
    this._engagementService.getMetadataList().subscribe((metadata: SPSiteMetadata[]) => {
      const sharepointMetadata: SPSiteMetadata[] = [];
      this._engagementService.getSharePointMetadataMapping(engagementId1, collaborationSiteId)
        .subscribe((engagementSharePointData: SharePointMetadataMapping) => {
          engagementSharePointData.propertiesMapping.forEach(item => {
            metadata.forEach(element => {
              console.log(element.name);
              if (item.value === element.name && this.avePointMetadaFieldToUpdate.indexOf(element.name) > -1) {
                element.value = engagementSharePointData.metadata[item.key];
                sharepointMetadata.push(element);
              }
            });
          });
          const sharePointWrapper = new SharePointWrapper();
          sharePointWrapper.siteURL = engagementSharePointData.metadata.siteUrl;
          sharePointWrapper.metadataList = sharepointMetadata;
          this._engagementService.updateSiteMetadata(sharePointWrapper).subscribe(status => {
            console.log('Metadata updated successfully')
          }
            , err => {
              console.log(`${engagementId1} - Error while updating AvePoint metadata`);
              console.log(err);
              this._loadingScreenService.stopLoading();
            });

          setTimeout(() => {
            this.add(engagementId1, 'Audit engagement has been processed successfully and updated all required details', 'success', null);
            this._loadingScreenService.stopLoading();
          }, 2000);
        },
          err => {
            this._loadingScreenService.stopLoading();
            console.log(err);
            this.add(engagementId1, 'Error while loading sharepoint metadata mapping from database', 'danger', null);
          });
    },
      err => {
        this._loadingScreenService.stopLoading();
        console.log(err);
        this.add(engagementId1, 'Error while loading metadata from AvePoint', 'danger', null);
      });
  }

  //------------------ Alert section -----------------------------------------------
  alerts: AlertProcessAudit[] = [];
  alert: AlertProcessAudit;
  isSuccess: boolean = false;
  add(engId: number, message: string, messageType: string, debug: string) {
    //this.isSuccess = messageType == 'success';
    this.alerts.push({ engagementId: engId, message: message, messageType: messageType, debug: debug, isSuccess: messageType == 'success' });
  }
}

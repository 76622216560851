<div class='input-group mw-100 input-group-div'>
  <input class="form-control input-date {{txtDate}} border-right-1 input-height rounded-0"
    data-inputmask-alias="mm/dd/yyyy" data-val="true" data-val-required="Required" id="{{txtDate}}" 
    name="{{txtDate}}" placeholder="{{placeholderValue}}" type="text" value="" 
    [(ngModel)]="FormattedDate" />
  <div class="input-group-append">
    <div class="mw-100 calender-div text-center input-height">
      <img class="mx-auto d-block" src="../assets/images/Engagement/date_24px.svg" alt="date"
        id="imgcalendar_{{txtDate}}" style="cursor: pointer;" />
    </div>
  </div>
</div>
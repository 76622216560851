export class InterviewModel {
    serviceMetadataUrl: string;
    interviewJsUrl: string;
    coreSessionId: string;
    workItemId: string;
    token: string;
    hdaSessionId:string;
    versionId:string;
    templateName: string;
}
